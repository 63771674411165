<template>
  <div
    :id="
      chatTypeEnum[chatTypeEnum.Pie] === chartType
        ? 'isPie'
        : chatTypeEnum[chatTypeEnum.Bar] === chartType
        ? 'isBar'
        : chatTypeEnum[chatTypeEnum.Line] === chartType
        ? 'isLine'
        : 'chart-area'
    "
    :class="wrapperClass"
  >
    <canvas v-if="chatTypeEnum[chatTypeEnum.Pie] === chartType" ref="chartElement" :width="100" :height="65"></canvas>
    <canvas
      v-else-if="chatTypeEnum[chatTypeEnum.Bar] === chartType"
      ref="chartElement"
      :width="100"
      :height="65"
    ></canvas>
    <canvas
      v-else-if="chatTypeEnum[chatTypeEnum.Line] === chartType"
      ref="chartElement"
      :width="22"
      :height="5"
    ></canvas>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import { Chart, ChartConfiguration } from 'chart.js'
import Shared from '@/common/shared'
import { ChartType } from '@/common/enums'

@Component({
  name: 'DashboardChart',
})
export default class DashboardChart extends Vue {
  @Prop() private chartData: any[]
  @Prop() private maxValue: number
  @Prop() private chartType: string
  @Prop({ default: false })
  private isTop: boolean
  private chatTypeEnum = ChartType

  private canvas: HTMLCanvasElement | null = null
  private chart: Chart | null = null

  public mounted() {
    this.canvas = this.$refs.chartElement as HTMLCanvasElement
    SetThemeForJobType(this, undefined)
    if (this.chartConfiguration != null) {
      this.chart = new Chart(this.canvas, this.chartConfiguration)
    }
  }

  @Watch('chartData')
  private updateChart() {
    setTimeout(() => {
      if (this.chart != null && this.canvas != null && this.chartConfiguration != null) {
        this.chart.destroy()
        this.chart = new Chart(this.canvas, this.chartConfiguration)
      }
    }, 1000)
  }

  private get wrapperClass() {
    if (this.chatTypeEnum[this.chatTypeEnum.Line].toString() === this.chartType) {
      return 'chart-line'
    } else if (this.isTop) {
      return 'chart chart-top-info'
    } else if (this.chatTypeEnum[this.chatTypeEnum.Pie].toString() === this.chartType) {
      return 'chart-pie chart-top-info'
    } else {
      return 'chart'
    }
  }

  private get chartConfiguration() {
    return this.chatTypeEnum[this.chatTypeEnum.Line].toString() === this.chartType
      ? this.lineChartConfiguration()
      : this.chatTypeEnum[this.chatTypeEnum.Bar].toString() === this.chartType
      ? this.barChartConfiguration()
      : this.chatTypeEnum[this.chatTypeEnum.Pie].toString() === this.chartType
      ? this.PieChartConfiguration()
      : null
  }

  private barChartConfiguration() {
    const values = 'values'
    return {
      type: 'bar',
      data: {
        labels: Object.keys(this.chartData),
        datasets: [
          {
            data: Object[values](this.chartData),
            backgroundColor: 'rgb(255,255,255)',
            lineTension: 1,
          },
        ],
      },
      options: {
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
              return ''
            },
            label: (tooltipItem, data) => {
              const datasets = 'datasets'
              const dataIndex = 'data'
              const tooltipIndex = 'index'
              return this.chartData[tooltipItem.index!].toString()
            },
          },
          backgroundColor: '#FFF',
          titleFontSize: 10,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 10,
          displayColors: false,
        },
        animation: {
          duration: 0,
        },
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                max: this.maxValue,
                beginAtZero: true,
                display: false,
                padding: 2,
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    }
  }

  private lineChartConfiguration(): ChartConfiguration | null {
    if (this.canvas == null) {
      return null
    }

    const gradient = this.canvas.getContext('2d')!.createLinearGradient(0, 0, 0, 450)
    gradient.addColorStop(0, 'rgba(255,255,255, 0.3)')
    gradient.addColorStop(0.5, 'rgba(3, 169, 244, 0.25)')
    gradient.addColorStop(1, 'rgba(3, 169, 244, 0.3)')
    const values = 'values'
    return {
      type: 'line',
      data: {
        labels: Object.keys(this.chartData),
        datasets: [
          {
            data: Object[values](this.chartData),
            backgroundColor: gradient,
            pointBackgroundColor: 'rgba(255,255,255,0.5)',
            borderColor: 'rgba(255,255,255,0.2)',
            spanGaps: true,
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 5,
          },
        ],
      },
      options: {
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
              if (tooltipItem[0]) {
                const xLabel: any | undefined = tooltipItem[0].xLabel
                if (xLabel) {
                  // check if chartdata is type of number array
                  const numberTitle = /^\d+$/.test(xLabel)
                  return !numberTitle ? xLabel : ''
                }
              }
              return ''
            },
            label: (tooltipItem, data) => {
              const datasets = 'datasets'
              const dataIndex = 'data'
              const tooltipIndex = 'index'
              const yLabel: any = tooltipItem.yLabel
              const checkValidLabel: any = tooltipItem.yLabel && parseInt(tooltipItem.yLabel.toString(), 10) >= 0
              return checkValidLabel >= 0 ? yLabel.toString() : ''
            },
          },
          backgroundColor: '#FFF',
          titleFontSize: 11,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 12,
          xPadding: 5,
          yPadding: 5,
          displayColors: false,
        },
        layout: {
          padding: {
            left: 7,
            right: -3,
            top: 20,
            bottom: 5,
          },
        },
        animation: {
          duration: 0,
        },
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                max: this.maxValue,
                beginAtZero: true,
                display: false,
                minRotation: 90,
              },
              stacked: true,
              position: 'right',
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    }
  }

  // private PieChartConfiguration(): ChartConfiguration | null {
  // TODO: Fix this properly
  private PieChartConfiguration(): any {
    if (this.canvas == null) {
      return null
    }

    const values = 'values'
    return {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: Object[values](this.chartData[0]),
            backgroundColor: ['#7CB342', '#29B6F6', '#FB8C00', '#E53935', '#00897B'],
          },
        ],
        labels: Object.keys(this.chartData[0]),
      },
      options: Shared.getPieChartOptions(),
    }
  }
}
</script>

<style scoped>
.chart {
  max-width: 70px;
  position: relative;
  vertical-align: bottom;
  display: inline-block;
  top: 5px;
}
.chart-top-info {
  top: -15px;
  float: right;
}
.chart-line {
  width: 100%;
  margin-top: 20px;
  max-height: 100px;
  position: relative;
}
.chart-line canvas {
  max-height: 100px;
}
.chart-line .chartjs-render-monitor {
  width: 100% !important;
}
.chart-pie {
  width: 100% !important;
}
@media only screen and (max-width: 1683px) {
  .chart {
    max-width: 65px;
  }
}
</style>
