import { JobNoteType } from '@/common/enums'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'

export default class AddNewJobNote {
  public jobId: string
  public noteType: JobNoteType
  public title: string
  public description: string
  public loggedByUserId: string
  public loggedByUserName: string
  public forEmergencyDetailId: number
  public forEmergencyDetailDescription: string | null
  public callSid: string

  public constructor() {
    this.noteType = 'Miscellaneous'
  }
}
