import store from '@/store'
import { commandNames } from '../commands/command-names'
import CommandHandler from '../interfaces/command-handler'

export default class CloseWizardHandler implements CommandHandler {
  public readonly commandName = commandNames.closeWizardCommand
  async execute(value: any): Promise<void> {
    const closeWizard = value as unknown as boolean

    if (closeWizard) {
      await store.Instance.dispatch('heWizardModule/setHeWizardVisible', false)
    }
  }
}
