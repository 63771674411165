<template>
  <div class="signatureform">
    <v-container fluid class="pa-0 grid-list-md">
      <v-layout wrap>
        <v-flex xs12>
          <p>I hereby confirm that in signing this Pre-Qualification Questionnaire;</p>
          <p>
            a. That the
            <strong>information supplied in this application is true and complete</strong>
            and;
          </p>
          <p>
            b. I understand that
            <strong>CET</strong>
            will reply upon these answers in consideration of our suitability to become a contractor and;
          </p>
          <p>
            c. I will comply with the requirements of the document;
            <i><strong>Health, Safety & Environmental Requirements.</strong></i>
          </p>
          <p>
            d. I have read, understood and will comply with the CET
            <a :href="getTOBAUrl()" target="_blank" class="toba-link">
              <i><strong>Terms of Business Agreement (TOBA)</strong></i>
            </a>
          </p>
          <p>
            e.
            <strong>Where any subsequent changes in the information provided is identified</strong>
            or change in circumstances as described in this application are known, I will notify
            <strong>CET</strong>
            immediately.
          </p>
          <v-spacer></v-spacer>
          <i>
            <strong class="red--text">
              <h4>Important:</h4>
              <p>
                Any contract with
                <span class="black--text">CET</span>
                is entered into on the basis of the information provided within this online application process and the
                <a :href="getTOBAUrl()" target="_blank" class="toba-link">
                  <u>Terms of Business Agreement (TOBA)</u>
                </a>
                document.
              </p>
              <p>
                Failure to disclose any subsequent changes in the information provided may result in termination of any
                such contract and proceedings in consideration of any loss, damage or injury arising. All information
                provided will be treated as strictly confidential and used for processing this application only.
              </p>
              <p></p>
            </strong>
          </i>
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model.trim="contractorSignatureModel.name"
            v-validate="'required'"
            label="Name"
            maxlength="50"
            required
            class="required"
            data-vv-scope="frmSignature"
            data-vv-name="Name"
            :error-messages="errors.collect('Name')"
          ></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-select
            v-model="contractorSignatureModel.position"
            v-validate="'required'"
            :items="position"
            label="Select Position"
            required
            class="required"
            data-vv-scope="frmSignature"
            data-vv-name="Position"
            :error-messages="errors.collect('Position')"
          ></v-select>
        </v-flex>
        <v-flex xs4>
          <v-menu
            ref="toDatePickerMenu"
            v-model="showSignatureOnDateSelection"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="chosenSignatureOnFormattedDate"
                v-validate="'required'"
                label="Date"
                append-icon="date_range"
                readonly
                class="required"
                data-vv-scope="frmSignature"
                data-vv-name="Date"
                :error-messages="errors.collect('Date')"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="chosenSignatureOnDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="showSignatureOnDateSelection = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="onSelectSignatureOnDatePicker(chosenSignatureOnDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12>
          <v-checkbox
            v-model="isAgreedToTermsAndCondition"
            color="primary"
            class="d-inline-block"
            :class="isAgreedToTermsAndCondition ? '' : 'red-border-checkbox'"
            label="I Agree to the terms and conditions"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </v-container>
    <v-divider class="mt-3"></v-divider>
    <v-card-actions class="bottom-align">
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isUserRoleContractor"
        color="primary"
        :loading="isLoading"
        :disabled="isLoading"
        @click="saveSignature"
      >
        save
      </v-btn>
      <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
        {{ saveSnackbarText }}
        <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
      </v-snackbar>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import Shared from '@/common/shared'
import SignatureModel from '@/models/contractor/SignatureModel'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import store from '@/store'
import Store from '@/store'
import eventBus from '@/common/bus'

@Component
export default class ContractorSignature extends Vue {
  @Prop() private contractorId: string
  @Prop() private contractorSignature: SignatureModel | null
  @Prop({ default: true }) private isContractorReviewed: boolean

  private showSignatureOnDateSelection = false
  private chosenSignatureOnFormattedDate: any = null
  private chosenSignatureOnDate: any = null
  private position: any = ['Principal', 'Director']
  private isLoading = false
  private contractorSignatureModel: SignatureModel = new SignatureModel()
  private saveSnackbarTimeout = 3000
  private saveSnackbar = false
  private saveSnackbarText = 'Signature saved successfully.'
  private isAgreedToTermsAndCondition: boolean | null = null

  // Check if userRole is contractor
  private get isUserRoleContractor(): boolean {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail' && this.isContractorReviewed
  }

  @Watch('contractorId')
  private onContractorChange() {
    this.contractorSignatureModel = new SignatureModel()
    this.chosenSignatureOnFormattedDate = moment(new Date()).format(DateTimePicker.DATE_FORMAT)
    if (this.contractorSignature) {
      // bind Contractor declaration details
      this.contractorSignatureModel = this.contractorSignature
      this.chosenSignatureOnFormattedDate = moment(this.contractorSignatureModel.signatureDate).format(
        DateTimePicker.DATE_FORMAT
      )
    }
    this.isAgreedToTermsAndCondition = this.contractorSignature ? (this.contractorSignature.name ? true : false) : null
    this.$validator.errors.items = []
  }

  private mounted() {
    this.chosenSignatureOnFormattedDate = moment(new Date()).format(DateTimePicker.DATE_FORMAT)
    if (this.contractorSignature) {
      // bind Contractor declaration details
      this.contractorSignatureModel = this.contractorSignature
      this.chosenSignatureOnFormattedDate = moment(this.contractorSignatureModel.signatureDate).format(
        DateTimePicker.DATE_FORMAT
      )
      this.isAgreedToTermsAndCondition = this.contractorSignature.name ? true : false
    }
  }

  private onSelectSignatureOnDatePicker(date: string) {
    this.chosenSignatureOnFormattedDate = moment(date).format(DateTimePicker.DATE_FORMAT)
    this.showSignatureOnDateSelection = false
  }

  private saveSignature() {
    const self = this
    self.contractorSignatureModel.signatureDate = moment.utc(self.chosenSignatureOnFormattedDate)
    self
      .validate()
      .then((result: boolean) => {
        if (result && self.contractorSignatureModel !== null) {
          self.isLoading = true
          ContractorController.SaveSignature(self.contractorSignatureModel, self.contractorId)
            .then((res: boolean) => {
              if (res) {
                self.isLoading = false
                self.saveSnackbar = true
                this.$emit('onSaveContractorSignatureDetails', this.contractorSignatureModel)
              }
            })
            .catch((err: any) => {
              self.isLoading = false
              eventBus.$emit('errorHandler', 'Error saving contractor signature, please try again', true)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
        self.isLoading = false
      })
  }

  private async validate(): Promise<boolean> {
    let result: boolean = await this.$validator.validateAll('frmSignature')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    // set validation for terms and conditions
    result = result ? (this.isAgreedToTermsAndCondition ? true : false) : false

    return result
  }

  private getTOBAUrl() {
    return Store.Instance.state.Environment.TOBAFileUrl
  }
}
</script>

<style scoped>
.signatureform >>> .signeture-box {
  border-bottom: 1px solid #949494 !important;
  width: 100%;
  border-color: #949494 !important;
}
.validation-text {
  vertical-align: text-bottom;
}
.signaturepad-content {
  position: relative;
}
.signaturepad_icon {
  position: absolute;
  top: 0px;
  right: 0px;
}
.red-border-checkbox >>> .v-icon,
.red-border-checkbox >>> .v-label {
  color: rgb(255, 86, 86);
}
.toba-link {
  text-decoration: underline;
}
</style>
