import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import CustomDashboardModel from '@/models/customDashboard/CustomDashboardModel'
import { WorkerWithAdditions } from '@/components/dashboard/worker'
import ComplaintModel from '@/models/claim/ComplaintModel'
import JobDashboardModel from '@/models/claim/JobDashboardModel'
import InsurerStatsModel from '@/models/InsurerStatsModel'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import ClientPortalStatsModel from '@/models/ClientPortalStatsModel'

export default class CustomDashboardController {
  public static async GetTwilioDashboardTopTileData(elementId: number): Promise<any> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetTwilioDashboardTopTileData?element=' + elementId)
    return res.data ? res.data : []
  }

  public static async GetComplaintTopTile(elementId: number): Promise<any> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetComplaintTopTile?element=' + elementId)
    return res.data ? res.data : []
  }

  public static async GetJobLoggedInLast15Day(): Promise<any> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetJobLoggedInLast15Day')
    return res.data ? res.data : []
  }

  public static async GetTop5LoggedEmergency(): Promise<any> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetTop5LoggedEmergency')
    return res.data ? res.data : []
  }

  public static async GetManagerSupervisorTableData(): Promise<WorkerWithAdditions[] | null> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetOnlineWorkersWithSurge')
    return res.data ? res.data : []
  }

  public static async GetOutstandingComplaints(): Promise<ComplaintModel[] | null> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetOutstandingComplaints')
    return res.data ? res.data : []
  }

  public static async GetCustomDashboardData(dashboardId: string): Promise<CustomDashboardModel | null> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetCustomDashboardData?dashboardId=' + dashboardId)
    return res.data ? res.data : null
  }

  public static async GetTwilioDashboardPanelData(
    InsurerId: number,
    InsurerName: string
  ): Promise<InsurerStatsModel | null> {
    const res = await CallCentreApi.get<any>(
      'CustomDashboard/GetTwilioDashboardPanelData?insurerId=' + InsurerId + '&insurerName=' + InsurerName
    )
    return res.data ? res.data : null
  }

  public static async GetJobHistoryTableData(): Promise<JobDashboardModel | null> {
    const res = await CallCentreApi.get<JobDashboardModel>('Dashboard/GetJobDashboardData')
    return res.data
  }

  public static async GetJobDetails(
    pageNumber: number,
    pageSize: number,
    searchField: string,
    search: string,
    sortBy: string,
    sortOrder: string
  ): Promise<JobDashboardModel | null> {
    const res = await CallCentreApi.get<JobDashboardModel>(
      'Dashboard/GetJobDetails?pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize +
        '&searchField=' +
        searchField +
        '&search=' +
        search +
        '&sortBy=' +
        sortBy +
        '&sortOrder=' +
        sortOrder
    )
    return res.data
  }

  public static async SaveCustomDashboard(dynamicLayout: CustomDashboardModel): Promise<string | null> {
    const res = await CallCentreApi.post<any>('CustomDashboard/SaveCustomDashboard', dynamicLayout)
    return res.data ? res.data : null
  }

  public static async DeleteCustomDashboard(dashboardId: string): Promise<boolean | null> {
    const res = await CallCentreApi.post<any>('CustomDashboard/DeleteCustomDashboard?dashboardId=' + dashboardId)
    return res.status === 202 ? true : false
  }

    public static async GetInsurerPortalList(): Promise<ClientTemplateModel[]> {
        const res = await CallCentreApi.get('Insurer/GetBrandSpecificClientTemplates');
        return res.status === 200 ? res.data : [];
    }

  public static async GetInsurerPortalPanelData(documentId: string): Promise<ClientPortalStatsModel | null> {
    const res = await CallCentreApi.get<any>('CustomDashboard/GetInsurerPortalPanelData?documentId=' + documentId)
    return res.data ? res.data : null
  }

  public static async GetUserCustomDashboards(): Promise<CustomDashboardModel[]> {
    const res = await CallCentreApi.get<CustomDashboardModel[]>('CustomDashboard/GetUserCustomDashboards')
    return res.data
  }
}
