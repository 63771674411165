import InitalWizardData from '@/messaging/models/initial-wizard-data'

export default class InitialWizardDataCommand implements CommandMessage<InitalWizardData> {
  public constructor(createClaim: InitalWizardData) {
    this.data = createClaim
  }

  public readonly command = 'InitialWizardDataCommand'
  public readonly data: InitalWizardData
}
