<template>
  <div>
    <p>{{ errorMessage }}</p>

    <v-btn class="primary" @click="logout">Log Out</v-btn>
  </div>
</template>

<script lang="ts">
import TwilioController from '@/api/twilioController'
import eventBus from '@/common/bus'
import Store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class UnauthorizedView extends Vue {
  @Action('signOutOidc') private signOutOidc
  private contractorAccountClosed = 'Your contractor account has been closed.'
  private unauthorized = 'You do not have access to this portal.'

  get errorMessage(): string {
    switch (Store.Instance.state.SessionDetail.detailRecordType) {
      case 'EngineerDetail':
        if (Store.Instance.state.ContractorRegistrationStatus === 'Archived') {
          return this.contractorAccountClosed
        }
        break
    }

    return this.unauthorized
  }

  logout() {
    if (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
      TwilioController.LogoutWorker()
        .then((result: boolean) => {
          if (result) {
            return this.signOutOidc()
          }

          throw new Error('Error logging out of Twilio')
        })
        .catch(() => {
          eventBus.$emit('errorHandler', 'There was an error logging you out, please try again.', true)
        })
    } else {
      this.signOutOidc()
    }
  }
}
</script>
