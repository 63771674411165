<template>
  <div>
    <div class="mb-2">
      <h4>{{ item.id === null ? 'Added Complaint' : 'Updated Complaint' }}</h4>
    </div>
    <div v-if="item.severity !== ''" class="mb-1">
      <label>Severity:</label>
      <span>{{ item.severity }}</span>
    </div>
    <div v-if="item.responsiblePerson" class="mb-1">
      <label class="MEDIUM">Responsible Person:</label>
      <span>{{ username }}</span>
    </div>
    <div v-if="item.complaintTypeDescription" class="mb-1">
      <label>Complaint Type:</label>
      <span>{{ item.complaintTypeDescription }}</span>
    </div>
    <div class="mb-1">
      <label>Received At:</label>
      <span>
        {{
          item.receivedDate
            ? getFormattedDateTime(item.receivedDate)
            : item.createdAt
            ? getFormattedDateTime(item.createdAt)
            : '-'
        }}
      </span>
    </div>
    <div v-if="item.reasonForDelay" class="mb-1">
      <label>Reason For Delay:</label>
      <span>{{ item.reasonForDelay }}</span>
    </div>
    <div v-if="item.dateOfResolution" class="mb-1">
      <label>Date Of Resolution:</label>
      <span>
        {{ item.dateOfResolution ? getFormattedDateTime(item.dateOfResolution) : '' }}
      </span>
    </div>
    <div v-if="item.complaintSummary" class="mb-1">
      <label>Complaint Summary:</label>
      <span>{{ item.complaintSummary }}</span>
    </div>
    <div v-if="item.agreedAction" class="mb-1">
      <label>Agreed Action:</label>
      <span>{{ item.agreedAction }}</span>
    </div>

    <div v-if="item.initialResponseLetterDate" class="mb-1">
      <label>Initial Response Letter Due:</label>
      <span>{{ getFormattedDateTime(item.initialResponseLetterDate) }}</span>
    </div>
    <div v-if="item.initialResponseLetterSent" class="mb-1">
      <label>Initial Response Letter Sent:</label>
      <span>{{ getFormattedDateTime(item.initialResponseLetterSent) }}</span>
    </div>
    <div v-if="item.fourWeekLetterDate" class="mb-1">
      <label>Four Week Letter Due:</label>
      <span>{{ getFormattedDateTime(item.fourWeekLetterDate) }}</span>
    </div>
    <div v-if="item.fourWeekLetterSent" class="mb-1">
      <label>Four Week Letter Sent:</label>
      <span>{{ getFormattedDateTime(item.fourWeekLetterSent) }}</span>
    </div>
    <div v-if="item.eightWeekLetterDate" class="mb-1">
      <label>Eight Week Letter Due:</label>
      <span>{{ getFormattedDateTime(item.eightWeekLetterDate) }}</span>
    </div>
    <div v-if="item.eightWeekLetterSent" class="mb-1">
      <label>Eight Week Letter Sent:</label>
      <span>{{ getFormattedDateTime(item.eightWeekLetterSent) }}</span>
    </div>
    <div v-if="item.summaryResolutionCommunicationDate" class="mb-1">
      <label>Summary Resolution Communication Due:</label>
      <span>
        {{ getFormattedDateTime(item.summaryResolutionCommunicationDate) }}
      </span>
    </div>
    <div v-if="item.summaryResolutionCommunicationSent" class="mb-1">
      <label>Summary Resolution Communication Sent:</label>
      <span>
        {{ getFormattedDateTime(item.summaryResolutionCommunicationSent) }}
      </span>
    </div>
    <div v-if="item.finalResponseLetterDate" class="mb-1">
      <label>Final Response Letter Due:</label>
      <span>{{ getFormattedDateTime(item.finalResponseLetterDate) }}</span>
    </div>
    <div v-if="item.finalResponseLetterSent" class="mb-1">
      <label>Final Response Letter Sent:</label>
      <span>{{ getFormattedDateTime(item.finalResponseLetterSent) }}</span>
    </div>

    <div v-if="item.resolutionSummary" class="mb-1">
      <label>Resolution Summary:</label>
      <span>{{ item.resolutionSummary }}</span>
    </div>
    <div v-if="selectedResolutionCodes.length > 0" class="mb-1">
      <label>Resolution Code:</label>
      <span>{{ selectedResolutionCodes.join(', ') }}</span>
    </div>
    <div v-if="item.rootCause" class="mb-1">
      <label>Root Cause:</label>
      <span>{{ item.rootCauseDescription }}</span>
    </div>
    <div v-if="item.loggedByUserName" class="mb-1">
      <label>Logged By UserName:</label>
      <span>{{ item.loggedByUserName }}</span>
    </div>
    <div v-if="item.escalationRequired" class="mb-1">
      <label>Is an escalation to client required?:</label>
      <span>{{ item.escalationRequired }}</span>
    </div>
    <div v-if="item.concernsRaisedDescription" class="mb-1">
      <label>Concerns Raised:</label>
      <span>{{ item.concernsRaisedDescription.join(', ') }}</span>
    </div>
    <div v-if="item.isNoticeSent" class="mb-1">
      <label>Has an ‘On Notice’ been sent:</label>
      <span>
        {{ item.isNoticeSent }}
        {{ item.noticeSentAt ? ' (' + getFormattedDateTime(item.noticeSentAt) + ')' : '' }}
      </span>
    </div>
    <div class="mb-1">
      <label>Compensation By Cheque:</label>
      <span>{{ item.isCompensationByCheque ? 'Yes' : 'No' }}</span>
    </div>
    <div class="mb-1">
      <label>Is Complaint Resolved:</label>
      <span>{{ item.isResolved ? 'Yes' : 'No' }}</span>
    </div>
    <div class="mb-1">
      <label>Is Complaint Justified:</label>
      <span>{{ item.justified }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import ComplaintModel from '@/models/claim/ComplaintModel'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import UserModel from '../../models/user/UserModel'

@Component
export default class AddUpdateComplaintLog extends Vue {
  @Prop() public requestBody: any

  private get item(): ComplaintModel {
    return Object.assign(new ComplaintModel(), this.requestBody)
  }

  private get selectedResolutionCodes(): string[] {
    const resolutionDescriptions: string[] = this.requestBody.resolutionDescription
    return resolutionDescriptions ? resolutionDescriptions : []
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get users(): UserModel[] {
    return storeGetters.getUsers()
  }

  private get username(): string {
    const user = this.users.find((x) => x.id === this.item.responsiblePerson)
    return user ? user.displayValue : '-'
  }
}
</script>
