<template>
  <v-card>
    <v-card-title class="py-0">
      <h3>Policy Summary</h3>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-0" @click.native="close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="scroll-content-dialog">
      <div class="policysummary-details">
        <v-progress-circular
          v-show="!policySummary"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
          :style="getLoaderStyle(70)"
        ></v-progress-circular>
        <v-layout v-if="policySummary" wrap>
          <v-flex xs12>
            <div><b>Policy Name:</b></div>
            <div>
              <span>{{ policySummary.name }}</span>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div><b>Policy Number:</b></div>
            <div>
              <span>{{ job.policyNumber }}</span>
            </div>
            <div v-if="job && job.policyNumberNotPresentReason" class="mt-1 policy-number-reason">
              <b>Policy Number Not Present Reason:</b>
              <div>{{ job.policyNumberNotPresentReason }}</div>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div><b>Insurer Reference:</b></div>
            <div>
              <span>
                <b class="secondary--text">
                  {{ policySummary.insurerReference }}
                </b>
              </span>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div>
              <b>{{ jobTypeLabel('customer ref') }}:</b>
            </div>
            <div>
              <span>
                <b class="secondary--text">{{ job.customerRef }}</b>
              </span>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div><b>Intention:</b></div>
            <div>
              <pre>{{ policySummary.intention }}</pre>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div>
              <b>{{ jobTypeLabel('emergency definition') }}:</b>
            </div>
            <div>
              <pre v-html="sanitizeHTML(policySummary.emergencyDefinition)"></pre>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div>
              <b>{{ jobTypeLabel('policy limit') }}:</b>
            </div>
            <div>
              <span>
                <b class="secondary--text">£{{ policySummary.policyLimit }}</b>
                {{ policyLimitText ? policyLimitText + '.' : '' }}
              </span>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div><b>Accommodation Coverage status:</b></div>
            <div>
              <span v-if="policySummary.includesAccommodation">Yes</span>
              <span v-else>No</span>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div><b>Transport to accommodation:</b></div>
            <div>
              <span v-if="policySummary.includesTransportToAccommodation">Yes</span>
              <span v-else>No</span>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div>
              <b>Separate {{ jobTypeLabel('policy limit') }} for accommodation:</b>
            </div>
            <div>
              <span class="mt-1">
                {{ policySummary.accommodationExtraValue }}
              </span>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12>
            <div>
              <b>{{ jobTypeLabel('emergency type') }} Covered:</b>
              <div
                v-for="coveredEmergencies in policySummaryEmergencies"
                :key="coveredEmergencies.emergency"
                class="mt-1"
              >
                <b class="grey--text text--darken-2">
                  {{ coveredEmergencies.emergency }}
                </b>
                - {{ coveredEmergencies.emergencyDetail }}
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-flex>

          <div><b>Coverage and Exclusion Information:</b></div>
          <v-flex v-for="(coveredEmergencies, index) in policyCoverageDetails" :key="index" xs12>
            <div class="grey pa-2 my-2 lighten-3">
              <div>
                <b>{{ coveredEmergencies.emergencyDetail }} ({{ coveredEmergencies.emergency }})</b>
              </div>
              <div class="mt-1">
                <b class="grey--text text--darken-2">Coverage Details:</b>
                <span v-if="coveredEmergencies.coverageDetail">
                  {{ coveredEmergencies.coverageDetail }}
                </span>
                <span v-else><v-icon small>remove</v-icon></span>
              </div>
              <div class="mt-1">
                <b class="grey--text text--darken-2">Exclusions:</b>
                <span v-if="coveredEmergencies.exclusions">
                  {{ coveredEmergencies.exclusions }}
                </span>
                <span v-else><v-icon small>remove</v-icon></span>
              </div>
              <div class="mt-1">
                <b class="grey--text text--darken-2">Marked as CTP:</b>
                <span class="emergency-detail-ctp">
                  {{ coveredEmergencies.isMarkedAsCTP ? 'Yes' : 'No' }}
                </span>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import PolicySummaryModel from '@/models/policy/PolicySummaryModel'
import PolicySummaryEmergenciesModel from '@/models/policy/PolicySummaryEmergenciesModel'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'

@Component
export default class PolicySummary extends Vue {
  @Prop() private policySummaryItem: PolicySummaryModel | null
  @Prop() private jobId: string

  private sanitizeHTML = Shared.sanitizeHTML

  private jobTypeLabel(name: string) {
    return Shared.JobTypeLabel(name, this.job ? this.job.jobType : 'HE')
  }

  private get policySummary(): PolicySummaryModel | null {
    return this.policySummaryItem
  }

  public get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  // policy limit text which is append after policy limit
  private get policyLimitText(): string | undefined {
    if (this.policySummary) {
      if (this.policySummary.includesAccommodation && this.policySummary.includesTransportToAccommodation) {
        return 'with accommodation covered and transport included'
      } else if (this.policySummary.includesAccommodation) {
        return 'with accommodation covered'
      } else if (this.policySummary.includesTransportToAccommodation) {
        return 'with transport included'
      }
    }
    return undefined
  }

  // get list of policy emergency and comma separatd emergency details
  private get policySummaryEmergencies(): PolicySummaryEmergenciesModel[] {
    const coveredEmergencies: PolicySummaryEmergenciesModel[] = []
    if (this.policySummary && this.policySummary.coveredEmergencies) {
      const distinctEmergencies: string[] = [
        ...new Set(this.policySummary.coveredEmergencies.map((item) => item.emergency)),
      ]
      for (const distinctEmergency of distinctEmergencies) {
        const emergencyDetails = this.policySummary.coveredEmergencies
          .filter((c) => {
            return c.emergency === distinctEmergency
          })
          .map((c) => c.emergencyDetail)
          .join(', ')

        coveredEmergencies.push({
          emergency: distinctEmergency,
          emergencyDetail: emergencyDetails,
          emergencyDetailId: 0,
          startMonth: 0,
          endMonth: 0,
          coverageDetails: '',
          exclusions: '',
          isMarkedAsCTP: false,
        })
      }
    }
    return coveredEmergencies
  }

  private close() {
    this.$emit('OnPolicySummaryClose')
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private get policyCoverageDetails(): PolicySummaryEmergenciesModel[] {
    let coverageAndExclusionInformation: PolicySummaryEmergenciesModel[] = []
    if (this.policySummary && this.policySummary.coveredEmergencies) {
      // get selected policies by comparing coveredEmergencies with job emergencies
      coverageAndExclusionInformation = this.policySummary.coveredEmergencies.filter((coveredEmergencies) =>
        this.job
          ? this.job.emergencies.some((emergency) => coveredEmergencies.emergencyDetailId === emergency.detailId)
          : []
      )
    }
    return coverageAndExclusionInformation
  }
}
</script>

<style scoped>
pre {
  font-family: inherit;
  white-space: inherit;
}
.policysummary-details {
  min-height: 500px;
}
</style>
