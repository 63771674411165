<template>
  <v-layout wrap class="cctv-accordion">
    <v-flex
      v-if="
        cctvControlLogDetail &&
        cctvControlLogDetail.cctvControlRunLogs &&
        cctvControlLogDetail.cctvControlRunLogs.length > 0
      "
      xs12
    >
      <v-expansion-panel class="trialpit-accordion elevation-0">
        <v-expansion-panel-content
          v-for="(cctvControlLog, index) in cctvControlLogDetail.cctvControlRunLogs"
          :key="index"
          class="grey lighten-4 mb-2"
        >
          <v-layout slot="header" wrap class="align-center">
            <v-flex :class="isCCTVLogAbandoned ? 'xs12' : 'xs4'">
              <h3>Run {{ cctvControlLog.cctvRunNumber }}</h3>
            </v-flex>
            <v-flex v-if="!isCCTVLogAbandoned" xs8 text-xs-right>
              <v-btn
                v-if="job && job.jobType === 'US'"
                color="primary"
                class="ma-0 mr-3 btn-createRepairEstimate"
                :loading="loading.cctvRunLogs"
                @click.stop="showRepairEstimationDialog = true"
              >
                Create Estimate
              </v-btn>
              <v-btn
                flat
                icon
                color="primary"
                class="ma-0 close-icon btn-addUpdateCCTV"
                @click.stop="onAddUpdatCCTVControlRun(false, cctvControlLog)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="secondary"
                class="ma-0 close-icon btn-deleteCCTV"
                @click.stop="onDeleteCCTVControlRun(cctvControlLog)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <CCTVControlRunLog
            :cctv-control-run-log="cctvControlLog"
            :video-source="getVideoSource(cctvControlLog)"
            :job-id="jobId"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-flex>
    <v-flex v-if="!isCCTVLogAbandoned" xs12 class="text-xs-right">
      <v-btn class="mb-3 mr-0 btn-addNewRun" color="primary" @click="onAddUpdatCCTVControlRun(true)">Add New Run</v-btn>
    </v-flex>
    <v-flex v-if="cctvControlLogDetail && !isCCTVLogAbandoned" xs12 pl-1 lighten-4 px-3 pt-2>
      <v-textarea v-model="cctvControlLogDetail.notes" rows="3" label="Notes"></v-textarea>
    </v-flex>
    <v-flex
      v-if="
        cctvControlLogDetail &&
        (isCCTVLogAbandoned || cctvControlLogDetail.status === siJobProductProgress[siJobProductProgress.Finished])
      "
      xs12
      pl-3
      grey
      lighten-4
      pa-2
    >
      <b :class="isCCTVLogAbandoned ? 'red--text' : 'text-green'">
        CCTV Log {{ isCCTVLogAbandoned ? 'Abandoned' : 'Completed' }}
      </b>
    </v-flex>
    <v-flex v-if="cctvControlLogDetail" xs12>
      <div v-if="isCCTVLogAbandoned" class="pa-3 mb-2 abandon-detail grey lighten-4">
        <div class="pb-1">
          <label><b>Notes:</b></label>
          <span class="ml-1">
            {{ cctvControlLogDetail.notes ? cctvControlLogDetail.notes : '-' }}
          </span>
        </div>
        <div class="pb-1">
          <label><b>Abandon Reason:</b></label>
          <span class="ml-1">{{ cctvControlLogDetail.abandonedReason }}</span>
        </div>
        <div v-if="cctvControlLogDetail.abandonedReasonNotes">
          <label><b>Abandon Note:</b></label>
          <span class="ml-1">
            {{ cctvControlLogDetail.abandonedReasonNotes }}
          </span>
        </div>
      </div>
      <div v-else class="text-xs-right">
        <v-btn
          class="mb-3 mr-0 btn-saveCCTVControl"
          color="primary"
          :loading="loading.saveCCTVControl"
          @click="saveCCTVLog()"
        >
          Save
        </v-btn>
        <v-btn
          v-if="
            cctvControlLogDetail.status !== siJobProductProgress[siJobProductProgress.Finished] &&
            cctvControlLogDetail.cctvControlRunLogs &&
            cctvControlLogDetail.cctvControlRunLogs.length > 0
          "
          class="mb-3 mr-0 btn-completeCCTVControl"
          color="success"
          :loading="loading.completeCCTVControl"
          @click="saveCCTVLog(true)"
        >
          Complete
        </v-btn>
        <v-btn class="mb-3 mr-0 white--text btn-abandonCCTVLog" color="red" @click.native="openAbandonReasonDialog()">
          Abandon
        </v-btn>
      </div>
    </v-flex>
    <v-dialog
      v-if="addUpdateRunDialog"
      v-model="addUpdateRunDialog"
      max-width="850"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>
            {{ isAddNewRun ? 'Add New Run' : 'Update Run Detail' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="btn-runDialogClose" @click.native="discardRunChangesConfirmation">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog">
          <CCTVControlRunLog
            ref="refCCTVControlRunLog"
            :is-add-update-mode="true"
            :cctv-log-configuration="cctvLogConfiguration"
            :cctv-control-run-log="cctvControlRunLogModel"
          ></CCTVControlRunLog>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-runDialogClose" flat="flat" @click.native="discardRunChangesConfirmation">
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-runDialogSubmit"
            :disabled="loading.cctvRunLogs"
            :loading="loading.cctvRunLogs"
            @click.native="onAddUpdateRunDetail"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AbandonSiteDataDialog
      v-if="showAbandonReasonDialog"
      ref="abandonSiteDataDialog"
      :record-type="abandonRecordType"
      :abandon-reason-details="abandonReasonDetails"
      @abandonReasonSave="onAbandonReasonSave"
      @closeAbandonReasonDialog="showAbandonReasonDialog = false"
    ></AbandonSiteDataDialog>
    <RepairEstimationDialog
      v-if="showRepairEstimationDialog"
      ref="repairEstimationDialog"
      :policy-client-rate-package-id="job && job.policyClientRatePackageId ? job.policyClientRatePackageId : ''"
      :job-id="jobId"
      @closeRepairEstimationDialog="onCloseRepairEstimationDialog"
    ></RepairEstimationDialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import CCTVControlLogModel from '@/models/siteInvestigation/CCTVControlLog'
import CCTVControlRunLogModel from '@/models/siteInvestigation/CCTVControlRunLogModel'
import AddCCTVControlRunLogModel from '@/models/siteInvestigation/requests/AddCCTVControlRunLogModel'
import SICCTVLogConfiguration from '@/models/siteInvestigation/SICCTVLogConfiguration'
import CCTVControlDistance from '@/models/siteInvestigation/CCTVControlDistance'
import SiteInvestigationController from '@/api/siteInvestigationController'
import { SIJobProductProgress, RecordType, SIProductType } from '@/common/enums'
import CCTVControlRunLog from '@/components/siteInvestigation/CCTVControlRunLog.vue'
import DeleteCCTVControlRunLogModel from '../../models/requests/DeleteCCTVControlRunLogModel'
import AbandonSiteDataDialog from '@/components/siteInvestigation/AbandonSiteDataDialog.vue'
import AddAbandonedSIProductRequest from '@/models/siteInvestigation/requests/AddAbandonedSIProductRequest'
import eventBus from '@/common/bus'
import RepairEstimationDialog from '@/components/RepairEstimationDialog.vue'
import Job from '@/models/Job'
import DocumentController from '../../api/documentController'
import DrainageVideoSource from '@/models/DrainageVideoSource'

@Component({
  components: {
    CCTVControlRunLog,
    AbandonSiteDataDialog,
    RepairEstimationDialog,
  },
})
export default class CCTVControlLog extends Vue {
  @Prop({ default: '' }) private jobId: string
  @Prop() private engineerVisitDetailId: string
  private addUpdateRunDialog = false
  private cctvControlRunLogModel: CCTVControlRunLogModel = new CCTVControlRunLogModel()
  private cctvControlLogDetail: CCTVControlLogModel | null = null
  private runLogVideoSources: DrainageVideoSource[] = []
  private isAddNewRun = false
  private cctvLogConfiguration: SICCTVLogConfiguration | null = null
  private loading: Record<string, boolean> = {
    cctvRunLogs: false,
    saveCCTVControl: false,
    completeCCTVControl: false,
  }
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private siJobProductProgress = SIJobProductProgress
  private showAbandonReasonDialog = false
  private abandonRecordType = RecordType[RecordType.AbandonCCTVReason]
  private abandonReasonDetails: AddAbandonedSIProductRequest = new AddAbandonedSIProductRequest()
  private showRepairEstimationDialog = false

  private async created() {
    const cctvLogConfiguration = await SiteInvestigationController.GetCCTVLogConfiguration()
    this.cctvLogConfiguration = cctvLogConfiguration
  }

  private get getCCTVControlLogDetail(): CCTVControlLogModel | null {
    return storeGetters.getCCTVControlLogDetail(this.jobId, this.engineerVisitDetailId)
  }

  private async mounted() {
    this.cctvControlLogDetail = this.getCCTVControlLogDetail
    if (this.cctvControlLogDetail) {
      this.runLogVideoSources = await DocumentController.GetSASTokensForCCTVRunVideo(
        this.jobId,
        this.cctvControlLogDetail.id
      )
    } else {
      this.runLogVideoSources = []
    }
  }

  @Watch('getCCTVControlLogDetail')
  private async onCCTVControlLogDetailChange() {
    this.cctvControlLogDetail = this.getCCTVControlLogDetail
    if (this.cctvControlLogDetail) {
      this.runLogVideoSources = await DocumentController.GetSASTokensForCCTVRunVideo(
        this.jobId,
        this.cctvControlLogDetail.id
      )
    } else {
      this.runLogVideoSources = []
    }
  }

  private onAddUpdatCCTVControlRun(isAddNewRun: boolean, cctvControlRun?: CCTVControlRunLogModel) {
    this.isAddNewRun = isAddNewRun
    this.cctvControlRunLogModel = Object.assign(new CCTVControlRunLogModel(), cctvControlRun)
    if (isAddNewRun) {
      // add one cctv distance while adding new run
      const cctvControlDistance = new CCTVControlDistance()
      cctvControlDistance.id = 1 // assign unique id to distance
      this.cctvControlRunLogModel.distances.push(cctvControlDistance)
    }
    this.addUpdateRunDialog = true
  }

  private discardRunChangesConfirmation() {
    Shared.confirmationPopup.open(
      'Are you sure you want to discard the changes?',
      '',
      '',
      'No',
      'Yes',
      this,
      'onAddUpdateRunDialogClose',
      true
    )
  }

  private onAddUpdateRunDialogClose() {
    this.cctvControlRunLogModel = new CCTVControlRunLogModel()
    this.addUpdateRunDialog = false
  }

  private onAddUpdateRunDetail() {
    const cctvControlRunLogSub = this.$refs.refCCTVControlRunLog as CCTVControlRunLog
    if (cctvControlRunLogSub) {
      cctvControlRunLogSub
        .validate()
        .then(async (result: boolean) => {
          if (result) {
            this.$set(this.loading, 'cctvRunLogs', true)
            const cctvControlRun: AddCCTVControlRunLogModel = Object.assign(
              new AddCCTVControlRunLogModel(),
              cctvControlRunLogSub.cctvControlRunLogLocal
            )
            cctvControlRun.distances = []
            const cctvContrologDistances: any = cctvControlRunLogSub.$refs.refCCTVControlRunDistance
            if (cctvContrologDistances) {
              cctvContrologDistances.forEach((distance) => {
                cctvControlRun.distances.push(distance.cctvControlDistanceLocal)
              })
            }
            cctvControlRun.jobId = this.jobId
            cctvControlRun.forEngineerVisitDetailId = this.cctvControlLogDetail
              ? this.cctvControlLogDetail.forEngineerVisitDetailId
              : ''
            cctvControlRun.cctvRunNumber = this.isAddNewRun
              ? this.cctvControlLogDetail &&
                this.cctvControlLogDetail.cctvControlRunLogs &&
                this.cctvControlLogDetail.cctvControlRunLogs.length > 0
                ? Math.max.apply(
                    Math,
                    this.cctvControlLogDetail.cctvControlRunLogs.map((e) => e.cctvRunNumber)
                  ) + 1
                : 1
              : cctvControlRun.cctvRunNumber
            const response = await SiteInvestigationController.UpsertCCTVControlRunLogs(cctvControlRun)
            if (response) {
              this.addUpdateRunDialog = false
              this.onCCTVControlRunLogUpdate(cctvControlRun)
              this.showMessage('CCTV Run Detail Saved Successfully.')
            }
            this.$set(this.loading, 'cctvRunLogs', false)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
          this.$set(this.loading, 'cctvRunLogs', false)
        })
    }
  }

  private async saveCCTVLog(completeCCTVControl = false) {
    this.$set(this.loading, completeCCTVControl ? 'completeCCTVControl' : 'saveCCTVControl', true)
    const cctvControlLogDetail = this.cctvControlLogDetail ? this.cctvControlLogDetail : new CCTVControlLogModel()
    cctvControlLogDetail.status = completeCCTVControl
      ? SIJobProductProgress[SIJobProductProgress.Finished]
      : this.cctvControlLogDetail!.status === SIJobProductProgress[SIJobProductProgress.Finished]
      ? SIJobProductProgress[SIJobProductProgress.Finished]
      : SIJobProductProgress[SIJobProductProgress.InProgress]
    const response = await SiteInvestigationController.AddUpdateCCTVControlLogs(cctvControlLogDetail)
    if (response) {
      this.showMessage('CCTV Detail Saved Successfully.')
    }
    this.$set(this.loading, completeCCTVControl ? 'completeCCTVControl' : 'saveCCTVControl', false)
  }

  private onCCTVControlRunLogUpdate(cctvControlRunLog: CCTVControlRunLogModel) {
    if (this.cctvControlLogDetail) {
      if (this.isAddNewRun) {
        this.cctvControlLogDetail.cctvControlRunLogs =
          this.cctvControlLogDetail.cctvControlRunLogs && this.cctvControlLogDetail.cctvControlRunLogs.length > 0
            ? this.cctvControlLogDetail.cctvControlRunLogs
            : []
        this.cctvControlLogDetail.cctvControlRunLogs.push(cctvControlRunLog)
      } else {
        const index = this.cctvControlLogDetail.cctvControlRunLogs.findIndex(
          (e) => e.cctvRunNumber === cctvControlRunLog.cctvRunNumber
        )
        if (index !== -1) {
          this.cctvControlLogDetail.cctvControlRunLogs[index] = cctvControlRunLog
        }
      }
    }
  }

  private async onDeleteCCTVControlRun(cctvControlRunLog: DeleteCCTVControlRunLogModel) {
    const deleteCCTVControlRunLog: DeleteCCTVControlRunLogModel = new DeleteCCTVControlRunLogModel()
    deleteCCTVControlRunLog.id = this.cctvControlLogDetail!.id
    deleteCCTVControlRunLog.jobId = this.jobId
    deleteCCTVControlRunLog.engineerVisitDetailId = this.engineerVisitDetailId
    deleteCCTVControlRunLog.cctvRunNumber = cctvControlRunLog.cctvRunNumber
    Shared.confirmationPopup.open(
      'Are you sure you want to delete CCTV control run log - ' + cctvControlRunLog.cctvRunNumber + '?',
      '',
      '',
      'No',
      'Yes',
      this,
      'deleteCCTVControlRun',
      deleteCCTVControlRunLog
    )
  }

  private async deleteCCTVControlRun(deleteCCTVControlRunLog: DeleteCCTVControlRunLogModel) {
    const response = await SiteInvestigationController.DeleteCCTVControlRunLog(deleteCCTVControlRunLog)
    if (response) {
      this.showMessage('CCTV run log deleted')
    } else {
      this.showMessage('Something went wrong')
    }
  }

  private showMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private openAbandonReasonDialog() {
    this.abandonReasonDetails.jobId = this.jobId
    this.abandonReasonDetails.id = this.cctvControlLogDetail!.id
    this.abandonReasonDetails.forEngineerVisitDetailId = this.cctvControlLogDetail!.forEngineerVisitDetailId
    this.abandonReasonDetails.siProductType = SIProductType[SIProductType.CCTV]
    this.showAbandonReasonDialog = true
  }

  private async onAbandonReasonSave(result: boolean) {
    this.showAbandonReasonDialog = false
    if (result) {
      this.showMessage('CCTV Log has been abandoned')
    } else {
      this.showMessage('Something went wrong')
    }
  }

  private get isCCTVLogAbandoned(): boolean {
    return this.cctvControlLogDetail &&
      this.cctvControlLogDetail.status === SIJobProductProgress[SIJobProductProgress.Abandoned]
      ? true
      : false
  }

  // get job detail
  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private onCloseRepairEstimationDialog(message = '') {
    if (message) {
      this.showMessage(message)
    }
    this.showRepairEstimationDialog = false
  }

  private getVideoSource(runLog: CCTVControlRunLogModel) {
    if (!this.runLogVideoSources) {
      return undefined
    }
    return this.runLogVideoSources.find((s) => s.runNumber === runLog.cctvRunNumber)
  }
}
</script>
