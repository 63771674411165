<template>
  <div>
    <div class="mb-2"><h4>Update QuestionMode Job Details</h4></div>
    <div v-if="item && item.emergenciesQAs.length > 0">
      <div v-for="(emergencyQAItem, questionIndex) in item.emergenciesQAs" :key="questionIndex">
        <AddEmergencyAnswerLog
          v-if="emergencyQAItem.answer"
          :request-body="emergencyQAItem"
          :index="questionIndex"
          :has-list="true"
        />
      </div>
    </div>
    <v-divider v-if="item && item.healthAndSafetyQAs.length > 0" class="mb-2"></v-divider>
    <div v-if="item && item.healthAndSafetyQAs.length > 0">
      <div
        v-for="(healthAndSafetyItem, healthAndSafetyItemIndex) in item.healthAndSafetyQAs"
        :key="healthAndSafetyItemIndex"
      >
        <AddVulnerabilityAndHealthSafetyAnswerLog
          v-if="healthAndSafetyItem.isHealthAffected !== null"
          :request-body="healthAndSafetyItem"
          :request-type="'AddHealthAndSafetyAnswer'"
          :index="healthAndSafetyItemIndex"
          :has-list="true"
        />
      </div>
    </div>
    <v-divider v-if="item.vulnerabilityQA" class="mb-2"></v-divider>
    <AddVulnerabilityAndHealthSafetyAnswerLog
      v-if="item.vulnerabilityQA"
      :request-body="item.vulnerabilityQA"
      :request-type="'AddVulnerabilityAnswer'"
      :has-list="true"
    />
    <v-divider v-if="item.accessNoteQA" class="mb-2"></v-divider>
    <AddAccessNoteAnswerLog v-if="item.accessNoteQA" :request-body="item.accessNoteQA"></AddAccessNoteAnswerLog>
    <v-divider v-if="item.customerAvailability.timeSlot.length > 0" class="mb-2"></v-divider>
    <AddCustomerAvailabilityTimeSlotLog
      v-if="item.customerAvailability.timeSlot.length > 0"
      :request-body="item.customerAvailability"
    ></AddCustomerAvailabilityTimeSlotLog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateQuestionModeJobDetailModel from '@/models/requests/UpdateQuestionModeJobDetailModel'
import AddVulnerabilityAndHealthSafetyAnswerLog from '@/components/jobauditlog/AddVulnerabilityAndHealthSafetyAnswerLog.vue'
import AddEmergencyAnswerLog from '@/components/jobauditlog/AddEmergencyAnswerLog.vue'
import AddAccessNoteAnswerLog from '@/components/jobauditlog/AddAccessNoteAnswerLog.vue'
import AddCustomerAvailabilityTimeSlotLog from '@/components/jobauditlog/AddCustomerAvailabilityTimeSlotLog.vue'

@Component({
  components: {
    AddVulnerabilityAndHealthSafetyAnswerLog,
    AddEmergencyAnswerLog,
    AddAccessNoteAnswerLog,
    AddCustomerAvailabilityTimeSlotLog,
  },
})
export default class UpdateQuestionModeJobDetailsLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateQuestionModeJobDetailModel {
    return Object.assign(new UpdateQuestionModeJobDetailModel(), this.requestBody)
  }
}
</script>
