<template>
  <v-flex v-if="jobPackage">
    <div v-if="jobPackage.packageInfo">
      <div class="mb-2">
        <b>{{ jobPackage.packageInfo.name }}</b>
      </div>
      <v-container class="pa-0 pb-2 pb-3">
        <v-flex>{{ packageInfoDisplay }}</v-flex>
      </v-container>
    </div>
    <v-divider v-if="jobPackage.packageInfo" class="mb-3" />
    <div
      v-if="
        (jobPackage.extraProducts && jobPackage.extraProducts.length > 0) ||
        (jobPackage.bulkPitProducts && jobPackage.bulkPitProducts.length > 0)
      "
    >
      <div class="mb-2">
        <b>Additional Products</b>
      </div>
      <v-container class="pa-0 pb-2 pb-3">
        <v-flex v-for="(product, ind) in jobPackage.extraProducts" :key="ind">
          {{ product.quantity }} x {{ product.name }}
        </v-flex>
        <v-flex v-for="(product, ind) in jobPackage.bulkPitProducts" :key="ind">
          {{ product.quantity }} x {{ product.name }}
        </v-flex>
      </v-container>
    </div>
    <div v-if="jobPackage.monitoringProducts && jobPackage.monitoringProducts.length > 0">
      <div class="mb-2">
        <b>Monitoring Products</b>
      </div>
      <v-container class="pa-0 pb-2 pb-3">
        <v-flex v-for="(product, ind) in jobPackage.monitoringProducts" :key="ind">
          <div class="xs12">{{ product.quantity }} x {{ product.name }}</div>
          <template v-if="product.selectedChildProducts && product.selectedChildProducts.length">
            <div v-for="(child, i) in product.selectedChildProducts" :key="i" class="xs12">
              {{ child.quantity }} x {{ child.name }}
            </div>
          </template>
        </v-flex>
      </v-container>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import moment from 'moment'
import Shared from '@/common/shared'
import { SIProductType } from '@/common/enums'
import Store from '@/store'
import Product from '@/models/siteInvestigation/Product'
import Pit from '@/models/siteInvestigation/Pit'
import DehydratedJobPackage from '@/models/siteInvestigation/DehydratedJobPackage'

@Component
export default class JobPackageCard extends TimeLineItemBase {
  private get jobPackage(): DehydratedJobPackage | undefined {
    return this.job && this.job.sIJobDetail ? this.job.sIJobDetail.jobPackage : undefined
  }

  private get packageInfoDisplay(): string {
    if (this.jobPackage && this.jobPackage.packageInfo) {
      const pits = this.jobPackage.packageInfo.productList.filter((prod) => prod.productTypeId === SIProductType.Pit)
      if (pits.length < 1) {
        return ''
      }
      const pit = pits[0] as Pit
      return pits.length + ' x ' + pit.name
    } else {
      return ''
    }
  }
}
</script>
