import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import Job from '@/models/Job'
import EmergencyQA from '@/models/EmergencyQA'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import AddEmergencyAnswer from '@/models/requests/AddEmergencyAnswer'
import StoreMutations from '@/storeMutations'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import { WeatherForecastModel } from '@/models/WeatherForecastModel'
import Emergency from '@/models/Emergency'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import CallContractorModel from '@/models/claim/CallContractorModel'
import AddEmergency from '@/models/requests/AddEmergency'
import CallLogModel from '@/models/claim/CallLogModel'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import FinancialSummaryModel from '@/models/claim/FinancialSummaryModel'
import AddCustomerAvailabilityTimeSlot from '@/models/requests/AddCustomerAvailabilityTimeSlot'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import UpdateEngineerRequestStatus from '@/models/requests/UpdateEngineerRequestStatus'
import AddNewJobNote from '@/models/requests/AddNewJobNote'
import JobNoteModel from '@/models/claim/JobNoteModel'
import SMSModel from '@/models/claim/SMSModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import AddJobAnswerModel from '@/models/requests/AddJobAnswerModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import AddContractorAppointedModel from '@/models/requests/AddContractorAppointedModel'
import JobHeader from '@/models/JobHeader'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import UpdateEmergencyAcceptedOrRejected from '@/models/requests/UpdateEmergencyAcceptedOrRejected'
import AddJobWithPolicyEnquiry from '@/models/requests/AddJobWithPolicyEnquiry'
import PolicyEnquiry from '@/models/claim/PolicyEnquiry'
import ComplaintModel from '@/models/claim/ComplaintModel'
import RequestModel from '@/models/RequestModel'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import AddThirdPartyContractorDetail from '@/models/requests/AddThirdPartyContractorDetail'
import ThirdPartyAppointedContractor from '@/models/claim/ThirdPartyAppointedContractor'
import UpdateCustomerAddressDetail from '@/models/requests/UpdateCustomerAddressDetail'
import SoftFixedEmergency from '@/models/claim/SoftFixedEmergencyModel'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'
import CancelAssignedJobContractorModel from '@/models/requests/CancelAssignedJobContractorModel'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import UpdateEmergencyFollowOnWorkModel from '@/models/requests/UpdateEmergencyFollowOnWorkModel'
import UpdateHealthAndSafetyForJob from '@/models/requests/UpdateHealthAndSafetyForJob'
import UpdateEngineerJobToComplete from '@/models/requests/UpdateEngineerJobToComplete'
import AddEngineerRequest from '@/models/requests/AddEngineerRequest'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import AddAccessNoteAnswerModel from '@/models/requests/AddAccessNoteAnswerModel'
import CompleteEngineerRequest from '@/models/claim/engineerRequest/CompleteEngineerRequest'
import AddCloseJobRequestModel from '@/models/requests/AddCloseJobRequestModel'
import AddAdditionalCostAuthorisedRequestModel from '@/models/requests/AddAdditionalCostAuthorisedRequestModel'
import UpdateCanChargeCalloutForVisit from '@/models/requests/UpdateCanChargeCalloutForVisit'
import AddRequestToEscalateCTPProcessModel from '@/models/requests/AddRequestToEscalateCTPProcessModel'
import BoilerBreakdownQA from '@/models/claim/BoilerBreakdownQA'
import ContractorInvoiceDetailModel from '@/models/claim/ContractorInvoiceDetailModel'
import ClientInvoiceDetailModel from '@/models/claim/ClientInvoiceDetailModel'
import CheckPolicyLimitResponse from '@/models/claim/CheckPolicyLimitResponse'
import AddHelplineJobRequestModel from '@/models/requests/AddHelplineJobRequestModel'
import HelplinePolicyModel from '@/models/claim/HelplinePolicyModel'
import SendClientEmail from '@/models/requests/SendClientEmail'
import AddRequestToCompleteHelplineJobModel from '@/models/requests/AddRequestToCompleteHelplineJobModel'
import UpdateEngineerJobVisitStatus from '@/models/requests/UpdateEngineerJobVisitStatus'
import VisitHealthAndSafetyProcess from '@/models/claim/VisitHealthAndSafetyProcessModel'
import AddDrainageJobWithCommodity from '@/models/requests/AddDrainageJobWithCommodity'
import CompleteJobDataModel from '@/models/claim/CompleteJobDataModel'
import AddRequestForCancelJobModel from '@/models/requests/AddRequestForCancelJobModel'
import AssignEngineerRequestModel from '@/models/requests/AssignEngineerRequestModel'
import AddEscalateJobRequest from '@/models/requests/AddEscalateJobRequestModel'
import AcceptProvisionedVisit from '@/models/requests/AcceptProvisionedVisit'
import AddSIJob from '@/models/requests/AddSIJob'
import UpdateJobPolicySchedule from '@/models/requests/UpdateJobPolicySchedule'
import UpdateSIJobPackage from '@/models/requests/UpdateSIJobPackage'
import ProductQA from '@/models/ProductQA'
import EmailModel from '@/models/claim/EmailModel'
import PhotosController from './photos-controller'
import ReportDetail from '@/models/ReportDetail'
import MonitoringDetail from '@/models/siteInvestigation/MonitoringDetail'
import TestingProgressDetail from '@/models/siteInvestigation/TestingProgressDetail'
import RemoveEmergencyFromJobModel from '@/models/claim/RemoveEmergencyFromJobModel'
import UpdateGasSafetyForJob from '@/models/requests/UpdateGasSafetyForJob'
import IVSDetailModel from '@/models/IVSDetailModel'
import UpdateJobOffsiteTime from '@/models/requests/UpdateJobOffsiteTimeModel'
import SIVisitTrialPit from '@/models/siteInvestigation/SIVisitTrialPit'
import CCTVControlLogModel from '@/models/siteInvestigation/CCTVControlLog'
import DatumControlLog from '@/models/siteInvestigation/DatumControlLog'
import WaterMainsTest from '@/models/siteInvestigation/WaterMainsTest'
import TrialPitDrawingModel from '@/models/siteInvestigation/TrialPitDrawingModel'
import UpdateMaterialCostRequestModel from '@/models/requests/UpdateMaterialCostRequestModel'
import DrainageReportModel from '@/models/claim/DrainageReportModel'
import JobSummaryCardModel from '@/models/claim/JobSummaryCardModel'
import JobNotReadyToCloseModel from '@/models/requests/JobNotReadyToCloseModel'
import PreviousJobModel from '@/models/policyHolder/PreviousJobModel'
import SaveOtherFeesDetailModel from '@/models/requests/SaveOtherFeesDetailModel'
import CustomerAppointedTradespeople from '@/models/claim/CustomerAppointedTradespeople'
import AddCustomerAppointedTradespeopleRequest from '@/models/requests/AddCustomerAppointedTradespeopleRequest'
import { APIResponseCode } from '@/models/types'
import JobDelay from '@/models/JobDelay'
import ClientCostDetailModel from '@/models/client/ClientCostDetailModel'
import FinancialSummaryCardDetailModel from '@/models/FinancialSummaryCardDetailModel'
import AddDeEscalateJobRequest from '@/models/requests/AddDeEscalateJobRequestModel'
import JobEscalationDetail from '@/models/claim/JobEscalationDetailModel'
import RepairEstimateModel from '@/models/undergroundServices/RepairEstimateModel'
import AddReopenJobRequest from '@/models/requests/AddReopenJobRequestModel'
import UpdateCustomerRef from '@/models/requests/UpdateCustomerRef'
import ClearEta from '@/models/requests/ClearEta'
import eventBus from '@/common/bus'
import TimelineCardModel from '@/models/general/timeline'

export default class JobController {
  public static async SaveClaim(model: AddHEJobWithEmergencies): Promise<string | null> {
    const res = await CallCentreApi.post('jobs', model)

    if (res.data) {
      // loop over collection properties to cast to correct classes
      for (let i = 0, l = (res.data.emergencies || []).length; i < l; i++) {
        res.data.emergencies[i] = Object.assign(new Emergency(), res.data.emergencies[i])
      }
      for (let i = 0, l = (res.data.emergenciesQAs || []).length; i < l; i++) {
        res.data.emergenciesQAs[i] = Object.assign(new EmergencyQA(), res.data.emergenciesQAs[i])
      }
      res.data.healthAndSafetyQAs[0] = Object.assign(new HealthAndSafetyQAModel(), res.data.healthAndSafetyQAs[0])
      StoreMutations.addOrReplaceJob(res.data)
      return res.data.id
    } else {
      return null
    }
  }

  public static async SaveDrainageClaim(model: AddDrainageJobWithCommodity): Promise<string | null> {
    const res = await CallCentreApi.post('Job/AddDrainageJob', model)

    if (res.data) {
      // loop over collection properties to cast to correct classes
      // TODO: handle response once timeline is updated for drainage
      return res.data.id
    } else {
      return null
    }
  }

  public static async SaveSIClaim(model: AddSIJob): Promise<string | null> {
    const res = await CallCentreApi.post('Job/AddSIJob', model)

    if (res.data) {
      // loop over collection properties to cast to correct classes
      // TODO: handle response once timeline is updated for drainage
      return res.data.id
    } else {
      return null
    }
  }

  public static async UpdateSIJobPackage(model: UpdateSIJobPackage): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('Job/UpdateSIJobPackage', model)

    return res.data ? res.data.requestId : ''
  }

  public static async SaveEmergencyQA(model: AddEmergencyAnswer): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddAnswer', model)
    return res.data ? res.data.requestId : ''
  }

  public static async SaveHealthAndSafetyQA(model: AddJobAnswerModel): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddHealthAndSafetyAnswer', model)
    return res.data ? res.data.requestId : ''
  }

  public static async SaveVulnerabilityQA(model: AddJobAnswerModel): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddVulnerabilityAnswer', model)
    return res.data ? res.data.requestId : ''
  }

  public static async GetJobDocument(jobId: string, documentId: string): Promise<CosmosJobsDbRecordBase | null> {
    const res: AxiosResponse = await CallCentreApi.get(
      `jobs/${encodeURIComponent(jobId)}/documents/${encodeURIComponent(documentId)}`
    )

    if (res.status === 200) {
      // process document
      this.castAndStoreJobDocument(res.data)
      return res.data
    } else {
      return null
    }
  }

  public static async GetJobDocuments(jobId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get('Job/GetJobDocuments?jobId=' + encodeURIComponent(jobId))

    if (res.status === 200) {
      // loop over and process/store documents
      // do job detail first, as rest hang off it
      const l = res.data.length
      let jobDetailJsonConverted = null
      for (let i = 0; i < l; i++) {
        if (res.data[i].type === 'JobDetail') {
          jobDetailJsonConverted = res.data[i]
        }
      }
      if (jobDetailJsonConverted) {
        this.castAndStoreJobDocument(jobDetailJsonConverted)
      }
      for (let i = 0; i < l; i++) {
        if (res.data[i].type !== 'JobDetail') {
          this.castAndStoreJobDocument(res.data[i])
        }
      }
      return true
    } else {
      return false
    }
  }

  public static async UpdateEngineerRequest(
    emergencyRequest: UpdateEngineerRequestStatus
  ): Promise<CheckPolicyLimitResponse | null> {
    const res: AxiosResponse = await CallCentreApi.post('Job/UpdateEngineerRequest', emergencyRequest)
    if (res.status >= 200 && res.status < 300) {
      const result = res.data as CheckPolicyLimitResponse
      if (!result) {
        return null
      }
      return result
    }
    return null
  }

  public static async UpdateCustomerAvailability(request: AddCustomerAvailabilityTimeSlot): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/UpdateCustomerAvailability', request)
    return res.status >= 200 && res.status < 300
  }

  public static async AddNewEmergencyToJob(emergency: AddEmergency): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddNewEmergencyToJob', emergency)
    return res.status >= 200 && res.status < 300
  }

  public static async AddJobNote(jobNote: AddNewJobNote): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddJobNote', jobNote)
    return res.status >= 200 && res.status < 300
  }

  public static async AddContractorAppointedDetail(contractorAppointed: AddContractorAppointedModel): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.post('Job/AddContractorAppointedDetail', contractorAppointed)
      return res.status >= 200 && res.status < 300
    } catch (err) {
      return false
    }
  }

  public static async CancelAssignedJobContractor(
    cancelAssignedJobContractorModel: CancelAssignedJobContractorModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Job/CancelAssignedJobContractor',
      cancelAssignedJobContractorModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async AddThirdPartyContractorDetail(
    thirdPartyContractorAppointed: AddThirdPartyContractorDetail
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Job/AddThirdPartyContractorDetail',
      thirdPartyContractorAppointed
    )
    return res.status >= 200 && res.status < 300
  }

  public static async GetBasicDetailOfPolicyHolderJobs(
    lastname: string,
    postcode: string,
    pastXDays = 0
  ): Promise<PreviousJobModel[]> {
    const res = await CallCentreApi.get<PreviousJobModel[]>(
      'Job/GetBasicDetailOfPolicyHolderJobs?lastname=' +
        encodeURIComponent(lastname) +
        '&postcode=' +
        encodeURIComponent(postcode) +
        '&pastXDays=' +
        pastXDays
    )
    return res.data ? res.data : []
  }

  public static async UpdateEmergencyAcceptedOrRejected(
    emergencyAcceptOrReject: UpdateEmergencyAcceptedOrRejected
  ): Promise<CheckPolicyLimitResponse | null> {
    const res: AxiosResponse = await CallCentreApi.post(
      'job/UpdateEmergencyAcceptedOrRejected',
      emergencyAcceptOrReject
    )
    if (res.status >= 200 && res.status < 300) {
      const result = res.data as CheckPolicyLimitResponse
      if (!result) {
        return null
      }
      return result
    }
    return null
  }

  public static async SavePolicyEnquiry(model: AddJobWithPolicyEnquiry): Promise<string | null> {
    const res = await CallCentreApi.post<Job>('Job/AddPolicyEnquiry', model)

    if (res.data) {
      StoreMutations.addOrReplaceJob(res.data)
      return res.data.id
    } else {
      return null
    }
  }

  public static async GetAllRequestDocuments(jobId: string): Promise<RequestModel[]> {
    const res = await CallCentreApi.get<RequestModel[]>('job/GetAllRequestDocuments?jobId=' + encodeURIComponent(jobId))
    return res.data ? res.data : []
  }

  public static async GetJobHeader(jobId: string): Promise<JobHeader | null> {
    const res = await CallCentreApi.get<JobHeader>('Job/GetJobHeader?jobId=' + encodeURIComponent(jobId))
    return res.data
  }

  public static async UpdateCustomerAddressDetail(
    updateCustomerAddressDetail: UpdateCustomerAddressDetail
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/UpdateCustomerAddressDetail', updateCustomerAddressDetail)
    if (res.status === 202) {
      return true
    } else {
      return false
    }
  }

  public static async UpdateCustomerRef(updateCustomerRef: UpdateCustomerRef): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.put('Job/UpdateCustomerRef', updateCustomerRef)
    return res.status === 202
  }

  public static async UpdateCustomerDetail(model: UpdateCustomerContactPreferenceModal): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<boolean>('Job/UpdateCustomerDetail', model)
    if (res.status === 202) {
      return true
    } else {
      return false
    }
  }

  public static async AddSoftFixedEmergency(softFixedEmergency: SoftFixedEmergency): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<boolean>('Job/AddSoftFixedEmergency', softFixedEmergency)
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateEmergencyFollowOnWork(
    follwOnWorkModel: UpdateEmergencyFollowOnWorkModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<UpdateEmergencyFollowOnWorkModel>(
      'Job/UpdateEmergencyFollowOnWork',
      follwOnWorkModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateHealthAndSafetyForJob(
    updateHealthAndSafetyForJob: UpdateHealthAndSafetyForJob
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<UpdateHealthAndSafetyForJob>(
      'Job/UpdateHealthAndSafetyForJob',
      updateHealthAndSafetyForJob
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateEngineerJobToComplete(
    updateEngineerJobToComplete: UpdateEngineerJobToComplete
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<UpdateEngineerJobToComplete>(
      'Job/UpdateEngineerJobToComplete',
      updateEngineerJobToComplete
    )
    return res.status >= 200 && res.status < 300
  }

  public static async AddEngineerRequest(
    addEngineerRequest: AddEngineerRequest,
    isJobNotReadyToClose = false
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<AddEngineerRequest>(
      'Job/AddEngineerRequest?isJobNotReadyToClose=' + isJobNotReadyToClose,
      addEngineerRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SaveAccessNoteAnswer(model: AddAccessNoteAnswerModel): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('Job/SaveAccessNoteAnswer', model)
    return res.data ? res.data.requestId : ''
  }

  public static async GetAllEngineerRequests(): Promise<EngineerRequestModel[]> {
    const res = await CallCentreApi.get<EngineerRequestModel[]>('job/GetAllEngineerRequests')
    return res.data ? res.data : []
  }

  public static async GetEngineerRequestAsync(jobId: string, documentId: string): Promise<EngineerRequestModel | null> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Job/GetEngineerRequestAsync?jobId=' + encodeURIComponent(jobId) + '&documentId=' + encodeURIComponent(documentId)
    )

    if (res.status === 200) {
      // process document
      return res.data
    } else {
      return null
    }
  }

  public static async AssignEngineerRequest(
    assignEngineerRequestModel: AssignEngineerRequestModel
  ): Promise<EngineerRequestModel | null> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AssignEngineerRequest', assignEngineerRequestModel)
    if (res.status >= 200 && res.status < 300) {
      return res.data
    } else {
      return null
    }
  }

  public static async MarkEngineerRequestComplete(
    completeEngineerRequest: CompleteEngineerRequest
  ): Promise<[APIResponseCode, string]> {
    const res: AxiosResponse = await CallCentreApi.post('Job/MarkEngineerRequestComplete', completeEngineerRequest)
    if (res.status >= 200 && res.status < 300) {
      return ['Success', res.data]
    } else {
      return ['Error', res.statusText]
    }
  }

  public static async AddCloseJobRequest(
    closeJobRequestModel: AddCloseJobRequestModel
  ): Promise<[APIResponseCode, boolean]> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddCloseJobRequest', closeJobRequestModel)
    if (res.status >= 200 && res.status < 300) {
      return ['Success', true]
    } else {
      return ['Error', false]
    }
  }

  public static async AddAdditionalCostAuthorisedRequest(
    additionalCostAuthorisedRequestModel: AddAdditionalCostAuthorisedRequestModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Job/AddAdditionalCostAuthorisedRequest',
      additionalCostAuthorisedRequestModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateCanChargeCalloutForVisit(
    updateCanChargeCalloutForVisit: UpdateCanChargeCalloutForVisit
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<UpdateCanChargeCalloutForVisit>(
      'Job/UpdateCanChargeCalloutForVisit',
      updateCanChargeCalloutForVisit
    )
    return res.status >= 200 && res.status < 300
  }

  public static async AddRequestToEscalateCTPProcess(
    escalateCTPProcess: AddRequestToEscalateCTPProcessModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddRequestToEscalateCTPProcess', escalateCTPProcess)
    return res.status >= 200 && res.status < 300
  }

  public static async AddHelplinePolicyRequest(
    helpLineJobRequestModel: AddHelplineJobRequestModel
  ): Promise<string | null> {
    const res = await CallCentreApi.post<Job>('Job/AddHelplinePolicyRequest', helpLineJobRequestModel)

    if (res.data) {
      for (let i = 0, l = (res.data.emergenciesQAs || []).length; i < l; i++) {
        res.data.emergenciesQAs[i] = Object.assign(new EmergencyQA(), res.data.emergenciesQAs[i])
      }
      StoreMutations.addOrReplaceJob(res.data)
      return res.data.id
    } else {
      return null
    }
  }

  public static async CompleteHelplineJob(model: AddRequestToCompleteHelplineJobModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<AddRequestToCompleteHelplineJobModel>(
      'Job/CompleteHelplineJob',
      model
    )
    return res.status >= 200 && res.status < 300
  }

  public static async ResendClientEmail(clientEmailModel: SendClientEmail): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<SendClientEmail>('Job/ResendClientEmail', clientEmailModel)
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateEngineerJobVisitStatus(
    updateEngineerJobVisitStatus: UpdateEngineerJobVisitStatus
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Job/UpdateEngineerJobVisitStatus',
      updateEngineerJobVisitStatus
    )
    return res.status >= 200 && res.status < 300
  }

  public static async RejectEmergencyAsync(emergencyReject: UpdateEmergencyAcceptedOrRejected): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('job/RejectEmergencyAsync', emergencyReject)
    return res.status >= 200 && res.status < 300
  }

  public static async GetCompleteJobData(jobId: string): Promise<CompleteJobDataModel | null> {
    const res = await CallCentreApi.get<CompleteJobDataModel>('Job/GetCompleteJobData?jobId=' + jobId)
    return res.data
  }

  public static async AddRequestForCancelJob(cancelJobRequestModel: AddRequestForCancelJobModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/AddRequestForCancelJob', cancelJobRequestModel)
    return res.status >= 200 && res.status < 300
  }

  public static async EscalateJobAsync(escalateJobRequest: AddEscalateJobRequest): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/EscalateJobAsync', escalateJobRequest)
    return res.status >= 200 && res.status < 300
  }

  public static async AcceptProvisionedVisit(acceptProvisionedVisit: AcceptProvisionedVisit): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<AcceptProvisionedVisit>(
      'Job/AcceptProvisionedVisit',
      acceptProvisionedVisit
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateJobPolicySchedule(
    updateJobPolicySchedule: UpdateJobPolicySchedule
  ): Promise<CheckPolicyLimitResponse | null> {
    const res: AxiosResponse = await CallCentreApi.post('job/UpdateJobPolicySchedule', updateJobPolicySchedule)

    if (res.status >= 200 && res.status < 300) {
      const result = res.data as CheckPolicyLimitResponse
      if (!result) {
        return null
      }
      return result
    }
    return null
  }

  public static async RemoveEmergencyFromJob(
    removeEmergencyFromJobModel: RemoveEmergencyFromJobModel
  ): Promise<boolean | string> {
    const res: AxiosResponse = await CallCentreApi.post('Job/RemoveEmergencyFromJob', removeEmergencyFromJobModel)
    return res.status >= 200 && res.status < 300 ? true : res.statusText
  }

  public static async UpdateJobOffsiteTime(
    updateDataOfCompletedJobJobToComplete: UpdateJobOffsiteTime
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<UpdateJobOffsiteTime>(
      'Job/UpdateJobOffsiteTime',
      updateDataOfCompletedJobJobToComplete
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateGasSafetyForJob(updateGasSafetyForJob: UpdateGasSafetyForJob): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<UpdateGasSafetyForJob>(
      'Job/UpdateGasSafetyForJob',
      updateGasSafetyForJob
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SendQuestionModeLink(jobId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get('Job/SendQuestionModeLink?jobId=' + jobId)
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateMaterialCostRequest(
    updateMaterialCostRequest: UpdateMaterialCostRequestModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<UpdateMaterialCostRequestModel>(
      'Job/UpdateMaterialCostRequest',
      updateMaterialCostRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async GetJobSummaryDetail(jobId: string): Promise<JobSummaryCardModel | null> {
    const res: AxiosResponse = await CallCentreApi.get('Job/GetJobSummaryDetail?jobId=' + jobId)
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async JobNotReadyToClose(jobNotReadyToCloseModel: JobNotReadyToCloseModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/JobNotReadyToClose', jobNotReadyToCloseModel)
    return res.status >= 200 && res.status < 300
  }

  public static async SaveOtherFeesDetail(saveOtherFeesDetailModel: SaveOtherFeesDetailModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/SaveOtherFeesDetail', saveOtherFeesDetailModel)
    return res.status >= 200 && res.status < 300
  }

  public static async AddCustomerAppointedTradespeopleRequest(
    addCustomerAppointedTradespeopleRequest: AddCustomerAppointedTradespeopleRequest
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Job/AddCustomerAppointedTradespeopleRequest',
      addCustomerAppointedTradespeopleRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async GetClientCostDetailAsync(jobId: string): Promise<ClientCostDetailModel[]> {
    const res: AxiosResponse = await CallCentreApi.get('Job/GetClientCostDetailAsync?jobId=' + jobId)
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async GetFinancialSummaryCardDetail(jobId: string): Promise<FinancialSummaryCardDetailModel | null> {
    const res: AxiosResponse = await CallCentreApi.get('Job/GetFinancialSummaryCardDetail?jobId=' + jobId)
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async DeEscalateJobAsync(escalateJobRequest: AddDeEscalateJobRequest): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/DeEscalateJobAsync', escalateJobRequest)
    return res.status >= 200 && res.status < 300
  }

  public static async ReopenJobAsync(reopenJobRequest: AddReopenJobRequest): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/ReopenJobAsync', reopenJobRequest)
    return res.status >= 200 && res.status < 300
  }

  public static async ClearEta(clearEta: ClearEta): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Job/ClearEta', clearEta)
    return res.status === 202
  }

  public static async GetTimelineCards(jobId: string): Promise<TimelineCardModel[]> {
    const res = await CallCentreApi.get<TimelineCardModel[]>(`jobs/${jobId}/timeline-cards`)
    return res.data ? res.data : []
  }

  private static castAndStoreJobDocument(documentJsonConverted: any): void {
    if (!documentJsonConverted) {
      return
    }
    const jobId = documentJsonConverted.jobId
    if (!jobId) {
      return
    }

    try {
      switch (documentJsonConverted.type) {
        case 'JobDetail':
          if (!storeGetters.getJob(jobId)) {
            const jd = Object.assign(new Job(), documentJsonConverted)
            storeMutations.addOrReplaceJob(jd)
          } else {
            storeMutations.updateJobTopLevelProperties(documentJsonConverted)
          }
          break
        case 'EmergencyDetail':
          const ed = Object.assign(new Emergency(), documentJsonConverted)
          storeMutations.addOrReplaceEmergency(ed)
          break
        case 'EmergencyQA':
          const eqa = Object.assign(new EmergencyQA(), documentJsonConverted)
          storeMutations.addOrReplaceEmergencyQA(eqa)
          break
        case 'ProductQA':
          const pqa = Object.assign(new ProductQA(), documentJsonConverted)
          storeMutations.addOrReplaceProductQA(pqa)
          break
        case 'HealthAndSafetyQA':
          const hsa = Object.assign(new HealthAndSafetyQAModel(), documentJsonConverted)
          storeMutations.addOrReplaceHealthAndSafetyQA(hsa)
          break
        case 'VulnerabilityQA':
          const vqa = Object.assign(new VulnerabilityQAModel(), documentJsonConverted)
          storeMutations.addOrReplaceVulnerabilityQA(vqa)
          break
        case 'CallContractor':
          const cc = Object.assign(new CallContractorModel(), documentJsonConverted)
          storeMutations.addOrReplaceCallContractor(cc)
          break
        case 'CallLog':
          const cl = Object.assign(new CallLogModel(), documentJsonConverted)
          storeMutations.addOrReplaceCallLog(cl)
          break
        case 'PictureUpload':
          try {
            const pu = Object.assign(new PictureUploadModel(), documentJsonConverted)
            // get SAS token for picture thumbnail and then update in store directly to remove multiple API calls to get SAS token for same image
            PhotosController.GetThumbnailUrl(jobId, pu.id).then((res: string) => {
              if (res && typeof res === 'string') {
                pu.fileURL = res
                storeMutations.addOrReplaceClaimPicture(pu)
              }
            })
          } catch (e) {
            console.log('Error in PictureUpload document: ', e)
            eventBus.$emit('errorHandler', e, false)
          }
          break
        case 'CustomerAvailability':
          const ca = Object.assign(new CustomerAvailabilityModel(), documentJsonConverted)
          storeMutations.addOrReplaceCustomerAvailability(ca)
          break
        case 'FinancialSummary':
          const fs = Object.assign(new FinancialSummaryModel(), documentJsonConverted)
          storeMutations.addOrReplaceFinancialSummary(fs)
          break
        case 'CustomerToPay':
          const cp = Object.assign(new CustomerToPayModel(jobId), documentJsonConverted)
          storeMutations.addOrReplaceCustomerToPay(cp)
          break
        case 'EngineerRequest':
          const er = Object.assign(new EngineerRequestModel(), documentJsonConverted)
          storeMutations.addOrReplaceEngineerRequest(er)
          break
        case 'WeatherForecast':
          const wf = Object.assign(new WeatherForecastModel(), documentJsonConverted)
          storeMutations.setWeatherForcast(jobId, wf)
          break
        case 'JobNote':
          const jn = Object.assign(new JobNoteModel(), documentJsonConverted)
          storeMutations.addOrReplaceJobNote(jn)
          break
        case 'SMS':
          const sms = Object.assign(new SMSModel(), documentJsonConverted)
          storeMutations.addOrReplaceJobSmsText(sms)
          break
        case 'Complaint':
          const c = Object.assign(new ComplaintModel(), documentJsonConverted)
          storeMutations.addOrReplaceComplaint(c)
          break
        case 'ContractorAppointedDetail':
          const cad = Object.assign(new ContractorAppointedModel(), documentJsonConverted)
          storeMutations.addOrReplaceContractorAppointedDetail(cad)
          break
        case 'PolicyEnquiry':
          const pe = Object.assign(new PolicyEnquiry(), documentJsonConverted)
          storeMutations.addOrReplacePolicyEnquiry(pe)
          break
        case 'ThirdPartyAppointedContractor':
          const cn = Object.assign(new ThirdPartyAppointedContractor(), documentJsonConverted)
          storeMutations.addOrReplaceThirdPartyContractorAppointedDetail(cn)
          break
        case 'SoftFixedEmergency':
          const se = Object.assign(new SoftFixedEmergency(), documentJsonConverted)
          storeMutations.addOrReplaceSoftFixedEmergencyDetail(se)
          break
        case 'AccessNote':
          const an = Object.assign(new AccessNoteModel(), documentJsonConverted)
          storeMutations.addOrReplaceAccessNote(an)
          break
        case 'Email':
          const email = Object.assign(new EmailModel(), documentJsonConverted)
          storeMutations.addOrReplaceJobEmail(email)
          break
        case 'EngineerVisitDetail':
          const ev = Object.assign(new EngineerVisitDetail(), documentJsonConverted)
          storeMutations.addOrReplaceEngineerVisitDetail(ev)
          break
        case 'BoilerBreakdownQA':
          const bbqa = Object.assign(new BoilerBreakdownQA(), documentJsonConverted)
          storeMutations.addOrReplaceBoilerBreakdownQA(bbqa)
          break
        case 'ContractorInvoiceDetail':
          const ci = Object.assign(new ContractorInvoiceDetailModel(), documentJsonConverted)
          storeMutations.addOrReplaceContractorInvoiceDetail(ci)
          break
        case 'ClientInvoiceDetail':
          const cid = Object.assign(new ClientInvoiceDetailModel(), documentJsonConverted)
          storeMutations.addOrReplaceClientInvoiceDetail(cid)
          break
        case 'HelplinePolicy':
          const hp = Object.assign(new HelplinePolicyModel(), documentJsonConverted)
          storeMutations.addOrReplaceHelplinePolicy(hp)
          break
        case 'VisitHealthAndSafetyProcess':
          const vhsp = Object.assign(new VisitHealthAndSafetyProcess(), documentJsonConverted)
          storeMutations.addOrReplaceVisitHealthAndSafetyProcess(vhsp)
          break
        case 'ReportDetail':
          const rd = Object.assign(new ReportDetail(), documentJsonConverted)
          storeMutations.addOrReplaceReportDetail(rd)
          break
        case 'MonitoringDetail':
          const md = Object.assign(new MonitoringDetail(), documentJsonConverted)
          storeMutations.setMonitoringDetail(md)
          break
        case 'TestingProgressDetail':
          const tpd = Object.assign(new TestingProgressDetail(), documentJsonConverted)
          storeMutations.addOrReplaceTestingProgress(tpd)
          break
        case 'IVSDetail':
          const ivsd = Object.assign(new IVSDetailModel(), documentJsonConverted)
          storeMutations.addOrReplaceIVSDetail(ivsd)
          break
        case 'SIJobDelay':
          const siJobDelay = Object.assign(new JobDelay(), documentJsonConverted)
          storeMutations.setSIJobDelay(siJobDelay)
          break
        case 'USJobDelay':
          const usJobDelay = Object.assign(new JobDelay(), documentJsonConverted)
          storeMutations.setUSJobDelay(usJobDelay)
          break
        case 'SIVisitTrialPitRecord':
          const siVisitTrialPitRecord = Object.assign(new SIVisitTrialPit(), documentJsonConverted)
          storeMutations.addOrReplaceTrialPitDetail(siVisitTrialPitRecord)
          break
        case 'CCTVControlLog':
          const cctvControlLog = Object.assign(new CCTVControlLogModel(), documentJsonConverted)
          storeMutations.addOrReplaceCCTVControlLogDetail(cctvControlLog)
          break
        case 'DatumControlLog':
          const datumControlLog = Object.assign(new DatumControlLog(), documentJsonConverted)
          storeMutations.addOrReplaceDatumControlLogDetail(datumControlLog)
          break
        case 'WaterMainsTest':
          const waterMainsTest = Object.assign(new WaterMainsTest(), documentJsonConverted)
          storeMutations.addOrReplaceWaterMainsTestDetail(waterMainsTest)
          break
        case 'TrialPitDrawing':
          const trialPitDrawing = Object.assign(new TrialPitDrawingModel(), documentJsonConverted)
          storeMutations.addOrReplaceTrialPitDrawing(trialPitDrawing)
          break
        case 'DrainageReport':
          const drainageReport = Object.assign(new DrainageReportModel(), documentJsonConverted)
          storeMutations.addOrReplaceDrainageReport(drainageReport)
          break
        case 'CustomerAppointedTradespeople':
          const customerAppointedTradespeople = Object.assign(
            new CustomerAppointedTradespeople(),
            documentJsonConverted
          )
          storeMutations.addOrReplaceCustomerAppointedTradespeople(customerAppointedTradespeople)
          break
        case 'JobEscalationDetail':
          const jobEscalationDetail = Object.assign(new JobEscalationDetail(), documentJsonConverted)
          storeMutations.addOrReplaceJobEscalationDetails(jobEscalationDetail)
          break
        case 'RepairEstimate':
          const repairEstimate = Object.assign(new RepairEstimateModel(), documentJsonConverted)
          storeMutations.addOrReplaceRepairEstimateDetail(repairEstimate)
          break
        default:
          // commented below line to avoid console errors on unknown cases.
          // throw new Error("unknown job document type: " + documentJsonConverted.type);
          break
      }
    } catch (e) {
      console.log('Error in document: ', e)
      eventBus.$emit('errorHandler', e, false)
    }
  }
}
