<template>
  <div>
    <div class="mb-2"><h4>Job Note</h4></div>
    <div class="mb-1">
      <label>Note Type: </label>
      <span>{{ item.noteType }}</span>
    </div>
    <div class="mb-1">
      <label>Title: </label>
      <span>{{ item.title }}</span>
    </div>
    <div class="mb-1">
      <label>Description: </label>
      <pre>{{ item.description }}</pre>
    </div>
    <div v-if="getEmergencyDetailDescription" class="mb-1">
      <label>Emergency Detail: </label>
      <span>
        <pre>{{ getEmergencyDetailDescription }}</pre>
      </span>
    </div>
    <div v-if="item.callSid" class="mb-1">
      <label>Description: </label>
      <span>
        <pre>{{ item.callSid }}</pre>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddNewJobNote from '@/models/requests/AddNewJobNote'
import storeGetters from '@/storeGetters'

@Component
export default class AddJobNoteLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddNewJobNote {
    return Object.assign(new AddNewJobNote(), this.requestBody)
  }

  private get getEmergencyDetailDescription(): string | null {
    if (this.item.forEmergencyDetailDescription === 'All') {
      const jobItem = storeGetters.getJob(this.item && this.item.jobId)
      const emergencyDetailDescription =
        jobItem && jobItem.emergencies && jobItem.emergencies.length > 0
          ? jobItem.emergencies.map((x) => x.detailDescription)
          : []
      return emergencyDetailDescription.join(', ')
    } else {
      return this.item.forEmergencyDetailDescription ? this.item.forEmergencyDetailDescription : null
    }
  }
}
</script>

<style scoped>
pre {
  display: inherit;
  font-family: inherit;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
