<template>
  <div>
    <v-menu
      offset-y
      :close-on-content-click="true"
      :nudge-width="320"
      :max-width="320"
      :z-index="999"
      content-class="preferred-contact-menu"
      left
      fixed
    >
      <template #activator="{ on }">
        <span class="ma-0" v-on="on">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-badge overlap color="grey small-size-badge darken-2">
                <v-avatar color="white" class="elevation-5 btn-contactDetails" :size="35" v-on="on">
                  <v-icon dark class="secondary--text md-icon">perm_contact_calendar</v-icon>
                </v-avatar>
              </v-badge>
            </template>
            <span>Contact Details</span>
          </v-tooltip>
          <span class="n-arrow"></span>
        </span>
      </template>
      <v-card>
        <div class="view-panel contact-list">
          <v-list two-line>
            <v-list-tile
              v-if="
                (jobItem.mobilePhone &&
                  (jobItem.contactPreference == contactPreference[contactPreference.Call] ||
                    jobItem.contactPreference == contactPreference[contactPreference.SMS])) ||
                (jobItem.email && jobItem.contactPreference == contactPreference[contactPreference.Email])
              "
            >
              <v-list-tile-content>
                <span class="name primary--text">
                  {{ jobItem.clientFullName }}
                  <span class="grey--text">(User)</span>
                </span>
                <span
                  v-if="
                    jobItem.mobilePhone &&
                    (jobItem.contactPreference == contactPreference[contactPreference.Call] ||
                      jobItem.contactPreference == contactPreference[contactPreference.SMS])
                  "
                  class="grey--text text--darken-2 number"
                >
                  <v-icon small color="secondary">phone</v-icon>
                  <a class="number" @click="onCallIconClick(jobItem.mobilePhone)">
                    {{ jobItem.mobilePhone }}
                  </a>
                </span>
                <span
                  v-if="jobItem.email && jobItem.contactPreference == contactPreference[contactPreference.Email]"
                  class="grey--text text--darken-2"
                >
                  <v-icon small color="secondary">email</v-icon>
                  <a :href="'mailto:' + jobItem.email" class="number">
                    {{ jobItem.email }}
                  </a>
                </span>
                <span
                  v-if="
                    jobItem.mobilePhone &&
                    jobItem.reasonForChoosingCallOption &&
                    jobItem.contactPreference == contactPreference[contactPreference.Call]
                  "
                  class="grey--text text--darken-2 mt-1 ml-1 call-preference-Reason"
                >
                  <b>Reason For selecting Call As a Preference:</b>
                  <span>{{ jobItem.reasonForChoosingCallOption }}</span>
                </span>
                <span v-if="showEditContactPreferencePopup" class="action edit-contact-detail">
                  <a class="elevation-1" @click="openContactPreferencePopup()">
                    <v-icon btn small color="primary">edit</v-icon>
                  </a>
                </span>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="
                (jobItem.nomineeMobilePhone &&
                  (jobItem.nomineeContactPreference == contactPreference[contactPreference.Call] ||
                    jobItem.nomineeContactPreference == contactPreference[contactPreference.SMS])) ||
                (jobItem.nomineeEmail && jobItem.nomineeContactPreference == contactPreference[contactPreference.Email])
              "
            >
              <v-list-tile-content>
                <span class="name primary--text">
                  {{ jobItem.nomineeClientFullName }}
                  <span class="grey--text">(Nominee)</span>
                </span>
                <span
                  v-if="
                    jobItem.nomineeMobilePhone &&
                    (jobItem.nomineeContactPreference == contactPreference[contactPreference.Call] ||
                      jobItem.nomineeContactPreference == contactPreference[contactPreference.SMS])
                  "
                  class="grey--text text--darken-2 number"
                >
                  <v-icon small color="secondary">phone</v-icon>
                  <a class="number" @click="onCallIconClick(jobItem.nomineeMobilePhone)">
                    {{ jobItem.nomineeMobilePhone }}
                  </a>
                  <br />
                </span>
                <span
                  v-if="
                    jobItem.nomineeEmail &&
                    jobItem.nomineeContactPreference == contactPreference[contactPreference.Email]
                  "
                  class="grey--text text--darken-2"
                >
                  <v-icon small color="secondary">email</v-icon>
                  <a :href="'mailto:' + jobItem.nomineeEmail" class="number">
                    {{ jobItem.nomineeEmail }}
                  </a>
                </span>
                <span
                  v-if="
                    jobItem.nomineeMobilePhone &&
                    jobItem.nomineeReasonForChoosingCallOption &&
                    jobItem.nomineeContactPreference == contactPreference[contactPreference.Call]
                  "
                  class="grey--text text--darken-2 mt-1 ml-1 nominee-call-preference-Reason"
                >
                  <b>Reason For selecting Call As a Preference:</b>
                  <span>{{ jobItem.nomineeReasonForChoosingCallOption }}</span>
                </span>
              </v-list-tile-content>
            </v-list-tile>
            <template v-if="getContractorAppointedDetail.length > 0">
              <v-list-tile v-for="contractor in getContractorAppointedDetail" :key="contractor.id">
                <v-list-tile-content>
                  <span class="name primary--text mb-2">
                    {{ contractor.companyName }}
                    <span class="grey--text">(Contractor)</span>
                  </span>
                  <template v-if="contractor.contactNumbers.length > 0">
                    <span
                      v-for="(contact, index) in contractor.contactNumbers"
                      :key="index"
                      class="grey--text text--darken-2"
                    >
                      <a class="number" @click="onCallIconClick(contact.phoneNumber, contractor.contractorId)">
                        {{ contact.phoneNumber }}
                      </a>
                      <span class="caption grey--text">- {{ contact.label ? contact.label : 'Phone number' }}</span>
                    </span>
                  </template>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </div>
      </v-card>
    </v-menu>

    <v-dialog
      v-if="changePreference && jobItem"
      v-model="changePreference"
      max-width="800"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Change Contact Preference</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeContactPreferenceDetailPopup()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-layout>
            <v-flex xs12>
              <ContactPreferenceDetails
                ref="contactPreferenceDetails"
                :customer-contact-preference-modal="customerContactPreferenceModal"
                @updateContactPreference="updateContactPreference"
                @isHasNominee="isHasNominee"
              ></ContactPreferenceDetails>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click="closeContactPreferenceDetailPopup()">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0"
            :disabled="isLoading"
            :loading="isLoading"
            @click="updateContactPreferenceDetail()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import eventBus from '@/common/bus'
import { JobVisitStatus } from '@/common/enums'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import CallParameters from '@/models/twilio/CallParameters'
import ContactPreferenceDetails from '@/components/ContactPreferenceDetails.vue'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import { ContactPreferenceEnum } from '@/common/enums'
import { ContactPreference } from '@/models/types'
import JobController from '@/api/jobController'
import Shared from '@/common/shared'
import JobPermissions from '@/models/job/job-permissions'

@Component({
  components: { ContactPreferenceDetails },
})
export default class CustomerCall extends Vue {
  @Prop() private jobId: string
  private isLoading = false
  private changePreference = false
  private contactPreference = ContactPreferenceEnum
  private hasNominee = false
  private customerContactPreferenceModal: UpdateCustomerContactPreferenceModal =
    new UpdateCustomerContactPreferenceModal()

  private get jobItem(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private get getContractorAppointedDetail(): ContractorAppointedModel[] {
    const job = storeGetters.getJob(this.jobId)
    if (job) {
      return job.contractorAppointed.filter(
        (ac) =>
          ac.status !== JobVisitStatus[JobVisitStatus.Unavailable] &&
          ac.status !== JobVisitStatus[JobVisitStatus.Cancelled]
      )
    }
    return []
  }

  public get jobPermissions(): JobPermissions {
    return this.$store.getters['jobModule/jobPermissions']
  }

  public get showEditContactPreferencePopup() {
    if (!this.jobItem) return false
    if (this.jobItem.jobType !== 'HE') {
      return true
    }

    return this.jobPermissions.canChangeContactDetails
  }

  private onCallIconClick(phoneNumber: string, contractorId: string) {
    const callParameters = new CallParameters()
    callParameters.to = phoneNumber
    callParameters.jobId = this.jobId
    if (contractorId) {
      callParameters.contractorId = contractorId
    }
    eventBus.$emit('callIconClick', callParameters)
  }

  private bindContactPreferenceDetails(job: Job) {
    const self = this
    if (self.jobItem) {
      Object.keys(self.jobItem).forEach(
        (key) => (self.customerContactPreferenceModal[key] = self.jobItem !== null && self.jobItem[key])
      )
    }
  }

  private updateContactPreference(updateCustomerContactPreferenceModel: UpdateCustomerContactPreferenceModal) {
    this.customerContactPreferenceModal = updateCustomerContactPreferenceModel
  }

  private openContactPreferencePopup() {
    if (this.jobItem) {
      this.clearData()
      this.bindContactPreferenceDetails(this.jobItem)
      // open popup
      this.changePreference = true
    }
  }

  private clearData() {
    // close popup
    this.changePreference = false
  }

  private closeContactPreferenceDetailPopup() {
    if (this.jobItem) {
      // bind initial values
      this.bindContactPreferenceDetails(this.jobItem)
    }
    this.clearData()
    this.customerContactPreferenceModal = new UpdateCustomerContactPreferenceModal()
  }

  private async updateContactPreferenceDetail() {
    let result = true
    const sub3 = this.$refs.contactPreferenceDetails as ContactPreferenceDetails
    if (sub3) {
      result = await sub3.validateContactPreferenceStep()
    }
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    } else {
      this.isLoading = true
      // reset email on sms/call selection
      if (
        this.customerContactPreferenceModal.contactPreference === this.contactPreference[this.contactPreference.SMS] ||
        this.customerContactPreferenceModal.contactPreference === this.contactPreference[this.contactPreference.Call]
      ) {
        this.customerContactPreferenceModal.email = ''
      }
      // reset reasonForChoosingCallOption on SMS/Email selection
      if (
        this.customerContactPreferenceModal.contactPreference !== this.contactPreference[this.contactPreference.Call]
      ) {
        this.customerContactPreferenceModal.reasonForChoosingCallOption = ''
      }
      // reset mobilephone on email selection
      if (
        this.customerContactPreferenceModal.contactPreference === this.contactPreference[this.contactPreference.Email]
      ) {
        this.customerContactPreferenceModal.mobilePhone = ''
      }
      // nominee - reset email on sms/call selection
      if (
        this.customerContactPreferenceModal.nomineeContactPreference ===
          this.contactPreference[this.contactPreference.SMS] ||
        this.customerContactPreferenceModal.nomineeContactPreference ===
          this.contactPreference[this.contactPreference.Call]
      ) {
        this.customerContactPreferenceModal.nomineeEmail = ''
      }
      // nominee - reset mobilephone on email selection
      if (
        this.customerContactPreferenceModal.nomineeContactPreference ===
        this.contactPreference[this.contactPreference.Email]
      ) {
        this.customerContactPreferenceModal.nomineeMobilePhone = ''
      }
      // nominee - reasonForChoosingCallOption on SMS/Email selection
      if (
        this.customerContactPreferenceModal.nomineeContactPreference !==
        this.contactPreference[this.contactPreference.Call]
      ) {
        this.customerContactPreferenceModal.nomineeReasonForChoosingCallOption = ''
      }
      // call API to update contact details
      JobController.UpdateCustomerDetail(this.customerContactPreferenceModal)
        .then((res: boolean) => {
          if (!res) {
            alert('error updating customer contact detail, please try again')
            return
          }
          // rebind model to update preference card on job page
          this.rebindCustomerCard()
          this.clearData()
          eventBus.$emit('showSnackbar', 'Customer contact detail updated successfully.')
          this.isLoading = false
        })
        .catch((err: any) => {
          alert('failed to submit')
          this.isLoading = false
          eventBus.$emit('errorHandler', 'Error updating customer contact detail, please try again', true)
        })
    }
  }

  private rebindCustomerCard() {
    const self = this
    if (self.jobItem) {
      Object.keys(self.customerContactPreferenceModal).forEach(
        (key) => self.jobItem !== null && (self.jobItem[key] = self.customerContactPreferenceModal[key])
      )
    }
  }

  private isHasNominee(hasNominee: boolean) {
    this.hasNominee = hasNominee
  }
}
</script>

<style>
.v-menu__content.preferred-contact-menu {
  z-index: 9 !important;
}
</style>

<style type="text/css" scoped>
.contact-list >>> .v-list {
  font-size: 15px;
  position: relative;
}
.contact-list >>> .v-list .action {
  position: absolute;
  right: 0px;
  top: 8px;
}
.contact-list >>> .v-list .action a {
  height: 30px;
  width: 30px;
  display: inline-flex;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}
.contact-list >>> .v-list .action a i {
  margin: auto;
}
.contact-list >>> .v-list > div:not(:last-child) {
  border-bottom: 1px solid #d8d1d1;
}
.contact-list >>> .v-list .name {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 3px;
}
.contact-list >>> .v-list--two-line .v-list__tile {
  height: auto !important;
  padding: 10px 10px !important;
}
.contact-list >>> .v-list .v-list__tile__content {
  padding-right: 30px;
}
.number {
  color: #4a4a4a;
}
.number:hover {
  color: #c9004b;
}
</style>
