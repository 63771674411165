import HariCallTransferDetails from './HariCallTransferDetails'

export default class CallTaskAlert {
  public incomingAlertType: string // incoming call, callback
  public incomingAlertReason: string // fnol call, existing job call
  public additionalDetails: string // answerPhone or other info
  public allowReject: boolean // true for internal calls, false for external

  public policyDetails: string
  public policyLogoUrl: string

  public isVerified: boolean // FNOL calls where client went through data verification
  public clientDetails: string // caller name or number

  public hariSummary: HariCallTransferDetails | null = null
  public isCallFromHari = false

  public constructor() {
    this.incomingAlertType = ''
    this.incomingAlertReason = ''
    this.additionalDetails = ''
    this.allowReject = false
    this.policyDetails = ''
    this.policyLogoUrl = ''
    this.isVerified = false
    this.clientDetails = ''
  }

  public loadFromReservation(reservation: any) {
    if (!reservation) {
      return
    }
    const callDetail = reservation.task.attributes

    this.isCallFromHari = Boolean(callDetail.isFromHari)

    if (!callDetail.callType) {
      this.incomingAlertReason = 'Incoming Call' // TODO: check this
      return
    }
    if (!callDetail.taskType || callDetail.taskType === 'Incoming') {
      this.incomingAlertType = 'Incoming Call'
      switch (callDetail.callType) {
        // for FNOL and WIP calls, load the client name and policy information
        case 'FNOL':
        case 'ComplaintCall':
        case 'WorkInProgress':
          if (callDetail.clientName) {
            this.clientDetails = `Incoming call from ${callDetail.clientName}`
          } else if (callDetail.from) {
            this.clientDetails = `Incoming call from ${callDetail.from}`
          } else {
            this.clientDetails = 'Incoming call'
          }

          if (callDetail.callType === 'WorkInProgress') {
            if (callDetail.jobId) {
              this.incomingAlertReason = `Calling about job ${callDetail.jobId}`
            } else {
              this.incomingAlertReason = 'Calling about existing job'
            }
          } else if (callDetail.callType === 'ComplaintCall') {
            this.incomingAlertReason = 'Customer Relations'
          } else {
            this.incomingAlertReason = 'FNOL Call'
          }

          if (callDetail.policyName) {
            if (callDetail.isVerified) {
              this.policyDetails = `Verified ${callDetail.policyName} policy`
              this.isVerified = true
            } else {
              this.policyDetails = `${callDetail.policyName} policy`
            }
          } else {
            this.policyDetails = 'Unknown Policy'
          }

          if (callDetail.policyLogoURI) {
            this.policyLogoUrl = callDetail.policyLogoURI
          } else {
            this.policyLogoUrl = '/img/unknownpolicy.png'
          }

          if (callDetail.isFromHari) {
            this.hariSummary = {
              postcode: callDetail.customerPostcode ? callDetail.customerPostcode : '',
              postcodeMatchScore: callDetail.customerPostcodeMatchScore ? callDetail.customerPostcodeMatchScore : 0,
              surname: callDetail.customerSurname ? callDetail.customerSurname : '',
              surnameMatchScore: callDetail.customerSurnameMatchScore ? callDetail.customerSurnameMatchScore : '',
              callReason: callDetail.callReason ? callDetail.callReason : '',
              policyScheduleName: callDetail.hariPolicyScheduleName ? callDetail.hariPolicyScheduleName : '',
              callType: callDetail.callType ? callDetail.callType : '',
              overallMatchScore: callDetail.overallMatchScore ? callDetail.overallMatchScore : '',
              policyReference: callDetail.customerPolicyReference ? callDetail.customerPolicyReference : '',
              isMatchSuccessful: callDetail.isMatchSuccessful,
              jobId: callDetail.jobId ? callDetail.jobId : '',
            }
          }
          break
        // for contractor calls, load the contractor information
        case 'ContractorCall':
        case 'Deployment':
          this.incomingAlertReason = 'Incoming Contractor Call'
          if (callDetail.contractorName) {
            this.clientDetails = callDetail.contractorName
            this.policyDetails = callDetail.contractorName
          } else {
            this.clientDetails = callDetail.from
          }
          if (callDetail.contractorLogo) {
            this.policyLogoUrl = callDetail.contractorLogo
          } else {
            this.policyLogoUrl = '/img/unknownuser.png'
          }
          break
      }
      if (callDetail.answerPhone && callDetail.callType !== 'ComplaintCall') {
        this.additionalDetails = callDetail.answerPhone
      } else if (callDetail.callType === 'ComplaintCall') {
        this.additionalDetails = 'Thank you for calling CET Customer Relations! How may I help?'
      } else {
        this.additionalDetails = 'Welcome to our home emergency department! How may I help?'
      }
    } else if (callDetail.taskType === 'Callback') {
      this.incomingAlertType = 'Callback Request'
      // populate the alert for callback requests
      if (callDetail.clientName) {
        this.clientDetails = `Callback request from ${callDetail.clientName}`
      } else if (callDetail.callbackPhoneNumber) {
        this.clientDetails = `Callback request from ${callDetail.callbackPhoneNumber}`
      } else {
        this.clientDetails = `Callback request from ${callDetail.from}`
      }

      if (callDetail.callType === 'WorkInProgress') {
        if (callDetail.jobId) {
          this.incomingAlertReason = `Callback request about job ${callDetail.jobId}`
        } else {
          this.incomingAlertReason = 'Callback request about existing job'
        }
      } else {
        this.incomingAlertReason = 'FNOL Callback request'
      }

      if (callDetail.policyName) {
        if (callDetail.isVerified) {
          this.policyDetails = `Verified ${callDetail.policyName} policy`
          this.isVerified = true
        } else {
          this.policyDetails = `${callDetail.policyName} policy`
        }
      } else {
        this.policyDetails = 'Unknown Policy'
      }

      if (callDetail.policyLogoURI) {
        this.policyLogoUrl = callDetail.policyLogoURI
      } else {
        this.policyLogoUrl = '/img/unknownpolicy.png'
      }
    }
  }
}
