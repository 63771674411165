export default class ProductAttribute {
  public id: number
  public productId: number
  public attributeName: string
  public attributeValue: string

  public get displayName(): string {
    return (
      this.attributeName
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())
    )
  }

  public get isDisabled(): boolean {
    if (this.attributeName.toLowerCase() === 'readcost') {
      return true
    }
    return false
  }
}
