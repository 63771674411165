<template>
  <div>
    <div class="mb-2"><h4>Emergency Follow on Work</h4></div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="item.followOnDescription" class="mb-1">
      <label>Description: </label>
      <span>{{ item.followOnDescription }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateEmergencyFollowOnWorkModel from '@/models/requests/UpdateEmergencyFollowOnWorkModel'
import Shared from '@/common/shared'

@Component
export default class UpdateEmergencyFollowOnWorkLog extends Vue {
  @Prop() private request: any

  private get item(): UpdateEmergencyFollowOnWorkModel {
    return Object.assign(new UpdateEmergencyFollowOnWorkModel(), this.request)
  }

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.item.emergencyId)
  }
}
</script>
