export function FormatDate(input?: string): string {
  if (!input) {
    return 'xx/xx'
  }

  if (input.length > 4) {
    return 'Too many digits provided'
  }

  if (input.length < 3) {
    return input
  }

  return input.slice(0, 2) + '/' + input.slice(2)
}

export function FormatPaymentCard(input?: string): string {
  if (!input) {
    return 'xxxx xxxx xxxx xxxx'
  }

  if (input.length > 16) {
    return 'Too many digits provided'
  }

  const groups = input.match(/.{1,4}/g)
  if (!groups) {
    return 'xxxx xxxx xxxx xxxx'
  }
  return groups.join(' ')
}

export function FormatCVC(input?: string): string {
  return input || 'xxx'
}
