<template>
  <div>
    <div class="mb-2"><h4>Abandoned Trial Pit</h4></div>
    <div v-if="item.abandonedTrialPitReason" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.abandonedTrialPitReason }}</span>
    </div>
    <div v-if="item.abandonedTrialPitReasonNotes" class="mb-1">
      <label>Notes: </label>
      <span>{{ item.abandonedTrialPitReasonNotes }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddAbandonedTrialPitRequest from '@/models/siteInvestigation/requests/AddAbandonedTrialPitRequest'

@Component
export default class AddAbandonedTrialPitRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddAbandonedTrialPitRequest {
    return Object.assign(new AddAbandonedTrialPitRequest(), this.requestBody)
  }
}
</script>
