<template>
  <div :id="tableName">
    <v-layout wrap grid-view>
      <v-flex xs6 request-info>
        <h3>Job History</h3>
      </v-flex>
      <v-flex xs6 job-dashboard-seach>
        <div class="search-content">
          <v-text-field
            v-model="searchValue"
            append-icon="search"
            :label="setSearchLabel()"
            single-line
            hide-details
            class="mt-0 pt-0"
          ></v-text-field>
        </div>
        <div class="search-options">
          <v-select
            v-model="searchByFieldName"
            :items="searchByFieldItems"
            item-value="value"
            item-text="text"
            single-line
            hide-details
            @change="changeSearchByFieldValue($event)"
          ></v-select>
        </div>
      </v-flex>
      <v-flex xs12 mt-2 class="elevation-1">
        <v-data-table
          :headers="headers"
          :items="jobItems"
          :total-items="totalJobs"
          :loading="isLoading"
          class="complaint-table"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPageItems"
        >
          <template slot="items" slot-scope="props">
            <tr :active="props.selected" @click="props.selected = !props.selected"></tr>
            <tr>
              <td>
                <a class="primary--text" @click="redirectToJobUrl(props.item.id)">
                  {{ props.item.id }}
                </a>
              </td>
              <td>{{ props.item.policyNumber }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.postCode }}</td>
              <td>{{ getFormattedDateTime(props.item.createdAt) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import JobHeader from '@/models/JobHeader'
import JobDashboardModel from '@/models/claim/JobDashboardModel'
import CustomDashboardController from '@/api/customDashboardController'
import eventBus from '@/common/bus'

@Component
export default class JobHistoryTable extends Vue {
  private pagination: any = {}
  private headers: any = []
  private isLoading = false
  private searchByFieldItems: any = [
    { text: 'All', value: 'all' },
    { text: 'Id', value: 'id' },
    { text: 'Policy Number', value: 'policyNumber' },
    { text: 'Name', value: 'name' },
    { text: 'Postcode', value: 'postCode' },
  ]
  private sortable = false
  private totalJobs = 0
  private jobItems: JobHeader[] = []
  private waitForMoreInputTimeoutHandle: number | null = null
  private searchByFieldName = 'all'
  private rowsPerPageItems: number[] = [5, 10, 25]
  private pageNumber = 0
  private rowsPerPage = 0
  private searchValue = ''
  private searchField = ''
  private sortByField = ''
  private sortOrder = ''
  @Prop() private tableName: string

  private created() {
    this.headers = [
      { text: 'JobId', value: 'id', align: 'left', sortable: false },
      { text: 'Policy Number', value: 'policyNumber', align: 'left' },
      { text: 'Name', value: 'name', align: 'left' },
      {
        text: 'Postcode',
        value: 'postCode',
        align: 'left',
        class: 'severity-col',
      },
      {
        text: 'Logged At',
        value: 'createdAt',
        align: 'left',
        class: 'date-col',
      },
    ]
    this.pagination.page = 1
    this.pagination.rowsPerPage = 10
    this.pagination.sortBy = ''
    this.pagination.descending = ''
    this.searchByFieldName = 'all' // set default to All for search into data.
    this.GetJobHistoryTableData()
  }

  // Get JobDashboard Data
  private GetJobHistoryTableData() {
    this.isLoading = true
    CustomDashboardController.GetJobHistoryTableData()
      .then((res: JobDashboardModel | null) => {
        if (res) {
          this.jobItems = res.jobs
          this.totalJobs = res.totalJobCount
          this.isLoading = false
        } else {
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading job history data, please try again', true)
        this.isLoading = false
      })
  }

  @Watch('pagination')
  private paginationChange() {
    this.rowsPerPage = this.pagination.rowsPerPage
    this.pageNumber = this.pagination.page
    this.sortByField = this.pagination.sortBy === null ? '' : this.pagination.sortBy
    if (this.pagination.descending && this.sortByField) {
      this.sortOrder = 'desc'
    } else if (!this.pagination.descending && this.sortByField) {
      this.sortOrder = 'asc'
    } else {
      this.sortOrder = ''
    }
    this.getJobDetails()
  }

  @Watch('searchValue')
  private searchValueChanged(newValue: string) {
    this.searchValue = newValue
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }
    const self = this
    this.waitForMoreInputTimeoutHandle = window.setTimeout(() => {
      this.searchField = this.searchByFieldName
      this.pagination.page = 1
      this.pageNumber = 1
      this.getJobDetails()
    }, 300)
  }

  private changeSearchByFieldValue(event) {
    this.searchByFieldName = event
    this.searchField = this.searchByFieldName
    if (this.searchValue !== null && this.searchValue !== '' && this.searchValue !== undefined) {
      this.getJobDetails()
    }
  }

  // Get JobDashboard Detail
  private getJobDetails() {
    if (this.searchValue === null || this.searchValue === '') {
      this.searchField = ''
    }
    this.isLoading = true
    CustomDashboardController.GetJobDetails(
      this.pageNumber,
      this.rowsPerPage,
      this.searchField,
      this.searchValue,
      this.sortByField,
      this.sortOrder
    )
      .then((res: JobDashboardModel | null) => {
        if (res) {
          this.jobItems = res.jobs
          this.totalJobs = res.totalJobCount
          this.isLoading = false
        } else {
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading job list, please try again', true)
        this.isLoading = false
      })
  }

  private setSearchLabel(): string {
    if (this.searchByFieldName === 'id') {
      return 'Search by JobId'
    } else if (this.searchByFieldName === 'policyNumber') {
      return 'Search by Policy Number'
    } else if (this.searchByFieldName === 'name') {
      return 'Search by Name'
    } else if (this.searchByFieldName === 'postCode') {
      return 'Search by Postcode'
    } else {
      return 'Search'
    }
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private redirectToJobUrl(jobId: string) {
    this.$router.replace({ path: '/job/' + jobId })
  }
}
</script>

<style scoped>
.gridView tbody tr td:last-child {
  padding-left: 0px !important;
}
.gridView >>> table > thead > tr > th:nth-child(2) {
  width: 120px;
}
.gridView >>> table > thead > tr > th:nth-child(1) {
  width: 100px;
}
.gridView >>> table > thead > tr > th:nth-child(3) {
  width: 50%;
}
.calls-info.request-info >>> .v-card .v-card__text {
  display: flex;
  align-self: flex-end;
}
@media only screen and (max-width: 1200px) {
  .calls-info h2 {
    font-size: 30px;
  }
}
.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}
.gridView {
  border: 0px;
}
.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
}
.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}
.chart-line {
  width: 100%;
  position: relative;
  display: flex;
}
.data-not-availble-msg {
  margin: auto;
}
.v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
  width: 100% !important;
  max-height: 370px !important;
  object-fit: contain;
}
.search-options {
  min-width: 50px;
  max-width: 50px;
  position: absolute;
  top: -15px;
  right: 0px;
}
.job-dashboard-seach {
  position: relative;
}
@media only screen and (max-width: 1600px) {
  .v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 1300px) {
  .v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
    margin-top: 50px;
  }
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.search-content >>> .v-input__append-inner {
  padding-right: 40px;
  cursor: default;
}
.search-options >>> .v-select .v-input__slot:after,
.search-options >>> .v-select .v-input__slot:before {
  content: '';
  display: none;
}
.search-options >>> .v-select .v-icon {
  position: relative;
  right: 0px;
}
.search-options >>> .v-select__selections {
  visibility: hidden;
  display: none;
}
</style>
