<template>
  <div class="questions-list-content">
    <EmergencyQAEditControl
      v-for="item in localPolicyWideQAs"
      :key="item.id"
      :job-id="jobId"
      :emergency-q-a-id="item.id"
      :type="item.type"
      :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
      @setSyncing="setSyncing"
    />
    <EmergencyQAEditControl
      v-for="item in localProductQAs"
      :key="item.id"
      :job-id="jobId"
      :type="item.type"
      :emergency-q-a-id="item.id"
      :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
      @setSyncing="setSyncing"
    />
    <AccessNote
      :access-note-question="getAccessNoteItem"
      :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
      @setSyncing="setSyncing"
    ></AccessNote>
    <HealthAndSafetyQA
      v-for="healthAndSafetyQAItem in healthAndSafetyQAItems"
      :key="healthAndSafetyQAItem.id"
      :health-and-safety-q-a="healthAndSafetyQAItem"
      :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
      @setSyncing="setSyncing"
    ></HealthAndSafetyQA>
    <HealthAndSafetyQA
      :key="vulnerabilityQAItem.id"
      :health-and-safety-q-a="vulnerabilityQAItem"
      :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
      @setSyncing="setSyncing"
    ></HealthAndSafetyQA>
    <EnablementTriggerCard
      v-if="isSIJob"
      ref="enablementTriggerCard"
      :job-id="jobId"
      :emergency-id="emergencyId"
      @setSyncing="setSyncing"
    />
    <div v-if="!isEmergencyAccepted" class="claim-btn-content grey lighten-3">
      <v-card wrap class="elevation-0 grey lighten-3">
        <v-card-actions xs12>
          <v-spacer></v-spacer>
          <v-btn
            class="confirm-button"
            :class="!isConfirmationLoading ? 'green white--text' : 'grey'"
            :disabled="!criteriaToConfirmQuestions || isSyncing || isActionPerformed"
            :loading="isConfirmationLoading"
            @click="onConfirmQuestions"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import storeGetters from '@/storeGetters'

// components
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQAEditControl from '@/components/timeline/EmergencyQAEditControl.vue'
import HealthAndSafetyQA from '@/components/timeline/HealthAndSafetyQA.vue'
import AccessNote from '@/components/timeline/AccessNote.vue'
import EnablementTriggerCard from '@/components/timeline/EnablementTriggerCard.vue'
import JobController from '@/api/jobController'
import SiteInvestigationController from '@/api/siteInvestigationController'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'

// models
import EmergencyQA from '@/models/EmergencyQA'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import Job from '@/models/Job'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import ProductQA from '@/models/ProductQA'
import UpdateEmergencyAcceptedOrRejected from '@/models/requests/UpdateEmergencyAcceptedOrRejected'
import CheckPolicyLimitResponse from '@/models/claim/CheckPolicyLimitResponse'
import ChangeSIEnablementModel from '@/models/requests/ChangeSIEnablementModel'

@Component({
  components: {
    HealthAndSafetyQA,
    EmergencyQAEditControl,
    AccessNote,
    EnablementTriggerCard,
  },
})
export default class SiUsJobQuestionsPreview extends TimeLineItemBase {
  private syncing = false
  private isConfirmationLoading = false
  private isActionPerformed = false // if true disable all buttons
  private localPolicyWideQAs: EmergencyQA[] = []
  private localProductQAs: ProductQA[] = []

  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private emergencyAcceptOrReject: UpdateEmergencyAcceptedOrRejected = new UpdateEmergencyAcceptedOrRejected()

  private get healthAndSafetyQAItems(): HealthAndSafetyQAModel[] {
    return storeGetters.getHealthAndSafetyQA(this.jobId)
  }
  private get vulnerabilityQAItem(): VulnerabilityQAModel {
    return storeGetters.getVulnerabilityQA(this.jobId)
  }

  private get getAcceptRejectQuestionCriteria(): boolean {
    return this.isEmergencyAccepted || this.isActionPerformed
  }

  private mounted() {
    this.updateLocalStore()
  }

  @Watch('job')
  private updateLocalStore() {
    this.localPolicyWideQAs = Object.assign([], this.policyWideQAs)
    this.localProductQAs = Object.assign([], this.productQAs)
  }

  private get policyWideQAs(): EmergencyQA[] {
    return storeGetters.getPolicyWideQAs(this.jobId).sort((a, b) => a.orderByForDisplay - b.orderByForDisplay)
  }

  private get productQAs(): ProductQA[] {
    return storeGetters.getProductQAs(this.jobId)
  }

  private get isEmergencyAccepted(): boolean {
    return this.emergency !== null ? this.emergency.isEmergencyAccepted : false
  }

  private get getAccessNoteItem(): AccessNoteModel {
    return storeGetters.getAccessNote(this.jobId)
  }

  // isSyncing disable accept/reject button until sync is not completed
  private get isSyncing(): boolean {
    return this.syncing
  }

  private setSyncing(sync: boolean) {
    this.syncing = sync
  }

  private async onConfirmQuestions() {
    if (this.criteriaToConfirmQuestions) {
      let enablement = true
      if (this.job && this.job.enablementTriggeredAt !== null && this.isSIJob) {
        enablement = await (this.$refs.enablementTriggerCard as EnablementTriggerCard).validate()
      }
      if (enablement) {
        this.isConfirmationLoading = true
        this.confirmQuestions()
      }
    }
  }

  private confirmQuestions() {
    this.emergencyAcceptOrReject.id = this.emergencyId
    this.emergencyAcceptOrReject.jobId = this.jobId
    this.emergencyAcceptOrReject.isEmergencyAccepted = true
    JobController.UpdateEmergencyAcceptedOrRejected(this.emergencyAcceptOrReject)
      .then(async (res: CheckPolicyLimitResponse | null) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to confirm answers!')
        } else {
          this.isConfirmationLoading = false
          this.snackbarText = 'Question answers confirmed.'
          this.snackbar = true
          if (this.isSIJob) {
            await this.checkEnablement()
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', err, false)
        this.isConfirmationLoading = false
      })
  }

  // criteria to confirm questions
  private get criteriaToConfirmQuestions(): boolean {
    let vulnerabilityQA = true
    let healthAndSafetyQA = true
    let mandatoryQA = true
    let mandatoryProductQA = true
    let invalidQA = true

    // All mandatory questions have been answered.
    this.localPolicyWideQAs.forEach((element: EmergencyQA) => {
      if (!element.isValid && element.validationStatus === 'missing') {
        mandatoryQA = false
        return
      }
    })

    // All mandatory questions have been answered.
    this.localProductQAs.forEach((element: ProductQA) => {
      if (!element.isValid && element.validationStatus === 'missing') {
        mandatoryProductQA = false
        return
      }
    })

    // Vulnerability Question has been answered.
    if (this.vulnerabilityQAItem.isHealthAffected === null) {
      vulnerabilityQA = false
    }

    // Health and safety question has been answered.
    this.healthAndSafetyQAItems.forEach((element: HealthAndSafetyQAModel) => {
      if (element.isHealthAffected === null) {
        healthAndSafetyQA = false
        return
      }
    })

    // There are no invalid responses to any questions.
    this.localPolicyWideQAs.forEach((element: EmergencyQA) => {
      if (!element.isValid && element.validationStatus === 'invalid') {
        invalidQA = false
        return
      }
    })

    // Check all criteria to accept emergency
    if (mandatoryQA && vulnerabilityQA && healthAndSafetyQA && invalidQA && mandatoryProductQA) {
      return true
    }
    return false
  }

  // check is enablement triggered or not
  private async checkEnablement() {
    if (this.job && this.job.enablementTriggeredAt === null) {
      const req = new ChangeSIEnablementModel()
      req.jobId = this.jobId
      req.enablementRequired = false
      req.completionNotes = Shared.enablementMessage

      try {
        await SiteInvestigationController.Enablement(req)
      } catch (error) {
        eventBus.$emit('errorHandler', error, false)
      }
    }
  }

  private get isSIJob() {
    return this.job && this.job.jobType === 'SI'
  }
}
</script>

<style scoped>
.questions-list-content {
  position: relative;
  padding-bottom: 50px;
}
</style>
