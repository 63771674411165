<template>
  <div>
    <v-layout>
      <v-flex v-if="getRequestData" xs12>
        <div class="mb-2">
          <v-layout>
            <v-flex xs5>
              <label>Contractor:</label>
              <span>{{ getRequestData.engineerRequests[0].contractor }}</span>
              &nbsp;
            </v-flex>
            <v-flex xs5>
              <label>Emergency Type:</label>
              <span>{{ emergencyTypeName }}</span>
            </v-flex>
          </v-layout>
        </div>
        <div class="mb-2">
          <v-layout>
            <v-flex xs5>
              <label>Engineer Name:</label>
              <span>{{ getRequestData.engineerRequests[0].engineer }}</span>
              &nbsp;
              <b class="primary--text phone-number">
                (
                <a href="Javascript:void(0)">
                  <v-icon small class="primary--text">call</v-icon>
                  {{ getRequestData.engineerRequests[0].engineerPhoneNumber }}
                </a>
                )
              </b>
            </v-flex>
            <v-flex xs5>
              <label>Emergency Details:</label>
              <span>{{ emergencyDetailName }}</span>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <v-layout>
            <v-flex xs5>
              <label>Customer Name:</label>
              <span>{{ getRequestData.engineerRequests[0].customer }}</span>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs4 text-xs-right>
              <div class="action-engineer">
                <div>
                  <span
                    v-if="
                      isUserSupervisor &&
                      getRequestData.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                    "
                    class="primary--text"
                  >
                    Assigned To:
                    {{ getAssignedUserName(getRequestData.assignedUserId) }}
                  </span>
                </div>
                <div v-if="isUserSupervisor" class="supervisor-assign text-xs-right">
                  <v-btn
                    v-if="getRequestData.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Pending]"
                    color="primary"
                    class="mr-0"
                    :disabled="activeIndex === index"
                    small
                    @click="openAssignJobDialog(getRequestData, index)"
                  >
                    Assign
                  </v-btn>
                  <v-btn
                    v-if="getRequestData.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                    color="primary"
                    class="mr-0"
                    :disabled="activeIndex === index"
                    small
                    @click="openAssignJobDialog(getRequestData, index)"
                  >
                    Reassign
                  </v-btn>
                  <v-btn
                    v-if="getRequestData.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                    color="primary"
                    class="mr-0"
                    small
                    @click="openCompleteJobDialog(getRequestData)"
                  >
                    Complete
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    v-if="getRequestData.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Pending]"
                    color="primary"
                    class="mr-0"
                    :disabled="isLoadingLocal && activeIndex === index"
                    :loading="isLoadingLocal && activeIndex === index"
                    small
                    @click="assignJob(getRequestData, index)"
                  >
                    Assign To Me
                  </v-btn>
                  <v-btn
                    v-else-if="
                      getRequestData.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                      isAssignedUserLoggedIn(getRequestData.assignedUserId)
                    "
                    color="primary"
                    class="mr-0"
                    small
                    @click="openCompleteJobDialog(getRequestData)"
                  >
                    Complete
                  </v-btn>
                  <span v-else class="primary--text">
                    Assigned To:
                    {{ getAssignedUserName(getRequestData.assignedUserId) }}
                  </span>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import { IMRequestProcessStatus } from '@/common/enums'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import UserModel from '@/models/user/UserModel'

@Component
export default class EngineerRequestDetailView extends Vue {
  @Prop() private requestData: EngineerRequestModel | undefined
  @Prop() private emergencyTypeName: string
  @Prop() private emergencyDetailName: string
  @Prop() private isLoading: boolean
  @Prop() private index: number
  @Prop() private usersList: UserModel[]
  private iMRequestProcessStatus = IMRequestProcessStatus
  private activeIndex = -1
  private isLoadingLocal = false

  private get getRequestData(): EngineerRequestModel | undefined {
    if (this.requestData) {
      return this.requestData
    } else {
      return undefined
    }
  }

  @Watch('isLoading')
  private localIsLoadingChange(newValue: boolean) {
    this.isLoadingLocal = newValue
    if (!this.isLoadingLocal) {
      this.activeIndex = -1
    }
  }

  private mounted() {
    this.isLoadingLocal = this.isLoading
  }

  private isAssignedUserLoggedIn(assignedUserId: string): boolean {
    const userId = Store.Instance.state.SessionDetail.detailRecord.UserDetail.id
    return userId === assignedUserId ? true : false
  }

  private assignJob(engineerRequest: EngineerRequestModel, index: number, userData: UserModel): void {
    this.activeIndex = this.index
    this.$emit('assignJob', engineerRequest, this.activeIndex, userData)
  }

  private openAssignJobDialog(engineerRequest: EngineerRequestModel, index: number) {
    this.$emit('openAssignJobDialog', engineerRequest, index)
  }

  private openCompleteJobDialog(engineerRequest: EngineerRequestModel): void {
    this.$emit('openCompleteJobDialog', engineerRequest)
  }

  private get getUsersList() {
    return storeGetters.getUsers()
  }

  private getAssignedUserName(assignedUserId: string): string {
    const match = this.getUsersList.find((e) => e.id === assignedUserId)
    if (match && match !== undefined) {
      return match.displayValue
    } else {
      return 'Unknown User'
    }
  }

  private get isUserSupervisor(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Finance' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager')
    )
  }

  private getDefaultResponsibleUserOption(): UserModel {
    const user = new UserModel()
    user.id = ''
    user.firstName = 'Unassign'
    user.lastName = ''
    return user
  }
}
</script>

<style scoped>
.request-details label {
  font-weight: 600;
}
.supervisor-assign {
  display: inline-flex;
}
.supervisor-assign >>> .v-btn {
  align-self: flex-end;
}
@media only screen and (max-width: 1370px) {
  .phone-number {
    display: block;
  }
}
</style>
