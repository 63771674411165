import TimeSlot from '@/models/claim/TimeSlot'
import { ContractorTradeStatus } from '@/common/enums'

export default class ContractorTradeAvailabilityModel {
  public id: string
  public tradeId: number
  public description: string
  public timeSlot: TimeSlot[]
  public contractorId: string
  public preferredContractor: ContractorTradeStatus
  public constructor() {
    if (!this.timeSlot) {
      this.timeSlot = []
    }
  }
}
