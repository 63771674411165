import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import moment, { Moment } from 'moment'
import { ComplaintContactPreference } from '@/models/types'
import { VulnerabilityLevelEnum } from '@/common/enums';

export default class ComplaintModel extends CosmosJobsDbRecordBase {
  public severity: string
  public responsiblePerson: string
  public responsiblePersonName: string
  public teamName: string
  public complaintType: string
  public concernsRaisedIds: string[]
  public concernsRaisedDescription: string[]
  public concernsRaisedComment: string
  public complaintTypeDescription: string
  public reasonForDelay: string
  public files: string[]
  public complaintSummary: string
  public agreedAction: string
  public resolutionSummary: string
  public resolutionCode: string[]
  public resolutionDescription: string[]
  public rootCause: string
  public rootCauseComment: string
  public rootCauseDescription: string
  public dateOfResolution: moment.Moment | null

  public initialResponseLetterDate: Moment | null
  public initialResponseLetterSent: Moment | null
  public fourWeekLetterDate: Moment | null
  public fourWeekLetterSent: Moment | null
  public eightWeekLetterDate: Moment | null
  public eightWeekLetterSent: Moment | null
  public summaryResolutionCommunicationDate: Moment | null
  public summaryResolutionCommunicationSent: Moment | null
  public finalResponseLetterDate: Moment | null
  public finalResponseLetterSent: Moment | null

  public loggedAt: moment.Moment
  public isResolved: boolean
  public loggedByUserId: string
  public loggedByUserName: string
  public email: string
  public mobilePhone: string
  public contactPreference: ComplaintContactPreference
  public escalationRequired: string
  public isNoticeSent: string
  public noticeSentAt: moment.Moment | null
  public isCompensationByCheque: boolean
  public insurerName: string
  public policyName: string
  public policyNumber: string
  public customerName: string
  public postCode: string
  public isVulnerableCustomer: boolean
  public assignedContractors: string
  public assignedTrades: string
  public resolvedByUserName: string
  public resolvedDate: string
  public justified: string
  public escalationDate: moment.Moment | null
  public isEscalated: boolean
  public escalationReasonId: string
  public escalationReason: string
  public escalationDescription: string
  public receivedDate: moment.Moment | null
  public reopenedDate: moment.Moment | null

  public paymentReasonId?: string
  public paymentReason?: string
  public accountHolderFirstName: string
  public accountHolderLastName: string
  public accountHolderAddress: string
  public accountNumber: string
  public IFSCCode: string
  public compensationPaid: number
  public lessonsLearned: string

  public vulnerabilityStatus?: VulnerabilityLevelEnum;

  public constructor() {
    super()
    if (!this.files) {
      this.files = []
    }
    if (!this.resolutionCode) {
      this.resolutionCode = []
    }
    if (!this.concernsRaisedIds) {
      this.concernsRaisedIds = []
    }
    this.receivedDate = null
    this.initialResponseLetterDate = null
    this.initialResponseLetterSent = null
    this.fourWeekLetterDate = null
    this.fourWeekLetterSent = null
    this.eightWeekLetterDate = null
    this.eightWeekLetterSent = null
    this.summaryResolutionCommunicationDate = null
    this.summaryResolutionCommunicationSent = null
    this.finalResponseLetterDate = null
    this.finalResponseLetterSent = null
  }
}
