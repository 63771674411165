<template>
  <div>
    <v-dialog
      v-if="vipCustomer && showVIPCustomerDialog"
      v-model="showVIPCustomerDialog"
      max-width="650"
      content-class="v-dialog--scrollable"
      persistent
    >
      <VIPCustomer
        :customer-item="vipCustomer"
        @OnCustomerSave="saveCustomer"
        @OnCustomerDialogClose="closeDialog"
      ></VIPCustomer>
    </v-dialog>
    <div>
      <v-card-title primary-title class="pa-0 mb-2">
        <h3 class="mb-0">VIP Customer List</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model.trim="search"
          append-icon="search"
          label="Search"
          single-line
          class="mt-0 pt-0"
          hide-details
        ></v-text-field>
        <span class="add-vip-cutomer-action">
          <v-tooltip bottom nudge-left="30px">
            <template #activator="{ on }">
              <v-btn icon class="mr-0" v-on="on" @click="showVIPCustomerDialog = true">
                <v-icon color="grey">add</v-icon>
              </v-btn>
            </template>
            <span>Add VIP Customer</span>
          </v-tooltip>
        </span>
      </v-card-title>
      <div>
        <v-data-table
          :headers="headers"
          :items="vipCustomerList"
          :loading="isLoading"
          :search="search"
          :pagination.sync="pagination"
          class="gridView"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.title }}</td>
            <td>{{ props.item.firstName }}</td>
            <td>{{ props.item.lastName }}</td>
            <td>{{ props.item.homePhone }}</td>
            <td>{{ props.item.mobilePhone }}</td>
            <td class="text-xs-right px-0">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="onEditClick(props.item)">
                    <v-icon color="primary">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0 ml-2" v-on="on" @click="onDeleteClick(props.item)">
                    <v-icon color="primary">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import VIPCustomerModel from '@/models/vipcustomer/VIPCustomerModel'
import VIPCustomerController from '@/api/vipCustomerController'
import VIPCustomer from '@/components/VIPCustomer.vue'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'

@Component({
  components: { VIPCustomer },
})
export default class VIPCustomerList extends Vue {
  private showVIPCustomerDialog = false
  private headers: any = []
  private vipCustomer: VIPCustomerModel | null = new VIPCustomerModel()
  private vipCustomerList: VIPCustomerModel[] = []
  private isLoading = false
  private search = ''
  private pagination: any = {}
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''

  private created() {
    this.headers = [
      { text: 'Title', value: 'title' },
      { text: 'First Name', value: 'firstName' },
      { text: 'Last Name', value: 'lastName' },
      { text: 'Home Number', value: 'homePhone' },
      { text: 'Mobile Number', value: 'mobilePhone' },
      { text: '', sortable: false },
    ]
    this.pagination.rowsPerPage = 10
  }

  private mounted() {
    this.getVIPCustomerList()
  }

  private getVIPCustomerList() {
    const self = this
    self.isLoading = true
    VIPCustomerController.GetVIPCustomers().then((res: VIPCustomerModel[]) => {
      self.vipCustomerList = res
      self.isLoading = false
    })
  }

  private onEditClick(vipCustomer: VIPCustomerModel) {
    this.vipCustomer = vipCustomer
    this.showVIPCustomerDialog = true
  }

  private closeDialog(vipCustomer: VIPCustomerModel) {
    this.showVIPCustomerDialog = false
    this.vipCustomer = new VIPCustomerModel()
  }

  private saveCustomer(vipCustomer: VIPCustomerModel) {
    this.showVIPCustomerDialog = false
    this.snackbarText = 'VIP Customer Detail Saved Successfully.'
    this.snackbar = true
    const index: number = this.vipCustomerList.findIndex((c) => c.id === vipCustomer.id)
    if (index !== -1) {
      this.vipCustomerList[index].title = vipCustomer.title
      this.vipCustomerList[index].firstName = vipCustomer.firstName
      this.vipCustomerList[index].lastName = vipCustomer.lastName
      this.vipCustomerList[index].homePhone = vipCustomer.homePhone
      this.vipCustomerList[index].mobilePhone = vipCustomer.mobilePhone
    } else {
      this.vipCustomerList.push(vipCustomer)
    }
    this.vipCustomer = new VIPCustomerModel()
  }

  private onDeleteClick(vipCustomer: VIPCustomerModel) {
    Shared.confirmationPopup.open(
      'Do you really want to delete VIP Customer?',
      '',
      '',
      '',
      'Delete',
      this,
      'DeleteVIPCustomer',
      vipCustomer
    )
  }

  private DeleteVIPCustomer(vipCustomer: VIPCustomerModel) {
    const self = this
    self.isLoading = true
    if (vipCustomer.id) {
      VIPCustomerController.DeleteVIPCustomer(vipCustomer.id)
        .then((res: boolean) => {
          if (res) {
            self.vipCustomerList.splice(self.vipCustomerList.indexOf(vipCustomer), 1)
            self.vipCustomerList = [...self.vipCustomerList]
            self.snackbarText = 'VIP Customer is deleted'
            self.snackbar = true
          }
          self.isLoading = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error submitting delete VIP customer request, please try again', true)
          self.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.add-vip-cutomer-action {
  position: relative !important;
  top: -4px;
}
</style>
