import { Component, Vue, Prop } from 'vue-property-decorator'
import storeGetters from '@/storeGetters'
import Job from '@/models/Job'
import Emergency from '@/models/Emergency'
import Shared from '@/common/shared'

@Component
export default class TimeLineItemBase extends Vue {
  @Prop() public jobId: string
  @Prop() public emergencyId: string
  @Prop() public itemId: string
  @Prop() public cardIndex: number

  protected sanitizeHTML = Shared.sanitizeHTML

  public get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  public get emergency(): Emergency | null {
    if (this.emergencyId && this.job && this.job.emergencies) {
      const item = this.job.emergencies.find((e) => e.id === this.emergencyId)
      if (item) {
        return item
      }
    }
    return null
  }
}
