export default class AssistedPaymentDetails {
  public paymentToken: string
  public brand: string
  public last4: string
  public isPaymentComplete: boolean

  public constructor(paymentComplete: boolean) {
    this.isPaymentComplete = paymentComplete
  }

  public get active(): boolean {
    return !this.isPaymentComplete
  }

  public get paymentDetailErrors(): {
    value: boolean
    message: string
  }[] {
    return [
      {
        value: Boolean(this.paymentToken),
        message: 'Payment token is missing',
      },
      {
        value: Boolean(this.brand),
        message: 'Payment brand is missing',
      },
      {
        value: this.last4.length === 4,
        message: 'Last 4 card digits are not set',
      },
    ].filter((x) => !x.value)
  }

  public reset() {
    this.paymentToken = ''
    this.brand = ''
    this.last4 = ''
  }
}
