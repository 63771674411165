<template>
  <v-layout wrap class="grey lighten-3 pt-2 px-3 pb-2">
    <v-flex xs12 my-1>
      <h4>Assign Emergency based on Answer</h4>
    </v-flex>
    <v-flex xs12 class="trade-content">
      <v-layout wrap grey lighten-4 px-3 mb-3 class="section-repeat">
        <v-flex xs6 pr-2>
          <v-select
            v-model="getEmergencyDetail.operatorId"
            v-validate="{
              rules: {
                required: getEmergencyDetail.conditionalEmergencyTypeId ? true : false,
              },
            }"
            :items="getOperatorsByAnswerType"
            label="Operator"
            item-text="name"
            item-value="id"
            :required="getEmergencyDetail.conditionalEmergencyTypeId"
            :class="getEmergencyDetail.conditionalEmergencyTypeId ? 'required' : ''"
            :data-vv-name="'OperatorId'"
            :error-messages="errors.collect('OperatorId')"
            class="operator-type"
          ></v-select>
        </v-flex>
        <v-flex xs6 pl-2>
          <v-text-field
            v-if="
              getEmergencyQuestionModel.answerTypeId === answerType.Text ||
              getEmergencyQuestionModel.answerTypeId === answerType.Number
            "
            v-model.trim="getEmergencyDetail.comparisonValue"
            v-validate="{
              rules: {
                required: getEmergencyDetail.conditionalEmergencyTypeId ? true : false,
                max: 200,
              },
            }"
            label="Comparison Value"
            maxlength="200"
            :data-vv-name="'ComparisonValue'"
            :error-messages="errors.collect('ComparisonValue')"
            :required="getEmergencyDetail.conditionalEmergencyTypeId"
            :class="getEmergencyDetail.conditionalEmergencyTypeId ? 'required' : ''"
          ></v-text-field>
          <v-select
            v-if="getEmergencyQuestionModel.answerTypeId === answerType.Dropdown"
            v-model.trim="getEmergencyDetail.comparisonValue"
            v-validate="{
              rules: {
                required: getEmergencyDetail.conditionalEmergencyTypeId ? true : false,
                max: 200,
              },
            }"
            :items="getdropdownOptions(getEmergencyQuestionModel.additionalValues)"
            label="Comparison Value"
            maxlength="200"
            :data-vv-name="'ComparisonValue'"
            :error-messages="errors.collect('ComparisonValue')"
            :required="getEmergencyDetail.conditionalEmergencyTypeId"
            :class="getEmergencyDetail.conditionalEmergencyTypeId ? 'required' : ''"
          ></v-select>
          <v-select
            v-if="getEmergencyQuestionModel.answerTypeId === answerType.Checkbox"
            v-model.trim="getEmergencyDetail.comparisonValue"
            v-validate="{
              rules: {
                required: getEmergencyDetail.conditionalEmergencyTypeId ? true : false,
                max: 200,
              },
            }"
            :items="getcheckboxOptions"
            label="Comparison Value"
            maxlength="200"
            :data-vv-name="'ComparisonValue'"
            :error-messages="errors.collect('ComparisonValue')"
            :required="getEmergencyDetail.conditionalEmergencyTypeId"
            :class="getEmergencyDetail.conditionalEmergencyTypeId ? 'required' : ''"
          ></v-select>
        </v-flex>
        <v-flex xs6 pr-2>
          <v-autocomplete
            v-model="getEmergencyDetail.conditionalEmergencyTypeId"
            v-validate="{
              rules: {
                required: getEmergencyDetail.conditionalEmergencyTypeId ? true : false,
              },
            }"
            :items="emergencyTypeList"
            item-value="emergencyId"
            item-text="description"
            label="Emergency Type"
            :required="getEmergencyDetail.conditionalEmergencyTypeId"
            :class="getEmergencyDetail.conditionalEmergencyTypeId ? 'required' : ''"
            data-vv-name="EmergencyType"
            :error-messages="errors.collect('EmergencyType')"
            class="emergency-type"
            @change="onEmergencyTypeChange()"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs6 pl-2>
          <v-autocomplete
            v-model="getEmergencyDetail.conditionalEmergencyDetailId"
            v-validate="{
              rules: {
                required: getEmergencyDetail.conditionalEmergencyTypeId ? true : false,
              },
            }"
            :items="emergencyDetailsFiltered"
            item-value="emergencyDetailId"
            item-text="description"
            label="Emergency Detail"
            :required="getEmergencyDetail.conditionalEmergencyTypeId"
            :class="getEmergencyDetail.conditionalEmergencyTypeId ? 'required' : ''"
            data-vv-name="EmergencyDetail"
            :error-messages="errors.collect('EmergencyDetail')"
            :disabled="!getEmergencyDetail.conditionalEmergencyTypeId || getEmergencyDetailLoading"
            class="emergency-detail"
            :loading="getEmergencyDetailLoading"
            @change="onEmergencyDetailChange($event)"
          ></v-autocomplete>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import { Operator } from '@/common/interfaces'
import { OperatorType, AnswerType } from '@/common/enums'
import Shared from '@/common/shared'
import storeGetters from '@/storeGetters'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import PolicyHolderController from '@/api/policyHolderController'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import storeMutations from '@/storeMutations'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'

@Component
export default class AddEmergencyAgainstAnswer extends Vue {
  @Prop() private getEmergencyQuestionModel: EmergencyQuestionModel
  @Prop() private thirdPartyDetail: EmergencyThirdPartyTrade[]

  private answerType = AnswerType
  private label = ''
  private checkboxOptions: string[] = ['Yes', 'No']
  private operators: Operator[]
  private emergencyDetails: EmergencyDetailModel[] = []
  private emergencyTypeList: EmergencyModel[] = []
  private getEmergencyDetailLoading = false

  public async validate(): Promise<boolean> {
    let result = true
    if (this.getEmergencyDetail!.conditionalEmergencyTypeId) {
      result = await this.$validator.validateAll()
    }

    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      return result
    }
    return result
  }

  private get getThirdPartyEmergencyId() {
    return this.thirdPartyDetail && this.thirdPartyDetail.length > 0
      ? this.thirdPartyDetail.map((e) => e.emergencyTypeId)
      : []
  }

  private created(): void {
    this.operators = Shared.getOperatorList()
    this.getEmergencyDetails()
    if (this.emergencyTypes.length === 0) {
      this.setEmergencies()
    } else {
      this.emergencyTypeList =
        this.getThirdPartyEmergencyId.length > 0
          ? this.emergencyTypes.filter((e) => !this.getThirdPartyEmergencyId.includes(e.emergencyId))
          : this.emergencyTypes
    }
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        this.emergencyTypeList =
          this.getThirdPartyEmergencyId.length > 0
            ? this.emergencyTypes.filter((e) => !this.getThirdPartyEmergencyId.includes(e.emergencyId))
            : this.emergencyTypes
        storeMutations.setEmergencies(res)
      }
    })
  }

  private getEmergencyDetails() {
    this.getEmergencyDetailLoading = true
    PolicyHolderController.GetEmergencyDetails().then((res: EmergencyDetailModel[]) => {
      this.emergencyDetails = res
      this.getEmergencyDetailLoading = false
    })
  }

  private get getcheckboxOptions(): string[] {
    return this.checkboxOptions
  }

  private get getEmergencyDetail() {
    return this.getEmergencyQuestionModel
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies()
  }

  private get emergencyDetailsFiltered(): EmergencyDetailModel[] {
    if (!this.getEmergencyDetail!.conditionalEmergencyTypeId) {
      return this.emergencyDetails
    }

    const emergencyDetails = this.emergencyDetails.filter(
      (e) => e.typeId === this.getEmergencyDetail!.conditionalEmergencyTypeId
    )

    if (emergencyDetails && emergencyDetails.length === 1) {
      // auto-select emergency if has only one record
      this.getEmergencyDetail!.conditionalEmergencyDetailId = emergencyDetails[0].emergencyDetailId
    }

    return emergencyDetails
  }

  private get getOperatorsByAnswerType(): Operator[] {
    let operators: Operator[] = this.operators
    switch (this.getEmergencyQuestionModel.answerTypeId) {
      case AnswerType.Text:
        // get 'equalto', 'not equal to', 'in' and 'not in' operators only
        operators = operators.filter(
          (c) =>
            c.id === OperatorType.EqualTo ||
            c.id === OperatorType.NotEqualTo ||
            c.id === OperatorType.In ||
            c.id === OperatorType.NotIn
        )
        return operators
      case AnswerType.Dropdown:
        // get 'equalto', 'not equal to', 'in' and 'not in' operators only
        operators = operators.filter(
          (c) =>
            c.id === OperatorType.EqualTo ||
            c.id === OperatorType.NotEqualTo ||
            c.id === OperatorType.In ||
            c.id === OperatorType.NotIn
        )
        return operators
      case AnswerType.Number:
        // get all operators
        return operators
      case AnswerType.Checkbox:
        // get 'equalto'and 'not equal to' only
        operators = operators.filter((c) => c.id === OperatorType.EqualTo || c.id === OperatorType.NotEqualTo)
        return operators
      default:
        // set to default all operators
        return operators
    }
  }

  private getdropdownOptions(additionalValues: string | null): string[] {
    if (additionalValues) {
      const items = additionalValues.split('\n')
      if (items && items.length > 0) {
        for (let i = 0, l = items.length; i < l; i++) {
          items[i] = items[i].trim()
        }
        return items
      }
    }
    return []
  }

  private onEmergencyTypeChange() {
    this.getEmergencyDetail!.conditionalEmergencyDetailId = null
  }

  private onEmergencyDetailChange(detailId: number) {
    this.getEmergencyDetail!.conditionalEmergencyDetailId = detailId
  }
}
</script>
