<template>
  <div v-if="item">
    <div class="mb-2"><h4>Update CCTV Control Run</h4></div>
    <div v-if="engineerName" class="mb-1">
      <label>Engineer: </label>
      <span>{{ engineerName }}</span>
    </div>
    <div v-if="item.from" class="mb-1">
      <label>From: </label>
      <span>{{ item.from }}</span>
    </div>
    <div v-if="item.to" class="mb-1">
      <label>To: </label>
      <span>{{ item.to }}</span>
    </div>
    <div v-if="item.pipeMaterial" class="mb-1">
      <label>Pipe Material: </label>
      <span>{{ item.pipeMaterial }}</span>
    </div>
    <div v-if="item.pipeDiameter" class="mb-1">
      <label>Pipe Diameter: </label>
      <span>{{ item.pipeDiameter }}</span>
    </div>
    <div v-if="item.direction" class="mb-1">
      <label>Direction: </label>
      <span>{{ item.direction }}</span>
    </div>
    <div v-if="item.function" class="mb-1">
      <label>Function: </label>
      <span>{{ item.function }}</span>
    </div>
    <div v-if="item.invertLevelOne" class="mb-1">
      <label>Invert Level: </label>
      <span>{{ item.invertLevelOne }}</span>
    </div>
    <div v-if="item.invertLevelTwo" class="mb-1">
      <label>Invert Level: </label>
      <span>{{ item.invertLevelTwo }}</span>
    </div>
    <div v-if="item.sharedRun" class="mb-1">
      <label>Shared Run: </label>
      <span>{{ item.sharedRun }}</span>
    </div>
    <div v-if="item.gullyCondition" class="mb-1">
      <label>Gully Condition: </label>
      <span>{{ item.gullyCondition }}</span>
    </div>
    <div v-if="item.comments" class="mb-1">
      <label>Comments: </label>
      <span>{{ item.comments }}</span>
    </div>
    <v-divider class="mb-1"></v-divider>
    <div v-if="item.distances.length > 0">
      <div v-for="(distanceItem, index) in item.distances" :key="index">
        <div v-if="distanceItem.distance" class="mb-1">
          <label>Distance (m): </label>
          <span>{{ distanceItem.distance }}</span>
        </div>
        <div v-if="distanceItem.code" class="mb-1">
          <label>Code: </label>
          <span>{{ distanceItem.code }}</span>
        </div>
        <div v-if="distanceItem.clockRefAt" class="mb-1">
          <label>Clock Ref At: </label>
          <span>{{ distanceItem.clockRefAt }}</span>
        </div>
        <div v-if="distanceItem.clockRefTo" class="mb-1">
          <label>Clock Ref To: </label>
          <span>{{ distanceItem.clockRefTo }}</span>
        </div>
        <div v-if="distanceItem.intrusionInPercentage" class="mb-1">
          <label>Intrusion(%): </label>
          <span>{{ distanceItem.intrusionInPercentage }}</span>
        </div>
        <div v-if="distanceItem.intrusionInMM" class="mb-1">
          <label>Intrusion(MM): </label>
          <span>{{ distanceItem.intrusionInMM }}</span>
        </div>
        <div v-if="distanceItem.remarks" class="mb-1">
          <label>Remarks: </label>
          <span>{{ distanceItem.remarks }}</span>
        </div>
        <v-divider v-if="index !== item.distances.length - 1" class="mb-1"></v-divider>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import CCTVControlRunLogModel from '@/models/siteInvestigation/CCTVControlRunLogModel'

@Component
export default class UpdateCCTVControlRunLog extends Vue {
  @Prop() private requestBody: any

  private get item(): CCTVControlRunLogModel {
    return Object.assign(new CCTVControlRunLogModel(), this.requestBody)
  }

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.requestBody.id)
  }
}
</script>
