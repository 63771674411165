import moment from 'moment'

export default class AutoDeployRequest {
  public jobId: string
  public emergencyId: string
  public contractorIds: string[]
  public autoDeploymentStartedAt: moment.Moment | null

  public constructor() {
    this.autoDeploymentStartedAt = moment()
    this.contractorIds = []
  }
}
