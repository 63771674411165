import Product from './Product'
import Pit from './Pit'
import BulkPit from './BulkPit'
import SitePackage from './SitePackage'
import PitTest from './PitTest'
import { SIProductType } from '@/common/enums'
import DehydratedJobPackage from './DehydratedJobPackage'
import MonitoringProduct from './MonitoringProduct'

export default class JobPackageState {
  // Job Data
  public selectedPackage: SitePackage | null
  public selectedPits: Array<{ value: Pit }> = []
  public selectedBulkPits: BulkPit[] = []
  public selectedExtraPits: Array<{ value: Pit }> = []
  public selectedMonitoringProducts: MonitoringProduct[] = []
  public selectedOther: Product[] = []
  public extraProductNotes: string
  public jobNotes: string
  public packageIncludedTests: Product[] = []

  public constructor(model?: DehydratedJobPackage) {
    if (model) {
      // let model2 = {...model};
      this.selectedPackage = model.packageInfo
        ? Object.assign(new SitePackage(), {
            id: model.packageInfo.packageId,
            name: model.packageInfo.name,
            description: model.packageInfo.description,
            price: model.packageInfo.price,
          })
        : null
      this.selectedPits = model.packageInfo
        ? model.packageInfo.productList
            .filter((prod) => prod.productTypeId === SIProductType.Pit)
            .map((pit) => ({ value: pit as Pit }))
        : []

      if (model.extraProducts && model.extraProducts.length > 0) {
        this.selectedExtraPits = model.extraProducts
          .filter((prod) => prod.productTypeId === SIProductType.Pit)
          .map((pit) => ({ value: pit as Pit }))
        this.selectedOther = model.extraProducts
          .filter(
            (prod) =>
              prod.productTypeId === SIProductType.Other ||
              prod.productTypeId === SIProductType.CCTV ||
              prod.productTypeId === SIProductType.Datum ||
              prod.productTypeId === SIProductType.WaterMains ||
              prod.productTypeId === SIProductType.TestingOnly
          )
          .map((prod) => prod as Product)
      }
      if (model.bulkPitProducts && model.bulkPitProducts.length > 0) {
        this.selectedBulkPits = model.bulkPitProducts
          .filter((prod) => prod.productTypeId === SIProductType.BulkPit)
          .map((prod) => {
            const x = prod as any
            x.pits = x.pits.map((pit: any) => {
              if (pit.value) {
                return pit
              } else {
                return { value: pit }
              }
            })
            return x
          })
      }
      if (model.monitoringProducts && model.monitoringProducts.length > 0) {
        this.selectedMonitoringProducts = model.monitoringProducts.map((product) =>
          Object.assign(new MonitoringProduct(), product)
        )
      }
      this.extraProductNotes = model.extraProductNotes
      this.jobNotes = model.jobNotes
    }
  }

  public get dehydratedModel(): DehydratedJobPackage {
    return Object.assign(new DehydratedJobPackage(), {
      packageInfo: this.selectedPackage
        ? {
            packageId: this.selectedPackage.id,
            productList: this.selectedPits.map((pit) => this.compressPit(pit.value)),
            price: this.selectedPackage.price,
          }
        : undefined,
      extraProducts: [
        ...this.selectedExtraPits.map((pit) => this.compressPit(pit.value)),
        ...this.selectedOther.map((product) => this.compressProduct(product)),
      ],
      bulkPitProducts: [...this.selectedBulkPits.map((bp) => this.compressBulkPitProduct(bp))],
      monitoringProducts: this.selectedMonitoringProducts,
      extraProductNotes: this.extraProductNotes,
      jobNotes: this.jobNotes,
      totalPrice: this.totalPrice,
    })
  }

  private compressProduct(product: Product) {
    return {
      id: product.id,
      quantity: product.quantity,
      productTypeId: product.productTypeId,
      basePrice: product.basePrice,
    }
  }

  private compressBulkPitProduct(product: BulkPit) {
    const compressedPits: any = []
    for (const pit of product.pits) {
      const compressedPit = this.compressPit(pit.value)
      compressedPits.push(compressedPit)
    }
    return {
      id: product.id,
      quantity: product.quantity,
      productTypeId: product.productTypeId,
      pits: compressedPits,
      basePrice: product.basePrice,
    }
  }

  private compressTest(test: PitTest) {
    return {
      ...this.compressProduct(test),
      notes: test.notes,
    }
  }

  private compressPit(pit: Pit) {
    return {
      ...this.compressProduct(pit),
      pitId: pit.pitId,
      boreDepth: pit.boreDepth,
      pitTests: pit.pitTests.map((test) => this.compressTest(test)),
      basePrice: pit.basePrice,
    }
  }

  public get totalPrice(): number {
    let total = this.selectedPackage ? this.selectedPackage.price : 0
    total = this.selectedPits.reduce((sum, pit) => sum + this.pitPrice(pit.value, true), total)
    total = this.selectedExtraPits.reduce((sum, pit) => sum + this.pitPrice(pit.value), total)
    total = this.selectedMonitoringProducts.reduce((sum, product) => sum + this.monitoringProductPrice(product), total)
    total = this.selectedOther.reduce(
      (sum, product) => sum + (product.quantity && product.quantity > 0 ? product.basePrice * product.quantity : 0),
      total
    )
    // get total price for BulkPit product
    total = this.selectedBulkPits.reduce((sum, product) => sum + product.basePrice, total)
    const additionalPits: Array<{ value: Pit }> = []
    this.selectedBulkPits.map((bulkPit) => bulkPit.pits.map((pit) => additionalPits.push(pit)))
    total = additionalPits.reduce((sum, pit) => sum + this.pitPrice(pit.value, true), total)
    return total
  }

  public get configurationIssues(): string | null {
    if (
      !this.selectedPackage &&
      this.selectedExtraPits.length === 0 &&
      this.selectedBulkPits.length === 0 &&
      this.selectedOther.length === 0 &&
      this.selectedMonitoringProducts.length === 0
    ) {
      return 'You must have at least one product added for this job.'
    }
    return null
  }

  private pitPrice(pit: Pit, packageIncludedPit = false): number {
    const total = packageIncludedPit ? 0 : pit.basePrice
    return pit.pitTests.reduce((sum, test) => {
      const add =
        packageIncludedPit && this.testIncluded(pit, test)
          ? 0
          : test.quantity && test.quantity > 0
          ? test.basePrice * test.quantity
          : 0
      return sum + add
    }, total)
  }

  private monitoringProductPrice(product: MonitoringProduct): number {
    const total = product.basePrice
    return product.selectedChildProducts.reduce((sum, child) => {
      return sum + child.quantity * child.basePrice
    }, total)
  }

  private testIncluded(pit: Pit, test: PitTest): boolean {
    return this.packageIncludedTests.some((t) => t.id === test.id)
  }
}
