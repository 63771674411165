<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Insurer</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.native="closeInsurerDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text class="scroll-content-dialog">
      <div class="configure-dropdown-details">
        <v-progress-circular
          v-show="!isLoaded"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
          :style="getLoaderStyle(70)"
        ></v-progress-circular>
        <v-layout v-show="isLoaded && items">
          <v-flex xs12 class="gridView tbody-scroll-table">
            <v-data-table :headers="headers" :items="items" item-key="id" :loading="isLoading" hide-actions>
              <template slot="items" slot-scope="props">
                <tr ref="datatable">
                  <td class="text-xs-left name-col">
                    <v-text-field
                      v-if="isUpdateRecord && index === props.item.id"
                      v-model="props.item.name"
                      v-validate="'required'"
                      class="pt-2"
                      data-vv-scope="updateRecordFormRef"
                      data-vv-name="editName"
                      :error-messages="validationMessage('editName')"
                    ></v-text-field>
                    <span v-else>{{ props.item.name }}</span>
                  </td>
                  <td class="text-xs-left">
                    <v-text-field
                      v-if="isUpdateRecord && index === props.item.id"
                      v-model="props.item.alternativeName"
                      v-validate="'required'"
                      class="pt-2"
                      data-vv-scope="updateRecordFormRef"
                      data-vv-name="editAlternativeName"
                      :error-messages="validationMessage('editAlternativeName')"
                    ></v-text-field>
                    <span v-else>{{ props.item.alternativeName }}</span>
                  </td>
                  <td class="text-xs-center aling-center-chekbox">
                    <span v-if="isUpdateRecord && index === props.item.id">
                      <v-checkbox v-model="props.item.passToThirdParty" color="primary"></v-checkbox>
                    </span>
                    <v-tooltip v-else-if="props.item.passToThirdParty" top>
                      <template #activator="{ on }">
                        <v-icon color="secondary" v-on="on">group_add</v-icon>
                      </template>
                      <span>Pass to third party</span>
                    </v-tooltip>
                    <span v-else color="secondary">-</span>
                  </td>
                  <td class="text-xs-right action-col">
                    <v-flex v-if="isUpdateRecord && index === props.item.id">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            flat
                            color="primary"
                            small
                            v-on="on"
                            @click.native="onAddUpdateRecord(props.item)"
                          >
                            <v-icon>update</v-icon>
                          </v-btn>
                        </template>
                        <span>Update</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            flat
                            color="primary"
                            small
                            v-on="on"
                            @click.native="onCancelEditRecord(props.item)"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </template>
                        <span>Cancel</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex v-else class="">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn icon flat color="primary" small v-on="on" @click.native="onEditRecord(props.item)">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            flat
                            color="primary"
                            small
                            v-on="on"
                            @click.native="onDeleteRecordClick(props.item)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-flex>
                  </td>
                </tr>
              </template>
              <template slot="footer">
                <tr>
                  <td class="name-col">
                    <v-text-field
                      v-model="record.name"
                      v-validate="'required'"
                      label="Name"
                      required
                      class="required"
                      data-vv-scope="addRecordFormRef"
                      data-vv-name="Name"
                      :error-messages="errors.collect('Name')"
                      @focus="onAddRecordFocus($event)"
                    ></v-text-field>
                  </td>
                  <td class="pl-0">
                    <v-text-field
                      v-model="record.alternativeName"
                      v-validate="'required'"
                      label="Alternative Name"
                      required
                      class="required"
                      data-vv-scope="addRecordFormRef"
                      data-vv-name="AlternativeName"
                      :error-messages="errors.collect('AlternativeName')"
                      @focus="onAddRecordFocus($event)"
                    ></v-text-field>
                  </td>
                  <td class="pr-0 text-xs-center aling-center-chekbox">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-checkbox v-model="record.passToThirdParty" color="primary" v-on="on"></v-checkbox>
                      </template>
                      <span>Pass to third party</span>
                    </v-tooltip>
                  </td>
                  <td class="text-xs-right action-col">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn icon flat color="primary" small v-on="on" @click="onAddUpdateRecord(null)">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Add</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import InsurerModel from '@/models/policy/InsurerModel'
import Shared from '@/common/shared'
import InsurerController from '@/api/InsurerController'
import eventBus from '@/common/bus'
@Component
export default class Insurer extends Vue {
  private headers: any = []
  private items: InsurerModel[] = []
  private index: any = null
  private isUpdateRecord = false
  private isLoading = false
  private previousValues: any[] = [] // set default values of name and alternativename of Editing Record
  private record: InsurerModel = new InsurerModel()
  private isLoaded = false

  private created() {
    this.GetInsurerList()
    this.headers = [
      {
        text: 'Name',
        value: 'name',
        sortable: false,
        align: 'left',
        class: 'name-col',
      },
      {
        text: 'AlternativeName',
        value: 'alternativeName',
        sortable: false,
        align: 'left',
        class: 'name-col',
      },
      {
        text: 'Third party',
        value: 'passToThirdParty',
        sortable: false,
        class: '',
      },
      {
        text: '',
        sortable: false,
        value: 'action',
        align: 'right',
        class: 'action-col',
      },
    ]
  }

  private GetInsurerList() {
    this.isLoading = true
    InsurerController.GetInsurerList().then((res: InsurerModel[]) => {
      this.items = res.filter((i) => !i.isDelete)
      this.isLoading = false
      this.isLoaded = true
    })
  }

  private onAddRecordFocus(item: any) {
    if (this.isUpdateRecord && this.items) {
      this.isUpdateRecord = false
      this.setRecordToDefault()
    }
  }

  private onAddUpdateRecord(record: any) {
    this.$validator.errors.items = []
    if (!this.isLoading) {
      if (record === null && this.isUpdateRecord) {
        this.isUpdateRecord = false
        this.setRecordToDefault()
      }
      const self = this
      this.validate(record === null ? 'addRecordFormRef' : 'updateRecordFormRef')
        .then((result: boolean) => {
          if (result) {
            this.isLoading = true
            const item = record !== null ? record : self.record
            InsurerController.SaveInsurer(item)
              .then((res: InsurerModel) => {
                if (res) {
                  if (this.isUpdateRecord && this.items) {
                    const index = this.items.findIndex((e) => e.id === record.id)
                    if (index !== -1) {
                      this.items[index].name = item.name
                      this.items[index].alternativeName = item.alternativeName
                      this.items[index].passToThirdParty = item.passToThirdParty
                      this.items = [...this.items]
                    }
                    this.isUpdateRecord = false
                    this.previousValues = []
                    this.previousValues.push(item.name, item.alternativeName, item.passToThirdParty)
                  } else {
                    if (this.items) {
                      this.items.push(res)
                    }
                    self.record = new InsurerModel()
                    // set scrollTop for tbody
                    setTimeout(() => {
                      const dataTableBody: any = this.$el.querySelector('.v-datatable tbody')
                      dataTableBody.scrollTop = dataTableBody.scrollHeight - dataTableBody.offsetHeight
                    }, 0)
                  }
                  this.isLoading = false
                } else {
                  this.isLoading = false
                }
              })
              .catch((err: any) => {
                eventBus.$emit('errorHandler', 'Error saving insurer detail, please try again', true)
                this.isLoading = false
                self.record = new InsurerModel()
              })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }
  }

  private onCancelEditRecord(item: any) {
    item.name = this.previousValues[0]
    item.alternativeName = this.previousValues[1]
    item.passToThirdParty = this.previousValues[2]
    this.$validator.errors.items = []
    this.isUpdateRecord = false
  }

  private onEditRecord(item: any) {
    this.setRecordToDefault()
    this.$validator.errors.items = []
    this.record.name = this.record.alternativeName = ''
    this.record.passToThirdParty = false
    this.isUpdateRecord = true
    this.index = item.id
    this.previousValues = []
    this.previousValues.push(item.name, item.alternativeName, item.passToThirdParty)
  }

  private onDeleteRecordClick(item: any) {
    this.setRecordToDefault()
    this.$validator.errors.items = []
    this.isUpdateRecord = false
    this.record.name = this.record.alternativeName = ''
    this.record.passToThirdParty = false
    Shared.confirmationPopup.open(
      'Do you really want to delete Insurer?',
      '',
      '',
      '',
      '',
      this,
      'DeleteRecord',
      item.id
    )
  }

  private DeleteRecord(id: number) {
    if (!this.isLoading) {
      this.isLoading = true
      InsurerController.DeleteInsurer(id)
        .then((res: boolean) => {
          if (res) {
            this.isLoading = false
            this.items = this.items.filter((e) => e.id !== id)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error deleting insurer detail, please try again', true)
          this.isLoading = false
        })
    }
  }

  private closeInsurerDialog() {
    this.setRecordToDefault()
    this.$validator.errors.items = []
    this.isUpdateRecord = false
    this.record.name = this.record.alternativeName = ''
    this.record.passToThirdParty = false
    this.$emit('CloseInsurerDialog', this.items)
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message === 'The editDescription field is required.'
      ? (message = 'The Description field is required.')
      : message
  }

  private setRecordToDefault() {
    if (this.items) {
      const index = this.items.findIndex((e) => e.id === this.index)
      if (index !== -1) {
        this.items[index].name = this.previousValues[0]
        this.items[index].alternativeName = this.previousValues[1]
        this.items[index].passToThirdParty = this.previousValues[2]
        this.items = [...this.items]
      }
    }
  }

  private async validate(formref: any): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(formref)
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style scoped>
.gridView >>> .action-col {
  min-width: 100px;
  width: 20%;
}
.gridView >>> table > tfoot td:nth-last-child(2) {
  width: 15%;
}
.gridView >>> .v-table > thead th:nth-last-child(2) {
  width: 15%;
}
.gridView >>> .v-table > thead th:first-child,
.gridView >>> .v-table > tbody > tr > td:first-child {
  width: 35%;
}
.gridView >>> .v-table > thead th:nth-child(2),
.gridView >>> .v-table > tbody > tr > td:nth-child(2) {
  width: 35%;
}
.configure-dropdown-details {
  min-height: 300px;
}
.tbody-scroll-table >>> table {
  width: 100%;
}
.tbody-scroll-table >>> tbody {
  display: block;
  max-height: 500px;
  overflow-y: scroll;
  min-height: 200px;
}
.tbody-scroll-table >>> tbody tr,
.tbody-scroll-table >>> thead,
.tbody-scroll-table >>> tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.gridView >>> th {
  padding: 0 10px 0 10px !important;
}
.tbody-scroll-table >>> tbody::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.tbody-scroll-table >>> tbody::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 3px;
}
</style>
