import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { Worker } from '@/components/dashboard/worker'
import { InsurerStats, ContractorStats } from '@/signalr/twilioEventModels/dashboardState'
import { WorkerReservationEvents } from '@/signalr/twilioEventModels/workerEvent'
import { InsurerAnnouncingBreakdown } from '@/signalr/twilioEventModels/insurerAnnouncingUpdate'
import { TopLevelCallStats, DailyCallStats } from '@/signalr/twilioEventModels/events'
import ComplaintTrackerModel from '@/models/claim/ComplaintTrackerModel'
import JobDashboardModel from '@/models/claim/JobDashboardModel'
import UnlinkedPhoneCallsModel from '@/models/claim/UnlinkedPhoneCallsModel'
import JobHeader from '@/models/JobHeader'
import CETWayNoteModel from '@/models/claim/CETWayNoteModel'
import IncidentManagerDashboardDataModel from '@/models/claim/IncidentManagerDashboardDataModel'
import NetworkDashboardModel from '@/models/claim/NetworkDashboardModel'
import { ManagerSupervisorDashboardCount } from '@/models/twilio/ManagerSupervisorDashboardCount'
import QueueDashboardModel from '@/models/twilio/QueueDashboardModel'
import OutstandingJobModel from '@/models/OutstandingJobModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import JobFinancialSummaryDataModel from '@/models/claim/JobFinancialSummaryDataModel'
import JobFinancialSummary from '@/models/claim/JobFinancialSummary'
import ContractorFinancialSummaryDataModel from '@/models/claim/ContractorFinancialSummaryDataModel'
import ContractorFinancialSummary from '@/models/claim/ContractorFinancialSummaryModel'
import DisputeContractorInvoiceModel from '@/models/claim/DisputeContractorInvoiceModel'
import MissedJobSearchAndFilterModel from '@/models/claim/MissedJobSearchAndFilterModel'
import MissedJobDataModel from '@/models/claim/MissedJobDataModel'
import MissedJobDetailDataModel from '@/models/claim/MissedJobDetailDataModel'
import { JobFinancialSummaryWidget, JobType } from '@/common/enums'
import ApproveListOfJobsModel, { ApproveJob } from '@/models/claim/ApproveListOfJobsModel'
import AddWIPCustomerNotificationModel from '@/models/requests/AddWIPCustomerNotificationModel'
import SIJobFinancialSummaryDataModel from '@/models/claim/SIJobFinancialSummaryDataModel'
import SIJobFinancialSummary from '@/models/claim/SIJobFinancialSummary'
import JobFinancialSummaryWidgetCount from '@/models/JobFinancialSummaryWidgetCount'

export interface DashboardInitialData {
  workers: Worker[]
  callStats: TopLevelCallStats
  insurerStats: InsurerStats[]
  announcingBreakdown: InsurerAnnouncingBreakdown
  workerReservationEvents: WorkerReservationEvents[]
  todaysCallStats: DailyCallStats
  inSurge: boolean
  dashboardCount: ManagerSupervisorDashboardCount
  contractorStats: ContractorStats
  contractorAnnouncingCount: number
}

export default class DashboardController {
  public static async GetSupervisorDashboardData(): Promise<DashboardInitialData> {
    const response = await CallCentreApi.get<DashboardInitialData>('dashboards/supervisors')
    return response.data
  }

  public static async GetWorkers(): Promise<Worker[]> {
    const response = await CallCentreApi.get<Worker[]>('Dashboard/GetWorkers')
    return response.data
  }

  public static async GetManagerSupervisorDashboardData(): Promise<DashboardInitialData> {
    const response = await CallCentreApi.get<DashboardInitialData>('dashboards/supervisors')
    return response.data
  }

  public static async GetComplaintTrackingDetail(): Promise<ComplaintTrackerModel | null> {
    const res = await CallCentreApi.get<ComplaintTrackerModel>('Dashboard/GetComplaintTrackingDetail')
    return res.data ? res.data : null
  }

  public static async UpdateSurgeStatus(inSurge: boolean): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get('Dashboard/UpdateSurgeStatus?inSurge=' + inSurge)
    return res.data ? res.data : false
  }

  public static async GetSurgeStatus(): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get('Dashboard/GetSurgeStatus')
    return res.data ? res.data : false
  }

  public static async GetJobDashboardData(): Promise<JobDashboardModel | null> {
    const res = await CallCentreApi.get<JobDashboardModel>('Dashboard/GetJobDashboardData')
    return res.data
  }

  public static async GetUndeployedJobEmergencies(): Promise<JobHeader[]> {
    const res = await CallCentreApi.get<JobHeader[]>('Dashboard/GetUndeployedJobEmergencies')
    return res.data ? res.data : []
  }

  public static async GetJobDetails(
    pageNumber: number,
    pageSize: number,
    searchField: string,
    search: string,
    sortBy: string,
    sortOrder: string
  ): Promise<JobDashboardModel | null> {
    const res = await CallCentreApi.get<JobDashboardModel>(
      'Dashboard/GetJobDetails?pageNumber=' +
      pageNumber +
      '&pageSize=' +
      pageSize +
      '&searchField=' +
      searchField +
      '&search=' +
      search +
      '&sortBy=' +
      sortBy +
      '&sortOrder=' +
      sortOrder
    )
    return res.data
  }

  public static async GetUnlinkedPhoneCallsDashboardData(directory: string): Promise<UnlinkedPhoneCallsModel[]> {
    const res = await CallCentreApi.get<UnlinkedPhoneCallsModel[]>('Dashboard/GetUnlinkedCalls?directory=' + directory)
    return res.data ? res.data : []
  }

  public static async GetJobsData(from: string, to: string): Promise<JobHeader[]> {
    const res = await CallCentreApi.get<JobHeader[]>(
      'Dashboard/GetJobListToLinkedWithCall?' + 'from=' + encodeURIComponent(from) + '&to=' + encodeURIComponent(to)
    )
    return res.data ? res.data : []
  }

  public static async MarkAsNoise(recordingId: string, recordingUrl: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Dashboard/MarkAsNoise?' +
      'recordingId=' +
      encodeURIComponent(recordingId) +
      '&recordingUrl=' +
      encodeURIComponent(recordingUrl)
    )
    return res.status >= 200 && res.status < 300
  }

  public static async AssignJobToUnlinkedPhoneCall(
    jobId: string,
    recordingId: string,
    callSid: string,
    callInfo: string,
    recordingUrl: string
  ): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Dashboard/AssignUnlinkedCallToJob?' +
      'jobId=' +
      encodeURIComponent(jobId) +
      '&recordingId=' +
      encodeURIComponent(recordingId) +
      '&callSid=' +
      encodeURIComponent(callSid) +
      '&callInfo=' +
      encodeURIComponent(callInfo) +
      '&recordingUrl=' +
      encodeURIComponent(recordingUrl)
    )
    return res.data ? res.data.requestId : ''
  }

  public static async SaveCETWayNote(cetWayNoteModel: CETWayNoteModel): Promise<CETWayNoteModel | null> {
    const res = await CallCentreApi.post<CETWayNoteModel>('Dashboard/SaveCETWayNote', cetWayNoteModel)
    return res.data
  }

  public static async GetIncidentManagerDashboardData(
    incidentManagerId: string
  ): Promise<IncidentManagerDashboardDataModel | null> {
    const res = await CallCentreApi.get<IncidentManagerDashboardDataModel>(
      'Dashboard/GetIncidentManagerDashboardData?selectedIM=' + incidentManagerId
    )
    return res.data
  }

  public static async GetCETWayNote(): Promise<CETWayNoteModel | null> {
    const res: AxiosResponse = await CallCentreApi.get<CETWayNoteModel>('Dashboard/GetCETWayNote')
    return res.data
  }

  public static async GetNetworkDashboardData(tradeId: number): Promise<NetworkDashboardModel | null> {
    const res = await CallCentreApi.get<NetworkDashboardModel>('Dashboard/GetNetworkDashboardData?tradeId=' + tradeId)
    return res.data
  }

  public static async GetThirdPartyDashboardData(): Promise<DashboardInitialData> {
    const response = await CallCentreApi.get<DashboardInitialData>('Dashboard/GetCarpeoDashboardData')
    return response.data
  }
  public static async GetQueueDashboardData(): Promise<QueueDashboardModel[]> {
    const res = await CallCentreApi.get<QueueDashboardModel[]>('Dashboard/GetQueueDashboardData')
    return res.data
  }

  public static async GetAllOutstandingJobs(
    recordCount: number,
    contractorId: string,
    teamName: string,
    status: string,
    escalationType: string,
    vulnerabilityLevel: string,
    sortBy: string,
    visitType: string,
    jobDateFilterType: string
  ): Promise<OutstandingJobModel | null> {
    const res = await CallCentreApi.get<OutstandingJobModel>(
      'Dashboard/GetAllOutstandingJobs?recordCount=' +
      recordCount +
      '&contractorId=' +
      contractorId +
      '&status=' +
      status +
      '&teamName=' +
      teamName +
      '&escalationType=' +
      escalationType +
      '&vulnerabilityLevel=' +
      vulnerabilityLevel +
      '&sortBy=' +
      sortBy +
      '&visitType=' +
      visitType +
      '&jobDateFilterType=' +
      jobDateFilterType
    )
    return res.data
  }

  public static async UpdateCallPriority(id: string, priority: number): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/UpdateCallPriority', { id, priority })
    return res.status >= 200 && res.status < 300
  }

  public static async GetOutstandingJobsLocation(contractorId: string): Promise<ContractorAppointedModel[]> {
    const res = await CallCentreApi.get<ContractorAppointedModel[]>(
      'Dashboard/GetOutstandingJobsLocation?contractorId=' + contractorId
    )
    return res.data ? res.data : []
  }

  public static async GetJobFinancialSummaryData(
    insurerId: number,
    jobType: JobType
  ): Promise<JobFinancialSummaryDataModel | null> {
    const res = await CallCentreApi.get<JobFinancialSummaryDataModel>(
      'Dashboard/GetJobFinancialSummaryData?insurerId=' + insurerId + '&jobType=' + jobType
    )
    return res.data
  }

  public static async GetJobFinancialSummaryWidgetData(
    insurerId: number,
    filter: string,
    jobType: JobType
  ): Promise<JobFinancialSummary[] | null> {
    const res = await CallCentreApi.get<JobFinancialSummary[]>(
      'Dashboard/GetJobFinancialSummaryWidgetData?insurerId=' +
      insurerId +
      '&filterBy=' +
      filter +
      '&jobType=' +
      jobType
    )
    return res.data ? res.data : []
  }

  public static async GetClientFinanceDashboardSummary(
    clientId: number,
    jobType: JobType
  ): Promise<JobFinancialSummaryWidgetCount> {
    const res = await CallCentreApi.get<JobFinancialSummaryWidgetCount>(
      `Dashboard/GetClientFinanceDashboardSummary?clientId=${clientId}&jobType=${jobType}`
    )
    return res.data
  }

  public static async GetClientFinanceDashboardData(
    clientId: number,
    jobType: JobType,
    kanbanColumn: JobFinancialSummaryWidget,
    policyScheduleId: number | null,
    tradeId: number | null
  ): Promise<JobFinancialSummary[]> {
    const res = await CallCentreApi.get<JobFinancialSummary[]>(
      `Dashboard/GetClientFinanceDashboardData` +
        `?clientId=${clientId}` +
        `&jobType=${jobType}` +
        `&kanbanColumn=${JobFinancialSummaryWidget[kanbanColumn]}` +
        `&policyScheduleId=${policyScheduleId}` +
        `&tradeId=${tradeId}`
    )
    return res.data ? res.data : []
  }

  public static async ApproveForJobInvoice(model: ApproveListOfJobsModel): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/ApproveForJobInvoice', model)
    return res.status === 202 ? true : false
  }

  public static async RejectForJobInvoice(model: ApproveListOfJobsModel): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/RejectForJobInvoice', model)
    return res.status === 202 ? true : false
  }

  public static async RecalculateInvoiceOfJobs(model: ApproveListOfJobsModel): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/RecalculateInvoiceOfJobs', model)
    return res.status === 202 ? true : false
  }

  public static async CompleteJobReview(model: ApproveListOfJobsModel): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/CompleteJobReview', model)
    return res.status === 202 ? true : false
  }

  public static async GetFinancialSummaryJobs(model: ApproveListOfJobsModel): Promise<JobFinancialSummary[] | null> {
    const res = await CallCentreApi.post<JobFinancialSummary[]>('Dashboard/GetFinancialSummaryJobs', model)
    return res.data
  }

  public static async GetContractorFinancialSummaryData(
    contractorId: string
  ): Promise<ContractorFinancialSummaryDataModel> {
    const res = await CallCentreApi.get<ContractorFinancialSummaryDataModel>(
      'Dashboard/GetContractorFinancialSummaryData?contractorId=' + contractorId
    )
    return res.data
  }

  public static async GetContractorFinancialSummaryWidgetData(
    contractorId: string,
    filter: string
  ): Promise<ContractorFinancialSummary[]> {
    const res = await CallCentreApi.get<ContractorFinancialSummary[]>(
      'Dashboard/GetContractorFinancialSummaryWidgetData?contractorId=' + contractorId + '&filterBy=' + filter
    )
    return res.data ? res.data : []
  }

  public static async DisputeContractorInvoices(
    disputeContractorInvoice: DisputeContractorInvoiceModel
  ): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/DisputeContractorInvoices', disputeContractorInvoice)
    return res.status === 202 ? true : false
  }

  public static async GetContractorFinancialSummary(
    disputeContractorInvoice: DisputeContractorInvoiceModel
  ): Promise<ContractorFinancialSummary[]> {
    const res = await CallCentreApi.post<ContractorFinancialSummary[]>(
      'Dashboard/GetContractorFinancialSummary',
      disputeContractorInvoice
    )
    return res.data ? res.data : []
  }

  public static async ConfirmDisputeContractorInvoices(
    disputeContractorInvoice: DisputeContractorInvoiceModel
  ): Promise<boolean> {
    const res = await CallCentreApi.post<ContractorFinancialSummary[]>(
      'Dashboard/ConfirmDisputeContractorInvoices',
      disputeContractorInvoice
    )
    return res.status === 202 ? true : false
  }

  public static async confirmDisputeJob(model: ApproveListOfJobsModel): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/confirmDisputeJob', model)
    return res.status === 202 ? true : false
  }

  public static async CompleteContractorReview(
    disputeContractorInvoice: DisputeContractorInvoiceModel
  ): Promise<boolean> {
    const res = await CallCentreApi.post<ContractorFinancialSummary[]>(
      'Dashboard/CompleteContractorReview',
      disputeContractorInvoice
    )
    return res.status === 202 ? true : false
  }

  public static async UpdateComplaintResponsiblePerson(
    id: string,
    jobId: string,
    responsiblePersonId: string
  ): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/UpdateComplaintResponsiblePerson', {
      id,
      jobId,
      responsiblePersonId,
    })
    return res.status === 202 ? true : false
  }

  public static async GenerateExcelForComplaint(fromDate: string, toDate: string, status: boolean): Promise<any> {
    const res = await CallCentreApi.get<any[]>(
      'Dashboard/GenerateExcelForComplaint?complaintLoggedFrom=' +
      fromDate +
      '&complaintLoggedTo=' +
      toDate +
      '&status=' +
      status
    )
    return res.data ? res.data : []
  }

  public static async GetMissedJobDashboardData(
    missedJobSearchAndFilterModel: MissedJobSearchAndFilterModel
  ): Promise<MissedJobDataModel | null> {
    const res = await CallCentreApi.post<MissedJobDataModel>(
      'Dashboard/GetMissedJobDashboardData',
      missedJobSearchAndFilterModel
    )
    return res.data ? res.data : null
  }

  public static async GetMissedJobTradeFilterData(
    missedJobSearchAndFilterModel: MissedJobSearchAndFilterModel
  ): Promise<MissedJobDetailDataModel | null> {
    const res = await CallCentreApi.post<MissedJobDetailDataModel>(
      'Dashboard/GetMissedJobTradeFilterData',
      missedJobSearchAndFilterModel
    )
    return res.data ? res.data : null
  }

  public static async GetUnlinkedCallDirectories(): Promise<string[]> {
    const res = await CallCentreApi.get<string[]>('Dashboard/GetUnlinkedCallDirectories')
    return res.data ? res.data : []
  }

  public static async ApproveJobInvoice(model: ApproveJob): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/ApproveJobInvoice', model)
    return res.status === 202 ? true : false
  }

  public static async GetWorkInProgressTemplates(jobId: string): Promise<any> {
    const res = await CallCentreApi.get<any>('Dashboard/GetWorkInProgressTemplates?jobId=' + jobId)
    return res.status >= 200 && res.status < 300 ? res.data : []
  }

  public static async SendWIPCustomerNotification(model: AddWIPCustomerNotificationModel): Promise<boolean> {
    const res = await CallCentreApi.post<MissedJobDetailDataModel>('Dashboard/SendWIPCustomerNotification', model)
    return res.status === 202 ? true : false
  }

  public static async ApproveForContractorInvoice(model: DisputeContractorInvoiceModel): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Dashboard/ApproveForContractorInvoice', model)
    return res.status === 202 ? true : false
  }

  public static async GetSIJobFinancialSummaryData(
    insurerId: number,
    claimManagementCompanyId: number
  ): Promise<SIJobFinancialSummaryDataModel | null> {
    const res = await CallCentreApi.get<SIJobFinancialSummaryDataModel>(
      'Dashboard/GetSIJobFinancialSummaryData?insurerId=' +
      insurerId +
      '&claimManagementCompanyId=' +
      claimManagementCompanyId
    )
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async GetSIJobFinancialSummaryWidgetData(
    insurerId: number,
    filter: string,
    claimManagementCompanyId: number
  ): Promise<SIJobFinancialSummary[] | null> {
    const res = await CallCentreApi.get<SIJobFinancialSummary[] | null>(
      'Dashboard/GetSIJobFinancialSummaryWidgetData?insurerId=' +
      insurerId +
      '&claimManagementCompanyId=' +
      claimManagementCompanyId +
      '&filterBy=' +
      filter
    )
    return res.status >= 200 && res.status < 300 ? res.data : []
  }

  public static async GetSIFinancialSummaryJobs(
    model: ApproveListOfJobsModel
  ): Promise<SIJobFinancialSummary[] | null> {
    const res = await CallCentreApi.post<SIJobFinancialSummary[] | null>('Dashboard/GetSIFinancialSummaryJobs', model)
    return res.status >= 200 && res.status < 300 ? res.data : []
  }

  public static async GetSIJobFinancialSummaryWidgetCount(
    insurerId: number,
    claimManagementCompanyId: number
  ): Promise<JobFinancialSummaryWidgetCount | null> {
    const res = await CallCentreApi.get<JobFinancialSummaryWidgetCount | null>(
      'Dashboard/GetSIJobFinancialSummaryWidgetCount?insurerId=' +
      insurerId +
      '&claimManagementCompanyId=' +
      claimManagementCompanyId
    )
    return res.status >= 200 && res.status < 300 ? res.data : null
  }
}
