<template>
  <div>
    <div v-if="requestType === reqType[reqType.AddVulnerabilityAnswer]" class="mb-2">
      <h4>Vulnerability Answer</h4>
    </div>
    <div
      v-else-if="requestType === reqType[reqType.AddHealthAndSafetyAnswer] && (hasList ? index === 0 : true)"
      class="mb-2"
    >
      <h4>Health & Safety Answer</h4>
    </div>
    <div v-if="item.isHealthAffected !== null || item.level">
      <div :class="hasList ? '' : 'mb-1'">
        <label>Health affected: </label>
        <span>{{ item.isHealthAffected ? 'Yes' : 'No' }}</span>
      </div>
      <div :class="hasList ? (!(item.isHealthAffected && item.answerDetail) ? 'mb-2' : '') : 'mb-1'">
        <label>Health affected level: </label>
        <span>{{ item.level ? item.level : '' }}</span>
      </div>
      <div v-if="item.isHealthAffected && item.answerDetail" :class="hasList ? 'mb-2' : 'mb-1'">
        <label>Answer Detail: </label>
        <span>{{ item.answerDetail }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddJobAnswerModel from '@/models/requests/AddJobAnswerModel'
import { RequestType } from '@/common/enums'

@Component
export default class AddVulnerabilityAndHealthSafetyAnswerLog extends Vue {
  @Prop() private requestBody: any
  @Prop() private requestType: string
  @Prop({ default: 0 }) private index: number
  @Prop({ default: false }) private hasList: boolean
  private reqType = RequestType

  private get item(): AddJobAnswerModel {
    return Object.assign(new AddJobAnswerModel(), this.requestBody)
  }
}
</script>

<style scoped></style>
