import Product from '@/models/siteInvestigation/Product'

export default class MonitoringProduct extends Product {
  public selectedChildProducts: Product[]

  constructor(product?: Product | MonitoringProduct) {
    super(product)
    this.selectedChildProducts = []
    this.quantity = 1
    if (product && 'selectedChildProducts' in product && product.selectedChildProducts) {
      this.selectedChildProducts = product.selectedChildProducts
    }
  }
}
