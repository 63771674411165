<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="close-configure-dropdown" @click.native="closeConfigureDropdownDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text class="scroll-content-dialog">
      <v-progress-circular
        v-show="isLoaded"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
        :style="getLoaderStyle(70)"
      ></v-progress-circular>
      <v-layout v-show="!isLoaded">
        <v-flex xs12 class="gridView tbody-scroll-table">
          <v-data-table :headers="headers" :items="items" item-key="id" :loading="isLoading" hide-actions>
            <template slot="items" slot-scope="props">
              <tr ref="datatable">
                <td class="text-xs-left">
                  <v-text-field
                    v-if="isUpdateRecord && index === props.item.id"
                    v-model="props.item.description"
                    v-validate="'required'"
                    class="pt-2 required"
                    data-vv-scope="updateRecordFormRef"
                    data-vv-name="editDescription"
                    name="editDescription"
                    :error-messages="validationMessage('editDescription')"
                  ></v-text-field>
                  <span v-else>{{ props.item.description }}</span>
                </td>
                <td class="text-xs-right">
                  <v-flex v-if="isUpdateRecord && index === props.item.id">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          name="updateRecordButton"
                          icon
                          flat
                          color="primary"
                          small
                          v-on="on"
                          @click.native="onAddUpdateRecord(props.item)"
                        >
                          <v-icon>update</v-icon>
                        </v-btn>
                      </template>
                      <span>Update</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          name="cancelRecordButton"
                          icon
                          flat
                          color="primary"
                          small
                          v-on="on"
                          @click.native="onCancelEditRecord(props.item)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </template>
                      <span>Cancel</span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex v-else>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          name="editRecordButton"
                          icon
                          flat
                          color="primary"
                          small
                          v-on="on"
                          @click.native="onEditRecord(props.item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          name="deleteRecordButton"
                          icon
                          flat
                          color="primary"
                          small
                          v-on="on"
                          @click.native="onDeleteRecordClick(props.item)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-flex>
                </td>
              </tr>
            </template>
            <template slot="footer">
              <tr>
                <td>
                  <v-text-field
                    v-model="record.description"
                    v-validate="'required'"
                    label="Description"
                    required
                    class="required"
                    data-vv-scope="addRecordFormRef"
                    data-vv-name="Description"
                    name="Description"
                    :error-messages="errors.collect('Description')"
                    @focus="onAddDescriptionFocus"
                  ></v-text-field>
                </td>
                <td class="text-xs-right">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        name="addRecordButton"
                        icon
                        flat
                        color="primary"
                        small
                        v-on="on"
                        @click="onAddUpdateRecord(null)"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Add</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import Shared from '@/common/shared'
import { RecordType } from '@/common/enums'
import eventBus from '@/common/bus'
@Component
export default class ConfigureDropdown extends Vue {
  @Prop() private recordType: string

  private headers: any = []
  private items: MasterRecord[] = []
  private index: any = null
  private isUpdateRecord = false
  private isLoading = false
  private previousValue = ''
  private record: MasterRecord = new MasterRecord()
  private isLoaded = false

  private created() {
    this.headers = [
      {
        text: 'Description',
        value: 'description',
        sortable: false,
        align: 'left',
        class: 'description-col',
      },
      {
        text: '',
        sortable: false,
        value: 'action',
        align: 'right',
        class: 'action-col',
      },
    ]
    this.getMasterRecords()
  }

  private get dialogTitle(): string {
    if (this.recordType === RecordType[RecordType.PaymentReason]) {
      return 'Payment Reason'
    } else if (this.recordType === RecordType[RecordType.ResolutionCode]) {
      return 'Resolution Code'
    } else if (this.recordType === RecordType[RecordType.ContractorUnavailableReason]) {
      return 'Unavailable Reason'
    } else if (this.recordType === RecordType[RecordType.ComplaintType]) {
      return 'Complaint Type'
    } else if (this.recordType === RecordType[RecordType.DelayCode]) {
      return 'Delay Code'
    } else if (this.recordType === RecordType[RecordType.NomineeRelation]) {
      return 'Nominee Relation'
    } else if (this.recordType === RecordType[RecordType.JobCancellationReason]) {
      return 'Job Cancellation Reason'
    } else if (this.recordType === RecordType[RecordType.RemoveEmergencyFromJobReason]) {
      return 'Remove Emergency Reason'
    } else if (this.recordType === RecordType[RecordType.AbandonVisitReason]) {
      return 'Abandon Visit Reason'
    } else if (this.recordType === RecordType[RecordType.ComplaintRootCause]) {
      return 'Complaint Root Cause'
    } else if (this.recordType === RecordType[RecordType.ComplaintEscalationReason]) {
      return 'Complaint Escalation Reason'
    } else if (this.recordType === RecordType[RecordType.ConcernsRaised]) {
      return 'Concerns Raised'
    } else if (
      this.recordType === RecordType[RecordType.SIJobDelayReason] ||
      this.recordType === RecordType[RecordType.USJobDelayReason]
    ) {
      return 'Delay Reason'
    } else if (this.recordType === RecordType[RecordType.AbandonTrialPitReason]) {
      return 'Abandon Trial Pit Reason'
    } else if (this.recordType === RecordType[RecordType.AbandonCCTVReason]) {
      return 'Abandon CCTV Log Reason'
    } else if (this.recordType === RecordType[RecordType.AbandonWaterMainsTestReason]) {
      return 'Abandon Water Mains Test Reason'
    } else if (this.recordType === RecordType[RecordType.AbandonDatumReason]) {
      return 'Abandon Datum Reason'
    } else if (this.recordType === RecordType[RecordType.JobReopenReason]) {
      return 'Job Reopen Reason'
    } else {
      return this.recordType
    }
  }

  // Get list based on recordType (e.g. PaymentReason, ResolutionCode).
  @Watch('recordType')
  private getMasterRecords() {
    this.isLoading = true
    if (this.recordType) {
      MasterRecordController.GetMasterRecords(this.recordType)
        .then((res: MasterRecord[]) => {
          if (res) {
            this.items = res.filter((e) => e.isDeleted === false)
            this.isLoading = false
          }
          this.isLoaded = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading' + this.dialogTitle + 'list, please try again', true)
          this.isLoading = false
        })
    }
  }
  // Set focus on add mew description field.
  private onAddDescriptionFocus() {
    if (this.isUpdateRecord) {
      this.isUpdateRecord = false
      const index = this.items.findIndex((e) => e.id === this.index)
      if (index !== -1) {
        this.items[index].description = this.previousValue
        this.items = [...this.items]
      }
    }
  }
  // Add/Update records.
  private onAddUpdateRecord(record: any) {
    this.$validator.errors.items = []
    if (!this.isLoading) {
      if (record === null && this.isUpdateRecord) {
        this.isUpdateRecord = false
        const index = this.items.findIndex((e) => e.id === this.index)
        if (index !== -1) {
          this.items[index].description = this.previousValue
          this.items = [...this.items]
        }
      }
      const self = this
      this.validate(record === null ? 'addRecordFormRef' : 'updateRecordFormRef')
        .then((result: boolean) => {
          if (result) {
            this.isLoading = true
            const item = record !== null ? record : self.record
            item.type = this.recordType
            MasterRecordController.SaveMasterRecord(item)
              .then((res: MasterRecord) => {
                if (res) {
                  if (this.isUpdateRecord) {
                    const index = this.items.findIndex((e) => e.id === record.id)
                    if (index !== -1) {
                      this.items[index] = res
                      this.items = [...this.items]
                    }
                    this.isUpdateRecord = false
                    this.previousValue = this.items[index].description
                  } else {
                    this.items.push(res)
                    self.record = new MasterRecord()
                    // set scrollTop for tbody
                    setTimeout(() => {
                      const dataTableBody: any = this.$el.querySelector('.v-datatable tbody')
                      dataTableBody.scrollTop = dataTableBody.scrollHeight - dataTableBody.offsetHeight
                    }, 0)
                  }
                  this.isLoading = false
                } else {
                  this.isLoading = false
                }
              })
              .catch((err: any) => {
                eventBus.$emit('errorHandler', 'Error saving' + this.dialogTitle + 'detail, please try again', true)
                this.isLoading = false
                self.record = new MasterRecord()
              })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }
  }
  // set description value of item to previous value if updation of record is cancelled, remove error messages if any.
  private onCancelEditRecord(item: any) {
    item.description = this.previousValue
    this.$validator.errors.items = []
    this.isUpdateRecord = false
  }
  // get index of the current row, make row editable.
  private onEditRecord(item: any) {
    const index = this.items.findIndex((e) => e.id === this.index)
    if (index !== -1) {
      this.items[index].description = this.previousValue
      this.items = [...this.items]
    }
    this.$validator.errors.items = []
    this.record.description = ''
    this.isUpdateRecord = true
    this.index = item.id
    this.previousValue = item.description
  }

  private onDeleteRecordClick(item: any) {
    const index = this.items.findIndex((e) => e.id === this.index)
    if (index !== -1) {
      this.items[index].description = this.previousValue
      this.items = [...this.items]
    }
    this.$validator.errors.items = []
    this.isUpdateRecord = false
    this.record.description = ''
    Shared.confirmationPopup.open(
      'Do you really want to delete ' + this.dialogTitle + '?',
      '',
      '',
      '',
      '',
      this,
      'DeleteRecord',
      item.id
    )
  }
  // deletion of record.
  private DeleteRecord(id: string) {
    if (!this.isLoading) {
      this.isLoading = true
      MasterRecordController.DeleteMasterRecord(id, this.recordType)
        .then((res: boolean) => {
          if (res) {
            this.isLoading = false
            this.items = this.items.filter((e) => e.id !== id)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error deleting' + this.dialogTitle + 'detail, please try again', true)
          this.isLoading = false
        })
    }
  }

  private closeConfigureDropdownDialog() {
    this.$validator.errors.items = []
    this.isUpdateRecord = false
    this.record.description = ''
    this.$emit('CloseConfigureDropdownDialog', this.items)
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message === 'The editDescription field is required.'
      ? (message = 'The Description field is required.')
      : message
  }

  private async validate(formref: any): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(formref)

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style scoped>
.gridview >>> .description-col {
  width: 70%;
}
.gridview >>> .action-col {
  width: 30%;
}
.tbody-scroll-table >>> table {
  width: 100%;
}
.tbody-scroll-table >>> tbody {
  display: block;
  max-height: 460px;
  min-height: 460px;
  overflow-y: scroll;
}
.tbody-scroll-table >>> tbody tr,
.tbody-scroll-table >>> thead,
.tbody-scroll-table >>> tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.tbody-scroll-table >>> tbody::-webkit-scrollbar {
  width: 6px;
}

.tbody-scroll-table >>> tbody::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 3px;
}
</style>
