<template>
  <div>
    <div class="mb-2"><h4>Customer Availability TimeSlot</h4></div>
    <template v-if="getlocalTimeSlot">
      <div v-for="(keyItem, keyIndex) in Object.keys(getlocalTimeSlot)" :key="keyIndex">
        <div class="mb-1">
          <label>{{ keyItem }}: </label>
          <span v-for="(ValueItem, valueIndex) in getlocalTimeSlot[keyItem]" :key="valueIndex">
            {{ ValueItem }}{{ valueIndex === getlocalTimeSlot[keyItem].length - 1 ? '' : ',' }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddCustomerAvailabilityTimeSlot from '@/models/requests/AddCustomerAvailabilityTimeSlot'
import TimeSlot from '@/models/claim/TimeSlot'
import moment, { Moment } from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'

@Component
export default class AddCustomerAvailabilityTimeSlotLog extends Vue {
  @Prop() private requestBody: any
  private localTimeSlot: any = {}

  private get item(): AddCustomerAvailabilityTimeSlot {
    return Object.assign(new AddCustomerAvailabilityTimeSlot(), this.requestBody)
  }

  private get getlocalTimeSlot(): any {
    if (this.item.timeSlot && this.item.timeSlot.length > 0) {
      this.item.timeSlot.forEach((e: TimeSlot) => {
        const startTime = this.getFormattedDate(e.startTime)
        if (!Object.prototype.hasOwnProperty.call(this.localTimeSlot, startTime)) {
          this.localTimeSlot[startTime] = []
        }

        this.localTimeSlot[startTime].push(e.slot)
      })
    }
    return this.localTimeSlot
  }

  private getFormattedDate(date: Date) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }
}
</script>

<style scoped></style>
