const validationMessages = {
  en: {
    custom: {
      password: {
        required: 'Please enter a password',
      },
      password_confirmation: {
        required: 'Please confirm your password',
        confirmed: 'Passwords need to match',
      },
      Postcode: {
        regex: 'Please enter a postcode in a valid format',
        required: 'Please enter your postcode',
      },
    },
  },
}

export default validationMessages
