<template>
  <div v-if="item">
    <div class="mb-2"><h4>Third party contractor appointed on job</h4></div>
    <div v-if="item.companyName" class="mb-1">
      <label>Contractor: </label>
      <span>{{ item.companyName }}</span>
    </div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddThirdPartyContractorDetail from '@/models/requests/AddThirdPartyContractorDetail'
import Shared from '@/common/shared'

@Component
export default class AddThirdPartyAppointedContractorLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddThirdPartyContractorDetail {
    return Object.assign(new AddThirdPartyContractorDetail(), this.requestBody)
  }

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.item.emergencyId)
  }
}
</script>

<style scoped></style>
