import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import InvoiceDetailModel from '@/models/claim/InvoiceDetailModel'
import CTPAuthorisedRecordModel from '@/models/claim/CTPAuthorisedRecordModel'
import { ClientInvoiceStatus } from '@/common/enums'
import OtherJobFeeDetailModel from '@/models/claim/OtherJobFeeDetailModel'
export default class ClientInvoiceDetailModel extends CosmosJobsDbRecordBase {
  public policyName: string
  public policyLimit: number
  public insurerId: number
  public address: string
  public ctpRecords: CTPAuthorisedRecordModel[]
  public status: ClientInvoiceStatus
  public disputedReason: string
  public clientNotes: string[]
  public invoiceDetail: InvoiceDetailModel
  public ctpUsed: InvoiceDetailModel
  public totalCTPPaidAmount: number | null
  public otherJobFeeDetail: OtherJobFeeDetailModel | null
  public ratePackageId: string
  public description: string
  public originalInvoiceDetail: InvoiceDetailModel
}
