<template>
  <div v-if="item">
    <div class="mb-2">
      <h4>{{ item.id ? ' Update' : 'Add' }} Repair Estimate Detail</h4>
    </div>
    <div v-if="item.products.length > 0">
      <div v-for="(product, index) in item.products" :key="index">
        <div v-if="product.subCategory" class="mb-1">
          <label>Sub Category: </label>
          <span>{{ product.subCategory }}</span>
        </div>
        <div v-if="product.description" class="mb-1">
          <label>Description: </label>
          <span>{{ product.description }}</span>
        </div>
        <div v-if="product.unit" class="mb-1">
          <label>Unit: </label>
          <span>{{ product.unit }}</span>
        </div>
        <div v-if="product.unitPrice" class="mb-1">
          <label>Unit Price: </label>
          <span>{{ getFormatedCurrency(product.unitPrice) }}</span>
        </div>
        <div v-if="product.vatRate" class="mb-1">
          <label>Vat Rate(%): </label>
          <span>{{ product.vatRate }}</span>
        </div>
        <div v-if="product.quantity" class="mb-1">
          <label>Quantity: </label>
          <span>{{ product.quantity }}</span>
        </div>
        <div v-if="product.clientPrice" class="mb-1">
          <label>Client Price: </label>
          <span>{{ getFormatedCurrency(product.clientPrice) }}</span>
        </div>
        <div v-if="product.repairCost" class="mb-1">
          <label>Repair Cost: </label>
          <span>{{ getFormatedCurrency(product.repairCost) }}</span>
        </div>
        <div v-if="product.customerAppointedTradePrice" class="mb-1">
          <label>Customer Appointed Trade Price: </label>
          <span>
            {{ getFormatedCurrency(product.customerAppointedTradePrice) }}
          </span>
        </div>
        <v-divider v-if="index !== item.products.length - 1" class="mb-1"></v-divider>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpsertRepairEstimateRequestModel from '@/models/undergroundServices/UpsertRepairEstimateRequestModel'
import Shared from '@/common/shared'

@Component
export default class UpsertRepairEstimateRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpsertRepairEstimateRequestModel {
    return Object.assign(new UpsertRepairEstimateRequestModel(), this.requestBody)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>
