<template>
  <v-card class="wrapper" :class="{ 'overflow-y-hidden': loading }">
    <v-layout align-center flex-col class="profile-banner pt-3">
      <ProfileImage :image.sync="engineer.profileImageUrl" :file.sync="imageFile" />
    </v-layout>

    <div class="pa-4">
      <div class="fields">
        <v-text-field
          v-model.trim="engineer.firstName"
          v-validate="'required'"
          label="First Name"
          maxlength="50"
          required
          data-vv-scope="engineerDetails"
          data-vv-name="First Name"
          :error-messages="errors.collect('First Name')"
        />

        <v-text-field
          v-model.trim="engineer.lastName"
          v-validate="'required'"
          label="Last Name"
          maxlength="50"
          required
          data-vv-scope="engineerDetails"
          data-vv-name="Last Name"
          :error-messages="errors.collect('Last Name')"
        />

        <v-text-field
          v-model.trim="engineer.email"
          v-validate="'required|email'"
          label="Email"
          maxlength="50"
          required
          data-vv-scope="engineerDetails"
          data-vv-name="Email"
          :error-messages="errors.collect('Email')"
          name="Email"
        />

        <PhoneNumber
          ref="engineerContactNumber"
          :passed-phone-number="engineer.contactNumber"
          :is-validation-required="true"
          class="phone v-text-field"
          @phoneNumberUpdated="(phone) => (engineer.contactNumber = phone)"
        />
      </div>

      <h3 class="text-lg v-label theme--light font-weight-regular">New Claims Notification Preferences</h3>
      <div class="d-flex mb-4">
        <v-switch v-model="comms.autodeployByEmail" color="primary" small label="Email" hide-details />
        <v-switch v-model="comms.autodeployBySms" color="primary" small label="SMS" hide-details />
        <v-switch v-model="comms.autodeployByEngineerApp" color="primary" small label="App" hide-details />
        <v-switch v-model="comms.autodeployByWhatsApp" color="primary" small label="WhatsApp" hide-details />
      </div>

      <v-textarea v-model.trim="engineer.address" label="Address" maxlength="500" rows="3" />

      <v-switch v-model="engineer.hasPortalAccess" color="primary" small label="Ability to use Portal" hide-details />
    </div>

    <v-divider />

    <div class="pa-4">
      <v-select
        v-model="sortedTrades"
        :items="availableTrades"
        label="Select Trade"
        item-text="description"
        item-value="tradeId"
        clearable
        chips
        deletable-chips
        hide-details
        multiple
        persistent-hint
        return-object
        :readonly="isUserContractor"
        @change="onTradesChanged"
      />

      <v-flex v-for="trade in sortedTrades" :key="trade.tradeId" xs12 class="engineer-timeslot">
        <TimeSlotPicker
          :header-text="trade.description"
          :filter-by-date="false"
          :model="trade"
          :slot-items="getTradeAvailability(trade.tradeId)"
          slot-type="today"
          @updateSlotItems="onTimeSlotsChanged"
        />
      </v-flex>

      <v-layout row justify-end sticky b-0 pa-2 class="modal-actions">
        <v-btn flat color="primary" @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-layout>
    </div>

    <div v-if="loading" class="loader">
      <v-progress-circular :size="64" :width="7" indeterminate color="primary" />
    </div>
  </v-card>
</template>

<script lang="ts">
import ContractorController from '@/api/contractorController'
import EngineerController from '@/api/engineerController'
import eventBus from '@/common/bus'
import DateTimePicker from '@/components/DateTimePicker.vue'
import TimeSlotPicker from '@/components/TimeSlotPicker.vue'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import TimeSlot from '@/models/claim/TimeSlot'
import CommunicationPreferences from '@/models/contractor/CommunicationPreferences'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import Store from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ProfileImage from './Engineer/ProfileImage.vue'

@Component({
  components: {
    DateTimePicker,
    PhoneNumber,
    ProfileImage,
    TimeSlotPicker,
  },
})
export default class Engineer extends Vue {
  @Prop() public isUpdate: boolean
  @Prop() public availableTrades: ContractorTradeAvailabilityModel[]
  @Prop() private existingEngineer: EngineerModel

  public loading = true
  public engineer: EngineerModel
  public imageFile: File | null = null
  public trades: ContractorTradeAvailabilityModel[] = []
  public contractorComms: CommunicationPreferences
  public comms: CommunicationPreferences['engineers'][number] = {
    engineerId: '',
    autodeployByEmail: false,
    autodeployBySms: false,
    autodeployByEngineerApp: false,
    autodeployByWhatsApp: false,
  }

  public get sortedTrades() {
    return [...this.trades].sort((a, b) => a.description.localeCompare(b.description))
  }
  public set sortedTrades(value) {
    this.trades = value
  }

  public async created() {
    try {
      this.engineer = this.existingEngineer
      this.trades = this.availableTrades.filter((trade) =>
        this.engineer.engineerAvailability.some((availibility) => availibility.tradeId === trade.tradeId)
      )
      this.contractorComms = await ContractorController.GetCommunicationPreferences(this.engineer.contractorId)
      // Needs initialising in created due to being derived from a prop
      this.comms.engineerId = this.engineer.id || ''
      this.comms = this.contractorComms.engineers.find((e) => e.engineerId === this.engineer.id) || this.comms
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading engineer details, please try again', true)
      this.$emit('close')
      return
    } finally {
      this.loading = false
    }
  }

  public close() {
    this.$emit('close')
  }

  public async save() {
    const valid: boolean = await this.$validator.validateAll('engineerDetails')
    if (!valid) {
      return
    }

    this.loading = true

    if (this.imageFile) {
      // NOTE: Need to add profileImageThumbnailUrl too?
      this.engineer.profileImageUrl = await this.saveImage(this.imageFile)
    }

    const savedEngineer = await this.saveEngineerDetails(this.isUpdate)
    if (!savedEngineer) {
      eventBus.$emit('errorHandler', 'Error saving engineer details, please try again', true)
      this.loading = false
      return
    }

    console.log('savedEngineer', savedEngineer)

    // If creating a new engineer, update the comms engineerId with the newly created one
    if (!this.isUpdate && typeof savedEngineer === 'string') {
      this.comms.engineerId = savedEngineer
    }

    const savedComms = await this.saveCommunicationPreferences()
    if (!savedComms) {
      eventBus.$emit('errorHandler', 'Error saving communication preferences', true)
      // If comms fails to save, only remain open if it's an edit not a create, otherwise we get in a weird state
      if (this.isUpdate) {
        this.loading = false
        return
      }
    }

    this.$emit('save', this.engineer)
  }

  private async saveEngineerDetails(isUpdate: boolean) {
    return isUpdate ? await EngineerController.update(this.engineer) : await EngineerController.create(this.engineer)
  }

  private async saveCommunicationPreferences() {
    const existing = this.contractorComms.engineers.find((e) => e.engineerId === this.engineer.id)
    if (existing) {
      Object.assign(existing, this.comms)
    } else {
      this.contractorComms.engineers.push(this.comms)
    }

    return await ContractorController.SaveCommunicationPreferences(this.engineer.contractorId, this.contractorComms)
  }

  private saveImage(file: File): Promise<string> {
    // NOTE: Doesn't work in existing code, implement later if required
    return Promise.resolve(file.name)
  }

  public get isUserContractor(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  public getTradeAvailability(tradeId: number): TimeSlot[] {
    const current = this.engineer.engineerAvailability.find((a) => a.tradeId === tradeId)
    return current ? current.timeSlot : []
  }

  public onTradesChanged(newValue: ContractorTradeAvailabilityModel[]) {
    this.trades = newValue
    this.engineer.engineerAvailability = this.engineer.engineerAvailability.filter((a) =>
      newValue.some((trade) => trade.tradeId === a.tradeId)
    )
  }

  public onTimeSlotsChanged(slots: TimeSlot[], model: ContractorTradeAvailabilityModel) {
    const availability = this.engineer.engineerAvailability.filter((a) => a.tradeId !== model.tradeId)
    const updated = {
      tradeId: model.tradeId,
      description: model.description,
      timeSlot: slots,
    } satisfies EngineerAvailabilityModel

    this.engineer.engineerAvailability = [...availability, updated]
  }
}
</script>

<style scoped>
.wrapper {
  height: 100%;
  overflow-y: auto;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: var(--z-modal);
}

.profile-banner {
  background-image: linear-gradient(#607d8c 50%, transparent 50%);
}

.phone {
  position: relative;
  padding-left: 0;

  .validation {
    top: 50%;
    transform: translateY(-50%);
  }
}

.fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  gap: 0 2rem;
}

.modal-actions {
  background-color: rgb(var(--bg-modal));
  margin: 0 -2rem;
  z-index: var(--z-base);
}

.v-select >>> .v-select__selections {
  padding: 0.75rem 0 0.25rem;
  gap: 0.25rem;
}
.v-select >>> .v-chip--select-multi {
  margin: 0;
}
</style>
