<template>
  <v-layout wrap class="grey lighten-3 pt-2 px-3 pb-2">
    <v-flex xs12 my-1>
      <h4>{{ title }}</h4>
    </v-flex>
    <v-flex xs12 class="trade-content">
      <div class="py-2 multiule-trade-filed add-remove-btn">
        <v-layout
          v-for="(item, index) in getQestions"
          :key="index"
          wrap
          grey
          lighten-4
          px-3
          mb-3
          class="section-repeat"
        >
          <v-flex xs6 pr-2>
            <v-select
              v-model="item.operatorId"
              v-validate="'required'"
              :items="getOperatorsByAnswerType"
              label="Operator"
              item-text="name"
              item-value="id"
              required
              class="required"
              :data-vv-name="'OperatorId' + index"
              :error-messages="validationMessage('OperatorId' + index)"
              @blur="onQuestionChange"
            ></v-select>
          </v-flex>
          <v-flex xs6 pl-2>
            <v-text-field
              v-if="
                getEmergencyQuestionModel.answerTypeId === answerType.Text ||
                getEmergencyQuestionModel.answerTypeId === answerType.Number
              "
              v-model.trim="item.comparisonValue"
              v-validate="'required|max:200'"
              label="Comparison Value"
              maxlength="200"
              :data-vv-name="'ComparisonValue' + index"
              :error-messages="validationMessage('ComparisonValue' + index)"
              required
              class="required"
              @blur="onQuestionChange"
            ></v-text-field>
            <v-select
              v-if="getEmergencyQuestionModel.answerTypeId === answerType.Dropdown"
              v-model.trim="item.comparisonValue"
              v-validate="'required|max:200'"
              :items="getdropdownOptions(getEmergencyQuestionModel.additionalValues)"
              label="Comparison Value"
              maxlength="200"
              :data-vv-name="'ComparisonValue' + index"
              :error-messages="validationMessage('ComparisonValue' + index)"
              required
              class="required"
              @blur="onQuestionChange"
            ></v-select>
            <v-select
              v-if="getEmergencyQuestionModel.answerTypeId === answerType.Checkbox"
              v-model.trim="item.comparisonValue"
              v-validate="'required|max:200'"
              :items="getcheckboxOptions"
              label="Comparison Value"
              maxlength="200"
              :data-vv-name="'ComparisonValue' + index"
              :error-messages="validationMessage('ComparisonValue' + index)"
              required
              class="required"
              @blur="onQuestionChange"
            ></v-select>
          </v-flex>
          <v-flex xs12>
            <v-select
              v-if="emergencyAnswerComparisonFor == 'Trade'"
              v-model="item.tradeId"
              v-validate="'required'"
              :items="getTrades"
              label="Trade"
              item-value="tradeId"
              item-text="description"
              required
              class="required"
              :data-vv-name="'Trade' + index"
              :error-messages="validateTradeList('Trade' + index, item)"
              @blur="onQuestionChange"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title
                      :class="getClass(data.item.tradeId)"
                      v-html="data.item.description"
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
            <v-select
              v-else-if="emergencyAnswerComparisonFor == 'Asbestos'"
              v-model="item.effectLevel"
              v-validate="'required'"
              :items="getAsbestosEffects"
              label="Asbestos"
              item-value="id"
              item-text="effect"
              required
              class="required"
              :data-vv-name="'Asbestos' + index"
              :error-messages="validationMessage('Asbestos' + index)"
              @blur="onQuestionChange"
            ></v-select>
          </v-flex>
          <v-btn icon class="trade-remove ma-0 elevation-3" small color="white" @click="removeQuestion(item)">
            <v-icon color="secondary" small>delete</v-icon>
          </v-btn>
        </v-layout>
        <v-tooltip top class="trade-add">
          <template #activator="{ on }">
            <v-btn icon class="ma-0" flat color="secondary" v-on="on" @click="addQuestion">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>{{ addButtonTooltipText }}</span>
        </v-tooltip>
      </div>
      <template v-if="hasDuplicateQuestionAsbestos || hasDuplicateQuestionTrade">
        <span class="red--text erorr-msg">
          {{ duplicateQuestionErrorMessage }}
        </span>
      </template>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import { Operator } from '@/common/interfaces'
import { OperatorType, AnswerType, AsbestosEffectLevel } from '@/common/enums'
import Shared from '@/common/shared'
import TradeModel from '@/models/policyHolder/TradeModel'
import QuestionTradeAssignmentModel from '@/models/policy/QuestionTradeAssignmentModel'
import QuestionAsbestosEffectAssignmentModel from '@/models/policy/QuestionAsbestosEffectAssignmentModel'

interface IEffectLevels {
  id: number
  effect: string
}

@Component
export default class EmergencyAssignTypesAgainstAnswer extends Vue {
  @Prop() private title: string
  @Prop() private duplicateQuestionErrorMessage: string
  @Prop() private addButtonTooltipText: string
  @Prop() private getEmergencyQuestionModel: EmergencyQuestionModel
  @Prop() private trades: TradeModel[]
  @Prop() private emergencyAnswerComparisonFor: string
  private answerType = AnswerType
  private effectLevel = AsbestosEffectLevel
  private label = ''
  private checkboxOptions: string[] = ['Yes', 'No']
  private operators: Operator[]
  private hasDuplicateQuestionTrade = false
  private hasDuplicateQuestionAsbestos = false
  private selected: any = null
  private tempEmergencyQuestionModel: EmergencyQuestionModel = new EmergencyQuestionModel()

  private created(): void {
    this.operators = Shared.getOperatorList()
  }

  private mounted() {
    this.tempEmergencyQuestionModel = JSON.parse(JSON.stringify(this.getEmergencyQuestionModel))
  }

  private get getcheckboxOptions(): string[] {
    return this.checkboxOptions
  }

  @Watch('getEmergencyQuestionModel')
  private emergencyQuestionModel() {
    return this.getEmergencyQuestionModel
  }

  private get getAsbestosEffects() {
    const keys: any[] = Object.keys(this.effectLevel).filter(Number)
    const priorityItems: IEffectLevels[] = []
    keys.forEach((element) => {
      const priorityEntity: any = {}
      priorityEntity.id = Number(element)
      priorityEntity.effect = AsbestosEffectLevel[element]
      priorityItems.push(priorityEntity)
    })
    return priorityItems
  }

  private get getQestions() {
    if (this.emergencyAnswerComparisonFor === 'Trade') {
      return this.getEmergencyQuestionModel.questionTrades
    } else if (this.emergencyAnswerComparisonFor === 'Asbestos') {
      return this.getEmergencyQuestionModel.questionAsbestosEffects
    }
    return undefined
  }

  private get getOperatorsByAnswerType(): Operator[] {
    let operators: Operator[] = this.operators
    switch (this.getEmergencyQuestionModel.answerTypeId) {
      case AnswerType.Text:
        // get 'equalto', 'not equal to', 'in' and 'not in' operators only
        operators = operators.filter(
          (c) =>
            c.id === OperatorType.EqualTo ||
            c.id === OperatorType.NotEqualTo ||
            c.id === OperatorType.In ||
            c.id === OperatorType.NotIn
        )
        return operators
      case AnswerType.Dropdown:
        // get 'equalto', 'not equal to', 'in' and 'not in' operators only
        operators = operators.filter(
          (c) =>
            c.id === OperatorType.EqualTo ||
            c.id === OperatorType.NotEqualTo ||
            c.id === OperatorType.In ||
            c.id === OperatorType.NotIn
        )
        return operators
      case AnswerType.Number:
        // get all operators
        return operators
      case AnswerType.Checkbox:
        // get 'equalto'and 'not equal to' only
        operators = operators.filter((c) => c.id === OperatorType.EqualTo || c.id === OperatorType.NotEqualTo)
        return operators
      default:
        // set to default all operators
        return operators
    }
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  // add policy question trade/asbestos assignment
  private addQuestion(): void {
    if (this.emergencyAnswerComparisonFor === 'Trade') {
      const att: QuestionTradeAssignmentModel = new QuestionTradeAssignmentModel()
      if (!this.getEmergencyQuestionModel.questionTrades) {
        this.getEmergencyQuestionModel.questionTrades = []
      }
      this.getEmergencyQuestionModel.questionTrades.push(att)
    } else if (this.emergencyAnswerComparisonFor === 'Asbestos') {
      const att: QuestionAsbestosEffectAssignmentModel = new QuestionAsbestosEffectAssignmentModel()
      if (!this.getEmergencyQuestionModel.questionAsbestosEffects) {
        this.getEmergencyQuestionModel.questionAsbestosEffects = []
      }
      this.getEmergencyQuestionModel.questionAsbestosEffects.push(att)
    }
  }

  private onQuestionChange() {
    // check duplicate questions into list
    if (this.emergencyAnswerComparisonFor === 'Trade') {
      this.hasDuplicateQuestionTrade = false
      if (this.getEmergencyQuestionModel.questionTrades && this.getEmergencyQuestionModel.questionTrades.length > 0) {
        for (const [index, item] of this.getEmergencyQuestionModel.questionTrades.entries()) {
          const element: QuestionTradeAssignmentModel | undefined = this.getEmergencyQuestionModel.questionTrades.find(
            (e: QuestionTradeAssignmentModel, index1) =>
              e.operatorId === item.operatorId && e.comparisonValue === item.comparisonValue && index !== index1
          )
          if (element) {
            this.hasDuplicateQuestionTrade = true
          }
          break
        }
      }
      this.$emit('isHasDuplicateTrade', this.hasDuplicateQuestionTrade)
    } else if (this.emergencyAnswerComparisonFor === 'Asbestos') {
      this.hasDuplicateQuestionAsbestos = false
      if (
        this.getEmergencyQuestionModel.questionAsbestosEffects &&
        this.getEmergencyQuestionModel.questionAsbestosEffects.length > 0
      ) {
        for (const [index, item] of this.getEmergencyQuestionModel.questionAsbestosEffects.entries()) {
          const element: QuestionAsbestosEffectAssignmentModel | undefined =
            this.getEmergencyQuestionModel.questionAsbestosEffects.find(
              (e: QuestionAsbestosEffectAssignmentModel, index1) =>
                e.operatorId === item.operatorId && e.comparisonValue === item.comparisonValue && index !== index1
            )
          if (element) {
            this.hasDuplicateQuestionAsbestos = true
          }
          break
        }
      }
      this.$emit('isHasDuplicateAsbestos', this.hasDuplicateQuestionAsbestos)
    }
  }

  private getdropdownOptions(additionalValues: string | null): string[] {
    if (additionalValues) {
      const items = additionalValues.split('\n')
      if (items && items.length > 0) {
        for (let i = 0, l = items.length; i < l; i++) {
          items[i] = items[i].trim()
        }
        return items
      }
    }
    return []
  }

  // remove policy question trade/asbestos assignment
  private removeQuestion(question: QuestionTradeAssignmentModel | QuestionAsbestosEffectAssignmentModel) {
    if (this.emergencyAnswerComparisonFor === 'Trade') {
      // remove selected trade question
      question = question as QuestionTradeAssignmentModel
      const index = this.getEmergencyQuestionModel.questionTrades.indexOf(question)
      this.getEmergencyQuestionModel.questionTrades.splice(index, 1)
      this.onQuestionChange()
    } else if (this.emergencyAnswerComparisonFor === 'Asbestos') {
      // remove selected asbestos question
      question = question as QuestionAsbestosEffectAssignmentModel
      const index = this.getEmergencyQuestionModel.questionAsbestosEffects.indexOf(question)
      this.getEmergencyQuestionModel.questionAsbestosEffects.splice(index, 1)
      this.onQuestionChange()
    }
  }

  private get getTrades() {
    return this.trades.filter(
      (e) =>
        !e.isDeleted ||
        (e.isDeleted
          ? this.tempEmergencyQuestionModel.questionTrades.findIndex((i) => i.tradeId === e.tradeId) !== -1
          : false)
    )
  }

  private getClass(tradeId: number) {
    const trade = this.trades.find((e) => e.tradeId === tradeId)
    return trade && trade.isDeleted ? 'deleted-item' : ''
  }

  private validateTradeList(label: string, item: any) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (message) {
      return (message = 'The ' + errorMessage[0] + ' is required.')
    } else {
      const trade = this.trades.find((e) => e.isDeleted && e.tradeId === item.tradeId)
      return trade ? (message = 'The selected trade is deleted.') : (message = '')
    }
  }
}
</script>

<style scoped>
.multiule-trade-filed,
.trade-content {
  position: relative;
}
.multiule-trade-filed.add-remove-btn .layout {
  position: relative;
}
.multiule-trade-filed.add-remove-btn .trade-remove {
  position: absolute;
  right: -6px;
  top: -8px;
}
.multiule-trade-filed .trade-add {
  position: absolute;
  right: 0px;
  top: 18px;
}
.multiule-trade-filed.add-remove-btn .trade-add {
  top: auto;
  bottom: 0px;
}
.multiule-trade-filed.add-remove-btn {
  padding-bottom: 30px !important;
}
.section-repeat {
  overflow: visible !important;
}
.erorr-msg {
  position: absolute;
  bottom: 20px;
  left: 4px;
}
</style>
