<template>
  <v-layout v-if="jobDelay" wrap class="mb-2">
    <v-flex xs12>
      <span>
        <b>Reason:</b>
        {{ jobDelay.reason }}
      </span>
    </v-flex>
    <v-flex v-if="jobDelay.notes" xs12 mt-1>
      <span>
        <b>Note:</b>
        {{ jobDelay.notes }}
      </span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
@Component
export default class JobDelayCard extends TimeLineItemBase {
  private get jobDelay() {
    return this.job && this.job.jobType === 'SI'
      ? storeGetters.getSIJobDelay(this.jobId)
      : storeGetters.getUSJobDelay(this.jobId)
  }
}
</script>
