import StoreWrapper from '@/store'
import { ApplicationInsights, IPageViewTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { Profile } from 'oidc-client'
import Vue, { PluginObject, VueConstructor } from 'vue'
import VueRouter from 'vue-router'

function addUserProperties(telemetry: ITelemetryItem): void {
  telemetry.data = telemetry.data || {}

  // Defaults
  telemetry.data.DetailRecordId = null
  telemetry.data.DetailRecordType = 'N/A'
  telemetry.data.UserName = 'Not Logged In'
  telemetry.data.Email = null
  telemetry.data.IdentityProviderUserId = null

  const store = StoreWrapper.Instance
  const state = store ? store.state : null

  const oidcUser: Profile | null = store ? store.getters['oidcUser'] : null
  if (oidcUser) {
    telemetry.data.DetailRecordId = oidcUser.extension_SimplifiUserID
    telemetry.data.DetailRecordType = oidcUser.extension_SimplifiUserType
    telemetry.data.UserName = oidcUser.name
    telemetry.data.Email = oidcUser.email
    telemetry.data.IdentityProviderUserId = oidcUser.sub
  }

  const session = state ? state.SessionDetail : null
  if (session) {
    telemetry.data.DetailRecordId = session.detailRecordId
    telemetry.data.DetailRecordType = session.detailRecordType
    telemetry.data.UserName = session.userName
    telemetry.data.Email = session.userEmail
  }
}

export function createAppInsights(): ApplicationInsights {
  const ai = new ApplicationInsights({ config: { connectionString: process.env.VUE_APP_AppInsightsConnectionString } })
  ai.loadAppInsights()
  ai.addTelemetryInitializer(addUserProperties)
  return ai
}

export interface AppInsightsPluginConfig {
  appInsights: ApplicationInsights
  router: VueRouter
}

export const AppInsightsPlugin: PluginObject<AppInsightsPluginConfig> = {
  install(vue: VueConstructor<Vue>, config: AppInsightsPluginConfig | undefined) {
    if (!config) {
      throw new Error('Parameter instance is not optional.')
    }

    config.router.beforeEach((to, from, next) => {
      const pageView: IPageViewTelemetry = {
        name: to.name,
        refUri: from.fullPath,
        uri: to.fullPath,
      }
      config.appInsights.trackPageView(pageView)
      next()
    })

    vue.prototype.$ai = config.appInsights
  },
}
