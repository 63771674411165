import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import DelayResult from '@/models/delay/DelayResult'
import DelayCode from '@/models/delay/DelayCode'
import TradeDelay from '@/models/delay/TradeDelay'
import IDelayRule from '@/models/delay/interfaces/delay-rule'

export default class DelayController {
  public static async CalculateDelay(jobId: string, emergencyId: string): Promise<DelayResult> {
    const res: AxiosResponse = await CallCentreApi.get(
      `Delay/CalculateDelay?jobId=${encodeURIComponent(jobId)}&emergencyId=${encodeURIComponent(emergencyId)}`
    )
    if (res.status === 200) {
      return res.data
    } else {
      return new DelayResult()
    }
  }

  public static async RetrieveDelayCodes(): Promise<DelayCode[]> {
    const res: AxiosResponse = await CallCentreApi.get('Delay/GetDelayCodes')
    return res.status === 200 ? res.data : []
  }

  public static async CreateDelayCode(delayCode: DelayCode): Promise<DelayCode> {
    const res: AxiosResponse = await CallCentreApi.post('Delay/CreateDelayCode', delayCode)
    return res.status === 200 ? res.data : new DelayCode()
  }

  public static async UpdateDelayCode(delayCode: DelayCode): Promise<DelayCode> {
    const res: AxiosResponse = await CallCentreApi.patch('Delay/UpdateDelayCode', delayCode)
    return res.status === 200 ? res.data : new DelayCode()
  }

  public static async DeleteDelayCode(delayCodeId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(`Delay/DeleteDelayCode?delayCodeId=${delayCodeId}`)
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateTradeDelay(tradeDelay: TradeDelay): Promise<TradeDelay | null> {
    const res: AxiosResponse = await CallCentreApi.patch('Delay/UpdateTradeDelay', tradeDelay)
    return res.status === 200 ? res.data : null
  }

  public static async GetTradeDelays(): Promise<TradeDelay[] | null> {
    const res: AxiosResponse = await CallCentreApi.get('Delay/GetTradeDelays')
    return res.status === 200 ? res.data : null
  }

  public static async GetDelayRules(): Promise<IDelayRule[] | null> {
    const res: AxiosResponse = await CallCentreApi.get('Delay/GetDelayRules')
    return res.status === 200 ? (res.data as IDelayRule[]) : null
  }
}
