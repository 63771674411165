<template>
  <v-card v-if="item">
    <v-card-title>
      <h3 class="heading">Linked Phone Call</h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <span>
        <b>Reason:</b>
        <span>{{ item.reasonType }}</span>
      </span>
      <br />
      <template v-if="item.reasonDescription">
        <span>
          <b>Description:</b>
          <span>{{ item.reasonDescription }}</span>
        </span>
        <br />
      </template>
      <span>
        <b>Contractor:</b>
        <span>{{ item.companyName }}</span>
      </span>
      <br />
      <span>
        <b>Call At:</b>
        <span>{{ getFormattedDate(item.createdAt) }}</span>
      </span>
    </v-card-text>
    <v-layout>
      <v-flex xs12>
        <v-divider></v-divider>
        <LinkedPhoneCallDetails
          :recording-url="item.callRecordingUrl"
          :call-sid="item.callSid"
          :job-id="item.jobId"
          :show-header="false"
        ></LinkedPhoneCallDetails>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import LinkedPhoneCallDetails from '@/components/timeline/LinkedPhoneCallDetails.vue'
import eventBus from '@/common/bus'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'

@Component({
  components: { LinkedPhoneCallDetails },
})
export default class FailedToContactCallPreview extends TimeLineItemBase {
  private item: ContractorAppointedModel | null = null

  private mounted() {
    eventBus.$on('showLinkedContractorCall', (item: ContractorAppointedModel) => {
      this.item = item
    })
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
