<template>
  <v-layout wrap>
    <v-flex xs12>
      <div v-if="trialPitData.length > 0">
        <v-expansion-panel v-model="selectedPanel" class="elevation-0">
          <v-expansion-panel-content
            v-for="(trialPit, index) in trialPitData"
            :key="index"
            class="trialpit-accordion grey lighten-5 mb-2"
            :class="getClassName(trialPit.status)"
          >
            <h3 slot="header" class="expansion-header btn-trialPitDetail">
              Trial Pit Details for {{ trialPit.pitId }}
            </h3>
            <v-divider></v-divider>
            <v-card class="grey lighten-5">
              <v-card-text>
                <TrialPitDrawingWrapper
                  v-if="index === currentIndex"
                  :ref="trialPitDrawingRef + (index + 1)"
                ></TrialPitDrawingWrapper>
                <div v-if="!isTrialPitAbandoned(trialPit)" class="text-xs-right">
                  <v-btn
                    class="mb-1 mr-0 btn-saveDrawingDetail"
                    color="primary"
                    :loading="loading.trialPitDrawingUpdates"
                    @click="saveTrialPitDrawingDetails(trialPit, index)"
                  >
                    Save Pit Drawing
                  </v-btn>
                </div>
                <b class="my-3 d-block">
                  <span class="secondary--text mr-2">Bore Hole Depth:</span>
                  {{ trialPit.boreHoleDepth }}m
                </b>
                <v-data-table
                  :headers="headers"
                  :items="
                    trialPit.productChildLinks && trialPit.productChildLinks.length > 0
                      ? trialPit.productChildLinks
                      : []
                  "
                  hide-actions
                  class="gridView gridView-full-width trial-data-table"
                >
                  <template slot="items" slot-scope="props">
                    <td>
                      <v-switch
                        v-model="props.item.isSelected"
                        :disabled="isTrialPitAbandoned(trialPit)"
                        color="primary"
                        :label="props.item.name"
                        value=""
                        @change="changeTestLinkState($event, props.item, trialPit.pitTests)"
                      ></v-switch>
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="props.item.quantity"
                        v-validate="'numeric|min_value:1'"
                        type="number"
                        name="quantity"
                        :readonly="isTrialPitAbandoned(trialPit)"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="text-xs-right">
                      <v-text-field
                        v-model.trim="props.item.notes"
                        :readonly="isTrialPitAbandoned(trialPit)"
                      ></v-text-field>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <div v-if="trialPit.boreHoleLogs">
              <v-expansion-panel
                v-for="(boreHoleLog, index1) in trialPit.boreHoleLogs"
                :key="index1"
                class="sitedata-accordion elevation-0 px-3"
              >
                <v-expansion-panel-content class="white mb-2">
                  <v-layout slot="header" wrap class="align-center">
                    <v-flex xs6>
                      <h3 slot="header" class="px-2 header-borehole">
                        Bore Hole Log {{ boreHoleLog.boreHoleRunNumber }}
                      </h3>
                    </v-flex>
                    <v-flex v-if="!isTrialPitAbandoned(trialPit)" xs6 class="text-xs-right pr-4">
                      <v-btn
                        flat
                        icon
                        color="primary"
                        class="ma-0 close-icon btn-editBoreHoleDetail"
                        @click.native.stop="editBoreHoleDetails(trialPit, boreHoleLog)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <v-btn
                        flat
                        icon
                        color="secondary"
                        class="ma-0 close-icon btn-deleteBoreHoleDetail"
                        @click.stop="onDeleteBoreHoleDetails(trialPit, boreHoleLog)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                  <v-card class="white">
                    <v-layout wrap px-3 pt-3 pb-2>
                      <v-flex lg6 xs12 mb-2>
                        <label><b>Boring Method:</b></label>
                        <span class="ml-1">{{ boreHoleLog.boringMethod }}</span>
                      </v-flex>
                      <v-flex lg6 xs12 mb-2>
                        <label><b>Ground Level:</b></label>
                        <span class="ml-1">{{ boreHoleLog.groundLevel }}</span>
                      </v-flex>
                      <v-flex lg6 xs12 mb-2>
                        <label><b>Diameter:</b></label>
                        <span class="ml-1">{{ boreHoleLog.diameter }}</span>
                      </v-flex>
                      <v-flex lg6 xs12 mb-2>
                        <label><b>Weather:</b></label>
                        <span class="ml-1">{{ boreHoleLog.weather }}</span>
                      </v-flex>
                    </v-layout>
                    <div>
                      <v-card-text v-if="boreHoleLog.changes">
                        <v-card
                          v-for="(changes, index2) in boreHoleLog.changes"
                          :key="index2"
                          class="elevation-0 mb-2 grey lighten-5"
                        >
                          <v-card-text class="py-2">
                            <v-layout wrap>
                              <v-flex xl4 lg6 xs12 my-1>
                                <label><b>Depth:</b></label>
                                <span class="ml-1">{{ changes.depth }}</span>
                              </v-flex>
                              <v-flex xl4 lg6 xs12 my-1>
                                <label><b>Thickness:</b></label>
                                <span class="ml-1">
                                  {{ changes.thickness }}
                                </span>
                              </v-flex>
                              <v-flex xl4 lg6 xs12 my-1>
                                <label><b>Test:</b></label>
                                <span class="ml-1">{{ changes.testType }}</span>
                              </v-flex>
                              <v-flex xl4 lg6 xs12 my-1>
                                <label><b>Result:</b></label>
                                <span class="ml-1">
                                  {{ changes.testResult }}
                                </span>
                              </v-flex>
                              <v-flex xl4 lg6 xs12 my-1>
                                <label><b>Legend:</b></label>
                                <span class="ml-1">{{ changes.legend }}</span>
                              </v-flex>
                              <v-flex xl4 lg6 xs12 my-1>
                                <label><b>Soil Description:</b></label>
                                <span class="ml-1">
                                  {{ changes.soilDescription }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </div>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </div>
            <div v-if="isTrialPitAbandoned(trialPit)" class="pa-3 grey lighten-4 mx-3 mt-2 mb-3 abandon-detail">
              <div class="pb-1">
                <label><b>Notes:</b></label>
                <span class="ml-1">
                  {{ trialPit.notes ? trialPit.notes : '-' }}
                </span>
              </div>
              <div class="pb-1">
                <label><b>Abandon Reason:</b></label>
                <span class="ml-1">{{ trialPit.abandonedTrialPitReason }}</span>
              </div>
              <div v-if="trialPit.abandonedTrialPitReasonNotes">
                <label><b>Abandon Note:</b></label>
                <span class="ml-1">
                  {{ trialPit.abandonedTrialPitReasonNotes }}
                </span>
              </div>
            </div>
            <div v-if="!isTrialPitAbandoned(trialPit)">
              <div class="text-xs-right">
                <v-btn class="mr-3 mb-3 btn-addBoreHoleDetail" color="primary" @click="addBoreHoleDetails(trialPit)">
                  Add Bore Hole Log
                </v-btn>
              </div>
              <div class="pa-3 grey lighten-4 mx-3 mb-2">
                <v-textarea v-model.trim="trialPit.notes" label="Notes" maxlength="2000" rows="3"></v-textarea>
              </div>
              <div class="text-xs-right mb-2">
                <v-btn
                  class="mr-3 mb-2 ml-0 btn-saveTrialPit"
                  color="primary"
                  :loading="loading.trialPitSaveUpdates"
                  @click="saveTrialPitDetails(trialPit)"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="trialPit.status !== siJobProductProgress[siJobProductProgress.Finished]"
                  class="mr-3 mb-2 ml-0 white--text btn-completeTrialPit"
                  color="green"
                  :loading="loading.trialPitCompleteUpdates"
                  @click="completeTrialPit(trialPit)"
                >
                  Complete
                </v-btn>
                <v-btn
                  v-if="!isTrialPitAbandoned(trialPit)"
                  class="mr-3 ml-0 mt-0 white--text btn-abandonTrialPit"
                  color="red"
                  @click.native="openAbandonReasonDialog(trialPit)"
                >
                  Abandon
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </div>
      <span v-else>No Data Found</span>
    </v-flex>

    <v-dialog
      v-if="boreHoleDialog"
      v-model="boreHoleDialog"
      max-width="850"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>{{ isAddBoreHole ? 'Add' : 'Edit' }} Bore Hole Log</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="btn-closeBoreHoleDetai" @click="discardBoreHoleChangesConfirmation">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-4 pt-3">
          <v-container fluid grid-list-lg pa-0>
            <v-layout wrap>
              <v-flex xs6 py-0>
                <v-select
                  v-model="selectedBoreHoleLogDetails.boringMethod"
                  v-validate="'required'"
                  :items="getBoreHoleLogConfiguration.boringMethods"
                  item-value="value"
                  item-text="value"
                  label="Boring Method"
                  class="required"
                  required
                  data-vv-name="Boring Method"
                  :error-messages="validationMessage('Boring Method')"
                  data-vv-scope="selectedBoreHoleLog"
                ></v-select>
              </v-flex>
              <v-flex xs6 py-0>
                <v-text-field
                  v-model.number="selectedBoreHoleLogDetails.groundLevel"
                  v-validate="'required|min_value:0'"
                  label="Ground Level"
                  required
                  class="required"
                  data-vv-scope="selectedBoreHoleLog"
                  data-vv-name="Ground Level"
                  name="Ground Level"
                  :error-messages="errors.collect('Ground Level')"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 py-0>
                <v-select
                  v-model="selectedBoreHoleLogDetails.diameter"
                  v-validate="'required'"
                  label="Diameter"
                  :items="getBoreHoleLogConfiguration.diameters"
                  required
                  class="required"
                  data-vv-scope="selectedBoreHoleLog"
                  data-vv-name="Diameter"
                  name="Diameter"
                  :error-messages="validationMessage('Diameter')"
                ></v-select>
              </v-flex>
              <v-flex xs6 py-0>
                <v-select
                  v-model="selectedBoreHoleLogDetails.weather"
                  v-validate="'required'"
                  label="Weather"
                  :items="getBoreHoleLogConfiguration.weathers"
                  required
                  class="required"
                  data-vv-scope="selectedBoreHoleLog"
                  data-vv-name="Weather"
                  name="Weather"
                  :error-messages="validationMessage('Weather')"
                ></v-select>
              </v-flex>
            </v-layout>
            <div v-if="selectedBoreHoleLogDetails.changes" class="changesWizard">
              <v-card
                v-for="(changes, index4) in selectedBoreHoleLogDetails.changes"
                :key="index4"
                class="grey lighten-4 elevation-0 mt-2 layer-card"
              >
                <v-btn
                  flat
                  icon
                  color="secondary"
                  class="ma-0 close-icon btn-deleteLayer"
                  @click="confirmLayerDelete(changes.depth, index4)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-card-text class="pt-4">
                  <v-layout wrap>
                    <v-flex xs4 py-0>
                      <v-text-field
                        v-model.number="changes.depth"
                        v-validate="{
                          rules: {
                            required: true,
                            min_value: 0,
                            max_value: getTrialPitDrawingDepthById(
                              selectedTrialPitData.trialPitDrawingId,
                              selectedTrialPitData.boreHoleDepth
                            ),
                            decimal: 2,
                          },
                        }"
                        name="Depth"
                        label="Depth (m)"
                        required
                        class="required"
                        data-vv-scope="selectedBoreHoleLog"
                        :data-vv-name="'Depth' + index4"
                        :error-messages="validationMessage('Depth' + index4)"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs4 py-0>
                      <v-text-field
                        v-model.number="changes.thickness"
                        v-validate="'min_value:0|decimal:2'"
                        name="Thickness"
                        label="Thickness (m)"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs4 py-0>
                      <v-select
                        v-model="changes.testType"
                        label="Test"
                        :items="getBoreHoleLogConfiguration.testTypes"
                        name="Test"
                      ></v-select>
                    </v-flex>
                    <v-flex xs6 py-0>
                      <v-text-field
                        v-model="changes.testResult"
                        v-validate="changes.testType ? 'required' : ''"
                        name="Result"
                        label="Result"
                        required
                        data-vv-scope="selectedBoreHoleLog"
                        :class="changes.testType ? 'required' : ''"
                        :data-vv-name="'Result' + index4"
                        :error-messages="validationMessage('Result' + index4)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 py-0>
                      <v-autocomplete
                        v-model="changes.legend"
                        label="Legend"
                        name="Legend"
                        maxlength="20"
                        :items="getBoreHoleLogConfiguration.legends"
                        :error-messages="validationMessage('Legend' + index4)"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 py-0>
                      <v-textarea v-model="changes.soilDescription" label="Soil Description"></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </div>
            <div class="text-xs-right">
              <v-btn
                class="mt-3 ma-0 btn-addLayer"
                color="primary"
                :loading="loading.boreHoleUpdates"
                @click="addLayerToBoreHole"
              >
                Add Layer
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            class="btn-closeBoreHoleDetail"
            color="primary"
            flat="flat"
            @click.native="discardBoreHoleChangesConfirmation"
          >
            Discard
          </v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-addUpdateBoreHole"
            :loading="loading.selectedBoreHoleLog"
            @click.native="addBoreHoleToList"
          >
            {{ isAddBoreHole ? 'Add' : 'Update' }} Bore Hole
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add abandon reason -->
    <AbandonSiteDataDialog
      v-if="showAbandonReasonDialog"
      ref="abandonSiteDataDialog"
      :record-type="abandonRecordType"
      :abandon-reason-details="abandonReasonDetails"
      @abandonReasonSave="onAbandonReasonSave"
      @closeAbandonReasonDialog="showAbandonReasonDialog = false"
    ></AbandonSiteDataDialog>
    <!-- </v-dialog> -->
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import SIVisitTrialPit from '../../models/siteInvestigation/SIVisitTrialPit'
import Job from '../../models/Job'
import BoreHoleLog from '../../models/siteInvestigation/BoreHoleLog'
import Changes from '../../models/siteInvestigation/Changes'
import AddBoreHoleLog from '../../models/siteInvestigation/requests/AddBoreHoleLog'
import SiteInvestigationController from '../../api/siteInvestigationController'
import UpdateTrialPitDetail from '../../models/siteInvestigation/requests/UpdateTrialPitDetail'
import { SIJobProductProgress, RecordType, SIProductType } from '../../common/enums'
import SIVisitPitTestDetail from '../../models/siteInvestigation/SIVisitPitTestDetail'
import SIBoreHoleLogConfiguration from '../../models/siteInvestigation/SIBoreHoleLogConfiguration'
import store from '@/store'
import TrialPitDrawingWrapper from '@/components/TrialPitDrawingWrapper.vue'
import TrialPitDrawingModel from '@/models/siteInvestigation/TrialPitDrawingModel'
import AddTrialPitDrawingModel from '@/models/siteInvestigation/requests/AddTrialPitDrawingModel'
import TestDetail from '../../models/siteInvestigation/TestDetail'
import PitDetailModel from '../../models/siteInvestigation/PitDetailModel'
import DeleteBoreholeLogModel from '../../models/requests/DeleteBoreholeLogModel'
import AbandonSiteDataDialog from '@/components/siteInvestigation/AbandonSiteDataDialog.vue'
import AddAbandonedSIProductRequest from '@/models/siteInvestigation/requests/AddAbandonedSIProductRequest'

@Component({
  components: { TrialPitDrawingWrapper, AbandonSiteDataDialog },
})
export default class SitePitDetailLog extends Vue {
  @Prop() private jobId: string
  @Prop() private engineerVisitDetailId: string
  private loading: Record<string, boolean> = {
    boreHoleUpdates: false,
    trialPitSaveUpdates: false,
    trialPitCompleteUpdates: false,
    trialPitAbandonListGet: false,
    trialPitAbandonUpdates: false,
    trialPitDrawingUpdates: false,
  }
  private trialPitData: SIVisitTrialPit[] = []
  private selectedTrialPitData: SIVisitTrialPit = new SIVisitTrialPit()
  private selectedBoreHoleLogDetails: BoreHoleLog = new BoreHoleLog()
  private newChangesDetails: Changes = new Changes()
  private isAddBoreHole = true
  private boreHoleDialog = false
  private boreHoleLogConfiguration: SIBoreHoleLogConfiguration | null = null
  private showAbandonReasonDialog = false
  private selectedAbandonDetails: any = { type: '', reason: '' }
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private headers: any = [
    {
      text: 'Onsite Tests',
      align: 'left',
      sortable: false,
      value: 'name',
      class: 'name-col',
    },
    {
      text: 'Qty',
      align: 'left',
      value: 'oty',
      sortable: false,
      class: 'qty-col',
    },
    {
      text: 'Notes',
      align: 'left',
      value: 'notes',
      sortable: false,
      class: 'notes-col',
    },
  ]
  private siJobProductProgress = SIJobProductProgress
  private currentIndex = -1
  private trialPitDrawingRef = 'refTrialPitDrawing'
  private abandonRecordType = RecordType[RecordType.AbandonTrialPitReason]
  private abandonReasonDetails: AddAbandonedSIProductRequest = new AddAbandonedSIProductRequest()
  private selectedPanel = -1

  private created() {
    this.getTrialPitData()
  }

  private get getTrialPitDetails() {
    return Shared.SortData(storeGetters.getTrialPitDetail(this.jobId, this.engineerVisitDetailId), 'pitId')
  }

  private get getTrialPitDrawingDetails(): TrialPitDrawingModel[] {
    return storeGetters.getTrialPitDrawing(this.jobId)
  }

  private getTrialPitDrawingDepthById(id: string, depth = 0): number {
    const trialPitDrawingDetails: TrialPitDrawingModel | undefined = this.getTrialPitDrawingDetails!.find(
      (e) => e.id === id
    )
    if (trialPitDrawingDetails) {
      const totalDepth: number =
        Math.round((trialPitDrawingDetails.pitDetail.depth / 1000 + depth + Number.EPSILON) * 100) / 100
      return totalDepth
    }
    return 0
  }

  @Watch('getTrialPitDetails', { deep: true })
  private getTrialPitData() {
    this.trialPitData = JSON.parse(JSON.stringify(this.getTrialPitDetails))
    if (this.trialPitData && this.trialPitData.length > 0) {
      this.trialPitData!.forEach((pitDetails) => {
        this.getBoreHoleLogList(pitDetails)
      })
      this.GetBoreHoleLogConfiguration()
    }
  }

  private getBoreHoleLogList(pitDetails: SIVisitTrialPit) {
    if (!this.trialPitData || this.trialPitData.length < 1) {
      return
    }
    const jobDetails: Job | null = storeGetters.getJob(this.jobId)
    if (jobDetails!.sIJobDetail!.jobPackage!.packageInfo!.productList) {
      let productList = jobDetails!.sIJobDetail!.jobPackage!.packageInfo!.productList.find(
        (x) => x.pitId === pitDetails.pitId
      )
      if (!productList) {
        productList = jobDetails!.sIJobDetail!.jobPackage!.extraProducts.find(
          (x) => x.pitId !== undefined && x.pitId === pitDetails.pitId
        )
      }
      if (productList && productList.productChildLinks) {
        const productChildLinksList: SIVisitPitTestDetail[] = []
        productList.productChildLinks.forEach((element) => {
          let pitAlreadyExists = false
          let pitReadyToPush: SIVisitPitTestDetail | undefined = pitDetails.pitTests.find((x) => x.id === element.id)
          if (!pitReadyToPush) {
            pitAlreadyExists = false
            pitReadyToPush = new SIVisitPitTestDetail()
            pitReadyToPush.id = element.id
            pitReadyToPush.name = element.name
            pitReadyToPush.quantity = element.quantity
            pitReadyToPush.notes = element.notes
          } else {
            pitAlreadyExists = true
          }
          pitReadyToPush.isSelected = pitAlreadyExists ? true : false
          productChildLinksList.push(pitReadyToPush)
        })
        pitDetails.productChildLinks = productChildLinksList
      }
    }
    return []
  }

  private async GetBoreHoleLogConfiguration() {
    this.boreHoleLogConfiguration = await SiteInvestigationController.GetBoreHoleLogConfiguration()
  }

  private get getBoreHoleLogConfiguration(): SIBoreHoleLogConfiguration | null {
    return this.boreHoleLogConfiguration ? this.boreHoleLogConfiguration : null
  }

  private addBoreHoleDetails(trialPitData: SIVisitTrialPit) {
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
    this.selectedBoreHoleLogDetails = new BoreHoleLog()
    this.selectedTrialPitData = JSON.parse(JSON.stringify(trialPitData))
    this.isAddBoreHole = true
    this.boreHoleDialog = true
  }

  private editBoreHoleDetails(trialPitData: SIVisitTrialPit, boreHoleLog: BoreHoleLog) {
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
    this.selectedBoreHoleLogDetails = JSON.parse(JSON.stringify(boreHoleLog))
    this.selectedTrialPitData = JSON.parse(JSON.stringify(trialPitData))
    this.isAddBoreHole = false
    this.boreHoleDialog = true
  }

  private addLayerToBoreHole() {
    const self = this
    this.validate('selectedBoreHoleLog').then((result) => {
      if (result) {
        const newCharge: Changes = new Changes()
        if (self.selectedBoreHoleLogDetails.changes.length > 0) {
          const tempCharge: Changes =
            self.selectedBoreHoleLogDetails.changes[self.selectedBoreHoleLogDetails.changes.length - 1]
          const thickness: number = tempCharge.thickness ? tempCharge.thickness : 0
          const totalDepth: number = this.getTrialPitDrawingDepthById(
            this.selectedTrialPitData.trialPitDrawingId,
            this.selectedTrialPitData.boreHoleDepth
          )
          newCharge.depth =
            tempCharge.depth + thickness > totalDepth
              ? totalDepth
              : Math.round((tempCharge.depth + thickness + Number.EPSILON) * 100) / 100
        } else {
          newCharge.depth = this.getTrialPitDrawingDepthById(this.selectedTrialPitData.trialPitDrawingId)
        }
        self.selectedBoreHoleLogDetails.changes.push(newCharge)
      }
    })
  }

  private async validate(formref: any): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(formref)
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private validationMessage(label: string) {
    const message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (message) {
      const isNullMessage: boolean = message.indexOf('field is required') !== -1
      if (!isNullMessage) {
        return message.replace(label, errorMessage[0])
      } else {
        return 'The ' + errorMessage[0] + ' is required.'
      }
    }
    return message
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private addBoreHoleToList() {
    this.validate('selectedBoreHoleLog').then((result) => {
      if (result && this.trialPitData.length > 0) {
        const selectedPitData: SIVisitTrialPit | undefined = this.trialPitData.find(
          (x) => x.pitId === this.selectedTrialPitData.pitId
        )
        if (selectedPitData) {
          if (this.isAddBoreHole) {
            let highestBoreHoleRunNumber = 0
            if (selectedPitData.boreHoleLogs && selectedPitData.boreHoleLogs.length > 0) {
              highestBoreHoleRunNumber = Math.max.apply(
                Math,
                selectedPitData.boreHoleLogs.map((o) => o.boreHoleRunNumber)
              )
            }
            this.selectedBoreHoleLogDetails.boreHoleRunNumber = highestBoreHoleRunNumber + 1
          }
          this.updateBoreHole()
        }
      }
    })
  }

  private async updateBoreHole() {
    this.$set(this.loading, 'selectedBoreHoleLog', true)

    const addBoreHoleLog: AddBoreHoleLog = Object.assign(new AddBoreHoleLog(), this.selectedBoreHoleLogDetails)
    addBoreHoleLog.jobId = this.jobId
    addBoreHoleLog.pitId = this.selectedTrialPitData.pitId
    const response = await SiteInvestigationController.UpsertSIVisitTrialPitBoreHoleLogs(addBoreHoleLog)
    if (response) {
      this.boreHoleDialog = false
      this.showMessage('Bore hole log details saved successfully')
    } else {
      this.showMessage('Something went wrong')
    }

    this.$set(this.loading, 'selectedBoreHoleLog', false)
  }

  private discardBoreHoleChangesConfirmation() {
    Shared.confirmationPopup.open(
      'Are you sure you want to discard the changes?',
      '',
      '',
      'No',
      'Yes',
      this,
      'discardBoreHoleChanges',
      true
    )
  }

  private discardBoreHoleChanges() {
    this.selectedBoreHoleLogDetails = new BoreHoleLog()
    this.selectedTrialPitData = new SIVisitTrialPit()
    this.isAddBoreHole = true
    this.boreHoleDialog = false
  }

  private async saveTrialPitDetails(trialPit: SIVisitTrialPit) {
    this.$set(this.loading, 'trialPitSaveUpdates', true)

    const updateTrialPitDetail: UpdateTrialPitDetail = Object.assign(new UpdateTrialPitDetail(), trialPit)
    updateTrialPitDetail.jobId = this.jobId
    updateTrialPitDetail.id = trialPit.id
    const response = await SiteInvestigationController.UpdateSIVisitTrialPit(updateTrialPitDetail)
    if (response) {
      this.showMessage('Trial pit details saved successfully')
    } else {
      this.showMessage('Something went wrong')
    }

    this.$set(this.loading, 'trialPitSaveUpdates', false)
  }

  private async completeTrialPit(trialPit: SIVisitTrialPit) {
    this.$set(this.loading, 'trialPitCompleteUpdates', true)

    const updateTrialPitDetail: UpdateTrialPitDetail = Object.assign(new UpdateTrialPitDetail(), trialPit)
    updateTrialPitDetail.jobId = this.jobId
    updateTrialPitDetail.id = trialPit.id
    updateTrialPitDetail.pitId = trialPit.pitId
    updateTrialPitDetail.status = SIJobProductProgress[SIJobProductProgress.Finished]
    const response = await SiteInvestigationController.UpdateSIVisitTrialPit(updateTrialPitDetail)
    if (response) {
      this.showMessage('Trial pit details saved successfully')
    } else {
      this.showMessage('Something went wrong')
    }

    this.$set(this.loading, 'trialPitCompleteUpdates', false)
  }

  private async openAbandonReasonDialog(trialPit: SIVisitTrialPit) {
    this.selectedTrialPitData = trialPit
    this.abandonReasonDetails.jobId = this.jobId
    this.abandonReasonDetails.id = this.selectedTrialPitData.id
    this.abandonReasonDetails.forEngineerVisitDetailId = this.selectedTrialPitData.forEngineerVisitDetailId
    this.abandonReasonDetails.siProductType = SIProductType[SIProductType.Pit]
    this.showAbandonReasonDialog = true
  }

  private async onAbandonReasonSave(result: boolean) {
    this.showAbandonReasonDialog = false
    if (result) {
      this.showMessage('Trial pit has been abandoned')
      this.selectedTrialPitData = new SIVisitTrialPit()
    } else {
      this.showMessage('Something went wrong')
    }
  }

  private changeTestLinkState(event, selectedPitTest, selectedPit) {
    selectedPitTest.isSelected = event
    const index: number = selectedPit.findIndex((x) => x.id === selectedPitTest.id)
    if (event && index === -1) {
      selectedPit.push(selectedPitTest)
    } else if (!event && index !== -1) {
      selectedPit.splice(index, 1)
    }
  }

  private confirmLayerDelete(depth: number, index: number) {
    const message = 'Are you sure you want to delete layer'
    const subMessage: string = depth ? ' with depth - ' + depth : ''
    Shared.confirmationPopup.open(message + subMessage + '?', '', '', 'No', 'Yes', this, 'deleteLayer', index)
  }

  private deleteLayer(index: number) {
    this.selectedBoreHoleLogDetails.changes.splice(index, 1)
  }

  private isTrialPitAbandoned(trialPit: SIVisitTrialPit): boolean {
    return trialPit.status === SIJobProductProgress[SIJobProductProgress.Abandoned] ? true : false
  }

  private showMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private getClassName(status: string): string {
    if (status === SIJobProductProgress[SIJobProductProgress.Finished]) {
      return 'finished'
    } else if (status === SIJobProductProgress[SIJobProductProgress.Abandoned]) {
      return 'lightcoral'
    }
    return ''
  }

  private onTrialPitDetailClick(id: string, pitId: string, index: number) {
    this.currentIndex = index
    setTimeout(async () => {
      // get index for multiple drawing
      const trialPitDrawingRef = this.trialPitDrawingRef + (index + 1)
      const trialPitDrawingWrapperSub = this.$refs[trialPitDrawingRef] as TrialPitDrawingWrapper[]
      if (trialPitDrawingWrapperSub.length > 0) {
        const res = await trialPitDrawingWrapperSub[0].checkIsTrialPitDrawingLoaded(true)
        if (res) {
          const trialPitDrawingDetails: TrialPitDrawingModel | undefined = this.getTrialPitDrawingDetails
            ? this.getTrialPitDrawingDetails.find((e) => e.id === id)
            : undefined
          if (trialPitDrawingWrapperSub[0]) {
            const jsonData: TrialPitDrawingModel = new TrialPitDrawingModel()
            if (trialPitDrawingDetails) {
              jsonData.testDetails = trialPitDrawingDetails.testDetails
              jsonData.testDetails.testId = pitId
              jsonData.pitDetail = trialPitDrawingDetails.pitDetail
              jsonData.leftLayers = trialPitDrawingDetails.leftLayers
              jsonData.rightLayers = trialPitDrawingDetails.rightLayers
              jsonData.features = trialPitDrawingDetails.features
              jsonData.tests = trialPitDrawingDetails.tests
              trialPitDrawingWrapperSub[0].setTrialPitJson(JSON.stringify(jsonData))
            } else {
              const trialPitJson = await trialPitDrawingWrapperSub[0].getTrialPitJson()
              if (trialPitJson) {
                const trialPitDrawingData = JSON.parse(trialPitJson)
                trialPitDrawingData.testDetails.testId = pitId
                trialPitDrawingWrapperSub[0].setTrialPitJson(JSON.stringify(trialPitDrawingData))
              }
            }
          }
        }
      }
    }, 0)
  }

  private async saveTrialPitDrawingDetails(trialPit: SIVisitTrialPit, index: number) {
    // get index for multiple drawing
    const trialPitDrawingRef = this.trialPitDrawingRef + (index + 1)
    const trialPitDrawingWrapperSub = this.$refs[trialPitDrawingRef] as TrialPitDrawingWrapper[]
    if (trialPitDrawingWrapperSub.length > 0) {
      const imageFile = await trialPitDrawingWrapperSub[0].getTrialPitImage()
      const res = await trialPitDrawingWrapperSub[0].getTrialPitJson()
      if (res) {
        const trialPitDrawingData = JSON.parse(res)
        const trialPitDrawingModel: AddTrialPitDrawingModel = new AddTrialPitDrawingModel()
        if (trialPit.trialPitDrawingId) {
          trialPitDrawingModel.id = trialPit.trialPitDrawingId
        }
        trialPitDrawingModel.jobId = trialPit.jobId
        trialPitDrawingModel.pitId = trialPit.id
        trialPitDrawingModel.forEngineerVisitDetailId = trialPit.forEngineerVisitDetailId
        trialPitDrawingModel.testDetails = trialPitDrawingData.testDetails
        trialPitDrawingModel.pitDetail = trialPitDrawingData.pitDetail
        trialPitDrawingModel.leftLayers = trialPitDrawingData.leftLayers
        trialPitDrawingModel.rightLayers = trialPitDrawingData.rightLayers
        trialPitDrawingModel.features = trialPitDrawingData.features
        trialPitDrawingModel.tests = trialPitDrawingData.tests
        trialPitDrawingModel.fileUrl = trialPitDrawingData.fileUrl
        trialPitDrawingModel.imageFile = imageFile
        this.$set(this.loading, 'trialPitDrawingUpdates', true)
        const response = await SiteInvestigationController.UploadTrialPitDrawing(trialPitDrawingModel)
        if (response) {
          this.showMessage('Trail pit drawing details saved successfully')
        } else {
          this.showMessage('Something went wrong')
        }
        this.$set(this.loading, 'trialPitDrawingUpdates', false)
      }
    }
  }

  private async onDeleteBoreHoleDetails(trialPitData: SIVisitTrialPit, boreHoleLog: BoreHoleLog) {
    const deleteBoreholeLog: DeleteBoreholeLogModel = new DeleteBoreholeLogModel()
    deleteBoreholeLog.id = trialPitData.id
    deleteBoreholeLog.jobId = this.jobId
    deleteBoreholeLog.engineerVisitDetailId = this.engineerVisitDetailId
    deleteBoreholeLog.boreHoleRunNumber = boreHoleLog.boreHoleRunNumber
    Shared.confirmationPopup.open(
      'Are you sure you want to delete bore hole log - ' + boreHoleLog.boreHoleRunNumber + '?',
      '',
      '',
      'No',
      'Yes',
      this,
      'deleteBoreHoleDetails',
      deleteBoreholeLog
    )
  }

  private async deleteBoreHoleDetails(deleteBoreholeLog: DeleteBoreholeLogModel) {
    const response = await SiteInvestigationController.DeleteBoreholeLog(deleteBoreholeLog)
    if (response) {
      this.showMessage('Bore hole log deleted')
    } else {
      this.showMessage('Something went wrong')
    }
  }

  @Watch('selectedPanel')
  private onPanelSelectionChange() {
    if (this.selectedPanel !== -1) {
      const trialPit = this.trialPitData[this.selectedPanel]
      this.onTrialPitDetailClick(trialPit.trialPitDrawingId, trialPit.pitId, this.selectedPanel)
    }
  }
}
</script>

<style scoped>
.gridView >>> .name-col {
  width: 260px;
}
.gridView >>> .qty-col {
  width: 50px;
}
.gridView >>> .notes-col {
  width: 280px;
}
.bold-select {
  font-weight: 600;
}
.layer-card .close-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.trialpit-accordion >>> .v-expansion-panel__header {
  padding: 0 !important;
  position: relative;
}
.trialpit-accordion.finished >>> .v-expansion-panel__header {
  background: #d5f5d5;
}
.trialpit-accordion.lightcoral >>> .v-expansion-panel__header {
  background: #f7cccc;
}
.trialpit-accordion .expansion-header {
  padding: 13px 10px;
}
.trialpit-accordion >>> .v-expansion-panel__header__icon {
  position: absolute;
  top: 13px;
  right: 0px;
}
.edit-icon {
  right: 18px;
}
@media (max-width: 1440px) {
  .data-accordion .gridView >>> th,
  .data-accordion .gridView >>> td {
    font-size: 14px !important;
    padding: 5px 10px !important;
  }
  .data-accordion .gridView >>> .v-input--selection-controls.v-input .v-label {
    font-size: 14px;
  }
  .trial-data-table >>> table {
    max-width: 160%;
    width: 160%;
  }
}
</style>
