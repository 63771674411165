import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { JobPermissionsResponse } from '@/api/models/jobs/job-permissions-response'
import JobSearchIndexModel from '@/models/JobSearchIndexModel'
import { AxiosResponse } from 'axios'

export type SearchFilter = {
  type: 'ClientId' | 'ContractorId' | 'JobType' | 'PolicyNumber'
  value: string
}

export default class JobsController {
  public static async GetJobPermissionsAsync(jobId: string): Promise<JobPermissionsResponse> {
    const apiResponse = await CallCentreApi.get<JobPermissionsResponse>(`jobs/${jobId}/permissions`)

    const response = new JobPermissionsResponse()
    response.canChangeContactDetails = apiResponse.data.canChangeContactDetails
    response.canTakeManualPayments = apiResponse.data.canTakeManualPayments

    return response
  }

  public static async SearchJobs(
    searchKeyword: string,
    filters: (SearchFilter | undefined)[] = []
  ): Promise<JobSearchIndexModel[]> {
    const keyword = searchKeyword.toLowerCase().trim()
    const sanitizedFilters = filters
      .filter((filter): filter is SearchFilter => !!filter)
      .map((filter) => {
        filter.value = String(filter.value)
        return filter
      })

    const res: AxiosResponse = await CallCentreApi.post<JobSearchIndexModel[]>(`jobs/search`, {
      searchText: keyword,
      filters: sanitizedFilters,
    })
    return res.data || []
  }
}
