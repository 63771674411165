<template>
  <v-autocomplete v-model="selectedTradeId" label="Trade" :items="trades" @change="onTradeChange">
    <template slot="item" slot-scope="data">
      <v-list-tile-content>
        <v-list-tile-title :class="{ 'deleted-item': data.item.deleted }">
          {{ data.item.text }}
        </v-list-tile-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import PolicyHolderController from '@/api/policyHolderController'
import { JobType } from '@/common/enums'
import TradeModel from '@/models/policyHolder/TradeModel'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import sortBy from 'lodash.sortby'

interface ListType {
  value: number | null
  text: string
  deleted: boolean
}

@Component({})
export default class TradeList extends Vue {
  @Prop() public allowAll: boolean
  @Prop() public jobType: JobType | null

  @Emit('change') public onTradeChange(id: string | null): string | null {
    return id
  }

  selectedTradeId: string | null = null

  private get rawTrades(): ListType[] {
    return (this.$store.getters['getTrades']() as TradeModel[])
      .filter((t) => !this.jobType || t.jobType === this.jobType)
      .map((p) => ({
        value: p.tradeId,
        text: p.description,
        deleted: p.isDeleted,
      }))
  }

  get trades(): ListType[] {
    const temp = sortBy(
      this.rawTrades,
      (t: ListType) => t.deleted,
      (t: ListType) => t.text
    )
    if (this.allowAll) {
      temp.splice(0, 0, { value: null, text: '(All Trades)', deleted: false })
    }
    return temp
  }

  async created(): Promise<void> {
    // This should be done through a dispatched action rather than a direct API call.
    if (!this.rawTrades.length) {
      const res = await PolicyHolderController.GetTrades()
      this.$store.commit('setTrades', res)
    }
  }
}
</script>
