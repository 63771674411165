<template>
  <div>
    <h2>Cookie Management</h2>
    <div v-for="(cookieRecord, index) in cookieRecords" :key="`${cookieRecord.name}${index}`">
      <div class="cookie-detail">
        <div>
          <h3 class="cookie-detail__header">{{ cookieRecord.name }}</h3>
          <h4>Description: {{ cookieRecord.description }}</h4>
        </div>
        <div>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn flat icon color="primary" v-on="on" @click="openCookieModal(cookieRecord)">
                <v-icon dark color="primary">edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Cookie Record</span>
          </v-tooltip>
        </div>
      </div>
    </div>

    <v-btn @click="openCookieModal()">Add new cookie record</v-btn>

    <v-dialog
      v-if="showCookieModal"
      v-model="showCookieModal"
      max-width="800"
      persistent
      content-class="v-dialog--scrollable"
      hide-overlay
    >
      <CookieConfiguration @closeModal="showCookieModal = false" />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import CookieRecord from '@/models/cookies/cookie-record'
import { Component, Vue } from 'vue-property-decorator'
import CookieConfiguration from '@/components/cookies/admin/CookieConfiguration.vue'

@Component({
  components: {
    CookieConfiguration,
  },
})
export default class CookieManagement extends Vue {
  private showCookieModal = false
  private selectedCookieRecord: CookieRecord | null = null

  private openCookieModal(cookieRecord = new CookieRecord()) {
    this.showCookieModal = true
    this.$store.dispatch('cookieModule/submitCookieRecord', cookieRecord)
  }

  private get cookieRecords(): CookieRecord[] {
    return this.$store.getters['cookieModule/cookieRecords']
  }

  private async created() {
    this.$store.dispatch('cookieModule/submitRetrieveCookieRecords')
  }
}
</script>

<style lang="scss">
.cookie-detail {
  padding: 10px;
  margin: 10px 0;
  border: 2px solid black;
  display: flex;
  justify-content: space-between;

  &__header {
    margin-bottom: 10px;
  }
}
</style>
