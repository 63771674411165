import { SIJobProductProgress } from '@/common/enums'
import WaterTestModel from '../WaterTestModel'

export default class UpdateWaterMainsTest {
  public id: string
  public jobId: string
  public engineerVisitDetailId: string
  public waterTest: WaterTestModel
  public status: SIJobProductProgress | string
}
