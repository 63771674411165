import CosmosAdminDbRecordBase from '@/models/CosmosAdminDbRecordBase'
import ClientRateUSModel from './ClientRateUSModel'

export default class ClientRatePackageUSModel extends CosmosAdminDbRecordBase {
  public packageName: string
  public clientRateUSList: ClientRateUSModel[]
  public forCustomPackagePolicyId: number

  public constructor() {
    super()
    this.clientRateUSList = []
  }
}
