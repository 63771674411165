import TestDetail from '@/models/siteInvestigation/TestDetail'
import PitDetailModel from '@/models/siteInvestigation/PitDetailModel'
import LeftSideLayer from '@/models/siteInvestigation/LeftSideLayer'
import RightSideLayer from '@/models/siteInvestigation/RightSideLayer'
import TrialPitFeatureModel from '@/models/siteInvestigation/TrialPitFeatureModel'
import TrialPitTestModel from '@/models/siteInvestigation/TrialPitTestModel'

export default class AddTrialPitDrawingModel {
  public id: string
  public jobId: string
  public pitId: string
  public imageFile: string
  public forEngineerVisitDetailId: string
  public fileUrl: string
  public testDetails: TestDetail
  public pitDetail: PitDetailModel
  public leftLayers: LeftSideLayer[]
  public rightLayers: RightSideLayer[]
  public features: TrialPitFeatureModel[]
  public tests: TrialPitTestModel[]
}
