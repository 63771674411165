<template>
  <div class="pt-4">
    <v-flex xs12 grid-view>
      <v-data-table
        :headers="tableHeaders"
        :items="orderingStage ? selectedItems : items"
        hide-actions
        class="elevation-1"
        :loading="tableLoading"
      >
        <template slot="items" slot-scope="props">
          <tr v-if="!props.item.isDivider" :key="props.item.id" class="sortableRow">
            <td>
              {{ props.item.name }}
            </td>
            <td color="primary">
              {{ props.item.type }}
            </td>
            <td>
              <v-checkbox
                v-if="!orderingStage"
                v-model="selectedItems"
                class="text-xs-center"
                :value="props.item"
                hide-details
              />
              <div v-else class="sortHandle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#757575"
                  style="cursor: move"
                >
                  <circle cy="6.5" cx="9.5" r="1.5" />
                  <circle cy="6.5" cx="14.5" r="1.5" />
                  <circle cy="12.5" cx="9.5" r="1.5" />
                  <circle cy="12.5" cx="14.5" r="1.5" />
                  <circle cy="18.5" cx="9.5" r="1.5" />
                  <circle cy="18.5" cx="14.5" r="1.5" />
                </svg>
              </div>
            </td>
          </tr>
          <div v-else>
            <v-container>
              <v-label>{{ props.item.name }}</v-label>
            </v-container>
          </div>
        </template>
      </v-data-table>
    </v-flex>
    <v-flex v-if="reportDetail" xs12 text-xs-center mt-2 mr-0>
      <v-label :key="reportDetail.reportStatus">
        {{ reportStatusDisplay }}
      </v-label>
    </v-flex>
    <v-btn v-if="!orderingStage" color="primary" :disabled="selectedItems.length < 1" @click="changeToOrderingStage">
      Select
    </v-btn>
    <v-btn v-if="orderingStage" color="primary" flat="flat" :disabled="reportLoading" @click="cancelOrderingStage">
      Cancel
    </v-btn>
    <v-btn
      v-if="orderingStage"
      class="build-btn"
      color="primary"
      :loading="reportLoading"
      :disabled="disableBuild"
      @click="buildReport"
    >
      Build Report
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ReportController from '@/api/reportController'
import ReportDetail, { IncludeData } from '@/models/ReportDetail'
import DocumentController from '@/api/documentController'
import SiteInvestigationController from '@/api/siteInvestigationController'
import Sortable from 'sortablejs'
import { ReportStatus, UploadedDocumentTypeEnum, SiteDataRecordType } from '../common/enums'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import eventBus from '@/common/bus'
import ReportBuilderDataModel from '@/models/siteInvestigation/ReportBuilderDataModel'
@Component({})
export default class ReportBuilderSelector extends Vue {
  @Prop() private jobId: string

  private tableLoading = false
  private reportLoading = false

  private Sortable = require('sortablejs')
  private orderingStage = false

  private tableHeaders: any = [
    {
      text: 'Name',
      value: 'name',
      sortable: false,
      isAction: false,
    },
    {
      text: 'Type',
      value: 'type',
      sortable: false,
      isAction: false,
    },
    { text: '', value: 'includeOrDrag', sortable: false, isAction: true },
  ]

  private documentList: any[]
  private dataList: any[]

  private items: Array<{
    name?: string
    type?: string
    id?: string
    dataType?: SiteDataRecordType
    isDivider?: boolean
  }> = []
  private selectedItems: Array<{
    name: string
    type: string
    id: string
    dataType: SiteDataRecordType
  }> = []

  public async mounted() {
    this.tableLoading = true

    await this.getAllDocuments()

    this.items = []
    if (this.documentList) {
      this.items.push({ isDivider: true, name: 'Uploaded Documents' })
      this.items.push(...this.documentList)
    }

    await this.getSiteData()

    if (this.dataList) {
      this.items.push({ isDivider: true, name: 'Site Data' })
      this.items.push(...this.dataList)
    }

    this.tableLoading = false
    this.ApplyDragAndDropOnDocument()
  }

  private changeToOrderingStage() {
    this.orderingStage = true
  }

  private cancelOrderingStage() {
    this.orderingStage = false
  }

  private async getAllDocuments() {
    const documents = await DocumentController.GetAllUploadedJobDocument(this.jobId)
    if (!documents) {
      return
    }
    this.documentList = documents
      .filter((d) => d.documentTypeId !== UploadedDocumentTypeEnum.SIReport)
      .map((d) => {
        return {
          name: d.documentName,
          type: d.documentType,
          id: d.documentUrl,
          dataType: null,
        }
      })
  }

  private async getSiteData() {
    const data: ReportBuilderDataModel | null = await SiteInvestigationController.GetReportBuilderSelectorData(
      this.jobId
    )
    if (data) {
      let pits: any[] = []
      if (data.trialPits) {
        pits = data.trialPits.map((p) => {
          return {
            name: 'Trial Pit: ' + p.pitId,
            id: p.id,
            type: 'Trial Pit, Borehole',
            dataType: 'SIVisitTrialPitRecord',
          }
        })
      }

      let cctvLogs: any[] = []
      if (data.cctvControlLogs) {
        cctvLogs = data.cctvControlLogs.map((c) => {
          return {
            name: 'CCTV Log',
            id: c.id,
            type: 'Drainage Pack',
            dataType: 'CCTVControlLog',
          }
        })
      }

      let waterMainsTest: any[] = []
      if (data.waterMainsTests) {
        waterMainsTest = data.waterMainsTests.map((w) => {
          return {
            name: 'Water Mains Test',
            id: w.id,
            type: 'Drainage Pack',
            dataType: 'WaterMainsTest',
          }
        })
      }
      pits = pits.concat(cctvLogs)
      this.dataList = pits.concat(waterMainsTest)
    }
  }

  private async buildReport() {
    if (this.disableBuild) {
      return
    }
    this.reportLoading = true
    const includeData = this.selectedItems.map((item, index) => {
      const include = new IncludeData()
      include.id = item.dataType ? item.id : item.id.substring(item.id.indexOf(this.jobId), item.id.indexOf('?'))
      include.sequence = index
      include.dataType = item.dataType
      include.type = item.dataType ? 'Data' : 'Document'
      return include
    })

    const reportDetail: ReportDetail = Object.assign(new ReportDetail(), {
      jobId: this.jobId,
      reportStatus: ReportStatus.BuildingReport,
      includeData,
    })

    try {
      const res = await ReportController.BuildReport(reportDetail)
      if (res) {
        this.orderingStage = false
      } else {
        eventBus.$emit('errorHandler', 'There was a problem sending your request, please try again later.', true)
      }
    } catch (ex) {
      eventBus.$emit('errorHandler', 'An error occured requesting the Report, please try again later.', true)
    }
    this.selectedItems = []
    this.reportLoading = false
  }

  @Watch('reportDetail', { deep: true })
  private async reportStatusChanged() {
    if (this.tableLoading) {
      return
    }
    this.tableLoading = true
    await this.getAllDocuments()

    this.items = []
    if (this.documentList) {
      this.items.push({ isDivider: true, name: 'Uploaded Documents' })
      this.items.push(...this.documentList)
    }
    if (this.dataList) {
      this.items.push({ isDivider: true, name: 'Site Data' })
      this.items.push(...this.dataList)
    }

    this.tableLoading = false
  }

  private ApplyDragAndDropOnDocument(): void {
    this.Sortable = new this.Sortable(this.$el.querySelector('.v-datatable tbody'), {
      draggable: '.sortableRow',
      handle: '.sortHandle',
      onEnd: this.dragReorder,
    })
  }

  private get reportDetail(): ReportDetail | null {
    return storeGetters.getReportDetail(this.jobId)
  }

  private get disableBuild(): boolean {
    return this.reportDetail !== null && this.reportDetail.reportStatus === ReportStatus.BuildingReport
  }

  private get reportStatusDisplay(): string {
    if (!this.reportDetail) {
      return ''
    }
    switch (this.reportDetail.reportStatus) {
      case ReportStatus.BuildingReport: {
        const requestedAt = this.reportDetail.requestedAt
        const str = requestedAt ? ` (Requested ${this.getFormattedDate(requestedAt)})` : ''
        return 'Building Report...' + str
      }
      case ReportStatus.ReportBuilt: {
        const builtAt = this.reportDetail.builtAt
        return `Report Completed ${builtAt ? this.getFormattedDate(builtAt) : ''}`
      }
      case ReportStatus.ReportFailed:
        return 'Report Failed.'
      case ReportStatus.ReportSent:
        return 'Report Sent.'
      default:
        return ''
    }
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private dragReorder(event: any): void {
    const movedItem = this.selectedItems.splice(event.oldIndex, 1)[0]
    this.selectedItems.splice(event.newIndex, 0, movedItem)
  }
}
</script>

<style scoped>
.gridView >>> th {
  font-size: 14px !important;
}
.dataRow {
  color: blue !important;
}
</style>
