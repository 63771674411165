import { ActionTree } from 'vuex'
import { State } from '@/store'
import DelayController from './api/delayController'
import TradeDelay from './models/delay/TradeDelay'
import PolicyHolderController from './api/policyHolderController'
import EmergencyModel from './models/policyHolder/EmergencyModel'
import TradeModel from './models/policyHolder/TradeModel'
import InsurerController from './api/InsurerController'
import InsurerModel from './models/policy/InsurerModel'
import ContractorController from './api/contractorController'
import ContractorModel from './models/contractor/ContractorModel'
import { PostcodeRegion } from './models/contractor/RegionsConfiguration'
import MasterRecordController from './api/masterRecordController'
import { RecordType } from './common/enums'
import MasterRecord from './models/MasterRecord'
import PolicySchedulesController from './api/policySchedulesController'
import eventBus from '@/common/bus'

export const actions: ActionTree<State, any> = {
  async retrieveTradeDelays({ commit }) {
    await DelayController.GetTradeDelays().then((tradeDelays: TradeDelay[] | null) => {
      if (tradeDelays) {
        commit('setTradeDelays', tradeDelays)
      }
    })
  },

  async updateTradeDelay({ commit }, tradeDelay: TradeDelay) {
    await DelayController.UpdateTradeDelay(tradeDelay).then((result: TradeDelay | null) => {
      if (result) {
        commit('setTradeDelay', result)
      }
    })
  },

  async updateEmergencies({ commit }) {
    await PolicyHolderController.GetEmergencies().then((result: EmergencyModel[] | null) => {
      if (result) {
        commit('setEmergencies', result)
      }
    })
  },

  async updateTrades({ commit }) {
    await PolicyHolderController.GetTrades().then((result: TradeModel[] | null) => {
      if (result) {
        commit('setTrades', result)
      }
    })
  },

  async updateInsurers({ commit }) {
    await InsurerController.GetInsurerList().then((result: InsurerModel[] | null) => {
      if (result) {
        commit('setInsurerList', result)
      }
    })
  },

  async updateContractors({ commit }) {
    await ContractorController.GetAllContractors().then((result: ContractorModel[] | null) => {
      if (result) {
        commit('setContractors', result)
      }
    })
  },

  async updateRegions({ commit }) {
    await ContractorController.GetRegionConfiguration().then((result: PostcodeRegion[] | null) => {
      if (result) {
        commit('setRegions', result)
      }
    })
  },

  async updateEscalationReasons({ commit }) {
    await MasterRecordController.GetMasterRecords(RecordType[RecordType.JobEscalationReason]).then(
      (result: MasterRecord[] | null) => {
        if (result) {
          commit('setEscalationReasons', result)
        }
      }
    )
  },

  async updatePolicyScheduleFromDeliveryNumber({ commit }, deliveryNumber: string) {
    const result = await PolicySchedulesController.GetPolicyScheduleByDeliveryNumber(deliveryNumber)
    commit('updateCurrentCallPolicySchedule', result)
    eventBus.$emit('sendInitialDataToWizard')
  },
}
