export default class PolicyCommunicationDetailModel {
  public policyId: number
  public announcementMessageFile: string | null
  public announcementMessage: string | null
  public reassuranceMessageOneFile: string | null
  public reassuranceMessageOne: string | null
  public reassuranceMessageTwoFile: string | null
  public reassuranceMessageTwo: string | null
  public surgeMessageFile: string | null
  public surgeMessage: string | null
  public announcementMessageIsText: boolean
  public reassuranceMessageOneIsText: boolean
  public reassuranceMessageTwoIsText: boolean
  public surgeMessageIsText: boolean
  public portalLink: string
  public isPortalLinkEnabled: boolean
  public readAverageHoldTime: boolean
  public callbackEnabled: boolean
  public voiceFNOLEnabled: boolean
  public effortScore: boolean
  public totalSurveysPerJobPerDay: number | null
  public totalSurveysPerJob: number | null

  public constructor() {
    if (!this.totalSurveysPerJobPerDay) {
      this.totalSurveysPerJobPerDay = 1
    }
    if (!this.totalSurveysPerJob) {
      this.totalSurveysPerJob = 3
    }
    this.effortScore = false
  }
}
