import InsurerSalesLedgerModel from './InsurerSalesLedgerModel'

export default class InsurerModel {
  public id: number
  public name: string
  public alternativeName: string
  public passToThirdParty: boolean
  public logoURL: string
  public heNotificationEmail: string
  public siNotificationEmail: string
  public usNotificationEmail: string
  public bordereauMaxAmount: number
  public heInvoiceMaxValue: number
  public insurerSalesLedgers: InsurerSalesLedgerModel[]
  public isDelete: boolean
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postCode: string

  constructor() {
    this.insurerSalesLedgers = []
  }
}
