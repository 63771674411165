<template>
  <v-card v-if="pitData" class="mb-4 pit-card elevation-2">
    <v-card-title>
      <h3 v-if="priceIncluded" :class="pitData.isDeleted ? 'deleted-item' : ''">
        {{ pitTitle }}
      </h3>
      <h3 v-else>
        {{ pitTitle }} -
        <span class="primary--text">&pound;{{ pitData.basePrice }}</span>
      </h3>
      <v-spacer />
      <v-btn
        v-if="!disabled && allowedDelete"
        class="btn-delete"
        :disabled="!isPitIdEditable"
        icon
        @click="$emit('delete-pit')"
      >
        <v-icon>delete</v-icon>
      </v-btn>
    </v-card-title>
    <v-flex xs12 px-0>
      <v-layout wrap>
        <v-flex xs6 class="px-3">
          <v-text-field
            id="pitId"
            v-model="pitData.pitId"
            v-validate="`required|unique:${pitIds}`"
            label="Trial Pit ID"
            class="required"
            :error-messages="$validator.errors.collect('Trial Pit ID')"
            data-vv-name="Trial Pit ID"
            :disabled="disabled || !isPitIdEditable"
            required
          />
        </v-flex>
        <v-flex xs6 class="px-3">
          <v-text-field
            id="depth"
            v-model.number="pitData.boreDepth"
            v-validate="`required|between:0,${maxDepth}`"
            label="Borehole Depth"
            class="required"
            :error-messages="$validator.errors.collect('Depth')"
            data-vv-name="Depth"
            type="number"
            hint="meters"
            :disabled="disabled"
            required
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-container class="pa-3 test-block">
      <v-flex xs12>
        <h4 class="pb-2">Tests:</h4>
        <v-flex v-for="test in availableTests" :key="test.name">
          <v-layout wrap pb-2>
            <v-flex xs4>
              <v-layout wrap xs12>
                <v-flex xs8>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-switch
                        ref="pitTest"
                        :input-value="findTestOnPit(test)"
                        class="pt-1 switch-toggle"
                        color="primary"
                        :label="testIncluded(test) ? test.name : `${test.name}`"
                        :disabled="disabled"
                        :class="test.isDeleted ? 'deleted-item' : ''"
                        v-on="on"
                        @change="(t) => toggleTest(test, t)"
                      />
                    </template>
                    <span>{{ test.description }}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex xs3 pl-4 mr-1 mt-2 class="priceLabel">
                  <UpdateProductPriceDialog
                    ref="updateProductPriceDialogRef"
                    :price-label="`£${test.basePrice}`"
                    :is-price-updated.sync="test.isPriceUpdated"
                    :product-price.sync="productBasePrice"
                    :is-disabled="disabled || !findTestOnPit(test)"
                    @editProductPrice="
                      () => {
                        openEditProductPriceDialog(test)
                      }
                    "
                    @productPriceUpdated="onProductPriceUpdated"
                  ></UpdateProductPriceDialog>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-show="findTestOnPit(test)" :ref="test.name + ':data'" xs8>
              <v-layout wrap xs12>
                <v-flex xs4 px-3>
                  <v-text-field
                    v-model.number="test.quantity"
                    v-validate="`required|min_value:0`"
                    label="Quantity"
                    class="required"
                    :error-messages="$validator.errors.collect(test.name)"
                    :data-vv-name="test.name"
                    type="number"
                    :hint="test.unit"
                    :disabled="disabled"
                    required
                  />
                </v-flex>
                <v-spacer />
                <v-flex xs8 mr-0>
                  <v-text-field v-model="test.notes" label="Test Notes" :disabled="disabled" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-flex>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import Pit from '@/models/siteInvestigation/Pit'
import Product from '@/models/siteInvestigation/Product'
import PitTest from '@/models/siteInvestigation/PitTest'
import UpdateProductPriceDialog from '@/components/UpdateProductPriceDialog.vue'

@Component({
  components: {
    UpdateProductPriceDialog,
  },
})
export default class SIPitCard extends Vue {
  @Prop({ default: undefined }) public value: Pit
  @Prop({ default: false }) public disabled: boolean
  @Prop({ default: undefined }) public includedTests: Product[]
  @Prop({ default: '' }) public pitTitle: string
  @Prop({ default: false }) public priceIncluded: boolean
  @Prop({ default: () => [] }) public pits: Pit[]
  @Prop({ default: true }) public allowedDelete: boolean
  @Prop({ default: false }) public isEngineerVisitCreated: boolean
  @Prop({ default: () => [] }) public addedPitIds: string[]
  @Prop({ default: false }) public isNewJob: boolean

  public pitData: Pit | null = null
  public isPitIdEditable = true

  // update product price
  private productBasePrice = 0
  private selectedProductForPriceUpdate: PitTest | null = null

  public async validatePitCard(): Promise<boolean> {
    const result = await this.$validator.validateAll()
    return result
  }

  private get pitIds(): string[] {
    // modify this to concat the ids of the pits stored under each of the bulkPit products
    return this.pits.map((x) => x.pitId)
  }

  public mounted() {
    this.$validator.extend('unique', {
      getMessage(name, val) {
        return `${name} must be unique.`
      },
      validate(value, arr) {
        if (value) {
          const filterIds = arr.filter((x) => x !== value)

          if (filterIds.length === arr.length) {
            return true
          }
          if (filterIds.length === arr.length - 1) {
            return true
          }
          if (arr.length - 1 > filterIds.length) {
            return false
          }
          if (arr.filter((x) => x === value).length > 1) {
            return true
          }
        }
      },
    })

    this.pitData = this.value
    if (this.pitData) {
      const listReferencedTests: PitTest[] = []
      this.pitData.pitTests.forEach((test) => {
        const testFromList = this.availableTests.find((availableTest) => availableTest.id === test.id)
        if (testFromList) {
          testFromList.quantity = test.quantity
          testFromList.notes = test.notes
          // set value of isPriceUpdated flag
          testFromList.isPriceUpdated = test.basePrice !== testFromList.basePrice ? true : false
          testFromList.basePrice = test.basePrice
          listReferencedTests.push(testFromList)
        }
      })
      this.pitData.pitTests = listReferencedTests
    }
  }

  @Watch('value', { deep: true })
  public updateData() {
    this.pitData = this.value
  }

  @Watch('pitData', { deep: true })
  public localUpdateData() {
    if (this.disabled) {
      return
    }
    this.$emit('input', this.pitData)
  }

  private get maxDepth() {
    if (!this.pitData || !this.pitData.productAttributes) {
      return 20
    }
    const attr = this.pitData.productAttributes.find((a) => a.attributeName === 'maxDepth')
    if (attr) {
      return Number(attr.attributeValue)
    } else {
      return 20
    }
  }

  private get availableTests(): PitTest[] {
    if (!this.pitData || !this.pitData.productChildLinks) {
      return []
    }
    let productChildLinks: Product[] = []
    productChildLinks = this.isNewJob
      ? this.pitData.productChildLinks.filter((productChildLink) => !productChildLink.isDeleted)
      : this.pitData.productChildLinks.filter(
          (productChildLink) =>
            !productChildLink.isDeleted ||
            (productChildLink.isDeleted && this.pitData!.pitTests!.find((t) => t.id === productChildLink.id))
        )

    return productChildLinks.map((link) => new PitTest(link))
  }

  private testIncluded(test: Product): boolean {
    return this.includedTests ? this.includedTests.some((t) => t.id === test.id) : false
  }

  private findTestOnPit(test) {
    return this.pitData && this.pitData.pitTests.find((t) => t.id === test.id)
  }

  private toggleTest(test: PitTest, value: boolean) {
    if (!this.pitData || this.disabled) {
      return
    }
    if (!value) {
      const t = this.findTestOnPit(test)
      if (t) {
        this.pitData.pitTests.splice(this.pitData.pitTests.indexOf(t), 1)
      }
      test.quantity = 1
      test.notes = ''
    } else {
      test.quantity = test.quantity ? test.quantity : 1
      this.pitData.pitTests.push(test)
    }
    this.$validator.errors.remove(test.name)
  }

  @Watch('addedPitIds')
  private onAddedPitIdChange() {
    if (this.pitData && this.addedPitIds) {
      this.isNewPitAdded(this.pitData.pitId)
    }
  }

  private isNewPitAdded(pit: string) {
    if (this.isEngineerVisitCreated) {
      if (this.addedPitIds.find((p) => p === pit)) {
        this.isPitIdEditable = false
      } else {
        this.isPitIdEditable = true
      }
    }
  }

  private openEditProductPriceDialog(item: PitTest) {
    this.productBasePrice = item.basePrice
    this.selectedProductForPriceUpdate = item
  }

  private onProductPriceUpdated(price: number) {
    if (this.selectedProductForPriceUpdate) {
      this.selectedProductForPriceUpdate.basePrice = price
      this.selectedProductForPriceUpdate.isPriceUpdated = true
    }
  }
}
</script>

<style scoped>
.v-dialog .pit-card {
  background: white !important;
}
.pit-card .test-block >>> .v-input {
  padding-top: 0;
  margin-top: 0;
}
</style>
