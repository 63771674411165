<template>
  <div v-if="contractorJobs" class="contjob-status-board elevation-1">
    <v-container grid-list-xl fluid>
      <v-layout wrap class="contjob-status-head">
        <v-flex xs3>
          <h3>Pending</h3>
          <v-divider class="mt-2 mb-2"></v-divider>
        </v-flex>
        <v-flex xs3>
          <h3>Accepted</h3>
          <v-divider class="mt-2 mb-2"></v-divider>
        </v-flex>
        <v-flex xs3>
          <h3>In progress</h3>
          <v-divider class="mt-2 mb-2"></v-divider>
        </v-flex>
        <v-flex xs3>
          <h3>Completed</h3>
          <v-divider class="mt-2 mb-2"></v-divider>
        </v-flex>
      </v-layout>
      <div v-bar="{ useScrollbarPseudo: true }">
        <v-layout class="contjob-status-body d-flex">
          <ContractorJobCard
            :id="id"
            status-text="Pending"
            :contractor-jobs-by-visit-status="
              getContractorJobList.filter((c) => c.status === jobVisitStatus[jobVisitStatus.Pending])
            "
            :engineers="engineers"
            :selected-job-to-open="selectedJobToOpen"
            :show-engineer-details="showEngineerDetails"
            :emergencies="emergencies"
            :total-job-count="totalJobCount ? totalJobCount.totalPendingJobCount : 0"
            :show-load-more="showLoadMore"
            :visit-type="visitType"
          ></ContractorJobCard>
          <ContractorJobCard
            :id="id"
            status-text="Accepted"
            :contractor-jobs-by-visit-status="
              getContractorJobList.filter((c) => c.status === jobVisitStatus[jobVisitStatus.Accepted])
            "
            :engineers="engineers"
            :selected-job-to-open="selectedJobToOpen"
            :show-engineer-details="showEngineerDetails"
            :emergencies="emergencies"
            :total-job-count="totalJobCount ? totalJobCount.totalAcceptedJobCount : 0"
            :show-load-more="showLoadMore"
            :visit-type="visitType"
          ></ContractorJobCard>
          <ContractorJobCard
            :id="id"
            status-text="In progress"
            :contractor-jobs-by-visit-status="
              getContractorJobList.filter((c) => c.status === jobVisitStatus[jobVisitStatus.InProgress])
            "
            :engineers="engineers"
            :selected-job-to-open="selectedJobToOpen"
            :show-engineer-details="showEngineerDetails"
            :emergencies="emergencies"
            :total-job-count="totalJobCount ? totalJobCount.totalInProgressJobCount : 0"
            :show-load-more="showLoadMore"
            :visit-type="visitType"
          ></ContractorJobCard>
          <ContractorJobCard
            :id="id"
            status-text="Completed"
            :contractor-jobs-by-visit-status="
              getContractorJobList.filter((c) => c.status === jobVisitStatus[jobVisitStatus.Completed])
            "
            :engineers="engineers"
            :selected-job-to-open="selectedJobToOpen"
            :show-engineer-details="showEngineerDetails"
            :emergencies="emergencies"
            :total-job-count="totalJobCount ? totalJobCount.totalCompletedJobCount : 0"
            :show-load-more="showLoadMore"
            :visit-type="visitType"
          ></ContractorJobCard>
          <div v-if="getContractorJobs && getContractorJobList.length === 0" class="data_nf_msg">
            <span>Data Not Available</span>
          </div>
        </v-layout>
      </div>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { JobVisitStatus, ContractorVisitTypeEnum } from '@/common/enums'
import ContractorController from '@/api/contractorController'
import eventBus from '@/common/bus'
import JobHeader from '@/models/JobHeader'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import ContractorJobCard from '@/components/ContractorJobCard.vue'
import EngineerModel from '@/models/contractor/EngineerModel'
import PolicyHolderController from '../api/policyHolderController'
import EmergencyModel from '../models/policyHolder/EmergencyModel'
import storeMutations from '../storeMutations'
import storeGetters from '@/storeGetters'
import TradeModel from '@/models/policyHolder/TradeModel'
import ClientUserController from '@/api/clientUserController'
import InsurerController from '@/api/InsurerController'
import moment from 'moment'

@Component({
  components: {
    ContractorJobCard,
  },
})
export default class ContractorJobs extends Vue {
  @Prop() private id: string | number // Id -> ContractorId for ContractorJobs, InsurerId for ClientJobs
  @Prop() private contractorJobs: ContractorAppointedModel[]
  @Prop() private engineers: EngineerModel[]
  @Prop() private selectedJobToOpen: string
  @Prop({ default: true }) private showEngineerDetails: boolean
  @Prop() private totalJobCount: any
  @Prop({ default: false }) private showLoadMore: boolean
  @Prop() private visitType: string
  private jobVisitStatus = JobVisitStatus
  private contractorJobList: any = []
  private tempContractorJobList: ContractorAppointedModel[] = []
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''

  private created() {
    if (this.emergencies.length === 0) {
      this.setEmergencies()
    }
    this.getTrades()
  }

  private async getTrades() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      const res = await PolicyHolderController.GetTrades()
      storeMutations.setTrades(res)
    }
  }

  private get getContractorJobs(): ContractorAppointedModel[] {
    return this.contractorJobs
  }

  private get getContractorJobList(): CosmosJobsDbRecordBase[] {
    return this.contractorJobList
  }

  private set getContractorJobList(newValue: CosmosJobsDbRecordBase[]) {
    this.contractorJobList = newValue
  }

  private get emergencies() {
    return storeGetters.getEmergencies()
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        storeMutations.setEmergencies(res)
      }
    })
  }

  @Watch('contractorJobs')
  private onContractorJobsChange() {
    this.getContractorJobList = this.getContractorJobs
    this.tempContractorJobList = JSON.parse(JSON.stringify(this.contractorJobList))
    if (!this.showLoadMore) {
      this.filterJobByVisitType(this.visitType)
    }
  }

  private mounted() {
    const self = this
    self.contractorJobList = self.contractorJobs
    self.tempContractorJobList = JSON.parse(JSON.stringify(self.contractorJobList))
    // search through contractorJobs using keyword.
    eventBus.$off('searchContractorJobKeyword').$on('searchContractorJobKeyword', (keyword: string) => {
      if (keyword) {
        ContractorController.SearchContractorJobHeader(this.id.toString(), keyword)
          .then((res: JobHeader[] | null) => {
            eventBus.$emit('filteredContractorJobItems', res)
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error loading contractor job(s), please try again', true)
          })
      }
    })
    // update contractorJobs based on search result.
    eventBus.$on('searchContractorJob', (item: any) => {
      if (item) {
        let contractorJobs: ContractorAppointedModel[] = []
        contractorJobs = self.getContractorJobs.filter((e) => e.jobId === item.id)
        self.getContractorJobList = contractorJobs
      }
    })
    // reset contractorJobList, ClientJobList when clicking back button.
    eventBus.$on('resetContractorJobs', () => {
      self.getContractorJobList = this.getContractorJobs
      if (!this.showLoadMore) {
        this.filterJobByVisitType(this.visitType)
      }
    })
    // search through clientJobs using keyword.
    eventBus.$off('searchClientJobKeyword').$on('searchClientJobKeyword', (keyword: string) => {
      if (keyword) {
        ClientUserController.SearchClientJobHeader(Number(this.id), keyword)
          .then((res: JobHeader[] | null) => {
            eventBus.$emit('filteredClientJobItems', res)
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error loading client job(s), please try again', true)
          })
      }
    })
    // update clientJobs based on search result.
    eventBus.$on('searchClientJob', (item: any) => {
      if (item) {
        // get all jobList for searched jobId
        InsurerController.GetSearchedClientJob(Number(this.id), item.id, this.visitType)
          .then((res: ContractorAppointedModel[]) => {
            if (res && res.length > 0) {
              self.getContractorJobList = res
            } else {
              this.snackbarText = 'No Jobs(s) found.'
              this.snackbar = true
            }
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error loading client job(s), please try again', true)
          })
      }
    })
    // apply visit type filter
    eventBus.$on('onVisitTypeChange', (visitType: string) => {
      this.filterJobByVisitType(visitType)
    })
  }

  private filterJobByVisitType(visitType: string) {
    if (visitType === ContractorVisitTypeEnum[ContractorVisitTypeEnum.FirstVisit]) {
      this.getContractorJobList = this.tempContractorJobList.filter((e) => !e.isContractorReAttend)
    } else if (visitType === ContractorVisitTypeEnum[ContractorVisitTypeEnum.ReturnVisit]) {
      this.getContractorJobList = this.tempContractorJobList.filter((e) => e.isContractorReAttend)
    } else if (visitType === ContractorVisitTypeEnum[ContractorVisitTypeEnum.VisitWithoutETA]) {
      this.getContractorJobList = this.tempContractorJobList.filter(
        (e) =>
          e.isContractorReAttend &&
          (e.etaFrom ? !moment(e.etaFrom).isValid() : true) &&
          (e.etaTo ? !moment(e.etaTo).isValid() : true)
      )
    } else {
      this.getContractorJobList = this.tempContractorJobList
    }
  }
}
</script>

<style scoped>
.contjob-status-board {
  background-color: #fff;
  margin: 12px 0px 0 0;
  position: relative;
}
.contjob-status-board >>> .container {
  padding: 0;
}
.contjob-status-board .contjob-status-head {
  position: absolute;
  top: 0;
  left: 12px;
  right: 12px;
  background: #fff;
  z-index: 1;
}
.contjob-status-board .contjob-status-body {
  padding-top: 50px;
  height: calc(100vh - 170px) !important;
  margin: 0 !important;
}
.data_nf_msg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  z-index: 4;
}
.data_nf_msg span {
  margin: auto;
  font-size: 18px;
  color: #b4b4b4;
}
</style>
