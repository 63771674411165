import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'

export default class CustomerAppointedTradespeople extends CosmosJobsDbRecordBase {
  public emergencyId: string
  public invoiceImageUrl: string
  public invoiceUrlLinkExpiredAt: Date
  public invoiceUrlLinkExpired: boolean
  public escalateJob: boolean
  public escalationReason: string
}
