<template>
  <div>
    <ReportBuilderSelector :job-id="jobId" />
    <v-flex
      v-if="
        reportDetail &&
        reportDetail.reportStatus === reportStatus.ReportBuilt &&
        (job && !job.isTestingOnlyJob ? isEngineerVisitCompleted : true)
      "
      class="text-xs-right"
    >
      <v-btn color="primary" class="mr-0 upload-btn sentReport-btn" :disabled="loading" @click="sentReport">
        Send Email
      </v-btn>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import ReportBuilderSelector from '@/components/ReportBuilderSelector.vue'
import DocumentList from '@/components/DocumentList.vue'
import storeGetters from '@/storeGetters'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import ReportDetail from '@/models/ReportDetail'
import { ReportStatus } from '@/common/enums'
import ReportController from '@/api/reportController'

@Component({
  components: { ReportBuilderSelector },
})
export default class BuildReportPreview extends TimeLineItemBase {
  private loading = false
  private reportStatus = ReportStatus

  private get isEngineerVisitCompleted(): boolean {
    if (this.job && this.job.engineerVisitDetail.length > 0) {
      const engineerVisit: EngineerVisitDetail[] = this.job.engineerVisitDetail.filter(
        (e) =>
          e.status !== 'Completed' &&
          e.status !== 'Cancelled' &&
          e.status !== 'Rejected' &&
          e.status !== 'Abandoned' &&
          e.status !== 'ReAssigned'
      )
      return engineerVisit.length === 0 ? true : false
    }
    return false
  }

  private get reportDetail(): ReportDetail | null {
    return storeGetters.getReportDetail(this.jobId)
  }

  private async sentReport() {
    if (!this.reportDetail) {
      return {}
    }
    this.loading = true
    await ReportController.SendReport(this.jobId)
    this.loading = false
  }
}
</script>
