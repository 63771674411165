<template>
  <div class="user">
    <EditUserDialog />
    <div>
      <v-card-title primary-title class="pa-0 mb-2">
        <h3 class="mb-0">Users</h3>
        <v-spacer></v-spacer>
        <v-switch
          v-model="showDeletedUsers"
          color="primary"
          class="show-deleted"
          small
          label="Show Deleted Users"
        ></v-switch>
        <v-text-field
          v-model.trim="search"
          append-icon="search"
          label="Search"
          class="mt-0 pt-0"
          single-line
          hide-details
        ></v-text-field>
        <v-tooltip v-if="canCreateUser" top>
          <template #activator="{ on }">
            <v-btn icon class="mx-0 add-action-btn" v-on="on" @click="createUser">
              <v-icon color="grey">add</v-icon>
            </v-btn>
          </template>
          <span>Add User</span>
        </v-tooltip>
      </v-card-title>
      <div>
        <v-data-table
          :headers="headers"
          :items="users"
          :loading="loading"
          :search="search"
          :pagination.sync="pagination"
          class="user-list grid-view"
        >
          <template slot="items" slot-scope="props">
            <tr :class="props.item.isActive ? '' : 'deactivated-user'">
              <td class="team-member-img">
                <img :src="props.item.profileImageThumbnail || '/img/unknownuser.png'" alt />
              </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.roleName }}</td>
              <td class="text-xs-center">{{ props.item.canAcceptEmergencies ? 'Yes' : 'No' }}</td>
              <td class="text-xs-center">{{ props.item.canAcceptPayments ? 'Yes' : 'No' }}</td>
              <td class="text-xs-center">{{ props.item.worksForThirdParty ? 'Yes' : 'No' }}</td>
              <td>
                <star-rating
                  :star-size="20"
                  :rating="props.item.averageSkill"
                  :max-rating="5"
                  active-color="#e7711b"
                  :increment="0.1"
                  :fixed-points="1"
                  :read-only="true"
                ></star-rating>
              </td>
              <td class="justify-center layout px-0">
                <v-tooltip v-if="canUpdateUser(props.item)" top class="update-user">
                  <template #activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click="updateUser(props.item)">
                      <v-icon color="primary">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip v-if="canDeleteUser(props.item)" top>
                  <template #activator="{ on }">
                    <v-btn icon class="mx-0 mt-2 ml-2 deactivate-btn" v-on="on" @click="deleteUser(props.item)">
                      <v-icon color="primary">delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import UserModel from '@/models/user/UserModel'
import StarRating from 'vue-star-rating'
import Shared from '@/common/shared'
import EditUserDialog from '@/components/users/EditUserDialog.vue'
import UserListItemModel from '@/models/users/user-list-item-model'

@Component({
  components: { EditUserDialog, StarRating },
})
export default class Users extends Vue {
  public headers: any = [
    { text: ' ', value: 'profileImageThumbnail', class: 'team-img-head', sortable: false },
    { text: 'Name', value: 'Name' },
    { text: 'Email', value: 'email' },
    { text: 'Role', value: 'roleName' },
    { text: 'Can Accept Emergencies?', value: 'canAcceptEmergencies', align: 'center' },
    { text: 'Can Accept Payments?', value: 'canAcceptPayments', align: 'center' },
    { text: 'Third Party?', value: 'worksForThirdParty', align: 'center' },
    { text: 'Skill Level', value: 'averageSkill' },
    { text: '', sortable: false, class: 'action-btn' },
  ]

  public search = ''
  public pagination: unknown = { rowsPerPage: 10 }

  public get loggedInUser(): UserModel {
    return this.$store.getters['userDetail']
  }

  public get showDeletedUsers(): boolean {
    return this.$store.getters['usersModule/showDeletedUsers']
  }

  public set showDeletedUsers(value: boolean) {
    this.$store.commit('usersModule/setShowDeletedUsers', value)
  }

  public get loading(): boolean {
    return this.$store.getters['usersModule/loading']
  }

  public get users(): UserListItemModel[] {
    return this.$store.getters['usersModule/allUsers']
  }

  public get canCreateUser(): boolean {
    return this.$store.getters['usersModule/canCreateUsers']
  }

  public canUpdateUser(user: UserListItemModel): boolean {
    return user.isActive && user.canEdit
  }

  public canDeleteUser(user: UserListItemModel): boolean {
    return this.$store.getters['usersModule/canDeleteUsers'] && user.isActive
  }

  public updateUser(user: UserModel): void {
    this.$store.dispatch('usersModule/selectUser', user.id)
  }

  public createUser(): void {
    this.$store.dispatch('usersModule/createUser')
  }

  public deleteUser(user: UserModel): void {
    Shared.confirmationPopup.open(
      `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      '',
      '',
      'No',
      'Yes',
      this,
      'deactivateUser',
      user
    )
  }

  public deactivateUser(user: UserModel): void {
    this.$store.dispatch('usersModule/deleteUser', user.id)
  }

  public mounted(): void {
    this.$store.dispatch('usersModule/retrieveUsers')
  }
}
</script>

<style scoped>
.vue-star-rating >>> .vue-star-rating-rating-text {
  margin-top: 1px;
}
.grid-view >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}
.grid-view >>> .action-btn {
  min-width: 100px;
}
.team-member-img {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.deactivated-user td {
  text-decoration: line-through;
}

.show-deleted {
  max-width: 250px;
  width: 250px;
}
</style>
