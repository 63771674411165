export default class RepairEstimateProductModel {
  public subCategory: string
  public description: string
  public unit: string
  public unitPrice: number
  public vatRate: number
  public quantity: number
  public clientPrice: number
  public repairCost?: number
  public customerAppointedTradePrice?: number
}
