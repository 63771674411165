import moment from 'moment'

export default class UpdateJobOffsiteTime {
  public id: string
  public jobId: string
  public visitStartedAt: moment.Moment | null
  public visitCompletedAt: moment.Moment | null
  public visitNote: string

  public constructor() {
    this.visitStartedAt = null
    this.visitCompletedAt = null
    this.visitNote = ''
  }
}
