<template>
  <div>
    <v-form>
      <v-card class="mb-3 enablement">
        <v-card-title class="pb-0">
          <v-layout>
            <v-flex xs11>
              <h3>IVS Detail</h3>
            </v-flex>
            <v-flex xs1 text-xs-right>
              <v-icon v-if="syncing" class="processing-icon" medium>sync</v-icon>
              <v-icon v-else medium dark color="success">check_circle</v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-layout wrap>
            <v-flex xs12>
              <v-textarea
                v-model="localIVSDetailModel.notes"
                label="Notes"
                data-vv-name="Notes"
                name="Notes"
                :disabled="disableControls"
                @focus="editStarted"
              ></v-textarea>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model.number="localIVSDetailModel.cost"
                v-validate="'required|min_value:0'"
                label="Cost"
                type="number"
                :disabled="disableControls"
                class="required"
                data-vv-name="Cost"
                name="Cost"
                :error-messages="errors.collect('Cost')"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions v-if="!isIVSCompleted" xs12>
          <v-spacer />
          <v-btn
            class="update-button"
            type="submit"
            :class="!saving ? 'green white--text' : 'grey'"
            :loading="saving"
            :disabled="saving || syncing"
            @click.prevent="saveNotes"
          >
            update
          </v-btn>
          <v-btn
            class="confirm-button"
            type="submit"
            :class="!saving ? 'green white--text' : 'grey'"
            :loading="saving"
            :disabled="saving || syncing"
            @click.prevent="editComplete"
          >
            Complete
          </v-btn>
        </v-card-actions>
        <v-progress-linear
          v-show="saving"
          color="primary"
          height="3"
          value="15"
          :indeterminate="true"
        ></v-progress-linear>
      </v-card>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SiteInvestigationController from '@/api/siteInvestigationController'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'
import TimeLineItemBase from '../TimeLineItemBase'
import store from '@/store'
import IVSDetailModel from '@/models/IVSDetailModel'
import storeGetters from '@/storeGetters'
import moment from 'moment'
import { SIJobProductProgress } from '../../common/enums'

@Component
export default class IVSProcessPreview extends TimeLineItemBase {
  private inEdit = false
  private saving = false
  private syncing = false

  private localIVSDetailModel: IVSDetailModel = new IVSDetailModel()

  private created() {
    this.setLocalAnswer()
  }

  private get ivsDetail(): IVSDetailModel | null {
    return storeGetters.getIVSDetail(this.jobId)
  }

  private get isIVSCompleted(): boolean {
    return this.job && this.job.cancellationReason
      ? true
      : this.ivsDetail
      ? this.ivsDetail.completionDate !== null
      : false
  }

  private get disableControls(): boolean {
    return this.saving || this.syncing || this.isIVSCompleted
  }

  @Watch('syncing')
  private syncingChanged(): void {
    this.$emit('setSyncing', this.syncing)
  }

  @Watch('ivsDetail')
  private jobChanged(): void {
    if (!this.inEdit) {
      // user not editing (got new value so stop syncing)
      this.syncing = false
      if (
        this.ivsDetail &&
        (this.localIVSDetailModel.notes !== this.ivsDetail.notes ||
          this.localIVSDetailModel.cost !== this.ivsDetail.cost)
      ) {
        this.setLocalAnswer()
      }
    }
  }

  // set local answer as provided in job
  private setLocalAnswer(): void {
    if (this.ivsDetail) {
      this.localIVSDetailModel = this.ivsDetail
    }
  }

  // on answer edit started
  private editStarted(): void {
    this.inEdit = true
    this.syncing = false
  }

  // on answer edit complete
  private async editComplete() {
    const validate: boolean = await this.$validator.validateAll()
    if (!validate) {
      return
    }

    this.inEdit = false
    this.saving = true

    // set local answer to request answer model
    const req = Object.assign(new IVSDetailModel(), this.ivsDetail)
    req.notes = this.localIVSDetailModel.notes
    req.cost = this.localIVSDetailModel.cost
    req.status = SIJobProductProgress[SIJobProductProgress.Finished]
    req.completionDate = moment()
    this.SaveIVSDetail(req)
  }

  private async saveNotes() {
    const validate: boolean = await this.$validator.validateAll()
    if (!validate) {
      return
    }

    this.inEdit = false
    this.saving = true

    // set local answer to request answer model
    const req = Object.assign(new IVSDetailModel(), this.ivsDetail)
    req.notes = this.localIVSDetailModel.notes
    req.cost = this.localIVSDetailModel.cost
    req.status = SIJobProductProgress[SIJobProductProgress.InProgress]
    req.completionDate = null
    this.SaveIVSDetail(req)
  }

  // save IVSDetail
  private SaveIVSDetail(req: IVSDetailModel) {
    if (this.isIVSCompleted) {
      this.saving = false
      return
    }

    SiteInvestigationController.UpdateIVSDetails(req)
      .then((res: boolean) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to Update IVS detail!')
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        this.saving = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', err, false)
        this.saving = false
      })
  }
}
</script>

<style scoped>
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.enablement >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
