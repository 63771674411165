export default class UploadedJobDocumentDetail {
  public documentName: string
  public documentTypeId: number
  public documentUrl: string
  public documentType: string
  public order: string
  public uploadedByUserName: string
  public uploadedAt: string
  public note: string
  public availableForEngineer: boolean
  public includeInReport: boolean
  public id: number // required for front-end to manage deleted trade

  public constructor() {
    this.documentType = ''
    this.documentTypeId = 0
    this.note = ''
    this.availableForEngineer = false
    this.includeInReport = false
  }
}
