import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';
import { IEngineerState } from '../states/interfaces/engineer-state';
import EngineerState from '../states/engineer-state';
import EngineerController from '@/api/engineerController';
import EngineerModel from '@/models/contractor/EngineerModel';

const engineerState: IEngineerState = new EngineerState();

const getters: GetterTree<IEngineerState, any> = {
  engineers: (state) => state.engineers,
  engineer: (state) => (engineerId: string) => state.engineers.find(x => x.id === engineerId) || null
};

const mutations: MutationTree<IEngineerState> = {
  updateEngineer: (state: IEngineerState, engineer: EngineerModel) => {
    const index = state.engineers.findIndex((x) => x.id === engineer.id)
    index > -1 ? state.engineers.splice(index, 1, engineer) : state.engineers.push(engineer)
  },
};

const actions: ActionTree<IEngineerState, any> = {
  async submitRetrieveEngineer({ commit }, engineerId: string) {
    const engineer = await EngineerController.GetEngineerDetails(engineerId);
    if (engineer) {
      commit('updateEngineer', engineer)
    }
  }
};

export const engineerModule: Module<IEngineerState, any> = {
  state: engineerState,
  actions,
  mutations,
  getters,
  namespaced: true,
};
