<template>
  <div v-if="cookieRecord" class="cookie-record">
    <v-tooltip class="cookie-record cookie-record--close" top>
      <template #activator="{ on }">
        <v-btn icon flat color="primary" small v-on="on" @click="closeModal()">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
      <span>Close</span>
    </v-tooltip>
    <v-layout row wrap class="pa-3">
      <v-flex xs6 pr-2>
        <v-text-field
          v-model="cookieName"
          v-validate="'required'"
          label="Cookie Name"
          hint="The name of the cookie"
          required
          data-vv-name="cookieName"
          :data-vv-scope="validationScope"
          :error-messages="errors.collect('cookieName')"
          class="mb-3"
        ></v-text-field>
      </v-flex>
      <v-flex xs6 pl-2>
        <v-text-field
          v-model="cookieDescription"
          v-validate="'required'"
          label="Cookie Description"
          hint="A brief description of the cookie"
          required
          data-vv-name="cookieDescription"
          :data-vv-scope="validationScope"
          :error-messages="errors.collect('cookieDescription')"
          class="mb-3"
        ></v-text-field>
      </v-flex>
      <v-flex mb-3 xs12>
        <div class="warning--text mb-2">
          When adding a script, any text inside curly brackets {{}} will be treated as a placeholder and added to the
          cookie settings.
        </div>
        <div class="warning--text mb-2">
          After adding your placeholders to the script, click update placeholders, and proceeed to update the relevant
          settings.
        </div>
        <v-flex>
          <v-expansion-panel>
            <v-expansion-panel-content class="expansion-header">
              <template #header>
                <h4 class="text-uppercase">Cookie Settings</h4>
              </template>
              <CookieConfigurationSettings ref="cookieConfigSettings" :validation-scope="validationScope" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-flex>
      </v-flex>

      <v-flex mb-3 xs12>
        <v-flex>
          <v-expansion-panel>
            <v-expansion-panel-content class="expansion-header">
              <template #header>
                <h4 class="text-uppercase">Cookie Scripts</h4>
              </template>
              <CookieConfigurationScripts ref="cookieConfigScripts" :validation-scope="validationScope" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-flex>
      </v-flex>
      <v-layout row wrap>
        <v-flex class="d-flex" xs12>
          <v-btn color="success" @click="updatePlaceholders()">Update Placeholders</v-btn>
          <v-btn color="success" @click="saveRecord()">Save</v-btn>
          <v-btn color="warning" @click="resetChanges()">Reset changes</v-btn>
          <v-btn color="error" @click="closeModal()">Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-snackbar v-model="showCookieErrors" :timeout="3000" bottom center multi-line color="red">
      <div>{{ cookieRecordErrors[0] }}</div>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import CookieRecord from '@/models/cookies/cookie-record'
import { Component, Vue } from 'vue-property-decorator'
import CookieConfigurationSettings from '@/components/cookies/admin/CookieConfigurationSettings.vue'
import CookieConfigurationScripts from '@/components/cookies/admin/CookieConfigurationScripts.vue'
import Shared from '@/common/shared'

@Component({
  components: {
    CookieConfigurationSettings,
    CookieConfigurationScripts,
  },
})
export default class CookieConfiguration extends Vue {
  private validationScope = 'cookieRecordValidation'
  private cookieRecordErrors: string[] = []
  private showCookieErrors = false

  private get cookieName(): string {
    return this.cookieRecord.name
  }

  private set cookieName(cookieName: string) {
    this.$store.dispatch('cookieModule/submitCookieName', cookieName)
  }

  private get cookieDescription(): string {
    return this.cookieRecord.description
  }

  private set cookieDescription(cookieDescription: string) {
    this.$store.dispatch('cookieModule/submitCookieDescription', cookieDescription)
  }

  private get cookieRecord(): CookieRecord {
    return this.$store.getters['cookieModule/cookieRecord']
  }

  private set cookieRecord(cookieRecord: CookieRecord) {
    this.$store.dispatch('cookieModule/submitCookieRecord', cookieRecord)
  }

  private get cookieRecords(): CookieRecord[] {
    return this.$store.getters['cookieModule/cookieRecords']
  }

  // Needs fixed - Should be able to use Provide/Inject to keep vee validate state global.
  // This is a mess.
  private async validateCookieRecord() {
    const cookieConfigSettings = this.$refs.cookieConfigSettings as CookieConfigurationSettings
    const cookieConfigScripts = this.$refs.cookieConfigScripts as CookieConfigurationScripts
    const isCookieManagementValid: boolean = await this.$validator.validateAll(this.validationScope)
    const isCookieSettingsValid: boolean = await cookieConfigSettings.validateAll()
    const isCookieScriptsValid: boolean = await cookieConfigScripts.validateAll()
    return isCookieManagementValid && isCookieSettingsValid && isCookieScriptsValid
  }

  private async updatePlaceholders() {
    await this.$store.dispatch('cookieModule/submitNewPlaceholderSettings')
  }

  private async saveRecord() {
    await this.updatePlaceholders()

    if (!(await this.validateCookieRecord())) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      this.showValidationBanner()
    } else {
      this.$store.dispatch('cookieModule/submitSaveCookieRecord').then((result) => {
        if (result) {
          this.closeModal()
        }
      })
    }
  }

  // Needs fixed - Again due to issues with how vee validate is implemented.
  // This is nasty
  private showValidationBanner() {
    const cookieConfigSettings = this.$refs.cookieConfigSettings as CookieConfigurationSettings
    const cookieConfigScripts = this.$refs.cookieConfigScripts as CookieConfigurationScripts

    const cookieConfigErrors = this.$validator.errors.items.filter((x) => x.scope === this.validationScope)
    const cookieSettingsErrors = cookieConfigSettings.$validator.errors.items.filter(
      (x) => x.scope === this.validationScope
    )
    const cookieScriptsErrors = cookieConfigScripts.$validator.errors.items.filter(
      (x) => x.scope === this.validationScope
    )

    this.cookieRecordErrors = [...cookieConfigErrors, ...cookieSettingsErrors, ...cookieScriptsErrors].map((x) => x.msg)
    this.showCookieErrors = true
  }

  private closeModal() {
    this.$emit('closeModal')
  }

  private resetChanges() {
    this.cookieRecord = this.cookieRecords.filter((x) => x.id === this.cookieRecord.id)[0]
  }
}
</script>

<style lang="scss">
.cookie-record {
  background: white;
  position: relative;

  &--close {
    position: absolute;
    right: 0;
  }
}

.expansion-header {
  background: #eaeaea !important;
}
</style>
