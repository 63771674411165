<template>
  <div>
    <div class="mb-2"><h4>Product Verification Request</h4></div>
    <div class="mb-1">
      <label>Type: </label>
      <span>{{ productType }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddSIProductVerificationRequest from '../../models/siteInvestigation/requests/AddSIProductVerificationRequest'
import { SIProductType } from '../../common/enums'

@Component
export default class AddSIProductVerificationRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddSIProductVerificationRequest {
    return Object.assign(new AddSIProductVerificationRequest(), this.requestBody)
  }

  private get productType(): string {
    return SIProductType[this.item.siProductType]
  }
}
</script>
