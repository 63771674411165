<template>
  <div>
    <div class="mb-2"><h4>Access Note Answer</h4></div>
    <div v-if="item.answer" class="mb-1">
      <label>Answer Detail: </label>
      <span>{{ item.answer }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddAccessNoteAnswerModel from '@/models/requests/AddAccessNoteAnswerModel'

@Component
export default class AddAccessNoteAnswerLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddAccessNoteAnswerModel {
    return Object.assign(new AddAccessNoteAnswerModel(), this.requestBody)
  }
}
</script>
