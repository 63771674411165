<template>
  <v-card class="elevation-0">
    <v-progress-circular
      v-if="isLoading"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <v-layout v-else wrap bg-white>
      <!-- Job Frees -->
      <v-flex xs12 pa-3>
        <v-expansion-panel class="data-accordion elevation-0">
          <v-expansion-panel-content v-if="job && job.jobType === 'HE'" class="grey lighten-5 mb-3 elevation-1">
            <v-layout slot="header" wrap class="align-center">
              <v-flex xs8>
                <h3 class="pb-2">Job Fees</h3>
              </v-flex>
              <v-flex xs4 class="text-xs-right pr-2">
                <b>
                  Total:
                  {{ getFormatedCurrency(jobFeeItems.reduce((a, b) => a + b.amount, 0)) }}
                </b>
              </v-flex>
            </v-layout>
            <v-card class="grey lighten-5">
              <v-card-text>
                <v-data-table
                  :headers="jobFeeHeaders"
                  :items="jobFeeItems"
                  hide-headers
                  class="gridView elevation-1"
                  hide-actions
                  width="500"
                >
                  <template slot="items" slot-scope="props">
                    <tr>
                      <td>{{ props.item.description }}</td>
                      <td class="text-xs-right">
                        {{ getFormatedCurrency(props.item.amount) }}
                      </td>
                    </tr>
                  </template>
                  <template slot="footer">
                    <td class="text-xs-right"><strong>Total</strong></td>
                    <td class="text-xs-right">
                      <b>
                        {{ getFormatedCurrency(jobFeeItems.reduce((a, b) => a + b.amount, 0)) }}
                      </b>
                    </td>
                  </template>
                </v-data-table>
                <v-flex v-if="getOtherFeesDetail.notes" xs12 pa-3 class="otherFeesDetailNotes">
                  <h3 class="pb-2">Other Fee Notes</h3>
                  <v-divider class="mb-2"></v-divider>
                  <span>{{ getOtherFeesDetail.notes }}</span>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
          <!-- Trade Frees -->
          <v-expansion-panel-content
            v-if="getJobSummaryCardModel && getJobSummaryCardModel.tradeFeeDetails.length > 0"
            class="grey lighten-5 mb-3 elevation-1"
          >
            <v-layout slot="header" wrap class="align-center">
              <v-flex xs8>
                <h3 class="pb-2">Trade Fees</h3>
              </v-flex>
              <v-flex xs4 class="text-xs-right pr-2">
                <b>
                  Total:
                  {{
                    getFormatedCurrency(
                      getJobSummaryCardModel.tradeFeeDetails.reduce((a, b) => a + b.labourCost, 0) +
                        getJobSummaryCardModel.tradeFeeDetails.reduce((a, b) => a + b.materialCost, 0) +
                        getJobSummaryCardModel.tradeFeeDetails.reduce((a, b) => a + b.travelCost, 0)
                    )
                  }}
                </b>
              </v-flex>
            </v-layout>
            <v-card class="grey lighten-5">
              <v-card-text>
                <v-data-table
                  :headers="tradeFeesheaders"
                  :items="getJobSummaryCardModel.tradeFeeDetails"
                  class="gridView elevation-1"
                  hide-actions
                >
                  <template slot="items" slot-scope="props">
                    <tr>
                      <td>{{ props.index + 1 }}</td>
                      <td>{{ props.item.emergencyTypeDescription }} ( {{ props.item.emergencyDetailDescription }} )</td>
                      <td>{{ props.item.tradeDescription }}</td>
                      <td>{{ getFormattedDate(props.item.visitStartedAt) }}</td>
                      <td>
                        {{ getFormattedDate(props.item.engineerOnSiteAt) }}
                      </td>
                      <td class="text-xs-right">
                        {{ getFormatedCurrency(props.item.labourCost + props.item.travelCost) }}
                      </td>
                      <td class="text-xs-right">
                        {{ getFormatedCurrency(props.item.materialCost) }}
                      </td>
                    </tr>
                    <tr
                      rowspan="2"
                      class="revisit-status"
                      :class="props.item.isAnotherVisitRequested ? 'orange lighten-2' : 'green lighten-4'"
                    >
                      <td colspan="3">
                        <span class="font-weight-bold">Visit Notes</span>
                      </td>
                      <td v-if="props.item.isAnotherVisitRequested" colspan="4" class="orange--text text-xs-right">
                        <b>Another Visit Needed</b>
                      </td>
                      <td v-else colspan="4" class="green--text text-xs-right">
                        <b>Additional Visit Not Requested</b>
                      </td>
                    </tr>
                    <tr
                      class="revisit-status btn-isAnotherVisitRequested"
                      :class="props.item.isAnotherVisitRequested ? 'orange lighten-2' : 'green lighten-4'"
                    >
                      <td :colspan="props.item.isAnotherVisitRequested || job.cancellationReason ? 7 : 5">
                        {{ props.item.visitNote }}
                      </td>
                      <td v-if="!props.item.isAnotherVisitRequested && !job.cancellationReason" colspan="2">
                        <v-btn
                          class="red white--text btn-not-ready-to-close"
                          :disabled="payLoading || notReadyToCloseJobLoading"
                          :loading="notReadyToCloseJobLoading"
                          @click="openAdditionalRequestDialog(props.item.visitId)"
                        >
                          Not Ready to Close
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <template slot="footer">
                    <td colspan="3"></td>
                    <td class="text-xs-right"><strong>Total</strong></td>
                    <td class="text-xs-right">
                      <b>
                        {{
                          getFormatedCurrency(
                            getJobSummaryCardModel.tradeFeeDetails.reduce((a, b) => a + b.labourCost, 0)
                          )
                        }}
                      </b>
                    </td>
                    <td class="text-xs-right">
                      <b>
                        {{
                          getFormatedCurrency(
                            getJobSummaryCardModel.tradeFeeDetails.reduce((a, b) => a + b.materialCost, 0)
                          )
                        }}
                      </b>
                    </td>
                    <td class="text-xs-right">
                      <b>
                        {{
                          getFormatedCurrency(
                            getJobSummaryCardModel.tradeFeeDetails.reduce((a, b) => a + b.travelCost, 0)
                          )
                        }}
                      </b>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
          <!-- Customer To Pay -->
          <v-expansion-panel-content
            v-if="getJobSummaryCardModel && getJobSummaryCardModel.ctpJobCardDetails.length > 0"
            class="grey lighten-5 mb-3 elevation-1"
          >
            <v-layout slot="header" wrap class="align-center">
              <v-flex xs8>
                <h3 class="pb-2">Customer To Pay</h3>
              </v-flex>
              <v-flex xs4 class="text-xs-right pr-2">
                <b>
                  Total:
                  {{ getFormatedCurrency(getJobSummaryCardModel.ctpJobCardDetails.reduce((a, b) => a + b.amount, 0)) }}
                </b>
              </v-flex>
            </v-layout>
            <v-card class="grey lighten-5">
              <v-card-text>
                <v-flex xs12 mt-2>
                  <v-data-table
                    :headers="ctpHeaders"
                    :items="getJobSummaryCardModel.ctpJobCardDetails"
                    class="gridView elevation-1 ctptable"
                    hide-actions
                    width="500"
                  >
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td>
                          {{ getFormattedDate(props.item.recordCreatedAt) }}
                        </td>
                        <td class="text-xs-right">
                          {{ getFormatedCurrency(props.item.amount) }}
                        </td>
                        <td
                          class="text-xs-right"
                          :style="'color:' + (props.item.isTransactionCompleted ? 'green' : 'red')"
                        >
                          {{ props.item.isTransactionCompleted ? 'Yes' : 'No' }}
                        </td>
                      </tr>
                    </template>
                    <template slot="footer">
                      <td><strong>Total</strong></td>
                      <td class="text-xs-right">
                        <b>
                          {{
                            getFormatedCurrency(
                              getJobSummaryCardModel.ctpJobCardDetails.reduce((a, b) => a + b.amount, 0)
                            )
                          }}
                        </b>
                      </td>
                      <td class="text-xs-right" colspan="1">------</td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
      <v-flex v-if="isCTPTriggered()" xs12 pa-3 class="ctp-block">
        <h3 class="pb-2">Remaining Payment Summary</h3>
        <v-divider></v-divider>
        <div class="bg-white pa-2">
          <div class="grey px-2 pt-2 pb-3 lighten-3">
            <v-layout wrap>
              <v-flex xs12 py-1 pt-3>
                <b>Paid Amount</b>
                :
                <b class="secondary--text">
                  {{ getFormatedCurrency(getJobSummaryCardModel.completeJobData.prePaidAmount) }}
                </b>
              </v-flex>
              <v-flex xs12 py-1>
                <b>Due Amount</b>
                :
                <span class="amount-pay">
                  <v-text-field
                    v-model="getTotalOverdueAmount"
                    class="mt-0 pt-0 prepend-icon-image"
                    hide-details
                    prepend-icon="money"
                    name="dueAmount"
                  >
                    >
                  </v-text-field>
                  <span class="pound-img">
                    <b class="secondary--text" value="300">&pound;</b>
                  </span>
                </span>
                <v-tooltip v-if="getJobSummaryCardModel.completeJobData.totalConsumerCharged" top>
                  <template #activator="{ on }">
                    <v-icon class="ml-2 md-icon" v-on="on">info</v-icon>
                  </template>
                  <span>Total Chargable Amount</span>
                </v-tooltip>
                <span v-if="getJobSummaryCardModel.completeJobData.totalConsumerCharged" class="grey--text">
                  ({{ getFormatedCurrency(getJobSummaryCardModel.completeJobData.totalConsumerCharged) }})
                </span>
              </v-flex>
              <v-flex v-if="errorMessage" xs12 mt-2>
                <span class="error-info error--text">{{ errorMessage }}</span>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>

      <v-flex
        v-if="
          job &&
          job.jobType === 'US' &&
          job.cancellationReason &&
          getJobSummaryCardModel &&
          !getJobSummaryCardModel.tradeFeeDetails.length > 0 &&
          !getJobSummaryCardModel.ctpJobCardDetails.length > 0 &&
          !isCTPTriggered() &&
          !unableToCompleteReason
        "
        xs12
        pa-3
      >
        <div class="bg-white pa-3">
          <div class="text-xs-center py-2">
            <h3>Do you want to complete the job?</h3>
          </div>
        </div>
      </v-flex>

      <!-- <v-flex xs12 pa-3 v-else>
            <div class="bg-white pa-3">
                <div class="text-xs-center py-2">
                    <h3 v-if="job.cancellationReason">Do you want to close the job?</h3>
                    <h3 v-else>All the visits are completed, do you want to close the job?</h3>
                </div>
            </div>
        </v-flex> -->

      <v-flex xs12 pa-3 text-xs-right>
        <v-divider></v-divider>
        <v-btn
          v-if="job.cancellationReason && isCTPTriggered()"
          class="red white--text btn-pay-and-cancel"
          :disabled="payLoading || notReadyToCloseJobLoading || getTotalOverdueAmount < 0 || unableToCompleteReason"
          :loading="payLoading"
          @click="completeCTPProcess('Cancelled')"
        >
          Pay & Cancel
        </v-btn>
        <v-btn
          v-else-if="job.cancellationReason && !isCTPTriggered()"
          class="red white--text btn-cancel"
          :disabled="payLoading || notReadyToCloseJobLoading || unableToCompleteReason"
          :loading="payLoading"
          @click="onCompleteJobConfirm('Cancelled')"
        >
          Cancel
        </v-btn>
        <v-btn
          v-else-if="!job.cancellationReason && isCTPTriggered()"
          class="green white--text btn-pay-and-complete"
          :disabled="payLoading || notReadyToCloseJobLoading || getTotalOverdueAmount < 0 || unableToCompleteReason"
          :loading="payLoading"
          @click="completeCTPProcess('Completed')"
        >
          Pay & Complete
        </v-btn>
        <v-btn
          v-else-if="!job.cancellationReason && !isCTPTriggered()"
          class="green white--text btn-complete"
          :disabled="payLoading || notReadyToCloseJobLoading || unableToCompleteReason"
          :loading="payLoading"
          @click="onCompleteJobConfirm('Completed')"
        >
          Complete
        </v-btn>
      </v-flex>
      <JobCompletionWarning
        v-if="showJobCompletionWarningDialog"
        @closeJobCompletionWarningDialog="showJobCompletionWarningDialog = false"
      />
      <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
        {{ saveSnackbarText }}
        <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
      </v-snackbar>
    </v-layout>

    <!-- dialog - material request -->
    <v-dialog
      v-if="additionalRequestDialog && itemId && jobId"
      v-model="additionalRequestDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <AdditionalRequest
        ref="refAdditionalRequest"
        :job-id="jobId"
        :item-id="itemId"
        :is-from-job-completion-card="true"
        @closeAdditionalRequestDialog="closeAdditionalRequestDialog"
      ></AdditionalRequest>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import moment from 'moment'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import JobSummaryCardModel from '@/models/claim/JobSummaryCardModel'
import store from '@/store'
import eventBus from '@/common/bus'
import AddRequestToEscalateCTPProcessModel from '../../models/requests/AddRequestToEscalateCTPProcessModel'
import JobController from '../../api/jobController'
import AddCloseJobRequestModel from '../../models/requests/AddCloseJobRequestModel'
import JobNotReadyToCloseModel from '../../models/requests/JobNotReadyToCloseModel'
import OtherJobFeeDetailModel from '@/models/claim/OtherJobFeeDetailModel'
import JobCompletionWarning from '@/components/JobCompletionWarning.vue'
import { APIResponseCode, JobStatus } from '@/models/types'
import AdditionalRequest from '@/components/timeline/AdditionalRequest.vue'

@Component({
  components: { JobCompletionWarning, AdditionalRequest },
})
export default class JobCompleteConfirmationPreview extends Vue {
  @Prop() private jobId: string
  private jobSummaryCardModel: JobSummaryCardModel = new JobSummaryCardModel()
  private tradeFeesheaders: any = []
  private ctpHeaders: any = []
  private jobFeeHeaders: any = []
  private jobFeeItems: any = []
  private notReadyToCloseJobLoading = false
  private payLoading = false
  private isLoading = true
  private errorMessage = ''
  private saveSnackbarTimeout: number = Shared.snackbarTimeout
  private saveSnackbar = false
  private saveSnackbarText = ''
  private showJobCompletionWarningDialog = false
  private additionalRequestDialog = false
  private itemId = ''

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  public get unableToCompleteReason(): string | null {
    return this.$store.getters['getUnableToCompleteReason'](this.jobId)
  }

  public get jobStatus(): JobStatus | undefined {
    return this.job && this.job.status
  }

  private created() {
    this.tradeFeesheaders = [
      { text: 'Visit', value: 'visitId', sortable: false },
      { text: 'Emergency', value: 'emergencyTypeDescription', sortable: false },
      { text: 'Trade Type', value: 'tradeDescription', sortable: false },
      { text: 'Visit Start', value: 'visitStartedAt', sortable: false },
      { text: 'Time Onsite', value: 'engineerOnSiteAt', sortable: false },
      { text: 'Labour Cost', value: 'labourCost', sortable: false },
      { text: 'Material Cost', value: 'materialCost', sortable: false },
    ]
    this.ctpHeaders = [
      { text: 'Date', value: 'recordCreatedAt', sortable: false },
      { text: 'Amount', value: 'amount', align: 'right', sortable: false },
      {
        text: 'Amount Paid',
        value: 'isTransactionCompleted',
        align: 'right',
        sortable: false,
      },
    ]
    this.jobFeeHeaders = [
      { text: 'Description', value: 'description', sortable: false },
      { text: 'Amount', value: 'amount', align: 'right', sortable: false },
    ]
    this.jobFeeItems = [
      { description: 'BER', amount: this.getOtherFeesDetail.berAmount },
      {
        description: 'Heaters',
        amount: this.getOtherFeesDetail.temporaryHeatersAmount,
      },
      {
        description: 'Alternative Accommodation',
        amount: this.getOtherFeesDetail.alternativeAccommodationAmount,
      },
      {
        description: 'Handling Fee',
        amount:
          this.job && this.job.clientInvoiceDetails && this.job.clientInvoiceDetails.length > 0
            ? this.job.clientInvoiceDetails.reduce(
                (a, b) => a + (b.invoiceDetail && b.invoiceDetail.handlingFee ? b.invoiceDetail.handlingFee : 0),
                0
              )
            : 0,
      },
    ]
    this.getJobSummaryDetails()
  }

  private async getJobSummaryDetails() {
    JobController.GetJobSummaryDetail(this.jobId)
      .then((res: JobSummaryCardModel | null) => {
        if (res) {
          this.jobSummaryCardModel = res
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading job summary detail, please try again', true)
      })
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), store.Instance.state.Environment.DateTimeFormat)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private get getJobSummaryCardModel() {
    return this.jobSummaryCardModel
  }

  private isCTPTriggered() {
    return this.getJobSummaryCardModel.completeJobData.isCTPTrigger
  }

  private completeCTPProcess(status: string) {
    // open confirmation popup for trigger CTP process
    const amountToPay = Number(
      (
        this.getJobSummaryCardModel!.completeJobData!.dueAmount -
        this.getJobSummaryCardModel!.completeJobData!.prePaidAmount
      ).toFixed(2)
    )
    if (amountToPay > 0) {
      if (this.getAbilityToAccessCustomerToPay) {
        Shared.confirmationPopup.open(
          'Do you want to pay ' + this.getFormatedCurrency(amountToPay) + '?',
          '',
          '',
          'No',
          'Yes',
          this,
          'makeCustomerToPay',
          amountToPay
        )
        return
      } else {
        // add audit log and show message if user is not authorized to do CTP process.
        const escalateCTPProcess: AddRequestToEscalateCTPProcessModel = new AddRequestToEscalateCTPProcessModel()
        escalateCTPProcess.jobId = this.jobId
        escalateCTPProcess.escalateBy = store.Instance.state.SessionDetail.userName
        escalateCTPProcess.description = 'Customer To Pay Process is required to complete the job.'
        Shared.confirmationPopup.open(
          'You do not have permission to perform customer to pay. Do you want to escalate this to your team leader?',
          '',
          '',
          'No',
          'Yes',
          this,
          'escalateCTPProcess',
          escalateCTPProcess
        )
      }
    } else {
      this.onCompleteJobConfirm(status)
    }
  }

  private escalateCTPProcess(escalateCTPProcess: AddRequestToEscalateCTPProcessModel) {
    // user agreed to escalate CTP process, add request to add audit log
    JobController.AddRequestToEscalateCTPProcess(escalateCTPProcess)
      .then((response: boolean) => {
        if (response) {
          this.showSnackBar(
            'You do not have permission to perform customer to pay, you must escalate this to your team leader.'
          )
        }
        return
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting request to escalate CTP process, please try again', true)
      })
  }

  private makeCustomerToPay(amount: number) {
    // trigger CTP process
    eventBus.$emit('makeCustomerToPay')
    // wait till the customerToPay Card Added to timeline
    setTimeout(() => {
      eventBus.$emit('paymentOnClosingJob', amount)
    }, 0)
  }

  private onCompleteJobConfirm(status = '') {
    const confirmStatus: string =
      'Are you sure you want to ' + (status === 'Completed' ? 'complete' : 'cancel') + ' the job?'
    Shared.confirmationPopup.open(confirmStatus, '', '', 'No', 'Yes', this, 'onCompleteJob', status)
  }

  private onCompleteJob(status = '') {    
    this.payLoading = true
    const addCloseJobRequestModel: AddCloseJobRequestModel = new AddCloseJobRequestModel()
    addCloseJobRequestModel.jobId = this.jobId
    addCloseJobRequestModel.jobStatus = status
    addCloseJobRequestModel.dueAmount = this.getTotalOverdueAmount
    JobController.AddCloseJobRequest(addCloseJobRequestModel)
      .then((res: [APIResponseCode, boolean]) => {
        if (res[0] === 'Error') {
          this.showSnackBar('Something went wrong!')
        }
      })
      .catch((err: any) => {
        if (err.response && err.response.status === 412) {
          // error when job has outstanding engineer request(s)
          this.showJobCompletionWarningDialog = true
        } else {
          this.errorMessage = err.response.statusText
          this.showSnackBar(this.errorMessage)
        }
        this.payLoading = false
      })
  }

  private get getTotalOverdueAmount() {
    return this.getJobSummaryCardModel!.completeJobData!.dueAmount
  }

  private set getTotalOverdueAmount(newValue: number) {
    this.getJobSummaryCardModel!.completeJobData!.dueAmount = newValue
  }

  private get getAbilityToAccessCustomerToPay(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.hasCustomerToPayAccess
    )
  }

  private get getOtherFeesDetail(): OtherJobFeeDetailModel {
    const clientInvoiceDetail =
      this.job && this.job.clientInvoiceDetails && this.job.clientInvoiceDetails.length > 0
        ? this.job.clientInvoiceDetails.find((c) => c.otherJobFeeDetail !== null)
        : undefined
    return clientInvoiceDetail && clientInvoiceDetail.otherJobFeeDetail
      ? clientInvoiceDetail.otherJobFeeDetail
      : new OtherJobFeeDetailModel()
  }

  private showSnackBar(text: string) {
    this.saveSnackbar = true
    this.saveSnackbarText = text
  }

  private openAdditionalRequestDialog(visitId: string) {
    this.itemId = visitId
    this.additionalRequestDialog = true
  }

  private closeAdditionalRequestDialog() {
    this.itemId = ''
    this.additionalRequestDialog = false
  }

  @Watch('jobStatus')
  private jobCompleteChange() {
    if (this.jobStatus === 'Completed') 
      this.payLoading = false      
  }
}
</script>

<style scoped>
.amount-pay {
  width: 110px;
  display: inline-block;
  position: relative;
  top: 6px;
}
.pound-img {
  position: absolute;
  left: 8px;
  top: 6px;
  font-size: 15px;
}
.amount-pay >>> .v-input__prepend-outer {
  opacity: 0;
}
.amount-pay >>> .v-text-field__slot {
  font-weight: 600;
  color: #e91e63;
  font-size: 15px;
}
.gridView table.v-table tbody td:not(:first-child) {
  padding: 0 25px 0 0 !important;
}
.job-fee {
  padding: 4px;
}
.revisit-status b {
  background-color: white;
  padding: 4px;
}
.bold-select {
  font-weight: 600;
}
</style>
