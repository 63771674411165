import { render, staticRenderFns } from "./SiUsJobQuestionsPreview.vue?vue&type=template&id=54b4727d&scoped=true"
import script from "./SiUsJobQuestionsPreview.vue?vue&type=script&lang=ts"
export * from "./SiUsJobQuestionsPreview.vue?vue&type=script&lang=ts"
import style0 from "./SiUsJobQuestionsPreview.vue?vue&type=style&index=0&id=54b4727d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b4727d",
  null
  
)

export default component.exports