import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import moment from 'moment'

export default class EngineerVisitDetail extends CosmosJobsDbRecordBase {
  public forEmergencyTypeId: number
  public forEmergencyDetailId: number
  public contractorId: string
  public contractorAppointedDetailId: string
  public engineerId: string
  public companyName: string
  public latitude: number
  public longitude: number
  public status: string
  public address: string
  public postcode: string
  public policyScheme: string
  public isEngineerReAttend: boolean
  public insuranceValue: string
  public customerName: string
  public etaFrom: moment.Moment | null
  public etaTo: moment.Moment | null
  public description: string
  public tradeId: string
  public processByUserId: string
  public processByUserName: string
  public processAt: moment.Moment | null
  public isSatisfied: boolean
  public customerSignatureFileURL: string
  public customerDisclaimerSignOffSignatureFileURL: string
  public isCheckForHealthAndSafety: boolean
  public visitNote: string
  public visitNumber: number
  public canChargeCallout: boolean
  public jobAcceptOrRejectAt: moment.Moment | null
  public engineerOnSiteAt: moment.Moment | null
  public engineerOnTheWayAt: moment.Moment | null
  public distanceToCustomerLocation: number | null
  public visitStartedAt: moment.Moment | null
  public visitCompletedAt: moment.Moment | null
  public isGasSafetyCheckComplete: boolean
  public abandonNotes: string
  public abandonedAt: moment.Moment | null
  public engineerOffSiteAt: moment.Moment | null
  public siReturnVisitReason: string
  public siReturnVisitNote: string
  public engineerFirstName: string
  public engineerLastName: string
  public jobStartLatitude: number
  public jobStartLongitude: number
  public isCustomerContacted: boolean
  public clientInvoiceDetailId: string
  public contractorInvoiceDetailId: string
  public visitOutcomeDescription: string
}
