<template>
  <v-layout wrap class="mb-2">
    <v-flex v-if="ivsDetail.cost > 0" xs12 mb-2>
      <span>
        Cost :
        <b class="secondary--text">&pound;{{ ivsDetail.cost }}</b>
      </span>
    </v-flex>
    <v-flex v-if="ivsDetail.completionDate" xs12 mb-2>
      <span>
        Completion Date :
        <b class="secondary--text">
          {{ formatDate(ivsDetail.completionDate) }}
        </b>
      </span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import IVSDetailModel from '@/models/IVSDetailModel'
import moment, { Moment } from 'moment'

@Component
export default class IVSProcessCard extends TimeLineItemBase {
  private get ivsDetail(): IVSDetailModel | null {
    const detail: IVSDetailModel | null = storeGetters.getIVSDetail(this.jobId)
    if (detail) {
      return detail
    }
    return new IVSDetailModel()
  }

  private formatDate(date: Date | string | Moment) {
    return moment(date).format('DD MMM YYYY')
  }
}
</script>
