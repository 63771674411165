<template>
  <div class="pictureUploadPreview inner-content">
    <v-card v-if="claimPictures.length > 0" class="pt-0 px-0 pb-2 up-card mb-3 elevation-0 grey lighten-5">
      <v-layout wrap>
        <v-flex xs12>
          <h3 v-if="uploadedBy === 'Customer'" class="pt-3 pb-1 pl-2 mb-2">Uploaded Images By Client</h3>
          <h3 v-else-if="uploadedBy === 'EngineerBeforeJob'" class="pt-3 pb-1 pl-2 mb-2">
            Uploaded Images By Engineer Before Job
          </h3>
          <h3 v-else-if="uploadedBy === 'EngineerAfterJob'" class="pt-3 pb-1 pl-2 mb-2">
            Uploaded Images By Engineer After Job
          </h3>
          <h3 v-else-if="uploadedBy === 'PolicyHolderMissing'" class="pt-3 pb-1 pl-2 mb-2">
            Uploaded Images By Engineer For Customer Not Present
          </h3>
          <h3 v-else-if="uploadedBy === 'DuringJobPhotos'" class="pt-3 pb-1 pl-2 mb-2"
            >Uploaded Images By Engineer During Job
          </h3>
          <v-container fluid grid-list-lg pa-2 :class="uploadedBy === '' ? 'pa-0' : ''">
            <v-layout wrap>
              <v-flex v-for="(item, i) in claimPictures" :key="i" lg4 md6 class="img-block pa1 mb-2">
                <div class="up-images pa-1" @click="toggleCarousel(i, item.uploadedBy, item.fileURL)">
                  <div v-if="item.note" class="hover-content-section uploadimage-notes">
                    <v-btn icon small color="white" class="notes elevation-1">
                      <v-icon class="primary--text md-icon">notes</v-icon>
                    </v-btn>
                    <div class="yellow lighten-4 hover-item text-xs-left px-2">
                      <h4 class="my-2">Notes</h4>
                      <p>{{ item.note }}</p>
                    </div>
                  </div>
                  <img :src="item.fileURL" class="image" :alt="item.fileName" width="100%" height="100%" />
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-card>

    <VueEasyLightbox
      :visible="showPictureUploadDialog"
      :imgs="blobOfImages"
      :index="pictureIndex"
      @hide="hidePictureUploadDialog"
    ></VueEasyLightbox>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import VueEasyLightbox from 'vue-easy-lightbox'
import eventBus from '@/common/bus'
import DocumentController from '@/api/documentController'
import { imageExists } from '@/common/images'
import PhotosController from '@/api/photos-controller'

@Component({
  components: { VueEasyLightbox },
})
export default class PictureUpload extends Vue {
  @Prop() private pictureList: PictureUploadModel[] | any[]
  @Prop() private jobId: string
  @Prop() private uploadedBy: string
  @Prop({ default: true }) private showClaimThumb: boolean

  private showPictureUploadDialog = false
  private pictureIndex = 0
  private pictureType = ''
  private carouselPictureList: string[] = []
  private claimPictures: PictureUploadModel[] = []
  private blobOfImages: string[] = []

  @Watch('pictureList')
  private onClaimPictureChange(newValue: PictureUploadModel[], oldValue: PictureUploadModel[]) {
    this.claimPictures = JSON.parse(JSON.stringify(this.pictureList))
    this.getImages()
  }

  private mounted() {
    this.claimPictures = JSON.parse(JSON.stringify(this.pictureList))
    Object.assign(
      this.blobOfImages,
      this.claimPictures.map((c) => c.fileURL)
    )
    this.getImages()
  }

  private getImages() {
    if (this.claimPictures.length > 0) {
      for (let i = 0; i < this.claimPictures.length; i++) {
        this.getSASTokenForClaimThumb(i)
      }
    }
  }

  private async getSASTokenForClaimThumb(i: number): Promise<void> {
    const photo = this.claimPictures[i]
    const url: string = photo.fileURL
    const exists = await imageExists(url)
    if (exists) {
      return
    }
    if (photo.fileURL.indexOf('?') > 0) {
      return
    }
    if (this.showClaimThumb) {
      try {
        const res = await PhotosController.GetThumbnailUrl(photo.jobId, photo.id)
        if (res) {
          this.handleResponse(res, i)
        }
      } catch {
        eventBus.$emit('errorHandler', 'Error loading image, please try again', true)
      }
    } else {
      const fileUrl = photo.fileURL.substring(photo.fileURL.indexOf(this.jobId))
      const res = await DocumentController.GetSASTokenForRecordingUrl(fileUrl)
      if (res && typeof res === 'string') {
        this.handleResponse(res, i)
      }
    }
  }

  private handleResponse(url: string, index: number) {
    this.claimPictures[index].fileURL = url
    this.claimPictures = [...this.claimPictures]
  }

  private toggleCarousel(itemIndex: number, pictureType: string, fileURL: string) {
    if (this.showPictureUploadDialog) {
      eventBus.$emit('showHideOverlayImage', true)
      this.showPictureUploadDialog = false
    }
    if (fileURL !== '') {
      if (this.carouselPictureList.length > 0) {
        for (let i = 0; i < this.carouselPictureList.length; i++) {
          if (this.carouselPictureList[i] !== undefined) {
            this.imageUrlCheck(i)
          }
        }
      }
      const item: string | undefined =
        this.carouselPictureList.length > 0
          ? this.carouselPictureList.find(
              (e) =>
                e !== undefined &&
                e.substring(e.indexOf('claimPictures/'), e.indexOf('?')).replace(/^.*[\\/]/, '') ===
                  fileURL.substring(fileURL.indexOf(this.jobId), fileURL.indexOf('?')).replace(/^.*[\\/]/, '')
            )
          : undefined
      if (item === undefined) {
        const url: string = fileURL.substring(fileURL.indexOf(this.jobId), fileURL.indexOf('?'))
        this.GetSASTokenForBlob(url, itemIndex)
      } else {
        this.pictureIndex = itemIndex
        this.showPictureUploadDialog = true
        eventBus.$emit('showHideOverlayImage', false)
        this.bindPrevNextClickEvent()
      }
      this.pictureType = pictureType
    } else {
      this.showPictureUploadDialog = false
    }
  }

  private async imageUrlCheck(i: number) {
    const fileURL = this.carouselPictureList[i]
    const exists = await imageExists(fileURL)
    if (!exists) {
      this.carouselPictureList.splice(
        this.carouselPictureList.indexOf(
          fileURL.substring(fileURL.indexOf('claimPictures/'), fileURL.indexOf('?')).replace(/^.*[\\/]/, '')
        ),
        1
      )
      const url: string = fileURL.substring(fileURL.indexOf(this.jobId), fileURL.indexOf('?'))
      this.GetSASTokenForBlob(url, i)
    }
  }

  private GetSASTokenForBlob(url: string, index) {
    const self = this
    const tempArray: string[] = Object.assign([], self.carouselPictureList) // required for Dynamic rendering of <v-carousel> items
    self.carouselPictureList = []
    DocumentController.GetSASTokenForRecordingUrl(url).then((res: string | null) => {
      if (res && typeof res === 'string') {
        const asd: any = self.blobOfImages
        tempArray[index] = res
        self.blobOfImages[index] = ''
        asd[index] = res
        self.showPictureUploadDialog = true
        eventBus.$emit('showHideOverlayImage', false)
        self.pictureIndex = index
        self.carouselPictureList = tempArray
        Object.assign(self.blobOfImages, asd)
        // bind next prev button click event.
        self.bindPrevNextClickEvent()
      } else {
        self.showPictureUploadDialog = false
      }
    })
  }

  private bindPrevNextClickEvent() {
    setTimeout(() => {
      const nextBtn = document.getElementsByClassName('btn__next')[0] as HTMLElement
      if (nextBtn) {
        nextBtn.onclick = () => {
          if (nextBtn.className === 'btn__next disable') {
            return false
          }
          const index = this.pictureIndex + 1
          if (this.claimPictures[index] && this.claimPictures[index].uploadedBy) {
            const pictureType = this.claimPictures[index].uploadedBy
            const fileURL = this.claimPictures[index].fileURL
            this.toggleCarousel(index, pictureType, fileURL)
          }
        }
      }

      const prevBtn = document.getElementsByClassName('btn__prev')[0] as HTMLElement
      if (prevBtn) {
        prevBtn.onclick = () => {
          if (prevBtn.className === 'btn__prev disable') {
            return false
          }
          const index = this.pictureIndex - 1
          if (this.claimPictures[index] && this.claimPictures[index].uploadedBy) {
            const pictureType = this.claimPictures[index].uploadedBy
            const fileURL = this.claimPictures[index].fileURL
            this.toggleCarousel(index, pictureType, fileURL)
          }
        }
      }
    }, 200)
  }

  private hidePictureUploadDialog() {
    this.showPictureUploadDialog = false
    this.toggleCarousel(0, '', '')
  }
}
</script>

<style>
.jumbotron__wrapper .jumbotron__image {
  min-width: auto !important;
  max-width: 100%;
  height: 100%;
}
.active-img {
  display: block !important;
}
</style>

<style scoped>
.picture-craousel {
  position: relative;
}

.img-block {
  text-align: center;
  cursor: pointer;
}

.img-block .up-images {
  border: 1px solid #ededed;
}

.img-block img {
  height: 100px;
  width: 100%;
  cursor: pointer;
  object-fit: contain;
  display: inherit;
}

.img-title {
  font-weight: 600;
  font-size: 12px;
}

.picture-craousel >>> .carousel__right .v-btn .v-btn__content .v-icon,
.picture-craousel >>> .carousel__left .v-btn .v-btn__content .v-icon {
  color: #fff;
  background-color: #e0e0e0;
  border-radius: 50%;
  font-size: 38px !important;
}

.craousel-dialog-close {
  top: 8px;
  right: 8px;
}
.up-images {
  position: relative;
}
.up-images .notes {
  box-shadow: 0px 1px 2px 0px !important;
  position: absolute;
  top: -18px;
  right: -18px;
}
.hover-content-section .hover-item {
  display: none;
  position: absolute;
}
.hover-content-section {
  position: absolute;
  display: inline-block;
  right: 0px;
}
.hover-content-section:hover .hover-item {
  display: block;
  position: absolute;
  right: -31px;
  top: 16px;
  text-align: center;
  /* left: 0px; */
  width: 250px;
}
@media only screen and (max-width: 1650px) {
  .hover-content-section:hover .hover-item {
    width: 200px;
  }
}
@media only screen and (max-width: 1300px) {
  .hover-content-section:hover .hover-item {
    width: 180px;
  }
}
</style>
