<template>
  <div>
    <v-layout row wrap>
      <v-flex>
        <v-card class="elevation-1 pa-0">
          <v-layout>
            <v-flex class="covrage-sidebar">
              <v-layout wrap>
                <v-flex xs12 pl-3 pr-4>
                  <v-text-field
                    v-model="searchInput"
                    label="Search by postcode"
                    prepend-icon="search"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="outcodesByArea.length === 0" pr-3 mt-2>
                  <span class="record-not-found">Record not found.</span>
                </v-flex>
                <v-flex v-bar xs12 px-3 mt-2>
                  <div class="area-list-content pb-3 pr-0">
                    <div v-if="outcodesByArea.length > 0">
                      <v-layout v-for="postcode in outcodesByArea" :key="postcode.id" class="checkoutput-row">
                        <div class="check-outcode">
                          <v-checkbox
                            v-model="selectedPostcode"
                            name="areaCode"
                            :hide-details="true"
                            :value="postcode.areaCode"
                            :label="postcode.areaCode + ' - ' + postcode.description"
                            color="primary"
                            class="que-checkbox"
                            @change="onPostalCodeChange()"
                          ></v-checkbox>
                        </div>
                        <v-flex>
                          <v-expansion-panel class="elevation-0">
                            <div class="collapse-list">
                              <v-expansion-panel-content :lazy="true">
                                <div slot="header">
                                  <span class="area-checkbox">
                                    <a v-if="selectedPostcode.indexOf(postcode.areaCode) !== -1" class="tradecount">
                                      (Trade: {{ getTradeList.length }})
                                    </a>
                                  </span>
                                </div>
                                <v-card v-for="district in postcode.outcodes" :key="district" class="elevation-0">
                                  <v-card-text class="pl-4 district-list">
                                    <div>
                                      <v-checkbox
                                        v-model="selectedDistrict"
                                        :hide-details="true"
                                        :label="district"
                                        :value="district"
                                        color="primary"
                                        class="que-checkbox"
                                        @change="onDistrictChange(selectedDistrict, district, postcode.areaCode)"
                                      ></v-checkbox>
                                      <a
                                        v-if="selectedDistrict.indexOf(district) !== -1"
                                        class="tradecount"
                                        @click="onTradeCountClick(district, postcode.areaCode)"
                                      >
                                        (Trade: {{ getTradeCount(district) }})
                                      </a>
                                    </div>
                                  </v-card-text>
                                </v-card>
                              </v-expansion-panel-content>
                            </div>
                          </v-expansion-panel>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex class="covrage-mapsection">
              <v-layout wrap row class="show-map-cn inblock">
                <v-flex xs12 class="map-content">
                  <v-card class="map-card elevation-0" :class="showTradelist ? 'show-availability-job' : ''">
                    <div class="map-section-content">
                      <v-icon
                        v-show="!showFullScreenMap"
                        ref="fullScreenIcon"
                        class="fullScreenIcon"
                        :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
                        @click="toggleFullScreenView"
                      >
                        fullscreen
                      </v-icon>
                      <v-icon
                        v-show="showFullScreenMap"
                        ref="fullScreenIcon"
                        class="fullScreenIcon"
                        :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
                        @click="toggleFullScreenView"
                      >
                        fullscreen_exit
                      </v-icon>
                      <div class="map">
                        <div class="map-area">
                          <v-layout row wrap class="map-toolbar">
                            <v-flex xs12 pl-2 pr-2 class="search-lc pt-2 pr-3">
                              <v-text-field
                                id="searchTextField"
                                v-model="autocompleteValue"
                                hide-details
                                prepend-icon="place"
                              ></v-text-field>
                              <div class="fl-polygon-btn">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn
                                      flat
                                      :class="drawPolygon ? 'btn polygon-btn Activeoption' : 'btn polygon-btn'"
                                      @click="drawPolygonOnMap"
                                      v-on="on"
                                    >
                                      <img src="/img/polygon.svg" alt="" />
                                    </v-btn>
                                  </template>
                                  <span>Draw Polygon</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn
                                      flat
                                      :class="drawCircle ? 'btn circle-btn Activeoption' : 'btn circle-btn'"
                                      v-on="on"
                                      @click="drawCircleOnMap"
                                    >
                                      <img src="/img/circle.svg" alt="" />
                                    </v-btn>
                                  </template>
                                  <span>Draw Circle</span>
                                </v-tooltip>
                              </div>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                            <v-flex xs12>
                              <div id="customMap" ref="mapView"></div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                    </div>
                    <div class="availability-job">
                      <v-card-title class="py-3 px-2">
                        <h3 class="grey--text text--darken-1 d-inline-block">
                          Trade
                          <span v-if="selectedDistrictName">({{ selectedDistrictName }})</span>
                        </h3>
                        <v-spacer></v-spacer>
                        <v-btn icon flat color="primary" class="back-btn" @click="hideTradeList()">
                          <v-icon>arrow_forward</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-divider />
                      <div v-if="getTradeList.length > 0" v-bar="{ useScrollbarPseudo: true }">
                        <div class="job-availability-content pl-2">
                          <v-checkbox
                            v-model="selectAllTradeList"
                            :hide-details="true"
                            label="Select All"
                            color="primary"
                            class="que-checkbox"
                            @change="onSelectAllTradeList"
                          ></v-checkbox>
                          <v-checkbox
                            v-for="trade in getTradeList"
                            :key="trade.tradeId"
                            v-model="selectedTradeList"
                            :hide-details="true"
                            :label="trade.description"
                            :value="trade"
                            color="primary"
                            class="que-checkbox"
                            :class="trade.isDeleted ? 'deleted-item' : ''"
                            @change="onTradeSelectionChange($event, trade)"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-flex>
                <v-flex xs12 text-xs-right mb-1 mt-1>
                  <v-btn
                    color="primary white--text"
                    class="mr-3"
                    :disabled="isLoading"
                    :loading="isLoading"
                    @click.stop="save"
                  >
                    Save
                  </v-btn>
                </v-flex>
                <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
                  {{ saveSnackbarText }}
                  <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
                </v-snackbar>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-if="mapCircleDialog" v-model="mapCircleDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="pl-3 pr-1 pb-0">
          <h4>Enter Radius in KM:</h4>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-text-field v-model="circleRadius" :hide-details="true"></v-text-field>
        </v-card-text>
        <v-card-actions class="pt-2 px-3 pb-2">
          <v-spacer></v-spacer>
          <v-btn color="primary" small flat @click="mapCircleDialog = false">Cancel</v-btn>
          <v-btn color="primary" small :disabled="circleRadius <= 0" @click="drawCircleWithRadius">Draw Circle</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import axios from 'axios'
import ContractorCoverageModel from '@/models/contractor/ContractorCoverageModel'
import MapLocationModel from '@/models/contractor/MapLocationModel'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import PolicyHolderController from '@/api/policyHolderController'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import ContractorCoverageDistrictModel from '@/models/contractor/ContractorCoverageDistrictModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import Shared from '@/common/shared'
import Area from '@/models/contractor/Area'
import eventBus from '@/common/bus'
declare let google: any

export interface LatLng {
  Lat: number
  Lng: number
}

@Component
export default class ContractorArea extends Vue {
  @Prop() private coverage: ContractorCoverageModel[]
  @Prop() private contractorId: string
  @Prop() private contractorAvailability: ContractorTradeAvailabilityModel[]
  private location: MapLocationModel = new MapLocationModel()
  private cordinateList: any[] = []
  private resultMap: any
  private tempSelectedPostcode: any[] = []
  private bounds: any
  private circleRadius = 1
  private drawingManager: any = null
  private selectedPostcode: any[] = []
  private mapAutocomplete: any
  private autocompleteValue = ''
  private marker: any = null
  private mapCircleDialog = false
  private mapListenerEvent: any = null
  private drawPolygon = false
  private drawCircle = false
  private polygonCoords: any = []
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = 'Coverage area saved successfully.'
  private isLoading = false
  private showFullScreenMap = false
  private searchInput: any = ''
  private showTradelist = false
  private trades: TradeModel[] = []
  private selectedTradeList: TradeModel[] = []
  private tradeList: TradeModel[] = []
  private tooltipObject: any = null
  private coordPropName: any = null
  private offset: any = { x: 20, y: 20 }
  private selectedAreaTradeList: any = []
  private selectedCoverage: any = null
  private selectedCoveragePostCode: any = null
  private selectAllTradeList = false
  private drawnPolygonList: any[] = []
  private drawnCircleList: any[] = []
  private selectedDistrict: string[] = []
  private selectedDistrictName = ''

  private center: LatLng = { Lat: 54.5, Lng: -3 }
  private currentFeature!: any
  private currentOutCodes!: any
  private internalAreas: Area[] = []
  private outcodesByArea: any[] = []
  private tempOutcodesByArea: any[] = []
  private allSelectedPostcode: any[] = []
  private tempSelectedTradeList: TradeModel[] = []

  private get internalOutCodeStrings(): string[] {
    return this.internalAreas.reduce((p, c) => p.concat(...c.OutCodes), [] as string[])
  }
  private get internalAreaStrings(): string[] {
    return this.internalAreas.map((ia) => ia.AreaCode)
  }

  private async addMapsScript() {
    try {
      this.initMap()
    } catch (ex) {
      eventBus.$emit('validationErrorHandler', 'Error rendering map. Please try again')
    }
  }

  private async loadAreas() {
    await new Promise<void>((r) => this.resultMap.data.loadGeoJson('/mapdata/areas.json', undefined, () => r()))
    this.resultMap.data.setStyle({
      fillColor: 'transparent',
      opacity: 0,
      strokeWeight: 1.2,
    })
    this.resultMap.data.addListener('click', this.featureClickedHandler)

    // Show Tooltip
    this.resultMap.data.addListener('mouseover', (event) => {
      const areaTradeList: any = this.selectedAreaTradeList.find(
        (item) =>
          !item.object &&
          (event.feature.getProperty('o')
            ? item.postCode === event.feature.getProperty('a') + '-' + event.feature.getProperty('o')
            : item.postCode.split('-')[0] === event.feature.getProperty('a'))
      )
      let trades: any = []
      if (areaTradeList !== undefined) {
        areaTradeList.tradeList.forEach((trade) => {
          trades.push(trade.description)
        })
      } else {
        trades = []
      }
      const tradeArea: any = {}
      tradeArea.postCode = event.feature.getProperty('o')
        ? event.feature.getProperty('o')
        : event.feature.getProperty('a') + ' - ' + event.feature.getProperty('d')
      tradeArea.tradeList = event.feature.getProperty('o') ? trades : []
      this.injectTooltip(event, tradeArea)
    })
    this.resultMap.data.addListener('mousemove', (e) => {
      this.moveTooltip(e)
    })
    this.resultMap.data.addListener('mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private featureClickedHandler(event: any) {
    this.hideTradeList()
    if (this.currentFeature && !event.feature.getProperty('o') && this.currentFeature !== event.feature) {
      // if we have a current feature, the event feature isn't an outcode and the current feature isn't the event feature, clear outcodes
      this.currentOutCodes.forEach((f) => this.resultMap.data.remove(f))
      // add region back
      this.resultMap.data.add(this.currentFeature)
      // update the area incase it was selected and now isn't or vice versa
      this.updateRenderedAreas([this.currentFeature])
    }
    if (!event.feature.getProperty('o')) {
      // handle area selection
      this.currentFeature = event.feature
      // clear data and load next geo data
      this.resultMap.data.remove(event.feature)
      this.resultMap.data.loadGeoJson(
        `/mapdata/${this.currentFeature.getProperty('a')}-geo.json`,
        undefined,
        (features) => {
          this.currentOutCodes = features
          this.updateRenderedAreas(features)
        }
      )
    } else {
      const areaCode = event.feature.getProperty('a')
      const outCode = event.feature.getProperty('o')
      if (!this.outCodeIsSelected(outCode)) {
        this.selectOutCodeOnFeatureClick(areaCode, outCode)
      }
    }
    // open trade selection area
    if (event.feature.getProperty('o') && this.selectedDistrict.indexOf(event.feature.getProperty('o')) !== -1) {
      this.selectedCoveragePostCode = event.feature.getProperty('a') + '-' + event.feature.getProperty('o')
      const tradeAreaElement: any = this.selectedAreaTradeList.find(
        (item) => item.postCode === this.selectedCoveragePostCode
      )
      this.selectedTradeList = tradeAreaElement !== undefined ? tradeAreaElement.tradeList : []
      this.tempSelectedTradeList = JSON.parse(JSON.stringify(tradeAreaElement.tempTradeList))
      this.selectAllTradeList = this.selectedTradeList.length === this.getTradeList.length ? true : false
      this.selectedDistrictName = event.feature.getProperty('o')
      this.removeSelectedTradeArea()
      this.showTradelist = true
    }
  }

  private updateRenderedAreas(featureSet?: any) {
    const itterateOn: any = featureSet ? featureSet : this.resultMap.data

    if (itterateOn) {
      itterateOn.forEach((feature) => {
        const outCode = feature.getProperty('o')
        if (outCode) {
          if (this.outCodeIsSelected(outCode)) {
            const areaTradeList: any = this.selectedAreaTradeList.find(
              (item) => item.postCode.split('-')[1] === feature.getProperty('o')
            )
            if (areaTradeList !== undefined && areaTradeList.tradeList.length < this.getTradeList.length) {
              this.resultMap.data.overrideStyle(feature, {
                fillColor: '#0000FF',
              })
            } else {
              this.resultMap.data.overrideStyle(feature, {
                fillColor: '#FF0000',
              })
            }
          } else {
            this.resultMap.data.revertStyle(feature)
          }
        } else {
          const areaCode = feature.getProperty('a')
          if (areaCode) {
            if (this.areaIsSelected(areaCode)) {
              if (this.areaHasFullCoverage(areaCode)) {
                this.resultMap.data.overrideStyle(feature, {
                  fillColor: '#4b0082',
                })
              } else {
                this.resultMap.data.overrideStyle(feature, {
                  fillColor: 'yellow',
                })
              }
            } else {
              this.resultMap.data.revertStyle(feature)
            }
          }
        }
      })
    }
  }

  private areaIsSelected(areaCode: string): boolean {
    return this.internalAreaStrings.indexOf(areaCode) !== -1
  }

  private areaHasFullCoverage(areaCode: string): boolean {
    const expectedOutcodes = this.tempOutcodesByArea.find((a) => a.areaCode === areaCode).outcodes as string[]
    return expectedOutcodes.reduce((p, c) => p && this.outCodeIsSelected(c), true as boolean)
  }

  private outCodeIsSelected(outCode: string): boolean {
    return this.internalOutCodeStrings.indexOf(outCode) !== -1
  }

  private selectOutCode(feature: any) {
    // is the feature already selected?
    const areaCode = feature.getProperty('a')
    const outCode = feature.getProperty('o')
    if (this.outCodeIsSelected(outCode)) {
      // a few features have multiple polygons, we need to look for any with a matching outcode property and clear that feature
      this.runFunctionOnMatchingFeatures(
        (f) => f.getProperty('o') === outCode,
        (f) => this.resultMap.data.revertStyle(f)
      )
      // remove outcode from selected areas
      const area = this.internalAreas.find((a) => a.AreaCode === areaCode)!
      area.OutCodes.splice(area.OutCodes.indexOf(outCode), 1)
      this.selectedDistrict.splice(this.selectedDistrict.indexOf(outCode), 1)
      const index = this.selectedAreaTradeList.findIndex((e) => e.postCode.split('-')[1] === outCode)
      if (index !== -1) {
        this.selectedAreaTradeList.splice(index, 1)
      }
      // if area has no outcodes left, remove area
      if (area.OutCodes.length === 0) {
        this.internalAreas.splice(this.internalAreas.indexOf(area), 1)
        this.selectedPostcode.splice(this.selectedPostcode.indexOf(area), 1)
        this.tempSelectedPostcode.splice(this.tempSelectedPostcode.indexOf(area), 1)
        this.allSelectedPostcode.splice(this.allSelectedPostcode.indexOf(area), 1)
      }
    } else {
      this.selectOutCodeOnFeatureClick(areaCode, outCode)
    }
  }

  private runFunctionOnMatchingFeatures(predicate: (feature: any) => true | false, fun: (feature: any) => void) {
    this.resultMap.data.forEach((f) => {
      if (predicate(f)) {
        fun(f)
      }
    })
  }

  private selectOutCodeOnFeatureClick(areaCode, outCode) {
    // select the outcode
    this.runFunctionOnMatchingFeatures(
      (f) => f.getProperty('o') === outCode,
      (f) => this.resultMap.data.overrideStyle(f, { fillColor: '#FF0000' })
    )
    // look for the area and add it if we don't have it
    const area = this.internalAreas.find((a) => a.AreaCode === areaCode)
    if (!area) {
      const description = this.tempOutcodesByArea.find((a) => a.areaCode === areaCode).description || ''
      this.internalAreas.push({
        AreaCode: areaCode,
        OutCodes: [outCode],
        Description: description,
        id: '',
      })
      const index = this.selectedPostcode.findIndex((i) => i === areaCode)
      if (index === -1) {
        this.selectedPostcode.push(areaCode)
        this.tempSelectedPostcode.push(areaCode)
        const pIndex = this.allSelectedPostcode.indexOf(areaCode)
        if (pIndex === -1) {
          this.allSelectedPostcode.push(areaCode)
        }
        this.getSelectedItemFirstInList()
      }
    } else {
      area.OutCodes.push(outCode)
    }
    const outCodeIndex = this.selectedDistrict.findIndex((d) => d === outCode)
    if (outCodeIndex === -1) {
      this.selectedDistrict.push(outCode)
    }
    this.selectedCoveragePostCode = areaCode + '-' + outCode
    const tradeArea: any = {}
    tradeArea.object = this.selectedCoverage
    tradeArea.postCode = this.selectedCoveragePostCode

    // When a user selects an outcode to add to their coverage area,
    // we only want to add coverage for trades that they've marked as available.
    const tradesWithAvailability = this.contractorAvailability.map((a) => a.tradeId)
    tradeArea.tradeList = this.trades.filter((t) => !t.isDeleted && tradesWithAvailability.some((a) => t.tradeId === a))
    tradeArea.tempTradeList = tradeArea.tradeList
    this.selectedAreaTradeList.push(tradeArea)
  }

  @Watch('searchInput')
  private onSearchInputChanged(newValue: any) {
    if (newValue === '') {
      this.outcodesByArea = this.tempOutcodesByArea
      this.getSelectedItemFirstInList()
    }
    this.selectedPostcode = this.allSelectedPostcode
    let searchItems: any | undefined = this.tempOutcodesByArea.filter((e) =>
      e.areaCode.toLowerCase().startsWith(newValue.toLowerCase())
    )
    if (searchItems.length === 0) {
      const postcode = this.tempOutcodesByArea.find((e) => newValue.toUpperCase().startsWith(e.areaCode))
      if (postcode !== undefined) {
        const postcodeWithDistrict = postcode.outcodes.find((d) => d.startsWith(newValue.toUpperCase()))
        if (postcodeWithDistrict !== undefined) {
          searchItems = this.tempOutcodesByArea.filter((e) => e.areaCode === postcode.areaCode)
        }
      }
    }
    this.outcodesByArea = searchItems.length > 0 ? searchItems : []
    this.selectedPostcode = this.selectedPostcode.filter(
      (postcode) => this.outcodesByArea.findIndex((a) => a.areaCode === postcode) !== -1
    )
    this.tempSelectedPostcode = this.selectedPostcode
  }

  @Watch('contractorAvailability')
  private oncontractorAvailabilityChanged(
    newValue: ContractorTradeAvailabilityModel[],
    oldValue: ContractorTradeAvailabilityModel[]
  ) {
    this.hideTradeList()
    const oldTrdeList = this.tradeList
    this.tradeList = []
    if (this.contractorAvailability && this.contractorAvailability.length > 0) {
      for (const item of this.contractorAvailability) {
        const trade = this.trades.find((a) => a.tradeId === item.tradeId)
        if (trade) {
          this.tradeList.push(trade)
        }
      }
    }
    // remove trade from tooltip if removed from Contractor Availability.
    if (this.getTradeList.length < oldTrdeList.length) {
      const removedTrades: any = []
      oldTrdeList.forEach((oldTrade) => {
        const trade: any = this.tradeList.find((e) => e.tradeId === oldTrade.tradeId)
        if (trade === undefined) {
          removedTrades.push(oldTrade)
        }
      })
      this.selectedAreaTradeList.forEach((tradeArea) => {
        const tradesToRemoveFromTradeArea: any = tradeArea.tradeList.filter((e) => removedTrades.includes(e))
        tradesToRemoveFromTradeArea.forEach((trade) => {
          tradeArea.tradeList.splice(tradeArea.tradeList.indexOf(trade), 1)
        })
      })
    }
  }

  @Watch('coverage')
  private oncontractorCoverageChanged(newValue: ContractorCoverageModel[], oldValue: ContractorCoverageModel[]) {
    if (newValue !== oldValue) {
      this.removeCurrentFeatureFromMap()
      this.hideTradeList()
      this.resultMap.setZoom(5.5)
      this.resultMap.setCenter(new (Shared.getGoogle().maps.LatLng)(this.center.Lat, this.center.Lng))
      this.tempSelectedPostcode = []
      this.allSelectedPostcode = []
      this.selectedPostcode = []
      this.selectedDistrict = []
      this.isLoading = false
      if (this.marker) {
        this.marker.setMap(null)
      }
      this.drawnPolygonList.forEach((polygon) => {
        polygon.object.setMap(null)
        google.maps.event.clearInstanceListeners(polygon)
      })
      this.drawnCircleList.forEach((circle) => {
        circle.object.setMap(null)
        google.maps.event.clearInstanceListeners(circle)
      })
      this.internalAreas = []
      this.drawnPolygonList = []
      this.drawnCircleList = []
      this.selectedAreaTradeList = []
      this.currentFeature = null
      setTimeout(() => {
        this.bindCoverageCoordinates()
      }, 0)
    }
  }

  private async created() {
    // Set tradeList.
    await this.getTrades()
    await this.fillOutcodesByArea()
  }

  private async fillOutcodesByArea() {
    const response = await fetch('/mapdata/outcodes-by-area.json')
    this.outcodesByArea = await response.json()
    this.tempOutcodesByArea = JSON.parse(JSON.stringify(this.outcodesByArea))
  }

  private async getTrades() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      await PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.setTradeList(res)
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.setTradeList(trades)
    }
  }

  private setTradeList(trades: TradeModel[]) {
    this.trades = trades
    if (this.contractorAvailability) {
      for (const item of this.contractorAvailability) {
        const trade = trades.find((a) => a.tradeId === item.tradeId)
        if (trade) {
          this.tradeList.push(trade)
        }
      }
    }
  }

  private async mounted() {
    SetThemeForJobType(this, undefined)
    await this.addMapsScript()
  }

  private async initMap() {
    const el = this.$refs.mapView as HTMLElement
    this.resultMap = new (Shared.getGoogle().maps.Map)(el, {
      center: new (Shared.getGoogle().maps.LatLng)(this.center.Lat, this.center.Lng),
      zoom: 5.5,
      minZoom: 5,
      maxZoom: 15,
      mapTypeId: Shared.getGoogle().maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      streetViewControl: false,
      clickableIcons: false,
    })
    await this.loadAreas()

    const input = document.getElementById('searchTextField')
    this.mapAutocomplete = new google.maps.places.Autocomplete(input)

    this.mapAutocomplete.bindTo('bounds', this.resultMap)
    this.mapAutocomplete.addListener('place_changed', () => {
      if (this.drawingManager != null) {
        this.drawingManager.setMap(null)
      }
      const place = this.mapAutocomplete.getPlace()
      this.autocompleteValue = place.formatted_address
      const latitude: number = place.geometry.location.lat()
      const longitude: number = place.geometry.location.lng()
      this.marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: this.resultMap,
        visible: true,
      })
      this.resultMap.setCenter(new google.maps.LatLng(latitude, longitude))
    })
    this.bounds = new google.maps.LatLngBounds()
    setTimeout(() => {
      this.bindCoverageCoordinates()
    }, 0)
    this.resultMap.setCenter(new (Shared.getGoogle().maps.LatLng)(this.center.Lat, this.center.Lng))
  }

  private bindCoverageCoordinates() {
    if (this.coverage && this.coverage.length > 0) {
      for (const coverageItem of this.coverage) {
        if (coverageItem.postcode) {
          const districts: string[] = []
          const postCode: any = this.outcodesByArea.find((e) => coverageItem.postcode === e.areaCode)
          if (postCode !== undefined) {
            if (coverageItem.district) {
              this.selectedPostcode.push(postCode.areaCode)
              coverageItem.district.forEach((d) => {
                districts.push(d.postcode)
                const tradeAreaList: any = []
                if (d.trades !== null && d.trades.length > 0) {
                  d.trades.forEach((trade: any) => {
                    const tradeItem = this.trades.find((e) => e.tradeId === trade)
                    if (tradeItem !== undefined) {
                      tradeAreaList.push(tradeItem)
                    }
                  })
                }
                const tradeArea: any = {}
                tradeArea.postCode = postCode.areaCode + '-' + d.postcode
                tradeArea.tradeList = tradeAreaList
                tradeArea.tempTradeList = tradeAreaList
                this.selectedAreaTradeList.push(tradeArea)
                this.selectedDistrict.push(d.postcode)
              })
            }
            const areaObj: any = {}
            areaObj.id = coverageItem.id
            areaObj.AreaCode = postCode.areaCode
            areaObj.OutCodes = districts
            areaObj.Description = postCode.description
            this.internalAreas.push(areaObj)
            this.tempSelectedPostcode.push(postCode.areaCode)
            const pIndex = this.allSelectedPostcode.indexOf(postCode.areaCode)
            if (pIndex === -1) {
              this.allSelectedPostcode.push(postCode.areaCode)
            }
          }
        }
        this.getSelectedItemFirstInList()
        this.allSelectedPostcode = JSON.parse(JSON.stringify(this.selectedPostcode))

        if (coverageItem.location && coverageItem.location.type === 'Polygon') {
          const arrCoords: any[] = []
          coverageItem.location.coordinates.forEach((coordinate) => {
            coordinate.forEach((point) => {
              const lat = point[0]
              const long = point[1]
              arrCoords.push(new google.maps.LatLng(lat, long))
              this.bounds.extend(new google.maps.LatLng(lat, long))
            })
          })

          const polygon = new google.maps.Polygon({
            geodesic: true,
            editable: true,
            paths: arrCoords,
            strokeColor: '#FF0000',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#FF0000',
            suppressUndo: true,
          })
          polygon.id = coverageItem.id
          polygon.setMap(this.resultMap)
          const tradeAreaList: any = []
          if (coverageItem.trades !== null && coverageItem.trades.length > 0) {
            coverageItem.trades.forEach((trade: any) => {
              const tradeItem = this.trades.find((e) => e.tradeId === trade)
              if (tradeItem !== undefined) {
                tradeAreaList.push(tradeItem)
              }
            })
          }
          if (
            tradeAreaList.length === 0 ||
            (tradeAreaList.length > 0 && tradeAreaList.length < this.getTradeList.length)
          ) {
            polygon.setOptions({
              strokeColor: '#0000FF',
              fillColor: '#0000FF',
            })
          }
          const tradeArea: any = {}
          tradeArea.object = polygon
          tradeArea.postCode = ''
          tradeArea.tradeList = tradeAreaList
          tradeArea.tempTradeList = tradeAreaList
          this.selectedAreaTradeList.push(tradeArea)
          this.bindPolygonListenerEvents(polygon)
        } else if (coverageItem.location && coverageItem.location.type === 'Point') {
          const latitude: number = coverageItem.location.coordinates[0]
          const longitude: number = coverageItem.location.coordinates[1]

          this.marker = new google.maps.Marker({
            position: new google.maps.LatLng(latitude, longitude),
            map: this.resultMap,
            visible: false,
          })
          const circleCoords: any[] = []
          const circleOptions = {
            strokeColor: '#FF0000',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#FF0000',
            map: this.resultMap,
            radius: coverageItem.radius,
          }
          const circle = new google.maps.Circle(circleOptions)
          circle.id = coverageItem.id
          circle.bindTo('center', this.marker, 'position')
          const tradeAreaList: any = []
          if (coverageItem.trades !== null && coverageItem.trades.length > 0) {
            coverageItem.trades.forEach((trade: any) => {
              const tradeItem = this.trades.find((e) => e.tradeId === trade)
              if (tradeItem !== undefined) {
                tradeAreaList.push(tradeItem)
              }
            })
          }
          if (
            tradeAreaList.length === 0 ||
            (tradeAreaList.length > 0 && tradeAreaList.length < this.getTradeList.length)
          ) {
            circle.setOptions({ strokeColor: '#0000FF', fillColor: '#0000FF' })
          }
          const tradeArea: any = {}
          tradeArea.object = circle
          tradeArea.postCode = ''
          tradeArea.tradeList = tradeAreaList
          tradeArea.tempTradeList = tradeAreaList
          this.selectedAreaTradeList.push(tradeArea)
          this.bindCircleListenerEvents(circle)
        }
        this.updateRenderedAreas()
      }
    }
  }

  private bindPolygonListenerEvents(element) {
    element.getPaths().forEach((marker, index) => {
      this.setPolygonCoordinates(marker, element.id)
      const polygonArea: any = {}
      polygonArea.object = element
      polygonArea.coordinates = this.polygonCoords
      polygonArea.id = element.id
      this.drawnPolygonList.push(polygonArea)

      this.selectedCoverage = element
      if (element.id === undefined) {
        const tradeArea: any = {}
        tradeArea.object = this.selectedCoverage
        tradeArea.tradeList = this.trades.filter((t) => !t.isDeleted)
        tradeArea.tempTradeList = tradeArea.tradeList
        this.selectedAreaTradeList.push(tradeArea)
      }
      google.maps.event.addListener(marker, 'set_at', () => {
        this.setPolygonCoordinates(marker, element.id)
        this.drawnPolygonList.forEach((polygon) => {
          if (polygon.coordinates.length === this.polygonCoords.length) {
            this.drawnPolygonList.splice(this.drawnPolygonList.indexOf(polygon), 1)
          }
        })
        const polygonAreaAfterDrag: any = {}
        polygonAreaAfterDrag.object = element
        polygonAreaAfterDrag.coordinates = this.polygonCoords
        polygonAreaAfterDrag.id = element.id
        this.drawnPolygonList.push(polygonAreaAfterDrag)
      })

      google.maps.event.addListener(marker, 'insert_at', () => {
        this.setPolygonCoordinates(marker, element.id)
        this.drawnPolygonList.forEach((polygon) => {
          if (polygon.coordinates.length === this.polygonCoords.length - 1) {
            this.drawnPolygonList.splice(this.drawnPolygonList.indexOf(polygon), 1)
          }
        })
        const polygonAreaAfterInsert: any = {}
        polygonAreaAfterInsert.object = element
        polygonAreaAfterInsert.coordinates = this.polygonCoords
        polygonAreaAfterInsert.id = element.id
        this.drawnPolygonList.push(polygonAreaAfterInsert)
      })
    })
    google.maps.event.addListener(element, 'rightclick', (e) => {
      element.getPaths().forEach((marker, index) => {
        this.setPolygonCoordinates(marker, element.id)
      })
      this.drawnPolygonList.forEach((polygon) => {
        if (
          polygon.coordinates.length === this.polygonCoords.length &&
          polygon.id === this.polygonCoords.id &&
          JSON.stringify(polygon.coordinates) === JSON.stringify(this.polygonCoords)
        ) {
          this.drawnPolygonList.splice(this.drawnPolygonList.indexOf(polygon), 1)
          element.setMap(null)
        }
      })
    })
    google.maps.event.addListener(element, 'click', (e) => {
      this.showTradelist = true
      this.removeSelectedTradeArea()
      this.selectedCoverage = element
      const tradeAreaElement: any = this.selectedAreaTradeList.find((item) => item.object === element)
      this.selectedTradeList = tradeAreaElement !== undefined ? tradeAreaElement.tradeList : []
      this.tempSelectedTradeList = JSON.parse(JSON.stringify(tradeAreaElement.tempTradeList))
      this.selectAllTradeList = this.selectedTradeList.length === this.getTradeList.length ? true : false
      element.setOptions({ strokeColor: '#1B5E20', fillColor: '#1B5E20' })
    })

    // Show Tooltip
    google.maps.event.addListener(element, 'mouseover', (e) => {
      const areaTradeList: any = this.selectedAreaTradeList.find((item) => item.object === element)
      let trades: any = []
      if (areaTradeList !== undefined) {
        areaTradeList.tradeList.forEach((trade) => {
          trades.push(trade.description)
        })
      } else {
        trades = []
      }
      const tradeArea: any = {}
      tradeArea.postCode = ''
      tradeArea.tradeList = trades
      if (trades.length > 0) {
        this.injectTooltip(e, tradeArea)
      }
    })
    google.maps.event.addListener(element, 'mousemove', (e) => {
      this.moveTooltip(e)
    })
    google.maps.event.addListener(element, 'mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private bindCircleListenerEvents(circle) {
    const circleCoords: any[] = []
    const coordinate: any = []
    coordinate[0] = this.marker.position.lat()
    coordinate[1] = this.marker.position.lng()
    coordinate.radius = circle.getRadius() / 1000
    coordinate.id = circle.id
    circleCoords.push(coordinate)

    const circleArea: any = {}
    circleArea.object = circle
    circleArea.coordinates = circleCoords
    circleArea.id = circle.id
    this.drawnCircleList.push(circleArea)

    this.selectedCoverage = circle
    if (circle.id === undefined) {
      const tradeArea: any = {}
      tradeArea.object = this.selectedCoverage
      tradeArea.tradeList = this.trades.filter((t) => !t.isDeleted)
      tradeArea.tempTradeList = tradeArea.tradeList
      this.selectedAreaTradeList.push(tradeArea)
    }

    this.mapListenerEvent = circle.addListener('rightclick', (event) => {
      const elementCircle = circle
      this.drawnCircleList.forEach((element) => {
        element.coordinates.forEach((el) => {
          if (
            el[0] === elementCircle.getCenter().lat() &&
            el[1] === elementCircle.getCenter().lng() &&
            el.radius.toString() === (elementCircle.getRadius() / 1000).toString()
          ) {
            this.drawnCircleList.splice(this.drawnCircleList.indexOf(element), 1)
            circle.setMap(null)
          }
        })
      })
    })
    this.mapListenerEvent = circle.addListener('click', (event) => {
      const elementCircle = circle
      this.showTradelist = true
      this.removeSelectedTradeArea()
      this.selectedCoverage = elementCircle
      const areaTradeList: any = this.selectedAreaTradeList.find((e) => e.object === elementCircle)
      this.selectedTradeList = areaTradeList !== undefined ? areaTradeList.tradeList : []
      this.tempSelectedTradeList = JSON.parse(JSON.stringify(areaTradeList.tempTradeList))
      this.selectAllTradeList = this.selectedTradeList.length === this.getTradeList.length ? true : false
      elementCircle.setOptions({
        strokeColor: '#1B5E20',
        fillColor: '#1B5E20',
      })
    })

    this.mapListenerEvent = circle.addListener('mouseover', (e) => {
      const elementCircle = circle
      const areaTradeList: any = this.selectedAreaTradeList.find((item) => item.object === elementCircle)
      let trades: any = []
      if (areaTradeList !== undefined) {
        areaTradeList.tradeList.forEach((trade) => {
          trades.push(trade.description)
        })
      } else {
        trades = []
      }
      const tooltipData: any = {}
      tooltipData.postCode = ''
      tooltipData.tradeList = trades
      if (trades.length > 0) {
        this.injectTooltip(e, tooltipData)
      }
    })
    this.mapListenerEvent = circle.addListener('mousemove', (e) => {
      this.moveTooltip(e)
    })
    this.mapListenerEvent = circle.addListener('mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private drawCircleOnMap() {
    this.hideTradeList()
    if (this.drawingManager != null) {
      this.drawingManager.setMap(null)
      this.drawPolygon = false
    }
    this.drawCircle = !this.drawCircle
    if (this.drawCircle) {
      this.mapListenerEvent = this.resultMap.addListener('click', (e) => {
        this.mapCircleDialog = true
        this.circleRadius = 1
        const latitude: number = e.latLng.lat()
        const longitude: number = e.latLng.lng()

        this.marker = new google.maps.Marker({
          position: new google.maps.LatLng(latitude, longitude),
          map: this.resultMap,
          visible: false,
        })
      })
    } else {
      google.maps.event.removeListener(this.mapListenerEvent)
    }
  }

  private drawCircleWithRadius() {
    this.mapCircleDialog = false
    const circleOptions = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: '#FF0000',
      map: this.resultMap,
      radius: this.circleRadius * 1000, // in meters
    }
    const circle = new google.maps.Circle(circleOptions)
    circle.bindTo('center', this.marker, 'position')
    google.maps.event.removeListener(this.mapListenerEvent)
    this.bindCircleListenerEvents(circle)
    this.drawCircle = false
  }

  private setPolygonCoordinates(marker: any, id: string) {
    this.polygonCoords = []
    this.polygonCoords.id = id
    marker.getArray().forEach((el) => {
      const coordinate: any = []
      coordinate[0] = el.lat()
      coordinate[1] = el.lng()
      this.polygonCoords.push(coordinate)
    })
    const firstCoordinate = this.polygonCoords[0]
    const lastCoordinate = this.polygonCoords[this.polygonCoords.length - 1]
    if (this.polygonCoords.id === undefined || firstCoordinate[0] !== lastCoordinate[0]) {
      const coordinate: any = []
      coordinate[0] = this.polygonCoords[0][0]
      coordinate[1] = this.polygonCoords[0][1]
      this.polygonCoords.push(coordinate)
    }

    // determine if a list of polygon points are in clockwise order.
    const end = this.polygonCoords.length - 1
    let area =
      this.polygonCoords[end][0] * this.polygonCoords[0][1] - this.polygonCoords[0][0] * this.polygonCoords[end][1]
    for (let i = 0; i < end; ++i) {
      const n = i + 1
      area += this.polygonCoords[i][0] * this.polygonCoords[n][1] - this.polygonCoords[n][0] * this.polygonCoords[i][1]
    }
    if (area < 0) {
      this.polygonCoords = [...this.polygonCoords].reverse()
    }
  }

  private drawPolygonOnMap() {
    this.hideTradeList()
    if (this.drawCircle) {
      this.drawCircle = false
    }
    this.drawPolygon = !this.drawPolygon
    if (this.drawPolygon) {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon'],
        },
        polygonOptions: {
          strokeColor: '#FF0000',
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: '#FF0000',
          editable: true,
          suppressUndo: true,
        },
      })
      this.drawingManager.setMap(this.resultMap)
      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
        if (event.type === 'polygon') {
          if (this.drawingManager != null) {
            this.drawingManager.setMap(null)
          }
          const element = event.overlay
          this.bindPolygonListenerEvents(element)
          this.drawPolygon = false
        }
      })
    } else {
      if (this.drawingManager != null) {
        this.drawingManager.setMap(null)
      }
    }
  }

  private getSelectedItemFirstInList() {
    this.outcodesByArea = Shared.getSelectedItemFirstInList(this.outcodesByArea, this.selectedPostcode, 'areaCode')
    if (!this.searchInput) {
      this.tempOutcodesByArea = JSON.parse(JSON.stringify(this.outcodesByArea))
    }
  }

  private onDistrictChange(districtList: any, district: string, postcode: string) {
    this.hideTradeList()
    const index = districtList.findIndex((e) => e === district)
    const districtsOfPostcode: any = this.tempOutcodesByArea.find((e) => e.areaCode === postcode)
    // if any of the district is selected then select relevant postcode.
    const tempDistrictsArray: any = []
    if (districtsOfPostcode) {
      districtsOfPostcode.outcodes.forEach((d) => {
        const tempIndex = districtList.indexOf(d)
        if (tempIndex !== -1) {
          tempDistrictsArray.push(tempIndex)
        }
      })
      const postCodeIndex = this.selectedPostcode.indexOf(postcode)
      if (tempDistrictsArray.length >= 1) {
        if (postCodeIndex === -1) {
          this.selectedPostcode.push(postcode)
          this.getSelectedItemFirstInList()
        }
        setTimeout(() => {
          const tempPostCodeIndex = this.tempSelectedPostcode.indexOf(postcode)
          if (tempPostCodeIndex === -1) {
            this.tempSelectedPostcode.push(postcode)
          }
          const pIndex = this.allSelectedPostcode.indexOf(postcode)
          if (pIndex === -1) {
            this.allSelectedPostcode.push(postcode)
          }
        }, 0)
      } else {
        if (postCodeIndex !== -1) {
          this.selectedPostcode.splice(postCodeIndex, 1)
          this.tempSelectedPostcode.splice(this.tempSelectedPostcode.indexOf(postcode), 1)
          this.allSelectedPostcode.splice(this.allSelectedPostcode.indexOf(postcode), 1)
        }
      }
    }
    if (index === -1) {
      const area = this.internalAreas.find((a) => a.AreaCode === postcode)
      if (area) {
        const outCodeIndex = area.OutCodes.findIndex((o) => o === district)
        if (outCodeIndex !== -1) {
          this.runFunctionOnMatchingFeatures(
            (f) => f.getProperty('o') === district,
            (f) => this.resultMap.data.revertStyle(f)
          )
          area.OutCodes.splice(outCodeIndex, 1)
          const areaTradeIndex = this.selectedAreaTradeList.findIndex((e) => e.postCode.split('-')[1] === district)
          if (areaTradeIndex !== -1) {
            this.selectedAreaTradeList.splice(areaTradeIndex, 1)
          }
        }
      }
    } else {
      let currentOutCode: any = null
      if (this.currentOutCodes && this.currentOutCodes.length > 0) {
        currentOutCode = this.currentOutCodes.find((f) => f.getProperty('o') === district)
      }
      if (currentOutCode) {
        this.currentFeature = currentOutCode
        this.resultMap.data.add(this.currentFeature)
        this.updateRenderedAreas([this.currentFeature])
        this.selectOutCode(currentOutCode)
      } else {
        this.removeCurrentFeatureFromMap()
        this.resultMap.data.forEach((feature) => {
          if (feature.getProperty('a') === postcode) {
            this.currentFeature = feature
            this.resultMap.data.add(this.currentFeature)
            this.updateRenderedAreas([this.currentFeature])
          }
        })
        this.resultMap.data.loadGeoJson(`/mapdata/${postcode}-geo.json`, undefined, (features) => {
          this.currentOutCodes = features
          const outcode = features.find((f) => f.getProperty('o') === district)
          if (outcode) {
            this.selectOutCode(outcode)
          }
        })
      }
    }
  }

  private removeCurrentFeatureFromMap() {
    if (this.currentFeature && this.currentOutCodes && this.currentOutCodes.length > 0) {
      this.currentOutCodes.forEach((f) => this.resultMap.data.remove(f))
      this.resultMap.data.add(this.currentFeature)
      this.updateRenderedAreas()
    }
  }

  private getTradeCount(district: string) {
    const area: any = this.selectedAreaTradeList.find((e) => e.postCode.split('-')[1] === district)
    if (area !== undefined) {
      return area.tradeList.length
    }
    return this.tradeList.length
  }

  private onTradeCountClick(district: string, postcode: string) {
    const area = this.internalAreas.find((e) => e.AreaCode === postcode)
    if (area !== undefined) {
      const outCode: any = area.OutCodes.find((e) => e === district)
      if (outCode !== undefined) {
        this.showTradelist = true
        this.removeSelectedTradeArea()
        this.selectedCoveragePostCode = postcode + '-' + district
        const tradeAreaElement: any = this.selectedAreaTradeList.find(
          (item) => item.postCode === this.selectedCoveragePostCode
        )
        this.selectedTradeList = tradeAreaElement !== undefined ? tradeAreaElement.tradeList : []
        this.tempSelectedTradeList = JSON.parse(JSON.stringify(tradeAreaElement.tempTradeList))
        this.selectAllTradeList = this.selectedTradeList.length === this.getTradeList.length ? true : false
        this.selectedDistrictName = district
        this.resultMap.data.overrideStyle(district, {
          strokeColor: '#1B5E20',
          fillColor: '#1B5E20',
        })
      }
    }
  }

  private onPostalCodeChange() {
    this.hideTradeList()
    this.removeCurrentFeatureFromMap()
    if (this.tempSelectedPostcode.length < this.selectedPostcode.length) {
      const AreaCode = this.selectedPostcode[this.selectedPostcode.length - 1]
      this.resultMap.data.forEach((feature) => {
        if (feature.getProperty('a') === this.selectedPostcode[this.selectedPostcode.length - 1]) {
          this.currentFeature = feature
          this.resultMap.data.remove(feature)
          this.updateRenderedAreas([this.currentFeature])
        }
      })
      if (AreaCode) {
        // handle area selection
        this.resultMap.data.loadGeoJson(`/mapdata/${AreaCode}-geo.json`, undefined, (features) => {
          this.currentOutCodes = features
          features.forEach((feature) => {
            this.selectOutCode(feature)
          })
        })
      }
      this.tempSelectedPostcode.push(this.selectedPostcode[this.selectedPostcode.length - 1])
      const pIndex = this.allSelectedPostcode.indexOf(this.selectedPostcode[this.selectedPostcode.length - 1])
      if (pIndex === -1) {
        this.allSelectedPostcode.push(this.selectedPostcode[this.selectedPostcode.length - 1])
      }
    } else {
      let postCodeToRemove: any = []
      if (this.selectedPostcode.length === 0) {
        postCodeToRemove = this.tempSelectedPostcode.splice(0, 1)
      } else {
        for (const city of this.tempSelectedPostcode) {
          let flag = 0
          for (const selectedCity of this.selectedPostcode) {
            if (city === selectedCity) {
              flag = 1
              break
            }
          }
          if (flag === 0) {
            postCodeToRemove = this.tempSelectedPostcode.splice(this.tempSelectedPostcode.indexOf(city), 1)
            break
          }
        }
      }
      this.allSelectedPostcode.splice(this.allSelectedPostcode.indexOf(postCodeToRemove[0]), 1)
      const area = this.internalAreas.find((a) => a.AreaCode === postCodeToRemove[0])
      if (area) {
        const tempOutCodes = JSON.parse(JSON.stringify(area.OutCodes))
        tempOutCodes.forEach((element, i) => {
          this.runFunctionOnMatchingFeatures(
            (f) => f.getProperty('o') === element,
            (f) => this.resultMap.data.revertStyle(f)
          )
          area.OutCodes.splice(area.OutCodes.indexOf(element), 1)
          this.selectedDistrict.splice(this.selectedDistrict.indexOf(element), 1)
          const areaTradeIndex = this.selectedAreaTradeList.findIndex((e) => e.postCode.split('-')[1] === element)
          if (areaTradeIndex !== -1) {
            this.selectedAreaTradeList.splice(areaTradeIndex, 1)
          }
        })
        // if area has no outcodes left, remove area
        if (area.OutCodes.length === 0) {
          this.internalAreas.splice(this.internalAreas.indexOf(area), 1)
        }
      }
      this.updateRenderedAreas()
    }
    this.getSelectedItemFirstInList()
  }

  private onTradeSelectionChange(event: any, trade: any) {
    this.selectAllTradeList = this.selectedTradeList.length === this.getTradeList.length ? true : false
    let tradeArea: any = []
    if (this.selectedCoveragePostCode !== null && this.selectedCoveragePostCode !== '') {
      tradeArea = this.selectedAreaTradeList.find((e) => e.postCode === this.selectedCoveragePostCode)
    } else {
      tradeArea = this.selectedAreaTradeList.find((e) => e.object === this.selectedCoverage)
    }
    if (tradeArea !== undefined) {
      tradeArea.tradeList = event
    }
  }

  private onSelectAllTradeList() {
    let tradeArea: any = []
    if (this.selectedCoveragePostCode !== null && this.selectedCoveragePostCode !== '') {
      tradeArea = this.selectedAreaTradeList.find((e) => e.postCode === this.selectedCoveragePostCode)
    } else {
      tradeArea = this.selectedAreaTradeList.find((e) => e.object === this.selectedCoverage)
    }
    if (tradeArea !== undefined) {
      this.selectedTradeList = this.selectAllTradeList ? this.tradeList : []
      tradeArea.tradeList = this.selectedTradeList
    }
  }

  private setCoverageColor(areaTradeList, element) {
    if (areaTradeList !== undefined && areaTradeList.tradeList.length < this.getTradeList.length) {
      element.setOptions({ strokeColor: '#0000FF', fillColor: '#0000FF' })
    }
    if (element.strokeColor === '#1B5E20' && element.fillColor === '#1B5E20') {
      element.setOptions({ strokeColor: '#FF0000', fillColor: '#FF0000' })
    }
  }

  private removeSelectedTradeArea() {
    if (this.selectedCoveragePostCode === null) {
      this.resultMap.data.forEach((d) => {
        if (this.selectedDistrict.indexOf(d.getProperty('o')) !== -1) {
          const areaTradeList: any = this.selectedAreaTradeList.find(
            (item) => item.postCode.split('-')[1] === d.getProperty('o')
          )
          if (areaTradeList !== undefined && areaTradeList.tradeList.length < this.getTradeList.length) {
            this.resultMap.data.overrideStyle(d, { fillColor: '#0000FF' })
          } else {
            this.resultMap.data.overrideStyle(d, { fillColor: '#FF0000' })
          }
        }
      })
    } else if (this.selectedCoveragePostCode !== null) {
      const areaCode = this.selectedCoveragePostCode.split('-')[0]
      const outCode = this.selectedCoveragePostCode.split('-')[1]
      this.resultMap.data.forEach((d) => {
        if (this.selectedDistrict.indexOf(d.getProperty('o')) !== -1) {
          if (d.getProperty('o') !== outCode) {
            const areaTradeList: any = this.selectedAreaTradeList.find(
              (item) => item.postCode.split('-')[1] === d.getProperty('o')
            )
            if (areaTradeList !== undefined && areaTradeList.tradeList.length < this.getTradeList.length) {
              this.resultMap.data.overrideStyle(d, { fillColor: '#0000FF' })
            } else {
              this.resultMap.data.overrideStyle(d, { fillColor: '#FF0000' })
            }
          } else {
            this.resultMap.data.overrideStyle(d, { fillColor: '#01370f' })
          }
        }
      })
    } else {
      let element: any = this.drawnPolygonList.find((e) => e.object === this.selectedCoverage)
      if (element === undefined) {
        element = this.drawnCircleList.find((e) => e.object === this.selectedCoverage)
      }
      if (element) {
        const areaTradeList: any = this.selectedAreaTradeList.find((item) => item.object === element.object)
        this.setCoverageColor(areaTradeList, element.object)
      }
    }
  }

  private hideTradeList() {
    this.showTradelist = false
    this.selectedCoverage = null
    this.selectedCoveragePostCode = null
    this.selectedDistrictName = ''
    this.removeSelectedTradeArea()
  }

  private toggleFullScreenView() {
    this.showFullScreenMap = !this.showFullScreenMap
    const mapView: any = this.$refs.mapView
    if (mapView) {
      if (this.showFullScreenMap) {
        mapView.classList.add('fullScreen')
        this.toggleFullScreenIcon('hide')
        setTimeout(() => {
          ;(this.$refs.fullScreenIcon as any).$el.classList.remove('hide')
        }, 800)
      } else {
        mapView.classList.remove('fullScreen')
        setTimeout(() => {
          this.toggleFullScreenIcon('show')
        }, 800)
      }

      this.$nextTick()
    }
  }

  private injectTooltip(event, data) {
    if (!this.tooltipObject && event) {
      // create the tooltip object
      this.tooltipObject = document.createElement('div')
      this.tooltipObject.style.width = 'auto'
      this.tooltipObject.style.maxWidth = '200px'
      this.tooltipObject.style.background = 'white'
      this.tooltipObject.style.borderRadius = '5px'
      this.tooltipObject.style.padding = '5px'
      this.tooltipObject.style.display = 'inline-block'
      this.tooltipObject.style.lineHeight = '1.5'
      this.tooltipObject.style.fontFamily = 'Arial,Helvetica'
      if (data.postCode !== null && data.postCode !== '') {
        this.tooltipObject.innerHTML =
          "<b style='color:#4c4c4c'>" + data.postCode + '</b>' + '<br />' + data.tradeList.join(', ')
      } else {
        this.tooltipObject.innerHTML = data.tradeList.join(', ')
      }
      const eventPropNames: any = Object.keys(event)
      if (!this.coordPropName) {
        // discover the name of the prop with MouseEvent
        for (const i of eventPropNames) {
          const name = i
          if (event[name] instanceof MouseEvent) {
            this.coordPropName = name
            break
          }
        }
      }
      if (this.coordPropName !== null) {
        // position it
        this.tooltipObject.style.position = 'fixed'
        if (event[this.coordPropName] !== undefined) {
          this.tooltipObject.style.top = event[this.coordPropName].clientY + window.scrollY + this.offset.y + 'px'
          this.tooltipObject.style.left = event[this.coordPropName].clientX + window.scrollX + this.offset.x + 'px'
        }
        // add it to the body
        document.body.appendChild(this.tooltipObject)
      }
    }
  }

  private moveTooltip(event) {
    if (this.tooltipObject && event && this.coordPropName) {
      // position it
      if (event[this.coordPropName] !== undefined) {
        this.tooltipObject.style.top = event[this.coordPropName].clientY + window.scrollY + this.offset.y + 'px'
        this.tooltipObject.style.left = event[this.coordPropName].clientX + window.scrollX + this.offset.x + 'px'
      }
    }
  }

  private deleteTooltip(event) {
    if (this.tooltipObject) {
      // delete the tooltip if it exists in the DOM
      document.body.removeChild(this.tooltipObject)
      this.tooltipObject = null
    }
  }

  private toggleFullScreenIcon(displayStyle: string) {
    const fullScreenIcons: any = document.getElementsByClassName('fullScreenIcon')
    if (fullScreenIcons && fullScreenIcons.length > 0) {
      for (const item of fullScreenIcons) {
        if (displayStyle === 'show') {
          item.classList.remove('hide')
        } else {
          item.classList.add('hide')
        }
      }
    }
  }

  private save() {
    this.hideTradeList()
    const coverageArea: ContractorCoverageModel[] = []
    // Add polygon to CovergeArea.
    this.drawnPolygonList.forEach((polygon) => {
      this.location = new MapLocationModel()
      this.location.type = 'Polygon'
      const coverage: ContractorCoverageModel = new ContractorCoverageModel()
      const polygonCoordinates = polygon.coordinates
      delete polygonCoordinates.id
      this.location.coordinates.push(polygonCoordinates)
      coverage.id = polygon.id === undefined ? null : polygon.id
      coverage.location = this.location
      coverage.contractorId = this.contractorId
      const trades: any = []
      const selectedTradeList = this.selectedAreaTradeList.find((e) => e.object === polygon.object)
      if (selectedTradeList !== undefined) {
        selectedTradeList.tradeList.forEach((e) => {
          trades.push(e.tradeId)
        })
      }
      coverage.trades = trades
      coverageArea.push(coverage)
    })
    // Add circle to CovergeArea.
    this.drawnCircleList.forEach((circle) => {
      this.location = new MapLocationModel()
      this.location.type = 'Point'
      const coverage: ContractorCoverageModel = new ContractorCoverageModel()
      circle.coordinates.forEach((element) => {
        const coordinate = []
        coordinate[0] = element[0] as never
        coordinate[1] = element[1] as never
        this.location.coordinates = coordinate
        coverage.radius = element.radius * 1000
      })
      coverage.id = circle.id === undefined ? null : circle.id
      coverage.location = this.location
      coverage.contractorId = this.contractorId
      const trades: any = []
      const selectedTradeList = this.selectedAreaTradeList.find((e) => e.object === circle.object)
      if (selectedTradeList !== undefined) {
        selectedTradeList.tradeList.forEach((e) => {
          trades.push(e.tradeId)
        })
      }
      coverage.trades = trades
      coverageArea.push(coverage)
    })

    // Add postcode to CovergeArea.
    this.internalAreas.forEach((element) => {
      const coverage: ContractorCoverageModel = new ContractorCoverageModel()
      coverage.contractorId = this.contractorId
      coverage.postcode = element.AreaCode
      coverage.id = element.id ? element.id : ''
      const coverageDistrict: ContractorCoverageDistrictModel[] = []
      if (element.OutCodes.length > 0) {
        element.OutCodes.forEach((polygon) => {
          const trades: any = []
          const district: ContractorCoverageDistrictModel = new ContractorCoverageDistrictModel()
          district.postcode = polygon
          const selectedTradeList = this.selectedAreaTradeList.find((e) => e.postCode.split('-')[1] === polygon)
          if (selectedTradeList !== undefined) {
            selectedTradeList.tradeList.forEach((e) => {
              trades.push(e.tradeId)
            })
          }
          district.trades = trades
          coverageDistrict.push(district)
        })
        coverage.district = coverageDistrict
        coverageArea.push(coverage)
      }
    })
    const contractorDetail: ContractorModel = new ContractorModel()
    contractorDetail.id = this.contractorId
    contractorDetail.coverage = [...coverageArea]
    delete contractorDetail.contractorAvailability
    this.isLoading = true
    ContractorController.SaveContractorCoverage(contractorDetail)
      .then((res: ContractorModel) => {
        if (res) {
          //  assign id to newly added object, once saved.
          const contractorCoverage: ContractorCoverageModel[] = (contractorDetail.coverage || []).filter(
            (coverage) => !coverage.id
          )
          contractorCoverage.forEach((coverage) => {
            if (coverage.location === undefined && coverage.postcode !== undefined) {
              const postcodeElement: any = (res.coverage || []).find((e) => e.postcode === coverage.postcode)
              const polygonElement = this.internalAreas.findIndex((polygon) => polygon.AreaCode === coverage.postcode)
              if (polygonElement !== -1) {
                this.internalAreas[polygonElement].id = postcodeElement.id
              }
            } else if (
              coverage.location !== null &&
              coverage.location.type === 'Polygon' &&
              coverage.postcode === undefined
            ) {
              const polygonElement: any = (res.coverage || []).find(
                (e) =>
                  e.location !== null &&
                  e.location.type === coverage.location.type &&
                  JSON.stringify(e.location.coordinates[0]) === JSON.stringify(coverage.location.coordinates[0])
              )
              const polygon = this.drawnPolygonList.find(
                (drawnPolygon) =>
                  JSON.stringify(drawnPolygon.coordinates) === JSON.stringify(coverage.location.coordinates[0])
              )
              polygon.object.id = polygonElement.id
            } else if (
              coverage.location !== null &&
              coverage.location.type === 'Point' &&
              coverage.postcode === undefined
            ) {
              const circleElement: any = (res.coverage || []).find(
                (e) =>
                  e.location !== null &&
                  e.location.type === coverage.location.type &&
                  JSON.stringify(e.location.coordinates) === JSON.stringify(coverage.location.coordinates)
              )
              const circle = this.drawnCircleList.find(
                (drawnCircle) =>
                  JSON.stringify(drawnCircle.coordinates[0]) === JSON.stringify(coverage.location.coordinates)
              )
              circle.id = circleElement.id
            }
          })
          this.isLoading = false
          this.saveSnackbar = true
        } else {
          this.isLoading = false
        }
        this.$emit('coverageChange', res.coverage)
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving coverage area, please try again', true)
        this.isLoading = false
      })
  }

  private get getTradeList() {
    this.tradeList = this.trades.filter(
      (e) =>
        !e.isDeleted || (e.isDeleted && this.tempSelectedTradeList.findIndex((c) => c.tradeId === e.tradeId) !== -1)
    )
    return this.tradeList
  }
}
</script>

<style scoped>
.area-list {
  padding-left: 0px;
  list-style: none;
}
.area-list li {
  margin-bottom: 5px;
}
.map-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.area-list-content {
  min-height: 690px;
  max-height: 690px;
  background-color: #f9f9f9;
  width: auto !important;
  padding-top: 3px;
  margin-top: 20px;
}
.covrage-sidebar {
  background-color: #f9f9f9;
  z-index: 1;
  min-width: 345px !important;
}
.covrage-mapsection {
  width: 100%;
}
.record-not-found {
  vertical-align: top;
  padding-left: 55px;
}
.cta-content >>> .input-group__selections {
  max-height: 30px;
  opacity: 0;
}
.inblock {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 100%;
}
.cta-content {
  max-height: 400px;
}
.map,
.map-card {
  height: 100%;
  width: 100%;
  border: 0px;
}
.map-card {
  overflow: hidden;
}
.show-map-cn .map-btn-cn {
  display: none;
}
.map-btn-cn {
  display: flex;
  height: 100%;
}
.map-btn-cn .show-mp-btn {
  margin: auto;
}
.show-map-cn .map-content {
  opacity: 1;
  visibility: visible;
  position: relative;
}
.contractor-avial >>> .contractor-avial-content {
  width: 80%;
  margin: auto;
}
#customMap {
  height: 100%;
  min-height: calc(100vh - 245px);
  max-height: calc(100vh - 245px);
  width: 100%;
  max-width: 100%;
  position: relative;
}
.map-area {
  position: relative;
}
.map-toolbar {
  position: absolute;
  left: 10px;
  right: 60px;
  top: 10px;
  z-index: 1;
  max-height: 50px;
  background-color: #fff;
  max-width: 560px;
}
.search-lc {
  padding-right: 100px !important;
}
.map-toolbar >>> .v-input {
  padding-top: 0px;
  margin-top: 0;
  padding-bottom: 10px;
}
.fl-polygon-btn {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
}
.fl-polygon-btn:before {
  content: '';
  top: 0px;
  left: 0px;
  position: absolute;
  width: 1px;
  background-color: #ddd;
  height: 28px;
}
.fl-polygon-btn img {
  max-width: 100%;
  width: 20px;
}
.fl-polygon-btn >>> .v-btn {
  padding: 0px !important;
  width: 30px;
  height: 30px;
  margin: 0px;
  margin-left: 10px;
  min-width: 30px;
}
.fl-polygon-btn >>> .v-btn__content {
  padding: 0px !important;
  width: 30px !important;
}
.Activeoption {
  background-color: #eee !important;
}
.availability-job {
  width: 300px;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: -300px;
  background-color: #fff;
  height: 740px;
  overflow: hidden;
  transition: all 0.3s linear;
}
.map-section-content {
  position: relative;
  transition: all 0.3s linear;
  width: 100%;
}
.show-availability-job .map-section-content {
  width: calc(100% - 300px);
}
.show-availability-job .availability-job {
  right: 0px;
}
.availability-job .job-availability-content {
  position: relative;
  max-height: 700px;
  height: calc(100vh - 295px) !important;
  padding-top: 5px;
}
.availability-job .job-availability-content .que-checkbox {
  margin-top: 4px;
}
.back-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 99;
}
.back-btn:hover {
  position: absolute !important;
}
.area-list-content >>> .v-expansion-panel > div.collapse-list {
  width: 100%;
  position: relative;
}
.area-title {
  position: absolute;
  top: 5px;
  left: 32px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
}
.area-list-content >>> .v-expansion-panel__header {
  padding: 0px;
  min-height: 10px;
}
.area-list-content >>> .v-expansion-panel__container {
  background-color: transparent !important;
  border-top: 0px !important;
  min-height: 32px;
}
.area-list-content .tradecount {
  position: absolute;
  top: 2px;
  right: 0px;
  z-index: 4;
  font-weight: 600;
}
.area-list-content >>> .v-expansion-panel__container .v-expansion-panel__body {
  margin-top: 5px;
  margin-bottom: 10px;
}
.area-list-content >>> .v-expansion-panel__container .v-expansion-panel__body .que-checkbox .v-input__control {
  min-height: 30px;
}
.district-list > div {
  position: relative;
  border-bottom: 1px solid #f1f1f1;
}
.district-list > div:last-child {
  border-bottom: 0px;
}
.district-list > div .tradecount {
  right: 5px;
  font-weight: normal;
}
.district-list {
  width: 84% !important;
}
.collapse-list >>> .v-expansion-panel__body .v-card__text {
  padding-bottom: 1px !important;
  padding-top: 1px !important;
  border-bottom: 1px solid #f3f3f3;
}
.collapse-list .area-checkbox {
  position: absolute;
  left: 0px;
  right: 30px;
  z-index: 4;
  top: 0px;
}
.collapse-list .que-checkbox >>> .v-input__control {
  overflow: hidden;
}
.collapse-list .que-checkbox >>> .v-input__control label {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.area-list-content >>> .que-checkbox.v-input--checkbox {
  width: calc(100% - 75px);
  padding-top: 0;
  margin-top: 0;
}
.trade-area-name {
  display: inline-block;
  white-space: nowrap;
  width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.check-outcode {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0px;
  z-index: 1;
  width: 200px;
}
.checkoutput-row {
  position: relative;
}
.check-outcode >>> .v-input--selection-controls.v-input .v-label {
  display: block !important;
  white-space: nowrap;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1199px) {
  .map-toolbar {
    right: 50px;
  }
  .contractor-avial >>> .contractor-avial-content {
    width: 90%;
    margin: auto;
  }
  .availability-job {
    width: 220px;
    right: -220px;
  }
  .show-availability-job .map-section-content {
    width: calc(100% - 220px);
  }
  .trade-area-name {
    width: 120px;
  }
}
</style>
