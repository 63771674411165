export default class ContractorRateModel {
  public tradeId: number
  public insideM25: boolean
  public calloutDayPrice: number
  public calloutNightPrice: number
  public calloutWeekendPrice: number
  public calloutHolidayPrice: number
  public calloutChristmasHolidayPrice: number
  public followOnDayPrice: number
  public followOnNightPrice: number
  public followOnWeekendPrice: number
  public followOnHolidayPrice: number
  public followOnChristmasHolidayPrice: number
  public hasFixedTradeRate: boolean
  public fixedRateAmount: number
  public id: number // required for front-end to manage deleted trade

  public constructor() {
    this.calloutDayPrice = 0
    this.calloutNightPrice = 0
    this.calloutWeekendPrice = 0
    this.calloutHolidayPrice = 0
    this.calloutChristmasHolidayPrice = 0
    this.followOnDayPrice = 0
    this.followOnNightPrice = 0
    this.followOnWeekendPrice = 0
    this.followOnHolidayPrice = 0
    this.followOnChristmasHolidayPrice = 0
    this.fixedRateAmount = 0
    this.hasFixedTradeRate = false
  }
}
