/* tslint:disable:variable-name */
export default class OneClickPolicyHolderModel {
  public Policy_Number: string
  public Address_Line1: string
  public Address_Line2: string
  public Address_Line3: string
  public Address_Line4: string
  public Postcode: string
  public Policy_holder_Title: string
  public Policy_holder_First_Name: string
  public Policy_holder_Last_Name: string
  public Policy_holder2_Title: string
  public Policy_holder2_First_Name: string
  public Policy_holder2_Last_Name: string
  public Mobile_number: string
  public routeToMarket: string
  public policyScheduleId: number

  public constructor(callTask: any) {
    this.Policy_Number = callTask.Policy_Number
    this.Address_Line1 = callTask.Address_Line1
    this.Address_Line2 = callTask.Address_Line2
    this.Address_Line3 = callTask.Address_Line3
    this.Address_Line4 = callTask.Address_Line4
    this.Postcode = callTask.Postcode
    this.Policy_holder_Title = callTask.Policy_holder_Title
    this.Policy_holder_First_Name = callTask.Policy_holder_First_Name
    this.Policy_holder_Last_Name = callTask.Policy_holder_Last_Name
    this.Policy_holder2_Title = callTask.Policy_holder2_Title
    this.Policy_holder2_First_Name = callTask.Policy_holder2_First_Name
    this.Policy_holder2_Last_Name = callTask.Policy_holder2_Last_Name
    this.routeToMarket = callTask.routeToMarket
    this.Mobile_number = callTask.from
    this.policyScheduleId = callTask.policyScheduleId
  }
}
