<template>
  <div>
    <div class="mb-2"><h4>Water Mains Test Details</h4></div>
    <div v-if="waterDetails.length > 0">
      <div v-for="(waterTest, index) in waterDetails" :key="index">
        <div v-if="waterTest.testNumber" class="mb-1">
          <label>Test Number: </label>
          <span>{{ waterTest.testNumber }}</span>
        </div>
        <div v-if="waterTest.pressureAtZeroMinutes" class="mb-1">
          <label>Pressure At Zero Minutes: </label>
          <span>{{ waterTest.pressureAtZeroMinutes }}</span>
        </div>
        <div v-if="waterTest.pressureAtFiveMinutes" class="mb-1">
          <label>Pressure At Five Minutes: </label>
          <span>{{ waterTest.pressureAtFiveMinutes }}</span>
        </div>
        <div v-if="waterTest.pressureAtTenMinutes" class="mb-1">
          <label>Pressure At Ten Minutes: </label>
          <span>{{ waterTest.pressureAtTenMinutes }}</span>
        </div>
        <div v-if="waterTest.startTime" class="mb-1">
          <label>Start Time: </label>
          <span>{{ getFormattedDate(waterTest.startTime) }}</span>
        </div>
        <div v-if="waterTest.testNote" class="mb-1">
          <label>Test Note: </label>
          <span>{{ waterTest.testNote }}</span>
        </div>
        <div v-if="item && item.status" class="mb-1">
          <label>Status: </label>
          <span>{{ item.status }}</span>
        </div>
        <v-divider v-if="index !== waterDetails.length - 1" class="mb-1"></v-divider>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import SaveWaterMainTestRecord from '@/models/siteInvestigation/requests/SaveWaterMainTestRecord'
import WaterTestModel from '@/models/siteInvestigation/WaterTestModel'
import { RequestType } from '@/common/enums'

@Component
export default class AddUpdateWaterMainsTestLog extends Vue {
  @Prop() private requestBody: any
  @Prop() private requestType: string

  private get item(): SaveWaterMainTestRecord {
    return Object.assign(new SaveWaterMainTestRecord(), this.requestBody)
  }

  private get waterDetails(): WaterTestModel[] {
    const isSaveWaterMainsTest = this.requestType !== RequestType[RequestType.SaveWaterMainsTest]
    return isSaveWaterMainsTest ? [this.item.waterTest] : this.item.waterTests
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>

<style scoped></style>
