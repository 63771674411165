import { render, staticRenderFns } from "./AddEmergencyAnswerLog.vue?vue&type=template&id=1c38a065&scoped=true"
import script from "./AddEmergencyAnswerLog.vue?vue&type=script&lang=ts"
export * from "./AddEmergencyAnswerLog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c38a065",
  null
  
)

export default component.exports