import { DocumentTemplateType } from '@/common/enums'
export default class SendClientEmail {
  public jobId: string
  public documentTemplateType: DocumentTemplateType

  public accessibleRecords: {}

  // to be replaced
  public emergencyDetailId: string
  public helplinePolicyId: string
}
