<template>
  <div class="dashboard">
    <v-container fluid pr-2 pl-0 pt-1>
      <v-layout wrap>
        <v-flex>
          <CTPTable table-name="ctpDashboard"></CTPTable>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import CTPTable from '@/components/tables/CTPTable.vue'
import Shared from '../common/shared'

@Component({
  components: { CTPTable },
})
export default class CTPDashboard extends Vue {}
</script>

<style scoped>
.CTP-count-tile >>> .v-card__title h3 {
  width: 100%;
}
.CTP-count-tile >>> .flex {
  width: 20%;
}
</style>
