<template>
  <div>
    <div class="mb-2">
      <h4>Engineer {{ item.visitStartedAt ? 'OnSite' : 'Offsite' }} Time Update</h4>
    </div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency Type and Detail: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="item.visitStartedAt" class="mb-1">
      <label>Visit Started At: </label>
      <span>{{ getFormattedDate(item.visitStartedAt) }}</span>
    </div>
    <div v-if="item.visitCompletedAt" class="mb-1">
      <label>Visit Completed At: </label>
      <span>{{ getFormattedDate(item.visitCompletedAt) }}</span>
    </div>
    <div v-if="item.visitNote" class="mb-1">
      <label>Site Notes: </label>
      <span>{{ item.visitNote }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateJobOffsiteTime from '../../models/requests/UpdateJobOffsiteTimeModel'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class UpdateJobOffSiteTimeLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateJobOffsiteTime {
    return Object.assign(new UpdateJobOffsiteTime(), this.requestBody)
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get emergencyName(): string {
    if (this.requestedJob) {
      const visitDetail = this.requestedJob.engineerVisitDetails.find((x) => x.id === this.item.id)
      return visitDetail ? Shared.getEmergencyNameFromTypeId(visitDetail.forEmergencyTypeId) : ''
    }
    return ''
  }
}
</script>
