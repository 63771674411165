import TemplateCookieSettings from './template-cookie-settings'

export default class TemplateCookieRecord {
  public cookieId = ''
  public settings: TemplateCookieSettings[] = []

  constructor(cookieId = '', settings: TemplateCookieSettings[]) {
    this.cookieId = cookieId
    this.settings = settings
  }
}
