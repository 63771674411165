import { Complexity, Priority, EnquiryType } from '@/common/enums'
import { JobType } from '@/models/types'

export default class PolicyBasicDetailModel {
  public policyId: number
  public insurerId: number
  public name: string
  public isStarred: boolean
  public insurerReference: string
  public complexity: Complexity
  public priority: Priority
  public intention: string
  public emergencyDefinition: string
  public deliveryPhone: string
  public answerPhone: string
  public policyLimit: number
  public hasPolicyLimitPerEmergency: boolean
  public cutOffHours: number | null
  public enquiryType: EnquiryType
  public isHelplinePolicy: boolean
  public isCommercialPropertyPolicy: boolean | null
  public helplineEmail: string | null
  public fileURL: string | null
  public file: string | null
  public ratePackageId: string
  public disclaimer: string
  public isNewPolicyPublished: boolean
  public jobType: JobType
  public isDeleted: boolean

  public exclusions: number
  public exclusionsHTML: number
  public onlyOneVisitCovered: boolean
  public maxNotificationHours: number
  public passTo: number

  public constructor() {
    this.isStarred = false
    this.isHelplinePolicy = false
    this.policyLimit = 0
    this.helplineEmail = ''
    this.name = ''
    this.isHelplinePolicy = false
    if (!this.complexity) {
      this.complexity = Complexity.Normal
    }
    if (!this.priority) {
      this.priority = Priority.High
    }
    if (!this.enquiryType) {
      this.enquiryType = EnquiryType.On
    }
  }
}
