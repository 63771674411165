<template>
  <v-layout wrap>
    <v-flex v-for="item in keyItems" :key="item.id" xs12>
      <span>
        {{ item.questionText }}:
        <b>{{ item.answer }} {{ item.answer !== '' ? item.unit : '' }}</b>
      </span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQA from '@/models/EmergencyQA'
import storeGetters from '@/storeGetters'

@Component
export default class PolicyWideQACard extends TimeLineItemBase {
  private get items(): EmergencyQA[] {
    return storeGetters.getPolicyWideQAs(this.jobId)
  }

  private get keyItems(): EmergencyQA[] {
    return this.items.filter((e) => e.keyQuestion)
  }
}
</script>

<style scoped>
.custom-warning {
  border-top: 0px;
}
.custom-warning >>> .v-icon {
  color: #f44336 !important;
}
</style>
