<template>
  <div>
    <v-card v-if="jobItem" class="mapsidebar-content">
      <ul class="contact-details">
        <li>
          <v-icon color="black">my_location</v-icon>
          {{ jobItem.clientForename + ' ' + jobItem.clientLastName }}
        </li>
        <li class="prolicy-name-customer">
          <a href="#" class="secondary--text" @click="openPolicySummaryDialog(jobItem.policyScheduleId)">
            <v-icon :class="jobItem.additionalCostsAuthorised ? 'green--text' : 'secondary--text'">assignment</v-icon>
            <u :class="jobItem.additionalCostsAuthorised ? 'green--text' : 'secondary--text'">
              {{ jobItem.policyScheme }}
            </u>
          </a>
          <v-btn
            v-if="showUpdatePolicyScheduleButton"
            icon
            class="ma-0 name-edit lighten-2 underline edit-policy-btn"
            @click="openPolicyReAssignDialogue"
          >
            <v-icon small>edit</v-icon>
          </v-btn>
        </li>
        <li>
          <i class="svg-wrap">
            <img src="/img/pound.svg" />
          </i>
          {{ jobItem.insuranceValue }}
          {{ jobItem.jobType === 'US' ? 'Delegated Authority' : 'Limit' }}
        </li>
        <li v-if="jobItem.jobType === 'HE' && jobItem.excessAmount">
          <i class="svg-wrap excess-amount">
            <img src="/img/pound.svg" />
          </i>
          {{ jobItem.excessAmount }} {{ 'Excess' }}
        </li>
        <li class="chat-box-wrap">
          <span v-if="jobItem.mobilePhone || jobItem.nomineeMobilePhone || jobItem.email || jobItem.nomineeEmail">
            <CustomerCall :job-id="jobId"></CustomerCall>
          </span>
          <!-- <span v-if="jobItem.email || jobItem.nomineeEmail">
            <CustomerEmail :jobId="jobId"></CustomerEmail>
          </span> -->
          <!-- CustomerToPay Accessible based on User Right to Access Button. -->
          <span v-if="getAbilityToAccessCustomerToPay && !isHEJobCompleted">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-badge overlap color="grey small-size-badge darken-2">
                  <span v-if="jobItem.customerToPay.length > 0" slot="badge" small>
                    {{ jobItem.customerToPay.length }}
                  </span>
                  <v-avatar class="btn-payment" color="white" :size="35" v-on="on">
                    <v-icon color="secondary" @click="CustomerToPay">payment</v-icon>
                  </v-avatar>
                </v-badge>
              </template>
              <span>Payment</span>
            </v-tooltip>
          </span>
          <span>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-avatar color="white" :size="35" v-on="on">
                  <v-icon color="secondary" @click="openAddNote">notes</v-icon>
                </v-avatar>
              </template>
              <span>Notes</span>
            </v-tooltip>
          </span>
          <span>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-badge overlap color="grey small-size-badge darken-2">
                  <span v-if="jobItem.complaint.length > 0" slot="badge" small>
                    {{ jobItem.complaint.length }}
                  </span>
                  <v-avatar color="white" :size="35" v-on="on">
                    <v-icon color="secondary" @click="complaintRaised">announcement</v-icon>
                  </v-avatar>
                </v-badge>
              </template>
              <span>Complaint</span>
            </v-tooltip>
          </span>
          <span>
            <PreviousJobs :job-id="jobId"></PreviousJobs>
          </span>
          <span>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-avatar color="white" :size="35" v-on="on" @click="openAuditLogModal()">
                  <v-icon color="secondary">description</v-icon>
                </v-avatar>
              </template>
              <span>Audit Log</span>
            </v-tooltip>
          </span>
          <span class="subMenuContent">
            <v-speed-dial v-model="subMenu" :absolute="true" :open-on-hover="true" class="more-btn">
              <v-btn slot="activator" v-model="subMenu" small color="white" dark fab>
                <v-icon class="secondary--text">more_horiz</v-icon>
              </v-btn>
              <span v-if="!isHEJobCompleted" class="mb-2 card-img-content">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-avatar
                      class="cost-authorised-btn"
                      color=""
                      :class="jobItem.additionalCostsAuthorised ? 'green' : 'white'"
                      :size="35"
                      v-on="on"
                      @click="openCostAuthorizationDialog"
                    >
                      <i class="svg-wrap">
                        <img
                          src="/img/get-money.svg"
                          :class="jobItem.additionalCostsAuthorised ? 'hide-img' : 'show-img'"
                        />
                        <img
                          src="/img/get-money-white.svg"
                          :class="jobItem.additionalCostsAuthorised ? 'show-img' : 'hide-img'"
                        />
                      </i>
                    </v-avatar>
                  </template>
                  <span>Additional Payment Authorised</span>
                </v-tooltip>
              </span>
              <span v-if="showOtherFeesDetailIcon" class="mb-2">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-avatar
                      color="white"
                      class="btn-addOtherFeesDetail"
                      :size="35"
                      v-on="on"
                      @click="addOtherFeesDetail"
                    >
                      <v-icon color="secondary">post_add</v-icon>
                    </v-avatar>
                  </template>
                  <span>Other Fees</span>
                </v-tooltip>
              </span>
              <span v-if="jobItem.status !== 'Completed'" class="mb-2">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-avatar
                      v-if="jobItem.status === 'Cancelled' || jobItem.cancellationReason"
                      color="white"
                      :size="35"
                      v-on="on"
                    >
                      <v-icon color="white" class="red">work_off</v-icon>
                    </v-avatar>
                    <v-avatar
                      v-else-if="getAbilityToCancelJob"
                      color="white"
                      :size="35"
                      v-on="on"
                      @click="cancelJob(jobItem.cancellationReason)"
                    >
                      <v-icon color="secondary">work_off</v-icon>
                    </v-avatar>
                  </template>
                  <span>
                    {{ jobItem.status === 'Cancelled' || jobItem.cancellationReason ? 'Job Cancelled ' : 'Cancel Job' }}
                  </span>
                </v-tooltip>
              </span>
              <span
                v-if="
                  canEscalateJob || (getJobCurrentEscalationDetail && getJobCurrentEscalationDetail.escalationReason)
                "
                class="mb-2"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-avatar
                      color=""
                      class="btn-escalateJobDetail"
                      :class="
                        getJobCurrentEscalationDetail && getJobCurrentEscalationDetail.escalationReason
                          ? 'secondary'
                          : 'white'
                      "
                      :size="35"
                      v-on="on"
                      @click="showEscalateJobDialog"
                    >
                      <v-icon
                        :class="
                          getJobCurrentEscalationDetail && getJobCurrentEscalationDetail.escalationReason
                            ? 'white--text'
                            : 'secondary--text'
                        "
                      >
                        forward
                      </v-icon>
                    </v-avatar>
                  </template>
                  <span v-if="getJobCurrentEscalationDetail && getJobCurrentEscalationDetail.escalationReason">
                    Job is Escalated due to
                    {{ getJobCurrentEscalationDetail.escalationReason }}.
                    <p>
                      Description:
                      {{ getJobCurrentEscalationDetail.escalationDescription }}
                    </p>
                  </span>
                  <span v-else>Escalate Job</span>
                </v-tooltip>
              </span>
              <span v-if="showRepairEstimateIcon" class="mb-2">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-avatar
                      color="white"
                      class="btn-repairEstimate"
                      :size="35"
                      v-on="on"
                      @click="showRepairEstimationDialog = true"
                    >
                      <v-icon color="secondary">home_repair_service</v-icon>
                    </v-avatar>
                  </template>
                  <span>Repair Estimate</span>
                </v-tooltip>
              </span>
            </v-speed-dial>
          </span>
        </li>
      </ul>
    </v-card>
    <v-dialog v-model="showPolicySummaryDialog" max-width="800" persistent content-class="v-dialog--scrollable">
      <PolicySummary
        :policy-summary-item="policySummaryModel"
        :job-id="jobId"
        @OnPolicySummaryClose="closePolicySummaryDialog"
      ></PolicySummary>
    </v-dialog>
    <v-dialog
      v-if="showAuditLogDialog"
      v-model="showAuditLogDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <JobAuditLog :job-id="jobId" @closeModal="closeAuditLog()"></JobAuditLog>
    </v-dialog>
    <v-dialog v-model="costAuthorizationDialog" max-width="450" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Cost Authorization</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="onCostAuthorizationDialogClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-switch
            v-model="additionalCostsAuthorised"
            label="Authorize Additional Cost"
            hide-details
            color="primary"
            @change="additionalCostsAuthorisedChange"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-flex>
            <v-select
              v-if="additionalCostsAuthorised"
              v-model="additionalPaymentAuthorizationReason"
              v-validate="'required'"
              :items="additionalPaymentAuthorizationReasonList"
              item-text="description"
              item-value="id"
              label="Select additional payment authorization reason"
              required
              class="required"
              :loading="getadditionalPaymentAuthorizationReasonLoading"
              :disabled="disableCostReason"
              data-vv-scope="frmAuthorizationReason"
              data-vv-name="Authorization Job Reason"
              :error-messages="errors.collect('Authorization Job Reason')"
              @change="additionalPaymentAuthorizationReasonChange"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                      {{ data.item.description }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="onCostAuthorizationDialogClose">Cancel</v-btn>
          <v-btn
            color="primary"
            class="mr-0"
            :disabled="isLoading"
            :loading="isLoading"
            @click="onAdditionalCostAuthorized"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="cancelJobDialog"
      v-model="cancelJobDialog"
      max-width="650"
      content-class="v-dialog--scrollable"
      persistent
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Cancel Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="cancelJobDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-3">
          <v-layout row wrap>
            <v-flex>
              <v-select
                v-model="cancelJobReason"
                v-validate="'required'"
                :items="cancelJobReasonList"
                item-text="description"
                item-value="id"
                label="Select cancellation reason for job"
                required
                class="required"
                :loading="getCancelJobReasonLoading"
                data-vv-scope="frmCancellationReason"
                data-vv-name="Cancel Job Reason"
                :error-messages="errors.collect('Cancel Job Reason')"
                @change="cancelJobReasonChange"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                        {{ data.item.description }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-select>
            </v-flex>
            <v-flex xs12>
              <v-textarea v-model="reasonDescription" label="Description"></v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="cancelJobDialog = false">Cancel</v-btn>
          <v-btn color="primary" class="mr-0" :loading="isLoading" :disabled="isLoading" @click="addCancelJobRequest">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureDropdown
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>
    <!-- Escalate job dialog -->
    <v-dialog
      v-if="openEscalateJobDialog"
      v-model="openEscalateJobDialog"
      max-width="450"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Escalate Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="openEscalateJobDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-select
            v-model="escalationTypeId"
            v-validate="'required'"
            :items="escalationTypeList"
            item-text="description"
            item-value="id"
            label="Reason for escalation"
            required
            class="required escalationType-list"
            data-vv-scope="frmEscalateReason"
            data-vv-name="Job Escalate Reason"
            :error-messages="errors.collect('Job Escalate Reason')"
            @change="onEscalationTypeChange"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                    {{ data.item.description }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-select>
          <v-textarea
            v-model="escalationDescription"
            v-validate="'required'"
            label="Description"
            required
            class="required"
            data-vv-scope="frmEscalateReason"
            data-vv-name="Job Escalate Description"
            :error-messages="errors.collect('Job Escalate Description')"
          ></v-textarea>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="resetEscalationFields()">Cancel</v-btn>
          <v-btn color="primary" class="mr-0" :disabled="isLoading" :loading="isLoading" @click="escalateJob()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="policyReAssignDialogue"
      v-model="policyReAssignDialogue"
      max-width="650"
      persistent
      content-class="overflow-visible"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Select Policy</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="policyReAssignDialogue = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 policy-selector">
          <v-progress-circular
            v-show="!policies"
            :width="2"
            :size="50"
            indeterminate
            color="primary"
            :style="getLoaderStyle(40)"
          ></v-progress-circular>
          <v-flex v-show="policies" mb-2>
            <PolicySelector
              v-if="policies && preSelectedPolicyScheduleId"
              id="policySelector"
              ref="policySelector"
              type="number"
              :policy-tree="policies"
              :error-messages="policySelected ? [] : ['A policy must be selected']"
              :selected-policy="selectedPolicy"
              :pre-selected-policy="preSelectedPolicyScheduleId"
              parent-component="CustomerCard"
              @update:selectedPolicy="updatePolicySelection"
            />
          </v-flex>
          <span v-if="showInfoMessage" class="warning--text">
            {{ showInfoMessage }}
          </span>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="closePolicyReAssignDialogue">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0"
            :disabled="policySearchRunning || policyReAssignInProgress"
            :loading="policyReAssignInProgress"
            @click="onUpdateJobPolicySchedule"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="triggerCTPForPolicyChangeDialog"
      v-model="triggerCTPForPolicyChangeDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="pa-3">
          <h3>
            {{
              'Policy Limit Exceeded. Do you want to pay ' +
              getFormatedCurrency(triggerCTPResponse.extraAmountNeedToPay) +
              ' to proceed further?'
            }}
          </h3>
        </v-card-title>
        <v-card-actions class="pt-2 px-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn small flat @click="closeTriggerCTPForPolicyChangeDialog">No</v-btn>
          <v-btn color="primary" small @click="onConfirmTriggerCTPForPolicyChange">Yes</v-btn>
          <v-btn
            color="primary"
            small
            :disabled="useTriggerCTPLoading"
            :loading="useTriggerCTPLoading"
            @click="changePolicyWithoutCTP"
          >
            Skip CTP
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="useExtraBalanceConfirmationDialog"
      v-model="useExtraBalanceConfirmationDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="pa-3">
          <h3>
            {{
              'You have ' +
              getFormatedCurrency(triggerCTPResponse.extraBalanceLeft) +
              ' extra balance. Do you want to use this balance to proceed further?'
            }}
          </h3>
        </v-card-title>
        <v-card-actions class="pt-2 px-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn small flat @click="onCancelExtraBalanceConfirmationDialog">No</v-btn>
          <v-btn
            color="primary"
            small
            :disabled="useTriggerCTPLoading"
            :loading="useTriggerCTPLoading"
            @click="onConfirmUseExtraBalance"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RepairEstimationDialog
      v-if="showRepairEstimationDialog"
      ref="repairEstimationDialog"
      :policy-client-rate-package-id="
        jobItem && jobItem.policyClientRatePackageId ? jobItem.policyClientRatePackageId : ''
      "
      :job-id="jobId"
      @closeRepairEstimationDialog="onCloseRepairEstimationDialog"
    ></RepairEstimationDialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- Configure job escalation reasons, channel id -->
    <v-dialog
      v-if="configureEscalationReasonDialog"
      v-model="configureEscalationReasonDialog"
      max-width="860"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureEscalationReason
        ref="refConfigureEscalationReason"
        @CloseConfigureEscalationReasonDialog="onConfigureEscalationReasonDialogClose"
      ></ConfigureEscalationReason>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Job from '@/models/Job'
import StoreGetters from '@/storeGetters'
import AddNewJobNote from '@/models/requests/AddNewJobNote'
import Shared from '@/common/shared'
import JobController from '@/api/jobController'
import AddJobNote from '@/components/AddJobNote.vue'
import PreviousJobs from '@/components/PreviousJobs.vue'
import Store from '@/store'
import PolicySummaryModel from '@/models/policy/PolicySummaryModel'
import PolicySummary from '@/components/PolicySummary.vue'
import JobAuditLog from '@/components/JobAuditLog.vue'
import RequestModel from '@/models/RequestModel'
import UserModel from '@/models/user/UserModel'
import storeMutations from '@/storeMutations'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import Emergency from '@/models/Emergency'
import CustomerCall from '@/components/CustomerCall.vue'
import CustomerEmail from '@/components/CustomerEmail.vue'
import store from '@/store'
import AddAdditionalCostAuthorisedRequestModel from '@/models/requests/AddAdditionalCostAuthorisedRequestModel'
import AddRequestForCancelJobModel from '@/models/requests/AddRequestForCancelJobModel'
import moment from 'moment'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import { RecordType } from '@/common/enums'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import AddEscalateJobRequest from '../models/requests/AddEscalateJobRequestModel'
import storeGetters from '@/storeGetters'
import { isNullOrUndefined } from 'util'
import { SelectedPolicy, InsurerBrandList } from '@/models/policy/PolicyLists'
import PolicySelector from '@/components/PolicySelector.vue'
import UpdateJobPolicySchedule from '@/models/requests/UpdateJobPolicySchedule'
import eventBus from '@/common/bus'
import EscalateCTPResponseModel from '../models/claim/EscalateCTPResponseModel'
import CheckPolicyLimitResponse from '../models/claim/CheckPolicyLimitResponse'
import PolicyScheduleController from '@/api/policyScheduleController'
import JobEscalationDetail from '@/models/claim/JobEscalationDetailModel'
import RepairEstimationDialog from '@/components/RepairEstimationDialog.vue'
import ConfigureEscalationReason from '@/components/ConfigureEscalationReason.vue'
import JobEscalationReasonModel from '@/models/claim/JobEscalationReasonModel'
import JobEscalationReasonController from '@/api/jobEscalationReasonController'

@Component({
  components: {
    AddJobNote,
    PreviousJobs,
    PolicySummary,
    JobAuditLog,
    CustomerCall,
    CustomerEmail,
    ConfigureDropdown,
    PolicySelector,
    RepairEstimationDialog,
    ConfigureEscalationReason,
  },
})
export default class CustomerCard extends Vue {
  @Prop() private jobId: string
  private isLoading = false
  private showPolicySummaryDialog = false
  private policySummaryModel: PolicySummaryModel | null = null
  private showAuditLogDialog = false
  private requestDocs: RequestModel[] = []
  private costAuthorizationDialog = false
  private additionalCostsAuthorised = false
  private additionalPaymentAuthorizationReasonList: MasterRecord[] = []
  private disableCostReason = false
  private additionalPaymentAuthorizationReason = ''
  private getadditionalPaymentAuthorizationReasonLoading = false
  private cancelJobDialog = false
  private reasonDescription = ''
  private configureDropdownDialog = false
  private configureDropdownType = ''
  private cancelJobReasonList: MasterRecord[] = []
  private cancelJobReason = ''
  private getCancelJobReasonLoading = false
  private fnolSkill = 3
  private openEscalateJobDialog = false
  private escalationTypeId = ''
  private escalationDescription = ''
  private escalationTypeList: JobEscalationReasonModel[] = []
  private policyReAssignDialogue = false
  private policySearchRunning = false
  private policyReAssignInProgress = false
  private policies: InsurerBrandList[] | null = null
  private selectedPolicy: SelectedPolicy = {
    insurer: null,
    brand: null,
    policy: null,
  }
  private preSelectedPolicyScheduleId: null | number = null
  private updateJobPolicySchedule: UpdateJobPolicySchedule = new UpdateJobPolicySchedule()
  private showInfoMessage = ''
  private triggerCTPResponse: CheckPolicyLimitResponse
  private triggerCTPForPolicyChangeDialog = false
  private useTriggerCTPLoading = false
  private useExtraBalanceConfirmationDialog = false
  private subMenu = false
  private showRepairEstimationDialog = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText: string | null = ''
  private configureEscalationReasonDialog = false

  public complaintRaised(): void {
    this.$emit('complaintRaised')
  }

  private get jobItem(): Job | null {
    return StoreGetters.getJob(this.jobId)
  }

  private CustomerToPay(): void {
    this.$emit('CustomerToPay')
  }

  private get getAbilityToAccessCustomerToPay(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.hasCustomerToPayAccess
    )
  }

  @Watch('jobItem.additionalCostsAuthorised')
  private additionalCostsAuthorizationChange(): void {
    if (this.isLoading) {
      this.isLoading = false
    }
    if (this.costAuthorizationDialog) {
      this.costAuthorizationDialog = false
    }
  }

  private openAddNote(): void {
    eventBus.$emit('openAddNote')
  }

  // open policy coverage summary dialog
  private openPolicySummaryDialog(policyScheduleId: number) {
    const self = this
    self.showPolicySummaryDialog = true

    // get policy summry by scheduleId
    PolicyScheduleController.GetPolicySummary(policyScheduleId)
      .then((res: PolicySummaryModel | null) => {
        self.policySummaryModel = res
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading policy summary detail, please try again', true)
      })
  }

  // close policy coverage summary dialog
  private closePolicySummaryDialog() {
    this.showPolicySummaryDialog = false
    this.policySummaryModel = null
  }

  private openAuditLog() {
    this.$store.dispatch('jobRequestModule/submitRetrieveEnhancedJobRequests', this.jobId)
    this.showAuditLogDialog = true
  }

  private closeAuditLog(): void {
    this.showAuditLogDialog = false
    this.requestDocs = []
  }

  private openCostAuthorizationDialog() {
    this.additionalCostsAuthorised = this.jobItem ? this.jobItem.additionalCostsAuthorised : false
    this.disableCostReason = this.additionalCostsAuthorised
    this.getadditionalPaymentAuthorizationReasonLoading = true
    this.GetAdditionalPaymentAuthorizationReasonList(
      this.jobItem ? this.jobItem.additionalPaymentAuthorizationReason : null
    )
    this.costAuthorizationDialog = true
  }

  private GetAdditionalPaymentAuthorizationReasonList(authorizationReason: string | null): void {
    const recordType: string = RecordType[RecordType.AdditionalPaymentAuthorizationReason]
    this.configureDropdownType = RecordType[RecordType.AdditionalPaymentAuthorizationReason]
    MasterRecordController.GetMasterRecords(recordType)
      .then((res: MasterRecord[]) => {
        if (res) {
          this.additionalPaymentAuthorizationReasonList = res.filter((e) => e.isDeleted === false)
          if (this.isUserRoleAdministrator) {
            const additionalPaymentAuthorizationReasonConfigure: any = []
            additionalPaymentAuthorizationReasonConfigure.description = 'Configure'
            this.additionalPaymentAuthorizationReasonList.push(additionalPaymentAuthorizationReasonConfigure)
          }
          if (authorizationReason != null && authorizationReason !== '') {
            this.additionalPaymentAuthorizationReason = authorizationReason
          }
        }
        this.getadditionalPaymentAuthorizationReasonLoading = false
      })
      .catch((e) => {
        this.getadditionalPaymentAuthorizationReasonLoading = false
      })
  }

  private onAdditionalCostAuthorized() {
    if (this.jobItem) {
      if (
        this.jobItem.additionalCostsAuthorised === this.additionalCostsAuthorised &&
        this.jobItem.additionalPaymentAuthorizationReason === this.additionalPaymentAuthorizationReason
      ) {
        this.costAuthorizationDialog = false
        return
      } else {
        this.validate('frmAuthorizationReason').then((result: boolean) => {
          if (
            result &&
            this.jobItem &&
            (!this.additionalCostsAuthorised ||
              (this.additionalCostsAuthorised &&
                this.additionalPaymentAuthorizationReason != null &&
                this.additionalPaymentAuthorizationReason !== ''))
          ) {
            const additionalCostAuthorisedRequestModel: AddAdditionalCostAuthorisedRequestModel =
              new AddAdditionalCostAuthorisedRequestModel()
            additionalCostAuthorisedRequestModel.jobId = this.jobItem.jobId
            additionalCostAuthorisedRequestModel.additionalCostsAuthorised = this.additionalCostsAuthorised
            additionalCostAuthorisedRequestModel.additionalPaymentAuthorizationReason =
              this.additionalPaymentAuthorizationReason
            this.isLoading = true
            JobController.AddAdditionalCostAuthorisedRequest(additionalCostAuthorisedRequestModel)
              .then((res: boolean) => {
                if (res) {
                  // close dialog and hide loading once additionalCostsAuthorised property's value from JobDetail set through SignalR call.
                }
              })
              .catch((err: any) => {
                eventBus.$emit(
                  'errorHandler',
                  'Error saving additional cost authorisation request, please try again.',
                  true
                )
                this.isLoading = false
              })
          }
        })
      }
    }
  }

  private additionalCostsAuthorisedChange() {
    if (!this.additionalCostsAuthorised) {
      this.additionalPaymentAuthorizationReason = ''
      this.disableCostReason = true
    }
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on Additional Payment Authorization Reason type.
  private additionalPaymentAuthorizationReasonChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.AdditionalPaymentAuthorizationReason]
      this.configureDropdownDialog = true
    }
  }

  private onCostAuthorizationDialogClose() {
    this.costAuthorizationDialog = false
    this.isLoading = false
  }

  private get getAbilityToCancelJob(): boolean {
    return Store.Instance.state.SessionDetail.canCancelJob
  }

  private async validate(frmValidation: string): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(frmValidation)
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  // Get list of Cancel Job Reason.
  private GetCancelJobReasonList(): void {
    const recordType: string = RecordType[RecordType.JobCancellationReason]
    this.configureDropdownType = RecordType[RecordType.JobCancellationReason]
    MasterRecordController.GetMasterRecords(recordType)
      .then((res: MasterRecord[]) => {
        if (res) {
          this.cancelJobReasonList = res.filter((e) => e.isDeleted === false)
          if (this.isUserRoleAdministrator) {
            const cancelJobReasonConfigure: any = []
            cancelJobReasonConfigure.description = 'Configure'
            this.cancelJobReasonList.push(cancelJobReasonConfigure)
          }
        }
        this.getCancelJobReasonLoading = false
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error loading job cancellation reason list, please try again', true)
        this.getCancelJobReasonLoading = false
      })
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on Job Cancellation Reason type.
  private cancelJobReasonChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.JobCancellationReason]
      this.configureDropdownDialog = true
    }
  }

  // when dialog to configure type 'JobCancellationReason','AdditionalPaymentAuthorisedReason' is closed according list items should be updated.
  private onConfigureDropdownDialogClose(Items: any[]) {
    if (this.configureDropdownType === RecordType[RecordType.AdditionalPaymentAuthorizationReason]) {
      this.configureDropdownDialog = false
      this.configureDropdownType = ''
      this.escalationTypeId = ''
      const deletedRecord: MasterRecord | undefined = this.additionalPaymentAuthorizationReasonList.find(
        (e) => e.isDeleted === true
      )
      this.additionalPaymentAuthorizationReasonList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.additionalPaymentAuthorizationReasonList.push(deletedRecord)
      }
      if (this.isUserRoleAdministrator) {
        const authorizationReasonType: any = {}
        authorizationReasonType.description = 'Configure'
        this.additionalPaymentAuthorizationReasonList.push(authorizationReasonType)
      }
    } else {
      this.configureDropdownDialog = false
      this.configureDropdownType = ''
      this.cancelJobReason = ''
      const deletedRecord: MasterRecord | undefined = this.cancelJobReasonList.find((e) => e.isDeleted === true)
      this.cancelJobReasonList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.cancelJobReasonList.push(deletedRecord)
      }
      if (this.isUserRoleAdministrator) {
        const cancelJobReasonConfigure: any = []
        cancelJobReasonConfigure.description = 'Configure'
        this.cancelJobReasonList.push(cancelJobReasonConfigure)
      }
    }
  }

  private cancelJob(cancellationReason: string) {
    if (cancellationReason) {
      return
    }
    this.cancelJobReason = ''
    this.reasonDescription = ''
    this.cancelJobDialog = true
    this.getCancelJobReasonLoading = true
    this.GetCancelJobReasonList()
  }

  private addCancelJobRequest() {
    this.validate('frmCancellationReason')
      .then((result: boolean) => {
        if (result) {
          const addRequestForCancelJobModel: AddRequestForCancelJobModel = new AddRequestForCancelJobModel()
          addRequestForCancelJobModel.jobId = this.jobId
          addRequestForCancelJobModel.processAtUtc = moment.utc(new Date())
          addRequestForCancelJobModel.cancellationReason = this.cancelJobReason
          addRequestForCancelJobModel.reasonDescription = this.reasonDescription
          this.isLoading = true
          JobController.AddRequestForCancelJob(addRequestForCancelJobModel)
            .then((res: boolean) => {
              if (res) {
                this.cancelJobDialog = false
              }
              this.isLoading = false
            })
            .catch((err: any) => {
              this.isLoading = false
              eventBus.$emit('errorHandler', 'Error submitting cancel job request, please try again', true)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private get isUserSupervisor(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Finance' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager')
    )
  }

  private async getPolicySchedule() {
    if (!this.policies || this.policies.length === 0) {
      if (this.jobItem) {
        const jobType = this.jobItem.jobType
        this.policies = await PolicyScheduleController.GetPolicySchedule(false, jobType)
      }
    }
    return this.policies
  }

  private async openPolicyReAssignDialogue() {
    const self = this
    self.preSelectedPolicyScheduleId = null
    self.showInfoMessage = ''
    self.policyReAssignDialogue = true
    this.policySearchRunning = true
    await this.getPolicySchedule()
    try {
      if (this.jobItem && this.jobItem.insurerId) {
        const insurer = self.policies!.find(
          (currentInsurer) => currentInsurer.id === (this.jobItem ? this.jobItem.insurerId : '')
        )!
        // finds the first brand which contains the selected policy
        const brand = insurer.brands!.find(
          (currentBrand) =>
            currentBrand.policies.findIndex((p) => p.id === (this.jobItem ? this.jobItem.policyScheduleId : '')) > -1
        )!
        const policy = brand.policies.find(
          (currentPolicy) => currentPolicy.id === (this.jobItem ? this.jobItem.policyScheduleId : '')
        )!
        this.selectedPolicy = {
          insurer: { id: insurer.id, name: insurer.name },
          brand: {
            id: brand.id,
            name: brand.subDomain,
            defaultPolicy: brand.fallBackStarPolicy,
          },
          policy: { id: policy.id, name: policy.name },
        }
        this.preSelectedPolicyScheduleId =
          this.selectedPolicy && this.selectedPolicy.policy ? this.selectedPolicy.policy.id : null
        this.policySearchRunning = false
      }
    } catch (error) {
      eventBus.$emit('validationErrorHandler', 'Error loading selected policy detail, please try again')
      this.policySearchRunning = false
    }
  }

  private closePolicyReAssignDialogue() {
    this.policyReAssignDialogue = false
  }

  private get policySelected(): boolean {
    return !isNullOrUndefined(this.selectedPolicy.policy)
  }

  private updatePolicySelection(newSelected: SelectedPolicy) {
    this.showInfoMessage = ''
    this.selectedPolicy = newSelected
    if (this.selectedPolicy.policy) {
      if (this.selectedPolicy) {
        this.updateJobPolicySchedule.jobId = this.jobId
        this.updateJobPolicySchedule.insurerId = this.selectedPolicy.insurer ? this.selectedPolicy.insurer.id : null
        this.updateJobPolicySchedule.insurerName = this.selectedPolicy.insurer ? this.selectedPolicy.insurer.name : ''
        this.updateJobPolicySchedule.policyScheduleId = this.selectedPolicy.policy.id
        this.updateJobPolicySchedule.policyName = this.selectedPolicy.policy.name
      }
    }
  }

  private onUpdateJobPolicySchedule() {
    const policySelectorSub = this.$refs.policySelector as PolicySelector
    if (policySelectorSub && policySelectorSub.value) {
      if (
        this.selectedPolicy &&
        this.selectedPolicy.policy &&
        this.jobItem &&
        this.jobItem.policyScheduleId === this.updateJobPolicySchedule.policyScheduleId
      ) {
        this.showInfoMessage = 'Policy ' + this.selectedPolicy.policy.name + ' already selected!'
        return
      }
      this.policyReAssignInProgress = true
      JobController.UpdateJobPolicySchedule(this.updateJobPolicySchedule)
        .then((res: CheckPolicyLimitResponse | null) => {
          if (res) {
            if (res.requiredToTriggerCTP) {
              this.triggerCTPResponse = res
              this.triggerCTPForPolicyChangeDialog = true
            }
          }
          this.policyReAssignInProgress = false
          this.policyReAssignDialogue = false
        })
        .catch((err: any) => {
          this.policyReAssignInProgress = false
          this.policyReAssignDialogue = false
        })
    } else {
      return
    }
  }

  private onConfirmTriggerCTPForPolicyChange() {
    if (this.triggerCTPResponse.extraBalanceLeft > 0) {
      this.useExtraBalanceConfirmationDialog = true
    } else {
      this.makeCustomerToPayRequest(false)
    }
    this.triggerCTPForPolicyChangeDialog = false
  }

  private closeTriggerCTPForPolicyChangeDialog() {
    this.triggerCTPForPolicyChangeDialog = false
  }

  private changePolicyWithoutCTP() {
    this.useTriggerCTPLoading = true
    const updateJobPolicySchedule: UpdateJobPolicySchedule = this.updateJobPolicySchedule
    updateJobPolicySchedule.processWithoutCTP = true
    this.triggerPolicyChangeRequest(updateJobPolicySchedule)
  }

  private onConfirmUseExtraBalance() {
    this.makeCustomerToPayRequest(true)
  }

  private onCancelExtraBalanceConfirmationDialog() {
    this.makeCustomerToPayRequest(false)
  }

  private makeCustomerToPayRequest(useExtraBalance: boolean) {
    // trigger CTP process
    const amountNeedToPay: number = useExtraBalance
      ? this.triggerCTPResponse.extraAmountNeedToPay - this.triggerCTPResponse.extraBalanceLeft
      : this.triggerCTPResponse.extraAmountNeedToPay
    if (amountNeedToPay > 0) {
      eventBus.$emit('makeCustomerToPay')
      const updateJobPolicySchedule: UpdateJobPolicySchedule = this.updateJobPolicySchedule
      updateJobPolicySchedule.useExtraBalanceLeft = useExtraBalance

      const escalateCTPResponseModel: EscalateCTPResponseModel = new EscalateCTPResponseModel()
      escalateCTPResponseModel.extraAmountNeedToPay = amountNeedToPay
      escalateCTPResponseModel.useExtraBalance = useExtraBalance
      escalateCTPResponseModel.recordType = 'JobDetail'
      escalateCTPResponseModel.updateJobPolicySchedule = updateJobPolicySchedule
      setTimeout(() => {
        eventBus.$emit('triggerCTPForPolicyChange', escalateCTPResponseModel, amountNeedToPay)
      }, 0)
      this.useExtraBalanceConfirmationDialog = false
      this.triggerCTPForPolicyChangeDialog = false
    } else {
      const updateJobPolicySchedule: UpdateJobPolicySchedule = this.updateJobPolicySchedule
      updateJobPolicySchedule.useExtraBalanceLeft = useExtraBalance
      this.triggerPolicyChangeRequest(updateJobPolicySchedule)
    }
  }

  private triggerPolicyChangeRequest(updateJobPolicySchedule: UpdateJobPolicySchedule) {
    JobController.UpdateJobPolicySchedule(updateJobPolicySchedule)
      .then((res: CheckPolicyLimitResponse | null) => {
        if (res) {
          this.useTriggerCTPLoading = false
          this.useExtraBalanceConfirmationDialog = false
          this.triggerCTPForPolicyChangeDialog = false
          this.policyReAssignInProgress = false
          this.policyReAssignDialogue = false
        } else {
          eventBus.$emit('errorHandler', 'Error submitting policy change request, please try again', true)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting policy change request, please try again', true)
        this.useExtraBalanceConfirmationDialog = false
        this.triggerCTPForPolicyChangeDialog = false
        this.policyReAssignInProgress = false
        this.policyReAssignDialogue = false
      })
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  // Get list of escalation type.
  private getEscalationTypeList(): void {
    JobEscalationReasonController.GetJobEscalationReasons().then((res: JobEscalationReasonModel[]) => {
      if (res) {
        this.escalationTypeList = res.filter((e) => e.isDeleted === false)
        if (this.isUserRoleAdministrator) {
          const escalationTypeConfigure: any = {}
          escalationTypeConfigure.description = 'Configure'
          this.escalationTypeList.push(escalationTypeConfigure)
        }
      }
    })
  }

  private onEscalationTypeChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureEscalationReasonDialog = true
    }
  }

  private get canEscalateJob(): boolean {
    return Store.Instance.state.SessionDetail.canEscalateJob
  }

  private showEscalateJobDialog() {
    if (!(this.getJobCurrentEscalationDetail && this.getJobCurrentEscalationDetail.escalationReason)) {
      this.resetEscalationFields()
      this.getEscalationTypeList()
      this.openEscalateJobDialog = true
    } else {
      eventBus.$emit('showJobEscalateCard', this.getJobCurrentEscalationDetail.id)
    }
  }

  private escalateJob() {
    this.validate('frmEscalateReason')
      .then((result: boolean) => {
        if (result) {
          const addEscalateJobRequest: AddEscalateJobRequest = new AddEscalateJobRequest()
          addEscalateJobRequest.jobId = this.jobId
          const escalationReason: JobEscalationReasonModel | undefined = this.escalationTypeList.find(
            (x) => x.id === this.escalationTypeId
          )
          addEscalateJobRequest.escalationReason = escalationReason ? escalationReason.description : ''
          addEscalateJobRequest.escalationDescription = this.escalationDescription
          addEscalateJobRequest.channelId =
            escalationReason && escalationReason.channelId ? escalationReason.channelId : ''
          this.isLoading = true
          JobController.EscalateJobAsync(addEscalateJobRequest)
            .then((res: boolean) => {
              if (res) {
                this.resetEscalationFields()
              }
              this.isLoading = false
            })
            .catch((err: any) => {
              this.isLoading = false
              eventBus.$emit('errorHandler', 'Error submitting escalation job request, please try again', true)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private resetEscalationFields() {
    this.escalationTypeId = ''
    this.escalationDescription = ''
    this.$validator.errors.items = []
    this.openEscalateJobDialog = false
  }

  private get showOtherFeesDetailIcon() {
    return this.jobItem
      ? this.jobItem.jobType === 'HE' &&
          this.jobItem.clientInvoiceDetails &&
          this.jobItem.status !== 'Completed' &&
          (this.jobItem.status !== 'Cancelled' || !this.jobItem.cancellationReason) &&
          !this.jobItem.isJobReopened
      : false
  }

  private addOtherFeesDetail() {
    eventBus.$emit('addOtherFeesDetail')
  }

  private get getJobCurrentEscalationDetail(): JobEscalationDetail | null {
    return StoreGetters.getJobActiveEscalationDetails(this.jobId)
  }

  private onCloseRepairEstimationDialog(message = '') {
    if (message) {
      this.snackbarText = message
      this.snackbar = true
    }
    this.showRepairEstimationDialog = false
  }

  private get showRepairEstimateIcon() {
    return this.jobItem
      ? this.jobItem.jobType === 'US' &&
          this.jobItem.status !== 'Completed' &&
          (this.jobItem.status !== 'Cancelled' || !this.jobItem.cancellationReason)
      : false
  }

  private onConfigureEscalationReasonDialogClose(Items: any[]) {
    this.configureEscalationReasonDialog = false
    this.escalationTypeId = ''
    const deletedRecord: JobEscalationReasonModel | undefined = this.escalationTypeList.find(
      (e) => e.isDeleted === true
    )
    this.escalationTypeList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.escalationTypeList.push(deletedRecord)
    }
    if (this.isUserRoleAdministrator) {
      const escalationType: any = {}
      escalationType.description = 'Configure'
      this.escalationTypeList.push(escalationType)
    }
  }

  private get isHEJobCompleted() {
    return this.jobItem && this.jobItem.jobType === 'HE' ? this.jobItem.status === 'Completed' : false
  }

  private get showUpdatePolicyScheduleButton() {
    return (
      this.isUserSupervisor &&
      (this.jobItem
        ? this.jobItem.jobType === 'HE'
          ? this.jobItem.status !== 'Completed' &&
            (this.jobItem.status !== 'Cancelled' || !this.jobItem.cancellationReason) &&
            !this.jobItem.isJobReopened
          : true
        : false)
    )
  }

  private async openAuditLogModal() {
    this.showAuditLogDialog = true
    //Needs fixed - Temporary until we sort modules
    if (!this.allJobs[this.jobId]) {
      await JobController.GetJobDocuments(this.jobId)
    }
    await this.$store.dispatch('jobRequestModule/submitRetrieveJobRequests', this.jobId)
    await this.$store.dispatch('jobRequestModule/submitAuditLogData', this.jobId)
  }

  private get allJobs(): { [id: string]: Job } {
    return this.$store.getters['jobModule/allJobs']
  }
}
</script>

<style scoped>
.v-card.mapsidebar-content {
  width: calc(100% - 20px);
  width: -webkit-calc(100% - 20px);
  border-top: 4px solid #e91e63; /* Need to change this color with theme secondary color code */
  box-shadow: 0 6px 10px 0 rgba(204, 204, 204, 0.5), 0 1px 18px 0 rgba(204, 204, 204, 0.5),
    0 3px 5px -1px rgba(204, 204, 204, 0.5);
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  margin: 0;
  padding: 15px;
}
.contact-details {
  padding-left: 0;
  list-style: none;
}
.contact-details li {
  position: relative;
  padding-left: 25px;
  padding-bottom: 10px;
  display: inline-block;
  margin-right: 15px;
  min-height: 30px;
  vertical-align: top;
  line-height: 18px;
}
.contact-details li:last-child,
.contact-details li:nth-last-child(2) {
  margin-right: 0px;
}
.contact-details li .svg-wrap {
  height: 20px;
  left: 2px;
  width: 15px;
}
.contact-details li .svg-wrap img {
  height: 100%;
  width: 100%;
  display: block;
}
.contact-details li i {
  position: absolute;
  left: 0;
  top: -1px;
  font-size: 20px;
}
.contact-details li.chat-box-wrap {
  display: block;
  padding-left: 0;
  padding-bottom: 0;
}
.contact-details li.chat-box-wrap .v-tooltip {
  display: inline-block;
}
.contact-details li.chat-box-wrap >>> .v-avatar {
  box-shadow: 0 6px 10px 0 rgba(204, 204, 204, 0.5), 0 1px 18px 0 rgba(204, 204, 204, 0.5),
    0 3px 5px -1px rgba(204, 204, 204, 0.5);
  background: #ffffff;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.chat-box-wrap > span {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 12px;
  vertical-align: top;
}
.card-img-content {
  position: relative;
}
.card-img-content >>> .svg-wrap {
  width: 22px !important;
  left: 6px !important;
  height: 35px !important;
}
.show-img {
  display: block !important;
}
.hide-img {
  display: none !important;
}
.bold-select {
  font-weight: 600;
}
.job-transfer-icon {
  width: 25px !important;
  position: relative;
  left: -1px;
}

.prolicy-name-customer {
  position: relative;
  padding-right: 24px;
}
.name-edit {
  position: absolute !important;
  right: -5px;
  top: -3px;
  bottom: 0px;
  width: 24px;
  height: 24px;
}
.name-edit >>> i {
  position: static !important;
}
.policy-selector {
  min-height: 85px;
}
.mapsidebar-content >>> .small-size-badge.v-badge__badge {
  font-size: 11px;
  height: 22px;
  width: 22px;
  top: -9px;
  right: -9px;
}
.subMenuContent,
.more-btn .v-btn {
  width: 35px;
  height: 35px;
}
.subMenuContent .v-speed-dial {
  z-index: 2 !important;
  position: relative !important;
}
@media (max-width: 1280px) {
  .contact-details li {
    margin-right: 7px;
  }
}
@media (max-width: 1100px) {
  .contact-details li {
    margin-right: 0;
  }
}
</style>
