<template>
  <v-card class="elevation-0 mb-2">
    <v-card-title v-if="fromContractorDetailsView">
      <h4>Gas & Safety Answers</h4>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider v-if="fromContractorDetailsView"></v-divider>
    <v-card-text
      v-if="fromContractorDetailsView && showGasSafetyReportDialog && gasSafetyQuestionReportList.length > 0"
    >
      <v-container fluid grid-list-md pa-0>
        <v-layout wrap>
          <v-expansion-panel v-for="(list, index) in gasSafetyQuestionReportList" :key="index">
            <v-expansion-panel-content v-if="list.values.length > 0">
              <div slot="header">
                <h4>{{ list.header }}</h4>
              </div>
              <v-card>
                <div v-for="(onSiteQA, index) in list.values" :key="index" class="pt-2 pb-2 pl-3">
                  <div class="pt-2">
                    <b>Q:</b>
                    {{ onSiteQA.question }}
                  </div>
                  <div>
                    <b>A:</b>
                    <span v-if="onSiteQA.answer">{{ onSiteQA.answer }}</span>
                    <span v-else>No</span>
                  </div>
                  <div v-if="onSiteQA.description">
                    <b>D:</b>
                    <span>{{ onSiteQA.description }}</span>
                  </div>
                  <div v-if="onSiteQA.imageId && onSiteQA.imageUrl">
                    <img
                      :src="onSiteQA.imageUrl"
                      class="profile-img"
                      style="width: auto; height: auto; max-width: 500px; max-height: 500px"
                    />
                  </div>
                </div>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-layout>
      </v-container>
      <v-divider />
      <v-card-actions class="text-xs-right pt-3 pb-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mr-0 btn-generatePdf"
          :disabled="generatePDFInProcess"
          :loading="generatePDFInProcess"
          @click="generateReport()"
        >
          Generate PDF
        </v-btn>
      </v-card-actions>
    </v-card-text>
    <v-card-text
      v-else-if="fromContractorDetailsView && !showGasSafetyReportDialog && !(gasSafetyQuestionReportList.length > 0)"
      class="text-xs-center"
    >
      No Gas and Safety records found.
    </v-card-text>
    <v-card-text class="pa-0" :class="fromContractorDetailsView || !showGasSafetyReportDialog ? 'hide' : ''">
      <div id="gasSafety">
        <h3 class="mb-2 primary--text">Job Summary</h3>
        <div class="grey lighten-4 pa-2">
          <div v-if="jobId" class="pb-1 mb-2">
            <b>Job ID :</b>
            {{ jobId }}
          </div>
          <div class="pb-1 mb-2">
            <b>Emergency Type:</b>
            <span v-if="emergency && emergency.typeDescription">
              {{ emergency.typeDescription }}
            </span>
            <span v-else>---</span>
          </div>
          <div class="pb-1 mb-2">
            <b>Emergency Detail:</b>
            <span v-if="emergency && emergency.detailDescription">
              {{ emergency.detailDescription }}
            </span>
            <span v-else>---</span>
          </div>
          <div class="pb-1 mb-2">
            <b>Contractor Name:</b>
            <span v-if="getEngineerVisitDetail && getEngineerVisitDetail.companyName">
              {{ getEngineerVisitDetail.companyName }}
            </span>
            <span v-else>---</span>
          </div>
          <div class="pb-1 mb-2">
            <b>Engineer Name:</b>
            <span v-if="engineerName">{{ engineerName }}</span>
            <span v-else>---</span>
          </div>
          <div class="pb-1 mb-2">
            <b>H&S Completion Date:</b>
            <span v-if="getVisitHealthAndSafetyDetails && getVisitHealthAndSafetyDetails.createdAt">
              {{ getFormattedDate(getVisitHealthAndSafetyDetails.createdAt) }}
            </span>
            <span v-else>---</span>
          </div>
          <div class="pb-1 mb-2">
            <b>Job Address:</b>
            <span v-if="getVisitHealthAndSafetyDetails && getVisitHealthAndSafetyDetails.jobAddress">
              {{ getVisitHealthAndSafetyDetails.jobAddress }}
            </span>
            <span v-else>---</span>
          </div>
        </div>
        <div class="grey lighten-4 pa-2">
          <h3 class="mb-2 primary--text pt-2">Appliance Checks</h3>
          <div v-if="getBoilerBreakdownQA">
            <div class="mt-1">
              <b>{{ getBoilerBreakdownQA.modelQuestion }}:</b>
              <span v-if="getBoilerBreakdownQA.modelAnswer">
                {{ getBoilerBreakdownQA.modelAnswer }}
              </span>
              <span v-else>-</span>
            </div>
            <div class="mt-1">
              <b>{{ getBoilerBreakdownQA.makeQuestion }}:</b>
              <span v-if="getBoilerBreakdownQA.makeAnswer">
                {{ getBoilerBreakdownQA.makeAnswer }}
              </span>
              <span v-else>-</span>
            </div>
            <div class="mt-1">
              <b>{{ getBoilerBreakdownQA.gcNumberQuestion }}:</b>
              <span v-if="getBoilerBreakdownQA.gcNumberAnswer">
                {{ getBoilerBreakdownQA.gcNumberAnswer }}
              </span>
              <span v-else>-</span>
            </div>
            <div class="mt-1">
              <b>{{ getBoilerBreakdownQA.gasSafetyDetails.serialNumberQuestion }}:</b>
              <span v-if="getBoilerBreakdownQA.gasSafetyDetails.serialNumberAnswer">
                {{ getBoilerBreakdownQA.gasSafetyDetails.serialNumberAnswer }}
              </span>
              <span v-else>-</span>
            </div>
            <div class="mt-1">
              <b>{{ getBoilerBreakdownQA.installationYearQuestion }}:</b>
              <span v-if="getBoilerBreakdownQA.installationYearAnswer">
                {{ getBoilerBreakdownQA.installationYearAnswer }}
              </span>
              <span v-else>-</span>
            </div>
            <div class="mt-1">
              <b>{{ getBoilerBreakdownQA.yearOfLastServiceQuestion }}:</b>
              <span v-if="getBoilerBreakdownQA.yearOfLastServiceAnswer">
                {{ getBoilerBreakdownQA.yearOfLastServiceAnswer }}
              </span>
              <span v-else>-</span>
            </div>
            <template v-if="getBoilerBreakdownQA.gasSafetyDetails">
              <div class="mt-1">
                <b>{{ getBoilerBreakdownQA.gasSafetyDetails.locationConditionQuestion }}:</b>
                <span v-if="getBoilerBreakdownQA.gasSafetyDetails.locationOfApplianceAnswer">
                  {{ getBoilerBreakdownQA.gasSafetyDetails.locationOfApplianceAnswer }}
                </span>
                <span v-else>-</span>
              </div>
              <div class="mt-1">
                <b>{{ getBoilerBreakdownQA.gasSafetyDetails.applianceTypeQuestion }}:</b>
                <span v-if="getBoilerBreakdownQA.gasSafetyDetails.applianceTypeAnswer">
                  {{ getBoilerBreakdownQA.gasSafetyDetails.applianceTypeAnswer }}
                </span>
                <span v-else>-</span>
              </div>
            </template>
          </div>
          <div v-for="(list, index) in gasSafetyQuestionReportList" :key="index">
            <div v-if="list.values.length > 0" class="pt-2">
              <v-divider />
              <h3 class="mb-1 primary--text pt-4">{{ list.header }}</h3>
              <div v-for="(qaItem, index) in list.values" :key="index">
                <div v-if="qaItem.question" class="pt-3">
                  <b>Que:</b>
                  {{ qaItem.question }}
                </div>
                <div v-if="qaItem.question">
                  <b>Ans:</b>
                  <span>{{ qaItem.answer ? qaItem.answer : '--' }}</span>
                </div>
                <div v-if="qaItem.description">
                  <b>Desc:</b>
                  <span>{{ qaItem.description }}</span>
                </div>
                <div v-if="qaItem.imageId && qaItem.imageUrl" class="pb-2">
                  <b>Img:</b>
                  <span>-</span>
                </div>
                <div v-if="qaItem.imageId && qaItem.imageUrl">
                  <img
                    :src="qaItem.imageUrl"
                    class="profile-img"
                    style="width: auto; height: auto; max-width: 500px; max-height: 500px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'
import jsPDF from 'jspdf'
import Emergency from '../models/Emergency'
import Job from '../models/Job'
import storeGetters from '../storeGetters'
import VisitHealthAndSafetyProcess from '../models/claim/VisitHealthAndSafetyProcessModel'
import EngineerVisitDetail from '../models/claim/EngineerVisitDetailModel'
import PictureUploadModel from '../models/claim/PictureUploadModel'
import BoilerBreakdownQA from '../models/claim/BoilerBreakdownQA'
import PhotosController from '@/api/photos-controller'
import { imageExists } from '@/common/images'
import DocumentController from '@/api/documentController'

@Component
export default class GasSafetyQAReport extends Vue {
  @Prop() private boilerBreakdownQA: BoilerBreakdownQA
  @Prop() private healthSafetyQA: VisitHealthAndSafetyProcess | undefined
  @Prop() private engineerVisitDetail: EngineerVisitDetail
  @Prop() private jobId: string
  @Prop() private itemId: string
  @Prop() private emergencyId: string
  @Prop() private visitEmergencyDetails: Emergency
  @Prop() private engineerName: string
  @Prop({ default: true }) private fromContractorDetailsView: boolean
  @Prop() private picturesUpload: PictureUploadModel[]

  private marginsForPdf = { top: 70, bottom: 40, left: 30, width: 550 }
  private showGasSafetyReportDialog = false
  private generatePDFInProcess = false
  private gasSafetyQuestionReportList: Array<{
    header: string
    values: Array<{
      question: string
      answer: string | number
      description?: string
      imageId?: string
      imageUrl?: string
    }>
  }> = []

  public generateReport(id = 'gasSafety', desc = 'Gas Safety') {
    this.generatePDFInProcess = true
    const pdf = new jsPDF('p', 'pt', 'a4')
    // set font size
    pdf.setFontSize(18)
    // generate pdf from html
    pdf.fromHTML(
      document.getElementById(id),
      this.marginsForPdf.left,
      this.marginsForPdf.top,
      { width: this.marginsForPdf.width },
      (dispose) => {
        // set header and footer
        this.headerFooterFormatting(pdf, pdf.internal.getNumberOfPages(), desc)
        // hide loader
        this.generatePDFInProcess = false
        // generate pdf
        pdf.save(
          id +
            '_' +
            (this.getEngineerVisitDetail && this.getEngineerVisitDetail.id
              ? this.getEngineerVisitDetail.id
              : this.jobId)
        )
      },
      this.marginsForPdf
    )
  }

  private get getEngineerVisitDetail(): EngineerVisitDetail {
    if (this.fromContractorDetailsView) {
      return this.engineerVisitDetail
    } else {
      return storeGetters.getEngineerVisitDetail(this.jobId, this.itemId)
    }
  }

  private get items(): PictureUploadModel[] {
    if (this.picturesUpload && this.picturesUpload.length > 0) {
      return this.picturesUpload.filter((x) => {
        return x.forEmergencyDetailId === this.getEngineerVisitDetail.forEmergencyDetailId
      })
    }
    if (this.jobId && this.emergency) {
      return storeGetters.getClaimPicturesById(this.jobId, this.getEngineerVisitDetail.forEmergencyDetailId)
    }
    return []
  }

  private async mounted() {
    this.ShowGasSafetyCheckDetails()
  }

  private async ShowGasSafetyCheckDetails() {
    if (this.getBoilerBreakdownQA && this.gasSafetyQuestionReportList && this.gasSafetyQuestionReportList.length > 0) {
      const arr: any[] = []
      const questionListWithImage = this.gasSafetyQuestionReportList
        .reduce((sum, lst) => sum.concat(lst.values), arr)
        .filter((x) => x.imageId)
      let count = 0
      if (questionListWithImage.length > 0) {
        for (const question of questionListWithImage) {
          const exists = await imageExists(question.imageUrl)
          if (!exists) {
            const getEngineerBeforeJobData: PictureUploadModel | undefined = this.items.find(
              (a: PictureUploadModel) => a.id === question.imageId
            )
            if (getEngineerBeforeJobData) {
              if (this.picturesUpload && this.picturesUpload.length > 0) {
                question.imageUrl = await PhotosController.GetThumbnailUrl(
                  getEngineerBeforeJobData.jobId,
                  getEngineerBeforeJobData.id
                )
              } else {
                const fileUrl = getEngineerBeforeJobData.fileURL.substring(
                  getEngineerBeforeJobData.fileURL.indexOf(this.jobId),
                  getEngineerBeforeJobData.fileURL.indexOf('?')
                )
                question.imageUrl = await DocumentController.GetSASTokenForRecordingUrl(fileUrl)
              }
            }
            // save imageUrl
            const imageUrlFor = this.gasSafetyQuestionReportList
              .reduce((sum, lst) => sum.concat(lst.values), arr)
              .find((x) => x.imageId === question.imageId)
            imageUrlFor.imageUrl = question.imageUrl
            count++
            if (count === questionListWithImage.length) {
              this.showGasSafetyReportDialog = true
            }
          } else {
            count++
            if (count === questionListWithImage.length) {
              this.showGasSafetyReportDialog = true
            }
          }
        }
      } else {
        this.showGasSafetyReportDialog = true
      }
    }
  }

  private get getBoilerBreakdownQA(): BoilerBreakdownQA | null {
    let details = this.boilerBreakdownQA
    if (!details && this.getEngineerVisitDetail) {
      details = storeGetters.getBoilerBreakdownQA(this.jobId, this.getEngineerVisitDetail.id)
    }
    if (details && details.gasSafetyDetails) {
      const list = details.gasSafetyDetails
      this.gasSafetyQuestionReportList.push({
        header: 'Electrical Checks',
        values: [
          {
            question: list.hasWorkedOnApplianceBeforeQuestion,
            answer: list.hasWorkedOnApplianceBeforeAnswer ? 'Yes' : 'No',
          },
          {
            question: list.hasVisualRiskAssessmentQuestion,
            answer: list.hasVisualRiskAssessmentAnswer,
          },
          { question: list.fuseRatingQuestion, answer: list.fuseRatingAnswer },
          {
            question: list.preliminaryElectricalChecksQuestion,
            answer: list.preliminaryElectricalCheckAnswer,
            description: list.preliminaryElectricalCheckDescription,
          },
          {
            question: list.applianceConditionQuestion,
            answer: list.applianceConditionAnswer,
            description: list.applianceConditionDescription,
          },
          { question: list.flueTypeQuestion, answer: list.flueTypeAnswer },
          {
            question: list.chimneyConditionQuestion,
            answer: list.chimneyConditionAnswer,
          },
          {
            question: list.fluePerformanceChecksQuestion,
            answer: list.fluePerformanceChecksAnswer,
          },
          {
            question: list.flueOperationalChecksQuestion,
            answer: list.flueOperationalChecksAnswer,
          },
          {
            question: list.ventilationSatisfactoryQuestion,
            answer: list.ventilationSatisfactoryAnswer,
          },
          {
            question: list.workingPressureQuestion,
            answer: list.workingPressureAnswer,
          },
          {
            question: list.operatingPressureQuestion,
            answer: list.operatingPressureAnswer,
          },
          { question: list.heatInputQuestion, answer: list.heatInputAnswer },
          {
            question: list.safetyDevicesTestedQuestion,
            answer: list.safetyDevicesTestedAnswer,
          },
          {
            question: list.disturbedPointsTestedQuestion,
            answer: list.disturbedPointsTestedAnswer,
          },
          {
            question: list.caseSealsInspectedQuestion,
            answer: list.caseSealsInspectedAnswer,
          },
        ],
      })
      this.gasSafetyQuestionReportList.push({
        header: 'Combustion Analysis',
        values: [
          {
            question: list.maximumCarbonMonoxideQuestion,
            answer: list.maximumCarbonMonoxideAnswer,
          },
          {
            question: list.maximumCarbonDioxideQuestion,
            answer: list.maximumCarbonDioxideAnswer,
          },
          {
            question: list.maximumRatioQuestion,
            answer: list.maximumRatioAnswer,
          },
          {
            question: list.maximumOxygenQuestion,
            answer: list.maximumOxygenAnswer,
          },
          {
            question: list.minimumCarbonMonoxideQuestion,
            answer: list.minimumCarbonMonoxideAnswer,
          },
          {
            question: list.minimumCarbonDioxideQuestion,
            answer: list.minimumCarbonDioxideAnswer,
          },
          {
            question: list.minimumRatioQuestion,
            answer: list.minimumRatioAnswer,
          },
          {
            question: list.minimumOxygenQuestion,
            answer: list.minimumOxygenAnswer,
          },
          {
            question: list.isFGATestPointRefittedQuestion,
            answer: list.isFGATestPointRefittedAnswer,
            imageId: list.fgaTestPointRefittedPictureId,
          },
        ],
      })
      this.gasSafetyQuestionReportList.push({
        header: 'Gas Meter Checks',
        values: [
          {
            question: list.hasEarthContinuityBondingQuestion,
            answer: list.hasEarthContinuityBondingAnswer,
            description: list.hasEarthContinuityBondingDescription,
          },
          {
            question: list.doesECVFallsOffQuestion,
            answer: list.doesECVFallsOffAnswer,
            description: list.doesECVFallsOffDescription,
          },
          {
            question: list.areLabelsCorrectQuestion,
            answer: list.areLabelsCorrectAnswer,
            description: list.areLabelsCorrectDescription,
          },
          {
            question: list.tightnessTestQuestion,
            answer: list.tightnessTestAnswer,
            description: list.tightnessTestDescription,
          },
        ],
      })
      this.gasSafetyQuestionReportList.push({
        header: 'Gas Safety',
        values: [
          {
            question: list.isGasInstallationSafeQuestion,
            answer: list.isGasInstallationSafeAnswer,
          },
          {
            question: list.areWarningLabelsAttachedQuestion,
            answer: list.areWarningLabelsAttachedAnswer,
            imageId: list.warningLabelsPictureId,
          },
          {
            question: list.isWarningNoticeIssuedQuestion,
            answer: list.isWarningNoticeIssuedAnswer,
          },
        ],
      })
    }
    return details
  }

  private headerFooterFormatting(doc, totalPages, desc) {
    for (let i = totalPages; i >= 1; i--) {
      doc.setPage(i)
      this.header(doc, desc)
      this.footer(doc, i, totalPages)
      doc.page++
    }
  }

  private header(doc, desc) {
    // set style for header
    doc.setFontSize(20)
    doc.setTextColor('#009688')
    doc.setFontStyle('normal')
    doc.text(desc + ' Report', this.marginsForPdf.left + 180, 30)
    doc.setLineCap(2)
    doc.line(3, 50, this.marginsForPdf.width + 43, 50) // horizontal line
  }

  private footer(doc, pageNumber, totalPages) {
    // set style for footer
    const str = 'Page ' + pageNumber + ' of ' + totalPages
    doc.setLineCap(1)
    doc.setFontSize(10)
    doc.text(str, this.marginsForPdf.left, doc.internal.pageSize.height - 20)
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get getVisitHealthAndSafetyDetails(): VisitHealthAndSafetyProcess | undefined {
    if (this.fromContractorDetailsView) {
      return this.healthSafetyQA
    } else {
      const visitHealthAndSafetyProcess = storeGetters.getVisitHealthAndSafetyProcessForEmergency(
        this.jobId,
        this.getEngineerVisitDetail.id
      )
      if (visitHealthAndSafetyProcess) {
        return visitHealthAndSafetyProcess
      }
      return undefined
    }
  }

  public get emergency(): Emergency | null {
    if (this.visitEmergencyDetails) {
      return this.visitEmergencyDetails
    } else {
      const jobDetails: Job | null = storeGetters.getJob(this.jobId)
      if (this.emergencyId && jobDetails && jobDetails.emergencies) {
        const item = jobDetails.emergencies.find((e) => e.id === this.emergencyId)
        if (item) {
          return item
        }
      }
    }
    return null
  }
}
</script>

<style scoped>
img.profile-img {
  width: 80px;
}
img.profile-img {
  width: 80px;
  object-fit: contain;
  height: 100px;
}
</style>
