<template>
  <v-container grid-list-md>
    <v-layout class="user-details" row wrap>
      <v-flex xs6>
        <v-text-field
          v-model="user.firstName"
          v-validate="'required'"
          name="firstName"
          label="First Name"
          class="required"
          :error-messages="errors.first('firstName')"
          :disabled="!relativePermissions.canChangeAccount"
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="user.lastName"
          v-validate="'required'"
          name="lastName"
          label="Last Name"
          class="required"
          :error-messages="errors.first('lastName')"
          :disabled="!relativePermissions.canChangeAccount"
        />
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="user.email"
          v-validate="'required|email|unique'"
          name="email"
          type="email"
          label="Email"
          class="required"
          :error-messages="errors.first('email')"
          :disabled="!relativePermissions.canChangeAccount"
        />
      </v-flex>
      <v-flex xs12>
        <v-select
          v-model="user.role"
          name="role"
          label="Role"
          :items="roles"
          :loading="loadingRoles"
          item-value="id"
          item-text="name"
          :disabled="!canChangeRole"
        />
      </v-flex>

      <v-flex v-if="showTeamField" xs12>
        <div class="newindicator-chip">
          <v-combobox
            v-model="user.team"
            name="Team"
            label="Team"
            maxlength="50"
            :items="teams"
            :loading="loadingTeams"
            item-value="id"
            item-text="name"
            :return-object="false"
            :disabled="!relativePermissions.canChangeTeam"
          ></v-combobox>
          <v-chip color="secondary" class="white--text" small disabled :value="isNewTeam">New</v-chip>
        </div>
      </v-flex>
    </v-layout>

    <v-layout class="job-types" row wrap>
      <v-flex xs12>
        <v-label>Job Types</v-label>
      </v-flex>
      <v-flex v-for="jobType in jobTypes" :key="jobType.id" sm4>
        <v-checkbox
          v-model="user.jobTypes"
          :value="jobType.id"
          :label="jobType.name"
          color="primary"
          :disabled="!relativePermissions.canChangeJobTypes"
        ></v-checkbox>
      </v-flex>
    </v-layout>

    <v-layout class="switches" row wrap>
      <v-flex xs6>
        <v-switch
          v-model="user.canAcceptEmergencies"
          color="primary"
          small
          label="Can Accept/Reject Emergencies"
        ></v-switch>
      </v-flex>
      <v-flex xs6>
        <v-switch v-model="user.canAcceptPayments" color="primary" small label="Can Action Customer To Pay"></v-switch>
      </v-flex>
      <v-flex xs6>
        <v-switch v-model="user.worksForThirdParty" color="primary" small label="Works For Third Party"></v-switch>
      </v-flex>
    </v-layout>

    <v-layout class="dashboards" row wrap>
      <v-flex xs12>
        <v-label>Dashboards</v-label>
      </v-flex>
      <v-flex xs12>
        <v-checkbox
          v-model="allDashboardsSelected"
          label="Select All"
          hide-details
          name="select-all-checkbox"
          color="primary"
          :disabled="!relativePermissions.canChangeDashboards"
        ></v-checkbox>
      </v-flex>
      <v-flex v-for="dashboard in dashboards" :key="dashboard.id" class="dashboards__dashboard" sm4>
        <v-checkbox
          v-model="user.dashboards"
          :value="dashboard.id"
          :label="dashboard.name"
          :disabled="!relativePermissions.canChangeDashboards"
          color="primary"
        ></v-checkbox>
      </v-flex>
      <v-flex xs12>
        <v-select
          v-model="user.defaultDashboard"
          label="Default Dashboard"
          item-text="name"
          item-value="id"
          :items="dashboards"
          :disabled="!relativePermissions.canChangeDashboards"
        />
      </v-flex>
    </v-layout>

    <v-layout class="skills" row wrap>
      <v-flex xs12>
        <v-label>Skill Matrix</v-label>
      </v-flex>
      <v-flex v-for="skill of skills" :key="skill.key" class="skills__skill" xs6>
        <label class="skills__label">{{ skill.display }}</label>
        <star-rating
          v-model="user.skills[skill.key]"
          :star-size="18"
          :max-rating="5"
          active-color="#e7711b"
          :show-rating="false"
          :clearable="true"
          :read-only="!relativePermissions.canChangeSkills"
        ></star-rating>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import AccountsController from '@/api/accounts-controller'
import DashboardListItemModel from '@/models/dashboards/dashboard-list-item-model'
import { UserRole } from '@/models/user/UserModel'
import UserDetailModel from '@/models/users/user-detail-model'
import UserRoleListItemModel from '@/models/users/user-role-list-item-model'
import UserTeamListItemModel from '@/models/users/user-team-list-item-model'
import { Validator } from 'vee-validate'
import { Component, Inject, Vue } from 'vue-property-decorator'
import StarRating from 'vue-star-rating'
import debounce from 'lodash.debounce'
import { Skill, SkillModelKeys } from '@/models/user/SkillModel'
import RelativeUserPermissionsModel from '@/models/users/relative-user-permissions-model'

@Component({
  components: { StarRating },
})
export default class EditUser extends Vue {
  @Inject('validator') public readonly $validator!: Validator

  public get user(): UserDetailModel {
    return this.$store.getters['usersModule/selectedUser']
  }

  public get relativePermissions(): RelativeUserPermissionsModel {
    return this.$store.getters['usersModule/relativeUserPermissions']
  }

  public get teams(): UserTeamListItemModel[] {
    return this.$store.getters['usersModule/teams']
  }

  public get roles(): UserRoleListItemModel[] {
    const allRoles: UserRoleListItemModel[] = this.$store.getters['usersModule/roles']
    const allowedRoles = allRoles.filter(
      (r) => this.user.role === r.id || this.relativePermissions.allowedRoles.includes(r.id)
    )
    return allowedRoles
  }

  public get dashboards(): DashboardListItemModel[] {
    return this.$store.getters['dashboardsModule/dashboards']
  }

  public get skills(): Skill[] {
    return SkillModelKeys
  }

  public get jobTypes(): { id: string; name: string }[] {
    return [
      { id: 'HE', name: 'Home Emergency' },
      { id: 'SI', name: 'Site Investigation' },
      { id: 'US', name: 'Underground Services' },
    ]
  }

  public get loadingTeams(): string[] {
    return this.$store.getters['usersModule/loadingTeams']
  }

  public get loadingRoles(): string[] {
    return this.$store.getters['usersModule/loadingRoles']
  }

  public get allDashboardsSelected(): boolean {
    return this.user.dashboards.length === this.dashboards.length
  }

  public set allDashboardsSelected(value: boolean) {
    this.user.dashboards = value ? this.dashboards.map((d) => d.id) : []
  }

  public get isNewTeam(): boolean {
    return !this.teams.some((t) => t.id === this.user.team)
  }

  public get showTeamField(): boolean {
    return this.userHasRole('IncidentManager', 'Supervisor', 'Finance')
  }

  public get canChangeRole(): boolean {
    return !!this.relativePermissions.allowedRoles.length
  }

  private userHasRole(...roles: UserRole[]): boolean {
    return roles.some((r) => this.user.role === r)
  }

  private async validateEmailAsync(email: string): Promise<boolean> {
    try {
      const valid = await AccountsController.CheckEmailAsync(email)
      return !valid.foundUser || valid.userId === this.user.id
    } catch (err) {
      return false
    }
  }

  public created(): void {
    this.$validator.extend('unique', {
      getMessage: (field: string) => `${field} is already in use.`,
      validate: debounce(this.validateEmailAsync.bind(this), 1000),
    })

    this.$store.dispatch('usersModule/retrieveTeams')
    this.$store.dispatch('usersModule/retrieveRoles')
    this.$store.dispatch('dashboardsModule/retrieveDashboards')
  }
}
</script>

<style lang="scss">
.dashboards__dashboard {
  .v-messages {
    min-height: 0;
  }
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}

.skills__skill {
  display: flex;
}

.skills__label {
  flex-grow: 1;
  font-weight: bold;
}
</style>
