import moment from 'moment'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'

export default class AddCustomerToPay {
  public id: string
  public jobId: string
  public createdAt: moment.Moment | null
  public cardHolderName: string
  public addressLine1: string
  public addressLine2: string
  public city: string
  public state: string
  public country: string
  public postcode: string
  public cardBrand: string
  public cardLastFourDigit: number
  public statementDescription: string
  public amount: number
  public token: string
  public chargeId: string
  public emergencyId: string
  public escalateCTPResponse: EscalateCTPResponseModel | null
  public cardHolderEmail: string
  public paymentIntentId: string
  public transactionDate: moment.Moment | null
  public isTransactionCompleted: boolean
  public balanceTransactionId: string
  public paymentType: string
}
