<template>
  <span v-if="job.sIJobDetail.jobPackage">Total Price: £{{ job.sIJobDetail.jobPackage.totalPrice }}</span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
@Component
export default class JobPackageFooter extends TimeLineItemBase {}
</script>
