export default class EmergencyCoverageModel {
  public id: number
  public policyId: number
  public emergencyDetailId: number
  public startMonth: number
  public endMonth: number
  public coverageDetails: string
  public exclusions: string
  public isMarkedAsCTP: boolean
}
