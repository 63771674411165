<template>
  <v-card v-if="customer">
    <v-toolbar card dark color="primary">
      <v-toolbar-title>VIP Customers</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="Close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text class="pa-3 scroll-content-dialog">
      <form>
        <v-layout row wrap>
          <v-flex sm12>
            <v-combobox
              v-model.trim="customer.title"
              label="Title"
              maxlength="20"
              return-object
              :items="clientTitleList"
            ></v-combobox>
          </v-flex>
          <v-flex sm12>
            <v-text-field
              v-model.trim="customer.firstName"
              v-validate="'required'"
              label="First Name"
              class="required"
              required
              data-vv-scope="frmVIPCustomer"
              data-vv-name="First Name"
              :error-messages="errors.collect('First Name')"
            />
          </v-flex>
          <v-flex sm12 mb-3>
            <v-text-field
              v-model.trim="customer.lastName"
              v-validate="'required'"
              label="Last Name"
              class="required"
              required
              data-vv-scope="frmVIPCustomer"
              data-vv-name="Last Name"
              :error-messages="errors.collect('Last Name')"
            />
          </v-flex>
          <v-flex xs12 sm6 pr-2>
            <PhoneNumber
              ref="customerHomeNumber"
              :passed-phone-number="customer.homePhone"
              :is-validation-required="true"
              class="vip-customer-number"
              @phoneNumberUpdated="homePhoneNumberUpdated"
            />
          </v-flex>
          <v-flex xs12 sm6 pl-2>
            <PhoneNumber
              ref="customerMobileNumber"
              :passed-phone-number="customer.mobilePhone"
              :is-validation-required="true"
              class="vip-customer-number"
              @phoneNumberUpdated="mobilePhoneNumberUpdated"
            />
          </v-flex>
        </v-layout>
      </form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn @click="Close">Close</v-btn>
      <v-btn :disabled="isLoading" :loading="isLoading" color="primary" class="mr-0" @click="Save">submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import VIPCustomerController from '@/api/vipCustomerController'
import VIPCustomerModel from '@/models/vipcustomer/VIPCustomerModel'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import eventBus from '@/common/bus'

@Component({
  components: { PhoneNumber },
})
export default class VIPCustomer extends Vue {
  @Prop() private customerItem: VIPCustomerModel | null

  private isLoading = false
  private clientTitleList: string[] = ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms']
  private get customer(): VIPCustomerModel | null {
    return Object.assign({}, this.customerItem)
  }

  private Close() {
    this.$validator.errors.items = []
    this.$emit('OnCustomerDialogClose')
  }

  private Save() {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          VIPCustomerController.SaveVIPCustomer(this.customer)
            .then((res: number | null) => {
              if (res !== null) {
                let customerDetail: VIPCustomerModel = new VIPCustomerModel()
                customerDetail = Object.assign({}, this.customer)
                customerDetail.id = res
                this.$emit('OnCustomerSave', customerDetail)
              }
              this.isLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error VIP customer detail, please try again', true)
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validate(): Promise<boolean> {
    let result = true
    const customerFormValidation: boolean = await this.$validator.validateAll('frmVIPCustomer')

    let homeNumberValidation = true
    const homeNumberValidationSub: any = this.$refs.customerHomeNumber as PhoneNumber
    homeNumberValidation = (await homeNumberValidationSub.$validator.validateAll()) && homeNumberValidationSub.isValid

    let mobileNumberValidation = true
    const mobileNumberValidationSub: any = this.$refs.customerMobileNumber as PhoneNumber
    mobileNumberValidation =
      (await mobileNumberValidationSub.$validator.validateAll()) && mobileNumberValidationSub.isValid

    // set focus to non validate field
    if (!customerFormValidation || !homeNumberValidation || !mobileNumberValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = customerFormValidation && homeNumberValidation && mobileNumberValidation
    }

    return result
  }

  private homePhoneNumberUpdated(phoneNumber: string): void {
    if (this.customer) {
      this.customer.homePhone = phoneNumber
    }
  }

  private mobilePhoneNumberUpdated(phoneNumber: string): void {
    if (this.customer) {
      this.customer.mobilePhone = phoneNumber
    }
  }
}
</script>

<style scoped>
/*PhoneNumber Component*/
.internal-phone-number.vip-customer-number {
  padding-left: 0px;
  position: relative;
}
.internal-phone-number.validation {
  position: absolute;
  right: 7px;
  top: 21px;
}
</style>
