<template>
  <div>
    <v-icon
      v-show="showFullscreenControl && !showFullScreenMap"
      ref="fullScreenIcon"
      class="fullScreenIcon"
      :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
      @click="toogleFullScreenMap"
    >
      fullscreen
    </v-icon>
    <v-icon
      v-show="showFullscreenControl && showFullScreenMap"
      ref="fullScreenIcon"
      class="fullScreenIcon"
      :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
      @click="toogleFullScreenMap"
    >
      fullscreen_exit
    </v-icon>
    <div :id="mapId" ref="mapView" class="google-map"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import GoogleMap from '@/components/GoogleMap.vue'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'
declare let google: any

@Component
export default class NetworkManagerDashboardMap extends GoogleMap {
  @Prop() private mapId: string
  @Prop() private zoomLevel: number
  @Prop() private coverage: any[]

  private bounds: any
  private marker: any = null
  private showFullScreenMap = false
  private showFullscreenControl = true
  private polygonList: any[] = []
  private circleList: any[] = []

  public clearPlottedPolygon() {
    this.polygonList.forEach((polygon) => {
      polygon.setMap(null)
      google.maps.event.clearInstanceListeners(polygon)
    })
    this.circleList.forEach((circle) => {
      circle.setMap(null)
      google.maps.event.clearInstanceListeners(circle)
    })
    this.polygonList = []
    this.circleList = []
  }

  private async loadAreas() {
    await new Promise<void>((r) => this.resultMap.data.loadGeoJson('/mapdata/areas.json', undefined, () => r()))
    this.resultMap.data.setStyle({
      fillColor: 'transparent',
      opacity: 0,
      strokeWeight: 1.2,
    })
    this.resultMap.data.addListener('click', this.featureClickedHandler)

    // Show Tooltip
    this.resultMap.data.addListener('mouseover', (event) => {
      if (event.feature.getProperty('o')) {
        const tooltipData: any = {}
        tooltipData.postCode = event.feature.getProperty('o')
        const coverage = this.coverage.filter(
          (c) =>
            c.districtPostcode &&
            (c.districtPostcode.split(' - ')[0] === event.feature.getProperty('o') ||
              c.districtPostcode === event.feature.getProperty('o'))
        )
        const contractors: any = []
        coverage.forEach((c) => {
          contractors.push(c.contractors)
        })
        tooltipData.contractors = contractors
        this.showTooltip(event, tooltipData)
      }
    })
    this.resultMap.data.addListener('mousemove', (e) => {
      this.moveTooltip(e)
    })
    this.resultMap.data.addListener('mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private async created() {
    await this.fillOutcodesByArea()
  }

  private async mounted() {
    try {
      this.initMap()
    } catch (ex) {
      eventBus.$emit('validationErrorHandler', 'Error rendering map. Please try again')
    }
  }

  private destroyed() {
    this.clearPlottedPolygon()
    this.resultMap = null
  }

  private async initMap() {
    this.resultMap = this.initGoogleMap(this.mapId, this.zoomLevel, false)
    await this.loadAreas()
    if (this.coverage && this.coverage.length > 0) {
      this.bindCoverageCoordinates()
    }
  }

  @Watch('coverage')
  private onContractorCoverageChanged(newValue: any) {
    if (
      this.coverage.filter((c) => c.location && (c.location.type === 'Polygon' || c.location.type === 'Point')).length >
      0
    ) {
      this.bounds = new google.maps.LatLngBounds()
    }
    this.resultMap.setZoom(5.5)
    this.bindCoverageCoordinates()
  }

  private bindCoverageCoordinates() {
    const self = this
    self.internalAreas = []
    if (self.coverage && self.coverage.length > 0) {
      for (const item of self.coverage) {
        if (item.postcode) {
          self.bindOutcodeWithArea(item.postcode, item.districtPostcode, item.contractors)
        } else if (item.location && item.location.type === 'Polygon') {
          const arrCoords: any[] = []
          item.location.coordinates.forEach((coordinate) => {
            coordinate.forEach((point) => {
              const lat = point[0]
              const long = point[1]
              arrCoords.push(new google.maps.LatLng(lat, long))
              self.bounds.extend(new google.maps.LatLng(lat, long))
            })
          })

          const polygon = new google.maps.Polygon({
            geodesic: true,
            editable: false,
            paths: arrCoords,
            strokeColor: '#1B5E20',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#1B5E20',
            suppressUndo: true,
          })
          polygon.id = item.id
          polygon.setMap(self.resultMap)
          self.bindPolygonListenerEvent(polygon, item.contractors)
          this.polygonList.push(polygon)
        } else if (item.location && item.location.type === 'Point') {
          const latitude: number = item.location.coordinates[0]
          const longitude: number = item.location.coordinates[1]

          self.marker = new google.maps.Marker({
            position: new google.maps.LatLng(latitude, longitude),
            map: this.resultMap,
            visible: false,
          })
          const circleCoords: any[] = []
          const circleOptions = {
            strokeColor: '#1B5E20',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#1B5E20',
            map: self.resultMap,
            radius: item.radius,
          }
          const circle = new google.maps.Circle(circleOptions)
          circle.id = item.id
          circle.bindTo('center', self.marker, 'position')
          self.bindCircleListenerEvent(circle, item.contractors)
          this.circleList.push(circle)
        }
      }
      if (
        self.coverage.filter((c) => c.location && (c.location.type === 'Polygon' || c.location.type === 'Point'))
          .length > 0
      ) {
        self.resultMap.fitBounds(self.bounds)
      }
      google.maps.event.addListenerOnce(this.resultMap, 'idle', () => {
        this.$emit('setMapWaitTime', false)
      })
      this.updateRenderedAreas()
    } else {
      this.resultMap.setCenter(new google.maps.LatLng(54.5, -3))
    }
    this.updateRenderedAreas()
  }

  private bindPolygonListenerEvent(element, contractors) {
    // Show Tooltip
    google.maps.event.addListener(element, 'mouseover', (e) => {
      const tooltipData: any = {}
      tooltipData.postCode = ''
      tooltipData.contractors = contractors
      if (tooltipData.contractors.length > 0) {
        this.showTooltip(e, tooltipData)
      }
    })
    google.maps.event.addListener(element, 'mousemove', (e) => {
      this.moveTooltip(e)
    })
    google.maps.event.addListener(element, 'mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private bindCircleListenerEvent(circle, contractors) {
    // Show Tooltip
    google.maps.event.addListener(circle, 'mouseover', (e) => {
      const tooltipData: any = {}
      tooltipData.postCode = ''
      tooltipData.contractors = contractors
      if (tooltipData.contractors.length > 0) {
        this.showTooltip(e, tooltipData)
      }
    })
    google.maps.event.addListener(circle, 'mousemove', (e) => {
      this.moveTooltip(e)
    })
    google.maps.event.addListener(circle, 'mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private bindOutcodeWithArea(postcode: string | null, districtPostcode: string, contractors: string[]) {
    const outcodesWithArea = this.outcodesByArea.find((e) => postcode === e.areaCode)
    if (outcodesWithArea) {
      const area = this.internalAreas.find((a) => a.AreaCode === postcode)
      if (area) {
        area.OutCodes.push(districtPostcode.split(' - ')[0])
      } else {
        const areaObj: any = { AreaCode: postcode, OutCodes: [] }
        const outcodes: string[] = []
        outcodes.push(districtPostcode.split(' - ')[0])
        areaObj.OutCodes = outcodes
        this.internalAreas.push(areaObj)
      }
    }
  }

  private showTooltip(event, data) {
    if (!this.tooltipObject && event) {
      // create the tooltip object
      this.tooltipObject = document.createElement('div')
      this.tooltipObject.style.width = 'auto'
      this.tooltipObject.style.maxWidth = '200px'
      this.tooltipObject.style.background = 'white'
      this.tooltipObject.style.borderRadius = '5px'
      this.tooltipObject.style.padding = '5px'
      this.tooltipObject.style.display = 'inline-block'
      this.tooltipObject.style.lineHeight = '1.5'
      this.tooltipObject.style.fontFamily = 'Arial,Helvetica'
      this.tooltipObject.style.zIndex = 3
      if (data.postCode !== null && data.postCode !== '') {
        this.tooltipObject.innerHTML =
          "<b style='color:#4c4c4c'>" + data.postCode + '</b>' + '<br />' + data.contractors.join(', ')
      } else {
        this.tooltipObject.innerHTML = data.contractors.join(', ')
      }
      const eventPropNames: any = Object.keys(event)
      if (!this.coordPropName) {
        // discover the name of the prop with MouseEvent
        for (const i of eventPropNames) {
          const name = i
          if (event[name] instanceof MouseEvent) {
            this.coordPropName = name
            break
          }
        }
      }
      if (this.coordPropName !== null) {
        // position it
        this.tooltipObject.style.position = 'absolute'
        if (event[this.coordPropName] !== undefined) {
          this.tooltipObject.style.top = event[this.coordPropName].clientY + window.scrollY + this.offset.y + 'px'
          this.tooltipObject.style.left = event[this.coordPropName].clientX + window.scrollX + this.offset.x + 'px'
        }
        // add it to the body
        document.body.appendChild(this.tooltipObject)
      }
    }
  }

  private toogleFullScreenMap() {
    this.showFullScreenMap = !this.showFullScreenMap
    this.toggleFullScreenView(this.showFullScreenMap, false)
  }
}
</script>

<style scoped>
.google-map,
.google-map-street-view {
  height: 100%;
  width: 100%;
  max-width: 100%;
  position: relative;
}
.map-view {
  height: 100%;
}
</style>
