<template>
  <div>
    <v-layout wrap>
      <v-flex pr-2 xs6>
        <v-autocomplete
          v-model="value.additionalMaterialRequest"
          v-validate="'required'"
          :items="materialList"
          multiple
          label="Selected Material"
          item-text="name"
          item-value="name"
          required
          data-vv-name="Material Name"
          :error-messages="errors.collect('Material Name')"
          class="required materialList"
          return-object
        ></v-autocomplete>
      </v-flex>
      <v-flex pl-2 xs6>
        <v-text-field
          v-model="value.materialCost"
          v-validate="'required'"
          type="Number"
          name="price"
          label="Price"
          min="1"
          required
          class="required"
          data-vv-name="Material Price"
          :error-messages="errors.collect('Material Price')"
        ></v-text-field>
      </v-flex>
      <v-flex v-if="showAdditionalInfoField()" xs12>
        <v-textarea
          v-model="value.additionalInfo"
          v-validate="'required'"
          label="Additional Information"
          required
          class="required"
          data-vv-name="Additional Information"
          :error-messages="validationMessage('Additional Information')"
          name="AdditionalInfoField"
        ></v-textarea>
      </v-flex>
      <v-flex xs12>
        <v-textarea
          v-model="value.furtherInfo"
          v-validate="'required'"
          name="description"
          label="Further Information"
          required
          class="required"
          data-vv-name="Further Information"
          :error-messages="validationMessage('Further Information')"
        ></v-textarea>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EngineerMaterialRequest from '@/models/claim/engineerRequest/EngineerMaterialRequest'
import MaterialModel from '@/models/material/MaterialModel'

@Component({})
export default class AddMaterialRequest extends Vue {
  @Prop({ default: [] }) private materialList: MaterialModel[]
  @Prop() private value: EngineerMaterialRequest

  @Watch('value')
  private onRequestUpdated() {
    this.$emit('input', this.value)
  }

  private showAdditionalInfoField(): boolean {
    let isAdditionalInfoRequired = false
    if (this.value.additionalMaterialRequest && this.value.additionalMaterialRequest.length > 0) {
      for (const request of this.value.additionalMaterialRequest) {
        const material = this.materialList.find((e) => e.id === request.id && e.isAdditionalInfoRequired)
        if (material) {
          isAdditionalInfoRequired = true
          break
        }
      }
    }
    return isAdditionalInfoRequired
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }
}
</script>
