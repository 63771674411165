export default class ClientTradeRateModel {
  public tradeId: number
  public visit1: number
  public visit2: number
  public visit3: number
  public isContainMaterials: boolean
  public materialUplift: number
  public useFixedRate: boolean
  public fixedRate: number
  public id: number // required for front-end to manage deleted trade

  public constructor() {
    this.visit1 = 0
    this.visit2 = 0
    this.visit3 = 0
    this.materialUplift = 0
    this.fixedRate = 0
  }
}
