<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-btn color="primary" dark style="display: none">Open Dialog</v-btn>
        <v-card>
          <v-card-title class="pa-3">
            <h3>{{ message }}</h3>
          </v-card-title>
          <v-card-actions class="pt-2 px-3 pb-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="onConfirm">
              {{ positiveActionText }}
            </v-btn>
            <v-btn small flat @click="onCancel">{{ negativeActionText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'

type OpenCallbackOptions = {
  message: string
  positiveCallback?: (callbackData: unknown) => void
  negativeCallback?: (callbackData: unknown) => void
  callbackData?: unknown
  positiveText?: string
  negativeText?: string
}

@Component
export default class Confirmation extends Vue {
  private callbackData: unknown
  private positiveCallbackMethod: ((callbackData: unknown) => void) | undefined
  private negativeCallbackMethod: ((callbackData: unknown) => void) | undefined

  public positiveActionText = 'Delete'
  public negativeActionText = 'Cancel'
  public dialog = false
  public message = ''

  public open(
    message: string,
    name: string,
    type: string,
    negativeActionText: string,
    positiveActionText: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    parentComponent: Object,
    positiveCallback: string,
    callbackData: unknown,
    negativeCallback = ''
  ): void {
    this.callbackData = callbackData
    this.positiveActionText = positiveActionText ? positiveActionText : this.positiveActionText
    this.negativeActionText = negativeActionText ? negativeActionText : this.negativeActionText
    this.message = message ? message : 'Do you really want to delete ' + type + (name ? ' : ' + name + '?' : '?')

    this.positiveCallbackMethod = parentComponent && positiveCallback ? parentComponent[positiveCallback] : null
    this.negativeCallbackMethod = parentComponent && negativeCallback ? parentComponent[negativeCallback] : null

    this.dialog = true
  }

  public openCallback({
    message,
    positiveCallback,
    negativeCallback,
    callbackData,
    positiveText = 'Yes',
    negativeText = 'No',
  }: OpenCallbackOptions): void {
    this.message = message
    this.positiveCallbackMethod = positiveCallback
    this.negativeCallbackMethod = negativeCallback
    this.callbackData = callbackData
    this.positiveActionText = positiveText
    this.negativeActionText = negativeText

    this.dialog = true
  }

  created(): void {
    Shared.confirmationPopup = this
  }

  public onConfirm(): void {
    try {
      this.dialog = false
      if (this.positiveCallbackMethod) {
        this.positiveCallbackMethod(this.callbackData)
      } else {
        this.$emit('DialogConfirm', this.callbackData)
      }
    } catch (e) {
      eventBus.$emit('validationErrorHandler', 'Unable to proceed. Please try again.')
    }
  }

  public onCancel(): void {
    try {
      this.dialog = false
      if (this.negativeCallbackMethod) {
        this.negativeCallbackMethod(this.callbackData)
      } else {
        this.$emit('DialogCancel', this.callbackData)
      }
    } catch (e) {
      eventBus.$emit('validationErrorHandler', 'Unable to proceed. Please close the model or refresh the page.')
    }
  }
}
</script>
