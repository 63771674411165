import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import NotificationModel from '@/models/notification/NotificationModel'

export default class NotificationController {
  public static async GetNotification(notificationId: string): Promise<NotificationModel> {
    const res = await CallCentreApi.get<NotificationModel>(
      'Notification/GetNotification?notificationId=' + notificationId
    )
    return res.data
  }

  public static async GetNotifications(): Promise<NotificationModel[]> {
    const res = await CallCentreApi.get<NotificationModel[]>('Notification/GetNotifications')
    return res.status >= 200 && res.status < 300 && res.data ? res.data : []
  }

  public static async MarkNotificationAsReadForUser(notificationId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Notification/MarkNotificationAsReadForUser?notificationId=' + notificationId
    )
    return res.status >= 200 && res.status < 300
  }

  public static async ClearNotificationForUser(notificationId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Notification/ClearNotificationForUser?notificationId=' + notificationId
    )
    return res.status >= 200 && res.status < 300
  }

  public static async ClearAllNotificationForUser(): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get('Notification/ClearAllNotificationForUser')
    return res.status >= 200 && res.status < 300
  }
}
