import EmergencyCoverageModel from '@/models/policyHolder/EmergencyCoverageModel'
export default class EmergencyDetailModel {
  public id: string
  public emergencyDetailId: number
  public typeId: number
  public description: string
  public tradeId: number | null
  public orderBy: number
  public emergencyCoverage: EmergencyCoverageModel | null
  public isLocal: boolean
  public isEmergencyCoverageExist: boolean
  public isDeleted: boolean
  public integrationReference: string
}
