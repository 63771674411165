var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',[_c('v-flex',{class:_vm.showDateTimePicker && _vm.selectedFileList.length > 0 ? 'xs6' : 'xs12'},[_c('v-text-field',{class:_vm.isRequiredValidation ? 'required' : '',attrs:{"disabled":_vm.isDisabled,"label":_vm.label,"append-icon":"attach_file","hide-details":true},on:{"click":_vm.pickFile}}),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:({
          rules: { required: _vm.isRequiredValidation, size: _vm.getAttachmentSize },
        }),expression:"{\n          rules: { required: isRequiredValidation, size: getAttachmentSize },\n        }"}],ref:"fileUpload",staticStyle:{"display":"none"},attrs:{"type":"file","name":"file","multiple":"","disabled":_vm.isDisabled},on:{"change":_vm.onFilePicked}}),_c('div',{staticClass:"input-group__details"},[_c('div',{staticClass:"input-group__messages input-group__error input-group--error error--text"},[_c('span',[_vm._v(_vm._s(_vm.fileValidationMessage(_vm.errors, _vm.fileErrorMessage)))])])]),_c('div',{staticClass:"uploaded-files mt-3"},_vm._l((_vm.selectedFileList),function(file){return _c('span',{key:file.fileName,staticClass:"elevation-1"},[_c('div',{staticClass:"image-action hover-content-section"},[(typeof file.fileURL === 'string')?_c('span',[(
                  file.fileType.toLowerCase() === 'jpg' ||
                  file.fileType.toLowerCase() === 'jpeg' ||
                  file.fileType.toLowerCase() === 'png'
                )?_c('img',{attrs:{"src":file.fileURL,"title":file.fileName},on:{"click":function($event){_vm.uploadCarousel = true}}}):_c('img',{attrs:{"src":_vm.documentTypeWiseThumbnail(file.fileType.toLowerCase()),"title":file.fileName}})]):_c('span',[_c('img',{attrs:{"src":_vm.documentTypeWiseThumbnail(file.fileType.toLowerCase()),"title":file.fileName}})]),_c('div',{staticClass:"grey lighten-3 hover-item"},[_c('v-btn',{staticClass:"elevation-0 upload-img-action-btn ma-0",attrs:{"icon":"","small":"","flat":"","color":"primary"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.removeFile(file)}}},[_c('v-icon',[_vm._v("delete")])],1),(typeof file.fileURL === 'string' && _vm.showDownloadButton(file.fileURL))?_c('v-btn',{staticClass:"elevation-0 upload-img-action-btn ma-0",attrs:{"icon":"","flat":"","small":"","color":"primary"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(file.fileURL)}}},[_c('v-icon',[_vm._v("get_app")])],1):_vm._e()],1)])])}),0),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.uploadCarousel),callback:function ($$v) {_vm.uploadCarousel=$$v},expression:"uploadCarousel"}},[_c('v-card',[_c('v-btn',{staticClass:"uploadCarousel-btn white",attrs:{"icon":"","flat":"","color":"primary"},on:{"click":function($event){_vm.uploadCarousel = false}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-carousel',{attrs:{"cycle":false}},_vm._l((_vm.selectedFileList.filter(
                (e) =>
                  (e.fileType.toLowerCase() === 'png' ||
                    e.fileType.toLowerCase() === 'jpg' ||
                    e.fileType.toLowerCase() === 'jpeg') &&
                  typeof e.fileURL === 'string'
              )),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.fileURL}})}),1)],1)],1)],1),(_vm.showDateTimePicker && _vm.selectedFileList.length > 0)?_c('v-flex',{attrs:{"xs6":""}},[_c('DateTimePicker',{ref:"dateTimePicker",attrs:{"date-time":_vm.getDateTime,"is-static-location":false,"place-holder-text":"Document Expiration Date","is-current-time":false,"is-validation-required":_vm.isDateValidationRequired,"show-present-time-icon":false,"hide-time-picker":true,"display-format":_vm.getDateFormat,"disabled":_vm.isDisabled,"min-date":_vm.minDate},on:{"update:dateTime":function($event){_vm.getDateTime=$event},"update:date-time":function($event){_vm.getDateTime=$event}}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }