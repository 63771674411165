import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';
import { IJobRequestState } from '../states/interfaces/job-request-state';
import JobRequestState from '../states/job-request-state';
import IRequestedJob from '@/models/requests/base/interfaces/requested-job';
import Job from '@/models/Job';
import JobDetail from '@/models/job/job-detail';
import Shared from '@/common/shared';
import JobController from '@/api/jobController';
import RequestModel from '@/models/RequestModel';
import EnhancedClientController from '@/api/enhancedClientController';

const jobRequestState: IJobRequestState = new JobRequestState();

const getters: GetterTree<IJobRequestState, any> = {
  jobRequests: (state) => state.jobRequestDocuments,
  requestedJob: (state) => state.requestedJob,
  engineerName: (state) => (engineerId: string): string | null => {
    const { requestedJob } = state
    if (!requestedJob) {
      return null
    }
    const visitDetail = requestedJob.engineerVisitDetails.find(x => x.engineerId === engineerId)
    return visitDetail ? `${visitDetail.engineerFirstName} ${visitDetail.engineerLastName}` : null
  }
};

const mutations: MutationTree<IJobRequestState> = {
  setRequestedJob: (state: IJobRequestState, requestedJob: IRequestedJob) => {
    state.requestedJob = requestedJob;
  },
  setJobRequests: (state: IJobRequestState, requests: RequestModel[]) => {
    state.jobRequestDocuments = requests;
  },
  clearJobRequests: (state: IJobRequestState) => {
    state.jobRequestDocuments = [];
    state.requestedJob = null
  },
};

const actions: ActionTree<IJobRequestState, any> = {
  async submitRetrieveEnhancedJobRequests({ commit, rootState }, jobId: string) {
    const jobRequest = await EnhancedClientController.GetJobRequests(jobId)
    if (jobRequest) {
      commit('setRequestedJob', jobRequest.job)
      commit('setJobRequests', jobRequest.requests)
    }
  },
  submitAuditLogData({ commit, rootState }, jobId) {
    const job: Job = rootState.Jobs[jobId]

    const requestedJob: IRequestedJob = {
      emergencies: job.emergencies,
      engineerVisitDetails: job.engineerVisitDetail,
      jobDetail: Shared.mapObject(new JobDetail(), job),
      contractorAppointedDetails: job.contractorAppointed,
      repairEstimates: job.repairEstimates,
      customerAppointedTrades: job.customerAppointedTradespeople,
      monitoringDetail: job.monitoringDetail,
      clientInvoiceDetails: job.clientInvoiceDetails,
      contractorInvoiceDetails: job.contractorInvoiceDetail,
      smsTexts: job.SmsTexts,
      emergenciesQAs: job.emergenciesQAs,
      engineerRequests: job.engineerRequest
    }

    commit('setRequestedJob', requestedJob)
  },
  async submitRetrieveJobRequests({ commit }, jobId: string) {
    const jobRequests = await JobController.GetAllRequestDocuments(jobId)
   if (jobRequests.length > 0) {
     commit('setJobRequests', jobRequests)
   }
  },
  submitClearJobRequests({ commit }) {
    commit('clearJobRequests');
  },
};

export const jobRequestModule: Module<IJobRequestState, any> = {
  state: jobRequestState,
  actions,
  mutations,
  getters,
  namespaced: true,
};
