<template>
  <span v-if="getSoftFixedEmergency && getUser">
    accepted by
    <b class="text-secondary">{{ getUser }}</b>
  </span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import SoftFixedEmergency from '@/models/claim/SoftFixedEmergencyModel'
@Component
export default class SoftFixFooter extends TimeLineItemBase {
  private get getSoftFixedEmergency(): SoftFixedEmergency {
    return storeGetters.getSoftFixedEmergencyDetailById(this.jobId, this.itemId)
  }

  private get getUser(): string {
    const users = storeGetters.getUsers()
    const user = users.filter((x) => x.id === this.getSoftFixedEmergency.softFixedBy)[0]
    if (user) {
      return user.firstName + ' ' + user.lastName
    }
    return ''
  }
}
</script>
