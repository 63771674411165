import { SIJobProductProgress } from '@/common/enums'

export default class CompleteWaterMainsTestRecord {
  public id: string
  public jobId: string
  public status: SIJobProductProgress | string
  public periodListeningTest: number
  public isLeakDetected: boolean
  public isNoiseHeardFromListeningStick: boolean
}
