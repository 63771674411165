<template>
  <div class="dashboard">
    <div v-show="ready">
      <v-card-title class="pa-0 pb-2">
        <v-spacer></v-spacer>
        <v-select
          v-model="selectedJobType"
          :items="getJobTypes"
          label="Job Type"
          item-text="jobTypeDescription"
          item-value="jobType"
          class="mr-2 pt-2 mw-350 jobType"
        ></v-select>
        <v-autocomplete
          v-model="searchUserValue"
          :items="userListForFilter"
          single-line
          item-text="displayValue"
          item-value="id"
          class="mw-350 pt-0 mt-0 mr-3"
          label="Assigned to"
          return-object
          hide-details
          @change="onSearchInputChanged($event)"
        ></v-autocomplete>
        <v-text-field
          v-model="searchInput"
          class="en-request-search pt-0 mt-0"
          single-line
          label="Search by JobId"
          hide-details
          append-icon="search"
          @keypress="jobIdValidation($event)"
        ></v-text-field>
      </v-card-title>
      <v-layout wrap>
        <v-flex xs12 class="en-request-section">
          <v-tabs v-model="activeTab" icons-and-text :hide-slider="true" class="en-request-detail">
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <div class="tab-wrapper" :class="activeTab === 'tab-1' ? 'active' : ''">
              <span>New Job</span>
              <v-tab href="#tab-1">
                New Job
                <b>({{ getNewJobsList.length }})</b>
              </v-tab>
            </div>
            <div class="tab-wrapper" :class="activeTab === 'tab-2' || activeTab === 'tab-3' ? 'active' : ''">
              <span>Pre-Start</span>
              <v-tab href="#tab-2">
                Customer not present
                <b>({{ getPolicyHolderNotPresentList.length }})</b>
              </v-tab>
              <v-tab href="#tab-3">
                Engineer Reject Visit
                <b>({{ getEngineerRejectVisitList.length }})</b>
              </v-tab>
              <v-tab href="#tab-7">
                Running Late
                <b>({{ getRunningLateRequestList.length }})</b>
              </v-tab>
            </div>
            <div class="tab-wrapper" :class="activeTab === 'tab-4' || activeTab === 'tab-5' ? 'active' : ''">
              <span>Urgent from Site</span>
              <v-tab href="#tab-4">
                Engineer Abandoned Visit
                <b>({{ engineerAbandonedVisitList.length }})</b>
              </v-tab>
              <v-tab href="#tab-5">
                Resource Request
                <b>({{ getResourceRequestList.length }})</b>
              </v-tab>
            </div>
            <div class="tab-wrapper" :class="activeTab === 'tab-6' ? 'active' : ''">
              <span>Completion</span>
              <v-tab href="#tab-6">
                Job Complete
                <b>({{ VisitCompleteList.length }})</b>
              </v-tab>
            </div>
            <!-- Tab 1 data -->
            <v-tab-item value="tab-1">
              <div v-bar="{ useScrollbarPseudo: true }">
                <v-card class="pr-1 elevation-0 py-0 pl-0">
                  {{ startTimer() }}
                  <v-list v-if="getNewJobsList.length" class="request-list">
                    <v-list-tile v-for="(request, index) in getNewJobsList" :key="index">
                      <div v-if="request" class="request-details">
                        <div class="jobnumber mb-2">
                          <h4>
                            Job Number:
                            <a class="secondary--text" @click="redirectToJob(request)">
                              {{ request.jobId }}
                            </a>
                          </h4>
                          <span
                            v-if="request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                            class="request-time"
                            :class="
                              request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                              !isAssignedUserLoggedIn(request.assignedUserId)
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <b>
                              <v-icon>timer</v-icon>
                              <span class="countdown-time">
                                {{ timerCounter(request) }}
                              </span>
                            </b>
                            <br />
                          </span>
                        </div>
                        <div
                          v-if="
                            request.engineerRequests &&
                            request.engineerRequests.length > 0 &&
                            request.engineerRequests[0].address
                          "
                          class="address mb-2"
                        >
                          <label>Address :</label>
                          {{ request.engineerRequests[0].address }}
                        </div>
                        <div class="mb-2">
                          <v-layout wrap>
                            <v-flex
                              v-if="
                                request.engineerRequests &&
                                request.engineerRequests.length > 0 &&
                                request.engineerRequests[0].loggedAt
                              "
                              xs4
                            >
                              <label>Logged Date:</label>
                              {{ getFormattedDate(request.engineerRequests[0].loggedAt) }}
                            </v-flex>
                            <v-flex v-if="request.forEmergencyTypeId" xs4>
                              <label>Emergency Type:</label>
                              <span>
                                {{ getEmergencyTypeName(request.forEmergencyTypeId) }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div class="mb-2">
                          <v-layout wrap>
                            <v-flex
                              v-if="
                                request.engineerRequests &&
                                request.engineerRequests.length > 0 &&
                                request.engineerRequests[0].loggedVia
                              "
                              xs4
                            >
                              <label>By:</label>
                              <span>
                                {{ request.engineerRequests[0].loggedVia }}
                              </span>
                            </v-flex>
                            <v-flex v-if="request.forEmergencyDetailId" xs4>
                              <label>Emergency Details:</label>
                              <span>
                                {{ getEmergencyDetailName(request.forEmergencyDetailId) }}
                              </span>
                            </v-flex>
                            <v-flex xs4 text-xs-right>
                              <div class="action-engineer">
                                <div>
                                  <span
                                    v-if="
                                      isUserSupervisor &&
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                    "
                                    class="primary--text"
                                  >
                                    Assigned To:
                                    {{ getAssignedUserName(request.assignedUserId) }}
                                  </span>
                                </div>
                                <div v-if="isUserSupervisor" class="supervisor-assign">
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Pending]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    :disabled="activeIndex === index"
                                    small
                                    @click="openAssignJobDialog(request, index)"
                                  >
                                    Assign
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    :disabled="activeIndex === index"
                                    small
                                    @click="openAssignJobDialog(request, index)"
                                  >
                                    Reassign
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    small
                                    @click="openCompleteJobDialog(request)"
                                  >
                                    Complete
                                  </v-btn>
                                </div>
                                <div v-else>
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Pending]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    :disabled="isLoading && activeIndex === index"
                                    :loading="isLoading && activeIndex === index"
                                    small
                                    @click="assignJob(request, index)"
                                  >
                                    Assign To Me
                                  </v-btn>
                                  <v-btn
                                    v-else-if="
                                      request.assignedStatus ===
                                        iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                                      isAssignedUserLoggedIn(request.assignedUserId)
                                    "
                                    color="primary"
                                    class="mr-0"
                                    small
                                    @click="openCompleteJobDialog(request)"
                                  >
                                    Complete
                                  </v-btn>
                                  <span v-else class="primary--text">
                                    Assigned To:
                                    {{ getAssignedUserName(request.assignedUserId) }}
                                  </span>
                                </div>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                    </v-list-tile>
                  </v-list>
                  <div v-else class="nodata-text">No Data Available</div>
                </v-card>
              </div>
            </v-tab-item>
            <!-- Tab 2 data -->
            <v-tab-item value="tab-2">
              <div v-bar="{ useScrollbarPseudo: true }">
                <v-card class="pr-1 elevation-0 py-0 pl-0">
                  <v-list v-if="getPolicyHolderNotPresentList.length" class="request-list">
                    <v-list-tile v-for="(request, index) in getPolicyHolderNotPresentList" :key="index">
                      <div class="request-details">
                        <div class="jobnumber mb-2">
                          <h4>
                            Job Number:
                            <a class="secondary--text" @click="redirectToJob(request)">
                              {{ request.jobId }}
                            </a>
                          </h4>
                          <span
                            class="request-time"
                            :class="
                              request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                              !isAssignedUserLoggedIn(request.assignedUserId)
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <b
                              v-if="request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                              class="timer"
                            >
                              <v-icon>timer</v-icon>
                              <span class="countdown-time">
                                {{ timerCounter(request) }}
                              </span>
                            </b>
                            <v-tooltip v-if="request.engineerOnSite" left>
                              <template #activator="{ on }">
                                <span
                                  v-if="showEnginnerOnSiteIcon(request.engineerOnSite)"
                                  class="request-icon mb-2"
                                  v-on="on"
                                >
                                  <img src="img/engineer_on_site.svg" class="engineer-time" />
                                </span>
                              </template>
                              <span>Engineer is waiting on site</span>
                            </v-tooltip>
                          </span>
                        </div>
                        <EngineerRequestDetailView
                          v-if="request"
                          :request-data="request"
                          :emergency-type-name="getEmergencyTypeName(request.forEmergencyTypeId)"
                          :emergency-detail-name="getEmergencyDetailName(request.forEmergencyDetailId)"
                          :users-list="usersList"
                          :is-loading="isLoading"
                          :index="index"
                          @openCompleteJobDialog="openCompleteJobDialog"
                          @openAssignJobDialog="openAssignJobDialog"
                          @assignJob="assignJob"
                        ></EngineerRequestDetailView>
                      </div>
                    </v-list-tile>
                  </v-list>
                  <div v-else class="nodata-text">No Data Available</div>
                </v-card>
              </div>
            </v-tab-item>
            <!-- Tab 3 data -->
            <v-tab-item value="tab-3">
              <div v-bar="{ useScrollbarPseudo: true }">
                <v-card class="pr-1 elevation-0 py-0 pl-0">
                  <v-list v-if="getEngineerRejectVisitList.length" class="request-list">
                    <v-list-tile v-for="(request, index) in getEngineerRejectVisitList" :key="index">
                      <div class="request-details">
                        <div class="jobnumber mb-2">
                          <h4>
                            Job Number:
                            <a class="secondary--text" @click="redirectToJob(request)">
                              {{ request.jobId }}
                            </a>
                          </h4>
                          <span
                            v-if="request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                            class="request-time"
                            :class="
                              request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                              !isAssignedUserLoggedIn(request.assignedUserId)
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <b>
                              <v-icon>timer</v-icon>
                              <span class="countdown-time">
                                {{ timerCounter(request) }}
                              </span>
                            </b>
                            <br />
                          </span>
                        </div>
                        <EngineerRequestDetailView
                          v-if="request"
                          :request-data="request"
                          :emergency-type-name="getEmergencyTypeName(request.forEmergencyTypeId)"
                          :emergency-detail-name="getEmergencyDetailName(request.forEmergencyDetailId)"
                          :users-list="usersList"
                          :is-loading="isLoading"
                          :index="index"
                          @openCompleteJobDialog="openCompleteJobDialog"
                          @openAssignJobDialog="openAssignJobDialog"
                          @assignJob="assignJob"
                        ></EngineerRequestDetailView>
                      </div>
                    </v-list-tile>
                  </v-list>
                  <div v-else class="nodata-text">No Data Available</div>
                </v-card>
              </div>
            </v-tab-item>
            <!-- Tab 7 data -->
            <v-tab-item value="tab-7">
              <div v-bar="{ useScrollbarPseudo: true }">
                <v-card class="pr-1 elevation-0 py-0 pl-0">
                  <v-list v-if="getRunningLateRequestList.length" class="request-list">
                    <v-list-tile v-for="(request, index) in getRunningLateRequestList" :key="index">
                      <div class="request-details">
                        <div class="jobnumber mb-2">
                          <h4>
                            Job Number:
                            <a class="secondary--text" @click="redirectToJob(request)">
                              {{ request.jobId }}
                            </a>
                          </h4>
                          <span
                            v-if="request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                            class="request-time"
                            :class="
                              request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                              !isAssignedUserLoggedIn(request.assignedUserId)
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <b>
                              <v-icon>timer</v-icon>
                              <span class="countdown-time">
                                {{ timerCounter(request) }}
                              </span>
                            </b>
                          </span>
                        </div>
                        <div class="mb-2">
                          <v-layout>
                            <v-flex xs5>
                              <label>Contractor:</label>
                              <span>
                                {{ request.engineerRequests[0].contractor }}
                              </span>
                              &nbsp;
                            </v-flex>
                            <v-flex xs5>
                              <label>Reason:</label>
                              {{ request.engineerRequests[0].reasonForLate }}
                            </v-flex>
                          </v-layout>
                        </div>
                        <div class="mb-2">
                          <v-layout>
                            <v-flex xs5>
                              <label>Engineer Name:</label>
                              <span>
                                {{ request.engineerRequests[0].engineer }}
                              </span>
                              &nbsp;
                              <b
                                v-if="request.engineerRequests[0].engineerPhoneNumber"
                                class="primary--text phone-number"
                              >
                                (
                                <a href="Javascript:void(0)">
                                  <v-icon small class="primary--text">call</v-icon>
                                  {{ request.engineerRequests[0].engineerPhoneNumber }}
                                </a>
                                )
                              </b>
                            </v-flex>
                            <v-flex xs5>
                              <label>Reach on Site At:</label>
                              <span>
                                {{ getFormattedDate(request.engineerRequests[0].newEtaTo) }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div>
                          <v-layout>
                            <v-flex xs5>
                              <label>Customer Name:</label>
                              <span>
                                {{ request.engineerRequests[0].customer }}
                              </span>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex xs4 text-xs-right>
                              <div class="action-engineer">
                                <div>
                                  <span
                                    v-if="
                                      isUserSupervisor &&
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                    "
                                    class="primary--text"
                                  >
                                    Assigned To:
                                    {{ getAssignedUserName(request.assignedUserId) }}
                                  </span>
                                </div>
                                <div v-if="isUserSupervisor" class="supervisor-assign">
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Pending]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    :disabled="activeIndex === index"
                                    small
                                    @click="openAssignJobDialog(request, index)"
                                  >
                                    Assign
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    :disabled="activeIndex === index"
                                    small
                                    @click="openAssignJobDialog(request, index)"
                                  >
                                    Reassign
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    small
                                    @click="openCompleteJobDialog(request)"
                                  >
                                    Complete
                                  </v-btn>
                                </div>
                                <div v-else>
                                  <v-btn
                                    v-if="
                                      request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Pending]
                                    "
                                    color="primary"
                                    class="mr-0"
                                    :disabled="isLoading && activeIndex === index"
                                    :loading="isLoading && activeIndex === index"
                                    small
                                    @click="assignJob(request, index)"
                                  >
                                    Assign To Me
                                  </v-btn>
                                  <v-btn
                                    v-else-if="
                                      request.assignedStatus ===
                                        iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                                      isAssignedUserLoggedIn(request.assignedUserId)
                                    "
                                    color="primary"
                                    class="mr-0"
                                    small
                                    @click="openCompleteJobDialog(request)"
                                  >
                                    Complete
                                  </v-btn>
                                  <span v-else class="primary--text">
                                    Assigned To:
                                    {{ getAssignedUserName(request.assignedUserId) }}
                                  </span>
                                </div>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                    </v-list-tile>
                  </v-list>
                  <div v-else class="nodata-text">No Data Available</div>
                </v-card>
              </div>
            </v-tab-item>
            <!-- Tab 4 data -->
            <v-tab-item value="tab-4">
              <div v-bar="{ useScrollbarPseudo: true }">
                <v-card class="pr-1 elevation-0 py-0 pl-0">
                  <v-list v-if="engineerAbandonedVisitList.length" class="request-list">
                    <v-list-tile v-for="(request, index) in engineerAbandonedVisitList" :key="index">
                      <div class="request-details">
                        <div class="jobnumber mb-2">
                          <h4>
                            Job Number:
                            <a class="secondary--text" @click="redirectToJob(request)">
                              {{ request.jobId }}
                            </a>
                          </h4>
                          <span
                            v-if="request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                            class="request-time"
                            :class="
                              request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                              !isAssignedUserLoggedIn(request.assignedUserId)
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <b>
                              <v-icon>timer</v-icon>
                              <span class="countdown-time">
                                {{ timerCounter(request) }}
                              </span>
                            </b>
                            <br />
                          </span>
                        </div>
                        <EngineerRequestDetailView
                          v-if="request"
                          :request-data="request"
                          :emergency-type-name="getEmergencyTypeName(request.forEmergencyTypeId)"
                          :emergency-detail-name="getEmergencyDetailName(request.forEmergencyDetailId)"
                          :users-list="usersList"
                          :is-loading="isLoading"
                          :index="index"
                          @openCompleteJobDialog="openCompleteJobDialog"
                          @openAssignJobDialog="openAssignJobDialog"
                          @assignJob="assignJob"
                        ></EngineerRequestDetailView>
                      </div>
                    </v-list-tile>
                  </v-list>
                  <div v-else class="nodata-text">No Data Available</div>
                </v-card>
              </div>
            </v-tab-item>
            <!-- Tab 5 data -->
            <v-tab-item value="tab-5">
              <div v-bar="{ useScrollbarPseudo: true }">
                <v-card class="pr-1 elevation-0 py-0 pl-0">
                  <v-list v-if="getResourceRequestList.length" class="request-list">
                    <v-list-tile v-for="(request, index) in getResourceRequestList" :key="index">
                      <div class="request-details">
                        <div class="jobnumber mb-2">
                          <h4>
                            Job Number:
                            <a class="secondary--text" @click="redirectToJob(request)">
                              {{ request.jobId }}
                            </a>
                          </h4>
                          <span
                            class="request-time"
                            :class="
                              request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                              !isAssignedUserLoggedIn(request.assignedUserId)
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <b
                              v-if="request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                              class="timer"
                            >
                              <v-icon>timer</v-icon>
                              <span class="countdown-time">
                                {{ timerCounter(request) }}
                              </span>
                            </b>
                            <v-tooltip v-if="request.engineerOnSite" left>
                              <template #activator="{ on }">
                                <span
                                  v-if="showEnginnerOnSiteIcon(request.engineerOnSite)"
                                  class="request-icon mb-2"
                                  v-on="on"
                                >
                                  <img src="img/engineer_on_site.svg" class="engineer-time" />
                                </span>
                              </template>
                              <span>Engineer is waiting on site</span>
                            </v-tooltip>
                          </span>
                        </div>
                        <div class="inner-request-details">
                          <v-layout wrap>
                            <v-flex xs5 mb-2>
                              <label v-if="request.engineerRequests.length > 0">
                                <v-icon class="primary--text">assignment</v-icon>
                                Summary Description:
                              </label>
                              <div class="address-wrap">
                                <div
                                  v-for="(material, index1) in request.engineerRequests"
                                  :key="index1"
                                  class="address"
                                >
                                  <span
                                    v-if="material.requestType === engineerRequestType[engineerRequestType.Material]"
                                  >
                                    {{ material.materialName.split(',').length }}
                                    x parts
                                  </span>
                                  <span v-if="material.requestType === engineerRequestType[engineerRequestType.Time]">
                                    <span v-if="checkIfMaterialTypeExist(request.engineerRequests)">,</span>
                                    {{ calculateMinutes(material.extraMinutes) }}
                                    hours extra time
                                  </span>
                                  <span
                                    v-if="material.requestType === engineerRequestType[engineerRequestType.ReturnVisit]"
                                  >
                                    <span v-if="checkIfMaterialOrTimeTypeExist(request.engineerRequests)">,</span>
                                    additional visit
                                  </span>
                                </div>
                              </div>
                            </v-flex>
                            <v-flex xs4>
                              <label><b>Requested At:</b></label>
                              <span>
                                {{ getFormattedDate(request.createdAt) }}
                              </span>
                            </v-flex>
                            <v-flex xs3>
                              <label><b>Status:</b></label>
                              <span
                                :class="
                                  request.requestStatus === 'Pending'
                                    ? 'orange--text'
                                    : request.requestStatus === 'Approved'
                                    ? 'green--text'
                                    : request.requestStatus === 'Rejected'
                                    ? 'red--text'
                                    : ''
                                "
                                class="engineer-requests-statu"
                              >
                                <b>{{ request.requestStatus }}</b>
                              </span>
                            </v-flex>
                          </v-layout>
                          <div v-for="(material, index) in request.engineerRequests" :key="index">
                            <v-layout wrap>
                              <v-flex v-if="material.materialName" xs4>
                                <div class="mb-2">
                                  <label>
                                    <i class="img-icon">
                                      <img src="/img/material_box_p.svg" alt="" />
                                    </i>
                                    Material Name:
                                  </label>
                                  {{ material.materialName }} (
                                  <b>&pound; {{ material.materialCost }}</b>
                                  )
                                </div>
                              </v-flex>
                              <v-flex v-if="material.extraMinutes" xs4>
                                <div class="mb-2">
                                  <label>
                                    <v-icon class="primary--text">access_time</v-icon>
                                    Additional Hours:
                                  </label>
                                  <span>
                                    {{ calculateMinutes(material.extraMinutes) }}
                                  </span>
                                </div>
                              </v-flex>
                              <v-flex v-if="material.returnVisitType" xs4>
                                <div class="mb-2">
                                  <label>
                                    <i class="img-icon">
                                      <img src="/img/user-time-p.svg" alt="" />
                                    </i>
                                    Return Visit Type:
                                  </label>
                                  <span>
                                    {{ material.returnVisitType }} (at {{ getFormattedDate(material.returnVisitAt) }})
                                  </span>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                          <div class="mb-2 mt-2">
                            <v-layout wrap>
                              <v-flex xs4 text-xs-right>
                                <div class="action-engineer">
                                  <div>
                                    <span
                                      v-if="
                                        isUserSupervisor &&
                                        request.assignedStatus ===
                                          iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                      "
                                      class="primary--text"
                                    >
                                      Assigned To:
                                      {{ getAssignedUserName(request.assignedUserId) }}
                                    </span>
                                  </div>
                                  <div v-if="isUserSupervisor" class="supervisor-assign">
                                    <v-btn
                                      v-if="
                                        request.assignedStatus ===
                                        iMRequestProcessStatus[iMRequestProcessStatus.Pending]
                                      "
                                      color="primary"
                                      class="mr-0"
                                      :disabled="activeIndex === index"
                                      small
                                      @click="openAssignJobDialog(request, index)"
                                    >
                                      Assign
                                    </v-btn>
                                    <v-btn
                                      v-if="
                                        request.assignedStatus ===
                                        iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                      "
                                      color="primary"
                                      class="mr-0"
                                      :disabled="activeIndex === index"
                                      small
                                      @click="openAssignJobDialog(request, index)"
                                    >
                                      Reassign
                                    </v-btn>
                                    <v-btn
                                      v-if="
                                        request.assignedStatus ===
                                        iMRequestProcessStatus[iMRequestProcessStatus.Assigned]
                                      "
                                      color="primary btn-complete"
                                      class="mr-0"
                                      small
                                      @click="openCompleteJobDialog(request)"
                                    >
                                      Complete
                                    </v-btn>
                                  </div>
                                  <div v-else>
                                    <v-btn
                                      v-if="
                                        request.assignedStatus ===
                                        iMRequestProcessStatus[iMRequestProcessStatus.Pending]
                                      "
                                      color="primary"
                                      class="mr-0"
                                      :disabled="isLoading && activeIndex === index"
                                      :loading="isLoading && activeIndex === index"
                                      small
                                      @click="assignJob(request, index)"
                                    >
                                      Assign To Me
                                    </v-btn>
                                    <v-btn
                                      v-else-if="
                                        request.assignedStatus ===
                                          iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                                        isAssignedUserLoggedIn(request.assignedUserId)
                                      "
                                      color="primary"
                                      class="mr-0"
                                      small
                                      @click="openCompleteJobDialog(request)"
                                    >
                                      Complete
                                    </v-btn>
                                    <span v-else class="primary--text">
                                      Assigned To:
                                      {{ getAssignedUserName(request.assignedUserId) }}
                                    </span>
                                  </div>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </div>
                      </div>
                    </v-list-tile>
                  </v-list>
                  <div v-else class="nodata-text">No Data Available</div>
                </v-card>
              </div>
            </v-tab-item>
            <!-- Tab 6 data -->
            <v-tab-item value="tab-6">
              <div v-bar="{ useScrollbarPseudo: true }">
                <v-card class="pr-1 elevation-0 py-0 pl-0">
                  <v-list v-if="VisitCompleteList.length" class="request-list">
                    <v-list-tile v-for="(request, index) in VisitCompleteList" :key="index">
                      <div class="request-details">
                        <div class="jobnumber mb-2">
                          <h4>
                            Job Number:
                            <a class="secondary--text" @click="redirectToJob(request)">
                              {{ request.jobId }}
                            </a>
                          </h4>
                          <span
                            v-if="request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned]"
                            class="request-time"
                            :class="
                              request.assignedStatus === iMRequestProcessStatus[iMRequestProcessStatus.Assigned] &&
                              !isAssignedUserLoggedIn(request.assignedUserId)
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <b>
                              <v-icon>timer</v-icon>
                              <span class="countdown-time">
                                {{ timerCounter(request) }}
                              </span>
                            </b>
                          </span>
                        </div>
                        <EngineerRequestDetailView
                          v-if="request"
                          :request-data="request"
                          :emergency-type-name="getEmergencyTypeName(request.forEmergencyTypeId)"
                          :emergency-detail-name="getEmergencyDetailName(request.forEmergencyDetailId)"
                          :users-list="usersList"
                          :is-loading="isLoading"
                          :index="index"
                          @openCompleteJobDialog="openCompleteJobDialog"
                          @openAssignJobDialog="openAssignJobDialog"
                          @assignJob="assignJob"
                        ></EngineerRequestDetailView>
                      </div>
                    </v-list-tile>
                  </v-list>
                  <div v-else class="nodata-text">No Data Available</div>
                </v-card>
              </div>
            </v-tab-item>
          </v-tabs>
          <PartialJobView
            :job-id="selectedJobIdToExpand"
            :record-id="engineerRequestId"
            :request-type="requestType"
            @closeJobView="closeJobView"
          ></PartialJobView>
        </v-flex>
      </v-layout>
      <!-- job complete model -->
      <v-dialog
        v-if="selectedJobIdToComplete"
        v-model="completeJobDialog"
        max-width="650"
        persistent
        content-class="v-dialog--scrollable"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Complete Job</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="completeJobDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-3 scroll-content-dialog">
            <v-textarea
              v-model="requestCompletionNote"
              v-validate="'required'"
              label="Description"
              required
              data-vv-scope="completeEngineerRequest"
              data-vv-name="Description"
              class="required"
              :error-messages="errors.collect('Description')"
            ></v-textarea>
            <div v-if="errorMessage" class="common-error-message">
              <div class="red pa-2 d-block lighten-4">
                <span>
                  <b>{{ errorMessage }}</b>
                </span>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="completeJobDialog = false">Close</v-btn>
            <v-btn
              color="primary btn-complete-request"
              class="mr-0"
              :disabled="isLoading"
              :loading="isLoading"
              @click="completeJob(selectedJobIdToComplete)"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-show="!ready">
      <v-progress-circular
        class="loading-spinner"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-dialog
      v-if="showAssignRequestDialog"
      v-model="showAssignRequestDialog"
      max-width="600"
      content-class="v-dialog--scrollable"
      persistent
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Assign Request</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="closeAssignJobDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <span v-if="assigningMessage" class="info--text">
            {{ assigningMessage }}
          </span>
          <v-autocomplete
            v-model="currentAssignedUserId"
            :items="usersList"
            item-text="displayValue"
            item-value="id"
            label="Assign to"
            return-object
            hide-details
          ></v-autocomplete>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat class="m-auto" @click="closeAssignJobDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            class="m-auto"
            :loading="isLoading"
            :disabled="isLoading"
            @click="assignRequestToUser(currentAssignedUserId)"
          >
            Assign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import DateTimePicker from '@/components/DateTimePicker.vue'
import moment from 'moment'
import Store from '@/store'
import Shared from '@/common/shared'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import storeGetters from '@/storeGetters'
import JobController from '@/api/jobController'
import {
  EngineerRequestType,
  EngineerRequestStatus,
  EngineeringRequestCategory,
  IMRequestProcessStatus,
  JobType,
} from '@/common/enums'
import EngineerRequestBase from '@/models/claim/engineerRequest/EngineerRequestBase'
import Job from '@/models/Job'
import Emergency from '@/models/Emergency'
import PolicyHolderController from '@/api/policyHolderController'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import CompleteEngineerRequest from '@/models/claim/engineerRequest/CompleteEngineerRequest'
import EngineerRequestDetailView from '@/components/EngineerRequestDetailView.vue'
import { EngineeringRequestCategoryType } from '@/models/types'
import UserModel from '@/models/user/UserModel'
import AssignEngineerRequestModel from '@/models/requests/AssignEngineerRequestModel'
import UserController from '@/api/userController'
import storeMutations from '@/storeMutations'
import PartialJobView from '@/components/PartialJobView.vue'
import { APIResponseCode } from '@/models/types'
import store from '@/store'
import eventBus from '@/common/bus'

export interface IJobType {
  jobType: any // 'All' option can be included, so datatype is any instead of JobType
  jobTypeDescription: string
}

@Component({
  components: { EngineerRequestDetailView, PartialJobView },
})
export default class EngineerRequestDashboard extends Vue {
  private ready = false
  private completeJobDialog = false
  private isLoading = false
  private activeTab = 'tab-1'
  private selectedJobIdToComplete: string | null = null
  private selectedJobDocumentId = ''
  private selectedRequestType = ''
  private engineerRequestType = EngineerRequestType
  private emergencyDetails: EmergencyDetailModel[] = []
  private iMRequestProcessStatus = IMRequestProcessStatus
  private requestCompletionNote = ''
  private activeIndex = -1
  private searchInput = ''
  // Tab data
  private newJobsList: EngineerRequestModel[] = []
  private policyHolderNotPresentList: EngineerRequestModel[] = []
  private engineerRejectVisitList: EngineerRequestModel[] = []
  private engineerAbandonedVisitList: EngineerRequestModel[] = []
  private resourceRequestList: EngineerRequestModel[] = []
  private VisitCompleteList: EngineerRequestModel[] = []
  private runningLateRequestList: EngineerRequestModel[] = []
  private allNewJobsList: EngineerRequestModel[] = []
  private allPolicyHolderNotPresentList: EngineerRequestModel[] = []
  private allEngineerRejectVisitList: EngineerRequestModel[] = []
  private allEngineerAbandonedVisitList: EngineerRequestModel[] = []
  private allResourceRequestList: EngineerRequestModel[] = []
  private allVisitCompleteList: EngineerRequestModel[] = []
  private allRunningLateRequestList: EngineerRequestModel[] = []
  // Timer
  private isRunning = false
  private timerMinutes = 0
  private timerSecondes = 1
  private timeCounter = 0
  private timer: any = null
  // SignalR
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('engineerRequest')
  // open job view
  private openJobView = false
  private selectedJobIdToExpand = ''
  private engineerRequestId = ''
  private requestType: EngineeringRequestCategoryType | string = ''
  private searchUserId = ''
  private searchUserValue = ''
  private usersList: UserModel[] = []
  private userListForFilter: UserModel[] = []
  private currentEngineerRequest: EngineerRequestModel = new EngineerRequestModel()
  private currentAssignedUserId: UserModel | null = null
  private showAssignRequestDialog = false
  private saveSnackbar = false
  private saveSnackbarTimeout = 6000
  private saveSnackbarText = ''
  private assigningMessage = ''
  private selectedJobType = ''
  private errorMessage = ''
  private userJobTypeRight: JobType[] = store.Instance.state.SessionDetail.detailRecord.UserDetail.jobTypes

  private created() {
    // get emergency detail list
    PolicyHolderController.GetEmergencyDetails().then((res: EmergencyDetailModel[]) => {
      this.emergencyDetails = res
    })
    // get engineer request list
    this.getEngineerRequestList()

    // get users list to assign users to request
    this.getUserListToAssignRequest()

    // bind userList for filtering
    this.userListForFiltering()

    this.timeCounter = this.timerMinutes * 60 + this.timerSecondes

    // signalr for add-update requests
    this.signalRHub.addHandler('addUpdateEngineerRequest', async (jobId: string, documentId: string) => {
      if (jobId && documentId && Shared.isUserHasJobRights(jobId, false)) {
        JobController.GetEngineerRequestAsync(jobId, documentId).then((resData: EngineerRequestModel | null) => {
          if (resData) {
            this.fillTabData(resData, true)
          }
        })
      }
    })
    // signalr for remove requests on complete
    this.signalRHub.addHandler(
      'removeEngineerRequest',
      async (jobId: string, documentId: string, requestCategory: string) => {
        if (jobId && documentId && requestCategory && Shared.isUserHasJobRights(jobId, false)) {
          this.removeTabData(jobId, documentId, requestCategory)
        }
      }
    )
    this.signalRHub.connect()
  }

  private mounted() {
    SetThemeForJobType(this, undefined)
    // set selectedJobType value based on user jobType rights
    this.selectedJobType = this.getJobTypes.length > 0 ? this.getJobTypes[0].jobType : ''
  }

  private searchJobList(searchInput: string, selectedJobType: string, searchUserId: string) {
    const self = this

    // search by jobId in newJobsList
    const newJobItems: EngineerRequestModel[] = self.allNewJobsList.filter(
      (e: any) =>
        (searchInput ? e.jobId.includes(searchInput) : true) &&
        (selectedJobType !== 'All' ? self.returnJobType(e.jobId) === selectedJobType : true) &&
        (searchUserId ? e.assignedUserId === searchUserId : true)
    )
    self.newJobsList = newJobItems.length > 0 ? newJobItems : []
    // search by jobId in policyHolderNotPresentList
    const policyHolderNotPresentItems: EngineerRequestModel[] = self.allPolicyHolderNotPresentList.filter(
      (e: any) =>
        (searchInput ? e.jobId.includes(searchInput) : true) &&
        (selectedJobType !== 'All' ? self.returnJobType(e.jobId) === selectedJobType : true) &&
        (searchUserId ? e.assignedUserId === searchUserId : true)
    )
    self.policyHolderNotPresentList = policyHolderNotPresentItems.length > 0 ? policyHolderNotPresentItems : []
    // search by jobId in engineerRejectVisitList
    const engineerRejectVisitItems: EngineerRequestModel[] = self.allEngineerRejectVisitList.filter(
      (e: any) =>
        (searchInput ? e.jobId.includes(searchInput) : true) &&
        (selectedJobType !== 'All' ? self.returnJobType(e.jobId) === selectedJobType : true) &&
        (searchUserId ? e.assignedUserId === searchUserId : true)
    )
    self.engineerRejectVisitList = engineerRejectVisitItems.length > 0 ? engineerRejectVisitItems : []

    // search by jobId in engineerAbandonedVisitList
    const engineerAbandonedVisitItems: EngineerRequestModel[] = self.allEngineerAbandonedVisitList.filter(
      (e: any) =>
        (searchInput ? e.jobId.includes(searchInput) : true) &&
        (selectedJobType !== 'All' ? self.returnJobType(e.jobId) === selectedJobType : true) &&
        (searchUserId ? e.assignedUserId === searchUserId : true)
    )
    self.engineerAbandonedVisitList = engineerAbandonedVisitItems.length > 0 ? engineerAbandonedVisitItems : []
    // search by jobId in resourceRequestList
    const resourceRequestItems: EngineerRequestModel[] = self.allResourceRequestList.filter(
      (e: any) =>
        (searchInput ? e.jobId.includes(searchInput) : true) &&
        (selectedJobType !== 'All' ? self.returnJobType(e.jobId) === selectedJobType : true) &&
        (searchUserId ? e.assignedUserId === searchUserId : true)
    )
    self.resourceRequestList = resourceRequestItems.length > 0 ? resourceRequestItems : []
    // search by jobId in VisitCompleteList
    const visitCompleteItems: EngineerRequestModel[] = self.allVisitCompleteList.filter(
      (e: any) =>
        (searchInput ? e.jobId.includes(searchInput) : true) &&
        (selectedJobType !== 'All' ? self.returnJobType(e.jobId) === selectedJobType : true) &&
        (searchUserId ? e.assignedUserId === searchUserId : true)
    )
    self.VisitCompleteList = visitCompleteItems.length > 0 ? visitCompleteItems : []
    // search by jobId in runningLateRequestList
    const runningLateRequestItems: EngineerRequestModel[] = self.allRunningLateRequestList.filter(
      (e: any) =>
        (searchInput ? e.jobId.includes(searchInput) : true) &&
        (selectedJobType !== 'All' ? self.returnJobType(e.jobId) === selectedJobType : true) &&
        (searchUserId ? e.assignedUserId === searchUserId : true)
    )
    self.runningLateRequestList = runningLateRequestItems.length > 0 ? runningLateRequestItems : []
  }

  @Watch('searchInput')
  @Watch('selectedJobType')
  private onSearchInputChanged(newValue: any) {
    const self = this
    if (self.searchInput !== '') {
      self.searchInput = self.searchInput.toUpperCase()
    }

    if (typeof newValue === 'object') {
      self.searchUserId = newValue.id
    }

    this.searchJobList(self.searchInput, self.selectedJobType, self.searchUserId)
  }

  private getEngineerRequestList() {
    JobController.GetAllEngineerRequests()
      .then((resData: EngineerRequestModel[] | null) => {
        if (resData !== null && resData.length > 0) {
          for (const requestModel of resData) {
            this.fillTabData(requestModel, false)
          }
          this.allNewJobsList = JSON.parse(JSON.stringify(this.newJobsList))
          this.allPolicyHolderNotPresentList = JSON.parse(JSON.stringify(this.policyHolderNotPresentList))
          this.allEngineerRejectVisitList = JSON.parse(JSON.stringify(this.engineerRejectVisitList))
          this.allEngineerAbandonedVisitList = JSON.parse(JSON.stringify(this.engineerAbandonedVisitList))
          this.allResourceRequestList = JSON.parse(JSON.stringify(this.resourceRequestList))
          this.allVisitCompleteList = JSON.parse(JSON.stringify(this.VisitCompleteList))
          this.allRunningLateRequestList = JSON.parse(JSON.stringify(this.runningLateRequestList))
        }
        this.ready = true
        return
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading engineer request list, please try again', true)
      })
  }

  private fillTabData(requestModel: EngineerRequestModel, isSignalRCall: boolean) {
    switch (requestModel.requestCategory) {
      case EngineeringRequestCategory[EngineeringRequestCategory.NewJob]:
        if (isSignalRCall) {
          const newJobIndex = this.allNewJobsList.findIndex((x: EngineerRequestModel) => x.id === requestModel.id)
          if (newJobIndex !== -1) {
            this.allNewJobsList.splice(newJobIndex, 1, requestModel)
          } else {
            this.allNewJobsList.push(requestModel)
          }

          const filteredNewJobIndex = this.newJobsList.findIndex((x: EngineerRequestModel) => x.id === requestModel.id)
          if (filteredNewJobIndex !== -1) {
            this.newJobsList.splice(filteredNewJobIndex, 1, requestModel)
          } else {
            if (this.isFiltered(requestModel)) {
              this.newJobsList.push(requestModel)
            }
          }
        } else {
          this.newJobsList.push(requestModel)
        }
        break
      case EngineeringRequestCategory[EngineeringRequestCategory.PolicyHolderNotPresent]:
        if (isSignalRCall) {
          const phNotPresentIndex = this.allPolicyHolderNotPresentList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (phNotPresentIndex !== -1) {
            this.allPolicyHolderNotPresentList.splice(phNotPresentIndex, 1, requestModel)
          } else {
            this.allPolicyHolderNotPresentList.push(requestModel)
          }

          const filteredPhNotPresentIndex = this.policyHolderNotPresentList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (filteredPhNotPresentIndex !== -1) {
            this.policyHolderNotPresentList.splice(filteredPhNotPresentIndex, 1, requestModel)
          } else {
            if (this.isFiltered(requestModel)) {
              this.policyHolderNotPresentList.push(requestModel)
            }
          }
        } else {
          this.policyHolderNotPresentList.push(requestModel)
        }
        break
      case EngineeringRequestCategory[EngineeringRequestCategory.RejectVisit]:
        if (isSignalRCall) {
          const rejectVisitIndex = this.allEngineerRejectVisitList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (rejectVisitIndex !== -1) {
            this.allEngineerRejectVisitList.splice(rejectVisitIndex, 1, requestModel)
          } else {
            this.allEngineerRejectVisitList.push(requestModel)
          }

          const filteredRejectVisitIndex = this.engineerRejectVisitList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (filteredRejectVisitIndex !== -1) {
            this.engineerRejectVisitList.splice(filteredRejectVisitIndex, 1, requestModel)
          } else {
            if (this.isFiltered(requestModel)) {
              this.engineerRejectVisitList.push(requestModel)
            }
          }
        } else {
          this.engineerRejectVisitList.push(requestModel)
        }
        break
      case EngineeringRequestCategory[EngineeringRequestCategory.AbandonedVisit]:
        if (isSignalRCall) {
          const abandonedVisitIndex = this.allEngineerAbandonedVisitList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (abandonedVisitIndex !== -1) {
            this.allEngineerAbandonedVisitList.splice(abandonedVisitIndex, 1, requestModel)
          } else {
            this.allEngineerAbandonedVisitList.push(requestModel)
          }

          const filteredAbandonedVisitIndex = this.engineerAbandonedVisitList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (filteredAbandonedVisitIndex !== -1) {
            this.engineerAbandonedVisitList.splice(filteredAbandonedVisitIndex, 1, requestModel)
          } else {
            if (this.isFiltered(requestModel)) {
              this.engineerAbandonedVisitList.push(requestModel)
            }
          }
        } else {
          this.engineerAbandonedVisitList.push(requestModel)
        }
        break
      case EngineeringRequestCategory[EngineeringRequestCategory.Resource]:
        if (isSignalRCall) {
          const resourceRequestIndex = this.allResourceRequestList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (resourceRequestIndex !== -1) {
            this.allResourceRequestList.splice(resourceRequestIndex, 1, requestModel)
          } else {
            this.allResourceRequestList.push(requestModel)
          }

          const filteredResourceRequestIndex = this.resourceRequestList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (filteredResourceRequestIndex !== -1) {
            this.resourceRequestList.splice(filteredResourceRequestIndex, 1, requestModel)
          } else {
            if (this.isFiltered(requestModel)) {
              this.resourceRequestList.push(requestModel)
            }
          }
        } else {
          this.resourceRequestList.push(requestModel)
        }
        break
      case EngineeringRequestCategory[EngineeringRequestCategory.VisitComplete]:
        if (isSignalRCall) {
          const visitCompleteIndex = this.allVisitCompleteList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (visitCompleteIndex !== -1) {
            this.allVisitCompleteList.splice(visitCompleteIndex, 1, requestModel)
          } else {
            this.allVisitCompleteList.push(requestModel)
          }

          const filteredVisitCompleteIndex = this.VisitCompleteList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (filteredVisitCompleteIndex !== -1) {
            this.VisitCompleteList.splice(filteredVisitCompleteIndex, 1, requestModel)
          } else {
            if (this.isFiltered(requestModel)) {
              this.VisitCompleteList.push(requestModel)
            }
          }
        } else {
          this.VisitCompleteList.push(requestModel)
        }
        break
      case EngineeringRequestCategory[EngineeringRequestCategory.RunningLate]:
        if (isSignalRCall) {
          const runningLateRequestIndex = this.allRunningLateRequestList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (runningLateRequestIndex !== -1) {
            this.allRunningLateRequestList.splice(runningLateRequestIndex, 1, requestModel)
          } else {
            this.allRunningLateRequestList.push(requestModel)
          }

          const filteredRunningLateRequestIndex = this.runningLateRequestList.findIndex(
            (x: EngineerRequestModel) => x.id === requestModel.id
          )
          if (filteredRunningLateRequestIndex !== -1) {
            this.runningLateRequestList.splice(filteredRunningLateRequestIndex, 1, requestModel)
          } else {
            if (this.isFiltered(requestModel)) {
              this.runningLateRequestList.push(requestModel)
            }
          }
        } else {
          this.runningLateRequestList.push(requestModel)
        }
        break
    }
  }

  private removeTabData(jobId: string, documentId: string, requestCategory: string) {
    switch (requestCategory) {
      case EngineeringRequestCategory[EngineeringRequestCategory.NewJob]: {
        const filteredNewJobIndex = this.newJobsList.findIndex((x: EngineerRequestModel) => x.id === documentId)
        if (filteredNewJobIndex !== -1) {
          this.newJobsList.splice(filteredNewJobIndex, 1)
        }
        // remove from temp list of all New Jobs
        const newJobIndex = this.allNewJobsList.findIndex((x: EngineerRequestModel) => x.id === documentId)
        if (newJobIndex !== -1) {
          this.allNewJobsList.splice(newJobIndex, 1)
        }
        break
      }
      case EngineeringRequestCategory[EngineeringRequestCategory.PolicyHolderNotPresent]: {
        const filteredPhNotPresentIndex = this.policyHolderNotPresentList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (filteredPhNotPresentIndex !== -1) {
          this.policyHolderNotPresentList.splice(filteredPhNotPresentIndex, 1)
        }
        // remove from temp list of all Policy Holder Not Present
        const phNotPresentIndex = this.allPolicyHolderNotPresentList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (phNotPresentIndex !== -1) {
          this.allPolicyHolderNotPresentList.splice(phNotPresentIndex, 1)
        }
        break
      }
      case EngineeringRequestCategory[EngineeringRequestCategory.RejectVisit]: {
        const filteredRejectVisitIndex = this.engineerRejectVisitList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (filteredRejectVisitIndex !== -1) {
          this.engineerRejectVisitList.splice(filteredRejectVisitIndex, 1)
        }
        // remove from temp list of all Engineer Reject Visit
        const rejectVisitIndex = this.allEngineerRejectVisitList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (rejectVisitIndex !== -1) {
          this.allEngineerRejectVisitList.splice(rejectVisitIndex, 1)
        }
        break
      }
      case EngineeringRequestCategory[EngineeringRequestCategory.AbandonedVisit]: {
        const filteredAbandonedVisitIndex = this.engineerAbandonedVisitList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (filteredAbandonedVisitIndex !== -1) {
          this.engineerAbandonedVisitList.splice(filteredAbandonedVisitIndex, 1)
        }
        // remove from temp list of all Engineer Abandoned Visit
        const abandonedVisitIndex = this.allEngineerAbandonedVisitList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (abandonedVisitIndex !== -1) {
          this.allEngineerAbandonedVisitList.splice(abandonedVisitIndex, 1)
        }
        break
      }
      case EngineeringRequestCategory[EngineeringRequestCategory.Resource]: {
        const filteredResourceRequestIndex = this.resourceRequestList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (filteredResourceRequestIndex !== -1) {
          this.resourceRequestList.splice(filteredResourceRequestIndex, 1)
        }
        // remove from temp list of all Resource Request
        const resourceRequestIndex = this.allResourceRequestList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (resourceRequestIndex !== -1) {
          this.allResourceRequestList.splice(resourceRequestIndex, 1)
        }
        break
      }
      case EngineeringRequestCategory[EngineeringRequestCategory.VisitComplete]: {
        const filteredVisitCompleteIndex = this.VisitCompleteList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (filteredVisitCompleteIndex !== -1) {
          this.VisitCompleteList.splice(filteredVisitCompleteIndex, 1)
        }
        // remove from temp list of all Visit Complete
        const visitCompleteIndex = this.allVisitCompleteList.findIndex((x: EngineerRequestModel) => x.id === documentId)
        if (visitCompleteIndex !== -1) {
          this.allVisitCompleteList.splice(visitCompleteIndex, 1)
        }
        break
      }
      case EngineeringRequestCategory[EngineeringRequestCategory.RunningLate]: {
        const filteredRunningLateRequestIndex = this.runningLateRequestList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (filteredRunningLateRequestIndex !== -1) {
          this.runningLateRequestList.splice(filteredRunningLateRequestIndex, 1)
        }
        // remove from temp list of all Running Late Request
        const runningLateRequestIndex = this.allRunningLateRequestList.findIndex(
          (x: EngineerRequestModel) => x.id === documentId
        )
        if (runningLateRequestIndex !== -1) {
          this.allRunningLateRequestList.splice(runningLateRequestIndex, 1)
        }
        break
      }
    }
  }

  private get getNewJobsList(): EngineerRequestModel[] | undefined {
    if (this.newJobsList) {
      return this.newJobsList
    } else {
      return undefined
    }
  }

  private get getPolicyHolderNotPresentList(): EngineerRequestModel[] | undefined {
    if (this.policyHolderNotPresentList) {
      return this.policyHolderNotPresentList
    } else {
      return undefined
    }
  }

  private get getEngineerRejectVisitList(): EngineerRequestModel[] | undefined {
    if (this.engineerRejectVisitList) {
      return this.engineerRejectVisitList
    } else {
      return undefined
    }
  }

  private get getResourceRequestList(): EngineerRequestModel[] | undefined {
    if (this.resourceRequestList) {
      return this.resourceRequestList
    } else {
      return undefined
    }
  }

  private get getRunningLateRequestList(): EngineerRequestModel[] | undefined {
    if (this.runningLateRequestList) {
      return this.runningLateRequestList
    } else {
      return undefined
    }
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies()
  }

  private get getUsersList() {
    return storeGetters.getUsers()
  }

  private isAssignedUserLoggedIn(assignedUserId: string): boolean {
    const userId = Store.Instance.state.SessionDetail.detailRecord.UserDetail.id
    return userId === assignedUserId ? true : false
  }

  private getAssignedUserName(assignedUserId: string): string {
    const match = this.getUsersList.find((e) => e.id === assignedUserId)
    if (match && match !== undefined) {
      return match.displayValue
    } else {
      return 'Unknown User'
    }
  }

  private getEmergencyTypeName(emergencyTypeId: number): string {
    const emergency = this.emergencyTypes.find((e) => e.emergencyId === emergencyTypeId)
    let emergencyTypeDescription = ''
    if (emergency) {
      emergencyTypeDescription = emergency.description
    }
    return emergencyTypeDescription
  }

  private getEmergencyDetailName(id: number): string {
    const emergencyDetail = this.emergencyDetails.find((e: EmergencyDetailModel) => e.emergencyDetailId === id)
    let emergencyDetailDescription = ''
    if (emergencyDetail) {
      emergencyDetailDescription = emergencyDetail.description
    }
    return emergencyDetailDescription
  }

  private checkIfMaterialTypeExist(requestList: EngineerRequestBase[]): boolean {
    if (requestList) {
      const isRequesTypeExists: number = requestList.filter(
        (x) => x.requestType.toString() === EngineerRequestType[EngineerRequestType.Material]
      ).length
      if (isRequesTypeExists > 0) {
        return true
      }
    }
    return false
  }

  private checkIfMaterialOrTimeTypeExist(requestList: EngineerRequestBase[]): boolean {
    if (requestList) {
      const isMaterialRequestTypeExists: number = requestList.filter(
        (x) => x.requestType.toString() === EngineerRequestType[EngineerRequestType.Material]
      ).length
      const isTimeRequestTypeExists: number = requestList.filter(
        (x) => x.requestType.toString() === EngineerRequestType[EngineerRequestType.Time]
      ).length
      if (isMaterialRequestTypeExists > 0) {
        return true
      } else if (isTimeRequestTypeExists > 0) {
        return true
      }
    }
    return false
  }

  // Calculate and formate minutes and second
  private calculateMinutes(second: number): string {
    return Shared.formatDuration(second)
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private openAssignJobDialog(engineerRequest: EngineerRequestModel, index: number) {
    this.assigningMessage = ''
    const userId = engineerRequest.assignedUserId
      ? engineerRequest.assignedUserId
      : Store.Instance.state.SessionDetail.detailRecord.UserDetail.id
    const user = this.usersList.find((u) => u.id === userId)
    if (user) {
      this.currentAssignedUserId = user
    }
    if (
      engineerRequest.requestCategory === EngineeringRequestCategory[EngineeringRequestCategory.Resource] &&
      this.getResourceRequestList
    ) {
      const resourceRequests = this.getResourceRequestList.filter(
        (e) =>
          e.jobId === engineerRequest.jobId &&
          e.requestCategory === EngineeringRequestCategory[EngineeringRequestCategory.Resource] &&
          e.requestStatus === this.iMRequestProcessStatus[this.iMRequestProcessStatus.Pending]
      ).length
      this.assigningMessage =
        resourceRequests >= 2 ? 'Assigning this and ' + (resourceRequests - 1) + ' more engineer request' : ''
    }
    this.currentEngineerRequest = engineerRequest
    this.activeIndex = index
    this.showAssignRequestDialog = true
  }

  private closeAssignJobDialog() {
    this.showAssignRequestDialog = false
    this.activeIndex = -1
    this.currentEngineerRequest = new EngineerRequestModel()
  }

  private assignRequestToUser(userData: UserModel) {
    this.assignJob(this.currentEngineerRequest, this.activeIndex, userData)
  }

  private assignJob(engineerRequest: EngineerRequestModel, index: number, userData: UserModel): void {
    if (userData) {
      if (userData.id === '' && engineerRequest.assignedUserId === null) {
        // if already un-assigned
        return
      } else if (userData.id === engineerRequest.assignedUserId) {
        // if already assigned to user
        this.saveSnackbarText = 'Request is already assigned to ' + userData.displayValue
        this.saveSnackbar = true
        return
      }
    }
    this.isLoading = true

    const assignEngineerRequestModel: AssignEngineerRequestModel = new AssignEngineerRequestModel()
    assignEngineerRequestModel.jobId = engineerRequest.jobId
    assignEngineerRequestModel.documentId = engineerRequest.id
    assignEngineerRequestModel.userId = userData ? userData.id : this.loggedInUserId
    const requestType =
      engineerRequest.requestCategory !== EngineeringRequestCategory[EngineeringRequestCategory.NewJob]
        ? engineerRequest.requestCategory
        : ''
    this.selectedJobIdToExpand = ''
    this.engineerRequestId = ''
    this.requestType = ''

    JobController.AssignEngineerRequest(assignEngineerRequestModel)
      .then((res: EngineerRequestModel | null) => {
        if (res && res !== null) {
          if (!userData || (userData && userData.id === this.loggedInUserId)) {
            // open timeline with relative card open if assigned to self.
            this.selectedJobIdToExpand = assignEngineerRequestModel.jobId
            this.engineerRequestId = assignEngineerRequestModel.documentId
            this.requestType = requestType
            this.openJobView = true
          }
          if (this.showAssignRequestDialog) {
            this.saveSnackbarText = userData.id
              ? 'Request is Assigned to ' + userData.displayValue
              : 'Request has been Unassigned'
            this.saveSnackbar = true
            this.closeAssignJobDialog()
          }
        }
        this.isLoading = false
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error assigning engineer request, please try again', true)
        this.isLoading = false
      })
  }

  private openCompleteJobDialog(engineerRequest: EngineerRequestModel): void {
    this.errorMessage = ''
    this.requestCompletionNote = ''
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
    this.selectedJobIdToComplete = engineerRequest.jobId
    this.selectedJobDocumentId = engineerRequest.id
    this.selectedRequestType = engineerRequest.engineerRequests[0].requestType.toString()
    this.completeJobDialog = true
  }

  private completeJob(): void {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          if (this.selectedJobIdToComplete) {
            const completeEngineerRequestModel: CompleteEngineerRequest = new CompleteEngineerRequest()
            completeEngineerRequestModel.jobId = this.selectedJobIdToComplete
            completeEngineerRequestModel.id = this.selectedJobDocumentId
            completeEngineerRequestModel.completionNote = this.requestCompletionNote
            this.isLoading = true
            JobController.MarkEngineerRequestComplete(completeEngineerRequestModel)
              .then((res: [APIResponseCode, string]) => {
                if (res[0] === 'Success') {
                  if (this.selectedJobIdToComplete) {
                    this.completeJobDialog = false
                    this.requestCompletionNote = ''
                    this.removeTabData(
                      this.selectedJobIdToComplete,
                      this.selectedJobDocumentId,
                      this.selectedRequestType
                    )
                  }
                } else {
                  this.errorMessage = res[1]
                }
                this.isLoading = false
              })
              .catch((error) => {
                eventBus.$emit(
                  'errorHandler',
                  'Error submitting engineer request complete request, please try again',
                  true
                )
                this.isLoading = false
                if (error.response.statusText !== null) {
                  this.errorMessage = error.response.statusText
                }
              })
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validate(): Promise<boolean> {
    let result = true
    const validation: boolean = await this.$validator.validateAll('completeEngineerRequest')
    // set focus to non validate field
    if (!validation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = validation
    }
    return result
  }

  private timerCounter(request: EngineerRequestModel) {
    let diffInSecond = 0
    if (this.timeCounter === 1 && request.assignedAt) {
      const currentDateTime = new Date()
      const requestAddedAtTime = new Date(request.assignedAt.toString())
      const diff = currentDateTime.getTime() - requestAddedAtTime.getTime()
      diffInSecond = Math.round(diff / 1000)
    }
    return Shared.formatDuration(this.timeCounter + diffInSecond)
  }

  private startTimer() {
    this.timeCounter = 1
    this.isRunning = true
    if (!this.timer) {
      this.timer = setInterval(() => {
        if (this.timeCounter > 0) {
          this.timeCounter++
        } else {
          clearInterval(this.timer)
          this.resetTimer()
        }
      }, 1000)
    }
  }

  private stopTimer() {
    this.isRunning = false
    clearInterval(this.timer)
    this.timer = null
  }

  private resetTimer() {
    this.stopTimer()
    this.timeCounter = 0
    this.timerSecondes = 0
    this.timerMinutes = 0
  }

  private redirectToJob(engineerRequest: EngineerRequestModel) {
    // open timeline with relative card open.
    if (!Shared.isUserHasJobRights(engineerRequest.jobId)) {
      return
    }
    this.selectedJobIdToExpand = engineerRequest.jobId
    this.engineerRequestId = engineerRequest.id
    this.requestType =
      engineerRequest.requestCategory !== EngineeringRequestCategory[EngineeringRequestCategory.NewJob]
        ? engineerRequest.requestCategory
        : ''
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }

  private showEnginnerOnSiteIcon(engineerReachOnSiteTime: any): boolean {
    if (engineerReachOnSiteTime) {
      const now = moment(new Date())
      const onSiteTime = moment(engineerReachOnSiteTime)
      const duration = moment.duration(now.diff(onSiteTime))
      const days = duration.days()
      const hours = duration.hours()
      return days >= 1 || hours >= 1 ? false : true
    }
    return false
  }

  private jobIdValidation(event: any) {
    Shared.jobIdValidation(event)
  }

  private destroyed() {
    this.signalRHub.disconnect()
    Shared.passJobIdInHeader()
  }

  private get isUserSupervisor(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Finance' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager')
    )
  }

  private async userListForFiltering() {
    const usersList: UserModel[] = await this.getUsers()
    // bind default option
    if (usersList && !usersList.find((e) => e.id === null)) {
      usersList.splice(0, 0, this.getDefaultOption('All'))
    }
    this.userListForFilter = [...usersList]
  }

  private async getUserListToAssignRequest() {
    const usersList: UserModel[] = await this.getUsers()
    // bind default option
    if (usersList && !usersList.find((e) => e.id === null)) {
      usersList.splice(0, 0, this.getDefaultOption('Unassign'))
    }
    this.usersList = [...usersList]
  }

  private getDefaultOption(optionName: string): UserModel {
    const user = new UserModel()
    user.id = ''
    user.firstName = optionName
    user.lastName = ''
    return user
  }

  private async getUsers(): Promise<UserModel[]> {
    if (storeGetters.getUsers().length === 0) {
      // set users
      const users = await UserController.GetUserList()
      if (users) {
        storeMutations.setUsers(users)
      }
      return storeGetters.getUsers()
    } else {
      return storeGetters.getUsers()
    }
  }

  private get loggedInUserId(): string {
    return Store.Instance.state.SessionDetail.detailRecord.UserDetail.id
  }

  private get getJobTypes(): any {
    const jobTypeList: IJobType[] = [
      { jobType: 'HE', jobTypeDescription: 'Home Emergency' },
      { jobType: 'SI', jobTypeDescription: 'Site Investigation' },
      { jobType: 'US', jobTypeDescription: 'Underground Services' },
    ]
    if (this.userJobTypeRight && this.userJobTypeRight.length === 0) {
      return []
    }
    const jobTypeListItem: IJobType[] = []
    jobTypeList.forEach((item: IJobType) => {
      if (this.userJobTypeRight.includes(item.jobType)) {
        jobTypeListItem.push(item)
      }
    })
    if (jobTypeListItem.length > 1) {
      jobTypeListItem.unshift({ jobType: 'All', jobTypeDescription: 'All' })
    }
    return jobTypeListItem
  }

  private isFiltered(requestModel: EngineerRequestModel): boolean {
    return this.selectedJobType === 'All' || this.selectedJobType === this.returnJobType(requestModel.jobId)
      ? this.searchUserId === ''
        ? this.searchInput !== ''
          ? requestModel.jobId.includes(this.searchInput)
          : true
        : this.searchUserId === requestModel.assignedUserId
        ? this.searchInput !== ''
          ? requestModel.jobId.includes(this.searchInput)
          : true
        : false
      : false
  }

  private returnJobType(jobId: string) {
    const jobType = jobId.substr(0, 2)
    const jobTypeList = ['HE', 'SI', 'US']
    return jobTypeList.includes(jobType) ? jobType : 'HE'
  }
}
</script>

<style scoped>
.en-request-section >>> .en-request-detail .v-tabs {
  display: flex;
  height: 100%;
}
.en-request-section >>> .en-request-detail .v-tabs__bar {
  position: absolute;
  width: 330px;
}
.en-request-section >>> .en-request-detail .v-window-item .vb-content {
  max-height: calc(100vh - 158px);
  margin-bottom: 0 !important;
  min-height: 518px;
}
.en-request-section >>> .en-request-detail .v-tabs__items .v-tabs__content:before {
  content: '';
  position: absolute;
}
.en-request-section >>> .en-request-detail .v-window {
  margin-left: 330px;
  width: calc(100% - 330px);
  text-align: left;
  background-color: #fff;
}
.en-request-section >>> .en-request-detail .v-tabs__wrapper {
  overflow: visible;
  display: block;
}
.en-request-section >>> .en-request-detail .v-tabs__container {
  white-space: inherit;
  display: block;
  transform: inherit !important;
  height: auto;
}
.en-request-section >>> .en-request-detail .v-tabs__div {
  width: 100%;
  max-width: 100%;
}
.en-request-section {
  height: 100%;
  position: relative;
}
.en-request-section >>> .en-request-detail .v-tabs__item--active,
.en-request-section >>> .en-request-detail .v-tabs__item--active i,
.en-request-section >>> .en-request-detail .v-tabs__div a:hover,
.en-request-section >>> .en-request-detail .v-tabs__div a:hover i {
  background: #efefef !important;
  border-left: 3px solid #e91e63;
}
.en-request-section >>> .en-request-detail .v-tabs__bar {
  background-color: transparent;
  z-index: 2;
}
.en-request-section >>> .en-request-detail .v-tabs__bar .v-tabs__div a {
  text-align: left;
  display: inline-block;
  padding: 15px 15px;
  background: #f7f7f7;
  margin-bottom: 10px;
}
.en-request-section >>> .en-request-detail .v-window-item .v-list > div:last-child .v-list__tile {
  border-bottom: none;
}
.en-request-section >>> .en-request-detail .v-tabs__content {
  transition: none !important;
}
.tab-wrapper {
  padding: 5px 0px 10px 10px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
}
.tab-wrapper.active {
  -webkit-box-shadow: 0px 2px 8px 0px #cfcfcf;
  box-shadow: 0px 2px 8px 0px #cfcfcf;
}
.tab-wrapper span {
  font-size: 15px;
  color: #9a9a9a;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 5px;
}
.tab-wrapper.active span {
  color: #e91e63;
}
.en-request-section >>> .en-request-detail .v-card {
  margin-bottom: 20px !important;
}
.request-list {
  background: #efefef !important;
  padding: 0px 15px !important;
}
.request-list >>> .v-list__tile {
  padding: 20px 0px 15px;
  height: auto;
  border-bottom: 1px solid #d6d3d3;
  font-size: 14px;
}
.request-details {
  width: 100%;
  position: relative;
}
.request-details .jobnumber {
  position: relative;
  padding-right: 80px;
}
.request-details .request-time {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 16px;
}
.request-time >>> .v-icon {
  color: inherit !important;
}
.request-time >>> .timer {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}
.request-time {
  text-align: right;
}
.request-details label {
  font-weight: 600;
}
.request-details .action {
  right: 0px;
}
.request-details .action-engineer {
  position: absolute;
  right: 0;
  bottom: -10px;
}
.request-details .countdown-time {
  position: relative;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.address-wrap {
  display: inline-flex;
  flex-wrap: wrap;
}
.img-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
.img-icon img {
  width: 100%;
  height: 100%;
}
.inner-request-details >>> .v-icon {
  font-size: 16px;
  margin-right: 6px;
}
.inner-request-details .img-icon {
  width: 16px;
  height: 16px;
}
.supervisor-assign {
  display: flex;
}
.supervisor-assign >>> .v-btn {
  align-self: flex-end;
}
.request-icon {
  position: absolute;
  left: -29px;
  top: 0px;
}
@media only screen and (max-width: 1370px) {
  .phone-number {
    display: block;
  }
}
.en-request-search {
  max-width: 500px;
}
.nodata-text {
  font-size: 24px;
  text-align: center;
  padding: 20px;
  color: #aeaeae;
}
.engineer-time {
  width: 22px;
  right: -2px;
}
.common-error-message {
  color: #ff5252 !important;
}
</style>
