import { IAgentAssistState } from '../states/interfaces/agent-assist-state'
import AgentAssistState from '../states/agent-assist-state'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import AssistedPaymentDetails from '@/models/twilio/assistedPay/AssistedPaymentDetails'

const agentAssistState: IAgentAssistState = new AgentAssistState()

const getters: GetterTree<IAgentAssistState, any> = {
  token: (state): string | null => (state.assistedPaymentDetails ? state.assistedPaymentDetails.paymentToken : null),
  active: (state): boolean => (state.assistedPaymentDetails ? state.assistedPaymentDetails.active : false),
  brand: (state): string | null => (state.assistedPaymentDetails ? state.assistedPaymentDetails.brand : null),
  last4: (state): string | null => (state.assistedPaymentDetails ? state.assistedPaymentDetails.last4 : null),
  paymentDetailErrors: (state): string[] =>
    state.assistedPaymentDetails ? state.assistedPaymentDetails.paymentDetailErrors.map((x) => x.message) : [],
  complete: (state): boolean => (state.assistedPaymentDetails ? state.assistedPaymentDetails.isPaymentComplete : false),
}

const mutations: MutationTree<IAgentAssistState> = {
  initiate: (state, details: AssistedPaymentDetails) => {
    state.assistedPaymentDetails = details
  },
  token: (state, token: string) => {
    if (state.assistedPaymentDetails) {
      state.assistedPaymentDetails.paymentToken = token
    }
  },
  last4: (state, last4: string) => {
    if (state.assistedPaymentDetails) {
      state.assistedPaymentDetails.last4 = last4
    }
  },
  brand: (state, brand: string) => {
    if (state.assistedPaymentDetails) {
      state.assistedPaymentDetails.brand = brand
    }
  },
  complete: (state, complete: boolean) => {
    if (state.assistedPaymentDetails) {
      state.assistedPaymentDetails.isPaymentComplete = complete
    }
  },
  reset: (state) => {
    if (state.assistedPaymentDetails) {
      state.assistedPaymentDetails.reset()
    }
  },
  clear: (state) => {
    state.assistedPaymentDetails = null
  },
}

const actions: ActionTree<IAgentAssistState, any> = {
  initiate({ commit }, paymentComplete: boolean) {
    const details = new AssistedPaymentDetails(paymentComplete)
    commit('initiate', details)
  },
}

export const agentAssistModule: Module<IAgentAssistState, any> = {
  state: agentAssistState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
