import moment from 'moment'

export default class AddEmergency {
  public jobId: string // can be blank/undefined if adding as part of a new job
  public policyId: number
  public emergencyTypeId: number
  public emergencyTypeDesc: string
  public emergencyDetailId: number | null
  public emergencyDetailDesc: string
  public tradeId: number | undefined
  public tradeDesc: string
  public note: string
  public fileURL: string | null
  public firstNoticedAtUtc: moment.Moment | null
  public excessValue: number

  public constructor() {
    this.firstNoticedAtUtc = null
    this.emergencyDetailId = null
  }
}
