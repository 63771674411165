<template>
  <div>
    <v-dialog v-model="siRevisitDialog" max-width="650" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Revisit</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-btn" @click.native="$emit('closeReturnVisitDialog')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-layout wrap mb-1>
            <v-flex xs6 pr-2>
              <DateTimePicker
                ref="ETAFromDateTimePicker"
                :date-time.sync="addSIRevisitRequest.returnVisitETAFrom"
                :is-static-location="false"
                place-holder-text="Revisit ETA From"
                :min-date="minETADate"
                :is-validation-required="true"
                :allowed-minutes-step="true"
              />
            </v-flex>
            <v-flex xs6 pl-2>
              <DateTimePicker
                ref="ETATodateTimePicker"
                :date-time.sync="addSIRevisitRequest.returnVisitETATo"
                :is-static-location="false"
                place-holder-text="Revisit ETA To"
                :min-date="minETAToDate"
                :is-validation-required="true"
                :allowed-minutes-step="true"
              />
            </v-flex>
            <v-flex v-if="isWrongDateRange" xs12 mb-2>
              <span class="error--text">To date must be greater than from date.</span>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="slt-engineerList">
              <v-autocomplete
                ref="engineerPicker"
                v-model="selectedEngineerId"
                v-validate="'required'"
                :items="engineerList"
                label="Select Engineer"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: 'auto' }"
                chips
                required
                class="required engineer-list"
                data-vv-scope="siRevisitformReference"
                data-vv-name="Select Engineer"
                :error-messages="$validator.errors.collect('Select Engineer')"
                :disabled="!addSIRevisitRequest.returnVisitETAFrom || !addSIRevisitRequest.returnVisitETATo"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip :key="JSON.stringify(data.item)" :selected="data.selected" class="chip--select-multi">
                    <v-avatar>
                      <img v-if="data.item.profileImageThumbnailUrl" :src="data.item.profileImageThumbnailUrl" />
                      <img v-else src="/img/unknownuser.png" />
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template slot="item" slot-scope="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-tile-content v-text="data.name"></v-list-tile-content>
                  </template>
                  <template v-else>
                    <v-list-tile-avatar>
                      <img v-if="data.item.profileImageThumbnailUrl" :src="data.item.profileImageThumbnailUrl" />
                      <img v-else src="/img/unknownuser.png" />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ data.item.name }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout wrap mb-1>
            <v-flex xs12>
              <v-flex class="slt-returnTypeVisit">
                <v-select
                  v-model="addSIRevisitRequest.returnVisitReason"
                  v-validate="'required'"
                  :items="returnVisitTypeList"
                  item-text="description"
                  item-value="description"
                  label="What is the type for return visit"
                  required
                  data-vv-scope="siRevisitformReference"
                  data-vv-name="Return Visit Type"
                  :error-messages="errors.collect('Return Visit Type')"
                  class="required"
                  name="reVisitReason"
                  @change="onReturnVisitTypeChange"
                >
                  <template slot="item" slot-scope="data">
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                          {{ data.item.description }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-select>
              </v-flex>
            </v-flex>
          </v-layout>
          <v-layout wrap mb-3>
            <v-flex xs12>
              <v-textarea
                v-model="addSIRevisitRequest.returnVisitNote"
                v-validate="'required'"
                label="Note"
                rows="5"
                required
                data-vv-scope="siRevisitformReference"
                data-vv-name="Note"
                :error-messages="errors.collect('Note')"
                class="required"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="$emit('closeReturnVisitDialog')">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-submit"
            :disabled="onSubmitLoading || getEngineersLoading"
            :loading="onSubmitLoading"
            @click="submitSIRevisitRequest"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import AddSIRevisitRequest from '@/models/requests/AddSIRevisitRequest'
import MasterRecord from '@/models/MasterRecord'
import SiteInvestigationController from '@/api/siteInvestigationController'
import DateTimePicker from '@/components/DateTimePicker.vue'
import moment from 'moment'
import Shared from '@/common/shared'
import EngineerController from '@/api/engineerController'
import EngineerModel from '@/models/contractor/EngineerModel'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import eventBus from '@/common/bus'

@Component({
  components: { DateTimePicker },
})
export default class SIRevisit extends Vue {
  @Prop() private returnVisitTypeList: MasterRecord[]
  @Prop() private jobId: string
  @Prop() private engineerVisitDetail: EngineerVisitDetail

  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText: string | null = ''
  private isWrongDateRange = false
  private addSIRevisitRequest: AddSIRevisitRequest = new AddSIRevisitRequest()
  private minETADate: string = moment().format(DateTimePicker.DATE_FORMAT)
  private onSubmitLoading = false
  private siRevisitDialog = true
  private engineerList: EngineerModel[] = []
  private tempEngineerList: EngineerModel[] = []
  private selectedEngineerId = ''
  private getEngineersLoading = false

  public resetReturnVisitReason() {
    this.addSIRevisitRequest.returnVisitReason = ''
  }

  private mounted() {
    if (this.engineerVisitDetail) {
      this.getEngineerList()
    }
  }

  private submitSIRevisitRequest() {
    this.validateDates()
      .then((validate: boolean) => {
        if (validate) {
          return this.validate('siRevisitformReference')
        }
        return false
      })
      .then((result: boolean) => {
        if (result && !this.isWrongDateRange) {
          this.onSubmitLoading = true
          this.addSIRevisitRequest.jobId = this.jobId
          this.addSIRevisitRequest.engineerVisitDetailId = this.engineerVisitDetail.id
          this.addSIRevisitRequest.engineerId = this.selectedEngineerId
          SiteInvestigationController.AddSIRevisitRequest(this.addSIRevisitRequest)
            .then((res: boolean) => {
              if (res) {
                this.$emit('closeReturnVisitDialog')
              } else {
                this.snackbar = true
                this.snackbarText = 'Something went wrong. Please try again.'
              }
              this.onSubmitLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error submitting revisit request, please try again', true)
              this.onSubmitLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private onReturnVisitTypeChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.$emit('openConfigureDropDown')
    }
  }

  private async validateDates(): Promise<boolean> {
    this.isWrongDateRange = false
    const etaFromDateTimePicker = this.$refs.ETAFromDateTimePicker as DateTimePicker
    const etaToDateTimePicker = this.$refs.ETATodateTimePicker as DateTimePicker
    const etaFromDateTimePickerResult: boolean = await etaFromDateTimePicker.$validator.validateAll()
    const etaToDateTimePickerResult: boolean = await etaToDateTimePicker.$validator.validateAll()
    if (!etaFromDateTimePickerResult) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    if (
      this.addSIRevisitRequest.returnVisitETAFrom &&
      this.addSIRevisitRequest.returnVisitETATo &&
      this.addSIRevisitRequest.returnVisitETAFrom > this.addSIRevisitRequest.returnVisitETATo
    ) {
      this.isWrongDateRange = true
    }
    if (etaFromDateTimePickerResult === false) {
      return etaFromDateTimePickerResult
    } else {
      return etaToDateTimePickerResult
    }
  }

  private async validate(scope: string): Promise<boolean> {
    let result = true
    result = await this.$validator.validateAll(scope)

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private get minETAToDate() {
    return moment(this.addSIRevisitRequest.returnVisitETAFrom).format(DateTimePicker.DATE_FORMAT)
  }

  private getEngineerList(): void {
    this.getEngineersLoading = true
    EngineerController.GetContractorEngineers(this.engineerVisitDetail.contractorId)
      .then((res: EngineerModel[]) => {
        // check engineers availability
        const engineerList: EngineerModel[] = res.filter((e) => e.engineerAvailability !== null)
        if (this.engineerVisitDetail && this.engineerVisitDetail.tradeId) {
          this.engineerList = engineerList.filter(
            (e: EngineerModel) =>
              e.engineerAvailability.findIndex(
                (t: EngineerAvailabilityModel) => t.tradeId === Number(this.engineerVisitDetail.tradeId)
              ) > -1 || e.id === null
          )
        }
        this.engineerList.forEach((element) => {
          element.name = element.firstName + ' ' + element.lastName
        })
        this.tempEngineerList = JSON.parse(JSON.stringify(this.engineerList))
        const engineer = this.engineerList.find((e) => e.id === this.engineerVisitDetail.engineerId)
        this.selectedEngineerId = engineer && engineer.id ? engineer.id : ''
        this.getEngineersLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading engineer(s), please try again', true)
        this.getEngineersLoading = false
      })
  }

  private checkEngineerAvailability(
    etaFrom: moment.Moment | null,
    etaTo: moment.Moment | null,
    engineer: EngineerModel
  ) {
    // check engineer availability compared to job eta.
    let isEngineerAvailable = false
    if (etaFrom && etaTo) {
      if (engineer) {
        if (
          engineer.outsideToDate &&
          engineer.outsideFromDate &&
          moment(engineer.outsideToDate).isValid() &&
          moment(engineer.outsideFromDate).isValid()
        ) {
          const etaFromDate: string = moment(etaFrom).toISOString().slice(0, 10)
          const outsideFromDate: string = moment(engineer.outsideFromDate).toISOString().slice(0, 10)
          const outsideToDate: string = moment(engineer.outsideToDate).toISOString().slice(0, 10)
          if (etaFromDate === outsideFromDate || etaFromDate === outsideToDate) {
            isEngineerAvailable = false
          } else {
            isEngineerAvailable = etaFrom.isBetween(engineer.outsideFromDate, engineer.outsideToDate) ? false : true
          }
        } else {
          isEngineerAvailable = true
        }
      }
    }
    return isEngineerAvailable
  }

  @Watch('addSIRevisitRequest.returnVisitETAFrom')
  @Watch('addSIRevisitRequest.returnVisitETATo')
  private onETAChange() {
    if (this.addSIRevisitRequest.returnVisitETAFrom && this.addSIRevisitRequest.returnVisitETATo) {
      this.validateDates().then((validate: boolean) => {
        if (validate && !this.isWrongDateRange) {
          // set engineer list based on availability
          if (this.tempEngineerList.length > 0) {
            this.engineerList = []
            this.tempEngineerList.forEach((element) => {
              const isEngineerAvailable = this.checkEngineerAvailability(
                this.addSIRevisitRequest.returnVisitETAFrom,
                this.addSIRevisitRequest.returnVisitETATo,
                element
              )
              if (isEngineerAvailable) {
                this.engineerList.push(element)
              }
            })
            const engineer = this.engineerList.find((e) => e.id === this.selectedEngineerId)
            this.selectedEngineerId = engineer ? this.selectedEngineerId : ''
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.bold-select {
  font-weight: 600;
}
</style>
