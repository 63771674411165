<template>
  <v-layout v-if="policyQuestion" wrap>
    <v-flex xs12 class="pt-0 pb-1">
      <v-textarea
        v-model.trim="policyQuestion.overWrittenText"
        v-validate="'required|max:500'"
        maxlength="500"
        data-vv-scope="questionformRef"
        data-vv-name="OverwrittenText"
        :error-messages="errors.collect('OverwrittenText')"
        class="required"
        required
      ></v-textarea>
    </v-flex>
    <v-layout wrap class="grey lighten-3 pa-2 mb-3">
      <v-flex xs12 class="switch-width-auto">
        <v-switch
          v-model="policyQuestion.requiredForValidation"
          hide-details
          color="primary"
          @change="requiredForValidationChange"
        ></v-switch>
        <span class="mt-2 ml-2">Set Validation rules</span>
      </v-flex>

      <v-flex xs12 grey lighten-2 px-2 pt-2>
        <label class="caption blue--text" :class="policyQuestion.requiredForValidation ? '' : 'text--lighten-2'">
          A claim must meet this following validation rule set up to be valid.
        </label>
        <v-select
          v-model="policyQuestion.operatorId"
          v-validate="policyQuestion.requiredForValidation ? 'required' : ''"
          :items="getOperatorsByAnswerType"
          label="Select"
          :disabled="!policyQuestion.requiredForValidation"
          item-text="name"
          item-value="id"
          data-vv-scope="questionformRef"
          data-vv-name="OperatorId"
          :error-messages="errors.collect('OperatorId')"
          :class="policyQuestion.requiredForValidation ? 'required' : ''"
          :required="policyQuestion.requiredForValidation ? true : false"
        ></v-select>
      </v-flex>

      <v-flex xs12 grey lighten-2 px-2 pb-2 mb-2>
        <v-text-field
          v-if="
            policyQuestion.question.answerTypeId === answerType.Text ||
            policyQuestion.question.answerTypeId === answerType.Number
          "
          v-model.trim="policyQuestion.comparisonValue"
          v-validate="{
            rules: {
              required: policyQuestion.mandatory,
              decimal: policyQuestion.question.answerTypeId === answerType.Number,
              max: 200,
            },
          }"
          label="Comparison Value"
          maxlength="200"
          :hint="
            policyQuestion.question.unit ? 'Comparison value should be in ' + policyQuestion.question.unit + '.' : ''
          "
          :disabled="!policyQuestion.requiredForValidation"
          data-vv-scope="questionformRef"
          data-vv-name="ComparisonValue"
          :class="policyQuestion.requiredForValidation ? 'required' : ''"
          :error-messages="errors.collect('ComparisonValue')"
          :required="policyQuestion.requiredForValidation ? true : false"
        ></v-text-field>
        <v-select
          v-if="policyQuestion.question.answerTypeId === answerType.Dropdown"
          v-model.trim="policyQuestion.comparisonValue"
          v-validate="policyQuestion.requiredForValidation ? 'required|max:200' : 'max:200'"
          :items="getdropdownOptions"
          label="Comparison Value"
          maxlength="200"
          :multiple="policyQuestion.operatorId === operatorType.In || policyQuestion.operatorId === operatorType.NotIn"
          :chips="policyQuestion.operatorId === operatorType.In || policyQuestion.operatorId === operatorType.NotIn"
          :disabled="!policyQuestion.requiredForValidation"
          data-vv-scope="questionformRef"
          data-vv-name="ComparisonValue"
          :error-messages="errors.collect('ComparisonValue')"
          :required="policyQuestion.requiredForValidation ? true : false"
          :class="policyQuestion.requiredForValidation ? 'required' : ''"
          @change="onDropdpwnChange($event, policyQuestion)"
        ></v-select>
        <v-select
          v-if="policyQuestion.question.answerTypeId === answerType.Checkbox"
          v-model.trim="policyQuestion.comparisonValue"
          v-validate="policyQuestion.requiredForValidation ? 'required|max:200' : 'max:200'"
          :items="getcheckboxOptions"
          label="Comparison Value"
          maxlength="200"
          :disabled="!policyQuestion.requiredForValidation"
          data-vv-scope="questionformRef"
          data-vv-name="ComparisonValue"
          :error-messages="errors.collect('ComparisonValue')"
          :required="policyQuestion.requiredForValidation ? true : false"
          :class="policyQuestion.requiredForValidation ? 'required' : ''"
        ></v-select>
      </v-flex>

      <v-flex xs12 grey lighten-2 px-2 pt-2>
        <label class="caption blue--text" :class="policyQuestion.requiredForValidation ? '' : 'text--lighten-2'">
          If the answer to this questions makes the claim not valid, use this repudiation reason.
        </label>
        <v-autocomplete
          v-model="policyQuestion.repudiationId"
          v-validate="'required'"
          label="Repudiation reason"
          :items="repudiations"
          item-text="description"
          item-value="id"
          :disabled="!policyQuestion.requiredForValidation"
          :required="policyQuestion.requiredForValidation ? true : false"
          :class="policyQuestion.requiredForValidation ? 'required' : ''"
          data-vv-scope="questionformRef"
          data-vv-name="Repudiation reason"
          :error-messages="errors.collect('Repudiation reason')"
        ></v-autocomplete>
      </v-flex>
      <v-flex v-if="repudiationMessage" xs12 grey lighten-2 px-2 pb-2 mb-2>
        <label>
          <b>Repudiation message:</b>
          <span>{{ repudiationMessage }}</span>
        </label>
      </v-flex>
    </v-layout>
    <v-layout wrap class="grey lighten-3 pa-2">
      <v-flex xs12 class="switch-width-auto">
        <v-switch
          v-model="policyQuestion.hasComment"
          hide-details
          color="primary"
          @change="requiredForCommentChange"
        ></v-switch>
        <span class="mt-2 ml-2">Get Additional Information</span>
      </v-flex>
      <v-flex xs12>
        <v-select
          v-model="policyQuestion.forCommentOperatorId"
          v-validate="policyQuestion.hasComment ? 'required' : ''"
          :items="getOperatorsByAnswerType"
          label="Select"
          :disabled="!policyQuestion.hasComment"
          item-text="name"
          item-value="id"
          data-vv-scope="questionformRef"
          data-vv-name="CommentOperatorId"
          :error-messages="errors.collect('CommentOperatorId')"
          :required="policyQuestion.hasComment ? true : false"
          :class="policyQuestion.hasComment ? 'required' : ''"
        ></v-select>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-if="
            policyQuestion.question.answerTypeId === answerType.Text ||
            policyQuestion.question.answerTypeId === answerType.Number
          "
          v-model.trim="policyQuestion.forCommentComparisonValue"
          v-validate="{
            rules: {
              required: policyQuestion.mandatory,
              decimal: policyQuestion.question.answerTypeId === answerType.Number,
              max: 200,
            },
          }"
          label="Comment Comparison value"
          maxlength="200"
          :hint="
            policyQuestion.question.unit
              ? 'Comment Comparison value should be in ' + policyQuestion.question.unit + '.'
              : ''
          "
          :disabled="!policyQuestion.hasComment"
          data-vv-scope="questionformRef"
          data-vv-name="CommentComparisonValue"
          :error-messages="errors.collect('CommentComparisonValue')"
          :required="policyQuestion.hasComment ? true : false"
          :class="policyQuestion.hasComment ? 'required' : ''"
        ></v-text-field>
        <v-select
          v-if="policyQuestion.question.answerTypeId === answerType.Dropdown"
          v-model.trim="policyQuestion.forCommentComparisonValue"
          v-validate="policyQuestion.hasComment ? 'required|max:200' : 'max:200'"
          :items="getdropdownOptions"
          label="Comment Comparison Value"
          maxlength="200"
          :multiple="
            policyQuestion.forCommentOperatorId === operatorType.In ||
            policyQuestion.forCommentOperatorId === operatorType.NotIn
          "
          :chips="
            policyQuestion.forCommentOperatorId === operatorType.In ||
            policyQuestion.forCommentOperatorId === operatorType.NotIn
          "
          :disabled="!policyQuestion.hasComment"
          data-vv-scope="questionformRef"
          data-vv-name="CommentComparisonValue"
          :error-messages="errors.collect('CommentComparisonValue')"
          :required="policyQuestion.hasComment ? true : false"
          :class="policyQuestion.hasComment ? 'required' : ''"
        ></v-select>
        <v-select
          v-if="policyQuestion.question.answerTypeId === answerType.Checkbox"
          v-model.trim="policyQuestion.forCommentComparisonValue"
          v-validate="policyQuestion.hasComment ? 'required|max:200' : 'max:200'"
          :items="getcheckboxOptions"
          label="Comment Comparison Value"
          maxlength="200"
          :disabled="!policyQuestion.hasComment"
          data-vv-scope="questionformRef"
          data-vv-name="CommentComparisonValue"
          :error-messages="errors.collect('CommentComparisonValue')"
          :required="policyQuestion.hasComment ? true : false"
          :class="policyQuestion.hasComment ? 'required' : ''"
        ></v-select>
      </v-flex>
      <v-flex xs12>
        <v-textarea
          v-model.trim="policyQuestion.commentLabel"
          v-validate="policyQuestion.hasComment ? 'required|max:500' : 'max:500'"
          label="Comment label"
          maxlength="500"
          :disabled="!policyQuestion.hasComment"
        ></v-textarea>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PolicyQuestionModel from '@/models/policy/PolicyQuestionModel'
import Shared from '@/common/shared'
import { Operator } from '@/common/interfaces'
import { AnswerType, OperatorType } from '@/common/enums'
import RepudiationModel from '@/models/policyHolder/RepudiationModel'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import QuestionTradeAssignmentModel from '@/models/policy/QuestionTradeAssignmentModel'
import eventBus from '@/common/bus'
@Component
export default class EditPolicyQuestion extends Vue {
  @Prop() private question: PolicyQuestionModel | null
  @Prop() private repudiations: RepudiationModel[]

  private operators: Operator[]
  private policyQuestion: PolicyQuestionModel | null = null
  private answerType = AnswerType
  private checkboxOptions: string[] = ['Yes', 'No']
  private repudiationMessage = ''
  private operatorType = OperatorType

  private get getcheckboxOptions(): string[] {
    return this.checkboxOptions
  }

  public saveQuestion(): void {
    const self = this
    this.validateQuestion()
      .then((result: boolean) => {
        if (result) {
          if (
            this.policyQuestion &&
            this.policyQuestion.comparisonValue &&
            typeof this.policyQuestion.comparisonValue === 'object'
          ) {
            const arrayItems: string[] = this.policyQuestion.comparisonValue
            const items: string = arrayItems.join()
            Vue.set(this.policyQuestion, 'comparisonValue', items)
          }
          this.$emit('setQuestionDetail', this.policyQuestion)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private created(): void {
    this.operators = Shared.getOperatorList()
    this.policyQuestion = this.question
  }

  private get getOperatorsByAnswerType(): Operator[] {
    let operators: Operator[] = this.operators
    switch (this.policyQuestion && this.policyQuestion.question.answerTypeId) {
      case AnswerType.Text:
        // get 'equalto', 'not equal to', 'in' and 'not in' operators only
        operators = operators.filter(
          (c) =>
            c.id === OperatorType.EqualTo ||
            c.id === OperatorType.NotEqualTo ||
            c.id === OperatorType.In ||
            c.id === OperatorType.NotIn
        )
        return operators
      case AnswerType.Dropdown:
        // get 'equalto', 'not equal to', 'in' and 'not in' operators only
        operators = operators.filter(
          (c) =>
            c.id === OperatorType.EqualTo ||
            c.id === OperatorType.NotEqualTo ||
            c.id === OperatorType.In ||
            c.id === OperatorType.NotIn
        )
        return operators
      case AnswerType.Number:
        // get all operators
        return operators
      case AnswerType.Checkbox:
        // get 'equalto'and 'not equal to' only
        operators = operators.filter((c) => c.id === OperatorType.EqualTo || c.id === OperatorType.NotEqualTo)
        return operators
      default:
        // set to default all operators
        return operators
    }
  }

  private requiredForValidationChange(value: boolean): void {
    if (this.policyQuestion) {
      if (!value) {
        this.policyQuestion.operatorId = null
        this.policyQuestion.comparisonValue = null
        this.policyQuestion.repudiationId = null
        this.repudiationMessage = ''
      }
    }
  }

  private requiredForCommentChange(value: boolean): void {
    if (this.policyQuestion) {
      if (!value) {
        this.policyQuestion.forCommentOperatorId = null
        this.policyQuestion.forCommentComparisonValue = ''
        this.policyQuestion.commentLabel = ''
      }
    }
  }

  private async validateQuestion(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('questionformRef')

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private get getdropdownOptions(): string[] {
    if (this.policyQuestion) {
      return this.dropdownOptions(this.policyQuestion.question.additionalValues)
    }
    return []
  }

  private dropdownOptions(additionalValues: string | null): string[] {
    if (additionalValues) {
      const items: string[] = additionalValues.split('\n')
      if (items && items.length > 0) {
        for (let i = 0, l = items.length; i < l; i++) {
          items[i] = items[i].trim()
        }
        return items
      }
    }
    return []
  }

  @Watch('policyQuestion.repudiationId')
  private onRepudiationChange(id: number) {
    const element: RepudiationModel | undefined = this.repudiations.find((e: RepudiationModel) => e.id === id)
    if (element) {
      this.repudiationMessage = element.message
    }
  }

  private onDropdpwnChange(event: string[], policyQuestion: PolicyQuestionModel) {
    if (typeof event === 'object') {
      const items = event.join()
      Vue.set(policyQuestion, 'comparisonValue', items)
    }
  }
}
</script>

<style scoped>
.switch-width-auto >>> .v-input--switch {
  display: inline-block;
  width: auto;
  margin: 0 0 10px;
}
.switch-width-auto > span {
  vertical-align: top;
  display: inline-block;
  line-height: 1;
  color: #949494;
}
</style>
