<template>
  <v-flex xs12>
    <v-card class="pt-0 px-2 pb-2 up-card">
      <v-card-title>
        <h3 class="heading">Delay Details</h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-2">
        <v-layout wrap>
          <v-flex xs12>
            <v-select
              v-model="delay.reason"
              v-validate="'required'"
              :items="delayReasonList"
              item-text="description"
              item-value="description"
              label="Reason"
              required
              class="required"
              data-vv-name="Delay Reason"
              :error-messages="errors.collect('Delay Reason')"
              :loading="loading.delayReasonList"
              @change="onChangeDelayReason"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                      {{ data.item.description }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-textarea v-model="delay.notes" label="Note" rows="3"></v-textarea>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-3">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-0 btn-save" :loading="loading.saveReason" @click="saveDelayReason">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureDropdown
        ref="configureDropdown"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import { RecordType } from '@/common/enums'
import MasterRecord from '@/models/MasterRecord'
import store from '@/store'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import MasterRecordController from '@/api/masterRecordController'
import Shared from '@/common/shared'
import SiteInvestigationController from '@/api/siteInvestigationController'
import UpsertSIJobDelay from '@/models/siteInvestigation/requests/UpsertSIJobDelay'
import JobDelay from '../../models/JobDelay'
import eventBus from '@/common/bus'

@Component({
  components: { ConfigureDropdown },
})
export default class JobDelayPreview extends TimeLineItemBase {
  private delay: JobDelay = new JobDelay()
  private configureDropdownDialog = false
  private configureDropdownType = ''
  private delayReasonList: MasterRecord[] = []
  private loading: Record<string, boolean> = {
    delayReasonList: false,
    saveReason: false,
  }
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''

  private created() {
    this.getDelayReasonList()
    this.delay = Object.assign(
      new JobDelay(),
      storeGetters.getSIJobDelay(this.jobId) || storeGetters.getUSJobDelay(this.jobId) || new JobDelay()
    )
  }

  private async getDelayReasonList() {
    try {
      this.$set(this.loading, 'delayReasonList', true)
      const recordType: string =
        RecordType[this.job && this.job.jobType === 'SI' ? RecordType.SIJobDelayReason : RecordType.USJobDelayReason]
      const res = await MasterRecordController.GetMasterRecords(recordType)
      if (res) {
        this.delayReasonList = res.filter(
          (e) =>
            e.isDeleted === false ||
            (e.isDeleted
              ? this.delay && this.delay.reason && e.description.toLowerCase() === this.delay.reason.toLowerCase()
              : false)
        )
        if (this.isUserRoleAdministrator) {
          const delayReasonConfigure: any = {}
          delayReasonConfigure.description = 'Configure'
          this.delayReasonList.push(delayReasonConfigure)
        }
      }
      this.$set(this.loading, 'delayReasonList', false)
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading delay reason list, please try again', true)
      this.$set(this.loading, 'delayReasonList', false)
    }
  }

  private onChangeDelayReason(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType =
        RecordType[this.job && this.job.jobType === 'SI' ? RecordType.SIJobDelayReason : RecordType.USJobDelayReason]
      this.configureDropdownDialog = true
    }
  }

  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    this.delay.reason = ''
    const deletedRecord: MasterRecord | undefined = this.delayReasonList.find((e) => e.isDeleted === true)
    this.delayReasonList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.delayReasonList.push(deletedRecord)
    }
    if (this.isUserRoleAdministrator) {
      const delayReasonConfigure: any = {}
      delayReasonConfigure.description = 'Configure'
      this.delayReasonList.push(delayReasonConfigure)
    }
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll()
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private async saveDelayReason() {
    try {
      const result = await this.validate()
      if (result) {
        this.$set(this.loading, 'saveReason', true)
        const upsertSIJobDelay: UpsertSIJobDelay = new UpsertSIJobDelay()
        if (this.delay.id) {
          upsertSIJobDelay.id = this.delay.id
        }
        upsertSIJobDelay.jobId = this.jobId
        upsertSIJobDelay.reason = this.delay.reason
        upsertSIJobDelay.notes = this.delay.notes
        const res = await SiteInvestigationController.UpsertSIJobDelay(upsertSIJobDelay)
        if (res) {
          this.snackbarText = 'Delay Reason Saved Successfully.'
          this.snackbar = true
        }
        this.$set(this.loading, 'saveReason', false)
      }
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error saving delay reason, please try again', true)
      this.$set(this.loading, 'saveReason', false)
    }
  }

  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }
}
</script>

<style scoped>
.bold-select {
  font-weight: 600;
}
</style>
