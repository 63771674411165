<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import SessionController from '@/api/sessionController'
import JobController from '@/api/jobController'
import RequestProcessedRecord from '@/signalr/jobModels/requestProcessedRecord'
import OutstandingJobModel from '@/models/OutstandingJobModel'
import {
  JobVisitStatus,
  EscalationTypeEnum,
  VulnerabilityLevelEnum,
  WIPDashboardSortingTypeEnum,
  VisitTypeEnum,
  JobDateFilterType,
} from '@/common/enums'
import { ContractorOperation } from '@/models/types'
import EngineerModel from '@/models/contractor/EngineerModel'
import moment from 'moment'
import TradeModel from '@/models/policyHolder/TradeModel'
import eventBus from '@/common/bus'
import Shared from '../common/shared'

export type SignalRChildPage = 'ContractorManagement' | 'OutstandingJobDashboard'

@Component
export default class ContractorManagementHub extends Vue {
  public childPage: SignalRChildPage
  public contractorManagementHub: SignalRHubConnection = new SignalRHubConnection('contractorManagement')
  // this hub require to get data when all contractor option selected
  public outStandingJobDashboardHub: SignalRHubConnection = new SignalRHubConnection('outstandingJobDashboard')
  public contractorJobs: ContractorAppointedModel[] = []
  public selectedContractorId = '' // for contractorManagement
  public liveSync = false
  public interval: any = null
  public pendingJobList: ContractorAppointedModel[] = []
  public acceptedJobList: ContractorAppointedModel[] = []
  public inProgressJobList: ContractorAppointedModel[] = []
  public completedJobList: ContractorAppointedModel[] = []
  public jobVisitStatus = JobVisitStatus
  public tempAppointedContractor: ContractorAppointedModel = new ContractorAppointedModel()
  public totalPendingJobs = 0
  public totalAcceptedJobs = 0
  public totalInProgressJobs = 0
  public totalCompletedJobs = 0
  public engineers: EngineerModel[] = []
  public trades: TradeModel[] = []
  public jobsTradeList: any = []
  public currentIndex = -1
  // this hub require to get engineer data when engineer availability changed from App
  public engineerAvailabilityHub: SignalRHubConnection = new SignalRHubConnection('engineerAvailabilityHub')
  public outstandingJobData: ContractorAppointedModel[] = []
  public jobsFilter: {
    contractorId: string
    teamName: string
    jobEscalationType: EscalationTypeEnum
    vulnerabilityLevel: VulnerabilityLevelEnum
    sortingType: WIPDashboardSortingTypeEnum
    visitType: string
    jobDateFilterType: JobDateFilterType
  } = {
    contractorId: '',
    teamName: '',
    jobEscalationType: EscalationTypeEnum.All,
    vulnerabilityLevel: VulnerabilityLevelEnum.All,
    sortingType: WIPDashboardSortingTypeEnum.EtaStart,
    visitType: VisitTypeEnum[VisitTypeEnum.AllVisit],
    jobDateFilterType: JobDateFilterType.TodayJobs,
  }
  private todayDate: moment.Moment = moment()

  public destroyed() {
    if (this.contractorManagementHub != null) {
      this.contractorManagementHub.disconnect()
    }
    if (this.outStandingJobDashboardHub != null) {
      this.outStandingJobDashboardHub.disconnect()
    }
    if (this.childPage === 'OutstandingJobDashboard') {
      if (this.interval != null) {
        window.clearInterval(this.interval)
      }
    }
    if (this.engineerAvailabilityHub != null) {
      this.engineerAvailabilityHub.disconnect()
    }
    if (this.childPage === 'OutstandingJobDashboard') {
      Shared.passJobIdInHeader()
    }
  }

  public appointedContractorHandler() {
    this.contractorManagementHub.addHandler('appointedContractor', this.appointedContractor)
  }

  public contractorGroupAddedHandler() {
    this.contractorManagementHub.addHandler('contractorGroupAdded', this.contractorGroupAdded)
  }

  public contractorGroupRemovedHandler() {
    this.contractorManagementHub.addHandler('contractorGroupRemoved', this.contractorGroupRemoved)
  }

  public outstandingJobDashboardHandler() {
    this.outStandingJobDashboardHub.addHandler('appointedContractor', this.appointedContractor)
  }

  public engineerAvailabilityHandler() {
    this.engineerAvailabilityHub.addHandler('availabilityChanged', this.engineerAvailabilityChanged)
  }

  public updateAppointedContractorDetailHandler() {
    this.contractorManagementHub.addHandler('updateAppointedContractorDetail', this.updateAppointedContractorDetail)
  }

  @Watch('getSignalRConnectionStatus')
  public updateSignalRGroup() {
    this.liveSync = false
    if (this.selectedContractorId) {
      // have connected and have contractor id, update status
      SessionController.AddUserToNotificationsForContractor(this.selectedContractorId)
    }
  }

  // TODO: SignalR connection
  @Watch('getoutStandingJobSignalRConnectionStatus')
  public updateSignalRGroupId() {
    if (this.jobsFilter.contractorId && this.jobsFilter.contractorId !== '0') {
      // have connected and have contractor id, update status
      SessionController.AddUserToNotificationsForContractor(this.jobsFilter.contractorId)
    }
  }

  public sortOutStandingJobDashboardData(outStandingJobs: ContractorAppointedModel[]) {
    if (
      WIPDashboardSortingTypeEnum[this.jobsFilter.sortingType] ===
      WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.EtaStart]
    ) {
      // sort data as per eta from
      outStandingJobs.sort((a, b) => {
        a.etaFrom = typeof a.etaFrom === 'string' ? moment(a.etaFrom) : a.etaFrom
        b.etaFrom = typeof b.etaFrom === 'string' ? moment(b.etaFrom) : b.etaFrom
        if (a.etaFrom && b.etaFrom && a.etaFrom.isBefore(b.etaFrom)) {
          return -1
        } else if (a.etaFrom && b.etaFrom && a.etaFrom.isAfter(b.etaFrom)) {
          return 1
        } else {
          return 0
        }
      })
    } else if (
      WIPDashboardSortingTypeEnum[this.jobsFilter.sortingType] ===
      WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.EtaFinish]
    ) {
      // sort data as per eta to
      outStandingJobs.sort((a, b) => {
        a.etaTo = typeof a.etaTo === 'string' ? moment(a.etaTo) : a.etaTo
        b.etaTo = typeof b.etaTo === 'string' ? moment(b.etaTo) : b.etaTo
        if (a.etaTo && b.etaTo && a.etaTo.isBefore(b.etaTo)) {
          return -1
        } else if (a.etaTo && b.etaTo && a.etaTo.isAfter(b.etaTo)) {
          return 1
        } else {
          return 0
        }
      })
    } else if (
      WIPDashboardSortingTypeEnum[this.jobsFilter.sortingType] ===
      WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.SchemePriorityLowestToHighest]
    ) {
      // sort data as per scheme priority lowest to highest
      outStandingJobs.sort((n1, n2) => n1.schemePriority - n2.schemePriority)
    } else if (
      WIPDashboardSortingTypeEnum[this.jobsFilter.sortingType] ===
      WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.SchemePriorityHighestToLowest]
    ) {
      // sort data as per scheme priority highest to lowest
      outStandingJobs.sort((n1, n2) => n1.schemePriority - n2.schemePriority)
      outStandingJobs.reverse()
    }
    return outStandingJobs
  }

  public bindOutstandingJobsData() {
    let outstandingJobs: ContractorAppointedModel[] = this.getFilterWiseOutstandingJobs(this.outstandingJobData)
    if (this.jobsTradeList.length > 0) {
      // filter by trade wise
      outstandingJobs = outstandingJobs.filter(
        (job) => this.currentIndex === -1 || job.tradeId === this.jobsTradeList[this.currentIndex].tradeId
      )
    }
    if (outstandingJobs.length === 0 && this.outstandingJobData.length > 0) {
      // currentIndex is not -1, and no data to bind, so set currentIndex to -1 and bind all outstandingJobData
      outstandingJobs = this.getFilterWiseOutstandingJobs(this.outstandingJobData)
      this.currentIndex = -1
    }
    this.getStatusWiseOutstandingJobs(outstandingJobs)
    // update eta wise card color
    eventBus.$emit('updateETAColors')
  }

  public getFilterWiseOutstandingJobs(jobData: ContractorAppointedModel[]): ContractorAppointedModel[] {
    // filter and return data by teamName, JobEscalationType, Vulnerability type, Visit type, JobDateFilter Type
    return jobData.filter(
      (job) =>
        ((job.loggedByTeamName && job.loggedByTeamName === this.jobsFilter.teamName) ||
          this.jobsFilter.teamName.toLowerCase() === 'all' ||
          this.jobsFilter.teamName.toLowerCase() === 'unknown') &&
        ((job.escalateJob && this.jobsFilter.jobEscalationType !== EscalationTypeEnum.Standard) ||
          (!job.escalateJob && this.jobsFilter.jobEscalationType !== EscalationTypeEnum.Escalated)) &&
        (this.jobsFilter.vulnerabilityLevel === VulnerabilityLevelEnum.All
          ? true
          : job.vulnerabilityLevel
          ? job.vulnerabilityLevel === VulnerabilityLevelEnum[this.jobsFilter.vulnerabilityLevel]
          : this.jobsFilter.vulnerabilityLevel === VulnerabilityLevelEnum.None) &&
        ((job.isContractorReAttend && this.jobsFilter.visitType !== VisitTypeEnum[VisitTypeEnum.FirstVisit]) ||
          (!job.isContractorReAttend && this.jobsFilter.visitType !== VisitTypeEnum[VisitTypeEnum.ReturnVisit])) &&
        ((this.jobsFilter.jobDateFilterType === JobDateFilterType.TodayJobs &&
          job.etaFrom &&
          job.etaTo &&
          moment(job.etaFrom).isValid() &&
          moment(job.etaTo).isValid() &&
          (job.etaFrom.toISOString().slice(0, 10) === this.todayDate.toISOString().slice(0, 10) ||
          job.etaTo.toISOString().slice(0, 10) === this.todayDate.toISOString().slice(0, 10)
            ? true
            : job.etaFrom.toISOString().slice(0, 10) <= this.todayDate.toISOString().slice(0, 10) &&
              job.etaTo.toISOString().slice(0, 10) >= this.todayDate.toISOString().slice(0, 10))) ||
          (this.jobsFilter.jobDateFilterType === JobDateFilterType.FutureJobs &&
            job.etaFrom &&
            job.etaTo &&
            moment(job.etaFrom).isValid() &&
            moment(job.etaTo).isValid() &&
            (job.etaFrom.toISOString().slice(0, 10) > this.todayDate.toISOString().slice(0, 10)
              ? true
              : job.etaTo.toISOString().slice(0, 10) > this.todayDate.toISOString().slice(0, 10))) ||
          (this.jobsFilter.jobDateFilterType === JobDateFilterType.NoETASetJobs &&
            (job.etaFrom ? !moment(job.etaFrom).isValid() : true) &&
            (job.etaTo ? !moment(job.etaTo).isValid() : true)))
    )
  }

  public getStatusWiseOutstandingJobs(outstandingJobs: ContractorAppointedModel[]) {
    // set pending, accepted, inProgress, completed jobs data
    this.pendingJobList = []
    this.acceptedJobList = []
    this.inProgressJobList = []
    this.completedJobList = []
    this.pendingJobList = outstandingJobs.filter((x) => x.status === JobVisitStatus[JobVisitStatus.Pending])
    this.acceptedJobList = outstandingJobs.filter((x) => x.status === JobVisitStatus[JobVisitStatus.Accepted])
    this.inProgressJobList = outstandingJobs.filter((x) => x.status === JobVisitStatus[JobVisitStatus.InProgress])
    this.completedJobList = outstandingJobs.filter((x) => x.status === JobVisitStatus[JobVisitStatus.Completed])
    if (outstandingJobs.length === 0) {
      // no data to disply, set currentIndex to -1
      this.currentIndex = -1
      this.jobsTradeList = []
      return
    }
    if (this.currentIndex === -1) {
      // "All" is selected for trade wise jobs
      this.getJobTrades(outstandingJobs)
    } else {
      // trade is selected for filter, get all jobs and set trade count
      const filteredJobs: ContractorAppointedModel[] = this.getFilterWiseOutstandingJobs(this.outstandingJobData)
      this.getJobTrades(filteredJobs)
    }
  }

  private getJobTrades(outstandingJobs: ContractorAppointedModel[]) {
    const self = this
    this.jobsTradeList = []
    let jobTrades = outstandingJobs.map((obj) => obj.tradeId)
    jobTrades = jobTrades.filter((v, i) => jobTrades.indexOf(v) === i)
    jobTrades.forEach((tradeId) => {
      // rebind trade list
      const trade = self.trades.find((t) => t.tradeId === tradeId)
      if (trade) {
        const tradeObject: any = {}
        tradeObject.trade = trade.description
        tradeObject.tradeId = trade.tradeId
        tradeObject.jobCount = outstandingJobs.filter((e) => e.tradeId === tradeId).length
        self.jobsTradeList.push(tradeObject)
      }
    })
  }

  private async appointedContractor(
    jobId: string,
    contractorId: string,
    requestId: string,
    requestType: string,
    processedItem: RequestProcessedRecord,
    success: boolean,
    newStatus: string,
    oldStatus: string
  ) {
    if (!success) {
      return // message not processed, nothing to update
    }
    // refresh item
    if (processedItem) {
      const res = await JobController.GetJobDocument(jobId, processedItem.id)
      const appointedContractor = Object.assign(new ContractorAppointedModel(), res)
      if (appointedContractor) {
        if (this.childPage === 'ContractorManagement') {
          // update contractor job data in ContractorManagement Portal.
          this.updateContractorJobsData(jobId, processedItem, appointedContractor)
        } else if (this.childPage === 'OutstandingJobDashboard') {
          // update data in OutStandingJob Dashboard.
          this.updateOutStandingJobDashboardData(jobId, processedItem, appointedContractor, newStatus, oldStatus)
        }
      }
    }
  }

  private updateContractorJobsData(
    jobId: string,
    processedItem: RequestProcessedRecord,
    appointedContractor: ContractorAppointedModel
  ) {
    if (appointedContractor.contractorId === this.selectedContractorId) {
      const index: number = this.contractorJobs.findIndex(
        (c: ContractorAppointedModel) => c.id === appointedContractor.id
      )
      if (index === -1) {
        this.contractorJobs.push(appointedContractor)
      } else {
        this.contractorJobs.splice(index, 1, appointedContractor)
      }
    }
  }

  private updateOutStandingJobDashboardData(
    jobId: string,
    processedItem: RequestProcessedRecord,
    appointedContractor: ContractorAppointedModel,
    newStatus: string,
    oldStatus: string
  ) {
    if (JSON.stringify(this.tempAppointedContractor) === JSON.stringify(appointedContractor)) {
      return // prevent execution of code in case of multiple signalR call
    }
    if (appointedContractor.contractorId === this.jobsFilter.contractorId || this.jobsFilter.contractorId === '0') {
      const index = this.outstandingJobData.findIndex((e) => e.id === appointedContractor.id)
      if (index !== -1) {
        this.outstandingJobData.splice(index, 1)
      }
      if (appointedContractor.status !== JobVisitStatus[JobVisitStatus.Cancelled]) {
        this.outstandingJobData.push(appointedContractor)
        if (this.getFilterWiseOutstandingJobs(new Array(appointedContractor)).length < 1) {
          const jobIndex = this.outstandingJobData.findIndex((x) => x.id === appointedContractor.id)
          if (jobIndex !== -1) {
            this.outstandingJobData.splice(jobIndex, 1)
          }
        }
      }
      this.sortOutStandingJobDashboardData(this.outstandingJobData)
      // update total job count as per old status and new status
      this.updateTotalJobCount(newStatus, oldStatus, appointedContractor)
      this.bindOutstandingJobsData()
    }
    // manage temp varible to prevent execution of code multiple times
    this.tempAppointedContractor = Object.assign(new ContractorAppointedModel(), appointedContractor)
  }

  private checkIsJobExists(appointedContractor: ContractorAppointedModel, status: string): boolean {
    let isJobExist = false
    switch (status) {
      case JobVisitStatus[JobVisitStatus.Pending]:
        isJobExist = this.pendingJobList.filter((x) => x.id === appointedContractor.id).length > 0 ? true : false
        break
      case JobVisitStatus[JobVisitStatus.Accepted]:
        isJobExist = this.acceptedJobList.filter((x) => x.id === appointedContractor.id).length > 0 ? true : false
        break
      case JobVisitStatus[JobVisitStatus.InProgress]:
        isJobExist = this.inProgressJobList.filter((x) => x.id === appointedContractor.id).length > 0 ? true : false
        break
      case JobVisitStatus[JobVisitStatus.Completed]:
        isJobExist = this.completedJobList.filter((x) => x.id === appointedContractor.id).length > 0 ? true : false
        break
      default:
        break
    }
    return isJobExist
  }

  private updateTotalJobCount(newStatus: string, oldStatus: string, appointedContractor: ContractorAppointedModel) {
    if (oldStatus && oldStatus === newStatus) {
      if (this.getFilterWiseOutstandingJobs(new Array(appointedContractor)).length >= 1) {
        // record match with filter job criteria
        if (!this.checkIsJobExists(appointedContractor, newStatus)) {
          // record not exist, increase count
          this.increaseTotalJobCount(newStatus)
        }
      } else {
        // record does not match with filter job criteria
        if (this.checkIsJobExists(appointedContractor, newStatus)) {
          // record already exist, decrease count
          this.reduceTotalJobCount(newStatus)
        }
      }
      return
    }
    if (this.getFilterWiseOutstandingJobs(new Array(appointedContractor)).length >= 1) {
      // record match with filter job criteria, so update count as per status
      if (newStatus === JobVisitStatus[JobVisitStatus.Cancelled]) {
        // newStatus is cancelled, decrease count
        this.reduceTotalJobCount(oldStatus)
      } else {
        // as per new status, increase count
        this.increaseTotalJobCount(newStatus)
        // as per old status, decrease count
        this.reduceTotalJobCount(oldStatus)
      }
    }
  }

  private increaseTotalJobCount(status: string) {
    switch (status) {
      case JobVisitStatus[JobVisitStatus.Pending]:
        this.getTotalPendingJobsCount = this.getTotalPendingJobsCount + 1
        break
      case JobVisitStatus[JobVisitStatus.Accepted]:
        this.getTotalAcceptedJobsCount = this.getTotalAcceptedJobsCount + 1
        break
      case JobVisitStatus[JobVisitStatus.InProgress]:
        this.getTotalInProgressJobsCount = this.getTotalInProgressJobsCount + 1
        break
      case JobVisitStatus[JobVisitStatus.Completed]:
        this.getTotalCompletedJobsCount = this.getTotalCompletedJobsCount + 1
        break
      default:
        break
    }
  }

  private reduceTotalJobCount(status: string) {
    switch (status) {
      case JobVisitStatus[JobVisitStatus.Pending]:
        this.getTotalPendingJobsCount = this.getTotalPendingJobsCount > 0 ? this.getTotalPendingJobsCount - 1 : 0
        break
      case JobVisitStatus[JobVisitStatus.Accepted]:
        this.getTotalAcceptedJobsCount = this.getTotalAcceptedJobsCount > 0 ? this.getTotalAcceptedJobsCount - 1 : 0
        break
      case JobVisitStatus[JobVisitStatus.InProgress]:
        this.getTotalInProgressJobsCount =
          this.getTotalInProgressJobsCount > 0 ? this.getTotalInProgressJobsCount - 1 : 0
        break
      case JobVisitStatus[JobVisitStatus.Completed]:
        this.getTotalCompletedJobsCount = this.getTotalCompletedJobsCount > 0 ? this.getTotalCompletedJobsCount - 1 : 0
        break
      default:
        break
    }
  }

  private contractorGroupAdded(contractorId: string) {
    if (contractorId === this.selectedContractorId) {
      this.liveSync = true
    }
  }

  private contractorGroupRemoved(contractorId: string) {
    if (contractorId === this.selectedContractorId) {
      this.liveSync = false
    }
  }

  private get getSignalRConnectionStatus(): ConnectionState {
    return this.contractorManagementHub.status
  }

  private get getoutStandingJobSignalRConnectionStatus(): ConnectionState {
    return this.outStandingJobDashboardHub.status
  }

  private async engineerAvailabilityChanged(
    engineerId: string,
    outsideFromDate: moment.Moment,
    outsideToDate: moment.Moment
  ) {
    // refresh item
    if (engineerId) {
      const engineer: EngineerModel | undefined = this.engineers.find((e) => e.id === engineerId)
      if (engineer) {
        if (outsideFromDate && outsideFromDate !== null) {
          engineer.outsideFromDate = moment(outsideFromDate)
        }
        if (outsideToDate && outsideToDate !== null) {
          engineer.outsideToDate = moment(outsideToDate)
        }
      }
    }
  }

  private updateAppointedContractorDetail(
    contractorId: string,
    contractrorAppointedId: string[],
    previousEngineerVisitId: string
  ) {
    if (contractorId === this.selectedContractorId) {
      const contractorJobs = this.contractorJobs.filter((c) => contractrorAppointedId.includes(c.id))
      contractorJobs.forEach((c) => (c.previousEngineerVisitId = previousEngineerVisitId))
    }
  }

  public get getTotalPendingJobsCount() {
    // manage total pending job count
    return this.totalPendingJobs
  }

  public set getTotalPendingJobsCount(newValue: number) {
    this.totalPendingJobs = newValue
  }

  public get getTotalAcceptedJobsCount() {
    // manage total accepted job count
    return this.totalAcceptedJobs
  }

  public set getTotalAcceptedJobsCount(newValue: number) {
    this.totalAcceptedJobs = newValue
  }

  public get getTotalInProgressJobsCount() {
    // manage total in progress job count
    return this.totalInProgressJobs
  }

  public set getTotalInProgressJobsCount(newValue: number) {
    this.totalInProgressJobs = newValue
  }

  public get getTotalCompletedJobsCount() {
    // manage total completed job count
    return this.totalCompletedJobs
  }

  public set getTotalCompletedJobsCount(newValue: number) {
    this.totalCompletedJobs = newValue
  }
}
</script>
