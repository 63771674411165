import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import TestDetail from './TestDetail'
import PitDetailModel from './PitDetailModel'
import LeftSideLayer from './LeftSideLayer'
import RightSideLayer from './RightSideLayer'
import TrialPitFeatureModel from './TrialPitFeatureModel'
import TrialPitTestModel from './TrialPitTestModel'

export default class TrialPitDrawingModel extends CosmosJobsDbRecordBase {
  public imageFile: string
  public forEngineerVisitDetailId: string
  public fileUrl: string
  public testDetails: TestDetail
  public pitDetail: PitDetailModel
  public leftLayers: LeftSideLayer[]
  public rightLayers: RightSideLayer[]
  public features: TrialPitFeatureModel[]
  public tests: TrialPitTestModel[]
}
