import Product from '@/models/siteInvestigation/Product'
import Pit from './Pit'

export default class BulkPit extends Product {
  public name = ''
  public maxPits = 0
  public pits: Array<{ value: Pit }> = []

  constructor(product?: Product) {
    super(product)
  }
}
