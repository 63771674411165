<template>
  <div>
    <v-dialog v-model="showDialog" max-width="850" content-class="v-dialog--scrollable">
      <v-card class="elevation-0">
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Agent Reservations ({{ agentName }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancel()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-data-table
            :headers="headers"
            :items="reservations"
            :loading="isLoading"
            :search="search"
            :pagination.sync="pagination"
            class="gridView"
          >
            <template #items="{ item }">
              <td>{{ item.sid }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.dateCreated }}</td>
              <td>{{ item.dateUpdated }}</td>
              <td class="text-xs-right px-0">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn small color="error" class="mx-0" v-on="on" @click="onEndClick(item)">
                      End Reservation
                    </v-btn>
                  </template>
                  <span>End Reservation</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import ReservationModelResponse from '@/api/models/ReservationModelResponse'
import WorkerModelResponse from '@/api/models/WorkerModelResponse'
import { Vue, Component, Provide } from 'vue-property-decorator'

@Component({})
export default class AgentReservationsDialog extends Vue {
  @Provide('validator') public validator = this.$validator

  public headers: any = []
  public isLoading = true
  public search = ''
  public pagination: any = {}

  private created() {
    this.headers = [
      { text: 'Reservation Id', value: 'sid' },
      { text: 'Status', value: 'status' },
      { text: 'Created', value: 'dateCreated' },
      { text: 'Last Updated', value: 'dateUpdated' },
      { text: '', sortable: false },
    ]
    this.pagination.rowsPerPage = 10
  }

  private mounted() {
    this.isLoading = false
  }

  public get showDialog(): boolean {
    return !!this.$store.getters['agentModule/worker']
  }

  public set showDialog(value: boolean) {
    if (!value) {
      this.cancel()
    }
  }

  public get agentName(): string | null {
    const agent: WorkerModelResponse | null = this.$store.getters['agentModule/worker']
    return agent !== null ? agent.name : null
  }

  public get reservations() {
    return this.$store.getters['agentModule/reservations']
  }

  public onEndClick(reservation: ReservationModelResponse) {
    this.$store.commit('agentModule/endReservation', reservation)
  }

  public cancel(): void {
    this.$store.commit('agentModule/reset')
  }
}
</script>
