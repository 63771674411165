<template>
  <div class="pb-2 component-container">
    <v-progress-circular
      v-show="!isLoaded"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <v-card class="pt-0 px-2 pb-2 up-card">
      <v-layout wrap class="clt-avb-card">
        <v-flex xs12>
          <h3 class="pt-3 pb-1 ml-4">Confirm Booking Details</h3>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-container fluid grid-list-md class="pb-0">
            <v-layout wrap>
              <v-flex xs12>
                <v-layout wrap>
                  <v-flex xs6>
                    <DateTimePicker
                      ref="startPicker"
                      class="start-datepicker"
                      :is-validation-required="true"
                      place-holder-text="Start Date & Time"
                      :date-time.sync="confModel.etaFrom"
                      :min-date="minDateFrom"
                      :min-time="minTimeFrom"
                      :allowed-minutes-step="true"
                      :disabled="isContractorAcepted"
                    ></DateTimePicker>
                    <!-- TODO:
                    <div class="grey lighten-4 pa-2 mt-2" v-if="emergency.engineerSiteSLA">
                      <b class="error--text">SLA cut off time : {{getSLACutOffTime}}</b>
                    </div>-->
                  </v-flex>
                  <v-flex xs6>
                    <DateTimePicker
                      ref="endPicker"
                      class="end-datepicker"
                      :is-validation-required="true"
                      place-holder-text="End Date & Time"
                      :date-time.sync="confModel.etaTo"
                      :min-date="minDateTo"
                      :min-time="minTimeTo"
                      :allowed-minutes-step="true"
                      :disabled="isContractorAcepted"
                    ></DateTimePicker>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 class="pt-0 pb-0">
                    <v-autocomplete
                      ref="engineerPicker"
                      v-model="confModel.engineerId"
                      v-validate="'required'"
                      :is-validation-required="true"
                      :items="engineerList"
                      label="Select Engineer"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ maxHeight: 'auto' }"
                      chips
                      required
                      class="required engineer-picker"
                      data-vv-scope="confModel.engineerId"
                      data-vv-name="Select Engineer"
                      :error-messages="$validator.errors.collect('Select Engineer')"
                      :disabled="isContractorAcepted"
                    >
                      <template slot="selection" slot-scope="data">
                        <v-chip :key="JSON.stringify(data.item)" :selected="data.selected" class="chip--select-multi">
                          <v-avatar>
                            <img v-if="data.item.profileImageThumbnailUrl" :src="data.item.profileImageThumbnailUrl" />
                            <img v-else src="/img/unknownuser.png" />
                          </v-avatar>
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template slot="item" slot-scope="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-tile-content v-text="data.name"></v-list-tile-content>
                        </template>
                        <template v-else>
                          <v-list-tile-avatar>
                            <img v-if="data.item.profileImageThumbnailUrl" :src="data.item.profileImageThumbnailUrl" />
                            <img v-else src="/img/unknownuser.png" />
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              {{ data.item.name }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout v-if="getRepairEstimates && getRepairEstimates.length > 0" wrap>
              <div class="font-weight-bold ma-1">Repair Estimation Details</div>
              <v-flex v-if="repairEstimateItems.length > 0" xs12 mt-1>
                <v-data-table
                  v-model="selectedEstimates"
                  :headers="repairEstimateHeaders"
                  :items="repairEstimateItems"
                  class="gridView elevation-1 estimate-detail-table"
                  item-key="estimateId"
                  hide-actions
                  select-all
                >
                  <template slot="items" slot-scope="props">
                    <tr :active="props.selected">
                      <td>
                        <v-checkbox
                          v-model="props.selected"
                          class="select-visit-chkbox"
                          :disabled="isContractorAcepted"
                          color="primary"
                          hide-details
                          @click.stop="props.selected = !props.selected"
                        ></v-checkbox>
                      </td>
                      <td>{{ props.item.estimateName }}</td>
                      <td class="text-xs-center">
                        {{ getFormatedCurrency(props.item.price) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
          <DelayCodes
            class="mr-2 ml-2 delay-component"
            :has-delay="confModel.hasDelay"
            :delay-code="confModel.delayCode"
            @updateDelayCode="onDelayCodeChange"
          ></DelayCodes>
        </v-flex>
        <v-flex v-if="!isContractorAcepted" xs12 class="text-xs-right">
          <v-btn
            class="xs12 mt-3 elevation-1 submit-button"
            :disabled="updateBtnDisabled"
            color="primary"
            @click="handleBooking"
          >
            Confirm Booking
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import Map from '@/components/Map.vue'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import Store from '@/store'
import moment, { Moment } from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import TimeSlotPicker from '@/components/TimeSlotPicker.vue'
import DelayCodes from '@/components/timeline/DelayCodes.vue'
import AcceptProvisionedVisit from '@/models/requests/AcceptProvisionedVisit'
import EngineerController from '@/api/engineerController'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import ContractorController from '@/api/contractorController'
import EngineerModel from '@/models/contractor/EngineerModel'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'
import JobController from '@/api/jobController'
import { JobVisitStatus } from '@/common/enums'
import AcceptProvisionedUSVisit from '@/models/requests/AcceptProvisionedUSVisit'
import UndergroundServicesController from '@/api/undergroundServicesController'
import RepairEstimateModel from '@/models/undergroundServices/RepairEstimateModel'
import eventBus from '@/common/bus'

export class RepairEstimate {
  public estimateId: string
  public estimateName: string
  public price: number
}
@Component({
  components: { Map, DateTimePicker, DelayCodes },
})
export default class ConfirmDatePreview extends TimeLineItemBase {
  // Variables to hold details gathered from form
  private confModel: AcceptProvisionedVisit = new AcceptProvisionedVisit()
  private minDateFrom: string = moment().format(DateTimePicker.DATE_FORMAT)
  private minTimeFrom: string = moment().format(DateTimePicker.TIME_FORMAT)
  private minDateTo: string = moment().format(DateTimePicker.DATE_FORMAT)
  private minTimeTo: string = moment().format(DateTimePicker.TIME_FORMAT)
  private engineerList: EngineerModel[] = []
  private isComponentLoaded = false
  private btnDisabled = false
  private repairEstimateItems: RepairEstimate[] = []
  private selectedEstimates: RepairEstimate[] = []
  private repairEstimateHeaders: any = [
    { text: 'Estimate', value: 'estimateName', sortable: false },
    { text: 'Client Price', value: 'price', sortable: false, align: 'center' },
  ]

  private get isLoaded(): boolean {
    return this.isComponentLoaded
  }

  private get getAssignedContractor(): ContractorAppointedModel {
    if (this.jobId && this.itemId) {
      return storeGetters.getContractorAppointedDetailById(this.jobId, this.itemId)
    }
    return new ContractorAppointedModel()
  }

  private get updateBtnDisabled(): boolean {
    return (
      !(this.confModel && this.confModel.etaFrom && this.confModel.etaTo && this.confModel.engineerId) ||
      this.btnDisabled
    )
  }

  public get isContractorAcepted(): boolean {
    return this.getAssignedContractor.status === JobVisitStatus[JobVisitStatus.Accepted]
  }

  private get getRepairEstimates(): RepairEstimateModel[] {
    return storeGetters.getRepairEstimateDetail(this.jobId)
  }

  private created() {
    if (this.getAssignedContractor) {
      this.getEngineerList()
      this.confModel.jobId = this.$props.jobId
      this.confModel.etaFrom = this.getAssignedContractor.etaFrom
      this.confModel.etaTo = this.getAssignedContractor.etaTo
      this.confModel.engineerId = this.getAssignedContractor.engineerId ? this.getAssignedContractor.engineerId : ''
      this.confModel.hasDelay = this.getAssignedContractor.hasDelay
      this.confModel.delayCode = this.getAssignedContractor.delayCode
      this.confModel.contractorAppointedDetailId = this.getAssignedContractor.id
    }
    // get repair estimate items
    if (this.getRepairEstimates && this.getRepairEstimates.length > 0) {
      this.getRepairEstimates.forEach((repairEstimate) => {
        const object: RepairEstimate = new RepairEstimate()
        object.estimateId = repairEstimate.id
        object.estimateName = repairEstimate.estimateName
        object.price = repairEstimate.products.reduce(
          (a, b) => a + (b.clientPrice + (b.clientPrice * (b.vatRate ? b.vatRate : 1)) / 100),
          0
        )
        this.repairEstimateItems.push(object)
      })
    }
  }

  private checkEngineerAvailability(
    etaFrom: moment.Moment | null,
    etaTo: moment.Moment | null,
    engineer: EngineerModel
  ) {
    // check engineer availability compared to job eta.
    let isEngineerAvailable = false
    if (etaFrom && etaTo) {
      if (engineer) {
        if (
          engineer.outsideToDate &&
          engineer.outsideFromDate &&
          engineer.outsideToDate.isValid() &&
          engineer.outsideFromDate.isValid()
        ) {
          const etaFromDate: string = etaFrom.toISOString().slice(0, 10)
          const outsideFromDate: string = engineer.outsideFromDate.toISOString().slice(0, 10)
          const outsideToDate: string = engineer.outsideToDate.toISOString().slice(0, 10)
          if (etaFromDate === outsideFromDate || etaFromDate === outsideToDate) {
            isEngineerAvailable = false
          } else {
            isEngineerAvailable = etaFrom.isBetween(engineer.outsideFromDate, engineer.outsideToDate) ? false : true
          }
        } else {
          isEngineerAvailable = true
        }
      }
    }
    return isEngineerAvailable
  }

  private getEngineerList(): void {
    EngineerController.GetContractorEngineers(this.getAssignedContractor.contractorId)
      .then((res: EngineerModel[]) => {
        // check engineers availability
        const engineerList: EngineerModel[] = res.filter((e) => e.engineerAvailability !== null)
        if (this.emergency && this.emergency.tradeId) {
          const localId = this.emergency.tradeId
          this.engineerList = engineerList.filter(
            (e: EngineerModel) =>
              e.engineerAvailability.findIndex((t: EngineerAvailabilityModel) => t.tradeId === localId) > -1 ||
              e.id === null
          )
        }
        // if availability found, add engineer into list
        if (this.engineerList.length > 0) {
          this.engineerList.forEach((element) => {
            if (this.getAssignedContractor) {
              const isEngineerAvailable = this.checkEngineerAvailability(
                this.getAssignedContractor.etaFrom,
                this.getAssignedContractor.etaTo,
                element
              )
              if (!isEngineerAvailable) {
                const index = this.engineerList.indexOf(element)
                if (index > -1) {
                  this.engineerList = this.engineerList.splice(index, 1)
                }
              }
            }
            element.name = element.firstName + ' ' + element.lastName
          })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading engineer list, please try again', true)
      })
  }

  private onDelayCodeChange(hasDelay: boolean, delayCode: string | null) {
    this.confModel.hasDelay = hasDelay
    this.confModel.delayCode = delayCode
  }

  @Watch('confModel.etaFrom')
  private onFromChange(newVal: Moment | null, oldVal: Moment | null) {
    if (newVal) {
      this.minDateTo = moment(newVal).format(DateTimePicker.DATE_FORMAT)
      this.minTimeTo = moment(newVal).format(DateTimePicker.TIME_FORMAT)
      if (!this.confModel.etaTo || moment(this.confModel.etaTo) < moment(newVal).add(3, 'h')) {
        this.confModel.etaTo = moment(newVal).add(3, 'h')
      }
    }
    const dateTimeFrom = this.confModel.etaFrom
    if (dateTimeFrom) {
      this.minTimeFrom =
        moment(dateTimeFrom).format(DateTimePicker.DATE_FORMAT) === moment().format(DateTimePicker.DATE_FORMAT)
          ? moment().format(DateTimePicker.TIME_FORMAT)
          : ''
    }
  }

  @Watch('confModel.etaTo')
  private onToChange(newVal: Moment | null, oldVal: Moment | null) {
    const dateTimeTo = this.confModel.etaTo
    const dateTimeFrom = this.confModel.etaFrom
    if (dateTimeFrom && dateTimeTo) {
      this.minTimeTo =
        moment(dateTimeFrom).format(DateTimePicker.DATE_FORMAT) ===
        moment(dateTimeTo).format(DateTimePicker.DATE_FORMAT)
          ? moment(dateTimeFrom).format(DateTimePicker.TIME_FORMAT)
          : ''
    } else if (!dateTimeFrom && dateTimeTo) {
      this.minTimeTo =
        moment().format(DateTimePicker.DATE_FORMAT) === moment(dateTimeTo).format(DateTimePicker.DATE_FORMAT)
          ? moment().format(DateTimePicker.TIME_FORMAT)
          : ''
    }
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private async handleBooking() {
    const etaFromPicker = this.$refs.startPicker as DateTimePicker
    const etaToPicker = this.$refs.endPicker as DateTimePicker
    const engineerPicker = this.$refs.engineerPicker as DelayCodes

    // Use validator library to validate required fields
    const bool1: boolean = await this.$validator.validateAll()
    const bool2: boolean = await etaFromPicker.$validator.validateAll()
    const bool3: boolean = await etaToPicker.$validator.validateAll()
    const bool4: boolean = await engineerPicker.$validator.validateAll()

    this.btnDisabled = true
    if (this.job && this.job.jobType === 'SI') {
      JobController.AcceptProvisionedVisit(this.confModel)
        .then((res: boolean) => {
          this.btnDisabled = bool1 && bool2 && bool3 && bool4 && this.confModel.engineerId ? res : false
        })
        .catch((err) => {
          this.btnDisabled = false
        })
    } else if (this.job && this.job.jobType === 'US') {
      const acceptProvisionedUSVisit: AcceptProvisionedUSVisit = Object.assign(
        new AcceptProvisionedUSVisit(),
        this.confModel
      )
      // bind selected estimate's Id
      acceptProvisionedUSVisit.repairEstimateIds = this.selectedEstimates.map((e) => e.estimateId)
      UndergroundServicesController.AcceptProvisionedUSVisit(acceptProvisionedUSVisit)
        .then((res: boolean) => {
          this.btnDisabled = bool1 && bool2 && bool3 && bool4 && acceptProvisionedUSVisit.engineerId ? res : false
        })
        .catch((err) => {
          this.btnDisabled = false
        })
    }
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style scoped>
img.profile-img {
  width: 80px;
}
.btn-padding-zero >>> .v-btn__content {
  padding: 0px !important;
  padding-left: 5px !important;
}
.map {
  position: relative;
}
.signechar-img img {
  width: 100%;
  max-width: 90px;
}
.item-qty >>> input {
  text-align: center;
}
.full-width {
  max-width: 100%;
}
.description {
  word-break: break-word;
}
.bold-select {
  font-weight: 600;
}
.hide {
  display: none;
}
</style>
