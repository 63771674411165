import { AxiosResponse } from 'axios'
import ContractorUnavailableReasonModel from '@/models/contractor/ContractorUnavailableReasonModel'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'

export default class ContractorUnavailableReasonController {
  public static async SaveContractorUnavailableReason(
    model: ContractorUnavailableReasonModel
  ): Promise<ContractorUnavailableReasonModel> {
    const res = await CallCentreApi.post<ContractorUnavailableReasonModel>(
      'ContractorUnavailableReason/SaveContractorUnavailableReason',
      model
    )
    return res.data
  }

  public static async GetContractorUnavailableReasons(): Promise<ContractorUnavailableReasonModel[]> {
    const res = await CallCentreApi.get<ContractorUnavailableReasonModel[]>(
      'ContractorUnavailableReason/GetContractorUnavailableReasons'
    )
    return res.data ? res.data : []
  }

  public static async DeleteContractorUnavailableReason(recordId: string, recordType: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(
      'ContractorUnavailableReason/DeleteContractorUnavailableReason?recordId=' + recordId + '&recordType=' + recordType
    )
    return res.status >= 200 && res.status < 300
  }
}
