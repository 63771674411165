export default class JobPolicyDetails {
  public clientId: number | null
  public policyDetails: PolicyDetails
}

export class PolicyDetails {
  public policyNumber: string
  public clientPolicyId: string
  public effectiveDate: Date | null
  public expirationDate: Date | null
}
