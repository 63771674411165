<template>
  <v-card>
    <v-toolbar v-if="parentComponent !== 'ContractorFinancialSummary'" dark color="primary">
      <v-toolbar-title>
        <span v-if="contractorJobDetail && contractorJobDetail.jobDetail">
          Job Id: {{ contractorJobDetail.jobDetail.jobId }} -
          {{ contractorJobDetail.getAddress(',') }}
        </span>
        <span v-else>Contractor Job Detail</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="showIconToRedirectToJob"
        icon
        class="btn-redirectToJob"
        @click="redirectToJob(contractorJobDetail.jobDetail.jobId)"
      >
        <v-icon>launch</v-icon>
      </v-btn>
      <v-btn icon class="btn-close-dialog" @click="closeContractorJobDetaildialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider v-if="parentComponent !== 'ContractorFinancialSummary'" />
    <v-card-text class="grey lighten-3 job-detail-dlog-content scroll-content-dialog">
      <v-layout mt-0 class="scoll-vb-content">
        <v-progress-circular
          v-if="!contractorJobDetail"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
          :style="getLoaderStyle(70)"
        ></v-progress-circular>
        <v-flex v-if="contractorJobDetail" xs12>
          <v-card class="elevation-0 mb-2">
            <v-card-text>
              <v-layout v-if="contractorJobDetail.jobDetail" wrap>
                <v-flex xs5 job-basicinfo pr-3>
                  <div>
                    <v-icon>person</v-icon>
                    <span>
                      {{ contractorJobDetail.jobDetail.clientTitle ? contractorJobDetail.jobDetail.clientTitle : '' }}
                      {{ contractorJobDetail.jobDetail.clientForename }}
                      {{ contractorJobDetail.jobDetail.clientLastName }}
                    </span>
                  </div>
                  <div>
                    <v-icon>place</v-icon>
                    {{ contractorJobDetail.jobDetail.addressLine1 }}
                    {{ contractorJobDetail.jobDetail.addressLine2 }}, {{ contractorJobDetail.jobDetail.addressLine3 }},
                    {{ contractorJobDetail.jobDetail.addressLine4 }}
                  </div>
                  <div
                    v-if="contractorJobDetail.emergencyDetail && contractorJobDetail.emergencyDetail.firstNoticedAt"
                    class="mt-3"
                  >
                    <h4>First Noticed At</h4>
                    <span class="date-label">
                      <v-icon small class="pl-1" color="secondary">date_range</v-icon>
                      {{ getFormattedDate(contractorJobDetail.emergencyDetail.firstNoticedAt) }}
                    </span>
                  </div>
                  <div v-if="contractorJobDetail.jobDetail && contractorJobDetail.jobDetail.createdAt">
                    <h4>Logged At</h4>
                    <span class="date-label">
                      <v-icon small class="pl-1" color="secondary">date_range</v-icon>
                      {{ getFormattedDate(contractorJobDetail.jobDetail.createdAt) }}
                    </span>
                  </div>
                  <div
                    v-if="
                      contractorJobDetail.contractorAppointedDetail &&
                      contractorJobDetail.contractorAppointedDetail.createdAt
                    "
                  >
                    <h4>Deployed At</h4>
                    <span class="date-label">
                      <v-icon small class="pl-1" color="secondary">date_range</v-icon>
                      {{ getFormattedDate(contractorJobDetail.contractorAppointedDetail.createdAt) }}
                    </span>
                  </div>
                  <template v-if="contractorJobDetail.jobDetail.sIJobDetail">
                    <div>
                      <v-icon>business</v-icon>
                      {{ contractorJobDetail.jobDetail.sIJobDetail.claimManagementCompany }}
                    </div>
                    <div>
                      <v-icon>event_note</v-icon>
                      {{ getFormattedDate(contractorJobDetail.jobDetail.sIJobDetail.instructionDate) }}
                    </div>
                  </template>
                  <template v-if="contractorJobDetail.siJobDelay">
                    <div>
                      <v-icon>av_timer</v-icon>
                      Delay Reason : {{ contractorJobDetail.siJobDelay.reason }}
                    </div>
                    <div>
                      <v-icon>notes</v-icon>
                      Delay Notes : {{ contractorJobDetail.siJobDelay.notes }}
                    </div>
                  </template>
                  <template v-if="contractorJobDetail.usJobDelay">
                    <div>
                      <v-icon>av_timer</v-icon>
                      Delay Reason : {{ contractorJobDetail.usJobDelay.reason }}
                    </div>
                    <div>
                      <v-icon>notes</v-icon>
                      Delay Notes : {{ contractorJobDetail.usJobDelay.notes }}
                    </div>
                  </template>
                </v-flex>
                <v-flex xs7>
                  <div class="prperty-detail">
                    <Map
                      :map-id="new Date().getTime().toString()"
                      :source-latitude="contractorJobDetail.jobDetail.latitude"
                      :source-longitude="contractorJobDetail.jobDetail.longitude"
                      :source-address="contractorJobDetail.getAddress(',')"
                      :show-street-view="true"
                      :show-fullscreen-control="false"
                      style="height: 250px"
                    ></Map>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-expansion-panel class="elevation-0 mb-2">
            <v-expansion-panel-content>
              <template #header>
                <h4>Policy Information</h4>
                <v-spacer></v-spacer>
              </template>
              <v-divider></v-divider>
              <v-layout class="ma-5">
                <div v-bar="{ useScrollbarPseudo: true }" class="pre-block">
                  <v-layout>
                    <pre class="mb-0 mt-0" v-html="sanitizeHTML(emergencyDefinition)"></pre>
                  </v-layout>
                </div>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-card v-if="getEngineerVisitDetail" class="elevation-0 mb-2">
            <v-card-title class="">
              <h4>Engineer Visit Detail</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 class="engineer-name">
                  <h4>Name: {{ engineerName }}</h4>
                </v-flex>
                <v-flex v-if="getEngineerVisitDetail.jobAcceptOrRejectAt" xs6 my-1>
                  <b>Accepted At :</b>
                  {{ getFormattedDate(getEngineerVisitDetail.jobAcceptOrRejectAt) }}
                </v-flex>
                <v-flex v-if="getEngineerVisitDetail.engineerOnSiteAt" xs6 my-1>
                  <b>On Site At :</b>
                  {{ getFormattedDate(getEngineerVisitDetail.engineerOnSiteAt) }}
                </v-flex>
                <v-flex v-if="getEngineerVisitDetail.visitStartedAt" xs6 my-1>
                  <b>Started At :</b>
                  {{ getFormattedDate(getEngineerVisitDetail.visitStartedAt) }}
                </v-flex>
                <v-flex v-if="getEngineerVisitDetail.visitCompletedAt" xs6 my-1>
                  <b>Completed At :</b>
                  {{ getFormattedDate(getEngineerVisitDetail.visitCompletedAt) }}
                </v-flex>
                <v-flex
                  v-if="
                    (getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] ||
                      getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Rejected] ||
                      getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned]) &&
                    getEngineerVisitDetail.processAt
                  "
                  xs6
                  my-1
                >
                  <b>{{ getEngineerVisitDetail.status }} At :</b>
                  {{ getFormattedDate(getEngineerVisitDetail.processAt) }}
                </v-flex>
                <v-flex
                  v-if="getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Cancelled]"
                  xs6
                  my-1
                >
                  <span
                    v-if="
                      getUserName(getEngineerVisitDetail.processByUserId) &&
                      getUserName(getEngineerVisitDetail.processByUserId) !== 'undefined' &&
                      getUserName(getEngineerVisitDetail.processByUserId) !== 'undefined undefined'
                    "
                    class="cancelledby-engineer"
                  >
                    <b>{{ getEngineerVisitDetail.status }} by :</b>
                    {{ getUserName(getEngineerVisitDetail.processByUserId) }}
                  </span>
                  <b v-else>{{ getEngineerVisitDetail.status }} by :</b>
                  Engineer
                </v-flex>
                <v-flex
                  v-if="
                    (getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Rejected] ||
                      getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned]) &&
                    getEngineerVisitDetail.processByUserName
                  "
                  xs6
                  my-1
                >
                  <b>{{ getEngineerVisitDetail.status }} by :</b>
                  {{ getEngineerVisitDetail.processByUserName }}
                </v-flex>
                <v-flex
                  v-if="
                    getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] ||
                    getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Rejected] ||
                    getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned]
                  "
                  xs6
                  my-1
                >
                  <b>Status:</b>
                  &nbsp;
                  <b>
                    <span class="red--text">
                      {{ getEngineerVisitDetail.status }}
                    </span>
                  </b>
                </v-flex>
                <v-flex
                  v-if="getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned]"
                  xs6
                  my-1
                >
                  <b>Abandoned Reason:</b>
                  &nbsp;
                  <span>{{ getEngineerVisitDetail.description }}</span>
                </v-flex>
                <v-flex
                  v-if="getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned]"
                  xs6
                  my-1
                  class="abandonNotes"
                >
                  <b>Abandoned Notes:</b>
                  &nbsp;
                  <span>
                    <pre>{{ getEngineerVisitDetail.abandonNotes }}</pre>
                  </span>
                </v-flex>
                <v-flex v-if="getEngineerVisitDetail.visitNote" xs12 mt-2>
                  <div class="notes body-2 black--text text--lighten-1 pa-2">
                    <h4 class="mb-1">Site Notes</h4>
                    <pre class="notes">{{ getEngineerVisitDetail.visitNote }}</pre>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-card class="elevation-0 mb-2">
            <v-card-title>
              <h4>Questions and Answers</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="contractorJobDetail.productsQA.length || contractorJobDetail.emergenciesQA.length">
              <v-container v-if="contractorJobDetail.emergenciesQA.length" fluid grid-list-md pa-0>
                <v-layout wrap>
                  <v-flex v-for="emergencyQA in contractorJobDetail.emergenciesQA" :key="emergencyQA.questionId" xs6>
                    <div class="question-inner-content grey lighten-3 pa-2">
                      <div>{{ emergencyQA.questionText }}</div>
                      <div>
                        <b class="secondary--text">
                          {{ emergencyQA.answer }}
                          {{ emergencyQA.answer !== '' ? emergencyQA.unit : '' }}
                        </b>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container v-if="contractorJobDetail.productsQA.length" fluid grid-list-md pa-0>
                <v-layout wrap>
                  <v-flex v-for="productQA in contractorJobDetail.productsQA" :key="productQA.questionId" xs6>
                    <div class="question-inner-content grey lighten-3 pa-2">
                      <div>{{ productQA.questionText }}</div>
                      <div>
                        <b class="secondary--text">
                          {{ productQA.answer }}
                          {{ productQA.answer !== '' ? productQA.unit : '' }}
                        </b>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-text v-else class="text-xs-center">No questions and answers found.</v-card-text>
          </v-card>
          <v-card class="elevation-0 mb-2">
            <v-card-title>
              <h4>Vulnerability Questions and Answers</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-layout wrap class="mx-auto mt-3 mb-3">
              <div v-if="vulnerabilityAnswer.isHealthAffected === true">
                <v-flex v-if="vulnerabilityAnswer.level" xs12 mb-2>
                  <span class="pl-10">
                    Level :
                    <b class="secondary--text">{{ vulnerabilityAnswer.level }}</b>
                  </span>
                </v-flex>
                <v-flex v-if="vulnerabilityAnswer.answerDetail" xs12>
                  <div class="affected-details">
                    <span>Details :</span>
                    <span class="affected-description">
                      {{ vulnerabilityAnswer.answerDetail }}
                    </span>
                  </div>
                </v-flex>
              </div>
              <div v-else-if="vulnerabilityAnswer.isHealthAffected === false">
                <v-flex xs12 mb-2>
                  <span padding-2>No Vulnerability</span>
                </v-flex>
              </div>
              <div v-else>
                <v-flex xs12 mb-2>
                  <span>Not answered yet.</span>
                </v-flex>
              </div>
              <v-card-text v-else class="text-xs-center">No questions and answers found.</v-card-text>
            </v-layout>
          </v-card>

          <GasSafetyQAReport
            v-if="contractorJobDetail"
            :boiler-breakdown-q-a="getBoilerBreakdownQA"
            :health-safety-q-a="getHealthSafetyQA"
            :engineer-visit-detail="getEngineerVisitDetail"
            :from-contractor-details-view="true"
            :job-id="contractorJobDetail.jobDetail.jobId"
            :visit-emergency-details="contractorJobDetail.emergencyDetail"
            :engineer-name="engineerName"
            :pictures-upload="contractorJobDetail.picturesUpload"
          />
          <v-expansion-panel v-if="getHealthSafetyQA">
            <v-expansion-panel-content>
              <div slot="header">
                <h4>Health & Safety Questions and Answers</h4>
              </div>
              <HealthSafetyQAReport
                class="pa-3"
                :get-health-safety-q-a="getHealthSafetyQA"
                :job-id="contractorJobDetail.jobDetail.jobId"
                :emergency="contractorJobDetail.emergencyDetail"
                :engineer-visit-detail="getEngineerVisitDetail"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-else disabled>
            <v-expansion-panel-content>
              <div slot="header"><h4>No Health & Safety records found</h4></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-card class="elevation-0 mt-2">
            <v-card-title>
              <h4>Pictures</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="contractorJobDetail.picturesUpload.length > 0">
              <v-layout wrap>
                <v-flex
                  v-for="(pictureUpload, index) in contractorJobDetail.picturesUpload"
                  :key="index"
                  class="img-block pa1 xs3"
                >
                  <div class="up-images pa-1">
                    <img :src="getBlobUrl(pictureUpload.fileURL)" width="100%" height="100%" class="image" />
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-else class="text-xs-center">No pictures found.</v-card-text>
          </v-card>
          <v-card class="elevation-0 mt-2">
            <v-card-title>
              <h4>Engineer Requests</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="getAdditionalRequestList.length > 0">
              <EngineerResourceRequestComponent
                :additional-request-list="getAdditionalRequestList"
                :job-id="contractorJobDetail.jobDetail.jobId"
                :parent-component="parentComponent"
              ></EngineerResourceRequestComponent>
            </v-card-text>
            <v-card-text v-else class="text-xs-center">No resource requests found.</v-card-text>
          </v-card>
          <v-card class="elevation-0 mt-2">
            <v-card-title class="">
              <h4>Job Summary</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="px-3 pt-0">
                <v-divider class="mb-2"></v-divider>
                <div class="text-xs-center message pt-2 pb-3 grey--text">
                  In Progress... Job Financial Summary data.
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="elevation-0 mb-2">
            <v-card-title>
              <h4>Additional Information</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-layout wrap class="mx-auto mt-1 mb-1">
              <div class="pa-12">
                <v-container fluid grid-list-md pa-0>
                  <v-layout wrap class="gap-2 mx-auto p-2">
                    <v-flex v-if="getAccessNotes && getAccessNotes.answer" class="mx-auto text-xs-center" xs12>
                      <span>Access Notes:</span><br />
                      <span class="secondary--text font-bold">
                        {{ getAccessNotes.answer }}
                      </span>
                    </v-flex>
                    <v-card-text v-else class="mx-auto text-xs-center">No access notes added</v-card-text>
                  </v-layout>
                  <v-layout wrap class="gap-3 p-2 text-xs-center">
                    <v-flex v-if="getAdditionalHealthQA && getAdditionalHealthQA.answerDetail" xs12>
                      <div class="affected-details text-xs-center">
                        <span> {{ getAdditionalHealthQA.questionText }}</span>
                        <br />
                        <span class="secondary--text font-bold text-xs-center">
                          {{ getAdditionalHealthQA.answerDetail }}
                        </span>
                      </div>
                    </v-flex>
                    <v-card-text v-else class="text-xs-center">No additional information entered</v-card-text>
                  </v-layout>
                </v-container>
              </div>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment, { Moment } from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import Map from '@/components/Map.vue'
import store from '@/store'
import EngineerResourceRequestComponent from '@/components/EngineerResourceRequestComponent.vue'
import JobController from '@/api/jobController'
import { EngineeringRequestCategory, EngineerJobVisitStatus } from '@/common/enums'
import UserModel from '@/models/user/UserModel'
import storeGetters from '@/storeGetters'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import VisitHealthAndSafetyProcess from '@/models/claim/VisitHealthAndSafetyProcessModel'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import RequestProcessedRecord from '@/signalr/jobModels/requestProcessedRecord'
import HealthSafetyQAReport from '@/components/HealthSafetyQAReport.vue'
import GasSafetyQAReport from '@/components/GasSafetyQAReport.vue'
import BoilerBreakdownQA from '../models/claim/BoilerBreakdownQA'
import PhotosController from '@/api/photos-controller'
import VulnerabilityQA from '@/models/VulnerabilityQA'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import AccessNoteModel from '@/models/claim/AccessNoteModel'

@Component({
  components: {
    Map,
    EngineerResourceRequestComponent,
    HealthSafetyQAReport,
    GasSafetyQAReport,
  },
})
export default class ContractorJobDetail extends Vue {
  @Prop() private contractorJobDetailItem: ContractorJobDetailModel | null
  @Prop() private parentComponent: string
  @Prop() private engineers: EngineerModel[]
  private sanitizeHTML = Shared.sanitizeHTML

  private headers: any = [
    {
      text: 'Name',
      align: 'left',
      sortable: false,
      value: 'name',
      class: 'item-name',
    },
    {
      text: 'Received At',
      value: 'date',
      sortable: false,
      class: 'request-col',
    },
    {
      text: 'Processed At',
      value: 'date',
      sortable: false,
      class: 'request-col',
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false,
      align: 'center',
      class: 'status-col',
    },
    { text: 'Price(ex. Vat)', value: 'price', sortable: false, align: 'right' },
    { text: '', value: 'multiply', sortable: false, class: 'icon-colum' },
    {
      text: 'Qty',
      value: 'qty',
      sortable: false,
      align: 'right',
      class: 'qty-col',
    },
    { text: 'Total price', value: 'price', sortable: false, align: 'right' },
  ]
  private pictureList: any[] = []
  private engineerJobVisitStatus = EngineerJobVisitStatus
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('contractorManagement')

  private created() {
    if (this.parentComponent === 'ContractorJobCard') {
      // if contractor job detail dialog is opened from ContractorJobCard, connect signalR
      this.handleSignalR()
    }
  }

  @Watch('contractorJobDetail')
  private contractorJobDetailChange() {
    this.getPictureFromBlob()
  }

  private get emergencyDefinition(): string | void {
    if (this.contractorJobDetail && this.contractorJobDetail.emergencyPolicyDefinition) {
      return this.contractorJobDetail.emergencyPolicyDefinition
    }
    return 'No policy information available'
  }

  private get vulnerabilityAnswer(): VulnerabilityQA | void {
    if (this.contractorJobDetail && this.contractorJobDetail.vulnerabilityWarning) {
      return this.contractorJobDetail.vulnerabilityWarning
    }
    return new VulnerabilityQA()
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }
  // Check if userRole is Network Manager
  private get isUserRoleNetworkManager(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'NetworkManager'
    )
  }
  // show icon to redirect to job in ''OutstandingJobDashboard' amd CET users
  private get showIconToRedirectToJob() {
    if (this.isUserRoleAdministrator || this.isUserRoleNetworkManager) {
      if (
        this.contractorJobDetail &&
        this.contractorJobDetail.jobDetail &&
        (this.parentComponent === 'OutstandingJobDashboard' || this.parentComponent === 'SIJobDetail')
      ) {
        return true
      }
      return false
    }
    return false
  }
  // get contractor job detail
  private get contractorJobDetail(): ContractorJobDetailModel | null {
    return this.contractorJobDetailItem
  }

  // get Additional Request
  private get getAdditionalRequestList(): EngineerRequestModel[] {
    if (this.contractorJobDetail) {
      let engineerRequest: EngineerRequestModel[] | undefined = this.contractorJobDetail.engineerRequests
      engineerRequest = this.contractorJobDetail.engineerRequests.filter(
        (x) => x.requestCategory === EngineeringRequestCategory[EngineeringRequestCategory.Resource]
      )
      return Shared.SortData(engineerRequest, 'createdAt').reverse()
    }
    return []
  }

  private set getAdditionalRequestList(newValue: EngineerRequestModel[]) {
    if (this.contractorJobDetail) {
      this.contractorJobDetail.engineerRequests = newValue
      Shared.SortData(this.contractorJobDetail.engineerRequests, 'createdAt').reverse()
    }
  }

  private getFormattedDate(date: Moment): string {
    if (date) {
      return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
    }
    return ''
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private closeContractorJobDetaildialog() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
    this.$emit('contractorJobDetailDialogClose')
  }

  private redirectToJob(jobId: string) {
    if (!Shared.isUserHasJobRights(jobId)) {
      return
    }
    const routeData = this.$router.resolve({ path: '/job/' + jobId })
    window.open(routeData.href, '_blank')
  }

  private getBlobUrl(imageURL: string): string {
    const url: any = this.pictureList.find((x) => x.imageUrl === imageURL)
    if (url) {
      return url.imageUrlBlob
    }
    return ''
  }

  private async getPictureFromBlob(): Promise<void> {
    if (this.contractorJobDetail) {
      for (const picture of this.contractorJobDetail.picturesUpload) {
        const pictureObject: object = {
          imageUrl: picture.fileURL,
          imageUrlBlob: await PhotosController.GetThumbnailUrl(picture.jobId, picture.id),
        }
        this.pictureList.push(pictureObject)
      }
    }
  }

  private get getUsers(): UserModel[] {
    return storeGetters.getUsers()
  }

  private getUserName(id: string) {
    const user: UserModel | undefined = this.getUsers.find((e) => e.id === id)
    if (user !== undefined) {
      return user.displayValue
    }
  }

  private get getEngineerVisitDetail(): EngineerVisitDetail | null {
    if (this.contractorJobDetail && this.contractorJobDetail.engineerVisitDetails) {
      return this.contractorJobDetail.engineerVisitDetails
    }
    return null
  }

  private get getHealthSafetyQA(): VisitHealthAndSafetyProcess | null {
    if (this.contractorJobDetailItem && this.contractorJobDetail) {
      if (this.contractorJobDetail.healthSafetyQAs) {
        return this.contractorJobDetail.healthSafetyQAs[0]
      }
    }
    return null
  }

  private get getBoilerBreakdownQA(): BoilerBreakdownQA | null {
    if (this.contractorJobDetailItem && this.contractorJobDetail) {
      if (this.contractorJobDetailItem.boilerBreakdownQA) {
        return this.contractorJobDetail.boilerBreakdownQA
      }
    }
    return null
  }

  public get getAccessNotes(): AccessNoteModel | null {
    if (this.contractorJobDetailItem && this.contractorJobDetail) {
      if (this.contractorJobDetailItem.accessNotes) {
        return this.contractorJobDetail.accessNotes
      }
    }
    return null
  }

  public get getAdditionalHealthQA(): HealthAndSafetyQAModel | null {
    if (this.contractorJobDetailItem && this.contractorJobDetail) {
      if (this.contractorJobDetailItem.healthAndSafetyQA) {
        return this.contractorJobDetail.healthAndSafetyQA
      }
    }
    return null
  }

  private getEngineer(engineerId: string) {
    return this.engineers.find((e) => e.id === engineerId)
  }

  private handleSignalR() {
    this.signalRHub.addHandler('appointedContractor', this.updateContractorDetail)
    this.signalRHub.addHandler('updateEngineerRequest', this.updateEngineerRequest)
    this.signalRHub.connect() // connect signalR Hub.
  }

  private async updateContractorDetail(
    jobId: string,
    contractorId: string,
    requestType: string,
    processedItem: RequestProcessedRecord,
    success: boolean
  ) {
    if (!success) {
      return // message not processed, nothing to update
    }
    // refresh item
    if (processedItem) {
      // when dialog is open, update visit started and completed date when updated from timeline
      if (
        this.contractorJobDetail &&
        this.contractorJobDetail.jobDetail.jobId === jobId &&
        this.getEngineerVisitDetail &&
        this.getEngineerVisitDetail.contractorId === contractorId &&
        this.getEngineerVisitDetail.id === processedItem.id
      ) {
        const res = await JobController.GetJobDocument(jobId, processedItem.id)
        const engineerVisitDetail = Object.assign(new EngineerVisitDetail(), res)
        this.contractorJobDetail.engineerVisitDetails = engineerVisitDetail
      }
    }
  }

  private async updateEngineerRequest(
    jobId: string,
    engineerRequestId: string,
    processedItem: RequestProcessedRecord,
    success: boolean
  ) {
    if (!success) {
      return // message not processed, nothing to update
    }
    // refresh item
    if (processedItem) {
      // when dialog is open, update material cost when updated from timeline or from contractor job detail
      if (this.contractorJobDetail && this.contractorJobDetail.jobDetail.jobId === jobId) {
        const index = this.getAdditionalRequestList.findIndex((e) => e.id === engineerRequestId)
        if (index !== -1) {
          const res = await JobController.GetJobDocument(jobId, processedItem.id)
          const engineerRequest = Object.assign(new EngineerRequestModel(), res)
          const requests = JSON.parse(JSON.stringify(this.getAdditionalRequestList))
          requests.splice(index, 1, engineerRequest)
          this.getAdditionalRequestList = requests
        }
      }
    }
  }

  public get engineerName(): string {
    const exists =
      this.getEngineerVisitDetail !== null &&
      this.getEngineerVisitDetail.engineerFirstName !== null &&
      this.getEngineerVisitDetail.engineerLastName !== null
    return exists
      ? `${this.getEngineerVisitDetail!.engineerFirstName} ${this.getEngineerVisitDetail!.engineerLastName}`
      : '---'
  }

  private destroyed() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }
}
</script>

<style scoped>
.job-basicinfo > div {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.job-basicinfo > div >>> .v-icon {
  position: absolute;
  left: 0px;
}
.prperty-detail {
  line-height: 0px;
}
table {
  width: 100%;
}
table td {
  padding-top: 0px;
  padding-bottom: 3px;
}
.total {
  font-weight: 600;
}
.total span {
  padding-right: 2px;
}
.img-block .img-title {
  font-weight: 600;
}
.img-block .up-images img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.request-datatable >>> .icon-colum {
  max-width: 20px !important;
}
.request-datatable >>> .icon-colum,
.request-datatable >>> .total-label,
.request-datatable >>> .qty-col,
.request-datatable >>> .status-col,
.request-datatable >>> .total-colum {
  padding: 0px !important;
}
.request-datatable >>> .item-name {
  min-width: 250px !important;
}
.job-detail-dlog-content >>> .scoll-vb-content {
  min-height: 500px;
}
.question-inner-content {
  height: 100%;
  min-height: 40px;
}
.gridView th {
  font-size: 14px !important;
}
table.v-table tfoot tr td {
  padding: 0 10px;
}
.engineer-requests-statu {
  display: inline-block;
  padding: 0px 10px;
}
.request-datatable >>> .request-col {
  min-width: 140px;
}
.notes {
  background-color: #fff2af;
}
</style>
