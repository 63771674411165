import { Moment } from 'moment'
import { RequestStatus } from './RequestStatus'

export default class ClientTemplateDeploymentModel {
  public id: string
  public createdAt: Moment
  public createdByName: string
  public createdByNote: string
  public request: PublishClientTemplateModel
  public status: RequestStatus
  public errorDetail: string
}

export class PublishClientTemplateModel {
  public PublishToStagingPortal: boolean
  public PublishToProductionPortal: boolean
  public UpdateClientTemplateRequestId: string
}
