<template>
  <div>
    <HealthAndSafetyQA ref="healthAndSafetyQA" :health-and-safety-q-a="vulnerabilityQAItem"></HealthAndSafetyQA>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQA from '@/models/EmergencyQA'
import storeGetters from '@/storeGetters'

import HealthAndSafetyQA from '@/components/timeline/HealthAndSafetyQA.vue'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import JobController from '@/api/jobController'

@Component({
  components: { HealthAndSafetyQA },
})
export default class VulnerabilityQAPreview extends TimeLineItemBase {
  private get vulnerabilityQAItem(): VulnerabilityQAModel {
    return storeGetters.getVulnerabilityQA(this.jobId)
  }
}
</script>
