<template>
  <v-layout wrap class="mb-2">
    <v-flex xs12 mb-2>
      <v-alert
        v-if="!isEnablementCompleted"
        :value="!isEnablementCompleted"
        color="error lighten-5 red--text"
        type="error"
        class="custom-warning-error pa-2"
      >
        <span>Enablement is not completed.</span>
      </v-alert>
      <v-alert
        v-if="isEnablementCompleted"
        :value="isEnablementCompleted"
        color="green lighten-5 green--text"
        type="success"
        class="custom-warning-success pa-2"
      >
        <span>Enablement is completed.</span>
      </v-alert>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'

@Component
export default class EnablementNotesCard extends TimeLineItemBase {
  private get isEnablementCompleted(): boolean {
    return this.job !== null ? this.job.enablementCompletedAt !== null : false
  }
}
</script>

<style scoped>
.affected-description {
  padding-left: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.affected-details {
  position: relative;
}
.affected-details span:first-child {
  position: absolute;
  left: 0px;
}
.custom-warning-error,
.custom-warning-success {
  border-top: 0px;
}
.custom-warning-error >>> .v-icon {
  color: #f44336 !important;
  margin-right: 5px;
}
.custom-warning-success >>> .v-icon {
  color: #66bb6a !important;
  margin-right: 5px;
}
</style>
