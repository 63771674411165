import ContactNumber from '@/models/contractor/ContactNumber'
export default class AddThirdPartyContractorDetail {
  public jobId: string
  public forEmergencyTypeId: number
  public forEmergencyDetailId: number
  public emergencyId: string
  public contractorId: string
  public companyName: string
  public contactNumbers: ContactNumber[]
  public status: string
}
