import MapLocationModel from '@/models/contractor/MapLocationModel'
import ContractorCoverageDistrictModel from './ContractorCoverageDistrictModel'

export default class ContractorCoverageModel {
  public id: string
  public contractorId: string
  public radius: number
  public location: MapLocationModel
  public postcode: string
  public trades: number[]
  public district: ContractorCoverageDistrictModel[]
}
