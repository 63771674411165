<template>
  <v-container>
    <v-layout wrap>
      <v-flex xs12 sm6>
        <v-text-field
          v-model="policySearch.forename"
          v-validate="{ required: isSearchValueNotSet }"
          :data-vv-scope="validationScope"
          data-vv-name="Forename"
          label="Forename"
          maxlength="50"
          name="Forename"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6>
        <v-text-field
          v-model="policySearch.surname"
          v-validate="{ required: isSearchValueNotSet }"
          :data-vv-scope="validationScope"
          data-vv-name="Surname"
          label="Surname"
          maxlength="50"
          name="Surname"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12 sm6>
        <v-text-field
          v-model="policySearch.policyNumber"
          v-validate="{ required: isSearchValueNotSet }"
          data-vv-name="PolicyNumber"
          :data-vv-scope="validationScope"
          label="Policy Number"
          maxlength="50"
          name="PolicyNumber"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6>
        <v-text-field
          v-model="policySearch.postcode"
          v-validate="{
            required: isSearchValueNotSet,
            regex: '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{0,2}|[Gg][Ii][Rr] ?0[0-9][A-Za-z]{2})$',
          }"
          data-vv-name="Postcode"
          :data-vv-scope="validationScope"
          :error-messages="errorMessage('Postcode')"
          label="Postcode"
          maxlength="50"
          name="Postcode"
        ></v-text-field>
      </v-flex>
      <v-flex>
        <v-text-field
          v-model="policySearch.addressLine1"
          v-validate="{ required: isSearchValueNotSet }"
          data-vv-name="AddressLine1"
          :data-vv-scope="validationScope"
          label="Address Line 1"
          maxlength="50"
          name="AddressLine1"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12>
        <span v-if="isSearchCriteriaRequired" name="SearchCriteriaError" class="red--text"
          >Please enter data into at least one field
        </span>
      </v-flex>
      <v-flex xs12>
        <v-btn
          name="PolicySearch"
          color="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="submitPolicySearch"
          >Search For Policy</v-btn
        >
      </v-flex>
      <v-flex xs12>
        <h3 v-if="policies.length" name="CustomPolicySearchResultHeader">Policies ({{ policies.length }})</h3>
        <v-card
          v-for="(item, index) in policies"
          :key="`customPolicyResult-${index}`"
          class="my-3 policy-item"
          @click="selectPolicy(item)"
        >
          <v-card-title class="py-1"
            >{{ item.customerName }} - <span class="font-weight-bold">{{ item.policyNumber }}</span></v-card-title
          >
          <v-card-text>{{ item.customerAddressLine1 }} - {{ item.customerPostcode }}</v-card-text>
        </v-card>
      </v-flex>
      <v-flex v-if="assumedCoverResults.length" xs12>
        <h3 name="AssumedCoverSearchResultHeader">Default Policies ({{ assumedCoverResults.length }})</h3>
        <v-card
          v-for="(item, index) in assumedCoverResults"
          :key="`assumedCoverResult-${index}`"
          class="my-3 policy-item"
          @click="selectAssumedCoverPolicy(item)"
        >
          <v-card-title class="py-1"
            >{{ item.name }} - <span class="font-weight-bold"> {{ item.id }}</span></v-card-title
          >
          <v-card-text class="text-uppercase text-orange">{{ item.message }}</v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import PolicyHolderController from '@/api/policyHolderController'
import AssumedCover from '@/models/policy/AssumedCover'
import CustomPolicySearchRequest from '@/models/policy/CustomPolicySearchRequest'
import CustomPolicySearchResponse from '@/models/policy/CustomPolicySearchResponse'
import PolicySummary from '@/models/policy/PolicySummary'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import { ErrorBag } from 'vee-validate'
import { Component, Vue } from 'vue-property-decorator'
import storeGetters from '@/storeGetters'

@Component
export default class CustomPolicySearch extends Vue {
  public isLoading = false
  public searchResponse: CustomPolicySearchResponse | null = null
  public validationScope = 'customPolicySearchValidator'
  public policySearch = new CustomPolicySearchRequest()

  public async submitPolicySearch() {
    const isValid: boolean = await this.$validator.validateAll(this.validationScope)
    if (isValid) {
      this.isLoading = true

      if (this.currentCallClientId) {
        this.policySearch.clientId = this.currentCallClientId
      }

      this.searchResponse = await PolicyHolderController.SearchCustomerPolicy(this.policySearch)
      if (
        !this.searchResponse ||
        (this.searchResponse.results &&
          !this.searchResponse.results.length &&
          !this.searchResponse.assumedCoverResults?.length)
      ) {
        this.$store.dispatch(
          'snackbarModule/showSnackbar',
          new ShowErrorSnackbar('Policy details not found, please select a policy manually', 0)
        )
        this.isLoading = false
        return
      }

      this.isLoading = false
    }
  }

  public selectPolicy(policy: PolicySummary) {
    this.$emit('policySelected', policy)
  }

  public selectAssumedCoverPolicy(assumedCover: AssumedCover | null) {
    this.$emit('assumedCoverPolicySelected', assumedCover)
  }

  public get isSearchValueNotSet(): boolean {
    return Object.values(this.policySearch).every((x) => x === '' || x === null)
  }

  public get policies(): PolicySummary[] {
    return this.searchResponse && this.searchResponse.results ? this.searchResponse.results : []
  }

  public get assumedCoverResults(): AssumedCover[] {
    return this.searchResponse && this.searchResponse.assumedCoverResults ? this.searchResponse.assumedCoverResults : []
  }

  public get errors(): ErrorBag {
    return this.$validator.errors
  }

  public get isSearchCriteriaRequired(): boolean {
    return this.errors.items.filter((x) => x.rule === 'required').length > 0
  }

  public get currentCallClientId(): string {
    return storeGetters.getCurrentCallClientId()
  }

  public errorMessage(fieldName: string) {
    const errorsWithoutRequiredField = this.errors.items.filter((x) => x.rule !== 'required')
    const error = errorsWithoutRequiredField.find((x) => x.field === fieldName)
    return error ? error.msg : ''
  }

  public clearCustomPolicyForm() {
    this.policySearch = new CustomPolicySearchRequest()
    this.searchResponse = null
  }
}
</script>

<style lang="scss" scoped>
.policy-item {
  cursor: pointer;
}
</style>
