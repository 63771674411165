import CosmosJobsDbRecordBase from '../CosmosJobsDbRecordBase'
import SIVisitPitTestDetail from './SIVisitPitTestDetail'
import BoreHoleLog from './BoreHoleLog'

export default class SIVisitTrialPit extends CosmosJobsDbRecordBase {
  public forEngineerVisitDetailId: string
  public pitId: string
  public pitName: string
  public pitDescription: string
  public boreHoleDepth: number
  public pitTests: SIVisitPitTestDetail[]
  public productChildLinks: SIVisitPitTestDetail[] | null
  public boreHoleLogs: BoreHoleLog[]
  public trialPitDrawingId: string
  public status: string
  public notes: string
  public abandonedTrialPitReason: string
  public abandonedTrialPitReasonNotes: string

  constructor() {
    super()
    this.productChildLinks = null
  }
}
