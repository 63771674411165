<template>
  <div class="task-col grey lighten-3">
    <div class="task-header d-flex">
      <h3 :class="record.headerClass">{{ siJobStatus }} ({{ totalJobsCount }})</h3>
      <v-menu
        v-if="isTestingHeader(siJobStatus) || isNewHeader(siJobStatus)"
        bottom
        right
        :close-on-content-click="false"
        :nudge-width="240"
        offset-x
        class="status-filter"
      >
        <template #activator="{ on }">
          <v-btn slot="activator" icon color="primary" flat small class="ma-0 filter-btn" v-on="on">
            <v-icon class="md-icon" :color="getFilterColor(siJobStatus)" :class="getFilterClass(siJobStatus)" dark>
              filter_list
            </v-icon>
          </v-btn>
        </template>
        <v-card class="pa-2 pt-0">
          <v-select
            v-if="isTestingHeader(siJobStatus)"
            v-model="selectedPostVisitAction"
            placeholder="Visit Type"
            class="visit-type-select"
            :items="postVisitActionFilterList"
            @change="postVisitActionFilterChange($event)"
          />
          <v-select
            v-else
            v-model="selectedDelayReason"
            placeholder="Delay"
            class="visit-type-select"
            :items="delayReasonList"
            item-text="description"
            item-value="description"
            @change="postDelayReasonFilterChange($event)"
          />
        </v-card>
      </v-menu>
    </div>
    <v-divider class="mt-0 mx-3 mb-2"></v-divider>
    <div v-if="record.items.length" class="task-body px-3 pb-3 pt-1">
      <v-card v-for="(item, index) in record.items" :key="index">
        <JobSummaryCard :key="index" :job="item" :title-generator="titleGenerator" @redirectToJob="onRedirectToJob">
          <div slot="JobCardDetails">
            <div class="primary-title">
              <h4 class="date-title">
                <span v-if="item.claimManagementCompany && !item.companyName">
                  <v-icon color="secondary" class="claim-management-icon">business</v-icon>
                  <a
                    class="ml-1 pl-4"
                    href="javascript:void(0)"
                    :name="'companyName-' + item.id"
                    @click.prevent="openSIJobDetailModal(item)"
                  >
                    {{ item.claimManagementCompany }}
                  </a>
                </span>
                <v-tooltip v-if="item.visitNumber > 1" top>
                  <template #activator="{ on }">
                    <v-badge overlap color="grey darken-2" class="return-visit">
                      <span slot="badge" small>{{ item.visitNumber - 1 }}</span>
                      <span v-on="on">
                        <img src="/img/return-visit.svg" alt="" />
                      </span>
                    </v-badge>
                  </template>
                  <span>Return Visit</span>
                </v-tooltip>
                <span v-if="item.companyName">
                  <a
                    href="javascript:void(0)"
                    :name="'companyName-' + item.id"
                    @click.prevent="openSIJobDetailModal(item)"
                  >
                    {{ item.companyName }}
                  </a>
                  <span v-if="!item.engineerName" class="primary--text">
                    (
                    <v-menu attach offset-y>
                      <template #activator="{ on }">
                        <a href="javascript:void(0)" :name="'contractor-' + item.id" v-on="on">
                          <v-icon color="secondary" small>call</v-icon>
                        </a>
                      </template>
                      <v-list v-for="(contactNumber, numberIndex) in item.contactNumbers" :key="numberIndex">
                        <v-list-tile>
                          <v-list-tile-title>
                            <span
                              :name="'contractor-' + numberIndex + '-' + item.id"
                              class="primary--text cursor-pointer"
                              @click.prevent="onCallIconClick(item, numberIndex)"
                            >
                              <v-icon>call</v-icon>
                              {{ contactNumber.phoneNumber }}
                            </span>
                            <span class="primary--text">
                              {{ contactNumber.label ? '(' + contactNumber.label + ')' : '' }}
                            </span>
                          </v-list-tile-title>
                        </v-list-tile>
                        <v-divider v-if="numberIndex !== item.contactNumbers.length - 1"></v-divider>
                      </v-list>
                    </v-menu>
                    )
                  </span>
                </span>
              </h4>
              <span v-if="isNewHeader(siJobStatus) && item.delayCode">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon color="red" v-on="on">av_timer</v-icon>
                  </template>
                  <span>Delay</span>
                </v-tooltip>
                <v-tooltip bottom :disabled="!item.delayNotes">
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.delayCode }}</span>
                  </template>
                  <span>{{ item.delayNotes }}</span>
                </v-tooltip>
              </span>
            </div>
            <div v-if="isTesting(item)" class="bottom-row">
              <v-tooltip v-for="(itemType, ind) in item.itemsToVerify" :key="ind + 'i'" bottom>
                <template #activator="{ on }">
                  <v-icon v-if="!isProductTypeVerified(item, itemType)" dark color="orange" v-on="on">info</v-icon>
                  <v-icon v-else dark color="success" v-on="on">check_circle</v-icon>
                </template>
                <span>{{ itemTypeTitle(itemType) }}</span>
              </v-tooltip>
              <v-tooltip v-for="(docType, ind) in item.requiredTestingDocuments" :key="ind" bottom>
                <template #activator="{ on }">
                  <v-icon v-if="!testingDocumentStatus(item, docType)" dark color="orange" v-on="on">info</v-icon>
                  <v-icon v-else dark color="success" v-on="on">check_circle</v-icon>
                </template>
                <span>{{ documentTypeTitle(docType) }}</span>
              </v-tooltip>
            </div>
            <div v-else-if="item.engineerName || item.engineerContactNumber" class="bottom-row">
              <span class="img-text">
                {{ item.engineerName }}
              </span>
              <span class="primary--text">
                (
                <v-menu attach offset-y>
                  <template #activator="{ on }">
                    <a href="javascript:void(0)" :name="'engineer-' + item.id" v-on="on">
                      <v-icon color="secondary" small>call</v-icon>
                    </a>
                  </template>
                  <v-list>
                    <v-list-tile>
                      <v-list-tile-title>
                        <span
                          :name="'engineer-' + item.id"
                          class="primary--text cursor-pointer"
                          @click.prevent="onCallIconClick(item)"
                        >
                          <v-icon>call</v-icon>
                          {{ item.engineerContactNumber }}
                        </span>
                      </v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
                )
              </span>
            </div>
            <div v-if="item.visitStartedAt && item.visitStartedAt.isValid()" class="bottom-date">
              <v-icon small class="mr-2">date_range</v-icon>
              <span>Job Start:</span>
              <b>{{ getETADateFormat(item.visitStartedAt) }}</b>
            </div>
          </div>
        </JobSummaryCard>
      </v-card>
    </div>
    <div v-else class="unavailable-job-msg">
      <span>Data not available</span>
    </div>
    <PartialJobView :job-id="selectedJobIdToExpand" @closeJobView="closeJobView"></PartialJobView>
    <v-dialog
      v-if="siJobDetailDialog"
      v-model="siJobDetailDialog"
      max-width="1000"
      persistent
      content-class="v-dialog--scrollable"
    >
      <SIJobDetail
        ref="SIJobDetailRef"
        :job-id="selectedJobId"
        @closeSIJobDetailDialog="closeSIJobDetailDialog"
      ></SIJobDetail>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import JobSummaryCard from '@/components/JobSummaryCard.vue'
import OutstandingJobModel from '@/models/OutstandingJobModel'
import DashboardController from '@/api/dashboardController'
import moment, { Moment } from 'moment'
import eventBus from '@/common/bus'
import CallParameters from '@/models/twilio/CallParameters'
import SIReportModel from '@/models/claim/SIReportModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import ISIJobStatusBarModel from '@/models/ISIJobStatusBarModel'
import SIJobDetail from '@/components/SIJobDetail.vue'
import {
  SIJobStatus,
  UploadedDocumentTypeEnum,
  PostVisitActionsTypeEnum,
  RecordType,
  SIProductType,
} from '@/common/enums'
import MasterRecordController from '@/api/masterRecordController'
import MasterRecord from '@/models/MasterRecord'
import PartialJobView from '@/components/PartialJobView.vue'
import Shared from '@/common/shared'

@Component({
  name: 'JobStatusBar',
  components: {
    JobSummaryCard,
    SIJobDetail,
    PartialJobView,
  },
})
export default class JobStatusBar extends Vue {
  @Prop() private record: ISIJobStatusBarModel

  private selectedJobId = ''
  private siJobDetailDialog = false
  private postVisitActionFilterList: any = [
    PostVisitActionsTypeEnum.All,
    PostVisitActionsTypeEnum.TrialPitOutstanding,
    PostVisitActionsTypeEnum.CCTVOutstanding,
    PostVisitActionsTypeEnum.DatumOutstanding,
    PostVisitActionsTypeEnum.WaterMainsOutstanding,
    PostVisitActionsTypeEnum.TreeRootsOutstanding,
    PostVisitActionsTypeEnum.LabSummaryOustanding,
    PostVisitActionsTypeEnum.Verified,
  ]
  private selectedPostVisitAction: string = PostVisitActionsTypeEnum.All
  private delayReasonList: MasterRecord[] = []
  private selectedDelayReason = 'All'
  private openJobView = false
  private selectedJobIdToExpand = ''

  public titleGenerator = (job: ContractorAppointedModel) => job.id

  private created() {
    this.getDelayReasonList()
  }

  private isProductTypeVerified(siReport: SIReportModel, siProductType: SIProductType): boolean {
    return siReport.verifiedItems && siReport.verifiedItems.includes(siProductType)
  }

  private get siJobStatus() {
    let status: string = this.record.status
    if (status === SIJobStatus[SIJobStatus.CustomerNotified]) {
      status = 'ProvisionalDate'
    } else if (status === SIJobStatus[SIJobStatus.Testing]) {
      status = 'VisitComplete'
    }
    return status
      .split(/(?=[A-Z])/)
      .join(' ')
      .toString()
  }

  private get totalJobsCount() {
    return this.record.totalJobsCount
  }

  private onCallIconClick(sIJob: SIReportModel, index?: number) {
    const callParameters = new CallParameters()
    if (index != null) {
      callParameters.to = sIJob.contactNumbers[index].phoneNumber
    } else {
      callParameters.to = sIJob.engineerContactNumber
    }

    callParameters.jobId = sIJob.id
    callParameters.contractorId = sIJob.contractorId
    // callParameters.emergencyId = sIJob.emergencyId;
    eventBus.$emit('callIconClick', callParameters)
  }

  private openSIJobDetailModal(sIJob: SIReportModel): void {
    this.siJobDetailDialog = true
    this.selectedJobId = sIJob.id
  }

  private closeSIJobDetailDialog(): void {
    this.siJobDetailDialog = false
    this.selectedJobId = ''
  }

  private documentTypeTitle(type: UploadedDocumentTypeEnum): string {
    switch (type) {
      case UploadedDocumentTypeEnum.LabSummary:
        return 'Lab Summary'
      case UploadedDocumentTypeEnum.TreeRoots:
        return 'Tree Roots'
      default:
        return ''
    }
  }

  private itemTypeTitle(siProductType: number): string {
    switch (siProductType) {
      case SIProductType.Pit:
        return 'Trial Pit'
      case SIProductType.CCTV:
        return 'CCTV Log'
      case SIProductType.WaterMains:
        return 'Water Mains Test'
      case SIProductType.Datum:
        return 'Datum Log'
      default:
        return ''
    }
  }

  private isTesting(item: SIReportModel): boolean {
    return item.status === SIJobStatus[SIJobStatus.Testing]
  }

  private testingDocumentStatus(item: SIReportModel, type: UploadedDocumentTypeEnum): boolean {
    if (!item.missingTestingDocuments) {
      return true
    }
    return !item.missingTestingDocuments.includes(type)
  }

  private isTestingHeader(status: string): boolean {
    return status === 'Visit Complete'
  }

  private isNewHeader(status: string): boolean {
    return status === 'New'
  }

  private postVisitActionFilterChange(status: string) {
    this.selectedPostVisitAction = status
    this.$emit('filterVisitCompleted', status)
  }

  private getFilterColor(status: string): string {
    if (this.isTestingHeader(status)) {
      return 'green'
    } else {
      return 'red'
    }
  }

  private getFilterClass(status: string): string {
    if (
      this.isTestingHeader(status) &&
      this.selectedPostVisitAction !== PostVisitActionsTypeEnum[PostVisitActionsTypeEnum.All]
    ) {
      return 'filter-visit-activated'
    } else if (this.isNewHeader(status) && this.selectedDelayReason !== 'All') {
      return 'filter-new-activated'
    }
    return ''
  }

  private postDelayReasonFilterChange(status) {
    this.selectedDelayReason = status
    this.$emit('filterDelay', status)
  }

  private async getDelayReasonList() {
    try {
      const recordType: string = RecordType[RecordType.SIJobDelayReason]
      const res = await MasterRecordController.GetMasterRecords(recordType)
      if (res) {
        this.delayReasonList = res
      }
      const delayReason = new MasterRecord()
      delayReason.description = 'All'
      delayReason.id = 'All'
      this.delayReasonList.unshift(delayReason)
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading SI job delay reason list, please try again.', true)
    }
  }

  private onRedirectToJob(jobId: string) {
    if (!Shared.isUserHasJobRights(jobId)) {
      return
    }
    // open timeline with relative card open.
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.task-header {
  padding: 10px 15px;
  position: relative;
  min-height: 48px;
}
.task-header span {
  font-weight: 500;
  display: inline-block;
  margin-left: 5px;
}
.task-body .v-card {
  margin-bottom: 15px;
}
.task-body .v-card:last-child {
  margin-bottom: 0;
}
.task-body .v-card >>> .v-card__title {
  padding: 6px 12px 4px;
  border-bottom: 1px solid #e0e0e0;
}
.task-body .v-card >>> .v-card__text {
  padding: 4px 12px 6px;
}
.task-body >>> .v-card__text .primary-title {
  position: relative;
}
.task-body >>> .v-card__text .primary-title h4 a {
  vertical-align: middle;
}
.task-body h4.date-title .card-emergency-icon,
.claim-management-icon {
  position: absolute;
  left: 0;
  top: 0px;
}
.claim-management-icon {
  top: -2px;
}
.task-body h4.date-title >>> .v-tooltip {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  top: -2px;
}
.category-icon-img {
  width: 15px;
  vertical-align: top;
}
.task-body >>> .v-card .details-row {
  position: relative;
  padding-left: 20px;
}
.task-body >>> .v-card .details-row i {
  position: absolute;
  left: -5px;
  top: 0px;
  font-size: 22px;
}
.task-body .eta-info {
  font-size: 13px;
  vertical-align: top;
  position: relative;
  padding-left: 30px;
  margin: 0;
  color: #fff;
}
.task-body .eta-info >>> .v-icon {
  vertical-align: text-bottom;
  position: absolute;
  left: 8px;
  top: 3px;
}
.all-jobs.open-block .jobs-taskboard {
  display: none;
}
.all-jobs .jobs-map {
  display: none;
}
.all-jobs.open-block .jobs-map {
  display: block;
}
.task-action {
  position: relative;
}
.task-action >>> .progress-linear {
  position: absolute;
  bottom: -10px;
  left: 0;
}
.task-col {
  min-height: 250px;
}
.unavailable-job-msg {
  display: flex;
  min-height: 200px;
}
.unavailable-job-msg span {
  margin: auto;
  color: #ababab;
  font-size: 16px;
}
.search-contractor-outjobs {
  display: inline-block;
  width: 300px;
}
.task-body .v-card >>> .v-card__actions {
  padding: 8px 4px;
}
.all-jobs >>> .v-btn--flat {
  background: transparent !important;
  box-shadow: none !important;
}
.task-body .v-card >>> .v-card__title.icon-space {
  padding-right: 25px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.img-text {
  position: relative;
  padding-left: 20px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.img-text img {
  position: absolute;
  top: 3px;
  max-width: 17px;
  left: -2px;
}
.task-body h4.date-title {
  width: 100%;
  height: 100%;
  vertical-align: top;
  padding-right: 20px;
}
.status-filter {
  flex: 0 !important;
}
.filter-visit-activated {
  background: lightgreen;
  border-radius: 50%;
  padding: 4px;
}
.filter-new-activated {
  background: lightpink;
  border-radius: 50%;
  padding: 4px;
}
.return-visit {
  position: absolute;
  right: -5px;
  top: 0px;
}
.return-visit img {
  width: 25px;
  height: 20px;
}
.return-visit >>> .v-badge__badge {
  width: 15px;
  height: 15px;
  font-size: 11px;
  line-height: 0;
  top: -8px;
  right: -6px;
}
</style>
