<template>
  <div>
    {{ displayReportStatus }}
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import ReportDetail from '../../models/ReportDetail'
import { ReportStatus } from '../../common/enums'

@Component
export default class BuildReportCard extends TimeLineItemBase {
  private get displayReportStatus() {
    if (!this.reportDetail || this.reportDetail.reportStatus === ReportStatus.New) {
      return 'Report ready to build.'
    }
    switch (this.reportDetail.reportStatus) {
      case ReportStatus.BuildingReport:
        return 'Building Report...'
      case ReportStatus.ReportBuilt:
        return 'Report built and ready for download.'
      case ReportStatus.ReportFailed:
        return 'Report failed to build.'
      case ReportStatus.ReportSent:
        return 'Report has been emailed to client.'
    }
    return undefined
  }

  private get reportDetail(): ReportDetail | null {
    return storeGetters.getReportDetail(this.jobId)
  }
}
</script>
