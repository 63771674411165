<template>
  <div class="center-sec-layout">
    <v-dialog v-model="tradeDialog" max-width="500" persistent content-class="v-dialog--scrollable">
      <Trade
        :trade-item="tradeModel"
        :emergency-types="emergencyTypes"
        :emergency-details="emergencyDetails"
        :selected-emergency-details="selectedEmergencyDetails"
        @OnTradeSave="OnTradeSave"
      ></Trade>
    </v-dialog>
    <div class="content">
      <v-layout wrap>
        <v-flex xs12>
          <v-card flat class="transparent">
            <v-card-title primary-title class="py-0 px-0">
              <h3>Trade List</h3>
              <v-spacer></v-spacer>
              <v-select
                v-model="selectedJobType"
                :items="getJobTypes"
                label="Job Type"
                item-text="jobType"
                item-value="jobType"
                class="mt-0 pt-0 mr-3 job-type-select"
                single-line
                hide-details
                multiple
                @change="onJobTypeChange(selectedJobType, $event)"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile-action>
                    <v-checkbox
                      v-model="jobTypeCheckboxes[getJobTypes.indexOf(data.item)].checked"
                      color="primary"
                    ></v-checkbox>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ data.item.jobTypeDescription }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </v-select>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                class="mt-0 pt-0"
                hide-details
              ></v-text-field>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon color="primary" flat class="mt-2" @click="openTradeDialog" v-on="on">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Add Trade</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-layout wrap>
              <v-flex xs12 class="trade-table">
                <v-data-table
                  :headers="headers"
                  :items="tradelist"
                  class="elevation-1"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                  sort-icon="mdi-menu-down"
                  :search="search"
                  :pagination.sync="pagination"
                >
                  <template slot="items" slot-scope="props">
                    <td>{{ props.item.description }}</td>
                    <td class="text-xs-center trade-code">
                      {{ props.item.shortCode ? props.item.shortCode : '-' }}
                    </td>
                    <td>
                      <v-chip
                        v-for="emergency in props.item.emergency"
                        :key="emergency.emergencyId"
                        flat
                        outline
                        small
                        class="primary--text emergency"
                        color="primary"
                        :class="emergency.isDeleted ? 'deleted-item' : ''"
                      >
                        {{ emergency.description }}
                      </v-chip>
                    </td>
                    <td>
                      <v-chip
                        v-for="emergencyDetails in props.item.emergencydetail"
                        :key="emergencyDetails.emergencyDetailId"
                        flat
                        small
                        outline
                        class="primary--text emergency-detail"
                        color="primary"
                        :class="emergencyDetails.isDeleted ? 'deleted-item' : ''"
                      >
                        {{ emergencyDetails.description }}
                      </v-chip>
                    </td>
                    <td>
                      <v-chip
                        v-for="(delayId, index) in props.item.delayCodes"
                        :key="`${delayId}-${index}`"
                        flat
                        small
                        outline
                        class="primary--text emergency-detail"
                        color="primary"
                      >
                        {{ retrieveDelayCode(delayId).description }}
                      </v-chip>
                    </td>
                    <td>{{ props.item.jobType }}</td>
                    <td class="text-xs-right">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn icon class="mx-0" v-on="on" @click="onEditClick(props.item)">
                            <v-icon color="primary">edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn icon class="mx-0" v-on="on" @click="onDeleteClick(props.item)">
                            <v-icon color="primary">delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
            <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
              {{ snackbarText }}
              <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TradeModel from '@/models/policyHolder/TradeModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import Trade from '@/components/Trade.vue'
import PolicyHolderController from '@/api/policyHolderController'
import Shared from '@/common/shared'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import { JobType } from '@/models/types'
import eventBus from '@/common/bus'
import DelayCode from '@/models/delay/DelayCode'
import { RecordType } from '@/common/enums'
import MasterRecordController from '@/api/masterRecordController'
import MasterRecord from '@/models/MasterRecord'

interface IJobTypeEnum {
  jobType: JobType
  jobTypeDescription: string
}

@Component({
  components: { Trade },
})
export default class TradeManagement extends Vue {
  private tradeDialog = false
  private tradeModel: TradeModel = new TradeModel()
  private savedTradeModel: TradeModel = new TradeModel()
  private snackbarTimeout = 3000
  private snackbar = false
  private snackbarText = ''
  private isLoading = false
  private search = ''
  private pagination: any = {}
  private headers: any = [
    {
      text: 'Trade Name',
      align: 'left',
      value: 'description',
      class: 'tradename-cl',
    },
    {
      text: 'Trade Code',
      value: 'shortCode',
      align: 'left',
      class: 'tradecode-cl',
    },
    {
      text: 'Emergency',
      value: 'emergency',
      align: 'left',
      class: 'emergency-cl',
    },
    {
      text: 'Emergency Details',
      value: 'emergencydetail',
      align: 'left',
      class: 'emergency-details-cl',
    },
    {
      text: 'Delay Codes',
      value: 'delayCodes',
      align: 'left',
      class: 'delay-codes-cl',
    },
    {
      text: 'JobType',
      value: 'jobType',
      align: 'left',
      class: 'emergency-details-cl',
    },
    { text: 'Action', align: 'right', value: '', class: 'action-cl' },
  ]
  private tradelist: TradeModel[] = []
  private emergencyDetails: EmergencyDetailModel[] = []
  private selectedEmergencyDetails: EmergencyDetailModel[] = []
  private jobtype: JobType = 'HE'
  private selectedJobType: JobType[] = ['HE', 'US', 'SI']
  private jobTypeCheckboxes: any = []
  private localTradeList: TradeModel[] = [] // temp variable to manage all trade list

  private get getJobTypes(): IJobTypeEnum[] {
    return Shared.jobTypeList
  }

  private getTrades() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.setTradeList(res)
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.setTradeList(trades)
    }
  }

  private setTradeList(trades: TradeModel[]) {
    // set data as per selected jobType
    trades = trades.filter((t) => !t.isDeleted)
    const typeWiseTradeList: TradeModel[] = []
    this.selectedJobType.forEach((type) => {
      const filteredData = trades.filter((e) => e.jobType === type)
      typeWiseTradeList.push(...filteredData)
    })
    this.tradelist = typeWiseTradeList
    this.localTradeList = JSON.parse(JSON.stringify(trades))
    this.tradelist.forEach((element) => {
      this.SetEmergencyDetailsForDataBinding(element)
    })
  }

  private SetEmergencyDetailsForDataBinding(item: any) {
    if (item.emergencyDetailList != null) {
      item.emergencydetail = item.emergencyDetailList
      const emergencies: any = [...new Set(item.emergencyDetailList.map((a) => a.typeId))]
      item.emergency = this.emergencyTypes.filter((f) => emergencies.includes(f.emergencyId))
    }
  }

  private created(): void {
    if (!this.delayCodes.length) {
      this.setDelayCodes()
    }

    if (!storeGetters.getTradeDelays().length) {
      this.$store.dispatch('retrieveTradeDelays')
    }

    const self = this
    if (self.emergencyTypes.length === 0) {
      self.setEmergencies()
    }
    PolicyHolderController.GetEmergencyDetails().then((res: EmergencyDetailModel[]) => {
      self.emergencyDetails = res
      self.getTrades()
    })
    // show all checkboxes of jobType checked on page load
    this.jobTypeCheckboxes = this.selectedJobType.map((code) => {
      return {
        checked: true,
      }
    })
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies()
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        storeMutations.setEmergencies(res)
      }
    })
  }

  private setUniqueTypeIds(item: TradeModel) {
    return [...new Set(item.emergencyDetailList.map((a) => a.typeId))]
  }

  private setEmergemcyDetailIds(item: TradeModel) {
    return item.emergencyDetailList.map((a) => a.emergencyDetailId)
  }

  private setSnackBarText(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private openTradeDialog() {
    this.tradeDialog = true
    this.tradeModel = new TradeModel()
  }

  private OnTradeSave(trade: TradeModel, isSaved: boolean) {
    this.tradeDialog = false
    if (isSaved) {
      const trades: TradeModel[] = storeGetters.getTrades()
      if (trade.tradeId) {
        const index = trades.findIndex((item) => item.tradeId === trade.tradeId)
        if (index !== -1) {
          trades.splice(index, 1, trade)
        } else {
          trades.push(trade)
        }
      }
      storeMutations.setTrades(trades)
    }
    this.getTrades()
    this.tradeModel = new TradeModel()
  }

  private onEditClick(item: TradeModel) {
    this.savedTradeModel = item
    this.tradeDialog = true
    this.tradeModel.description = item.description
    this.tradeModel.tradeId = item.tradeId
    this.tradeModel.emergencyDetailList = item.emergencyDetailList === null ? [] : item.emergencyDetailList
    this.tradeModel.emergencyIds = []
    this.tradeModel.disclaimer = item.disclaimer
    this.tradeModel.emergencyDetailIds = []
    this.tradeModel.shortCode = item.shortCode
    this.tradeModel.jobType = item.jobType
    this.tradeModel.delayCodes = item.delayCodes
    if (item.emergencyDetailList != null) {
      this.tradeModel.emergencyDetailIds = this.setEmergemcyDetailIds(item)
      this.tradeModel.emergencyIds = this.setUniqueTypeIds(item)
      this.selectedEmergencyDetails = []
      for (const emergencyDetail of this.emergencyDetails) {
        for (const emergencyId of this.tradeModel.emergencyIds) {
          if (emergencyDetail.typeId === emergencyId) {
            this.selectedEmergencyDetails.push(emergencyDetail)
          }
        }
      }
      storeMutations.setEmergencies(
        Shared.getSelectedItemFirstInList(this.emergencyTypes, this.tradeModel.emergencyIds, 'emergencyId')
      )
      this.selectedEmergencyDetails = Shared.getSelectedItemFirstInList(
        this.selectedEmergencyDetails,
        this.tradeModel.emergencyDetailIds,
        'emergencyDetailId'
      )
    } else {
      this.selectedEmergencyDetails = []
    }
  }

  private onDeleteClick(item: TradeModel) {
    Shared.confirmationPopup.open('', '', 'trade', '', '', this, 'deleteTrade', item)
  }

  private deleteTrade(item: any) {
    if (item.tradeId !== 0) {
      const self = this
      self.isLoading = true
      PolicyHolderController.DeleteTrade(item.tradeId)
        .then((res: boolean) => {
          if (res) {
            self.tradelist.splice(self.tradelist.indexOf(item), 1)
            self.localTradeList.splice(self.tradelist.indexOf(item), 1)
            storeMutations.setTrades(self.localTradeList)
            self.setSnackBarText('Trade is deleted successfully')
            self.isLoading = false
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error deleting trade detail, please try again', true)
          self.isLoading = false
        })
    }
  }

  private onJobTypeChange(selectedItems: JobType[], items: JobType[]) {
    if (items.length === 0) {
      // no jobtype is selected
      this.tradelist = []
    } else {
      // set data as per selected jobType
      const typeWiseTradeList: TradeModel[] = []
      items.forEach((type) => {
        const filteredData = this.localTradeList.filter((e) => e.jobType === type)
        typeWiseTradeList.push(...filteredData)
      })
      this.tradelist = typeWiseTradeList
      this.tradelist.forEach((trade) => {
        this.SetEmergencyDetailsForDataBinding(trade)
      })
    }
    // set 'checked' property of checkbox (check or uncheck) based on item selection
    if (selectedItems.length < items.length) {
      const selectedItem = this.getJobTypes.find((e) => e.jobType === items[items.length - 1])
      if (selectedItem !== undefined) {
        this.jobTypeCheckboxes[this.getJobTypes.indexOf(selectedItem)].checked = true
      }
    } else if (selectedItems.length > items.length) {
      const itemToRemoveFromList = selectedItems.find((e) => !(items as any).includes(e))
      const item = this.getJobTypes.find((e) => e.jobType === itemToRemoveFromList)
      if (item !== undefined) {
        this.jobTypeCheckboxes[this.getJobTypes.indexOf(item)].checked = false
      }
    }
  }

  private get delayCodes(): DelayCode[] {
    return storeGetters.getDelayCodes() as DelayCode[]
  }

  private retrieveDelayCode(delayId: string): DelayCode {
    return this.delayCodes.filter((x) => x.id === delayId)[0]
  }

  // Set list of delay code.
  private setDelayCodes(): void {
    const recordType: string = RecordType[RecordType.DelayCode]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        storeMutations.setDelayCodes(res)
      }
    })
  }
}
</script>

<style scoped>
.trade-table >>> th {
  font-size: 14px !important;
  font-weight: 600;
}
.trade-table >>> .emergency-details-cl,
.delay-codes-cl {
  width: 50%;
}
.trade-table >>> .emergency-cl {
  width: 25%;
}
.trade-table >>> .tradename-cl {
  min-width: 250px;
}
.trade-table >>> .tradecode-cl {
  min-width: 10px;
}

.trade-table >>> .action-cl {
  min-width: 120px;
}
.job-type-select {
  max-width: 200px;
  width: 200px;
}
</style>
