<template>
  <div>
    <div class="mb-2"><h4>Return Visit Request</h4></div>
    <div v-if="item.returnVisitETAFrom" class="mb-1">
      <label>ETA From: </label>
      <span>{{ getFormattedDate(item.returnVisitETAFrom) }}</span>
    </div>
    <div v-if="item.returnVisitETATo" class="mb-1">
      <label>ETA To: </label>
      <span>{{ getFormattedDate(item.returnVisitETATo) }}</span>
    </div>
    <div v-if="engineerName" class="mb-1">
      <label>Engineer: </label>
      <span>{{ engineerName }}</span>
    </div>
    <div v-if="item.returnVisitReason" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.returnVisitReason }}</span>
    </div>
    <div v-if="item.returnVisitNote" class="mb-1">
      <label>Note: </label>
      <span>{{ item.returnVisitNote }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import AddSIRevisitRequest from '../../models/requests/AddSIRevisitRequest'
import Shared from '../../common/shared'
import Store from '@/store'

@Component
export default class AddSIRevisitRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddSIRevisitRequest {
    return Object.assign(new AddSIRevisitRequest(), this.requestBody)
  }

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.item.engineerId)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
