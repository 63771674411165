import CosmosAdminDbRecordBase from '@/models/CosmosAdminDbRecordBase'
import ClientTradeRateModel from './ClientTradeRateModel'
import ClientFixedFeeRateModel from './ClientFixedFeeRateModel'
export default class ClientRatePackageModel extends CosmosAdminDbRecordBase {
  public forCustomPackagePolicyId: number
  public packageName: string
  public claimHandling: number
  public isClaimHandlingVATable: boolean
  public softFix: number
  public isSoftFixVATable: boolean
  public abortedVisit: number
  public isAbortedVisitVATable: boolean
  public preDeploymentCancelledFee: number
  public isPreDeploymentCancelledFeeVATable: boolean
  public postDeploymentCancelledFee: number
  public isPostDeploymentCancelledFeeVATable: boolean
  public preDeploymentRepudiatedFee: number
  public isPreDeploymentRepudiatedFeeVATable: boolean
  public postDeploymentRepudiatedFee: number
  public isPostDeploymentRepudiatedFeeVATable: boolean
  public isFixedFeePolicy: boolean
  public clientFixedFeeRates: ClientFixedFeeRateModel
  public clientTradeRates: ClientTradeRateModel[]

  public constructor() {
    super()
    this.claimHandling = 0
    this.softFix = 0
    this.abortedVisit = 0
    this.preDeploymentCancelledFee = 0
    this.postDeploymentCancelledFee = 0
    this.preDeploymentRepudiatedFee = 0
    this.postDeploymentRepudiatedFee = 0
    this.clientTradeRates = [new ClientTradeRateModel()]
    this.clientFixedFeeRates = new ClientFixedFeeRateModel()
  }
}
