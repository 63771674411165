<template>
  <v-layout wrap>
    <v-flex>
      <v-container v-if="videoSource" pa-3>
        <video v-if="videoSource.webEncoded" class="video-player" controls muted playsinline>
          <source :src="videoSource.sasUrl" type="video/mp4" />
        </video>
        <v-card v-else>
          <v-layout wrap class="align-center">
            <v-flex pa-3 xs6>
              <span>Browser playback not available for this run.</span>
            </v-flex>
            <v-flex pa-3 xs6 class="text-xs-right">
              <v-btn color="primary" @click="downloadVideoFile">Download Video</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-container>
    </v-flex>
    <v-flex v-if="!isAddUpdateMode && cctvControlRunLogLocal" pa-3>
      <v-layout wrap mb-2 class="sitedata-accordion">
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>From:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.from }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>To:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.to }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Pipe Material:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.pipeMaterial }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Pipe Diameter:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.pipeDiameter }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Direction:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.direction }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Function:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.function }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Invert Level:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.invertLevelOne }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Invert Level:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.invertLevelTwo }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Shared Run:</b></label>
          <span class="ml-1">
            {{ cctvControlRunLogLocal.sharedRun ? cctvControlRunLogLocal.sharedRun : '-' }}
          </span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Gully Condition:</b></label>
          <span class="ml-1">
            {{ cctvControlRunLogLocal.gullyCondition ? cctvControlRunLogLocal.gullyCondition : '-' }}
          </span>
        </v-flex>
        <v-flex xs12 mb-1>
          <label><b>Comments:</b></label>
          <span class="ml-1">{{ cctvControlRunLogLocal.comments }}</span>
        </v-flex>
      </v-layout>
      <v-card class="elevation-0 pt-3 pb-2">
        <v-card-text v-for="(cctvControlDistance, index) in cctvControlRunLogLocal.distances" :key="index" class="pb-2">
          <CCTVControlRunDistance :cctv-control-distance="cctvControlDistance" :job-id="jobId"></CCTVControlRunDistance>
          <v-divider v-if="cctvControlRunLogLocal.distances.length - 1 !== index" mt-1></v-divider>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex v-if="isAddUpdateMode">
      <v-layout wrap>
        <v-flex xs6 pr-1>
          <v-combobox
            v-model="cctvControlRunLogLocal.from"
            v-validate="'required'"
            :items="
              cctvLogConfiguration && cctvLogConfiguration.fromToLocations ? cctvLogConfiguration.fromToLocations : []
            "
            label="From"
            class="required from-location"
            name="fromLocation"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="From"
            :error-messages="errors.collect('From')"
            :search-input.sync="cctvControlRunLogLocal.from"
            maxlength="20"
            @keypress="alphanumericValidation($event)"
          ></v-combobox>
        </v-flex>
        <v-flex xs6 pr-1>
          <v-combobox
            v-model="cctvControlRunLogLocal.to"
            v-validate="'required'"
            :items="
              cctvLogConfiguration && cctvLogConfiguration.fromToLocations ? cctvLogConfiguration.fromToLocations : []
            "
            label="To"
            class="required to-location"
            name="toLocation"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="To"
            :error-messages="errors.collect('To')"
            :search-input.sync="cctvControlRunLogLocal.to"
            maxlength="20"
            @keypress="alphanumericValidation($event)"
          ></v-combobox>
        </v-flex>
        <v-flex xs6 pr-1>
          <v-autocomplete
            v-model="cctvControlRunLogLocal.pipeMaterial"
            v-validate="'required'"
            :items="
              cctvLogConfiguration && cctvLogConfiguration.pipeMaterials ? cctvLogConfiguration.pipeMaterials : []
            "
            label="Pipe Material"
            class="required"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="Pipe Material"
            :error-messages="errors.collect('Pipe Material')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs6 pl-1>
          <v-autocomplete
            v-model="cctvControlRunLogLocal.pipeDiameter"
            v-validate="'required'"
            :items="
              cctvLogConfiguration && cctvLogConfiguration.pipeDiameters ? cctvLogConfiguration.pipeDiameters : []
            "
            label="Pipe Diameter"
            class="required"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="Pipe Diameter"
            :error-messages="errors.collect('Pipe Diameter')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs6 pr-1>
          <v-autocomplete
            v-model="cctvControlRunLogLocal.direction"
            v-validate="'required'"
            :items="cctvLogConfiguration && cctvLogConfiguration.directions ? cctvLogConfiguration.directions : []"
            label="Direction"
            class="required"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="Direction"
            :error-messages="errors.collect('Direction')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs6 pl-1>
          <v-autocomplete
            v-model="cctvControlRunLogLocal.function"
            v-validate="'required'"
            :items="cctvLogConfiguration && cctvLogConfiguration.functions ? cctvLogConfiguration.functions : []"
            label="Function"
            class="required"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="Function"
            :error-messages="errors.collect('Function')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs6 pr-1>
          <v-text-field
            v-model="cctvControlRunLogLocal.invertLevelOne"
            v-validate="'required'"
            label="Invert Level"
            class="required"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="Invert Level One"
            :error-messages="validationMessage('Invert Level One')"
            maxlength="10"
            name="invertLevelOne"
            @keypress="numberKeyValidation($event)"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 pl-1>
          <v-text-field
            v-model="cctvControlRunLogLocal.invertLevelTwo"
            v-validate="'required'"
            label="Invert Level"
            class="required"
            required
            data-vv-scope="formCCTVControlRun"
            data-vv-name="Invert Level Two"
            :error-messages="validationMessage('Invert Level Two')"
            maxlength="10"
            @keypress="numberKeyValidation($event)"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 pr-1>
          <v-text-field v-model="cctvControlRunLogLocal.sharedRun" label="Shared Run"></v-text-field>
        </v-flex>
        <v-flex xs6 pl-1>
          <v-text-field v-model="cctvControlRunLogLocal.gullyCondition" label="Gully Condition"></v-text-field>
        </v-flex>
        <v-flex xs12 pl-1>
          <v-textarea v-model="cctvControlRunLogLocal.comments" rows="3" label="Comments"></v-textarea>
        </v-flex>
        <v-flex v-for="(cctvControlDistance, distanceIndex) in getCCTVControlDistance" :key="distanceIndex">
          <v-card class="mt-2 grey lighten-4 elevation-0 layer-card">
            <v-btn
              v-if="getCCTVControlDistance.length > 1"
              flat
              icon
              color="secondary"
              class="ma-0 close-icon btn-deleteDistance"
              @click="onDeleteCCTVDistance(distanceIndex)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-card-text class="pt-4">
              <CCTVControlRunDistance
                ref="refCCTVControlRunDistance"
                :is-add-update-mode="true"
                :cctv-log-configuration="cctvLogConfiguration"
                :cctv-control-distance="cctvControlDistance"
                :all-c-c-t-v-run-log-distance="JSON.parse(JSON.stringify(distances))"
              ></CCTVControlRunDistance>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 class="text-xs-right">
          <v-btn class="mr-0 my-3 btn-addNewDistance" color="primary" @click="onAddNewDistance">Add Distance</v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import CCTVControlRunLogModel from '@/models/siteInvestigation/CCTVControlRunLogModel'
import CCTVControlDistance from '@/models/siteInvestigation/CCTVControlDistance'
import SICCTVLogConfiguration from '@/models/siteInvestigation/SICCTVLogConfiguration'
import CCTVControlRunDistance from '@/components/siteInvestigation/CCTVControlRunDistance.vue'
import DrainageVideoSource from '@/models/DrainageVideoSource'

@Component({
  components: { CCTVControlRunDistance },
})
export default class CCTVControlRunLog extends Vue {
  public cctvControlRunLogLocal: CCTVControlRunLogModel | null = new CCTVControlRunLogModel()
  @Prop({ default: null }) public cctvControlRunLog: CCTVControlRunLogModel
  @Prop({ default: false }) public isAddUpdateMode: boolean
  @Prop({ default: () => [] })
  public cctvLogConfiguration: SICCTVLogConfiguration
  @Prop({ default: null }) public videoSource: DrainageVideoSource | null
  @Prop({ default: '' }) private jobId: string
  private cctvContrologDistance: CCTVControlDistance[] = []
  private distances: number[] = [] // manage all distance value added in cctv run to get minimum distance value

  public async validate(): Promise<boolean> {
    // check validation of CCTV run and all distances
    let result = true
    const cctvFormValidation: boolean = await this.$validator.validateAll('formCCTVControlRun')
    if (!cctvFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    let cctvDistanceValidation = true
    const cctvControlRunDistanceSub: any = this.$refs.refCCTVControlRunDistance as CCTVControlRunDistance[]
    if (cctvControlRunDistanceSub) {
      for (const cctvRunDistance of cctvControlRunDistanceSub) {
        cctvDistanceValidation = (await cctvRunDistance.$validator.validateAll()) && !cctvRunDistance.errorMessage
        if (!cctvDistanceValidation) {
          Shared.setValidationFocus(cctvRunDistance.$el as HTMLElement)
          break
        }
      }
    }

    // set focus to non validate field
    if (!cctvDistanceValidation || !cctvFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = cctvDistanceValidation && cctvFormValidation
    }
    return result
  }

  private mounted() {
    this.updateData()
  }

  @Watch('cctvControlRunLog')
  private updateData() {
    this.cctvControlRunLogLocal = Object.assign({}, this.cctvControlRunLog)
    this.getCCTVControlDistance = this.cctvControlRunLogLocal
      ? JSON.parse(JSON.stringify(this.cctvControlRunLogLocal.distances))
      : []
    this.distances = []
  }

  private downloadVideoFile() {
    if (this.videoSource && this.videoSource.sasUrl) {
      window.open(this.videoSource.sasUrl, '_blank')
    }
  }

  private get getCCTVControlDistance(): CCTVControlDistance[] {
    return this.cctvContrologDistance
  }

  private set getCCTVControlDistance(newValue: CCTVControlDistance[]) {
    this.cctvContrologDistance = newValue
  }

  private onAddNewDistance() {
    // add new distance to CCTV run
    this.distances = []
    const cctvControlRunDistanceSub: any = this.$refs.refCCTVControlRunDistance as CCTVControlRunDistance[]
    if (cctvControlRunDistanceSub) {
      cctvControlRunDistanceSub.forEach((cctvControlRunDistance) => {
        this.distances.push(cctvControlRunDistance.cctvControlDistanceLocal.distance)
      })
    }
    if (this.cctvControlRunLogLocal) {
      const cctvControlDistance = new CCTVControlDistance()
      // generate unique id for each distance
      cctvControlDistance.id =
        this.getCCTVControlDistance && this.getCCTVControlDistance.length > 0
          ? Math.max.apply(
              Math,
              this.getCCTVControlDistance.map((e) => e.id)
            ) + 1
          : 1
      this.getCCTVControlDistance.push(cctvControlDistance)
    }
  }

  private onDeleteCCTVDistance(distanceIndex: number) {
    Shared.confirmationPopup.open(
      'Are you sure you want to delete CCTV distance?',
      '',
      '',
      '',
      '',
      this,
      'deleteCCTVDistance',
      distanceIndex
    )
  }

  private deleteCCTVDistance(distanceIndex: number) {
    // delete cctv control distance
    if (this.cctvControlRunLogLocal) {
      this.getCCTVControlDistance.splice(distanceIndex, 1)
      this.distances = []
      this.getCCTVControlDistance.map((e: any) => this.distances.push(e.distance))
    }
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The Invert Level field is required.') : message
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumeric(event)
  }

  private alphanumericValidation(event: any) {
    Shared.alphanumericValidation(event)
  }
}
</script>

<style scoped>
.layer-card .close-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.video-player {
  width: inherit;
  max-height: 40vh;
}
</style>
