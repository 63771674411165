import { ContactPreference } from '@/models/types'
import AddCommodity from './AddCommodity'

export default class AddDrainageJobWithCommodity {
  public clientTitle: string
  public clientForename: string
  public clientLastName: string
  public secondaryClientTitle: string | null
  public secondaryClientForename: string | null
  public secondaryClientLastName: string | null
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postcode: string
  public email: string
  public mobilePhone: string
  public contactPreference: ContactPreference
  public policyNumber: string
  public policyScheme: string
  public insurer: string
  public insuranceValue: number
  public policyScheduleId: number
  public commodity: AddCommodity | null
  public nomineeClientTitle: string | null
  public nomineeClientForename: string | null
  public nomineeClientLastName: string | null
  public relation: string | null
  public nomineeMobilePhone: string | null
  public nomineeEmail: string | null
  public nomineeContactPreference: ContactPreference
  public contactPreferenceId: number | null
  public nomineeContactPreferenceId: number | null
  public linkedJobId: string
  public reasonForChoosingCallOption: string
  public nomineeReasonForChoosingCallOption: string

  public constructor() {
    this.clientTitle = ''
    this.clientForename = ''
    this.clientLastName = ''
    this.policyNumber = ''
    this.email = ''
    this.mobilePhone = ''
    this.commodity = null
    this.contactPreference = null
    this.contactPreferenceId = null
    this.nomineeEmail = ''
    this.nomineeMobilePhone = ''
    this.nomineeContactPreference = null
    this.nomineeContactPreferenceId = null
    this.addressLine1 = ''
    this.addressLine2 = ''
    this.addressLine3 = ''
    this.addressLine4 = ''
    this.postcode = ''
    this.linkedJobId = ''
    this.reasonForChoosingCallOption = ''
    this.nomineeReasonForChoosingCallOption = ''
  }
}
