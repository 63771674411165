<template>
  <div>
    <div class="mb-2"><h4>Update Material Cost</h4></div>
    <div v-if="engineerRequest" class="mb-1">
      <label>Materials: </label>
      <span>{{ engineerRequest.materialName }}</span>
    </div>
    <div v-if="item.materialCost" class="mb-1">
      <label>Cost: </label>
      <span>{{ getFormatedCurrency(item.materialCost) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateMaterialCostRequestModel from '@/models/requests/UpdateMaterialCostRequestModel'
import storeGetters from '@/storeGetters'
import EngineerRequestBase from '@/models/claim/engineerRequest/EngineerRequestBase'
import Shared from '@/common/shared'
import { EngineerRequestType } from '@/common/enums'
import Job from '@/models/Job'

@Component
export default class UpdateMaterialCostRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateMaterialCostRequestModel {
    return Object.assign(new UpdateMaterialCostRequestModel(), this.requestBody)
  }

  private get selectedJob(): Job | null {
    return storeGetters.getJob(this.item.jobId)
  }

  private get engineerRequest(): EngineerRequestBase | undefined {
    if (this.selectedJob) {
      const request = this.selectedJob.engineerRequest.find((e) => e.id === this.item.id)
      return request ? request.engineerRequests.find((m) => m.requestType === EngineerRequestType.Material) : undefined
    }
    return undefined
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>
