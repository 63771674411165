import { TemplateStatus } from '@/common/enums'
import TemplateCookieRecord from '../cookies/template-cookie-record'

export default class ClientTemplateModel {
  public id = ''
  public insurerId: number

  public displayName = ''
  public subDomain = ''

  public primaryColor = ''
  public secondaryColor = ''
  public complementaryColor = ''
  public logoURL: string | null = null
  public favIconURL: string | null = null
  public headingStep1 = 'We can help you take care of your Home Emergency'
  public descriptionStep1 =
    "We link with many of the top insurers, by letting us know about your problem on this form, we can check whether you'll be covered, and if not, we'll call you with your options."
  public headingStep2 = 'Your Details'
  public descriptionStep2 = 'Please fill out your details so we know how to contact you.'
  public headingStep3 = 'Verify Your Contact Preference'
  public descriptionStep3 =
    'Verification code has been sent to the selected contact preference. Please verify your self and click next to continue.'
  public headingStep4 = 'Property Details'
  public descriptionStep4 = 'Please fill out your property details below.'
  public headingStep5 = 'Explain Your Situation'
  public descriptionStep5 = 'Please answer the following questions so we can get you help as fast as possible.'
  public headingStep6 = 'Your Availability'
  public descriptionStep6 =
    'Please let us know about your availability today, and if possible tomorrow, just in case we need to make a couple of visits.'
  public headingStep7 = 'Add Photos Of Your Emergency'
  public descriptionStep7 =
    'Attach up to 10 Photos to help further explain your situation. Max file size per item is 5MB.'
  public topEmergencies: number[] = []
  public policySchedules: number[] = []
  public fallBackStarPolicy = -1

  public dashboardWidgetEnabled = false
  public dashboardWidgetColor = ''

  public stagingPortalRequestId = ''
  public productionPortalRequestId = ''
  public privacyPolicyText = ''
  public legalNoticeText = ''
  public cookieNoticeText = ''
  public errorColor = ''

  public inputTextColor = ''

  public templateCookieRecord: TemplateCookieRecord | null = null

  public status: TemplateStatus = TemplateStatus.Online
  public holdingPageMessage = ''
}
