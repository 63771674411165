import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { JobType } from '@/common/enums'
import PolicyModel from '@/models/policy/PolicyModel'
import PolicySummaryModel from '@/models/policy/PolicySummaryModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import { InsurerBrandList } from '@/models/policy/PolicyLists'
import PolicyBasicDetailModel from '@/models/policy/PolicyBasicDetailModel'
import PolicyCommunicationDetailModel from '@/models/policy/PolicyCommunicationDetailModel'
import SavePolicyRouteToMarketModel from '@/models/policy/SavePolicyRouteToMarketModel'
import PolicyAccommodationDetailModel from '@/models/policy/PolicyAccommodationDetailModel'
import SavePolicyEmergencyQuestionsModel from '@/models/policy/SavePolicyEmergencyQuestionsModel'
import SavePolicyEmergencyCoverageModel from '@/models/policy/SavePolicyEmergencyCoverageModel'
import SavePolicyDocumentTemplatesModel from '@/models/policy/SavePolicyDocumentTemplatesModel'

export default class PolicyScheduleController {
  public static async GetPoliciesByInsurerId(insurerId: number, jobType?: JobType): Promise<PolicyModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<PolicyModel[]>(
      'PolicySchedule/GetAllPoliciesByInsurerId?insurerId=' + insurerId + '&jobType=' + jobType
    )
    return res.data ? res.data : []
  }

  public static async GetPolicyById(policyId: number): Promise<PolicyModel | null> {
    const res = await CallCentreApi.get<PolicyModel>('PolicySchedule/GetPolicyDetailById?policyId=' + policyId)
    return res.data
  }

  public static async GetPolicySummary(policyScheduleId: number): Promise<PolicySummaryModel | null> {
    const res = await CallCentreApi.get<PolicySummaryModel>(
      'PolicySchedule/GetPolicySummary?policyScheduleId=' + policyScheduleId
    )
    return res.data
  }

  public static async GetPolicyQuestionByPolicyId(
    policyId: number,
    selectedEmergencies: EmergencyModel[]
  ): Promise<PolicyModel> {
    const res = await CallCentreApi.post<PolicyModel>(
      'PolicySchedule/GetPolicyQuestionDetail?policyId=' + policyId,
      selectedEmergencies
    )
    return res.data ? res.data : new PolicyModel()
  }

  public static async GetPolicySchedule(isStarredPolicy: boolean, jobType: JobType): Promise<InsurerBrandList[]> {
    const res: AxiosResponse = await CallCentreApi.get(
      'PolicySchedule/GetPolicySchedule?isStarredPolicy=' + isStarredPolicy + '&jobType=' + jobType
    )
    return res.data
  }

  public static async SavePolicyDetail(policyDetail: PolicyBasicDetailModel): Promise<number> {
    const res: AxiosResponse = await CallCentreApi.post('PolicySchedule/SavePolicyDetail', policyDetail)
    if (res.status === 200) {
      const result = res.data as number
      if (!result) {
        return 0
      }
      return result
    } else {
      return 0
    }
  }

  public static async SavePolicyCommunicationDetail(
    policyCommunicationDetail: PolicyCommunicationDetailModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'PolicySchedule/SavePolicyCommunicationDetail',
      policyCommunicationDetail
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SavePolicyRouteToMarket(policyRouteToMarket: SavePolicyRouteToMarketModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'PolicySchedule/SavePolicyRouteToMarketDetail',
      policyRouteToMarket
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SavePolicyAccommodationDetail(
    policyAccommodationDetail: PolicyAccommodationDetailModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'PolicySchedule/SavePolicyAccommodationDetail',
      policyAccommodationDetail
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SavePolicyEmergencyCoverage(
    emergencyCoverageModel: SavePolicyEmergencyCoverageModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'PolicySchedule/SavePolicyEmergencyCoverageDetail',
      emergencyCoverageModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SavePolicyQuestion(policyQuestionModel: SavePolicyEmergencyQuestionsModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'PolicySchedule/SavePolicyEmergencyQuestions',
      policyQuestionModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SavePolicyDocumentTemplate(
    policyDocumentTemplateModel: SavePolicyDocumentTemplatesModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'PolicySchedule/SavePolicyDocumentTemplates',
      policyDocumentTemplateModel
    )
    return res.status >= 200 && res.status < 300
  }
}
