import { IAgentState } from '../states/interfaces/agent-state'
import AgentState from '../states/agent-state'
import { Module, MutationTree, GetterTree } from 'vuex'
import WorkerModelResponse from '@/api/models/WorkerModelResponse'
import ReservationModelResponse from '@/api/models/ReservationModelResponse'
import TwilioController from '@/api/twilioController'

const agentState: IAgentState = new AgentState()

const getters: GetterTree<IAgentState, any> = {
  worker: (state): WorkerModelResponse | null => state.worker,
  reservations: (state): ReservationModelResponse[] => state.reservations,
}

const mutations: MutationTree<IAgentState> = {
  set: (state, worker: WorkerModelResponse) => {
    state.worker = worker

    TwilioController.GetReservationsForWorker(worker.sid).then((res: ReservationModelResponse[]) => {
      state.reservations = res
    })
  },
  reset: (state) => {
    state.worker = null
    state.reservations = []
  },
  endReservation: (state, reservation: ReservationModelResponse) => {
    if (state.worker !== null) {
      TwilioController.CompleteWorkerReservation(state.worker.sid, reservation.sid).then((success) => {
        if (success && state.worker !== null) {
          TwilioController.GetReservationsForWorker(state.worker.sid).then((res: ReservationModelResponse[]) => {
            state.reservations = res
          })
        }
      })
    }
  },
}

export const agentModule: Module<IAgentState, any> = {
  state: agentState,
  mutations,
  getters,
  namespaced: true,
}
