import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import ContractorCoverageModel from '@/models/contractor/ContractorCoverageModel'
import moment from 'moment'
import { PreferredContractor, AutoDeploymentTypeEnum, CompanyType } from '@/common/enums'
import ContactNumber from '@/models/contractor/ContactNumber'
import HealthSafetyWellbeingEnvironment from './HealthSafetyWellbeingEnvironment'
import BankAccountDetail from './BankAccountDetailModel'
import Signature from './SignatureModel'

export default class ContractorModel {
  public id: string
  public firstName: string | null
  public lastName: string | null
  public companyName: string
  public website: string
  public rating: number
  public email: string
  public contactNumbers: ContactNumber[]
  public preferredContractor: PreferredContractor
  public address: string
  public description: string
  public fileURL: string | null
  public file: string | null
  public ratePackageId: string | null
  public createdDateUTC: moment.Moment
  public contractorAvailability?: ContractorTradeAvailabilityModel[]
  public coverage?: ContractorCoverageModel[]
  public isNearByContractor: boolean
  public distance: number
  public latitude: number
  public longitude: number
  public totalVisitsToday: number
  public totalOpenJobs: [number, number]
  public totalEngineers: [number, number]
  public isIndependent: boolean
  // public isAutoDeployment: boolean;
  public totalEngineerCount: number
  public holdOnFrom: moment.Moment | null
  public holdOnTo: moment.Moment | null
  public hasHoldDuration: boolean
  public country: string
  public delegatedAuthorityLimit: number
  public isDelegatedAuthority: boolean

  public tradingName: string
  public proprietorName: string
  public directorName: string
  public registrationNumber: string
  public vatRegistrationNumber: string
  public companyType: CompanyType
  public tradingAddress: string
  public bankAccountDetail: BankAccountDetail
  public isGasSafeRegistered: boolean
  public gasSafeRegistrationNumber: string
  public directEmployedHEWorker: number | null
  public subContractedHEWorker: number | null
  public healthSafetyWellbeingEnvironment: HealthSafetyWellbeingEnvironment
  public signature: Signature
  public subContractors: string[]
  public hasLicenceToCarryWaste: boolean

  public autoDeploymentType: string
  public autoDeployStartTime: moment.Moment | null
  public autoDeployEndTime: moment.Moment | null
  public autoDeployAlwaysActive: boolean
  public purchaseLedger: string
  public analysisCode: string

  public constructor() {
    this.contactNumbers = []
    if (!this.contractorAvailability) {
      this.contractorAvailability = []
    }
    if (!this.coverage) {
      this.coverage = []
    }
    this.holdOnFrom = null
    this.holdOnTo = null
    this.delegatedAuthorityLimit = 0
    this.healthSafetyWellbeingEnvironment = new HealthSafetyWellbeingEnvironment()
    this.bankAccountDetail = new BankAccountDetail()
    this.subContractors = []
    this.autoDeploymentType = AutoDeploymentTypeEnum[AutoDeploymentTypeEnum.NoAutodeployment]
    this.autoDeployAlwaysActive = false
    this.autoDeployStartTime = null
    this.autoDeployEndTime = null
    this.directEmployedHEWorker = null
    this.subContractedHEWorker = null
  }
}
