<template>
  <div>
    <div class="mb-2"><h4>Engineer Job Complete</h4></div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ item.status }}</span>
    </div>
    <div v-if="item.isSatisfied" class="mb-1">
      <label>Customer Satisfied: </label>
      <span>{{ item.isSatisfied ? 'Yes' : 'No' }}</span>
    </div>
    <div v-if="item.customerSignatureFileURL" class="mb-1">
      <label>Customer Signature URL: </label>
      <span>{{ item.customerSignatureFileURL }}</span>
    </div>
    <div v-if="item.visitNote" class="mb-1">
      <label>Site Notes: </label>
      <span>{{ item.visitNote }}</span>
    </div>
    <div v-if="item.visitOutcomeDescription" class="mb-1">
      <label>Visit Outcome: </label>
      <span>{{ item.visitOutcomeDescription }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateEngineerJobToComplete from '@/models/requests/UpdateEngineerJobToComplete'

@Component
export default class UpdateEngineerJobToCompleteLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateEngineerJobToComplete {
    return Object.assign(new UpdateEngineerJobToComplete(), this.requestBody)
  }
}
</script>
