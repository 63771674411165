<template>
  <v-snackbar v-model="showSnackbar" :color="snackbar.color" :timeout="snackbar.timeout" auto-height>
    <v-icon v-if="snackbar.icon" left>{{ snackbar.icon }}</v-icon>
    <div>
      {{ snackbar.message }}
    </div>
    <v-btn v-if="snackbar.showClose" flat color="secondary" @click="closeSnackbar()">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script lang="ts">
import SnackbarDisplay from '@/models/snackbar/snackbar-display'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Snackbar extends Vue {
  public get showSnackbar(): boolean {
    return this.$store.getters['snackbarModule/showSnackbar']
  }

  public set showSnackbar(value: boolean) {
    this.$store.commit('snackbarModule/setShowSnackbar', value)
  }

  public get snackbar(): SnackbarDisplay {
    return this.$store.getters['snackbarModule/snackbar']
  }

  public closeSnackbar(): void {
    this.$store.dispatch('snackbarModule/closeSnackbar')
  }
}
</script>
