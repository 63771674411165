<template>
  <div>
    <div class="mb-2"><h4>Delete CCTV Run Log</h4></div>
    <div v-if="item">
      <div v-if="item.cctvRunNumber" class="mb-1">
        <label>Run Number: </label>
        <span>{{ item.cctvRunNumber }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import DeleteCCTVControlRunLogModel from '../../models/requests/DeleteCCTVControlRunLogModel'

@Component
export default class DeleteCCTVControlRunLog extends Vue {
  @Prop() private requestBody: any

  private get item(): DeleteCCTVControlRunLogModel {
    return Object.assign(new DeleteCCTVControlRunLogModel(), this.requestBody)
  }
}
</script>

<style scoped></style>
