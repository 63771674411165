<template>
  <div>
    <span v-show="!contractor && isContractorReviewed" class="contractor-management-cp">
      <v-progress-circular
        :width="2"
        :size="50"
        indeterminate
        color="primary"
        :style="getLoaderStyle(70)"
      ></v-progress-circular>
    </span>
    <v-form v-if="contractor" autocomplete="off">
      <v-layout row wrap :class="!showContractorProfileInDialog ? 'mb-2' : ''">
        <v-flex
          :class="showContractorProfileInDialog ? 'profile-sec' : 'md8 lg6 offset-lg3 offset-md3 mt-3 profile-sec'"
        >
          <v-card class="elevation-2 pa-0">
            <v-layout wrap class="cntp-upper-sec primary pt-2">
              <v-flex xs12 class="text-xs-center cntp-logo-sec">
                <div class="cntp-logo-sec-content">
                  <span v-if="imageUrl" class="rm-logo elevation-1 pa-0">
                    <v-btn flat icon color="primary" class="ma-0 btn-remove" small @click.native.stop="removeImage">
                      <v-icon dark color="primary">delete</v-icon>
                    </v-btn>
                  </span>
                  <div class="cntp-logo-img mb-3" @click="pickFile">
                    <img :src="imageUrl ? imageUrl : '/img/unknownuser.png'" class="elevation-1" />
                    <span class="cntp-logo-img-edit">
                      <v-icon color="secondary" large>edit</v-icon>
                    </span>
                  </div>
                  <input
                    ref="image"
                    v-validate="{
                      rules: {
                        required: false,
                        image: true,
                        size: profileImageSize,
                        mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
                        ext: ['jpg', 'jpeg', 'png', 'gif'],
                      },
                    }"
                    type="file"
                    style="display: none"
                    accept="image/*"
                    name="logo"
                    data-vv-scope="frmProfile"
                    @change="onFilePicked"
                  />
                  <div class="input-group__details imageError-wrapper">
                    <div class="input-group__messages input-group__error input-group--error error--text">
                      <span v-if="imageValidationMessage(errors)">
                        {{ logoErrorMsg }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="pa-3 mt-5">
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="contractor.companyName"
                  v-validate="'required'"
                  label="Company Name"
                  maxlength="50"
                  required
                  class="required"
                  data-vv-scope="frmProfile"
                  data-vv-name="Company Name"
                  :error-messages="errors.collect('Company Name')"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="contractor.tradingName"
                  label="Trading Name (If different)"
                  maxlength="50"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="contractor.registrationNumber"
                  label="Company Registration No (If LTD)"
                  maxlength="100"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="contractor.vatRegistrationNumber"
                  label="VAT Registration Number"
                  maxlength="100"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="contractor.proprietorName"
                  label="Proprietor Name"
                  maxlength="100"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="contractor.directorName"
                  label="Director Name"
                  maxlength="100"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-select
                  v-model="contractor.companyType"
                  v-validate="'required'"
                  :items="getCompanyTypeList"
                  item-text="alterText"
                  item-value="description"
                  label="Type Of Company"
                  required
                  class="required"
                  data-vv-scope="frmProfile"
                  data-vv-name="Company Type"
                  :error-messages="errors.collect('Company Type')"
                  :readonly="isUserRoleContractor"
                ></v-select>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="contractor.website"
                  v-validate="'url'"
                  label="Website"
                  maxlength="50"
                  data-vv-scope="frmProfile"
                  data-vv-name="Website"
                  :error-messages="errors.collect('Website')"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-textarea
                  v-model.trim="contractor.address"
                  v-validate="'required'"
                  label="Address"
                  maxlength="500"
                  rows="3"
                  required
                  class="required"
                  data-vv-scope="frmProfile"
                  data-vv-name="Address"
                  :error-messages="errors.collect('Address')"
                  :readonly="isUserRoleContractor"
                ></v-textarea>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-textarea
                  v-model.trim="contractor.tradingAddress"
                  label="Trading Address"
                  maxlength="500"
                  rows="3"
                  :readonly="isUserRoleContractor"
                ></v-textarea>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="contractor.purchaseLedger"
                  label="Purchase Ledger"
                  maxlength="50"
                  :readonly="!canEditContractors"
                  name="PurchaseLedger"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-model.trim="contractor.analysisCode"
                  label="Analysis Code"
                  maxlength="50"
                  :readonly="!canEditContractors"
                  name="AnalysisCode"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model.trim="contractor.description"
                  label="Trade Type"
                  maxlength="500"
                  rows="3"
                  :readonly="isUserRoleContractor"
                ></v-textarea>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-text-field
                  v-model.trim="contractor.email"
                  v-validate="'required|email'"
                  label="Email"
                  maxlength="50"
                  required
                  class="required"
                  data-vv-scope="frmProfile"
                  data-vv-name="Email"
                  :error-messages="errors.collect('Email')"
                  :readonly="isUserRoleContractor"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-select
                  v-model.trim="contractor.country"
                  v-validate="'required'"
                  label="Country"
                  item-text="description"
                  item-value="id"
                  :items="getCountryNames"
                  class="required"
                  required
                  data-vv-scope="frmProfile"
                  data-vv-name="Country"
                  :error-messages="errors.collect('Country')"
                  :readonly="isUserRoleContractor"
                />
              </v-flex>
              <v-flex v-if="showUserNotificationPreferences" xs12>
                <v-switch
                  v-model="contractorComms.blindCopyEmailToEngineers"
                  :loading="loadingCommsPrefs"
                  :disabled="loadingCommsPrefs"
                  label="Copy Emails to Engineers"
                  color="primary"
                  hide-details
                />
              </v-flex>
              <v-flex xs12>
                <div
                  class="grey py-2 px-3 mt-4 lighten-4 multiple-contact-field"
                  :class="contractor.contactNumbers && contractor.contactNumbers.length > 1 ? 'add-remove-btn' : ''"
                >
                  <div v-bar="{ useScrollbarPseudo: true }">
                    <template v-if="contractor.contactNumbers">
                      <div v-for="(contact, index) in contractor.contactNumbers" :key="index">
                        <v-layout>
                          <v-flex xs6 pr-2>
                            <v-combobox
                              v-model.trim="contact.label"
                              label="Label"
                              maxlength="20"
                              return-object
                              :items="phoneNumberLabelOptions"
                              :readonly="isUserRoleContractor || disableIfCompulsory(contact.label)"
                            ></v-combobox>
                          </v-flex>
                          <v-flex xs6 pl-2>
                            <PhoneNumber
                              v-if="!isUserRoleContractor"
                              ref="contractorContactNumber"
                              :passed-phone-number="contact.phoneNumber"
                              :is-validation-required="true"
                              :index="index"
                              class="contractor-phone-number"
                              @phoneNumberUpdated="phoneNumberUpdated"
                            />
                            <v-text-field
                              v-else
                              :readonly="isUserRoleContractor"
                              :value="contact.phoneNumber"
                            ></v-text-field>
                          </v-flex>
                          <v-btn
                            v-if="
                              contractor.contactNumbers &&
                              contractor.contactNumbers.length > 1 &&
                              !isUserRoleContractor &&
                              !disableIfCompulsory(contact.label)
                            "
                            icon
                            class="contact-remove ma-0"
                            flat
                            color="secondary"
                            @click="deleteContact(index)"
                          >
                            <v-icon class="md-icon">delete</v-icon>
                          </v-btn>
                        </v-layout>
                      </div>
                    </template>
                  </div>
                  <v-btn
                    v-if="!isUserRoleContractor"
                    icon
                    class="ma-0 btn-add"
                    :class="
                      contractor.contactNumbers && contractor.contactNumbers.length > 1
                        ? 'add-remove-btn-icon'
                        : 'contact-add'
                    "
                    flat
                    color="primary"
                    @click="addNewContact"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </div>
              </v-flex>
              <template v-if="isContractorReviewed">
                <v-flex v-if="canEditContractors" xs12 mt-1>
                  <v-select
                    v-model.trim="contractor.preferredContractor"
                    :items="preferredContractorOptions"
                    label="Contractor Status"
                    item-value="id"
                    item-text="description"
                    :disabled="isFromCallContractor"
                    :readonly="isUserRoleContractor"
                  ></v-select>
                </v-flex>
                <v-flex v-if="!isUserRoleContractor" xs12 mt-1>
                  <v-switch
                    v-model="contractor.isDelegatedAuthority"
                    label="Delegated Authority"
                    color="primary"
                    hide-details
                  ></v-switch>
                </v-flex>
                <v-flex v-if="!isUserRoleContractor" xs12 mt-1>
                  <v-text-field
                    v-model.number="contractor.delegatedAuthorityLimit"
                    v-validate="{
                      rules: { max_value: 9999.99, decimal: 2, max: 7 },
                    }"
                    label="Delegated Authority Amount"
                    type="number"
                    :disabled="!contractor.isDelegatedAuthority"
                    placeholder="Enter Amount"
                    data-vv-scope="frmProfile"
                    data-vv-name="Delegated Authority Amount"
                    :error-messages="errors.collect('Delegated Authority Amount')"
                    @keypress="numberKeyValidation($event)"
                  ></v-text-field>
                </v-flex>

                <!-- group contractor -->
                <v-flex xs12 mt-1>
                  <v-autocomplete
                    v-model="contractor.subContractors"
                    :items="contractorList"
                    label="Select Members of the Group"
                    item-text="companyName"
                    item-value="id"
                    multiple
                    chips
                    :readonly="isUserRoleContractor"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        :selected="data.selected"
                        :close="!isUserRoleContractor"
                        class="chip--select-multi"
                        :readonly="isUserRoleContractor"
                        @input="data.parent.selectItem(data.item)"
                      >
                        {{ data.item.companyName }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex v-if="canEditContractors" xs12 mt-1>
                  <v-switch
                    v-model="contractor.isIndependent"
                    hide-details
                    label="Independent Contractor"
                    color="primary"
                    :readonly="isUserRoleContractor"
                    @change="onIsIndependentChange()"
                  ></v-switch>
                  <div v-if="isIndependentContractorErrorMsg" class="input-group__details">
                    <div class="input-group__messages input-group__error input-group--error error--text">
                      <span>You cannot be an Independent Contractor. You have multiple engineers configured.</span>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 sm6 pr-2>
                  <v-text-field
                    v-model.trim="contractor.firstName"
                    v-validate="'required'"
                    :disabled="!(contractor.isIndependent && (canEditContractors || isUserRoleContractor))"
                    label="First Name"
                    maxlength="50"
                    required
                    class="required"
                    data-vv-scope="frmProfile"
                    data-vv-name="First Name"
                    :error-messages="errors.collect('First Name')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pl-2>
                  <v-text-field
                    v-model.trim="contractor.lastName"
                    :disabled="!(contractor.isIndependent && (canEditContractors || isUserRoleContractor))"
                    label="Last Name"
                    maxlength="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 mt-1>
                  <v-select
                    v-model="contractor.autoDeploymentType"
                    :items="getAutoDeploymentTypes"
                    item-text="description"
                    item-value="name"
                    label="AutoDeployment Type"
                    :disabled="isFromCallContractor"
                    :readonly="isUserRoleContractor"
                  ></v-select>
                </v-flex>
                <template
                  v-if="
                    contractor.autoDeploymentType == autoDeploymentOption[autoDeploymentOption.AutodeployToEngineer] ||
                    contractor.autoDeploymentType == autoDeploymentOption[autoDeploymentOption.AutodeployToContractor]
                  "
                >
                  <v-flex xs12 mt-1 pb-2>
                    <v-switch
                      v-model="contractor.autoDeployAlwaysActive"
                      label="24 hours"
                      color="primary"
                      hide-details
                      @change="onAutoDeployChange"
                    ></v-switch>
                  </v-flex>
                  <v-flex xs12 sm6 pr-2>
                    <v-menu
                      ref="autoDeployStartTimePicker"
                      v-model="showautoDeployStartTimePicker"
                      :close-on-content-click="false"
                      :return-value.sync="contractor.autoDeployStartTime"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      :nudge-right="40"
                      :nudge-top="400"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="contractor.autoDeployStartTime"
                          v-validate="'required'"
                          label="From time"
                          append-icon="access_time"
                          readonly
                          required
                          class="required"
                          data-vv-scope="frmProfile"
                          data-vv-name="From time"
                          :error-messages="errors.collect('From time')"
                          :disabled="contractor.autoDeployAlwaysActive"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="showautoDeployStartTimePicker"
                        v-model="contractor.autoDeployStartTime"
                        format="24hr"
                        :allowed-minutes="allowedMinutes"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="showautoDeployStartTimePicker = false">Cancel</v-btn>
                        <v-btn
                          flat
                          color="primary"
                          @click="$refs.autoDeployStartTimePicker.save(contractor.autoDeployStartTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 pr-2>
                    <v-menu
                      ref="autoDeployEndTimePicker"
                      v-model="showautoDeployEndTimePicker"
                      :close-on-content-click="false"
                      :return-value.sync="contractor.autoDeployEndTime"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      :nudge-right="40"
                      :nudge-top="400"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="contractor.autoDeployEndTime"
                          v-validate="'required'"
                          label="To time"
                          append-icon="access_time"
                          readonly
                          required
                          class="required"
                          data-vv-name="To time"
                          :error-messages="errors.collect('To time')"
                          data-vv-scope="frmProfile"
                          :disabled="contractor.autoDeployAlwaysActive"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="showautoDeployEndTimePicker"
                        v-model="contractor.autoDeployEndTime"
                        format="24hr"
                        :allowed-minutes="allowedMinutes"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="showautoDeployEndTimePicker = false">Cancel</v-btn>
                        <v-btn
                          flat
                          color="primary"
                          @click="$refs.autoDeployEndTimePicker.save(contractor.autoDeployEndTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-menu>
                  </v-flex>
                </template>
                <v-flex v-if="canEditContractors" xs12 mt-1>
                  <v-switch
                    v-model="contractor.hasHoldDuration"
                    label="On hold"
                    color="primary"
                    hide-details
                  ></v-switch>
                </v-flex>
              </template>
              <v-flex v-if="contractor.hasHoldDuration && canEditContractors" xs6 pr-2>
                <v-menu
                  ref="fromDatePickerMenu"
                  v-model="showHoldOnFromDateSelection"
                  top
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="chosenHoldOnFromFormattedDate"
                      placeholder="Select from date"
                      append-icon="date_range"
                      readonly
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="chosenHoldOnFromDate" no-title scrollable :min="minOnHoldFromDate">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="showHoldOnFromDateSelection = false">Cancel</v-btn>
                    <v-btn
                      flat
                      class="btn-ok"
                      color="primary"
                      @click="onSelectHoldOnFromDatePicker(chosenHoldOnFromDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex v-if="contractor.hasHoldDuration" xs6 pl-2>
                <v-menu
                  ref="toDatePickerMenu"
                  v-model="showHoldOnToDateSelection"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="chosenHoldOnToFormattedDate"
                      placeholder="Select to date"
                      append-icon="date_range"
                      readonly
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="chosenHoldOnToDate" no-title scrollable :min="minOnHoldToDate">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="showHoldOnToDateSelection = false">Cancel</v-btn>
                    <v-btn class="btn-ok" flat color="primary" @click="onSelectHoldOnToDatePicker(chosenHoldOnToDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex v-if="commonErrorMsg" xs12 pl-2>
                <div class="pt-3 common-error-message">
                  <div class="red pa-2 d-block lighten-4">
                    <span>
                      <b>{{ commonErrorMsg }}</b>
                    </span>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12 class="text-xs-right mt-3">
                <v-btn
                  v-if="!isUserRoleContractor && showContractorProfileInDialog"
                  flat
                  class="ma-0 mr-2 btn-cancel"
                  color="primary"
                  @click="onCancelButtonClick"
                >
                  Cancel
                </v-btn>
                <v-btn
                  v-if="!isUserRoleContractor"
                  color="primary"
                  :loading="isLoading"
                  :disabled="isLoading"
                  class="ma-0 btn-save"
                  @click="saveContractorProfile"
                >
                  Save
                </v-btn>
              </v-flex>
              <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
                {{ saveSnackbarText }}
                <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
              </v-snackbar>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContactNumber from '@/models/contractor/ContactNumber'
import Shared from '@/common/shared'
import Store from '@/store'
import ContractorController from '@/api/contractorController'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import {
  type IPreferredContractor,
  PreferredContractors,
  type ICountryName,
  CountryNames,
  AutoDeploymentTypeEnum,
  CompanyType,
} from '@/common/enums'
import store from '@/store'
import moment from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import type EngineerModel from '../models/contractor/EngineerModel'
import eventBus from '@/common/bus'
import CommunicationPreferences from '@/models/contractor/CommunicationPreferences'
import debounce from 'lodash.debounce'

interface IAutoDeploymentOption {
  name: string
  description: string
}

interface IDropdownType {
  id: number
  description: string
  alterText?: string
}

@Component({
  components: { PhoneNumber, DateTimePicker },
})
export default class ContractorProfile extends Vue {
  @Prop() private contractorDetail: ContractorModel | null
  @Prop() public showContractorProfileInDialog: boolean
  @Prop({ default: 0 }) private totalEngineer: number
  @Prop() public isFromCallContractor: boolean
  @Prop({ default: null }) private engineer: EngineerModel | null
  @Prop({ default: true }) public isContractorReviewed: boolean
  @Prop({ default: () => [] }) public contractorList: ContractorModel[]

  private imageName = ''
  public imageUrl = ''
  public logoErrorMsg = ''
  public contractor: ContractorModel | null = null
  public contractorComms: CommunicationPreferences = new CommunicationPreferences()
  public loadingCommsPrefs = false
  private debounce: ReturnType<typeof debounce> | null = null
  public isLoading = false
  public saveSnackbarTimeout = 3000
  public saveSnackbar = false
  public phoneNumberLabelOptions = [
    'Emergency number',
    'OOH',
    'Services',
    'Admin',
    'Complaints',
    'Telephone Day',
    'Telephone Night',
    'Mobile',
    'Emergency Contact',
    'Emergency Telephone',
  ]
  public isIndependentContractorErrorMsg = false
  private isIndependentContractor = false
  public commonErrorMsg = ''
  public minOnHoldFromDate = ''
  public minOnHoldToDate = ''
  public showHoldOnFromDateSelection = false
  public showHoldOnToDateSelection = false
  public chosenHoldOnFromFormattedDate: any = null
  public chosenHoldOnToFormattedDate: any = null
  public chosenHoldOnFromDate: any = null
  public chosenHoldOnToDate: any = null
  public autoDeploymentOption = AutoDeploymentTypeEnum
  public showautoDeployEndTimePicker = false
  public showautoDeployStartTimePicker = false
  private isAutoDeployToEngineer = false

  public get saveSnackbarText(): string {
    return this.isContractorReviewed ? 'Company details saved successfully.' : 'Contractor saved successfully.'
  }

  private async created() {
    this.minOnHoldFromDate = moment(new Date()).format(DateTimePicker.DATE_FORMAT)
    this.minOnHoldToDate = moment(new Date()).format(DateTimePicker.DATE_FORMAT)

    let details = this.contractorDetail
    if (this.isUserRoleContractor) {
      try {
        details = await ContractorController.GetContractorDetails(
          Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.contractorId
        )
      } catch (err) {
        eventBus.$emit('errorHandler', 'Error loading contractor detail, please try again', true)
      }
    }

    if (details) {
      this.setContractorDetails(details)
    } else {
      this.contractor = new ContractorModel()
      this.contractor.contactNumbers = this.prepareRequiredContactNumberList()
    }

    // Used for Contractor Management.
    if (!this.isUserRoleContractor) {
      if (this.contractor && this.isFromCallContractor) {
        this.contractor.preferredContractor = 'Unverified'
      }
    }
  }

  public get showUserNotificationPreferences(): boolean {
    return !!this.$ld.variation('wip-498-user-notification-preferences')
  }

  @Watch('contractorDetail')
  private async onContractorChange() {
    this.commonErrorMsg = ''
    if (this.debounce) {
      this.debounce.cancel()
    }

    this.debounce = debounce(async () => {
      if (this.contractorDetail) {
        this.setContractorDetails(this.contractorDetail)
        if (this.showUserNotificationPreferences) {
          this.loadingCommsPrefs = true
          this.contractorComms = await ContractorController.GetCommunicationPreferences(this.contractorDetail.id)
          this.loadingCommsPrefs = false
        }
      }
    }, 500)
    this.debounce()
  }

  private setContractorDetails(contractor: ContractorModel) {
    if (contractor.isIndependent && this.engineer && this.totalEngineer === 1) {
      contractor.firstName = contractor.firstName || this.engineer.firstName || null
      contractor.lastName = contractor.lastName || this.engineer.lastName || null
    }

    this.contractor = contractor
    this.isIndependentContractor = this.contractor ? this.contractor.isIndependent : false
    this.imageUrl = this.contractor.file ? this.contractor.file : this.contractor.fileURL ? this.contractor.fileURL : ''
    this.imageName =
      this.contractor.fileURL && this.contractor.fileURL !== '' ? this.contractor.fileURL.replace(/^.*[\\/]/, '') : ''
    if (this.contractor && (!this.contractor.contactNumbers || this.contractor.contactNumbers.length === 0)) {
      this.prepareRequiredContactNumberList()
    } else {
      this.addRequiredContactNumbersInToExistingList()
    }
    // set on hold dates
    this.chosenHoldOnFromFormattedDate = null
    this.chosenHoldOnToFormattedDate = null
    if (this.contractor.holdOnFrom) {
      this.chosenHoldOnFromFormattedDate = moment(this.contractor.holdOnFrom).format(DateTimePicker.DATE_FORMAT)
    } else {
      this.chosenHoldOnFromFormattedDate = moment(this.minOnHoldFromDate).format(DateTimePicker.DATE_FORMAT)
    }
    if (this.contractor.holdOnTo) {
      this.chosenHoldOnToFormattedDate = moment(this.contractor.holdOnTo).format(DateTimePicker.DATE_FORMAT)
    }
    // set empty string as default value when getting 0 as country value from DB
    this.contractor.country = typeof contractor.country === 'string' ? contractor.country : ''
    this.$validator.errors.items = []
  }

  private async validate(): Promise<boolean> {
    let result = true
    const engineerFormValidation: boolean = await this.$validator.validateAll('frmProfile')
    if (!engineerFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    let contactNumberValidation = true
    const contactNumberValidationSub: any = this.$refs.contractorContactNumber as PhoneNumber[]
    if (contactNumberValidationSub) {
      for (const contact of contactNumberValidationSub) {
        contactNumberValidation = (await contact.$validator.validateAll()) && contact.isValid
        if (!contactNumberValidation) {
          Shared.setValidationFocus(contact.$el as HTMLElement)
          break
        }
      }
    }

    // set focus to non validate field
    if (!contactNumberValidation || !engineerFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = contactNumberValidation && engineerFormValidation
    }

    return result
  }

  public getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  public get profileImageSize(): number {
    return Store.Instance.state.Environment.LogoSize
  }

  public pickFile() {
    const logoError = this.$validator.errors.items.find((a) => a.field === 'logo')
    if (logoError) {
      logoError.msg = ''
    }
    const image = this.$refs.image as any
    image.click()
  }

  public onFilePicked(e: any) {
    const files = e.target.files
    if (files[0] !== undefined) {
      this.imageName = files[0].name
      if (this.imageName.lastIndexOf('.') <= 0) {
        return
      }
      const fr: any = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        this.imageUrl = fr.result
        if (this.contractor) {
          this.contractor.fileURL = this.imageName
          if (this.logoErrorMsg === '') {
            this.contractor.file = fr.result
          }
        }
      })
    }
  }

  public imageValidationMessage(err: any) {
    if (err && err.collect('logo').length > 0) {
      const message: string = err.collect('logo')[0]
      this.logoErrorMsg =
        message === 'The logo field must be an image.' ? 'Only JPEG, JPG, PNG, GIF files are allowed.' : message
      this.removeImage()
      return true
    }
  }

  public removeImage() {
    this.imageName = ''
    this.imageUrl = ''
    const image = this.$refs.image as any
    image.value = null
    if (this.contractor) {
      this.contractor.fileURL = ''
    }
  }

  public saveContractorProfile() {
    this.commonErrorMsg = ''
    if (this.contractor && this.contractor.isIndependent && this.totalEngineer > 1 && !this.isIndependentContractor) {
      this.isIndependentContractorErrorMsg = true
      return
    }
    if (this.contractor) {
      this.contractor.delegatedAuthorityLimit = this.contractor.isDelegatedAuthority
        ? this.contractor.delegatedAuthorityLimit
        : 0
      const autoDeploymentType = this.autoDeploymentOption[this.autoDeploymentOption.NoAutodeployment]
      if (this.contractor.autoDeploymentType === autoDeploymentType) {
        this.contractor.autoDeployStartTime = null
        this.contractor.autoDeployEndTime = null
        this.contractor.autoDeployAlwaysActive = false
      }
      if (this.contractor.autoDeployAlwaysActive) {
        this.contractor.autoDeployStartTime = null
        this.contractor.autoDeployEndTime = null
      }
    }

    this.validate()
      .then(() => {
        if (this.contractor) {
          this.isLoading = true
          // assign dates on switching On Hold flag
          if (!this.contractor.hasHoldDuration) {
            this.contractor.holdOnFrom = null
            this.contractor.holdOnTo = null
          } else {
            this.contractor.holdOnFrom = moment.utc(this.chosenHoldOnFromFormattedDate)
            this.contractor.holdOnTo = moment.utc(this.chosenHoldOnToFormattedDate)
          }
          this.contractor.email = this.contractor.email.toLowerCase()
          const tempFirstName = this.contractor.firstName || ''
          const tempLastName = this.contractor.lastName || ''
          // const hasEngineers = this.totalEngineer > 0 ? true : false;

          if (!this.isContractorReviewed) {
            this.saveInReviewContractor(tempFirstName, tempLastName)
          } else {
            this.saveContractor(tempFirstName, tempLastName)
          }
        }
      })
      .catch(() => {
        eventBus.$emit('validationErrorHandler')
        this.isLoading = false
      })
  }

  private saveContractor(tempFirstName: string, tempLastName: string) {
    if (this.contractor) {
      ContractorController.SaveContractor(this.contractor)
        .then((res: ContractorModel | string) => {
          if (typeof res !== 'string') {
            this.saveSnackbar = true
            this.contractor = res
            if (this.showUserNotificationPreferences) {
              this.saveContractorCommsPrefs(this.contractor.id)
            }
            this.isIndependentContractor = this.contractor ? this.contractor.isIndependent : false
            this.$emit(
              'isIndependentContractor',
              this.contractor ? this.contractor.isIndependent : false,
              this.contractor ? this.contractor : undefined
            )
            if (this.showContractorProfileInDialog && !this.isFromCallContractor) {
              this.$emit(
                'saveContractor',
                this.contractor ? (this.contractor.id === undefined ? false : true) : false,
                this.contractor ? this.contractor : undefined
              )
            }
            if (this.isFromCallContractor) {
              this.$emit('saveContractor', true, this.contractor)
            }
            this.contractor.firstName = tempFirstName
            this.contractor.lastName = tempLastName
          } else {
            this.showCommonError(res)
          }
        })
        .catch((err: any) => {
          if (err && err.response && err.response.statusText) {
            this.showCommonError(err.response.statusText)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  private saveInReviewContractor(tempFirstName: string, tempLastName: string) {
    if (this.contractor) {
      ContractorController.SaveContractorCompanyDetail(this.contractor)
        .then((res) => {
          if (res) {
            this.saveSnackbar = true
            this.$emit('updateContractorDetailUpdate', this.contractor)
          }
        })
        .catch(() => {
          eventBus.$emit('errorHandler', 'Error saving contractor company detail, please try again', true)
        })
        .finally(() => {
          this.isLoading = false
        })

      if (this.showUserNotificationPreferences) {
        this.saveContractorCommsPrefs(this.contractor.id)
      }
    }
  }

  private async saveContractorCommsPrefs(contractorId: string) {
    try {
      const saved = await ContractorController.SaveCommunicationPreferences(contractorId, this.contractorComms)
      if (!saved) {
        eventBus.$emit('errorHandler', 'Error saving communication preferences, please try again', true)
      }
      return saved
    } catch (err) {
      eventBus.$emit('errorHandler', 'Error saving communication preferences, please try again', true)
      return false
    }
  }

  private showCommonError(error: string | null) {
    if (error !== null) {
      this.commonErrorMsg = error
      this.isLoading = false
    }
  }

  public phoneNumberUpdated(phoneNumber: string, index: number): void {
    if (this.contractor && this.contractor.contactNumbers) {
      this.contractor.contactNumbers[index].phoneNumber = phoneNumber
    }
  }

  public addNewContact(): void {
    if (this.contractor && this.contractor.contactNumbers) {
      this.contractor.contactNumbers.push(new ContactNumber())
    }
  }

  public deleteContact(index: number): void {
    if (this.contractor && this.contractor.contactNumbers.length > 1) {
      this.contractor.contactNumbers.splice(index, 1)
    }
  }

  public onIsIndependentChange() {
    if (this.contractor && this.contractor.isIndependent && this.totalEngineer > 1 && !this.isIndependentContractor) {
      setTimeout(() => {
        if (this.contractor) {
          this.contractor.isIndependent = false
        }
      }, 0)
      this.isIndependentContractorErrorMsg = true
      setTimeout(() => {
        this.isIndependentContractorErrorMsg = false
      }, 5000)
    } else {
      this.isIndependentContractorErrorMsg = false
      if (this.contractor) {
        if (this.contractor.isIndependent && this.engineer && this.totalEngineer === 1) {
          this.contractor.firstName = this.engineer.firstName || null
          this.contractor.lastName = this.engineer.lastName || null
        } else {
          this.contractor.firstName = null
          this.contractor.lastName = null
        }
      }
    }
  }

  public get preferredContractorOptions(): IPreferredContractor[] {
    PreferredContractors.forEach((option: any) => {
      // disable option 'ApplicationPending' & 'ApplicationInProgress' while saving detail;
      option.disabled = option.id === 'ApplicationPending' || option.id === 'ApplicationInProgress' ? true : false
    })
    return PreferredContractors
  }

  // Check if userRole is Administrator or NetworkManager
  public get canEditContractors(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'NetworkManager')
    )
  }

  // Check if userRole is contractor
  public get isUserRoleContractor(): boolean {
    // for not allowing contractor to edit detail, we have checked if they have contractor role
    // so, if Contractor is not Reviewed, allow them to edit and save profile detail
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail' && this.isContractorReviewed
  }

  public onCancelButtonClick() {
    this.$emit('onCancelChanges')
  }

  public onSelectHoldOnFromDatePicker(date: string) {
    if (this.contractor) {
      this.chosenHoldOnFromFormattedDate = moment(date).format(DateTimePicker.DATE_FORMAT)
      this.showHoldOnFromDateSelection = false
    }

    // reset to date while changing from date
    this.minOnHoldToDate = moment(date).format(DateTimePicker.DATE_FORMAT)
    if (this.chosenHoldOnToFormattedDate && this.chosenHoldOnToFormattedDate < this.chosenHoldOnFromFormattedDate) {
      this.chosenHoldOnToDate = moment(date).format(DateTimePicker.DATE_FORMAT)
      this.chosenHoldOnToFormattedDate = moment(date).format(DateTimePicker.DATE_FORMAT)
    }
  }

  public onSelectHoldOnToDatePicker(date: string) {
    if (this.contractor) {
      this.chosenHoldOnToFormattedDate = moment(date).format(DateTimePicker.DATE_FORMAT)
      this.showHoldOnToDateSelection = false
    }
  }

  public get getCountryNames(): ICountryName[] {
    return CountryNames
  }

  public numberKeyValidation(event: any) {
    Shared.IsNumeric(event)
  }

  public allowedMinutes(value: number) {
    return value % 15 === 0
  }

  public onAutoDeployChange() {
    if (this.contractor && this.contractor.autoDeployAlwaysActive) {
      this.$validator.errors.items = []
    }
  }

  public get getAutoDeploymentTypes(): IAutoDeploymentOption[] {
    const keys: any[] = Object.keys(this.autoDeploymentOption).filter(Number)
    const autoDeploymentItems: IAutoDeploymentOption[] = []
    keys.forEach((element) => {
      const entity: any = {}
      entity.name = AutoDeploymentTypeEnum[element]
      entity.description = AutoDeploymentTypeEnum[element]
      if (entity.description === 'NoAutodeployment') {
        entity.description = 'No Auto deployment'
      } else if (entity.description === 'AutodeployToEngineer') {
        entity.description = 'Auto deploy To Engineer'
      } else if (entity.description === 'AutodeployToContractor') {
        entity.description = 'Auto deploy To Contractor'
      }
      autoDeploymentItems.push(entity)
    })
    return autoDeploymentItems
  }

  private prepareRequiredContactNumberList() {
    if (this.contractor) {
      return (this.contractor.contactNumbers = [
        { label: 'Main number', phoneNumber: '' },
        { label: '24-7 number', phoneNumber: '' },
        { label: 'Escalation', phoneNumber: '' },
      ])
    }
    return []
  }

  private addRequiredContactNumbersInToExistingList() {
    if (this.contractor) {
      // fill missing required phone numbers
      const mainNumber = this.contractor.contactNumbers.find((x) => x.label.trim().toLowerCase() === 'main number')
      const secondaryNumber = this.contractor.contactNumbers.find((x) => x.label.trim().toLowerCase() === '24-7 number')
      const escalationNumber = this.contractor.contactNumbers.find((x) => x.label.trim().toLowerCase() === 'escalation')
      // add escalation number
      if (!escalationNumber) {
        this.contractor.contactNumbers.unshift({
          label: 'Escalation',
          phoneNumber: '',
        })
      }
      // add 24/7 number
      if (!secondaryNumber) {
        this.contractor.contactNumbers.unshift({
          label: '24-7 number',
          phoneNumber: '',
        })
      }
      // add main number
      if (!mainNumber) {
        this.contractor.contactNumbers.unshift({
          label: 'Main number',
          phoneNumber: '',
        })
      }
    }
  }

  public disableIfCompulsory(label: string): boolean {
    if (
      label &&
      (label.trim().toLowerCase() === 'main number' ||
        label.trim().toLowerCase() === '24-7 number' ||
        label.trim().toLowerCase() === 'escalation')
    ) {
      if (
        this.contractor &&
        this.contractor.contactNumbers.filter((x) =>
          x.label ? x.label.trim().toLowerCase() === label.trim().toLowerCase() : true
        ).length > 1
      ) {
        return false
      }
      return true
    }
    return false
  }

  public get getCompanyTypeList(): IDropdownType[] {
    return [
      {
        id: 0,
        description: CompanyType[CompanyType.PLC],
        alterText: CompanyType[CompanyType.PLC],
      },
      {
        id: 1,
        description: CompanyType[CompanyType.LTD],
        alterText: CompanyType[CompanyType.LTD],
      },
      {
        id: 2,
        description: CompanyType[CompanyType.Partnership],
        alterText: CompanyType[CompanyType.Partnership],
      },
      {
        id: 3,
        description: CompanyType[CompanyType.SoleTrader],
        alterText: Shared.insertSpaceInEnumName(CompanyType[CompanyType.SoleTrader]),
      },
    ]
  }
}
</script>

<style scoped>
.ck-dmimg img {
  width: 100%;
}
.cntp-logo-img {
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  border: 10px solid #fff;
  background-color: #fff;
}
.cntp-logo-img img {
  max-width: 100%;
  margin: auto;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
}
.cntp-upper-sec {
  color: #fff;
}
.cntp-logo-img-edit {
  transition: 0.3s all linear;
  transform: scale(0, 0);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  border-radius: 50%;
}
.cntp-logo-img-edit i {
  margin: auto;
}
.cntp-logo-img:hover .cntp-logo-img-edit {
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}
.cntp-logo-sec {
  height: 90px;
  position: relative;
}
.imageError-wrapper {
  top: -23px;
  margin: auto;
  width: 100%;
  display: inline-block;
}
.full-height {
  height: 100%;
}
.profile-sec {
  display: flex;
}
.profile-sec >>> .v-card {
  margin: auto;
  margin-top: 0;
}
.rm-logo {
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  color: #696464;
  border-radius: 50%;
  padding: 3px;
  width: 30px;
  height: 30px;
  top: 7px;
  z-index: 2;
  right: 6px;
}
.cntp-logo-sec-content {
  display: inline-block;
  position: relative;
}
.rm-logo >>> .v-icon {
  font-size: 19px;
}
.contractor-management-cp {
  height: 88vh;
  display: inline-block;
}

/*PhoneNumber Component*/
.internal-phone-number.contractor-phone-number {
  padding-left: 0px;
}
.internal-phone-number.contractor-phone-number {
  position: relative;
  padding-top: 18px !important;
}
.internal-phone-number.contractor-phone-number >>> .input-group input,
.internal-phone-number.contractor-phone-number >>> .selectedCountry .input-group__input,
.internal-phone-number.contractor-phone-number >>> .validation,
.internal-phone-number.contractor-phone-number >>> .contact-number .v-input-group--text-field label {
  opacity: 1;
}
.internal-phone-number.contractor-phone-number >>> .validation {
  position: absolute;
  right: 7px;
  top: 21px;
}
.internal-phone-number.contractor-phone-number >>> .contact-number .v-input-group--text-field label {
  top: 0px !important;
  font-size: 15px;
  display: block;
}
.internal-phone-number.contractor-phone-number >>> .contact-number .v-input-group--focused label {
  -webkit-transform: translateY(-18px) scale(0.75);
  transform: translateY(-18px) scale(0.75);
}
.multiple-contact-field {
  position: relative;
  padding-right: 60px !important;
}
.multiple-contact-field.add-remove-btn {
  padding-right: 16px !important;
  padding-bottom: 40px !important;
  top: auto;
  bottom: 5px;
}
.multiple-contact-field.add-remove-btn .layout {
  padding-right: 40px;
  position: relative;
}
.multiple-contact-field.add-remove-btn .contact-remove {
  position: absolute;
  right: 0px;
  top: 16px;
}
.multiple-contact-field .contact-add {
  position: absolute;
  right: 16px;
  top: 22px;
  bottom: 5px;
}
.multiple-contact-field >>> .add-remove-btn-icon {
  position: absolute;
  right: 15px;
}
.multiple-contact-field >>> .add-remove-btn-icon:hover {
  position: absolute !important;
}
.add-remove-btn-icon {
  position: absolute;
  right: 15px;
}
.vb-content {
  max-height: 200px !important;
}
.common-error-message {
  color: #ff5252 !important;
}
</style>
