import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import { JobType } from '@/models/types'

export default class EmergencyModel {
  public id: string
  public emergencyId: number
  public description: string
  public orderBy: number
  public emergencyDetails: EmergencyDetailModel[] | null
  public isLocal: boolean
  public fileURL: string | null
  public file: string | null
  public engineerSiteSLA: number
  public jobType: JobType
  public hideOnCustomerPortals: boolean
  public isDeleted: boolean
}
