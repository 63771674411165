<template>
  <div class="user contractor-registration">
    <div class="background-content-section">
      <div class="background-image">
        <span class="overlay"></span>
      </div>
    </div>
    <v-card flat class="elevation-0 px-3" :class="!isRegistrationCompleted ? '' : 'completed-msg grey lighten-5'">
      <div class="inner-content">
        <v-card-title class="pb-0 mb-3">
          <h1 v-if="!isRegistrationCompleted && !showSetPasswordScreen && !showErrorBlock">Contractor Registration</h1>
          <h1 v-else-if="isRegistrationCompleted && showSetPasswordScreen && !showErrorBlock">Setup Password</h1>
          <h1 v-else-if="showErrorBlock" class="color--red">Account verification failed</h1>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0 grid-list-xl">
            <v-layout v-if="!isRegistrationCompleted && !showSetPasswordScreen && !showErrorBlock" wrap>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="onlineContractorApplicationModel.companyName"
                  v-validate="'required'"
                  label="Company Name"
                  maxlength="100"
                  required
                  class="required"
                  data-vv-scope="createOnlineContractor"
                  data-vv-name="Company Name"
                  :error-messages="errors.collect('Company Name')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model.trim="onlineContractorApplicationModel.firstName"
                  v-validate="'required'"
                  label="First Name"
                  maxlength="50"
                  required
                  class="required"
                  data-vv-scope="createOnlineContractor"
                  data-vv-name="First Name"
                  :error-messages="errors.collect('First Name')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model.trim="onlineContractorApplicationModel.lastName"
                  v-validate="'required'"
                  label="Last Name"
                  maxlength="50"
                  required
                  class="required"
                  data-vv-scope="createOnlineContractor"
                  data-vv-name="Last Name"
                  :error-messages="errors.collect('Last Name')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model.trim="onlineContractorApplicationModel.email"
                  v-validate="'required|email'"
                  label="Email Address"
                  maxlength="100"
                  required
                  class="required"
                  data-vv-scope="createOnlineContractor"
                  data-vv-name="Email Address"
                  :error-messages="errors.collect('Email Address')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <PhoneNumber
                  ref="contractorContactNumber"
                  :passed-phone-number="onlineContractorApplicationModel.phoneNumber"
                  :is-validation-required="true"
                  class="contractor-phone-number"
                  @phoneNumberUpdated="phoneNumberUpdated"
                />
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="onlineContractorApplicationModel.isAgreedToTermsAndCondition"
                  color="primary"
                  class="d-inline-block"
                  :class="isTCChecked ? '' : 'red-border-checkbox'"
                  :label="`Terms & Conditions`"
                ></v-checkbox>
                <a href="Javascript:void(0)" class="pl-2 teams-condition-text" @click="openTCDialogue = true">
                  <u>T&C</u>
                </a>
              </v-flex>
            </v-layout>
            <v-layout v-else-if="isRegistrationCompleted && showSetPasswordScreen && !showErrorBlock" wrap>
              <v-flex xs6>
                <v-text-field
                  v-model.trim="contractorSignupPasswordDetailsModel.password"
                  v-validate="'required'"
                  label="Password"
                  maxlength="64"
                  required
                  class="required"
                  name="password"
                  data-vv-name="password"
                  :data-vv-scope="passwordValidationScope"
                  :error-messages="errors.collect('password')"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  hint="Password length must be between 8 to 64 characters"
                  counter="64"
                  @input="checkPasswordCriteria"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model.trim="verifyPassword"
                  v-validate="'required|confirmed:password'"
                  label="Confirm Password"
                  maxlength="50"
                  required
                  class="required"
                  :data-vv-scope="passwordValidationScope"
                  data-vv-name="password_confirmation"
                  :error-messages="retrieveErrorMessages('password_confirmation')"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pt-0>
                <p class="mb-1">The password must have all of the following:</p>
                <p v-for="(criteria, index) in passwordSetCriteria" :key="index" class="mb-1" :class="criteria.color">
                  {{ criteria.text }}
                </p>
              </v-flex>
            </v-layout>
            <v-layout
              v-else-if="
                isRegistrationCompleted && !showSetPasswordScreen && !showCompletedSetPasswordScreen && !showErrorBlock
              "
              wrap
            >
              <v-flex xs12>
                <h1 class="primary--text mb-4">Registration completed successfully!</h1>
                <h3 class="mb-3">
                  Welcome to SIMPLIFi
                  <b class="secondary--text">
                    {{ onlineContractorApplicationModel.firstName }}
                    {{ onlineContractorApplicationModel.lastName }}
                  </b>
                  !
                </h3>
                <p class="mb-0 mb-3">We are excited to welcome you onboard.</p>
                <p class="mb-0">
                  Kindly verify yourself by email we sent to
                  <u class="secondary--text">
                    {{ onlineContractorApplicationModel.email }}
                  </u>
                  .
                </p>
              </v-flex>
            </v-layout>
            <v-layout
              v-else-if="
                isRegistrationCompleted && !showSetPasswordScreen && showCompletedSetPasswordScreen && !showErrorBlock
              "
              wrap
            >
              <v-flex xs12>
                <h3 class="mb-3">
                  Welcome to SIMPLIFi
                  <b class="secondary--text">
                    {{ onlineContractorApplicationModel.firstName }}
                    {{ onlineContractorApplicationModel.lastName }}
                  </b>
                  !
                </h3>
                <p class="mb-0 mb-3">We are excited to welcome you onboard.</p>
                <p class="mb-0">Kindly Login to proceed further.</p>
              </v-flex>
            </v-layout>
            <v-layout v-else-if="showErrorBlock" wrap>
              <v-flex xs12>
                <h3 class="mb-3">Something went wrong with your registration.</h3>
                <p class="mb-0 mb-3">Kindly try again or contact to the SIMPLIFi if you rapidly face this error.</p>
                <p class="mb-0">Kindly Register again to proceed further.</p>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="bottom-align" :class="!isRegistrationCompleted ? '' : 'px-3'">
          <v-spacer v-if="showErrorBlock ? false : !isRegistrationCompleted"></v-spacer>
          <v-btn
            v-if="!isRegistrationCompleted && !showSetPasswordScreen && !showErrorBlock"
            color="primary"
            :disabled="contractorSignupInProcess"
            :loading="contractorSignupInProcess"
            @click="registerContractor"
          >
            Register
          </v-btn>
          <v-btn
            v-else-if="isRegistrationCompleted && showSetPasswordScreen && !showErrorBlock"
            color="primary"
            :disabled="contractorSignupInProcess || disableSetPassword"
            :loading="contractorSignupInProcess"
            @click="setContractorPassword"
          >
            Set Password
          </v-btn>
          <v-btn
            v-else-if="
              isRegistrationCompleted && !showSetPasswordScreen && showCompletedSetPasswordScreen && !showErrorBlock
            "
            color="primary"
            @click="redirectToLogin"
          >
            Login
          </v-btn>
          <v-btn v-else-if="showErrorBlock" color="primary" @click="redirectToSignup">Signup</v-btn>
        </v-card-actions>
        <!-- show error -->
        <v-alert v-show="error" dense outlined type="error" class="ma-3">
          {{ error }}
        </v-alert>
      </div>
    </v-card>
    <!-- dialog - Terms and Conditions -->
    <v-dialog
      v-if="openTCDialogue"
      v-model="openTCDialogue"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Terms And Conditions</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="openTCDialogue = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-4 pt-4">
          <v-layout wrap>
            <v-flex xs12 pr-2>
              <p>
                The details you supply in this application will, together with the CET Terms of Business Agreement form
                your agreement with CET.
              </p>
              <p>
                All questions MUST be completed by all Contractors wishing to be considered for work with CET. The
                completion and submission of this questionnaire does not mean that the Contractor has passed the
                selection criteria used by CET, and/or that CET will employ the services of the Contractor.
              </p>
              <p>
                Answers will NOT in any way relieve the Contractor of their statutory and contractual Health, Safety and
                Environmental responsibilities.
              </p>
              <p>
                Any information provided in response to this application will remain strictly confidential and will NOT
                be disclosed to any Third Party other than as may be required by law.
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="openTCDialogue = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Shared from '@/common/shared'
import ContractorSignupDetailModel from '@/models/contractor/ContractorSignupDetailModel'
import ContractorSignupPasswordDetailsModel from '@/models/contractor/ContractorSignupPasswordDetailsModel'
import { Environment } from '../common/environment'
import Store from '@/store'
import ContractorController from '@/api/contractorController'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import { ReCaptchaErrorType } from '@/common/enums'
import ErrorResponseModel from '@/models/ErrorResponseModel'
import eventBus from '@/common/bus'
import PasswordValidation from '@/models/validation/PasswordValidation'
declare let grecaptcha: any

@Component({
  components: { PhoneNumber },
})
export default class ContractorSignup extends Vue {
  private openTCDialogue = false
  private onlineContractorApplicationModel: ContractorSignupDetailModel = new ContractorSignupDetailModel()
  private contractorSignupPasswordDetailsModel: ContractorSignupPasswordDetailsModel =
    new ContractorSignupPasswordDetailsModel()
  private isTCChecked = true
  private reCaptchaErrorType = ReCaptchaErrorType
  private error = ''
  private isRegistrationCompleted = false
  private verifyPassword = ''
  private signupToken = ''
  private showSetPasswordScreen = false
  private isTokenVerified = false
  private contractorSignupInProcess = false
  private showCompletedSetPasswordScreen = false
  private showErrorBlock = false
  private showPassword = false
  private showConfirmPassword = false
  private isPasswordValid = false

  private passwordValidationScope = 'contractorPasswordValidation'

  // Needs fixed - Needs moved to global service after refactor.
  private passwordSetCriteria: PasswordValidation[] = [
    new PasswordValidation('- a lowercase letter', 'lower', new RegExp(/[a-z]+/)),
    new PasswordValidation('- an uppercase letter', 'upper', new RegExp(/[A-Z]+/)),
    new PasswordValidation('- a number', 'number', new RegExp(/[0-9]/)),
    new PasswordValidation('- a symbol', 'special', new RegExp(/[#?!@$%^&*-]/)),
    new PasswordValidation('- at least 8 characters', 'min-length', new RegExp(/.{8,}/)),
  ]

  private created() {
    const self = this
    // hide user lane
    self.showSetPasswordScreen = false
    self.signupToken = self.$route.params.signupToken !== undefined ? self.$route.params.signupToken : ''
    if (this.signupToken) {
      self.isRegistrationCompleted = true
      self.showSetPasswordScreen = true
      self.contractorSignupPasswordDetailsModel.token = self.signupToken
    } else if (self.$route.query.error !== undefined) {
      // handle password verification error
      this.error = self.$route.query.error.toString()
      this.showErrorBlock = true
    }
    // bing g-captcha
    if (!this.signupToken && !self.$route.query.error) {
      this.renderGrecaptcha()
    }
  }

  private renderGrecaptcha() {
    // script for captcha version 3
    const captchaScript = document.createElement('script')
    captchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?render=' +
        Store.Instance.state.Environment.GoogleCaptchaVerificationSiteKey
    )
    document.head.appendChild(captchaScript)
  }

  private registerContractor() {
    const self = this
    self.error = ''
    self.validate().then((result: boolean) => {
      // if validated and terms and condition checked
      if (result && self.isTCChecked) {
        // make captcha call to get token
        self.contractorSignupInProcess = true
        grecaptcha.ready(() => {
          grecaptcha.execute(Store.Instance.state.Environment.GoogleCaptchaVerificationSiteKey).then((token) => {
            // call api with captcha token for validation
            self.onlineContractorApplicationModel.captchaToken = token
            ContractorController.ContractorSignup(self.onlineContractorApplicationModel)
              .then((res: ErrorResponseModel | null) => {
                if (res) {
                  if (!res.message) {
                    // register successful
                    self.isRegistrationCompleted = true
                  } else {
                    // show error
                    self.handleReCaptchaError(res.message)
                  }
                }
                self.contractorSignupInProcess = false
              })
              .catch((err: any) => {
                eventBus.$emit('errorHandler', 'Error submitting contractor Signup request, please try again', true)
                self.contractorSignupInProcess = false
              })
          })
        })
      }
    })
  }

  private async validate(): Promise<boolean> {
    let result = true
    const contractorFormValidation: boolean = await this.$validator.validateAll('createOnlineContractor')
    // set focus to non validate field
    if (!contractorFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    // phone number validation
    let contactNumberValidation = true
    const contactNumberValidationSub: any = this.$refs.contractorContactNumber as PhoneNumber
    contactNumberValidation =
      (await contactNumberValidationSub.$validator.validateAll()) && contactNumberValidationSub.isValid

    // set focus to non validate field
    if (!contactNumberValidation || !contractorFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = contactNumberValidation && contractorFormValidation
    }

    // set validation for terms and conditions
    this.isTCChecked = this.onlineContractorApplicationModel.isAgreedToTermsAndCondition ? true : false

    return result
  }

  private handleReCaptchaError(responseErrorMessage: string) {
    const self = this
    let errorMessage = ''
    switch (responseErrorMessage) {
      case self.reCaptchaErrorType.MissingInputResponse:
        errorMessage = 'The response parameter is missing.'
        break
      case self.reCaptchaErrorType.InvalidInputResponse:
        errorMessage = 'The response parameter is invalid or malformed.'
        break
      case self.reCaptchaErrorType.BadRequest:
        errorMessage = 'The request is invalid or malformed.'
        break
      default:
        errorMessage = responseErrorMessage
        break
    }
    // show error message
    self.error = errorMessage
  }

  private phoneNumberUpdated(phoneNumber: string): void {
    this.onlineContractorApplicationModel.phoneNumber = phoneNumber
  }

  private redirectToLogin() {
    this.$router.replace('/')
  }

  private setContractorPassword() {
    const self = this
    self.error = ''
    self.validatePassword().then((result: boolean) => {
      if (result) {
        self.error = ''
        self.contractorSignupInProcess = true
        ContractorController.ContractorSignupPassword(this.contractorSignupPasswordDetailsModel)
          .then((res: ErrorResponseModel | null) => {
            if (res) {
              if (!res.message) {
                // register successful
                self.isRegistrationCompleted = true
                self.showSetPasswordScreen = false
                self.showCompletedSetPasswordScreen = true
              } else {
                // show error
                self.handleReCaptchaError(res.message)
              }
            }
            self.contractorSignupInProcess = false
          })
          .catch((err: any) => {
            self.handleReCaptchaError('Password reset failed. Please try again.')
            eventBus.$emit('errorHandler', 'Error resetting password, please try again', true)
            self.contractorSignupInProcess = false
          })
      }
    })
  }

  private async validatePassword(): Promise<boolean> {
    const result = true
    const contractorFormValidation: boolean = await this.$validator.validateAll('createOnlineContractor')
    // set focus to non validate field
    if (!contractorFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private redirectToSignup() {
    this.$router.replace('/contractorSignup')
  }

  // Needs fixed - This will be renamed to validatePassword
  // after refactor.
  private checkPasswordCriteria() {
    const errorColorText = 'error--text'
    const successColorText = 'green--text'

    let password = this.contractorSignupPasswordDetailsModel.password
    if (!password) {
      // if password is empty,
      this.passwordSetCriteria.forEach((element) => {
        element.color = errorColorText
      })
      this.isPasswordValid = false
    }
    password = password.trim()

    // Loop through password criteria and set text colour accordingly based
    // upon whether they meet the relevent regex.
    this.passwordSetCriteria.forEach((x: PasswordValidation) => {
      x.color = password.search(x.regex) < 0 ? errorColorText : successColorText
    })

    // Make sure all criteria are met.
    this.isPasswordValid = this.passwordSetCriteria.every((x) => x.color === successColorText)
  }

  // Needs fixed - Temporary until the refactor and we can implement proper Vee-Validate.
  // Only show error messages after field has been interacted with.
  private retrieveErrorMessages(fieldName: string) {
    const field = this.$validator.fields.items.filter((x) => x.name === fieldName)[0]
    const error = this.$validator.errors.items.filter((x) => x.field === fieldName)[0]
    return error && field && field.flags.touched ? error.msg : ''
  }

  public get disableSetPassword(): boolean {
    return (
      !this.isPasswordValid ||
      this.$validator.errors.items.filter((x) => x.scope === this.passwordValidationScope).length > 0
    )
  }
}
</script>

<style>
.signup-page #g1-slide-container-0 {
  display: none !important;
}
</style>

<style scoped>
.contractor-registration {
  display: flex;
  width: 100%;
}
.contractor-registration >>> .v-card {
  max-width: 650px;
  margin: auto;
  display: flex;
  margin-top: 150px;
}
.background-image {
  position: fixed;
  left: 0px;
  right: 700px;
  top: 0px;
  bottom: 0px;
  background-image: url('/img/contractor.png');
  background-size: cover;
}
.overlay {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.6);
}
.inner-content {
  margin: auto;
}
.teams-condition-text {
  vertical-align: super;
}
.internal-phone-number.contractor-phone-number {
  padding-left: 0px;
  padding-top: 16px;
  position: relative;
}
.red-border-checkbox >>> .v-icon,
.red-border-checkbox >>> .v-label {
  color: rgb(255, 86, 86);
}
.completed-msg {
  width: 100%;
}
.completed-msg .inner-content {
  margin: inherit;
  width: 100%;
}
.background-content-section {
  width: calc(100% - 650px);
}
</style>
