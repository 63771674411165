<template>
  <div>
    <v-dialog v-model="showMaterialDialog" max-width="650" content-class="v-dialog--scrollable" persistent>
      <Material
        ref="materialRef"
        :show-material-dialog="showMaterialDialog"
        :material-item="material"
        @OnMaterialSave="saveMaterial"
        @OnMaterialDialogClose="closeDialog"
      ></Material>
    </v-dialog>
    <div>
      <v-card-title primary-title class="pa-0 mb-2">
        <h3 class="mb-0">Material List</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model.trim="search"
          append-icon="search"
          label="Search"
          single-line
          class="pt-0 mt-0"
          hide-details
        ></v-text-field>
        <v-tooltip bottom nudge-left="30px">
          <template #activator="{ on }">
            <v-btn icon class="mr-0 add-material-action" v-on="on" @click="showMaterialDialog = true">
              <v-icon color="grey">add</v-icon>
            </v-btn>
          </template>
          <span>Add Material</span>
        </v-tooltip>
      </v-card-title>
      <div>
        <v-data-table
          :headers="headers"
          :items="materialList"
          :loading="isLoading"
          :search="search"
          :pagination.sync="pagination"
          class="gridView material-list"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td class="text-xs-center">
              {{ props.item.isAdditionalInfoRequired ? 'Yes' : 'No' }}
            </td>
            <td class="text-xs-center">
              {{ props.item.isExcludedFromClientInvoicing ? 'Yes' : 'No' }}
            </td>
            <td class="text-xs-center">
              {{ props.item.isCostExcludedFromContractorFixedFee ? 'Yes' : 'No' }}
            </td>
            <td class="text-xs-center">
              {{ props.item.excludeFromCustomerApprovalUpdates ? 'Yes' : 'No' }}
            </td>
            <td class="px-4 text-xs-right">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0 btn-edit" v-on="on" @click="onEditClick(props.item)">
                    <v-icon color="primary">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0 ml-2 btn-delete" v-on="on" @click="onDeleteClick(props.item)">
                    <v-icon color="primary">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import MaterialModel from '@/models/material/MaterialModel'
import MaterialController from '@/api/materialController'
import Material from '@/components/Material.vue'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'

@Component({
  components: { Material },
})
export default class MaterialManagement extends Vue {
  private showMaterialDialog = false
  private headers: any = []
  private material: MaterialModel | null = new MaterialModel()
  private materialList: MaterialModel[] = []
  private isLoading = false
  private search = ''
  private pagination: any = {}
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''

  private created() {
    this.headers = [
      { text: 'Name', value: 'name', sortable: false, class: 'col-width' },
      {
        text: 'Additional Detail',
        value: 'isAdditionalInfoRequired',
        sortable: false,
        align: 'center',
        class: 'col-width',
      },
      {
        text: 'Exclude from Client Invoice',
        value: 'isExcludedFromClientInvoicing',
        sortable: false,
        align: 'center',
        class: 'col-width',
      },
      {
        text: 'Cost Excluded from Contractor Fixed Fee',
        value: 'isCostExcludedFromContractorFixedFee',
        sortable: false,
        align: 'center',
        class: 'col-width',
      },
      {
        text: 'Excluded from Customer Updates after Approval',
        value: 'excludeFromCustomerApprovalUpdates',
        sortable: false,
        align: 'center',
        class: 'col-width',
      },
      { text: 'Action', sortable: false, class: 'col-action' },
    ]
    this.pagination.rowsPerPage = 10
  }

  private mounted() {
    this.getMaterialList()
  }

  private getMaterialList() {
    const self = this
    self.isLoading = true
    MaterialController.GetMaterials()
      .then((res: MaterialModel[]) => {
        self.materialList = res
        self.isLoading = false
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error loading material list, please try again', true)
        self.isLoading = false
      })
  }

  private onEditClick(material: MaterialModel) {
    this.material = material
    this.showMaterialDialog = true
  }

  private closeDialog(material: MaterialModel) {
    this.showMaterialDialog = false
    this.material = new MaterialModel()
  }

  private saveMaterial(material: MaterialModel) {
    this.showMaterialDialog = false
    this.snackbarText = 'Material Detail Saved Successfully.'
    this.snackbar = true
    const index: number = this.materialList.findIndex((c) => c.id === material.id)
    if (index !== -1) {
      this.materialList[index].name = material.name
      this.materialList[index].isAdditionalInfoRequired = material.isAdditionalInfoRequired
      this.materialList[index].isExcludedFromClientInvoicing = material.isExcludedFromClientInvoicing
      this.materialList[index].excludeFromCustomerApprovalUpdates = material.excludeFromCustomerApprovalUpdates
      this.materialList[index].isCostExcludedFromContractorFixedFee = material.isCostExcludedFromContractorFixedFee
    } else {
      this.materialList.push(material)
    }
    this.material = new MaterialModel()
  }

  private onDeleteClick(material: MaterialModel) {
    Shared.confirmationPopup.open(
      'Do you really want to delete Material?',
      '',
      '',
      '',
      'Delete',
      this,
      'DeleteMaterial',
      material
    )
  }

  private DeleteMaterial(material: MaterialModel) {
    const self = this
    self.isLoading = true
    if (material.id) {
      MaterialController.DeleteMaterial(material.id)
        .then((res: boolean) => {
          if (res) {
            self.materialList.splice(self.materialList.indexOf(material), 1)
            self.materialList = [...self.materialList]
            self.snackbarText = 'Material is deleted'
            self.snackbar = true
          }
          self.isLoading = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error deleting material, please try again', true)
          self.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.add-material-action {
  position: relative !important;
  top: -4px;
}
.gridView >>> .col-action {
  text-align: right !important;
  padding-right: 40px !important;
  min-width: 50px !important;
  width: 50px;
  max-width: 50px !important;
}
.gridView >>> table tbody tr td:last-child {
  padding-right: 24px !important;
}
.gridView >>> .col-width {
  min-width: 100px !important;
  width: 100px;
  max-width: 100px !important;
  white-space: normal;
}
</style>
