<template>
  <v-menu
    v-model="showNotificationWindow"
    offset-y
    :close-on-content-click="false"
    :nudge-width="380"
    :max-width="380"
    :z-index="99"
    left
    :position-y="56"
    content-class="notification-box"
  >
    <template #activator="{ on }">
      <v-btn icon large class="notification-bell" @click.native="openNotificationWindow" v-on="on">
        <v-btn icon>
          <v-badge overlap color="secondary">
            <span v-if="getUnreadNotificationCount > 0" slot="badge" small>
              {{ getUnreadNotificationCount }}
            </span>
            <v-avatar :size="34">
              <v-icon dark>notifications</v-icon>
            </v-avatar>
          </v-badge>
        </v-btn>
        <span class="n-arrow"></span>
      </v-btn>
    </template>
    <div v-if="showNotificationWindow" class="notification-content">
      <v-progress-circular
        v-if="isLoading"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
        :style="getLoaderStyle(30)"
      ></v-progress-circular>
      <div v-else>
        <div v-if="notifications.length > 0" class="clearall-btn">
          <v-btn flat small color="primary" class="clear-all-notification" @click="onClearAllNotificationClick()">
            Clear all
          </v-btn>
        </div>
        <v-card
          v-bar="{ useScrollbarPseudo: true }"
          class="notification-menu"
          :class="notifications.length > 0 ? 'mt-4 show-notification' : ''"
        >
          <div class="inner-content view-panel px-3 py-3">
            <div v-if="notifications.length === 0" class="notification-msg grey lighten-3">
              <span class="grey--text">No notification(s)</span>
            </div>
            <v-list>
              <v-list-tile
                v-for="item in notifications"
                :key="item.id"
                :class="!item.isRead ? 'unread-notification' : 'grey lighten-3'"
              >
                <v-list-tile-avatar
                  :color="
                    isNewReturnVisitAwaitingETANotification(item.notificationType) ||
                    isMaterialRequestWithoutPriceNotification(item.notificationType)
                      ? 'white'
                      : 'red'
                  "
                  :size="32"
                >
                  <span v-if="isNewReturnVisitAwaitingETANotification(item.notificationType)" class="return-visit">
                    <img src="/img/return-visit.svg" alt="" />
                  </span>
                  <span
                    v-else-if="isMaterialRequestWithoutPriceNotification(item.notificationType)"
                    class="return-visit"
                  >
                    <img src="/img/pound-symbol-pink.svg" alt="" />
                  </span>
                  <v-icon v-else dark small>
                    {{ getIconName(item.notificationType) }}
                  </v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-tooltip left max-width="200">
                    <template #activator="{ on }">
                      <a
                        href="Javascript:void(0)"
                        class="notification-anchor"
                        @click="markNotificationAsReadForUser(item.id, item.isRead)"
                        v-on="on"
                      >
                        <v-list-tile-title v-if="item.title">
                          {{ item.title }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title>
                          {{ item.message }}
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title>
                          <span class="caption">
                            <v-icon small>date_range</v-icon>
                            <timeago :datetime="item.createdAt.format(dateFormat)" :auto-update="60"></timeago>
                          </span>
                        </v-list-tile-sub-title>
                      </a>
                    </template>
                    <span v-if="isBordereauNotification(item.notificationType)">
                      <span>
                        Client Id:
                        {{ item.notificationRecord ? item.notificationRecord.partitionKey : '--' }}
                      </span>
                      <br />
                      <span>
                        Bordereau Id:
                        {{ item.notificationRecord ? item.notificationRecord.id : '--' }}
                      </span>
                    </span>
                    <span v-else>
                      <span>
                        Job Id:
                        {{ item.notificationRecord ? item.notificationRecord.partitionKey : '--' }}
                      </span>
                      <br />
                      <span>Address: {{ item.address }}</span>
                    </span>
                  </v-tooltip>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>
                    <v-btn icon flat class="ma-0 redirect-job" small @click.native="redirectToJob(item)">
                      <v-icon color="grey" small>launch</v-icon>
                    </v-btn>
                    <v-btn class="ma-0 clear-notification" small flat icon @click="clearNotification(item.id)">
                      <v-icon class="md-icon" color="grey">close</v-icon>
                    </v-btn>
                  </v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </div>
        </v-card>
      </div>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import NotificationModel from '@/models/notification/NotificationModel'
import { NotificationType } from '@/common/enums'
import NotificationController from '@/api/notificationController'
import store from '@/store'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import VueTimeago from 'vue-timeago'
import Shared from '@/common/shared'
import SessionController from '@/api/sessionController'
import eventBus from '../../common/bus'

@Component
export default class Notification extends Vue {
  @Prop() private notificationSnackbar: boolean

  private notifications: NotificationModel[] = []
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('notification')
  private showNotificationWindow = false
  private dateFormat = 'YYYY-MM-DD HH:mm:ss'
  private newNotificationCount = 0
  private isLoading = false

  private created() {
    Vue.use(VueTimeago, {
      name: 'Timeago', // Component name, `Timeago` by default
      locale: 'en', // Default locale
    })
    this.setNotification()
  }

  private get canGetGroupNotifications(): boolean {
    return this.isUserSupervisor || this.isUserContractor
  }

  private get isUserSupervisor() {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor'
    )
  }

  private get isUserContractor() {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private setNotification() {
    this.signalRHub.addHandler('addNotification', () => {
      if (this.showNotificationWindow) {
        this.getAllNotifications()
      } else {
        this.newNotificationCount++
      }
      this.$emit('update:notificationSnackbar', true)
    })

    this.signalRHub.addHandler('readAllNotification', (notificationId: string) => {
      const element: NotificationModel | undefined = this.notifications.find((n) => n.id === notificationId)
      if (element) {
        element.isRead = true
      }
    })

    this.signalRHub.addHandler('dismissNotification', (notificationId: string) => {
      const index: number = this.notifications.findIndex((n) => n.id === notificationId)
      if (index !== -1) {
        this.notifications.splice(index, 1)
      }
    })

    this.signalRHub.addHandler('clearAllNotification', () => {
      this.notifications = []
      this.newNotificationCount = 0
    })

    this.signalRHub.connect()
    this.getAllNotifications()
  }

  private getAllNotifications() {
    this.isLoading = true
    NotificationController.GetNotifications()
      .then((res: NotificationModel[]) => {
        if (res) {
          this.newNotificationCount = 0
          this.notifications = res
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading notification list, please try again', true)
        this.isLoading = false
      })
  }

  private getIconName(notifType: NotificationType): string {
    const type: string = notifType.toString()
    if (type === NotificationType[NotificationType.SMS]) {
      return 'textsms'
    } else if (type === NotificationType[NotificationType.MissedCall]) {
      return 'call_missed'
    } else if (type === NotificationType[NotificationType.ETAChanged]) {
      return 'access_time'
    } else if (type === NotificationType[NotificationType.BordereauReady]) {
      return 'receipt'
    } else if (type === NotificationType[NotificationType.MaterialRequestApproved]) {
      return 'check_circle'
    } else if (type === NotificationType[NotificationType.EmergencyPendingAcceptReject]) {
      return 'hourglass_empty'
    } else {
      return 'notifications'
    }
  }

  private clearNotification(notificationId: string) {
    NotificationController.ClearNotificationForUser(notificationId)
  }

  private onClearAllNotificationClick() {
    if (this.getUnreadNotificationCount > 0) {
      Shared.confirmationPopup.open(
        'Do you want to clear all notifications? (You have ' +
          this.getUnreadNotificationCount +
          ' unread notifications)',
        '',
        '',
        'No',
        'Yes',
        this,
        'clearAllNotification',
        ''
      )
    } else {
      this.clearAllNotification()
    }
  }

  private clearAllNotification() {
    NotificationController.ClearAllNotificationForUser()
  }

  private markNotificationAsReadForUser(notificationId: string, isRead: boolean) {
    if (!isRead) {
      NotificationController.MarkNotificationAsReadForUser(notificationId)
    }
  }

  private redirectToJob(notification: NotificationModel) {
    if (!notification.isRead) {
      NotificationController.MarkNotificationAsReadForUser(notification.id)
    }

    if (!notification.notificationRecord) {
      // added this check to handle old notifications due to model change
      return
    }
    this.showNotificationWindow = false
    if (this.isBordereauNotification(notification.notificationType)) {
      // open bordereau detail
      if (notification.notificationRecord) {
        this.$router.push({
          name: 'clientReportsWithBordereauDetail',
          params: {
            insurerId: notification.notificationRecord.partitionKey,
            bordereauName: notification.notificationRecord.id,
          },
        })
      }
      return
    }
    if (this.isUserSupervisor) {
      this.$router.push({
        name: 'job',
        params: { jobId: notification.notificationRecord.partitionKey },
      })
    } else if (this.isUserContractor) {
      this.$router.push({
        name: 'contractorManagementWithId',
        params: {
          contractorId: notification.notificationRecord.partitionKey,
          contractorAppointedDetailId: notification.notificationRecord.id,
        },
      })
    }
  }

  private get getUnreadNotificationCount(): number {
    return this.showNotificationWindow
      ? this.notifications.filter((n) => n.isRead === false).length
      : this.notifications.filter((n) => n.isRead === false).length + this.newNotificationCount
  }

  private destroyed() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
      // remove user form hub to get notifications
      if (this.canGetGroupNotifications) {
        SessionController.RemoveUserFromNotificationHub()
      }
    }
  }

  private get getSignalRConnectionStatus(): ConnectionState {
    return this.signalRHub.status
  }

  @Watch('getSignalRConnectionStatus')
  private updateSignalRGroup() {
    // if user role supervisor or contractor/engineer add them to group-level notifications
    if (this.getSignalRConnectionStatus === 'connected' && this.canGetGroupNotifications) {
      SessionController.AddUserToNotificationHub()
    }
    // if logged in user is not supervisor or engineer, remove user form group-level notifications
    if (!this.canGetGroupNotifications) {
      SessionController.RemoveUserFromNotificationHub()
    }
  }

  private openNotificationWindow() {
    if (this.showNotificationWindow && this.newNotificationCount > 0) {
      this.getAllNotifications()
    }
  }

  private isBordereauNotification(type: NotificationType): boolean {
    return type.toString() === NotificationType[NotificationType.BordereauReady]
  }

  private isNewReturnVisitAwaitingETANotification(type: NotificationType): boolean {
    return type.toString() === NotificationType[NotificationType.NewReturnVisitAwaitingETA]
  }

  private isMaterialRequestWithoutPriceNotification(type: NotificationType): boolean {
    return type.toString() === NotificationType[NotificationType.MaterialRequestWithoutPrice]
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style type="text/css" scoped>
.notification-menu.card,
.notification-content {
  border-radius: 0;
  background-color: #fbfbfb;
  height: 400px !important;
  position: relative;
  max-width: 380px;
  overflow: hidden;
  box-shadow: none;
}
.notification-menu.show-notification {
  padding-bottom: 15px;
}
/*removed the radius of card*/
.notification-menu >>> .v-list {
  padding: 0px;
  background-color: transparent;
}
/*Change bg color notification list*/
.notification-menu >>> .v-list .v-list__tile__content {
  width: 100%;
}
.notification-menu >>> .v-list__tile__action {
  min-width: auto;
}
.notification-menu >>> .v-list__tile__action-text {
  position: absolute;
  bottom: 8px;
  right: 5px;
}
.notification-menu >>> .v-list > div {
  background-color: #e6e6e6;
  margin-bottom: 10px;
}
/*Add bg color in notification list item*/
.notification-menu >>> .v-list > div:last-child {
  margin-bottom: 0px;
}
.notification-menu >>> .v-list .v-list__tile {
  padding: 0px 10px;
}
.notification-menu >>> .v-list .v-list__tile__avatar {
  min-width: 42px;
  max-width: 42px;
}
.badge >>> .v-badge__badge {
  top: 0;
  right: 0;
}
/*to set bedge icon position*/
.n-arrow {
  transition: all 0.3s linear;
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: dashed dashed solid;
  border-width: 0 8.5px 8.5px;
  position: absolute;
  left: 13px;
  top: 50px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.v-menu__activator.v-menu__activator--active .n-arrow {
  top: 37px;
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.notification-menu >>> .vb-dragger {
  right: 10px;
}
.notification-bell >>> .v-badge--overlap .v-badge__badge {
  top: -6px;
  right: -8px;
}
.notification-menu >>> .v-list__tile {
  min-height: 92px;
  height: auto;
}
.notification-menu >>> .v-list__tile__sub-title {
  white-space: normal;
}
.notification-menu .notification-anchor,
.notification-menu >>> .v-tooltip {
  display: inline-block;
  width: 100%;
}
.notification-menu .v-list > .unread-notification {
  background-color: #fff;
}
.clearall-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.notification-menu.show-notification .vb-content {
  height: 92% !important;
  max-height: 350px;
  min-height: 350px;
}
.notification-menu .notification-msg {
  display: flex;
  height: 367px;
}
.notification-msg span {
  margin: auto;
}
.mt-4.show-notification {
  margin-top: 35px !important;
}
.caption >>> time {
  padding-left: 5px;
  font-size: 13px;
  position: relative;
  top: 2px;
  font-family: 'Noto Sans', Avenir, Helvetica, Arial, sans-serif !important;
}
.v-toolbar__content .v-btn--icon {
  margin: 3px !important;
}
.notification-box {
  position: fixed;
  top: 54px !important;
}
.notification-menu.v-card {
  background-color: #eee;
}
.return-visit {
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #e4e4e4;
}
.return-visit img {
  width: 20px;
}
</style>
