import Axios, { AxiosResponse } from 'axios'
import EnvironmentSecrets from '@/models/environment/environment-secrets'
import storeMutations from '@/storeMutations'
import eventBus from '@/common/bus'
import { getBaseUrl } from './CallCentreApiAxiosPlugin'

export default class EnvironmentController {
  public static async RetrieveEnvironmentSecrets(accessToken: string) {
    try {
      const url = `${getBaseUrl()}Environment/RetrieveEnvironmentSecrets`

      const result: AxiosResponse<EnvironmentSecrets> = await Axios.get(url, {
        validateStatus: (s) => true,
        headers: {
          'Cache-Control': 'no-cache, no-store',
          Authorization: `bearer ${accessToken}`,
        },
      })

      if (result.status === 200) {
        storeMutations.setEnvironmentSecrets(result.data as EnvironmentSecrets)
      } else {
        eventBus.$emit('errorHandler', 'Error loading environment secrets, please contact support', true)
      }
    } catch (err) {
      eventBus.$emit('errorHandler', err, false)
    }
  }
}
