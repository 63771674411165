<template>
  <v-flex xs12>
    <v-expansion-panel class="data-accordion elevation-0">
      <v-expansion-panel-content v-if="getTrialPitDetail.length > 0" class="mb-3 elevation-1">
        <v-layout slot="header" wrap class="align-center">
          <v-flex xs8>
            <h3 slot="header">Trial Pit Data</h3>
          </v-flex>
          <v-flex xs4 class="text-xs-right test-verify">
            <b class="green--text">
              {{ isTrialPitVerified ? 'Verified' : 'Verify' }}
            </b>
            <v-checkbox
              v-if="!isTrialPitVerified"
              v-model="isTrialPitVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="verifySiteData(siProductType[siProductType.Pit])"
            ></v-checkbox>
            <v-checkbox
              v-else
              v-model="isTrialPitVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="unverifySiteData(siProductType[siProductType.Pit])"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-card>
          <v-card-text>
            <SitePitDetailLog :job-id="jobId" :engineer-visit-detail-id="itemId"></SitePitDetailLog>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="getCCTVControlLogDetail" class="mb-3 elevation-1">
        <v-layout slot="header" wrap class="align-center">
          <v-flex
            :class="
              getCCTVControlLogDetail.cctvControlRunLogs && getCCTVControlLogDetail.cctvControlRunLogs.length > 0
                ? 'xs8'
                : 'xs12'
            "
          >
            <h3 slot="header">CCTV Logs</h3>
          </v-flex>
          <v-flex
            v-if="getCCTVControlLogDetail.cctvControlRunLogs && getCCTVControlLogDetail.cctvControlRunLogs.length > 0"
            xs4
            class="text-xs-right"
          >
            <b class="green--text">
              {{ isCCTVVerified ? 'Verified' : 'Verify' }}
            </b>
            <v-checkbox
              v-if="!isCCTVVerified"
              v-model="isCCTVVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="verifySiteData(siProductType[siProductType.CCTV])"
            ></v-checkbox>
            <v-checkbox
              v-else
              v-model="isCCTVVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="unverifySiteData(siProductType[siProductType.CCTV])"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-card>
          <v-card-text>
            <CCTVControlLog :job-id="jobId" :engineer-visit-detail-id="itemId"></CCTVControlLog>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="getWaterMainsTestDetail" class="mb-3 elevation-1">
        <v-layout slot="header" wrap class="align-center">
          <v-flex :class="getWaterMainsTestDetail.waterTests.length > 0 ? 'xs8' : 'xs12'">
            <h3 slot="header">Water mains test</h3>
          </v-flex>
          <v-flex v-if="getWaterMainsTestDetail.waterTests.length > 0" xs4 class="text-xs-right">
            <b class="green--text">
              {{ isWaterMainsVerified ? 'Verified' : 'Verify' }}
            </b>
            <v-checkbox
              v-if="!isWaterMainsVerified"
              v-model="isWaterMainsVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="verifySiteData(siProductType[siProductType.WaterMains])"
            ></v-checkbox>
            <v-checkbox
              v-else
              v-model="isWaterMainsVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="unverifySiteData(siProductType[siProductType.WaterMains])"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-card>
          <WaterTest :job-id="jobId" :engineer-visit-detail-id="itemId"></WaterTest>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="getDatumControlLogDetail.length > 0" class="mb-3 elevation-1">
        <v-layout slot="header" wrap class="align-center">
          <v-flex xs8>
            <h3 slot="header">Datums</h3>
          </v-flex>
          <v-flex v-if="isAnyDatumRunLogAdded" xs4 class="text-xs-right">
            <b class="green--text">
              {{ isDatumVerified ? 'Verified' : 'Verify' }}
            </b>
            <v-checkbox
              v-if="!isDatumVerified"
              v-model="isDatumVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="verifySiteData(siProductType[siProductType.Datum])"
            ></v-checkbox>
            <v-checkbox
              v-else
              v-model="isDatumVerified"
              color="green"
              @click.native.stop=""
              @click.prevent="unverifySiteData(siProductType[siProductType.Datum])"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-card>
          <v-card-text>
            <Datums :job-id="jobId" :engineer-visit-detail-id="itemId" />
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import SitePitDetailLog from '@/components/siteInvestigation/SitePitDetailLog.vue'
import CCTVControlLog from '@/components/siteInvestigation/CCTVControlLog.vue'
import Datums from '@/components/timeline/Datums.vue'
import CCTVControlLogModel from '@/models/siteInvestigation/CCTVControlLog'
import storeGetters from '@/storeGetters'
import WaterTest from '@/components/siteInvestigation/WaterTest.vue'
import AddSIProductVerificationRequest from '../../models/siteInvestigation/requests/AddSIProductVerificationRequest'
import { SIProductType } from '../../common/enums'
import SiteInvestigationController from '../../api/siteInvestigationController'
import Job from '../../models/Job'
import SIVisitTrialPit from '../../models/siteInvestigation/SIVisitTrialPit'
import DatumControlLog from '../../models/siteInvestigation/DatumControlLog'
import WaterMainsTest from '../../models/siteInvestigation/WaterMainsTest'
import Shared from '../../common/shared'

@Component({
  components: { SitePitDetailLog, CCTVControlLog, Datums, WaterTest },
})
export default class SiteDataPreview extends TimeLineItemBase {
  private siProductType = SIProductType
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private verificationDetails: Job | null = null
  private isTrialPitVerified = false
  private isCCTVVerified = false
  private isWaterMainsVerified = false
  private isDatumVerified = false

  private get getCCTVControlLogDetail(): CCTVControlLogModel | null {
    return storeGetters.getCCTVControlLogDetail(this.jobId, this.itemId)
  }

  private get getTrialPitDetail(): SIVisitTrialPit[] | [] {
    return storeGetters.getTrialPitDetail(this.jobId, this.itemId)
  }

  private get getDatumControlLogDetail(): DatumControlLog[] | [] {
    return storeGetters.getDatumControlLogDetail(this.jobId, this.itemId)
  }

  private get getWaterMainsTestDetail(): WaterMainsTest | null {
    return storeGetters.getWaterMainsTestDetail(this.jobId, this.itemId)
  }

  private created() {
    this.updateData()
  }

  private get getVerificationData(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private updateData() {
    if (
      this.getVerificationData &&
      this.getVerificationData.sIJobDetail &&
      this.getVerificationData.sIJobDetail.verifiedItems &&
      this.job!.jobType === 'SI'
    ) {
      const verificationData = this.getVerificationData!.sIJobDetail!.verifiedItems
      if (verificationData) {
        this.isTrialPitVerified = verificationData.includes(SIProductType.Pit)
        this.isCCTVVerified = verificationData.includes(SIProductType.CCTV)
        this.isWaterMainsVerified = verificationData.includes(SIProductType.WaterMains)
        this.isDatumVerified = verificationData.includes(SIProductType.Datum)
      }
    } else if (this.getCCTVControlLogDetail) {
      this.isCCTVVerified = this.getCCTVControlLogDetail.isCCTVDrainageVerified
    }
  }

  private verifySiteData(type: SIProductType) {
    Shared.confirmationPopup.open(
      'Are you sure you want to update status as verified?',
      '',
      '',
      'No',
      'Yes',
      this,
      'updateStatusToVerify',
      { type, verify: true }
    )
  }

  private unverifySiteData(type: SIProductType) {
    Shared.confirmationPopup.open(
      'Are you sure you want to revert status to unverified?',
      '',
      '',
      'No',
      'Yes',
      this,
      'updateStatusToVerify',
      { type, verify: false }
    )
  }

  private async updateStatusToVerify(arg: { type: SIProductType; verify: boolean }) {
    const addSIProductVerificationRequest: AddSIProductVerificationRequest = new AddSIProductVerificationRequest()
    addSIProductVerificationRequest.jobId = this.jobId
    addSIProductVerificationRequest.siProductType = arg.type
    addSIProductVerificationRequest.removeVerification = !arg.verify
    const request = await SiteInvestigationController.AddSIProductVerificationRequest(addSIProductVerificationRequest)
    this.snackbar = true
    this.snackbarText = request
      ? arg.verify
        ? arg.type + ' verified successfully'
        : arg.type + ' reverted to unverified'
      : 'Please try again'
  }

  private get isAnyDatumRunLogAdded(): boolean {
    const datumDatumControl = this.getDatumControlLogDetail
    if (datumDatumControl) {
      const datumRunLog: any[] = []
      datumDatumControl.forEach((datum) => {
        if (datum.datumControlRunLogs.find((x) => x.note !== null)) {
          datumRunLog.push(true)
        }
      })
      return datumRunLog.length > 0 ? true : false
    }
    return false
  }
}
</script>

<style>
.trialpit-accordion >>> .v-card__text {
  padding-top: 12px;
}
.trialpit-accordion >>> .v-expansion-panel__container {
  margin-bottom: 2px;
}
.trialpit-accordion >>> .v-expansion-panel__container >>> .v-expansion-panel__header {
  border: 1px solid lightgray;
}
</style>

<style scoped>
.data-accordion >>> .v-expansion-panel__container {
  border: none !important;
}
.data-accordion >>> .v-card__text {
  padding-top: 0;
}
.data-accordion >>> .v-input--checkbox {
  display: inline-flex;
  margin: 0 0 0 10px;
  padding: 0;
}
.data-accordion >>> .v-input--checkbox .v-input__slot {
  margin: 0;
}
.data-accordion >>> .v-expansion-panel__header {
  padding: 8px 15px;
}
.data-accordion >>> .v-expansion-panel__header .v-input--selection-controls__input {
  margin: 0;
  top: 6px;
}
.data-accordion >>> .v-input--switch .v-input__slot {
  margin: 0;
}
.data-accordion >>> .v-input--switch .v-messages {
  display: none;
}
.layer-card .close-icon {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 1380px) {
  .data-accordion >>> .v-card__text {
    padding: 0 10px 10px;
  }
  .data-accordion h3 {
    font-size: 14px !important;
  }
  .data-accordion >>> .v-expansion-panel__header {
    padding: 8px 10px;
  }
  .data-accordion >>> .v-expansion-panel__header h3 {
    font-size: 14px !important;
  }
  .data-accordion .gridView >>> th,
  .data-accordion .gridView >>> td {
    padding: 0px 10px !important;
  }
  .data-accordion >>> .layout .flex.pa-3 {
    padding: 10px !important;
  }
  .data-accordion >>> .v-card.pt-3 {
    padding-top: 10px !important;
  }
  .data-accordion >>> .sitedata-accordion {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .data-accordion >>> .sitedata-accordion .px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .data-accordion >>> .sitedata-accordion .pt-3 {
    padding-top: 10px !important;
  }
  .data-accordion >>> .v-btn {
    font-size: 13px;
    margin-right: 10px !important;
    padding: 0 10px;
  }
  .data-accordion >>> .v-card .mx-3 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .data-accordion >>> .cctv-accordion .sitedata-accordion {
    padding: 0 !important;
  }
  .data-accordion >>> .cctv-accordion .v-btn {
    margin-right: 0 !important;
    padding: 0 10px;
  }
  .data-accordion >>> .cctv-accordion .px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
</style>
