<template>
  <div>
    <div class="mb-2"><h4>Job Details</h4></div>
    <div v-if="item.clientTitle" class="mb-1">
      <label>Title: </label>
      <span>{{ item.clientTitle }}</span>
    </div>
    <div v-if="item.clientForename" class="mb-1">
      <label>First Name: </label>
      <span>{{ item.clientForename }}</span>
    </div>
    <div v-if="item.clientLastName" class="mb-1">
      <label>Last Name: </label>
      <span>{{ item.clientLastName }}</span>
    </div>

    <div v-if="item.secondaryClientTitle" class="mb-1">
      <label>Secondary Policy Holder Title: </label>
      <span>{{ item.secondaryClientTitle }}</span>
    </div>
    <div v-if="item.secondaryClientForename" class="mb-1">
      <label>Secondary Policy Holder First Name: </label>
      <span>{{ item.secondaryClientForename }}</span>
    </div>
    <div v-if="item.secondaryClientLastName" class="mb-1">
      <label>Secondary Policy Holder Last Name: </label>
      <span>{{ item.secondaryClientLastName }}</span>
    </div>

    <div v-if="item.addressLine1" class="mb-1">
      <label>Address Line1: </label>
      <span>{{ item.addressLine1 }}</span>
    </div>
    <div v-if="item.addressLine2" class="mb-1">
      <label>Address Line2: </label>
      <span>{{ item.addressLine2 }}</span>
    </div>
    <div v-if="item.addressLine3" class="mb-1">
      <label>Address Line3: </label>
      <span>{{ item.addressLine3 }}</span>
    </div>
    <div v-if="item.addressLine4" class="mb-1">
      <label>Address Line4: </label>
      <span>{{ item.addressLine4 }}</span>
    </div>
    <div v-if="item.postCode" class="mb-1">
      <label>Postcode: </label>
      <span>{{ item.postCode }}</span>
    </div>

    <div v-if="item.mobilePhone" class="mb-1">
      <label>Mobile Phone: </label>
      <span>{{ item.mobilePhone }}</span>
    </div>
    <div v-if="item.email" class="mb-1">
      <label>Email: </label>
      <span>{{ item.email }}</span>
    </div>

    <div v-if="item.contactPreference" class="mb-1">
      <label>Contact Preference: </label>
      <span>{{ item.contactPreference }}</span>
    </div>
    <div v-if="item.reasonForChoosingCallOption" class="mb-1">
      <label>Reason For selecting Call As a Preference: </label>
      <span>{{ item.reasonForChoosingCallOption }}</span>
    </div>

    <div v-if="item.policyNumber" class="mb-1">
      <label>Policy Number: </label>
      <span>{{ item.policyNumber }}</span>
    </div>
    <div v-if="item.policyNumberNotPresentReason" class="mb-1">
      <label>Policy Number Not Present Reason: </label>
      <span>{{ item.policyNumberNotPresentReason }}</span>
    </div>
    <div v-if="item.customerRef" class="mb-1">
      <label>Customer Reference: </label>
      <span>{{ item.customerRef }}</span>
    </div>
    <div v-if="item.policyScheme" class="mb-1">
      <label>Policy Scheme: </label>
      <span>{{ item.policyScheme }}</span>
    </div>

    <div v-if="item.insurer" class="mb-1">
      <label>Insurer: </label>
      <span>{{ item.insurer }}</span>
    </div>
    <div v-if="item.insuranceValue" class="mb-1">
      <label>Insurance Value: </label>
      <span>{{ item.insuranceValue }}</span>
    </div>
    <div v-if="item.policyScheduleId" class="mb-1">
      <label>PolicyScheduleId: </label>
      <span>{{ item.policyScheduleId }}</span>
    </div>

    <template v-if="item.emergencies && item.emergencies.length > 0">
      <div v-for="(emergency, index) in item.emergencies" :key="index">
        <div v-if="emergency.emergencyTypeDesc" class="mb-1">
          <label>Emergency: </label>
          <span>{{ emergency.emergencyTypeDesc }}</span>
        </div>
        <div v-if="emergency.emergencyDetailDesc" class="mb-1">
          <label>Emergency Detail: </label>
          <span>{{ emergency.emergencyDetailDesc }}</span>
        </div>
        <div v-if="emergency.tradeDesc" class="mb-1">
          <label>Trade: </label>
          <span>{{ emergency.tradeDesc }}</span>
        </div>
        <div v-if="emergency.note" class="mb-1">
          <label>Note: </label>
          <span>{{ emergency.note }}</span>
        </div>
        <div v-if="emergency.firstNoticedAtUtc" class="mb-1">
          <label>First Noticed: </label>
          <span>{{ getFormattedDate(emergency.firstNoticedAtUtc) }}</span>
        </div>
      </div>
    </template>

    <div v-if="item.reasonForCreatingDuplicateJob" class="mb-1">
      <label>Reason For Creating Duplicate Job: </label>
      <span>{{ item.reasonForCreatingDuplicateJob }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class AddHEJobWithEmergenciesLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddHEJobWithEmergencies {
    return Object.assign(new AddHEJobWithEmergencies(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>

<style scoped></style>
