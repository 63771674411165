<template>
  <div class="clientrate">
    <v-dialog v-model="repairEstimationDialog" max-width="1200" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Repair Estimate</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-btn" @click.native="$emit('closeRepairEstimationDialog')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog repair-estimation-content">
          <v-progress-circular
            v-show="!ready"
            :width="2"
            :size="50"
            indeterminate
            color="primary"
            :style="getLoaderStyle(50)"
          ></v-progress-circular>
          <v-layout v-show="ready" wrap mt-0>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="repairEstimateProducts"
                item-key="subCode"
                hide-actions
                class="elevation-1 repair-estimation-table"
                expand
              >
                <template slot="headers" slot-scope="props">
                  <tr>
                    <th v-for="header in props.headers" :key="header.text" :class="header.class" :align="header.align">
                      {{ header.text }}
                    </th>
                  </tr>
                </template>
                <template slot="items" slot-scope="props">
                  <tr>
                    <td>
                      <v-autocomplete
                        v-model="props.item.subCategory"
                        v-validate="'required'"
                        maxlength="500"
                        :items="subCategories"
                        :name="'SubCategory' + props.index"
                        data-vv-scope="frmRepairEstimation"
                        :data-vv-name="'Sub Category' + props.index"
                        :error-messages="validationMessage('Sub Category' + props.index)"
                        class="subCategory"
                        @change="onSubCategoryChange($event, props.index)"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        v-model.number="props.item.description"
                        v-validate="'required'"
                        maxlength="500"
                        name="description"
                        :items="getDescriptionList(props.item.subCategory)"
                        data-vv-scope="frmRepairEstimation"
                        :data-vv-name="'Description' + props.index"
                        :error-messages="validationMessage('Description' + props.index)"
                        class="description"
                        @change="onDescriptionChange($event, props.item.subCategory, props.index)"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-text-field
                        v-model="props.item.unit"
                        maxlength="30"
                        name="unit"
                        :readonly="!props.item.subCategory && !props.item.description"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="props.item.unitPrice"
                        v-validate="'required'"
                        maxlength="8"
                        class="prepend-icon-image"
                        prepend-icon="close"
                        :name="'unitPrice' + props.index"
                        data-vv-scope="frmRepairEstimation"
                        :data-vv-name="'Unit Price' + props.index"
                        :error-messages="validationMessage('Unit Price' + props.index)"
                        :readonly="!props.item.subCategory && !props.item.description"
                        @keypress="numberKeyValidation($event)"
                        @input="changeClientPrice(props.item)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="props.item.vatRate"
                        v-validate="{ rules: { max_value: 100 } }"
                        maxlength="5"
                        class="prepend-icon-image percent"
                        append-icon="close"
                        :name="'VAT' + props.index"
                        data-vv-scope="frmRepairEstimation"
                        :data-vv-name="'VAT' + props.index"
                        :error-messages="validationMessage('VAT' + props.index, true)"
                        :readonly="!props.item.subCategory && !props.item.description"
                        @keypress="numberKeyValidation($event)"
                        @input="changeClientPrice(props.item)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        :ref="'quantity' + props.index"
                        v-model.number="props.item.quantity"
                        v-validate="'required'"
                        maxlength="8"
                        :name="'quantity' + props.index"
                        data-vv-scope="frmRepairEstimation"
                        :data-vv-name="'Quantity' + props.index"
                        :error-messages="validationMessage('Quantity' + props.index)"
                        @keypress="quantityNumberValidation($event)"
                        @input="changeClientPrice(props.item)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="props.item.clientPrice"
                        maxlength="8"
                        class="prepend-icon-image"
                        prepend-icon="close"
                        name="clientPrice"
                        :readonly="true"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="props.item.repairCost"
                        maxlength="8"
                        class="prepend-icon-image"
                        prepend-icon="close"
                        name="repairCost"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="props.item.customerAppointedTradePrice"
                        maxlength="8"
                        class="prepend-icon-image"
                        prepend-icon="close"
                        name="customerAppointedTradePrice"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            v-if="repairEstimateProducts.length > 1"
                            flat
                            icon
                            class="mr-0 btn-remove"
                            color="primary"
                            v-on="on"
                            @click.prevent="removeAdditionalEstimate(props.item)"
                          >
                            <v-icon dark color="primary">close</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove Row</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
                <template slot="footer">
                  <td colspan="3"></td>
                  <td class="text-xs-right"><strong>Total</strong></td>
                  <td class="text-xs-right">
                    <b>
                      {{
                        getFormatedCurrency(
                          repairEstimateProducts.reduce(
                            (a, b) => a + (b.vatRate && b.clientPrice ? (b.clientPrice * b.vatRate) / 100 : 0),
                            0
                          )
                        )
                      }}
                    </b>
                  </td>
                  <td class="text-xs-right">
                    <b>
                      {{ repairEstimateProducts.reduce((a, b) => a + (b.quantity ? b.quantity : 0), 0) }}
                    </b>
                  </td>
                  <td class="text-xs-right">
                    <b class="secondary--text">
                      {{
                        getFormatedCurrency(
                          repairEstimateProducts.reduce(
                            (a, b) =>
                              a +
                              (b.clientPrice ? b.clientPrice + (b.clientPrice * (b.vatRate ? b.vatRate : 1)) / 100 : 0),
                            0
                          )
                        )
                      }}
                    </b>
                  </td>
                  <td colspan="3"></td>
                </template>
              </v-data-table>
              <div class="text-xs-right mt-2">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn class="mr-0 btn-add" icon color="primary" small v-on="on" @click="addAdditionalEstimation">
                      <v-icon dark class="white--text" small>add</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Row</span>
                </v-tooltip>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="$emit('closeRepairEstimationDialog')">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-submit"
            :disabled="isLoading || repairEstimateProducts.length === 0"
            :loading="isLoading"
            @click="UpsertRepairEstimateRequest"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import SiteInvestigationController from '../api/siteInvestigationController'
import UpsertRepairEstimateRequestModel from '../models/undergroundServices/UpsertRepairEstimateRequestModel'
import ClientRateUSModel from '../models/client/ClientRateUSModel'
import Shared from '../common/shared'
import RateController from '../api/rateController'
import ClientRatePackageUSModel from '../models/client/ClientRatePackageUSModel'
import RepairEstimateProductModel from '../models/undergroundServices/RepairEstimateProductModel'
import RepairEstimateModel from '../models/undergroundServices/RepairEstimateModel'
import eventBus from '../common/bus'

@Component
export default class RepairEstimationDialog extends Vue {
  @Prop() private policyClientRatePackageId: string
  @Prop() private jobId: string
  @Prop({ default: () => [] }) private products: RepairEstimateProductModel[]
  @Prop({ default: '' }) private repairEstimateId: string

  private ready = true
  private repairEstimationDialog = true
  private isLoading = false
  private headers: any = []
  private repairEstimateProducts: RepairEstimateProductModel[] = []
  private clientRateList: ClientRateUSModel[] = []
  private subCategoriesList: any[] = []
  private subCategories: any[] = []

  private created() {
    this.headers = [
      {
        text: 'Sub Category',
        value: 'subCategory',
        sortable: false,
        align: 'left',
        class: 'col-subcategory',
      },
      {
        text: 'Description',
        value: 'description',
        align: 'left',
        sortable: false,
        class: 'col-description',
      },
      {
        text: 'Unit',
        value: 'unit',
        align: 'left',
        sortable: false,
        class: 'col-estimate-product',
      },
      {
        text: 'Unit Price',
        value: 'unitPrice',
        align: 'left',
        sortable: false,
        class: 'col-estimate-product',
      },
      {
        text: 'Vat rate',
        value: 'vatRate',
        sortable: false,
        align: 'left',
        class: 'col-vat-rate',
      },
      {
        text: 'Quantity',
        value: 'quantity',
        align: 'left',
        sortable: false,
        class: 'col-quantity',
      },
      {
        text: 'Client Price',
        value: 'clientPrice',
        align: 'left',
        sortable: false,
        class: 'col-estimate-product',
      },
      {
        text: 'Repair Cost',
        value: 'repairCost',
        align: 'left',
        sortable: false,
        class: 'col-estimate-product',
      },
      {
        text: 'Customer Appointed Trades quote',
        value: 'customerAppointedTradePrice',
        align: 'left',
        sortable: false,
        class: 'col-cat-price',
      },
      { text: '', value: '', sortable: false },
    ]
  }

  private mounted() {
    this.GetUSClientRatePackageAsync()
    this.repairEstimateProducts = JSON.parse(JSON.stringify(this.products))
  }

  private GetUSClientRatePackageAsync() {
    this.ready = false
    RateController.GetUSClientRatePackageAsync(this.policyClientRatePackageId)
      .then((res: ClientRatePackageUSModel) => {
        if (res && res.clientRateUSList.length > 0) {
          this.clientRateList = res.clientRateUSList
          this.subCategories =
            res.clientRateUSList.length > 0
              ? res.clientRateUSList
                  .filter((e) => e.subCategory !== null && e.subCategory !== undefined && e.subCategory.trim() !== '')
                  .map((c) => c.subCategory)
              : []
          if (!this.repairEstimateId) {
            this.repairEstimateProducts.push(new RepairEstimateProductModel())
          }
        }
        this.ready = true
      })
      .catch((err: any) => {
        this.repairEstimateProducts = []
        this.ready = true
        eventBus.$emit('errorHandler', 'Error in loading client rate package!', true)
      })
  }

  private validationMessage(label: string, forVat = false) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (forVat) {
      return message ? (message = 'The ' + errorMessage[0] + ' must be less than or equal to 100.') : message
    }
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private quantityNumberValidation(event: any) {
    Shared.IsNumeric(event)
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmRepairEstimation')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  // add additional estimation
  private addAdditionalEstimation(): void {
    const product: RepairEstimateProductModel = new RepairEstimateProductModel()
    this.repairEstimateProducts.push(product)
  }

  // remove additional estimation
  private removeAdditionalEstimate(product: RepairEstimateProductModel): void {
    const index = this.repairEstimateProducts.indexOf(product)
    this.repairEstimateProducts.splice(index, 1)
  }

  private getDescriptionList(subCategory: string) {
    // bind list of description
    const subDescriptionList: string[] = []
    if (subCategory) {
      const filteredClientRateList = this.clientRateList.filter((c) => c.subCategory === subCategory)
      filteredClientRateList.forEach((rate) => {
        subDescriptionList.push(rate.description)
      })
      return subDescriptionList
    }
    return subDescriptionList
  }

  private onDescriptionChange(description: string, subCategory: string, itemIndex: number) {
    this.$validator.errors.items = []
    const clientRate: ClientRateUSModel | undefined = this.clientRateList.find(
      (c) => c.subCategory === subCategory && c.description === description
    )
    if (clientRate) {
      this.repairEstimateProducts[itemIndex].vatRate = clientRate.vatRate
      this.repairEstimateProducts[itemIndex].unitPrice = clientRate.unitPrice
      this.repairEstimateProducts[itemIndex].unit = clientRate.unit
      this.repairEstimateProducts[itemIndex].description = clientRate.description
      // reset value of client price on description change
      this.changeClientPrice(this.repairEstimateProducts[itemIndex])
      // set focus to quantity field on description change
      const quantityRef = 'quantity' + itemIndex
      ;(this.$refs[quantityRef] as any).focus()
    }
  }

  private changeClientPrice(product: RepairEstimateProductModel) {
    product.clientPrice = (product.unitPrice ? product.unitPrice : 0) * (product.quantity ? product.quantity : 0)
    this.repairEstimateProducts = [...this.repairEstimateProducts]
  }

  private UpsertRepairEstimateRequest() {
    this.validate().then((result: boolean) => {
      if (result) {
        this.isLoading = true
        const upsertRepairEstimateRequestModel: UpsertRepairEstimateRequestModel =
          new UpsertRepairEstimateRequestModel()
        upsertRepairEstimateRequestModel.jobId = this.jobId
        upsertRepairEstimateRequestModel.id = this.repairEstimateId
        upsertRepairEstimateRequestModel.products = this.repairEstimateProducts
        SiteInvestigationController.UpsertRepairEstimateRequestAsync(upsertRepairEstimateRequestModel)
          .then((res: boolean) => {
            const message = res
              ? 'Repair estimation detail ' + (this.repairEstimateId ? 'updated' : 'added') + ' successfully.'
              : 'Something went wrong. Please try again.'
            this.isLoading = false
            if (res) {
              this.$emit('closeRepairEstimationDialog', message)
            }
          })
          .catch((err: any) => {
            this.isLoading = false
            eventBus.$emit('errorHandler', err, true)
          })
      }
    })
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private onSubCategoryChange(subCategory: string, itemIndex: number) {
    const filteredClientRateList = this.clientRateList.filter((c) => c.subCategory === subCategory)
    if (filteredClientRateList.length === 1) {
      this.onDescriptionChange(filteredClientRateList[0].description, subCategory, itemIndex)
    }
  }
}
</script>

<style scoped>
.repair-estimation-table :-ms-input-placeholder {
  font-size: 14px;
}
.repair-estimation-table :-moz-placeholder {
  font-size: 14px;
}
.repair-estimation-table ::-webkit-input-placeholder {
  font-size: 14px;
}
.repair-estimation-table .input-group--select .input-group__selections__comma,
.repair-estimation-table >>> .input-group--text-field input,
.repair-estimation-table >>> .input-group--text-field textarea {
  font-size: 14px;
}
.repair-estimation-table >>> table.v-table tbody td:first-child,
.repair-estimation-table >>> table.v-table tbody td:not(:first-child),
.repair-estimation-table >>> table.v-table tbody th:first-child,
.repair-estimation-table >>> table.v-table tbody th:not(:first-child),
.repair-estimation-table >>> table.v-table thead td:first-child,
.repair-estimation-table >>> table.v-table thead td:not(:first-child),
.repair-estimation-table >>> table.v-table thead th:first-child,
.repair-estimation-table >>> table.v-table thead th:not(:first-child) {
  padding: 0px 12px;
}
.repair-estimation-table >>> .input-group--select .input-group__selections__comma {
  font-size: 13px;
}
.clientrate >>> input[type='number']::-webkit-inner-spin-button,
.clientrate >>> input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.repair-estimation-table >>> .input-group {
  padding-top: 5px;
}
.repair-estimation-table >>> .v-input--checkbox {
  position: relative;
  top: 8px;
}
.repair-estimation-table .prepend-icon-image >>> i.v-icon {
  position: relative;
  color: #fff !important;
  height: 0px !important;
}
.repair-estimation-table >>> .v-table tr:hover .prepend-icon-image i.v-icon {
  color: #eee !important;
}
.repair-estimation-table .prepend-icon-image >>> i.v-icon::after {
  content: '';
  background-image: url('/img/pound-sterling.svg');
  background-size: cover;
  position: absolute;
  left: 5px;
  top: -7px;
  height: 16px;
  width: 16px;
}
.repair-estimation-table .prepend-icon-image.percent >>> i.v-icon::after {
  background-image: url('/img/percent.svg') !important;
}
.col-estimate-product {
  width: 150px;
}
.col-quantity {
  width: 100px;
}
.col-description {
  width: 300px;
}
.col-subcategory {
  width: 200px;
}
.col-cat-price {
  min-width: 141px;
  white-space: normal;
}
.repair-estimation-content {
  min-height: 170px;
}
.col-vat-rate {
  width: 80px;
}
</style>
