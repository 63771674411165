<template>
  <div>
    <v-flex v-for="(docType, ind) in testingProgress.requiredTestingDocuments" :key="ind">
      <v-alert
        v-if="missingDocument(docType)"
        color="error lighten-5 red--text"
        :value="true"
        type="error"
        class="custom-warning-error pa-2"
      >
        <span>{{ titleForType(docType) }} is required.</span>
      </v-alert>
      <v-alert
        v-else
        color="green lighten-5 green--text"
        :value="true"
        type="success"
        class="custom-warning-success pa-2"
      >
        <span>{{ titleForType(docType) }} uploaded.</span>
      </v-alert>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import TestingProgressDetail from '@/models/siteInvestigation/TestingProgressDetail'
import Product from '@/models/siteInvestigation/Product'
import { SIProductType, UploadedDocumentTypeEnum } from '@/common/enums'
import Pit from '@/models/siteInvestigation/Pit'

@Component
export default class TestingCard extends TimeLineItemBase {
  private missingDocument(type: number): boolean {
    if (!this.testingProgress) {
      return false
    }
    return this.testingProgress.missingTestingDocuments.includes(type)
  }

  private titleForType(type: number): string {
    switch (type) {
      case UploadedDocumentTypeEnum.LabSummary:
        return 'Lab Summary'
      case UploadedDocumentTypeEnum.TreeRoots:
        return 'Tree Roots'
      default:
        return ''
    }
  }

  private get testingProgress(): TestingProgressDetail | null {
    return storeGetters.getTestingProgress(this.jobId)
  }
}
</script>

<style scoped>
.custom-warning-error,
.custom-warning-success {
  border-top: 0px;
}
.custom-warning-error >>> .v-icon {
  color: #f44336 !important;
  margin-right: 5px;
}
.custom-warning-success >>> .v-icon {
  color: #66bb6a !important;
  margin-right: 5px;
}
</style>
