import { DocumentPreference } from '@/common/enums'

export default class DocumentTemplateModel {
  public templateId: number
  public insurerId: number
  public templateTypeId: number
  public templateTypeDescription: string
  public name: string
  public notificationPreferenceId: number
  public notificationPreferenceName: string | null
  public smsText: string | null
  public subject: string | null
  public emailText: string | null
  public clientSubject: string | null
  public clientEmailText: string | null
  public selectedTemplate: boolean
  public delay: number
  public isCommunicationWindowApplied: boolean
  public isDeleted: boolean

  public constructor() {
    if (!this.notificationPreferenceId) {
      this.notificationPreferenceId = DocumentPreference.Both
    }
  }
}
