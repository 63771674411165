<template>
  <div :class="'num-call-announcing sys-call-info pa-2 ' + (customColor ? customColor : colorClass)">
    <span v-if="availableWorkers >= 0 && showAvailableWorkers" class="badge">
      {{ availableWorkers }}
    </span>
    <label for="">{{ title }}</label>
    <span class="text-xs-right sys-call-content">
      <span class="num">
        {{ value }}
        <v-icon v-if="showArrow && arrowDirection" color="white">
          {{ 'arrow_' + arrowDirection }}
        </v-icon>
      </span>
      <span v-if="duration" class="time">{{ duration }}</span>
      <DashboardChart
        :chart-data="chartData"
        :max-value="chartMaxValue"
        :chart-type="chartTypeEnum[chartTypeEnum.Bar]"
      />
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DashboardChart from './DashboardChart.vue'
import { ChartType } from '@/common/enums'

export interface PanelLineProps {
  title: string
  value: number
  duration?: string
  chartData: number[]
  chartMaxValue?: number
  availableWorkers?: number
  showArrow: boolean
  colorClass?: string
}

@Component({
  name: 'DashboardPanelLine',
  components: {
    DashboardChart,
  },
})
export default class DashboardPanelLine extends Vue {
  @Prop() private title: string
  @Prop() private value: number
  @Prop() private duration: string
  @Prop() private chartData: number[]
  @Prop() private chartMaxValue: number
  @Prop({ required: false, default: false })
  private showAvailableWorkers: boolean
  @Prop() private availableWorkers: number
  @Prop() private showArrow: boolean
  @Prop() private colorClass: string
  @Prop() private customColor: string
  private chartTypeEnum = ChartType

  private get arrowDirection() {
    if (this.chartData && this.chartData.length) {
      if (this.value > this.chartData[this.chartData.length - 1]) {
        return 'upward'
      } else if (this.value < this.chartData[this.chartData.length - 1]) {
        return 'downward'
      }
    }
    return undefined
  }
}
</script>

<style scoped>
.sys-call-info > .sys-call-content {
  flex: 6;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
}

.sys-call-info {
  min-height: 57px;
}

.sys-call-content .num,
.sys-call-content .time {
  font-size: 30px;
  display: inline-block;
  height: 100%;
  line-height: normal;
  padding: 0px 5px;
}
</style>
