export default class ShowSnackbar {
  public message = ''
  public color = ''
  public icon = ''
  public timeout = 6000
  public showClose = true

  constructor(message: string, timeout = 6000, showClose = true, color = '', icon = '') {
    this.message = message
    this.color = color
    this.icon = icon
    this.timeout = timeout
    this.showClose = showClose
  }
}
