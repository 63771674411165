<template>
  <div v-if="flagEnabled">
    <AgentReservationsDialog />
    <div>
      <v-card-title primary-title class="pa-0 mb-2">
        <h3 class="mb-0">Agent Dashboard</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model.trim="search"
          append-icon="search"
          label="Search"
          single-line
          class="mt-0 pt-0"
          hide-details
        ></v-text-field>
      </v-card-title>
      <div>
        <v-data-table
          :headers="headers"
          :items="agents"
          :loading="isLoading"
          :search="search"
          :pagination.sync="pagination"
          class="gridView"
        >
          <template #items="{ item }">
            <td>{{ item.name }}</td>
            <td>{{ item.status }}</td>
            <td class="text-xs-right px-0">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn small color="primary" class="mx-0" v-on="on" @click="onFixClick(item)"> Fix </v-btn>
                </template>
                <span>Fix</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import TwilioController from '@/api/twilioController'
import WorkerModelResponse from '@/api/models/WorkerModelResponse'
import AgentReservationsDialog from '@/components/twilio/AgentReservationsDialog.vue'

@Component({ components: { AgentReservationsDialog } })
export default class AgentsDashboard extends Vue {
  public search = ''
  public headers: any = []
  public agents: WorkerModelResponse[] = []
  public isLoading = false
  public pagination: any = {}

  private created() {
    this.headers = [
      { text: 'Worker', value: 'name' },
      { text: 'Status', value: 'status' },
      { text: '', sortable: false },
    ]
    this.pagination.rowsPerPage = 10
  }

  private mounted() {
    this.getAgents()
  }

  public flagEnabled(): boolean {
    return !!this.$ld.variation('fnol-58-release-stuck-agents')
  }

  public onFixClick(agent: WorkerModelResponse) {
    this.$store.commit('agentModule/set', agent)
  }

  private getAgents() {
    this.isLoading = true
    TwilioController.GetAllWorkers().then((res: WorkerModelResponse[]) => {
      this.agents = res
      this.isLoading = false
    })
  }
}
</script>
