<template>
  <div>
    <div class="mb-2"><h4>Picture Upload</h4></div>
    <div v-if="item.fileName" class="mb-1">
      <label>fileName: </label>
      <span>{{ item.fileName }}</span>
    </div>
    <div v-if="item.uploadedBy" class="mb-1">
      <label>Description: </label>
      <span>{{ getDescription }}</span>
    </div>
    <div v-if="engineerName" class="mb-1">
      <label>Uploaded by: </label>
      <span>
        {{ engineerName }}
      </span>
    </div>
    <div v-if="item.note" class="mb-1">
      <label>Note: </label>
      <span>
        <pre>{{ item.note }}</pre>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddPictureUploadModel from '@/models/requests/AddPictureUploadModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class AddPictureUploadLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddPictureUploadModel {
    return Object.assign(new AddPictureUploadModel(), this.requestBody)
  }

  private get getDescription() {
    if (this.item.uploadedBy === 'Customer') {
      return 'Uploaded By Client'
    } else if (this.item.uploadedBy === 'EngineerBeforeJob') {
      return 'Uploaded Before Job'
    } else if (this.item.uploadedBy === 'EngineerAfterJob') {
      return 'Uploaded After Job'
    } else if (this.item.uploadedBy === 'PolicyHolderMissing') {
      return 'Uploaded For Customer Not Present'
    } else if (this.item.uploadedBy === 'DuringJobPhotos') {
      return 'Uploaded During Job'
    }
    return ''
  }

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.item.pictureUploadedByUserId.split('/')[1])
  }
}
</script>
