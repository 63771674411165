<template>
  <span class="counter">
    {{ counterLabel }}
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Counter extends Vue {
  private counter: any | null = null
  private showSeconds = '00'
  private showMinutes = '00'
  private totalSeconds = 0

  public startCounter(): void {
    this.counter = window.setInterval(this.setTime, 1000)
  }

  public clearCounter(): void {
    if (this.counter) {
      window.clearInterval(this.counter)
    }
    this.totalSeconds = 0
    this.showMinutes = '00'
    this.showSeconds = '00'
    this.counter = null
  }

  private get counterLabel(): string {
    if (this.totalSeconds === 0) {
      return ''
    } else {
      return this.showMinutes + ':' + this.showSeconds
    }
  }

  private setTime(): void {
    ++this.totalSeconds
    this.showSeconds = this.pad(this.totalSeconds % 60)
    this.showMinutes = this.pad(parseInt((this.totalSeconds / 60).toString(), 10))
  }

  private pad(val: number): string {
    const valString = val + ''
    if (valString.length < 2) {
      return '0' + valString
    } else {
      return valString
    }
  }
}
</script>

<style type="text/css" scoped></style>
