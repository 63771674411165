import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import { JobType } from '@/common/enums'

export default class QuestionController {
  public static async GetPolicyLevelQuestions(insurerId: number, jobType: JobType): Promise<EmergencyQuestionModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<EmergencyQuestionModel[]>(
      'Question/GetPolicyLevelQuestions?insurerId=' + insurerId + '&jobType=' + jobType
    )
    return res.data ? res.data : []
  }

  public static async GetEmergencyLevelQuestions(
    insurerId: number,
    emergencyId: number
  ): Promise<EmergencyQuestionModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<EmergencyQuestionModel[]>(
      'Question/GetEmergencyLevelQuestions?insurerId=' + insurerId + '&emergencyId=' + emergencyId
    )
    return res.data ? res.data : []
  }

  public static async GetEmergencyDetailLevelQuestions(
    insurerId: number,
    emergencyDetailId: number
  ): Promise<EmergencyQuestionModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<EmergencyQuestionModel[]>(
      'Question/GetEmergencyDetailLevelQuestions?insurerId=' + insurerId + '&emergencyDetailId=' + emergencyDetailId
    )
    return res.data ? res.data : []
  }

  public static async SaveQuestion(emergencyQuestion: EmergencyQuestionModel): Promise<EmergencyQuestionModel> {
    const res = await CallCentreApi.post<EmergencyQuestionModel>('Question/SaveQuestion', emergencyQuestion)
    return res.data
  }

  public static async DeleteEmergencyQuestion(questionId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(
      'Question/DeleteQuestion?questionId=' + encodeURIComponent(questionId)
    )
    return res.status >= 200 && res.status < 300
  }
}
