import moment from 'moment'

export default class MissedJobDetailModel {
  public jobId: string
  public contractorAppointedDetailId: string
  public status: string
  public address: string
  public companyName: string
  public engineerName: string
  public etaFrom: moment.Moment | null
  public etaTo: moment.Moment | null

  public constructor() {
    this.etaFrom = null
    this.etaTo = null
  }
}
