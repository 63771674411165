<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm6 class="radio-group-custom mt-3 mr-3">
        <h4 v-if="getPreferredModeTitle" class="pb-4">
          {{ getPreferredModeTitle }}
        </h4>
        <v-radio-group
          v-model="localContactPreferenceId"
          v-validate="'required'"
          :value="localContactPreferenceId"
          class="radio-group-input pt-0"
          :mandatory="false"
          data-vv-name="Contact Preference"
          :error-messages="errors.collect('Contact Preference')"
          name="contactPreferenceRadioGroup"
          @change="onPreferenceChange"
        >
          <v-layout wrap>
            <v-flex xs12 class="pr-4">
              <v-layout
                class="radio-inline-custom"
                :class="[
                  localContactPreferenceId !== contactPreference.SMS ? 'custom-value' : ' show-number',
                  contactPreferenceSMSClass,
                ]"
                @click="setContactPreference(contactPreference.SMS, contactPreferenceSMSClass)"
              >
                <v-radio
                  color="primary"
                  :value="contactPreference.SMS"
                  label="SMS"
                  name="contactPreferenceRadioSMS"
                ></v-radio>
                <PhoneNumber
                  ref="contactPreferenceSMS"
                  :passed-phone-number="getMobilePhone"
                  :is-disabled="localContactPreferenceId !== contactPreference.SMS"
                  :is-validation-required="true"
                  class="radio-phone-no"
                  :validate-number-for-s-m-s="true"
                  @phoneNumberUpdated="phoneNumberUpdated"
                />
              </v-layout>
              <v-layout
                class="radio-inline-custom"
                :class="[
                  localContactPreferenceId !== contactPreference.Email ? 'custom-value' : '',
                  contactPreferenceEmailClass,
                ]"
                @click="setContactPreference(contactPreference.Email, contactPreferenceEmailClass)"
              >
                <v-radio
                  color="primary"
                  :value="contactPreference.Email"
                  label="Email"
                  name="contactPreferenceRadioEmail"
                ></v-radio>
                <v-text-field
                  v-model.trim="getEmail"
                  v-validate="'required|max:50|email'"
                  maxlength="50"
                  :disabled="localContactPreferenceId !== contactPreference.Email"
                  required
                  class="required email-box"
                  data-vv-name="Contact Email"
                  name="ContactEmail"
                  :error-messages="errors.collect('Contact Email')"
                  @blur="updateContactPreference"
                ></v-text-field>
              </v-layout>
              <v-layout
                class="radio-inline-custom"
                :class="[
                  localContactPreferenceId !== contactPreference.Call ? 'custom-value' : 'show-number',
                  contactPreferenceCallClass,
                ]"
                @click="setContactPreference(contactPreference.Call, contactPreferenceCallClass)"
              >
                <v-radio
                  color="primary"
                  :value="contactPreference.Call"
                  label="Call"
                  name="contactPreferenceRadioCall"
                ></v-radio>
                <PhoneNumber
                  ref="contactPreferenceCall"
                  :passed-phone-number="getMobilePhone"
                  :is-disabled="localContactPreferenceId !== contactPreference.Call"
                  :is-validation-required="true"
                  class="radio-phone-no"
                  @phoneNumberUpdated="phoneNumberUpdated"
                />
              </v-layout>
            </v-flex>
          </v-layout>
        </v-radio-group>
      </v-flex>
      <v-flex v-if="localContactPreferenceId === contactPreference.Call" sm6 ma-0 call-preference-reason>
        <v-textarea
          v-model.trim="localNote"
          v-validate="'required'"
          label="Reason for selecting call preference"
          required
          class="required pt-0 pl-1 pr-1"
          rows="2"
          :error-messages="errors.collect('Reason')"
          name="Reason"
          data-vv-name="Reason"
          @blur="updateContactPreference"
        ></v-textarea>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import { ContactPreferenceEnum } from '@/common/enums'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import { ContactPreference } from '@/models/types'
import Job from '@/models/Job'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'
import Shared from '@/common/shared'

@Component({
  name: 'ContactDetails',
  components: {
    PhoneNumber,
  },
})
export default class ContactDetails extends Vue {
  public localMobilePhone = ''
  public localContactPreferenceId: number | null = null
  public localEmail = ''
  public localNote = ''

  // bind dynamic titles
  @Prop() private preferredModeTitle: string

  // bind props
  @Prop() private contactPreferenceId: number
  @Prop() private mobilePhone: string
  @Prop() private email: string
  @Prop() private isNominee: boolean
  @Prop() private reasonForCallOption: string

  private contactPreference = ContactPreferenceEnum
  private hasNominee = false
  private conPreId: number | null
  private contactPreferenceScope = 'contactPreferenceScope'
  private contactPreferenceSMSClass = 'contactPreference-sms'
  private contactPreferenceEmailClass = 'contactPreference-email'
  private contactPreferenceCallClass = 'contactPreference-call'

  private mounted() {
    this.localContactPreferenceId = this.contactPreferenceId
    this.localMobilePhone = this.mobilePhone
    this.localEmail = this.email
    this.localNote = this.reasonForCallOption
  }

  @Watch('mobilePhone')
  private assignExistingMobilePhone() {
    if (this.mobilePhone !== '') {
      this.localMobilePhone = this.mobilePhone
    }
  }

  private get getPreferredModeTitle() {
    return this.preferredModeTitle
  }

  @Watch('contactPreferenceId')
  private onContactPreferenceIdChange(newValue: number) {
    this.localContactPreferenceId = newValue
  }

  private get getMobilePhone() {
    return this.localMobilePhone
  }

  private set getMobilePhone(newValue: string) {
    this.localMobilePhone = newValue
  }

  private get getEmail() {
    return this.localEmail
  }

  private set getEmail(newValue: string) {
    this.localEmail = newValue
  }

  @Watch('hasNominee')
  private isHasNominee() {
    this.$emit('isHasNominee', this.hasNominee)
  }

  private phoneNumberUpdated(phoneNumber: string): void {
    this.localMobilePhone = phoneNumber
    this.updateContactPreference()
  }

  private onPreferenceChange() {
    this.updateContactPreference()
  }

  private updateContactPreference() {
    this.$emit(
      'updateContactPreference',
      this.localContactPreferenceId,
      this.localEmail,
      this.localMobilePhone,
      this.isNominee,
      this.localNote
    )
    this.$validator.errors.items = []
  }

  private setContactPreference(contactPreferenceCall: number, className: string) {
    this.localContactPreferenceId = contactPreferenceCall
    // set focus to text field
    const inputWrapper: any = this.$el.querySelector('.' + className)
    if (inputWrapper) {
      setTimeout(() => {
        const input = inputWrapper.getElementsByTagName('input')[className === this.contactPreferenceEmailClass ? 1 : 2]
        input.focus()
        // set focus at the end of the input
        const tempValue = input.value // store the value of the element
        input.value = '' // clear the value of the element
        input.value = tempValue
      }, 0)
    }
    this.updateContactPreference()
  }
}
</script>

<style type="text/css" scoped>
.radio-group-input >>> .radio-group--column .input-group__input {
  display: inherit;
}
.radio-group-input >>> .v-input--radio-group .input-group {
  padding: inherit;
}
.radio-inline-custom {
  position: relative;
  padding-left: 33px;
  cursor: pointer;
}
.radio-inline-custom >>> .v-radio {
  position: absolute;
  left: 0px;
  top: 5px;
}
.radio-group-custom >>> .v-input--radio-group .v-input {
  padding-top: 0px;
  margin-top: 0px;
}
.radio-inline-custom >>> label {
  width: auto;
}
.radio-inline-custom >>> .v-input input {
  color: #e91e63 !important;
  font-weight: 600;
}
.radio-group-custom >>> .v-input--radio-group > .v-input__control {
  padding-top: 0px;
  padding-left: 8px;
}
.radio-inline-custom >>> .v-text-field:not(.radio) {
  padding-left: 50px;
}
.radio-group-custom >>> .v-input--radio-group .error--text .input-group__messages {
  color: #ff5252 !important;
}
.custom-value >>> .v-input input {
  opacity: 0;
}
/*PhoneNumber Component*/
.internal-phone-number {
  width: 100%;
}
.internal-phone-number >>> .contact-number .input-group .v-text-field__details {
  opacity: 1;
  padding-right: 0px;
}
.internal-phone-number >>> .call-number-txt {
  max-width: calc(100% - 50px);
  display: inline-block;
  padding-left: 0 !important;
  width: 100%;
}
.internal-phone-number >>> .selectedCountry {
  max-width: 50px;
  display: inline-block;
  position: static;
  padding-left: 0 !important;
  vertical-align: top;
}
.internal-phone-number >>> .selectedCountry .v-select__slot,
.internal-phone-number >>> .validation,
.internal-phone-number >>> .contact-number .v-text-field__slot {
  opacity: 0;
}

.radio-group-custom >>> .v-input__control {
  width: 100%;
}
.radio-group-custom >>> .contact-number .v-input__control .v-input__slot:after,
.radio-group-custom >>> .contact-number .v-input__control .v-input__slot:before {
  opacity: 1;
}

.radio-group-input {
  margin-top: 0;
}
.show-number >>> .contact-number .v-input__control .v-input__slot:after,
.show-number >>> .contact-number .v-input__control .v-input__slot:before {
  opacity: 1 !important;
}
.radio-group-input >>> .v-input .v-input__slot {
  margin-bottom: 6px !important;
}
.radio-group-input >>> .v-input .v-text-field__details {
  min-height: 20px;
}
.call-preference-reason {
  padding-left: 87px;
  padding-right: 20px;
}
</style>
