<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Delay Codes</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="close-dialog" @click.native="closeConfigureDropdownDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text class="scroll-content-dialog">
      <v-progress-circular
        v-show="isLoaded"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
        :style="getLoaderStyle(70)"
      ></v-progress-circular>
      <v-layout v-show="!isLoaded">
        <v-flex xs12 class="gridView tbody-scroll-table">
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            :loading="isLoading"
            hide-actions
            class="unavailableReason-table"
          >
            <template slot="items" slot-scope="props">
              <tr ref="datatable">
                <td class="text-xs-left">
                  <v-text-field
                    v-if="isUpdateRecord && index === props.item.id"
                    v-model="props.item.description"
                    v-validate="'required'"
                    class="pt-2"
                    data-vv-scope="updateRecordFormRef"
                    data-vv-name="editDescription"
                    :error-messages="validationMessage('editDescription')"
                    name="Update-Description"
                  ></v-text-field>
                  <span v-else>{{ props.item.description }}</span>
                </td>
                <td class="text-xs-center align-center-checkbox">
                  <template>
                    <v-select
                      v-if="isUpdateRecord && index === props.item.id"
                      :id="'UpdateDelayRules'"
                      v-model="props.item.delayRules"
                      :items="delayRules"
                      multiple
                      item-id="id"
                      item-text="ruleName"
                      persistent-hint
                      label="Select Delay Rules"
                    ></v-select>
                    <v-chip v-for="(rule, index) in props.item.delayRules" v-else :key="`${rule}-${index}`" disabled>
                      {{ rule }}
                    </v-chip>
                  </template>
                </td>
                <td class="text-xs-right">
                  <v-flex v-if="isUpdateRecord && index === props.item.id">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          flat
                          color="primary"
                          small
                          class="update-btn"
                          v-on="on"
                          @click.native="updateDelayCode(props.item)"
                        >
                          <v-icon>update</v-icon>
                        </v-btn>
                      </template>
                      <span>Update</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          flat
                          color="primary"
                          small
                          class="cancel-edit-btn"
                          v-on="on"
                          @click.native="onCancelEditRecord(props.item)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </template>
                      <span>Cancel</span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex v-else>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          flat
                          color="primary"
                          small
                          class="edit-btn"
                          v-on="on"
                          @click.native="onEditRecord(props.item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          flat
                          color="primary"
                          small
                          class="delete-btn"
                          v-on="on"
                          @click.native="onDeleteRecordClick(props.item)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-flex>
                </td>
              </tr>
            </template>
            <template slot="footer">
              <tr>
                <td class="col-description">
                  <v-text-field
                    v-model="record.description"
                    v-validate="'required|min:1'"
                    label="Description"
                    required
                    class="required"
                    data-vv-scope="addRecordFormRef"
                    data-vv-name="Description"
                    :error-messages="errors.collect('Description')"
                    name="Description"
                    @focus="onAddDescriptionFocus"
                  ></v-text-field>
                </td>
                <td class="text-xs-center align-center-checkbox">
                  <template>
                    <v-select
                      :id="'CreateNewDelayRule'"
                      v-model="record.delayRules"
                      :items="delayRules"
                      multiple
                      item-id="id"
                      item-text="ruleName"
                      persistent-hint
                      label="Select Delay Rules"
                    ></v-select>
                  </template>
                </td>
                <td class="text-xs-right">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        name="addRecordButton"
                        icon
                        flat
                        color="primary"
                        small
                        v-on="on"
                        @click="createDelayCode()"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Add</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'
import DelayCode from '@/models/delay/DelayCode'
import DelayController from '@/api/delayController'
import DelayRule from '@/models/delay/delay-rule'

@Component
export default class DelayCodeConfiguration extends Vue {
  @Prop() private recordType: string

  private headers: any = []
  private items: DelayCode[] = []
  private index: any = null
  private isUpdateRecord = false
  private isLoading = false
  private previousValue = ''
  private record: DelayCode = new DelayCode()
  private isLoaded = false

  private get delayRules(): string[] {
    const availableDelayRules: DelayRule[] = this.$store.getters['delayModule/availableDelayRules']
    return availableDelayRules.map((x) => x.id)
  }

  private created() {
    this.headers = [
      {
        text: 'Description',
        value: 'description',
        sortable: false,
        align: 'left',
        class: 'description-col',
      },
      {
        text: 'Delay Rules',
        value: 'delayRules',
        sortable: false,
        align: 'left',
        class: 'description-col',
      },
      {
        text: '',
        sortable: false,
        value: 'action',
        align: 'right',
        class: 'action-col',
      },
    ]
    this.$store.dispatch('delayModule/submitRetrieveDelayRules')
    this.retrieveDelayCodes()
  }

  private retrieveDelayCodes() {
    this.isLoading = true
    if (this.recordType) {
      DelayController.RetrieveDelayCodes()
        .then((res: DelayCode[]) => {
          if (res) {
            this.items = res.filter((e) => e.isDeleted === false)
            this.isLoading = false
          }
          this.isLoaded = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading unavailable reason list, please try again', true)
          this.isLoading = false
        })
    }
  }
  // Set focus on add mew description field.
  private onAddDescriptionFocus() {
    if (this.isUpdateRecord) {
      this.isUpdateRecord = false
      const index = this.items.findIndex((e) => e.id === this.index)
      if (index !== -1) {
        this.items[index].description = this.previousValue
        this.items = [...this.items]
      }
    }
  }

  // Create delay code
  private async createDelayCode() {
    this.$validator.errors.items = []
    if (!this.isLoading) {
      const isValid = await this.validate('addRecordFormRef')
      if (isValid) {
        this.isLoading = true
        this.record.type = this.recordType
        DelayController.CreateDelayCode(this.record)
          .then((res: DelayCode) => {
            if (res) {
              this.items.push(res)
              this.record = new DelayCode()
              // set scrollTop for tbody
              setTimeout(() => {
                const dataTableBody: any = this.$el.querySelector('.v-datatable tbody')
                dataTableBody.scrollTop = dataTableBody.scrollHeight - dataTableBody.offsetHeight
              }, 0)
            }
          })
          .catch((err: Error) => {
            eventBus.$emit(
              'errorHandler',
              `Error saving unavailable reason detail, please try again. ErroeMessage: ${err.message}`,
              true
            )
            this.record = new DelayCode()
          })
      }
      this.isLoading = false
    }
  }

  // Update delay code
  private async updateDelayCode(record: DelayCode) {
    this.$validator.errors.items = []
    if (!this.isLoading) {
      if (record === null && this.isUpdateRecord) {
        this.isUpdateRecord = false
        const index = this.items.findIndex((e) => e.id === this.index)
        if (index !== -1) {
          this.items[index].description = this.previousValue
          this.items = [...this.items]
        }
      }
      const self = this
      const isValid = await this.validate('updateRecordFormRef')
      if (isValid) {
        this.isLoading = true
        record.type = this.recordType
        DelayController.UpdateDelayCode(record)
          .then((res: DelayCode) => {
            if (res) {
              this.isUpdateRecord = false
              const index = this.items.findIndex((e) => e.id === record.id)
              if (index !== -1) {
                this.items[index] = res
                this.items = [...this.items]
              }
              this.previousValue = this.items[index].description
            }
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error saving unavailable reason detail, please try again', true)
            this.isLoading = false
            self.record = new DelayCode()
          })
      }
    }
    this.isLoading = false
  }

  // set description value of item to previous value if updation of record is cancelled, remove error messages if any.
  private onCancelEditRecord(item: any) {
    item.description = this.previousValue
    this.$validator.errors.items = []
    this.isUpdateRecord = false
  }
  // get index of the current row, make row editable.
  private onEditRecord(item: any) {
    const index = this.items.findIndex((e) => e.id === this.index)
    if (index !== -1) {
      this.items[index].description = this.previousValue
      this.items = [...this.items]
    }
    this.$validator.errors.items = []
    this.record.description = ''
    this.isUpdateRecord = true
    this.index = item.id
    this.previousValue = item.description
  }

  private onDeleteRecordClick(item: any) {
    const index = this.items.findIndex((e) => e.id === this.index)
    if (index !== -1) {
      this.items[index].description = this.previousValue
      this.items = [...this.items]
    }
    this.$validator.errors.items = []
    this.isUpdateRecord = false
    this.record.description = ''
    Shared.confirmationPopup.open(
      'Do you really want to delete Delay Code' + '' + '?',
      '',
      '',
      '',
      '',
      this,
      'DeleteRecord',
      item.id
    )
  }
  // deletion of record.
  private DeleteRecord(id: string) {
    if (!this.isLoading) {
      this.isLoading = true
      DelayController.DeleteDelayCode(id)
        .then((res: boolean) => {
          if (res) {
            this.isLoading = false
            this.items = this.items.filter((e) => e.id !== id)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error deleting delay code, please try again', true)
          this.isLoading = false
        })
    }
  }

  private closeConfigureDropdownDialog() {
    this.$validator.errors.items = []
    this.isUpdateRecord = false
    this.record.description = ''
    this.$emit('closeConfigModal', this.items)
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message === 'The editDescription field is required.'
      ? (message = 'The Description field is required.')
      : message
  }

  private async validate(formref: any): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(formref)

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style scoped>
.gridview >>> .description-col {
  width: 70%;
}
.gridview >>> .action-col {
  width: 30%;
}
.tbody-scroll-table >>> table {
  width: 100%;
}
.tbody-scroll-table >>> tbody {
  display: block;
  max-height: 460px;
  min-height: 460px;
  overflow-y: scroll;
}
.tbody-scroll-table >>> tbody tr,
.tbody-scroll-table >>> thead,
.tbody-scroll-table >>> tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.tbody-scroll-table >>> tbody::-webkit-scrollbar {
  width: 6px;
}

.tbody-scroll-table >>> tbody::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 3px;
}
.gridView >>> table.v-table tbody td:first-child,
.gridView >>> table.v-table thead th:first-child {
  width: 250px;
  min-width: 250px;
}
.col-description {
  width: 250px;
  min-width: 250px;
}
</style>
