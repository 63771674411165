export default class Worker {
  public name: string // the name of the worker
  public available: boolean // whether or not they're available
  public activity: string // their activity description
  public userId: string // the contno
  public sid: string // the unique id of the worker
  public teamName: string // the team name
  public contactUri: string // the contact uri

  public text: string
  public value: string
  public disabled: boolean

  public constructor() {
    this.name = ''
    this.available = false
    this.activity = ''
    this.userId = ''
    this.sid = ''
    this.teamName = ''
    this.contactUri = ''
    this.text = ''
    this.value = ''
    this.disabled = true
  }
}
