<template>
  <div>
    <div class="mb-2">
      <h4>Send Job Email {{ item.sendToNominee ? '(Nominee)' : '' }}</h4>
    </div>
    <div v-if="getDocumentTemplateType" class="mb-1">
      <label>Document template type: </label>
      <span>{{ getDocumentTemplateType }}</span>
    </div>
    <div v-if="getContractorAppointedDetail" class="mb-1">
      <label>Company name: </label>
      <span>{{ getContractorAppointedDetail.companyName }}</span>
    </div>
    <div v-if="item.documentTemplateType === documentTemplateType.WorkInProgress" class="mb-1">
      <label>Is WIP Customer Notification?: </label>
      <span>Yes</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import SendJobEmail from '@/models/requests/SendJobEmail'
import storeGetters from '@/storeGetters'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { DocumentTemplateType } from '@/common/enums'

@Component
export default class SendJobEmailLog extends Vue {
  @Prop() private requestBody: any
  private documentTemplateType = DocumentTemplateType

  private get item(): SendJobEmail {
    return Object.assign(new SendJobEmail(), this.requestBody)
  }

  private get getContractorAppointedDetail(): ContractorAppointedModel {
    return storeGetters.getContractorAppointedDetailById(this.item.jobId, this.item.contractorAppointedDetailId)
  }

  private get getDocumentTemplateType() {
    switch (this.item.documentTemplateType) {
      case DocumentTemplateType.NewJobLogged:
        return 'New Job Logged'
      case DocumentTemplateType.ContractorAssigned:
        return 'Contractor Appointed'
      case DocumentTemplateType.JobCompleted:
        return 'Job Completed'
      case DocumentTemplateType.SIVisitBooked:
        return 'SI Visit Booked'
      case DocumentTemplateType.EmergencyAccepted:
        return 'Emergency Accepted'
      case DocumentTemplateType.EmergencyRejected:
        return 'Emergency Rejected'
      case DocumentTemplateType.HelplineCompleted:
        return 'Helpline Completed'
      case DocumentTemplateType.EngineerOnTheWay:
        return 'Engineer On The Way'
      case DocumentTemplateType.EngineerVisitNotification:
        return 'Engineer Visit Notification'
      case DocumentTemplateType.CustomerAppointedTradespeople:
        return 'Customer Appointed Tradespeople'
      case DocumentTemplateType.FollowOnWork:
        return 'Follow On Work'
      case DocumentTemplateType.DeploymentDelay:
        return 'Deployment Delay'
      case DocumentTemplateType.PartRequestApproved:
        return 'Part Request Approved'
      case DocumentTemplateType.SetETAOnReturnVisit:
        return 'Set ETA On Return Visit'
      case DocumentTemplateType.WorkInProgress:
        return 'Work In Progress'
      default:
        return DocumentTemplateType[this.item.documentTemplateType].toString()
    }
  }
}
</script>
