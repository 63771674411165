<template>
  <div>
    <div class="mb-2"><h4>Job Not Ready To Close</h4></div>
    <div v-if="item.emergencyDetail" class="mb-1">
      <label>Emergency: </label>
      <span>{{ item.emergencyDetail }}</span>
    </div>
    <div v-if="item.reason" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.reason }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import JobNotReadyToCloseModel from '../../models/requests/JobNotReadyToCloseModel'

@Component
export default class JobNotReadyToCloseLog extends Vue {
  @Prop() private requestBody: any

  private get item(): JobNotReadyToCloseModel {
    return Object.assign(new JobNotReadyToCloseModel(), this.requestBody)
  }
}
</script>
