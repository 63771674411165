<template>
  <div>
    <div class="mb-2"><h4>Update Customer Detail</h4></div>

    <div v-if="item.mobilePhone" class="mb-1">
      <label>Mobile Phone: </label>
      <span>{{ item.mobilePhone }}</span>
    </div>
    <div v-if="item.email" class="mb-1">
      <label>Email: </label>
      <span>{{ item.email }}</span>
    </div>

    <div v-if="item.contactPreference" class="mb-1">
      <label>Contact Preference: </label>
      <span>{{ item.contactPreference }}</span>
    </div>
    <div v-if="item.reasonForChoosingCallOption" class="mb-1">
      <label>Call Preference Reason: </label>
      <pre>{{ item.reasonForChoosingCallOption }}</pre>
    </div>

    <div v-if="item.nomineeClientTitle" class="mb-1">
      <label>Nominee Title: </label>
      <span>{{ item.nomineeClientTitle }}</span>
    </div>
    <div v-if="item.nomineeClientForename" class="mb-1">
      <label>Nominee First Name: </label>
      <span>{{ item.nomineeClientForename }}</span>
    </div>
    <div v-if="item.nomineeClientLastName" class="mb-1">
      <label>Nominee Last Name: </label>
      <span>{{ item.nomineeClientLastName }}</span>
    </div>

    <div v-if="item.nomineeMobilePhone" class="mb-1">
      <label>Nominee Mobile Phone: </label>
      <span>{{ item.nomineeMobilePhone }}</span>
    </div>
    <div v-if="item.nomineeEmail" class="mb-1">
      <label>Nominee Email: </label>
      <span>{{ item.nomineeEmail }}</span>
    </div>

    <div v-if="item.nomineeContactPreference" class="mb-1">
      <label>Nominee Contact Preference: </label>
      <span>{{ item.nomineeContactPreference }}</span>
    </div>
    <div v-if="item.nomineeReasonForChoosingCallOption" class="mb-1">
      <label>Nominee Call Preference Reason: </label>
      <pre>{{ item.nomineeReasonForChoosingCallOption }}</pre>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'

@Component
export default class UpdateCustomerDetailLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateCustomerContactPreferenceModal {
    return Object.assign(new UpdateCustomerContactPreferenceModal(), this.requestBody)
  }
}
</script>
