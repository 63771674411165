<template>
  <div class="question-title-desc">
    <v-layout wrap>
      <v-flex xs12 class="mb-2">
        <div v-if="claimPictures && !loading" class="avtar-container">
          <template v-for="(item, i) in claimPictures">
            <v-avatar v-if="i < 2" :key="i" class="mr-2 card-images">
              <img :src="item.fileURL" :alt="item.title" />
            </v-avatar>
          </template>
          <v-avatar v-show="claimPictures.length > 2">
            <span class="total-images">+{{ claimPictures.length - 2 }}</span>
          </v-avatar>
        </div>
        <div v-if="loading" class="pa-2">
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import eventBus from '@/common/bus'
import { imageExists } from '@/common/images'
import PhotosController from '@/api/photos-controller'

@Component
export default class PictureUploadCard extends TimeLineItemBase {
  private loading = false
  private claimPictures: PictureUploadModel[] = []

  @Watch('items')
  private onClaimPictureChange(newValue: PictureUploadModel[], oldValue: PictureUploadModel[]) {
    this.claimPictures = JSON.parse(JSON.stringify(this.items))
    this.getImages()
  }

  private getImages() {
    if (this.claimPictures.length > 0) {
      for (let i = 0; i < this.claimPictures.length; i++) {
        this.imageUrlCheck(i)
      }
    }
  }

  private created() {
    this.claimPictures = JSON.parse(JSON.stringify(this.items))
    this.getImages()
  }

  private get items(): PictureUploadModel[] {
    return storeGetters.getClaimPictures(this.jobId)
  }

  private async imageUrlCheck(i: number) {
    const photo = this.claimPictures[i]
    const exists = await imageExists(photo.fileURL)
    if (exists) {
      return
    }
    if (photo.fileURL.indexOf('?') > 0) {
      return
    }
    try {
      this.loading = true
      const url = await PhotosController.GetThumbnailUrl(photo.jobId, photo.id)
      if (url) {
        photo.fileURL = url
        this.claimPictures = [...this.claimPictures]
      }
    } catch {
      eventBus.$emit('errorHandler', 'Error loading image, please try again', true)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.pictureUploadCard .v-avatar img {
  object-fit: cover;
}
.total-images {
  font-size: 18px;
  font-weight: 600;
}
.card-images {
  border: 1px solid #dcd6d6;
  border-radius: 50%;
}
.card-images img {
  object-fit: cover;
}
</style>
