<template>
  <v-flex xs12>
    <v-progress-circular
      v-if="isLoading"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <div v-if="!isLoading" class="white pa-3">
      <v-layout row wrap>
        <v-flex xs12><h3>Engineer Visit Details</h3></v-flex>
      </v-layout>
      <v-divider class="mt-2 mb-3"></v-divider>
      <v-layout row wrap d-inline-flex>
        <v-flex>
          <div class="engineer-img">
            <img :src="engineer.profileImageUrl" class="profile-img" />
          </div>
        </v-flex>
        <v-flex px-3>
          <h2 v-if="engineer && engineer.firstName" class="subheading">
            {{ engineer.firstName }} {{ engineer.lastName }}
          </h2>
          <h2 v-else class="subheading">Engineer</h2>
          <p class="mb-1">
            <b class="primary--text d-inline-block mt-2">{{ engineerType }}</b>
          </p>
          <p v-if="canSeeEngineerPhoneNumber" class="mb-0">
            <span class="grey--text text--darken-1">
              <v-icon small color="grey" class="grey--text text--darken-1">call</v-icon>
              {{ engineer.contactNumber }}
            </span>
          </p>
          <p class="mb-0">
            <span v-if="engineerVisitDetail.companyName" class="grey--text text--darken-1 pt-1 d-inline-block">
              <img src="/img/contractor-icon.svg" class="contractor-icon" />
              {{ engineerVisitDetail.companyName }}
            </span>
          </p>
        </v-flex>
      </v-layout>
      <div
        v-if="
          engineerVisitDetail &&
          engineerVisitDetail.isEngineerReAttend &&
          engineerVisitDetail.visitNumber > 1 &&
          jobType === 'SI'
        "
      >
        <v-divider class="my-2"></v-divider>
        <v-layout row wrap mt-2>
          <v-flex>
            <h3>Engineer Return Visit</h3>
            <div class="mt-2">
              <div class="mb-1">
                <b>Reason:</b>
                {{ engineerVisitDetail.siReturnVisitReason }}
              </div>
              <div class="mb-1">
                <b>Note:</b>
                {{ engineerVisitDetail.siReturnVisitNote }}
              </div>
              <div class="mb-1">
                <b>Total Visits:</b>
                {{ engineerVisitDetail.visitNumber }}
              </div>
            </div>
          </v-flex>
        </v-layout>
      </div>
      <v-divider
        v-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.RunningLate]"
        class="my-2"
      ></v-divider>
      <v-layout v-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.RunningLate]">
        <v-flex>
          <h3>Engineer Running Late</h3>
          <div class="mt-2">
            <div class="mb-1">
              <v-icon>date_range</v-icon>
              &nbsp;
              <b>ETA From:</b>
              {{ getFormattedDate(engineerVisitDetail.etaFrom) }}
            </div>
            <div class="mb-1">
              <v-icon>date_range</v-icon>
              &nbsp;
              <b>ETA To:</b>
              {{ getFormattedDate(engineerVisitDetail.etaTo) }}
            </div>
            <div>{{ engineerVisitDetail.description }}</div>
          </div>
        </v-flex>
      </v-layout>
      <v-divider v-if="showEngineerLocation" class="my-2"></v-divider>
      <v-layout v-if="showEngineerLocation" row wrap mt-2>
        <v-flex v-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay]" lg6 xs12>
          <h3>Engineer On The Way</h3>
          <div class="mt-2 grey--text">
            {{ getFormattedDate(engineerVisitDetail.processAt) }}
          </div>
        </v-flex>
        <v-flex
          v-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.PolicyHolderAbsent]"
          lg6
          xs12
        >
          <v-checkbox
            v-model="engineerVisitDetail.isCustomerContacted"
            :readonly="true"
            name="isCustomerContacted"
            :hide-details="true"
            label="Customer Contacted"
            color="primary"
            class="customer-contact-chkbox full-width"
          ></v-checkbox>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex px-3 text-xs-right lg6 xs12>
          <v-btn
            flat
            small
            color="secondary"
            class="ma-0 btn-padding-zero engineer-location"
            @click="showLiveLocation = true"
          >
            {{
              engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay] ? 'Live' : 'Visit'
            }}
            location &nbsp;
            <v-icon>place</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-divider
        v-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.JobStarted]"
        class="my-2"
      ></v-divider>
      <v-layout
        v-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.JobStarted]"
        row
        wrap
        mt-2
      >
        <v-flex>
          <h3>Engineer On Site</h3>
          <div class="mt-2">{{ engineerVisitDetail.address }}</div>
          <div class="mt-2 grey--text">
            {{ getFormattedDate(engineerVisitDetail.processAt) }}
          </div>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex
          v-if="
            engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Pending] ||
            engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Accepted] ||
            engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay] ||
            engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.OnSite] ||
            engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.RunningLate]
          "
          xs12
          class="text-xs-right"
        >
          <v-divider class="my-2"></v-divider>
          <v-btn color="primary" @click="changeVisitStartTime">Add Visit Start Time</v-btn>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="
          !(
            engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Pending] ||
            engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Accepted]
          )
        "
      >
        <v-flex xs12>
          <v-divider v-if="policyHolderNotPresentPicture.length > 0" class="my-2"></v-divider>
          <v-layout v-if="policyHolderNotPresentPicture.length > 0" row wrap mt-2>
            <v-flex xs12>
              <PictureUpload
                :job-id="jobId"
                :picture-list="policyHolderNotPresentPicture"
                uploaded-by="PolicyHolderMissing"
              ></PictureUpload>
            </v-flex>
          </v-layout>
          <v-divider v-if="policyHolderNotPresentPicture.length > 0" class="my-2"></v-divider>
          <v-layout row wrap mt-2 mb-3>
            <v-flex v-if="engineerVisitDetail.visitNumber && engineerVisitDetail.visitNumber > 1" xs12>
              <v-checkbox
                v-model="engineerVisitDetail.canChargeCallout"
                :disabled="
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Rejected] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.ReAssigned]
                "
                name="CanChargeCallout"
                :hide-details="true"
                label="Can Charge Callout"
                color="primary"
                class="que-checkbox full-width"
                @change="canChargeCalloutChange"
              ></v-checkbox>
            </v-flex>
            <v-flex
              v-if="
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.RunningLate] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnSite]
              "
              lg7
              md12
            >
              <v-checkbox
                v-model="engineerVisitDetail.isCheckForHealthAndSafety"
                :disabled="
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Rejected] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.ReAssigned]
                "
                name="HealthAndSafetyPolicy"
                :hide-details="true"
                label="Health And Safety Checks Completed"
                color="primary"
                class="que-checkbox full-width"
                @change="onHealthAndSafetyPolicyChange"
              ></v-checkbox>
            </v-flex>
            <v-flex
              v-if="
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.RunningLate] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnSite] &&
                getVisitHealthAndSafetyDetails
              "
              lg5
              md12
              text-lg-right
              text-md-left
            >
              <v-btn flat color="primary" class="ma-0 mr-0 mt-1 pa-0" @click.stop="ShowHealthAndSafetyCheckDetails">
                <v-icon>picture_as_pdf</v-icon>
                &nbsp; Show H&S Details
              </v-btn>
            </v-flex>
            <v-flex
              v-if="
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.RunningLate] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnSite] &&
                getBoilerBreakdownQA
              "
              lg7
              md12
            >
              <v-checkbox
                v-model="engineerVisitDetail.isGasSafetyCheckComplete"
                :disabled="
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Abandoned] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Rejected] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed] ||
                  engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.ReAssigned]
                "
                name="GasSafetyPolicy"
                :hide-details="true"
                label="Gas Safety Checks Completed"
                color="primary"
                class="que-checkbox full-width"
                @change="onGasSafetyCheckChange"
              ></v-checkbox>
            </v-flex>
            <v-flex
              v-if="
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.RunningLate] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnSite] &&
                getBoilerBreakdownQA &&
                getBoilerBreakdownQA.gasSafetyDetails
              "
              lg5
              md12
              text-lg-right
              text-md-left
            >
              <v-btn flat color="primary" class="ma-0 mr-0 mt-1 pa-0" @click.stop="showGasSafetyCheckDetails = true">
                <v-icon>picture_as_pdf</v-icon>
                &nbsp; Show Gas Safety Details
              </v-btn>
            </v-flex>
            <v-flex v-if="engineerBeforeJobPictureList.length > 0" xs12 mt-3>
              <PictureUpload
                :job-id="jobId"
                :picture-list="engineerBeforeJobPictureList"
                uploaded-by="EngineerBeforeJob"
              ></PictureUpload>
            </v-flex>
          </v-layout>
          <v-divider v-if="getBoilerBreakdownQA" class="my-2"></v-divider>
          <v-layout v-if="getBoilerBreakdownQA" row wrap mt-2>
            <v-flex xs12>
              <h3>Boiler Breakdown Question & Answer</h3>
              <div class="mt-2">
                <b>{{ getBoilerBreakdownQA.modelQuestion }}:</b>
                <span v-if="getBoilerBreakdownQA.modelAnswer">
                  {{ getBoilerBreakdownQA.modelAnswer }}
                </span>
                <span v-else>-</span>
              </div>
              <div class="mt-2">
                <b>{{ getBoilerBreakdownQA.makeQuestion }}:</b>
                <span v-if="getBoilerBreakdownQA.makeAnswer">
                  {{ getBoilerBreakdownQA.makeAnswer }}
                </span>
                <span v-else>-</span>
              </div>
              <div class="mt-2">
                <b>{{ getBoilerBreakdownQA.gcNumberQuestion }}:</b>
                <span v-if="getBoilerBreakdownQA.gcNumberAnswer">
                  {{ getBoilerBreakdownQA.gcNumberAnswer }}
                </span>
                <span v-else>-</span>
              </div>
            </v-flex>
          </v-layout>
          <v-divider
            v-if="
              getBoilerBreakdownQA &&
              engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Completed] &&
              engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] &&
              engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Rejected] &&
              engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Abandoned] &&
              engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.ReAssigned]
            "
            class="my-2"
          ></v-divider>
          <v-layout v-if="showAdditionalRequestButton" mt-2>
            <v-flex xs12 class="text-xs-right">
              <v-btn
                color="primary"
                class="ma-0 mr-2 btn-addAdditionalRequest"
                @click.stop="additionalRequestDialog = true"
              >
                Additional Request
              </v-btn>
            </v-flex>
          </v-layout>
          <v-divider v-if="getAdditionalRequestList.length > 0" class="my-2"></v-divider>
          <EngineerResourceRequestComponent
            v-if="getAdditionalRequestList.length > 0"
            :additional-request-list="getAdditionalRequestList"
            :job-id="jobId"
            :engineer-visit-detail="engineerVisitDetail"
            :parent-component="'EngineerVisitPreview'"
          ></EngineerResourceRequestComponent>
          <template v-if="engineerDuringJobPictureList.length">
            <v-divider class="mt-2 mb-3"></v-divider>
            <v-flex xs12 mt-3>
              <PictureUpload
                :job-id="jobId"
                :picture-list="engineerDuringJobPictureList"
                uploaded-by="DuringJobPhotos"
              ></PictureUpload>
            </v-flex>
          </template>
          <v-divider v-if="engineerAfterJobPictureList.length > 0" class="mt-2 mb-3"></v-divider>
          <PictureUpload
            v-if="engineerAfterJobPictureList.length > 0"
            :job-id="jobId"
            :picture-list="engineerAfterJobPictureList"
            uploaded-by="EngineerAfterJob"
          ></PictureUpload>

          <v-divider
            v-if="
              engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed]
                ? true
                : engineerVisitDetail.visitNote
            "
            class="my-2"
          ></v-divider>
          <v-layout
            v-if="
              engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed]
                ? true
                : engineerVisitDetail.visitNote
            "
            mt-2
          >
            <v-flex xs12>
              <v-icon>event_note</v-icon>
              &nbsp;
              <b>Site Notes:</b>
              <pre class="notes">{{ engineerVisitDetail.visitNote ? engineerVisitDetail.visitNote : '-' }}</pre>
              <v-btn
                v-if="showEditSiteNoteButton"
                icon
                class="ma-0 name-edit lighten-2 underline edit-site-note-btn"
                @click.stop="openOffSiteTimeDialog(engineerVisitDetail.visitCompletedAt)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-divider
            v-if="
              (getContractorEtaFrom !== null && getContractorEtaTo !== null) ||
              (engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.RunningLate] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.PolicyHolderAbsent] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay] &&
                engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnSite]) ||
              healthAndSafetySignatureUrl !== '' ||
              (emergency.followOnWorkRequired && emergency.followOnDescription !== '')
            "
            class="my-2"
          ></v-divider>

          <v-layout row wrap pr-2 customer-signature>
            <v-flex class="mb-2" :class="healthAndSafetySignatureUrl === '' ? 'lg12' : 'lg7'">
              <div v-if="getContractorEtaFrom !== null && getContractorEtaTo !== null" class="mb-2">
                <v-icon>date_range</v-icon>
                &nbsp;
                <b>Original ETA:</b>
                <span>
                  <span>{{ getContractorEtaFrom }}</span>
                  <b>To</b>
                  <span>{{ getContractorEtaTo }}</span>
                </span>
              </div>
              <div v-if="engineerVisitDetail.distanceToCustomerLocation" class="mb-2">
                <v-icon>directions_car</v-icon>
                <label>
                  &nbsp;
                  <b>Travel Distance:</b>
                  {{ engineerVisitDetail.distanceToCustomerLocation }} KM
                </label>
              </div>
              <div v-if="engineerVisitDetail.engineerOnTheWayAt && engineerVisitDetail.engineerOnSiteAt" class="mb-2">
                <v-icon>access_time</v-icon>
                <label>
                  &nbsp;
                  <b>Travel Time:</b>
                  {{ getTravelTime(engineerVisitDetail.engineerOnTheWayAt, engineerVisitDetail.engineerOnSiteAt) }}
                </label>
                &nbsp; &nbsp;
              </div>
              <div
                v-if="
                  engineerVisitDetail.visitStartedAt &&
                  engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.JobStarted] &&
                  engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Pending] &&
                  engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] &&
                  engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.ReAssigned]
                "
                class="mb-2"
              >
                <v-icon>date_range</v-icon>
                &nbsp;
                <b>Started At:</b>
                {{ getFormattedDate(engineerVisitDetail.visitStartedAt) }}
                <v-btn
                  v-if="showEditButtonsOnJobComplete(engineerVisitDetail.status)"
                  icon
                  class="ma-0 name-edit lighten-2 underline edit-start-time-btn"
                  @click.stop="openOnSiteTimeDialog(engineerVisitDetail.visitStartedAt)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </div>
              <div
                v-if="
                  engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.RunningLate] &&
                  engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.PolicyHolderAbsent] &&
                  engineerVisitDetail.status !== engineerJobVisitStatus[engineerJobVisitStatus.OnTheWay]
                "
                class="mb-2"
              >
                <v-flex>
                  <!-- TODO: added temporary check for undefined user name-->
                  <div
                    v-if="
                      engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Pending] ||
                      engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Cancelled] ||
                      engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.ReAssigned]
                    "
                  >
                    <v-icon>date_range</v-icon>
                    &nbsp;
                    <b
                      v-if="
                        getUserName(engineerVisitDetail.processByUserId) &&
                        getUserName(engineerVisitDetail.processByUserId) !== 'undefined' &&
                        getUserName(engineerVisitDetail.processByUserId) !== 'undefined undefined'
                      "
                    >
                      {{ getEngineerVisitStatus(engineerVisitDetail.status) }}
                      by {{ getUserName(engineerVisitDetail.processByUserId) }}
                    </b>
                    <b v-else>
                      {{ getEngineerVisitStatus(engineerVisitDetail.status) }}
                      by Engineer
                    </b>
                  </div>
                  <div
                    v-else-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed]"
                  >
                    <v-icon>date_range</v-icon>
                    &nbsp;
                    <b>
                      {{ getEngineerVisitStatus(engineerVisitDetail.status) }}
                      At:
                    </b>
                    {{ getFormattedDate(engineerVisitDetail.visitCompletedAt) }}
                    <v-btn
                      v-if="showEditButtonsOnJobComplete(engineerVisitDetail.status)"
                      icon
                      class="ma-0 name-edit lighten-2 underline edit-complete-time-btn"
                      @click="openOffSiteTimeDialog(engineerVisitDetail.visitCompletedAt)"
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </div>
                  <div v-else>
                    <div v-if="engineerVisitDetail.engineerOffSiteAt" class="mb-2">
                      <v-icon>date_range</v-icon>
                      &nbsp;
                      <b>Off Site At:</b>
                      {{ getFormattedDate(engineerVisitDetail.engineerOffSiteAt) }}
                    </div>
                    <v-icon>date_range</v-icon>
                    &nbsp;
                    <b>
                      {{ getEngineerVisitStatus(engineerVisitDetail.status) }}
                      At:
                    </b>
                    {{ getFormattedDate(engineerVisitDetail.processAt) }}
                  </div>
                </v-flex>
                <v-spacer></v-spacer>
              </div>
              <div v-if="engineerVisitDetail.abandonedAt && engineerVisitDetail.abandonNotes">
                <v-flex my-2>
                  <label>
                    &nbsp;
                    <b>Abandoned Reason:</b>
                    {{ engineerVisitDetail.description }}
                  </label>
                </v-flex>
                <v-flex class="abandonNotes">
                  <label>
                    &nbsp;
                    <b>Abandoned Notes:</b>
                    <pre>{{ engineerVisitDetail.abandonNotes }}</pre>
                  </label>
                </v-flex>
              </div>
            </v-flex>
            <v-flex>
              <v-flex v-if="healthAndSafetySignatureUrl !== ''" lg7 pl-3>
                <div class="text-xs-center">
                  <p>Signed as Complete by Customer</p>
                  <div class="signature-img">
                    <img v-if="healthAndSafetySignatureUrl !== ''" :src="healthAndSafetySignatureUrl" />
                    <img v-else src="/img/signature.png" alt="signature" />
                  </div>
                </div>
              </v-flex>
              <v-flex v-if="!healthAndSafetySignatureUrl" lg7 pl-3>
                <div class="text-xs-center">
                  <p>Customer not present to sign</p>
                </div>
              </v-flex>
              <v-flex v-if="disclaimerSignatureUrl !== ''" lg7 pl-3>
                <div class="text-xs-center">
                  <p>Job Disclaimer Complete by Customer</p>
                  <div class="signature-img">
                    <img v-if="disclaimerSignatureUrl !== ''" :src="disclaimerSignatureUrl" />
                    <img v-else src="/img/signature.png" alt="signature" />
                  </div>
                </div>
              </v-flex>
              <v-flex v-if="!disclaimerSignatureUrl" lg7 pl-3>
                <div class="text-xs-center">
                  <p>No Disclaimer Signature Present</p>
                </div>
              </v-flex>
            </v-flex>
          </v-layout>
          <v-layout v-if="emergency.followOnWorkRequired && emergency.followOnDescription !== ''" row wrap px-2>
            <v-flex xs12>
              <v-divider class="mb-3 mt-2"></v-divider>
              <label for=""><b>Follow on Work Description</b></label>
              <p class="mt-1">{{ emergency.followOnDescription }}</p>
            </v-flex>
          </v-layout>
          <v-divider
            v-if="
              (!emergency.followOnDescription || !engineerJobVisitStatus[engineerJobVisitStatus.Completed]) &&
              engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.JobStarted]
            "
            class="mt-2 mb-3"
          ></v-divider>
          <v-flex xs12 class="text-xs-right">
            <!--<v-btn color="primary" v-if="jobType === 'HE' && !emergency.followOnDescription && engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed]" @click="follwOnWorkDialog = true">Follow on work</v-btn>-->
            <v-btn
              v-if="
                jobType === 'SI' &&
                engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.Completed]
              "
              color="primary"
              @click="siRevisitDialog = true"
            >
              Revisit
            </v-btn>
            <v-btn
              v-if="
                engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.JobStarted] ||
                engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.PolicyHolderAbsent]
              "
              color="primary"
              @click="completeVisit"
            >
              Visit Complete
            </v-btn>
            <v-btn
              v-if="engineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.JobStarted]"
              class="red white--text"
              @click="openvisitAbandonedDialog"
            >
              Abandon Visit
            </v-btn>
          </v-flex>
        </v-flex>
      </v-layout>
      <!-- dialog - show engineer's live location -->
      <v-dialog v-if="showLiveLocation" v-model="showLiveLocation" :fullscreen="true" persistent>
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Live Location</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="showLiveLocation = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-layout>
            <div class="map">
              <Map
                :map-id="new Date().getTime().toString()"
                :source-latitude="latitude"
                :source-longitude="longitude"
                :destination-address="engineerVisitDetail.address"
                :zoom-level="15"
                :previous-latitude="previousLatitude ? previousLatitude : latitude"
                :previous-longitude="previousLongitude ? previousLongitude : longitude"
                :heading-magnetic-north="headingMagneticNorth"
                :show-live-location="true"
                :show-fullscreen-control="false"
                :engineer-job-start-latitude="
                  engineerVisitDetail.jobStartLatitude ? engineerVisitDetail.jobStartLatitude : null
                "
                :engineer-job-start-longitude="
                  engineerVisitDetail.jobStartLongitude ? engineerVisitDetail.jobStartLongitude : null
                "
                style="height: 1000px"
              ></Map>
            </div>
          </v-layout>
        </v-card>
      </v-dialog>
      <!-- dialog - material request -->
      <v-dialog
        v-if="additionalRequestDialog"
        v-model="additionalRequestDialog"
        max-width="650"
        persistent
        content-class="v-dialog--scrollable"
      >
        <AdditionalRequest
          :job-id="jobId"
          :item-id="itemId"
          @closeAdditionalRequestDialog="closeAdditionalRequestDialog"
        ></AdditionalRequest>
      </v-dialog>
      <!-- dialog - engineer visit complete -->
      <v-dialog
        v-if="visitCompletedDialog"
        v-model="visitCompletedDialog"
        max-width="450"
        persistent
        content-class="v-dialog--scrollable"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Visit Complete Time</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeVisitCompletedDialog">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="scroll-content-dialog px-4 pt-4">
            <v-layout wrap>
              <v-flex xs12 pr-2>
                <DateTimePicker
                  ref="dateTimePickerEmgComplete"
                  :date-time.sync="visitCompletedAt"
                  :is-static-location="false"
                  place-holder-text="Select visiting complete date"
                  :is-current-time="true"
                  :is-validation-required="true"
                  :show-present-time-icon="true"
                  :min-date="emergencyCreatedAtDate"
                  :min-time="dateMinTime"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea v-model.trim="siteNote" label="Site Note" rows="5" name="siteNote"></v-textarea>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="closeVisitCompletedDialog">Close</v-btn>
            <v-btn
              color="primary"
              :loading="onSubmitLoading"
              :disabled="onSubmitLoading"
              class="mr-0"
              @click.native="visitCompleted"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog - follow on work required -->
      <v-dialog
        v-if="follwOnWorkDialog"
        v-model="follwOnWorkDialog"
        max-width="650"
        persistent
        content-class="v-dialog--scrollable"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Follow On Work</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="follwOnWorkDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-3 scroll-content-dialog">
            <v-layout>
              <v-flex xs12>
                <v-textarea
                  v-model="follwOnWorkModel.followOnDescription"
                  v-validate="'required'"
                  label="Description"
                  rows="5"
                  required
                  data-vv-scope="formReference"
                  data-vv-name="Description"
                  :error-messages="errors.collect('Description')"
                  class="required"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="follwOnWorkDialog = false">Close</v-btn>
            <v-btn
              color="primary"
              class="mr-0"
              :disabled="onSubmitLoading"
              :loading="onSubmitLoading"
              @click="submitFollowOnWorkDetail"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog - add return visit type -->
      <v-dialog v-model="configureDropdownDialog" max-width="700" persistent content-class="v-dialog--scrollable">
        <ConfigureDropdown
          :record-type="configureDropdownType"
          @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
        ></ConfigureDropdown>
      </v-dialog>
      <!-- edit visit start time dialog -->
      <v-dialog
        v-if="addVisitStartTimeDialog && emergency"
        v-model="addVisitStartTimeDialog"
        max-width="450"
        persistent
        content-class="v-dialog--scrollable"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Visit Start Time</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeVisitStartedDialog">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="scroll-content-dialog px-4 pt-2">
            <v-layout wrap>
              <v-flex xs12 pt-2>
                <DateTimePicker
                  ref="firstAssignedAtUtc"
                  :date-time.sync="firstAssignedAtUtc"
                  :is-static-location="false"
                  place-holder-text="Select Start date"
                  :is-current-time="true"
                  :is-validation-required="true"
                  :show-present-time-icon="engineerVisitDetail.visitCompletedAt ? false : true"
                  :min-date="emergencyCreatedAtDate"
                  :max-date="emergencyCompletedAtDate"
                  :min-time="dateMinTime"
                  :max-time="dateMaxTime"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="closeVisitStartedDialog">Close</v-btn>
            <v-btn
              color="primary"
              class="mr-0"
              :disabled="addVisitStartTimeLoading"
              :loading="addVisitStartTimeLoading"
              @click.native="addVisitStartTime"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog - visit health and safety details -->
      <v-dialog
        v-if="showVisitHealthAndSafetyReportDialog"
        v-model="showVisitHealthAndSafetyReportDialog"
        max-width="1000"
        persistent
        content-class="v-dialog--scrollable"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Visit Health And Safety Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="showVisitHealthAndSafetyReportDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-3 scroll-content-dialog">
            <HealthSafetyQAReport
              :get-health-safety-q-a="getVisitHealthAndSafetyDetails"
              :job-id="jobId"
              :emergency="emergency"
              :engineer-visit-detail="engineerVisitDetail"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="showVisitHealthAndSafetyReportDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog - gas safety details -->
      <v-dialog
        v-if="showGasSafetyCheckDetails"
        v-model="showGasSafetyCheckDetails"
        max-width="700"
        persistent
        content-class="v-dialog--scrollable"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Gas Safety Check Report</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="showGasSafetyCheckDetails = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-3 scroll-content-dialog">
            <GasSafetyQAReport
              ref="refGasSafetyQAReport"
              :job-id="jobId"
              :item-id="itemId"
              :emergency-id="emergencyId"
              :engineer-name="engineerName"
              :from-contractor-details-view="false"
            ></GasSafetyQAReport>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-0"
              :disabled="generatePDFInProcess"
              :loading="generatePDFInProcess"
              @click="generateGasReport"
            >
              Generate PDF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog - engineer abandon visit -->
      <v-dialog
        v-if="visitAbandonedDialog"
        v-model="visitAbandonedDialog"
        max-width="650"
        persistent
        content-class="v-dialog--scrollable"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Visit Abandoned</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="visitAbandonedDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="scroll-content-dialog px-4 pt-4">
            <v-layout wrap>
              <v-flex>
                <v-select
                  v-model="abandonReason"
                  v-validate="'required'"
                  :items="abandonJobReasonList"
                  item-text="description"
                  item-value="description"
                  label="Reason"
                  required
                  :data-vv-scope="visitAbandonedScope"
                  data-vv-name="Reason"
                  :error-messages="errors.collect('Reason')"
                  class="required"
                  @change="onAbandonJobReasonChange"
                >
                  <template slot="item" slot-scope="data">
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                          {{ data.item.description }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12 pr-2>
                <v-textarea
                  v-model.trim="abandonNotes"
                  v-validate="'required'"
                  label="Note"
                  required
                  class="required"
                  :data-vv-scope="visitAbandonedScope"
                  data-vv-name="Note"
                  :error-messages="errors.collect('Note')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" @click.native="visitAbandonedDialog = false">Close</v-btn>
            <v-btn
              color="primary"
              :loading="onSubmitLoading"
              :disabled="onSubmitLoading"
              class="mr-0"
              @click.native="visitAbandoned"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- SI revisit request -->
      <SIRevisit
        v-if="siRevisitDialog"
        ref="siRevisitRef"
        :job-id="jobId"
        :engineer-visit-detail="engineerVisitDetail"
        :return-visit-type-list="returnVisitTypeList"
        @openConfigureDropDown="openConfigureDropdown"
        @closeReturnVisitDialog="siRevisitDialog = false"
      ></SIRevisit>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import Map from '@/components/Map.vue'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import {
  EngineerJobVisitStatus,
  JobVisitStatus,
  EngineerRequestType,
  EngineeringRequestCategory,
  JobType,
} from '@/common/enums'
import EngineerController from '@/api/engineerController'
import EngineerModel from '@/models/contractor/EngineerModel'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'
import UserModel from '@/models/user/UserModel'
import PictureUpload from '@/components/timeline/PictureUpload.vue'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import JobController from '@/api/jobController'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import UpdateEmergencyFollowOnWorkModel from '@/models/requests/UpdateEmergencyFollowOnWorkModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import UpdateHealthAndSafetyForJob from '@/models/requests/UpdateHealthAndSafetyForJob'
import UpdateEngineerJobToComplete from '@/models/requests/UpdateEngineerJobToComplete'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import { RecordType } from '@/common/enums'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import EngineerResourceRequestComponent from '@/components/EngineerResourceRequestComponent.vue'
import UpdateCanChargeCalloutForVisit from '@/models/requests/UpdateCanChargeCalloutForVisit'
import BoilerBreakdownQA from '@/models/claim/BoilerBreakdownQA'
import UpdateEngineerJobVisitStatus from '@/models/requests/UpdateEngineerJobVisitStatus'
import VisitHealthAndSafetyProcess from '../../models/claim/VisitHealthAndSafetyProcessModel'
import jsPDF from 'jspdf'
import store from '@/store'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import DocumentController from '@/api/documentController'
import UpdateJobOffsiteTime from '@/models/requests/UpdateJobOffsiteTimeModel'
import UpdateGasSafetyForJob from '@/models/requests/UpdateGasSafetyForJob'
import Job from '../../models/Job'
import SIRevisit from '@/components/SIRevisit.vue'
import HealthSafetyQAReport from '@/components/HealthSafetyQAReport.vue'
import GasSafetyQAReport from '@/components/GasSafetyQAReport.vue'
import eventBus from '@/common/bus'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import SessionController from '@/api/sessionController'
import AdditionalRequest from '@/components/timeline/AdditionalRequest.vue'
import { imageExists } from '@/common/images'
import { JobStatus } from '@/models/types'

@Component({
  components: {
    Map,
    PictureUpload,
    DateTimePicker,
    ConfigureDropdown,
    EngineerResourceRequestComponent,
    SIRevisit,
    HealthSafetyQAReport,
    GasSafetyQAReport,
    AdditionalRequest,
  },
})
export default class EngineerVisitPreview extends TimeLineItemBase {
  private showLiveLocation = false
  private engineerJobVisitStatus = EngineerJobVisitStatus
  private engineerType = ''
  private engineer: EngineerModel | null = new EngineerModel()
  private isLoading = true
  private latitude: number | null = null
  private longitude: number | null = null
  private interval: any
  private healthAndSafetySignatureUrl = ''
  private disclaimerSignatureUrl = ''
  private visitCompletedDialog = false
  private additionalRequestDialog = false
  private visitCompletedAt: moment.Moment | null = null
  private headers: any = []
  private follwOnWorkDialog = false
  private follwOnWorkModel: UpdateEmergencyFollowOnWorkModel = new UpdateEmergencyFollowOnWorkModel()
  private requestedToAddTimeRequest = false
  private engineerRequestType = EngineerRequestType
  private emergencyIdTemp = ''
  private jobIdTemp = ''
  private additionalTimeRequestScope = 'additionalTimeRequestScope'
  private onSubmitLoading = false
  private requestedToAddReturnVisitRequest = false
  private configureDropdownDialog = false
  private configureDropdownType = ''
  private returnVisitTypeList: MasterRecord[] = []
  private firstAssignedAtUtc: moment.Moment | null = null
  private addVisitStartTimeDialog = false
  private addVisitStartTimeLoading = false
  private showVisitHealthAndSafetyReportDialog = false
  private generatePDFInProcess = false
  private marginsForPdf = { top: 70, bottom: 40, left: 30, width: 550 }
  private contractor: ContractorModel | null = null
  private completedJobStatus: JobStatus = 'Completed'
  private timeUpdateDialog = false
  private emergencyCreatedAtDate = ''
  private dateMinTime = ''
  private emergencyCompletedAtDate = ''
  private dateMaxTime = ''
  private visitAbandonedDialog = false
  private visitAbandonedScope = 'visitAbandonedScope'
  private abandonJobReasonList: MasterRecord[] = []
  private abandonReason = ''
  private abandonNotes = ''
  private jobType: JobType = 'HE'
  private siRevisitDialog = false
  private engineerName = ''
  private showGasSafetyCheckDetails = false
  private previousLatitude: number | null = null
  private previousLongitude: number | null = null
  private headingMagneticNorth = 0
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('engineerLiveLocation')
  private siteNote = ''
  private openAdditionalRequestDialog = false

  private get engineerVisitDetail(): EngineerVisitDetail {
    return storeGetters.getEngineerVisitDetail(this.jobId, this.itemId)
  }

  private get getBoilerBreakdownQA(): BoilerBreakdownQA {
    const details = storeGetters.getBoilerBreakdownQA(this.jobId, this.engineerVisitDetail.id)
    return details
  }

  private created() {
    this.setJobType()
    EngineerController.GetEngineerDetails(this.engineerVisitDetail.engineerId)
      .then((res: EngineerModel | null) => {
        if (res !== null) {
          this.engineer = res
          this.engineerName = this.engineer.firstName + ' ' + this.engineer.lastName
          const trade: EngineerAvailabilityModel =
            (this.engineer.engineerAvailability || []).find(
              (e) => e.tradeId === parseInt(this.engineerVisitDetail.tradeId, 10)
            ) || new EngineerAvailabilityModel()
          this.engineerType = trade.description
          this.isLoading = false
          if (this.engineerVisitDetail && this.engineerVisitDetail.customerSignatureFileURL) {
            const healthAndSafetySignatureUrl: string = this.engineerVisitDetail.customerSignatureFileURL.substring(
              this.engineerVisitDetail.customerSignatureFileURL.indexOf(this.jobId)
            )
            this.GetSASTokenForHealthAndSafetySignatureBlob(healthAndSafetySignatureUrl)
          }

          if (this.engineerVisitDetail && this.engineerVisitDetail.customerDisclaimerSignOffSignatureFileURL) {
            const disclaimerSignatureUrl: string =
              this.engineerVisitDetail.customerDisclaimerSignOffSignatureFileURL.substring(
                this.engineerVisitDetail.customerDisclaimerSignOffSignatureFileURL.indexOf(this.jobId)
              )

            this.GetSASTokenForDisclaimerSignatureBlob(disclaimerSignatureUrl)
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading engineer detail, please try again', true)
        this.isLoading = false
      })
    // code for -> get contractor 'isIndependent' flag value and based on it restrict ability to see engineer phone number
    // api call to get assigned contractor detail
    const contractorId =
      this.engineerVisitDetail && this.engineerVisitDetail.contractorId ? this.engineerVisitDetail.contractorId : ''
    ContractorController.GetContractorProfileDetail(contractorId).then((res: ContractorModel | null) => {
      if (res) {
        this.contractor = res
      }
    })
  }

  private mounted() {
    this.visitCompletedAt = null
  }

  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private getUserName(id: string) {
    const user: UserModel | undefined = this.getUsers.find((e) => e.id === id)
    if (user !== undefined) {
      return user.displayValue
    }
  }

  private get getUsers(): UserModel[] {
    return storeGetters.getUsers()
  }

  private getEngineerVisitStatus(engineerStatus: string): string {
    if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.Pending]) {
      return 'Assigned'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.RunningLate]) {
      return 'Running Late'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.PolicyHolderAbsent]) {
      return 'Customer Not Present'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.JobStarted]) {
      return 'Job Started'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.OnTheWay]) {
      return 'On The Way'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.OnSite]) {
      return 'Reached On Job Site'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.ReAssigned]) {
      return 'Reassigned'
    } else {
      return engineerStatus
    }
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  @Watch('showLiveLocation')
  private showLiveLocationChange() {
    if (this.showLiveLocation) {
      eventBus.$emit('showEngineerLiveLocation', true)
      this.latitude = this.latitude
        ? this.latitude
        : this.engineerVisitDetail && this.engineerVisitDetail.latitude
        ? this.engineerVisitDetail.latitude
        : 0
      this.longitude = this.longitude
        ? this.longitude
        : this.engineerVisitDetail && this.engineerVisitDetail.longitude
        ? this.engineerVisitDetail.longitude
        : 0
      if (this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.OnTheWay]) {
        this.signalRHub.addHandler(
          'updateEngineerLocation',
          async (latitude: number | null, longitude: number | null, headingMagneticNorth: number) => {
            // when show live location dialog is open, update engineer live location
            this.previousLatitude = this.latitude ? this.latitude : 0
            this.previousLongitude = this.longitude ? this.longitude : 0

            this.latitude = latitude
            this.longitude = longitude
            this.headingMagneticNorth = headingMagneticNorth
          }
        )
        this.signalRHub.connect()
      }
    } else {
      eventBus.$emit('showEngineerLiveLocation', false)
      if (this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.OnTheWay]) {
        if (this.signalRHub != null) {
          this.signalRHub.disconnect()
        }
        SessionController.RemoveUserFromEngineerLiveLocationUpdate(this.engineerVisitDetail.engineerId)
      }
    }
  }

  private onHealthAndSafetyPolicyChange() {
    const updateHealthAndSafetyForJob: UpdateHealthAndSafetyForJob = new UpdateHealthAndSafetyForJob()
    updateHealthAndSafetyForJob.jobId = this.engineerVisitDetail.jobId
    updateHealthAndSafetyForJob.id = this.engineerVisitDetail.id
    updateHealthAndSafetyForJob.isCheckForHealthAndSafety = this.engineerVisitDetail.isCheckForHealthAndSafety
    JobController.UpdateHealthAndSafetyForJob(updateHealthAndSafetyForJob)
      .then((res: boolean) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'Error updating health and safety detail for job, please try again', true)
          return
        }
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error updating health and safety detail for job, please try again', true)
      })
  }

  private onGasSafetyCheckChange() {
    const updateGasSafetyForJob: UpdateGasSafetyForJob = new UpdateGasSafetyForJob()
    updateGasSafetyForJob.jobId = this.engineerVisitDetail.jobId
    updateGasSafetyForJob.id = this.engineerVisitDetail.id
    updateGasSafetyForJob.isGasSafetyCheckComplete = this.engineerVisitDetail.isGasSafetyCheckComplete
    JobController.UpdateGasSafetyForJob(updateGasSafetyForJob)
      .then((res: boolean) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'Error updating gas safety detail for job, please try again', true)
          return
        }
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error updating gas safety detail for job, please try again', true)
      })
  }

  private get items(): PictureUploadModel[] {
    if (this.jobId && this.emergency) {
      return storeGetters.getClaimPicturesByVisitId(this.jobId, this.engineerVisitDetail.id)
    }
    return []
  }

  private get engineerBeforeJobPictureList(): PictureUploadModel[] {
    return this.items.filter(
      (a: PictureUploadModel) => a.uploadedBy === 'EngineerBeforeJob' && a.visitId === this.engineerVisitDetail.id
    )
  }

  private get engineerAfterJobPictureList(): PictureUploadModel[] {
    return this.items.filter(
      (a: PictureUploadModel) => a.uploadedBy === 'EngineerAfterJob' && a.visitId === this.engineerVisitDetail.id
    )
  }

  private get policyHolderNotPresentPicture(): PictureUploadModel[] {
    return this.items.filter(
      (a: PictureUploadModel) => a.uploadedBy === 'PolicyHolderMissing' && a.visitId === this.engineerVisitDetail.id
    )
  }

  private get engineerDuringJobPictureList(): PictureUploadModel[] {
    return this.items.filter(
      (a: PictureUploadModel) => a.uploadedBy === 'DuringJobPhotos' && a.visitId === this.engineerVisitDetail.id
    )
  }

  private GetSASTokenForHealthAndSafetySignatureBlob(url: string) {
    DocumentController.GetSASTokenForRecordingUrl(url).then((res: string | null) => {
      if (res && typeof res === 'string') {
        this.healthAndSafetySignatureUrl = res
      } else {
        this.healthAndSafetySignatureUrl = ''
      }
    })
  }

  private GetSASTokenForDisclaimerSignatureBlob(url: string) {
    DocumentController.GetSASTokenForRecordingUrl(url).then((res: string | null) => {
      if (res && typeof res === 'string') {
        this.disclaimerSignatureUrl = res
      } else {
        this.disclaimerSignatureUrl = ''
      }
    })
  }

  private get getContractorEtaTo() {
    if (this.getAssignedContractor && this.getAssignedContractor.etaTo) {
      return Shared.getFormatedDate(
        moment(this.getAssignedContractor.etaTo),
        Store.Instance.state.Environment.DateTimeFormat
      )
    }
    return null
  }

  private get getContractorEtaFrom() {
    if (this.getAssignedContractor && this.getAssignedContractor.etaFrom) {
      return Shared.getFormatedDate(
        moment(this.getAssignedContractor.etaFrom),
        Store.Instance.state.Environment.DateTimeFormat
      )
    }
    return null
  }

  private get getAssignedContractor(): ContractorAppointedModel | undefined {
    if (this.job && this.engineerVisitDetail) {
      return this.job.contractorAppointed.find(
        (e) =>
          e.forEmergencyTypeId === this.engineerVisitDetail.forEmergencyTypeId &&
          e.status === JobVisitStatus[JobVisitStatus.Completed]
      )
    }

    return undefined
  }

  private closeAdditionalRequestDialog() {
    this.additionalRequestDialog = false
  }

  private async validate(scope: string): Promise<boolean> {
    let result = true
    const validation: boolean = await this.$validator.validateAll(scope)

    let dateTimePickerEmgCompleteResult = true
    const dateTimePickerEmgComplete = this.$refs.dateTimePickerEmgComplete as DateTimePicker
    if (dateTimePickerEmgComplete) {
      dateTimePickerEmgCompleteResult = await dateTimePickerEmgComplete.$validator.validateAll()
    }
    // set focus to non validate field
    if (!validation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = validation
    }
    if (dateTimePickerEmgComplete && !dateTimePickerEmgCompleteResult) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = dateTimePickerEmgCompleteResult
    }
    return result
  }

  private submitFollowOnWorkDetail() {
    this.validate('formReference')
      .then((result: boolean) => {
        if (result) {
          this.onSubmitLoading = true
          this.follwOnWorkModel.jobId = this.jobId
          this.follwOnWorkModel.emergencyId = this.emergencyId
          JobController.UpdateEmergencyFollowOnWork(this.follwOnWorkModel)
            .then((res: boolean) => {
              if (res) {
                this.follwOnWorkDialog = false
              }
              this.onSubmitLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error updating emergency follow on work, please try again', true)
              this.onSubmitLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private get getAdditionalRequestList(): EngineerRequestModel[] | undefined {
    if (this.jobId && this.emergencyId) {
      let engineerRequest: EngineerRequestModel[] | undefined = storeGetters.getEngineerRequestForEmergency(
        this.jobId,
        this.engineerVisitDetail.id
      )
      engineerRequest = engineerRequest.filter(
        (x) => x.requestCategory === EngineeringRequestCategory[EngineeringRequestCategory.Resource]
      )
      return Shared.SortData(engineerRequest, 'createdAt').reverse()
    }
    return undefined
  }

  private visitCompleted() {
    this.validate('dateTimePickerEmgComplete')
      .then((result: boolean) => {
        if (result) {
          this.onSubmitLoading = true
          if (this.timeUpdateDialog) {
            this.onCompletedDateUpdateOfCompletedVisit()
          } else {
            this.visitCompleteOnFirstSave()
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private visitCompleteOnFirstSave() {
    const updateEngineerJobToComplete: UpdateEngineerJobToComplete = new UpdateEngineerJobToComplete()
    updateEngineerJobToComplete.id = this.engineerVisitDetail.id
    updateEngineerJobToComplete.jobId = this.engineerVisitDetail.jobId
    updateEngineerJobToComplete.status = EngineerJobVisitStatus[EngineerJobVisitStatus.Completed]
    updateEngineerJobToComplete.customerName = this.engineerVisitDetail.customerName
    updateEngineerJobToComplete.visitNote = this.siteNote
    if (this.visitCompletedAt) {
      updateEngineerJobToComplete.processAt = moment(this.visitCompletedAt)
    }
    JobController.UpdateEngineerJobToComplete(updateEngineerJobToComplete)
      .then((res: boolean) => {
        if (res) {
          this.visitCompletedDialog = false
          this.siteNote = ''
        }
        this.onSubmitLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error completing engineer visit, please try again', true)
        this.onSubmitLoading = false
      })
  }

  private onCompletedDateUpdateOfCompletedVisit() {
    const updateVisitCompletionDate: UpdateJobOffsiteTime = new UpdateJobOffsiteTime()
    updateVisitCompletionDate.id = this.engineerVisitDetail.id
    updateVisitCompletionDate.jobId = this.engineerVisitDetail.jobId
    updateVisitCompletionDate.visitStartedAt = null
    updateVisitCompletionDate.visitNote = this.siteNote
    if (this.visitCompletedAt) {
      updateVisitCompletionDate.visitCompletedAt = moment(this.visitCompletedAt)
    }
    JobController.UpdateJobOffsiteTime(updateVisitCompletionDate)
      .then((res: boolean) => {
        if (res) {
          this.visitCompletedDialog = false
          this.siteNote = ''
        }
        this.onSubmitLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating job offsite time, please try again', true)
        this.onSubmitLoading = false
      })
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private onAbandonJobReasonChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.AbandonVisitReason]
      this.configureDropdownDialog = true
    }
  }

  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    if (this.configureDropdownType === RecordType[RecordType.AbandonVisitReason]) {
      this.configureDropdownType = ''
      this.abandonReason = ''
      const deletedRecord: MasterRecord | undefined = this.abandonJobReasonList.find((e) => e.isDeleted === true)
      this.abandonJobReasonList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.abandonJobReasonList.push(deletedRecord)
      }
      if (this.isUserRoleAdministrator) {
        const abandonJobReason: any = []
        abandonJobReason.description = 'Configure'
        this.abandonJobReasonList.push(abandonJobReason)
      }
    }
  }

  private canChargeCalloutChange() {
    const updateCanChargeCalloutForVisit: UpdateCanChargeCalloutForVisit = new UpdateCanChargeCalloutForVisit()
    updateCanChargeCalloutForVisit.jobId = this.engineerVisitDetail.jobId
    updateCanChargeCalloutForVisit.visitId = this.engineerVisitDetail.id
    updateCanChargeCalloutForVisit.canChargeCallout = this.engineerVisitDetail.canChargeCallout
    JobController.UpdateCanChargeCalloutForVisit(updateCanChargeCalloutForVisit)
      .then((res: boolean) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'Error updating can charge callout, please try again', true)
          return
        }
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error updating can charge callout, please try again', true)
      })
  }

  private get getVisitHealthAndSafetyDetails(): VisitHealthAndSafetyProcess | undefined {
    const visitHealthAndSafetyProcess = storeGetters.getVisitHealthAndSafetyProcessForEmergency(
      this.jobId,
      this.engineerVisitDetail.id
    )
    if (visitHealthAndSafetyProcess) {
      return visitHealthAndSafetyProcess
    }
    return undefined
  }

  private ShowHealthAndSafetyCheckDetails() {
    if (this.getVisitHealthAndSafetyDetails) {
      if (this.getVisitHealthAndSafetyDetails.hasSignOff && this.getVisitHealthAndSafetyDetails.signOffSignatureURL) {
        // if has sign off, check image expired or not
        this.imageUrlCheck(this.getVisitHealthAndSafetyDetails.signOffSignatureURL)
      } else {
        this.showVisitHealthAndSafetyReportDialog = true
      }
    }
  }

  private async imageUrlCheck(signOffSignatureURL: any) {
    const url: string = signOffSignatureURL
    const exists = await imageExists(url)
    if (exists) {
      this.showVisitHealthAndSafetyReportDialog = true
      return
    }

    if (signOffSignatureURL.indexOf('?') > 0) {
      return
    }
    // if image expired, call blob and get new token
    const fileUrl = signOffSignatureURL.substring(signOffSignatureURL.indexOf(this.jobId))
    try {
      const res = await DocumentController.GetSASTokenForRecordingUrl(fileUrl)
      if (res && typeof res === 'string' && this.getVisitHealthAndSafetyDetails) {
        this.getVisitHealthAndSafetyDetails.signOffSignatureURL = res
        this.showVisitHealthAndSafetyReportDialog = true
      }
    } catch {
      eventBus.$emit('errorHandler', 'Error loading engineer signature, please try again', true)
    }
  }

  private changeVisitStartTime() {
    this.emergencyCreatedAtDate = this.emergency
      ? moment(this.emergency.firstNoticedAt).format(DateTimePicker.DATE_FORMAT)
      : moment().format(DateTimePicker.DATE_FORMAT)
    this.firstAssignedAtUtc = null
    this.addVisitStartTimeDialog = true
  }

  private async validateVisitStartTime(): Promise<boolean> {
    let result = true
    const subs = this.$refs.firstAssignedAtUtc as DateTimePicker
    result = await subs.$validator.validateAll()
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private addVisitStartTime() {
    this.validateVisitStartTime()
      .then((result: boolean) => {
        if (result) {
          this.addVisitStartTimeLoading = true
          if (this.timeUpdateDialog) {
            this.onStartDateUpdateOfCompletedVisit()
          } else {
            this.addVisitStartTimeOnFirstSave()
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private addVisitStartTimeOnFirstSave() {
    const updateEngineerJobVisitStatus: UpdateEngineerJobVisitStatus = new UpdateEngineerJobVisitStatus()
    updateEngineerJobVisitStatus.id = this.engineerVisitDetail.id
    updateEngineerJobVisitStatus.jobId = this.jobId
    updateEngineerJobVisitStatus.status = EngineerJobVisitStatus[EngineerJobVisitStatus.JobStarted]
    updateEngineerJobVisitStatus.processAt = this.firstAssignedAtUtc
    JobController.UpdateEngineerJobVisitStatus(updateEngineerJobVisitStatus)
      .then((res: boolean) => {
        if (res) {
          this.addVisitStartTimeDialog = false
        }
        this.addVisitStartTimeLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating visit status, please try again', true)
        this.addVisitStartTimeLoading = false
      })
  }

  private onStartDateUpdateOfCompletedVisit() {
    const updateVisitStartedDate: UpdateJobOffsiteTime = new UpdateJobOffsiteTime()
    updateVisitStartedDate.id = this.engineerVisitDetail.id
    updateVisitStartedDate.jobId = this.engineerVisitDetail.jobId
    updateVisitStartedDate.visitCompletedAt = null
    if (this.firstAssignedAtUtc) {
      updateVisitStartedDate.visitStartedAt = moment(this.firstAssignedAtUtc)
    }
    JobController.UpdateJobOffsiteTime(updateVisitStartedDate)
      .then((res: boolean) => {
        if (res) {
          this.addVisitStartTimeDialog = false
        }
        this.addVisitStartTimeLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating job offsite time, please try again', true)
        this.addVisitStartTimeLoading = false
      })
  }

  private generateReport(id: string, desc: string) {
    this.generatePDFInProcess = true
    const pdf = new jsPDF('p', 'pt', 'a4')
    // set font size
    pdf.setFontSize(18)
    // generate pdf from html
    pdf.fromHTML(
      document.getElementById(id),
      this.marginsForPdf.left,
      this.marginsForPdf.top,
      { width: this.marginsForPdf.width },
      (dispose) => {
        // set header and footer
        this.headerFooterFormatting(pdf, pdf.internal.getNumberOfPages(), desc)
        // hide loader
        this.generatePDFInProcess = false
        // generate pdf
        pdf.save(
          id +
            '_' +
            (this.engineerVisitDetail && this.engineerVisitDetail.id ? this.engineerVisitDetail.id : this.jobId)
        )
      },
      this.marginsForPdf
    )
  }

  private headerFooterFormatting(doc, totalPages, desc) {
    for (let i = totalPages; i >= 1; i--) {
      doc.setPage(i)
      this.header(doc, desc)
      this.footer(doc, i, totalPages)
      doc.page++
    }
  }

  private header(doc, desc) {
    // set style for header
    doc.setFontSize(20)
    doc.setTextColor('#009688')
    doc.setFontStyle('normal')
    doc.text(desc + ' Report', this.marginsForPdf.left + 150, 30)
    doc.setLineCap(2)
    doc.line(3, 50, this.marginsForPdf.width + 43, 50) // horizontal line
  }

  private footer(doc, pageNumber, totalPages) {
    // set style for footer
    const str = 'Page ' + pageNumber + ' of ' + totalPages
    doc.setLineCap(1)
    doc.setFontSize(10)
    doc.text(str, this.marginsForPdf.left, doc.internal.pageSize.height - 20)
  }

  private generateGasReport() {
    const gasSafetyQAReportSub = this.$refs.refGasSafetyQAReport as GasSafetyQAReport
    if (gasSafetyQAReportSub) {
      gasSafetyQAReportSub.generateReport()
    }
  }

  private getTravelTime(engineerOnTheWayAt: moment.Moment | null, engineerOnSiteAt: moment.Moment | null): string {
    if (engineerOnTheWayAt !== null && engineerOnSiteAt !== null) {
      const startDate = moment(engineerOnTheWayAt)
      const endDate = moment(engineerOnSiteAt)
      const duration = moment.duration(endDate.diff(startDate))
      const seconds = duration.seconds()
      const minutes = duration.minutes()
      const hours = duration.hours()
      const days = duration.days()
      if (days > 0) {
        return days + ' Days ' + (hours > 0 ? hours + ' Hours ' : '') + (minutes > 0 ? minutes + ' Minutes' : '')
      } else if (hours > 0) {
        return hours + ' Hours ' + (minutes > 0 ? minutes + ' Minutes' : '')
      } else if (minutes > 0) {
        return minutes + ' Minutes ' + (seconds > 0 ? seconds + ' Seconds' : '')
      } else {
        return seconds + ' Seconds'
      }
    }
    return ''
  }

  private get minVisitCompleteDate(): string {
    if (this.engineerVisitDetail && this.engineerVisitDetail.visitStartedAt) {
      return moment(this.engineerVisitDetail.visitStartedAt).format(DateTimePicker.DATE_FORMAT)
    } else {
      return moment().format(DateTimePicker.DATE_FORMAT)
    }
  }

  private get canSeeEngineerPhoneNumber(): boolean {
    // if contractor is Independent show engineer phone number, else check deployment and workInProgress skill
    return this.contractor && this.contractor.isIndependent
      ? true
      : Store.Instance.state.SessionDetail.canSeeEngineerPhoneNumber
  }

  // Get list of abandon visit reason.
  private getAbandonJobReasonList(): void {
    const recordType: string = RecordType[RecordType.AbandonVisitReason]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.abandonJobReasonList = res.filter((e) => e.isDeleted === false)
        if (this.isUserRoleAdministrator) {
          const abandonJobReason: any = {}
          abandonJobReason.description = 'Configure'
          this.abandonJobReasonList.push(abandonJobReason)
        }
      }
    })
  }

  private openvisitAbandonedDialog() {
    this.getAbandonJobReasonList()
    this.visitAbandonedDialog = true
  }

  private visitAbandoned() {
    this.validate(this.visitAbandonedScope)
      .then((result: boolean) => {
        if (result) {
          this.onSubmitLoading = true
          const updateEngineerJobVisitStatus: UpdateEngineerJobVisitStatus = new UpdateEngineerJobVisitStatus()
          updateEngineerJobVisitStatus.id = this.engineerVisitDetail.id
          updateEngineerJobVisitStatus.jobId = this.jobId
          updateEngineerJobVisitStatus.status = EngineerJobVisitStatus[EngineerJobVisitStatus.Abandoned]
          updateEngineerJobVisitStatus.processAt = this.firstAssignedAtUtc
          updateEngineerJobVisitStatus.reason = this.abandonReason
          updateEngineerJobVisitStatus.notes = this.abandonNotes
          JobController.UpdateEngineerJobVisitStatus(updateEngineerJobVisitStatus)
            .then((res: boolean) => {
              if (res) {
                this.visitAbandonedDialog = false
              }
              this.onSubmitLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error updating visit status, please try again', true)
              this.onSubmitLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private showEditButtonsOnJobComplete(engineerStatus: string): boolean {
    if (
      engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed] &&
      this.canAmendOnOffSiteTimes &&
      !(this.engineerVisitDetail.clientInvoiceDetailId && this.engineerVisitDetail.contractorInvoiceDetailId)
    ) {
      return true
    }
    return false
  }

  private get canAmendOnOffSiteTimes(): boolean {
    return Store.Instance.state.SessionDetail.canAmendOnOffSiteTimes
  }

  private openOnSiteTimeDialog(processAt): void {
    if (this.emergency) {
      if (this.emergency.firstNoticedAt) {
        this.emergencyCreatedAtDate = moment(this.emergency.firstNoticedAt).format(DateTimePicker.DATE_FORMAT)
        this.dateMinTime = moment(this.emergency.firstNoticedAt).format(DateTimePicker.TIME_FORMAT)
        if (this.engineerVisitDetail && this.engineerVisitDetail.visitCompletedAt) {
          this.emergencyCompletedAtDate = moment(this.engineerVisitDetail.visitCompletedAt).format(
            DateTimePicker.DATE_FORMAT
          )
          this.dateMaxTime = moment(this.engineerVisitDetail.visitCompletedAt).format(DateTimePicker.TIME_FORMAT)
        } else {
          this.emergencyCompletedAtDate = ''
          this.dateMaxTime = ''
        }
      } else {
        this.emergencyCreatedAtDate = ''
        this.dateMinTime = ''
      }
    }

    this.firstAssignedAtUtc = moment(processAt)
    this.addVisitStartTimeDialog = true
    this.timeUpdateDialog = true
  }

  private openOffSiteTimeDialog(processAt): void {
    if (this.emergency) {
      if (this.engineerVisitDetail && this.engineerVisitDetail.visitStartedAt) {
        this.emergencyCreatedAtDate = moment(this.engineerVisitDetail.visitStartedAt).format(DateTimePicker.DATE_FORMAT)
        this.dateMinTime = moment(this.engineerVisitDetail.visitStartedAt).format(DateTimePicker.TIME_FORMAT)
      } else {
        this.emergencyCreatedAtDate = ''
        this.dateMinTime = ''
      }
    }

    this.visitCompletedAt = moment(processAt)
    this.visitCompletedDialog = true
    this.timeUpdateDialog = true
    this.siteNote = this.engineerVisitDetail!.visitNote ? this.engineerVisitDetail.visitNote : ''
  }

  private closeVisitCompletedDialog() {
    this.visitCompletedDialog = false
    this.timeUpdateDialog = false
    this.siteNote = ''
  }

  private closeVisitStartedDialog() {
    this.addVisitStartTimeDialog = false
    this.timeUpdateDialog = false
  }

  @Watch('firstAssignedAtUtc')
  @Watch('visitCompletedAt')
  private updateTime() {
    if (this.emergency) {
      const dateToCompare = this.addVisitStartTimeDialog ? this.firstAssignedAtUtc : this.visitCompletedAt
      const minDateToCompare = this.addVisitStartTimeDialog
        ? this.emergency.firstNoticedAt
        : this.engineerVisitDetail.visitStartedAt
        ? this.engineerVisitDetail.visitStartedAt
        : ''
      if (
        dateToCompare &&
        moment(dateToCompare).format(DateTimePicker.DATE_FORMAT) !==
          moment(minDateToCompare).format(DateTimePicker.DATE_FORMAT)
      ) {
        this.dateMinTime = ''
        if (this.addVisitStartTimeDialog) {
          this.dateMaxTime =
            dateToCompare && moment(dateToCompare).format(DateTimePicker.DATE_FORMAT) === this.emergencyCompletedAtDate
              ? moment(
                  this.engineerVisitDetail.visitCompletedAt ? this.engineerVisitDetail.visitCompletedAt : ''
                ).format(DateTimePicker.TIME_FORMAT)
              : ''
        }
      } else {
        this.dateMinTime = moment(minDateToCompare).format(DateTimePicker.TIME_FORMAT)
        this.dateMaxTime =
          this.addVisitStartTimeDialog &&
          dateToCompare &&
          moment(dateToCompare).format(DateTimePicker.DATE_FORMAT) === this.emergencyCompletedAtDate
            ? this.dateMaxTime
            : ''
      }
    } else {
      this.dateMinTime = ''
      this.dateMaxTime = ''
    }
  }

  private setJobType() {
    const job: Job | null = storeGetters.getJob(this.jobId)
    this.jobType = job!.jobType
  }

  private async GetRetrunVisitTypes(): Promise<MasterRecord[]> {
    const recordType: string = RecordType[RecordType.ReturnVisitType]
    this.configureDropdownType = RecordType[RecordType.ReturnVisitType]
    const returnVisitTypes = await MasterRecordController.GetMasterRecords(recordType)
    const record: MasterRecord = new MasterRecord()
    record.description = 'Configure'
    record.isDeleted = false
    returnVisitTypes.push(record)
    return returnVisitTypes
  }

  @Watch('siRevisitDialog')
  private fillReturnVisitTypeList() {
    if (this.siRevisitDialog && this.returnVisitTypeList.length === 0 && !this.additionalRequestDialog) {
      this.GetRetrunVisitTypes().then((res: MasterRecord[]) => {
        if (res.length > 0) {
          this.returnVisitTypeList = res
        }
      })
    }
  }

  private openConfigureDropdown() {
    this.configureDropdownType = RecordType[RecordType.ReturnVisitType]
    this.configureDropdownDialog = true
  }

  private get getSignalRConnectionStatus(): ConnectionState {
    // get signalr connection status
    return this.signalRHub.status
  }

  @Watch('getSignalRConnectionStatus')
  private updateSignalRGroup() {
    // connect to signalr group to get live location update
    if (
      this.getSignalRConnectionStatus === 'connected' &&
      this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.OnTheWay]
    ) {
      SessionController.AddUserToEngineerLiveLocationUpdate(this.engineerVisitDetail.engineerId)
    }
  }

  private completeVisit() {
    this.emergencyCreatedAtDate =
      this.engineerVisitDetail && this.engineerVisitDetail.visitStartedAt
        ? moment(this.engineerVisitDetail.visitStartedAt).format(DateTimePicker.DATE_FORMAT)
        : ''
    this.dateMinTime =
      this.engineerVisitDetail && this.engineerVisitDetail.visitStartedAt
        ? moment(this.engineerVisitDetail.visitStartedAt).format(DateTimePicker.TIME_FORMAT)
        : ''
    this.visitCompletedDialog = true
    this.siteNote = this.engineerVisitDetail!.visitNote ? this.engineerVisitDetail.visitNote : ''
  }

  private get showEngineerLocation() {
    return (
      this.engineerVisitDetail &&
      (this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.OnTheWay] ||
        this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.JobStarted] ||
        this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.PolicyHolderAbsent] ||
        this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed])
    )
  }

  private get showEditSiteNoteButton(): boolean {
    const canUpdateSiteNote: boolean =
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.invoicing >= 3 ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.workInProgress >= 3)
    if (
      canUpdateSiteNote &&
      this.engineerVisitDetail &&
      this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed] &&
      !(this.engineerVisitDetail.clientInvoiceDetailId && this.engineerVisitDetail.contractorInvoiceDetailId)
    ) {
      return true
    }
    return false
  }

  private get showAdditionalRequestButton() {
    return (
      this.job!.status !== this.completedJobStatus &&
      this.engineerVisitDetail &&
      !(this.engineerVisitDetail.clientInvoiceDetailId && this.engineerVisitDetail.contractorInvoiceDetailId) &&
      (this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.JobStarted] ||
        this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.PolicyHolderAbsent] ||
        this.engineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed])
    )
  }
}
</script>

<style scoped>
img.profile-img {
  width: 80px;
}
.btn-padding-zero >>> .v-btn__content {
  padding: 0px !important;
  padding-left: 5px !important;
}
.map {
  position: relative;
}
.engineer-img {
  border: 1px solid #dedede;
  height: 100%;
}
img.profile-img {
  width: 80px;
  object-fit: contain;
  height: 100px;
}
.signature-img img {
  width: 100%;
  max-width: 100px;
}
.item-qty >>> input {
  text-align: center;
}
.engineer-edit-btn {
  position: absolute;
  right: -8px;
  top: -16px;
}
.full-width {
  max-width: 100%;
}
.description {
  word-break: break-word;
}
.bold-select {
  font-weight: 600;
}
.hide {
  display: none;
}
.contractor-icon {
  max-width: 19px;
  position: relative;
  top: 2px;
  left: -2px;
}
.customer-contact-chkbox {
  pointer-events: none;
  margin-top: 0px;
}
</style>