<template>
  <div>
    <div class="mb-2"><h4>Save Water Mains Test</h4></div>
    <div v-if="item">
      <div v-if="item && item.periodListeningTest" class="mb-1">
        <label
          >Period listening test carried out with all available water services within property switched off:
        </label>
        <span>{{ item.periodListeningTest }}</span>
      </div>
      <div class="mb-1">
        <label>Any indication of leak detected by way of sound with stick placed on stop cock?: </label>
        <span>{{ item.isLeakDetected ? 'Yes' : 'No' }}</span>
      </div>
      <div class="mb-1">
        <label>
          When taps are used, toilets flushed or any other water demand appliance used,is there a noticeable flushing
          noise heard from the listening stick?:
        </label>
        <span>{{ item.isNoiseHeardFromListeningStick ? 'Yes' : 'No' }}</span>
      </div>
      <div v-if="item && item.status" class="mb-1">
        <label>Status: </label>
        <span>{{ item.status }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import CompleteWaterMainsTestRecord from '../../models/siteInvestigation/requests/CompleteWaterMainsTestRecord'

@Component
export default class CompleteWaterMainsTestRecordLog extends Vue {
  @Prop() private requestBody: any

  private get item(): CompleteWaterMainsTestRecord {
    return Object.assign(new CompleteWaterMainsTestRecord(), this.requestBody)
  }
}
</script>

<style scoped></style>
