<template>
  <div v-if="contractor" class="call-cnt-list">
    <v-card :class="getClassNames">
      <v-card-title class="px-2 pt-2 pb-0 full-width">
        <h4
          :class="
            getAssignedContractor && getAssignedContractor.status !== jobVisitStatus[jobVisitStatus.Unavailable]
              ? 'data-title secondary--text'
              : 'data-title'
          "
        >
          {{ contractor.companyName }}
        </h4>
        <v-spacer></v-spacer>
        <span class="ratings">
          <v-tooltip
            v-if="getPreferredContractor(contractor) && getPreferredContractor(contractor) !== 'Unverified'"
            top
          >
            <template #activator="{ on }">
              <span class="badges-icon" v-on="on">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 471"
                  width="16"
                  height="13"
                  :fill="getContractorStatusIconColor"
                >
                  <path
                    id="Forma 1"
                    class="shp0"
                    d="M341.33 1L42.67 1C19.09 1 0.21 20.08 0.21 43.64L0 319.39C0 334.1 7.47 347.1 18.77 354.78L191.89 470L365.01 354.78C376.32 347.1 383.79 334.1 383.79 319.39L384 43.64C384 20.08 364.91 1 341.33 1ZM149.33 320.77L42.67 214.18L72.85 184.02L149.33 260.44L311.15 98.74L341.33 128.91L149.33 320.77Z"
                  ></path>
                  <path
                    id="Forma 1"
                    class="shp0"
                    d="M341.33 1L42.67 1C19.09 1 0.21 20.08 0.21 43.64L0 319.39C0 334.1 7.47 347.1 18.77 354.78L191.89 470L365.01 354.78C376.32 347.1 383.79 334.1 383.79 319.39L384 43.64C384 20.08 364.91 1 341.33 1ZM149.33 320.77L42.67 214.18L72.85 184.02L149.33 260.44L311.15 98.74L341.33 128.91L149.33 320.77Z"
                  ></path>
                  <path id="Shape 2" class="shp0" d="M53.77,206.77l-3.54,-3.54l-9,9l3.54,3.54z"></path>
                  <path id="Shape 3" class="shp0" d="M76.69,185.84l-3.38,-3.69l-24,22l3.38,3.69z"></path>
                  <path id="Shape 4" class="shp0" d="M147.17,263.71l3.65,-3.41l-72,-77l-3.65,3.41z"></path>
                  <path id="Shape 5" class="shp0" d="M40,294h107v-83h-107z"></path>
                  <path id="Shape 6" class="shp0" d="M89,214v0v36v0z"></path>
                  <path id="Shape 7" class="shp0" d="M43,337h156v-144h-156z"></path>
                  <path id="Shape 8" class="shp0" d="M55,228h66v-66h-66z"></path>
                  <path id="Shape 9" class="shp0" d="M245,257h75v-138h-75z"></path>
                  <path id="Shape 10" class="shp0" d="M173,299h108v-124h-108z"></path>
                  <path id="Shape 11" class="shp0" d="M200,208h72v-49h-72z"></path>
                  <path id="Shape 12" class="shp0" d="M276,170h69v-80h-69z"></path>
                </svg>
              </span>
            </template>
            <span>{{ getPreferredContractor(contractor) }} Contractor</span>
          </v-tooltip>

          <span v-if="hasAutoDeploymentOn(contractor.autoDeploymentType)">
            <v-tooltip
              v-if="
                contractor.autoDeploymentType === autoDeploymentTypeEnum[autoDeploymentTypeEnum.AutodeployToEngineer]
              "
              top
            >
              <template #activator="{ on }">
                <span class="badges-icon ml-1 autodeployToEngineer" v-on="on">
                  <svg
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="16px"
                    height="16px"
                    viewBox="0 0 535.806 535.807"
                    style="enable-background: new 0 0 535.806 535.807"
                    xml:space="preserve"
                    :fill="setAutoDeploymentOnColor"
                  >
                    <g>
                      <path
                        d="M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479 c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262 c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592 C474.31,507.654,477.982,415.242,440.956,373.932z"
                      />
                      <path
                        d="M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048 c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486 c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486 c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0 c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z"
                      />
                      <path
                        d="M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224 c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322 c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44 c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414 h-17.22L377.409,118.116z"
                      />
                      <path
                        d="M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95 v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546 c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262 C239.598,131.58,243.881,134.028,248.777,134.028z"
                      />
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </span>
              </template>
              <span>
                Auto Deployment To Engineers
                <span v-if="contractor.autoDeployAlwaysActive">for 24 hours</span>
                <span v-else>
                  from {{ contractor.autoDeployStartTime }} to
                  {{ contractor.autoDeployEndTime }}
                </span>
              </span>
            </v-tooltip>

            <v-tooltip v-else top>
              <template #activator="{ on }">
                <span class="badges-icon ml-1 autodeployToContractor" v-on="on">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    viewBox="0 0 297 297"
                    enable-background="new 0 0 297 297"
                    width="16px"
                    height="16px"
                    :fill="setAutoDeploymentOnColor"
                  >
                    <g>
                      <path
                        d="M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608 S116.193,117.216,148.51,117.216z"
                      />
                      <path
                        d="m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z"
                      />
                      <path
                        d="m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z"
                      />
                    </g>
                  </svg>
                </span>
              </template>
              <span>
                Auto Deployment To Contractor
                <span v-if="contractor.autoDeployAlwaysActive">for 24 hours</span>
                <span v-else>
                  from {{ contractor.autoDeployStartTime }} to
                  {{ contractor.autoDeployEndTime }}
                </span>
              </span>
            </v-tooltip>
          </span>

          <v-icon small color="grey">star</v-icon>
          {{ contractor.rating }}/5
        </span>
        <v-divider></v-divider>
      </v-card-title>
      <v-card-text class="pt-0 pb-2 px-2 cn-card-detail full-width">
        <template v-if="timeSlots.length > 0">
          <span>
            <i>
              <v-icon small>access_time</v-icon>
            </i>
            <span class="available-time">
              Weekdays -
              <template v-for="(item, index) in timeSlots">
                <span v-if="item.slotId > 0" :key="item.slotId">
                  {{ new Date(item.startTime).getHours() }} to
                  {{ new Date(item.endTime).getHours() === 0 ? 24 : new Date(item.endTime).getHours()
                  }}{{ index === timeSlots.length - 1 ? '' : ',' }}&nbsp;
                </span>
              </template>
            </span>
          </span>
          <br />
        </template>
        <template v-if="contractor.contactNumbers">
          <span v-for="(contact, index) in contractor.contactNumbers" :key="index">
            <i>
              <v-icon small>local_phone</v-icon>
            </i>
            <a class="grey--text darken-2--text">{{ contact.phoneNumber }}</a>
            <span class="caption grey--text">- {{ contact.label ? contact.label : 'Phone number' }}</span>
          </span>
        </template>
        <br />
        <span v-if="contractor.website">
          <i>
            <v-icon small>public</v-icon>
          </i>
          <a class="grey--text">{{ contractor.website }}</a>
        </span>
        <span v-if="contractor.distance">
          <i>
            <v-icon small>near_me</v-icon>
          </i>
          {{ (contractor.distance / 1000).toFixed(2) }} KM
        </span>
        <span
          v-if="
            getAssignedContractor &&
            !getAssignedContractor.isContractorReAttend &&
            getContractorEtaFrom &&
            getAssignedContractor.status !== jobVisitStatus[jobVisitStatus.Unavailable]
          "
        >
          <i>
            <img class="icon-img-tile" src="/img/user.svg" />
          </i>
          <b>ETA To:</b>
          {{ getContractorEtaFrom }}
          <b>
            <br />
            From
          </b>
          {{ getContractorEtaTo }}
        </span>
        <span
          v-else-if="
            getAssignedContractor &&
            getAssignedContractor.isContractorReAttend &&
            getContractorEtaFrom &&
            getAssignedContractor.status !== jobVisitStatus[jobVisitStatus.Unavailable]
          "
        >
          <i>
            <img class="icon-img-tile" src="/img/refresh-user.svg" />
          </i>
          <b>ETA To:</b>
          {{ getContractorEtaFrom }}
          <b>
            <br />
            From
          </b>
          {{ getContractorEtaTo }}
        </span>
        <div class="text-xs-right"></div>
      </v-card-text>
      <v-card-actions v-if="emergenciesIcon.length > 0" class="pa-1 grey lighten-2 show-icons full-width">
        <div v-for="(emergency, index) in emergenciesIcon" :key="index">
          <i class="icons">
            <v-tooltip top>
              <template #activator="{ on }">
                <img :src="emergency.fileURL" v-on="on" />
              </template>
              <span>{{ emergency.description }}</span>
            </v-tooltip>
          </i>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { JobVisitStatus, AutoDeploymentTypeEnum } from '@/common/enums'
import Shared from '@/common/shared'
import store from '@/store'
import moment from 'moment'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import PolicyHolderController from '@/api/policyHolderController'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import TimeSlot from '@/models/claim/TimeSlot'

@Component
export default class CallContractorTileView extends Vue {
  @Prop() private item: ContractorModel | null
  @Prop() private selected: boolean
  @Prop() private assignedContractors: ContractorAppointedModel[]
  @Prop() private status: string
  @Prop() private tradeId: number
  private jobVisitStatus = JobVisitStatus
  private emergenciesIcon: EmergencyModel[] = []
  private autoDeploymentTypeEnum = AutoDeploymentTypeEnum

  private created() {
    if (this.emergencies.length === 0) {
      this.setEmergencies()
    }
    this.getEmergenciesIcon()
  }

  private get emergencies() {
    return storeGetters.getEmergencies()
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        storeMutations.setEmergencies(res)
      }
    })
  }

  private get contractor(): ContractorModel | null {
    return this.item
  }

  private get getClassNames() {
    let classNames = ''

    if (this.selected) {
      classNames = 'card-active '
    }

    if (
      this.getAssignedContractor &&
      this.getAssignedContractor.status === JobVisitStatus[JobVisitStatus.Unavailable] &&
      this.getAssignedContractor.isUnavailable
    ) {
      classNames = classNames + 'grey lighten-3 grey--text'
    }
    return classNames
  }

  private get getAssignedContractor(): ContractorAppointedModel | null {
    const appointedContractor: ContractorAppointedModel | undefined = this.assignedContractors.find(
      (c) => c.contractorId === (this.item ? this.item.id : null)
    )
    if (appointedContractor) {
      return appointedContractor
    }
    return null
  }

  private get getContractorEtaTo() {
    if (this.getAssignedContractor && this.getAssignedContractor.etaTo) {
      return Shared.getFormatedDate(
        moment(this.getAssignedContractor.etaTo),
        store.Instance.state.Environment.DateTimeFormat
      )
    }
    return null
  }

  private get getContractorEtaFrom() {
    if (this.getAssignedContractor && this.getAssignedContractor.etaFrom) {
      return Shared.getFormatedDate(
        moment(this.getAssignedContractor.etaFrom),
        store.Instance.state.Environment.DateTimeFormat
      )
    }
    return null
  }
  @Watch('item')
  private onChange(newValue: any) {
    this.getEmergenciesIcon()
  }

  private getEmergenciesIcon() {
    if (this.emergencies.length > 0 && this.contractor && this.contractor.contractorAvailability) {
      const tradeIds: number[] = []

      this.contractor.contractorAvailability.forEach((a: ContractorTradeAvailabilityModel) => {
        if (tradeIds.indexOf(a.tradeId) < 0) {
          tradeIds.push(a.tradeId)
        }
      })

      this.emergenciesIcon = []
      this.emergencies.forEach((e: EmergencyModel) => {
        if (e.fileURL && e.emergencyDetails) {
          e.emergencyDetails.some((d: EmergencyDetailModel) => {
            if (d.tradeId && tradeIds.indexOf(d.tradeId) > -1) {
              this.emergenciesIcon.push(e)
              return true
            }
            return false
          })
        }
      })
    } else {
      this.emergenciesIcon = []
    }
  }

  private get timeSlots(): TimeSlot[] {
    if (this.contractor && this.contractor.contractorAvailability) {
      const availability: ContractorTradeAvailabilityModel | undefined = this.contractor.contractorAvailability.find(
        (e: ContractorTradeAvailabilityModel) => e.tradeId === this.tradeId
      )
      if (availability && availability.timeSlot && availability.timeSlot.length > 0) {
        return this.getAvailability(availability.timeSlot)
      } else {
        return []
      }
    }
    return []
  }

  private getAvailability(timeSlots: TimeSlot[]): TimeSlot[] {
    const availability: TimeSlot[] = []
    let isNewSlot = true
    let item: TimeSlot | null = null
    let slotId = 0
    let availableSlots: TimeSlot[] = []
    availableSlots = Object.assign([], availableSlots, timeSlots)
    availableSlots.forEach((slot: TimeSlot) => {
      if (isNewSlot) {
        item = new TimeSlot()
        this.setTimeSlot(item, slot)
        availability.push(item)
        isNewSlot = false
        slotId = item.slotId
      } else {
        if (item) {
          if (slot.slotId - slotId === 1) {
            item.endTime = slot.endTime
            slotId = slot.slotId
          } else {
            item = new TimeSlot()
            this.setTimeSlot(item, slot)
            availability.push(item)
            slotId = slot.slotId
          }
        }
      }
    })

    return availability
  }

  private setTimeSlot(newSlot: TimeSlot, existingSlot: TimeSlot) {
    newSlot.slotId = existingSlot.slotId
    newSlot.slot = existingSlot.slot
    newSlot.isDayTime = existingSlot.isDayTime
    newSlot.startTime = existingSlot.startTime
    newSlot.endTime = existingSlot.endTime
  }

  // set static color for contractor status icon
  private get getContractorStatusIconColor(): string {
    if (
      this.contractor &&
      this.getPreferredContractor(this.contractor) &&
      this.getPreferredContractor(this.contractor) !== 'Unverified'
    ) {
      let color = ''
      switch (this.getPreferredContractor(this.contractor)) {
        case 'Preferred':
          color = '#1c5cff'
          break
        case 'Approved':
          color = '#00c853'
          break
        case 'Platinum':
          color = '#f9a825'
          break
        default:
          break
      }
      return color
    }
    return ''
  }

  private hasAutoDeploymentOn(autoDeploymentType: string): boolean {
    if (
      autoDeploymentType !== undefined &&
      autoDeploymentType !== AutoDeploymentTypeEnum[AutoDeploymentTypeEnum.NoAutodeployment]
    ) {
      return true
    }
    return false
  }

  private get setAutoDeploymentOnColor(): string {
    const redColor = '#ff5252' // red color
    if (this.item) {
      const greenColor = '#4caf50' // red color
      // if auto deployment set to 24 hours set green color
      if (this.item.autoDeployAlwaysActive) {
        return greenColor
      }

      // if manual time selected, check the auto deployment time to toggle between call contractor and auto deployment button
      const currentTime = moment(new Date())
      const beginningTime = this.item.autoDeployStartTime ? moment(this.item.autoDeployStartTime, 'h:mma') : null
      const endTime = this.item.autoDeployEndTime ? moment(this.item.autoDeployEndTime, 'h:mma') : null
      if (beginningTime && endTime) {
        if (beginningTime <= endTime) {
          if (beginningTime.isSameOrBefore(currentTime) && currentTime.isSameOrBefore(endTime)) {
            return greenColor
          }
        } else {
          if (currentTime.isSameOrAfter(beginningTime) && currentTime.isSameOrAfter(endTime)) {
            return greenColor
          }
          if (currentTime.isSameOrBefore(beginningTime) && currentTime.isSameOrBefore(endTime)) {
            return greenColor
          }
        }
      }
    }
    return redColor
  }

  private getPreferredContractor(contractor: ContractorModel) {
    const availability: ContractorTradeAvailabilityModel | undefined =
      contractor.contractorAvailability && contractor.contractorAvailability.length > 0
        ? contractor.contractorAvailability.find((c) => c.tradeId === this.tradeId)
        : undefined
    return availability &&
      availability.preferredContractor &&
      availability.preferredContractor.toString() !== 'Unverified'
      ? availability.preferredContractor
      : contractor.preferredContractor
  }
}
</script>

<style scoped>
.cn-card-detail {
  font-size: 13px;
}
.cn-card-detail > span {
  display: inline-block;
  width: 100%;
  margin: 3px 0px;
  position: relative;
  padding-left: 22px;
}
.cn-card-detail > span i {
  position: absolute;
  left: 0px;
  top: 0.1em;
  max-width: 16px;
  text-align: center;
  right: 0px;
}
.call-cnt-list .show-icons i {
  margin-right: 4px;
}
.call-cnt-list {
  height: 100%;
}
.call-cnt-list .v-divider {
  flex: none;
  margin: 6px 0px;
  width: 100%;
}
.call-cnt-list .v-card {
  cursor: pointer;
  height: 100% !important;
  display: flex;
  flex-wrap: wrap;
}
.call-cnt-list .card-active:after {
  content: '';
  background-color: #ffa500;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 3px;
}
.show-icons i {
  display: inline-block;
  max-width: 13px;
}
.show-icons i img {
  max-width: 100%;
  width: 15px;
}
.icons >>> img {
  height: 20px;
  width: 20px;
  display: inline-block;
}
.show-icons {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  position: relative;
}
.icon-img-tile {
  width: 16px;
  height: 16px;
  margin-top: 5px;
}
.full-width {
  width: 100%;
}
.call-cnt-list .v-card__title h4 {
  padding-right: 80px;
}
.call-cnt-list .v-card__title {
  align-self: flex-start;
}
.call-cnt-list >>> .v-card__actions > * {
  margin: 0 4px !important;
}
.ratings {
  position: absolute;
  right: 8px;
  top: 9px;
}
.badges-icon {
  max-width: 11px;
  position: relative;
  top: 3px;
  right: 2px;
}
@media (max-width: 1279px) {
  .call-cnt-list .v-card__title h4 {
    font-size: 12px;
  }
}
</style>
