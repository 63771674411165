import store from '@/store'
import { commandNames } from '../commands/command-names'
import CommandHandler from '../interfaces/command-handler'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'

export default class OpenDuplicateJobHandler implements CommandHandler {
  public readonly commandName = commandNames.openDuplicateJobCommand
  async execute(value: any): Promise<void> {
    const jobId = value as unknown as string | null

    if (jobId) {
      eventBus.$emit('redirectToJob', jobId)
      Shared.passJobIdInHeader(jobId)
    }
  }
}
