<template>
  <td :class="letterType">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-icon dark :color="letterStatus.color" v-on="on">
          {{ letterStatus.icon }}
        </v-icon>
      </template>
      <span>
        Required by {{ getFormattedDate(letterDate.due) }}
        <br />
        <span v-if="letterDate.sent && letterDate.sent.isValid()">
          Sent on {{ getFormattedDate(letterDate.sent) }}
        </span>
      </span>
    </v-tooltip>
  </td>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import storeGetters from '@/storeGetters'
import moment, { Moment } from 'moment'
import ComplaintModel from '@/models/claim/ComplaintModel'

@Component({
  name: 'complaintTableLetterIcon',
})
export default class ComplaintTableLetterIcon extends Vue {
  @Prop() private letterType: string
  @Prop() private complaint: ComplaintModel

  private getFormattedDate(date: moment.Moment | null | undefined) {
    const dateFormat = storeGetters.getEnvironementVariables().DateFormat
    return date ? Shared.getFormatedDate(date, dateFormat) : ''
  }

  private get letterDate(): { due: Moment; sent: Moment } {
    return {
      due: this.complaint[`${this.letterType}Date`],
      sent: this.complaint[`${this.letterType}Sent`],
    }
  }

  private get letterStatus(): { color: string; icon: string } {
    let [color, icon] = ['grey', 'remove_circle_outline']

    if (this.letterDate.sent && this.letterDate.sent.isValid()) {
      color = 'success'
      icon = 'check'
    } else if (this.letterDate.due && this.letterDate.due.isValid()) {
      if (this.letterDate.due.isAfter(moment.utc())) {
        color = 'grey'
        icon = 'schedule'

        if (moment(this.letterDate.due).subtract(1, 'd').isBefore(moment.utc())) {
          color = 'orange'
          icon = 'warning'
        }
      } else {
        color = 'red'
        icon = 'warning'
      }
    }

    return { color, icon }
  }
}
</script>
