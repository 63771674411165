<template>
  <div class="dashboard">
    <v-dialog
      v-if="showContractorProfileDialog"
      v-model="showContractorProfileDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <div v-bar="{ useScrollbarPseudo: true }">
        <div>
          <ContractorProfile
            :contractor-detail="contractorProfileData"
            :total-engineer="totalEngineer"
            :show-contractor-profile-in-dialog="true"
            @saveContractor="saveContractor"
            @onCancelChanges="showContractorProfileDialog = false"
          ></ContractorProfile>
        </div>
      </div>
    </v-dialog>
    <v-container fluid pa-0>
      <v-snackbar v-model="mapWaitTime" :timeout="120000" :top="true" color="error" class="mapwaittime" absolute center>
        <b class="white--text">Please wait for a few seconds while map is loading</b>
        <v-btn color="white" flat @click="mapWaitTime = false">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-layout wrap>
        <v-flex xs3 offset-lg6 offset-sm6>
          <v-select
            v-model="selectedJobType"
            :items="getJobTypes"
            label="Job Type"
            item-text="jobTypeDescription"
            item-value="jobType"
            class="pr-3"
            @change="onJobTypeChange"
          ></v-select>
        </v-flex>
        <v-flex xs3>
          <v-autocomplete
            v-model="selectedTrade"
            :items="trades"
            label="Select Trade"
            item-text="description"
            item-value="tradeId"
            single-line
            :loading="loading"
            @change="onTrdeSelectionChange"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs8>
          <v-card class="pa-3 network-map">
            <div v-show="!loading" class="show-emergency">
              <NetworkManagerDashboardMap
                ref="networkManagerDashboardMap"
                :map-id="new Date().getTime().toString()"
                :zoom-level="7"
                :coverage="coverage"
                style="height: 600px"
                @setMapWaitTime="onSetMapWaitTime"
              ></NetworkManagerDashboardMap>
            </div>
            <div v-show="loading">
              <v-progress-circular
                class="loading-spinner"
                :width="2"
                :size="50"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs4 mt-2 pl-4>
          <v-container fluid class="grid-list-lg pa-0">
            <v-layout wrap calls-info request-info>
              <v-flex xs12 mb-1 py-0>
                <v-card class="elevation-1 white white--grey pa-0 piechart-jobdeshboard">
                  <div v-show="!loading" class="show-preferred-chart">
                    <v-card-title class="px-2 pb-0 subheading">Contractor Badges</v-card-title>
                    <v-card-text class="pb-0 pa-0 chart-height-100 mt-5">
                      <div v-show="contractorTypes !== null" class="chart-line chart-height-100">
                        <canvas ref="pieChartElement" width="60" height="50px" class="planet-pie-chart"></canvas>
                      </div>
                      <div
                        v-show="contractorTypes === null"
                        class="data-not-availble-msg grey--text text--lighten-0 title"
                      >
                        Data not available
                      </div>
                    </v-card-text>
                  </div>
                  <v-progress-circular
                    v-show="loading"
                    class="loading-spinner"
                    :width="2"
                    :size="50"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex sm12 class="gridView mt-3">
          <div>
            <v-layout wrap>
              <v-flex xs4 sm6 lg8 request-info>
                <h3 v-if="isApprovedContractor" class="mt-4">Approved Contractors</h3>
                <h3 v-else-if="isPreferredContractor" class="mt-4">Preferred Contractors</h3>
                <h3 v-else-if="isPlatinumContractor" class="mt-4">Platinum Contractors</h3>
                <h3 v-else-if="isUnverifiedContractor" class="mt-4">Unverified Contractors</h3>
                <h3 v-else class="mt-4">Contractors</h3>
              </v-flex>
              <v-flex xs8 sm6 lg4 job-dashboard-seach>
                <div class="search-content">
                  <v-text-field
                    v-model="search"
                    prepend-icon="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </v-flex>
              <v-flex xs12 mt-2 class="">
                <v-data-table
                  :headers="headers"
                  :items="filteredContractors"
                  :search="search"
                  item-key="name"
                  :loading="loading"
                  :class="
                    isApprovedContractor
                      ? 'approved-contractor elevation-1'
                      : isPreferredContractor
                      ? 'preferred-contractor elevation-1'
                      : isPlatinumContractor
                      ? 'platinum-contractor elevation-1'
                      : isUnverifiedContractor
                      ? 'unverified-contractor elevation-1'
                      : 'elevation-1'
                  "
                  :pagination.sync="pagination"
                >
                  <template slot="items" slot-scope="props">
                    <tr :active="props.selected" @click="props.selected = !props.selected"></tr>
                    <tr>
                      <td>
                        <a class="primary--text" @click="onCompanyNameClick(props.item)">
                          {{ props.item.companyName }}
                        </a>
                        <v-btn icon flat color="blue" small @click.native="redirectToContractorPortal(props.item)">
                          <v-icon small>launch</v-icon>
                        </v-btn>
                      </td>
                      <td>{{ props.item.email }}</td>
                      <td>
                        {{
                          props.item.totalOpenJobs !== null &&
                          props.item.totalOpenJobs !== undefined &&
                          Object.values(props.item.totalOpenJobs).length > 0
                            ? Object.values(props.item.totalOpenJobs).reduce((a, b) => a + b)
                            : 0
                        }}
                      </td>
                      <td>{{ props.item.totalEngineerCount }}</td>
                      <td>{{ props.item.preferredContractor }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import Chart from 'chart.js'
import NetworkManagerDashboardMap from '@/components/NetworkManagerDashboardMap.vue'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import DashboardController from '@/api/dashboardController'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import ContractorProfile from '@/views/ContractorProfile.vue'
import NetworkDashboardModel from '@/models/claim/NetworkDashboardModel'
import NetworkDashboardCoverageModel from '@/models/claim/NetworkDashboardCoverageModel'
import ContractorModel from '@/models/contractor/ContractorModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import { JobType } from '@/models/types'
import Shared from '../common/shared'

interface IJobTypeEnum {
  jobType: JobType
  jobTypeDescription: string
}

@Component({
  components: { NetworkManagerDashboardMap, ContractorProfile },
})
export default class NetworkManagerDashboard extends Vue {
  private search = ''
  private pagination: any = {}
  private headers: any = []
  private sortable = false
  private trades: TradeModel[] = []
  private selectedTrade: TradeModel | null = null
  private contractors: ContractorModel[] = []
  private filteredContractors: ContractorModel[] = []
  private coverage: NetworkDashboardCoverageModel[] = []
  private loading = false
  private contractorTypes: any = {}
  private isApprovedContractor = false
  private isPreferredContractor = false
  private isPlatinumContractor = false
  private isUnverifiedContractor = false
  private chart: any = null
  private showContractorProfileDialog = false
  private chartBackgroundColors: string[] = ['#00c852', '#1a59ff', '#faa826', '#EEEEEE']
  private chartActiveBackgroundColors: string[] = ['#048a3b', '#0b3bbb', '#c77f0c', '#909090']
  private contractorProfileData: ContractorModel | null = null
  private totalEngineer = 0
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private mapWaitTime = true
  private jobtype: JobType = 'HE'
  private selectedJobType: JobType = this.jobtype
  private allJobTypeTrades: TradeModel[] = []

  private get getJobTypes(): IJobTypeEnum[] {
    return Shared.jobTypeList
  }

  private async created() {
    this.headers = [
      { text: 'Name', sortable: true, value: 'companyName', align: 'left' },
      { text: 'Email', sortable: true, value: 'email', align: 'left' },
      {
        text: 'Open Job',
        sortable: true,
        value: 'totalOpenJobs',
        align: 'left',
      },
      {
        text: 'Total Engineer',
        sortable: true,
        value: 'totalEngineers',
        align: 'left',
      },
      { text: 'Type', value: 'preferredContractor', align: 'left' },
    ]
    this.pagination.rowsPerPage = 10
    await this.getTrades()
  }

  private onSetMapWaitTime(value: boolean) {
    this.mapWaitTime = value
  }

  // Get All Trades.
  private async getTrades() {
    this.loading = true
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      PolicyHolderController.GetTrades().then((res: TradeModel[]) => {
        storeMutations.setTrades(res)
        this.allJobTypeTrades = res.filter((t) => !t.isDeleted)
        this.setTrades(this.allJobTypeTrades)
      })
    } else {
      this.allJobTypeTrades = trades.filter((t) => !t.isDeleted)
      await this.setTrades(this.allJobTypeTrades)
    }
  }

  private async setTrades(tradeList: TradeModel[]) {
    this.trades = tradeList.filter((e) => e.jobType === 'HE')
    this.selectedTrade = this.trades[0]
    await this.GetNetworkDashboardDetail(this.selectedTrade.tradeId)
  }

  // Get NetworkDashboardDetail.
  private async GetNetworkDashboardDetail(tradeId: number) {
    this.loading = true
    this.contractorTypes = {}
    this.isApprovedContractor = false
    this.isUnverifiedContractor = false
    this.isPreferredContractor = false
    this.isPlatinumContractor = false
    this.contractors = []
    this.filteredContractors = []
    await DashboardController.GetNetworkDashboardData(tradeId).then((res: NetworkDashboardModel | null) => {
      if (res) {
        this.contractors = res.contractors
        this.filteredContractors = this.contractors
        this.contractorTypes['Approved Contractors'] = res.approvedContractorCount
        this.contractorTypes['Preferred Contractors'] = res.preferredContractorCount
        this.contractorTypes['Platinum Contractors'] = res.platinumContractorCount
        this.contractorTypes['Unverified Contractors'] = res.unverifiedContractorCount
        this.createPieChart('planet-pie-chart', this.PieChartConfiguration())
        this.coverage = res.coverages
      } else {
        this.contractorTypes = null
        this.coverage = []
      }
      this.loading = false
    })
  }

  private async onJobTypeChange(jobType: string) {
    this.trades = this.allJobTypeTrades.filter((e) => e.jobType === jobType)
    this.selectedTrade = this.trades[0]
    this.onTrdeSelectionChange(this.trades[0].tradeId)
  }

  private async onTrdeSelectionChange(tradeId: number) {
    this.mapWaitTime = true
    const networkManagerDashboardMap = this.$refs.networkManagerDashboardMap as NetworkManagerDashboardMap
    if (networkManagerDashboardMap) {
      networkManagerDashboardMap.clearPlottedPolygon()
    }
    await this.GetNetworkDashboardDetail(tradeId)
  }

  private PieChartConfiguration(): any {
    const values = 'values'
    return {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: Object[values](this.contractorTypes),
            backgroundColor: ['#00c852', '#1a59ff', '#faa826', '#EEEEEE'],
            hoverBorderWidth: 5,
          },
        ],
        labels: Object.keys(this.contractorTypes),
      },
      options: {
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 15,
          },
        },
        tooltips: {
          backgroundColor: '#FFF',
          titleFontSize: 10,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          xPadding: 5,
          yPadding: 5,
          displayColors: false,
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                display: false,
                minRotation: 90,
              },
              stacked: true,
              position: 'right',
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
            },
          ],
        },
        onClick: (evt, item) => {
          this.onChartClick(evt, item)
        },
      },
    }
  }

  private createPieChart(chartId, chartData) {
    if (this.chart) {
      this.chart.destroy()
    }
    const element = this.$refs.pieChartElement as HTMLCanvasElement
    this.chart = new Chart(element, chartData)
  }

  private onChartClick(event: any, item: any) {
    for (let i = 0; i < Object.keys(this.contractorTypes).length; i++) {
      const element = Object.keys(this.contractorTypes)[i]
      if (item.length > 0 && item[0] !== undefined) {
        if (item[0]._model.label === element) {
          this.isApprovedContractor = element === 'Approved Contractors' ? !this.isApprovedContractor : false
          this.isUnverifiedContractor = element === 'Unverified Contractors' ? !this.isUnverifiedContractor : false
          this.isPreferredContractor = element === 'Preferred Contractors' ? !this.isPreferredContractor : false
          this.isPlatinumContractor = element === 'Platinum Contractors' ? !this.isPlatinumContractor : false
          if (element === 'Approved Contractors') {
            this.chart.config.data.datasets[0].backgroundColor[i] = this.isApprovedContractor
              ? this.chartActiveBackgroundColors[i]
              : this.chartBackgroundColors[i]
            this.filteredContractors = this.isApprovedContractor
              ? this.contractors.filter((e) => e.preferredContractor === 'Approved')
              : this.contractors
          } else if (element === 'Unverified Contractors') {
            this.chart.config.data.datasets[0].backgroundColor[i] = this.isUnverifiedContractor
              ? this.chartActiveBackgroundColors[i]
              : this.chartBackgroundColors[i]
            this.filteredContractors = this.isUnverifiedContractor
              ? this.contractors.filter((e) => e.preferredContractor === 'Unverified')
              : this.contractors
          } else if (element === 'Preferred Contractors') {
            this.chart.config.data.datasets[0].backgroundColor[i] = this.isPreferredContractor
              ? this.chartActiveBackgroundColors[i]
              : this.chartBackgroundColors[i]
            this.filteredContractors = this.isPreferredContractor
              ? this.contractors.filter((e) => e.preferredContractor === 'Preferred')
              : this.contractors
          } else if (element === 'Platinum Contractors') {
            this.chart.config.data.datasets[0].backgroundColor[i] = this.isPlatinumContractor
              ? this.chartActiveBackgroundColors[i]
              : this.chartBackgroundColors[i]
            this.filteredContractors = this.isPlatinumContractor
              ? this.contractors.filter((e) => e.preferredContractor === 'Platinum')
              : this.contractors
          }
        } else {
          this.chart.config.data.datasets[0].backgroundColor[i] = this.chartBackgroundColors[i]
        }
      }
    }
    this.chart.update()
  }

  private onCompanyNameClick(item: ContractorModel) {
    this.contractorProfileData = item
    this.totalEngineer = item.totalEngineerCount
    this.showContractorProfileDialog = true
  }

  private redirectToContractorPortal(item: ContractorModel) {
    const routeData = this.$router.resolve({
      path: '/contractorManagement/' + item.id,
    })
    window.open(routeData.href, '_blank')
  }

  private saveContractor(isUpdated) {
    this.showContractorProfileDialog = false
    if (isUpdated) {
      this.snackbarText = 'Contractor Detail Updated Successfully.'
    } else {
      this.snackbarText = 'Contractor Saved Successfully.'
    }
    this.snackbar = true
  }
}
</script>

<style scoped>
/*Network Manager*/

.calls-info.request-info >>> .v-card {
  height: 145px !important;
  display: flex;
  flex-wrap: wrap;
}
.show-preferred-chart {
  width: 100%;
}

.calls-info.request-info >>> .v-card.piechart-jobdeshboard {
  height: 632px !important;
}

.calls-info.request-info >>> .v-card.piechart-jobdeshboard .v-card__text {
  height: calc(100% - 45px);
}

.calls-info.request-info >>> .v-card .v-card__title {
  width: calc(100% - 115px);
  display: flex;
  align-items: flex-start;
}

.gridView tbody tr td:last-child {
  padding-left: 0px !important;
}

.calls-info.request-info >>> .v-card .v-card__text {
  display: flex;
  align-self: flex-end;
}

.network-map {
  min-height: 632px !important;
}
.network-map > .show-emergency {
  position: relative;
}
.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}

.gridView {
  border: 0px;
}

.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
}

.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}

.chart-line {
  width: 100%;
  position: relative;
}

.v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
  width: 100% !important;
  max-height: 500px !important;
  object-fit: contain;
}
.search-content >>> .v-input__append-inner {
  padding-right: 40px;
  cursor: default;
}

.approved-contractor >>> table thead > tr {
  background-color: #00c852;
}
.preferred-contractor >>> table thead > tr {
  background-color: #1a59ff;
}
.platinum-contractor >>> table thead > tr {
  background-color: #faa826;
}
.approved-contractor >>> table thead > tr > th.column,
.approved-contractor >>> table thead > tr > th.column.active i,
.preferred-contractor >>> table thead > tr > th.column,
.preferred-contractor >>> table thead > tr > th.column.active i,
.platinum-contractor >>> table thead > tr > th.column,
.platinum-contractor >>> table thead > tr > th.column.active i {
  color: #fff !important;
}
.unverified-contractor >>> table thead > tr {
  background-color: #eee;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.data-not-availble-msg {
  margin: auto;
  margin-top: 40%;
}
.chart-height-100 {
  padding: 0 10px;
}
@media only screen and (min-width: 1600px) {
  .chartline-line canvas {
    height: calc(100% - 24px) !important;
  }
  .chart-height-100 {
    height: 100% !important;
  }
}

@media only screen and (max-width: 1600px) {
  .v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1300px) {
  .v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
    margin-top: 50px;
  }
}
</style>
