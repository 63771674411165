<template>
  <span v-if="job.jobCompletedBy">
    {{ job.cancellationReason ? 'Cancelled by' : 'Completed by' }}
    <b class="secondary--text">{{ getUserName(job.jobCompletedBy) }}</b>
  </span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
@Component
export default class JobCompleteFooter extends TimeLineItemBase {
  private getUserName(userId: string): string {
    const users = storeGetters.getUsers()
    const user = users.find((x) => x.id === userId.replace('UserDetail/', ''))
    return user ? user.firstName + ' ' + user.lastName : 'System'
  }
}
</script>
