<template>
  <div></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import StoreMutations from '@/storeMutations'
import UserController from '@/api/userController'

@Component({})
export default class GlobalNotificationsHandler extends Vue {
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('spaGlobalNotifications')

  private callCentreAppHub: SignalRHubConnection = new SignalRHubConnection('callCentreAppHub')

  private created() {
    this.signalRHub.addHandler('refreshUsers', this.refreshUserList)
    this.signalRHub.connect()
    this.refreshUserList()
    this.callCentreAppHub.addHandler('newAppVersion', this.newAppVersionFound)
    this.callCentreAppHub.connect()
  }

  private destroyed() {
    this.signalRHub.disconnect()
  }

  private async refreshUserList(): Promise<void> {
    if (this.$store.getters['sessionDetail'].detailRecordType !== 'UserDetail') {
      return
    }
    const users = await UserController.GetUserList()
    if (users) {
      StoreMutations.setUsers(users)
    }
  }

  private newAppVersionFound() {
    this.$emit('newAppVersionFound')
  }
}
</script>
