<template>
  <div>Autodeployment is now active</div>
</template>

<script lang="ts">
import TimeLineItemBase from '@/components/TimeLineItemBase'
import TimelineCardModel from '@/models/general/timeline'
import AutoDeployment, { AutoDeploymentOffer, AutoDeploymentStatus } from '@/models/timeline/AutoDeployment'
import { useTimelineStore } from '@/pinia/timeline'
import { Component } from 'vue-property-decorator'

@Component
export default class AutoDeploymentCard extends TimeLineItemBase {
  public get card(): TimelineCardModel | null {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card || null
  }

  public autoDeploymentStatus = AutoDeploymentStatus

  public get autoDeployment(): AutoDeployment | null {
    return this.card && this.card.payload ? (this.card.payload as AutoDeployment) : null
  }
}
</script>
