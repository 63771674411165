import DatumControlRunLog from '@/models/siteInvestigation/DatumControlRunLog'
import { SIJobProductProgress } from '@/common/enums'

export default class UpdateDatumControlLog {
  public id: string
  public jobId: string
  public datumId: number
  public engineerVisitDetailId: string
  public status: string
  public datumControlRunLogs: DatumControlRunLog[]
}
