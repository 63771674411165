<template>
  <v-card v-if="jobs">
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Phone Call Assign</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="close(false)">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-layout wrap class="job-search px-3">
      <v-flex v-if="isViaTwillio" xs12 mt-2 grey pa-2 lighten-3>
        <h4>
          <v-icon>info</v-icon>
          <span class="popup-close-msg">
            This window will close automatically after
            <b class="secondary--text">
              <v-icon class="md-icon secondary--text">access_time</v-icon>
              {{ popupCounter > 9 ? popupCounter + ' seconds' : popupCounter + ' second' }}.
            </b>
          </span>
        </h4>
      </v-flex>
      <v-flex xs12>
        <UniversalSearch
          class="search-job-item"
          :is-clear-search.sync="isClearSearch"
          :parent-component="'PhoneCallJobsAssign'"
          @searchJobResult="bindJobResult"
          @closeSearchJobResult="clearJobResult"
        />
      </v-flex>
      <v-flex xs12>
        <h3 class="mb-2 grey--text text--darken-2 subheading">Suggestion List</h3>
      </v-flex>
    </v-layout>
    <v-card-text class="scroll-content-dialog px-3 pt-0 pb-4">
      <v-progress-circular
        v-show="!getIsDataLoaded"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
        :style="getLoaderStyle(50)"
      ></v-progress-circular>
      <v-layout wrap fill-height>
        <v-flex xs12 class="suggestion-list grey lighten-3">
          <div v-show="getIsDataLoaded && suggestedJobList.length < 1" class="record-msg fill-height">
            <span class="subheading grey--text ma-auto">Record is not Found</span>
          </div>
          <v-list v-for="(job, index) in suggestedJobList" :key="index" three-line class="grey lighten-3">
            <template>
              <v-list-tile avatar ripple>
                <v-list-tile-content>
                  <v-list-tile-title class="body-1">
                    <b>Job ID: {{ job.id }}</b>
                  </v-list-tile-title>
                  <v-list-tile-sub-title v-if="job.name" class="text--primary">
                    <v-icon small>person</v-icon>
                    {{ job.name }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title v-if="job.phoneNumber" class="text--primary">
                    <v-icon small>call</v-icon>
                    <b>{{ job.phoneNumber }}</b>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title v-if="job.address || job.postCode">
                    <v-icon small>place</v-icon>
                    {{ job.address }}
                    {{ job.address ? ',' + job.postCode : job.postCode }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn color="primary" small dark @click="assignJobClick(job)">Assign</v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider />
            </template>
          </v-list>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions v-if="isViaTwillio" class="py-2 px-3">
      <v-spacer></v-spacer>
      <v-btn class="text-xs-right" color="primary" :disabled="isLoading" :loading="isLoading" @click="markCallAsNoise">
        <v-icon class="md-icon" color="white">phonelink_erase</v-icon>
        &nbsp; Mark as Noise
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import DashboardController from '@/api/dashboardController'
import UnlinkedPhoneCallsModel from '@/models/claim/UnlinkedPhoneCallsModel'
import UniversalSearch from '@/components/timeline/UniversalSearch.vue'
import JobController from '@/api/jobController'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import JobHeader from '@/models/JobHeader'
import moment from 'moment'
import store from '@/store'
import eventBus from '@/common/bus'

@Component({
  components: {
    UniversalSearch,
  },
})
export default class PhoneCallJobsAssign extends Vue {
  @Prop() private jobItemList: JobHeader[]
  @Prop() private unlinkedPhoneCall: UnlinkedPhoneCallsModel | null
  @Prop() private isDataLoaded: boolean
  @Prop({ default: false }) private isViaTwillio: boolean
  private isClearSearch = false
  private jobItems: JobHeader[]
  private suggestedJobList: JobHeader[] = []
  private isLoading = false
  private popupCounter = 15

  private created() {
    // show message for window close after 15 seconds, when isViaTwillio is true
    const popupTimer = setInterval(() => {
      this.popupCounter--
      if (this.popupCounter <= 0) {
        clearInterval(popupTimer)
      }
    }, 1000)
  }

  private mounted() {
    this.suggestedJobList = this.jobItemList
  }

  @Watch('jobItemList')
  private jobs() {
    this.suggestedJobList = this.jobItemList
  }

  private get getIsDataLoaded(): boolean {
    return this.isDataLoaded
  }

  private get unlinkedPhoneCallDetail(): UnlinkedPhoneCallsModel {
    if (this.unlinkedPhoneCall) {
      return this.unlinkedPhoneCall
    } else {
      return new UnlinkedPhoneCallsModel()
    }
  }

  private close(IsJobAssigned: boolean) {
    this.$validator.errors.items = []
    this.isClearSearch = true
    if (this.unlinkedPhoneCall) {
      this.$emit('onJobAssignClose', IsJobAssigned, this.unlinkedPhoneCall.recordingId)
    } else {
      this.$emit('onJobAssignClose', IsJobAssigned, '')
    }
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  // assign bob button click
  private assignJobClick(job: JobHeader) {
    if (this.isViaTwillio) {
      this.$emit('linkPhoneCallWithJob', job.id, this.unlinkedPhoneCallDetail.callSid)
    } else {
      Shared.confirmationPopup.open(
        'Are you sure you want to assign this job?',
        '',
        '',
        'No',
        'Yes',
        this,
        'assignJob',
        job
      )
    }
  }

  // assign job to unlinked call
  private assignJob(job: JobHeader) {
    let callInfo = ''

    if (job.phoneNumber === this.unlinkedPhoneCallDetail.from) {
      if (job.phoneNumber) {
        callInfo = 'Call from customer'
      } else if (job.nomineePhoneNumber) {
        callInfo = 'Call from customer nominee'
      }
    } else if (job.phoneNumber === this.unlinkedPhoneCallDetail.to) {
      if (job.phoneNumber) {
        callInfo = 'Call to customer'
      } else if (job.nomineePhoneNumber) {
        callInfo = 'Call to customer nominee'
      }
    } else {
      callInfo = ''
    }
    callInfo =
      (callInfo ? callInfo + ' <br /> ' : '') +
      '<b>From:</b> ' +
      this.unlinkedPhoneCallDetail.from +
      '<br /> ' +
      '<b>Received By:</b> ' +
      this.unlinkedPhoneCallDetail.to +
      '<br /> ' +
      '<b>Received At:</b> ' +
      this.getFormattedDateTime(this.unlinkedPhoneCallDetail.callDateTime) +
      '<br /> '
    DashboardController.AssignJobToUnlinkedPhoneCall(
      job.id,
      this.unlinkedPhoneCallDetail.recordingId,
      this.unlinkedPhoneCallDetail.callSid,
      callInfo,
      this.unlinkedPhoneCallDetail.recordingUrl
    )
      .then((res: string) => {
        if (res) {
          this.close(true)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error assigning job to phonecall, please try again', true)
      })
  }

  private clearJobResult() {
    this.suggestedJobList = []
    this.jobItems.forEach((element) => {
      this.suggestedJobList.push(element)
    })
    this.jobItems = []
  }

  private bindJobResult(id: string) {
    // save existing jobs in a temp array
    this.jobItems = this.suggestedJobList
    this.suggestedJobList = []
    JobController.GetJobHeader(id)
      .then((res: JobHeader | null) => {
        if (res) {
          this.suggestedJobList = [res]
          return this.suggestedJobList
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading job list, please try again', true)
      })
  }

  private markCallAsNoise() {
    Shared.confirmationPopup.open(
      'Are you sure you want to mark this call as noise?',
      '',
      '',
      '',
      'Mark as Noise',
      this,
      'markAsNoise',
      this.unlinkedPhoneCallDetail
    )
  }

  private markAsNoise(unlinkedPhoneCallDetails: UnlinkedPhoneCallsModel): void {
    this.$emit('linkPhoneCallWithJob', 'noise', this.unlinkedPhoneCallDetail.callSid)
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>

<style scoped>
.suggestion-list {
  min-height: 75px;
}
.suggestion-list >>> .v-list__tile__content {
  position: relative;
  padding-left: 25px;
}
.suggestion-list >>> .v-list__tile__content .v-icon {
  position: absolute;
  left: 0px;
}
.suggestion-list >>> .v-list__tile__sub-title {
  margin-top: 3px;
}
.suggestion-list >>> .v-list--three-line .v-list__tile {
  height: auto;
  padding: 10px 16px;
}
.job-search {
  min-height: 100px;
}
.scroll-content-dialog {
  max-height: 400px;
  position: relative;
}
.record-msg {
  display: flex;
}
.record-msg span {
  margin: auto;
}
.job-search >>> .search-job-item > div {
  position: relative;
}
.job-search >>> .search-job-item .seach-loader {
  position: absolute !important;
  top: 13px;
  right: 35px;
  margin: 0px;
}
.job-search >>> .search-job-item .v-input {
  position: relative;
}
.job-search >>> .search-job-item .v-input .v-input__prepend-outer {
  margin: 0;
}
.job-search >>> .search-job-item .v-input .v-input__icon--prepend {
  position: absolute;
  left: 0;
}
.job-search >>> .search-job-item .v-input .v-text-field__details {
  max-width: calc(100% - 0px);
}
.job-search >>> .search-job-item .v-input__slot {
  padding-left: 40px;
  padding-right: 40px;
}
.job-search >>> .search-job-item .v-input .v-select__slot .v-input__append-inner {
  position: absolute;
  right: -30px;
}
.popup-close-msg {
  vertical-align: super;
  position: relative;
  top: 2px;
}
</style>
