<template>
  <div>
    <div v-if="!createdJobId">
      <v-stepper v-model="stepIndex" non-linear class="elevation-0">
        <v-stepper-header>
          <v-stepper-step
            :step="1"
            :editable="stepIndex > 1"
            :complete="step1validated"
            class="enquiry-step1"
            @click.native.stop="switchToStep(1)"
          >
            Policy Details
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :step="2"
            :editable="step1validated && stepIndex > 2"
            :complete="step2validated"
            class="enquiry-step2"
            @click.native.stop="switchToStep(2)"
          >
            Commodity Details
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :step="3"
            :editable="step1validated && step2validated && stepIndex > 3"
            :complete="wizardSubmitStatus === 'submitted'"
            class="enquiry-step3"
            @click.native.stop="switchToStep(3)"
          >
            CONTACT PREFERENCES
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-content step="1">
          <v-form autocomplete="off">
            <v-container fluid pa-0 grid-list-lg>
              <v-layout wrap>
                <v-layout wrap>
                  <v-flex xs12 pa-0>
                    <v-flex>
                      <PolicySelector
                        v-if="policies"
                        id="policySelector"
                        ref="policySelectorRef"
                        v-validate="'required'"
                        style="padding-top: 10px"
                        type="number"
                        required
                        :policy-tree="policies"
                        :error-messages="policySelected ? [] : ['A policy must be selected']"
                        :data-vv-scope="step1formRef"
                        data-vv-name="Selected Policy"
                        :selected-policy="selectedPolicy"
                        :pre-selected-policy="preSelectedPolicyScheduleId"
                        :job-type="'US'"
                        class="mb-4 pb-0 pl-0"
                        @update:selectedPolicy="updatePolicySelection"
                      />
                      <v-select
                        v-else
                        v-model="claimModel.policyScheduleId"
                        v-validate="'required'"
                        :disabled="true"
                        type="number"
                        :placeholder="claimModel.policyScheme"
                        data-vv-name="Selected Policy"
                      />
                      <v-alert v-model="showDisclaimer" color="warning" icon="priority_high" dismissible>
                        DISCLAIMER: {{ disclaimerMessage }}
                      </v-alert>
                      <v-alert
                        v-model="isAssumedCoverPolicy"
                        color="warning"
                        icon="priority_high"
                        outline
                        class="pa-0"
                        style="border: 0px !important; top: -15px !important"
                      >
                        This is an assumed cover policy, you should create a job even if you can't find the customer in
                        the validation database.
                      </v-alert>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 py-0>
                    <v-checkbox
                      ref="siFollowUpTick"
                      v-model="siFollowUp"
                      class="que-checkbox full-width pb-2"
                      :disabled="false"
                      name="siFollowUpTick"
                      :hide-details="true"
                      label="Is this following up from an SI job?"
                      color="primary"
                      @change="siFollowUp"
                    />
                    <v-flex
                      v-if="siFollowUp"
                      ref="siFollowUpSearch"
                      style="min-height: 100px"
                      name="siFollowUpSearch"
                      xs-12
                    >
                      <p>Search for SI job, or enter information below</p>

                      <UniversalSearch
                        class="search-job-item"
                        style="width: 100%"
                        :s-i-search="true"
                        :is-clear-search="false"
                        :parent-component="'AddDrainageClaimWizard'"
                      />
                      <v-divider class="pb-2 pt-2"></v-divider>
                    </v-flex>
                    <CustomerAddress
                      ref="addCustomerAddress"
                      :passed-client-title.sync="getClientTitle"
                      :passed-first-name.sync="getFirstName"
                      :passed-last-name.sync="getLastName"
                      :passed-policy-number.sync="getPolicyNumber"
                      :passed-address-line1.sync="getAddressLine1"
                      :passed-address-line2.sync="getAddressLine2"
                      :passed-address-line3.sync="getAddressLine3"
                      :passed-address-line4.sync="getAddressLine4"
                      :passed-post-code.sync="getPostCode"
                      :is-display-policy-number="true"
                      :disabled="disableAddressForm"
                    ></CustomerAddress>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-container>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2" class="pb-0">
          <v-form autocomplete="off">
            <v-container class="pb-0 pt-2">
              <v-layout>
                <v-flex>
                  <div class="policy-logo">
                    <img v-if="policyUrl && policyUrl !== null && policyUrl !== ''" :src="policyUrl" />
                    <img v-else src="/img/dummy-logo.jpeg" />
                  </div>
                </v-flex>
                <v-flex class="emergency-info-table">
                  <v-layout wrap class="shadow-outer emergency-table">
                    <PropertyCard
                      :size="4"
                      title="PH Name"
                      :detail="
                        (claimModel.clientTitle ? claimModel.clientTitle : '') +
                        ' ' +
                        claimModel.clientForename +
                        ' ' +
                        claimModel.clientLastName
                      "
                      :other-detail="
                        (claimModel.secondaryClientTitle ? claimModel.secondaryClientTitle + ' ' : '') +
                        (claimModel.secondaryClientForename ? claimModel.secondaryClientForename + ' ' : '') +
                        (claimModel.secondaryClientLastName ? claimModel.secondaryClientLastName : '')
                      "
                    />
                    <PropertyCard
                      :size="4"
                      title="Policy Number"
                      :detail="claimModel.policyNumber ? '#' + claimModel.policyNumber : ''"
                    />
                    <PropertyCard :size="4" title="PostCode" :detail="claimModel.postcode" />
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-container class="pb-0 px-0">
                  <div class="perilItems">
                    <AddDrainageCommodity
                      v-if="wizardLoadState === 'loaded' || true"
                      ref="addCommodity"
                      v-model="claimModel.commodity"
                    />
                    <div v-else>Loading dropdown values, please wait ...</div>
                  </div>
                </v-container>
              </v-layout>
            </v-container>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-form autocomplete="off">
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-layout wrap class="shadow-outer">
                    <PropertyCard
                      :size="4"
                      title="PH Name"
                      :detail="
                        (claimModel.clientTitle ? claimModel.clientTitle : '') +
                        ' ' +
                        claimModel.clientForename +
                        ' ' +
                        claimModel.clientLastName
                      "
                      :other-detail="
                        (claimModel.secondaryClientTitle ? claimModel.secondaryClientTitle + ' ' : '') +
                        (claimModel.secondaryClientForename ? claimModel.secondaryClientForename + ' ' : '') +
                        (claimModel.secondaryClientLastName ? claimModel.secondaryClientLastName : '')
                      "
                    />
                    <PropertyCard
                      :size="4"
                      title="Policy Number"
                      :detail="claimModel.policyNumber ? '#' + claimModel.policyNumber : ''"
                    />
                    <PropertyCard :size="4" title="PostCode" :detail="claimModel.postcode" />
                  </v-layout>
                </v-flex>
                <v-flex xs12 pt-4>
                  <v-layout wrap>
                    <v-flex xs12>
                      <ContactPreferenceDetails
                        ref="contactPreferenceDetails"
                        :customer-contact-preference-modal="customerContactPreferenceModal"
                        @updateContactPreference="updateContactPreference"
                      ></ContactPreferenceDetails>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper>
    </div>
    <div v-else>
      <v-card class="elevation-0 pa-0">
        <v-card-text class="pa-0">
          <v-layout wrap>
            <v-flex xs12 px-1 mb-2>
              <UploadDocument
                ref="uploadDocument"
                :job-id="createdJobId"
                :document-types="uploadedDocumentTypes"
                :is-in-wizard-step="true"
                @documentUploadComplete="onDocumentUploadComplete"
              ></UploadDocument>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import AddDrainageCommodity from '@/components/AddDrainageCommodity.vue'
import PropertyCard from '@/components/PropertyCard.vue'
import DialogWizard from '@/common/DialogWizard'
import SearchItemModel from '@/models/policyHolder/SearchItemModel'
import PolicyHolderController from '@/api/policyHolderController'
import JobController from '@/api/jobController'
import VeeValidate from 'vee-validate'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'
import { ConnectionStatus } from '@/common/environment'
import AddDrainageJobWithCommodity from '@/models/requests/AddDrainageJobWithCommodity'
import AddCommodity from '@/models/requests/AddCommodity'
import Store from '@/store'
import BrandPolicyModel from '@/models/policy/BrandPolicyModel'
import { InsurerBrandList, BrandPolicyList, SelectedPolicy } from '@/models/policy/PolicyLists'
import { ContactPreferenceEnum } from '@/common/enums'
import { ContactPreference, JobType } from '@/models/types'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import CustomerAddress from '@/components/CustomerAddress.vue'
import ContactPreferenceDetails from '@/components/ContactPreferenceDetails.vue'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'
import InsurerPhoneNumberModel from '@/models/client/InsurerPhoneNumberModel'
import InsurerController from '../api/InsurerController'
import PolicySelector from '@/components/PolicySelector.vue'
import { isNullOrUndefined } from 'util'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import UploadedDocumentTypeModel from '@/models/UploadedDocumentTypeModel'
import UploadDocument from '@/components/UploadDocument.vue'
import DocumentController from '@/api/documentController'
import PolicyScheduleController from '@/api/policyScheduleController'
import UniversalSearch from '@/components/timeline/UniversalSearch.vue'
import JobHeader from '../models/JobHeader'

@Component({
  name: 'AddDrainageClaimWizard',
  components: {
    AddDrainageCommodity,
    PropertyCard,
    CustomerAddress,
    ContactPreferenceDetails,
    PolicySelector,
    UploadDocument,
    UniversalSearch,
  },
})
export default class AddDrainageClaimWizard extends DialogWizard {
  public stepIndex = 1
  private step1formRef = 'ahecw_step1form'
  private step2formRef = 'ahecw_step2form'
  private step3formRef = 'ahecw_step3form'
  private step1validated = false
  private step2validated = false
  private step3validated = false
  private claimModel: AddDrainageJobWithCommodity = new AddDrainageJobWithCommodity()
  private wizardSubmitStatus: 'none' | 'submitting' | 'submitted' = 'none'
  private wizardLoadState: 'unloaded' | 'loading' | 'loaded' = 'unloaded'
  private waitForMoreInputTimeoutHandle: number | null = null
  private wizardVisiblityTimeoutHandlerId: number | null = null
  private contactPreference = ContactPreferenceEnum
  private policySearchDisabled: boolean = Store.Instance.state.Environment.ConnectionStatus !== ConnectionStatus.Online
  private preSelectedPolicyScheduleId: null | number = null
  private clientTitleList: string[] = ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms']
  private policyUrl: string | null = ''
  private customerContactPreferenceModal: UpdateCustomerContactPreferenceModal =
    new UpdateCustomerContactPreferenceModal()
  private customerExistingPhoneNumbersList: InsurerPhoneNumberModel[] = []
  private selectedPolicy: SelectedPolicy = {
    insurer: null,
    brand: null,
    policy: null,
  }
  private policies: InsurerBrandList[] | null = null
  private policyList: BrandPolicyModel[] = []
  private prevPolicyId: number | undefined = undefined
  private disclaimerMessage = ''
  private showDisclaimer = false
  private jobType: JobType = 'US'
  private searchRunning = false
  private createdJobId = ''
  private uploadedDocumentTypes: UploadedDocumentTypeModel[] = []
  private siFollowUp = false
  private disableAddressForm = false
  private isAssumedCoverPolicy = false

  public mounted() {
    eventBus.$on('siJobHeader', (siJobHeader: JobHeader) => {
      this.disableAddressForm = true
      const splitName = siJobHeader.name.split(' ')
      const titleTemp = splitName.shift()
      this.claimModel.clientTitle = titleTemp != null || titleTemp !== undefined ? titleTemp : ''
      const lastNameTemp = splitName.pop()
      this.claimModel.clientLastName = lastNameTemp != null || lastNameTemp !== undefined ? lastNameTemp : ''
      this.claimModel.clientForename = splitName.join(' ')
      this.claimModel.policyNumber = siJobHeader.policyNumber
      this.claimModel.addressLine1 = siJobHeader.addressLine1
      this.claimModel.addressLine2 = siJobHeader.addressLine2
      this.claimModel.addressLine3 = siJobHeader.addressLine3
      this.claimModel.addressLine4 = siJobHeader.addressLine4
      this.claimModel.postcode = siJobHeader.postCode
      this.claimModel.linkedJobId = siJobHeader.id
      this.disableAddressForm = false
    })
    eventBus.$on('siSearchClear', () => {
      this.disableAddressForm = true
      this.claimModel.clientTitle = ''
      this.claimModel.clientLastName = ''
      this.claimModel.clientForename = ''
      this.claimModel.policyNumber = ''
      this.claimModel.addressLine1 = ''
      this.claimModel.addressLine2 = ''
      this.claimModel.addressLine3 = ''
      this.claimModel.addressLine4 = ''
      this.claimModel.postcode = ''
      this.disableAddressForm = false
    })
  }

  // Retrieves all policies in the system
  public async getPolicies() {
    if (this.searchRunning) {
      return
    }
    this.searchRunning = true
    this.policies = await PolicyScheduleController.GetPolicySchedule(false, this.jobType)
    this.searchRunning = false
    this.policyList = []
    if (this.policies && this.policies.length > 0) {
      const policies = Array.from(this.policies).map((b) => b.brands && b.brands.map((p) => p.policies))
      policies.forEach((policy: any) => {
        if (policy) {
          if (policy.flatMap) {
            const mappedPolicies = policy.flatMap((p) => p)
            mappedPolicies.forEach((p) => {
              this.policyList.push(p)
            })
          } else {
            this.policyList.push(policy)
          }
        }
      })
    } else {
      this.policies = []
    }
    // set select policy option by default on drainage type
    this.claimModel.policyScheduleId = -1
  }

  public clearForm() {
    // Trigger a reset of the form
    this.wizardVisibilityChanged(true)
  }

  public switchToStep(step: number) {
    // dont move wizard if submitting/submitted
    if (this.wizardSubmitStatus !== 'none') {
      return
    }
    const self = this
    if (step > self.stepIndex) {
      this.validateStep(self.stepIndex)
        .then((result: [number, string, boolean]) => {
          const passed: boolean = result[2]
          switch (result[1]) {
            case self.step1formRef:
              self.step1validated = passed
              break
            case self.step2formRef:
              self.step2validated = passed
              break
            case self.step3formRef:
              self.step3validated = passed
              break
          }
          if (passed) {
            switch (step) {
              case 3:
                self.wizardPageChanged('Submit')
                break
              // case 2:
              // break;
              default:
                self.wizardPageChanged('Next')
            }
            self.stepIndex = step
          } else {
            self.stepIndex = result[0]
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    } else {
      self.stepIndex = step
      self.wizardPageChanged('Next')
    }
  }

  private get getClientTitle(): string {
    return this.claimModel.clientTitle
  }

  private set getClientTitle(newValue: string) {
    this.claimModel.clientTitle = newValue
  }

  private get getFirstName(): string {
    return this.claimModel.clientForename
  }

  private set getFirstName(newValue: string) {
    this.claimModel.clientForename = newValue
  }

  private get getLastName(): string {
    return this.claimModel.clientLastName
  }

  private set getLastName(newValue: string) {
    this.claimModel.clientLastName = newValue
  }

  private get getPolicyNumber(): string {
    return this.claimModel.policyNumber
  }

  private set getPolicyNumber(newValue: string) {
    this.claimModel.policyNumber = newValue
  }

  private get getAddressLine1(): string {
    return this.claimModel.addressLine1
  }

  private set getAddressLine1(newValue: string) {
    this.claimModel.addressLine1 = newValue
  }

  private get getAddressLine2(): string {
    return this.claimModel.addressLine2
  }

  private set getAddressLine2(newValue: string) {
    this.claimModel.addressLine2 = newValue
  }

  private get getAddressLine3(): string {
    return this.claimModel.addressLine3
  }

  private set getAddressLine3(newValue: string) {
    this.claimModel.addressLine3 = newValue
  }

  private get getAddressLine4(): string {
    return this.claimModel.addressLine4
  }

  private set getAddressLine4(newValue: string) {
    this.claimModel.addressLine4 = newValue
  }

  private get getPostCode(): string {
    return this.claimModel.postcode
  }

  private set getPostCode(newValue: string) {
    this.claimModel.postcode = newValue
  }

  private get policySelected(): boolean {
    return !isNullOrUndefined(this.selectedPolicy.policy)
  }

  public processCurrentPage(): void {
    const self = this
    this.validateStep(self.stepIndex)
      .then((result: [number, string, boolean]) => {
        const passed: boolean = result[2]
        switch (result[1]) {
          case self.step1formRef:
            self.step1validated = passed
            if (passed) {
              self.wizardPageChanged('Next')
              self.stepIndex = 2
            }
            break
          case self.step2formRef:
            self.step2validated = passed
            if (passed) {
              self.wizardPageChanged('Submit')
              self.stepIndex = 3
            }
            break
          case self.step3formRef:
            self.step3validated = passed
            if (passed) {
              if (!self.step2validated) {
                self.stepIndex = 2
                self.wizardPageChanged('Next')
              } else {
                this.saveClaim()
              }
            }
            break
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  @Watch('selectedPolicy')
  protected policyChanged() {
    if (this.selectedPolicy && this.selectedPolicy.policy) {
      const policy: BrandPolicyModel | undefined = this.policyList.find((p) => p.id === this.selectedPolicy.policy!.id)
      if (this.prevPolicyId && this.selectedPolicy.policy.id !== this.prevPolicyId) {
        if (policy && policy.disclaimer && policy.disclaimer.trim() !== '') {
          this.disclaimerMessage = policy.disclaimer
          this.showDisclaimer = true
        }
        this.claimModel.policyNumber = ''
      }
      this.prevPolicyId = this.selectedPolicy.policy!.id
      this.isAssumedCoverPolicy = policy && policy.isStarred ? true : false
    } else {
      this.isAssumedCoverPolicy = false
    }
  }

  @Watch(DialogWizard.WizardVisiblePropName)
  protected async wizardVisibilityChanged(newValue: boolean) {
    if (newValue) {
      // get policies
      if (!this.policies || this.policies.length === 0) {
        await this.getPolicies()
      }
      this.resetUploadDocumentProperties()
      //  wizard shown, reset form
      this.claimModel = new AddDrainageJobWithCommodity()
      this.disableAddressForm = false
      this.siFollowUp = false

      const sub = this.$refs.contactPreferenceDetails as ContactPreferenceDetails
      if (sub !== undefined) {
        sub.setDefaultValues()
      }
      this.customerContactPreferenceModal = new UpdateCustomerContactPreferenceModal()

      const customerAddress = this.$refs.addCustomerAddress as CustomerAddress
      if (customerAddress !== undefined) {
        customerAddress.setDefaultValues()
      }

      const addCommoditySub = this.$refs.addCommodity as AddDrainageCommodity
      if (addCommoditySub !== undefined) {
        addCommoditySub.resetAddCommodity()
      }
      if (this.wizardLoadState === 'loaded') {
        // wizard loaded, push initial empty commodity on
        this.claimModel.commodity = new AddCommodity()
      } else if (this.wizardLoadState === 'unloaded') {
        // wizard not loaded yet, get items
        this.wizardLoadState = 'loading'
        this.addDefaultCommodity()
      }
      this.updateContentHeight(550)
      this.wizardPageChanged('Next')
      this.stepIndex = 1
      this.step1validated = false
      this.step2validated = false
      this.step3validated = false
      this.wizardSubmitStatus = 'none'

      this.selectedPolicy = {
        insurer: null,
        brand: null,
        policy: null,
      }
      this.isAssumedCoverPolicy = false

      const policySelector = this.$refs.policySelectorRef as PolicySelector
      if (policySelector !== undefined) {
        policySelector.setDefaultValues()
      }

      if (this.wizardVisiblityTimeoutHandlerId) {
        window.clearTimeout(this.wizardVisiblityTimeoutHandlerId)
      }
      this.wizardVisiblityTimeoutHandlerId = window.setTimeout(() => {
        this.$validator.errors.items = []
      }, 0)
    }
  }

  private addDefaultCommodity(): void {
    // we have all dropdown data required to create an commodity
    // now add an initial one
    if (!this.claimModel.commodity) {
      this.claimModel.commodity = new AddCommodity()
    }
    this.wizardLoadState = 'loaded'
  }

  private setContactPreferenceDetail() {
    const self = this
    Object.keys(self.customerContactPreferenceModal).forEach(
      (key) => (self.claimModel[key] = self.customerContactPreferenceModal[key])
    )

    // reset email on sms/call selection
    if (
      self.claimModel.contactPreference === this.contactPreference[this.contactPreference.SMS] ||
      self.claimModel.contactPreference === this.contactPreference[this.contactPreference.Call]
    ) {
      self.claimModel.email = ''
    }
    // reset mobilephone on email selection
    if (self.claimModel.contactPreference === this.contactPreference[this.contactPreference.Email]) {
      self.claimModel.mobilePhone = ''
    }
    // reset reasonForChoosingCallOption on SMS/Email selection
    if (this.claimModel.contactPreference !== this.contactPreference[this.contactPreference.Call]) {
      this.claimModel.reasonForChoosingCallOption = ''
    }
    // nominee - reset email on sms/call selection
    if (
      self.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.SMS] ||
      self.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.Call]
    ) {
      self.claimModel.nomineeEmail = ''
    }
    // nominee - reset mobilephone on email selection
    if (self.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.Email]) {
      self.claimModel.nomineeMobilePhone = null
    }
    // nominee - reset reasonForChoosingCallOption on SMS/Email selection
    if (this.claimModel.nomineeContactPreference !== this.contactPreference[this.contactPreference.Call]) {
      this.claimModel.nomineeReasonForChoosingCallOption = ''
    }
  }

  private saveClaim() {
    const self = this

    self.setContactPreferenceDetail()
    // ingore if currently submitting or submitted
    if (self.wizardSubmitStatus !== 'none') {
      return
    }
    self.wizardBackEndOperation(true)

    // check store for existing phone numbers to compare
    this.customerExistingPhoneNumbersList = storeGetters.getCustomersPhoneNumber()
    if (self.customerExistingPhoneNumbersList.length === 0) {
      InsurerController.GetInsurerPhoneNumbers()
        .then((res: InsurerPhoneNumberModel[]) => {
          if (res && res.length > 0) {
            storeMutations.setCustomersPhoneNumber(res)
            self.customerExistingPhoneNumbersList = res
            self.saveJobClaim()
          } else {
            eventBus.$emit('errorHandler', 'Error loading insurer phone numbers, please try again', true)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading insurer phone numbers, please try again', true)
        })
    } else {
      self.saveJobClaim()
    }
  }

  private saveJobClaim() {
    const self = this

    if (self.claimModel.mobilePhone !== '' || self.claimModel.mobilePhone !== null) {
      if (self.checkIsNumberAlreadyAssigned(self.claimModel.mobilePhone)) {
        // show error
        self.wizardBackEndOperation(false)
        eventBus.$emit('errorHandler', 'Provided contact number already in use', true)
        self.wizardSubmitStatus = 'none'
        return
      }
    }

    if (self.claimModel.nomineeMobilePhone !== null) {
      if (self.checkIsNumberAlreadyAssigned(self.claimModel.nomineeMobilePhone)) {
        // show error
        self.wizardBackEndOperation(false)
        eventBus.$emit('errorHandler', 'Provided nominee contact number already in use', true)
        self.wizardSubmitStatus = 'none'
        return
      }
    }

    JobController.SaveDrainageClaim(self.claimModel)
      .then((res: string | null) => {
        self.wizardSubmitStatus = 'submitted'
        self.wizardBackEndOperation(false)
        // submitted ok, route to it
        if (!res) {
          eventBus.$emit('errorHandler', 'error submitting job (SaveDrainageClaim), please try again', true)
          self.wizardSubmitStatus = 'none'
          return
        }

        // get list of upload type
        this.getUploadedDocumentTypes(res)
        self.createdJobId = res
        self.$router.push({
          name: 'job',
          params: {
            jobId: res,
          },
        })
      })
      .catch((err: any) => {
        self.wizardBackEndOperation(false)
        self.wizardSubmitStatus = 'none'
        eventBus.$emit('errorHandler', err, false)
      })
  }

  private checkIsNumberAlreadyAssigned(phoneNumber: string): boolean {
    let numberAlreadyAssignes = false
    // new, check number already exists
    const index = this.customerExistingPhoneNumbersList.findIndex(
      (x: InsurerPhoneNumberModel) => x.phoneNumber === phoneNumber
    )
    if (index !== -1) {
      numberAlreadyAssignes = true
    }
    return numberAlreadyAssignes
  }

  private async validateStep(step: number): Promise<[number, string, boolean]> {
    const self = this
    let scope = ''
    switch (step) {
      case 1:
        scope = self.step1formRef
        break
      case 2:
        scope = self.step2formRef
        break
      case 3:
        scope = self.step3formRef
        break
      default:
        return [step, scope, false]
    }

    let result: boolean = await self.$validator.validateAll(scope)

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    } else {
      let result2 = true
      if (step === 1) {
        const subs = this.$refs.addCustomerAddress as CustomerAddress
        result2 = await subs.$validator.validateAll()
        if (!result2) {
          Shared.setValidationFocus(this.$el as HTMLElement)
          result = result2
        }
      }
      if (step === 2) {
        const subs = this.$refs.addCommodity as AddDrainageCommodity
        result2 = await subs.$validator.validateAll()
        if (!result2) {
          Shared.setValidationFocus(this.$el as HTMLElement)
          result = result2
        }
      }
      if (step === 3) {
        const sub3 = this.$refs.contactPreferenceDetails as ContactPreferenceDetails
        result = await sub3.validateContactPreferenceStep()
      }
    }
    return [step, scope, result]
  }

  private updateContactPreference(updateCustomerContactPreferenceModel: UpdateCustomerContactPreferenceModal) {
    this.customerContactPreferenceModal = updateCustomerContactPreferenceModel
  }
  private updatePolicySelection(newSelected: SelectedPolicy) {
    this.selectedPolicy = newSelected
    if (this.selectedPolicy && this.selectedPolicy.policy) {
      this.claimModel.policyScheduleId = this.selectedPolicy.policy.id
      this.claimModel.policyScheme = this.selectedPolicy.policy.name
      this.claimModel.insurer = this.selectedPolicy.insurer ? this.selectedPolicy.insurer.name : ''
      const policySelector: any = this.$el.querySelector('#policySelector')
      setTimeout(() => {
        policySelector.blur()
      }, 100)
    } else {
      this.selectedPolicy = { insurer: null, brand: null, policy: null }
    }
  }

  private getUploadedDocumentTypes(newJobId: string) {
    const self = this
    this.uploadedDocumentTypes = []
    this.$emit('showUploadDocument', true)
    DocumentController.GetUploadedDocumentTypes(this.jobType).then((res: UploadedDocumentTypeModel[]) => {
      // error while getting jobtypes, route to it
      if (!res) {
        eventBus.$emit('errorHandler', 'error getting job types, please try to add from new job card', true)
        self.wizardSubmitStatus = 'none'
        self.sendWizardCompleteEvent()
        return
      }

      self.uploadedDocumentTypes = res
    })
  }

  private resetUploadDocumentProperties() {
    this.$emit('showUploadDocument', false)
    this.createdJobId = ''
  }

  private onDocumentUploadComplete() {
    this.sendWizardCompleteEvent()
    eventBus.$emit('documentUploaded', true)
  }
}
</script>

<style scoped>
.v-dialog__content .v-dialog >>> .v-card,
.application .theme--light.v-stepper,
.theme--light .v-stepper {
  background: #fafafa;
}
.v-dialog >>> .v-stepper {
  background: inherit;
}
.v-dialog .v-stepper >>> .v-stepper__header {
  box-shadow: none;
  text-transform: uppercase;
}
.v-dialog .v-stepper >>> .v-stepper__content {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
}
.application .theme--light.v-stepper .v-stepper__step--editable:hover,
.theme--light .v-stepper .v-stepper__step--editable:hover {
  background: none;
}
.shadow-outer {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.enquiry-step3 {
  width: 240px;
}
.v-form > .container > .layout:only-child {
  margin: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-text-field__details {
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  font-size: 12px;
  min-height: 26px;
  overflow: hidden;
  position: relative;
  width: 100%;
  align-items: center;
}
.policy-logo {
  width: 118px;
  height: 100%;
  webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.policy-logo img {
  padding: 0px 15px;
  width: 100%;
  margin: auto;
  position: ABSOLUTE;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.emergency-info-table {
  width: 100%;
  padding-left: 15px;
}
.emergency-info-table .shadow-outer.emergency-table {
  height: 100%;
}
.emergency-info-table .shadow-outer.emergency-table {
  height: 100%;
}

.search-job-item > div {
  position: relative;
}
.search-job-item .seach-loader {
  position: absolute !important;
  top: 13px;
  right: 35px;
  margin: 0px;
}
.search-job-item .v-input {
  position: relative;
}
.search-job-item .v-input .v-input__prepend-outer {
  margin: 0;
}
.search-job-item .v-input .v-input__icon--prepend {
  position: absolute;
  left: 0;
}
.search-job-item .v-input .v-text-field__details {
  max-width: calc(100% - 0px);
}
.search-job-item .v-input__slot {
  padding-left: 40px;
  padding-right: 40px;
}
.search-job-item .v-input .v-select__slot .v-input__append-inner {
  position: absolute;
  right: -30px;
}
</style>
