import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'

export default class VulnerabilityQA extends CosmosJobsDbRecordBase {
  questionText: string
  isHealthAffected: boolean | null
  level: AnswerLevel

  public constructor() {
    super()
    // set defaults
    this.questionText = ''
    this.isHealthAffected = null
    this.level = AnswerLevel.None
  }
}

export enum AnswerLevel {
  All = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  None = 4,
}
