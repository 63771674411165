<template>
  <span>
    {{ job.loggedVia }}
  </span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
@Component
export default class FNOLCardFooter extends TimeLineItemBase {}
</script>
