export default class AddEngineerMaterialAndExtraTimeRequest {
  public materialName
  public materialType
  public quantity
  public materialCost
  public isPartAvailable
  public jobId
  public forEmergencyTypeId
  public forEmergencyDetailId
  public contractorId
  public companyName
  public requestType
  public description
  public requestStatus
  public fileURL
  public address
  public requestProcessedAt
  public extraHours
  public timeRequestReason
}
