<template>
  <div v-if="getRepairEstimates" xs12 class="bg-white pa-3">
    <v-layout v-for="(repairEstimateDetail, index) in getRepairEstimates" :key="index" row wrap>
      <v-flex xs7>
        <span class="font-weight-bold estimate-name">
          {{ repairEstimateDetail.estimateName }}
        </span>
      </v-flex>
      <v-flex xs5 text-xs-right>
        <v-btn
          flat
          icon
          color="primary"
          class="ma-0 btn-editRepairEstimate"
          @click="editRepairEstimate(repairEstimateDetail)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          flat
          icon
          color="secondary"
          class="ma-0 mr-1 btn-deleteRepairEstimate"
          @click="deleteRepairEstimate(repairEstimateDetail.id, index)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs12 class="gridView mt-2 mb-2">
        <v-data-table
          :headers="headers"
          :items="repairEstimateDetail.products"
          class="elevation-1"
          item-key="id"
          hide-actions
          :loading="isLoading && currentIndex === index"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.description }}</td>
              <td class="text-xs-center">
                {{ getFormatedCurrency(props.item.clientPrice) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <v-divider v-if="index !== getRepairEstimates.length - 1" class="ml-1 mt-2 mb-2"></v-divider>
    </v-layout>
    <RepairEstimationDialog
      v-if="showRepairEstimationDialog"
      ref="repairEstimationDialog"
      :policy-client-rate-package-id="job && job.policyClientRatePackageId ? job.policyClientRatePackageId : ''"
      :job-id="jobId"
      :products="selectedRepairEstimateDetail.products"
      :repair-estimate-id="selectedRepairEstimateDetail.id"
      @closeRepairEstimationDialog="onCloseRepairEstimationDialog"
    ></RepairEstimationDialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import RepairEstimateModel from '../../models/undergroundServices/RepairEstimateModel'
import storeGetters from '../../storeGetters'
import RepairEstimationDialog from '@/components/RepairEstimationDialog.vue'
import Shared from '../../common/shared'
import SiteInvestigationController from '../../api/siteInvestigationController'
import DeleteRepairEstimateModel from '../../models/undergroundServices/DeleteRepairEstimateModel'
import eventBus from '../../common/bus'

@Component({
  components: { RepairEstimationDialog },
})
export default class RepairEstimatesPreview extends TimeLineItemBase {
  private showRepairEstimationDialog = false
  private selectedRepairEstimateDetail: RepairEstimateModel | null = null
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText: string | null = ''
  private isLoading = false
  private currentIndex = -1
  private headers: any = [
    { text: 'Description', value: 'description', sortable: false },
    {
      text: 'Client Price',
      value: 'clientPrice',
      sortable: false,
      align: 'center',
    },
  ]

  private get getRepairEstimates(): RepairEstimateModel[] {
    return storeGetters.getRepairEstimateDetail(this.jobId)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private editRepairEstimate(repairEstimateDetail: RepairEstimateModel) {
    this.selectedRepairEstimateDetail = repairEstimateDetail
    this.showRepairEstimationDialog = true
  }

  private deleteRepairEstimate(repairEstimateId: string, index: number) {
    Shared.confirmationPopup.open(
      'Are you sure you want to delete estimation detail?',
      '',
      '',
      'No',
      'Yes',
      this,
      'onDeleteRepairEstimateDetail',
      { repairEstimateId, index }
    )
  }

  private onDeleteRepairEstimateDetail(arg: { repairEstimateId: string; index: number }) {
    this.currentIndex = arg.index
    const deleteRepairEstimate: DeleteRepairEstimateModel = new DeleteRepairEstimateModel()
    deleteRepairEstimate.id = arg.repairEstimateId
    deleteRepairEstimate.jobId = this.jobId
    this.isLoading = true
    SiteInvestigationController.DeleteRepairEstimateAsync(deleteRepairEstimate)
      .then((res: boolean) => {
        this.showMessage(
          res ? 'Repair estimation detail deleted successfully.' : 'Something went wrong. Please try again.'
        )
        this.isLoading = false
      })
      .catch((err: any) => {
        this.isLoading = false
        eventBus.$emit('errorHandler', err, true)
      })
  }

  private showMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private onCloseRepairEstimationDialog(message = '') {
    if (message) {
      this.showMessage(message)
    }
    this.selectedRepairEstimateDetail = null
    this.showRepairEstimationDialog = false
  }
}
</script>

<style scoped>
.estimate-name {
  display: table-cell;
  padding: 10px 5px;
}
</style>
