import Store from '@/store'
import moment, { type Moment } from 'moment'

export function formatDateTime(
  input: Moment | Date | string | null | undefined,
  format = Store.Instance.state.Environment.DateTimeFormat
): string {
  const fallback = '--/--/----'

  if (!input) {
    return fallback
  }

  const date = moment(input)
  if (!date.isValid()) {
    return fallback
  }

  return date.format(format)
}
