import { render, staticRenderFns } from "./SIRevisit.vue?vue&type=template&id=7dc60075&scoped=true"
import script from "./SIRevisit.vue?vue&type=script&lang=ts"
export * from "./SIRevisit.vue?vue&type=script&lang=ts"
import style0 from "./SIRevisit.vue?vue&type=style&index=0&id=7dc60075&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dc60075",
  null
  
)

export default component.exports