<template>
  <div>
    <v-flex v-if="getRepairEstimates && getRepairEstimates.length > 0" xs12>
      <v-layout v-for="(repairEstimate, index) in getRepairEstimates" :key="index" row wrap>
        <v-flex xs12>
          <div class="font-weight-bold pb-1">
            {{ repairEstimate.estimateName }}
          </div>
          <span>
            {{ repairEstimate.products.length }} * items =
            {{ getFormatedCurrency(getEstimateWiseTotal(repairEstimate)) }}
          </span>
        </v-flex>
        <v-divider v-if="index !== getRepairEstimates.length - 1" class="ml-1 mt-2 mb-2"></v-divider>
      </v-layout>
    </v-flex>
    <v-flex xs12 mt-4>
      <span class="font-weight-bold grey--text">Total Price: {{ getFormatedCurrency(getTotalPrice) }}</span>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import RepairEstimateModel from '../../models/undergroundServices/RepairEstimateModel'
import storeGetters from '../../storeGetters'
import Shared from '../../common/shared'

@Component
export default class RepairEstimatesCard extends TimeLineItemBase {
  private get getRepairEstimates(): RepairEstimateModel[] {
    return storeGetters.getRepairEstimateDetail(this.jobId)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private getEstimateWiseTotal(repairEstimate: RepairEstimateModel) {
    return repairEstimate.products.reduce(
      (a, b) => a + (b.clientPrice + (b.clientPrice * (b.vatRate ? b.vatRate : 1)) / 100),
      0
    )
  }

  private get getTotalPrice(): number {
    let total = 0
    this.getRepairEstimates.map((r) => {
      total += r.products.reduce((a, b) => a + (b.clientPrice + (b.clientPrice * (b.vatRate ? b.vatRate : 1)) / 100), 0)
    })
    return total
  }
}
</script>
