import { DocumentTemplateType } from '@/common/enums'
export default class SendJobEmail {
  public jobId: string
  public documentTemplateType: DocumentTemplateType

  public accessibleRecords: {}

  // To be replaced
  public contractorAppointedDetailId: string

  public documentTemplateId?: number

  public sendToNominee: boolean
}
