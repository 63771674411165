import { AnswerType, OperatorType } from '@/common/enums'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import storeMutations from '@/storeMutations'
export type ValidationResult = 'unknown' | 'valid' | 'manual' | 'invalid' | 'missing' | 'unanswered'

export default class EmergencyQA extends CosmosJobsDbRecordBase {
  // properties gathered from DB //
  public forEmergencyTypeId: number | null // if this QA is for emergency type level the emergency type id it is for, otherwise null
  public forEmergencyDetailId: number | null // if this QA is for emergency detail level the emergency detail id it is for, otherwise null

  public policyQuestionId: number // the id of the policy question
  public questionId: number // the id of the question from the policy holder db
  public questionText: string // question text to display
  public orderBy: number // order id for this question within its group (from db)

  public answer: string // answer given (as string)
  public answerType: AnswerType // type the answer should be
  public additionalValues: string | null // if answerType === AnswerType.Dropdown the possible values, otherwise null
  public unit: string | null // if answerType === AnswerType.Number the possible values, otherwise null

  public operatorType: OperatorType | null // if the answer should be validated how should it be compared, otherwise set to 'none'
  public comparisonValue: string // the value (as string) to use for comparison

  public keyQuestion: boolean
  public mandatory: boolean

  public forCommentOperatorId: OperatorType | null // for get additional information
  public forCommentComparisonValue: string | null
  public commentLabel: string | null
  public comment: string | null

  public repudiationDescription: string | null
  public repudiationMessage: string | null
  public note: string | null
  public asbestosEffectLevel: number

  // added for create emergency based on answer
  public questionOperatorId: number
  public questionComparisonValue: string
  public conditionalEmergencyTypeId: number
  public conditionalEmergencyDetailId: number

  // eslint:disable-next-line:variable-name
  public __validationErrorMessage: string

  public constructor() {
    super()
    // set defaults
    this.forEmergencyTypeId = null
    this.forEmergencyDetailId = null
    this.additionalValues = null
    this.operatorType = null
    this.orderBy = 0
    this.answerType = AnswerType.Text
    this.keyQuestion = false
    this.mandatory = false
    this.repudiationMessage = null
    this.asbestosEffectLevel = 0
  }

  // computed properties

  // if the answer type is dropdown returns the
  // list of possible options gathered fom the additionalValues new line
  // seperated string. otherwise returns an empty list.
  public get dropdownOptions(): string[] {
    if (this.answerType === AnswerType.Dropdown && this.additionalValues) {
      const items = this.additionalValues.split('\n')
      if (items && items.length > 0) {
        for (let i = 0, l = items.length; i < l; i++) {
          items[i] = items[i].trim()
        }
        return items
      }
    }
    return []
  }

  // returns the number to use to order by when displaying QA form
  public get orderByForDisplay(): number {
    let prefix: number
    if (this.forEmergencyDetailId === null) {
      if (this.forEmergencyTypeId === null) {
        prefix = 30000 // job level question (show all after emergency & detail level)
      } else {
        prefix = 10000 // emergency level question (show all first)
      }
    } else {
      prefix = 20000 // detail question (show all after emergency)
    }
    return prefix + this.orderBy
  }

  public get isValid(): boolean {
    switch (this.validationStatus) {
      case 'unknown':
      case 'valid':
      case 'manual':
        return true
      case 'invalid':
      case 'missing':
      case 'unanswered':
      default:
        return false
    }
  }

  public get validationErrorMessage(): string {
    if (!this.isValid && this.__validationErrorMessage) {
      return this.__validationErrorMessage
    }
    return ''
  }

  public setValidationErrorMessage(message: string) {
    storeMutations.updateValidationErrorMessage(this.jobId, this.id, message)
  }

  // checks if the given answer is valid based on the
  // additional comment validation rule and returns the result
  public get validationForAdditionalInfoStatus(): ValidationResult {
    if (this.forCommentComparisonValue === null && this.forCommentOperatorId === null) {
      return 'unknown'
    }
    if (!this.answer) {
      return 'unanswered'
    }
    // validate based on comparison method and madatory flag
    switch (this.answerType) {
      case AnswerType.Text:
        return this.validateDropDownAndTextBox(this.forCommentOperatorId, this.forCommentComparisonValue)
      case AnswerType.Dropdown:
        return this.validateDropDownAndTextBox(this.forCommentOperatorId, this.forCommentComparisonValue)
      case AnswerType.Number:
        return this.validateNumber(this.forCommentOperatorId, this.forCommentComparisonValue)
      case AnswerType.Checkbox:
        return this.validateBoolean(this.forCommentOperatorId, this.forCommentComparisonValue)
      default:
        // unexpected type, dont know how to validate
        return 'unknown'
    }
  }

  // checks if the given answer is valid based on the
  // comparison rule and returns the result
  public get validationStatus(): ValidationResult {
    // validate based on comparison method and madatory flag

    // madatory check
    if (!this.answer && this.mandatory) {
      this.setValidationErrorMessage('an answer to this question is mandatory')
      return 'missing'
    }

    if (!this.answer) {
      return 'unanswered'
    }

    // first check if we validation required, if not then just skip
    if (this.operatorType === null) {
      return 'valid'
    }

    switch (this.answerType) {
      case AnswerType.Text:
        return this.validateDropDownAndTextBox(this.operatorType, this.comparisonValue)
      case AnswerType.Dropdown:
        return this.validateDropDownAndTextBox(this.operatorType, this.comparisonValue)
      case AnswerType.Number:
        return this.validateNumber(this.operatorType, this.comparisonValue)
      case AnswerType.Checkbox:
        return this.validateBoolean(this.operatorType, this.comparisonValue)
      default:
        // unexpected type, dont know how to validate
        return 'unknown'
    }
  }

  private validateDropDownAndTextBox(
    operatorType: OperatorType | null,
    comparisonValue: string | null
  ): ValidationResult {
    let comparingValue = ''
    if (comparisonValue) {
      comparingValue = comparisonValue.trim()
    }
    let res: boolean
    const self = this
    switch (operatorType) {
      case null:
        res = true
        break
      case OperatorType.EqualTo:
        res = this.answer.trim() === comparingValue
        break
      case OperatorType.NotEqualTo:
        res = this.answer.trim() !== comparingValue
        break
      case OperatorType.In:
        const items = comparingValue ? comparingValue.split(',') : null
        let validResult = false
        if (items) {
          items.forEach((item) => {
            if (this.answer.trim() === item.trim()) {
              validResult = true
            }
          })
        }
        res = validResult
        break
      case OperatorType.NotIn:
        const itemsNotIn: any = comparingValue ? comparingValue.split(',') : null
        let validResultNotIn = true
        if (itemsNotIn) {
          itemsNotIn.forEach((item) => {
            if (self.answer.trim() === item.trim()) {
              validResultNotIn = false
              return
            }
          })
        }
        res = validResultNotIn
        break
      default:
        // bad choice for dropdown
        return 'unknown'
    }
    if (res) {
      return 'valid'
    } else {
      if (this.repudiationMessage) {
        this.setValidationErrorMessage(this.repudiationMessage)
      }
      return 'invalid'
    }
  }

  private validateNumber(operatorType: OperatorType | null, comparisonValue: string | null): ValidationResult {
    let num: number
    let cNum = 0
    const cNums: number[] = []

    num = parseFloat(this.answer)
    if ((operatorType === OperatorType.In || operatorType === OperatorType.NotIn) && comparisonValue) {
      comparisonValue.split(',').forEach((ans) => {
        cNums.push(parseFloat(ans.trim()))
      })
    } else {
      cNum = comparisonValue ? parseFloat(comparisonValue) : 0
    }

    let res: boolean
    switch (operatorType) {
      case null:
        res = true
        break
      case OperatorType.GreaterThan:
        res = num > cNum
        break
      case OperatorType.LessThan:
        res = num < cNum
        break
      case OperatorType.GreaterThanOrEqualTo:
        res = num >= cNum
        break
      case OperatorType.LessThanOrEqualTo:
        res = num <= cNum
        break
      case OperatorType.EqualTo:
        res = num === cNum
        break
      case OperatorType.NotEqualTo:
        res = num !== cNum
        break
      case OperatorType.In:
        res = cNums.indexOf(num) > -1
        break
      case OperatorType.NotIn:
        res = !(cNums.indexOf(num) > -1)
        break
      default:
        // unexpected conparision type for number
        return 'unknown'
    }
    if (res) {
      return 'valid'
    } else {
      if (this.repudiationMessage) {
        this.setValidationErrorMessage(this.repudiationMessage)
      }
      return 'invalid'
    }
  }

  private validateBoolean(operatorType: OperatorType | null, comparisonValue: string | null) {
    // note: comparision value should be either 'true' or 'false';
    let res: boolean
    switch (operatorType) {
      case null:
        res = true
        break
      case OperatorType.EqualTo:
        res = this.answer === comparisonValue
        break
      case OperatorType.NotEqualTo:
        res = this.answer !== comparisonValue
        break
      default:
        return 'unknown'
    }
    if (res) {
      return 'valid'
    } else {
      if (this.repudiationMessage) {
        this.setValidationErrorMessage(this.repudiationMessage)
      }
      return 'invalid'
    }
  }
}
