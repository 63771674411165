import { ReportStatus, SiteDataRecordType } from '@/common/enums'
export default class ReportDetail {
  public jobId: string
  public reportStatus: ReportStatus
  public requestedAt: string
  public builtAt: string
  public includeData: IncludeData[]
}

export class IncludeData {
  public type: 'Document' | 'Data'
  public dataType: SiteDataRecordType
  public id: string
  public sequence: number
}
