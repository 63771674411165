<template>
  <v-flex xs12>
    <v-layout>
      <v-flex :class="showDateTimePicker && selectedFileList.length > 0 ? 'xs6' : 'xs12'">
        <v-text-field
          :disabled="isDisabled"
          :label="label"
          append-icon="attach_file"
          :hide-details="true"
          :class="isRequiredValidation ? 'required' : ''"
          @click="pickFile"
        ></v-text-field>
        <input
          ref="fileUpload"
          v-validate="{
            rules: { required: isRequiredValidation, size: getAttachmentSize },
          }"
          type="file"
          style="display: none"
          name="file"
          multiple
          :disabled="isDisabled"
          @change="onFilePicked"
        />
        <div class="input-group__details">
          <div class="input-group__messages input-group__error input-group--error error--text">
            <span>{{ fileValidationMessage(errors, fileErrorMessage) }}</span>
          </div>
        </div>
        <div class="uploaded-files mt-3">
          <span v-for="file in selectedFileList" :key="file.fileName" class="elevation-1">
            <div class="image-action hover-content-section">
              <span v-if="typeof file.fileURL === 'string'">
                <img
                  v-if="
                    file.fileType.toLowerCase() === 'jpg' ||
                    file.fileType.toLowerCase() === 'jpeg' ||
                    file.fileType.toLowerCase() === 'png'
                  "
                  :src="file.fileURL"
                  :title="file.fileName"
                  @click="uploadCarousel = true"
                />
                <img v-else :src="documentTypeWiseThumbnail(file.fileType.toLowerCase())" :title="file.fileName" />
              </span>
              <span v-else>
                <img :src="documentTypeWiseThumbnail(file.fileType.toLowerCase())" :title="file.fileName" />
              </span>
              <div class="grey lighten-3 hover-item">
                <v-btn
                  icon
                  small
                  flat
                  color="primary"
                  class="elevation-0 upload-img-action-btn ma-0"
                  @click.native.stop="removeFile(file)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-btn
                  v-if="typeof file.fileURL === 'string' && showDownloadButton(file.fileURL)"
                  icon
                  flat
                  small
                  color="primary"
                  class="elevation-0 upload-img-action-btn ma-0"
                  @click.native.stop="downloadFile(file.fileURL)"
                >
                  <v-icon>get_app</v-icon>
                </v-btn>
              </div>
            </div>
          </span>
        </div>
        <v-dialog v-model="uploadCarousel" max-width="50%">
          <v-card>
            <v-btn icon flat color="primary" class="uploadCarousel-btn white" @click="uploadCarousel = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-carousel :cycle="false">
              <v-carousel-item
                v-for="(item, i) in selectedFileList.filter(
                  (e) =>
                    (e.fileType.toLowerCase() === 'png' ||
                      e.fileType.toLowerCase() === 'jpg' ||
                      e.fileType.toLowerCase() === 'jpeg') &&
                    typeof e.fileURL === 'string'
                )"
                :key="i"
                :src="item.fileURL"
              ></v-carousel-item>
            </v-carousel>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex v-if="showDateTimePicker && selectedFileList.length > 0" xs6>
        <DateTimePicker
          ref="dateTimePicker"
          :date-time.sync="getDateTime"
          :is-static-location="false"
          place-holder-text="Document Expiration Date"
          :is-current-time="false"
          :is-validation-required="isDateValidationRequired"
          :show-present-time-icon="false"
          :hide-time-picker="true"
          :display-format="getDateFormat"
          :disabled="isDisabled"
          :min-date="minDate"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import store from '@/store'
import ContractorController from '@/api/contractorController'
import UploadedContractorDocumentDetail from '@/models/contractor/UploadedContractorDocumentDetail'
import eventBus from '@/common/bus'
import moment from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import Store from '@/store'

interface IFile {
  fileName: string
  fileURL: string
  fileType: string
  uploadFileFor: string
  expirationDate: moment.Moment | null
}

@Component({
  components: { DateTimePicker },
})
export default class FileUpload extends Vue {
  public selectedFileList: IFile[] = []
  public fileErrorMessage = ''
  @Prop({ default: '' }) public uploadDocumentFor: string
  @Prop({ default: false }) public showDateTimePicker: boolean
  @Prop() private uploadedFiles: UploadedContractorDocumentDetail[]
  @Prop({ default: '' }) private id: string // Id = contractorId for contractor
  @Prop({ default: 'Upload Files' }) private label: string
  @Prop({ default: false }) private isRequiredValidation: boolean
  @Prop({ default: false }) private isDisabled: boolean
  @Prop({ default: true }) private isDateValidationRequired: boolean
  private selectedDate: moment.Moment | null = null
  private fileUrl = ''
  private uploadCarousel = false
  private minDate: string = moment().format(DateTimePicker.DATE_FORMAT)

  // return validation message on file selection if any.
  public fileValidationMessage(err: any, fileErrorMessage: any) {
    if (err && err.collect('file').length > 0) {
      const message: string = err.collect('file')[0]
      this.fileErrorMessage = message
      return message
    } else if (fileErrorMessage !== '') {
      this.fileErrorMessage = fileErrorMessage
      return this.fileErrorMessage
    }
  }

  public removeUnUploadedFiles(uploadFileFor: string) {
    this.selectedFileList = this.selectedFileList.filter((x) => x.uploadFileFor !== uploadFileFor)
    this.getDateTime = null
  }

  public get getDateTime(): moment.Moment | null {
    return this.selectedDate
  }

  public set getDateTime(newValue: moment.Moment | null) {
    this.selectedDate = newValue
  }

  @Watch('Id')
  private onContractorChange() {
    this.selectedFileList = []
    this.getDateTime = null
  }

  @Watch('uploadedFiles')
  private getUploadedFileListOnUpdate() {
    const self = this
    this.uploadedFiles.forEach((file) => {
      const uploadedFile: IFile | undefined = self.selectedFileList.find((e) => e.fileName === file.documentName)
      if (uploadedFile) {
        uploadedFile.fileURL = file.documentUrl
        uploadedFile.uploadFileFor = ''
      } else {
        const fileSelected: any = {}
        fileSelected.fileName = file.documentName
        fileSelected.fileURL = file.documentUrl
        fileSelected.fileType = fileSelected.fileName.substring(fileSelected.fileName.lastIndexOf('.') + 1)
        fileSelected.uploadFileFor = ''
        fileSelected.expirationDate = file.documentExpirationDate
        this.selectedFileList.push(fileSelected)
      }
    })
    // set date of last uploaded document
    if (this.uploadedFiles.length > 0 && this.uploadedFiles[0].documentExpirationDate) {
      const getLastUploadedDocument: moment.Moment | null =
        this.uploadedFiles[this.uploadedFiles.length - 1].documentExpirationDate
      if (getLastUploadedDocument) {
        this.getDateTime = moment(getLastUploadedDocument)
      }
    }
  }

  private mounted() {
    this.getUploadedFileListOnUpdate()
  }

  private get getAttachmentSize(): number {
    return store.Instance.state.Environment.AudioAttachmentSize
  }

  private pickFile() {
    const fileError = this.$validator.errors.items.find((a) => a.field === 'file')
    if (fileError) {
      fileError.msg = ''
    }
    const file = this.$refs.fileUpload as any
    file.click()
  }

  private onFilePicked(event: any) {
    const selectedFileList: any = event.target.files
    const self = this
    for (const file of selectedFileList) {
      if (file !== undefined) {
        const fileName = file.name.split(' ').join('_')
        if (fileName.lastIndexOf('.') <= 0) {
          return
        }
        const existingFile: any = self.selectedFileList.find((e) => e.fileName === fileName)
        if (existingFile) {
          this.fileValidationMessage(null, 'file with name ' + fileName + ' already exist.')
        } else {
          this.fileErrorMessage = ''
          const fr = new FileReader()
          fr.readAsDataURL(file)
          fr.addEventListener('load', () => {
            if (self.fileErrorMessage === null || self.fileErrorMessage === '') {
              self.fileUrl = fr.result as string
              // save file to local filelist.
              const fileSelected: any = {}
              fileSelected.fileName = fileName
              fileSelected.fileURL = file
              fileSelected.fileType = fileName.substr(fileName.lastIndexOf('.') + 1)
              fileSelected.uploadFileFor = self.uploadDocumentFor
              self.selectedFileList.push(fileSelected)
              this.fileUrl = ''
              const fileUpload = this.$refs.fileUpload as any
              fileUpload.value = null
            }
          })
        }
      }
    }
  }

  private removeFile(file: any) {
    if (typeof file.fileURL === 'object') {
      // remove file from local filelist.
      const fileToRemove: IFile | undefined = this.selectedFileList.find((e) => e.fileName === file.fileName)
      if (fileToRemove) {
        this.selectedFileList.splice(this.selectedFileList.indexOf(fileToRemove), 1)
      }
    } else {
      // remove file from azure.
      Shared.confirmationPopup.open(
        'Are you sure you want to delete this document?',
        '',
        '',
        '',
        '',
        this,
        'deleteUploadedFile',
        file
      )
    }
    this.fileUrl = ''
    const fileUpload = this.$refs.fileUpload as any
    fileUpload.value = null
  }

  private deleteUploadedFile(file: any, isLastDocument = false, data: any) {
    const self = this
    const fileUrl = file.fileURL.split('?')[0]
    ContractorController.DeleteUploadedFile(self.id, fileUrl)
      .then((res: boolean) => {
        if (res) {
          eventBus.$emit('onDocumentDelete', file.fileURL)
          const index = self.selectedFileList.findIndex((x) => x.fileURL === file.fileURL)
          if (index !== -1) {
            self.selectedFileList.splice(index, 1)
          }
          if (isLastDocument) {
            self.$emit('removeSelectedAssociation', data.item)
          }
        }
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error deleting uploaded file, please try again', true)
      })
  }

  private downloadFile(fileURL: string) {
    // get the document of url and open it in new tab
    window.open(fileURL, '_blank')
  }

  private showDownloadButton(fileURL: string) {
    // show download button once saved and document uploaded to server
    return fileURL.includes(this.id) ? true : false
  }

  private documentTypeWiseThumbnail(fileType: string) {
    if (
      fileType === 'pdf' ||
      fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'xls' ||
      fileType === 'xlsx' ||
      fileType === 'mp3' ||
      fileType === 'txt' ||
      fileType === 'ppt' ||
      fileType === 'pptx' ||
      fileType === 'svg' ||
      fileType === 'avi' ||
      fileType === 'csv' ||
      fileType === 'zip' ||
      fileType === 'mp4' ||
      fileType === 'jpg' ||
      fileType === 'jpeg' ||
      fileType === 'png'
    ) {
      fileType =
        fileType === 'jpeg'
          ? 'jpg'
          : fileType === 'xlsx'
          ? 'xls'
          : fileType === 'pptx'
          ? 'ppt'
          : fileType === 'docx'
          ? 'doc'
          : fileType
      return '/img/' + fileType + '.svg'
    }
    return '/img/file.svg'
  }

  private get getDateFormat(): string {
    return Store.Instance.state.Environment.DateFormat
  }
}
</script>

<style type="text/css" scoped>
.uploaded-files span {
  position: relative;
  margin-right: 10px;
  display: inline-flex;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  vertical-align: text-bottom;
  margin-bottom: 10px;
  cursor: pointer;
}
.uploaded-files span img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #f1f1f1;
}
.uploaded-files span.filedoc img {
  height: 40px;
  width: 40px;
  margin: auto;
}
.image-action {
  min-width: auto !important;
}
.uploadCarousel-btn {
  position: absolute !important;
  right: 0px;
  top: 0px;
  z-index: 1;
}
.hover-content-section .hover-item {
  display: none;
  position: absolute;
}
.hover-content-section {
  position: relative;
}
.hover-content-section:hover .hover-item {
  display: block;
  position: absolute;
  top: -20px;
  left: 0px;
  right: 0;
  text-align: center;
}
</style>
