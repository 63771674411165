<template>
  <v-layout wrap>
    <v-flex v-if="item.escalationReason" xs12>
      <label><b>Escalation Reason:</b></label>
      <span>{{ item.escalationReason }}</span>
    </v-flex>
    <v-flex v-if="item.escalationDescription" xs12>
      <label><b>Escalation Description:</b></label>
      <span>{{ item.escalationDescription }}</span>
    </v-flex>
    <v-flex v-if="item.deEscalationDescription" xs12 class="de-escalationDescription">
      <label><b>De-Escalation Description:</b></label>
      <span>{{ item.deEscalationDescription }}</span>
    </v-flex>
    <v-flex v-if="item.deEscalationDescription && item.deEscalatedAt" xs12>
      <label><b>De-Escalated At:</b></label>
      <span>{{ getFormatedDate(item.deEscalatedAt) }}</span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import JobEscalationDetail from '@/models/claim/JobEscalationDetailModel'
import Shared from '@/common/shared'
import Store from '@/store'
import moment, { isMoment } from 'moment'

@Component
export default class JobEscalationCard extends TimeLineItemBase {
  private get item(): JobEscalationDetail | undefined {
    return this.job!.jobEscalationDetails.find((x) => x.id === this.itemId)
  }

  public getFormatedDate(createdAt: moment.Moment): string {
    if (createdAt) {
      return Shared.getFormatedDate(moment(createdAt), Store.Instance.state.Environment.DateTimeFormat)
    }
    return ''
  }
}
</script>

<style scoped>
.status {
  padding-top: 20px;
}
</style>
