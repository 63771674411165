import moment from 'moment'

export default class ContractorAcceptAutoDeploymentJob {
  public jobId: string
  public contractorAppointedId: string
  public hasAcceptJob: boolean
  public etaFrom: moment.Moment | null
  public etaTo: moment.Moment | null

  public constructor() {
    this.etaFrom = null
    this.etaTo = null
  }
}
