import EmergencyCoverageModel from '@/models/policyHolder/EmergencyCoverageModel'
import PolicyQuestionModel from '@/models/policy/PolicyQuestionModel'
import PolicyRouteToMarketModel from '@/models/policy/PolicyRouteToMarketModel'
import PolicyDocumentTemplateModel from '@/models/policy/PolicyDocumentTemplateModel'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'
import PolicyAccommodationDetailModel from '@/models/policy/PolicyAccommodationDetailModel'
import PolicyCommunicationDetailModel from '@/models/policy/PolicyCommunicationDetailModel'
import PolicyBasicDetailModel from '@/models/policy/PolicyBasicDetailModel'

export default class PolicyModel {
  public policyBasicDetail: PolicyBasicDetailModel
  public emergencyCoverage: EmergencyCoverageModel[]
  public policyQuestion: PolicyQuestionModel[]
  public policyRouteToMarket: PolicyRouteToMarketModel[]
  public policyDocumentTemplates: PolicyDocumentTemplateModel[]
  public thirdPartyDetail: EmergencyThirdPartyTrade[]
  public policyCommunicationDetail: PolicyCommunicationDetailModel
  public policyAccommodationDetail: PolicyAccommodationDetailModel

  public constructor() {
    this.policyRouteToMarket = [new PolicyRouteToMarketModel()]
    if (!this.thirdPartyDetail) {
      this.thirdPartyDetail = []
    }
    if (!this.emergencyCoverage) {
      this.emergencyCoverage = []
    }
    if (!this.policyQuestion) {
      this.policyQuestion = []
    }
    this.policyDocumentTemplates = []
    this.policyBasicDetail = new PolicyBasicDetailModel()
    this.policyCommunicationDetail = new PolicyCommunicationDetailModel()
    this.policyAccommodationDetail = new PolicyAccommodationDetailModel()
  }
}
