<template>
  <v-layout wrap>
    <v-flex v-if="getIsThirdPartyContractor" xs12>
      <h4>Warm Transfer Completed.</h4>
    </v-flex>
    <v-flex
      v-else-if="getAssignedContractor && getAssignedContractor.status === jobVisitStatus[jobVisitStatus.AutoDeploying]"
    >
      <b>Auto deployment of engineers is running</b>
    </v-flex>
    <v-flex v-else xs12>
      <div class="mb-1">
        <b>ETA From:</b>
        {{ getContractorEtaFrom }}
      </div>
      <div class="mb-1">
        <b>ETA To:</b>
        {{ getContractorEtaTo }}
      </div>
      <div v-if="getContractorAdditionalTravelTime" class="mb-1">
        Additional Travel Time:
        <b class="secondary--text">
          {{ getContractorAdditionalTravelTime }} (&pound; {{ getContractorAdditionalPay }})
        </b>
      </div>
      <div v-if="getContractorReason">
        <b>Reason</b>
        : {{ getContractorReason }}
      </div>
      <div v-if="getDelayCode" class="mb-1">
        <b>DelayCode:</b>
        {{ getDelayCode }}
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import moment, { isMoment } from 'moment'
import storeGetters from '@/storeGetters'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import { JobVisitStatus, RecordType } from '@/common/enums'
import store from '@/store'
import Shared from '@/common/shared'
import MasterRecordController from '@/api/masterRecordController'
import MasterRecord from '@/models/MasterRecord'
import storeMutations from '@/storeMutations'

@Component
export default class TradeAppointedCard extends TimeLineItemBase {
  private jobVisitStatus = JobVisitStatus
  private created() {
    if (this.delayCodes.length === 0) {
      this.SetDelayCodes()
    }
  }

  private get delayCodes() {
    return storeGetters.getDelayCodes()
  }

  private get getAssignedContractor(): ContractorAppointedModel | undefined {
    return storeGetters.getContractorAppointedDetailById(this.jobId, this.itemId)
  }

  private get getContractorEtaTo() {
    if (this.getAssignedContractor && this.getAssignedContractor.etaTo) {
      return Shared.getFormatedDate(
        moment(this.getAssignedContractor.etaTo),
        store.Instance.state.Environment.DateTimeFormat
      )
    }
    return null
  }

  private get getContractorEtaFrom() {
    if (this.getAssignedContractor && this.getAssignedContractor.etaFrom) {
      return Shared.getFormatedDate(
        moment(this.getAssignedContractor.etaFrom),
        store.Instance.state.Environment.DateTimeFormat
      )
    }
    return null
  }

  private get getContractorAdditionalPay() {
    if (this.getAssignedContractor && this.getAssignedContractor.additionalCharge) {
      return this.getAssignedContractor.additionalCharge
    }
    return 0
  }

  private get getContractorReason() {
    if (this.getAssignedContractor && this.getAssignedContractor.reasonType) {
      return this.getAssignedContractor.reasonDescription
    }
    return null
  }

  // Set list of delay code.
  private SetDelayCodes(): void {
    const recordType: string = RecordType[RecordType.DelayCode]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        storeMutations.setDelayCodes(res)
      }
    })
  }

  private get getDelayCode(): string | null | undefined {
    if (this.delayCodes) {
      const getDelayCode: MasterRecord | undefined = this.delayCodes.find((e: MasterRecord) =>
        this.getAssignedContractor ? e.id === this.getAssignedContractor.delayCode : false
      )
      if (getDelayCode) {
        return getDelayCode.description
      }
    }
    return null
  }

  private get getIsThirdPartyContractor() {
    return this.emergency !== undefined &&
      this.emergency !== null &&
      this.emergency.thirdPartyContractor !== '' &&
      this.emergency.thirdPartyContractor !== null
      ? true
      : false
  }

  private get getContractorAdditionalTravelTime() {
    if (this.getAssignedContractor && this.getAssignedContractor.additionalTravelTime) {
      return Shared.formatTimeSpanToString(this.getAssignedContractor.additionalTravelTime)
    }
    return null
  }
}
</script>

<style scoped>
.date-range {
  display: inline-flex;
}
.date-range > div {
  margin: auto 0 auto auto;
}
</style>
