<template>
  <div class="mid-section-content contractor-section py-3">
    <v-progress-circular
      v-show="!ready"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <div v-show="ready">
      <div v-if="!isContractorRegistrationCompleted && getContractor">
        <div class="px-3 progressbar-content">
          <v-card-title class="px-0 pt-0 mb-0">
            <h4
              class="red--text text--darken-4"
              :class="getFormCompletionStatusValue < 100 ? 'error--text' : 'green--text'"
            >
              {{ getFormCompletionStatusText }}
            </h4>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-0 oca-submit-btn"
              :disabled="getFormCompletionStatusValue < 100"
              color="primary"
              @click="submitContractorToReview"
            >
              Submit
            </v-btn>
          </v-card-title>
          <v-progress-linear
            color="light-green darken-4"
            class="mb-0 mt-1"
            height="8"
            :value="getFormCompletionStatusValue"
            striped
          ></v-progress-linear>
        </div>
        <div>
          <div v-if="isLoading" class="full-height coa-loader">
            <v-progress-circular :width="2" :size="50" indeterminate color="primary"></v-progress-circular>
          </div>
          <v-tabs v-model="activeTab" icons-and-text :hide-slider="true">
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap">
              <v-layout>
                <v-tab
                  href="#tab-1"
                  :class="getContractor.country ? 'green--text text--darken-4' : 'error--text text--darken-1'"
                >
                  Contractor Profile
                  <svg
                    class="mb-1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    viewBox="0 0 297 297"
                    enable-background="new 0 0 297 297"
                    width="25px"
                    height="25px"
                  >
                    <g>
                      <path
                        d="M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608   S116.193,117.216,148.51,117.216z"
                        fill="#757575"
                      ></path>
                      <path
                        d="m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z"
                        fill="#757575"
                      ></path>
                      <path
                        d="m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z"
                        fill="#757575"
                      ></path>
                    </g>
                  </svg>
                </v-tab>
                <v-tab
                  href="#tab-2"
                  :class="
                    getContractor.bankAccountDetail && getContractor.bankAccountDetail.name
                      ? 'green--text text--darken-4'
                      : 'error--text text--darken-1'
                  "
                >
                  Bank Details
                  <v-icon
                    :class="
                      getContractor.bankAccountDetail && getContractor.bankAccountDetail.name
                        ? 'green--text text--darken-4'
                        : 'error--text text--darken-1'
                    "
                  >
                    credit_card
                  </v-icon>
                </v-tab>
                <v-tab
                  href="#tab-3"
                  :class="
                    checkValidationForCompanyTrades() ? 'green--text text--darken-4' : 'error--text text--darken-1'
                  "
                >
                  Company Trades
                  <v-icon
                    :class="
                      checkValidationForCompanyTrades() ? 'green--text text--darken-4' : 'error--text text--darken-1'
                    "
                  >
                    business
                  </v-icon>
                </v-tab>
                <v-tab href="#tab-4" class="green--text text--darken-4">
                  Coverage
                  <v-icon class="green--text text--darken-4">place</v-icon>
                </v-tab>
                <v-tab
                  href="#tab-5"
                  :class="
                    checkValidationForTradesAssociation() ? 'green--text text--darken-4' : 'error--text text--darken-1'
                  "
                >
                  Trade Association Memberships
                  <v-icon
                    :class="
                      checkValidationForTradesAssociation()
                        ? 'green--text text--darken-4'
                        : 'error--text text--darken-1'
                    "
                  >
                    people
                  </v-icon>
                </v-tab>
                <v-tab
                  href="#tab-6"
                  :class="
                    checkHSWellbeingAndEnvironment() ? 'green--text text--darken-4' : 'error--text text--darken-1'
                  "
                >
                  <span class="d-inline-block mt-2">Health, Safety, Wellbeing and the Environment</span>
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 455 455"
                    style="enable-background: new 0 0 455 455"
                    xml:space="preserve"
                    fill="#949494"
                    width="25px"
                    height="25px"
                  >
                    <g>
                      <polygon
                        points="370.5,158.498 310.5,158.498 310.5,227.498 241.5,227.498 241.5,287.498 310.5,287.498 310.5,356.498    370.5,356.498 370.5,287.498 439.5,287.498 439.5,227.498 370.5,227.498  "
                      />
                      <path
                        d="M211.5,197.498h69v-69h120v69h34.799c12.468-20,19.701-42.674,19.701-67.53C455,60.686,398.847,4.51,329.579,4.51   c-42.087,0-79.329,20.731-102.079,52.544C204.75,25.24,167.508,4.501,125.421,4.501C56.153,4.501,0,60.664,0,129.947   c0,30.118,10.612,57.752,28.299,79.376L227.5,450.499l53-64.169v-68.832h-69V197.498z"
                      />
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </v-tab>
                <v-tab
                  href="#tab-7"
                  :class="
                    getContractor.signature && getContractor.signature.name
                      ? 'green--text text--darken-4'
                      : 'error--text text--darken-1'
                  "
                >
                  Signature
                  <v-icon
                    :class="
                      getContractor.signature && getContractor.signature.name
                        ? 'green--text text--darken-4'
                        : 'error--text text--darken-1'
                    "
                  >
                    edit
                  </v-icon>
                </v-tab>
              </v-layout>
            </div>

            <v-tab-item value="tab-1" class="py-3">
              <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap py-2 px-4">
                <div>
                  <v-card flat class="grey lighten-2">
                    <ContractorProfile
                      :contractor-detail="Object.assign({}, getContractor)"
                      :is-contractor-reviewed="false"
                      class="online-contractor-information"
                      @updateContractorDetailUpdate="onContractorDetailUpdate"
                    ></ContractorProfile>
                  </v-card>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item v-if="activeTab === 'tab-2'" value="tab-2" class="py-3">
              <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap py-2 px-4">
                <div>
                  <v-card flat>
                    <v-card-title>
                      <h3>Bank Details</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <ContractorBankDetails
                        :contractor-bank-detail="getContractor.bankAccountDetail"
                        :contractor-id="getContractorId"
                        :is-contractor-reviewed="false"
                        @updateBankDetail="onContractorBankDetailUpdate"
                      ></ContractorBankDetails>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item v-if="activeTab === 'tab-3'" value="tab-3" class="py-3 px-4">
              <v-card class="fill-height">
                <v-card-title>
                  <h3>Company Trade</h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="contractorspecialization-content pr-0">
                  <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap pr-3">
                    <div>
                      <ContractorSpecialization
                        v-if="getContractor"
                        class="contractor-rs"
                        :contractor-id="getContractor.id"
                        :contractor="getContractor"
                        :is-at-contractor-registration="true"
                        @updateContractorAvailability="onContractorAvailabilityUpdate"
                      ></ContractorSpecialization>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="activeTab === 'tab-4'" value="tab-4" class="py-3">
              <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap px-4">
                <div>
                  <v-card flat>
                    <div>
                      <ContractorArea
                        v-if="getContractor"
                        class="contactor-rcov"
                        :contractor-id="getContractor.id"
                        :coverage="getContractorCoverage"
                        :contractor-availability="getContractorAvailability"
                        @coverageChange="onCoverageChange"
                      ></ContractorArea>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item v-if="activeTab === 'tab-5'" value="tab-5" class="py-3">
              <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap px-4">
                <div>
                  <v-card flat>
                    <v-card-title>
                      <h3>Trade Association Memberships</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <ContractorTradeAssociationMemberships
                        v-if="getContractor"
                        :contractor-id="getContractor.id"
                        :contractor="getContractor"
                        :document-detail-list="documentDetailList"
                        :is-contractor-reviewed="false"
                        @onSaveTradeMembershipDetails="onSaveTradeMembershipDetails"
                      ></ContractorTradeAssociationMemberships>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item v-if="activeTab === 'tab-6'" value="tab-6" class="py-3">
              <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap px-4">
                <div>
                  <v-card flat>
                    <v-card-title>
                      <h3>Health, Safety, Wellbeing and the Environment</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <ContractorHSAndWellbeingEnvironment
                        v-if="getContractor"
                        :health-safety-wellbeing-environment="getContractor.healthSafetyWellbeingEnvironment"
                        :contractor-id="getContractor.id"
                        :document-detail-list="documentDetailList"
                        :is-contractor-reviewed="false"
                        @onSaveHealthSafetyWellbeingEnvironment="onSaveHealthSafetyWellbeingEnvironment"
                      ></ContractorHSAndWellbeingEnvironment>
                    </v-card-text>
                    <v-divider></v-divider>
                  </v-card>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item v-if="activeTab === 'tab-7'" value="tab-7" class="py-3">
              <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap px-4">
                <div>
                  <v-card flat class="elevation-1 card-box-shadow-none">
                    <v-card-title>
                      <h3>Declaration</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <ContractorSignature
                        v-if="getContractor"
                        :contractor-id="getContractor.id"
                        :contractor-signature="getContractor.signature"
                        :is-contractor-reviewed="false"
                        @onSaveContractorSignatureDetails="onSaveContractorSignatureDetails"
                      ></ContractorSignature>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
        <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
          {{ snackbarText }}
          <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
        </v-snackbar>
      </div>
      <div v-else class="pa-4">
        We’ve received your application and we’re currently reviewing it. We’ll get in touch with you with any questions
        or queries we might have.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import Store from '@/store'
import ContractorModel from '@/models/contractor/ContractorModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import EngineerController from '@/api/engineerController'
import ContractorProfile from '@/views/ContractorProfile.vue'
import ContractorSpecialization from '@/components/ContractorSpecialization.vue'
import ContractorArea from '@/components/ContractorArea.vue'
import ContractorController from '@/api/contractorController'
import ContractorCoverageModel from '@/models/contractor/ContractorCoverageModel'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import DocumentList from '@/components/DocumentList.vue'
import ContractorBankDetails from '@/components/ContractorBankDetails.vue'
import FileUpload from '@/components/FileUpload.vue'
import ContractorTradeAssociationMemberships from '@/components/ContractorTradeAssociationMemberships.vue'
import ContractorHSAndWellbeingEnvironment from '@/components/ContractorHSAndWellbeingEnvironment.vue'
import ContractorSignature from '@/components/ContractorSignature.vue'
import store from '@/store'
import Shared from '@/common/shared'
import UploadedContractorDocumentDetail from '@/models/contractor/UploadedContractorDocumentDetail'
import HealthSafetyWellbeingEnvironment from '@/models/contractor/HealthSafetyWellbeingEnvironment'
import BankAccountDetail from '@/models/contractor/BankAccountDetailModel'
import SignatureModel from '@/models/contractor/SignatureModel'
import eventBus from '@/common/bus'

@Component({
  components: {
    ContractorProfile,
    ContractorSpecialization,
    ContractorArea,
    DocumentList,
    ContractorBankDetails,
    FileUpload,
    ContractorTradeAssociationMemberships,
    ContractorHSAndWellbeingEnvironment,
    ContractorSignature,
  },
})
export default class ContractorRegistration extends Vue {
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private activeTab = 'tab-1'
  private contractor: ContractorModel | null = null
  private contractorId = ''
  private coverage: ContractorCoverageModel[] = []
  private getContractorDetailLoading = false
  private contractorAvailability: ContractorTradeAvailabilityModel[] = []
  private uploadedCompanyTradeFiles: any = []
  private documentDetailList: UploadedContractorDocumentDetail[] = []
  private formCompletionStatusValue = 0
  private isContractorRegistrationCompleted = false
  private isLoading = false
  private ready = false

  private created() {
    if (
      store.Instance.state.ContractorRegistrationStatus &&
      store.Instance.state.ContractorRegistrationStatus === 'ApplicationPending'
    ) {
      this.isContractorRegistrationCompleted = true
      this.ready = true
    } else {
      this.contractorId = Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.contractorId
      this.getContractorDetails(this.contractorId)
      this.getContractorDocumentMetadata(this.contractorId)
    }
  }

  private mounted() {
    eventBus.$on('onDocumentDelete', (fileUrl: string) => {
      const index = this.documentDetailList.findIndex((i) => i.documentUrl === fileUrl)
      if (index !== -1) {
        this.documentDetailList.splice(index, 1)
      }
    })
  }

  private getContractorDetails(contractorId: string) {
    const self = this
    this.isLoading = true
    ContractorController.GetContractorDetails(contractorId)
      .then((res: ContractorModel | null) => {
        if (res) {
          self.contractor = res
          self.getContractorAvailability = res.contractorAvailability || []
          self.getContractorCoverage = res.coverage || []
        } else {
          self.contractor = new ContractorModel()
        }
        this.isLoading = false
        self.getContractorDetailLoading = false
        this.ready = true
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor detail, please try again', true)
        self.getContractorDetailLoading = false
        this.isLoading = false
      })
  }

  private getContractorDocumentMetadata(contractorId: string) {
    const self = this
    ContractorController.GetContractorDocumentMetadata(contractorId)
      .then((res: UploadedContractorDocumentDetail[] | null) => {
        if (res) {
          self.documentDetailList = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading document(s), please try again', true)
      })
  }

  private get getContractorAvailability(): ContractorTradeAvailabilityModel[] {
    return this.contractorAvailability
  }

  private set getContractorAvailability(newValue: ContractorTradeAvailabilityModel[]) {
    this.contractorAvailability = newValue
  }

  private get getContractorCoverage(): ContractorCoverageModel[] {
    return this.coverage
  }

  private set getContractorCoverage(newValue: ContractorCoverageModel[]) {
    this.coverage = newValue
  }

  private onCoverageChange(coverage: ContractorCoverageModel[]) {
    this.getContractorCoverage = coverage
  }

  private get getContractor(): ContractorModel | null {
    return this.contractor
  }

  private set getContractor(newValue: ContractorModel | null) {
    this.contractor = newValue
  }

  private get getContractorId(): string {
    return this.getContractor ? this.getContractor.id : ''
  }

  private onContractorAvailabilityUpdate(tradesAvailability: ContractorTradeAvailabilityModel[]) {
    this.getContractorAvailability = tradesAvailability
    if (this.getContractor) {
      this.getContractor.contractorAvailability = tradesAvailability
    }
  }

  private onSaveTradeMembershipDetails(contractor: ContractorModel, isDocumentUploaded: boolean) {
    if (this.getContractor) {
      this.getContractor.isGasSafeRegistered = contractor.isGasSafeRegistered
      this.getContractor.gasSafeRegistrationNumber = contractor.gasSafeRegistrationNumber
      this.getContractor.directEmployedHEWorker = contractor.directEmployedHEWorker
      this.getContractor.subContractedHEWorker = contractor.subContractedHEWorker
      this.getContractor.hasLicenceToCarryWaste = contractor.hasLicenceToCarryWaste
      if (isDocumentUploaded) {
        this.getContractorDocumentMetadata(this.contractorId)
      }
    }
  }

  private onSaveHealthSafetyWellbeingEnvironment(
    healthSafetyWellbeingEnvironmentModel: HealthSafetyWellbeingEnvironment,
    isDocumentUploaded: boolean
  ) {
    if (this.getContractor) {
      this.getContractor.healthSafetyWellbeingEnvironment = healthSafetyWellbeingEnvironmentModel
      if (isDocumentUploaded) {
        this.getContractorDocumentMetadata(this.contractorId)
      }
    }
  }

  private onContractorBankDetailUpdate(bankDetail: BankAccountDetail) {
    if (this.getContractor) {
      this.getContractor.bankAccountDetail = bankDetail
    }
  }

  private onContractorDetailUpdate(contractor: ContractorModel) {
    this.contractor = contractor
  }

  private get getFormCompletionStatusValue() {
    this.formCompletionStatusValue = 0
    const stepCompletionValue: number = 100 / 7
    if (this.getContractor) {
      // profile
      this.formCompletionStatusValue += this.getContractor.country ? stepCompletionValue : 0
      // bank
      this.formCompletionStatusValue += this.getContractor.bankAccountDetail
        ? this.getContractor.bankAccountDetail.name
          ? stepCompletionValue
          : 0
        : 0
      // company trades
      this.formCompletionStatusValue += this.checkValidationForCompanyTrades() ? stepCompletionValue : 0
      // coverage
      this.formCompletionStatusValue += stepCompletionValue
      // Trades Association
      this.formCompletionStatusValue += this.checkValidationForTradesAssociation() ? stepCompletionValue : 0
      // HS Wellbeing And Environment
      this.formCompletionStatusValue += this.checkHSWellbeingAndEnvironment() ? stepCompletionValue : 0
      // signature
      this.formCompletionStatusValue += this.getContractor.signature
        ? this.getContractor.signature.name
          ? stepCompletionValue
          : 0
        : 0
    }
    return this.formCompletionStatusValue
  }

  private get getFormCompletionStatusText(): string {
    return this.getFormCompletionStatusValue < 100
      ? 'Required Information - Only ' +
          Math.ceil(this.getFormCompletionStatusValue) +
          '% details are saved, Please fill IN the details'
      : 'Pending submission - All details are saved, please submit your application for verification'
  }

  private onSaveContractorSignatureDetails(signature: SignatureModel) {
    if (this.getContractor) {
      this.getContractor.signature = signature
    }
  }

  private checkValidationForCompanyTrades(): boolean {
    if (this.getContractor) {
      return (this.getContractor.contractorAvailability || []).filter((x) => x.timeSlot.length > 0).length > 0
    }
    return false
  }

  private checkValidationForTradesAssociation() {
    if (this.documentDetailList && this.documentDetailList.length > 0) {
      return this.documentDetailList.filter(
        (e) => e.documentFor === 'Public Liability Insurance' && e.contractorId === this.contractorId
      ).length > 0
        ? true
        : false
    }
  }

  private checkHSWellbeingAndEnvironment() {
    if (this.getContractor) {
      return this.getContractor.healthSafetyWellbeingEnvironment &&
        this.getContractor.healthSafetyWellbeingEnvironment.name &&
        this.documentDetailList.filter(
          (e) => e.documentFor === 'Risk Assessment' && e.contractorId === this.contractorId
        ).length > 0 &&
        this.documentDetailList.filter(
          (e) => e.documentFor === 'Safe methods of work' && e.contractorId === this.contractorId
        ).length > 0
        ? true
        : false
    }
    return false
  }

  private submitContractorToReview() {
    Shared.confirmationPopup.open(
      'Once you submit this, you will not be able to update these information afterwards. You want to submit?',
      '',
      '',
      'No',
      'Yes',
      this,
      'submitContractorForReview',
      true
    )
  }

  private submitContractorForReview() {
    ContractorController.SubmitContractorToReview(this.getContractorId)
      .then((res: boolean) => {
        if (res) {
          this.isContractorRegistrationCompleted = true
        } else {
          this.snackbarText = 'Error is Submitting Application!'
          this.snackbar = true
        }
      })
      .catch((err: any) => {
        this.snackbarText = 'Error is Submitting Application!'
        this.snackbar = true
      })
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style>
.signup-page #g1-slide-container-0 {
  display: none !important;
}
</style>

<style scoped>
.contractor-section >>> .v-tabs__bar,
.contractor-section >>> .v-window {
  display: inline-block;
  vertical-align: top;
}
.contractor-section >>> .v-tabs__item {
  display: flex;
  width: 100%;
}
.contractor-section >>> .v-window {
  width: calc(100% - 135px);
  display: block;
  margin-left: auto;
}
.contractor-section >>> .v-tabs .v-tabs__bar {
  position: fixed;
  left: 0;
  width: 135px;
  z-index: 1;
  overflow: auto;
  top: 60px;
}
.contractor-section >>> .v-tabs .v-tabs__bar .tab-wrap {
  height: calc(100vh - 80px);
  padding: 0 15px 90px 0;
  margin-top: 25px;
}
.contractor-section >>> .v-tabs__bar > i {
  display: none;
}
.contractor-section >>> .v-tabs__wrapper {
  overflow: visible;
  display: block;
  margin: 0;
  padding: 0;
}
.contractor-section >>> .v-tabs__container {
  text-align: center;
  white-space: inherit;
  position: static;
  display: block;
  transform: inherit !important;
  height: auto;
}
.contractor-section >>> .v-tabs__div {
  width: 100%;
  margin-bottom: 10px;
  min-width: auto;
  height: auto;
}
.contractor-section {
  background-color: #eeeeee;
  height: 100%;
  position: relative;
}
.contractor-section >>> .v-tabs__item--active,
.contractor-section >>> .v-tabs__item--active i {
  color: #e91e63 !important;
}
.contractor-section >>> .green--text svg path,
.contractor-section >>> .green--text svg polygon {
  fill: #1b5e20;
}
.contractor-section >>> .error--text svg path,
.contractor-section >>> .error--text svg polygon {
  fill: #ff5252;
}
.contractor-section >>> .v-tabs__item--active svg path,
.contractor-section >>> .v-tabs__item--active svg polygon,
.contractor-section >>> .v-tabs__item--active .green--text svg path,
.contractor-section >>> .v-tabs__item--active .green--text svg polygon,
.contractor-section >>> .v-tabs__item--active .error--text svg path,
.contractor-section >>> .v-tabs__item--active .error--text svg polygon {
  fill: #e91e63;
}
.contractor-section.mid-section-content,
.contractor-section >>> .v-tabs__bar {
  background-color: #eeeeee;
}
.contractor-section >>> .v-window-item {
  transition: none !important;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item .menu-images {
  padding-bottom: 10px;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item .active-menu-icon {
  display: none;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item--active .menu-icon {
  display: none;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item--active .active-menu-icon {
  display: block;
}
.contractor-section >>> .v-tabs__bar {
  position: fixed;
}
.contractor-section >>> .v-tabs__items {
  left: 115px;
}
.contractor-section >>> .v-window {
  padding-left: 16px;
  padding-right: 16px;
}
.contractor-section >>> .v-window__container {
  background-color: #e0e0e0;
  height: calc(100vh - 150px) !important;
}
.progressbar-content {
  padding-left: 151px !important;
}
.progressbar-content .oca-submit-btn {
  position: absolute;
  right: 0px;
  top: 0px;
}
.progressbar-content >>> .v-card__title {
  position: relative;
  padding-right: 100px !important;
}
.tab-wrap {
  height: 100%;
}
.coa-loader {
  position: ABSOLUTE;
  left: 150px;
  right: 15px;
  top: 55px;
  bottom: 25px;
  z-index: 99;
  display: flex;
}
.coa-loader >>> .v-progress-circular {
  margin: auto;
}
.contractorspecialization-content {
  height: calc(100% - 50px);
}
.contractor-rs >>> .cta-content {
  max-height: 500px;
  min-height: 500px;
}
.contractor-rs >>> .specialization-list-content {
  min-height: 525px;
  max-height: 525px;
}
.contactor-rcov >>> #customMap {
  min-height: calc(100vh - 245px);
  max-height: calc(100vh - 245px);
}
.contactor-rcov >>> .area-list-content {
  min-height: calc(100vh - 265px);
  max-height: calc(100vh - 265px);
}

@media (max-width: 1400px) {
  .online-contractor-information >>> .profile-sec {
    max-width: 80% !important;
    flex-basis: 80%;
    margin-left: 10% !important;
  }
}
@media (max-width: 1200px) {
  .online-contractor-information >>> .profile-sec {
    max-width: 100% !important;
    flex-basis: 100%;
    margin-left: 0% !important;
  }
}
</style>
