<template>
  <div>
    <template v-if="!showProductSelection">
      <v-card class="mb-3 enablement">
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12 text-xs-center>
              <div class="question-title-desc">
                <v-btn
                  color="green white--text"
                  large
                  :ripple="true"
                  class="ml-0 btn"
                  :disabled="isJobCancelled"
                  @click="reInitialize"
                >
                  <v-icon dark>refresh</v-icon>
                  &nbsp; Re-initialize SI Process
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <JobPackagePreview ref="jobPackagePreviewRef" :job-id="jobId" />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SiteInvestigationController from '@/api/siteInvestigationController'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'
import TimeLineItemBase from '../TimeLineItemBase'
import store from '@/store'
import IVSDetailModel from '@/models/IVSDetailModel'
import storeGetters from '@/storeGetters'
import moment from 'moment'
import { SIJobProductProgress } from '@/common/enums'
import JobPackagePreview from '@/components/timeline/JobPackagePreview.vue'

@Component({
  components: {
    JobPackagePreview,
  },
})
export default class IVSCompletePreview extends TimeLineItemBase {
  private showProductSelection = false

  private mounted() {
    this.showProductSelection = false
  }
  private reInitialize() {
    if (this.isJobCancelled) {
      return
    }
    this.showProductSelection = true
    const self = this
    setTimeout(() => {
      ;(self.$refs.jobPackagePreviewRef as JobPackagePreview).editMode = true
    }, 10)
  }

  private get isJobCancelled(): boolean {
    return this.job && this.job.cancellationReason ? true : false
  }
}
</script>

<style scoped>
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.enablement >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
