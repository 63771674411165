<template>
  <v-card>
    <v-layout wrap bg-white>
      <!-- SI Product and amounts -->
      <v-flex v-if="getSIProductItems" xs12 pa-3>
        <h3 class="pb-2">Product Items</h3>
        <v-divider></v-divider>
        <v-flex xs12 mt-2>
          <v-data-table
            v-if="siJobProductItems && siJobProductItems.length > 0"
            :items="siJobProductItems"
            hide-headers
            class="gridView elevation-1 si-products"
            hide-actions
            width="500"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td :class="{ 'font-weight-bold': props.item.isProductTitle }">
                  {{ props.item.description }}
                  <v-tooltip v-if="props.item.isMonitoringProduct && props.item.isProductTitle" right>
                    <template #activator="{ on }">
                      <v-icon color="blue" class="md-icon pr-2 pl-1 mt-1" v-on="on">info</v-icon>
                    </template>
                    <span>Monitoring products will be excluded from job invoice.</span>
                  </v-tooltip>
                </td>
                <td class="text-xs-right">
                  <span
                    v-if="!props.item.isProductTitle"
                    :class="{ 'red--text': props.item.isMonitoringProduct }"
                    class="product-amount"
                  >
                    {{ getFormatedCurrency(props.item.amount) }}
                  </span>
                </td>
              </tr>
            </template>
            <template slot="footer">
              <td class="text-xs-right"><strong>Total</strong></td>
              <td class="text-xs-right">
                <b>{{ getFormatedCurrency(getTotalProductPrice) }}</b>
              </td>
            </template>
          </v-data-table>
          <div v-else class="text-xs-center pt-2">No data available.</div>
        </v-flex>
      </v-flex>
      <!-- Customer To Pay -->
      <v-flex v-if="getCustomerToPay && getCustomerToPay.length > 0" xs12 pa-3>
        <v-layout wrap>
          <v-flex xs12>
            <h3 class="pb-2">Customer To Pay</h3>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 mt-2>
            <v-data-table
              :headers="ctpHeaders"
              :items="getCustomerToPay"
              class="gridView elevation-1 ctptable"
              hide-actions
              width="500"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>{{ getFormattedDate(props.item.createdAt) }}</td>
                  <td class="text-xs-right">
                    {{ getFormatedCurrency(props.item.amount) }}
                  </td>
                  <td class="text-xs-right" :style="'color:' + (props.item.isTransactionCompleted ? 'green' : 'red')">
                    {{ props.item.isTransactionCompleted ? 'Yes' : 'No' }}
                  </td>
                </tr>
              </template>
              <template slot="footer">
                <td><strong>Total</strong></td>
                <td class="text-xs-right pr-2">
                  <b>{{ getFormatedCurrency(getTotalCTPAmount) }}</b>
                </td>
                <td class="text-xs-right pr-2">
                  <b>{{ getFormatedCurrency(getTotalPaidAmount) }}</b>
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 pa-2 text-xs-right>
        <v-divider></v-divider>
        <v-btn
          v-if="!job.cancellationReason"
          class="red white--text btn-not-ready-to-close"
          :disabled="isLoading || notReadyToCloseJobLoading"
          :loading="notReadyToCloseJobLoading"
          @click="changeJobStatusToNotReadyToClose()"
        >
          Not Ready to Close
        </v-btn>
        <v-btn
          v-if="job.cancellationReason"
          class="red white--text btn-cancel"
          :disabled="isLoading || notReadyToCloseJobLoading"
          :loading="isLoading"
          @click="onCompleteJobConfirm('Cancelled')"
        >
          Cancel
        </v-btn>
        <v-btn
          v-else
          class="green white--text btn-complete"
          :disabled="isLoading || notReadyToCloseJobLoading"
          :loading="isLoading"
          @click="onCompleteJobConfirm('Completed')"
        >
          Complete
        </v-btn>
      </v-flex>
      <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
        {{ saveSnackbarText }}
        <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
      </v-snackbar>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import moment from 'moment'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import eventBus from '@/common/bus'
import JobController from '../../api/jobController'
import AddCloseJobRequestModel from '../../models/requests/AddCloseJobRequestModel'
import JobNotReadyToCloseModel from '../../models/requests/JobNotReadyToCloseModel'
import DehydratedJobPackage from '../../models/siteInvestigation/DehydratedJobPackage'
import { SIProductType } from '../../common/enums'
import Product from '@/models/siteInvestigation/Product'
import Pit from '@/models/siteInvestigation/Pit'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import store from '@/store'
import { APIResponseCode } from '@/models/types'

@Component
export default class SIJobCompleteConfirmationPreview extends Vue {
  @Prop() private jobId: string
  private notReadyToCloseJobLoading = false
  private isLoading = false
  private saveSnackbarTimeout: number = Shared.snackbarTimeout
  private saveSnackbar = false
  private saveSnackbarText = ''
  private siJobProductItems: any = []
  private ctpHeaders = [
    { text: 'Date', value: 'recordCreatedAt', sortable: false },
    { text: 'Amount', value: 'amount', align: 'right', sortable: false },
    {
      text: 'Amount Paid',
      value: 'isTransactionCompleted',
      align: 'right',
      sortable: false,
    },
  ]

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private get jobPackage(): DehydratedJobPackage | undefined {
    return this.job && this.job.sIJobDetail ? this.job.sIJobDetail.jobPackage : undefined
  }

  private get getCustomerToPay(): CustomerToPayModel[] {
    return this.job && this.job.customerToPay.length > 0 ? this.job.customerToPay : []
  }

  private getPitTestPrice(productList: Array<Partial<Product>>) {
    let pitTestPrice = 0
    productList.map((prod: any) => {
      prod.pitTests && prod.pitTests.length > 0
        ? prod.pitTests.forEach((test) => {
            pitTestPrice += test.basePrice * test.quantity
          })
        : (pitTestPrice += 0)
    })
    return pitTestPrice
  }

  private getBulkPitPrice(pits: Array<{ value: Pit }>) {
    let pitPrice = 0
    pits.map((bulkPit) => {
      const pit: any = bulkPit.value ? bulkPit.value : bulkPit
      pit.pitTests && pit.pitTests.length > 0
        ? pit.pitTests.forEach((test) => {
            pitPrice += test.basePrice * test.quantity
          })
        : (pitPrice += 0)
    })
    return pitPrice
  }

  private get getSIProductItems() {
    let pitDetail = ''
    if (this.jobPackage) {
      if (this.jobPackage && this.jobPackage.packageInfo) {
        const pits =
          this.jobPackage!.packageInfo!.productList.length > 0
            ? this.jobPackage!.packageInfo!.productList.filter((prod) => prod.productTypeId === SIProductType.Pit)
            : []
        if (pits.length > 0) {
          pitDetail = pits.length + ' x ' + pits[0].name
        }
        const packagePitTestPrice: number =
          this.jobPackage!.packageInfo!.productList.length > 0
            ? this.getPitTestPrice(this.jobPackage!.packageInfo!.productList)
            : 0
        const packageInfo = [
          {
            description: 'Package' + ' - ' + this.jobPackage!.packageInfo!.name,
            amount: 0,
            isProductTitle: true,
          },
          {
            description: pitDetail,
            amount:
              (this.jobPackage!.packageInfo!.price ? this.jobPackage!.packageInfo!.price : 0) + packagePitTestPrice,
          },
        ]
        this.siJobProductItems.push(packageInfo[0], packageInfo[1])
      }
      if (this.jobPackage!.extraProducts && this.jobPackage!.extraProducts.length > 0) {
        this.siJobProductItems.push({
          description: 'Additional Products',
          amount: 0,
          isProductTitle: true,
        })
        this.jobPackage!.extraProducts.map((product) => {
          this.siJobProductItems.push({
            description: product.quantity + ' x ' + product.name,
            amount: product.basePrice && product.quantity ? product.basePrice * product.quantity : 0,
          })
        })
      }
      if (this.jobPackage!.bulkPitProducts && this.jobPackage!.bulkPitProducts.length > 0) {
        this.jobPackage!.bulkPitProducts.map((product) => {
          const bulkPitTestPrice: number = this.getBulkPitPrice((product as any).pits)
          this.siJobProductItems.push({
            description: product.quantity + ' x ' + product.name,
            amount: (product.basePrice ? product.basePrice : 0) + bulkPitTestPrice,
          })
        })
      }
      if (this.jobPackage!.monitoringProducts && this.jobPackage!.monitoringProducts.length > 0) {
        this.siJobProductItems.push({
          description: 'Monitoring Products',
          amount: 0,
          isProductTitle: true,
          isMonitoringProduct: true,
        })
        this.jobPackage!.monitoringProducts.map((product) => {
          let childProductPrice = 0
          const monitoringProduct = {
            description: product.quantity + ' x ' + product.name,
            amount: product.basePrice,
            isMonitoringProduct: true,
          }
          this.siJobProductItems.push(monitoringProduct)
          if (product.selectedChildProducts && product.selectedChildProducts.length > 0) {
            product.selectedChildProducts.map((childProduct) => {
              childProductPrice += childProduct.quantity * childProduct.basePrice
              this.siJobProductItems.push({
                description: childProduct.quantity + ' x ' + childProduct.name,
                amount: childProductPrice,
                isMonitoringProduct: true,
              })
            })
          }
          monitoringProduct.amount += childProductPrice
        })
      }
    }
    return this.siJobProductItems
  }

  private get getTotalProductPrice() {
    return this.siJobProductItems.filter((s) => !s.isMonitoringProduct).reduce((a, b) => a + b.amount, 0)
  }

  private onCompleteJobConfirm(status = '') {
    const confirmStatus: string =
      'Are you sure you want to ' + (status === 'Completed' ? 'complete' : 'cancel') + ' the job?'
    Shared.confirmationPopup.open(confirmStatus, '', '', 'No', 'Yes', this, 'onCompleteJob', status)
  }

  private onCompleteJob(status = '') {
    this.isLoading = true
    const addCloseJobRequestModel: AddCloseJobRequestModel = new AddCloseJobRequestModel()
    addCloseJobRequestModel.jobId = this.jobId
    addCloseJobRequestModel.jobStatus = status
    JobController.AddCloseJobRequest(addCloseJobRequestModel)
      .then((res: [APIResponseCode, boolean]) => {
        if (res[0] === 'Error') {
          this.showSnackBar('Something went wrong!')
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        if (err.response.statusText !== null) {
          this.showSnackBar(err.response.statusText)
        }
        this.isLoading = false
      })
  }

  private changeJobStatusToNotReadyToClose() {
    this.notReadyToCloseJobLoading = true
    const jobNotReadyToCloseModel: JobNotReadyToCloseModel = new JobNotReadyToCloseModel()
    jobNotReadyToCloseModel.jobId = this.jobId
    JobController.JobNotReadyToClose(jobNotReadyToCloseModel)
      .then((res: boolean) => {
        this.notReadyToCloseJobLoading = false
        if (res) {
          this.showSnackBar('Job marked as not ready to close')
          setTimeout(() => {
            eventBus.$emit('closeJobCompleteConfirmation')
          }, 1000)
        }
      })
      .catch((err: any) => {
        if (err && err.response && err.response.statusText !== null) {
          this.showSnackBar(err.response.statusText)
        }
        this.notReadyToCloseJobLoading = false
      })
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private showSnackBar(text: string) {
    this.saveSnackbar = true
    this.saveSnackbarText = text
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), store.Instance.state.Environment.DateTimeFormat)
  }

  private get getTotalCTPAmount() {
    return this.getCustomerToPay && this.getCustomerToPay.length > 0
      ? this.getCustomerToPay.reduce((a, b) => a + b.amount, 0)
      : 0
  }

  private get getTotalPaidAmount() {
    return this.getCustomerToPay && this.getCustomerToPay.length > 0
      ? this.getCustomerToPay.filter((c) => c.isTransactionCompleted).reduce((a, b) => a + b.amount, 0)
      : 0
  }
}
</script>
