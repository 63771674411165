<template>
  <div>
    <div v-if="hasList ? index === 0 : true" class="mb-2">
      <h4>Emergency Answer</h4>
    </div>
    <div v-if="questionText" :class="hasList ? '' : 'mb-1'">
      <label>Question: </label>
      <span>{{ questionText }}</span>
    </div>
    <div v-if="item.answer" :class="hasList ? 'mb-2' : 'mb-1'">
      <label>Answer: </label>
      <span>{{ item.answer }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddEmergencyAnswer from '@/models/requests/AddEmergencyAnswer'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class AddEmergencyAnswerLog extends Vue {
  @Prop() private requestBody: any
  @Prop({ default: 0 }) private index: number
  @Prop({ default: false }) private hasList: boolean

  private get item(): AddEmergencyAnswer {
    return Object.assign(new AddEmergencyAnswer(), this.requestBody)
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get questionText(): string {
    if (this.requestedJob) {
      const emergencyQaItem = this.requestedJob.emergenciesQAs.find((e) => e.id === this.requestBody.id)
      return emergencyQaItem ? emergencyQaItem.questionText : ''
    }
    return ''
  }
}
</script>

<style scoped></style>
