<template>
  <div class="step-header">
    <v-flex>
      <v-expansion-panel>
        <v-expansion-panel-content v-for="(stepHeader, index) in stepHeaders" :key="`StepHeader-${index}`">
          <template #header>
            <h4 class="step-header__title">Step - {{ index + 1 }}</h4>
          </template>
          <div>
            <v-layout row wrap class="pa-3">
              <v-flex xs12 pl-2>
                <label :for="`stepHeading-${index + 1}`">Heading</label>
                <textarea
                  :id="`stepHeading-${index + 1}`"
                  v-validate="'required'"
                  class="step-header__textarea"
                  :class="{ 'step-header__textarea--error': retrieveError(`stepHeading ${index + 1}`) !== '' }"
                  placeholder="Please enter a heading"
                  :value="stepHeader.heading"
                  :name="`stepHeading-${index + 1}`"
                  :data-vv-as="`Step ${index + 1} Header`"
                  :data-vv-name="`stepHeading ${index + 1}`"
                  :data-vv-scope="validationScope"
                  @input="setHeader('heading', index + 1, $event.target.value)"
                >
                </textarea>
                <span class="step-header__error">{{ retrieveError(`stepHeading ${index + 1}`) }}</span>
              </v-flex>
              <v-flex xs12 pl-2>
                <label :for="`stepHeader-${index}`">Description</label>
                <textarea
                  :id="`stepDescription-${index}`"
                  v-validate="'required'"
                  class="step-header__textarea"
                  :class="{ 'step-header__textarea--error': retrieveError(`stepDescription ${index + 1}`) !== '' }"
                  placeholder="Please enter a description for the step"
                  :value="stepHeader.description"
                  :name="`stepDescription-${index}`"
                  :data-vv-as="`Step ${index + 1} Description`"
                  :data-vv-name="`stepDescription ${index + 1}`"
                  :data-vv-scope="validationScope"
                  @input="setHeader('description', index + 1, $event.target.value)"
                >
                </textarea>
                <span class="step-header__error">{{ retrieveError(`stepDescription ${index + 1}`) }}</span>
              </v-flex>
            </v-layout>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-flex>
  </div>
</template>

<script lang="ts">
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import TemplateHeader from '@/models/client/template-header'
import { Validator } from 'vee-validate'

@Component
export default class TemplateHeaders extends Vue {
  @Inject('validator') public readonly $validator!: Validator

  @Prop({ default: null })
  public ct: ClientTemplateModel

  @Prop({ default: '' })
  public validationScope: string

  private timeout: any

  // Needs fixed - This entire component should be reading/writing directly from/to Vuex.
  // We shouldnt be using props/emits.
  // When we rebuild clientTemplate this will be done properly.
  private get stepHeaders(): TemplateHeader[] {
    return [
      new TemplateHeader(this.ct.headingStep1, this.ct.descriptionStep1),
      new TemplateHeader(this.ct.headingStep2, this.ct.descriptionStep2),
      new TemplateHeader(this.ct.headingStep3, this.ct.descriptionStep3),
      new TemplateHeader(this.ct.headingStep4, this.ct.descriptionStep4),
      new TemplateHeader(this.ct.headingStep5, this.ct.descriptionStep5),
      new TemplateHeader(this.ct.headingStep6, this.ct.descriptionStep6),
      new TemplateHeader(this.ct.headingStep7, this.ct.descriptionStep7),
    ]
  }

  private setHeader(field: string, step: string, value: string) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.$emit('updateHeader', { field, step, value })
    }, 200)
  }

  private retrieveError(fieldName: string): string {
    const errors = this.$validator.errors.collect(fieldName)
    return errors && errors.length > 0 ? errors[0] : ''
  }
}
</script>

<style scoped lang="scss">
.step-header {
  &__title {
    text-transform: uppercase;
  }

  &__textarea {
    border: 1px solid black;
    padding: 5px;
    width: 100%;
    margin-bottom: 3px;
    border-radius: 5px;

    &--error {
      border: 2px solid red;
    }
  }

  &__error {
    color: red;
  }
}
</style>
