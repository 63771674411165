import { EscalationTypeEnum, ETAFilterForMissedJob } from '@/common/enums'

export default class MissedJobSearchAndFilterModel {
  public pageNumber: number
  public pageSize: number
  public search: string
  public sortBy: string
  public sortOrder: string
  public contractorId: string
  public teamName: string
  public tradeId: number
  public escalationType: EscalationTypeEnum
  public escalationReason: string
  public etaFilter: ETAFilterForMissedJob

  public constructor() {
    this.pageNumber = 0
    this.pageSize = 0
    this.search = ''
    this.sortBy = ''
    this.sortOrder = ''
    this.contractorId = ''
    this.teamName = ''
    this.tradeId = 0
    this.escalationType = EscalationTypeEnum.All
    this.escalationReason = ''
    this.etaFilter = ETAFilterForMissedJob.AllOpenJobs
  }
}
