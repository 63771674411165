<template>
  <div>
    <UnlinkedPhoneCallsTable table-name="unlinkedPhoneCallsDashboard"></UnlinkedPhoneCallsTable>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import DashboardController from '@/api/dashboardController'
import UnlinkedPhoneCallsModel from '@/models/claim/UnlinkedPhoneCallsModel'
import UnlinkedPhoneCallsTable from '@/components/tables/UnlinkedPhoneCallsTable.vue'
import Shared from '@/common/shared'

@Component({
  components: { UnlinkedPhoneCallsTable },
})
export default class UnlinkedPhoneCallsDashboard extends Vue {}
</script>
