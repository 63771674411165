<template>
  <span v-if="items">
    logged by
    <b class="text-secondary">{{ items.loggedByUserName }}</b>
  </span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import JobNoteModel from '@/models/claim/JobNoteModel'
import storeGetters from '@/storeGetters'
@Component
export default class JobNoteFooter extends TimeLineItemBase {
  private get items(): JobNoteModel | null {
    if (this.jobId && this.itemId) {
      return storeGetters.getJobNote(this.jobId, this.itemId)
    }
    return null
  }
}
</script>
