import { Moment } from 'moment'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'

export default class Emergency extends CosmosJobsDbRecordBase {
  public typeId: number // main type id of this emergency
  public typeDescription: string // description of the main type
  public detailId: number // detail id of this emergency
  public detailDescription: string // description of the detail
  public tradeId: number // type code of the contractor/engineer required to fix this emergency
  public tradeDescription: string // description of the trade
  public firstNoticedAt: Moment // date/time this problem was first noticed
  public note: string // addtional note
  public cutOffHours: number | null // cut off hours of the emergency
  public isEmergencyAccepted: boolean // check emergency is accepted or not
  public isEmergencySoftFixed: boolean // check emergency is accepted or not softly
  public acceptRejectNote: string // accept-reject claim note
  public repudiationDescription: string // repudiation description
  public isCovered: boolean // check emergency is covered or not
  public thirdPartyContractor: string
  public followOnWorkRequired: boolean // check follow on work required or not for job.
  public followOnDescription: string // the follow on work description.
  public autoDeploymentRunning: boolean // auto deployment of emergency
  public engineerSiteSLA: number // engineer on Site SLA time
  public ctpCompletedForRejectedEmergency: boolean // true if CTP completed for the rejected emergency
  public firstUpdatedAt: Moment | null // date/time at which the first operation was performed on emergency
  public isEmergencyCompleted: boolean // check if emergency is marked as completed after contractor is cancelled
  public excessValue: number | null

  // eslint:disable-next-line:variable-name
  private _displayInTimeline
  public get displayInTimeline(): boolean {
    return this._displayInTimeline
  }
  public set displayInTimeline(value: boolean) {
    this._displayInTimeline = value
  }

  public constructor() {
    super()
    this._displayInTimeline = true
  }
}
