<template>
  <v-layout wrap>
    <Availability :customer-availability="customerAvailability"></Availability>
    <v-flex v-if="getDelayCode" xs12>
      <span class="delay-code">
        <b>DelayCode:</b>
        {{ getDelayCode }}
      </span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeSlot from '@/models/claim/TimeSlot'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import { RecordType } from '@/common/enums'
import MasterRecordController from '@/api/masterRecordController'
import MasterRecord from '@/models/MasterRecord'
import storeMutations from '@/storeMutations'
import Availability from '@/components/Availability.vue'

@Component({
  components: { Availability },
})
export default class CustomerAvailabilityCard extends TimeLineItemBase {
  private customerAvailabilityModel: CustomerAvailabilityModel = new CustomerAvailabilityModel()

  private created() {
    this.setDefaultValues()
  }

  private setDefaultValues() {
    this.customerAvailability = Object.assign({}, this.getCustomerAvailability)
    if (this.delayCodes.length === 0) {
      this.SetDelayCodes()
    }
  }

  @Watch('getCustomerAvailability')
  private onCustomerAvailabilityChange() {
    this.setDefaultValues()
  }

  private get getCustomerAvailability(): CustomerAvailabilityModel {
    return storeGetters.getCustomerAvailability(this.jobId)
  }

  private get customerAvailability(): CustomerAvailabilityModel {
    return this.customerAvailabilityModel
  }

  private set customerAvailability(newValue: CustomerAvailabilityModel) {
    this.customerAvailabilityModel = newValue
  }

  private get delayCodes() {
    return storeGetters.getDelayCodes()
  }

  // Set list of delay code.
  private SetDelayCodes(): void {
    const recordType: string = RecordType[RecordType.DelayCode]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        storeMutations.setDelayCodes(res)
      }
    })
  }

  private get getDelayCode(): string | null | undefined {
    if (this.delayCodes) {
      const getDelayCode: MasterRecord | undefined = this.delayCodes.find(
        (e: MasterRecord) => e.id === this.customerAvailability.delayCode
      )
      if (getDelayCode) {
        return getDelayCode.description
      }
    }
    return null
  }
}
</script>
