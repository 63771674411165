<template>
  <v-card class="mb-3">
    <v-card-title class="pb-0">
      <v-layout wrap>
        <v-flex xs11 mb-2>
          <h3>
            {{ storedRecord.questionText }}
            {{ storedRecord.unit ? '(' + storedRecord.unit + ')' : '' }}
          </h3>
        </v-flex>
        <v-flex xs1 text-xs-right>
          <v-icon v-if="syncing" class="processing-icon" medium>sync</v-icon>
          <v-icon v-else-if="storedRecord.validationStatus === 'invalid'" medium dark color="error">warning</v-icon>
          <v-icon v-else-if="storedRecord.validationStatus === 'missing'" medium dark color="orange">info</v-icon>
          <v-icon v-else-if="storedRecord.validationStatus === 'manual'" medium>help</v-icon>
          <v-icon v-else-if="storedRecord.isValid" medium dark color="success">check_circle</v-icon>
        </v-flex>
        <v-flex v-if="storedRecord.note" xs12>
          <pre class="agent-note body-2 black--text text--lighten-1 pa-2 mt-1">{{ storedRecord.note }}</pre>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-textarea
        v-if="storedRecord.answerType === answerTypeText"
        :ref="'answer' + storedRecord.orderBy"
        v-model="localAnswer"
        placeholder="enter a value"
        :loading="saving"
        class="answer"
        rows="4"
        :full-width="true"
        :disabled="isJobCompleted || getAcceptRejectQuestionCriteria"
        @focus="editStarted"
        @blur="editComplete(false)"
      />
      <v-select
        v-else-if="storedRecord.answerType === answerTypeDropdown"
        :ref="'answer' + storedRecord.orderBy"
        v-model="localAnswer"
        :items="storedRecord.dropdownOptions"
        placeholder="select a value"
        :loading="saving"
        class="answer"
        :disabled="isJobCompleted || getAcceptRejectQuestionCriteria"
        @focus="editStarted"
        @input="editComplete(false)"
      />
      <v-text-field
        v-else-if="storedRecord.answerType === answerTypeNumber"
        :ref="'answer' + storedRecord.orderBy"
        v-model="localAnswer"
        v-validate="'decimal'"
        :error-messages="validationMessage(storedRecord.id)"
        :data-vv-name="storedRecord.id"
        placeholder="enter a value"
        :loading="saving"
        class="answer"
        :disabled="isJobCompleted || getAcceptRejectQuestionCriteria"
        @focus="editStarted"
        @blur="editComplete(false)"
      />
      <v-radio-group
        v-else-if="storedRecord.answerType === answerTypeCheckbox"
        :ref="'answer' + storedRecord.orderBy"
        v-model="localAnswer"
        :loading="saving"
        class="answer"
        @focus="editStarted"
        @change="editComplete(false)"
      >
        <v-radio
          label="Yes"
          color="primary"
          value="Yes"
          :disabled="isJobCompleted || getAcceptRejectQuestionCriteria"
        />
        <v-radio label="No" color="primary" value="No" :disabled="isJobCompleted || getAcceptRejectQuestionCriteria" />
      </v-radio-group>
      <div v-else>Unsupported answer type, please contact support for assistance.</div>
      <div
        v-if="!storedRecord.isValid"
        :class="storedRecord.validationStatus === 'invalid' ? 'answer-invalid' : 'answer-missing'"
      >
        {{ storedRecord.validationErrorMessage }}
      </div>
      <div v-if="storedRecord.forCommentOperatorId">
        <template v-if="storedRecord.validationForAdditionalInfoStatus === 'valid'">
          <v-textarea
            :ref="'comment' + storedRecord.orderBy"
            v-model="localComment"
            :loading="savingComment"
            :label="!storedRecord.commentLabel ? 'Additional Information' : storedRecord.commentLabel"
            class="answer"
            name="AdditionalInfo"
            :disabled="isJobCompleted || getAcceptRejectQuestionCriteria"
            @focus="editStarted"
            @blur="editComplete(true)"
          ></v-textarea>
          <div v-if="storedRecord.mandatory && (localComment === '' || localComment === null)" class="answer-missing">
            {{ 'comment to this question is mandatory' }}
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
/* tslint:disable:no-console */

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store'
import EmergencyQA from '@/models/EmergencyQA'
import AddEmergencyAnswer from '@/models/requests/AddEmergencyAnswer'
import JobController from '@/api/jobController'
import SiteInvestigationController from '@/api/siteInvestigationController'
import { AnswerType } from '@/common/enums'
import { isNumber } from 'util'
import store from '@/store'
import storeMutations from '@/storeMutations'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'
import storeGetters from '@/storeGetters'
import ProductQA from '@/models/ProductQA'

@Component
export default class EmergencyQAEditControl extends Vue {
  @Prop() private jobId: string
  @Prop() private emergencyQAId: string
  @Prop() private getAcceptRejectQuestionCriteria: boolean
  @Prop() private isJobCompleted: boolean
  @Prop() private type: string

  private answerTypeText: AnswerType = AnswerType.Text
  private answerTypeDropdown: AnswerType = AnswerType.Dropdown
  private answerTypeNumber: AnswerType = AnswerType.Number
  private answerTypeCheckbox: AnswerType = AnswerType.Checkbox

  private inEdit = false
  private saving = false
  private savingComment = false
  private syncing = false
  private localAnswer = ''
  private localComment: string | null = null
  private lastSubmitted = ''

  private created() {
    this.setLocalAnswer()
  }

  private mounted() {
    const answer1: any = this.$refs.answer1
    if (
      answer1 &&
      this.storedRecord.answerType !== this.answerTypeDropdown &&
      this.storedRecord.answerType !== this.answerTypeCheckbox
    ) {
      answer1.focus()
    }
  }

  private get isProductQA(): boolean {
    return this.type && this.type === 'ProductQA' ? true : false
  }

  private get storedRecord(): EmergencyQA | ProductQA {
    let item
    if (this.isProductQA) {
      item = storeGetters.getProductQAs(this.jobId).find((e) => e.id === this.emergencyQAId)
    } else {
      item = Store.Instance.state.Jobs[this.jobId].emergenciesQAs.find((e) => e.id === this.emergencyQAId)
    }
    if (!item) {
      throw new Error('failed to find QA item')
    }
    if (!this.isProductQA && item.validationForAdditionalInfoStatus !== 'valid') {
      this.localComment = ''
    }
    return item
  }

  @Watch('syncing')
  private syncingChanged(): void {
    this.$emit('setSyncing', this.syncing)
  }

  @Watch('storedRecord')
  private storedRecordChanged(): void {
    if (!this.inEdit || this.syncing) {
      // user not editing (got new value so stop syncing)
      this.syncing = false
      if (
        this.storedRecord.answer !== this.localAnswer ||
        (this.localComment !== '' && 'comment' in this.storedRecord && this.storedRecord.comment !== this.localComment)
      ) {
        // stored answer has been updated, update local version
        this.setLocalAnswer()
      }
    }
  }

  private editStarted(): void {
    this.inEdit = true
    this.syncing = false
  }

  private editComplete(isAdditionalInfoQA: boolean): void {
    if (
      this.lastSubmitted === this.localAnswer &&
      (!this.localComment || ('comment' in this.storedRecord && this.localComment === this.storedRecord.comment))
    ) {
      // same as last sumbitted, will skip
      return
    }

    // update store as per new answer and comment
    const eqa: EmergencyQA | ProductQA = this.storedRecord
    eqa.answer = this.localAnswer
    if (eqa instanceof EmergencyQA) {
      eqa.comment = this.localComment ? this.localComment.trim() : this.localComment
      storeMutations.addOrReplaceEmergencyQA(eqa)
    }

    // set focus on additional info comment box when validation fire
    setTimeout(() => {
      if (
        !isAdditionalInfoQA &&
        'forCommentOperatorId' in this.storedRecord &&
        this.storedRecord.forCommentOperatorId &&
        'validationForAdditionalInfoStatus' in this.storedRecord &&
        this.storedRecord.validationForAdditionalInfoStatus === 'valid'
      ) {
        const commentRef: any = this.$refs['comment' + this.storedRecord.orderBy]
        commentRef.focus()
        // prevent answer to save, when additional info commnet box is visible (as per validation)
        if (
          !this.localComment &&
          !isAdditionalInfoQA &&
          'validationForAdditionalInfoStatus' in this.storedRecord &&
          this.storedRecord.validationForAdditionalInfoStatus === 'valid'
        ) {
          return
        }
      }
      const answer = this.localAnswer
      this.inEdit = false

      if (this.storedRecord.mandatory && (!answer || !answer.trim())) {
        // invalid, mandatory answer. wont submit
        return
      }
      if (this.storedRecord.answerType === AnswerType.Number && answer && isNaN(answer as any)) {
        // invalid, numeric answer, and is not numeric. wont submit.
        return
      }

      const req = new AddEmergencyAnswer()
      req.id = this.emergencyQAId
      req.jobId = this.jobId
      req.answer = answer
      req.comment = this.localComment ? this.localComment.trim() : this.localComment
      if (
        this.lastSubmitted === answer &&
        this.localComment !== '' &&
        'forCommentOperatorId' in this.storedRecord &&
        this.storedRecord.forCommentOperatorId
      ) {
        this.savingComment = true
      } else {
        this.saving = true
      }
      this.lastSubmitted = answer

      if (this.isProductQA) {
        this.SaveProductQA(req)
      } else {
        this.SaveEmergencyQA(req)
      }
    }, 0)
  }

  private SaveEmergencyQA(req: AddEmergencyAnswer) {
    const self = this
    JobController.SaveEmergencyQA(req)
      .then((res: string) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to save answer')
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        self.saving = false
        if (this.savingComment) {
          this.savingComment = false
        }
      })
      .catch((err: any) => {
        self.saving = false
        eventBus.$emit('errorHandler', err, false)
      })
  }

  private SaveProductQA(req: AddEmergencyAnswer) {
    const self = this
    SiteInvestigationController.SaveProductQA(req)
      .then((res: string) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to save answer')
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        self.saving = false
        if (this.savingComment) {
          this.savingComment = false
        }
      })
      .catch((err: any) => {
        self.saving = false
        eventBus.$emit('errorHandler', err, false)
      })
  }

  private setLocalAnswer(): void {
    this.localAnswer = this.storedRecord.answer
    if ('comment' in this.storedRecord) {
      this.localComment = this.storedRecord.comment
    }
    this.lastSubmitted = this.localAnswer
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (message) {
      if (message.includes('decimal')) {
        message = 'An answer must be numeric and may contain decimal points.'
      } else {
        message = ''
      }
    }
    return message
  }
}
</script>

<style scoped>
.answer {
  padding-bottom: 0;
  width: 100%;
}
.answer-invalid {
  color: #ef9a9a;
}
.answer-missing {
  color: #ffcc80;
}
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.agent-note {
  background-color: #fff2af;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
