import ContractorState from '@/vuex/states/contractor-state'
import { IContractorState } from '@/vuex/states/interfaces/contractor-state'
import { GetterTree, Module, MutationTree } from 'vuex'

export const state: IContractorState = new ContractorState()

export const getters: GetterTree<IContractorState, any> = {
  selectedContractorId: (state) => state.selectedContractorId,
}

export const mutations: MutationTree<IContractorState> = {
  setSelectedContractorId: (state, id: string | null) => {
    state.selectedContractorId = id
  },
}

export const contractorModule: Module<IContractorState, any> = {
  state,
  getters,
  mutations,
  namespaced: true,
}
