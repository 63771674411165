import { ISnackbarState } from './interfaces/snackbar-state'

export default class SnackbarState implements ISnackbarState {
  public show = false
  public message = ''
  public color = ''
  public icon = ''
  public timeout = 6000
  public showClose = true
}
