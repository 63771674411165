<template>
  <div>
    <div class="mb-2"><h4>Emergency Accepted Or Rejected</h4></div>
    <div class="mb-1">
      <label>Emergency Accepted: </label>
      <span>{{ item.isEmergencyAccepted ? 'Yes' : 'No' }}</span>
    </div>
    <div v-if="item.repudiationDescription" class="mb-1">
      <label>Repudiation Description: </label>
      <span>
        <pre>{{ item.repudiationDescription }}</pre>
      </span>
    </div>
    <div v-if="item.acceptRejectNote" class="mb-1">
      <label>
        {{ item.isEmergencyAccepted ? 'Accept Note:' : 'Reject Note:' }}
      </label>
      <span>
        <pre>{{ item.acceptRejectNote }}</pre>
      </span>
    </div>
    <div class="mb-1">
      <label>Agreed for CTP?: </label>
      <span>
        <pre>{{ item.ctpCompletedForRejectedEmergency ? 'Yes' : 'No' }}</pre>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateEmergencyAcceptedOrRejected from '@/models/requests/UpdateEmergencyAcceptedOrRejected'

@Component
export default class UpdateEmergencyAcceptedOrRejectedLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateEmergencyAcceptedOrRejected {
    return Object.assign(new UpdateEmergencyAcceptedOrRejected(), this.requestBody)
  }
}
</script>

<style scoped>
pre {
  display: inherit;
  font-family: inherit;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
