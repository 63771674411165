<template>
  <div>
    <div class="mb-2"><h4>Deleted Job Document</h4></div>
    <div v-if="item.documentName" class="mb-1">
      <label>Document Name: </label>
      <span>{{ item.documentName }}</span>
    </div>
    <div v-if="item.documentType" class="mb-1">
      <label>Document Type: </label>
      <span>{{ item.documentType }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UploadedJobDocumentDetail from '@/models/UploadedJobDocumentDetailModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class RemoveUploadJobDocumentLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UploadedJobDocumentDetail {
    return Object.assign(new UploadedJobDocumentDetail(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }
}
</script>
