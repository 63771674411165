<template>
  <div>
    <div class="mb-2"><h4>Escalate Complaint</h4></div>
    <div v-if="complaint.complaintTypeDescription" class="mb-1">
      <label>Complaint Type: </label>
      <span>{{ complaint.complaintTypeDescription }}</span>
    </div>
    <div v-if="complaint.severity !== ''" class="mb-1">
      <label>Severity: </label>
      <span>{{ complaint.severity }}</span>
    </div>
    <div class="mb-1">
      <label>Escalation Reason: </label>
      <span>{{ request.escalationReason }}</span>
    </div>
    <div class="mb-1">
      <label>Escalation Description: </label>
      <span>{{ request.escalationDescription }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddComplaintEscalateRequest from '@/models/requests/AddComplaintEscalateRequest'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'

@Component
export default class AddComplaintEscalateRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddComplaintEscalateRequest {
    return Object.assign(new AddComplaintEscalateRequest(), this.requestBody)
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.item.jobId)
  }

  private get complaint() {
    return this.item.jobId && this.job ? this.job.complaint.find((c) => c.id === this.item.complaintId) : undefined
  }
}
</script>
