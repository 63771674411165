import store from '@/store'
import { commandNames } from '../commands/command-names'
import CommandHandler from '../interfaces/command-handler'
import router from '@/router'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import StoreMutations from '@/storeMutations'
import EmergencyQA from '@/models/EmergencyQA'
import CreateClaimResponse from '@/messaging/models/claim-response/create-claim-response'
import Job from '@/models/Job'

export default class CreateHelplineClaimHandler implements CommandHandler {
  public readonly commandName = commandNames.createClaimCommand
  async execute(value: any): Promise<void> {
    const claim = value as unknown as CreateClaimResponse

    await store.Instance.dispatch('heWizardModule/setHeWizardVisible', false)

    if (!claim) {
      await store.Instance.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar('No job passed from wizard, cannot navigate to job')
      )
      return
    }

    const job = new Job()

    if (claim.emergenciesQAs) {
      job.emergenciesQAs = claim.emergenciesQAs.map((x) => Object.assign(new EmergencyQA(), x))
    }

    StoreMutations.addOrReplaceJob(job)

    router.push({
      name: 'job',
      params: {
        jobId: claim.jobId,
      },
    })
  }
}
