import { commandNames } from './commands/command-names'
import CloseWizardHandler from './handlers/close-wizard-handler'
import CreateClaimHandler from './handlers/create-claim-handler'
import CreateEnquiryHandler from './handlers/create-enquiry-handler'
import CreateHelplineClaimHandler from './handlers/create-helpline-claim-handler'
import OpenDuplicateJobHandler from './handlers/open-duplicate-job-handler'
import CommandHandler from './interfaces/command-handler'

export function receiveCommandMessage(event: MessageEvent) {
  const hostMatches = event.origin.toLowerCase().startsWith(`${window.location.protocol}//${window.location.host}`)
  if (!hostMatches || !event.data || !event.data.command) return

  const commandName: string = event.data.command
  const callbackHandler: CommandHandler | null = callbackHandlers[commandName] || null

  if (callbackHandler) {
    callbackHandler.execute(event.data.data)
  }
}

export function sendCommandMessageToFrame<T>(frame: HTMLIFrameElement | null, command: CommandMessage<T>) {
  if (frame && frame.contentWindow) {
    frame.contentWindow.postMessage(command, '*')
  }
}

const callbackHandlers: { [x: string]: CommandHandler } = {
  [commandNames.closeWizardCommand]: new CloseWizardHandler(),
  [commandNames.createClaimCommand]: new CreateClaimHandler(),
  [commandNames.createEnquiryCommand]: new CreateEnquiryHandler(),
  [commandNames.openDuplicateJobCommand]: new OpenDuplicateJobHandler(),
  [commandNames.createHelplineClaimCommand]: new CreateHelplineClaimHandler(),
}
