<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-card class="pa-1">
          <v-card-title>
            <h3>Monitoring Detail</h3>
          </v-card-title>
          <v-card-text class="px-0 py-2">
            <v-layout wrap xs12 class="px-2">
              <v-flex xs6 px-2>
                <v-text-field
                  v-model.number="monitoringDetail.plannedVisitCount"
                  v-validate="`required|min_value:0`"
                  label="Planned Visit Count"
                  name="plannedVisitCount"
                  :data-vv-scope="changeFormref"
                  required
                  class="required plannedVisitCount"
                  data-vv-name="plannedVisitCount"
                  type="number"
                  :error-messages="validationMessage('plannedVisitCount')"
                  :disabled="isMonitoringDetail || loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 px-2>
                <v-text-field
                  v-model.number="monitoringDetail.visitFrequency"
                  v-validate="`required|min_value:0`"
                  label="Visit Frequency"
                  name="visitFrequency"
                  :data-vv-scope="changeFormref"
                  required
                  class="required visitFrequency"
                  data-vv-name="visitFrequency"
                  type="number"
                  :error-messages="validationMessage('visitFrequency')"
                  :disabled="isMonitoringDetail || loading"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="px-3 pb-3">
            <v-spacer />
            <v-btn
              v-show="!isMonitoringDetail"
              color
              :disabled="loading"
              class="btnCancel"
              @click="cancelMonitoringDetail()"
            >
              Cancel
            </v-btn>
            <v-btn
              v-show="!isMonitoringDetail"
              solid
              color="primary"
              class="btnSave"
              :disabled="loading"
              @click="addMonitoringDetail()"
            >
              Save
            </v-btn>
            <v-btn
              v-show="isMonitoringDetail"
              class="btnEdit"
              solid
              color="primary"
              :disabled="loading"
              @click="editMonitoringDetail()"
            >
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 mt-3>
        <v-card class="pa-1">
          <v-card-title class="py-0">
            <h3>Monitoring Visit Logs</h3>
            <v-spacer />
            <v-btn
              flat
              solid
              class="text-xs-right add-record pr-0"
              color="primary"
              :disabled="isActionButtonDisabled()"
              @click="addVisitLog"
            >
              Add Visit
              <v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="monitoringVisitLogs.length > 0"
              flat
              solid
              class="text-xs-right visit-invoice pr-0"
              color="primary"
              :disabled="isActionButtonDisabled()"
              @click="showVisitInvoiceDialog = true"
            >
              Visit Invoice
              <v-icon class="pl-1">receipt</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-layout wrap pt-1>
              <v-flex xs12 grid-view monitor-product-section>
                <MonitoringVisitLogs
                  ref="monitoringVisitsLogRef"
                  :job-id="jobId"
                  :monitoring-visit-logs="monitoringVisitLogs"
                  :loading="loading"
                  @saveMonitoringVisitLog="saveMonitoringVisitLog"
                  @deleteMonitoringVisitLog="deleteMonitoringVisitLog"
                ></MonitoringVisitLogs>
              </v-flex>
              <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
                {{ snackbarText }}
                <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
              </v-snackbar>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- dialog - visit invoice -->
    <v-dialog
      v-if="showVisitInvoiceDialog"
      v-model="showVisitInvoiceDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Create Visit Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeVisitLogInvoiceDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog">
          <v-layout wrap mb-1>
            <v-flex xs12 grid-view>
              <MonitoringVisitLogs
                ref="monitoringVisitInvoiceRef"
                :job-id="jobId"
                :monitoring-visit-logs="getFilteredVisitLogs"
                :loading="loading"
                :is-visit-log-invoice="true"
              ></MonitoringVisitLogs>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="closeVisitLogInvoiceDialog">Close</v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || getFilteredVisitLogs.length === 0"
            class="mr-0 submit-invoice-btn"
            @click.native="createVisitLogInvoice"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import JobPackageState from '@/models/siteInvestigation/JobPackageState'
import MonitoringVisitLog from '@/models/siteInvestigation/MonitoringVisitLog'
import eventBus from '@/common/bus'
import Store from '@/store'
import Shared from '@/common/shared'
import JobController from '@/api/jobController'
import UpdateSIJobPackage from '@/models/requests/UpdateSIJobPackage'
import moment from 'moment'
import SiteInvestigationController from '@/api/siteInvestigationController'
import MonitoringDetail from '@/models/siteInvestigation/MonitoringDetail'
import storeGetters from '@/storeGetters'
import { error } from 'util'
import MonitoringProduct from '@/models/siteInvestigation/MonitoringProduct'
import ProductAttribute from '@/models/siteInvestigation/ProductAttribute'
import MonitoringVisitLogs from '@/components/MonitoringVisitLogs.vue'
import AddMonitoringVisitInvoiceRequestModel from '@/models/siteInvestigation/requests/AddMonitoringVisitInvoiceRequestModel'

@Component({
  name: 'MonitoringDetailPreview',
  components: {
    MonitoringVisitLogs,
  },
})
export default class MonitoringDetailPreview extends TimeLineItemBase {
  private monitoringDetail: MonitoringDetail = new MonitoringDetail()
  private isMonitoringDetail = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private loading = false
  private changeFormref = 'changeMonitoringDetailFormRef'
  private showVisitInvoiceDialog = false

  private get monitoringRecord(): MonitoringDetail | null {
    return storeGetters.getMonitoringDetail(this.jobId)
  }

  private get monitoringVisitLogs(): MonitoringVisitLog[] {
    if (this.monitoringDetail && this.monitoringDetail.monitoringVisitLogs) {
      return this.monitoringDetail.monitoringVisitLogs
    }
    return []
  }

  public mounted() {
    this.getMonitoringVisitLogs()
  }

  public sortMonitoringVisitLog(visitLogs: MonitoringVisitLog[]) {
    // sort data visit date vise
    return visitLogs.sort((a, b) => {
      const aVisitDate = moment(a.visitDate)
      const bVisitDate = moment(b.visitDate)
      if (aVisitDate.isBefore(bVisitDate)) {
        return -1
      } else if (aVisitDate && bVisitDate && aVisitDate.isAfter(bVisitDate)) {
        return 1
      } else {
        return 0
      }
    })
    // return visitLogs;
  }

  @Watch('monitoringRecord')
  private monitoringDetailChange() {
    this.getMonitoringVisitLogs()
  }

  private getMonitoringVisitLogs() {
    this.loading = true
    if (this.monitoringRecord) {
      this.monitoringDetail = this.monitoringRecord

      if (this.monitoringDetail.plannedVisitCount || this.monitoringDetail.visitFrequency) {
        this.isMonitoringDetail = true
      }
      if (this.monitoringDetail.monitoringVisitLogs) {
        this.sortMonitoringVisitLog(this.monitoringDetail.monitoringVisitLogs)
      }
    }
    this.loading = false
  }

  private addVisitLog() {
    const monitoringVisitLogSub = this.$refs.monitoringVisitsLogRef as MonitoringVisitLogs
    if (monitoringVisitLogSub) {
      monitoringVisitLogSub.addVisitLog()
    }
  }

  private editMonitoringDetail() {
    this.isMonitoringDetail = false
  }

  private async addMonitoringDetail() {
    const validate = await this.validate(this.changeFormref)
    if (!validate) {
      return
    }
    this.loading = true
    SiteInvestigationController.UpdateMonitoringDetails(this.monitoringDetail)
      .then((res: boolean) => {
        if (res) {
          this.showSnackbarMessage('Monitoring detail updated successfully.')
        }
        this.isMonitoringDetail = true
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
        eventBus.$emit('errorHandler', 'Error update monitoring detail, please try again!', true)
      })
  }

  private saveMonitoringVisitLog(visitLog: MonitoringVisitLog, isNewVisitLog: boolean) {
    this.loading = true
    SiteInvestigationController.UpsertMonitoringVisit(this.monitoringDetail.id, this.jobId, visitLog)
      .then((res: boolean) => {
        if (res) {
          this.showSnackbarMessage(
            isNewVisitLog ? 'Monitoring visit log added successfully.' : 'Monitoring visit log updated successfully.'
          )
        }
        this.setDefaultValues()
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
        eventBus.$emit('errorHandler', 'Error monitoring visit log, please try again!', true)
      })
  }

  private deleteMonitoringVisitLog(visitLog: MonitoringVisitLog) {
    Shared.confirmationPopup.open(
      'Are you sure you want to delete this visit log?',
      '',
      '',
      'No',
      'Yes',
      this,
      'deleteMonitoringVisit',
      visitLog
    )
  }

  private deleteMonitoringVisit(visitLog: MonitoringVisitLog) {
    this.loading = true
    SiteInvestigationController.DeleteMonitoringVisit(this.monitoringDetail.id, this.jobId, visitLog.visitId)
      .then((res: boolean) => {
        if (res) {
          const index = this.monitoringDetail.monitoringVisitLogs.indexOf(visitLog)
          if (index !== -1) {
            this.monitoringDetail.monitoringVisitLogs.splice(index, 1)
          }
          this.showSnackbarMessage('Monitoring visit log deleted successfully.')
        }
        this.setDefaultValues()
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
        eventBus.$emit('errorHandler', 'Error deleting visit log, please try again!', true)
      })
  }

  private cancelMonitoringDetail() {
    this.isMonitoringDetail = true
  }

  private async validate(formref: any): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(formref)
    return result
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private isActionButtonDisabled() {
    const monitoringVisitLogSub = this.$refs.monitoringVisitsLogRef as MonitoringVisitLogs
    if (monitoringVisitLogSub) {
      return monitoringVisitLogSub.disableActionButton()
    }
    return false
  }

  private setDefaultValues() {
    const monitoringVisitLogSub = this.$refs.monitoringVisitsLogRef as MonitoringVisitLogs
    if (monitoringVisitLogSub) {
      monitoringVisitLogSub.setDefaultValues()
    }
  }

  private get getFilteredVisitLogs(): MonitoringVisitLog[] {
    return this.monitoringVisitLogs.filter((m) => !m.invoicedDate)
  }

  private closeVisitLogInvoiceDialog() {
    this.showVisitInvoiceDialog = false
    this.loading = false
  }

  private createVisitLogInvoice() {
    let monitoringVisitLogs: MonitoringVisitLog[] = []
    const monitoringVisitLogSub = this.$refs.monitoringVisitInvoiceRef as MonitoringVisitLogs
    if (monitoringVisitLogSub) {
      monitoringVisitLogs = monitoringVisitLogSub.selectedVisitLogs
    }
    if (monitoringVisitLogs.length === 0) {
      this.showSnackbarMessage('Please select visit(s) to create invoice.')
    } else {
      this.loading = true
      const monitoringVisitInvoiceRequest: AddMonitoringVisitInvoiceRequestModel =
        new AddMonitoringVisitInvoiceRequestModel()
      monitoringVisitInvoiceRequest.id = this.monitoringDetail.id
      monitoringVisitInvoiceRequest.jobId = this.jobId
      monitoringVisitInvoiceRequest.visitIds = monitoringVisitLogs.map((m) => m.visitId)
      SiteInvestigationController.MonitoringVisitInvoice(monitoringVisitInvoiceRequest)
        .then((res: boolean) => {
          if (res) {
            this.showSnackbarMessage('Invoice created for selected monitoring visit(s).')
          }
          this.showVisitInvoiceDialog = false
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          eventBus.$emit('errorHandler', 'Error creating monitoring visit invoice, please try again!', true)
        })
    }
  }

  private showSnackbarMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }
}
</script>

<style scoped>
.monitor-product-section.gridView >>> th {
  font-size: 14px !important;
}
.monitor-product-section.gridView th:first-child {
  padding-left: 0px !important;
}
.monitor-product-section >>> .action-col {
  min-width: 15px;
}
.monitor-product-section >>> tbody td {
  word-break: break-all;
  white-space: normal;
}
.add-record {
  float: right;
}
.monitor-product-section >>> table.v-table tfoot tr td {
  padding: 0px 10px;
}
.monitor-product-section >>> thead th {
  min-width: 65px;
  word-break: break-all;
  white-space: normal;
}

@media only screen and (max-width: 1400px) {
  .action-td-col .v-btn .v-icon {
    font-size: 18px;
  }
  .action-td-col .v-btn {
    height: 24px;
    width: 24px;
  }
}
</style>
