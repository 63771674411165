<template>
  <div>
    <div class="mb-2"><h4>Engineer Auto Deployment</h4></div>
    <div class="mb-1">
      <label>Emergency Name: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-for="(contractorId, index) in item.contractorIds" :key="index" class="mb-1">
      <label v-if="companyName(contractorId)">Company Name: </label>
      <span>{{ companyName(contractorId) }}</span>
    </div>
    <div class="mb-1">
      <label>Started At: </label>
      <span>{{ getFormattedDate(item.autoDeploymentStartedAt) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AutoDeployRequest from '@/models/requests/AutoDeployRequestModel'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import moment from 'moment'
import ContractorAppointedModel from '../../models/claim/ContractorAppointedModel'

@Component
export default class EngineerAutoDeploymentLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AutoDeployRequest {
    return Object.assign(new AutoDeployRequest(), this.requestBody)
  }

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.item.emergencyId)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get getJobContractorDetail(): ContractorAppointedModel[] {
    return storeGetters.getContractorAppointedDetail(this.item.jobId, this.item.emergencyId)
  }

  private companyName(contractorId): string | undefined {
    const contractorDetail = this.getJobContractorDetail.find((x) => x.contractorId === contractorId)
    return contractorDetail ? contractorDetail.companyName : ''
  }
}
</script>

<style scoped>
pre {
  display: inherit;
  font-family: inherit;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
