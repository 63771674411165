<template>
  <div v-if="item">
    <div class="mb-2">
      <h4>{{ title }}</h4>
    </div>
    <div v-if="item.companyName" class="mb-1">
      <label>Contractor: </label>
      <span>{{ item.companyName }}</span>
    </div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ item.status }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateContractorAppointedStatus from '@/models/requests/UpdateContractorAppointedStatus'
import { JobVisitStatus } from '@/common/enums'

@Component
export default class UpdateContractorAppointedStatusLog extends Vue {
  @Prop() private requestBody: any
  @Prop() private emergencyName: string
  private jobVisitStatus = JobVisitStatus

  private get item(): UpdateContractorAppointedStatus {
    return Object.assign(new UpdateContractorAppointedStatus(), this.requestBody)
  }

  private get title() {
    if (this.item.status === JobVisitStatus[JobVisitStatus.Unavailable]) {
      return 'Contractor unavailable'
    } else if (this.item.status === JobVisitStatus[JobVisitStatus.Cancelled]) {
      return 'Remove contractor from job'
    }
    return 'Contractor appointed on job'
  }
}
</script>
