<template>
  <div>
    <div class="mb-2"><h4>Delete Bore Hole Log</h4></div>
    <div v-if="item">
      <div v-if="item.boreHoleRunNumber" class="mb-1">
        <label>Run Number: </label>
        <span>{{ item.boreHoleRunNumber }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import DeleteBoreholeLogModel from '../../models/requests/DeleteBoreholeLogModel'

@Component
export default class DeleteBoreholeLog extends Vue {
  @Prop() private requestBody: any

  private get item(): DeleteBoreholeLogModel {
    return Object.assign(new DeleteBoreholeLogModel(), this.requestBody)
  }
}
</script>

<style scoped></style>
