<template>
  <v-data-table
    v-model="selectedVisitLogs"
    :headers="monitoringVisitLogHeaders"
    :items="monitoringVisitLogs"
    item-key="visitId"
    hide-actions
    :loading="loading"
    :select-all="isVisitLogInvoice && monitoringVisitLogs.length > 0"
  >
    <template slot="items" slot-scope="props">
      <tr :active="props.selected">
        <td v-show="isVisitLogInvoice">
          <v-checkbox
            v-model="props.selected"
            class="select-visit-chkbox"
            :disabled="loading"
            color="primary"
            hide-details
            @click.stop="props.selected = !props.selected"
          ></v-checkbox>
        </td>
        <td class="text-xs-center">
          <DateTimePicker
            v-if="updatingLog && index === props.item.visitId"
            :data-vv-scope="editFormref"
            :date-time.sync="props.item.visitDate"
            :is-static-location="false"
            :is-current-time="true"
            :is-validation-required="true"
            :show-present-time-icon="false"
            :hide-time-picker="true"
            :display-format="dateFormat"
            :disabled="loading"
            place-holder-text="Visit Date"
          />
          <span v-else>{{ formatDate(props.item.visitDate) }}</span>
        </td>
        <td class="text-xs-center">
          <DateTimePicker
            v-if="updatingLog && index === props.item.visitId"
            :data-vv-scope="editFormref"
            :date-time.sync="props.item.reportSentDate"
            :min-date="formatDate(props.item.visitDate, true)"
            :is-static-location="false"
            :is-current-time="false"
            :is-validation-required="true"
            :show-present-time-icon="false"
            :hide-time-picker="true"
            :display-format="dateFormat"
            :disabled="loading"
            place-holder-text="Report Sent Date"
          />
          <span v-else>{{ formatDate(props.item.reportSentDate) }}</span>
        </td>
        <td class="text-xs-center">
          <DateTimePicker
            v-if="updatingLog && index === props.item.visitId"
            :data-vv-scope="editFormref"
            :date-time.sync="props.item.invoicedDate"
            :min-date="formatDate(props.item.reportSentDate, true)"
            :is-static-location="false"
            :is-current-time="false"
            :is-validation-required="false"
            :show-present-time-icon="false"
            :hide-time-picker="true"
            :display-format="dateFormat"
            :disabled="true"
            place-holder-text="Invoiced Date"
          />
          <span v-else>
            {{ props.item.invoicedDate ? formatDate(props.item.invoicedDate) : '-' }}
          </span>
        </td>
        <td class="text-xs-center">
          <v-text-field
            v-if="updatingLog && index === props.item.visitId"
            v-model.number="props.item.cost"
            v-validate="`required|min_value:0`"
            label="cost"
            hide-details
            :data-vv-scope="editFormref"
            data-vv-name="cost"
            type="number"
            :error-messages="validationMessage('cost')"
            :disabled="loading"
          ></v-text-field>
          <span v-else>{{ props.item.cost }}</span>
        </td>
        <td class="text-xs-center">
          <span v-if="updatingLog && index === props.item.visitId">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  icon
                  flat
                  color="primary"
                  class="ma-0 save-btn"
                  v-on="on"
                  @click="saveMonitoringVisitLog(props.item)"
                >
                  <v-icon class="md-icon">save</v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  icon
                  flat
                  color="primary"
                  class="ma-0 cancel-btn"
                  v-on="on"
                  @click="cancelMonitoringVisitLog(props.item)"
                >
                  <v-icon class="md-icon">close</v-icon>
                </v-btn>
              </template>
              <span>Cancel</span>
            </v-tooltip>
          </span>
          <v-menu
            v-else
            v-show="isVisitLogInvoice ? false : !props.item.invoicedDate"
            offset-y
            left
            class="menu-edit"
            :disabled="loading"
          >
            <template #activator="{ on }">
              <v-btn icon flat color="primary" class="ma-0 action-btn" :disabled="loading || isNewVisitLog" v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <div class="pa-2 white">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    flat
                    color="primary"
                    class="ma-0 delete-btn"
                    v-on="on"
                    @click="deleteMonitoringVisitLog(props.item)"
                  >
                    <v-icon class="md-icon">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    flat
                    color="primary"
                    class="ma-0 edit-btn"
                    v-on="on"
                    @click.native="editMonitoringVisitLog(props.item)"
                  >
                    <v-icon class="md-icon">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
          </v-menu>
        </td>
      </tr>
    </template>
    <template v-if="isNewVisitLog" :slot="monitoringVisitLogs.length ? 'footer' : 'no-data'">
      <tr>
        <td class="text-xs-center">
          <DateTimePicker
            :data-vv-scope="addFormref"
            :date-time.sync="newVisit.visitDate"
            :is-static-location="false"
            :is-current-time="true"
            :is-validation-required="true"
            :show-present-time-icon="false"
            :hide-time-picker="true"
            :display-format="dateFormat"
            :disabled="loading"
            place-holder-text="Visit Date"
          />
        </td>
        <td class="text-xs-center">
          <DateTimePicker
            :data-vv-scope="addFormref"
            :date-time.sync="newVisit.reportSentDate"
            :min-date="formatDate(newVisit.visitDate, true)"
            :is-static-location="false"
            :is-current-time="false"
            :is-validation-required="true"
            :show-present-time-icon="false"
            :hide-time-picker="true"
            :display-format="dateFormat"
            :disabled="loading"
            place-holder-text="Report Sent Date"
          />
        </td>
        <td class="text-xs-center">
          <DateTimePicker
            :data-vv-scope="addFormref"
            :date-time.sync="newVisit.invoicedDate"
            :min-date="formatDate(newVisit.reportSentDate, true)"
            :is-static-location="false"
            :is-current-time="false"
            :is-validation-required="false"
            :show-present-time-icon="false"
            :hide-time-picker="true"
            :display-format="dateFormat"
            :disabled="true"
            place-holder-text="Invoiced Date"
          />
        </td>
        <td class="text-xs-center">
          <v-text-field
            v-model.number="newVisit.cost"
            v-validate="`required|min_value:0`"
            :data-vv-scope="addFormref"
            data-vv-name="Read cost"
            label="cost"
            hide-details
            type="number"
            :error-messages="validationMessage('Read cost')"
            :disabled="loading"
          ></v-text-field>
        </td>
        <td class="text-xs-center">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                flat
                color="primary"
                class="ma-0 save-btn"
                v-on="on"
                @click="saveMonitoringVisitLog(newVisit)"
              >
                <v-icon class="md-icon">save</v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn icon flat color="primary" class="ma-0 cancel-btn" v-on="on" @click="close()">
                <v-icon class="md-icon">close</v-icon>
              </v-btn>
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import eventBus from '@/common/bus'
import MonitoringVisitLog from '../models/siteInvestigation/MonitoringVisitLog'
import MonitoringProduct from '../models/siteInvestigation/MonitoringProduct'
import ProductAttribute from '../models/siteInvestigation/ProductAttribute'
import Job from '../models/Job'
import DateTimePicker from '@/components/DateTimePicker.vue'

@Component({
  name: 'MonitoringVisitsLog',
  components: { DateTimePicker },
})
export default class MonitoringVisitLogs extends Vue {
  public selectedVisitLogs: MonitoringVisitLog[] = []

  @Prop() private monitoringVisitLogs: MonitoringVisitLog[]
  @Prop() private loading: boolean
  @Prop() private jobId: string
  @Prop({ default: false }) private isVisitLogInvoice: boolean

  private isNewVisitLog = false
  private updatingLog = false
  private index: any = null
  private newVisit: MonitoringVisitLog = new MonitoringVisitLog()
  private previousItem: MonitoringVisitLog = new MonitoringVisitLog()
  private monitoringVisitLogHeaders: any = [
    {
      text: 'Visit Date',
      value: 'visitDate',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Report Sent Date',
      value: 'reportSentDate',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Invoiced Date',
      value: 'invoicedDate',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Cost',
      value: 'cost',
      sortable: false,
      align: 'center',
    },
    {
      text: '',
      value: '',
      class: 'action-col',
      sortable: false,
      isAction: false,
    },
  ]
  private addFormref = 'addMonitoringVisitLogFormRef'
  private editFormref = 'editMonitoringVisitLogFormRef'

  public setDefaultValues() {
    this.isNewVisitLog = false
    this.updatingLog = false
    this.index = null
  }

  public addVisitLog() {
    this.isNewVisitLog = true
    this.updatingLog = false
    this.newVisit = new MonitoringVisitLog()
    this.newVisit.cost = this.readCost
  }

  public disableActionButton() {
    return this.isNewVisitLog || this.updatingLog || this.loading
  }

  // get job detail
  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private async validate(formref: any): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(formref)
    return result
  }

  private async saveMonitoringVisitLog(visitLog: MonitoringVisitLog) {
    const formRef = this.isNewVisitLog ? this.addFormref : this.editFormref
    const validate = await this.validate(formRef)
    if (!validate) {
      return
    }

    this.$emit('saveMonitoringVisitLog', visitLog, this.isNewVisitLog)
  }

  private get dateFormat(): string {
    return Store.Instance.state.Environment.DateFormat
  }

  private formatDate(date: Date | string | moment.Moment, isDateTimePicker = false) {
    if (isDateTimePicker) {
      return moment(date).format(Shared.dateFormatForDatePicker)
    }
    return moment(date).format(this.dateFormat)
  }

  private cancelMonitoringVisitLog(visitLog: MonitoringVisitLog) {
    visitLog.visitDate = this.previousItem.visitDate
    visitLog.reportSentDate = this.previousItem.reportSentDate
    visitLog.invoicedDate = this.previousItem.invoicedDate
    visitLog.cost = this.previousItem.cost
    this.index = null
    this.updatingLog = false
    this.$validator.errors.items = []
  }

  private deleteMonitoringVisitLog(visitLog: MonitoringVisitLog) {
    this.$emit('deleteMonitoringVisitLog', visitLog)
  }

  private editMonitoringVisitLog(visitLog: MonitoringVisitLog) {
    this.previousItem = Object.assign({}, visitLog)
    this.index = visitLog.visitId
    this.updatingLog = true
  }

  private close() {
    this.isNewVisitLog = false
  }

  private get readCost(): number {
    let readCost = 0
    if (
      this.job &&
      this.job.sIJobDetail &&
      this.job.sIJobDetail.jobPackage &&
      this.job.sIJobDetail.jobPackage.monitoringProducts
    ) {
      const monitoringProducts: MonitoringProduct[] = this.job.sIJobDetail.jobPackage.monitoringProducts
      const productAttributeArray: Array<ProductAttribute[] | undefined> = monitoringProducts.map(
        (x) => x.productAttributes
      )
      productAttributeArray.forEach((productAttribute) => {
        if (productAttribute) {
          readCost = productAttribute
            .filter((attribute) => attribute.attributeName === 'ReadCost')
            .reduce((sum, attribute) => sum + Number(attribute.attributeValue), readCost)
        }
      })
    }
    return readCost
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }
}
</script>
