import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { IPolicyState } from '../states/interfaces/policy-state'
import PolicyState from '../states/policy-state'
import JobPolicyDetails from '@/models/policy/JobPolicyDetails'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'

const policyState: IPolicyState = new PolicyState()

const getters: GetterTree<IPolicyState, any> = {
  jobPolicyDetails: (state) => state.jobPolicyDetails,
}

const mutations: MutationTree<IPolicyState> = {
  setJobPolicyDetails: (state: IPolicyState, policy: JobPolicyDetails) => {
    state.jobPolicyDetails = policy
  },
}

const actions: ActionTree<IPolicyState, any> = {
  async selectJobPolicyDetails({ commit }, policy: JobPolicyDetails) {
    commit('setJobPolicyDetails', policy)
  },

  async updatePolicyDetails({ commit, getters }, jobId: string) {
    const jobPolicyDetails: JobPolicyDetails = getters.jobPolicyDetails

    if (jobPolicyDetails.clientId === 1) {
      const res = await CallCentreApi.post(`jobs/${jobId}/policy`, jobPolicyDetails.policyDetails)
      if (res.status >= 200 && res.status < 300) {
        commit('setJobPolicyDetails', new JobPolicyDetails())
      }
    }
  },
}

export const policyModule: Module<IPolicyState, any> = {
  state: policyState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
