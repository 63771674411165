<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>{{ jobTypeLabel('emergency') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.native="closeEmergencyDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-3 scroll-content-dialog">
      <v-form autocomplete="off">
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              v-model.trim="description"
              v-validate="'required|max:50'"
              :label="jobTypeLabel('emergency')"
              maxlength="50"
              data-vv-scope="frmEmergency"
              :data-vv-name="jobTypeLabel('emergency')"
              :error-messages="errors.collect(jobTypeLabel('emergency'))"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-combobox
              v-model="engineerSiteSLA"
              label="Engineer Site SLA"
              :items="engineerSiteSLAItems"
              @keypress="numberKeyValidation($event)"
            ></v-combobox>
          </v-flex>
          <v-flex v-if="jobType === 'HE'" xs12 mb-2>
            <v-switch
              v-model="hideOnCustomerPortals"
              label="Exclude From Customer Portal"
              color="primary"
              hide-details
              class="mb-1 hide-on-portal"
            ></v-switch>
          </v-flex>
          <v-flex xs12 class="pl-0 text-xs-center icon-validation">
            <v-text-field
              v-model="imageName"
              label="Logo"
              placeholder="Select logo"
              prepend-icon="attach_file"
              @click="pickFile"
            ></v-text-field>
            <input
              ref="image"
              v-validate="{
                rules: {
                  required: false,
                  image: true,
                  size: LogoSize,
                  mimes: 'image/*',
                },
              }"
              type="file"
              style="display: none"
              accept="image/*"
              name="logo"
              data-vv-scope="frmEmergency"
              @change="onFilePicked"
            />
            <div class="input-group__details">
              <div class="input-group__messages input-group__error input-group--error error--text">
                <span v-if="imageValidationMessage(errors)">
                  {{ logoErrorMsg }}
                </span>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="pl-3 ml-3">
            <div v-if="imageUrl" class="show-up-logo">
              <img :src="imageUrl" />
              <v-btn icon color="grey" class="lighten-3" small @click.native.stop="removeImage">
                <v-icon small dark color="primary">delete</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn flat="flat" @click="closeEmergencyDialog">Cancel</v-btn>
      <v-btn color="primary" class="mr-0" :disabled="isLoading" :loading="isLoading" @click="saveEmergency">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import Shared from '@/common/shared'
import Store from '@/store'
import { JobType } from '../common/enums'
import eventBus from '@/common/bus'

@Component
export default class EmergencyComponent extends Vue {
  @Prop() private emergency: any
  @Prop({ default: 'HE' }) private jobType: JobType

  private description = ''
  private imageName = ''
  private imageUrl = ''
  private logoErrorMsg = ''
  private localEmergency: EmergencyModel = new EmergencyModel()
  private isLoading = false
  private engineerSiteSLA = 0
  private hideOnCustomerPortals = false
  private engineerSiteSLAItems: string[] = ['4', '8', '16', '24']

  public created() {
    this.description = ''
    this.engineerSiteSLA = 0
    this.hideOnCustomerPortals = false
    this.localEmergency = this.emergency
  }

  private closeEmergencyDialog() {
    this.$emit('closeEmergency')
  }

  @Watch('localEmergency')
  private setLocalEmergency(newValue: EmergencyModel) {
    this.localEmergency = newValue
    if (this.localEmergency === null || this.localEmergency === undefined) {
      this.localEmergency = new EmergencyModel()
    }
    if (this.localEmergency.emergencyId !== undefined) {
      this.description = this.localEmergency.description
      this.engineerSiteSLA = this.localEmergency.engineerSiteSLA
      this.hideOnCustomerPortals = this.localEmergency.hideOnCustomerPortals
      // Appended new Date().getTime() to fileURL to laod updated image without refreshing page.
      this.imageUrl =
        this.localEmergency.fileURL !== '' && this.localEmergency.fileURL !== null
          ? this.localEmergency.fileURL + '?' + new Date().getTime()
          : ''
      this.imageName = this.localEmergency.fileURL ? this.localEmergency.fileURL.replace(/^.*[\\/]/, '') : ''
    }
  }

  private saveEmergency() {
    setTimeout(() => {
      const self = this
      this.validate()
        .then((result: boolean) => {
          if (result) {
            this.isLoading = true
            self.localEmergency.description = this.description
            self.localEmergency.engineerSiteSLA = this.engineerSiteSLA
            self.localEmergency.hideOnCustomerPortals = this.hideOnCustomerPortals
            self.$emit('saveEmergency', self.localEmergency)
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }, 0)
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmEmergency')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private pickFile() {
    const logoError = this.$validator.errors.items.find((a) => a.field === 'logo')
    if (logoError) {
      logoError.msg = ''
    }
    const image = this.$refs.image as any
    image.click()
  }

  private onFilePicked(e: any) {
    const files = e.target.files
    const self = this
    if (files[0] !== undefined) {
      self.imageName = files[0].name
      if (self.imageName.lastIndexOf('.') <= 0) {
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        self.imageUrl = fr.result as string
        self.localEmergency.fileURL = self.imageName
        if (self.logoErrorMsg === '') {
          self.localEmergency.file = fr.result as string
        }
      })
    }
  }

  private jobTypeLabel(name: string) {
    return Shared.JobTypeLabel(name, this.jobType)
  }

  private get LogoSize(): number {
    return Store.Instance.state.Environment.LogoSize
  }

  private imageValidationMessage(err: any) {
    if (err && err.collect('logo').length > 0) {
      const message: string = err.collect('logo')[0]
      this.logoErrorMsg =
        message === 'The logo field must be an image.' ? 'Only JPEG, JPG, PNG, SVG, GIF files are allowed.' : message
      this.removeImage()
      return true
    }
  }

  private removeImage() {
    this.imageName = ''
    this.imageUrl = ''
    const image = this.$refs.image as any
    image.value = null
    this.localEmergency.fileURL = ''
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }
}
</script>

<style scoped>
/*Preview logo*/
.show-up-logo {
  position: relative;
  width: 180px;
  height: 70px;
  display: inline-flex;
  padding: 5px;
  border: 1px solid #d6d6d6;
}

.show-up-logo img {
  width: 100%;
  height: 60px;
  margin: auto;
  object-fit: contain;
}

.show-up-logo button {
  position: absolute;
  top: -15px;
  right: -15px;
}

.show-up-logo button >>> .v-btn__content {
  background-color: #ececec;
}

.show-up-logo button >>> .v-btn__content i {
  font-size: 17px;
}

.icon-validation .v-text-field__details {
  padding-left: 40px;
}
</style>
