// models the values from environment.json
export interface Environment {
  AudioAttachmentSize: number
  CallCentreApiBaseUrl: string
  WebhooksApiBaseUrl: string
  CETUserMinimumInvoicingSkill: number
  ClientAppUrl: string
  ComplementaryColor: string
  ConnectionStatus: ConnectionStatus
  CustomerRequestedLogDelayId: string
  DateFormat: string
  DateTimeFormat: string
  DocumentAttachmentSize: number
  EmergencyNotCoveredRepudiationId: number
  Environment: string
  FroalaEditorKey: string
  GoogleCaptchaVerificationSiteKey: string
  GoogleMapsJavascriptAPIKey: string
  HideAutoDeploymentAfterXMinutes: number
  IdentityClientId: string
  IdentityResponseType: string
  IdentityScope: string
  IdentityServerBaseUrl: string
  LinkPhoneCallTimeout: number
  LocateFindApi: string
  LocateRetrieveApi: string
  LogoSize: number
  PHPortalsBaseDomain: string
  PrimaryColor: string
  RecordingStatusCallbackUrl: string
  SecondaryColor: string
  StripePublishKey: string
  TimelineCardMinuteLimit: number
  TimelineCardHourLimit: number
  TimelineCardDayLimit: number
  TOBAFileUrl: string
  TrialPitDrawingWebsite: string
  TwilioBusyActivitySid: string
  TwilioDeploymentActivitySid: string
  TwilioIdleActivitySid: string
  TwilioOfflineActivitySid: string
  TwilioWrapUpActivitySid: string
}

export enum ConnectionStatus {
  Online,
  Offline,
}
