<template>
  <div>
    <v-card v-if="localQA" class="mb-3 access-note" :elevation="embedded ? '0' : undefined">
      <v-card-title class="pb-0">
        <v-layout>
          <v-flex xs11>
            <h3>{{ localQA.questionText }}</h3>
          </v-flex>
          <v-flex xs1 text-xs-right>
            <v-icon v-if="syncing" class="processing-icon" medium>sync</v-icon>
            <v-icon v-else-if="localQA.answer !== null" medium dark color="success">check_circle</v-icon>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-container class="pt-0">
        <v-textarea
          v-model="localQA.answer"
          label="Details"
          name="accessNote"
          @focus="editStarted"
          @blur="editComplete()"
        ></v-textarea>
      </v-container>
      <v-progress-linear
        v-show="saving"
        color="primary"
        height="3"
        value="15"
        :indeterminate="true"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import JobController from '@/api/jobController'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import AddAccessNoteAnswerModel from '@/models/requests/AddAccessNoteAnswerModel'
import eventBus from '@/common/bus'

@Component
export default class AccessNote extends Vue {
  @Prop() private accessNoteQuestion: AccessNoteModel | null
  @Prop() private embedded: boolean
  private localQA: AccessNoteModel | null = null
  private inEdit = false
  private saving = false
  private syncing = false

  private created() {
    this.setLocalAnswer()
  }

  private get item(): AccessNoteModel {
    if (this.accessNoteQuestion) {
      return this.accessNoteQuestion
    }
    return new AccessNoteModel()
  }

  @Watch('syncing')
  private syncingChanged(): void {
    this.$emit('setSyncing', this.syncing)
  }

  @Watch('item')
  private itemChanged(): void {
    if (!this.inEdit) {
      // user not editing (got new value so stop syncing)
      this.syncing = false
      if (this.item !== this.localQA) {
        // stored answer has been updated, update local version
        this.setLocalAnswer()
      }
    }
  }

  // set local answer as item of AccessNote
  private setLocalAnswer(): void {
    if (this.item) {
      this.localQA = { ...this.item }
    }
  }

  // on answer edit started
  private editStarted(): void {
    this.inEdit = true
    this.syncing = false
  }

  // on answer edit complete
  private editComplete(): void {
    if (!this.localQA || this.localQA.answer === null || JSON.stringify(this.item) === JSON.stringify(this.localQA)) {
      return
    }
    this.inEdit = false

    this.saving = true
    // set local answer to request answer model
    const req = new AddAccessNoteAnswerModel()
    req.id = this.localQA.id
    req.jobId = this.localQA.jobId
    req.answer = this.localQA.answer
    this.SaveAccessNoteAnswer(req)
  }

  // save AccessNote Answer
  private SaveAccessNoteAnswer(req: AddAccessNoteAnswerModel) {
    const self = this
    self.syncing = true
    JobController.SaveAccessNoteAnswer(req)
      .then((res: string) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to save answer')
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        self.saving = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', err, false)
        self.saving = false
      })
  }
}
</script>

<style scoped>
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.access-note >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
