import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import CustomerPortalListItemModel from '@/models/client/CustomerPortalListItemModel'

export default class CustomerPortalsController {
  public static async GetPortalsByClient(clientId: number): Promise<CustomerPortalListItemModel[]> {
    const res = await CallCentreApi.get<CustomerPortalListItemModel[]>(`CustomerPortals/ByClient?clientId=${clientId}`)
    if (res.status === 200) {
      return res.data
    }
    return []
  }
}
