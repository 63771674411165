<template>
  <v-flex :class="dashboardTileView === 'FinancialSummary' ? 'finance-cards' : tileClasses[0]">
    <v-card :class="tileClasses[1] + ' ' + backgroundColor" class="card-small-tile">
      <v-card-title :class="tileClasses[2]" class="card-title-pre">
        {{ title }}
        <div v-if="hasFilter">
          <v-spacer></v-spacer>
          <span class="tooltip-filter">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  :flat="isFilterActive ? false : true"
                  class="ma-0"
                  :class="isFilterActive ? 'white' : ''"
                  @click="onFilterIconClick"
                  v-on="on"
                >
                  <v-icon :class="isFilterActive ? 'secondary--text' : 'white--text'">filter_list</v-icon>
                </v-btn>
              </template>
              <span>Filter by {{ title }}</span>
            </v-tooltip>
          </span>
        </div>
      </v-card-title>
      <v-card-text :class="tileClasses[3]">
        <h2 :class="tileClasses[4]">
          {{ displayValue && showDisplayValueInTitle ? displayValue(value) : value }}
          <v-icon v-if="showArrow && arrowDirection" :color="textColor">
            {{ 'arrow_' + arrowDirection }}
          </v-icon>
        </h2>
        <DashboardChart
          v-if="showChart"
          :chart-data="chartData"
          :chart-type="chartIsLine ? chartTypeEnum[chartTypeEnum.Line] : chartTypeEnum[chartTypeEnum.Bar]"
          :is-top="true"
          :max-value="chartMaxValue"
        />
      </v-card-text>
    </v-card>
    <div v-if="infoValue && infoValue.length > 0" :class="backgroundColor" class="lighten-2 day-counts-section">
      <div class="text-xs-right">
        <span v-for="(item, index) in infoValue" :key="index">
          <span v-if="item.type == 'img'">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  <i class="v-icon">
                    <img v-if="item.path" :src="item.path" :alt="item.alt" />
                  </i>
                  <span class="count">
                    {{ displayValue ? displayValue(item.value) : item.value }}
                  </span>
                </span>
              </template>
              <span v-if="item.alt">{{ item.alt }}</span>
            </v-tooltip>
          </span>
          <span v-else-if="item.type == 'icon'">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  <i class="v-icon">
                    <v-icon color="white">{{ item.path }}</v-icon>
                  </i>
                  <span class="count">{{ item.value }}</span>
                </span>
              </template>
              <span v-if="item.alt">{{ item.alt }}</span>
            </v-tooltip>
          </span>
        </span>
      </div>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DashboardChart from './DashboardChart.vue'
import { ChartType } from '@/common/enums'
import Shared from '@/common/shared'

export interface TopTileProps {
  title: string
  value: number
  displayValue?: (v) => string
  showChart: boolean
  chartData: any[]
  chartMaxValue?: number
  backgroundColor: string
  textColor: string
  showArrow: boolean
  infoValue?: any[]
  hasFilter?: boolean
  isFilterActive?: boolean
  name?: string
  showDisplayValueInTitle?: boolean
}

export type DashboardTileView =
  | 'Twilio'
  | 'MangerSupervisorLeft'
  | 'MangerSupervisorRight'
  | 'ComplaintTracker'
  | 'JobDashboard'
  | 'IncidentManager'
  | 'ThirdPartyDashboard'
  | 'DynamicDashboardSmallTile'
  | 'DynamicDashboardMediumTile'
  | 'DynamicDashboardBigTile'
  | 'FinancialSummary'

@Component({
  name: 'DashboardTopTile',
  components: {
    DashboardChart,
  },
})
export default class DashboardTopTile extends Vue {
  @Prop() private title: string
  @Prop() private value: number
  @Prop() private displayValue: (v: number) => string
  @Prop() private showArrow: boolean
  @Prop() private showChart: boolean
  @Prop() private chartData: any[]
  @Prop() private chartMaxValue: number
  @Prop() private backgroundColor: string
  @Prop() private textColor: string
  @Prop() private infoValue: any[]
  @Prop() private dashboardTileView: DashboardTileView
  @Prop() private hasFilter: boolean
  @Prop() private isFilterActive: boolean
  @Prop({ default: true }) private showDisplayValueInTitle: boolean
  private chartIsLine: boolean
  private tileClasses: string[] = []
  private chartTypeEnum = ChartType

  private get arrowDirection() {
    const values = 'values'
    const chartDataValues = Object[values](this.chartData)
    const chartDataKeys = Object.keys(this.chartData)
    const comparingValue: number =
      chartDataKeys && chartDataKeys.filter((c) => /^\d+$/.test(c)).length > 0
        ? this.value - this.chartData[this.chartData.length - 1]
        : chartDataValues[chartDataValues.length - 1] - chartDataValues[chartDataValues.length - 2]
    if (this.chartData && chartDataValues.length) {
      if (comparingValue > 0) {
        return 'upward'
      } else if (comparingValue < 0) {
        return 'downward'
      }
    }
    return undefined
  }

  @Watch('dashboardTileView')
  private setDynamicTileValuesOnRebind() {
    if (this.dashboardTileView) {
      switch (this.dashboardTileView) {
        case 'DynamicDashboardSmallTile':
          this.tileClasses = ['', 'elevation-0 white--text pa-0 fill-height', 'px-2 pt-1 pb-1', 'pa-2', 'big-size']
          this.chartIsLine = false
          break
        case 'DynamicDashboardMediumTile':
          this.tileClasses = ['dashboardtop-tile py-0 graph-tile', 'elevation-0 pa-0 white--text', 'pa-2 pb-2', 'pa-0']
          this.chartIsLine = true
          break
      }
    }
  }

  private created() {
    if (this.dashboardTileView) {
      switch (this.dashboardTileView) {
        case 'Twilio':
          this.tileClasses = [
            'xs12 sm6 md3 tilecard-col',
            'elevation-0 pa-2 white--text card-small-tile',
            'pa-0 pb-2',
            'pa-1',
          ] // tileClasses indicates classes used in toptile conpoenent for fex, card, card-tile and card-text respectively
          this.chartIsLine = false
          break
        case 'MangerSupervisorLeft':
          this.tileClasses = ['xs12 md6 py-0', 'elevation-0 white--text pa-2', 'pa-0 pb-2', 'pa-2 text-xs-right']
          this.chartIsLine = false
          break
        case 'MangerSupervisorRight':
          this.tileClasses = ['xs12 md6 py-1', 'elevation-0 pa-2 white--text card-small-tile', 'pa-0 pb-2', 'pa-1']
          this.chartIsLine = false
          break
        case 'ComplaintTracker':
          this.tileClasses = [
            'xs12 md6 lg3 dashboardtop-tile py-0',
            'elevation-0 pa-0 white--text',
            'pa-2 pb-1',
            'pa-0',
          ]
          this.chartIsLine = true
          break
        case 'JobDashboard':
          this.tileClasses = [
            'xs12 md6 dashboardtop-tile py-0 mb-1',
            'elevation-0 white--text pa-0 ',
            'px-2 pt-1 pb-0',
            'pa-0',
          ]
          this.chartIsLine = true
          break
        case 'IncidentManager':
          this.tileClasses = ['xs12 sm6 md3 tilecard-col', 'elevation-0 pa-2 white--text', 'pa-0 pb-2', 'pa-1']
          this.chartIsLine = false
          break
        case 'DynamicDashboardSmallTile':
          this.tileClasses = ['', 'elevation-0 white--text pa-0 fill-height', 'px-2 pt-1 pb-1', 'pa-2', 'big-size']
          this.chartIsLine = false
          break
        case 'ThirdPartyDashboard':
          this.tileClasses = ['xs12 sm6 md3 tilecard-col', 'elevation-0 pa-2 white--text', 'pa-0 pb-2', 'pa-1']
          this.chartIsLine = false
          break
        case 'DynamicDashboardMediumTile':
          this.tileClasses = ['dashboardtop-tile py-0 graph-tile', 'elevation-0 pa-0 white--text', 'pa-2 pb-2', 'pa-0']
          this.chartIsLine = true
          break
        case 'FinancialSummary':
          this.tileClasses = ['', 'elevation-0 pa-2 white--text', 'pa-0 pb-1', 'pa-1']
          this.chartIsLine = false
      }
    }
  }

  private onFilterIconClick() {
    this.$emit('onTopTileFilter', this.backgroundColor)
  }
}
</script>

<style scoped>
.dashboardtop-tile >>> .v-card__text h2 {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 38px;
}
.dashboardtop-tile >>> .v-card__title {
  width: calc(100% - 115px);
}
.dashboardtop-tile >>> .v-card {
  height: 120px !important;
  overflow: hidden;
}
.dashboardtop-tile >>> .chart-line {
  margin-top: 5px;
}
@media only screen and (max-width: 1200px) {
  .dashboardtop-tile >>> .v-card__text h2 {
    font-size: 30px;
  }
  .dashboardtop-tile .request-info >>> .v-card {
    height: 100px !important;
  }
  .dashboardtop-tile .request-info >>> .v-card .v-card__title {
    width: calc(100% - 88px);
  }
}
.dashboardtop-tile >>> .chart-line .chartjs-render-monitor {
  width: 100% !important;
}
.card-small-tile.v-card {
  min-height: 75px;
}
.card-title-pre.v-card__title {
  white-space: pre;
}
.day-counts-section {
  padding: 15px 10px 8px;
}
.day-counts-section > div > span {
  display: inline-block;
  padding: 0px 10px;
}
.day-counts-section > div > span:last-child {
  padding-right: 0px;
}
.day-counts-section .count {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  padding-left: 5px;
}
.day-counts-section .v-icon img {
  height: 19px;
}
.big-size {
  font-size: 38px;
}
.card-small-tile >>> .v-icon {
  vertical-align: middle;
  height: 12px;
}
.tooltip-filter {
  position: absolute;
  top: 3px;
  right: 3px;
}
.finance-cards {
  width: 20%;
  position: relative;
}
.finance-cards .card-small-tile {
  height: 100%;
  padding-bottom: 58px !important;
}
.finance-cards >>> .card-small-tile + .day-counts-section {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
}
.tilecard-col {
  max-width: 20% !important;
  flex-basis: 20% !important;
}
@media only screen and (max-width: 1440px) {
  .finance-cards >>> .day-counts-section .count {
    font-size: 18px;
  }
  .finance-cards >>> .day-counts-section .v-icon {
    font-size: 20px !important;
  }
  .tilecard-col {
    max-width: 33.33% !important;
    flex-basis: 33.33% !important;
  }
}
@media only screen and (max-width: 1300px) {
  .finance-cards >>> .day-counts-section .count {
    font-size: 16px;
  }
  .finance-cards >>> .day-counts-section .v-icon {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .m-supvr-rcol .day-counts-section > div > span {
    padding: 0px 6px;
  }
  .finance-cards {
    width: 50%;
    max-width: 50%;
  }
}
/* @media only screen and (max-width: 959px) {
 .card-small-tile.v-card{
   margin-bottom: 10px;
 }
} */
</style>
