<template>
  <div class="clientrate elevation-0">
    <v-layout wrap>
      <v-flex xs12>
        <v-card class="elevation-1">
          <v-card-title v-if="!showRateTemplateInDialog">
            <h3>Client Rate</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <v-container fluid grid-list-lg pa-0>
              <v-layout wrap class="align-center">
                <v-flex xs7>
                  <div class="newindicator-chip search-field">
                    <v-autocomplete
                      v-if="!isAccessToCreateRatePackage"
                      v-model="selectedRatePackage"
                      v-validate="'required'"
                      label="Search package"
                      return-object
                      :items="getClientRatePackageList"
                      item-text="packageName"
                      item-value="id"
                      data-vv-scope="frmClientRate"
                      data-vv-name="Search package"
                      :error-messages="errors.collect('Search package')"
                      class="selectedRatePackage"
                      @change="onClientRatePackageChange($event)"
                    ></v-autocomplete>
                    <v-combobox
                      v-if="isAccessToCreateRatePackage"
                      v-model="selectedRatePackage"
                      v-validate="'required'"
                      label="Search package"
                      return-object
                      :items="getClientRatePackageList"
                      item-text="packageName"
                      item-value="id"
                      data-vv-scope="frmClientRate"
                      data-vv-name="Search package"
                      :error-messages="errors.collect('Search package')"
                      :data-vv-delay="validationDelay"
                      class="selectedRatePackage"
                      @change="onClientRatePackageChange($event)"
                    ></v-combobox>
                    <v-chip color="secondary" class="white--text" small disabled :value="isNewRatePackage">New</v-chip>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout wrap mt-0>
                <v-flex xs12>
                  <v-data-table
                    :headers="headers"
                    :items="clientRatePackageModel.clientRateUSList"
                    item-key="subCode"
                    hide-actions
                    class="elevation-1 client-rate-table"
                    expand
                  >
                    <template slot="headers" slot-scope="props">
                      <tr>
                        <th
                          v-for="header in props.headers"
                          :key="header.text"
                          :class="header.class"
                          :align="header.align"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </template>
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td>
                          <v-text-field
                            v-model="props.item.subCode"
                            v-validate="'required'"
                            maxlength="10"
                            :readonly="isUserRoleClientManager"
                            data-vv-scope="frmClientRate"
                            :data-vv-name="'Sub Code' + props.index"
                            :name="'Sub Code' + props.index"
                            :error-messages="validationMessage('Sub Code' + props.index)"
                            required
                            class="required"
                            @keypress="alphanumericValidation($event)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-combobox
                            v-model="props.item.subCategory"
                            v-validate="'required'"
                            maxlength="500"
                            :items="getSubCategories"
                            :readonly="isUserRoleClientManager"
                            data-vv-scope="frmClientRate"
                            :data-vv-name="'Sub Category' + props.index"
                            :name="'Sub Category' + props.index"
                            :error-messages="validationMessage('Sub Category' + props.index)"
                            required
                            class="required sub-category"
                            @change="checkIsDescriptionUnique($event, props.item.description, props.index)"
                          ></v-combobox>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="props.item.description"
                            maxlength="500"
                            :readonly="isUserRoleClientManager"
                            name="description"
                            @input="checkIsDescriptionUnique(props.item.subCategory, $event, props.index)"
                          ></v-text-field>
                          <div
                            v-if="validateDescription(errorMessage, props.index)"
                            class="input-group__details descrition-message"
                          >
                            <div class="input-group__messages input-group__error input-group--error error--text">
                              <span>
                                {{ validateDescription(errorMessage, props.index) }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <v-text-field
                            v-model="props.item.unit"
                            maxlength="30"
                            :readonly="isUserRoleClientManager"
                            name="unit"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="props.item.unitPrice"
                            maxlength="8"
                            class="prepend-icon-image"
                            prepend-icon="close"
                            :readonly="isUserRoleClientManager"
                            name="unitPrice"
                            @keypress="numberKeyValidation($event)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="props.item.vatRate"
                            v-validate="{ rules: { max_value: 100 } }"
                            maxlength="5"
                            class="prepend-icon-image percent pt-0 mt-0"
                            append-icon="close"
                            :readonly="isUserRoleClientManager"
                            :name="'VAT' + props.index"
                            data-vv-scope="frmClientRate"
                            :data-vv-name="'VAT' + props.index"
                            :error-messages="validationMessage('VAT' + props.index, true)"
                            @keypress="numberKeyValidation($event)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="props.item.comment"
                            maxlength="500"
                            :readonly="isUserRoleClientManager"
                            name="comment"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-tooltip v-if="!isUserRoleClientManager" bottom>
                            <template #activator="{ on }">
                              <v-btn
                                v-if="clientRatePackageModel.clientRateUSList.length > 1"
                                flat
                                icon
                                class="mr-0 btn-remove"
                                color="primary"
                                v-on="on"
                                @click.prevent="removeAdditionalRate(props.item)"
                              >
                                <v-icon dark color="primary">close</v-icon>
                              </v-btn>
                            </template>
                            <span>Remove Row</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div class="text-xs-right mt-2">
                    <v-tooltip v-if="!isUserRoleClientManager" bottom>
                      <template #activator="{ on }">
                        <v-btn class="mr-0 btn-add" icon color="primary" small v-on="on" @click="addAdditionalRate">
                          <v-icon dark class="white--text" small>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Row</span>
                    </v-tooltip>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isUserRoleClientManager"
              :disabled="isLoading"
              :loading="isLoading"
              color="primary"
              class="btn-saveOrAssignRatePackage"
              @click="SaveOrAssignRatePackage"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
          {{ saveSnackbarText }}
          <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import ClientRatePackageUSModel from '@/models/client/ClientRatePackageUSModel'
import RateController from '@/api/rateController'
import Shared from '@/common/shared'
import Store from '@/store'
import { UserRole } from '@/models/user/UserModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import ClientTradeRateModel from '@/models/client/ClientTradeRateModel'
import JobController from '@/api/jobController'
import ClientFixedFeeRateModel from '@/models/client/ClientFixedFeeRateModel'
import ClientRateUSModel from '../models/client/ClientRateUSModel'
import eventBus from '@/common/bus'

@Component
export default class ClientRateUS extends Vue {
  @Prop() private policyId: number
  @Prop() private policyName: string
  @Prop() private ratePackageId: string
  @Prop() private allClientRatePackageList: ClientRatePackageUSModel[]
  @Prop() private showRateTemplateInDialog: boolean
  @Prop() private selectedRatePackageInPolicy: ClientRatePackageUSModel | string | null

  private clientRatePackageList: ClientRatePackageUSModel[] = []
  private clientRatePackageModel: ClientRatePackageUSModel = new ClientRatePackageUSModel()
  private selectedRatePackage: ClientRatePackageUSModel | string | null = null
  private oldClientRatePackageModel: ClientRatePackageUSModel = new ClientRatePackageUSModel()
  private previouslySelectedRatePackage: ClientRatePackageUSModel = new ClientRatePackageUSModel()

  private isLoading = false
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = 'Rate saved successfully.'
  private isNewRatePackage = false
  private headers: any = []
  private validationDelay = Shared.validationDelay
  private errorMessage = ''
  private currentIndex = -1

  private created() {
    this.headers = [
      {
        text: 'Sub code',
        value: 'subCode',
        sortable: false,
        align: 'left',
        class: 'col-subcode',
      },
      {
        text: 'Sub Category',
        value: 'subCategory',
        sortable: false,
        align: 'left',
        class: 'col-subcategory',
      },
      {
        text: 'Description',
        value: 'description',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Unit',
        value: 'unit',
        align: 'left',
        sortable: false,
        class: 'col-unit',
      },
      {
        text: 'Unit Price',
        value: 'unitPrice',
        align: 'left',
        class: 'col-unit-price',
        sortable: false,
      },
      {
        text: 'Vat rate',
        value: 'vatRate',
        sortable: false,
        align: 'left',
        class: 'col-vat-rate',
      },
      { text: 'Comments', value: 'comment', align: 'left', sortable: false },
      { text: '', value: '', sortable: false },
    ]
  }

  private mounted() {
    this.getClientRatePackages()
    this.onRatePackageIdChanged(this.ratePackageId)
    this.selectedRatePackageInPolicyChange()
  }

  private get getSubCategories(): string[] {
    return this.clientRatePackageModel && this.clientRatePackageModel.clientRateUSList.length > 0
      ? this.clientRatePackageModel.clientRateUSList
          .filter((e) => e.subCategory !== null && e.subCategory !== undefined && e.subCategory.trim() !== '')
          .map((c) => c.subCategory)
      : []
  }

  private get getClientRatePackageList(): ClientRatePackageUSModel[] {
    return this.clientRatePackageList
  }

  // return true, if going to assign rate package
  // return false, if create/update rate template
  private get isAccessToCreateRatePackage(): boolean {
    return this.isUserRoleClientManager ? false : this.policyId ? false : true
  }

  private get isUserRoleClientManager(): boolean {
    return (
      (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager') ||
      Store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail'
    )
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  // add additional rate
  private addAdditionalRate(): void {
    const cr: ClientRateUSModel = new ClientRateUSModel()
    this.clientRatePackageModel.clientRateUSList.push(cr)
  }

  // remove additional rate
  private removeAdditionalRate(clientRate: ClientRateUSModel): void {
    const index = this.clientRatePackageModel.clientRateUSList.indexOf(clientRate)
    this.clientRatePackageModel.clientRateUSList.splice(index, 1)
  }

  private validationMessage(label: string, forVat = false) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (forVat) {
      return message ? (message = 'The ' + errorMessage[0] + ' must be less than or equal to 100.') : message
    }
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private validateDescription(fileErrorMessage: string, index = -1) {
    this.errorMessage = fileErrorMessage
    return this.currentIndex === index ? this.errorMessage : ''
  }

  private checkIsDescriptionUnique(subCategory: string, description: string, itemIndex: number) {
    if (subCategory && description) {
      this.currentIndex = itemIndex
      const filteredList = this.clientRatePackageModel.clientRateUSList.filter(
        (c, index) => c.subCategory === subCategory && c.description === description && index !== itemIndex
      )
      if (filteredList.length > 0) {
        this.validateDescription('The Description value must be unique.', itemIndex)
      } else {
        this.validateDescription('')
      }
    }
  }

  // client rate package dropdown change event
  private onClientRatePackageChange(event: any) {
    this.$validator.errors.items = []
    this.validateDescription('')
    if (event && event.id) {
      // select rate package from list
      this.isNewRatePackage = false

      this.clientRatePackageModel.id = event.id
      this.clientRatePackageModel.packageName = event.packageName
      this.clientRatePackageModel.clientRateUSList = event.clientRateUSList
      this.clientRatePackageModel.forCustomPackagePolicyId = event.forCustomPackagePolicyId
      this.oldClientRatePackageModel = JSON.parse(JSON.stringify(this.clientRatePackageModel))
    } else {
      // creating new rate package
      const packageName = event
      if (this.selectedRatePackage && typeof this.selectedRatePackage === 'object') {
        this.clientRatePackageModel = new ClientRatePackageUSModel()
        this.clientRatePackageModel.clientRateUSList = this.selectedRatePackage.clientRateUSList
      }
      this.clientRatePackageModel.packageName = packageName
      this.isNewRatePackage = true
    }
  }

  private get getAllClientRatePackageList(): ClientRatePackageUSModel[] {
    return this.allClientRatePackageList
  }

  @Watch('allClientRatePackageList')
  private allClientRatePackageListChange() {
    this.getClientRatePackages()
  }

  @Watch('selectedRatePackageInPolicy')
  private selectedRatePackageInPolicyChange() {
    this.getClientRatePackages()
    setTimeout(() => {
      this.selectedRatePackage = this.selectedRatePackageInPolicy
    }, 0)
    if (this.selectedRatePackageInPolicy && typeof this.selectedRatePackage === 'object') {
      this.clientRatePackageModel = JSON.parse(JSON.stringify(this.selectedRatePackageInPolicy))
      this.oldClientRatePackageModel = JSON.parse(JSON.stringify(this.clientRatePackageModel))
      this.previouslySelectedRatePackage = JSON.parse(JSON.stringify(this.selectedRatePackageInPolicy))
    }
  }

  // filter rate packages by ratePackageId from all rate packages
  @Watch('ratePackageId')
  private onRatePackageIdChanged(ratePackageId: string) {
    const clientRatePackageList: ClientRatePackageUSModel[] = this.getAllClientRatePackageList.filter(
      (a: ClientRatePackageUSModel) =>
        (a.id === this.ratePackageId && a.forCustomPackagePolicyId === this.policyId) ||
        a.forCustomPackagePolicyId === 0
    )
    this.clientRatePackageList = clientRatePackageList
    if (ratePackageId && this.clientRatePackageList) {
      const clientRatePackage: ClientRatePackageUSModel | undefined = this.clientRatePackageList.find(
        (e: ClientRatePackageUSModel) => e.id === ratePackageId
      )
      if (clientRatePackage) {
        this.selectedRatePackage = clientRatePackage
        this.clientRatePackageModel = JSON.parse(JSON.stringify(clientRatePackage))
        this.oldClientRatePackageModel = JSON.parse(JSON.stringify(clientRatePackage))
      }
    } else {
      this.selectedRatePackage = null
      this.clientRatePackageModel = new ClientRatePackageUSModel()
      this.oldClientRatePackageModel = new ClientRatePackageUSModel()
    }
  }

  // filter rate packages by client from all client rate packages
  private getClientRatePackages() {
    const self = this
    if (self.getAllClientRatePackageList) {
      const clientRatePackageList: ClientRatePackageUSModel[] = self.getAllClientRatePackageList.filter(
        (a: ClientRatePackageUSModel) =>
          a.forCustomPackagePolicyId === this.policyId || a.forCustomPackagePolicyId === 0
      )
      self.clientRatePackageList = JSON.parse(JSON.stringify(clientRatePackageList))
    }
  }

  // save rate template or assign rate package to client
  private SaveOrAssignRatePackage() {
    this.SaveRatePackage()
  }

  // this method return true if any changes done in rates, otherwise return false
  private getChanges(): boolean {
    if (JSON.stringify(this.oldClientRatePackageModel) !== JSON.stringify(this.clientRatePackageModel)) {
      return true
    }
    return false
  }

  // this method will call when create or update rate template
  private SaveRatePackage() {
    this.getClientRatePackages()
    const self = this
    setTimeout(() => {
      this.validate()
        .then((result: boolean) => {
          if (result && self.clientRatePackageModel) {
            self.isLoading = true
            let isCreatingCustomRate = false
            if (this.policyId) {
              // assign rate package to policy

              // if got any changes in package rate then create custom package rate for that client
              if (self.clientRatePackageModel && self.getChanges()) {
                const ratePackage: ClientRatePackageUSModel | undefined = self.getClientRatePackageList.find(
                  (p: ClientRatePackageUSModel) => p.forCustomPackagePolicyId !== 0
                )
                if (ratePackage && ratePackage.id) {
                  // modifying existing custom rate package
                  self.clientRatePackageModel.id = ratePackage.id
                  self.clientRatePackageModel.forCustomPackagePolicyId = ratePackage.forCustomPackagePolicyId
                } else {
                  // creating new custom rate package
                  self.clientRatePackageModel.id = ''
                  self.clientRatePackageModel.forCustomPackagePolicyId = self.policyId
                  isCreatingCustomRate = true
                  self.getAllClientRatePackageList.push(self.clientRatePackageModel)
                }

                // custom package rate name will be client name plus custome rate
                self.clientRatePackageModel.packageName = self.policyName + ' custom rate'
                self.selectedRatePackage = self.clientRatePackageModel
              }
            } else {
              // create/update rate template
              self.clientRatePackageModel.forCustomPackagePolicyId = 0
            }
            RateController.SaveClientRatePackageUS(self.clientRatePackageModel)
              .then((res: ClientRatePackageUSModel | null) => {
                self.isLoading = false
                if (res) {
                  if (this.policyId) {
                    if (isCreatingCustomRate) {
                      self.clientRatePackageModel = res
                      self.clientRatePackageList.push(self.clientRatePackageModel)
                      const index = this.getAllClientRatePackageList.findIndex(
                        (c) =>
                          c.packageName === res.packageName &&
                          c.forCustomPackagePolicyId === res.forCustomPackagePolicyId
                      )
                      if (index !== -1) {
                        this.getAllClientRatePackageList[index] = res
                      }
                    } else {
                      const clientRatePackage: ClientRatePackageUSModel =
                        this.getAllClientRatePackageList.find(
                          (e: ClientRatePackageUSModel) => e.id === self.clientRatePackageModel.id
                        ) || new ClientRatePackageUSModel()
                      Object.assign(clientRatePackage, self.clientRatePackageModel)
                    }
                    const oldClientRatePackageModelObj: ClientRatePackageUSModel | undefined =
                      this.getAllClientRatePackageList.find(
                        (e: ClientRatePackageUSModel) =>
                          e.id === this.previouslySelectedRatePackage.id && e.forCustomPackagePolicyId === 0
                      )
                    if (oldClientRatePackageModelObj) {
                      Object.assign(oldClientRatePackageModelObj, this.previouslySelectedRatePackage)
                    }
                  } else {
                    if (self.isNewRatePackage) {
                      self.clientRatePackageModel = res
                      self.clientRatePackageList.push(self.clientRatePackageModel)
                      self.getAllClientRatePackageList.push(self.clientRatePackageModel)
                      self.isNewRatePackage = false
                    } else {
                      const clientRatePackage: ClientRatePackageUSModel =
                        this.getAllClientRatePackageList.find(
                          (e: ClientRatePackageUSModel) => e.id === self.clientRatePackageModel.id
                        ) || new ClientRatePackageUSModel()
                      Object.assign(clientRatePackage, self.clientRatePackageModel)
                    }
                    this.getClientRatePackages()
                  }
                  this.$emit('update:allClientRatePackageList', self.getAllClientRatePackageList)
                  if (this.showRateTemplateInDialog) {
                    this.$emit('saveRatePackage', this.clientRatePackageModel)
                  } else {
                    self.saveSnackbar = true
                  }
                }
              })
              .catch((err: any) => {
                eventBus.$emit('errorHandler', 'Error submitting client rate package, please try again', true)
                self.isLoading = false
              })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }, 0)
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmClientRate')

    // check if description is unique or not for each subcategory
    for (let index = 0; index < this.clientRatePackageModel.clientRateUSList.length; index++) {
      const clientRate = this.clientRatePackageModel.clientRateUSList[index]
      this.checkIsDescriptionUnique(clientRate.subCategory, clientRate.description, index)
      if (this.errorMessage !== '') {
        break
      }
    }

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result && this.errorMessage === ''
  }

  private alphanumericValidation(event: any) {
    Shared.alphanumericValidation(event)
  }
}
</script>

<style scoped>
.client-rate-table >>> .rm-row-cul {
  min-width: 10px;
}
.client-rate-table :-ms-input-placeholder {
  font-size: 14px;
}
.client-rate-table :-moz-placeholder {
  font-size: 14px;
}
.client-rate-table ::-webkit-input-placeholder {
  font-size: 14px;
}
.client-rate-table .input-group--select .input-group__selections__comma,
.client-rate-table >>> .input-group--text-field input,
.client-rate-table >>> .input-group--text-field textarea {
  font-size: 14px;
}
.client-rate-table >>> table.v-table tbody td:first-child,
.client-rate-table >>> table.v-table tbody td:not(:first-child),
.client-rate-table >>> table.v-table tbody th:first-child,
.client-rate-table >>> table.v-table tbody th:not(:first-child),
.client-rate-table >>> table.v-table thead td:first-child,
.client-rate-table >>> table.v-table thead td:not(:first-child),
.client-rate-table >>> table.v-table thead th:first-child,
.client-rate-table >>> table.v-table thead th:not(:first-child) {
  padding: 0px 12px;
}
.client-rate-table >>> .input-group--select .input-group__selections__comma {
  font-size: 13px;
}
.clientrate >>> input[type='number']::-webkit-inner-spin-button,
.clientrate >>> input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.client-rate-table >>> .input-group {
  padding-top: 5px;
}
.client-rate-table >>> .v-input--checkbox {
  position: relative;
  top: 8px;
}
.ratetabs >>> .v-tabs__bar .v-tabs__div > .v-tabs__item--active {
  background-color: #fff !important;
  color: #e91e63 !important;
}
.newindicator-chip >>> .input-group__input .input-group__selections__comma {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.newindicator-chip >>> .input-group__selections {
  padding-right: 0px;
}
.clientrate .prepend-icon-image >>> i.v-icon {
  position: relative;
  color: #fff !important;
  height: 0px !important;
}
.client-rate-table >>> .v-table tr:hover .prepend-icon-image i.v-icon {
  color: #eee !important;
}
.clientrate .prepend-icon-image >>> i.v-icon::after {
  content: '';
  background-image: url('/img/pound-sterling.svg');
  background-size: cover;
  position: absolute;
  left: 5px;
  top: -7px;
  height: 16px;
  width: 16px;
}
.clientrate .prepend-icon-image.percent >>> i.v-icon::after {
  background-image: url('/img/percent.svg') !important;
}
.fixed-price {
  position: relative;
}
.fixed-price >>> .v-input--switch {
  position: absolute;
  right: 0;
  top: 2px;
  width: auto;
}
.fixed-price >>> .v-input--switch .v-input__slot {
  margin-bottom: 0;
}
.fixed-price >>> .v-input--switch .v-input__slot .v-input--selection-controls__input {
  margin: 0;
}
.fixed-price >>> input {
  padding-right: 50px;
}
.client-rate-table >>> table.v-table thead th:not(:first-child) {
  min-width: 150px;
}
.client-rate-table >>> table.v-table thead th:last-child {
  min-width: 50px !important;
}
.client-rate-table >>> table tbody .v-input {
  padding: 5px 0;
  margin: 0;
}
.client-rate-table >>> table tbody .v-input--checkbox .v-input__control {
  margin: 0 auto;
}
.fixed-fee >>> .v-input--switch.v-input--selection-controls {
  justify-content: flex-end;
}
.right-fee-col >>> .v-input--switch .v-input__slot {
  margin-bottom: 0 !important;
}
.right-fee-col >>> .v-input--switch {
  margin-bottom: 0 !important;
  justify-content: center;
}
.fixed-fee >>> .v-input--switch .v-input__slot {
  margin: 14px 0 8px 0 !important;
}
.fee-switch >>> .v-input--switch .v-input--selection-controls__input {
  order: 2;
  margin: 0 0 0 15px !important;
}
.fee-switch >>> .v-input__slot {
  margin-bottom: 0 !important;
}
.height-100 {
  height: 100%;
}
.fees-card .fixed-price >>> label {
  padding-right: 30px;
}
.search-field {
  max-width: 50%;
}
.fees-card {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.client-rate-table >>> .col-vat-rate {
  min-width: 90px !important;
  width: 90px !important;
}
.client-rate-table >>> .col-unit-price {
  min-width: 100px !important;
  width: 130px !important;
}
.client-rate-table >>> .col-unit {
  min-width: 130px !important;
  width: 130px !important;
}
.client-rate-table >>> .col-subcode {
  min-width: 120px !important;
  width: 120px !important;
}
.client-rate-table >>> .col-subcategory {
  min-width: 170px !important;
  width: 170px !important;
}
.descrition-message {
  margin-top: -15px;
  padding-bottom: 5px;
}
</style>
