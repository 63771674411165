<template>
  <v-menu
    v-model="menu"
    bottom
    :close-on-content-click="false"
    :nudge-width="150"
    offset-x
    :disabled="isDisabled"
    :class="[isDisabled ? 'menu-disabled' : '', isPackageSelection ? 'menu-product' : '']"
  >
    <template #activator="{ on }">
      <a
        href="javascript:void(0)"
        class="subheading priceLabel"
        :class="isPriceUpdated ? 'red--text' : ''"
        :disabled="isDisabled"
        v-on="on"
        @click="editProductPrice"
      >
        <b>{{ priceLabel }}</b>
      </a>
    </template>
    <v-card>
      <v-text-field
        v-model.number="value"
        class="pa-2"
        label="Price"
        single-line
        maxlength="9"
        name="productPrice"
        @keypress="numberKeyValidation($event)"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat="flat" @click.stop="menu = false">Close</v-btn>
        <v-btn
          color="primary"
          class="save-btn"
          flat
          :disabled="value < 0 || value === productPrice"
          @click="saveProductPrice"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'

@Component
export default class UpdateProductPriceDialog extends Vue {
  @Prop() private productPrice: number
  @Prop() private priceLabel: string
  @Prop() private isPriceUpdated: boolean
  @Prop({ default: false }) private isDisabled: boolean
  @Prop({ default: false }) private isPackageSelection: boolean

  private value = 0
  private menu = false

  private mounted() {
    this.value = this.productPrice
  }

  @Watch('productPrice')
  private onPriceChange() {
    this.value = this.productPrice
  }

  private saveProductPrice() {
    this.menu = false
    this.$emit('productPriceUpdated', this.value)
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private editProductPrice() {
    this.value = this.productPrice
    this.$emit('editProductPrice')
  }
}
</script>

<style scoped>
.menu-disabled {
  opacity: 0.5;
}
.menu-product {
  margin-top: 25px !important;
  margin-left: 5px;
}
</style>
