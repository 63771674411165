import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import EngineerModel from '@/models/contractor/EngineerModel'
import UpdateContractorAppointedDetail from '@/models/requests/UpdateContractorAppointedDetail'

export default class EngineerController {
  public static async SaveEngineer(engineer: EngineerModel | null): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('Engineer/SaveEngineer', engineer)
    return res.data ? res.data : ''
  }

  public static async GetEngineerDetails(id: string): Promise<EngineerModel | null> {
    const res = await CallCentreApi.get<EngineerModel>('Engineer/GetEngineerDetails?engineerId=' + id)
    return res.data
  }

  public static async DeactivateEngineer(id: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete('Engineer/DeactivateEngineer?engineerId=' + id)
    return res.status >= 200 && res.status < 300
  }

  public static async GetContractorEngineers(contractorId: string): Promise<EngineerModel[]> {
    const res = await CallCentreApi.get<EngineerModel[]>('Engineer/GetContractorEngineers?contractorId=' + contractorId)
    return res.data ? res.data : []
  }

  public static async AssignEngineerToJob(
    updateContractorAppointed: UpdateContractorAppointedDetail
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Engineer/AssignEngineerToJob', updateContractorAppointed)
    return res.status >= 200 && res.status < 300
  }

  public static async GetSASTokenForEngineerProfileImage(engineerId: string, fileName: string): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Engineer/GetSASTokenForEngineerProfileImage?engineerId=' + engineerId + '&fileName=' + fileName
    )
    return res.data ? res.data : ''
  }

  public static async get(id: string) {
    try {
      const { status, data } = await CallCentreApi.get<EngineerModel>(`engineers/${id}`)
      return status >= 200 && status < 300 ? data : null
    } catch (error) {
      return null
    }
  }

  public static async create(engineer: EngineerModel) {
    try {
      // For some damn reason this endpoint returns just the engineer id instead of the model
      const { status, data } = await CallCentreApi.post<string>('engineers', engineer)
      return status >= 200 && status < 300 ? data : null
    } catch (error) {
      return null
    }
  }

  public static async update(engineer: EngineerModel) {
    try {
      const { status, data } = await CallCentreApi.patch<EngineerModel>(`engineers/${engineer.id}`, engineer)
      return status >= 200 && status < 300 ? data : null
    } catch (error) {
      return null
    }
  }
}
