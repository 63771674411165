<template>
  <div>
    <v-flex md6>
      <v-select
        v-model="selectedCookieRecord"
        :items="cookieRecords"
        item-text="name"
        return-object
        label="Cookie Selection"
        @change="updateTemplateCookieRecord($event)"
      ></v-select>
    </v-flex>
    <v-flex v-if="showCookieSettings" md6>
      <div v-for="(setting, index) in templateCookieRecord.settings" :key="`${setting.id}${index}`">
        <v-text-field
          v-model="templateCookieRecord.settings[index].value"
          v-validate="'required'"
          :label="retrieveCookieSettingLabel(setting)"
          :data-vv-name="`CookieSetting${index}`"
          :error-messages="generateErrorMessage(`CookieSetting${index}`)"
          :data-vv-scope="validationScope"
        ></v-text-field>
      </div>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator'
import InsurerController from '@/api/InsurerController'
import storeMutations from '@/storeMutations'
import CookieRecord from '@/models/cookies/cookie-record'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import TemplateCookieRecord from '@/models/cookies/template-cookie-record'
import TemplateCookieSettings from '@/models/cookies/template-cookie-settings'

@Component
export default class CookieSelection extends Vue {
  private cookieRecords: CookieRecord[] = []
  private selectedCookieRecord: CookieRecord = new CookieRecord()
  private templateCookieRecord: TemplateCookieRecord | null = null

  @Prop({ default: '' })
  private validationScope: string

  @Prop({ default: null })
  private clientTemplate: ClientTemplateModel

  public async validateAll(): Promise<boolean> {
    return await this.$validator.validateAll(this.validationScope)
  }

  private generateErrorMessage(fieldName: string): string | null {
    return this.$validator.errors.items.filter((x) => x.field === fieldName)[0] !== undefined
      ? 'This field cannot be empty'
      : null
  }

  private retrieveCookieSettingLabel(setting: TemplateCookieSettings): string {
    const cookieSettings = this.selectedCookieRecord.settings.filter((x) => x.id === setting.id)[0]
    return cookieSettings ? cookieSettings.description : setting.id
  }

  private updateTemplateCookieRecord(selectedCookieRecord: CookieRecord) {
    // Checks to see if the cookie option that has been selected, is the same as the one that has ben previously set against template.
    const isActiveCookie: boolean =
      this.templateCookieRecord !== null && selectedCookieRecord.id === this.templateCookieRecord.cookieId

    // Map cookieRecordSettings to templateCookieRecordSettings
    const selectedCookieSettings: TemplateCookieSettings[] = selectedCookieRecord.settings.map((x) => {
      return {
        id: x.id,
        description: x.description,
        value: x.defaultValue,
      }
    })

    // If the selected cookie value is one that has been previously set against this template, show those settings, else show default settings
    const settings: TemplateCookieSettings[] =
      isActiveCookie && this.templateCookieRecord ? this.templateCookieRecord.settings : selectedCookieSettings

    this.templateCookieRecord = new TemplateCookieRecord(selectedCookieRecord.id, settings)
  }

  @Watch('templateCookieRecord', { deep: true })
  private setTemplateCookieRecord() {
    storeMutations.setTemplateCookieRecord(this.templateCookieRecord)
  }

  @Watch('clientTemplate')
  private setInitialCookieRecord() {
    if (this.clientTemplate.templateCookieRecord) {
      this.templateCookieRecord = this.clientTemplate.templateCookieRecord
      this.selectedCookieRecord = this.cookieRecords.filter((x) => x.id === this.templateCookieRecord!.cookieId)[0]
    } else {
      this.setDefaultCookie()
    }
  }

  private get showCookieSettings(): boolean {
    return (
      this.templateCookieRecord !== null &&
      this.templateCookieRecord.cookieId.length > 0 &&
      this.templateCookieRecord.settings.length > 0 &&
      this.selectedCookieRecord &&
      !this.selectedCookieRecord.isDefault
    )
  }

  private setDefaultCookie() {
    this.selectedCookieRecord = this.cookieRecords.filter((x) => x.isDefault)[0]
    this.updateTemplateCookieRecord(this.selectedCookieRecord)
  }

  private async created() {
    this.cookieRecords = await InsurerController.RetrieveCookieRecords()
    this.setDefaultCookie()
  }
}
</script>
