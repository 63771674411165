import { ContactPreference } from '@/models/types'
import moment from 'moment'
import DehydratedJobPackage from '@/models/siteInvestigation/DehydratedJobPackage'

export default class AddSIJob {
  public clientTitle: string
  public clientForename: string
  public clientLastName: string
  public secondaryClientTitle: string | null
  public secondaryClientForename: string | null
  public secondaryClientLastName: string | null
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postCode: string
  public email: string
  public mobilePhone: string
  public contactPreference: ContactPreference
  public contactPreferenceId: number | null
  public policyNumber: string
  public customerRef: string | null
  public policyScheme: string
  public insurer: string
  public insuranceValue: number
  public policyScheduleId: number
  public FNOLCallSid: string
  public nomineeClientTitle: string | null
  public nomineeClientForename: string | null
  public nomineeClientLastName: string | null
  public relation: string | null
  public nomineeMobilePhone: string | null
  public nomineeEmail: string | null
  public nomineeContactPreference: ContactPreference
  public nomineeContactPreferenceId: number | null
  public claimManagementCompany: string
  public claimManagementCompanyId: number | null
  public subsidenceConsultant: string
  public subsidenceConsultantId: string
  public instructionDate: moment.Moment | null
  public jobPackage: DehydratedJobPackage | null
  public isIVSJob: boolean
  public reasonForChoosingCallOption: string
  public nomineeReasonForChoosingCallOption: string

  public constructor() {
    this.clientTitle = ''
    this.clientForename = ''
    this.clientLastName = ''
    this.policyNumber = ''
    this.email = ''
    this.mobilePhone = ''
    this.contactPreference = null
    this.contactPreferenceId = null
    this.FNOLCallSid = ''
    this.nomineeEmail = ''
    this.nomineeMobilePhone = ''
    this.nomineeContactPreference = null
    this.nomineeContactPreferenceId = null
    this.addressLine1 = ''
    this.addressLine2 = ''
    this.addressLine3 = ''
    this.addressLine4 = ''
    this.postCode = ''
    this.claimManagementCompany = ''
    this.claimManagementCompanyId = null
    this.subsidenceConsultant = ''
    this.subsidenceConsultantId = ''
    this.instructionDate = null
    this.jobPackage = null
    this.customerRef = ''
    this.isIVSJob = false
    this.reasonForChoosingCallOption = ''
    this.nomineeReasonForChoosingCallOption = ''
  }
}
