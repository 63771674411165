import store from '@/store'
import { commandNames } from '../commands/command-names'
import CommandHandler from '../interfaces/command-handler'
import router from '@/router'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import StoreMutations from '@/storeMutations'
import Emergency from '@/models/Emergency'
import EmergencyQA from '@/models/EmergencyQA'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import CreateClaimResponse from '@/messaging/models/claim-response/create-claim-response'
import Job from '@/models/Job'

export default class CreateClaimHandler implements CommandHandler {
  public readonly commandName = commandNames.createClaimCommand
  async execute(value: any): Promise<void> {
    const claim = value as unknown as CreateClaimResponse

    await store.Instance.dispatch('heWizardModule/setHeWizardVisible', false)

    if (!claim) {
      await store.Instance.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar('No job passed from wizard, cannot navigate to job')
      )
      return
    }

    // The response from the backend doesnt match the legacy models, so we have to do this weird,
    // mapping
    const job = new Job()
    // Map response to job object
    Object.keys(claim).forEach((key) => {
      // Dont set empty array to null - instead, skip value
      if (Array.isArray(job[key]) && !claim![key]) {
        return
      }
      job[key] = claim![key]
    })

    if (claim.emergencies) {
      job.emergencies = claim.emergencies.map((x) => Object.assign(new Emergency(), x))
    }
    if (claim.emergenciesQAs) {
      job.emergenciesQAs = claim.emergenciesQAs.map((x) => Object.assign(new EmergencyQA(), x))
    }
    if (claim.healthAndSafetyQAs && claim.healthAndSafetyQAs.length) {
      job.healthAndSafetyQAs[0] = Object.assign(new HealthAndSafetyQAModel(), claim.healthAndSafetyQAs[0])
    }

    StoreMutations.addOrReplaceJob(job)

    router.push({
      name: 'job',
      params: {
        jobId: claim.jobId,
      },
    })
  }
}
