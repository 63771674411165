<template>
  <div>
    <div class="mb-2"><h4>Update Customer Information</h4></div>
    <div v-if="item.clientTitle" class="mb-1">
      <label>Title: </label>
      <span>{{ item.clientTitle }}</span>
    </div>
    <div v-if="item.clientForename" class="mb-1">
      <label>First Name: </label>
      <span>{{ item.clientForename }}</span>
    </div>
    <div v-if="item.clientLastName" class="mb-1">
      <label>Last Name: </label>
      <span>{{ item.clientLastName }}</span>
    </div>
    <div v-if="item.addressLine1" class="mb-1">
      <label>Address Line1: </label>
      <span>{{ item.addressLine1 }}</span>
    </div>
    <div v-if="item.addressLine2" class="mb-1">
      <label>Address Line2: </label>
      <span>{{ item.addressLine2 }}</span>
    </div>
    <div v-if="item.addressLine3" class="mb-1">
      <label>Address Line3: </label>
      <span>{{ item.addressLine3 }}</span>
    </div>
    <div v-if="item.addressLine4" class="mb-1">
      <label>Address Line4: </label>
      <span>{{ item.addressLine4 }}</span>
    </div>
    <div v-if="item.postCode" class="mb-1">
      <label>Postcode: </label>
      <span>{{ item.postCode }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateCustomerAddressDetail from '@/models/requests/UpdateCustomerAddressDetail'

@Component
export default class UpdateCustomerAddressDetailLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateCustomerAddressDetail {
    return Object.assign(new UpdateCustomerAddressDetail(), this.requestBody)
  }
}
</script>
