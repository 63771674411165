<template>
  <div>
    <v-dialog v-model="showAbandonReasonDialog" max-width="650" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="btn-closeAbandonReasonDialog" @click.native="$emit('closeAbandonReasonDialog')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="scroll-content-dialog">
          <v-layout wrap>
            <v-flex xs12>
              <v-autocomplete
                v-model="selectedAbandonDetails.type"
                v-validate="'required'"
                label="Abandon Reason"
                maxlength="20"
                :items="abandonReasonList"
                item-value="id"
                item-text="description"
                data-vv-scope="abandonformReference"
                data-vv-name="Abandon Reason"
                class="reasonList"
                :error-messages="errors.collect('Abandon Reason')"
                @change="onChangeAbandonType"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile-content>
                    <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                      {{ data.item.description }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <v-textarea v-model.trim="selectedAbandonDetails.reason" label="Reason" maxlength="2000"></v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat="flat"
            class="btn-closeAbandonReasonDialog"
            @click.native="$emit('closeAbandonReasonDialog')"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            class="mr-0 btn-saveAbandonReason"
            @click="abandonReasonSave()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureDropdown
        ref="configureDropdown"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import store from '@/store'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import { RecordType } from '@/common/enums'
import AddAbandonedSIProductRequest from '@/models/siteInvestigation/requests/AddAbandonedSIProductRequest'
import SiteInvestigationController from '@/api/siteInvestigationController'
import eventBus from '@/common/bus'

@Component({
  components: { ConfigureDropdown },
})
export default class AbandonSiteDataDialog extends Vue {
  @Prop() private recordType: string
  @Prop() private abandonReasonDetails: AddAbandonedSIProductRequest
  private abandonReasonList: MasterRecord[] = []
  private configureDropdownDialog = false
  private configureDropdownType = ''
  private selectedAbandonDetails: any = { type: '', reason: '' }
  private isLoading = false
  private showAbandonReasonDialog = true

  private created() {
    this.getMasterRecords()
  }

  private async getMasterRecords() {
    await MasterRecordController.GetMasterRecords(this.recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.selectedAbandonDetails = {}
        this.addConfigureOption(res)
      }
    })
  }

  private get dialogTitle(): string {
    if (this.recordType === RecordType[RecordType.AbandonTrialPitReason]) {
      return 'Abandon Trial Pit'
    } else if (this.recordType === RecordType[RecordType.AbandonCCTVReason]) {
      return 'Abandon CCTV Log'
    } else if (this.recordType === RecordType[RecordType.AbandonWaterMainsTestReason]) {
      return 'Abandon Water Test'
    } else if (this.recordType === RecordType[RecordType.AbandonDatumReason]) {
      return 'Abandon Datum'
    } else {
      return this.recordType
    }
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('abandonformReference')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private onChangeAbandonType(selectedItems) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = this.recordType
      this.configureDropdownDialog = true
    }
  }

  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    const deletedRecord: MasterRecord | undefined = this.abandonReasonList.find((e) => e.isDeleted === true)
    this.abandonReasonList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.abandonReasonList.push(deletedRecord)
    }
    MasterRecordController.GetMasterRecords(this.recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.selectedAbandonDetails.type = ''
        setTimeout(() => {
          this.$validator.errors.items = []
        }, 0)
        this.addConfigureOption(res)
      }
    })
  }

  private addConfigureOption(res: MasterRecord[]) {
    this.abandonReasonList = res && res.length > 0 ? res.filter((e) => e.isDeleted === false) : []
    if (this.isUserRoleAdministrator && this.abandonReasonList.filter((x) => x.description !== 'Configure')) {
      const relation: any = {}
      relation.description = 'Configure'
      this.abandonReasonList.push(relation)
    }
  }

  private async abandonReasonSave() {
    await this.validate()
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          const addAbandonedSIProduct: AddAbandonedSIProductRequest = new AddAbandonedSIProductRequest()
          addAbandonedSIProduct.id = this.abandonReasonDetails.id
          addAbandonedSIProduct.jobId = this.abandonReasonDetails.jobId
          addAbandonedSIProduct.forEngineerVisitDetailId = this.abandonReasonDetails.forEngineerVisitDetailId
          addAbandonedSIProduct.siProductType = this.abandonReasonDetails.siProductType

          const reason = this.abandonReasonList.find((x) => x.id === this.selectedAbandonDetails.type)
          addAbandonedSIProduct.abandonedReason = reason ? reason.description : ''
          addAbandonedSIProduct.abandonedReasonNotes = this.selectedAbandonDetails.reason
          SiteInvestigationController.AddAbandonedSIProductRequest(addAbandonedSIProduct)
            .then((res: boolean) => {
              this.isLoading = false
              this.$emit('abandonReasonSave', res ? true : false)
            })
            .catch((err: any) => {
              eventBus.$emit(
                'errorHandler',
                'Error submitting Abandoned' + this.abandonReasonDetails.siProductType + 'Request, please try again',
                true
              )
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
        this.isLoading = false
      })
  }
}
</script>

<style scoped>
.bold-select {
  font-weight: 600;
}
</style>
