<template>
  <div class="task-col grey lighten-3">
    <div class="task-header d-flex">
      <h3 :class="record.headerClass">{{ usJobStatus }}</h3>
      <v-menu
        v-if="isNewHeader(usJobStatus)"
        bottom
        right
        :close-on-content-click="false"
        :nudge-width="240"
        offset-x
        class="status-filter"
      >
        <template #activator="{ on }">
          <v-btn slot="activator" icon color="primary" flat small class="ma-0 filter-btn" v-on="on">
            <v-icon class="md-icon" color="red" :class="getFilterClass" dark>filter_list</v-icon>
          </v-btn>
        </template>
        <v-card class="pa-2 pt-0">
          <v-select
            v-model="selectedDelayReason"
            placeholder="Delay Type"
            class="visit-type-select"
            item-text="description"
            item-value="description"
            :items="delayReasonList"
            @change="onDelayReasonChange($event)"
          />
        </v-card>
      </v-menu>
    </div>
    <v-divider class="mt-0 mx-3 mb-2"></v-divider>
    <div v-if="record.items.length" class="task-body px-3 pb-3 pt-1">
      <v-card v-for="(item, index) in record.items" :key="index">
        <JobSummaryCard :key="index" :job="item" :title-generator="titleGenerator" @redirectToJob="onRedirectToJob">
          <div slot="JobCardDetails">
            <div class="primary-title">
              <h4 class="date-title">
                <span v-if="item.companyName">
                  <a href="javascript:void(0)" :name="'companyName-' + item.id">
                    {{ item.companyName }}
                  </a>
                  <span v-if="!item.engineerName" class="primary--text">
                    (
                    <v-menu attach offset-y>
                      <template #activator="{ on }">
                        <a href="javascript:void(0)" :name="'contractor-' + item.id" v-on="on">
                          <v-icon color="secondary" small>call</v-icon>
                        </a>
                      </template>
                      <v-list v-for="(contactNumber, numberIndex) in item.contactNumbers" :key="numberIndex">
                        <v-list-tile>
                          <v-list-tile-title>
                            <span
                              :name="'contractor-' + numberIndex + '-' + item.id"
                              class="primary--text cursor-pointer"
                              @click.prevent="onCallIconClick(item, numberIndex)"
                            >
                              <v-icon>call</v-icon>
                              {{ contactNumber.phoneNumber }}
                            </span>
                            <span class="primary--text">
                              {{ contactNumber.label ? '(' + contactNumber.label + ')' : '' }}
                            </span>
                          </v-list-tile-title>
                        </v-list-tile>
                        <v-divider v-if="numberIndex !== item.contactNumbers.length - 1"></v-divider>
                      </v-list>
                    </v-menu>
                    )
                  </span>
                </span>
                <span v-else>New Job</span>
                <v-tooltip v-if="item.commodityWorkType" top>
                  <template #activator="{ on }">
                    <v-badge overlap color="grey darken-2" class="return-visit">
                      <span v-on="on">
                        <v-icon
                          v-if="item.commodityWorkType === commodityWorkType[commodityWorkType.Response]"
                          color="secondary"
                        >
                          assignment_return
                        </v-icon>
                        <v-icon v-else color="secondary">construction</v-icon>
                      </span>
                    </v-badge>
                  </template>
                  <span>{{ item.commodityWorkType }}</span>
                </v-tooltip>
              </h4>
              <span v-if="isNewHeader(usJobStatus) && item.delayCode" class="delayCodeDetail">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon color="red" v-on="on">av_timer</v-icon>
                  </template>
                  <span>Delay</span>
                </v-tooltip>
                <v-tooltip bottom :disabled="!item.delayNotes">
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.delayCode }}</span>
                  </template>
                  <span>{{ item.delayNotes }}</span>
                </v-tooltip>
              </span>
            </div>
            <div v-if="item.engineerName || item.engineerContactNumber" class="bottom-row">
              <span class="img-text">
                {{ item.engineerName }}
              </span>
              <span class="primary--text">
                (
                <v-menu attach offset-y>
                  <template #activator="{ on }">
                    <a href="javascript:void(0)" :name="'engineer-' + item.id" v-on="on">
                      <v-icon color="secondary" small>call</v-icon>
                    </a>
                  </template>
                  <v-list>
                    <v-list-tile>
                      <v-list-tile-title>
                        <span
                          :name="'engineer-' + item.id"
                          class="primary--text cursor-pointer"
                          @click.prevent="onCallIconClick(item)"
                        >
                          <v-icon>call</v-icon>
                          {{ item.engineerContactNumber }}
                        </span>
                      </v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
                )
              </span>
            </div>
          </div>
        </JobSummaryCard>
      </v-card>
    </div>
    <div v-else class="unavailable-job-msg">
      <span>Data not available</span>
    </div>
    <PartialJobView :job-id="selectedJobIdToExpand" @closeJobView="closeJobView"></PartialJobView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import JobSummaryCard from '@/components/JobSummaryCard.vue'
import eventBus from '@/common/bus'
import CallParameters from '@/models/twilio/CallParameters'
import USDashboardRecordModel from '@/models/claim/USDashboardRecordModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import IUSJobStatusBarModel from '@/models/claim/IUSJobStatusBarModel'
import { USJobStatus, RecordType, CommodityWorkType } from '@/common/enums'
import Shared from '../common/shared'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import PartialJobView from '@/components/PartialJobView.vue'

interface IDropdownType {
  description: string
  alterText?: string
}

@Component({
  name: 'USJobStatusBar',
  components: {
    JobSummaryCard,
    PartialJobView,
  },
})
export default class USJobStatusBar extends Vue {
  @Prop() private record: IUSJobStatusBarModel

  private selectedDelayReason = 'All'
  private commodityWorkType = CommodityWorkType
  private delayReasonList: MasterRecord[] = []
  private openJobView = false
  private selectedJobIdToExpand = ''
  public titleGenerator = (job: ContractorAppointedModel) => job.id

  private created() {
    this.getDelayReasonList()
  }

  private get usJobStatus() {
    return this.record.status
      .split(/(?=[A-Z])/)
      .join(' ')
      .toString()
  }

  private onCallIconClick(usJob: USDashboardRecordModel, index?: number) {
    const callParameters = new CallParameters()
    if (index != null) {
      callParameters.to = usJob.contactNumbers[index].phoneNumber
    } else {
      callParameters.to = usJob.engineerContactNumber
    }

    callParameters.jobId = usJob.id
    callParameters.contractorId = usJob.contractorId
    eventBus.$emit('callIconClick', callParameters)
  }

  private get getFilterClass(): string {
    if (this.selectedDelayReason !== 'All') {
      return 'filter-new-activated'
    }
    return ''
  }

  private isNewHeader(status: string): boolean {
    return status === USJobStatus[USJobStatus.New]
  }

  private async getDelayReasonList() {
    try {
      const recordType: string = RecordType[RecordType.USJobDelayReason]
      const res = await MasterRecordController.GetMasterRecords(recordType)
      if (res && res.length > 0) {
        this.delayReasonList = res
      }
      // add default option
      const delayReason = new MasterRecord()
      delayReason.description = 'All'
      delayReason.id = 'All'
      this.delayReasonList.unshift(delayReason)
    } catch (error) {
      eventBus.$emit('showSnackbar', 'Error loading in delay reason.')
    }
  }

  private onDelayReasonChange(status) {
    this.selectedDelayReason = status
    this.$emit('filterDelay', status)
  }

  private onRedirectToJob(jobId: string) {
    if (!Shared.isUserHasJobRights(jobId)) {
      return
    }
    // open timeline with relative card open.
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.task-header {
  padding: 10px 15px;
  position: relative;
  min-height: 48px;
}
.task-header span {
  font-weight: 500;
  display: inline-block;
  margin-left: 5px;
}
.task-body .v-card {
  margin-bottom: 15px;
}
.task-body .v-card:last-child {
  margin-bottom: 0;
}
.task-body .v-card >>> .v-card__title {
  padding: 6px 12px 4px;
  border-bottom: 1px solid #e0e0e0;
}
.task-body .v-card >>> .v-card__text {
  padding: 4px 12px 6px;
}
.task-body >>> .v-card__text .primary-title {
  position: relative;
}
.task-body >>> .v-card__text .primary-title h4 a {
  vertical-align: middle;
}
.task-body h4.date-title .card-emergency-icon,
.claim-management-icon {
  position: absolute;
  left: 0;
  top: 0px;
}
.claim-management-icon {
  top: -2px;
}
.task-body h4.date-title >>> .v-tooltip {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  top: -2px;
}
.category-icon-img {
  width: 15px;
  vertical-align: top;
}
.task-body >>> .v-card .details-row {
  position: relative;
  padding-left: 20px;
}
.task-body >>> .v-card .details-row i {
  position: absolute;
  left: -5px;
  top: 0px;
  font-size: 22px;
}
.task-body .eta-info {
  font-size: 13px;
  vertical-align: top;
  position: relative;
  padding-left: 30px;
  margin: 0;
  color: #fff;
}
.task-body .eta-info >>> .v-icon {
  vertical-align: text-bottom;
  position: absolute;
  left: 8px;
  top: 3px;
}
.all-jobs.open-block .jobs-taskboard {
  display: none;
}
.all-jobs .jobs-map {
  display: none;
}
.all-jobs.open-block .jobs-map {
  display: block;
}
.task-action {
  position: relative;
}
.task-action >>> .progress-linear {
  position: absolute;
  bottom: -10px;
  left: 0;
}
.task-col {
  min-height: 250px;
}
.unavailable-job-msg {
  display: flex;
  min-height: 200px;
}
.unavailable-job-msg span {
  margin: auto;
  color: #ababab;
  font-size: 16px;
}
.search-contractor-outjobs {
  display: inline-block;
  width: 360px;
}
.task-body .v-card >>> .v-card__actions {
  padding: 8px 4px;
}
.all-jobs >>> .v-btn--flat {
  background: transparent !important;
  box-shadow: none !important;
}
.task-body .v-card >>> .v-card__title.icon-space {
  padding-right: 25px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.img-text {
  position: relative;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.img-text img {
  position: absolute;
  top: 3px;
  max-width: 17px;
  left: -2px;
}
.task-body h4.date-title {
  width: 100%;
  height: 100%;
  vertical-align: top;
  padding-right: 20px;
}
.status-filter {
  flex: 0 !important;
}
.filter-new-activated {
  background: lightpink;
  border-radius: 50%;
  padding: 4px;
}
.return-visit {
  position: absolute;
  right: -5px;
  top: 0px;
}
.return-visit img {
  width: 25px;
  height: 20px;
}
.return-visit >>> .v-badge__badge {
  width: 15px;
  height: 15px;
  font-size: 11px;
  line-height: 0;
  top: -8px;
  right: -6px;
}
</style>
