<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Additional Request</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="btn-closeAdditionalRequestDialog" @click="closeAdditionalRequestDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-card-text class="scroll-content-dialog px-3 pt-4">
      <v-layout wrap mb-1>
        <v-checkbox
          v-model="requestedToAddMaterialRequest"
          name="timeRequest"
          :hide-details="true"
          :value="false"
          label="Add Material Request"
          color="primary"
          class="que-checkbox"
        ></v-checkbox>
      </v-layout>
      <AddMaterialRequest
        v-if="requestedToAddMaterialRequest"
        ref="addMaterialRequest"
        v-model="additionalMaterialRequest"
        :material-list="materialList"
      />
      <v-layout wrap mb-1>
        <v-checkbox
          v-model="requestedToAddTimeRequest"
          name="timeRequest"
          :hide-details="true"
          :value="false"
          label="Add Time Request"
          color="primary"
          class="que-checkbox"
        ></v-checkbox>
      </v-layout>
      <AddTimeRequest
        v-if="requestedToAddTimeRequest"
        ref="addTimeRequest"
        v-model="additionalTimeRequest"
        @updateSelectedTimeRequestSlot="updateSelectedTimeRequestSlot"
      />
      <v-layout v-if="!isForContractorJobCard" wrap mb-3 class="returnVisit">
        <v-checkbox
          v-model="requestedToAddReturnVisitRequest"
          name="visitRequest"
          :hide-details="true"
          :value="false"
          label="Add Return Visit Request"
          color="primary"
          class="que-checkbox"
        ></v-checkbox>
      </v-layout>
      <AddReturnVisitRequest
        v-if="requestedToAddReturnVisitRequest"
        ref="addReturnVisitRequest"
        v-model="additionalReturnVisitRequest"
        :return-visit-type-list="returnVisitTypeList"
        @updateVisitHourMinutesValidation="updateVisitHourMinutesValidation"
        @openConfigureDropdown="openConfigureDropdown"
        @updateReturnVisitAtUtc="updateReturnVisitAtUtc"
      ></AddReturnVisitRequest>
    </v-card-text>

    <v-divider />

    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn color="primary" flat="flat" @click.native="closeAdditionalRequestDialog">Close</v-btn>
      <v-btn
        color="primary"
        class="mr-0 btn-submitAdditionalRequest"
        :disabled="
          (!requestedToAddTimeRequest && !requestedToAddMaterialRequest && !requestedToAddReturnVisitRequest) ||
          onSubmitLoading ||
          !isVisitTimeValid
        "
        @click.native="submitAdditionalRequest"
      >
        Submit
      </v-btn>
    </v-card-actions>

    <!-- dialog - add return visit type -->
    <v-dialog v-model="configureDropdownDialog" max-width="700" persistent content-class="v-dialog--scrollable">
      <ConfigureDropdown
        ref="configureDropdownDialog"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script lang="ts">
import store from '@/store'
import moment from 'moment'
import Shared from '@/common/shared'
import storeGetters from '@/storeGetters'
import JobController from '@/api/jobController'
import MasterRecord from '@/models/MasterRecord'
import { TimeSpanSlots } from '@/common/interfaces'
import MaterialController from '@/api/materialController'
import MaterialModel from '@/models/material/MaterialModel'
import MasterRecordController from '@/api/masterRecordController'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import AddTimeRequest from '@/components/timeline/AddTimeRequest.vue'
import AddEngineerRequest from '@/models/requests/AddEngineerRequest'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import AddMaterialRequest from '@/components/timeline/AddMaterialRequest.vue'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import AddReturnVisitRequest from '@/components/timeline/AddReturnVisitRequest.vue'
import { EngineerRequestStatus, EngineerRequestType, RecordType } from '@/common/enums'
import EngineerMaterialRequest from '@/models/claim/engineerRequest/EngineerMaterialRequest'
import EngineerExtraTimeRequest from '@/models/claim/engineerRequest/EngineerExtraTimeRequest'
import EngineerReturnVisitRequest from '@/models/claim/engineerRequest/EngineerReturnVisitRequest'
import eventBus from '@/common/bus'

@Component({
  components: {
    AddMaterialRequest,
    AddTimeRequest,
    AddReturnVisitRequest,
    ConfigureDropdown,
  },
})
export default class AdditionalRequest extends Vue {
  @Prop() private jobId: string
  @Prop() private itemId: string
  @Prop({ default: null })
  private previousEngineerVisitDetail: EngineerVisitDetail | null
  @Prop({ default: false }) private isFromJobCompletionCard: boolean
  @Prop({ default: false }) private isForContractorJobCard: boolean
  private snackbar = false
  private snackbarText: string | null = ''
  private isVisitTimeValid = true
  private onSubmitLoading = false
  private configureDropdownType = ''
  private materialList: MaterialModel[] = []
  private estimatedVisitTimeHour = 0
  private selectedTimeRequestSlot = 0
  private estimatedVisitTimeMinute = 0
  private returnVisitTypeList: MasterRecord[] = []
  private configureDropdownDialog = false
  private requestedToAddTimeRequest = false
  private returnVisitAtUtc: moment.Moment | null = null
  private requestedToAddMaterialRequest = false
  private snackbarTimeout: number = Shared.snackbarTimeout
  private requestedToAddReturnVisitRequest = false
  private additionalMaterialRequest: EngineerMaterialRequest = new EngineerMaterialRequest()
  private additionalTimeRequest: EngineerExtraTimeRequest = new EngineerExtraTimeRequest()
  private additionalReturnVisitRequest: EngineerReturnVisitRequest = new EngineerReturnVisitRequest()

  private get engineerVisitDetail(): EngineerVisitDetail | null {
    return this.isForContractorJobCard
      ? this.previousEngineerVisitDetail
        ? this.previousEngineerVisitDetail
        : null
      : storeGetters.getEngineerVisitDetail(this.jobId, this.itemId)
  }

  private created() {
    if (this.materialList.length > 0 && this.returnVisitTypeList.length > 0) {
      this.bindValuesInAdditionalRequestDialog()
    } else if (!(this.materialList.length > 0)) {
      this.getMaterialList()
    } else if (!(this.returnVisitTypeList.length > 0)) {
      this.GetReturnVisitTypeList()
    }
  }

  private mounted() {
    if (this.isFromJobCompletionCard) {
      this.requestedToAddReturnVisitRequest = true
      this.requestedToAddMaterialRequest = false
    } else {
      this.requestedToAddReturnVisitRequest = false
      this.requestedToAddMaterialRequest = true
    }
  }

  private bindValuesInAdditionalRequestDialog() {
    if (this.isFromJobCompletionCard) {
      this.requestedToAddReturnVisitRequest = true
      this.requestedToAddMaterialRequest = false
    } else {
      this.requestedToAddReturnVisitRequest = false
      this.requestedToAddMaterialRequest = true
    }
    this.requestedToAddTimeRequest = false
    this.additionalMaterialRequest.additionalMaterialRequestName = []
    this.additionalMaterialRequest.additionalMaterialRequest = []
    this.additionalMaterialRequest = new EngineerMaterialRequest()
    this.additionalTimeRequest = new EngineerExtraTimeRequest()
    this.additionalReturnVisitRequest = new EngineerReturnVisitRequest()
  }

  private getMaterialList() {
    MaterialController.GetMaterials()
      .then((res: MaterialModel[]) => {
        if (res) {
          this.materialList = res
          if (!(this.returnVisitTypeList.length > 0)) {
            // bind return visit types
            this.GetReturnVisitTypeList()
          } else {
            this.bindValuesInAdditionalRequestDialog()
          }
        }
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error loading material list, please try again.', true)
      })
  }

  // Get list of return visit type.
  private GetReturnVisitTypeList(): void {
    this.GetRetrunVisitTypes().then((res: MasterRecord[]) => {
      if (res) {
        if (this.additionalReturnVisitRequest.returnVisitType !== undefined) {
          this.returnVisitTypeList = res.filter(
            (e) => e.isDeleted === false || (e.isDeleted && this.additionalReturnVisitRequest.returnVisitType === e.id)
          )
        } else {
          this.returnVisitTypeList = res.filter((e) => e.isDeleted === false)
        }
        // bind dropdown default values
        this.bindValuesInAdditionalRequestDialog()
      }
    })
  }

  private async GetRetrunVisitTypes(): Promise<MasterRecord[]> {
    const recordType: string = RecordType[RecordType.ReturnVisitType]
    this.configureDropdownType = RecordType[RecordType.ReturnVisitType]
    const returnVisitTypes = await MasterRecordController.GetMasterRecords(recordType)
    const record: MasterRecord = new MasterRecord()
    record.description = 'Configure'
    record.isDeleted = false
    returnVisitTypes.push(record)
    return returnVisitTypes
  }

  private closeAdditionalRequestDialog() {
    this.$emit('closeAdditionalRequestDialog')
  }

  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    if (this.configureDropdownType === RecordType[RecordType.ReturnVisitType]) {
      this.configureDropdownType = ''
      this.additionalReturnVisitRequest.returnVisitType = ''
      const deletedRecord: MasterRecord | undefined = this.returnVisitTypeList.find((e) => e.isDeleted === true)
      this.returnVisitTypeList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.returnVisitTypeList.push(deletedRecord)
      }
      if (this.isUserRoleAdministrator) {
        const returnVisitType: any = []
        returnVisitType.description = 'Configure'
        this.returnVisitTypeList.push(returnVisitType)
      }
    }
  }

  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private submitAdditionalRequest() {
    this.validateAdditionalRequestFields().then((result: boolean) => {
      if (result) {
        this.onSubmitLoading = true
        const addEngineerRequest: AddEngineerRequest = new AddEngineerRequest()
        if (this.engineerVisitDetail) {
          addEngineerRequest.jobId = this.engineerVisitDetail.jobId
          addEngineerRequest.forEmergencyTypeId = this.engineerVisitDetail.forEmergencyTypeId
          addEngineerRequest.forEmergencyDetailId = this.engineerVisitDetail.forEmergencyDetailId
          addEngineerRequest.engineerVisitDetailId = this.engineerVisitDetail.id
          addEngineerRequest.companyName = this.engineerVisitDetail.companyName
        }

        addEngineerRequest.requestStatus = this.isForContractorJobCard
          ? EngineerRequestStatus.Pending
          : EngineerRequestStatus.Approved
        const selectedMaterialName = this.additionalMaterialRequest.materialName

        const request: any[] = []
        if (this.requestedToAddMaterialRequest) {
          const engineerMaterialRequest: EngineerMaterialRequest = Object.assign({}, this.additionalMaterialRequest)
          const commaSeparatedMaterialNames = engineerMaterialRequest.additionalMaterialRequest
            .map((m) => m.name)
            .join(',')
          const additionalMaterialRequestName = engineerMaterialRequest.additionalMaterialRequest.map((m) => m.name)
          const excludeFromCustomerApprovalUpdates = engineerMaterialRequest.additionalMaterialRequest.map(
            (m) => m.excludeFromCustomerApprovalUpdates
          )

          // bind material request if selected
          let materialRequestBase: any = {}
          engineerMaterialRequest.materialName = commaSeparatedMaterialNames
          engineerMaterialRequest.additionalMaterialRequestName = additionalMaterialRequestName
          engineerMaterialRequest.materialCost = Number(engineerMaterialRequest.materialCost)
          engineerMaterialRequest.excludeFromCustomerApprovalUpdates =
            excludeFromCustomerApprovalUpdates.filter((e) => e === false).length === 0

          delete (engineerMaterialRequest as any).additionalMaterialRequest

          materialRequestBase = engineerMaterialRequest
          materialRequestBase.requestType = EngineerRequestType[EngineerRequestType.Material]
          request.push(materialRequestBase)
        }
        if (this.requestedToAddTimeRequest) {
          // bind additional time request if selected
          let timeRequestBase: any = {}
          this.additionalTimeRequest.extraMinutes = this.selectedTimeRequestSlot
          timeRequestBase = this.additionalTimeRequest
          timeRequestBase.requestType = EngineerRequestType[EngineerRequestType.Time]
          request.push(this.additionalTimeRequest)
        }
        if (this.requestedToAddReturnVisitRequest) {
          // bind additional return visit request if selected
          let returnVisitRequestBase: any = {}
          // convert estimated visit duration to number
          this.additionalReturnVisitRequest.returnVisitDuration =
            this.estimatedVisitTimeHour > 0
              ? this.estimatedVisitTimeHour * 60 + this.estimatedVisitTimeMinute
              : this.estimatedVisitTimeMinute
          returnVisitRequestBase = this.additionalReturnVisitRequest
          returnVisitRequestBase.requestType = EngineerRequestType[EngineerRequestType.ReturnVisit]
          if (this.returnVisitAtUtc) {
            returnVisitRequestBase.returnVisitAt = moment(this.returnVisitAtUtc)
          }
          request.push(this.additionalReturnVisitRequest)
        }
        addEngineerRequest.engineerRequest = request

        JobController.AddEngineerRequest(addEngineerRequest, this.isFromJobCompletionCard)
          .then((res: boolean) => {
            if (res) {
              // add to the list
              this.onSubmitLoading = false
              this.$emit('closeAdditionalRequestDialog')
              this.snackbarText = 'Request added successfully'
              this.snackbar = true
            } else {
              this.onSubmitLoading = false
              this.additionalMaterialRequest.materialName = ''
              this.additionalMaterialRequest.materialName = selectedMaterialName
              this.snackbarText = 'Something went wrong'
              this.snackbar = true
            }
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error submitting engineer request, please try again.', true)
            this.onSubmitLoading = false
          })
      }
    })
  }

  private async validateAdditionalRequestFields(): Promise<boolean> {
    let result = true
    let validationForMaterial = true
    let validationForTime = true
    let validationForReturnVisit = true
    if (this.requestedToAddMaterialRequest) {
      const subs = this.$refs.addMaterialRequest as AddMaterialRequest
      validationForMaterial = await subs.$validator.validateAll()
    }
    if (this.requestedToAddTimeRequest) {
      const subss = this.$refs.addTimeRequest as AddMaterialRequest
      validationForTime = await subss.$validator.validateAll()
    }
    if (this.requestedToAddReturnVisitRequest) {
      const subsss = this.$refs.addReturnVisitRequest as AddReturnVisitRequest
      validationForReturnVisit = await subsss.$validator.validateAll()
    }
    // set focus to non validate field
    if (!(validationForMaterial && validationForTime && validationForReturnVisit)) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = validationForMaterial && validationForTime && validationForReturnVisit
    }
    return result
  }

  private openConfigureDropdown() {
    this.configureDropdownType = RecordType[RecordType.ReturnVisitType]
    this.configureDropdownDialog = true
  }

  private updateVisitHourMinutesValidation(
    isVisitTimeValid: boolean,
    estimatedVisitTimeHour: number,
    estimatedVisitTimeMinute: number
  ) {
    this.isVisitTimeValid = isVisitTimeValid
    this.estimatedVisitTimeHour = estimatedVisitTimeHour
    this.estimatedVisitTimeMinute = estimatedVisitTimeMinute
  }

  private updateReturnVisitAtUtc(returnVisitAtUtc: moment.Moment | null) {
    if (returnVisitAtUtc) {
      this.returnVisitAtUtc = returnVisitAtUtc
    }
  }

  private updateSelectedTimeRequestSlot(selectedTimeRequestSlot: number) {
    this.selectedTimeRequestSlot = selectedTimeRequestSlot
  }
}
</script>
