<template>
  <div v-if="(isComponentLoaded || disabled) && state">
    <v-container class="pa-0">
      <v-layout wrap>
        <v-select
          v-if="isComponentLoaded"
          ref="packageSelector"
          v-model="state.selectedPackage"
          :label="disabled ? '' : 'Select Package'"
          :items="packageSelectItems"
          :disabled="!isExtrasLoaded || disabled || isTestingOnlyJob"
          class="select-package"
          @input="changePackage"
        >
          <template #item="{ item }">
            <span
              :class="{
                'obsolete-item': item.isObsolete,
                'deleted-item': item.isDeleted,
              }"
            >
              {{ item.text }}
            </span>
          </template>
          <template #selection="{ item }">
            <span :class="{ 'obsolete-item': item.isObsolete }">
              {{ item.text }}
            </span>
          </template>
        </v-select>
        <v-select
          v-else
          ref="offlinePackageDisplay"
          disabled
          :placeholder="
            state.selectedPackage
              ? `${state.selectedPackage.name} - £${state.selectedPackage.price}`
              : '--- No Package ---'
          "
        />
        <v-tooltip v-if="state.selectedPackage" bottom class="mt-4 ml-2" max-width="500">
          <template #activator="{ on }">
            <v-icon class="grey--text text--darken-1" v-on="on">info</v-icon>
          </template>
          <span>{{ state.selectedPackage.description }}</span>
        </v-tooltip>
        <UpdateProductPriceDialog
          v-if="state && state.selectedPackage"
          ref="updateProductPriceDialogRef"
          :price-label="`£${state.selectedPackage.price}`"
          :is-price-updated.sync="state.selectedPackage.isPriceUpdated"
          :product-price.sync="productBasePrice"
          :is-disabled="disabled"
          :is-package-selection="true"
          @editProductPrice="
            () => {
              openEditProductPriceDialog(state.selectedPackage.price)
            }
          "
          @productPriceUpdated="onProductPriceUpdated"
        ></UpdateProductPriceDialog>
      </v-layout>
      <div v-if="isExtrasLoaded || disabled">
        <template v-for="(pit, ind) in state.selectedPits">
          <SIPitCard
            :key="ind"
            ref="Pit"
            v-model="pit.value"
            :pit-title="`Pit ${ind + 1}`"
            :included-tests="state.packageIncludedTests"
            :price-included="true"
            :disabled="disabled"
            :pits="pits"
            :is-engineer-visit-created="isEngineerVisitCreated"
            :added-pit-ids="pitIds"
            :is-new-job="jobId ? false : true"
            @delete-pit="() => deletePit(pit, state.selectedPits)"
          />
        </template>
        <v-layout v-if="pitProduct && !disabled" class="mb-4">
          <v-spacer />
          <v-btn
            ref="addPit"
            flat
            solid
            :disabled="reachedPitLimit()"
            class="ma-0"
            @click="() => addPit(pitProduct, state.selectedPits)"
          >
            Add Pit
            <v-icon>add</v-icon>
          </v-btn>
        </v-layout>
      </div>
      <v-layout v-show="isExtrasLoaded || disabled" wrap class="pa-3 grey lighten-3 mb-2">
        <v-flex pb-2 xs12><h3>Additional Products</h3></v-flex>
        <v-flex pb-2 xs12>
          <v-autocomplete
            v-if="!disabled"
            ref="extraPitSelector"
            :key="bulkPitModel"
            :search-input.sync="bulkPitModel"
            placeholder="Add Pit Products..."
            :items="extraPitSelectItems"
            class="additional-product"
            :disabled="isTestingOnlyJob"
            @input="
              (prod) => {
                bulkPitModel = prod.text
                addBulkPit(prod)
                bulkPitModel = ''
              }
            "
          >
            <template #item="{ item }">
              <span :class="{ 'deleted-item': item.isDeleted }">
                {{ item.text }}
              </span>
            </template>
          </v-autocomplete>
          <!-- Replace with BulkPit control -->
          <template v-for="(bulkPit, ind) in state.selectedBulkPits">
            <div :key="ind">
              <v-card class="mb-4 pit-card elevation-2">
                <v-card-title>
                  <h3>
                    {{ bulkPit.description }} -
                    <UpdateProductPriceDialog
                      :price-label="`£${bulkPit.basePrice}`"
                      :is-price-updated.sync="bulkPit.isPriceUpdated"
                      :product-price.sync="productBasePrice"
                      :is-disabled="disabled"
                      @editProductPrice="
                        () => {
                          openEditProductPriceDialog(bulkPit)
                        }
                      "
                      @productPriceUpdated="onProductPriceUpdated"
                    ></UpdateProductPriceDialog>
                  </h3>
                  <v-spacer />
                  <v-btn v-if="!disabled" icon @click="deleteBulkPit(bulkPit)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-card-title>
                <v-flex>
                  <div v-if="bulkPit.productAttributes.find((e) => e.attributeName === 'maxPits')">
                    <div v-for="(pit, index) in bulkPit.pits" :key="index + 'x'">
                      <SIPitCard
                        :key="index"
                        ref="ExtraPit"
                        v-model="pit.value"
                        :pit-title="`Extra Pit ${index + 1}`"
                        :price-included="true"
                        :included-tests="state.packageIncludedTests"
                        :disabled="disabled"
                        :pits="pits"
                        :is-engineer-visit-created="isEngineerVisitCreated"
                        :allowed-delete="false"
                        :added-pit-ids="pitIds"
                        :is-new-job="jobId ? false : true"
                      />
                    </div>
                  </div>
                </v-flex>
              </v-card>
            </div>
          </template>
        </v-flex>

        <v-flex xs12>
          <v-autocomplete
            v-if="!disabledMonitoringProduct"
            ref="extraMonitoringSelector"
            :key="monitoringProductModel"
            :disabled="isTestingOnlyJob"
            :search-input.sync="monitoringProductModel"
            return-object
            placeholder="Add Monitoring Products..."
            :items="filteredMonitoringProductItems"
            class="monitoring-product-card"
            @input="addMonitoringProduct"
          >
            <template #item="{ item }">
              <span :class="{ 'deleted-item': item.isDeleted }">
                {{ item.text }}
              </span>
            </template>
          </v-autocomplete>
          <template v-for="(product, index) in state.selectedMonitoringProducts">
            <MonitoringProductCard
              ref="MonitoringProductCard"
              :key="index + 'x'"
              v-model="state.selectedMonitoringProducts[index]"
              :product-title="product.name"
              :disabled="disabledMonitoringProduct"
              @delete-product="deleteProduct"
            />
          </template>
        </v-flex>

        <v-flex pb-2 xs12>
          <v-autocomplete
            v-if="!disabled"
            ref="siteExtraSelector"
            v-model="state.selectedOther"
            clearable
            placeholder="Select Site Extras"
            :items="productSelectItems"
            multiple
            :disabled="disabled"
            class="site-extra-product"
          >
            <template #item="{ item }">
              <span class="mr-2">
                <v-icon :color="state.selectedOther.find((i) => i.id === item.value.id) ? 'primary' : 'none'">
                  {{
                    state.selectedOther.find((i) => i.id === item.value.id) ? 'check_box' : 'check_box_outline_blank'
                  }}
                </v-icon>
              </span>
              <v-list-tile-content>
                <v-list-tile-title>
                  <span
                    :class="{
                      'obsolete-item': item.isObsolete,
                      'deleted-item': item.isDeleted,
                    }"
                  >
                    {{ item.text }}
                  </span>
                </v-list-tile-title>
              </v-list-tile-content>
            </template>
            <template #selection="{ item, index }">
              <div class="v-select__selection v-select__selection--comma">
                <span v-if="index + 1 < state.selectedOther.length" :class="{ 'obsolete-item': item.isObsolete }">
                  {{ item.text + ',' }}
                </span>
                <span v-else :class="{ 'obsolete-item': item.isObsolete }">
                  {{ item.text }}
                </span>
              </div>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex v-show="state.selectedOther.length > 0" xs12>
          <table class="white pa-3 quantity-table">
            <thead class="pb-2">
              <tr>
                <th class="text-left" width="250">Name</th>
                <th class="text-left">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(extra, ind) in state.selectedOther" :key="ind" ref="SiteExtra">
                <td>
                  <v-label v-if="isExtrasLoaded">
                    <span :class="{ 'obsolete-item': extraIsObsolete(extra) }">{{ extra.name }} -</span>
                  </v-label>
                  <v-label v-else>{{ extra.name }} -</v-label>
                  <UpdateProductPriceDialog
                    ref="updateProductPriceDialogRef"
                    :price-label="`£${extra.basePrice}`"
                    :is-price-updated.sync="extra.isPriceUpdated"
                    :product-price.sync="productBasePrice"
                    :is-disabled="disabled"
                    @editProductPrice="
                      () => {
                        openEditProductPriceDialog(extra)
                      }
                    "
                    @productPriceUpdated="onProductPriceUpdated"
                  ></UpdateProductPriceDialog>
                </td>
                <td>
                  <v-layout>
                    <v-flex>
                      <v-text-field
                        v-model.number="extra.quantity"
                        v-validate="`required|min_value:0`"
                        :data-vv-name="extra.name"
                        :error-messages="validationMessage(extra.name)"
                        type="number"
                        :hint="extra.unit"
                        :disabled="disabled"
                        required
                        @input="() => (totalPriceKey = !totalPriceKey)"
                      />
                    </v-flex>
                    <v-tooltip bottom class="ml-2 mt-2" max-width="300">
                      <template #activator="{ on }">
                        <v-icon class="grey--text text--darken-1" v-on="on">info</v-icon>
                      </template>
                      <span>{{ extra.description }}</span>
                    </v-tooltip>
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </table>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex v-if="isExtrasLoaded || disabled" xs12>
          <v-textarea
            v-model="state.extraProductNotes"
            label="Additional Product Notes"
            :disabled="disabled"
            rows="3"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex v-if="isExtrasLoaded || disabled" xs12>
          <v-textarea v-model="state.jobNotes" label="Job Notes" :disabled="disabled" rows="3" />
        </v-flex>
      </v-layout>
      <v-layout v-if="isExtrasLoaded || disabled" :key="totalPriceKey" class="price-label pa-0">
        <h3 class="ml-auto">
          Total Price:
          <span class="primary--text">&pound;{{ state.totalPrice }}</span>
        </h3>
      </v-layout>
      <div v-if="isComponentLoaded && !isExtrasLoaded && !disabled" style="min-height: 400px">
        <v-progress-circular
          :width="2"
          :size="50"
          indeterminate
          color="primary"
          :style="getLoaderStyle(70)"
        ></v-progress-circular>
      </div>
    </v-container>
  </div>
  <div v-else ref="componentLoader" style="min-height: 400px">
    <v-progress-circular
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(100)"
    ></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import SIPitCard from '@/components/SIPitCard.vue'
import MonitoringProductCard from '@/components/MonitoringProductCard.vue'
import SitePackage from '@/models/siteInvestigation/SitePackage'
import Pit from '@/models/siteInvestigation/Pit'
import BulkPit from '@/models/siteInvestigation/BulkPit'
import PitTest from '@/models/siteInvestigation/PitTest'
import Product from '@/models/siteInvestigation/Product'
import ProductAttribute from '@/models/siteInvestigation/ProductAttribute'
import ProductExtra from '@/models/siteInvestigation/ProductExtra'
import JobPackageState from '@/models/siteInvestigation/JobPackageState'
import SiteInvestigationController from '@/api/siteInvestigationController'
import { SIProductType } from '@/common/enums'
import MonitoringProduct from '@/models/siteInvestigation/MonitoringProduct'
import UpdateProductPriceDialog from '@/components/UpdateProductPriceDialog.vue'
import storeGetters from '../storeGetters'

@Component({
  name: 'PackageSelection',
  components: {
    SIPitCard,
    MonitoringProductCard,
    UpdateProductPriceDialog,
  },
})
export default class PackageSelection extends Vue {
  @Prop({ default: undefined }) public value: JobPackageState

  @Prop({ default: undefined }) public claimManagementCompanyId: number

  @Prop({ default: false }) public disabled: boolean

  @Prop({ default: '' }) public jobId: string

  @Prop({ default: false }) public disabledMonitoringProduct: boolean

  @Prop() public isTestingOnlyJob: boolean

  public packageProducts: Product[]
  public pitProduct: Pit | null = null
  public tempMonitoringProductItems: Array<{
    text: string
    value: MonitoringProduct
  }> = [] // temp variable to manage monitoring product list

  // Dropdown Lists
  private packageSelectItems: Array<{
    text: string
    value: SitePackage | null
    isObsolete: boolean
    isDeleted: boolean
  }> = []
  private extraPitSelectItems: Array<{
    text: string
    value: Product
    isDeleted: boolean
  }> = []
  private monitoringProductItems: Array<{
    text: string
    value: MonitoringProduct
    isDeleted: boolean
  }> = []
  private productSelectItems: Array<{
    text: string
    value: Product
    isObsolete: boolean
    isDeleted: boolean
  }> = []
  private tempPackageSelectItems: Array<{
    text: string
    value: SitePackage | null
    isObsolete: boolean
    isDeleted: boolean
  }> = [] // temp variable to manage packageList

  private state: JobPackageState | null = null

  // Utility
  private bulkPitModel = ''
  private monitoringProductModel = ''
  private totalPriceKey = false
  private isComponentLoaded = false
  private isExtrasLoaded = false
  private pitIds: string[] = []

  // update product price
  private productBasePrice = 0
  private selectedProductForPriceUpdate: BulkPit | Product | number | null = null

  private get pits(): Pit[] {
    if (this.state) {
      const selectedPits = this.state.selectedPits.map((x) => x.value)
      const selectedExtraPits = this.state.selectedExtraPits.map((x) => x.value)
      let selectedBulkPits: Pit[] = []
      for (const x of this.state.selectedBulkPits.map((y) => y.pits)) {
        selectedBulkPits = selectedBulkPits.concat(x.map((y) => y.value))
      }
      return [...selectedPits, ...selectedExtraPits, ...selectedBulkPits]
    }
    return []
  }

  private get filteredMonitoringProductItems(): Array<{
    text: string
    value: MonitoringProduct
  }> {
    let ids: number[] = []
    if (this.state && this.state.selectedMonitoringProducts) {
      ids = this.state.selectedMonitoringProducts.map((x) => x.id)
    }
    return this.monitoringProductItems.filter((x) => !ids.includes(x.value.id))
  }

  public async mounted() {
    this.passDownState()
    if (!this.state) {
      this.state = new JobPackageState()
      this.updateInternalState()
    }
    await this.LoadAPIData()
    await this.passDownPackageState()
    this.isComponentLoaded = true
    if (this.jobId) {
      this.$emit('loadedAPIData')
    }
    this.isExtrasLoaded = true
    this.pitIds = this.pits.map((x) => x.pitId)
  }

  @Watch('claimManagementCompanyId')
  public async LoadAPIData() {
    this.isComponentLoaded = false
    if (this.claimManagementCompanyId) {
      let packageData: SitePackage[] = await SiteInvestigationController.GetSitePackages(this.claimManagementCompanyId)
      packageData = packageData.filter(
        (p) =>
          !p.isDeleted ||
          (p.isDeleted && this.state && this.state.selectedPackage && this.state.selectedPackage.id === p.id)
      )
      const productData: Product[] = await SiteInvestigationController.GetProducts(this.claimManagementCompanyId, true)
      await this.updateDropdownLists(packageData, productData)
    }
    this.isExtrasLoaded = true
    this.isComponentLoaded = true
  }

  @Watch('value', { deep: true })
  public passDownState() {
    this.state = this.value
  }

  public async reloadComponent() {
    await this.LoadAPIData()
    await this.passDownPackageState()
  }

  @Watch('state', { deep: true })
  public updateInternalState() {
    if (this.disabled) {
      return
    }
    this.$emit('input', this.state)
  }

  public async validatePackageSelection(): Promise<boolean> {
    let localResult = true
    if (this.state && this.state.selectedOther.length) {
      localResult = await this.$validator.validateAll()
    }

    const packagePitCards = this.$refs.Pit as SIPitCard[]
    const extraPitCards = this.$refs.ExtraPit as SIPitCard[]
    const pitCards = packagePitCards ? packagePitCards.concat(extraPitCards ? extraPitCards : []) : extraPitCards

    let componentValidation = true
    if (pitCards && pitCards.length) {
      const tasks = pitCards.map(async (pitCard) => {
        return await pitCard.validatePitCard()
      })
      const results = await Promise.all(tasks)
      componentValidation = results.reduce((c, p) => c && p, true)
    }

    let monitoringProductValidation = true
    const monitoringProductCards = (await this.$refs.MonitoringProductCard) as MonitoringProductCard[]

    if (monitoringProductCards && monitoringProductCards.length) {
      const productTasks = monitoringProductCards.map(async (monitoringProductCardRef) => {
        return await monitoringProductCardRef.validateProductCard()
      })

      const productResults = await Promise.all(productTasks)
      monitoringProductValidation = productResults.reduce((c, p) => c && p, true)
    }
    return localResult && componentValidation && monitoringProductValidation
  }

  private async passDownPackageState() {
    if (this.state) {
      if (this.state.selectedPackage) {
        const search = this.packageSelectItems.find(
          (item) => item.value != null && this.state!.selectedPackage!.id === item.value.id
        )
        if (search && search.value) {
          // set value of isPriceUpdated flag
          search.value.isPriceUpdated = search.value.price !== this.state.selectedPackage.price ? true : false
          search.value.price = this.state.selectedPackage.price
          this.state.selectedPackage = search.value
        } else {
          const p = this.state.selectedPackage
          this.packageSelectItems.unshift({
            text: `${p.name} - £${p.price}`,
            value: p,
            isObsolete: true,
            isDeleted: p.isDeleted,
          })
        }

        this.packageProducts = await SiteInvestigationController.GetProductsByPackage(this.state.selectedPackage.id)
        const pitSearch = this.packageProducts.find((p) => p.productTypeId === SIProductType.Pit)
        if (pitSearch) {
          this.pitProduct = pitSearch as Pit
        }
        this.state.packageIncludedTests = this.packageProducts.filter((p) => p.productTypeId === SIProductType.Test)
        // map isDeleted property for Pit and Pit's ProductChildLinks
        this.state.selectedPits.forEach((selectedPit) => {
          const pit: Product | undefined = this.packageProducts.find((p) => p.id === selectedPit.value.id)
          if (pit) {
            selectedPit.value.isDeleted = pit.isDeleted
            this.updatePitProductChildLinks(pit.productChildLinks!, selectedPit.value)
          }
        })
      }

      const listReferencedExtras: Product[] = []
      this.state.selectedOther.forEach((extra) => {
        let extraFromList = this.productSelectItems.find((dropdownExtra) => dropdownExtra.value.id === extra.id)
        if (!extraFromList) {
          extraFromList = {
            text: `${extra.name} - £${extra.basePrice}`,
            value: new Product(extra),
            isObsolete: true,
            isDeleted: extra.isDeleted,
          }
          this.productSelectItems.unshift(extraFromList)
        }
        extraFromList.value.quantity = extra.quantity
        // set value of isPriceUpdated flag
        extraFromList.value.isPriceUpdated = extra.basePrice !== extraFromList.value.basePrice ? true : false
        extraFromList.value.basePrice = extra.basePrice
        listReferencedExtras.push(extraFromList.value)
      })
      this.state.selectedOther = listReferencedExtras

      // set value of isPriceUpdated flag
      this.state.selectedMonitoringProducts.forEach((monitoringProduct) => {
        const productItem = this.monitoringProductItems.find((extra) => extra.value.id === monitoringProduct.id)
        monitoringProduct.isPriceUpdated =
          productItem && productItem.value.basePrice !== monitoringProduct.basePrice ? true : false
        monitoringProduct.productChildLinks!.forEach((productChildLink) => {
          const selectedChildProduct =
            monitoringProduct && monitoringProduct.selectedChildProducts
              ? monitoringProduct.selectedChildProducts.find((x) => x.id === productChildLink.id)
              : undefined
          if (selectedChildProduct) {
            productChildLink.isPriceUpdated =
              productChildLink.basePrice !== selectedChildProduct.basePrice ? true : false
            productChildLink.basePrice = selectedChildProduct
              ? selectedChildProduct.basePrice
              : productChildLink.basePrice
            productChildLink.quantity = selectedChildProduct.quantity
          }
        })
      })

      // set value of isPriceUpdated flag
      this.state.selectedBulkPits.forEach((bulkPit: BulkPit) => {
        const extraPit = this.extraPitSelectItems.find((extra) => extra.value.id === bulkPit.id)
        bulkPit.isPriceUpdated = extraPit && extraPit.value.basePrice !== bulkPit.basePrice ? true : false
      })
    }
  }

  private updateDropdownLists(newPackages: SitePackage[], newProducts: Product[]) {
    if (!this.state) {
      return
    }
    this.packageSelectItems = newPackages.map((p) => {
      return {
        text: `${p.name} - £${p.price}`,
        value: p,
        isObsolete: false,
        isDeleted: p.isDeleted,
      }
    })
    this.tempPackageSelectItems = JSON.parse(JSON.stringify(this.packageSelectItems))
    this.packageSelectItems.unshift({
      text: '--- No Package ---',
      value: null,
      isObsolete: false,
      isDeleted: false,
    })
    this.extraPitSelectItems = newProducts
      .filter(
        (product) =>
          (!product.isDeleted ||
            (product.isDeleted &&
              this.state &&
              this.state.selectedBulkPits.findIndex((i) => i.id === product.id) !== -1)) &&
          product.productTypeId === SIProductType.BulkPit
      )
      .map((pit) => {
        return {
          text: `${pit.name} - £${pit.basePrice}`,
          value: pit,
          isDeleted: pit.isDeleted,
        }
      })
    this.monitoringProductItems = newProducts
      .filter(
        (product) =>
          (!product.isDeleted ||
            (product.isDeleted &&
              this.state &&
              this.state.selectedMonitoringProducts.findIndex((m) => m.id === product.id) !== -1)) &&
          product.productTypeId === SIProductType.Monitoring
      )
      .map((pit) => {
        return {
          text: `${pit.name} - £${pit.basePrice}`,
          value: new MonitoringProduct(pit),
          isDeleted: pit.isDeleted,
        }
      })
    this.tempMonitoringProductItems = JSON.parse(JSON.stringify(this.monitoringProductItems))
    let productItems = newProducts.filter((product) =>
      this.isTestingOnlyJob
        ? product.productTypeId === SIProductType.TestingOnly
        : product.productTypeId === SIProductType.Other ||
          product.productTypeId === SIProductType.CCTV ||
          product.productTypeId === SIProductType.Datum ||
          product.productTypeId === SIProductType.WaterMains ||
          product.productTypeId === SIProductType.TestingOnly
    )
    productItems = productItems.filter(
      (p) =>
        !p.isDeleted || (p.isDeleted && this.state && this.state.selectedOther.findIndex((i) => i.id === p.id) !== -1)
    )
    this.productSelectItems = productItems.map((product) => {
      return {
        text: `${product.name} - £${product.basePrice}`,
        value: product,
        isObsolete: false,
        isDeleted: product.isDeleted,
      }
    })
    if (!this.state.selectedPackage) {
      this.state.selectedPackage = this.packageSelectItems[0].value
    }
    // map isDeleted property for Pit and Pit's ProductChildLinks
    this.state.selectedBulkPits.forEach((bulkPit: BulkPit) => {
      bulkPit.pits.forEach((pit) => {
        const product = newProducts.find((p) => p.id === pit.value.id)
        if (product) {
          pit.value.isDeleted = product.isDeleted
          this.updatePitProductChildLinks(product.productChildLinks!, pit!.value)
        }
      })
    })
  }

  private updatePitProductChildLinks(productChildLinks: Product[], product: Pit) {
    productChildLinks!.forEach((productChildLink) => {
      if (productChildLink.isDeleted) {
        const selectedPitTest = product.pitTests!.findIndex((p) => p.id === productChildLink.id)
        const selectedPitIndex = product.productChildLinks!.findIndex((p) => p.id === productChildLink.id)
        if (selectedPitIndex > -1) {
          if (selectedPitTest === -1) {
            product.productChildLinks!.splice(selectedPitIndex, 1)
          } else {
            product.productChildLinks![selectedPitIndex].isDeleted = true
          }
        }
      }
    })
  }

  private async changePackage() {
    if (!this.state) {
      return
    }
    this.isExtrasLoaded = false
    this.state.selectedPits = []
    this.pitProduct = null
    if (this.state.selectedPackage) {
      const search = this.tempPackageSelectItems.find(
        (item) => item.value != null && this.state!.selectedPackage!.id === item.value.id
      )
      if (search && search.value) {
        this.state.selectedPackage.isPriceUpdated = false
        this.state.selectedPackage.price = search.value.price
      }
      this.packageProducts = await SiteInvestigationController.GetProductsByPackage(this.state.selectedPackage.id)
      const pitSearch = this.packageProducts.find((p) => !p.isDeleted && p.productTypeId === SIProductType.Pit)
      if (pitSearch) {
        this.pitProduct = pitSearch as Pit
        this.addPit(pitSearch, this.state.selectedPits)
      }
      this.state.packageIncludedTests = this.packageProducts.filter((p) => p.productTypeId === SIProductType.Test)
    } else {
      this.packageProducts = []
      this.state.packageIncludedTests = []
    }
    this.isExtrasLoaded = true
  }

  private addPit(pitProduct: Product, pitList: Array<{ value: Pit }>) {
    if (!this.state || this.disabled) {
      return
    }
    if (pitList === this.state.selectedPits && this.reachedPitLimit()) {
      return
    }
    const pit: Pit = new Pit(pitProduct)
    pitList.push({ value: pit })
  }

  // add a bulk pit based on the given product "bulkProduct"
  private async addBulkPit(bulkProduct: Product) {
    if (!this.state || this.disabled) {
      return
    }
    const pitProduct = await SiteInvestigationController.GetProducts(
      this.claimManagementCompanyId,
      true,
      SIProductType.Pit
    ) // call once at the start regardless to reduce amount of calls
    const bulkPit: BulkPit = new BulkPit(bulkProduct)
    if (bulkProduct.productAttributes) {
      const mPAttr = bulkProduct.productAttributes.find((e) => e.attributeName === 'maxPits')
      if (mPAttr) {
        bulkPit.maxPits = Number(mPAttr.attributeValue)
      }
    } else {
      bulkPit.maxPits = 1
    }
    for (let x = 0; x < bulkPit.maxPits; x++) {
      let pitModel: Product | null = null
      if (bulkProduct.productChildLinks != null) {
        const bpProdChildren = bulkProduct.productChildLinks.filter((y) => y.productTypeId === SIProductType.Pit)
        if (bpProdChildren.length >= 1) {
          pitModel = bpProdChildren[0]
        }
      }
      let tmpPit: Pit
      if (pitModel != null) {
        tmpPit = new Pit(pitProduct.find((z) => z.id === pitModel!.id))
      } else {
        tmpPit = new Pit()
      }
      this.addPit(tmpPit, bulkPit.pits)
    }
    this.state.selectedBulkPits.push(bulkPit)
  }

  private addMonitoringProduct(input: { text: string; value: MonitoringProduct }) {
    if (!this.state || this.disabledMonitoringProduct) {
      return
    }
    const monitoringProduct: MonitoringProduct = new MonitoringProduct(input.value)
    this.state.selectedMonitoringProducts.push(monitoringProduct)
  }

  private deletePit(pitListItem: { value: Pit }, pitList: Array<{ value: Pit }>) {
    if (!this.state || this.disabled) {
      return
    }
    const i = pitList.indexOf(pitListItem)
    pitList.splice(i, 1)
  }

  private deleteBulkPit(bulkPitItem: BulkPit) {
    if (!this.state || this.disabled) {
      return
    }
    const i = this.state.selectedBulkPits.indexOf(bulkPitItem)
    this.state.selectedBulkPits.splice(i, 1)
  }

  private deleteProduct(product: MonitoringProduct) {
    if (!this.state || this.disabledMonitoringProduct) {
      return
    }
    const i = this.state.selectedMonitoringProducts.indexOf(product)
    this.state.selectedMonitoringProducts.splice(i, 1)
  }

  private reachedPitLimit() {
    if (!this.state) {
      return false
    }
    return (
      this.pitProduct &&
      this.pitProduct.productExtra &&
      this.pitProduct.productExtra.maxQuantity === this.state.selectedPits.length
    )
  }

  private extraIsObsolete(extra) {
    const item = this.productSelectItems.find((ex) => ex.value.id === extra.id)
    return !item || item.isObsolete
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private validationMessage(label: string) {
    if (!label) {
      return ''
    }
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' quantity is required.') : message
  }

  private get isEngineerVisitCreated() {
    const job = storeGetters.getJob(this.jobId)
    if (job && job.engineerVisitDetail.length > 0) {
      return true
    }
    return false
  }

  private openEditProductPriceDialog(item: number | BulkPit | Product) {
    if (typeof item === 'number') {
      this.productBasePrice = item
      this.selectedProductForPriceUpdate = item
    } else if (typeof item === 'object') {
      this.productBasePrice = item.basePrice
      this.selectedProductForPriceUpdate = item
    }
  }

  private onProductPriceUpdated(price: number) {
    if (typeof this.selectedProductForPriceUpdate === 'number') {
      this.state!.selectedPackage!.price = price
      this.state!.selectedPackage!.isPriceUpdated = true
    } else if (this.selectedProductForPriceUpdate && typeof this.selectedProductForPriceUpdate === 'object') {
      this.selectedProductForPriceUpdate.basePrice = price
      this.selectedProductForPriceUpdate.isPriceUpdated = true
    }
  }
}
</script>

<style scoped>
.checkbox-input-wrapper {
  position: relative;
}
.checkbox-input-mover {
  position: absolute;
  right: 0;
  top: 8px;
}
.price-label {
  background: none;
  bottom: 8px;
  left: 8px;
  pointer-events: none;
}
.obsolete-item {
  text-decoration: line-through;
}
.quantity-table >>> .v-input {
  padding-top: 0;
  margin-top: 0;
}
.quantity-table {
  width: 100%;
}
</style>
