<template>
  <v-card v-if="card" class="pt-0 px-2 pb-2">
    <v-card-title>
      <h3 class="grey--text text--darken-3">{{ card.title }}</h3>
    </v-card-title>
    <v-spacer></v-spacer>
    <v-card-text>
      <h4>{{ card.payload }}</h4>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import TimeLineItemBase from '@/components/TimeLineItemBase'
import TimelineCardModel from '@/models/general/timeline'
import { useTimelineStore } from '@/pinia/timeline'
import { Component } from 'vue-property-decorator'

@Component
export default class AutoDeploymentCardPreview extends TimeLineItemBase {
  public get card(): TimelineCardModel {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card || this.defaultCard
  }

  public defaultCard: TimelineCardModel = {
    id: '',
    payload: 'Autodeployment Active Example',
    jobId: '',
    title: 'Autodeployment is active',
    createdAt: new Date(),
    type: 'AutoDeploymentCreated',
  }
}
</script>
