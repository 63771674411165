<template>
  <div class="dashboard">
    <v-layout wrap>
      <v-flex xs12>
        <v-container fluid grid-list-lg pt-0 pa-0>
          <v-layout wrap po-info visit-info>
            <v-flex xs12>
              <v-container fluid grid-list-lg pt-0 pa-0>
                <v-layout wrap po-info visit-info>
                  <DashboardTopTile
                    v-for="key in Object.keys(getTopTiles)"
                    ref="topTileBars"
                    :key="key"
                    :active-filter-color="tileColorWithActiveFilter === getTopTiles[key].backgroundColor ? true : false"
                    v-bind="getTopTiles[key]"
                    dashboard-tile-view="FinancialSummary"
                    @onTopTileFilter="onTopTileFilter"
                  />
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 class="gridView mt-4">
        <v-layout wrap>
          <v-flex xs4 visit-info>
            <h3>
              {{
                activeFilterValue === contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.Pending]
                  ? 'Ready For Approvals'
                  : activeFilterValue === contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.ToReview]
                  ? 'Awaiting Review from CET'
                  : activeFilterValue === contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.Disputed]
                  ? 'Disputed'
                  : activeFilterValue ===
                    contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.ReadyForInvoice]
                  ? 'Ready for Invoice'
                  : 'Outstanding'
              }}
              Visits
            </h3>
          </v-flex>
          <v-flex v-if="visitFinancialSummaryData.length > 0" class="text-xs-right search-controls">
            <div class="search-contractor">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                :disabled="isLoading"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <template v-if="contractorPolicies.updateFinancial">
              <v-btn
                v-if="activeFilterValue === contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.Pending]"
                color="primary"
                :disabled="isLoading"
                class="ma-0 ml-2 mt-2 btn-approve-invoice"
                @click.native="openConfirmationForVisitApprove()"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="activeFilterValue === contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.Pending]"
                color="primary"
                :disabled="isLoading"
                class="ma-0 ml-2 mt-2"
                @click="openConfirmationForVisitDispute()"
              >
                Dispute
              </v-btn>
              <v-btn
                v-if="activeFilterValue === contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.Disputed]"
                color="primary"
                class="ma-0 ml-2 mt-2"
                :disabled="isLoading"
                @click="onConfirmDisputingVisit"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="activeFilterValue === contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.ToReview]"
                color="primary"
                class="ma-0 ml-2 mt-2"
                :disabled="isLoading"
                @click="openConfirmationForVisitToReview"
              >
                Review Complete
              </v-btn>
              <v-btn
                v-if="
                  activeFilterValue ===
                    contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.ReadyForInvoice] &&
                  canViewFinance &&
                  selectedVisitList.length > 0
                "
                ref="export-button"
                slot="activator"
                color="primary"
                class="ma-0 ml-2 mt-2"
                :disabled="isLoading"
                @click="openExportToExcelDialog"
              >
                <v-icon class="md-icon">get_app</v-icon>
                &nbsp; Export
              </v-btn>
            </template>
          </v-flex>
          <v-flex xs12 mt-2 class="elevation-1">
            <div class="invoice-list">
              <v-data-table
                v-model="selectedVisitList"
                :headers="headers"
                :items="visitFinancialSummaryData"
                :pagination.sync="pagination"
                :loading="isLoading"
                item-key="contractorInvoiceDetailId"
                :select-all="hasRightForMarking"
                class="invoice-table"
                :search="search"
                :custom-filter="customFilter"
              >
                <v-progress-linear slot="progress" :color="tileColorWithActiveFilter" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr :active="props.selected" @click="props.expanded = !props.expanded">
                    <td v-if="hasRightForMarking">
                      <v-checkbox v-model="props.selected" color="primary" hide-details></v-checkbox>
                    </td>
                    <td>
                      <b>
                        <a
                          href="javascript:void(0)"
                          class="secondary--text action-btn"
                          @click.stop="
                            openContractorJobDetailModal(props.item.jobId, props.item.contractorAppointedDetailIds)
                          "
                        >
                          {{ props.item.jobId }}
                        </a>
                      </b>
                      <span v-if="props.item.clientNotes && props.item.clientNotes.length > 0" class="pl-2">
                        <v-tooltip right content-class="yellow lighten-2 grey--text text--darken-4 body-2">
                          <template #activator="{ on }">
                            <v-icon class="icon-arrow md-icon" v-on="on">info</v-icon>
                          </template>
                          <span>
                            <div v-for="(note, index) in props.item.clientNotes" :key="index">
                              <h3 class="mb-2">Note {{ index + 1 }}:</h3>
                              <p class="mb-2">- {{ note }}</p>
                            </div>
                          </span>
                        </v-tooltip>
                      </span>
                    </td>
                    <td>{{ props.item.address }}</td>
                    <td>{{ getTradeDescription(props.item.trades) }}</td>
                    <td class="text-right">
                      <b>{{ getFormatedCurrency(props.item.materialCost) }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ getFormatedCurrency(props.item.labourCost) }}</b>
                    </td>
                    <td class="text-right">
                      <span>
                        {{ getFormatedCurrency(props.item.additionalCharge) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span v-if="!props.item.overrideTotalCost">
                        {{
                          getFormatedCurrency(
                            props.item.materialCost + props.item.labourCost + props.item.additionalCharge
                          )
                        }}
                      </span>
                      <span v-else>
                        <v-tooltip v-if="props.item.materialCost || props.item.labourCost" right>
                          <template #activator="{ on }">
                            <v-icon class="ml-2 md-icon" v-on="on">info</v-icon>
                          </template>
                          <span>
                            Auto calculated amount was
                            {{ getFormatedCurrency(props.item.materialCost + props.item.labourCost) }}
                          </span>
                        </v-tooltip>
                        {{ getFormatedCurrency(props.item.overrideTotalCost) }}
                      </span>
                    </td>
                    <td class="text-xs-center">{{ props.item.visitCount }}</td>
                    <td
                      v-if="
                        activeFilterValue ===
                        contractorFinancialSummaryWidget[contractorFinancialSummaryWidget.Disputed]
                      "
                      class="text-xs-center"
                    >
                      {{ props.item.disputedReason }}
                    </td>
                  </tr>
                </template>
                <template slot="expand" slot-scope="props">
                  <v-card v-if="props.item.contractorInvoiceSummary" flat elevation-0>
                    <v-card-text
                      v-for="(invoiceSummary, index) in props.item.contractorInvoiceSummary"
                      :key="index"
                      class="lighten-4 pb-1 engineer-visit-detail"
                    >
                      <div class="mb-2">
                        <v-layout>
                          <v-flex>
                            <span class="mt-2 ml-0 d-inline-block title-visit">
                              <b>Visit {{ index + 1 }}:</b>
                              &nbsp;
                              <label>
                                <b>Start Time:</b>
                                ({{ getFormatedDate(invoiceSummary.visitStartedAt) }})
                              </label>
                              &nbsp; &nbsp;
                              <label>
                                <b>End Time:</b>
                                ({{ getFormatedDate(invoiceSummary.visitCompletedAt) }})
                              </label>
                              &nbsp; &nbsp;
                              <label v-if="invoiceSummary.visitCompletedBy">
                                <b>Attend By:</b>
                                {{ invoiceSummary.visitCompletedBy }}
                              </label>
                              &nbsp; &nbsp;
                              <label>
                                <b>Travel Time:</b>
                                <v-tooltip
                                  v-if="
                                    invoiceSummary.additionalCharge &&
                                    invoiceSummary.additionalCharge > 0 &&
                                    invoiceSummary.isInCoverageArea
                                  "
                                  right
                                >
                                  <template #activator="{ on }">
                                    <v-icon class="ml-2 md-icon" v-on="on">info</v-icon>
                                  </template>
                                  <span>Requested travel time in coverage area</span>
                                </v-tooltip>
                                <span
                                  :class="
                                    invoiceSummary.additionalCharge > 0 && invoiceSummary.isInCoverageArea
                                      ? 'red--text'
                                      : ''
                                  "
                                >
                                  {{ getFormatedTravelTime(invoiceSummary.additionalTravelTime) }}
                                  ({{ getFormatedCurrency(invoiceSummary.additionalCharge) }})
                                </span>
                              </label>
                              &nbsp;
                            </span>
                          </v-flex>
                          <v-flex text-xs-right visit-total>
                            <h3 class="ma-2 title-visit">
                              <b>Visit Total:</b>
                              &nbsp;
                              <b class="secondary--text">
                                {{ getFormatedCurrency(invoiceSummary.totalVisitCharge) }}
                              </b>
                            </h3>
                          </v-flex>
                        </v-layout>
                        <v-layout
                          v-if="
                            invoiceSummary.distanceToCustomerLocation ||
                            (invoiceSummary.engineerOnTheWayAt && invoiceSummary.engineerOnSiteAt)
                          "
                          wrap
                          pl-5
                          ml-2
                        >
                          <v-flex pl-1>
                            <span class="mb-2 ml-0 d-inline-block title-visit">
                              <label v-if="invoiceSummary.distanceToCustomerLocation">
                                <b>Travel Distance:</b>
                                {{ invoiceSummary.distanceToCustomerLocation }}
                                KM
                              </label>
                              &nbsp;
                              <label v-if="invoiceSummary.engineerOnTheWayAt && invoiceSummary.engineerOnSiteAt">
                                <b>Travel Time:</b>
                                {{ getTravelTime(invoiceSummary.engineerOnTheWayAt, invoiceSummary.engineerOnSiteAt) }}
                              </label>
                              &nbsp; &nbsp;
                            </span>
                          </v-flex>
                        </v-layout>
                        <v-layout px-2 pt-2 pb-0 grey lighten-3 wrap>
                          <v-flex pa-2>
                            <v-layout wrap fill-height>
                              <v-flex outer-col-height>
                                <v-card-title class="pa-0 secondary--text">
                                  <h3>Labour</h3>
                                  <v-spacer></v-spacer>
                                  <b class="secondary--text material-price">
                                    {{ getFormatedCurrency(invoiceSummary.labourCost) }}
                                  </b>
                                </v-card-title>
                                <v-layout wrap grey lighten-2 pa-2 equal-hegiht item-list-content>
                                  <v-flex xs12 mb-2>
                                    <v-card-title class="pa-0">
                                      {{ invoiceSummary.canChargeCallout ? 'Callout Rate' : 'Follow-On Rate' }}
                                      &nbsp;
                                      <b>({{ calculateMinutes(minimumCallOutMinutes) }})</b>
                                      <!-- <v-spacer></v-spacer> <b class="material-price"> {{getFormatedCurrency(30)}}</b> -->
                                    </v-card-title>
                                    <v-divider class="mt-1"></v-divider>
                                  </v-flex>
                                  <v-flex
                                    v-if="
                                      invoiceSummary.additionalAuthorisedMinutes &&
                                      invoiceSummary.additionalAuthorisedMinutes > 0
                                    "
                                    xs12
                                  >
                                    <v-card-title class="pa-0">
                                      Additional Minutes &nbsp;
                                      <b>({{ calculateMinutes(invoiceSummary.additionalAuthorisedMinutes) }})</b>
                                      <!-- <v-spacer></v-spacer> <b class="material-price"> {{getFormatedCurrency(30)}}</b> -->
                                    </v-card-title>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 pa-2>
                            <v-layout wrap fill-height>
                              <v-flex outer-col-height>
                                <v-card-title class="pa-0 secondary--text">
                                  <h3>Material</h3>
                                  <v-spacer></v-spacer>
                                  <b class="secondary--text material-price">
                                    {{
                                      getFormatedCurrency(getSumOfMaterialCost(invoiceSummary.engineerVisitMaterials))
                                    }}
                                  </b>
                                </v-card-title>
                                <v-layout wrap grey lighten-2 pa-2 equal-hegiht item-list-content>
                                  <div v-if="invoiceSummary.engineerVisitMaterials.length > 0">
                                    <v-flex
                                      v-for="(material, key) in invoiceSummary.engineerVisitMaterials"
                                      :key="key"
                                      xs12
                                      mb-1
                                    >
                                      <v-card-title class="pa-0">
                                        {{ material.materialName }}
                                        <v-spacer></v-spacer>
                                        <b class="material-price">
                                          {{ getFormatedCurrency(material.baseCost) }}
                                        </b>
                                      </v-card-title>
                                      <v-divider class="mt-1"></v-divider>
                                    </v-flex>
                                  </div>
                                  <div v-else>No data available</div>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-card v-else flat elevation-0 class="px-0">
                    <v-card-text class="grey lighten-4 pb-1 pb-3">
                      <b>No Data Found</b>
                    </v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <div v-if="isLoading" class="loader-content"></div>
    </v-layout>
    <v-dialog
      v-if="contractorJobDetailDialog"
      v-model="contractorJobDetailDialog"
      max-width="1000"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card class="shadow-none">
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            <span v-if="contractorJobDetails.length > 0 && contractorJobDetails[0].jobDetail">
              Job Id: {{ contractorJobDetails[0].jobDetail.jobId }} -
              {{ contractorJobDetails[0].getAddress(',') }}
            </span>
            <span v-else>Contractor Job Detail</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="contractorJobDetailDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="grey lighten-3 job-detail-dlog-content scroll-content-dialog">
          <v-layout mt-0 class="scoll-vb-content">
            <v-flex xs12>
              <v-progress-circular
                v-if="totalEmergenciesLogged !== contractorJobDetails.length"
                :width="2"
                :size="50"
                indeterminate
                color="primary"
                :style="getLoaderStyle(70)"
              ></v-progress-circular>
              <div v-if="totalEmergenciesLogged === 1 && contractorJobDetails[0]">
                <ContractorJobDetail
                  v-if="contractorJobDetails[0]"
                  :parent-component="'ContractorFinancialSummary'"
                  :contractor-job-detail-item="contractorJobDetails[0]"
                  :engineers="engineers"
                ></ContractorJobDetail>
              </div>
              <div v-else style="position: relative; display: block">
                <v-tabs
                  v-model="childTabIndex"
                  color="grey lighten-4"
                  light
                  class="jobdetail-tabs"
                  slider-color="white"
                >
                  <v-tab v-for="(contractorJobDetail, i) in contractorJobDetails" :key="i" :href="`#child-tab-${i}`">
                    {{ contractorJobDetail.emergencyDetail.tradeDescription }}
                  </v-tab>
                  <v-tab-item
                    v-for="(contractorJobDetail, j) in contractorJobDetails"
                    :key="j"
                    :value="`child-tab-${j}`"
                  >
                    <ContractorJobDetail
                      v-if="childTabIndex === 'child-tab-' + j"
                      :parent-component="'ContractorFinancialSummary'"
                      :contractor-job-detail-item="contractorJobDetail"
                      :engineers="engineers"
                    ></ContractorJobDetail>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog - dispute job -->
    <v-dialog
      v-if="showDisputeDialog"
      v-model="showDisputeDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Dispute Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDisputeDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-4 pt-4">
          <v-layout wrap>
            <v-flex xs12 pr-2>
              <v-text-field
                v-model="disputeReason"
                v-validate="'required'"
                label="Dispute Reason"
                required
                class="required"
                data-vv-scope="jobDisputeReason"
                data-vv-name="Dispute Reason"
                :error-messages="errors.collect('Dispute Reason')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showDisputeDialog = false">Close</v-btn>
          <v-btn
            color="primary"
            :loading="onSubmitLoading"
            :disabled="onSubmitLoading"
            class="mr-0"
            @click.native="disputeVisits"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <!-- export to excel dialog -->
    <v-dialog
      v-if="exportToExcelDialog && canViewFinance && selectedVisitList.length > 0"
      v-model="exportToExcelDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Export To Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="exportToExcelDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 pt-0 scroll-content-dialog">
          <v-container grid-list-xl class="pa-0">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="exportName" label="Export Name" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="exportToExcelDialog = false">Cancel</v-btn>
          <export-excel
            class="btn ml-2"
            :export-fields="exportToExcelHeaderFields"
            :fetch="exportContractorPO"
            worksheet="Contractor Purchase Order Details"
            :before-generate="startExportGeneration"
            :before-finish="stopExportGeneration"
            type="xls"
            name="contractorPOList.xls"
          >
            <v-btn ref="ExportToExcel" color="primary" :loading="exportToExcelLoader" :disabled="exportToExcelLoader">
              <v-icon class="md-icon">get_app</v-icon>
              &nbsp; Export
            </v-btn>
          </export-excel>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /export to excel dialog -->
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import Chart from 'chart.js'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import ContractorJobDetail from '@/components/ContractorJobDetail.vue'
import ContractorController from '@/api/contractorController'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import Shared from '@/common/shared'
import { ContractorFinancialSummaryWidget, ContractorInvoiceStatus } from '@/common/enums'
import ContractorFinancialSummaryWidgetCount from '@/models/ContractorFinancialSummaryWidgetCount'
import DashboardController from '@/api/dashboardController'
import ContractorFinancialSummaryDataModel from '@/models/claim/ContractorFinancialSummaryDataModel'
import ContractorFinancialSummaryModel from '@/models/claim/ContractorFinancialSummaryModel'
import DisputeContractorInvoiceModel, { DisputeContractorJob } from '@/models/claim/DisputeContractorInvoiceModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import PolicyHolderController from '@/api/policyHolderController'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import moment, { Moment } from 'moment'
import Store from '@/store'
import EngineerModel from '@/models/contractor/EngineerModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import excel from 'vue-excel-export'
import eventBus from '@/common/bus'
import ContractorAuthPolicies from '@/models/auth/contractor-auth-policies'

@Component({
  components: { ContractorJobDetail, DashboardTopTile, DateTimePicker, excel },
})
export default class ContractorFinancialSummary extends Vue {
  @Prop() private contractorId: string
  @Prop() private isVisitSummaryDataLoading: boolean
  @Prop() private engineers: EngineerModel[]
  private isLoading = false
  private search = ''
  private pagination: any = {}
  private headers: any = []
  private selectedVisitList: ContractorFinancialSummaryModel[] = []
  private contractorJobDetailDialog = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private contractorFinancialSummaryWidget = ContractorFinancialSummaryWidget
  private defaultTableHeaderColor = 'blue white--text'
  private tileColorWithActiveFilter: string = this.defaultTableHeaderColor
  private contractorFinancialSummaryWidgetCount: ContractorFinancialSummaryWidgetCount | null = null
  private visitFinancialSummaryData: ContractorFinancialSummaryModel[] = []
  private trades: TradeModel[] = []
  private activeFilterValue = ''
  private childTabIndex = 0
  private contractorJobDetails: ContractorJobDetailModel[] = []
  private contractorJobDetailLoading = false
  private totalEmergenciesLogged = 0
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('contractorFinancialSummary')
  private showDisputeDialog = false
  private disputeReason = ''
  private onSubmitLoading = false
  private disputedReasonTableHeader: any = {
    text: 'Dispute Reason',
    value: 'disputedReason',
    align: 'center',
    class: this.defaultTableHeaderColor,
  }
  private contractorInvoiceStatus = ContractorInvoiceStatus
  private minimumCallOutMinutes = 60
  private exportToExcelDialog = false
  private exportToExcelHeaderFields: any = {
    Name: 'name',
    'Job ID': 'job_id',
    Address: 'address',
    Trades: 'trades',
    'Material Cost': 'material_cost',
    'Labour Cost': 'labour_cost',
    'Travel Cost': 'travel_cost',
    'Total Cost': 'total_cost',
    'Number of Visits': 'num_visits',
  }
  private exportToExcelLoader = false
  private exportToExcelData: any = []
  private exportName = ''

  @Watch('contractorId')
  private async onselectedContractorIdChange() {
    await this.signalRHub.changeHubNameAndReconnect('contractorFinancialSummary' + this.contractorId.replace(/-/g, ''))
    this.activeFilterValue = this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending]
    const index = this.headers.findIndex((x) => x.value === 'disputedReason')
    if (index !== -1) {
      this.headers.splice(index, 1)
    }
    this.tileColorWithActiveFilter = this.defaultTableHeaderColor
    setTimeout(() => {
      this.setSelectAllCheckboxStyle()
    }, 0)
    // apply updated color to header
    this.headers.forEach((header) => {
      header.class = this.tileColorWithActiveFilter
    })
    const values = 'values'
    const topTileData = Object[values](this.getTopTiles)
    for (let i = 0; i < topTileData.length; i++) {
      const element = topTileData[i]
      element.isFilterActive = i === 0 ? true : false
    }
    this.getVisitList()
    this.selectedVisitList = []
  }

  private created() {
    this.getTrades()
    const headerColor: string = this.defaultTableHeaderColor
    this.headers = [
      { text: 'Job Number', value: 'jobId', class: headerColor },
      { text: 'Address', value: 'address', align: 'left', class: headerColor },
      { text: 'Trade', value: 'trades', align: 'left', class: headerColor },
      {
        text: 'Material Cost',
        value: 'materialCost',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Labour Cost',
        value: 'labourCost',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Travel Cost',
        value: 'additionalCharge',
        align: 'right',
        class: headerColor,
      },
      { text: 'Total Cost', value: '', align: 'right', class: headerColor },
      {
        text: 'Number of Visits',
        value: 'visitCount',
        align: 'center',
        class: headerColor,
      },
    ]
    this.pagination.rowsPerPage = Shared.rowsPerPageDefault
    // get visit list
    this.getVisitList()
    // handle signalR
    this.handleSignalR()
    Vue.use(excel)
  }

  private get contractorPolicies(): ContractorAuthPolicies {
    return this.$store.getters['authModule/contractors']
  }

  private get canViewFinance(): boolean {
    return (this.$store.getters['authModule/contractors'] as ContractorAuthPolicies).readFinancial
  }

  private openExportToExcelDialog() {
    this.exportToExcelDialog = true
  }

  private startExportGeneration() {
    this.exportToExcelLoader = true
  }

  private stopExportGeneration() {
    this.exportToExcelLoader = false
  }

  private async exportContractorPO() {
    const self = this
    if (this.selectedVisitList && this.selectedVisitList.length > 0) {
      let excelData: any = {}
      this.exportToExcelData = []
      this.selectedVisitList.forEach((element) => {
        excelData = {
          name: this.exportName,
          job_id: element.jobId,
          address: element.address,
          trades: element.trades,
          material_cost: element.materialCost,
          labour_cost: element.labourCost,
          travel_cost: element.additionalCharge,
          total_cost: element.totalCost,
          num_visits: element.visitCount,
        }
        self.exportToExcelData.push(excelData)
      })
      this.exportToExcelDialog = false
      this.snackbar = true
      this.snackbarText = 'Data Exported Successfully.'
      return self.exportToExcelData
    } else {
      this.exportToExcelData = []
      this.snackbar = true
      this.snackbarText = 'No Data Available.'
      this.stopExportGeneration()
      return this.exportToExcelData
    }
  }

  private handleSignalR() {
    // signalR on disputing a visit
    this.signalRHub.addHandler(
      'disputeVisit',
      (
        contractorId: string,
        contractorInvoiceDetailId: string,
        rejectListOfJobs: string[],
        updatedWidgetCounts: ContractorFinancialSummaryWidgetCount
      ) => {
        if (contractorId === this.contractorId) {
          if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending]
          ) {
            // if same filter activated, remove selected jobs
            rejectListOfJobs.forEach((jobId) => {
              const selectedJob = this.visitFinancialSummaryData.find(
                (x: ContractorFinancialSummaryModel) =>
                  x.jobId === jobId && x.contractorInvoiceDetailId === contractorInvoiceDetailId
              )
              if (selectedJob) {
                this.visitFinancialSummaryData.splice(this.visitFinancialSummaryData.indexOf(selectedJob), 1)
              }
            })
          } else if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Disputed]
          ) {
            // if invoice filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(rejectListOfJobs, contractorInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
        }
      }
    )

    // signalR on visit ready for approvals and visit ready for Invoice
    this.signalRHub.addHandler(
      'updateContractorInvoice',
      async (
        contractorId: string,
        contractorInvoiceDetailId: string,
        jobId: string,
        widgetCounts: ContractorFinancialSummaryWidgetCount,
        status: string
      ) => {
        if (contractorId === this.contractorId) {
          if (
            (this.activeFilterValue ===
              this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending] &&
              status === this.contractorInvoiceStatus[this.contractorInvoiceStatus.Pending]) ||
            (this.activeFilterValue ===
              this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ToReview] &&
              status === this.contractorInvoiceStatus[this.contractorInvoiceStatus.ToReview])
          ) {
            // active filter is set on Visits Ready For Invoice tile or visit first time fx or ToReview, update count and change data in grid.
            this.getJobsFinancialDetails([jobId], contractorInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR(widgetCounts)
        }
      }
    )

    // signalr on confirm/approve dispute contractor invoices
    this.signalRHub.addHandler(
      'confirmDisputeContractorInvoice',
      (
        contractorId: string,
        contractorInvoiceDetailId: string,
        rejectListOfJobs: string[],
        updatedWidgetCounts: ContractorFinancialSummaryWidgetCount
      ) => {
        if (contractorId === this.contractorId) {
          if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Disputed]
          ) {
            // if same filter activated, remove selected visits
            rejectListOfJobs.forEach((jobId) => {
              const selectedJob = this.visitFinancialSummaryData.find(
                (x: ContractorFinancialSummaryModel) =>
                  x.jobId === jobId && x.contractorInvoiceDetailId === contractorInvoiceDetailId
              )
              if (selectedJob) {
                this.visitFinancialSummaryData.splice(this.visitFinancialSummaryData.indexOf(selectedJob), 1)
              }
            })
          } else if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending]
          ) {
            // if pending visit filter activated, get and push visits to the list
            this.getJobsFinancialDetails(rejectListOfJobs, contractorInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
        }
      }
    )

    // signalR on complete contractor invoice review
    this.signalRHub.addHandler(
      'completeContractorReview',
      (
        contractorId: string,
        contractorInvoiceDetailId: string,
        rejectListOfJobs: string[],
        updatedWidgetCounts: ContractorFinancialSummaryWidgetCount
      ) => {
        if (contractorId === this.contractorId) {
          if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ToReview]
          ) {
            // if same filter activated, remove selected jobs
            rejectListOfJobs.forEach((jobId) => {
              const selectedJob = this.visitFinancialSummaryData.find(
                (x: ContractorFinancialSummaryModel) =>
                  x.jobId === jobId && x.contractorInvoiceDetailId === contractorInvoiceDetailId
              )
              if (selectedJob) {
                this.visitFinancialSummaryData.splice(this.visitFinancialSummaryData.indexOf(selectedJob), 1)
              }
            })
          } else if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending]
          ) {
            // if pending visit filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(rejectListOfJobs, contractorInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
        }
      }
    )

    // signalR on approve contractor invoice
    this.signalRHub.addHandler(
      'approveContractorInvoice',
      (
        contractorId: string,
        contractorInvoiceDetailId: string,
        listOfApprovedJobs: string[],
        updatedWidgetCounts: ContractorFinancialSummaryWidgetCount
      ) => {
        if (contractorId === this.contractorId) {
          if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending]
          ) {
            // if Pending visit filter activated, remove selected jobs
            listOfApprovedJobs.forEach((jobId) => {
              const selectedJobIndex = this.visitFinancialSummaryData.findIndex(
                (x: ContractorFinancialSummaryModel) =>
                  x.jobId === jobId && x.contractorInvoiceDetailId === contractorInvoiceDetailId
              )
              if (selectedJobIndex !== -1) {
                this.visitFinancialSummaryData.splice(selectedJobIndex, 1)
              }
            })
          } else if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ReadyForInvoice]
          ) {
            // if ReadyForInvoice filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(listOfApprovedJobs, contractorInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
        }
      }
    )

    // connect signalR
    this.signalRHub.connect()
  }

  private updateDataInGrid(data: ContractorFinancialSummaryModel, index: number) {
    if (index === -1) {
      // add new jobId data to grid
      this.visitFinancialSummaryData.push(data)
    } else {
      // replace existing jobId data
      this.visitFinancialSummaryData[index] = data
      this.visitFinancialSummaryData = [...this.visitFinancialSummaryData]
    }
  }

  private updateTileDataOnSignalR(updatedWidgetCounts: ContractorFinancialSummaryWidgetCount) {
    this.contractorFinancialSummaryWidgetCount = updatedWidgetCounts
  }

  private getJobsFinancialDetails(jobsList: string[], contractorInvoiceDetailId = '') {
    const jobList: DisputeContractorJob[] = []
    jobList.push({ jobId: jobsList[0], invoiceId: '' })

    const disputeContractorInvoice: DisputeContractorInvoiceModel = new DisputeContractorInvoiceModel()
    disputeContractorInvoice.contractorId = this.contractorId
    disputeContractorInvoice.disputeContractorJobs = jobList
    DashboardController.GetContractorFinancialSummary(disputeContractorInvoice)
      .then((res: ContractorFinancialSummaryModel[]) => {
        if (res) {
          const job = res.find((r) => r.contractorInvoiceDetailId === contractorInvoiceDetailId)
          if (job) {
            const index = this.visitFinancialSummaryData.findIndex(
              (x: ContractorFinancialSummaryModel) =>
                x.jobId === job.jobId && x.contractorInvoiceDetailId === contractorInvoiceDetailId
            )
            this.updateDataInGrid(job, index)
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary data, please try again', true)
      })
  }

  private destroyed() {
    this.signalRHub.disconnect()
  }

  private mounted() {
    this.activeFilterValue = this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending]
    setTimeout(() => {
      this.setSelectAllCheckboxStyle()
    }, 0)
  }

  private getTrades() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.trades = res.filter((e) => e.jobType === 'HE')
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.trades = trades.filter((e) => e.jobType === 'HE')
    }
  }

  private getVisitList() {
    this.isLoading = true
    this.$emit('update:isVisitSummaryDataLoading', this.isLoading)
    const self = this
    DashboardController.GetContractorFinancialSummaryData(this.contractorId)
      .then((res: ContractorFinancialSummaryDataModel | null) => {
        if (res) {
          self.visitFinancialSummaryData = res.contractorInvoices
          self.contractorFinancialSummaryWidgetCount = res.widgetCounts
        }
        this.isLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.isLoading)
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary data, please try again', true)
        this.isLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.isLoading)
      })
  }

  private getTradeDescription(tradeIds: number[]) {
    const tradesDescription: string[] = []
    tradeIds.forEach((tradeId) => {
      const trade: TradeModel | undefined = this.trades.find((e) => e.tradeId === tradeId)
      if (trade) {
        tradesDescription.push(trade.description)
      }
    })
    return tradesDescription.join(', ')
  }

  private getVisitListWithFilter(filterValue: string) {
    this.isLoading = true
    this.$emit('update:isVisitSummaryDataLoading', this.isLoading)
    DashboardController.GetContractorFinancialSummaryWidgetData(this.contractorId, filterValue)
      .then((res: ContractorFinancialSummaryModel[] | null) => {
        if (res) {
          this.visitFinancialSummaryData = []
          this.visitFinancialSummaryData = res
          this.activeFilterValue = filterValue
          // remove the disputed Reason header from the table
          const index = this.headers.findIndex((x) => x.value === 'disputedReason')
          if (
            this.activeFilterValue ===
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Disputed]
          ) {
            if (!(index !== -1)) {
              this.headers.push(this.disputedReasonTableHeader)
            }
          } else {
            if (index !== -1) {
              this.headers.splice(index, 1)
            }
          }
          // apply updated color to header
          this.headers.forEach((header) => {
            header.class = this.tileColorWithActiveFilter
          })
          if (
            this.activeFilterValue !==
            this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ReadyForInvoice]
          ) {
            this.selectedVisitList = []
            setTimeout(() => {
              this.setSelectAllCheckboxStyle()
            }, 0)
          }
          this.pagination.page = 1
          this.isLoading = false
          this.$emit('update:isVisitSummaryDataLoading', this.isLoading)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary widget data, please try again', true)
        this.isLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.isLoading)
      })
  }

  private openContractorJobDetailModal(jobId: string, contractorAppointedDetailIds: string[]) {
    const self = this
    this.totalEmergenciesLogged = contractorAppointedDetailIds.length
    self.contractorJobDetails = []
    self.contractorJobDetailDialog = true
    self.contractorJobDetailLoading = true
    for (const id of contractorAppointedDetailIds) {
      ContractorController.GetContractorJobDetailWithEmergencyDefinition(jobId, id)
        .then((res: ContractorJobDetailModel | null) => {
          if (res) {
            self.contractorJobDetails.push(res)
          }
          self.contractorJobDetailLoading = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading contractor jobDetail, please try again', false)
          this.snackbarText = 'Error in loading contractor jobDetail!'
          this.snackbar = true
          this.contractorJobDetailLoading = false
          self.contractorJobDetailDialog = false
        })
    }
  }

  private setSelectAllCheckboxStyle() {
    const el: HTMLElement | null = this.$el.querySelector('.v-datatable--select-all thead tr th')
    if (el) {
      el.className = this.tileColorWithActiveFilter ? this.tileColorWithActiveFilter : this.defaultTableHeaderColor
    }
  }

  private contractorJobDetailDialogClose(): void {
    this.contractorJobDetailDialog = false
  }

  private onTopTileFilter(color: string) {
    if (this.isLoading) {
      return
    }
    const values = 'values'
    let elementName = ''
    Object[values](this.getTopTiles).forEach((element) => {
      if (element.backgroundColor === color) {
        if (element.isFilterActive) {
          element.isFilterActive = false
          this.tileColorWithActiveFilter = ''
        } else {
          element.isFilterActive = true
          this.tileColorWithActiveFilter = color + ' white--text'
          elementName = element.name as string
        }
      } else {
        element.isFilterActive = false
      }
    })
    // bind new visit list as per selected tile
    if (elementName) {
      this.getVisitListWithFilter(elementName)
    }
  }

  private openConfirmationForVisitDispute() {
    if (this.selectedVisitList.length === 0) {
      this.snackbarText = 'Please select visit(s) to mark as dispute.'
      this.snackbar = true
      return
    }
    this.disputeReason = ''
    this.showDisputeDialog = true
    // Shared.confirmationPopup.open('Do you want to mark selected visit(s) as dispute?', '', '', 'No', 'Yes', this, 'disputeVisits', '');
  }

  private onConfirmDisputingVisit() {
    if (this.selectedVisitList.length === 0) {
      this.snackbarText = 'Please select visit(s) to confirm dispute.'
      this.snackbar = true
      return
    }
    Shared.confirmationPopup.open(
      'Do you want to confirm dispute of selected visit(s)?',
      '',
      '',
      'No',
      'Yes',
      this,
      'confirmDisputeContractorInvoice',
      ''
    )
  }

  private confirmDisputeContractorInvoice() {
    this.onSubmitLoading = true
    this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
    const disputeContractorInvoice = this.getListOfJobsToBeProcessed()
    DashboardController.ConfirmDisputeContractorInvoices(disputeContractorInvoice)
      .then((res: boolean) => {
        if (res) {
          this.snackbarText = 'selected disputed visit(s) confirmed'
          this.snackbar = true
        }
        this.selectedVisitList = []
        this.onSubmitLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting conformed disputed invoice(s), please try again', true)
        this.selectedVisitList = []
        this.onSubmitLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
      })
  }

  private openConfirmationForVisitToReview() {
    if (this.selectedVisitList.length === 0) {
      this.snackbarText = 'Please select visit(s) to complete the review.'
      this.snackbar = true
      return
    }
    Shared.confirmationPopup.open(
      'Do you want to complete the review of selected visit(s)?',
      '',
      '',
      'No',
      'Yes',
      this,
      'reviewCompleteVisits',
      ''
    )
  }

  private reviewCompleteVisits() {
    this.onSubmitLoading = true
    this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
    const disputeContractorInvoice = this.getListOfJobsToBeProcessed()
    DashboardController.CompleteContractorReview(disputeContractorInvoice)
      .then((res: boolean) => {
        if (res) {
          this.snackbarText = 'selected job(s) reviewed.'
          this.snackbar = true
        }
        this.selectedVisitList = []
        this.onSubmitLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting reviewed job(s), please try again', true)
        this.selectedVisitList = []
        this.onSubmitLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
      })
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('jobDisputeReason')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private disputeVisits() {
    const self = this
    self.validate().then((result: boolean) => {
      if (result) {
        this.onSubmitLoading = true
        this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
        const disputeContractorInvoice = this.getListOfJobsToBeProcessed()
        disputeContractorInvoice.disputedReason = this.disputeReason
        DashboardController.DisputeContractorInvoices(disputeContractorInvoice)
          .then((res: boolean) => {
            if (res) {
              this.snackbarText = 'selected visit(s) disputed'
              this.snackbar = true
              this.showDisputeDialog = false
            }
            this.selectedVisitList = []
            this.onSubmitLoading = false
            this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error submitting disputed invoice(s), please try again', true)
            this.selectedVisitList = []
            this.onSubmitLoading = false
            this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
          })
      }
    })
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private customFilter(items, searchValue, filter) {
    if (searchValue !== '') {
      searchValue = searchValue.toString().toLowerCase()
      return items.filter(
        (row) =>
          filter(row.jobId, searchValue) ||
          filter(row.address, searchValue) ||
          filter(row.trades ? this.getTradeDescription(row.trades) : '', searchValue) ||
          filter(row.materialCost, searchValue) ||
          filter(row.labourCost, searchValue) ||
          filter(row.visitCount, searchValue) ||
          filter(row.additionalCharge, searchValue)
      )
    } else {
      return items
    }
  }

  private get getTopTiles(): { [k: string]: TopTileProps } {
    if (this.contractorFinancialSummaryWidgetCount === null) {
      return {}
    }
    return {
      visitReadyForApprovals: {
        title: 'Ready For Approvals',
        value: this.contractorFinancialSummaryWidgetCount.totalPendingVisit,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: Shared.colorBlue,
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        name: this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending],
        isFilterActive:
          this.activeFilterValue ===
          this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Pending]
            ? true
            : false,
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of visits ready for approvals',
            value:
              this.contractorFinancialSummaryWidgetCount.totalPendingVisit != null
                ? this.contractorFinancialSummaryWidgetCount.totalPendingVisit
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.contractorFinancialSummaryWidgetCount.totalPendingVisitCost != null
                ? this.contractorFinancialSummaryWidgetCount.totalPendingVisitCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      visitsReadyForReview: {
        title: 'Awaiting Review from CET',
        value: this.contractorFinancialSummaryWidgetCount.totalVisitInToReview,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: 'purple day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        name: this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ToReview],
        isFilterActive:
          this.activeFilterValue ===
          this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ToReview]
            ? true
            : false,
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of visits ready to review',
            value:
              this.contractorFinancialSummaryWidgetCount.totalVisitInToReview != null
                ? this.contractorFinancialSummaryWidgetCount.totalVisitInToReview
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.contractorFinancialSummaryWidgetCount.totalVisitInToReviewCost != null
                ? this.contractorFinancialSummaryWidgetCount.totalVisitInToReviewCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      disputedVisits: {
        title: 'Disputed',
        value: this.contractorFinancialSummaryWidgetCount.totalDisputedVisit,
        showChart: false,
        chartData: [],
        backgroundColor: Shared.colorRed,
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.activeFilterValue ===
          this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Disputed]
            ? true
            : false,
        name: this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.Disputed],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of visits disputed',
            value:
              this.contractorFinancialSummaryWidgetCount.totalDisputedVisit != null
                ? this.contractorFinancialSummaryWidgetCount.totalDisputedVisit
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.contractorFinancialSummaryWidgetCount.totalDisputedVisitCost != null
                ? this.contractorFinancialSummaryWidgetCount.totalDisputedVisitCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      visitReadyForInvoice: {
        title: 'Ready for Invoice',
        value: this.contractorFinancialSummaryWidgetCount.totalVisitReadyForInvoice,
        showChart: false,
        chartData: [],
        backgroundColor: Shared.colorOrange,
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.activeFilterValue ===
          this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ReadyForInvoice]
            ? true
            : false,
        name: this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ReadyForInvoice],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of visits ready for Invoice',
            value:
              this.contractorFinancialSummaryWidgetCount.totalVisitReadyForInvoice != null
                ? this.contractorFinancialSummaryWidgetCount.totalVisitReadyForInvoice
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.contractorFinancialSummaryWidgetCount.totalVisitReadyForInvoiceCost != null
                ? this.contractorFinancialSummaryWidgetCount.totalVisitReadyForInvoiceCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
    }
  }

  private getFormatedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getSumOfMaterialCost(values: any) {
    let sum = 0
    values.forEach((element) => {
      sum += element.baseCost
    })
    return sum
  }

  private calculateMinutes(second: number): string {
    return Shared.formatDuration(second)
  }

  private getTravelTime(engineerOnTheWayAt: Moment | null, engineerOnSiteAt: Moment | null): string {
    if (engineerOnTheWayAt !== null && engineerOnSiteAt !== null) {
      const startDate = moment(engineerOnTheWayAt)
      const endDate = moment(engineerOnSiteAt)
      const duration = moment.duration(endDate.diff(startDate))
      const seconds = duration.seconds()
      const minutes = duration.minutes()
      const hours = duration.hours()
      const days = duration.days()
      if (days > 0) {
        return days + ' Days ' + (hours > 0 ? hours + ' Hours ' : '') + (minutes > 0 ? minutes + ' Minutes' : '')
      } else if (hours > 0) {
        return hours + ' Hours ' + (minutes > 0 ? minutes + ' Minutes' : '')
      } else if (minutes > 0) {
        return minutes + ' Minutes ' + (seconds > 0 ? seconds + ' Seconds' : '')
      } else {
        return seconds + ' Seconds'
      }
    }
    return ''
  }

  private selectableDateTime(date: moment.Moment | null): string {
    if (date) {
      return moment(date).format(DateTimePicker.DATE_FORMAT)
    }
    return ''
  }

  private dateFormat(): string {
    return Store.Instance.state.Environment.DateFormat
  }

  private getFormatedTravelTime(travelTime: string) {
    return Shared.formatTimeSpanToString(travelTime)
  }

  private openConfirmationForVisitApprove() {
    if (this.selectedVisitList.length === 0) {
      this.snackbarText = 'Please select visit(s) to approve.'
      this.snackbar = true
      return
    }
    Shared.confirmationPopup.open(
      'Do you want to approve selected visit(s)?',
      '',
      '',
      'No',
      'Yes',
      this,
      'confirmApproveForContractorInvoice',
      ''
    )
  }

  private confirmApproveForContractorInvoice() {
    this.onSubmitLoading = true
    this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
    const disputeContractorInvoice = this.getListOfJobsToBeProcessed()
    DashboardController.ApproveForContractorInvoice(disputeContractorInvoice)
      .then((res: boolean) => {
        if (res) {
          this.snackbarText = 'selected visit(s) approved.'
          this.snackbar = true
        }
        this.selectedVisitList = []
        this.onSubmitLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
      })
      .catch((err: any) => {
        this.selectedVisitList = []
        this.onSubmitLoading = false
        this.$emit('update:isVisitSummaryDataLoading', this.onSubmitLoading)
      })
  }

  private get hasRightForMarking() {
    return (
      this.contractorPolicies.updateFinancial &&
      this.activeFilterValue !==
        this.contractorFinancialSummaryWidget[this.contractorFinancialSummaryWidget.ReadyForInvoice]
    )
  }

  private getListOfJobsToBeProcessed(): DisputeContractorInvoiceModel {
    const listOfJobsModel: DisputeContractorInvoiceModel = new DisputeContractorInvoiceModel()
    listOfJobsModel.contractorId = this.contractorId

    const jobList: DisputeContractorJob[] = []
    this.selectedVisitList.forEach((selectedJob) => {
      const job = this.visitFinancialSummaryData.find((e) => e.jobId === selectedJob.jobId)
      if (job) {
        jobList.push({
          jobId: selectedJob.jobId,
          invoiceId: selectedJob.contractorInvoiceDetailId,
        })
      }
    })
    listOfJobsModel.disputeContractorJobs = jobList

    return listOfJobsModel
  }
}
</script>

<style scoped>
.po-info >>> h2 {
  font-size: 38px;
  display: inline-block;
  vertical-align: top;
}

.po-info.visit-info >>> .card {
  height: 78px !important;
  display: flex;
  flex-wrap: wrap;
}

.po-info.visit-info >>> .card .card__text {
  display: none;
  align-self: flex-end;
}

.po-info.visit-info >>> .card .card__title {
  align-self: flex-start;
}

@media only screen and (max-width: 1200px) {
  .po-info >>> h2 {
    font-size: 30px;
  }

  .po-info.visit-info >>> .card {
    height: 100px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .po-info.visit-info >>> .card .card__title {
    font-size: 16px;
  }
}

.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}

.gridView {
  border: 0px;
}

.gridView >>> thead th:nth-child(3) {
  min-width: 200px;
}

.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
}

.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}

.invoice-table >>> .table thead > tr > th:nth-last-child(1) {
  min-width: 208px;
  text-align: center !important;
}

.invoice-table >>> .table thead > tr > th:nth-child(3) {
  min-width: 300px;
}

.invoice-table >>> .v-datatable thead tr.v-datatable__progress th.column {
  padding: 0px 0px !important;
}

.invoice-table >>> .v-datatable__expand-col {
  padding: 0px !important;
}

.text-right {
  text-align: right;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.jobdetail-tabs >>> .v-tabs__div .v-tabs__item--active {
  background-color: #fff !important;
  font-weight: 600;
  border-top: 3px solid #cf1e60;
  color: #cf1e60;
}

.job-detail-dlog-content {
  min-height: 600px;
}

.invoice-list >>> tr.datatable__progress th {
  padding: 0px !important;
}

.action-btn {
  margin: 0;
}

.action-btn {
  font-weight: 600;
  padding: 0;
}

.loader-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #fff;
  z-index: 205;
  opacity: 0.2;
}

.icon-arrow {
  position: relative;
}

.equal-hegiht {
  height: calc(100% - 20px);
  display: block;
}

.material-price {
  position: absolute;
  right: 0px;
  top: 0px;
}

.engineer-visit-detail >>> .v-card__title {
  position: relative;
  padding-right: 100px !important;
}

.item-list-content >>> .flex {
  align-self: flex-start;
}

.visit-total {
  width: 170px;
}

.search-controls >>> .btn {
  vertical-align: top;
}

.search-contractor {
  display: inline-block;
  min-width: 450px;
  margin-right: 15px;
}
</style>
