<template>
  <div>
    <v-flex>
      <v-expansion-panel>
        <v-expansion-panel-content v-for="(setting, index) in cookieRecord.settings" :key="`CookieSetting-${index}`">
          <template #header>
            <h4 class="text-uppercase">{{ generateSettingHeader(index) }}</h4>
          </template>
          <div class="cookie-setting">
            <span v-if="isPlaceholder(setting.id)" class="cookie-setting__placeholder green--text font-weight-bold">
              PLACEHOLDER
            </span>
            <v-tooltip v-else class="cookie-setting__placeholder" top>
              <template #activator="{ on }">
                <v-btn icon flat color="primary" small v-on="on" @click="removeSetting(index)">
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>Remove Setting</span>
            </v-tooltip>
            <v-layout row wrap class="pa-3">
              <v-flex xs6 pl-2>
                <v-text-field
                  v-validate="'required'"
                  label="Cookie Setting Name"
                  hint="The name of the setting"
                  :value="setting.id"
                  required
                  :data-vv-name="`cookieSettingId ${index + 1}`"
                  :data-vv-scope="validationScope"
                  :error-messages="errors.collect(`cookieSettingId ${index + 1}`)"
                  class="mb-3"
                  @input="setCookieRecordSettings($event, index, 'id')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field
                  v-validate="'required'"
                  label="Description"
                  hint="A brief description about the setting"
                  :value="setting.description"
                  required
                  :data-vv-name="`cookieSettingDescription ${index + 1}`"
                  :data-vv-scope="validationScope"
                  :error-messages="errors.collect(`cookieSettingDescription ${index + 1}`)"
                  class="mb-3"
                  @input="setCookieRecordSettings($event, index, 'description')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pl-2>
                <v-textarea
                  label="Default Value (Optional - Not needed for placeholders)"
                  hint="Optional default value"
                  :value="setting.defaultValue"
                  class="mb-3"
                  @input="setCookieRecordSettings($event, index, 'defaultValue')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-flex>
    <v-flex xs12>
      <v-btn @click="addSetting()">Add Setting</v-btn>
    </v-flex>
  </div>
</template>

<script lang="ts">
import CookieRecord from '@/models/cookies/cookie-record'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CookieConfigurationSettings extends Vue {
  @Prop({ default: '' })
  public validationScope: string

  private get cookieRecord(): CookieRecord {
    return this.$store.getters['cookieModule/cookieRecord']
  }

  public async validateAll(): Promise<boolean> {
    return await this.$validator.validateAll(this.validationScope)
  }

  private addSetting() {
    this.$store.dispatch('cookieModule/submitNewCookieSetting')
  }

  private removeSetting(index: number) {
    this.$store.dispatch('cookieModule/submitRemoveCookieSetting', index)
  }

  private generateSettingHeader(index: number) {
    const id = this.cookieRecord.settings[index].id
    return !id || !id.length ? `New setting: ${index + 1}` : id
  }

  private get placeholders(): string[] {
    return this.$store.getters['cookieModule/cookiePlaceholders']
  }

  private isPlaceholder(settingId: string): boolean {
    return this.placeholders.some((ph) => ph === settingId)
  }

  private setCookieRecordSettings(value: any, index: number, fieldName: string) {
    this.$store.dispatch('cookieModule/submitUpdateCookieRecordSettings', {
      value,
      index,
      fieldName,
    })
  }
}
</script>

<style lang="scss">
.cookie-setting {
  position: relative;
  margin-bottom: 5px;
  padding: 5px;

  &__placeholder {
    position: absolute;
    top: -5px;
    right: 15px;
  }
}
</style>
