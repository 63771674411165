<template>
  <div v-if="ready" :id="tableName" class="dashboard outstanding-complaint-table">
    <v-layout v-if="complaints && complaints.length > 0" wrap grid-view>
      <v-flex xs12 request-info>
        <v-card-title class="pa-0">
          <v-text-field
            v-model="searchComplaint"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            class="outstanding-search"
          ></v-text-field>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                v-if="showExportButton"
                color="primary"
                class="mr-0 mb-0"
                @click="openExportToExcelDialog"
                v-on="on"
              >
                <v-icon class="md-icon">get_app</v-icon>
                &nbsp; Export
              </v-btn>
            </template>
            <span>Export To Excel</span>
          </v-tooltip>
        </v-card-title>
      </v-flex>
      <v-flex v-bar="{ useScrollbarPseudo: true }" xs12 mt-2 class="elevation-1">
        <div class="complaint-list">
          <v-data-table
            :headers="complaintListHeaders"
            :items="complaints"
            :search="searchComplaint"
            :loading="loadingComplaintList"
            class="complaint-table"
            :pagination.sync="pagination"
            :custom-sort="customSort"
          >
            <template slot="complaintListHeaders" slot-scope="props">
              <tr>
                <th
                  v-for="header in props.complaintListHeaders"
                  :key="header.text"
                  :class="[
                    'column sortable',
                    pagination.descending ? 'desc' : 'asc',
                    header.value === pagination.sortBy ? 'active' : '',
                  ]"
                  @click="changeSort(header.value)"
                >
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template slot="items" slot-scope="props">
              <tr
                :active="props.selected"
                :class="isComplaintOverdue(props.item) ? 'red lighten-5' : ''"
                @click="props.selected = !props.selected"
              >
                <td>
                  <a class="secondary--text" @click="redirectToJob(props.item)">
                    {{ props.item.jobId }} {{ props.item.indexCount }}
                  </a>
                </td>
                <td>{{ props.item.policyName }}</td>
                <td>{{ props.item.complaintTypeDescription ? props.item.complaintTypeDescription : '-' }}</td>
                <td>{{ toCommaSeparatedList(props.item.concernsRaisedDescription) }}</td>
                <td>
                  {{ props.item.severity }} &nbsp;
                  <span :class="severityTypeTextStyle(props.item.severity)">
                    ({{ severityTypeText(props.item.severity) }})
                  </span>
                </td>
                <td>{{ getVulnerabilityStatus(props.item.vulnerabilityStatus) }}</td>
                <td>
                  <v-autocomplete
                    v-model="props.item.responsiblePerson"
                    :items="userList"
                    item-text="displayValue"
                    item-value="id"
                    label="Who is responsible"
                    return-object
                    :disabled="!canUpdateComplaintResponsiblePerson"
                    hide-details
                    @change="updateComplaintResponsiblePerson($event, props.item.jobId, props.item.id)"
                  ></v-autocomplete>
                </td>
                <complaint-table-letter-icon
                  v-for="type in letterTypes"
                  :key="type"
                  :complaint="props.item"
                  :letter-type="type"
                />
                <td>{{ getFormattedDateTime(props.item.dateOfResolution) }}</td>
                <td>
                  {{
                    props.item.receivedDate
                      ? getFormattedDateTime(props.item.receivedDate)
                      : getFormattedDateTime(props.item.createdAt)
                  }}
                </td>
                <td>{{ props.item.compensationPaid > 0 ? `£${props.item.compensationPaid}` : `` }}</td>
                <td>{{ getFormattedDateTime(props.item.reopenedDate) }}</td>
                <td>{{ calculateAge(props.item.createdAt) }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-flex>
      <PartialJobView
        :job-id="selectedJobIdToExpand"
        :record-id="complainId"
        :record-type="'complaint'"
        @closeJobView="closeJobView"
      ></PartialJobView>
    </v-layout>

    <!-- export to excel dialog -->
    <v-dialog
      v-if="exportToExcelDialog"
      v-model="exportToExcelDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Export To Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="exportToExcelDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 pt-0 scroll-content-dialog">
          <v-container grid-list-xl class="pa-0">
            <v-layout wrap>
              <v-flex date-content xs6>
                <DateTimePicker
                  ref="exportComplaintsFromDate"
                  :date-time.sync="exportComplaintsFrom"
                  :is-static-location="false"
                  place-holder-text="From"
                  :is-current-time="false"
                  :is-validation-required="true"
                  :show-present-time-icon="false"
                  :max-date="selectableDateTime(exportComplaintsTo)"
                  :hide-time-picker="true"
                  :display-format="dateFormat()"
                />
              </v-flex>
              <v-flex date-content xs6>
                <DateTimePicker
                  ref="exportComplaintsToDate"
                  :date-time.sync="exportComplaintsTo"
                  :is-static-location="false"
                  place-holder-text="To"
                  :is-current-time="false"
                  :is-validation-required="true"
                  :show-present-time-icon="false"
                  :min-date="selectableDateTime(exportComplaintsFrom)"
                  :max-date="maxComplaintExportDate"
                  :hide-time-picker="true"
                  :display-format="dateFormat()"
                />
              </v-flex>
              <v-flex xs12>
                <v-select v-model="selectedStatus" label="Complaint Status" :items="exportComplaintsStatuses" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="exportToExcelDialog = false">Cancel</v-btn>
          <export-excel
            class="btn ml-2"
            :export-fields="exportToExcelHeaderFields"
            :fetch="exportComplaints"
            worksheet="Complaint Details"
            :before-generate="startExportGeneration"
            :before-finish="stopExportGeneration"
            type="xls"
            name="complaintList.xls"
          >
            <v-btn ref="ExportToExcel" color="primary" :loading="exportToExcelLoader" :disabled="exportToExcelLoader">
              <v-icon class="md-icon">get_app</v-icon>
              &nbsp; Export
            </v-btn>
          </export-excel>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import moment, { Moment } from 'moment'
import Store from '@/store'
import ComplaintModel from '@/models/claim/ComplaintModel'
import UserModel from '@/models/user/UserModel'
import storeGetters from '@/storeGetters'
import DashboardController from '@/api/dashboardController'
import DateTimePicker from '@/components/DateTimePicker.vue'
import excel from 'vue-excel-export'
import PartialJobView from '@/components/PartialJobView.vue'
import ComplaintTableLetterIcon from '@/components/ComplaintTableLetterIcon.vue'
import eventBus from '@/common/bus'
import { VulnerabilityLevelEnum } from '@/common/enums'
import ComplaintAuthPolicies from '@/models/auth/complaint-auth-policies'

const letterTypes = [
  'initialResponseLetter',
  'fourWeekLetter',
  'eightWeekLetter',
  'summaryResolutionCommunication',
  'finalResponseLetter',
]

@Component({
  name: 'complaintTableComponent',
  components: { PartialJobView, DateTimePicker, ComplaintTableLetterIcon, excel },
})
export default class OutstandingComplaintsTable extends Vue {
  @Prop() private complaints: ComplaintModel[]
  @Prop() private loadingComplaintList: boolean
  @Prop() private ready: boolean
  @Prop() private tableName: string

  private searchComplaint = ''
  private complaintListHeaders: any = []
  private pagination: any = {}
  private selectedJobIdToExpand = ''
  private openJobView = false
  private complainId = ''
  private exportToExcelDialog = false
  private exportComplaintsFrom: any = null
  private exportComplaintsTo: any = null
  private exportComplaintsStatuses: any[] = ['All', 'Outstanding']
  private selectedStatus = 'All'
  private maxComplaintExportDate = moment().format(DateTimePicker.DATE_FORMAT)
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private exportToExcelHeaderFields: any = {}
  private exportToExcelLoader = false
  private exportToExcelData: any = []
  private canExportConplaints = false
  private letterTypes = letterTypes

  private get userList(): UserModel[] {
    const userList = storeGetters.getSkilledComplaintResolutionUsers()
    if (userList && !userList.find((e) => e.id === null)) {
      userList.splice(0, 0, this.getDefaultResponsibleUserOption())
    }
    return userList
  }

  private get complaintPolicies(): ComplaintAuthPolicies {
    return this.$store.getters['authModule/complaints']
  }

  private get showExportButton(): boolean {
    return this.complaintPolicies.export
  }

  private get canUpdateComplaintResponsiblePerson(): boolean {
    return this.complaintPolicies.changeResponsiblePerson
  }

  private getDefaultResponsibleUserOption(): UserModel {
    const user = new UserModel()
    user.id = ''
    user.firstName = 'Unassigned'
    user.lastName = ''
    return user
  }

  private isComplaintOverdue(complaint: ComplaintModel) {
    return Shared.isComplaintOverdue(complaint)
  }

  private getImageUrl(userId: string): string {
    if (userId) {
      const user = this.userList.find((e) => e.id === userId)
      if (user) {
        return user.profileThumbnailUrlWithUnknownFallback
      }
    }
    return '/img/unknownuser.png'
  }

  private customSort(items, index, isDesc) {
    items.sort((a: ComplaintModel, b: ComplaintModel) => {
      const letterPriority = (due: keyof ComplaintModel, sent: keyof ComplaintModel) => {
        const statusA = this.letterStatus(a[due] as Moment | null, a[sent] as Moment | null)
        const statusB = this.letterStatus(b[due] as Moment | null, b[sent] as Moment | null)
        const priorityA = statusA === 'overdue' ? 3 : statusA === 'pending' ? 2 : statusA === 'sent' ? 1 : 0
        const priorityB = statusB === 'overdue' ? 3 : statusB === 'pending' ? 2 : statusB === 'sent' ? 1 : 0
        if (priorityA !== priorityB) {
          return Shared.compare(priorityB, priorityA, isDesc)
        } else {
          return Shared.compare(a[due], b[due], isDesc)
        }
      }

      if (index === 'age') {
        return Shared.compare(b.createdAt, a.createdAt, isDesc)
      } else if (index === 'responsiblePerson') {
        return Shared.compare(a.responsiblePersonName, b.responsiblePersonName, isDesc)
      } else if (index === 'severity') {
        return Shared.compare(Number(a.severity), Number(b.severity), isDesc)
      } else if (index === 'initialResponseLetterDate') {
        return letterPriority('initialResponseLetterDate', 'initialResponseLetterSent')
      } else if (index === 'fourWeekLetterDate') {
        return letterPriority('fourWeekLetterDate', 'fourWeekLetterSent')
      } else if (index === 'eightWeekLetterDate') {
        return letterPriority('eightWeekLetterDate', 'eightWeekLetterSent')
      } else if (index === 'summaryResolutionCommunicationDate') {
        return letterPriority('summaryResolutionCommunicationDate', 'summaryResolutionCommunicationSent')
      } else if (index === 'finalResponseLetterDate') {
        return letterPriority('finalResponseLetterDate', 'finalResponseLetterSent')
      } else {
        return Shared.compare(a[index], b[index], isDesc)
      }
    })
    return items
  }

  private severityTypeText(severity: string) {
    if (severity === '1' || severity === '2' || severity === '3') {
      return 'Customer Issue'
    } else if (severity === '4' || severity === '5' || severity === '6') {
      return 'Reviewed by supervisor'
    } else if (severity === '7' || severity === '8' || severity === '9' || severity === '10') {
      return 'Formal Complaint'
    }
    return ''
  }

  private severityTypeTextStyle(severity: string) {
    if (severity === '1' || severity === '2' || severity === '3') {
      return 'grey--text'
    } else if (severity === '4' || severity === '5' || severity === '6') {
      return 'orange--text'
    } else if (severity === '7' || severity === '8' || severity === '9' || severity === '10') {
      return 'red--text'
    }
    return 'grey--text'
  }

  private getFormattedDateTime(date: moment.Moment | null | undefined) {
    if (date) {
      return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
    } else {
      return ''
    }
  }

  private getFormattedDate(date: moment.Moment | null | undefined) {
    if (date) {
      return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
    } else {
      return ''
    }
  }

  private calculateAge(date: moment.Moment) {
    const age = moment(date).fromNow(true)
    return age
  }

  private redirectToJob(complaintDetail: ComplaintModel) {
    if (!Shared.isUserHasJobRights(complaintDetail.jobId)) {
      return
    }
    // open timeline with relative card open.
    this.selectedJobIdToExpand = complaintDetail.jobId
    this.complainId = complaintDetail.id
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }

  private letterStatus(letterDue: Moment | null, letterSent: Moment | null): 'sent' | 'pending' | 'overdue' | 'unset' {
    if (!letterDue || !letterDue.isValid()) {
      return 'unset'
    }
    if (letterSent && letterSent.isValid()) {
      return 'sent'
    }
    if (letterDue && moment(letterDue) < moment.utc().startOf('date')) {
      return 'overdue'
    }
    return 'pending'
  }

  private openExportToExcelDialog() {
    this.exportComplaintsFrom = moment(new Date()).subtract(1, 'months')
    this.exportComplaintsTo = moment(new Date())
    this.selectedStatus = 'All'
    this.exportToExcelDialog = true
  }

  private selectableDateTime(date: moment.Moment | null): string {
    if (date) {
      return moment(date).format(DateTimePicker.DATE_FORMAT)
    }
    return ''
  }

  private dateFormat(): string {
    return Store.Instance.state.Environment.DateFormat
  }

  private startExportGeneration() {
    this.exportToExcelLoader = true
    this.initializeColumnsForExcel()
  }

  private stopExportGeneration() {
    this.exportToExcelLoader = false
  }

  private initializeColumnsForExcel() {
    this.exportToExcelHeaderFields = {
      Id: 'complaint_id',
      'Lead No': 'lead_number',
      'Date Received': 'date_logged',
      'Logged By': 'logged_by',
      'Company Name': 'company_compname',
      Trade: 'trade',
      'Policy Number': 'policy_number',
      'Policy Division': 'policy_division',
      Postcode: 'postcode',
      'Root Cause': 'root_cause',
      'Nature Of Dissatisfaction': 'nature_of_dissatisfaction',
      Supplier: 'supplier',
      'Dissatisfaction Code': 'dissatisfaction_code',
      'Dissatisfaction Detail': 'dissatisfaction_detail',
      'Concerns Raised': 'concerns_raised',
      'Complaint Status': 'complaint_Status',
      'Notice Sent?': 'notice_sent',
      'Compensation By Cheque?': 'comp_cheque',
      'Escalated To Insurer': 'escalated_to_insurer',

      'Initial Response Letter Due': 'date_initial_response_letter_due',
      'Initial Response Letter Sent': 'date_initial_response_letter_sent',
      'Four Week Letter Due': 'date_four_week_letter_due',
      'Four Week Letter Sent': 'date_four_week_letter_sent',
      'Eight Week Letter Due': 'date_eight_week_letter_due',
      'Eight Week Letter Sent': 'date_eight_week_letter_sent',
      'Summary Resolution Communication Due': 'date_summary_resolution_communication_due',
      'Summary Resolution Communication Sent': 'date_summary_resolution_communication_sent',
      'Final Response Letter Due': 'date_final_response_letter_due',
      'Final Response Letter Sent': 'date_final_response_letter_sent',

      'Resolved by User': 'resolved_by_user',
      'Date Resolved': 'date_resolved',
      'Vulnerable Customer': 'vulnerable_customer',
      'Severity of Complaint': 'severity_of_complaint',
      Justified: 'justified',
      'Escalation Reason': 'reason_escalation',
      'Escalation Date': 'date_escalation',
      'Compensation Paid': 'compensation_paid',
      'Reopened Date': 'reopened_date',
    }
  }

  private async exportComplaints() {
    const self = this
    const generateExcelWithAllStatus: boolean = this.selectedStatus === 'All' ? true : false
    const res = await DashboardController.GenerateExcelForComplaint(
      this.exportComplaintsFrom.toISOString(),
      this.exportComplaintsTo.toISOString(),
      generateExcelWithAllStatus
    )
    if (res && res.length > 0) {
      let excelData: any = {}
      this.exportToExcelData = []
      res.forEach((element) => {
        excelData = {
          complaint_id: element.id,
          lead_number: element.jobId,
          date_logged: element.receivedDate
            ? this.getFormattedDateTime(element.receivedDate)
            : element.createdAt
            ? this.getFormattedDateTime(element.createdAt)
            : '',
          logged_by: element.loggedByUserName,
          company_compname: element.insurerName,
          trade: element.assignedTrades,
          policy_number: element.policyNumber,
          policy_division: element.policyName,
          postcode: element.postCode,
          root_cause: element.rootCauseDescription,
          nature_of_dissatisfaction: element.complaintTypeDescription,
          supplier: element.assignedContractors,
          dissatisfaction_code: element.resolutionDescription,
          dissatisfaction_detail: element.complaintSummary,
          concerns_raised: element.concernsRaisedDescription,
          complaint_Status: element.isResolved ? 'Resolved' : 'Pending',
          notice_sent: element.isNoticeSent === 'Yes' ? 'Yes' : 'No',
          comp_cheque: element.isCompensationByCheque ? 'Yes' : 'No',
          escalated_to_insurer: element.escalationRequired === 'Yes' ? 'Yes' : 'No',

          date_initial_response_letter_due: this.getFormattedDate(element.initialResponseLetterDate),
          date_initial_response_letter_sent: this.getFormattedDate(element.initialResponseLetterSent),
          date_four_week_letter_due: this.getFormattedDate(element.fourWeekLetterDate),
          date_four_week_letter_sent: this.getFormattedDate(element.fourWeekLetterSent),
          date_eight_week_letter_due: this.getFormattedDate(element.eightWeekLetterDate),
          date_eight_week_letter_sent: this.getFormattedDate(element.eightWeekLetterSent),
          date_summary_resolution_communication_due: this.getFormattedDate(element.summaryResolutionCommunicationDate),
          date_summary_resolution_communication_sent: this.getFormattedDate(element.summaryResolutionCommunicationSent),
          date_final_response_letter_due: this.getFormattedDate(element.finalResponseLetterDate),
          date_final_response_letter_sent: this.getFormattedDate(element.finalResponseLetterSent),

          resolved_by_user: element.responsiblePerson ? this.getResponsiblePersonName(element.responsiblePerson) : '',
          date_resolved: element.resolvedDate ? this.getFormattedDateTime(element.resolvedDate) : '',
          vulnerable_customer: element.isVulnerableCustomer ? 'Yes' : 'No',
          severity_of_complaint:
            element.severity +
            ' - ' +
            this.severityTypeText(element.severity) +
            ' - ' +
            this.severityPriorityText(element.severity),
          justified: element.justified === '-' ? '' : element.justified,
          reason_escalation: element.escalationReason ? element.escalationReason : '-',
          date_escalation: element.escalationDate ? this.getFormattedDateTime(element.escalationDate) : '-',
          compensation_paid: element.compensationPaid > 0 ? '£' + element.compensationPaid : '',
          reopened_date: element.reopenedDate ? this.getFormattedDateTime(element.reopenedDate) : '-',
        }
        self.exportToExcelData.push(excelData)
      })
      this.exportToExcelDialog = false
      this.snackbar = true
      this.snackbarText = 'Data Exported Successfully.'
      return self.exportToExcelData
    } else {
      this.exportToExcelData = []
      this.snackbar = true
      this.snackbarText = 'No Data Available.'
      this.stopExportGeneration()
      return this.exportToExcelData
    }
  }

  private severityPriorityText(severity: string) {
    if (severity === '1' || severity === '2' || severity === '3') {
      return 'Low'
    } else if (severity === '4' || severity === '5' || severity === '6') {
      return 'Medium'
    } else if (severity === '7' || severity === '8' || severity === '9' || severity === '10') {
      return 'High'
    }
    return ''
  }

  private updateComplaintResponsiblePerson(user: UserModel, jobId: string, complaintId: string) {
    const responsiblePersonId: string = user.id === '' ? '' : user.id
    DashboardController.UpdateComplaintResponsiblePerson(complaintId, jobId, responsiblePersonId)
      .then((res: boolean) => {
        if (res) {
          this.snackbar = true
          this.snackbarText = 'Responsible person updated successfully.'
        } else {
          this.snackbar = true
          this.snackbarText = 'Please try again.'
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating complaint detail, please try again', true)
      })
  }

  private getResponsiblePersonName(userId: string) {
    const userDetails: UserModel | undefined = this.userList.find((x) => x.id === userId)
    if (userDetails) {
      return userDetails.firstName + ' ' + userDetails.lastName
    }
    return '-'
  }

  private getComplaintByIndex() {
    const jobIdWithCount = this.complaints
      .map((e) => e.jobId)
      .reduce((jobId, value) => {
        jobId[value] = jobId[value] === undefined ? 1 : (jobId[value] += 1)
        return jobId
      }, {})
    for (let index = 0; index <= Object.keys(jobIdWithCount).length; index++) {
      const totalCount: any = Object.values(jobIdWithCount)[index]
      if (totalCount > 1) {
        let counter = 1
        const complaints = this.complaints.filter((e) => e.jobId === Object.keys(jobIdWithCount)[index])
        complaints.forEach((c: any) => {
          c.indexCount = '(' + counter++ + ' of ' + totalCount + ')'
        })
      }
    }
  }

  private toCommaSeparatedList(array: string[] | undefined): string {
    if (array) {
      return array.join(', ')
    }
    return ''
  }

  @Watch('complaints')
  private onComplaintListChange() {
    this.getComplaintByIndex()
  }

  private async created() {
    this.complaintListHeaders = [
      { text: 'JobNo', value: 'jobId', sortable: true },
      { text: 'Client', value: 'policyName', align: 'left', class: 'date-col' },
      { text: 'Type', value: 'complaintTypeDescription', align: 'left' },
      { text: 'Concerns Raised', value: 'concernsRaised', align: 'left' },
      { text: 'Severity', value: 'severity', align: 'left', class: 'severity-col' },
      { text: 'Vulnerability', value: 'vulnerabilityStatus', align: 'left' },
      { text: 'Responsible Person', value: 'responsiblePerson', align: 'left' },
      { text: 'IRL', value: 'initialResponseLetterDate' },
      { text: '4WL', value: 'fourWeekLetterDate' },
      { text: '8WL', value: 'eightWeekLetterDate' },
      { text: 'SRC', value: 'summaryResolutionCommunicationDate' },
      { text: 'FRL', value: 'finalResponseLetterDate' },
      { text: 'Resolution Date', value: 'dateOfResolution', align: 'left' },
      { text: 'Received At', value: 'createdAt', align: 'left', class: 'date-col' },
      { text: 'Compensation Paid', value: 'compensationPaid', align: 'left' },
      { text: 'Reopened', value: 'reopenedDate', align: 'left', class: 'date-col' },
      { text: 'Age', value: 'age', align: 'left', class: 'date-col' },
    ]
    this.pagination.rowsPerPage = Shared.rowsPerPageDefault
    Vue.use(excel)
  }

  private mounted() {
    this.getComplaintByIndex()
  }

  private destroyed() {
    Shared.passJobIdInHeader()
  }

  private getVulnerabilityStatus(level: VulnerabilityLevelEnum) {
    if (
      VulnerabilityLevelEnum.Low === level ||
      VulnerabilityLevelEnum.Medium === level ||
      VulnerabilityLevelEnum.High === level
    ) {
      return VulnerabilityLevelEnum[level]
    }
    return '-'
  }
}
</script>

<style scoped>
.gridView tbody tr td:last-child {
  padding-left: 0px !important;
}
.team-member-img {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
}
.team-member-img.b-red img {
  border-color: #cf1e60;
}
.team-member-img.b-pink img {
  border-color: #ff8a65;
}
.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}
.gridView {
  border: 0px;
}
.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
}
.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}
.search-complaint >>> .v-input {
  padding-top: 0px;
}
.search-complaint >>> .v-input label {
  top: 0px;
}
.gridView tbody tr td:last-child {
  padding-left: 10px;
}
.gridView >>> .vue-star-rating-rating-text {
  margin-top: -2px;
}
.outstanding-complaint-table {
  width: 100%;
}
.en-request-search {
  max-width: 500px;
}
.nodata-text {
  font-size: 24px;
  text-align: center;
  padding: 20px;
  color: #aeaeae;
}
.engineer-time {
  width: 32px;
  right: -2px;
}
.date-content {
  padding-right: 30px !important;
  position: relative;
}
.date-content .cleardate {
  position: absolute;
  right: 55px;
  top: 32px;
  cursor: pointer;
}
.dashboard .jobview {
  top: 65px;
  padding-top: 10px !important;
}
@media only screen and (max-width: 1400px) {
  .outstanding-complaint-table >>> .v-input.outstanding-search {
    width: 200px;
  }
}
@media only screen and (max-width: 1200px) {
  .outstanding-complaint-table >>> .v-input.outstanding-search {
    width: 170px;
  }
}
</style>
