import moment from 'moment'
import QuestionTradeAssignmentModel from '@/models/policy/QuestionTradeAssignmentModel'
import QuestionAsbestosEffectAssignmentModel from '@/models/policy/QuestionAsbestosEffectAssignmentModel'
import { JobType } from '@/models/types'

export default class EmergencyQuestionModel {
  public id: number
  public text: string
  public answerTypeId: number
  public additionalValues: string | null
  public unit: string | null
  public insurerId: number | null
  public emergencyId: number | null
  public emergencyDetailId: number | null
  public orderBy: number
  public isSelected: boolean
  public entityType: string
  public policyId: number
  public entityName: string
  public createdDate: moment.Moment
  public note: string | null
  public jobType: JobType | null
  public questionTrades: QuestionTradeAssignmentModel[]
  public questionAsbestosEffects: QuestionAsbestosEffectAssignmentModel[]
  // added for create emergency based on answer
  public operatorId: number | null
  public comparisonValue: string
  public conditionalEmergencyTypeId: number | null
  public conditionalEmergencyDetailId: number | null

  public constructor() {
    this.questionTrades = []
    this.questionAsbestosEffects = []
    this.conditionalEmergencyTypeId = null
    this.conditionalEmergencyDetailId = null
  }
}
