import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
export default class VulnerabilityQAModel extends CosmosJobsDbRecordBase {
  public questionText: string
  public isHealthAffected: boolean | null
  public level: string | null
  public answerDetail: string | null

  public constructor() {
    super()
    this.isHealthAffected = null
    this.level = null
    this.answerDetail = null
  }
}
