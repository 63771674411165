<template>
  <v-flex v-if="jobPackage">
    <div v-if="jobPackage.monitoringProducts && jobPackage.monitoringProducts.length > 0">
      <div class="mb-2">
        <b>Monitoring Products</b>
      </div>
      <v-container class="pa-0 pb-2 pb-3">
        <v-flex v-for="(product, ind) in jobPackage.monitoringProducts" :key="ind">
          <div class="xs12">{{ product.quantity }} x {{ product.name }}</div>
          <template v-if="product.selectedChildProducts && product.selectedChildProducts.length">
            <div v-for="(child, i) in product.selectedChildProducts" :key="i" class="xs12">
              {{ child.quantity }} x {{ child.name }}
            </div>
          </template>
        </v-flex>
      </v-container>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import DehydratedJobPackage from '@/models/siteInvestigation/DehydratedJobPackage'

@Component
export default class MonitoringDetailCard extends TimeLineItemBase {
  private get jobPackage(): DehydratedJobPackage | undefined {
    return this.job && this.job.sIJobDetail ? this.job.sIJobDetail.jobPackage : undefined
  }
}
</script>
