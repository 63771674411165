import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import { JobType } from '@/models/types'

export default class TradeModel {
  public id: string
  public tradeId: number
  public description: string
  public disclaimer: string
  public orderBy: number
  public emergencyList: EmergencyModel[]
  public emergencyDetailList: EmergencyDetailModel[]
  public emergencyIds: number[]
  public emergencyDetailIds: number[]
  public jobType: JobType
  public shortCode: string
  public isDeleted: boolean
  public delayCodes: string[] = []
  public constructor() {
    if (!this.emergencyDetailList) {
      this.emergencyDetailList = []
    }
    if (!this.emergencyList) {
      this.emergencyList = []
    }
    if (!this.emergencyIds) {
      this.emergencyIds = []
    }
    if (!this.emergencyDetailIds) {
      this.emergencyDetailIds = []
    }
  }
}
