<template>
  <v-autocomplete
    v-model="selectedPolicyId"
    label="Policy"
    class="policy-list"
    :items="policies"
    :loading="loading"
    :disabled="loading"
  >
    <template slot="item" slot-scope="data">
      <v-list-tile-content>
        <v-list-tile-title :class="{ 'deleted-item': data.item.deleted }">
          {{ data.item.text }}
        </v-list-tile-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import PolicyModel from '@/models/policy/PolicyModel'
import { Component, Prop, Vue } from 'vue-property-decorator'
import sortBy from 'lodash.sortby'

interface ListType {
  value: number | null
  text: string
  deleted: boolean
}

@Component({})
export default class ClientPolicyList extends Vue {
  @Prop() public allowAll: boolean

  get loading(): boolean {
    return this.$store.getters['clientModule/loading']
  }

  get policies(): ListType[] {
    let temp: ListType[] = (this.$store.getters['clientModule/policies'] as PolicyModel[]).map((p) => ({
      value: p.policyBasicDetail.policyId,
      text: p.policyBasicDetail.name,
      deleted: p.policyBasicDetail.isDeleted,
    }))

    temp = sortBy(
      temp,
      (t: ListType) => t.deleted,
      (t: ListType) => t.text
    )

    if (this.allowAll) {
      temp.splice(0, 0, { value: null, text: '(All Policies)', deleted: false })
    }
    return temp
  }

  get selectedPolicyId(): number | null {
    return this.$store.getters['clientModule/selectedPolicyId']
  }

  set selectedPolicyId(policyId: number | null) {
    this.$store.dispatch('clientModule/submitSetSelectedPolicy', policyId)
  }

  created(): void {
    this.$store.dispatch('clientModule/submitRetrievePolicies')
  }
}
</script>
