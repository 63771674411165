<template>
  <div>
    <div class="mb-2"><h4>Reopen Job Request</h4></div>
    <div v-if="item.reopenReason" class="mb-1">
      <label>Type: </label>
      <span>{{ item.reopenReason }}</span>
    </div>
    <div v-if="item.reopenDescription" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.reopenDescription }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddReopenJobRequest from '@/models/requests/AddReopenJobRequestModel'

@Component
export default class AddReopenJobRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddReopenJobRequest {
    return Object.assign(new AddReopenJobRequest(), this.requestBody)
  }
}
</script>
