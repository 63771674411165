import ClaimsManagementCompanySalesLedgerModel from './policy/ClaimsManagementCompanySalesLedgerModel';

export default class ClaimManagementCompanyModel {
  public id: number
  public description: string
  public phone: string
  public email: string
  public mainContact: string
  public claimsManagementCompanySalesLedgers: ClaimsManagementCompanySalesLedgerModel[];

  constructor() {
    this.claimsManagementCompanySalesLedgers = []
  }
}
