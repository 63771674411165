export class WeatherForecastModel {
  public forecastItems: DayWeatherForecast[]

  public constructor() {
    this.forecastItems = []
  }
}

export class DayWeatherForecast {
  public forecastDate: Date
  public daytimeForecast: ForecastItem
  public nighttimeForecast: ForecastItem
  public regionalText: string

  public constructor() {
    this.daytimeForecast = new ForecastItem()
    this.nighttimeForecast = new ForecastItem()
  }
}

export class ForecastItem {
  public weatherTypeCode: string
  public weatherTypeDescription: string
  public humidity: number | null
  public precipitationProbability: number | null
  public temperature: number | null
  public windSpeed: number | null
  public windGust: number | null
}
