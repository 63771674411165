<template>
  <v-card class="elevation-1 pa-0 profile-sec engineer-dialog">
    <v-card-text class="pa-0 scroll-content-dialog">
      <div class="full-height engineerCard">
        <v-form autocomplete="off">
          <v-layout wrap class="cntp-upper-sec primary pt-3">
            <v-flex xs12 class="text-xs-center cntp-logo-sec">
              <div class="cntp-logo-sec-content">
                <span v-if="imageUrl" class="rm-logo elevation-1 pa-0">
                  <v-btn flat icon color="primary" class="ma-0" small @click.native.stop="removeImage">
                    <v-icon dark color="primary">delete</v-icon>
                  </v-btn>
                </span>
                <div class="cntp-logo-img mb-3" @click="pickFile">
                  <img :src="imageUrl ? imageUrl : 'img/unknownuser.png'" class="elevation-1" />
                  <span class="cntp-logo-img-edit">
                    <v-icon color="secondary" large>edit</v-icon>
                  </span>
                </div>
                <input
                  ref="image"
                  v-validate="{
                    rules: {
                      required: false,
                      image: true,
                      size: profileImageSize,
                      mimes: ['image/jpeg', 'image/jpg', 'image/png'],
                      ext: ['jpg', 'jpeg', 'png'],
                    },
                  }"
                  type="file"
                  style="display: none"
                  accept="image/*"
                  name="logo"
                  data-vv-scope="frmProfile"
                  @change="onFilePicked"
                />
                <div class="input-group__details imageError-wrapper">
                  <div class="input-group__messages input-group__error input-group--error error--text">
                    <span v-if="imageValidationMessage(errors)">
                      {{ logoErrorMsg }}
                    </span>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="px-3 pt-3 mt-5">
            <v-flex xs12 sm6 pr-2>
              <v-text-field
                v-model.trim="engineer.firstName"
                v-validate="'required'"
                label="First Name"
                maxlength="50"
                class="required"
                required
                data-vv-scope="frmEngineer"
                data-vv-name="First Name"
                :error-messages="errors.collect('First Name')"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pl-2>
              <v-text-field
                v-model.trim="engineer.lastName"
                v-validate="'required'"
                label="Last Name"
                maxlength="50"
                class="required"
                required
                data-vv-scope="frmEngineer"
                data-vv-name="Last Name"
                :error-messages="errors.collect('Last Name')"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pr-2>
              <v-text-field
                v-model.trim="engineer.email"
                v-validate="'required|email'"
                label="Email"
                maxlength="50"
                class="required"
                required
                data-vv-scope="frmEngineer"
                data-vv-name="Email"
                :error-messages="errors.collect('Email')"
                name="Email"
                :readonly="isUserRoleContractor"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pl-2>
              <PhoneNumber
                ref="engineerContactNumber"
                :passed-phone-number="engineer.contactNumber"
                :is-validation-required="true"
                class="internal-phone-number engineer-number"
                @phoneNumberUpdated="phoneNumberUpdated"
              />
            </v-flex>
            <v-flex xs12>
              <v-textarea v-model.trim="engineer.address" label="Address" maxlength="500" rows="3"></v-textarea>
            </v-flex>
            <v-flex xs12 mt-1>
              <v-switch
                v-model="engineer.hasPortalAccess"
                color="primary"
                small
                label="Ability to use Portal"
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex v-if="isUpdateEngineer" xs12 my-2>
              <v-checkbox
                v-model="isEngineerOutOfDate"
                color="primary"
                hide-details
                label="Engineer not available"
                @change="IsEngineerOutOfDateChange"
              ></v-checkbox>
            </v-flex>
            <v-flex v-if="isUpdateEngineer && isEngineerOutOfDate" xs12 sm6 pr-2>
              <v-dialog
                ref="fromDatePickerDialog"
                v-model="showFromDatePicker"
                :return-value.sync="getOutsideFromDate"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="getOutsideFromDate"
                    label="From Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="getOutsideFromDate" color="primary" :min="setMinDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="showFromDatePicker = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.fromDatePickerDialog.save(getOutsideFromDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex v-if="isUpdateEngineer && isEngineerOutOfDate" xs12 sm6 pr-2>
              <v-dialog
                ref="toDatePickerDialog"
                v-model="showToDatePicker"
                :return-value.sync="getOutsideToDate"
                persistent
                lazy
                full-width
                width="290px"
                :disabled="!getOutsideFromDate"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="getOutsideToDate"
                    label="To Date"
                    prepend-icon="event"
                    readonly
                    :disabled="!getOutsideFromDate"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="getOutsideToDate" color="primary" :min="getOutsideFromDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="showToDatePicker = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.toDatePickerDialog.save(getOutsideToDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex v-if="isUpdateEngineer" xs12 mb-4>
              <v-select
                v-model="getSelectedTradeList"
                :items="tradeList"
                :item-disabled="disableTradeList"
                multiple
                return-object
                item-text="description"
                item-value="tradeId"
                persistent-hint
                label="Select Trade"
                hide-details
                class="hide-option trade-items"
                @change="onTradeSelectionChange(getSelectedTradeList, $event)"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-checkbox
                        v-model="tradeCheckboxes[tradeList.indexOf(data.item)].checked"
                        color="primary"
                      ></v-checkbox>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title
                        :class="data.item.isDeleted ? 'deleted-item' : ''"
                        v-html="data.item.description"
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-select>
            </v-flex>
            <template v-if="isUpdateEngineer">
              <v-flex v-for="trade in getSelectedTradeList" :key="trade.tradeId" xs12 class="engineer-timeslot">
                <TimeSlotPicker
                  class="engineer-availability"
                  :header-text="trade.description"
                  :filter-by-date="false"
                  slot-type="today"
                  :model="trade"
                  :slot-items="getTradeAvailabilityByTrade(trade.tradeId)"
                  @updateSlotItems="onSlotSelectionChange"
                ></TimeSlotPicker>
              </v-flex>
            </template>

            <v-flex v-show="errorMsg" xs12 pl-2>
              <div class="pt-3 common-error-message">
                <div class="red pa-2 d-block lighten-4">
                  <span>
                    <b>{{ errorMsg }}</b>
                  </span>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn @click="Close">Close</v-btn>
      <v-btn color="primary save-engineer" :loading="isLoading" :disabled="isLoading" class="mr-0" @click="Save">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EngineerModel from '@/models/contractor/EngineerModel'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import EngineerController from '@/api/engineerController'
import DateTimePicker from '@/components/DateTimePicker.vue'
import TimeSlotPicker from '@/components/TimeSlotPicker.vue'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import TimeSlot from '@/models/claim/TimeSlot'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import eventBus from '@/common/bus'
import SessionDetail from '@/models/auth/SessionDetail'
declare let AzureStorage: any

@Component({
  components: { DateTimePicker, TimeSlotPicker, PhoneNumber },
})
export default class Engineer extends Vue {
  @Prop()
  private engineerItem: EngineerModel | null
  @Prop()
  private isUpdateEngineer: boolean
  @Prop()
  private engineerAvailability: ContractorTradeAvailabilityModel[]

  private imageName = ''
  private imageUrl = ''
  private logoErrorMsg = ''
  private errorMsg = ''
  private isLoading = false
  private selectedTradeList: TradeModel[] = []
  private tradeList: TradeModel[] = []
  private trades: TradeModel[] = []
  private isEngineerOutOfDate = false
  private showFromDatePicker = false
  private showToDatePicker = false
  private setMinDate: any = null
  private outsideFromDate: any = null
  private outsideToDate: any = null
  private fileToUpload: any = null
  private tradeCheckboxes: any = []

  private get engineer(): EngineerModel | null {
    this.imageUrl =
      this.engineerItem && this.engineerItem.profileImageUrl !== null ? this.engineerItem.profileImageUrl : ''
    return this.engineerItem
  }

  private get getOutsideFromDate(): moment.Moment | null {
    return this.outsideFromDate !== null && this.outsideFromDate !== undefined ? this.outsideFromDate : null
  }
  private set getOutsideFromDate(newValue: moment.Moment | null) {
    this.outsideFromDate = newValue
  }

  private get getOutsideToDate(): moment.Moment | null {
    return this.outsideToDate !== null && this.outsideToDate !== undefined ? this.outsideToDate : null
  }
  private set getOutsideToDate(newValue: moment.Moment | null) {
    this.outsideToDate = newValue
  }

  private created() {
    this.errorMsg = ''
    this.setMinDate = new Date().toISOString().slice(0, 10)
    this.trades = storeGetters.getTrades()
    if (this.trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.trades = res
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    }
  }

  @Watch('engineer')
  private onEngineerChange() {
    this.errorMsg = ''
    if (
      this.engineer &&
      this.engineer.outsideFromDate !== null &&
      this.engineer.outsideFromDate !== undefined &&
      this.engineer.outsideFromDate.isValid()
    ) {
      this.isEngineerOutOfDate = true
    } else {
      this.isEngineerOutOfDate = false
    }
    if (this.engineer && this.engineer.outsideFromDate !== null) {
      const date = this.engineer.outsideFromDate
      if (date && date.toISOString() !== null) {
        this.outsideFromDate = date.toISOString().slice(0, 10)
      }
    }
    if (this.engineer && this.engineer.outsideToDate !== null) {
      const date = this.engineer.outsideToDate
      if (date && date.toISOString() !== null) {
        this.outsideToDate = date.toISOString().slice(0, 10)
      }
    }
    if (this.engineer && this.engineerAvailability) {
      this.engineerAvailabilityChange()
    }
  }

  @Watch('isUpdateEngineer')
  private isUpdateEngineerChange() {
    this.errorMsg = ''
    if (this.isUpdateEngineer) {
      this.getSelectedTradeList = []
    } else {
      this.outsideFromDate = null
      this.outsideToDate = null
    }
  }

  private engineerAvailabilityChange() {
    this.tradeList = []
    const self = this
    const engineerTradeList = this.trades.filter(
      (t) =>
        !t.isDeleted ||
        (t.isDeleted &&
          this.engineer &&
          this.engineer.engineerAvailability.findIndex((e) => e.tradeId === t.tradeId) !== -1)
    )
    this.engineerAvailability.forEach((contractorTrade) => {
      const engineerTrade = engineerTradeList.find((e) => e.tradeId === contractorTrade.tradeId)
      if (engineerTrade) {
        const trade: TradeModel = new TradeModel()
        trade.tradeId = contractorTrade.tradeId
        trade.description = contractorTrade.description
        trade.isDeleted = engineerTrade.isDeleted
        this.tradeList.push(trade)
      }
    })
    this.tradeCheckboxes = this.tradeList.map((trade) => {
      return {
        checked: false,
      }
    })
    return this.tradeList
  }

  private get getSelectedTradeList(): TradeModel[] {
    const self = this
    const tradeIdList: any = []

    if (this.engineer && this.engineer.engineerAvailability !== null) {
      for (const item of this.engineer.engineerAvailability) {
        const trade = self.tradeList.find((a) => a.tradeId === item.tradeId)
        if (trade) {
          if (self.selectedTradeList.length === 0) {
            self.selectedTradeList.push(trade)
          } else {
            self.selectedTradeList.forEach((e) => {
              if (!(self.selectedTradeList.indexOf(trade) > -1)) {
                self.selectedTradeList.push(trade)
              }
            })
          }
          tradeIdList.push(trade.tradeId)
        }
      }
    }
    this.tradeList =
      this.engineer !== null
        ? Shared.getSelectedItemFirstInList(this.tradeList, tradeIdList, 'tradeId')
        : this.tradeList
    this.selectedTradeList.forEach((t) => {
      self.tradeCheckboxes[self.tradeList.indexOf(t)].checked = self.tradeList.indexOf(t) !== -1 ? true : false
    })
    return this.selectedTradeList
  }

  private set getSelectedTradeList(newValue: TradeModel[]) {
    const tradeIdList: any = []
    newValue.forEach((element) => {
      tradeIdList.push(element.tradeId)
    })
    this.tradeList =
      this.engineer !== null
        ? Shared.getSelectedItemFirstInList(this.tradeList, tradeIdList, 'tradeId')
        : this.tradeList
    this.selectedTradeList = newValue
  }

  private IsEngineerOutOfDateChange() {
    if (this.engineer && !this.isEngineerOutOfDate) {
      this.getOutsideFromDate = null
      this.getOutsideToDate = null
    }
  }

  private getTradeAvailabilityByTrade(id: number): TimeSlot[] {
    if (this.engineer) {
      const engineerAvailability: EngineerAvailabilityModel | undefined =
        this.engineer.engineerAvailability !== null
          ? this.engineer.engineerAvailability.find((a: EngineerAvailabilityModel) => a.tradeId === id)
          : undefined
      if (engineerAvailability) {
        return engineerAvailability.timeSlot
      } else {
        return []
      }
    } else {
      return []
    }
  }

  private onSlotSelectionChange(slotItems: TimeSlot[], model: TradeModel, slotType: string) {
    if (this.engineer) {
      let engineerAvailability: EngineerAvailabilityModel | undefined =
        this.engineer.engineerAvailability !== null
          ? this.engineer.engineerAvailability.find((a: EngineerAvailabilityModel) => a.tradeId === model.tradeId)
          : undefined
      if (!engineerAvailability) {
        engineerAvailability = new EngineerAvailabilityModel()
        engineerAvailability.tradeId = model.tradeId
        engineerAvailability.description = model.description
        engineerAvailability.timeSlot = slotItems
        this.engineer.engineerAvailability =
          this.engineer.engineerAvailability === null ? [] : this.engineer.engineerAvailability
        this.engineer.engineerAvailability.push(engineerAvailability)
      } else {
        Vue.set(engineerAvailability, 'timeSlot', slotItems)
      }
    }
  }

  private onTradeSelectionChange(selectedItems: TradeModel[], items: any) {
    // set checked property value
    if (selectedItems.length > items.length) {
      const itemToRemove = selectedItems.find((e) => !items.includes(e))
      const item = itemToRemove ? this.tradeList.find((e) => e.tradeId === itemToRemove.tradeId) : undefined
      if (item !== undefined) {
        setTimeout(() => {
          this.tradeCheckboxes[this.tradeList.indexOf(item)].checked = false
        }, 0)
      }
    }

    const availability: TradeModel[] | undefined = selectedItems.filter((e) => !items.includes(e))
    if (availability.length > 0 && this.engineer) {
      const id = Number(availability[0].tradeId)
      const index = this.engineer.engineerAvailability.findIndex((e) => e.tradeId === id)
      this.engineer.engineerAvailability.splice(index, 1)
      this.getSelectedTradeList = items
    }
  }

  private get profileImageSize(): number {
    return Store.Instance.state.Environment.LogoSize
  }

  private pickFile() {
    const logoError = this.$validator.errors.items.find((a) => a.field === 'logo')
    if (logoError) {
      logoError.msg = ''
    }
    const image = this.$refs.image as any
    image.click()
  }

  private onFilePicked(event: any) {
    const self = this
    const file: any = event.target.files[0]
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1)
    if (file.size / 1048576 > 1) {
      return
    }
    if (fileType.toLowerCase() !== 'png' && fileType.toLowerCase() !== 'jpg' && fileType.toLowerCase() !== 'jpeg') {
      return
    }
    if (file !== undefined) {
      const fileName = file.name.split(' ').join('_')
      if (fileName.lastIndexOf('.') <= 0) {
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(file)
      fr.addEventListener('load', () => {
        self.fileToUpload = file
        self.imageName = fileName
        self.imageUrl = fr.result as string
      })
    }
  }

  private imageValidationMessage(err: any) {
    if (err && err.collect('logo').length > 0) {
      const message: string = err.collect('logo')[0]
      this.logoErrorMsg =
        message === 'The logo field must be an image.' ? 'Only JPEG, JPG, PNG files are allowed.' : message
      this.removeImage()
      return true
    }
  }

  private removeImage() {
    this.imageName = ''
    this.imageUrl = ''
    const image = this.$refs.image as any
    image.value = null
    this.fileToUpload = null
    if (this.engineer) {
      this.engineer.profileImageUrl = ''
      this.engineer.profileImageThumbnailUrl = ''
    }
  }

  private Close() {
    if (this.engineer) {
      this.selectedTradeList = []
      this.engineer.engineerAvailability = []
    }
    this.isEngineerOutOfDate = false
    this.$validator.errors.items = []
    const contactNumberSub = this.$refs.engineerContactNumber as PhoneNumber
    if (contactNumberSub) {
      contactNumberSub.$validator.errors.items = []
    }
    this.$emit('OnEngineerSave', false, false)
  }

  private Save() {
    const self = this
    self.errorMsg = ''
    this.validate()
      .then((result: boolean) => {
        if (result && self.engineer) {
          self.engineer.outsideFromDate = !self.isEngineerOutOfDate
            ? null
            : self.getOutsideFromDate !== null
            ? moment.utc(self.getOutsideFromDate)
            : null
          self.engineer.outsideToDate =
            self.engineer.outsideFromDate === null
              ? null
              : self.getOutsideToDate !== null
              ? moment.utc(self.getOutsideToDate)
              : null
          if (
            self.engineer.outsideFromDate &&
            self.engineer.outsideToDate &&
            self.engineer.outsideFromDate > self.engineer.outsideToDate
          ) {
            this.errorMsg = 'To date must be greater than from date'
            return
          }
          this.isLoading = true
          EngineerController.SaveEngineer(self.engineer)
            .then((res: string) => {
              if (res) {
                if (
                  this.isUserRoleContractor &&
                  !this.fileToUpload &&
                  this.isUpdateEngineer &&
                  !this.engineer!.profileImageUrl
                ) {
                  // profile picture removed - if engineerId same as logged in UserId, refresh profile image
                  const loggedInUserId = Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.id
                  if (
                    loggedInUserId === res &&
                    Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.profileImageUrl !== ''
                  ) {
                    const sd: SessionDetail = Object.assign(new SessionDetail(), Store.Instance.state.SessionDetail)
                    sd.detailRecord.EngineerDetail.profileImageUrl = ''
                    sd.detailRecord.EngineerDetail.profileImageThumbnailUrl = ''
                    storeMutations.setSessionDetail(sd)
                  }
                }
                if (self.fileToUpload) {
                  // upload engineer profile picture
                  this.uploadEngineerProfilePicture(res)
                } else {
                  this.handleResponse()
                }
              } else {
                this.isLoading = false
                eventBus.$emit('showSnackbar', 'Something went wrong. Please try again.')
              }
            })
            .catch((err: any) => {
              this.isLoading = false
              if (err.response.statusText !== null) {
                self.errorMsg = err.response.statusText
              }
              self.scrollToEnd()
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private handleResponse(isSuccess = true) {
    this.isLoading = false
    if (isSuccess) {
      if (this.engineer) {
        this.engineer.engineerAvailability = []
      }
      this.$emit('OnEngineerSave', true, this.fileToUpload ? true : false)
      eventBus.$emit('showSnackbar', 'Engineer Detail Saved Successfully!')
    }
  }

  private scrollToEnd() {
    setTimeout(() => {
      const container: any = this.$el.querySelector('.scroll-content-dialog')
      container.scrollTop = container.scrollHeight + 20
    }, 100)
  }

  private async validate(): Promise<boolean> {
    let result = true
    const engineerFormValidation: boolean = await this.$validator.validateAll('frmEngineer')
    let contactNumberValidation = true
    const contactNumberValidationSub: any = this.$refs.engineerContactNumber as PhoneNumber
    contactNumberValidation =
      (await contactNumberValidationSub.$validator.validateAll()) && contactNumberValidationSub.isValid

    // set focus to non validate field
    if (!contactNumberValidation || !engineerFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = contactNumberValidation && engineerFormValidation
    }

    return result
  }

  private phoneNumberUpdated(phoneNumber: string): void {
    if (this.engineer) {
      this.engineer.contactNumber = phoneNumber
    }
  }

  private uploadEngineerProfilePicture(engineerId: string) {
    const self = this
    EngineerController.GetSASTokenForEngineerProfileImage(engineerId, self.imageName)
      .then((res: string) => {
        if (res !== '') {
          const blobUri = res.split('/original')[0]
          const sasToken = res.split('?')[1]
          const containerName = 'original'
          const blobService = AzureStorage.Blob.createBlobServiceWithSas(blobUri, sasToken)
          const customBlockSize = self.fileToUpload.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512
          blobService.singleBlobPutThresholdInBytes = customBlockSize
          const imageName = res.substring(res.indexOf('EngineerDetail/'), res.indexOf('?'))
          blobService.createBlockBlobFromBrowserFile(
            containerName,
            imageName,
            self.fileToUpload,
            { blockSize: customBlockSize },
            (error, result, response) => {
              if (error) {
                this.handleResponse(false)
                eventBus.$emit('errorHandler', 'Error uploading profile picture. Please try again', true)
              } else {
                // if engineerId same as logged in UserId, refresh profile image
                if (
                  this.isUserRoleContractor
                    ? engineerId === Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.id
                    : false
                ) {
                  eventBus.$emit('refreshUserProfileImage')
                }
                this.handleResponse()
                const img = self.$refs.image as any
                img.value = null
                this.fileToUpload = null
              }
            }
          )
        }
      })
      .catch((error: any) => {
        if (error) {
          eventBus.$emit('errorHandler', 'Error uploading profile picture. Please try again', true)
        }
        this.handleResponse(false)
      })
  }

  private get isUserRoleContractor(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  // expected type of "item-disabled" prop is string | array | function, so used function instead of getter
  private disableTradeList(): boolean {
    return this.isUserRoleContractor
  }
}
</script>

<style scoped>
.ck-dmimg img {
  width: 100%;
}
.cntp-logo-img {
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  border: 10px solid #fff;
  background-color: #fff;
}
.cntp-logo-img img {
  max-width: 100%;
  margin: auto;
  border-radius: 50%;
}
.cntp-upper-sec {
  color: #fff;
}
.cntp-logo-img-edit {
  transition: 0.3s all linear;
  transform: scale(0, 0);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  border-radius: 50%;
}
.cntp-logo-img-edit i {
  margin: auto;
}
.cntp-logo-img:hover .cntp-logo-img-edit {
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}
.cntp-logo-sec {
  height: 90px;
  position: relative;
}
.imageError-wrapper {
  top: -23px;
  margin: auto;
  width: 100%;
  display: inline-block;
}
.full-height {
  height: 100%;
  max-height: 800px;
  box-sizing: border-box;
}
.profile-sec {
  display: flex;
}
.profile-sec >>> .v-card {
  margin: auto;
  margin-top: 0;
}
.rm-logo {
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  color: #696464;
  border-radius: 50%;
  padding: 3px;
  width: 30px;
  height: 30px;
  top: 7px;
  z-index: 2;
  right: 6px;
}
.cntp-logo-sec-content {
  display: inline-block;
  position: relative;
}
.rm-logo >>> .v-icon {
  font-size: 19px;
}
.engineer-dialog {
  width: 100%;
}
.engineer-dialog >>> .v-card {
  width: 100%;
}
.engineer-timeslot >>> .v-card {
  background-color: #eeeeee !important;
  margin-bottom: 20px;
}

/*PhoneNumber Component*/
.internal-phone-number {
  padding-left: 0px;
}
.internal-phone-number.engineer-number {
  position: relative;
  padding-top: 18px !important;
}
.internal-phone-number.engineer-number >>> .validation {
  position: absolute;
  right: 7px;
  top: 21px;
}
.engineer-availability >>> .cnt-time-head.select-date .v-icon {
  margin-top: 27px !important;
}
.common-error-message {
  color: #ff5252 !important;
}
</style>
