<template>
  <v-card :class="isEdit ? 'pb-0' : 'pb-2'" class="elevation-1 center-sec-layout">
    <v-toolbar v-if="isEdit" card dark color="primary">
      <v-toolbar-title>Document Template</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="Close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="templateContent" :class="isEdit ? 'pt-0 user-temp scroll-content-dialog' : 'pt-0'">
      <v-form autocomplete="off" class="template-form">
        <v-layout wrap class="mt-2">
          <v-flex xs12>
            <v-autocomplete
              v-model="getDocumentTemplate.insurerId"
              v-validate="'required'"
              label="Search insurer"
              required
              class="required"
              :items="insurers"
              :loading="insurerSearchRunning"
              item-text="name"
              item-value="id"
              :disabled="isEdit"
              data-vv-scope="templateformRef"
              data-vv-name="Search insurer"
              :error-messages="errors.collect('Search insurer')"
              @change="onInsurerChange($event)"
            />
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="getDocumentTemplate.templateTypeId"
              v-validate="'required'"
              label="Search template type"
              required
              class="required"
              :disabled="isEdit"
              :items="templatetypes"
              :loading="templatetypeSearchRunning"
              item-text="description"
              item-value="templateTypeId"
              data-vv-scope="templateformRef"
              data-vv-name="Search template type"
              :error-messages="errors.collect('Search template type')"
              @change="onTemplateTypeChange($event)"
            />
          </v-flex>
          <v-flex xs12>
            <div class="newindicator-chip">
              <v-combobox
                v-model="selectedDocumentTemplate"
                v-validate="'required|max:100'"
                label="Search name"
                maxlength="100"
                required
                class="required"
                return-object
                :disabled="isEdit"
                :items="docTemplatesbyTemplateType"
                :loading="documentTemplateSearchRunning"
                item-text="name"
                item-value="templateId"
                data-vv-scope="templateformRef"
                data-vv-name="Search name"
                :error-messages="errors.collect('Search name')"
                :data-vv-delay="validationDelay"
                @change="onTemplateChange($event)"
              ></v-combobox>
              <v-chip color="secondary" class="white--text" small disabled :value="IsNewTemplate">New</v-chip>
            </div>
          </v-flex>
          <v-flex xs6 pr-1>
            <v-combobox
              v-model="delayTimeHour"
              :items="delayTimeHourList"
              label="Delay Time (Hours)"
              item-text="text"
              item-value="value"
              :search-input.sync="delayTimeHour"
              @keypress="numberKeyValidation($event)"
            ></v-combobox>
          </v-flex>
          <v-flex xs6>
            <v-combobox
              v-model="delayTimeMinute"
              v-validate="'between:0,59'"
              :items="delayTimeMinuteList"
              label="Delay Time (Minutes)"
              item-text="text"
              item-value="value"
              data-vv-scope="templateformRef"
              data-vv-name="Delay Time"
              :error-messages="errors.collect('Delay Time')"
              :search-input.sync="delayTimeMinute"
              @keypress="numberKeyValidation($event)"
            ></v-combobox>
          </v-flex>
          <v-flex xs12>
            <v-checkbox
              v-model="getDocumentTemplate.isCommunicationWindowApplied"
              :value="getDocumentTemplate.isCommunicationWindowApplied"
              label="Notification window (8am-10pm) applies"
              color="primary"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 sm8>
            <v-radio-group v-model="getDocumentTemplate.notificationPreferenceId" row :mandatory="false">
              <v-radio label="SMS" color="primary" :value="DocumentPreference.SMS"></v-radio>
              <v-radio label="Email" color="primary" :value="DocumentPreference.Email"></v-radio>
              <v-radio label="Both" color="primary" :value="DocumentPreference.Both"></v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex v-show="showSMS" xs12 class="pa-3 bg-gray elevation-1">
            <v-layout wrap>
              <v-flex xs12>
                <h3>
                  <v-icon small color="secondary">sms</v-icon>
                  SMS
                </h3>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model.trim="getDocumentTemplate.smsText"
                  v-validate="showSMS ? 'required|max:500' : 'max:500'"
                  label="Description"
                  maxlength="500"
                  :required="showSMS"
                  :class="showSMS ? 'required' : ''"
                  :counter="500"
                  data-vv-scope="templateformRef"
                  data-vv-name="Description"
                  :error-messages="errors.collect('Description')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-show="showEmail" xs12 class="pa-3 mt-3 bg-gray elevation-1">
            <v-layout wrap>
              <v-flex xs12>
                <h3>
                  <v-icon small color="secondary">email</v-icon>
                  Email
                </h3>
              </v-flex>
              <v-flex xs12 class="mb-3">
                <v-text-field
                  v-model.trim="getDocumentTemplate.subject"
                  v-validate="showEmail ? 'required|max:200' : 'max:200'"
                  label="Subject"
                  maxlength="200"
                  :required="showEmail"
                  :class="showEmail ? 'required' : ''"
                  data-vv-scope="templateformRef"
                  data-vv-name="Subject"
                  :error-messages="errors.collect('Subject')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <div>
                  <froala
                    id="froala"
                    v-model="getDocumentTemplate.emailText"
                    v-validate="showEmail ? 'required' : ''"
                    :required="showEmail"
                    data-vv-scope="templateformRef"
                    data-vv-name="Email"
                    :error-messages="ValidateEditor('Email')"
                    :class="showEmail ? 'required' : ''"
                    :config="config"
                    :tag="'textarea'"
                  ></froala>
                  <div class="input-group__details">
                    <div class="input-group__messages input-group__error input-group--error error--text">
                      <span v-if="ValidateEditor('Email')">
                        {{ ValidateEditor('Email') }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Client Email Template -->
          <v-flex v-if="showClientEmail" xs12 class="pa-3 mt-3 bg-gray elevation-1">
            <v-layout wrap>
              <v-flex xs12>
                <h3>
                  <v-icon small color="secondary">email</v-icon>
                  Client Email
                </h3>
              </v-flex>
              <v-flex xs12 class="mb-3">
                <v-text-field
                  v-model.trim="getDocumentTemplate.clientSubject"
                  v-validate="'max:200'"
                  label="Subject"
                  maxlength="200"
                  data-vv-scope="templateformRef"
                  data-vv-name="Client Subject"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <div>
                  <froala
                    id="clientFroala"
                    v-model="getDocumentTemplate.clientEmailText"
                    :required="showEmail"
                    data-vv-scope="templateformRef"
                    data-vv-name="Client Email"
                    :config="config"
                    :tag="'textarea'"
                  ></froala>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn v-if="isEdit" @click="Close">Close</v-btn>
      <v-btn color="primary" :disabled="isLoading" :loading="isLoading" class="mr-0" @click="saveDocumentTemplate">
        Save
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import InsurerModel from '@/models/policy/InsurerModel'
import TemplateTypeModel from '@/models/policy/TemplateTypeModel'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import { DocumentPreference } from '@/common/enums'
import Shared from '@/common/shared'
import VueFroala from 'vue-froala-wysiwyg'
import { DocumentTemplateType } from '@/common/enums'
import InsurerController from '@/api/InsurerController'
import PolicyHolderController from '@/api/policyHolderController'
import Store from '@/store'
import eventBus from '@/common/bus'

@Component({
  name: 'DocumentTemplate',
  components: {},
})
export default class DocumentTemplate extends Vue {
  @Prop() private documentItem: DocumentTemplateModel | null
  @Prop() private isEdit: boolean

  private insurers: InsurerModel[] = []
  private insurerSearchRunning = false
  private selectedInsurerId: number | null = null

  private templatetypes: TemplateTypeModel[] = []
  private templatetypeSearchRunning = false

  private documentTemplates: DocumentTemplateModel[] = []
  private documentTemplateSearchRunning = false
  private docTemplatesbyTemplateType: DocumentTemplateModel[] = []
  private selectedDocumentTemplate: DocumentTemplateModel | string | null = null
  private IsNewTemplate = false

  private documentTemplateModel: DocumentTemplateModel = new DocumentTemplateModel()
  private DocumentPreference = DocumentPreference
  private snackbarTimeout = 3000
  private snackbar = false
  private snackbarText = 'Template created successfully.'
  private isLoading = false
  private config: any = Shared.froalaConfig()
  private validationDelay = Shared.validationDelay
  private delayTimeHourList: string[] = this.getTimeSpanSlotsList(25, 1)
  private delayTimeMinuteList: string[] = this.getTimeSpanSlotsList(4, 15)
  private delayTimeHour: string = this.delayTimeHourList[0]
  private delayTimeMinute: string = this.delayTimeMinuteList[0]
  private get getDocumentItems(): DocumentTemplateModel | null {
    return this.documentItem
  }

  private ValidateEditor(label: string): string {
    const message: string = this.$validator.errors.collect(label)[0]
    return message
  }

  private get getDocumentTemplate(): DocumentTemplateModel | null {
    if (this.getDocumentItems && this.getDocumentItems.templateId) {
      this.documentTemplateModel = this.getDocumentItems
      this.selectedDocumentTemplate = this.documentTemplateModel
    }
    return this.documentTemplateModel
  }
  private created(): void {
    const self = this
    self.insurerSearchRunning = true
    InsurerController.GetInsurerList()
      .then((res: InsurerModel[]) => {
        self.insurers = res.filter((i) => !i.isDelete)
        self.insurerSearchRunning = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading insurer list, please try again', true)
        self.insurerSearchRunning = false
      })

    self.templatetypeSearchRunning = true
    PolicyHolderController.GetTemplateTypes()
      .then((res: TemplateTypeModel[]) => {
        self.templatetypes = res
        self.templatetypeSearchRunning = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading template types, please try again', true)
        self.templatetypeSearchRunning = false
      })
  }

  private mounted() {
    // set delay time hour and minute
    if (this.documentItem && this.documentItem.delay) {
      this.setDelayTime(this.documentItem.delay)
    } else {
      this.resetDelayTime()
    }
  }

  private onInsurerChange(insurerId: number) {
    const self = this
    self.documentTemplateSearchRunning = true
    PolicyHolderController.GetDocumentTemplateByInsurerId(insurerId)
      .then((res: DocumentTemplateModel[]) => {
        if (res) {
          self.documentTemplates = res.filter((d) => !d.isDeleted)
          self.selectedDocumentTemplate = null
          self.docTemplatesbyTemplateType = self.documentTemplates
          self.documentTemplateSearchRunning = false
          self.resetDelayTime()
        }
      })
      .catch((err: any) => {
        self.documentTemplateSearchRunning = false
        eventBus.$emit('errorHandler', 'Error loading document template list, please try again', true)
      })
  }

  private onTemplateTypeChange(templateTypeId: number) {
    const self = this
    const documentTemplate: DocumentTemplateModel[] | undefined = self.documentTemplates.filter(
      (e: DocumentTemplateModel) => e.templateTypeId === templateTypeId
    )
    if (documentTemplate) {
      self.docTemplatesbyTemplateType = documentTemplate
    }

    if (self.documentTemplateModel.templateId) {
      const selectedTemplate: DocumentTemplateModel | undefined = self.docTemplatesbyTemplateType.find(
        (e: DocumentTemplateModel) => e.templateId === self.documentTemplateModel.templateId
      )
      self.selectedDocumentTemplate = selectedTemplate ? selectedTemplate : null
    } else {
      self.selectedDocumentTemplate = null
    }
  }

  private onTemplateChange(documentTemplate: DocumentTemplateModel) {
    if (documentTemplate.templateId) {
      this.IsNewTemplate = false
      this.documentTemplateModel = documentTemplate
    } else {
      this.IsNewTemplate = true
      const insurerId = this.documentTemplateModel.insurerId
      const templateTypeId = this.documentTemplateModel.templateTypeId
      const notificationPreferenceId = this.documentTemplateModel.notificationPreferenceId
      this.documentTemplateModel = new DocumentTemplateModel()
      this.documentTemplateModel.insurerId = insurerId
      this.documentTemplateModel.templateTypeId = templateTypeId
      this.documentTemplateModel.notificationPreferenceId = notificationPreferenceId
    }
    // set delay time hour and minute
    if (documentTemplate.delay) {
      this.setDelayTime(documentTemplate.delay)
    } else {
      this.resetDelayTime()
    }
  }

  private get showEmail(): boolean {
    return (
      this.documentTemplateModel.notificationPreferenceId === DocumentPreference.Email ||
      this.documentTemplateModel.notificationPreferenceId === DocumentPreference.Both
    )
  }

  private get showSMS(): boolean {
    return (
      this.documentTemplateModel.notificationPreferenceId === DocumentPreference.SMS ||
      this.documentTemplateModel.notificationPreferenceId === DocumentPreference.Both
    )
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('templateformRef')

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private setDocumentTemplate(): void {
    const self = this
    if (self.selectedDocumentTemplate === null) {
      return
    }

    if (typeof self.selectedDocumentTemplate === 'string') {
      self.documentTemplateModel.name = String(self.selectedDocumentTemplate)
      self.documentTemplateModel.templateId = 0
    } else {
      self.documentTemplateModel.templateId = self.selectedDocumentTemplate.templateId
    }
  }

  private saveDocumentTemplate(): void {
    const self = this
    self
      .validate()
      .then((result: boolean) => {
        if (result && self.documentTemplateModel) {
          self.isLoading = true

          if (this.documentTemplateModel.notificationPreferenceId === DocumentPreference.Email) {
            self.documentTemplateModel.smsText = ''
          } else if (this.documentTemplateModel.notificationPreferenceId === DocumentPreference.SMS) {
            self.documentTemplateModel.subject = ''
            self.documentTemplateModel.emailText = ''
          }

          this.setDocumentTemplate()
          // set delay time
          self.documentTemplateModel.delay = Shared.convertTimeToMinutes(
            Number(self.delayTimeHour),
            Number(self.delayTimeMinute)
          )
          PolicyHolderController.SaveDocumentTemplate(self.documentTemplateModel)
            .then((res: number) => {
              self.isLoading = false
              if (res && res !== 0) {
                if (self.IsNewTemplate) {
                  self.documentTemplateModel.templateId = res
                  self.docTemplatesbyTemplateType.push(self.documentTemplateModel)
                  self.selectedDocumentTemplate = self.documentTemplateModel
                  self.IsNewTemplate = false
                }
                self.snackbar = true
                if (this.isEdit) {
                  this.$emit('OnDocumentSave', self.documentTemplateModel)
                }
              }
            })
            .catch((err: any) => {
              self.isLoading = false
              eventBus.$emit('errorHandler', 'Error saving document template, please try again', true)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private get showClientEmail() {
    if (this.getDocumentTemplate) {
      return (
        this.getDocumentTemplate.templateTypeId === DocumentTemplateType.EmergencyAccepted ||
        this.getDocumentTemplate.templateTypeId === DocumentTemplateType.EmergencyRejected ||
        this.getDocumentTemplate.templateTypeId === DocumentTemplateType.HelplineCompleted ||
        this.getDocumentTemplate.templateTypeId === DocumentTemplateType.SIVisitBooked
      )
    }
    return false
  }

  private Close() {
    this.$validator.errors.items = []
    this.$emit('OnDocumentSave')
  }

  private getTimeSpanSlotsList(numberOfRows = 0, gapBetweenNumbers = 1): string[] {
    const list: string[] = []
    if (numberOfRows > 0) {
      for (let index = 0; index < numberOfRows; index++) {
        const newValue: number = index > 0 ? Number(list[index - 1]) + gapBetweenNumbers : index
        list.push(newValue < 10 ? '0' + newValue.toString() : newValue.toString())
      }
      return list
    }
    return []
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumeric(event)
  }

  private resetDelayTime() {
    this.delayTimeHour = this.delayTimeHourList[0]
    this.delayTimeMinute = this.delayTimeMinuteList[0]
  }

  private setDelayTime(value: number) {
    this.delayTimeHour = Shared.calculateHours(value).toString()
    this.delayTimeMinute = Shared.calculateMinutes(value).toString()
  }
}
</script>

<style scoped>
.center-sec-layout {
  max-width: 850px;
  margin: auto;
}
.template-form {
  width: 100%;
}
.atwho-wrap {
  border: 1px solid #c4c4c4;
  border-top: 0px;
}
.user-temp {
  max-height: 550px;
  height: 550px !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.templateContent {
  width: 100% !important;
}
.fr-toolbar {
  z-index: 1;
}
.document-temp.v-dialog .center-sec-layout {
  margin: 0;
}
.input-group__details {
  margin-top: 8px;
}
</style>
