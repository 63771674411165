<template>
  <div>
    <div class="mb-2"><h4>Complete Helpline Job</h4></div>
    <div class="mb-1">
      <label>Job completion email sent on: </label>
      <span>{{ helplineEmail }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddRequestToCompleteHelplineJobModel from '@/models/requests/AddRequestToCompleteHelplineJobModel'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'

@Component
export default class AddRequestToCompleteHelplineJobLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddRequestToCompleteHelplineJobModel {
    return Object.assign(new AddRequestToCompleteHelplineJobModel(), this.requestBody)
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.item.jobId)
  }

  private get helplineEmail(): string {
    return this.job && this.job.helplinePolicy ? this.job.helplinePolicy.helplineEmail : ''
  }
}
</script>

<style scoped>
pre {
  display: inherit;
  font-family: inherit;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
