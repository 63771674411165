var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-layout',{attrs:{"wrap":""}},[(!_vm.fulfilledCutOff)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-alert',{staticClass:"custom-warning pa-2",attrs:{"value":true,"color":"red lighten-5 red--text","type":"error"}},[_c('span',[_vm._v("Cut-off period doesn't comply for this emergency.")])])],1):_vm._e(),(!_vm.isEmergencyCovered)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-alert',{staticClass:"custom-warning pa-2",attrs:{"value":true,"color":"red lighten-5 red--text","type":"error"}},[_c('span',[_vm._v("Emergency not covered in policy schedule.")])])],1):_vm._e(),_vm._l((_vm.keyItems),function(item){return _c('v-flex',{key:item.id,attrs:{"xs12":""}},[_c('span',[_vm._v(" "+_vm._s(item.questionText)+": "),_c('b',[_vm._v(_vm._s(item.answer)+" "+_vm._s(item.answer !== '' ? item.unit : ''))])])])}),(
      !_vm.emergency.isEmergencyAccepted &&
      _vm.emergency.repudiationDescription !== null &&
      _vm.emergency.repudiationDescription !== ''
    )?_c('v-flex',{attrs:{"xs12":"","mt-1":""}},[_c('label',{staticClass:"red--text"},[_c('b',[_vm._v("Reject Claim:")])]),_c('span',[_vm._v(_vm._s(_vm.emergency.repudiationDescription))])]):_vm._e(),(!_vm.emergency.isEmergencyAccepted && _vm.emergency.acceptRejectNote !== null && _vm.emergency.acceptRejectNote !== '')?_c('v-flex',{attrs:{"xs12":"","mt-1":""}},[_c('label',{staticClass:"red--text"},[_c('b',[_vm._v("Additional Info:")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"repudiation-description"},on),[_vm._v(" "+_vm._s(_vm.emergency.acceptRejectNote)+" ")])]}}],null,false,339721826)},[_c('span',[_vm._v(_vm._s(_vm.emergency.acceptRejectNote))])])],1):(
      _vm.emergency.isEmergencyAccepted && _vm.emergency.acceptRejectNote !== null && _vm.emergency.acceptRejectNote !== ''
    )?_c('v-flex',{attrs:{"xs12":"","mt-1":""}},[_c('label',{staticClass:"green--text"},[_c('b',[_vm._v("Accept Claim:")])]),_c('span',[_vm._v(_vm._s(_vm.emergency.acceptRejectNote))])]):_vm._e(),(
      (_vm.emergency.isEmergencyAccepted ||
        _vm.emergency.isEmergencySoftFixed ||
        (!_vm.emergency.isEmergencyAccepted &&
          _vm.emergency.repudiationDescription !== null &&
          _vm.emergency.repudiationDescription !== '')) &&
      _vm.emergency.emergencyCompletedAt
    )?_c('v-flex',{attrs:{"xs12":"","mt-1":""}},[(
        _vm.emergency.isEmergencyAccepted &&
        (_vm.emergency.repudiationDescription === null || _vm.emergency.repudiationDescription === '')
      )?_c('label',[_c('b',[_vm._v("Accepted at:")])]):_vm._e(),(
        _vm.emergency.isEmergencySoftFixed &&
        (_vm.emergency.repudiationDescription === null || _vm.emergency.repudiationDescription === '')
      )?_c('label',[_c('b',[_vm._v("Soft Fixed at:")])]):(!_vm.emergency.isEmergencyAccepted)?_c('label',[_c('b',[_vm._v("Rejected at:")])]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.getFormattedDateTime(_vm.emergency.emergencyCompletedAt)))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }