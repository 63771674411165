<template>
  <div>
    <div class="mb-2"><h4>Close Complaint</h4></div>
    <div v-if="complaint.complaintTypeDescription" class="mb-1">
      <label>Complaint Type: </label>
      <span>{{ complaint.complaintTypeDescription }}</span>
    </div>
    <div v-if="complaint.severity !== ''" class="mb-1">
      <label>Severity: </label>
      <span>{{ complaint.severity }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddCloseComplaintRequest from '@/models/requests/AddCloseComplaintRequest'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'

@Component
export default class AddCloseComplaintRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddCloseComplaintRequest {
    return Object.assign(new AddCloseComplaintRequest(), this.requestBody)
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.item.jobId)
  }

  private get complaint() {
    return this.item.jobId && this.job ? this.job.complaint.find((c) => c.id === this.item.complaintId) : undefined
  }
}
</script>
