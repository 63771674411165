import { AxiosError, AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import SupervisorCall from '@/models/twilio/SupervisorCall'
import JobHeader from '@/models/JobHeader'
import CallSidResponse from '@/api/models/conference/CallSidResponse'
import WorkerModelResponse from './models/WorkerModelResponse'
import ReservationModelResponse from './models/ReservationModelResponse'

export default class TwilioController {
  public static async GetWorkerAccessToken(): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get('twilio-authentication/worker-access-token?api-version=2.0')
    if (res.status === 200) {
      const result = res.data as string
      if (!result) {
        return ''
      }
      return result
    } else {
      return ''
    }
  }

  public static async GetClientToken(): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get('twilio-authentication/client-token')
    if (res.status === 200) {
      const result = res.data as string
      if (!result) {
        return ''
      }
      return result
    } else {
      return ''
    }
  }

  public static async GetWorkspaceAccessToken(): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get('twilio-authentication/workspace-access-token')
    if (res.status === 200) {
      const result = res.data as string
      if (!result) {
        return ''
      }
      return result
    } else {
      return ''
    }
  }

  public static async ConferenceHold(currentParticipantCallSid: string, hold: boolean): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Conference/Hold?currentParticipantCallSid=' +
        encodeURIComponent(currentParticipantCallSid) +
        '&holdFlag=' +
        hold,
      null
    )
    if (res.status === 200) {
      const result = res.data as boolean
      return result
    } else {
      return false
    }
  }

  public static async UpdateWorkerQueueStatus(workerSid: string, inQueue: boolean): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Worker/UpdateQueueStatus?workerSid=' + encodeURIComponent(workerSid) + '&inQueue=' + inQueue,
      null
    )
    return res.status >= 200 && res.status < 300
  }

  public static async AddNewParticipantToConference(currentParticipantCallSid: string, to: string): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Conference/AddNewParticipantToConference?currentParticipantCallSid=' +
        encodeURIComponent(currentParticipantCallSid) +
        '&to=' +
        encodeURIComponent(to),
      null
    )
    if (res.status === 200) {
      const result = res.data as string
      return result
    } else {
      return ''
    }
  }

  public static async RemoveParticipantFromConference(currentParticipantCallSid: string, to: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Conference/RemoveParticipantFromConference?currentParticipantCallSid=' +
        encodeURIComponent(currentParticipantCallSid) +
        '&transferCallSid=' +
        encodeURIComponent(to),
      null
    )
    return res.status >= 200 && res.status < 300
  }

  public static async HandleAgentLeavingConference(currentParticipantCallSid: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Conference/HandleAgentLeavingConference?callSid=' + encodeURIComponent(currentParticipantCallSid),
      null
    )
    return res.status >= 200 && res.status < 300
  }

  public static async ReturnCallerToQueue(agentCallSid: string, taskSid: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Conference/ReturnCallerToQueue?agentcallSid=' +
        encodeURIComponent(agentCallSid) +
        '&taskSid=' +
        encodeURIComponent(taskSid),
      null
    )
    return res.status >= 200 && res.status < 300
  }

  public static async JoinConference(workerSid: string, muted = true): Promise<SupervisorCall> {
    const res: AxiosResponse = await CallCentreApi.post<SupervisorCall>(
      'Conference/JoinConference?workerSid=' + encodeURIComponent(workerSid) + '&muted=' + muted,
      null
    )
    return res.data
  }

  public static async Coach(workerSid: string): Promise<SupervisorCall> {
    const res: AxiosResponse = await CallCentreApi.post<SupervisorCall>(
      'Conference/Coach?workerSid=' + encodeURIComponent(workerSid),
      null
    )
    return res.data
  }

  public static async LeaveConference(participantCallSid: string, conferenceSid: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Conference/LeaveConference?participantCallSid=' +
        encodeURIComponent(participantCallSid) +
        '&conferenceSid=' +
        encodeURIComponent(conferenceSid),
      null
    )
    return res.status >= 200 && res.status < 300
  }

  public static async LogoutWorker(): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get('Worker/LogoutWorker')
    return res.status >= 200 && res.status < 300
  }

  public static async GetAllWorkers(): Promise<WorkerModelResponse[]> {
    const { status, data } = await CallCentreApi.get<WorkerModelResponse[]>('Worker/GetAllWorkers')
    return status >= 200 && status < 300 ? data : []
  }

  public static async GetReservationsForWorker(workerSid: string): Promise<ReservationModelResponse[]> {
    const { status, data } = await CallCentreApi.get<ReservationModelResponse[]>(
      `Worker/GetReservationsForWorker?workerSid=${workerSid}`
    )
    return status >= 200 && status < 300 ? data : []
  }

  public static async CompleteWorkerReservation(workerSid: string, reservationSid: string): Promise<boolean> {
    const { status } = await CallCentreApi.post(
      `Worker/CompleteWorkerReservation?workerSid=${workerSid}&reservationSid=${reservationSid}`
    )
    return status >= 200 && status < 300
  }

  public static async PauseRecording(callSid: string, pauseFlag: boolean): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get<boolean>(
      'Conference/PauseRecording?callSid=' + encodeURIComponent(callSid) + '&pauseFlag=' + pauseFlag
    )
    return res.data
  }

  public static async VerifyCallDetailForLinkJob(callSid: string): Promise<boolean | JobHeader[]> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Conference/VerifyCallDetailForLinkJob?callSid=' + encodeURIComponent(callSid)
    )
    if (res.status === 200) {
      const result = res.data as JobHeader[]
      if (!result) {
        return []
      }
      return result
    } else {
      return res.status === 202 ? true : false
    }
  }

  public static async UpdateCallDetail(callSid: string, jobId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get<boolean>(
      'Conference/UpdateCallDetail?callSid=' + encodeURIComponent(callSid) + '&jobId=' + jobId
    )
    return res.status >= 200 && res.status < 300
  }

  public static async TriggerAutoDeployment(callSid: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Conference/TriggerAutoDeployment?callSid=' + encodeURIComponent(callSid)
    )
    return res.status >= 200 && res.status < 300
  }

  public static async RetrieveCustomerCallSid(agentCallSid: string): Promise<CallSidResponse> {
    try {
      const res: AxiosResponse = await CallCentreApi.post(
        'Conference/RetrieveCustomerCallSid?agentCallSid=' + agentCallSid,
        null
      )

      const response: CallSidResponse = {
        callSid: res.data,
        exception: null,
      }

      return response
    } catch (err) {
      console.log(err)

      if (err instanceof AxiosError) {
        if (err.response && err.response.data && err.response.data.ExceptionMessage) {
          const response: CallSidResponse = {
            callSid: null,
            exception: err.response.data.ExceptionMessage,
          }

          return response
        }
      }

      throw err
    }
  }
}
