<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-autocomplete
          v-model="selectedTimeRequestSlot"
          v-validate="'required'"
          :items="timeRequestList"
          label="Add Hours"
          item-text="text"
          item-value="value"
          required
          data-vv-name="Additional Hours"
          :error-messages="errors.collect('Additional Hours')"
          class="required timeRequestList"
          @change="OnTimeRequestChange($event)"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12>
        <v-textarea
          id="Reason"
          v-model="value.timeRequestReason"
          v-validate="'required'"
          name="timeRequestReason"
          label="Reason"
          class="required"
          required
          data-vv-name="Reason"
          :error-messages="errors.collect('Reason')"
        ></v-textarea>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EngineerExtraTimeRequest from '@/models/claim/engineerRequest/EngineerExtraTimeRequest'
import { TimeSpanSlots } from '@/common/interfaces'
import DateTimePicker from '@/components/DateTimePicker.vue'
import Shared from '@/common/shared'

@Component({})
export default class AddTimeRequest extends Vue {
  @Prop() private value: EngineerExtraTimeRequest
  private selectedTimeRequestSlot = 0
  private timeRequestList: TimeSpanSlots[] = []

  @Watch('value')
  private onRequestUpdated() {
    this.$emit('input', this.value)
  }

  private mounted() {
    this.timeRequestList = Shared.getTimeSpanRangeList()
    this.selectedTimeRequestSlot = this.timeRequestList[0].value
    this.$emit('updateSelectedTimeRequestSlot', this.selectedTimeRequestSlot)
  }

  private OnTimeRequestChange(selectedTimeRequestSlot: number) {
    this.$emit('updateSelectedTimeRequestSlot', selectedTimeRequestSlot)
  }
}
</script>
