import { ProductCategory } from "@/models/ProductCategory";

let prospectSoftCategories = [
    new ProductCategory("1", "Crossrail"),
    new ProductCategory("2", "Materials Testing"),
    new ProductCategory("3", "Site Investigations"),
    new ProductCategory("4", "Drain Repairs"),
    new ProductCategory("5", "Drains Response"),
    new ProductCategory("6", "Remote Validation"),
    new ProductCategory("9", "FNOL Building Claims"),
    new ProductCategory("11", "Emergency Repairs"),
    new ProductCategory("14", "Building Monitoring"),
    new ProductCategory("15", "Restiration"),
    new ProductCategory("0", "Overheads")
];

let defaultProspectSoftCategory = prospectSoftCategories[7]; // Emergency Repairs

export { prospectSoftCategories, defaultProspectSoftCategory }

