<template>
  <v-snackbar v-model="showSnackbar" bottom :color="snackbarColor" :timeout="60000">
    {{ text }}
    <v-btn flat color="white" @click.native="snackbarClicked">Refresh</v-btn>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Store from '@/store'
import { ConnectionStatus } from '@/common/environment'

@Component
export default class ConnectionStatusBar extends Vue {
  private get showSnackbar(): boolean {
    return Store.Instance.state.Environment.ConnectionStatus === ConnectionStatus.Offline
  }
  private get text(): string {
    switch (Store.Instance.state.Environment.ConnectionStatus) {
      case ConnectionStatus.Offline:
        return 'You are now offline!'
      default:
        return ''
    }
  }
  private get snackbarColor(): string {
    switch (Store.Instance.state.Environment.ConnectionStatus) {
      case ConnectionStatus.Offline:
        return 'error'
      default:
        return 'info'
    }
  }

  private snackbarClicked(): void {
    window.location.reload()
  }
}
</script>
