<template>
  <div class="package-selection-content">
    <v-card v-if="packageState" class="elevation-0 pa-3">
      <v-form autocomplete="off" class="mb-4">
        <PackageSelection
          ref="packageSelection"
          v-model="packageState"
          :claim-management-company-id="job.sIJobDetail.claimManagementCompanyId"
          :disabled="!editMode || !userCanUpdate || isJobCompleted"
          :job-id="jobId"
          :disabled-monitoring-product="!editMode || !userCanUpdate"
          :is-testing-only-job="isTestingOnlyJob"
          @loadedAPIData="() => (disableEditMode = false)"
        />
      </v-form>
      <div class="action-btn-content grey lighten-4">
        <v-card wrap class="elevation-0 grey lighten-4">
          <v-card-actions xs12>
            <v-spacer></v-spacer>
            <v-btn
              v-if="userCanUpdate && !editMode"
              color="primary"
              class="ma-0"
              :disabled="disableEditMode || isJobCancelled"
              @click="() => (editMode = true)"
            >
              Edit
            </v-btn>
            <template v-if="editMode && userCanUpdate">
              <v-btn color="" class="ma-0 mr-3" @click="cancelEdit">Cancel</v-btn>
              <v-btn color="primary" class="ma-0" :loading="isSaving" @click="validateAndSave">Save</v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </div>
      <v-snackbar v-model="showSnackbar" top multi-line color="error" :timeout="snackbarTimeout">
        <h4>{{ snackbarText }}</h4>
        <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import JobPackageState from '../../models/siteInvestigation/JobPackageState'
import PackageSelection from '@/components/PackageSelection.vue'
import eventBus from '@/common/bus'
import Store from '@/store'
import Shared from '@/common/shared'
import JobController from '@/api/jobController'
import UpdateSIJobPackage from '@/models/requests/UpdateSIJobPackage'

@Component({
  components: { PackageSelection },
})
export default class JobPackagePreview extends TimeLineItemBase {
  private get userCanUpdate() {
    return Store.Instance.state.SessionDetail.canUpdateSIPackage
  }

  public editMode = false
  private packageState: JobPackageState | null = null

  private snackbarTimeout = 3000
  private snackbarText = ''
  private showSnackbar = false
  private isSaving = false
  private disableEditMode = true // used to disable edit button until API data loaded

  public mounted() {
    this.refreshState()
  }

  private refreshState() {
    if (this.job && this.job.sIJobDetail) {
      this.packageState = new JobPackageState(this.job.sIJobDetail.jobPackage)
      const packageSelection = this.$refs.packageSelection as PackageSelection
      packageSelection.reloadComponent()
    }
  }

  private cancelEdit() {
    this.editMode = false
    this.refreshState()
  }

  private async validateAndSave() {
    if (!this.job || !this.job.sIJobDetail || !this.packageState) {
      return
    }
    const packageSelection = this.$refs.packageSelection as PackageSelection
    const configIssues = this.packageState.configurationIssues
    if (configIssues) {
      this.snackbarText = configIssues
      this.showSnackbar = true
      return
    }
    const validationResult = await packageSelection.validatePackageSelection()
    if (!validationResult) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      return
    }
    const dehydratedModel = this.packageState.dehydratedModel
    if (packageSelection) {
      // set base price in product child link of monitoring product
      const monitoringProducts = JSON.parse(JSON.stringify(dehydratedModel.monitoringProducts))
      monitoringProducts.forEach((monitoringProduct) => {
        const selectedProduct = packageSelection.tempMonitoringProductItems.find(
          (e) => e.value.id === monitoringProduct.id
        )
        monitoringProduct.productChildLinks = selectedProduct
          ? selectedProduct.value.productChildLinks
          : monitoringProduct.productChildLinks
      })
      dehydratedModel.monitoringProducts = monitoringProducts
    }
    this.isSaving = true
    const res = await JobController.UpdateSIJobPackage(
      Object.assign(new UpdateSIJobPackage(), {
        jobId: this.job.id,
        claimManagementCompanyId: this.job.sIJobDetail.claimManagementCompanyId,
        jobPackage: dehydratedModel,
      })
    )
    this.isSaving = false
    if (!res) {
      eventBus.$emit('errorHandler', 'There was an error submitting the form. Please try again later', true)
      return
    } else {
      if (!this.$parent) {
        throw new Error('Missing parent component')
      }
      this.$parent.$emit('closePreview')
    }
  }

  private get isJobCompleted() {
    return this.job && this.job.status === 'Completed'
  }

  private get isJobCancelled() {
    return this.job && (this.job.cancellationReason || this.job.status === 'Cancelled') ? true : false
  }

  private get isTestingOnlyJob() {
    return this.job && this.job.isTestingOnlyJob
  }
}
</script>

<style scoped>
.package-selection-content {
  position: relative;
  padding-bottom: 50px;
}

.action-btn-content {
  position: fixed;
  left: 33.3%;
  right: 26.5%;
  bottom: 0px;
  background: #fff;
  padding: 10px 10px;
  z-index: 1;
}
@media only screen and (max-width: 1440px) {
  .action-btn-content >>> .v-card__actions .v-btn__content {
    font-size: 12px;
  }
  .action-btn-content {
    right: 26.9%;
  }
}
@media only screen and (max-width: 1300px) {
  .action-btn-content {
    right: 25.3%;
    left: 38%;
  }
}
@media only screen and (max-width: 1299px) {
  .action-btn-content >>> .v-card__actions .v-btn {
    padding: 0 10px;
  }
  .action-btn-content >>> .v-card__actions .v-btn__content {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1100px) {
  .action-btn-content >>> .v-card__actions .v-btn {
    padding: 0 4px;
  }
}
</style>
