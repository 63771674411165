<template>
  <div v-if="smsId">
    <div class="mb-2"><h4>Incoming Job Sms</h4></div>
    <div v-if="jobSmsText">
      <div v-if="jobSmsText.to" class="mb-1">
        <label>Phone Number: </label>
        <span>{{ jobSmsText.to }}</span>
      </div>
      <div v-if="jobSmsText.body" class="mb-1">
        <label>Text: </label>
        <span>{{ jobSmsText.body }}</span>
      </div>
      <div v-if="jobSmsText.status" class="mb-1">
        <label>Status: </label>
        <span>{{ jobSmsText.status }}</span>
      </div>
      <div v-if="jobSmsText.dateSent" class="mb-1">
        <label>Received at: </label>
        <span>{{ getFormattedDate(jobSmsText.dateSent) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import storeGetters from '@/storeGetters'
import SMSModel from '@/models/claim/SMSModel'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class SaveIncomingJobSmsLog extends Vue {
  @Prop() private requestBody: any

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get jobSmsText(): SMSModel | undefined {
    return this.requestedJob ? this.requestedJob.smsTexts.find((x) => x.id === this.requestBody.smsSid) : undefined
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }
}
</script>

<style scoped></style>
