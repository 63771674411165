export default class DisputeContractorInvoiceModel {
  public contractorId: string
  public disputeContractorJobs: DisputeContractorJob[]
  public disputedReason?: string
}

export class DisputeContractorJob {
  public jobId: string
  public invoiceId: string
}
