<template>
  <div>
    <div class="mb-2"><h4>Soft Fixed Emergency</h4></div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="softFixedEmergencyTitle" class="mb-1">
      <label>Title: </label>
      <span>{{ softFixedEmergencyTitle }}</span>
    </div>
    <div v-if="item.description" class="mb-1">
      <label>Description: </label>
      <span>{{ item.description }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddEmergencySoftFix from '@/models/requests/AddEmergencySoftFix'
import Shared from '@/common/shared'
import MasterRecord from '@/models/MasterRecord'

@Component
export default class AddEmergencySoftFixedLog extends Vue {
  @Prop() private requestBody: any
  @Prop() private softFixEmergencyTitle: string

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.item.emergencyId)
  }

  private get item(): AddEmergencySoftFix {
    return Object.assign(new AddEmergencySoftFix(), this.requestBody)
  }

  private get softFixedEmergencyTitles(): MasterRecord[] {
    const masterRecords: MasterRecord[] = this.$store.getters['jobModule/masterRecords']
    return masterRecords.filter((x) => x.type === 'SoftFixedEmergencyTitle')
  }

  private get softFixedEmergencyTitle() {
    const title = this.softFixedEmergencyTitles.find((c: MasterRecord) => c.id === this.item.title)
    return title ? title.description : ''
  }
}
</script>

<style scoped></style>
