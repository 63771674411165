import ContractorRateModel from '@/models/contractor/ContractorRateModel'
import CosmosAdminDbRecordBase from '@/models/CosmosAdminDbRecordBase'

export default class ContractorRatePackageModel extends CosmosAdminDbRecordBase {
  public forCustomPackageContractorId: string | null
  public packageName: string
  public materialUplift: number
  public sequence: number
  public contractorRates: ContractorRateModel[]
  public abortedVisit: number

  public constructor() {
    super()
    this.materialUplift = 0
    this.sequence = 0
    this.contractorRates = [new ContractorRateModel()]
    this.abortedVisit = 0
  }
}
