export default class ClientRateUSModel {
  public subCode: string
  public subCategory: string
  public description: string
  public unit: string
  public unitPrice: number
  public vatRate: number
  public comment: string

  public constructor() {
    this.unitPrice = 0
    this.vatRate = 0
  }
}
