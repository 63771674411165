<template>
  <div>
    <div class="mb-2"><h4>Overwrite Client Invoice Cost</h4></div>
    <div v-if="item.overrideCost && item.invoiceCostType && job && job.jobType !== 'SI'" class="mb-1">
      <label>Cost Updated For: </label>
      <span>{{ item.invoiceCostType }}</span>
    </div>
    <div v-if="clientInvoiceDetail && clientInvoiceDetail.originalInvoiceDetail" class="mb-1">
      <label>Original Cost: </label>
      <span>{{ getFormatedCurrency(getOriginalInvoiceCost) }}</span>
    </div>
    <div v-if="item.overrideCost" class="mb-1">
      <label>Overwrite Cost: </label>
      <span>{{ getFormatedCurrency(item.overrideCost) }}</span>
    </div>
    <div v-if="item.overrideReason" class="mb-1">
      <label>Overwrite Reason: </label>
      <span>{{ item.overrideReason }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import OverrideInvoiceCostRequestModel from '@/models/requests/OverrideInvoiceCostRequestModel'
import ClientInvoiceDetailModel from '@/models/claim/ClientInvoiceDetailModel'
import Shared from '@/common/shared'
import { InvoiceCostType } from '@/common/enums'
import Job from '@/models/Job'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class OverrideClientInvoiceCostLog extends Vue {
  @Prop() private requestBody: any

  private get item(): OverrideInvoiceCostRequestModel {
    return Object.assign(new OverrideInvoiceCostRequestModel(), this.requestBody)
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get clientInvoiceDetail(): ClientInvoiceDetailModel | null {
    if (this.requestedJob && this.requestedJob.clientInvoiceDetails.length > 0) {
      const invoiceDetail = this.requestedJob.clientInvoiceDetails.find((e) => e.id === this.item.id)
      return invoiceDetail ? invoiceDetail : null
    }
    return null
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private get getOriginalInvoiceCost() {
    if (this.clientInvoiceDetail) {
      const invoiceCostType = this.item.invoiceCostType.toString()
      switch (invoiceCostType) {
        case InvoiceCostType[InvoiceCostType.Labour]:
          return this.clientInvoiceDetail.originalInvoiceDetail.labourValue
        case InvoiceCostType[InvoiceCostType.Material]:
          return this.clientInvoiceDetail.originalInvoiceDetail.materialValue
        case InvoiceCostType[InvoiceCostType.HandlingFee]:
          return this.clientInvoiceDetail.originalInvoiceDetail.handlingFee
        default:
          return ''
      }
    }
    return ''
  }
}
</script>
