<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Third Party</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.native="closeThirdPartyDetail">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text class="scroll-content-dialog px-3 pt-2 pb-4">
      <v-layout wrap>
        <v-flex xs12 class="job-search">
          <v-autocomplete
            v-model="localSelectedContractor"
            :items="contractors"
            item-text="companyName"
            item-value="id"
            label="Search contractor"
            append-icon="close"
            @click:append="removeSelectedContracotr"
          ></v-autocomplete>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn flat color="primary" @click="closeThirdPartyDetail">Cancel</v-btn>
      <v-btn color="primary" :disabled="isLoading" :loading="isLoading" @click.native="saveThirdPartyDetail">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorModel from '@/models/contractor/ContractorModel'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'

@Component
export default class ThirdPartyDetail extends Vue {
  @Prop() private contractors: ContractorModel[]
  @Prop() private thirdPartyContractorId: string
  @Prop() private emergencyId: number
  private localSelectedContractor = ''
  private isLoading = false

  private created() {
    this.localSelectedContractor = this.thirdPartyContractorId
  }

  @Watch('thirdPartyContractorId')
  private setLocalSelectedContractor(newValue: string) {
    this.localSelectedContractor = newValue
  }

  private removeSelectedContracotr() {
    this.localSelectedContractor = ''
  }

  private saveThirdPartyDetail() {
    this.isLoading = true
    const thirdPartyTrade: EmergencyThirdPartyTrade = new EmergencyThirdPartyTrade()
    thirdPartyTrade.emergencyTypeId = this.emergencyId
    thirdPartyTrade.contractorId = this.localSelectedContractor
    this.$emit('saveThirdPartyDetail', thirdPartyTrade)
  }

  private closeThirdPartyDetail() {
    this.$emit('closeThirdPartyDetail')
  }
}
</script>
