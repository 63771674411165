import ShowSnackbar from '@/models/snackbar/show-snackbar';
import SnackbarDisplay from '@/models/snackbar/snackbar-display';
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { ISnackbarState } from '../states/interfaces/snackbar-state';
import SnackbarState from '../states/snackbar-state'

const snackbarState: ISnackbarState = new SnackbarState()

const getters: GetterTree<ISnackbarState, any> = {
  showSnackbar: (state) => state.show,
  snackbar: (state) => {
    const d = new SnackbarDisplay()
    d.color = state.color
    d.icon = state.icon
    d.message = state.message
    d.showClose = state.showClose
    d.timeout = state.timeout
    return d
  },
}

const mutations: MutationTree<ISnackbarState> = {
  setSnackbar: (state, args: ShowSnackbar) => {
    state.color = args.color
    state.icon = args.icon
    state.message = args.message
    state.showClose = args.showClose
    state.timeout = args.timeout
    state.show = true
  },
  setShowSnackbar: (state, show: boolean) => {
    state.show = show
  },
}

const actions: ActionTree<ISnackbarState, any> = {
  async showSnackbar({ commit }, args: ShowSnackbar) {
    commit('setSnackbar', args)
  },
  async closeSnackbar({ commit }) {
    commit('setShowSnackbar', false)
  },
}

export const snackbarModule: Module<ISnackbarState, any> = {
  state: snackbarState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
