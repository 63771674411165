import InsurerModel from '@/models/policy/InsurerModel'
import PolicyModel from '@/models/policy/PolicyModel'
import { IClientState } from './interfaces/client-state'

export default class ClientState implements IClientState {
  public loading = false
  public clients: InsurerModel[] = []
  public selectedClientId: number | null = null
  public selectedPolicyId: number | null = null
  public policies: PolicyModel[] = []
}
