<template>
  <v-card class="log-details">
    <v-card-title class="py-0">
      <h3>{{ isRequestStream ? 'Request Stream' : 'Job Audit Log' }} - {{ jobId }}</h3>
      <v-spacer></v-spacer>
      <div v-if="!isRequestStream">
        <span class="grey--text swich-label mr-2"><b>Success</b></span>
        <span>
          <v-switch v-model="isSuccess" color="primary" small hide-details></v-switch>
        </span>
      </div>
      <v-btn icon class="mr-0" @click="closeModal">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-0 pt-2 scroll-content-dialog">
      <v-layout mt-0 class="scrollvb-content">
        <v-flex xs12>
          <v-layout wrap>
            <v-progress-circular
              v-show="jobRequests.length === 0 && requestedJob == null"
              :width="2"
              :size="50"
              indeterminate
              color="primary"
              :style="getLoaderStyle(70)"
            ></v-progress-circular>
            <textarea id="clipboardText" class="clipboard" name="cbTxt" rows="4" cols="4" />
            <v-container fluid class="container d-flex px-3 py-1 fluid">
              <v-checkbox
                v-if="isRequestStream"
                hide-details
                class="align-center justify-end"
                @change="toggleSelectAll()"
              >
                <template #prepend>
                  <v-label>Select All</v-label>
                </template>
              </v-checkbox>
            </v-container>
            <template v-if="jobRequests.length > 0">
              <v-flex
                v-for="(request, index) in jobRequests"
                :key="index"
                xs12
                px-3
                py-2
                :class="
                  detailshow && activeLogIndex === index ? ' show-details grey lighten-3 log-details' : 'log-details'
                "
              >
                <v-checkbox
                  v-if="isRequestStream"
                  v-model="requestsToCopy"
                  class="log-status"
                  :value="`${request.requestType}-${request.id}`"
                ></v-checkbox>
                <span v-else class="log-status">
                  <b :class="requestProcessStatus(request.processStatus)">
                    {{ request.processStatus }}
                  </b>
                </span>
                <div class="show-log-details">
                  <div class="show-inner-content">
                    <component
                      :is="isExisitingComponent(request.requestType)"
                      v-if="isExisitingComponent(request.requestType)"
                      :id="`${request.requestType}-${request.id}`"
                      v-bind="{ 'request-body': request.requestBody, 'request-type': request.requestType }"
                    ></component>
                    <DefaultRequestLog v-else :id="`${request.requestType}-${request.id}`" :request="request" />
                  </div>
                </div>
                <v-layout mt-3>
                  <v-flex xs8 mb-1>
                    <span
                      :id="`processedByData-${request.requestType}-${request.id}`"
                      class="text-xs-right mt-2 person-entry grey--text text--darken-1"
                    >
                      Process {{ getUserName(request) }} at
                      {{ getProcessedAt(request) }}
                    </span>
                  </v-flex>
                  <v-flex xs4 text-xs-right>
                    <v-btn small flat class="pa-0 ma-0" color="primary" @click="showdetails(index)">
                      <span :class="detailshow && activeLogIndex === index ? 'hide' : 'show'">
                        More Details
                        <v-icon small>keyboard_arrow_down</v-icon>
                      </span>
                      <span :class="detailshow && activeLogIndex === index ? 'show' : 'hide'">
                        Less Details
                        <v-icon small>keyboard_arrow_up</v-icon>
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider v-if="request.length != index" class="0"></v-divider>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions v-if="isRequestStream && jobRequests.length > 0">
      <v-spacer></v-spacer>
      <v-btn color="primary" :disabled="!requestsToCopy.length" @click="copyToClipboard()">Copy To Clipboard</v-btn>
    </v-card-actions>
    <v-snackbar v-model="isCopiedToClipboard" :color="'green'" :timeout="1500" top center>
      <span>Copied To Clipboard</span>
    </v-snackbar>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import AddHEJobWithEmergenciesLog from '@/components/jobauditlog/AddHEJobWithEmergenciesLog.vue'
import AddJobNoteLog from '@/components/jobauditlog/AddJobNoteLog.vue'
import AddCustomerAvailabilityTimeSlotLog from '@/components/jobauditlog/AddCustomerAvailabilityTimeSlotLog.vue'
import AddCustomerToPayLog from '@/components/jobauditlog/AddCustomerToPayLog.vue'
import AddContractorAppointedDetailLog from '@/components/jobauditlog/AddContractorAppointedDetailLog.vue'
import AddEmergencyLog from '@/components/jobauditlog/AddEmergencyLog.vue'
import AddUpdateComplaintLog from '@/components/jobauditlog/AddUpdateComplaintLog.vue'
import AddEmergencyAnswerLog from '@/components/jobauditlog/AddEmergencyAnswerLog.vue'

import AddVulnerabilityAndHealthSafetyAnswerLog from '@/components/jobauditlog/AddVulnerabilityAndHealthSafetyAnswerLog.vue'
import UpdateEmergencyAcceptedOrRejectedLog from '@/components/jobauditlog/UpdateEmergencyAcceptedOrRejectedLog.vue'
import UpdateCustomerPaymentDetailLog from '@/components/jobauditlog/UpdateCustomerPaymentDetailLog.vue'
import UpdateContractorAppointedDetailLog from '@/components/jobauditlog/UpdateContractorAppointedDetailLog.vue'
import UpdateEngineerRequestStatusLog from '@/components/jobauditlog/UpdateEngineerRequestStatusLog.vue'
import UpdateContractorAppointedStatusLog from '@/components/jobauditlog/UpdateContractorAppointedStatusLog.vue'
import AddJobWithPolicyEnquiryLog from '@/components/jobauditlog/AddJobWithPolicyEnquiryLog.vue'
import SendJobSMSLog from '@/components/jobauditlog/SendJobSMSLog.vue'
import UpdateJobFNOLCallRecordingUrlLog from '@/components/jobauditlog/UpdateJobFNOLCallRecordingUrlLog.vue'
import UpdateJobNoteCallRecordingUrlLog from '@/components/jobauditlog/UpdateJobNoteCallRecordingUrlLog.vue'
import UpdateCustomerDetailLog from '@/components/jobauditlog/UpdateCustomerDetailLog.vue'
import AddThirdPartyAppointedContractorLog from '@/components/jobauditlog/AddThirdPartyAppointedContractorLog.vue'
import AddEmergencySoftFixedLog from '@/components/jobauditlog/AddEmergencySoftFixedLog.vue'
import SendJobEmailLog from '@/components/jobauditlog/SendJobEmailLog.vue'
import UpdateVisitDetailForAcceptRejectJobLog from '@/components/jobauditlog/UpdateVisitDetailForAcceptRejectJobLog.vue'
import UpdateEngineerJobVisitStatusLog from '@/components/jobauditlog/UpdateEngineerJobVisitStatusLog.vue'
import AddEngineerVisitCompleteLog from '@/components/jobauditlog/AddEngineerVisitCompleteLog.vue'
import UpdateEmergencyFollowOnWorkLog from '@/components/jobauditlog/UpdateEmergencyFollowOnWorkLog.vue'
import UpdateEngineerJobToCompleteLog from '@/components/jobauditlog/UpdateEngineerJobToCompleteLog.vue'
import AddEngineerMaterialAndExtraTimeRequestLog from '@/components/jobauditlog/AddEngineerMaterialAndExtraTimeRequestLog.vue'
import AddEngineerRunningLateRequestLog from '@/components/jobauditlog/AddEngineerRunningLateRequestLog.vue'
import UpdateCustomerAddressDetailLog from '@/components/jobauditlog/UpdateCustomerAddressDetailLog.vue'
import CancelAssignedJobContractorLog from '@/components/jobauditlog/CancelAssignedJobContractorLog.vue'
import SaveIncomingJobSmsLog from '@/components/jobauditlog/SaveIncomingJobSmsLog.vue'
import AddAccessNoteAnswerLog from '@/components/jobauditlog/AddAccessNoteAnswerLog.vue'
import DefaultRequestLog from '@/components/jobauditlog/DefaultRequestLog.vue'
import AddAdditionalCostAuthorisedRequestLog from '@/components/jobauditlog/AddAdditionalCostAuthorisedRequestLog.vue'
import AddRequestToEscalateCTPProcessLog from '@/components/jobauditlog/AddRequestToEscalateCTPProcessLog.vue'
import OverrideClientInvoiceCostLog from '@/components/jobauditlog/OverrideClientInvoiceCostLog.vue'
import OverrideContractorInvoiceCostLog from '@/components/jobauditlog/OverrideContractorInvoiceCostLog.vue'
import AddRequestToCompleteHelplineJobLog from '@/components/jobauditlog/AddRequestToCompleteHelplineJobLog.vue'
import AddPictureUploadLog from '@/components/jobauditlog/AddPictureUploadLog.vue'
import UpdateClientInvoiceDetailStatusLog from '@/components/jobauditlog/UpdateClientInvoiceDetailStatusLog.vue'
import UpdateContractorInvoiceDetailStatusLog from '@/components/jobauditlog/UpdateContractorInvoiceDetailStatusLog.vue'
import UpdateJobPolicyScheduleLog from '@/components/jobauditlog/UpdateJobPolicyScheduleLog.vue'

import UpdateInvoiceDetailLog from '@/components/jobauditlog/UpdateInvoiceDetailLog.vue'
import AddEngineerRequestLog from '@/components/jobauditlog/AddEngineerRequestLog.vue'
import AddEngineerJobVisitNoteLog from '@/components/jobauditlog/AddEngineerJobVisitNoteLog.vue'
import UpdateHealthAndSafetyForJobLog from '@/components/jobauditlog/UpdateHealthAndSafetyForJobLog.vue'
import RequestModel from '@/models/RequestModel'
import { RequestType, RequestProcessStatus } from '@/common/enums'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import UserModel from '@/models/user/UserModel'
import EngineerAutoDeploymentLog from '@/components/jobauditlog/EngineerAutoDeploymentLog.vue'
import AddCloseJobRequestLog from '@/components/jobauditlog/AddCloseJobRequestLog.vue'
import AddRequestForCancelJobLog from '@/components/jobauditlog/AddRequestForCancelJobLog.vue'
import AssignEngineerRequestLog from '@/components/jobauditlog/AssignEngineerRequestLog.vue'
import AddEscalateJobRequestLog from '@/components/jobauditlog/AddEscalateJobRequestLog.vue'
import UploadJobDocumentLog from '@/components/jobauditlog/UploadJobDocumentLog.vue'
import RemoveUploadJobDocumentLog from '@/components/jobauditlog/RemoveUploadJobDocumentLog.vue'
import ContractorAutoDeploymentLog from '@/components/jobauditlog/ContractorAutoDeploymentLog.vue'
import RemoveEmergencyFromJobLog from '@/components/jobauditlog/RemoveEmergencyFromJobLog.vue'
import UpdateJobOffSiteTimeLog from '@/components/jobauditlog/UpdateJobOffSiteTimeLog.vue'
import UpdateGasSafetyForJobLog from '@/components/jobauditlog/UpdateGasSafetyForJobLog.vue'
import UpdateQuestionModeJobDetailsLog from '@/components/jobauditlog/UpdateQuestionModeJobDetailsLog.vue'
import AddDeleteComplaintDocumentLog from '@/components/jobauditlog/AddDeleteComplaintDocumentLog.vue'
import AddReopenComplaintRequestLog from '@/components/jobauditlog/AddReopenComplaintRequestLog.vue'
import AddComplaintEscalateRequestLog from '@/components/jobauditlog/AddComplaintEscalateRequestLog.vue'
import AddCloseComplaintRequestLog from '@/components/jobauditlog/AddCloseComplaintRequestLog.vue'
import ContractorAcceptAutoDeploymentJobLog from '@/components/jobauditlog/ContractorAcceptAutoDeploymentJobLog.vue'
import ContractorRejectAutoDeploymentJobLog from '@/components/jobauditlog/ContractorRejectAutoDeploymentJobLog.vue'
import UpdateSIJobDelayLog from '@/components/jobauditlog/UpdateSIJobDelayLog.vue'
import UpdateCCTVControlLog from '@/components/jobauditlog/UpdateCCTVControlLog.vue'
import UpdateCCTVControlRunLog from '@/components/jobauditlog/UpdateCCTVControlRunLog.vue'
import UpdateDatumDetailsLog from '@/components/jobauditlog/UpdateDatumDetailsLog.vue'
import UpdateTrialPitBoreholeLog from '@/components/jobauditlog/UpdateTrialPitBoreholeLog.vue'
import UpdateSITrialPitDetailsLog from '@/components/jobauditlog/UpdateSITrialPitDetailsLog.vue'
import AddAbandonedTrialPitRequestLog from '@/components/jobauditlog/AddAbandonedTrialPitRequestLog.vue'
import AddUpdateWaterMainsTestLog from '@/components/jobauditlog/AddUpdateWaterMainsTestLog.vue'
import DeleteWaterMainsTestLog from '@/components/jobauditlog/DeleteWaterMainsTestLog.vue'
import AddSIProductVerificationRequestLog from '@/components/jobauditlog/AddSIProductVerificationRequestLog.vue'
import UploadTrialPitDrawingLog from '@/components/jobauditlog/UploadTrialPitDrawingLog.vue'
import AddSIRevisitRequestLog from '@/components/jobauditlog/AddSIRevisitRequestLog.vue'
import DeleteBoreholeLog from '@/components/jobauditlog/DeleteBoreholeLog.vue'
import DeleteCCTVControlRunLog from '@/components/jobauditlog/DeleteCCTVControlRunLog.vue'
import UpdateMaterialCostRequestLog from '@/components/jobauditlog/UpdateMaterialCostRequestLog.vue'
import AddUpdateDrainageReportLog from '@/components/jobauditlog/AddUpdateDrainageReportLog.vue'
import JobNotReadyToCloseLog from '@/components/jobauditlog/JobNotReadyToCloseLog.vue'
import AddAbandonedSIProductRequestLog from '@/components/jobauditlog/AddAbandonedSIProductRequestLog.vue'
import CompleteWaterMainsTestRecordLog from '@/components/jobauditlog/CompleteWaterMainsTestRecordLog.vue'
import SaveOtherFeesDetailLog from '@/components/jobauditlog/SaveOtherFeesDetailLog.vue'
import AcceptProvisionedVisitLog from '@/components/jobauditlog/AcceptProvisionedVisitLog.vue'
import AddCustomerAppointedTradespeopleRequestLog from '@/components/jobauditlog/AddCustomerAppointedTradespeopleRequestLog.vue'
import AddCATInvoiceImageLog from '@/components/jobauditlog/AddCATInvoiceImageLog.vue'
import AddMonitoringVisitInvoiceLog from '@/components/jobauditlog/AddMonitoringVisitInvoiceLog.vue'
import AddRequestForDeEscalateJobLog from '@/components/jobauditlog/AddRequestForDeEscalateJobLog.vue'
import UpsertRepairEstimateRequestLog from '@/components/jobauditlog/UpsertRepairEstimateRequestLog.vue'
import AddReopenJobRequestLog from '@/components/jobauditlog/AddReopenJobRequestLog.vue'
import storeGetters from '@/storeGetters'
import RequestedJob from '@/models/requests/base/requested-job'

@Component({
  components: {
    AddHEJobWithEmergenciesLog,
    AddJobNoteLog,
    AddCustomerAvailabilityTimeSlotLog,
    AddCustomerToPayLog,
    AddContractorAppointedDetailLog,
    AddEmergencyLog,
    AddUpdateComplaintLog,
    AddVulnerabilityAndHealthSafetyAnswerLog,
    AddEmergencyAnswerLog,
    AddAccessNoteAnswerLog,

    UpdateEmergencyAcceptedOrRejectedLog,
    UpdateCustomerPaymentDetailLog,
    UpdateContractorAppointedDetailLog,
    UpdateEngineerRequestStatusLog,
    UpdateContractorAppointedStatusLog,
    AddJobWithPolicyEnquiryLog,
    SendJobSMSLog,
    UpdateJobFNOLCallRecordingUrlLog,
    UpdateJobNoteCallRecordingUrlLog,
    UpdateCustomerDetailLog,
    AddThirdPartyAppointedContractorLog,
    AddEmergencySoftFixedLog,
    SendJobEmailLog,
    UpdateVisitDetailForAcceptRejectJobLog,
    UpdateEngineerJobVisitStatusLog,
    AddEngineerVisitCompleteLog,
    UpdateEmergencyFollowOnWorkLog,
    UpdateEngineerJobToCompleteLog,
    AddEngineerMaterialAndExtraTimeRequestLog,
    AddEngineerRunningLateRequestLog,
    UpdateCustomerAddressDetailLog,
    CancelAssignedJobContractorLog,
    SaveIncomingJobSmsLog,
    DefaultRequestLog,
    UpdateInvoiceDetailLog,
    AddEngineerRequestLog,
    AddEngineerJobVisitNoteLog,
    UpdateHealthAndSafetyForJobLog,
    AddAdditionalCostAuthorisedRequestLog,
    AddRequestToEscalateCTPProcessLog,
    OverrideClientInvoiceCostLog,
    OverrideContractorInvoiceCostLog,
    AddRequestToCompleteHelplineJobLog,
    AddPictureUploadLog,
    EngineerAutoDeploymentLog,
    UpdateClientInvoiceDetailStatusLog,
    UpdateContractorInvoiceDetailStatusLog,
    AddCloseJobRequestLog,
    AddRequestForCancelJobLog,
    AssignEngineerRequestLog,
    AddEscalateJobRequestLog,
    UploadJobDocumentLog,
    RemoveUploadJobDocumentLog,
    UpdateJobPolicyScheduleLog,
    ContractorAutoDeploymentLog,
    RemoveEmergencyFromJobLog,
    UpdateJobOffSiteTimeLog,
    UpdateGasSafetyForJobLog,
    UpdateQuestionModeJobDetailsLog,
    AddDeleteComplaintDocumentLog,
    AddReopenComplaintRequestLog,
    AddComplaintEscalateRequestLog,
    AddCloseComplaintRequestLog,
    ContractorAcceptAutoDeploymentJobLog,
    ContractorRejectAutoDeploymentJobLog,
    UpdateSIJobDelayLog,
    UpdateCCTVControlLog,
    UpdateCCTVControlRunLog,
    UpdateDatumDetailsLog,
    UpdateTrialPitBoreholeLog,
    UpdateSITrialPitDetailsLog,
    AddAbandonedTrialPitRequestLog,
    AddUpdateWaterMainsTestLog,
    DeleteWaterMainsTestLog,
    AddSIProductVerificationRequestLog,
    UploadTrialPitDrawingLog,
    AddSIRevisitRequestLog,
    DeleteBoreholeLog,
    DeleteCCTVControlRunLog,
    UpdateMaterialCostRequestLog,
    AddUpdateDrainageReportLog,
    JobNotReadyToCloseLog,
    AddAbandonedSIProductRequestLog,
    CompleteWaterMainsTestRecordLog,
    SaveOtherFeesDetailLog,
    AcceptProvisionedVisitLog,
    AddCustomerAppointedTradespeopleRequestLog,
    AddCATInvoiceImageLog,
    AddMonitoringVisitInvoiceLog,
    AddRequestForDeEscalateJobLog,
    UpsertRepairEstimateRequestLog,
    AddReopenJobRequestLog,
  },
})
export default class JobAuditLog extends Vue {
  @Prop() private jobId: string
  @Prop({ default: false })
  private isRequestStream: boolean

  private detailshow = false
  private activeLogIndex = -1
  private requestType = RequestType
  private isSuccess = true
  private isLoading = false
  private requestsToCopy: string[] = []
  private isCopiedToClipboard = false

  private masterRecordTypes: string[] = [
    'ResolutionCode',
    'SoftFixedEmergencyTitle',
    'JobCancellationReason',
    'RemoveEmergencyFromJobReason',
    'AdditionalPaymentAuthorizationReason',
    'ContractorUnavailableReason',
  ]

  private extraComponents = {
    AddHealthAndSafetyAnswer: AddVulnerabilityAndHealthSafetyAnswerLog,
    AddVulnerabilityAnswer: AddVulnerabilityAndHealthSafetyAnswerLog,
    UpdateComplaint: AddUpdateComplaintLog,
    AddComplaint: AddUpdateComplaintLog,
    AddEngineerMaterialRequest: AddEngineerMaterialAndExtraTimeRequestLog,
    AddEngineerExtraTimeRequest: AddEngineerMaterialAndExtraTimeRequestLog,
    AddComplaintDocuments: AddDeleteComplaintDocumentLog,
    DeleteComplaintDocument: AddDeleteComplaintDocumentLog,
    AcceptProvisionedVisit: AcceptProvisionedVisitLog,
    AcceptProvisionedUSVisit: AcceptProvisionedVisitLog,
    AddUpdateWaterMainsTest: AddUpdateWaterMainsTestLog,
    SaveWaterMainsTest: AddUpdateWaterMainsTestLog,
  }

  private isExisitingComponent(requestType: any) {
    if (this.extraComponents[requestType]) {
      return this.extraComponents[requestType]
    }

    return this.$options.components && this.$options.components[`${requestType}Log`]
  }

  private get jobRequests(): RequestModel[] {
    const requests: RequestModel[] = this.$store.getters['jobRequestModule/jobRequests']
    return this.isSuccess
      ? requests.filter((x) => x.processStatus === RequestProcessStatus[RequestProcessStatus.Success])
      : requests
  }

  private get requestedJob(): RequestedJob[] {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private async created() {
    this.masterRecordTypes.forEach((recordType) =>
      this.$store.dispatch('jobModule/submitRetrieveMasterRecords', recordType)
    )
  }

  private getProcessedAt(requestModel: RequestModel): string {
    let date = ''
    if (moment.isMoment(requestModel.processedAt) && requestModel.processedAt.isValid()) {
      date = Shared.getFormatedDate(moment(requestModel.processedAt), Store.Instance.state.Environment.DateTimeFormat)
    } else if (moment.isMoment(requestModel.createdAt) && requestModel.createdAt.isValid()) {
      date = Shared.getFormatedDate(moment(requestModel.createdAt), Store.Instance.state.Environment.DateTimeFormat)
    }
    return date
  }

  private get users(): UserModel[] {
    return storeGetters.getUsers()
  }

  private getUserName(requestModel: RequestModel): string {
    if (requestModel.requestedByUserName) {
      return 'by ' + requestModel.requestedByUserName
    } else if (requestModel.requestedByUserId && requestModel.requestedByUserId.includes('/')) {
      const userId = requestModel.requestedByUserId.split('/')[1]
      const user: UserModel | undefined = this.users.find((u: UserModel) => u.id === userId)
      if (user) {
        return 'by ' + user.firstName + ' ' + user.lastName
      }
      return ''
    }
    return ''
  }

  private requestProcessStatus(status: string) {
    if (status === RequestProcessStatus[RequestProcessStatus.Pending]) {
      return 'orange--text'
    } else if (status === RequestProcessStatus[RequestProcessStatus.Success]) {
      return 'green--text'
    } else {
      return 'red--text'
    }
  }

  private closeModal(): void {
    this.$store.dispatch('jobRequestModule/submitClearJobRequests')
    this.$emit('closeModal')
  }

  private showdetails(index: any) {
    if (this.activeLogIndex === index) {
      if (this.detailshow) {
        this.detailshow = false
      } else {
        this.detailshow = true
      }
    } else {
      this.activeLogIndex = index
      if (!this.detailshow) {
        this.detailshow = true
      }
    }
  }

  private updated() {
    this.showHideButton()
  }

  private showHideButton() {
    const classList: any = document.getElementsByClassName('show-inner-content')
    if (classList.length > 0) {
      for (const element of classList) {
        if (element.clientHeight < 75) {
          // currently we have static DOM structure if DOM structure change then we will update this also
          if (element.parentElement.parentElement.childNodes[2].childNodes[1] !== undefined) {
            element.parentElement.parentElement.childNodes[2].childNodes[1].hidden = true
          }
        }
      }
    }
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private copyToClipboard() {
    const clipBoardText = document.getElementById('clipboardText') as HTMLTextAreaElement
    clipBoardText.value = ''
    this.requestsToCopy.forEach((request, index) => {
      const requestText = document.getElementById(request)
      const processedByData = document.getElementById(`processedByData-${request}`)
      clipBoardText.value += `${requestText!.innerText} \n`

      // Dont want to add unneccessary space to end of document
      const isLastValue = index === this.requestsToCopy.length - 1
      clipBoardText.value += isLastValue ? `${processedByData!.innerText}` : `${processedByData!.innerText} \n\n`
    })
    clipBoardText.select()
    document.execCommand('copy')
    this.isCopiedToClipboard = true
  }

  private toggleSelectAll() {
    this.allRequestsSelected
      ? (this.requestsToCopy = [])
      : (this.requestsToCopy = this.jobRequests.map((x) => `${x.requestType}-${x.id}`))
  }

  private get allRequestsSelected(): boolean {
    return this.requestsToCopy.length === this.jobRequests.length
  }
}
</script>

<style scoped>
.person-entry {
  font-size: 13px;
}
.log-details {
  line-height: 1.5;
}
.scrollvb-content {
  min-height: 300px !important;
}
.show-log-details {
  max-height: 75px;
  overflow: hidden;
  font-size: 13px;
}
.show-log-details >>> h4 {
  font-size: 14px;
  padding-right: 85px;
}
.show-details .show-log-details {
  max-height: 100%;
}
.log-details {
  position: relative;
  max-width: 100%;
}
.scrollvb-content > .divider {
  position: relative;
  top: 8px;
}
pre {
  font-family: inherit;
}
.show-log-details >>> div > label {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
}
.log-details >>> .vb > .vb-dragger {
  margin-top: 5px;
}
.log-details >>> .vb > .vb-dragger > .vb-dragger-styler {
  padding: 10px 0px !important;
  margin-top: 5px;
}
.swich-label {
  line-height: 0px;
}
.log-status {
  position: absolute;
  right: 15px;
}
.log-details >>> .v-card__title .v-input--switch {
  padding-top: 0;
  margin-top: 0;
}

.clipboard {
  position: absolute;
  left: -1000px;
}

@media only screen and (min-width: 1024px) {
  .scroll-content-dialog {
    min-width: 700px;
  }
}
</style>
