<template>
  <v-layout wrap>
    <v-flex v-if="getDrainageReport" md8 lg9 xs8>
      <div class="small-text report-name">
        <b>Name:</b>
        {{ getDrainageReport.name }}
      </div>
      <div v-if="getDrainageReport.updatedAt" class="small-text report-updated-date">
        <b>Updated At:</b>
        {{ getFormattedDate(getDrainageReport.updatedAt) }}
      </div>
    </v-flex>
    <v-flex v-else>
      <div class="small-text">Drainage Report</div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import DrainageReportModel from '@/models/claim/DrainageReportModel'
import storeGetters from '@/storeGetters'
import moment from 'moment'
import Shared from '@/common/shared'
import store from '@/store'

@Component
export default class DrainageReportCard extends TimeLineItemBase {
  private get getDrainageReport(): DrainageReportModel | null {
    return storeGetters.getDrainageReport(this.jobId)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>

<style scoped></style>
