<template>
  <div class="dashboard">
    <v-layout wrap>
      <v-container fluid pr-2 pl-0 pt-1>
        <div wrap class="text-right insurer-search job-type-dropdown">
          <v-select
            v-model="selectedJobType"
            label="Job Type"
            required
            item-text="jobTypeDescription"
            item-value="jobType"
            :items="jobTypes"
            class="pr-3"
          />
          <ClientList />
        </div>
      </v-container>
      <v-flex class="mt-5">
        <v-container fluid pr-2 pl-0 pt-1>
          <v-layout wrap>
            <v-flex>
              <JobFinancialSummaryDashboardNew
                v-if="selectedInsurerId"
                :selected-job-type="selectedJobType"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import Shared from '../common/shared'
import JobFinancialSummaryDashboardNew from '@/views/JobFinancialSummaryDashboardNew.vue'
import InsurerModel from '../models/policy/InsurerModel'
import InsurerController from '../api/InsurerController'
import { JobType } from '@/common/enums'
import InsurerSalesLedgerModel from '@/models/policy/InsurerSalesLedgerModel'
import ClientList from '@/components/client/ClientList.vue'

@Component({
  components: {
    ClientList,
    JobFinancialSummaryDashboardNew,
  },
})
export default class ClientInvoiceDashboard extends Vue {
  selectedInsurerId: number | null = null
  selectedJobType: JobType = 'HE'
  insurers: InsurerModel[] = []
  insurerSearchRunning = true
  validationDelay = Shared.validationDelay
  insurerSalesLedgers: InsurerSalesLedgerModel[] = []
  jobTypes = Shared.jobTypeList

  onChangeInsurer(insurerId: number): void {
    if (this.insurers && this.insurers.length > 0) {
      const index = this.insurers.findIndex((x) => x.id === insurerId)
      this.insurerSalesLedgers =
        index !== -1 && this.insurers[index].insurerSalesLedgers ? this.insurers[index].insurerSalesLedgers : []
    }
  }

  async mounted(): Promise<void> {
    const res = await InsurerController.GetInsurerList()
    if (res) {
      this.insurers = res
      this.insurerSearchRunning = false
      this.selectedInsurerId = this.insurers[0].id
      this.insurerSalesLedgers = this.insurers[0].insurerSalesLedgers
    }
  }
}
</script>

<style scoped>
.insurer-search {
  position: absolute;
  right: 24px;
  top: 16px;
  min-width: 300px;
  width: 30%;
  z-index: 2;
}
.insurer-search.job-type-dropdown {
  min-width: 600px;
  width: 50%;
}
.insurer-search.job-type-dropdown >>> .v-select {
  width: 300px;
  display: inline-block;
}
</style>
