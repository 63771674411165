<template>
  <div v-if="item">
    <div class="mb-2">
      <h4>{{ item.id ? 'Update Drainage Report' : 'Add Drainage Report' }}</h4>
    </div>
    <div>
      <div v-if="item.name" class="mb-1">
        <label>Name: </label>
        <span>{{ item.name }}</span>
      </div>
      <div v-if="item.updatedAt" class="mb-1">
        <label>Updated At: </label>
        <span>{{ getFormattedDate(item.updatedAt) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import AddUpdateDrainageReportModel from '@/models/requests/AddUpdateDrainageReportModel'

@Component
export default class AddUpdateDrainageReportLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddUpdateDrainageReportModel {
    return Object.assign(new AddUpdateDrainageReportModel(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
