<template>
  <div class="reopen-job" @click.stop="ShowCardPreview()">
    <div class="question-title-desc mt-3">
      <v-btn color="green white--text" large :ripple="true" class="ml-0">
        <v-icon small>work</v-icon>
        &nbsp;&nbsp; Reopen Job
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import eventBus from '@/common/bus'

@Component
export default class JobReopenCard extends TimeLineItemBase {
  private ShowCardPreview() {
    this.$emit('cardClicked', this.cardIndex)
  }
}
</script>
