<template>
  <div>
    <div class="mb-2"><h4>Customer Appointed Tradespeople</h4></div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Shared from '@/common/shared'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AddCustomerAppointedTradespeopleRequestLog extends Vue {
  @Prop() private requestBody: any

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.requestBody.emergencyId)
  }
}
</script>
