<template>
  <div>
    <ContractorJobs
      :id="insurerId"
      :contractor-jobs="getClientJobList"
      :show-engineer-details="false"
      :total-job-count="totalJobCount"
      :show-load-more="true"
      :visit-type="visitType"
    ></ContractorJobs>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorJobs from '@/components/ContractorJobs.vue'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import JobController from '@/api/jobController'
import SessionController from '@/api/sessionController'
import Store from '@/store'
import ClientJobModel from '@/models/client/ClientJobModel'
import eventBus from '@/common/bus'
import { VisitTypeEnum } from '@/common/enums'

@Component({
  components: { ContractorJobs },
})
export default class ClientJobs extends Vue {
  @Prop() private insurerId: number
  @Prop() private clientJobs: ClientJobModel | null
  @Prop() private previousInsurerId: number
  @Prop() private visitType: string
  private clientJobList: ContractorAppointedModel[] = []
  private totalJobCount: any = new Object()
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('clientManagement')

  private created() {
    // handle signalR
    this.handleSignalR()
    this.onClientJobsChange()
  }

  private mounted() {
    if (this.insurerId && this.isUserRoleClientManager) {
      // have connected and have insurer id, update status
      SessionController.AddUserToNotificationsForClient(this.insurerId)
    }
    eventBus
      .$off('jobsOnLoadMoreClick')
      .$on('jobsOnLoadMoreClick', (loadedJobs: ContractorAppointedModel[], status: string) => {
        this.getClientJobList = this.getClientJobList.filter((e) => e.status !== status)
        this.getClientJobList.push(...loadedJobs)
      })
  }

  private destroyed() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }

  private get getClientJobList() {
    return this.clientJobList
  }

  private set getClientJobList(newValue: ContractorAppointedModel[]) {
    this.clientJobList = newValue
  }

  private handleSignalR() {
    this.signalRHub.addHandler('appointedContractor', this.appointedContractor)
    this.signalRHub.addHandler('moveAppointedContractor', this.moveAppointedContractor)
    this.signalRHub.connect() // connect signalR Hub
  }

  @Watch('clientJobs')
  private onClientJobsChange() {
    if (this.clientJobs) {
      this.clientJobList =
        this.clientJobs.clientJobs && this.clientJobs.clientJobs.length > 0 ? this.clientJobs.clientJobs : []
      this.totalJobCount.totalPendingJobCount = this.clientJobs.totalPendingJobs
      this.totalJobCount.totalAcceptedJobCount = this.clientJobs.totalAcceptedJobs
      this.totalJobCount.totalInProgressJobCount = this.clientJobs.totalInProgressJobs
      this.totalJobCount.totalCompletedJobCount = this.clientJobs.totalCompletedJobs
    }
  }

  @Watch('insurerId')
  private updateSignalRGroup() {
    if (this.previousInsurerId) {
      // remove previous insurer connection
      SessionController.RemoveUserFromNotificationsForClient(this.previousInsurerId)
    }
    if (this.insurerId) {
      // have connected and have contractor id, update status
      SessionController.AddUserToNotificationsForClient(this.insurerId)
    }
  }

  private async appointedContractor(jobId: string, appointedContractorId: string) {
    if (jobId && appointedContractorId) {
      const res = await JobController.GetJobDocument(jobId, appointedContractorId)
      const appointedContractor = Object.assign(new ContractorAppointedModel(), res)

      if (!appointedContractor || appointedContractor.insurerId !== this.insurerId) {
        return
      }

      if (
        (appointedContractor.isContractorReAttend && this.visitType !== VisitTypeEnum[VisitTypeEnum.FirstVisit]) ||
        (!appointedContractor.isContractorReAttend && this.visitType !== VisitTypeEnum[VisitTypeEnum.ReturnVisit])
      ) {
        const clientJobList = this.getClientJobList
        const index: number = clientJobList.findIndex((c: ContractorAppointedModel) => c.id === appointedContractor.id)
        if (index === -1) {
          clientJobList.push(appointedContractor)
        } else {
          clientJobList.splice(index, 1, appointedContractor)
        }
        this.getClientJobList = clientJobList
      }
    }
  }

  private async moveAppointedContractor(jobId: string, appointedContractorId: string) {
    if (jobId && appointedContractorId) {
      const clientJobList = this.getClientJobList
      const index: number = clientJobList.findIndex((c: ContractorAppointedModel) => c.id === appointedContractorId)
      if (index !== -1) {
        // on insurer/policy change, move contractor appointed to new insurer
        clientJobList.splice(index, 1)
        this.getClientJobList = clientJobList
      }
    }
  }

  private get isUserRoleClientManager(): boolean {
    return (
      (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager') ||
      Store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail'
    )
  }
}
</script>
