export default class ApproveListOfJobsModel {
  public insurerId: number
  public disputedReason: string
  public jobList: ApproveJob[]
}

export class ApproveJob {
  public jobId: string
  public clientInvoiceDetailId: string
}
