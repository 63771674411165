<template>
  <div v-if="item">
    <div class="mb-2"><h4>Other Fees Detail</h4></div>
    <div class="mb-1">
      <label>BER Amount: </label>
      <span>{{ getFormatedCurrency(item.berAmount) }}</span>
    </div>
    <div class="mb-1">
      <label>Alternative Accommodation Amount: </label>
      <span>
        {{ getFormatedCurrency(item.alternativeAccommodationAmount) }}
      </span>
    </div>
    <div class="mb-1">
      <label>Temporary Heaters Amount: </label>
      <span>{{ getFormatedCurrency(item.temporaryHeatersAmount) }}</span>
    </div>
    <div v-if="item.notes" class="mb-1">
      <label>Notes: </label>
      <span>{{ item.notes }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import SaveOtherFeesDetailModel from '@/models/requests/SaveOtherFeesDetailModel'
import Shared from '@/common/shared'

@Component
export default class SaveOtherFeesDetailLog extends Vue {
  @Prop() private requestBody: any

  private get item(): SaveOtherFeesDetailModel {
    return Object.assign(new SaveOtherFeesDetailModel(), this.requestBody)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>
