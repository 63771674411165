<template>
  <div class="all-jobs dashboard" :class="isActiveBlock ? 'open-block' : ''">
    <v-container fluid grid-list-xl pt-0 pa-0 px-0>
      <div v-show="ready">
        <div v-if="!isBtnPrimary" class="filter elevation-2 pa-3 mb-3">
          <v-layout>
            <v-flex>
              <span>
                <v-autocomplete
                  v-model="jobsFilter.jobEscalationType"
                  :items="getJobEscalationTypes"
                  item-text="description"
                  item-value="id"
                  label="Escalation Type"
                  :loading="gettingEscalationRecordsRunning"
                  @change="filterJobsBySelectedJobEscalationType($event)"
                ></v-autocomplete>
              </span>
            </v-flex>
            <v-flex>
              <span>
                <v-autocomplete
                  v-model="jobsFilter.teamName"
                  :items="userTeam"
                  item-text="teamName"
                  item-value="id"
                  label="Search team"
                  :loading="getteamRunning"
                  @change="filterJobsBySelectedTeam($event)"
                ></v-autocomplete>
              </span>
            </v-flex>
            <v-flex>
              <span>
                <v-autocomplete
                  v-model="jobsFilter.contractorId"
                  :items="contractors"
                  item-text="companyName"
                  item-value="id"
                  label="Search contractor"
                  :loading="getContractorRunning"
                  @change="onContractorChange($event)"
                ></v-autocomplete>
              </span>
            </v-flex>
            <v-flex>
              <span>
                <v-select
                  v-model="jobsFilter.vulnerabilityLevel"
                  :items="getVulnerabilityLevel"
                  item-text="description"
                  item-value="id"
                  label="Vulnerability Level"
                  :loading="vulnerabilityFilterRunning"
                  @change="filterJobsBySelectedJobVulnerabilityLevel($event)"
                />
              </span>
            </v-flex>
            <v-flex>
              <span>
                <v-autocomplete
                  v-model="jobsFilter.visitType"
                  :items="getVisitTypes"
                  item-text="alterText"
                  item-value="description"
                  label="Visit Type"
                  :loading="getVisitTypeRecordsRunning"
                  @change="filterJobsBySelectedVisitType($event)"
                ></v-autocomplete>
              </span>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-layout wrap>
            <v-flex xs2>
              <span>
                <v-select
                  v-model="jobsFilter.sortingType"
                  :items="getSortingType"
                  item-text="description"
                  item-value="id"
                  label="Sort By"
                  :loading="jobSortingRunning"
                  @change="filterJobsBySortingType($event)"
                />
              </span>
            </v-flex>
            <v-flex xs2>
              <span>
                <v-select
                  v-model="jobsFilter.jobDateFilterType"
                  :items="getJobDateFilterTypes"
                  item-text="alterText"
                  item-value="id"
                  label="Date Type"
                  class="jobDateFilterTypes"
                  :loading="getJobsByDateFilterRunning"
                  @change="filterJobsByDateType($event)"
                />
              </span>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex text-xs-right>
              <v-btn class="mt-3" :class="isBtnPrimary ? 'v-btn--flat' : 'primary'" @click="getJobList">
                job list
                <v-icon class="ml-1">view_list</v-icon>
              </v-btn>
              <v-btn class="mt-3 mr-0" :class="isBtnPrimary ? 'primary' : 'v-btn--flat'" @click="getJobLocations">
                Job location
                <v-icon class="ml-1">place</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
        <div v-else class="filter">
          <v-flex text-xs-right>
            <span class="contractor-filter">
              <v-autocomplete
                v-model="jobsFilter.contractorId"
                :items="contractors"
                item-text="companyName"
                item-value="id"
                label="Search contractor"
                :loading="getContractorRunning"
                @change="onContractorChange($event)"
              ></v-autocomplete>
            </span>
            <v-btn class="mt-2" :class="isBtnPrimary ? 'v-btn--flat' : 'primary'" @click="getJobList">
              job list
              <v-icon class="ml-1">view_list</v-icon>
            </v-btn>
            <v-btn class="mt-2 mr-0" :class="isBtnPrimary ? 'primary' : 'v-btn--flat'" @click="getJobLocations">
              Job location
              <v-icon class="ml-1">place</v-icon>
            </v-btn>
          </v-flex>
        </div>
        <div>
          <v-layout v-if="!isBtnPrimary" wrap calls-info undeployed-jobcount>
            <v-flex xs12>
              <v-card class="elevation-0 grey lighten-3 white--text">
                <v-card-title class="px-2 pb-0 pt-2 grey--text grey--text text--darken-2">
                  <b>Outstanding Trade Jobs</b>
                </v-card-title>
                <v-card-text v-if="jobsTradeList.length > 0" class="pa-1">
                  <div>
                    <v-chip
                      label
                      class="grey grey--text text--darken-4"
                      :class="currentIndex === -1 ? 'lighten-1' : 'lighten-2'"
                      @click="getTradeWiseJobs('All', 0, '')"
                    >
                      All &nbsp;
                      <b>
                        {{ jobsTradeList.reduce((a, b) => a + b.jobCount, 0) }}
                      </b>
                    </v-chip>
                    <span v-for="(jobTrade, i) in jobsTradeList" :key="i">
                      <v-chip
                        label
                        class="grey grey--text text--darken-2"
                        :class="currentIndex === i ? 'lighten-1' : 'lighten-2'"
                        @click="getTradeWiseJobs('', jobTrade.tradeId, i)"
                      >
                        {{ jobTrade.trade }} &nbsp;
                        <b>{{ jobTrade.jobCount }}</b>
                      </v-chip>
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
        <div class="taskboard-wrapper" :class="openJobView ? 'hide-dashboard' : 'show-dashboard'">
          <div class="jobs-taskboard">
            <v-layout wrap>
              <v-flex xs3 class="pending-job">
                <div class="task-col grey lighten-3">
                  <div class="task-header">
                    <h3 class="pb-0">Pending({{ getTotalPendingJobsCount }})</h3>
                    <v-divider class="mt-3"></v-divider>
                  </div>
                  <div v-if="pendingJobList.length > 0" class="task-body px-3 pb-3 pt-1">
                    <JobSummaryCard
                      v-for="pendingJob in pendingJobList"
                      :key="jobKey(pendingJob)"
                      :job="pendingJob"
                      :title-generator="(job) => job.jobId + (multipleVisits(job) ? '-' + job.visitNumber : '')"
                      @redirectToJob="onRedirectToJob"
                    >
                      <div slot="JobCardDetails" class="primary-title">
                        <h4 class="date-title">
                          <span class="card-emergency-icon">
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <img
                                  v-if="getEmergencyIcon(pendingJob.forEmergencyTypeId)"
                                  :src="getEmergencyIcon(pendingJob.forEmergencyTypeId)"
                                  class="category-icon-img"
                                  v-on="on"
                                />
                                <img v-else src="/img/emergency.svg" class="category-icon-img" alt="" v-on="on" />
                              </template>
                              <span>
                                {{ getEmergencyDescription(pendingJob.forEmergencyTypeId) }}
                              </span>
                            </v-tooltip>
                          </span>
                          <span>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                openContractorJobDetailModal(pendingJob.jobId, pendingJob.id, pendingJob.engineerName)
                              "
                            >
                              {{ pendingJob.companyName }}
                            </a>
                            <span class="primary--text">
                              (
                              <v-menu attach offset-y>
                                <template #activator="{ on }">
                                  <a href="javascript:void(0)" v-on="on">
                                    <v-icon color="secondary" small>call</v-icon>
                                  </a>
                                </template>
                                <v-list
                                  v-for="(contactNumber, numberIndex) in pendingJob.contactNumbers"
                                  :key="numberIndex"
                                >
                                  <v-list-tile>
                                    <v-list-tile-title>
                                      <span
                                        class="primary--text cursor-pointer"
                                        @click.prevent="onCallContractorIconClick(pendingJob, numberIndex)"
                                      >
                                        <v-icon>call</v-icon>
                                        {{ contactNumber.phoneNumber }}
                                      </span>
                                      <span class="primary--text">
                                        {{ contactNumber.label ? '(' + contactNumber.label + ')' : '' }}
                                      </span>
                                    </v-list-tile-title>
                                  </v-list-tile>
                                  <v-divider v-if="numberIndex !== pendingJob.contactNumbers.length - 1"></v-divider>
                                </v-list>
                              </v-menu>
                              )
                            </span>
                            <v-spacer></v-spacer>
                            <v-tooltip v-if="pendingJob.isContractorReAttend" top>
                              <template #activator="{ on }">
                                <span class="return-visit" v-on="on">
                                  <img src="/img/return-visit.svg" alt="" />
                                </span>
                              </template>
                              <span>Return Visit</span>
                            </v-tooltip>
                          </span>
                        </h4>
                      </div>
                    </JobSummaryCard>
                  </div>
                  <div v-else class="unavilable-job-msg">
                    <span>Data not available</span>
                  </div>
                  <div
                    v-if="
                      getTotalPendingJobsCount >= defaultLoadMoreRecordCount &&
                      pendingJobList.length !== getTotalPendingJobsCount &&
                      currentIndex === -1
                    "
                    class="task-action text-xs-center grey lighten-2 px-2"
                  >
                    <v-progress-linear
                      v-if="pendingJobsLoadMoreStarted"
                      :indeterminate="true"
                      height="4"
                    ></v-progress-linear>
                    <v-btn
                      color="primary"
                      flat
                      small
                      block
                      @click="loadMore(pendingJobList.length, jobVisitStatus[jobVisitStatus.Pending])"
                    >
                      <b>Load More</b>
                    </v-btn>
                  </div>
                </div>
              </v-flex>
              <v-flex xs3 class="accepted-job">
                <div class="task-col grey lighten-3">
                  <div class="task-header">
                    <h3>Accepted({{ getTotalAcceptedJobsCount }})</h3>
                    <v-divider class="mt-3"></v-divider>
                  </div>
                  <div v-if="acceptedJobList.length > 0" class="task-body px-3 pb-3 pt-1">
                    <JobSummaryCard
                      v-for="acceptedJob in acceptedJobList"
                      :key="jobKey(acceptedJob)"
                      :job="acceptedJob"
                      @redirectToJob="onRedirectToJob"
                    >
                      <div slot="JobCardDetails" class="primary-title">
                        <h4 class="date-title">
                          <span class="card-emergency-icon">
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <img
                                  v-if="getEmergencyIcon(acceptedJob.forEmergencyTypeId)"
                                  :src="getEmergencyIcon(acceptedJob.forEmergencyTypeId)"
                                  class="category-icon-img"
                                  v-on="on"
                                />
                                <img v-else src="/img/emergency.svg" class="category-icon-img" alt="" v-on="on" />
                              </template>
                              <span>
                                {{ getEmergencyDescription(acceptedJob.forEmergencyTypeId) }}
                              </span>
                            </v-tooltip>
                          </span>
                          <span>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                openContractorJobDetailModal(
                                  acceptedJob.jobId,
                                  acceptedJob.id,
                                  acceptedJob.engineerName
                                )
                              "
                            >
                              {{ acceptedJob.companyName }}
                            </a>
                            <span class="primary--text">
                              (
                              <v-menu attach offset-y>
                                <template #activator="{ on }">
                                  <a href="javascript:void(0)" v-on="on">
                                    <v-icon color="secondary" small>call</v-icon>
                                  </a>
                                </template>
                                <v-list
                                  v-for="(contactNumber, numberIndex) in acceptedJob.contactNumbers"
                                  :key="numberIndex"
                                >
                                  <v-list-tile>
                                    <v-list-tile-title>
                                      <span
                                        class="primary--text cursor-pointer"
                                        @click.prevent="onCallContractorIconClick(acceptedJob, numberIndex)"
                                      >
                                        <v-icon>call</v-icon>
                                        {{ contactNumber.phoneNumber }}
                                      </span>
                                      <span class="primary--text">
                                        {{ contactNumber.label ? '(' + contactNumber.label + ')' : '' }}
                                      </span>
                                    </v-list-tile-title>
                                  </v-list-tile>
                                  <v-divider v-if="numberIndex !== acceptedJob.contactNumbers.length - 1"></v-divider>
                                </v-list>
                              </v-menu>
                              )
                            </span>
                            <v-spacer></v-spacer>
                            <v-tooltip v-if="acceptedJob.isContractorReAttend" top>
                              <template #activator="{ on }">
                                <span class="return-visit" v-on="on">
                                  <img src="/img/return-visit.svg" alt="" />
                                </span>
                              </template>
                              <span>Return Visit</span>
                            </v-tooltip>
                          </span>
                        </h4>
                      </div>
                      <div v-if="acceptedJob.engineerName || acceptedJob.engineerContactNumber" class="bottom-row">
                        <span class="img-text">
                          <img src="/img/employee.svg" />
                          {{ acceptedJob.engineerName }}
                        </span>
                        <span v-if="canSeeEngineerPhoneNumber(acceptedJob.contractorId)" class="primary--text">
                          (
                          <v-menu attach offset-y>
                            <template #activator="{ on }">
                              <a href="javascript:void(0)" v-on="on">
                                <v-icon color="secondary" small>call</v-icon>
                              </a>
                            </template>
                            <v-list>
                              <v-list-tile>
                                <v-list-tile-title>
                                  <span
                                    class="primary--text cursor-pointer"
                                    @click.prevent="onCallIconClick(acceptedJob)"
                                  >
                                    <v-icon>call</v-icon>
                                    {{ acceptedJob.engineerContactNumber }}
                                  </span>
                                </v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                          )
                        </span>
                      </div>
                    </JobSummaryCard>
                  </div>
                  <div v-else class="unavilable-job-msg">
                    <span>Data not available</span>
                  </div>
                  <div
                    v-if="
                      getTotalAcceptedJobsCount >= defaultLoadMoreRecordCount &&
                      acceptedJobList.length !== getTotalAcceptedJobsCount &&
                      currentIndex === -1
                    "
                    class="task-action text-xs-center grey lighten-2 px-2"
                  >
                    <v-progress-linear
                      v-if="acceptedJobsLoadMoreStarted"
                      :indeterminate="true"
                      height="4"
                    ></v-progress-linear>
                    <v-btn
                      color="primary"
                      flat
                      small
                      block
                      @click="loadMore(acceptedJobList.length, jobVisitStatus[jobVisitStatus.Accepted])"
                    >
                      <b>Load More</b>
                    </v-btn>
                  </div>
                </div>
              </v-flex>
              <v-flex xs3 class="progress-job">
                <div class="task-col grey lighten-3">
                  <div class="task-header">
                    <h3>In progress({{ getTotalInProgressJobsCount }})</h3>
                    <v-divider class="mt-3"></v-divider>
                  </div>
                  <div v-if="inProgressJobList.length > 0" class="task-body px-3 pb-3 pt-1">
                    <JobSummaryCard
                      v-for="inProgressJob in inProgressJobList"
                      :key="jobKey(inProgressJob)"
                      :job="inProgressJob"
                      @redirectToJob="onRedirectToJob"
                    >
                      <div slot="JobCardDetails">
                        <div class="primary-title">
                          <h4 class="date-title">
                            <span class="card-emergency-icon">
                              <v-tooltip bottom>
                                <template #activator="{ on }">
                                  <img
                                    v-if="getEmergencyIcon(inProgressJob.forEmergencyTypeId)"
                                    :src="getEmergencyIcon(inProgressJob.forEmergencyTypeId)"
                                    class="category-icon-img"
                                    v-on="on"
                                  />
                                  <img v-else src="/img/emergency.svg" class="category-icon-img" alt="" v-on="on" />
                                </template>
                                <span>
                                  {{ getEmergencyDescription(inProgressJob.forEmergencyTypeId) }}
                                </span>
                              </v-tooltip>
                            </span>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                openContractorJobDetailModal(
                                  inProgressJob.jobId,
                                  inProgressJob.id,
                                  inProgressJob.engineerName
                                )
                              "
                            >
                              {{ inProgressJob.companyName }}
                            </a>
                          </h4>
                          <v-spacer></v-spacer>
                          <v-tooltip v-if="inProgressJob.isContractorReAttend" top>
                            <template #activator="{ on }">
                              <span class="return-visit" v-on="on">
                                <img src="/img/return-visit.svg" alt="" />
                              </span>
                            </template>
                            <span>Return Visit</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="inProgressJob.engineerName || inProgressJob.engineerContactNumber"
                          class="bottom-row"
                        >
                          <span class="img-text">
                            <img src="/img/employee.svg" />
                            {{ inProgressJob.engineerName }}
                          </span>
                          <span v-if="canSeeEngineerPhoneNumber(inProgressJob.contractorId)" class="primary--text">
                            (
                            <v-menu attach offset-y>
                              <template #activator="{ on }">
                                <a href="javascript:void(0)" v-on="on">
                                  <v-icon color="secondary" small>call</v-icon>
                                </a>
                              </template>
                              <v-list>
                                <v-list-tile>
                                  <v-list-tile-title>
                                    <span
                                      class="primary--text cursor-pointer"
                                      @click.prevent="onCallIconClick(inProgressJob)"
                                    >
                                      <v-icon>call</v-icon>
                                      {{ inProgressJob.engineerContactNumber }}
                                    </span>
                                  </v-list-tile-title>
                                </v-list-tile>
                              </v-list>
                            </v-menu>
                            )
                          </span>
                        </div>
                        <div v-if="inProgressJob.visitStartedAt" class="bottom-date">
                          <v-icon small class="mr-2">date_range</v-icon>
                          <span>Job Start:</span>
                          <b>
                            {{ getETADateFormat(inProgressJob.visitStartedAt) }}
                          </b>
                        </div>
                      </div>
                    </JobSummaryCard>
                  </div>
                  <div v-else class="unavilable-job-msg">
                    <span>Data not available</span>
                  </div>
                  <div
                    v-if="
                      getTotalInProgressJobsCount >= defaultLoadMoreRecordCount &&
                      inProgressJobList.length !== getTotalInProgressJobsCount &&
                      currentIndex === -1
                    "
                    class="task-action text-xs-center grey lighten-2 px-2"
                  >
                    <v-progress-linear
                      v-if="inProgressJobsLoadMoreStarted"
                      :indeterminate="true"
                      height="4"
                    ></v-progress-linear>
                    <v-btn
                      color="primary"
                      flat
                      small
                      block
                      @click="loadMore(inProgressJobList.length, jobVisitStatus[jobVisitStatus.InProgress])"
                    >
                      <b>Load More</b>
                    </v-btn>
                  </div>
                </div>
              </v-flex>
              <v-flex xs3 class="completed-job">
                <div class="task-col grey lighten-3">
                  <div class="task-header">
                    <h3>Completed({{ getTotalCompletedJobsCount }})</h3>
                    <v-divider class="mt-3"></v-divider>
                  </div>
                  <div v-if="completedJobList.length > 0" class="task-body px-3 pb-3 pt-1">
                    <JobSummaryCard
                      v-for="completedJobs in completedJobList"
                      :key="jobKey(completedJobs)"
                      :job="completedJobs"
                      @redirectToJob="onRedirectToJob"
                    >
                      <div slot="JobCardDetails">
                        <div class="primary-title">
                          <h4 class="date-title">
                            <span class="card-emergency-icon">
                              <v-tooltip bottom>
                                <template #activator="{ on }">
                                  <img
                                    v-if="getEmergencyIcon(completedJobs.forEmergencyTypeId)"
                                    :src="getEmergencyIcon(completedJobs.forEmergencyTypeId)"
                                    class="category-icon-img"
                                    v-on="on"
                                  />
                                  <img v-else src="/img/emergency.svg" class="category-icon-img" alt="" v-on="on" />
                                </template>
                                <span>
                                  {{ getEmergencyDescription(completedJobs.forEmergencyTypeId) }}
                                </span>
                              </v-tooltip>
                            </span>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                openContractorJobDetailModal(
                                  completedJobs.jobId,
                                  completedJobs.id,
                                  completedJobs.engineerName
                                )
                              "
                            >
                              {{ completedJobs.companyName }}
                            </a>
                          </h4>
                          <v-spacer></v-spacer>
                          <v-tooltip v-if="completedJobs.isContractorReAttend" top>
                            <template #activator="{ on }">
                              <span class="return-visit" v-on="on">
                                <img src="/img/return-visit.svg" alt="" />
                              </span>
                            </template>
                            <span>Return Visit</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="completedJobs.engineerName || completedJobs.engineerContactNumber"
                          class="bottom-row"
                        >
                          <span class="img-text">
                            <img src="/img/employee.svg" />
                            {{ completedJobs.engineerName }}
                          </span>
                          <span v-if="canSeeEngineerPhoneNumber(completedJobs.contractorId)" class="primary--text">
                            (
                            <v-menu attach offset-y>
                              <template #activator="{ on }">
                                <a href="javascript:void(0)" v-on="on">
                                  <v-icon color="secondary" small>call</v-icon>
                                </a>
                              </template>
                              <v-list>
                                <v-list-tile>
                                  <v-list-tile-title>
                                    <span
                                      class="primary--text cursor-pointer"
                                      @click.prevent="onCallIconClick(completedJobs)"
                                    >
                                      <v-icon>call</v-icon>
                                      {{ completedJobs.engineerContactNumber }}
                                    </span>
                                  </v-list-tile-title>
                                </v-list-tile>
                              </v-list>
                            </v-menu>
                            )
                          </span>
                        </div>
                        <div v-if="completedJobs.visitCompletedAt" class="bottom-date">
                          <v-icon small class="mr-2">date_range</v-icon>
                          <span>Visit Completed:</span>
                          <b>
                            {{ getETADateFormat(completedJobs.visitCompletedAt) }}
                          </b>
                        </div>
                      </div>
                    </JobSummaryCard>
                  </div>
                  <div v-else class="unavilable-job-msg">
                    <span>Data not available</span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div class="jobs-map mt-2">
            <Map
              :map-id="new Date().getTime().toString()"
              :source-latitude="51.509865"
              :source-longitude="-0.118092"
              :zoom-level="8"
              :show-marker="false"
              :outstanding-jobs-jobs-location="outstandingJobsForLocation"
              style="height: calc(100vh - 185px)"
            ></Map>
          </div>
        </div>
        <v-dialog
          v-if="contractorJobDetailDialog"
          v-model="contractorJobDetailDialog"
          max-width="1000"
          persistent
          content-class="v-dialog--scrollable"
        >
          <ContractorJobDetail
            :parent-component="'OutstandingJobDashboard'"
            :contractor-job-detail-item="contractorJobDetailModel"
            @contractorJobDetailDialogClose="contractorJobDetailDialogClose"
          ></ContractorJobDetail>
        </v-dialog>
      </div>
      <div v-show="!ready">
        <v-progress-circular
          class="loading-spinner"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
    <PartialJobView :job-id="selectedJobIdToExpand" @closeJobView="closeJobView"></PartialJobView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import moment, { Moment } from 'moment'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import Map from '@/components/Map.vue'
import ContractorJobDetail from '@/components/ContractorJobDetail.vue'
import OutstandingJobModel from '@/models/OutstandingJobModel'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import DashboardController from '@/api/dashboardController'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import storeGetters from '@/storeGetters'
import PolicyHolderController from '@/api/policyHolderController'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import storeMutations from '@/storeMutations'
import Shared from '@/common/shared'
import store from '@/store'
import {
  JobVisitStatus,
  ContactPreferenceEnum,
  EscalationTypeEnum,
  VulnerabilityLevelEnum,
  WIPDashboardSortingTypeEnum,
  VisitTypeEnum,
  JobDateFilterType,
} from '@/common/enums'
import eventBus from '@/common/bus'
import CallParameters from '@/models/twilio/CallParameters'
import ContractorManagementHub from '@/components/ContractorManagementHub.vue'
import TradeModel from '@/models/policyHolder/TradeModel'
import UserController from '../api/userController'
import JobSummaryCard from '@/components/JobSummaryCard.vue'
import PartialJobView from '@/components/PartialJobView.vue'

const ETA_REFRESH_INTERVAL: number = 60000 * 5 // 5 minutes

const DEFAULT_ETA_COLORS = {
  overdue: 'red',
  nearing: 'orange',
  ok: 'grey',
}

const PROGRESS_ETA_COLORS = {
  overdue: 'red',
  nearing: 'orange',
  ok: 'blue',
}

interface IDropdownType {
  id: number
  description: string
  alterText?: string
}

@Component({
  components: { Map, ContractorJobDetail, JobSummaryCard, PartialJobView },
})
export default class OutstandingJobDashboard extends ContractorManagementHub {
  private ready = false
  private isActiveBlock = false
  private isBtnPrimary = false
  private contractorJobDetailDialog = false
  private contractorJobDetailModel: ContractorJobDetailModel | null = null
  private contractors: ContractorModel[] = []
  private previousSelectedContractorForLocation = ''
  private previousSelectedContractorForJobs = ''
  private outstandingJobsForLocation: ContractorAppointedModel[] = []
  private pendingJobsLoadMoreStarted = false
  private acceptedJobsLoadMoreStarted = false
  private inProgressJobsLoadMoreStarted = false
  private completedJobsLoadMoreStarted = false
  private getContractorRunning = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private loadJobCountOnInit = 0

  private customerContactPreference = ContactPreferenceEnum

  private engineerName = ''
  private getteamRunning = false
  private gettingEscalationRecordsRunning = false
  private vulnerabilityFilterRunning = false
  private jobSortingRunning = false
  private userTeam: string[] = []
  // open job view
  private openJobView = false
  private selectedJobIdToExpand = ''
  private getJobsByDateFilterRunning = false
  private defaultLoadMoreRecordCount: number = Shared.defaultLoadMoreRecordCount
  private getVisitTypeRecordsRunning = false

  private created() {
    this.childPage = 'OutstandingJobDashboard' // for SignalR
    // get getTradeList
    this.getTradeList()
    // get all contractor list
    this.getAllContractors()
    // get team name list
    this.getTeamName()
    // get emergencies for logo and description
    if (this.emergencies.length === 0) {
      this.setEmergencies()
    }
    // set interval
    this.interval = window.setInterval(() => {
      this.pendingJobList = [...this.updateETAColors(this.pendingJobList)]
      this.acceptedJobList = [...this.updateETAColors(this.acceptedJobList)]
      this.inProgressJobList = [...this.updateETAColors(this.inProgressJobList)]
      this.completedJobList = [...this.updateETAColors(this.completedJobList)]
    }, ETA_REFRESH_INTERVAL)

    this.outstandingJobDashboardHandler()
    this.outStandingJobDashboardHub.connect()
  }

  private mounted() {
    SetThemeForJobType(this, undefined)
    eventBus.$on('updateETAColors', () => {
      this.updateETAColors()
    })
  }

  private updateETAColors(jobList?: ContractorAppointedModel[]) {
    if (!jobList) {
      jobList = this.outstandingJobData
    }
    jobList.map((x) => {
      let color
      if (x.status === JobVisitStatus[JobVisitStatus.Accepted] && x.engineerOnTheWayAt) {
        color = PROGRESS_ETA_COLORS.ok
      } else if (x.status === JobVisitStatus[JobVisitStatus.InProgress]) {
        color = x.visitStartedAt ? this.getETAStatusForInProgressJobs(x.visitStartedAt) : PROGRESS_ETA_COLORS.ok
      } else if (x.status === JobVisitStatus[JobVisitStatus.Completed]) {
        color = 'green'
      } else {
        color = DEFAULT_ETA_COLORS[this.getETAStatus(x.etaTo)]
      }
      x.dashboardDisplayData = { etaColor: color }
      return x
    })
    return jobList
  }

  private jobKey(job: ContractorAppointedModel) {
    return job.jobId + '|' + job.contractorId + '|' + job.visitNumber
  }

  private getTradeList() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.trades = res.filter((e) => e.jobType === 'HE')
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.trades = trades.filter((e) => e.jobType === 'HE')
    }
  }

  private getETAStatus(eta: moment.Moment | null) {
    if (eta) {
      const now = moment()
      const minutes = now.diff(eta, 'minutes')
      if (minutes > 0) {
        return 'overdue'
      } else if (minutes <= 0 && minutes >= -60) {
        return 'nearing'
      } else {
        return 'ok'
      }
    } else {
      return 'ok'
    }
  }

  private getETAStatusForInProgressJobs(eta: moment.Moment | null): string {
    if (eta) {
      const now = moment()
      const minutes = now.diff(eta, 'minutes')
      if (minutes >= 120) {
        return PROGRESS_ETA_COLORS.overdue
      } else if (minutes >= 60) {
        return PROGRESS_ETA_COLORS.nearing
      } else {
        return PROGRESS_ETA_COLORS.ok
      }
    } else {
      return PROGRESS_ETA_COLORS.ok
    }
  }

  private getAllContractors() {
    ContractorController.GetAllContractors()
      .then((res: ContractorModel[]) => {
        if (res) {
          this.contractors = res
          if (res.length > 1) {
            // add extra label for all selected contractor
            const addDummyLabel: ContractorModel = new ContractorModel()
            addDummyLabel.companyName = 'All'
            addDummyLabel.id = '0'
            this.contractors.unshift(addDummyLabel)
            this.onContractorChange('0')
          }
        } else {
          this.contractors = []
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractors, please try again', true)
      })
  }

  private GetAllOutstandingJobs(isContractorChanged = false) {
    this.getContractorRunning = isContractorChanged ? true : false
    const contractorId = Number(this.jobsFilter.contractorId) === 0 ? '' : this.jobsFilter.contractorId
    const teamName =
      this.jobsFilter.teamName && this.jobsFilter.teamName.toLowerCase() === 'unknown'
        ? 'unknown'
        : this.jobsFilter.teamName && this.jobsFilter.teamName.toLowerCase() === 'all'
        ? ''
        : this.jobsFilter.teamName
    DashboardController.GetAllOutstandingJobs(
      0,
      contractorId,
      teamName,
      '',
      EscalationTypeEnum[this.jobsFilter.jobEscalationType],
      VulnerabilityLevelEnum[this.jobsFilter.vulnerabilityLevel],
      WIPDashboardSortingTypeEnum[this.jobsFilter.sortingType],
      this.jobsFilter.visitType,
      JobDateFilterType[this.jobsFilter.jobDateFilterType]
    )
      .then((res: OutstandingJobModel | null) => {
        if (res) {
          this.outstandingJobData = res.outstandingJobs
          this.sortOutStandingJobDashboardData(this.outstandingJobData)
          this.getStatusWiseOutstandingJobs(this.outstandingJobData)
          this.totalPendingJobs = res.totalPendingJobs
          this.totalAcceptedJobs = res.totalAcceptedJobs
          this.totalInProgressJobs = res.totalInProgressJobs
          this.totalCompletedJobs = res.totalCompletedJobs
          this.updateETAColors()
          this.resetLoaders()
        } else {
          this.ready = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading outstanding job(s), please try again', true)
        this.resetLoaders()
      })
  }

  private get emergencies() {
    return storeGetters.getEmergencies()
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        storeMutations.setEmergencies(res)
      }
    })
  }

  private getEmergencyIcon(forEmergencyTypeId: number) {
    const emergency: any = this.emergencies.find((e) => e.emergencyId === forEmergencyTypeId)
    if (emergency !== undefined) {
      return emergency.fileURL
    } else {
      return ''
    }
  }

  private getEmergencyDescription(forEmergencyTypeId: number) {
    const emergency: any = this.emergencies.find((e) => e.emergencyId === forEmergencyTypeId)
    if (emergency !== undefined) {
      return emergency.description
    }
    return ''
  }

  private getETADateFormat(date: moment.Moment | null): string | null {
    if (date) {
      return Shared.getFormatedDate(moment(date), store.Instance.state.Environment.DateTimeFormat)
    }
    return null
  }

  private getJobList() {
    if (this.isActiveBlock) {
      if (this.previousSelectedContractorForJobs !== this.jobsFilter.contractorId) {
        this.previousSelectedContractorForJobs = this.jobsFilter.contractorId
        this.GetAllOutstandingJobs(true)
      }
    }
    this.isActiveBlock = false
    this.isBtnPrimary = false
  }

  private getJobLocations() {
    this.isActiveBlock = true
    this.isBtnPrimary = true
    if (this.previousSelectedContractorForLocation !== this.jobsFilter.contractorId) {
      this.previousSelectedContractorForLocation = this.jobsFilter.contractorId
      this.GetOutstandingJobsLocation()
    }
  }

  private GetOutstandingJobsLocation() {
    this.getContractorRunning = true
    const contractor: string = Number(this.jobsFilter.contractorId) === 0 ? '' : this.jobsFilter.contractorId
    DashboardController.GetOutstandingJobsLocation(contractor)
      .then((res: ContractorAppointedModel[]) => {
        if (res) {
          if (this.jobsFilter.contractorId === '') {
            this.jobsFilter.contractorId = '0'
          }
          this.outstandingJobsForLocation = []
          this.outstandingJobsForLocation = res
          this.getContractorRunning = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading outstanding jobs location, please try again', true)
        this.getContractorRunning = false
      })
  }

  private contractorJobDetailDialogClose(): void {
    this.contractorJobDetailDialog = false
    this.contractorJobDetailModel = null
  }

  private onContractorChange(contractorId: string) {
    this.jobsFilter.contractorId = contractorId
    if (!this.jobsFilter.contractorId) {
      return
    }
    if (this.jobsFilter.contractorId) {
      this.jobsTradeList = []
      this.currentIndex = -1
      if (this.isActiveBlock) {
        if (this.previousSelectedContractorForLocation !== this.jobsFilter.contractorId) {
          this.previousSelectedContractorForLocation = this.jobsFilter.contractorId
          this.GetOutstandingJobsLocation()
        }
      } else {
        if (this.previousSelectedContractorForJobs !== this.jobsFilter.contractorId) {
          this.previousSelectedContractorForJobs = this.jobsFilter.contractorId
          this.GetAllOutstandingJobs(true)
        }
      }
      this.updateSignalRGroupId()
    }
  }

  private loadMore(length: number, status: string) {
    if (status === JobVisitStatus[JobVisitStatus.Pending]) {
      this.pendingJobsLoadMoreStarted = true
      this.updateOutstandingJobs(length, JobVisitStatus[JobVisitStatus.Pending])
    } else if (status === JobVisitStatus[JobVisitStatus.Accepted]) {
      this.acceptedJobsLoadMoreStarted = true
      this.updateOutstandingJobs(length, JobVisitStatus[JobVisitStatus.Accepted])
    } else if (status === JobVisitStatus[JobVisitStatus.InProgress]) {
      this.inProgressJobsLoadMoreStarted = true
      this.updateOutstandingJobs(length, JobVisitStatus[JobVisitStatus.InProgress])
    } else if (status === JobVisitStatus[JobVisitStatus.Completed]) {
      this.completedJobsLoadMoreStarted = true
      this.updateOutstandingJobs(length, JobVisitStatus[JobVisitStatus.Completed])
    }
  }

  private updateOutstandingJobs(recordCount = 0, status = '') {
    const contractorId = Number(this.jobsFilter.contractorId) === 0 ? '' : this.jobsFilter.contractorId
    const teamName =
      this.jobsFilter.teamName && this.jobsFilter.teamName.toLowerCase() === 'all' ? '' : this.jobsFilter.teamName
    DashboardController.GetAllOutstandingJobs(
      recordCount,
      contractorId,
      teamName,
      status,
      EscalationTypeEnum[this.jobsFilter.jobEscalationType],
      VulnerabilityLevelEnum[this.jobsFilter.vulnerabilityLevel],
      WIPDashboardSortingTypeEnum[this.jobsFilter.sortingType],
      this.jobsFilter.visitType,
      JobDateFilterType[this.jobsFilter.jobDateFilterType]
    )
      .then((res: OutstandingJobModel | null) => {
        if (res) {
          res.outstandingJobs.forEach((job) => {
            const index = this.outstandingJobData.findIndex((e) => e.id === job.id)
            if (index === -1) {
              this.outstandingJobData.push(job)
            }
          })
          this.sortOutStandingJobDashboardData(this.outstandingJobData)
          this.getStatusWiseOutstandingJobs(this.outstandingJobData)
          this.updateETAColors()
        }
        // show loader under that block only
        this.clearLoadMoreLoaders()
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading outstanding job(s), please try again', true)
        this.clearLoadMoreLoaders()
      })
  }

  private clearLoadMoreLoaders() {
    this.pendingJobsLoadMoreStarted = false
    this.acceptedJobsLoadMoreStarted = false
    this.inProgressJobsLoadMoreStarted = false
    this.completedJobsLoadMoreStarted = false
  }

  private openContractorJobDetailModal(jobId: string, contractorAppointedDetailId: string, engineerName: string): void {
    const self = this
    self.contractorJobDetailDialog = true
    this.engineerName = ''
    ContractorController.GetContractorJobDetailWithEmergencyDefinition(jobId, contractorAppointedDetailId)
      .then((res: ContractorJobDetailModel | null) => {
        if (res) {
          self.contractorJobDetailModel = res
          this.engineerName = engineerName
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor job detail, please try again', false)
        this.snackbarText = 'Error in loading contractor jobDetail!'
        this.snackbar = true
        self.contractorJobDetailDialog = false
      })
  }

  private multipleVisits(job: ContractorAppointedModel) {
    return job.totalVisit > 1
  }

  private onCallIconClick(outStandingJob: ContractorAppointedModel) {
    // put together the parameters and emit the event
    const callParameters = new CallParameters()
    callParameters.to = outStandingJob.engineerContactNumber
    callParameters.jobId = outStandingJob.jobId
    callParameters.contractorId = outStandingJob.contractorId
    callParameters.emergencyId = outStandingJob.emergencyId
    eventBus.$emit('callIconClick', callParameters)
  }

  private onCallContractorIconClick(outStandingJob: ContractorAppointedModel, index: number) {
    // put together the parameters and emit the event
    const callParameters = new CallParameters()
    callParameters.to = outStandingJob.contactNumbers[index].phoneNumber
    callParameters.jobId = outStandingJob.jobId
    callParameters.contractorId = outStandingJob.contractorId
    callParameters.emergencyId = outStandingJob.emergencyId
    eventBus.$emit('callIconClick', callParameters)
  }

  private getTradeWiseJobs(type: string, tradeId: number, index: number) {
    const outstandingJobs: ContractorAppointedModel[] = this.getFilterWiseOutstandingJobs(this.outstandingJobData)
    if (type === 'All') {
      this.currentIndex = -1
      this.pendingJobList = outstandingJobs.filter((e) => e.status === JobVisitStatus[JobVisitStatus.Pending])
      this.acceptedJobList = outstandingJobs.filter((e) => e.status === JobVisitStatus[JobVisitStatus.Accepted])
      this.inProgressJobList = outstandingJobs.filter((e) => e.status === JobVisitStatus[JobVisitStatus.InProgress])
      this.completedJobList = outstandingJobs.filter((e) => e.status === JobVisitStatus[JobVisitStatus.Completed])
    } else {
      this.currentIndex = index
      this.pendingJobList = outstandingJobs.filter(
        (e) => e.status === JobVisitStatus[JobVisitStatus.Pending] && e.tradeId === tradeId
      )
      this.acceptedJobList = outstandingJobs.filter(
        (e) => e.status === JobVisitStatus[JobVisitStatus.Accepted] && e.tradeId === tradeId
      )
      this.inProgressJobList = outstandingJobs.filter(
        (e) => e.status === JobVisitStatus[JobVisitStatus.InProgress] && e.tradeId === tradeId
      )
      this.completedJobList = outstandingJobs.filter(
        (e) => e.status === JobVisitStatus[JobVisitStatus.Completed] && e.tradeId === tradeId
      )
    }
    this.pendingJobList = [...this.updateETAColors(this.pendingJobList)]
    this.acceptedJobList = [...this.updateETAColors(this.acceptedJobList)]
    this.inProgressJobList = [...this.updateETAColors(this.inProgressJobList)]
    this.completedJobList = [...this.updateETAColors(this.completedJobList)]
  }

  private getTeamName() {
    const self = this
    this.getteamRunning = true
    UserController.GetUserTeams().then((res: string[]) => {
      if (res.length > 0) {
        self.userTeam = res
        // add extra label for all selected contractor
        const allTeamOption = 'All'
        const unKnownTeamOption = 'Unknown'
        self.userTeam.unshift(unKnownTeamOption)
        self.userTeam.unshift(allTeamOption)
        this.jobsFilter.teamName = self.userTeam[0]
      }
      this.getteamRunning = false
    })
  }

  private filterJobsBySelectedTeam(teamName: string) {
    this.jobsFilter.teamName = teamName
    if (!this.jobsFilter.contractorId) {
      return
    }
    this.jobsTradeList = []
    this.currentIndex = -1
    this.getteamRunning = true
    this.GetAllOutstandingJobs()
  }

  private get getJobEscalationTypes(): IDropdownType[] {
    return [
      { id: 0, description: EscalationTypeEnum[EscalationTypeEnum.All] },
      { id: 1, description: EscalationTypeEnum[EscalationTypeEnum.Escalated] },
      { id: 2, description: EscalationTypeEnum[EscalationTypeEnum.Standard] },
    ]
  }

  private get getVulnerabilityLevel(): IDropdownType[] {
    return [
      {
        id: 0,
        description: VulnerabilityLevelEnum[VulnerabilityLevelEnum.All],
      },
      {
        id: 1,
        description: VulnerabilityLevelEnum[VulnerabilityLevelEnum.None],
      },
      {
        id: 2,
        description: VulnerabilityLevelEnum[VulnerabilityLevelEnum.Low],
      },
      {
        id: 3,
        description: VulnerabilityLevelEnum[VulnerabilityLevelEnum.Medium],
      },
      {
        id: 4,
        description: VulnerabilityLevelEnum[VulnerabilityLevelEnum.High],
      },
    ]
  }

  private get getSortingType(): IDropdownType[] {
    return [
      {
        id: 0,
        description: Shared.insertSpaceInEnumName(WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.EtaStart]),
      },
      {
        id: 1,
        description: Shared.insertSpaceInEnumName(WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.EtaFinish]),
      },
      {
        id: 2,
        description: Shared.insertSpaceInEnumName(
          WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.SchemePriorityLowestToHighest]
        ),
      },
      {
        id: 3,
        description: Shared.insertSpaceInEnumName(
          WIPDashboardSortingTypeEnum[WIPDashboardSortingTypeEnum.SchemePriorityHighestToLowest]
        ),
      },
    ]
  }

  private filterJobsBySelectedJobEscalationType(escalationType: EscalationTypeEnum) {
    this.jobsFilter.jobEscalationType = escalationType
    this.jobsTradeList = []
    this.currentIndex = -1
    this.gettingEscalationRecordsRunning = true
    this.GetAllOutstandingJobs()
  }

  private filterJobsBySelectedJobVulnerabilityLevel(vulnerabilityLevel: VulnerabilityLevelEnum) {
    this.jobsFilter.vulnerabilityLevel = vulnerabilityLevel
    this.jobsTradeList = []
    this.currentIndex = -1
    this.vulnerabilityFilterRunning = true
    this.GetAllOutstandingJobs()
  }

  private filterJobsBySortingType(sortingType: WIPDashboardSortingTypeEnum) {
    this.jobsFilter.sortingType = sortingType
    this.jobsTradeList = []
    this.currentIndex = -1
    this.jobSortingRunning = true
    this.GetAllOutstandingJobs()
  }

  private canSeeEngineerPhoneNumber(contractorId: string): boolean {
    // if contractor is Independent show engineer phone number, else check deployment and workInProgress skill
    const contractor: ContractorModel | undefined = this.contractors.find((e) => e.id === contractorId)
    if (contractor) {
      return contractor.isIndependent ? true : store.Instance.state.SessionDetail.canSeeEngineerPhoneNumber
    }
    return false
  }

  private onRedirectToJob(jobId: string) {
    if (!Shared.isUserHasJobRights(jobId)) {
      return
    }
    // open timeline with relative card open.
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }

  private resetLoaders() {
    this.getContractorRunning = false
    this.getteamRunning = false
    this.gettingEscalationRecordsRunning = false
    this.getJobsByDateFilterRunning = false
    this.vulnerabilityFilterRunning = false
    this.jobSortingRunning = false
    this.getVisitTypeRecordsRunning = false
    this.ready = true
  }

  private get getVisitTypes(): IDropdownType[] {
    return [
      {
        id: 0,
        description: VisitTypeEnum[VisitTypeEnum.AllVisit],
        alterText: 'All',
      },
      {
        id: 1,
        description: VisitTypeEnum[VisitTypeEnum.FirstVisit],
        alterText: 'First',
      },
      {
        id: 2,
        description: VisitTypeEnum[VisitTypeEnum.ReturnVisit],
        alterText: 'Return',
      },
    ]
  }

  private filterJobsBySelectedVisitType(visitType: string) {
    this.jobsFilter.visitType = visitType
    this.jobsTradeList = []
    this.currentIndex = -1
    this.getVisitTypeRecordsRunning = true
    this.GetAllOutstandingJobs()
  }

  private get getJobDateFilterTypes(): IDropdownType[] {
    return [
      {
        id: 0,
        description: JobDateFilterType[JobDateFilterType.TodayJobs],
        alterText: "Today's Jobs",
      },
      {
        id: 1,
        description: JobDateFilterType[JobDateFilterType.FutureJobs],
        alterText: 'Future Jobs',
      },
      {
        id: 2,
        description: JobDateFilterType[JobDateFilterType.NoETASetJobs],
        alterText: 'No ETA Set Jobs',
      },
    ]
  }

  private filterJobsByDateType(jobDateFilterType: JobDateFilterType) {
    this.jobsFilter.jobDateFilterType = jobDateFilterType
    this.jobsTradeList = []
    this.currentIndex = -1
    this.getJobsByDateFilterRunning = true
    this.GetAllOutstandingJobs()
  }
}
</script>

<style scoped>
.task-header {
  padding: 10px 15px;
}
.task-header span {
  font-weight: 500;
  display: inline-block;
  margin-left: 5px;
}
.task-body .v-card:last-child {
  margin-bottom: 0;
}
.task-body .v-card >>> .v-card__title {
  padding: 6px 12px 4px;
  border-bottom: 1px solid #e0e0e0;
}
.task-body .v-card >>> .v-card__text {
  padding: 4px 12px 6px;
}
.task-body >>> .v-card__text .primary-title {
  padding-left: 20px;
  position: relative;
}
.task-body >>> .v-card__text .primary-title h4 a {
  vertical-align: middle;
}
.task-body h4.date-title .card-emergency-icon {
  position: absolute;
  left: 0;
  top: 0px;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  top: 2px;
}
.task-body h4.date-title .category-icon-img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.task-body >>> .v-card .details-row {
  position: relative;
  padding-left: 20px;
}
.task-body >>> .v-card .details-row i {
  position: absolute;
  left: -5px;
  top: 0px;
  font-size: 22px;
}
.task-body .eta-info {
  font-size: 13px;
  vertical-align: top;
  position: relative;
  padding-left: 30px;
  margin: 0;
  color: #fff;
}
.task-body .eta-info >>> .v-icon {
  vertical-align: text-bottom;
  position: absolute;
  left: 8px;
  top: 3px;
}
.all-jobs.open-block .jobs-taskboard {
  display: none;
}
.all-jobs .jobs-map {
  display: none;
}
.all-jobs.open-block .jobs-map {
  display: block;
}
.bottom-row {
  padding-right: 100px;
  position: relative;
}
.bottom-row .phone {
  position: absolute;
  right: 0px;
}
.img-text {
  position: relative;
  padding-left: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.img-text img {
  position: absolute;
  top: 3px;
  max-width: 17px;
  left: -2px;
}
.task-action {
  position: relative;
}
.task-action >>> .progress-linear {
  position: absolute;
  bottom: -10px;
  left: 0;
}
.task-col {
  min-height: 250px;
}
.unavilable-job-msg {
  display: flex;
  min-height: 200px;
}
.unavilable-job-msg span {
  margin: auto;
  color: #ababab;
  font-size: 16px;
}
.task-body .v-card >>> .v-card__actions {
  padding: 8px 4px;
}
.all-jobs >>> .v-btn--flat {
  background: transparent !important;
  box-shadow: none !important;
}
.taskboard-wrapper {
  position: relative;
}
.preferance-contact >>> .v-list__tile__avatar .v-avatar {
  height: 30px !important;
  width: 30px !important;
}
.preferance-contact >>> .v-list__tile__title {
  height: auto !important;
}
.calls-info.undeployed-jobcount .v-card {
  min-height: 75px;
}
.task-body .v-card >>> .v-card__title.icon-space {
  padding-right: 30px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.v-menu {
  display: inline-block;
}
.dashboard >>> .v-card {
  margin-bottom: 20px !important;
}
.hide-dashboard {
  visibility: hidden;
}
.show-dashboard {
  visibility: visible;
}
.date-content {
  padding-right: 30px !important;
  position: relative;
}
.date-content .cleardate {
  position: absolute;
  right: 55px;
  top: 32px;
  cursor: pointer;
}
.date-content.col2 {
  width: 50px;
}
.contractor-filter {
  width: 350px;
  display: inline-block;
}
.return-visit {
  position: absolute;
  right: -10px;
  top: 0px;
}
.return-visit img {
  width: 25px;
  height: 20px;
}
@keyframes example {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@media only screen and (max-width: 1500px) {
  .bottom-row {
    padding-right: 0;
  }
  .bottom-row .phone {
    position: relative;
    right: auto;
    margin-left: 0 !important;
  }
  .bottom-row .phone >>> .v-icon {
    margin-right: 5px;
  }
}
</style>
