import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import { DocumentTemplateType } from '@/common/enums'

export default class EmailModel extends CosmosJobsDbRecordBase {
  public to: string
  public from: string
  public subject: string
  public body: string
  public documentTemplateType: DocumentTemplateType
  public sentToNominee: boolean
}
