import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import USDashboardRecordModel from '@/models/claim/USDashboardRecordModel'
import AcceptProvisionedUSVisit from '@/models/requests/AcceptProvisionedUSVisit'

export default class UndergroundServicesController {
  public static async GetUSJobs(jobId?: string): Promise<USDashboardRecordModel[]> {
    const url = `UndergroundServices/GetUSDashboardRecords`
    const response: AxiosResponse = await CallCentreApi.get(url, {
      params: { jobId },
    })
    return response.data || []
  }

  public static async AcceptProvisionedUSVisit(acceptProvisionedUSVisit: AcceptProvisionedUSVisit): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<AcceptProvisionedUSVisit>(
      'UndergroundServices/AcceptProvisionedUSVisit',
      acceptProvisionedUSVisit
    )
    return res.status >= 200 && res.status < 300
  }
}
