<template>
  <div class="consumerrate">
    <v-layout wrap>
      <v-flex xs12>
        <v-card class="elevation-0">
          <v-card-title primary-title class="py-0 mb-2">
            <h3 class="mb-0">Phone Numbers</h3>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
            <v-tooltip bottom nudge-left="30px">
              <template #activator="{ on }">
                <v-btn icon class="mx-0 add-action-btn" v-on="on" @click="onCreateCustomerPhoneNumber">
                  <v-icon color="grey">add</v-icon>
                </v-btn>
              </template>
              <span>Add Phone Number</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container fluid grid-list-lg pt-0>
              <v-layout wrap>
                <v-flex xs12 px-0>
                  <v-data-table
                    :headers="headers"
                    :items="insurerPhoneNumberList"
                    :loading="isLoading"
                    :search="search"
                    item-key="name"
                    class="gridView"
                    :pagination.sync="pagination"
                  >
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td>{{ props.item.label }}</td>
                        <td class="text-xs-center">
                          {{ props.item.phoneNumber }}
                        </td>
                        <td class="text-xs-right">
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-btn icon class="mx-0" v-on="on" @click="editPhoneNumber(props.item)">
                                <v-icon color="primary">edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                class="mx-0 mt-2 ml-2"
                                v-on="on"
                                @click="deletePhoneNumberConfirmation(props.item.phoneNumber)"
                              >
                                <v-icon color="primary">delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
        <!-- dialog - show add phone number box -->
        <v-dialog
          v-if="showPhoneNumberDialog"
          v-model="showPhoneNumberDialog"
          max-width="650"
          persistent
          content-class="v-dialog--scrollable"
        >
          <v-card>
            <v-toolbar card dark color="primary">
              <v-toolbar-title>Phone Number</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="showPhoneNumberDialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-3 scroll-content-dialog">
              <v-layout wrap>
                <v-flex xs6 pr-2>
                  <v-text-field
                    v-model="insurerPhoneNumberToAdd.label"
                    v-validate="'required'"
                    label="Label"
                    required
                    class="required"
                    data-vv-scope="refCustomerInput"
                    data-vv-name="Label"
                    :error-messages="errors.collect('Label')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 pl-2>
                  <PhoneNumber
                    ref="refCustomerPhoneNumber"
                    :passed-phone-number="insurerPhoneNumberToAdd.phoneNumber"
                    :is-validation-required="true"
                    class="internal-phone-number pl-0"
                    @phoneNumberUpdated="phoneNumberUpdated"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-3">
              <v-spacer></v-spacer>
              <v-btn color="primary" flat="flat" @click.native="showPhoneNumberDialog = false">Close</v-btn>
              <v-btn
                color="primary"
                class="mr-0"
                :disabled="onSubmitLoading"
                :loading="onSubmitLoading"
                @click="submitCustomerPhoneNumberDetail"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import PolicyHolderController from '@/api/policyHolderController'
import Shared from '@/common/shared'
import InsurerPhoneNumberModel from '@/models/client/InsurerPhoneNumberModel'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import InsurerController from '../api/InsurerController'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import eventBus from '@/common/bus'

@Component({
  name: 'CustomerPhoneNumber',
  components: {
    PhoneNumber,
  },
})
export default class CustomerPhoneNumbers extends Vue {
  @Prop() private selectedInsurerId: number
  private headers: any[]
  private search = ''
  private pagination: any = {}
  private insurerPhoneNumberList: InsurerPhoneNumberModel[] = []
  private isLoading = false
  private onSubmitLoading = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private showPhoneNumberDialog = false
  private insurerPhoneNumberToAdd: InsurerPhoneNumberModel = new InsurerPhoneNumberModel()
  private insurerPhoneNumberToAddTemp: InsurerPhoneNumberModel | null = null

  private created() {
    this.headers = [
      { text: 'Name', value: 'label', align: 'left' },
      { text: 'Phone Number', value: 'phoneNumber', align: 'center' },
      { text: 'Actions', sortable: false, value: 'action', align: 'right' },
    ]
    this.pagination.page = 1
    this.pagination.rowsPerPage = Shared.rowsPerPageDefault

    this.getInsurerPhoneList()
  }

  @Watch('selectedInsurerId')
  private async onInsurerChange() {
    this.getInsurerPhoneList()
  }

  private getInsurerPhoneList() {
    const self = this
    // check store for exists phone numbers
    let phoneNumbersList: InsurerPhoneNumberModel[] = storeGetters.getCustomersPhoneNumber()
    if (phoneNumbersList && phoneNumbersList.length === 0) {
      self.isLoading = true
      InsurerController.GetInsurerPhoneNumbers()
        .then((res: InsurerPhoneNumberModel[]) => {
          if (res) {
            storeMutations.setCustomersPhoneNumber(res)
            res = res.filter((x: InsurerPhoneNumberModel) => x.insurerId === this.selectedInsurerId)
            self.insurerPhoneNumberList = res
          }
          self.isLoading = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading insurer phonenumber list, please try again', true)
          self.isLoading = false
        })
    } else {
      phoneNumbersList = phoneNumbersList.filter((x: InsurerPhoneNumberModel) => x.insurerId === this.selectedInsurerId)
      self.insurerPhoneNumberList = phoneNumbersList
    }
  }

  private editPhoneNumber(insurerPhoneNumberData: InsurerPhoneNumberModel) {
    this.insurerPhoneNumberToAddTemp = null
    this.showPhoneNumberDialog = true
    this.insurerPhoneNumberToAdd = Object.assign({}, insurerPhoneNumberData)
    this.insurerPhoneNumberToAddTemp = Object.assign({}, insurerPhoneNumberData)
  }

  private onCreateCustomerPhoneNumber() {
    this.$validator.errors.items = []
    this.insurerPhoneNumberToAddTemp = null
    this.showPhoneNumberDialog = true
    this.insurerPhoneNumberToAdd = new InsurerPhoneNumberModel()
  }

  private submitCustomerPhoneNumberDetail() {
    const self = this
    self
      .validate()
      .then((result: boolean) => {
        if (result) {
          if (self.insurerPhoneNumberToAddTemp) {
            // existing, if values same as before
            if (
              JSON.stringify(self.insurerPhoneNumberToAdd).toLowerCase() ===
              JSON.stringify(self.insurerPhoneNumberToAddTemp).toLowerCase()
            ) {
              this.insurerPhoneNumberToAddTemp = null
              self.showPhoneNumberDialog = false
              return
            } else if (self.checkIsNumberAlreadyAssigned(self.insurerPhoneNumberToAdd.phoneNumber)) {
              if (self.insurerPhoneNumberToAdd.phoneNumber !== self.insurerPhoneNumberToAddTemp.phoneNumber) {
                self.snackbar = true
                self.snackbarText = 'Number already assigned.'
                return
              }
            }
          } else {
            // new, check number already exists
            if (self.checkIsNumberAlreadyAssigned(self.insurerPhoneNumberToAdd.phoneNumber)) {
              self.snackbar = true
              self.snackbarText = 'Number already assigned.'
              return
            }
          }

          const oldPhoneNumber: string = self.insurerPhoneNumberToAddTemp
            ? self.insurerPhoneNumberToAddTemp.phoneNumber
            : ''

          self.onSubmitLoading = true
          self.insurerPhoneNumberToAdd.insurerId = self.selectedInsurerId
          InsurerController.SaveInsurerPhoneNumber(oldPhoneNumber, self.insurerPhoneNumberToAdd)
            .then((res: boolean) => {
              if (res) {
                const insurerPhoneNumberList = storeGetters.getCustomersPhoneNumber()
                if (self.insurerPhoneNumberToAddTemp && self.insurerPhoneNumberToAddTemp != null) {
                  // update existing record from the list
                  const index = self.insurerPhoneNumberList.findIndex(
                    (x: InsurerPhoneNumberModel) => x.phoneNumber === oldPhoneNumber
                  )
                  if (index !== -1) {
                    self.insurerPhoneNumberList[index].label = self.insurerPhoneNumberToAdd.label
                    self.insurerPhoneNumberList[index].phoneNumber = self.insurerPhoneNumberToAdd.phoneNumber
                    self.insurerPhoneNumberList = [...self.insurerPhoneNumberList]

                    const indexStore = insurerPhoneNumberList.findIndex(
                      (x: InsurerPhoneNumberModel) => x.phoneNumber === oldPhoneNumber
                    )
                    if (indexStore !== -1) {
                      insurerPhoneNumberList.splice(indexStore, 1)
                    }
                  }
                } else {
                  // add new record to the list
                  self.insurerPhoneNumberList.push(self.insurerPhoneNumberToAdd)
                }
                // update values in store
                insurerPhoneNumberList.push(self.insurerPhoneNumberToAdd)
                storeMutations.setCustomersPhoneNumber(insurerPhoneNumberList)

                self.showPhoneNumberDialog = false
                self.insurerPhoneNumberToAddTemp = null
                // add/update in the list
              } else {
                self.snackbar = true
                self.snackbarText = 'Data not saved. Please try again.'
              }
              self.onSubmitLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error saving phone number, please try again', true)
              self.onSubmitLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
        self.onSubmitLoading = false
      })
  }

  private checkIsNumberAlreadyAssigned(phoneNumber: string): boolean {
    let numberAlreadyAssignes = false
    // take values from the store
    const insurerPhoneNumberList = storeGetters.getCustomersPhoneNumber()
    // new, check number already exists
    // const index = this.insurerPhoneNumberList.findIndex((x: InsurerPhoneNumberModel) => x.phoneNumber === phoneNumber);
    const index = insurerPhoneNumberList.findIndex((x: InsurerPhoneNumberModel) => x.phoneNumber === phoneNumber)
    if (index !== -1) {
      numberAlreadyAssignes = true
    }
    return numberAlreadyAssignes
  }

  private async validate(): Promise<boolean> {
    let result = true

    // validate label
    const isLabelValidated: boolean = await this.$validator.validateAll('refCustomerInput')
    // validate phone number
    let contactNumberValidation = true
    const contactNumberValidationSub: any = this.$refs.refCustomerPhoneNumber as PhoneNumber
    contactNumberValidation = contactNumberValidationSub.$validator.validateAll() && contactNumberValidationSub.isValid

    result = isLabelValidated && contactNumberValidation

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private deletePhoneNumberConfirmation(phoneNumber: string) {
    Shared.confirmationPopup.open(
      'Do you really want to delete ' + phoneNumber + '?',
      '',
      '',
      '',
      '',
      this,
      'deletePhoneNumber',
      phoneNumber
    )
  }

  private deletePhoneNumber(phoneNumber: string) {
    this.isLoading = true
    InsurerController.DeleteInsurerPhoneNumber(phoneNumber)
      .then((res: boolean) => {
        if (res) {
          const index = this.insurerPhoneNumberList.findIndex(
            (x: InsurerPhoneNumberModel) => x.phoneNumber === phoneNumber
          )
          if (index !== -1) {
            this.insurerPhoneNumberList.splice(index, 1)

            // update values in store
            const insurerPhoneNumberList = storeGetters.getCustomersPhoneNumber()
            const indexStore = insurerPhoneNumberList.findIndex(
              (x: InsurerPhoneNumberModel) => x.phoneNumber === phoneNumber
            )
            if (indexStore !== -1) {
              insurerPhoneNumberList.splice(indexStore, 1)
            }
            storeMutations.setCustomersPhoneNumber(insurerPhoneNumberList)
          }
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error deleting phone number, please try again', true)
        this.isLoading = false
      })
  }

  private phoneNumberUpdated(phoneNumber: string): void {
    if (this.insurerPhoneNumberToAdd) {
      this.insurerPhoneNumberToAdd.phoneNumber = phoneNumber
    }
  }
}
</script>

<style scoped>
/*PhoneNumber Component*/
.internal-phone-number {
  position: relative;
  padding-top: 18px !important;
}
.internal-phone-number >>> .contact-number {
  position: relative;
  border-bottom: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
.internal-phone-number >>> .contact-number .input-group .input-group__details {
  opacity: 1;
  padding-right: 0px;
}
.internal-phone-number >>> .call-number-txt {
  max-width: calc(100% - 50px);
  display: inline-block;
  padding-left: 0 !important;
}
.internal-phone-number >>> .selectedCountry {
  max-width: 50px;
  display: inline-block;
  position: static;
  padding-left: 0 !important;
  vertical-align: top;
}
.internal-phone-number >>> .selectedCountry .input-group__selections {
  display: inline-block;
  position: relative;
  top: 5px;
}
.internal-phone-number >>> .input-group input,
.internal-phone-number >>> .selectedCountry .input-group__input,
.internal-phone-number >>> .validation,
.internal-phone-number >>> .contact-number .input-group--text-field label {
  opacity: 1;
}
.internal-phone-number >>> .backspace-icon {
  display: none;
}
.internal-phone-number >>> .validation {
  position: absolute;
  right: 7px;
  top: 21px;
}
.internal-phone-number >>> .v-tooltip {
  z-index: 999;
}
.internal-phone-number >>> .contact-number .input-group--text-field label {
  top: 0px !important;
  left: 10px;
  font-size: 15px;
  display: block;
}
.internal-phone-number >>> .contact-number .input-group--text-field label {
  top: 0px !important;
  left: 0px;
  display: block;
}
.internal-phone-number >>> .contact-number .input-group--focused label {
  -webkit-transform: translateY(-18px) scale(0.75);
  transform: translateY(-18px) scale(0.75);
}
/* action button */
.add-action-btn {
  position: relative !important;
  top: 6px;
}
</style>
