<template>
  <v-snackbar
    v-model="snackbar"
    class="user-call-notification"
    :timeout="0"
    multi-line
    top
    right
    auto-height
    color="white"
  >
    <v-layout row wrap>
      <v-flex xs8>
        <p class="mb-1">
          <b class="subheadingfont">
            {{ callTaskAlert ? callTaskAlert.clientDetails : '' }}
          </b>
        </p>
        <p class="mb-1" :style="getColourStyle">
          <b>{{ callTaskAlert ? callTaskAlert.incomingAlertReason : '' }}</b>
        </p>
        <p class="mb-1 body-1 text-green">
          {{ callTaskAlert ? callTaskAlert.policyDetails : '' }}
          <v-icon v-if="callTaskAlert && callTaskAlert.isVerified" small color="green">check_circle</v-icon>
        </p>
        <p class="mb-1">
          {{ callTaskAlert ? callTaskAlert.additionalDetails : '' }}
        </p>
      </v-flex>
      <v-flex class="xs4">
        <img :src="callTaskAlert ? callTaskAlert.policyLogoUrl : ''" class="img-responsive" />
      </v-flex>
      <v-flex xs12 class="text-xs-right mt-2">
        <v-btn v-if="callTaskAlert && callTaskAlert.allowReject" fab color="red" @click.native="callEnd">
          <v-icon>call_end</v-icon>
        </v-btn>
        <v-btn
          v-if="callTaskAlert && callTaskAlert.incomingAlertType === 'Callback Request'"
          fab
          color="green"
          class="call-vibration"
          style="border-radius: 10px"
          @click.native="initiateCallback"
        >
          Start Call
        </v-btn>
        <v-btn
          v-if="callTaskAlert && callTaskAlert.incomingAlertType !== 'Callback Request'"
          fab
          color="green"
          class="call-vibration"
          @click.native="onCallReceived"
        >
          <v-icon>call</v-icon>
        </v-btn>
      </v-flex>
      <v-flex v-if="callTaskAlert && callTaskAlert.isVerified" xs12 mt-2>
        <span class="auto-dpa">Customer has completed Auto DPA</span>
      </v-flex>
    </v-layout>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store'
import CallTaskAlert from '@/models/twilio/CallTaskAlert'

@Component
export default class IncomingCallTaskAlert extends Vue {
  @Prop() public callTaskAlert: CallTaskAlert
  private snackbar = true

  private get getColourStyle() {
    return 'color:' + this.$vuetify.theme.secondary + ';'
  }

  private onCallReceived() {
    this.$emit('answerCall')
  }

  private callEnd() {
    this.$emit('rejectCall')
  }

  private initiateCallback() {
    this.$emit('initiateCallback')
  }
}
</script>

<style scoped>
.user-call-notification >>> .snack__content .v-btn {
  margin: 0px;
  margin-left: 8px;
  padding: 7px;
}
.user-call-notification {
  color: #000;
  margin-top: 35px;
  margin-right: 100px;
}
.user-call-notification >>> .snack__content {
  padding-bottom: 16px;
}
.user-call-notification >>> .snack__wrapper {
  max-width: 430px;
}
.img-responsive {
  max-height: 80px;
  max-width: 80px;
  width: auto;
  height: auto;
  float: right;
}
.call-vibration {
  animation-name: spaceboots;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.auto-dpa {
  background: lightgreen;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 13px;
}
</style>
