<template>
  <v-layout v-if="emergencyCoverage" wrap>
    <v-flex xs6 class="pr-2">
      <v-select
        v-model="emergencyCoverage.startMonth"
        :items="monthList"
        :hide-details="true"
        label="Start month"
        item-text="name"
        item-value="id"
      ></v-select>
    </v-flex>
    <v-flex xs6 class="pl-2">
      <v-select
        v-model="emergencyCoverage.endMonth"
        :items="monthList"
        :hide-details="true"
        label="End month"
        item-text="name"
        item-value="id"
      ></v-select>
    </v-flex>
    <v-flex xs12>
      <v-textarea
        v-model.trim="emergencyCoverage.coverageDetails"
        label="Coverage details"
        rows="2"
        :hide-details="true"
        :no-resize="true"
        maxlength="2000"
      ></v-textarea>
    </v-flex>
    <v-flex xs12>
      <v-textarea
        v-model.trim="emergencyCoverage.exclusions"
        label="Exclusions"
        rows="2"
        :hide-details="true"
        :no-resize="true"
        maxlength="2000"
      ></v-textarea>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        v-model="emergencyCoverage.isMarkedAsCTP"
        hide-details
        color="primary"
        label="Tag as CTP"
      ></v-checkbox>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import EmergencyCoverageModel from '@/models/policyHolder/EmergencyCoverageModel'
import { Month } from '@/common/interfaces'
import Shared from '@/common/shared'
@Component
export default class EditEmergencyCoverage extends Vue {
  @Prop() private item: EmergencyCoverageModel | null

  private get emergencyCoverage(): EmergencyCoverageModel | null {
    return this.item
  }

  private get monthList(): Month[] {
    return Shared.getMonthList()
  }
}
</script>
