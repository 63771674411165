import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import ClientJobSummary from '@/models/client/enhanced-access/client-job-summary'
import IJobRequest from '@/models/requests/base/interfaces/job-request'
import moment from 'moment'

export default class EnhancedClientController {
  public static async GetJobRequests(jobId: string): Promise<IJobRequest | null> {
    const response = await CallCentreApi.get<IJobRequest>(`EnhancedClient/GetJobRequests?jobId=${encodeURIComponent(jobId)}`)
    return response ? response.data : null
  }

  public static async GetJobs(clientId: number): Promise<ClientJobSummary[] | null> {
    const response = await CallCentreApi.get<ClientJobSummary[]>(`EnhancedClient/GetJobs?clientId=${clientId}`)
    const jobs = response.data || [];

    jobs.forEach(job => {
      if (!job.nextEta) return
      if (job.nextEta.from) { job.nextEta.from = moment(job.nextEta.from) }
      if (job.nextEta.to) { job.nextEta.to = moment(job.nextEta.to) }
    })

    return jobs
  }
}
