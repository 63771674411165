import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import ClientUserDetailModel from '@/models/client/ClientUserDetailModel'
import JobHeader from '@/models/JobHeader'

export default class ClientUserController {
  public static async SaveClientUser(
    clientUserModel: ClientUserDetailModel | null
  ): Promise<ClientUserDetailModel | string> {
    const res: AxiosResponse = await CallCentreApi.post('ClientUser/SaveClientUser', clientUserModel)
    return res.status >= 200 && res.status < 300 ? res.data : res.statusText
  }

  public static async GetClientUsers(insurerId: number): Promise<ClientUserDetailModel[]> {
    const res = await CallCentreApi.get<ClientUserDetailModel[]>('ClientUser/GetClientUsers?insurerId=' + insurerId)
    return res.data ? res.data : []
  }

  public static async DeactivateClientUser(clientUserId: string): Promise<boolean> {
    const res = await CallCentreApi.delete('ClientUser/DeactivateClientUser?clientUserId=' + clientUserId)
    return res.status >= 200 && res.status < 300
  }

  public static async SearchClientJobHeader(insurerId: number, searchKeyword: string): Promise<JobHeader[]> {
    const res: AxiosResponse<JobHeader[]> = await CallCentreApi.get<JobHeader[]>(
      'ClientUser/SearchClientJobHeader?insurerId=' + insurerId + '&keyword=' + searchKeyword.toUpperCase()
    )
    return res.data ? res.data : []
  }
}
