export default class AddCommodity {
  public jobId: string // can be blank/undefined if adding as part of a new job
  public policyId: number
  public commodityTypeId: number
  public commodityTypeDesc: string
  public commodityDetailId: number | null
  public commodityDetailDesc: string
  public note: string
  public fileURL: string | null
  public excessValue: number | null
  public commodityWorkType: string

  public constructor() {
    this.commodityDetailId = null
  }
}
