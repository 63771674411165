<template>
  <div>
    <v-flex
      v-if="todaySlots.length > 0 || (tomorrowSlots.length === 0 && dayAfterTomorrowSlots.length === 0)"
      xs12
      class="mb-2 today-slots"
    >
      <div class="show-clta-time">
        <span class="show-clta-label">
          <v-icon color="secondary" class="mr-1">date_range</v-icon>
          <label for="">{{ getFirstPickedDate }}:</label>
          &nbsp;
        </span>
        <template v-for="item in todaySlots">
          <v-chip v-if="item.slotId > 0" :key="item.slotId" flat outline class="white--text" color="primary" dark small>
            <b>
              {{ new Date(item.startTime).getHours() }} to
              {{ new Date(item.endTime).getHours() === 0 ? 24 : new Date(item.endTime).getHours() }}
            </b>
          </v-chip>
        </template>
      </div>
    </v-flex>
    <v-flex v-if="tomorrowSlots.length > 0" xs12 class="mb-2 tomorrow-slots">
      <div class="show-clta-time">
        <span class="show-clta-label">
          <v-icon color="secondary" class="mr-1">date_range</v-icon>
          <label for="">{{ getSecondPickedDate }}:</label>
          &nbsp;
        </span>
        <template v-for="item in tomorrowSlots">
          <v-chip v-if="item.slotId > 0" :key="item.slotId" flat outline class="white--text" color="primary" dark small>
            <b>
              {{ new Date(item.startTime).getHours() }} to
              {{ new Date(item.endTime).getHours() === 0 ? 24 : new Date(item.endTime).getHours() }}
            </b>
          </v-chip>
        </template>
      </div>
    </v-flex>
    <v-flex v-if="dayAfterTomorrowSlots.length > 0" xs12 class="mb-2 dayaftertomorrow-slots">
      <div class="show-clta-time">
        <span class="show-clta-label">
          <v-icon color="secondary" class="mr-1">date_range</v-icon>
          <label for="">{{ getThirdPickedDate }}:</label>
          &nbsp;
        </span>
        <template v-for="item in dayAfterTomorrowSlots">
          <v-chip v-if="item.slotId > 0" :key="item.slotId" flat outline class="white--text" color="primary" dark small>
            <b>
              {{ new Date(item.startTime).getHours() }} to
              {{ new Date(item.endTime).getHours() === 0 ? 24 : new Date(item.endTime).getHours() }}
            </b>
          </v-chip>
        </template>
      </div>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeSlot from '@/models/claim/TimeSlot'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'

@Component
export default class Availability extends Vue {
  @Prop() private customerAvailability: CustomerAvailabilityModel

  private today: TimeSlot[] = []
  private tomorrow: TimeSlot[] = []
  private dayAfterTomorrow: TimeSlot[] = []
  private todayDate = ''
  private tomorrowDate = ''
  private dayAfterTomorrowDate: Date = new Date()
  private customFirstPickedDate = ''
  private customSecondPickedDate = ''
  private customThirdPickedDate = ''

  private mounted() {
    this.setCustomerAvailability()
  }

  @Watch('customerAvailability')
  private onCustomerAvailabilityChange() {
    this.setCustomerAvailability()
  }

  private setCustomerAvailability() {
    if (
      this.customerAvailability &&
      this.customerAvailability.timeSlot &&
      this.customerAvailability.timeSlot.length > 0
    ) {
      this.customerAvailability.timeSlot.forEach((e: TimeSlot) => {
        e.startTime = new Date(e.startTime)
        e.endTime = new Date(e.endTime)
      })
    }

    if (!this.customerAvailability.timeSlot) {
      this.customerAvailability.timeSlot = []
    }

    // fill default dates as today, tomorrow and day after tomorrow
    this.fillDefaultDates()
  }

  private fillDefaultDates() {
    const tempDate: Date = new Date()
    this.customFirstPickedDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
    this.todayDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
    tempDate.setDate(tempDate.getDate() + 1)
    this.customSecondPickedDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
    this.tomorrowDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
    tempDate.setDate(tempDate.getDate() + 1)
    this.customThirdPickedDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
  }

  private get getFirstPickedDate(): string {
    if (this.todayDate === this.customFirstPickedDate) {
      return 'Today'
    } else if (this.tomorrowDate === this.customFirstPickedDate) {
      return 'Tomorrow'
    }
    return this.customFirstPickedDate
  }

  private get getSecondPickedDate(): string {
    if (this.todayDate === this.customSecondPickedDate) {
      return 'Today'
    } else if (this.tomorrowDate === this.customSecondPickedDate) {
      return 'Tomorrow'
    }
    return this.customSecondPickedDate
  }

  private get getThirdPickedDate(): string {
    if (this.todayDate === this.customThirdPickedDate) {
      return 'Today'
    } else if (this.tomorrowDate === this.customThirdPickedDate) {
      return 'Tomorrow'
    }
    return this.customThirdPickedDate
  }

  private getAvailability(timeSlots: TimeSlot[], availabilityType: number): TimeSlot[] {
    const availability: TimeSlot[] = []
    let isNewSlot = true
    let item: TimeSlot | null = null
    let slotId = 0
    let availableSlots: TimeSlot[] = []
    availableSlots = Object.assign([], availableSlots, timeSlots)
    availableSlots.forEach((slot: TimeSlot) => {
      if (isNewSlot) {
        item = new TimeSlot()
        this.setTimeSlot(item, slot)
        availability.push(item)
        isNewSlot = false
        slotId = item.slotId
      } else {
        if (item) {
          if (slot.slotId - slotId === 1) {
            item.endTime = slot.endTime
            slotId = slot.slotId
          } else {
            item = new TimeSlot()
            this.setTimeSlot(item, slot)
            availability.push(item)
            slotId = slot.slotId
          }
        }
      }
    })

    if (availabilityType === 1) {
      const firstDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 1)
      if (firstDateTimeSlots.length > 0) {
        Shared.SortData(firstDateTimeSlots, 'startTime')
        this.customFirstPickedDate = Shared.getFormatedDate(
          moment(firstDateTimeSlots.map((c) => c.startTime)[0]),
          Store.Instance.state.Environment.DateFormat
        )
      }
    } else if (availabilityType === 2) {
      const secondDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 2)
      if (secondDateTimeSlots.length > 0) {
        Shared.SortData(secondDateTimeSlots, 'startTime')
        this.customSecondPickedDate = Shared.getFormatedDate(
          moment(secondDateTimeSlots.map((c) => c.startTime)[0]),
          Store.Instance.state.Environment.DateFormat
        )
      }
    } else if (availabilityType === 3) {
      const thirdDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 3)
      if (thirdDateTimeSlots.length > 0) {
        Shared.SortData(thirdDateTimeSlots, 'startTime')
        this.customThirdPickedDate = Shared.getFormatedDate(
          moment(thirdDateTimeSlots.map((c) => c.startTime)[0]),
          Store.Instance.state.Environment.DateFormat
        )
      }
    }

    return availability
  }

  private setTimeSlot(newSlot: TimeSlot, existingSlot: TimeSlot) {
    newSlot.slotId = existingSlot.slotId
    newSlot.slot = existingSlot.slot
    newSlot.isDayTime = existingSlot.isDayTime
    newSlot.startTime = existingSlot.startTime
    newSlot.endTime = existingSlot.endTime
  }

  private get todaySlots(): TimeSlot[] {
    if (
      this.customerAvailability &&
      this.customerAvailability.timeSlot &&
      this.customerAvailability.timeSlot.length > 0
    ) {
      return this.getAvailability(
        Shared.SortData(
          this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 1),
          'startTime'
        ),
        1
      )
    } else {
      return []
    }
  }

  private get tomorrowSlots(): TimeSlot[] {
    if (
      this.customerAvailability &&
      this.customerAvailability.timeSlot &&
      this.customerAvailability.timeSlot.length > 0
    ) {
      return this.getAvailability(
        Shared.SortData(
          this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 2),
          'startTime'
        ),
        2
      )
    } else {
      return []
    }
  }

  private get dayAfterTomorrowSlots(): TimeSlot[] {
    if (
      this.customerAvailability &&
      this.customerAvailability.timeSlot &&
      this.customerAvailability.timeSlot.length > 0
    ) {
      return this.getAvailability(
        Shared.SortData(
          this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 3),
          'startTime'
        ),
        3
      )
    } else {
      return []
    }
  }
}
</script>

<style scoped>
.show-clta-time {
  position: relative;
  padding-left: 110px;
  min-height: 34px;
}
.show-clta-time .show-clta-label {
  position: absolute;
  left: 0px;
  top: 0px;
}
</style>
