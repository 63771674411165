import CCTVControlDistance from './CCTVControlDistance'

export default class CCTVControlRunLogModel {
  public cctvRunNumber: number
  public from: string
  public to: string
  public pipeMaterial: string
  public pipeDiameter: number
  public direction: string
  public function: string
  public comments: string
  public invertLevelOne: number
  public invertLevelTwo: number
  public sharedRun: string
  public gullyCondition: string
  public distances: CCTVControlDistance[]

  public constructor() {
    if (!this.distances) {
      this.distances = []
    }
  }
}
