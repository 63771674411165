<template>
  <div v-if="customerToPayModel" class="question-title-desc">
    <v-layout class="mt-2" wrap>
      <v-flex v-if="customerToPayModel.isTransactionCompleted" xs12>
        <p class="mb-2">Payment taken on {{ customerToPayModel.cardBrand }} card</p>
        <p class="mb-2">
          <v-icon>credit_card</v-icon>
          <b>**** **** **** {{ customerToPayModel.cardLastFourDigit }}</b>
        </p>
        <p class="mb-1">
          Payment date:
          <b class="secondary--text">{{ getPaymentDate }}</b>
        </p>
      </v-flex>
      <v-flex v-else>
        <p class="mb-2">Payment not done</p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import FinancialSummaryModel from '@/models/claim/FinancialSummaryModel'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import TimeLineItemBase from '@/components/TimeLineItemBase'

@Component
export default class CustomerToPayCard extends TimeLineItemBase {
  private customerToPayModel: CustomerToPayModel | null = new CustomerToPayModel(this.jobId)

  private get informationItem(): CustomerToPayModel | null {
    if (this.itemId) {
      return storeGetters.getCustomerToPayByItemId(this.jobId, this.itemId)
    } else {
      return new CustomerToPayModel(this.jobId)
    }
  }

  private get getPaymentDate(): string {
    if (this.informationItem && this.informationItem.transactionDate) {
      return Shared.getFormatedDate(
        moment(this.informationItem.transactionDate),
        Store.Instance.state.Environment.DateFormat
      )
    } else {
      return '--/--/----'
    }
  }

  private mounted() {
    if (this.informationItem) {
      this.customerToPayModel = { ...this.informationItem }
    }
  }

  @Watch('informationItem')
  private customerToPayChanged(): void {
    if (this.informationItem && this.informationItem !== this.customerToPayModel) {
      this.customerToPayModel = { ...this.informationItem }
    }
  }
}
</script>
