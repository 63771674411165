import { AxiosResponse } from 'axios';
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin';
import AuthPolicies from '@/models/auth/auth-policies';

export default class AuthorizationController {
    public static async GetUserPolicies(): Promise<AuthPolicies | null> {
        const res: AxiosResponse<AuthPolicies> = await CallCentreApi.get<AuthPolicies>('Authorization/GetUserPolicies');
        return res.status === 200 ? res.data : null;
    }
}
