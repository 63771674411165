<template>
  <v-card v-if="item" class="pt-0 px-2 pb-2">
    <v-card-title class="px-0 py-2">
      <h3 class="heading">
        {{ item.title }}
        <span v-if="item.noteType" class="teal--text">
          {{ getNoteTypeDescription(item.noteType) }}
        </span>
      </h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="px-0 py-0 mt-2">
      <pre>{{ item.description }}</pre>
      <v-layout>
        <v-flex v-if="item && canPlayCall && (item.callRecordingUrl || item.callSid)" xs12 class="px-0 mt-1">
          <v-divider v-if="item.description"></v-divider>
          <LinkedPhoneCallDetails
            :recording-url="item.callRecordingUrl"
            :call-sid="item.callSid"
            :job-id="item.jobId"
            :show-header="showHeader"
          ></LinkedPhoneCallDetails>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import JobNoteModel from '@/models/claim/JobNoteModel'
import storeGetters from '@/storeGetters'
import { JobNoteType, JobNoteTypes } from '@/common/enums'
import JobController from '@/api/jobController'
import store from '@/store'
import LinkedPhoneCallDetails from '@/components/timeline/LinkedPhoneCallDetails.vue'

@Component({
  components: { LinkedPhoneCallDetails },
})
export default class JobNotePreview extends TimeLineItemBase {
  private showHeader = false
  private get item(): JobNoteModel | null {
    if (this.jobId && this.itemId) {
      return storeGetters.getJobNote(this.jobId, this.itemId)
    }
    return null
  }

  private getNoteTypeDescription(noteType: JobNoteType): string {
    const match = JobNoteTypes.find((e) => e.id === noteType)
    if (match) {
      return match.description
    }
    return ''
  }

  private get canPlayCall() {
    return store.Instance.state.SessionDetail.canPlayCall
  }
}
</script>

<style scoped>
pre {
  font-family: inherit;
  white-space: pre-line;
}
</style>
