export default class PasswordValidation {
  public text: string
  public type: string
  public regex: RegExp
  public color: string

  public constructor(text: string, type: string, regex: RegExp, color = 'black--text') {
    this.text = text
    this.type = type
    this.regex = regex
    this.color = color
  }
}
