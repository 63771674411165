import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import IUsersState from '../states/interfaces/users-state'
import UsersController from '@/api/users-controller'
import UsersState from '../states/users-state'
import UserDetailModel from '@/models/users/user-detail-model'
import UserListItemModel from '@/models/users/user-list-item-model'
import UserPermissionsModel from '@/models/users/user-permissions-model'
import UserRoleListItemModel from '@/models/users/user-role-list-item-model'
import UserTeamListItemModel from '@/models/users/user-team-list-item-model'
import UpdateUserResponseModel from '@/models/users/update-user-response-model'
import RelativeUserPermissionsModel from '@/models/users/relative-user-permissions-model'

export const usersState: IUsersState = new UsersState()

export const usersGetters: GetterTree<IUsersState, any> = {
  loading: (state) => state.loadingUsers || state.loadingTeams || state.loadingRoles || state.loadingPermissions,
  loadingUsers: (state) => state.loadingUsers,
  loadingTeams: (state) => state.loadingTeams,
  loadingRoles: (state) => state.loadingRoles,
  showDeletedUsers: (state) => state.showDeletedUsers,
  allUsers: (state) => {
    if (state.showDeletedUsers) {
      return state.users
    }
    return state.users.filter((u) => u.isActive)
  },
  teams: (state) => state.teams,
  roles: (state) => state.roles,
  selectedUser: (state) => state.selectedUser,
  relativeUserPermissions: (state) => state.relativeUserPermissions,
  creatingUser: (state) => state.selectedUser && !state.selectedUser.id,
  showEditDialog: (state) => !!state.selectedUser,
  canCreateUsers: (state) => state.permissions.canCreate,
  canListUsers: (state) => state.permissions.canList,
  canDeleteUsers: (state) => state.permissions.canDelete,
}

export const mutations: MutationTree<IUsersState> = {
  setLoadingUsers: (state, loading: boolean) => {
    state.loadingUsers = loading
  },
  setLoadingTeams: (state, loading: boolean) => {
    state.loadingTeams = loading
  },
  setLoadingRoles: (state, loading: boolean) => {
    state.loadingRoles = loading
  },
  setLoadingPermissions: (state, loading: boolean) => {
    state.loadingPermissions = loading
  },
  setUsers: (state, users: UserListItemModel[]) => {
    state.users = [...users]
  },
  setTeams: (state, teams: UserTeamListItemModel[]) => {
    state.teams = [...teams]
  },
  setRoles: (state, roles: UserRoleListItemModel[]) => {
    state.roles = [...roles]
  },
  setPermissions: (state, perms: UserPermissionsModel) => {
    state.permissions = perms
  },
  setSelectedUser: (state, user: UserDetailModel | null) => {
    state.selectedUser = user
  },
  setRelativePermissions: (state, permissions: RelativeUserPermissionsModel | null) => {
    state.relativeUserPermissions = permissions
  },
  setShowDeletedUsers: (state, show: boolean) => {
    state.showDeletedUsers = show
  },
}

export const actions: ActionTree<IUsersState, any> = {
  async retrievePermissions({ commit }) {
    commit('setLoadingPermissions', true)
    const perms = await UsersController.GetPermissionsAsync()
    commit('setPermissions', perms)
    commit('setLoadingPermissions', false)
  },
  async retrieveTeams({ commit }) {
    commit('setLoadingTeams', true)
    const teams = await UsersController.GetTeamsAsync()
    commit('setTeams', teams)
    commit('setLoadingTeams', false)
  },
  async retrieveRoles({ commit }) {
    commit('setLoadingRoles', true)
    const roles = await UsersController.GetRolesAsync()
    commit('setRoles', roles)
    commit('setLoadingRoles', false)
  },
  async retrieveUsers({ commit }) {
    commit('setLoadingUsers', true)
    const users = await UsersController.GetUsersAsync()
    commit('setUsers', users)
    commit('setLoadingUsers', false)
  },
  async selectUser({ commit }, userId: string) {
    const user = await UsersController.GetUserByIdAsync(userId)
    const permissions = await UsersController.GetRelativePermissionsAsync(userId)
    commit('setSelectedUser', user)
    commit('setRelativePermissions', permissions)
  },
  async updateUser({ state, dispatch, commit, getters }) {
    const user = state.selectedUser
    if (!user) {
      throw new Error('Unable to save user as selectedUser is null.')
    }

    let passwordReset = false;
    const isNew: boolean = getters['creatingUser']
    if (isNew) {
      await UsersController.CreateUserAsync(user)
    } else {
      passwordReset = (await UsersController.UpdateUserAsync(user)).passwordReset
    }

    dispatch('retrieveUsers')
    commit('setSelectedUser', null)

    const result = new UpdateUserResponseModel()
    result.passwordReset = isNew || passwordReset
    return result
  },
  createUser({ commit, state }) {
    commit('setSelectedUser', new UserDetailModel())
    commit('setRelativePermissions', {
      canChangeAccount: true,
      canChangeJobTypes: true,
      canChangeDashboards: true,
      canChangeSkills: true,
      canChangeTeam: true,
      allowedRoles: state.roles.map((r) => r.id),
    })
  },
  clearSelectedUser({ commit }) {
    commit('setSelectedUser', null)
    commit('setRelativePermissions', new RelativeUserPermissionsModel())
  },
  async deleteUser({ dispatch }, userId: string) {
    await UsersController.DeleteUserAsync(userId)
    dispatch('retrieveUsers')
  },
}

export const usersModule: Module<IUsersState, any> = {
  state: usersState,
  actions,
  mutations,
  getters: usersGetters,
  namespaced: true,
}
