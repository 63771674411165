<template>
  <div>
    <div class="mb-2"><h4>Customer Payment Detail</h4></div>
    <div class="mb-1">
      <label>Transaction Completed: </label>
      <span>{{ item.isTransactionCompleted ? 'Yes' : 'No' }}</span>
    </div>
    <div class="mb-1">
      <label>Transaction Date: </label>
      <span>{{ transactionDate }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateCustomerPaymentDetail from '@/models/requests/UpdateCustomerPaymentDetail'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'

@Component
export default class UpdateCustomerPaymentDetailLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateCustomerPaymentDetail {
    return Object.assign(new UpdateCustomerPaymentDetail(), this.requestBody)
  }

  private get transactionDate(): string {
    if (this.item && this.item.transactionDate) {
      return Shared.getFormatedDate(moment(this.item.transactionDate), Store.Instance.state.Environment.DateFormat)
    }
    return '--/--/----'
  }
}
</script>

<style scoped></style>
