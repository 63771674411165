<template>
  <div>
    <v-layout row wrap>
      <v-flex>
        <v-card class="elevation-1 pa-0 card-content">
          <v-layout>
            <v-flex>
              <v-layout wrap>
                <v-flex xs12 pl-3 pr-4>
                  <v-text-field
                    v-model="searchInput"
                    label="Search by postcode"
                    prepend-icon="search"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="!filteredPostcodes.length" pl-3 mt-2>
                  <span>Record not found.</span>
                </v-flex>
                <v-flex class="checkbox-list">
                  <v-flex v-bar xs12 px-3 mt-2>
                    <div class="postcode-list pb-3 pr-0">
                      <div v-if="allAvailablePostcodes.length > 0">
                        <v-layout v-for="postcode in filteredPostcodes" :key="postcode.areaCode">
                          <v-flex>
                            <v-expansion-panel class="elevation-0">
                              <div class="checkbox-list__expansion-list">
                                <v-expansion-panel-content :lazy="true">
                                  <div slot="header">
                                    <div>
                                      <span>{{ postcode.areaCode + ' - ' + postcode.description }}</span>
                                    </div>
                                  </div>
                                  <v-card v-for="outcode in postcode.outcodes" :key="outcode" class="elevation-0">
                                    <v-card-text class="checkbox-list__expansion-items d-flex">
                                      <span class="checkbox-list__outcode">{{ outcode }}</span>
                                      <v-select
                                        :value="regions.find((region) => region.outcodes.includes(outcode))"
                                        item-text="name"
                                        class="checkbox-list__select"
                                        :items="regions"
                                        :loading="isLoading"
                                        return-object
                                        @change="changeRegion($event, outcode)"
                                      ></v-select>
                                    </v-card-text>
                                  </v-card>
                                </v-expansion-panel-content>
                              </div>
                            </v-expansion-panel>
                          </v-flex>
                        </v-layout>
                      </div>
                    </div>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ContractorController from '@/api/contractorController'
import { PostcodeRegion } from '@/models/contractor/RegionsConfiguration'
import { Postcode } from '@/models/contractor/Postcode'

@Component
export default class ContractorArea extends Vue {
  public isLoading = false
  public regions: PostcodeRegion[] = []
  public searchInput = ''

  public get allAvailablePostcodes(): Postcode[] {
    return this.$store.getters['outcodesByArea']
  }

  public get filteredPostcodes(): Postcode[] {
    return this.allAvailablePostcodes.filter((postcode) =>
      postcode.outcodes.some((outcode) => outcode.includes(this.searchInput.toUpperCase()))
    )
  }

  public async changeRegion(value: PostcodeRegion, outcode: string) {
    this.isLoading = true
    const res = await ContractorController.UpdateRegionConfiguration(value.id, outcode)
    this.regions = res
    this.isLoading = false
  }

  async created(): Promise<void> {
    this.isLoading = true
    this.$store.commit('loadOutcodesByArea')
    const res = await ContractorController.GetRegionConfiguration()
    this.regions = res

    this.regions.forEach((region: PostcodeRegion) => {
      region.postcodes = new Set<string>()
      region.outcodes.forEach((outcode: string) => {
        const mainPostcode = this.allAvailablePostcodes.find((e) => e.outcodes.includes(outcode))
        if (!mainPostcode) {
          throw new Error(`Unable to find postcode containing ${outcode}`)
        }
        if (!region.postcodes.has(mainPostcode.areaCode)) {
          region.postcodes.add(mainPostcode.areaCode)
        }
      })
    })

    this.isLoading = false
  }
}
</script>

<style scoped lang="scss">
.checkbox-list {
  width: 50%;

  &__expansion-list {
    width: 100%;
  }

  &__expansion-items {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__outcode {
    display: flex;
    align-items: center;
  }

  &__select {
    width: 60%;
  }
}

.region {
  width: 50%;
}

.v-expansion-panel::v-deep .v-expansion-panel__header {
  padding: 0 !important;
}

.postcode-list {
  min-height: 80vh;
  max-height: 80vh;
}
.card-content {
  width: 30%;
}
</style>
