<template>
  <div v-if="item">
    <div class="mb-2"><h4>Update CCTV Control</h4></div>
    <div v-if="engineerName" class="mb-1">
      <label>Engineer: </label>
      <span>{{ engineerName }}</span>
    </div>
    <div v-if="item.notes" class="mb-1">
      <label>Notes: </label>
      <span>{{ item.notes }}</span>
    </div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ item.status }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import CCTVControlLogModel from '@/models/siteInvestigation/CCTVControlLog'

@Component
export default class UpdateCCTVControlLog extends Vue {
  @Prop() private requestBody: any

  private get item(): CCTVControlLogModel {
    return Object.assign(new CCTVControlLogModel(), this.requestBody)
  }

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.requestBody.id)
  }
}
</script>
