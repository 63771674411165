<template>
  <span>
    <span class="status-icon">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon dark :color="getColorByCoveredStatus" v-on="on">
            {{ getCoveredIcon }}
          </v-icon>
        </template>
        <span>{{ getIconTooltip }}</span>
      </v-tooltip>
    </span>
    <span v-if="getAcceptedEmergency || getRejectedEmergency || getSoftFixedEmergency" class="chip-btn">
      <v-chip :color="getEmergencyStatusChipColor" text-color="white" small>
        {{ getEmergencyStatus }}
      </v-chip>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQA from '@/models/EmergencyQA'
import storeGetters from '@/storeGetters'

@Component
export default class EmergencyQACardChip extends TimeLineItemBase {
  @Watch('getCoveredClass')
  public emitCardClassChanged() {
    this.$emit('cardClassChanged', this.getCoveredClass)
  }

  private created() {
    this.emitCardClassChanged()
  }

  private get items(): EmergencyQA[] {
    return storeGetters.getQAsForEmergency(this.jobId, this.emergencyId)
  }

  private get hasInvalidItems(): boolean {
    return this.items.filter((e) => e.validationStatus === 'invalid').length > 0
  }

  private get hasMissingItems(): boolean {
    return this.items.filter((e) => e.validationStatus === 'missing').length > 0
  }

  private get getAcceptedEmergency(): boolean {
    const emergency = this.job
      ? this.job.emergencies.find((e) => e.id === this.emergencyId && e.isEmergencyAccepted)
      : undefined
    if (emergency) {
      return true
    }
    return false
  }

  private get getRejectedEmergency(): boolean {
    const emergency = this.job
      ? this.job.emergencies.find(
          (e) => e.id === this.emergencyId && e.repudiationDescription !== null && e.repudiationDescription !== ''
        )
      : undefined
    if (emergency) {
      return true
    }
    return false
  }

  private get getSoftFixedEmergency(): boolean {
    const emergency = this.job
      ? this.job.emergencies.find((e) => e.id === this.emergencyId && e.isEmergencySoftFixed)
      : undefined
    if (emergency) {
      return true
    }
    return false
  }

  private get getEmergencyStatus(): string {
    if (this.getAcceptedEmergency) {
      return 'Accepted'
    } else if (this.getSoftFixedEmergency) {
      return 'Soft Fixed'
    } else if (this.getRejectedEmergency) {
      return 'Rejected'
    }
    return ''
  }

  private get getEmergencyStatusChipColor(): string {
    if (this.getAcceptedEmergency || this.getSoftFixedEmergency) {
      return 'green'
    } else if (this.getRejectedEmergency) {
      return 'red'
    }
    return ''
  }

  private get getColorByCoveredStatus(): string {
    if (this.hasInvalidItems) {
      return 'error'
    } else if (this.hasMissingItems) {
      return 'orange'
    } else {
      return 'success'
    }
  }

  private get getCoveredIcon(): string {
    if (this.hasInvalidItems) {
      return 'warning'
    } else if (this.hasMissingItems) {
      return 'info'
    } else {
      return 'check_circle'
    }
  }

  private get getIconTooltip(): string {
    if (this.hasInvalidItems) {
      return 'Emergency not covered'
    } else if (this.hasMissingItems) {
      return 'Answer not submited'
    } else {
      return 'Answer done'
    }
  }

  private get getCoveredClass(): string {
    if (this.hasInvalidItems) {
      return 'not-covered'
    } else if (this.hasMissingItems) {
      return 'pending-question'
    } else {
      return 'covered'
    }
  }
}
</script>

<style scoped>
.additional-chips .status-icon + .chip-btn {
  padding-right: 25px;
}
</style>
