<template>
  <div @click.stop="ShowCardPreview()">
    <p class="mb-1">
      <span>Your next action is to contact contractors</span>
    </p>
    <div class="question-title-desc">
      <v-btn color="green white--text" large :ripple="true" class="ml-0">
        <v-icon small>call</v-icon>
        &nbsp;&nbsp; Find Contractor
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import eventBus from '@/common/bus'
import Store from '@/store'

@Component
export default class CallContractorCard extends TimeLineItemBase {
  private ShowCardPreview() {
    this.$emit('cardClicked', this.cardIndex)
    eventBus.$emit(
      'updateWorkerActivity',
      Store.Instance.state.Environment.TwilioDeploymentActivitySid,
      'Deployment',
      'Opened Call Contractor Card'
    )
  }
}
</script>

<style scoped>
.chip-btn .v-chip {
  font-size: 12px;
  font-weight: bold;
}
</style>
