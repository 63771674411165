import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import PolicyAnswerModel from '@/models/policy/PolicyAnswerModel'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'

export default class PolicyQuestionModel extends CosmosJobsDbRecordBase {
  public policyQuestionId: number
  public policyId: number
  public questionId: number
  public overWrittenText: string
  public requiredForValidation: boolean
  public operatorId: number | null
  public comparisonValue: string | null
  public mandatory: boolean
  public orderBy: number
  public questionType: number
  public keyQuestion: boolean
  public question: EmergencyQuestionModel
  public entityType: string
  public entityName: string
  public answerTypeId: number
  public additionalValues: string | null
  public unit: string | null
  public policyAnswer: PolicyAnswerModel
  public isValid: boolean
  public forCommentOperatorId: number | null
  public forCommentComparisonValue: string
  public commentLabel: string
  public hasComment: boolean
  public repudiationId: number | null

  public constructor() {
    super()
    this.isValid = true
  }
}
