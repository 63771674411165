<template>
  <v-card class="mt-2">
    <v-tabs v-model="activeTab" background-color="primary" color="primary">
      <v-tab href="#tab-1"><v-icon color="secondary">cloud</v-icon></v-tab>
      <v-tab href="#tab-2"><v-icon color="secondary">notes</v-icon></v-tab>
      <v-tabs-items>
        <v-tab-item value="tab-1">
          <v-card flat>
            <WeatherWidget :job-id="jobId"></WeatherWidget>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <AddJobNote ref="addNewJobNote" v-model="addNewJobNote"></AddJobNote>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-3">
              <v-btn class="btn-clear" @click="openAddNote">Clear</v-btn>
              <v-btn
                color="primary"
                class="mr-0 btn-submit"
                :disabled="isLoading"
                :loading="isLoading"
                @click="onSaveJobNote"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import WeatherWidget from '@/components/WeatherWidget.vue'
import AddJobNote from '@/components/AddJobNote.vue'
import AddNewJobNote from '@/models/requests/AddNewJobNote'
import JobController from '@/api/jobController'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'

@Component({
  name: 'WeatherWidgetAndJobNote',
  components: { WeatherWidget, AddJobNote },
})
export default class WeatherWidgetAndJobNote extends Vue {
  @Prop() private jobId: string
  private addNewJobNote: AddNewJobNote = new AddNewJobNote()
  private isLoading = false
  private activeTab = 'tab-1'
  private snackbarTimeout = 3000
  private snackbar = false
  private snackbarText = ''

  private mounted() {
    this.openAddNote()
    eventBus.$off('openAddNote').$on('openAddNote', () => {
      this.openAddNote()
      this.activeTab = 'tab-2'
    })
  }

  private openAddNote(): void {
    this.addNewJobNote = new AddNewJobNote()
    this.addNewJobNote.jobId = this.jobId ? this.jobId : '0'
    const addNewJobNoteSubs = this.$refs.addNewJobNote as AddJobNote
    if (addNewJobNoteSubs) {
      addNewJobNoteSubs.$validator.errors.items = []
    }
  }

  private async validateJobNote(): Promise<boolean> {
    const subs = this.$refs.addNewJobNote as AddJobNote
    const result: boolean = await subs.$validator.validateAll()
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private onSaveJobNote(): void {
    const self = this
    this.validateJobNote()
      .then((result: boolean) => {
        if (result) {
          self.isLoading = true
          if (self.addNewJobNote) {
            if (self.addNewJobNote.noteType === 'OutgoingCall' || self.addNewJobNote.noteType === 'IncomingCall') {
              self.addNewJobNote.callSid = storeGetters.getCurrentCallSid()
            } else {
              self.addNewJobNote.callSid = ''
            }
            JobController.AddJobNote(self.addNewJobNote)
              .then((res: boolean) => {
                if (res) {
                  self.isLoading = false
                  this.openAddNote()
                  this.snackbarText = 'Job note added successfully.'
                  this.snackbar = true
                  this.activeTab = 'tab-1'
                }
              })
              .catch((err: any) => {
                self.isLoading = false
              })
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }
}
</script>

<style scoped>
.weather >>> .v-carousel__controls {
  background-color: #fafafa;
}
</style>
