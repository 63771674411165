export default class CallParameters {
  public to: string // phone number or contact_uri
  public jobId: string // if calling about a specific job

  // if calling a contractor about a specific visit
  public contractorId: string
  public emergencyId: string

  public constructor() {
    this.to = ''
    this.jobId = ''
    this.contractorId = ''
    this.emergencyId = ''
  }
}
