<template>
  <span v-if="complaint.loggedByUserName">
    Logged by
    <b class="secondary--text">{{ complaint.loggedByUserName }}</b>
  </span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import ComplaintModel from '@/models/claim/ComplaintModel'
import storeGetters from '@/storeGetters'
@Component
export default class ComplaintCardFooter extends TimeLineItemBase {
  private get complaint(): ComplaintModel | null {
    if (this.jobId) {
      return storeGetters.getComplaintById(this.jobId, this.itemId)
    }
    return null
  }
}
</script>
