import { Complexity } from "@/common/enums"
import { ContactPreference } from "../types"

export default class JobDetail {
  public loggedVia: string = ''
  public loggedByUserId: string = ''
  public loggedByUserName: string = ''

  public clientTitle: string = ''
  public clientForename: string = ''
  public clientLastName: string = ''

  public secondaryClientTitle: string | null = null
  public secondaryClientForename: string | null = null
  public secondaryClientLastName: string | null = null

  public addressLine1: string = ''
  public addressLine2: string = ''
  public addressLine3: string = ''
  public addressLine4: string = ''
  public postCode: string = ''

  public latitude: number | null = null
  public longitude: number | null = null

  public mobilePhone: string = ''
  public email: string = ''

  public contactPreference: ContactPreference = null

  public policyNumber: string = ''
  public customerRef: string = ''
  public policyScheme: string = ''
  public insurer: string = ''
  public insurerId: number = 0
  public insuranceValue: number = 0
  public policyScheduleId: number = 0
  public policyScheduleImageUrl: string = ''

  public fnolCallRecordingUrl: string = ''

  public nomineeClientTitle: string | null = null
  public nomineeClientForename: string | null = null
  public nomineeClientLastName: string | null = null
  public relation: string | null = null
  public nomineeMobilePhone: string | null = null
  public nomineeEmail: string | null = null
  public nomineeContactPreference: ContactPreference = null
  public complexity: Complexity = Complexity.Normal

  public isStarredPolicy: boolean = false

  public dasPolicyHolderUNID: string = ''
}