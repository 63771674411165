import { ContactPreference } from '@/models/types'
export default class AddHelplineJobRequestModel {
  public clientTitle: string
  public clientForename: string
  public clientLastName: string
  public secondaryClientTitle: string | null
  public secondaryClientForename: string | null
  public secondaryClientLastName: string | null
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postCode: string

  public email: string
  public mobilePhone: string

  public contactPreference: ContactPreference
  public contactPreferenceId: number | null

  public nomineeClientTitle: string | null
  public nomineeClientForename: string | null
  public nomineeClientLastName: string | null
  public relation: string | null
  public nomineeMobilePhone: string | null
  public nomineeEmail: string | null
  public nomineeContactPreference: ContactPreference
  public nomineeContactPreferenceId: number | null

  public policyNumber: string
  public customerRef: string
  public policyScheme: string
  public insurer: string
  public insuranceValue: number
  public policyScheduleId: number

  public FNOLCallSid: string

  public isStarredPolicy: boolean

  public dasPolicyHolderUNID: string

  public isVIPCustomer: boolean
  public reasonForChoosingCallOption: string
  public nomineeReasonForChoosingCallOption: string
  public policyNumberNotPresentReason: string
}
