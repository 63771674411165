import { Moment } from 'moment'

export type TimeLineCardColour = 'red' | 'purple' | 'yellow' | 'green' | 'blue'

export default class TimeLineCardModel {
  public title: string
  public icon: string
  public colour: TimeLineCardColour
  public createdDate: Moment
  public forJobId: string
  public status?: string

  public forEmergencyId: string | undefined
  public forItemId: string | undefined

  public cardClass: string
  public cardBodyComponent: string
  public cardAdditionalChipsComponent: string | undefined
  public cardFooterComponent: string | undefined
  public detailComponent: string | undefined

  public constructor(init: Partial<TimeLineCardModel>) {
    this.cardClass = ''
    Object.assign(this, init)
  }
}
