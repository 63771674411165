<template>
  <div>
    <div class="mb-2"><h4>De-Escalate Job Request</h4></div>
    <div v-if="item.description" class="mb-1">
      <label>Description: </label>
      <span>{{ item.description }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddDeEscalateJobRequest from '@/models/requests/AddDeEscalateJobRequestModel'

@Component
export default class AddRequestForDeEscalateJobLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddDeEscalateJobRequest {
    return Object.assign(new AddDeEscalateJobRequest(), this.requestBody)
  }
}
</script>
