<template>
  <v-flex xs12>
    <v-card class="white elevation-0">
      <div v-if="getWaterMainTest && getWaterMainTest.waterTests.length > 0">
        <v-card-text v-for="waterTest in getWaterMainTest.waterTests" :key="waterTest.testNumber" class="v-card__text">
          <v-card class="elevation-0 mb-2 lighten-4 grey">
            <v-card-title class="justify-end py-2">
              <v-spacer></v-spacer>
              <div v-if="!isWaterTestAbandoned" class="action-btn py-2">
                <v-btn
                  flat
                  icon
                  color="primary"
                  class="ma-0 close-icon btn-editWaterTest"
                  @click="onEditWaterTest(waterTest)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                  flat
                  icon
                  color="secondary"
                  class="ma-0 close-icon btn-deleteWaterTest"
                  @click="deleteWaterTestRecord(waterTest.testNumber)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text class="layer-card">
              <v-layout wrap>
                <v-flex xs6 mb-1>
                  <label><b>Test Number:</b></label>
                  <span class="ml-1">{{ waterTest.testNumber }}</span>
                </v-flex>
                <v-flex xs6 mb-1>
                  <label><b>Start Time:</b></label>
                  <span class="ml-1">
                    {{ getFormattedDate(waterTest.startTime) }}
                  </span>
                </v-flex>
                <v-flex xs6 mb-1>
                  <label><b>Pressure At 0 Minutes:</b></label>
                  <span class="ml-1">
                    {{ waterTest.pressureAtZeroMinutes }}
                  </span>
                </v-flex>
                <v-flex xs6 mb-1>
                  <label><b>Pressure At 5 Minutes:</b></label>
                  <span class="ml-1">
                    {{ waterTest.pressureAtFiveMinutes }}
                  </span>
                </v-flex>
                <v-flex xs6 mb-1>
                  <label><b>Pressure At 10 Minutes:</b></label>
                  <span class="ml-1">{{ waterTest.pressureAtTenMinutes }}</span>
                </v-flex>
                <v-flex xs6 mb-1>
                  <label><b>Test Notes:</b></label>
                  <span class="ml-1">{{ waterTest.testNote }}</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-flex wrap pa-3>
          <h4 class="pb-1">Listening Stick Water Mains Test</h4>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-text-field
                v-model.number="getWaterMainTest.periodListeningTest"
                label="Period listening test carried out"
                type="number"
                name="periodListeningTest"
                v-on="on"
                @keypress="numberKeyValidation($event)"
              ></v-text-field>
            </template>
            <span>
              Period listening test carried out with all available water services within property switched off
            </span>
          </v-tooltip>
          <v-checkbox
            v-model="getWaterMainTest.isLeakDetected"
            label="Any indication of leak detected by way of sound with stick placed on stop cock"
            color="primary"
            class="isLeakDetected"
          ></v-checkbox>
          <v-checkbox
            v-model="getWaterMainTest.isNoiseHeardFromListeningStick"
            label="When taps are used, toilets flushed or any other water demand appliance used,is there a noticeable flushing noise heard from the listening stick"
            color="primary"
            class="isNoiseHeardFromListeningStick"
          ></v-checkbox>
        </v-flex>
      </div>
      <span v-else class="pa-3">No Data Found</span>
      <v-flex v-if="getWaterMainTest" xs12 pa-3>
        <div
          v-if="getWaterMainTest.status === siJobProductProgress[siJobProductProgress.Finished]"
          class="grey lighten-4 pa-2"
        >
          <b class="green--text">Water Test Completed</b>
        </div>
        <div v-if="isWaterTestAbandoned" class="pa-3 abandon-detail grey lighten-4">
          <div class="pb-3 red--text">
            <label><b>Water Test Abandoned</b></label>
          </div>
          <div class="pb-1">
            <label><b>Abandon Reason:</b></label>
            <span class="ml-1">{{ getWaterMainTest.abandonedReason }}</span>
          </div>
          <div v-if="getWaterMainTest.abandonedReasonNotes">
            <label><b>Abandon Note:</b></label>
            <span class="ml-1">
              {{ getWaterMainTest.abandonedReasonNotes }}
            </span>
          </div>
        </div>
        <div v-else class="text-xs-right">
          <v-btn
            v-if="getWaterMainTest.waterTests && getWaterMainTest.waterTests.length > 0"
            class="mb-3 mr-0 btn-saveWaterTest"
            color="primary"
            :loading="loading.saveWaterMainTest"
            @click="saveWaterMainTest(false)"
          >
            Save
          </v-btn>
          <v-btn class="mb-3 mr-0 btn-addWaterTest" color="primary" @click="openWaterTestDialog()">Add Test</v-btn>
          <v-btn
            v-if="
              getWaterMainTest.status !== siJobProductProgress[siJobProductProgress.Finished] &&
              getWaterMainTest.waterTests &&
              getWaterMainTest.waterTests.length > 0
            "
            class="mb-3 mr-0 btn-completeWaterTest"
            color="success"
            :loading="loading.completeWaterMainTest"
            @click="saveWaterMainTest(true)"
          >
            Complete
          </v-btn>
          <v-btn
            v-if="!isWaterTestAbandoned"
            class="mb-3 mr-0 white--text btn-abandonWaterMainsTest"
            color="red"
            @click.native="openAbandonReasonDialog()"
          >
            Abandon
          </v-btn>
        </div>
      </v-flex>
    </v-card>

    <!-- Water Test Dialog -->
    <v-dialog
      v-if="waterTestDialog"
      v-model="waterTestDialog"
      max-width="850"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>
            {{ isAddWaterTest ? 'Add' : 'Edit' }}
            Water Test
            {{ isAddWaterTest ? '' : ' - ' + [waterTestModel.testNumber] }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="discardWaterTestConfirmation">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog pa-3">
          <v-container fluid grid-list-lg pa-0>
            <v-layout wrap>
              <v-flex xs6 py-0>
                <v-text-field
                  v-model.number="waterTestModel.pressureAtZeroMinutes"
                  label="Pressure At 0 Minutes"
                  type="number"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 py-0>
                <v-text-field
                  v-model.number="waterTestModel.pressureAtFiveMinutes"
                  label="Pressure At 5 Minutes"
                  type="number"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 py-0>
                <v-text-field
                  v-model.number="waterTestModel.pressureAtTenMinutes"
                  label="Pressure At 10 Minutes"
                  type="number"
                  @keypress="numberKeyValidation($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 py-0>
                <DateTimePicker
                  ref="dateTimePicker"
                  :date-time.sync="getStartTime"
                  :is-static-location="false"
                  place-holder-text="Start Time"
                  :is-current-time="true"
                  :is-validation-required="true"
                  :show-present-time-icon="true"
                  data-vv-scope="waterTestScope"
                />
              </v-flex>
              <v-flex xs12 py-0>
                <v-textarea v-model="waterTestModel.testNote" label="Test Notes"></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-closeDialog" flat="flat" @click.native="discardWaterTestConfirmation">
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-waterTestSave"
            :loading="loading.waterMainsLogs"
            @click.native="onSaveWaterTestRecord()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AbandonSiteDataDialog
      v-if="showAbandonReasonDialog"
      ref="abandonSiteDataDialog"
      :record-type="abandonRecordType"
      :abandon-reason-details="abandonReasonDetails"
      @abandonReasonSave="onAbandonReasonSave"
      @closeAbandonReasonDialog="showAbandonReasonDialog = false"
    ></AbandonSiteDataDialog>

    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator'
import DateTimePicker from '@/components/DateTimePicker.vue'
import moment, { Moment } from 'moment'
import Vue from 'vue'
import WaterTestModel from '@/models/siteInvestigation/WaterTestModel'
import Shared from '@/common/shared'
import Store from '@/store'
import SiteInvestigationController from '@/api/siteInvestigationController'
import WaterMainsTest from '@/models/siteInvestigation/WaterMainsTest'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import { SIJobProductProgress, RecordType, SIProductType } from '@/common/enums'
import DeleteWaterMainsTestRecord from '@/models/siteInvestigation/requests/DeleteWaterMainsTestRecord'
import UpdateWaterMainsTest from '@/models/siteInvestigation/requests/UpdateWaterMainsTest'
import AbandonSiteDataDialog from '@/components/siteInvestigation/AbandonSiteDataDialog.vue'
import AddAbandonedSIProductRequest from '@/models/siteInvestigation/requests/AddAbandonedSIProductRequest'
import CompleteWaterMainsTestRecord from '@/models/siteInvestigation/requests/CompleteWaterMainsTestRecord'
import eventBus from '@/common/bus'

@Component({
  components: { DateTimePicker, AbandonSiteDataDialog },
})
export default class WaterTest extends Vue {
  @Prop() private jobId: string
  @Prop() private engineerVisitDetailId: string
  private waterTestModel: WaterTestModel = new WaterTestModel()
  private siJobProductProgress = SIJobProductProgress
  private waterTestDialog = false
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = ''
  private isAddWaterTest = false
  private showAbandonReasonDialog = false
  private abandonRecordType: string = RecordType[RecordType.AbandonWaterMainsTestReason]
  private abandonReasonDetails: AddAbandonedSIProductRequest = new AddAbandonedSIProductRequest()
  private loading: Record<string, boolean> = {
    waterMainsLogs: false,
    saveWaterMainTest: false,
    completeWaterMainTest: false,
  }

  private get getWaterMainTest(): WaterMainsTest | null {
    return storeGetters.getWaterMainsTestDetail(this.jobId, this.engineerVisitDetailId)
  }

  private get getStartTime(): Moment | null {
    return this.waterTestModel.startTime
  }

  private set getStartTime(newValue: Moment | null) {
    this.waterTestModel.startTime = newValue
  }

  private openWaterTestDialog() {
    this.waterTestModel = new WaterTestModel()
    this.waterTestDialog = true
    this.isAddWaterTest = true
  }

  private async validate(scope: string): Promise<boolean> {
    let result = true
    const validation: boolean = await this.$validator.validateAll(scope)
    let startTimeValidation = true
    const dateTimePickerSub = this.$refs.dateTimePicker as DateTimePicker
    startTimeValidation = await dateTimePickerSub.$validator.validateAll()
    // set focus to non validate field
    if (!validation || !startTimeValidation) {
      Shared.setValidationFocus(result ? (dateTimePickerSub.$el as HTMLElement) : (this.$el as HTMLElement))
      result = validation && startTimeValidation
    }
    return result
  }

  private async onSaveWaterTestRecord() {
    this.validate('waterTestScope')
      .then((result: boolean) => {
        if (result) {
          const self = this
          this.$set(this.loading, 'waterMainsLogs', true)
          if (!self.getWaterMainTest) {
            return
          }
          const updateWaterMainsTest: UpdateWaterMainsTest = Object.assign({}, new UpdateWaterMainsTest())
          updateWaterMainsTest.id = self.getWaterMainTest.id
          updateWaterMainsTest.jobId = self.getWaterMainTest.jobId
          updateWaterMainsTest.engineerVisitDetailId = self.getWaterMainTest.engineerVisitDetailId
          updateWaterMainsTest.waterTest = self.waterTestModel
          if (!self.waterTestModel.testNumber) {
            updateWaterMainsTest.waterTest.testNumber =
              1 +
              (self.getWaterMainTest.waterTests.length > 0
                ? Math.max.apply(
                    Math,
                    self.getWaterMainTest.waterTests.map((o: WaterTestModel) => o.testNumber)
                  )
                : 0)
          }
          updateWaterMainsTest.status =
            this.getWaterMainTest!.status === self.siJobProductProgress[SIJobProductProgress.NotStartedYet]
              ? self.siJobProductProgress[SIJobProductProgress.InProgress]
              : this.getWaterMainTest!.status
          SiteInvestigationController.UpdateWaterMainsTest(updateWaterMainsTest)
            .then((res: any) => {
              if (res) {
                this.showMessage('Water test detail saved successfully.')
              }
              this.$set(this.loading, 'waterMainsLogs', false)
              self.waterTestDialog = false
              self.isAddWaterTest = false
            })
            .catch((err) => {
              eventBus.$emit('errorHandler', 'Error updating water test detail, please try again', true)
              this.$set(this.loading, 'waterMainsLogs', false)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private onEditWaterTest(waterTest: WaterTestModel) {
    this.waterTestModel = Object.assign(new WaterMainsTest(), waterTest)
    this.getStartTime = this.waterTestModel.startTime ? moment(this.waterTestModel.startTime) : null
    this.waterTestDialog = true
  }

  private deleteWaterTestRecord(testNumber: number) {
    Shared.confirmationPopup.open(
      'Are you sure you want to delete water test record - ' + testNumber + '?',
      '',
      '',
      'No',
      'Yes',
      this,
      'deleteRecord',
      testNumber
    )
  }

  private discardWaterTestConfirmation() {
    Shared.confirmationPopup.open(
      'Are you sure you want to discard the changes?',
      '',
      '',
      'No',
      'Yes',
      this,
      'closeWaterTestDialog',
      true
    )
  }

  private closeWaterTestDialog() {
    this.waterTestDialog = false
    this.isAddWaterTest = false
  }

  private deleteRecord(testNumber: number) {
    const self = this
    this.$set(this.loading, 'waterMainsLogs', true)
    const deleteWaterMainsTestRecord: DeleteWaterMainsTestRecord = new DeleteWaterMainsTestRecord()
    if (self.getWaterMainTest) {
      deleteWaterMainsTestRecord.id = self.getWaterMainTest.id
      deleteWaterMainsTestRecord.jobId = self.getWaterMainTest.jobId
      deleteWaterMainsTestRecord.engineerVisitDetailId = self.getWaterMainTest.engineerVisitDetailId
    }
    deleteWaterMainsTestRecord.testNumber = testNumber
    SiteInvestigationController.DeleteWaterMainsTest(deleteWaterMainsTestRecord)
      .then((res: any) => {
        if (res) {
          this.showMessage('Water test detail deleted.')
        }
        this.$set(this.loading, 'waterMainsLogs', false)
        this.waterTestDialog = false
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error deleting water test detail, please try again', true)
        this.$set(this.loading, 'waterMainsLogs', false)
      })
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumeric(event)
  }

  private showMessage(message: string) {
    this.saveSnackbarText = message
    this.saveSnackbar = true
  }

  private openAbandonReasonDialog() {
    this.abandonReasonDetails.jobId = this.jobId
    this.abandonReasonDetails.id = this.getWaterMainTest!.id
    this.abandonReasonDetails.forEngineerVisitDetailId = this.getWaterMainTest!.engineerVisitDetailId
    this.abandonReasonDetails.siProductType = SIProductType[SIProductType.WaterMains]
    this.showAbandonReasonDialog = true
  }

  private async onAbandonReasonSave(result: boolean) {
    this.showAbandonReasonDialog = false
    if (result) {
      this.showMessage('Water test has been abandoned')
    } else {
      this.showMessage('Something went wrong')
    }
  }

  private get isWaterTestAbandoned(): boolean {
    return this.getWaterMainTest &&
      this.getWaterMainTest.status.toString() === SIJobProductProgress[SIJobProductProgress.Abandoned]
      ? true
      : false
  }

  private saveWaterMainTest(isCompleteWaterMainsTest: boolean) {
    this.$set(this.loading, isCompleteWaterMainsTest ? 'completeWaterMainTest' : 'saveWaterMainTest', true)
    const saveOrCompleteWaterMainsTest: CompleteWaterMainsTestRecord = new CompleteWaterMainsTestRecord()
    saveOrCompleteWaterMainsTest.id = this.getWaterMainTest ? this.getWaterMainTest.id : ''
    saveOrCompleteWaterMainsTest.jobId = this.jobId
    saveOrCompleteWaterMainsTest.status =
      this.getWaterMainTest!.status === SIJobProductProgress[SIJobProductProgress.Finished]
        ? SIJobProductProgress[SIJobProductProgress.Finished]
        : isCompleteWaterMainsTest
        ? SIJobProductProgress[SIJobProductProgress.Finished]
        : SIJobProductProgress[SIJobProductProgress.InProgress]
    saveOrCompleteWaterMainsTest.periodListeningTest = this.getWaterMainTest!.periodListeningTest
    saveOrCompleteWaterMainsTest.isLeakDetected = this.getWaterMainTest!.isLeakDetected
    saveOrCompleteWaterMainsTest.isNoiseHeardFromListeningStick = this.getWaterMainTest!.isNoiseHeardFromListeningStick

    SiteInvestigationController.SaveWaterMainsTestRecord(saveOrCompleteWaterMainsTest)
      .then((res: any) => {
        if (res) {
          this.showMessage('Water test detail saved successfully.')
        }
        this.$set(this.loading, isCompleteWaterMainsTest ? 'completeWaterMainTest' : 'saveWaterMainTest', false)
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error saving water test detail, please try again', true)
        this.$set(this.loading, isCompleteWaterMainsTest ? 'completeWaterMainTest' : 'saveWaterMainTest', false)
      })
  }
}
</script>

<style scoped>
.layer-card {
  padding-right: 80px;
}
.action-btn {
  position: absolute;
  right: 0;
  top: 0px;
}
.action-btn >>> .v-btn--icon .v-icon {
  font-size: 20px;
}
@media (max-width: 1380px) {
  .layer-card {
    padding-top: 20px !important;
  }
  .action-btn {
    top: -5px;
  }
  .action-btn >>> .v-btn--icon {
    width: 20px;
    height: 20px;
  }
}
</style>
