<template>
  <div>
    <div v-if="item.requestType === 'Material'" class="mb-2">
      <h4>Engineer Material Request</h4>
    </div>
    <div v-if="item.requestType === 'Time'" class="mb-2">
      <h4>Engineer Extra Time Request</h4>
    </div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="item.requestType === 'Material'">
      <div v-if="item.materialName" class="mb-1">
        <label>Material Name: </label>
        <span>{{ item.materialName }}</span>
      </div>
      <div v-if="item.materialType" class="mb-1">
        <label>Material Type: </label>
        <span>{{ item.materialType }}</span>
      </div>
      <div v-if="item.quantity" class="mb-1">
        <label>Quantity: </label>
        <span>{{ item.quantity }}</span>
      </div>
      <div v-if="item.materialCost" class="mb-1">
        <label>Material Cost: </label>
        <span>{{ item.materialCost }}</span>
      </div>
      <div v-if="item.isPartAvailable" class="mb-1">
        <label>Part Available: </label>
        <span>{{ item.isPartAvailable ? 'Yes' : 'No' }}</span>
      </div>
    </div>
    <div v-if="item.requestType === 'Time'">
      <div v-if="item.extraHours" class="mb-1">
        <label>Extra Hours: </label>
        <span>{{ item.extraHours }}</span>
      </div>
      <div v-if="item.timeRequestReason" class="mb-1">
        <label>Request Reason: </label>
        <span>{{ item.timeRequestReason }}</span>
      </div>
    </div>
    <div v-if="item.description" class="mb-1">
      <label>Description: </label>
      <span>{{ item.description }}</span>
    </div>
    <div v-if="item.requestStatus" class="mb-1">
      <label>Status: </label>
      <span>{{ item.requestStatus }}</span>
    </div>
    <div v-if="item.companyName" class="mb-1">
      <label>Contractor: </label>
      <span>{{ item.companyName }}</span>
    </div>
    <div v-if="item.address" class="mb-1">
      <label>Address: </label>
      <span>{{ item.address }}</span>
    </div>
    <div v-if="item.fileURL" class="mb-1">
      <label>URL: </label>
      <span>{{ item.fileURL }}</span>
    </div>
    <div v-if="getFormattedDate" class="mb-1">
      <label>Request process at: </label>
      <span>{{ getFormattedDate(item.requestProcessedAt) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddEngineerMaterialAndExtraTimeRequest from '@/models/requests/AddEngineerMaterialAndExtraTimeRequest'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class AddEngineerMaterialAndExtraTimeRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddEngineerMaterialAndExtraTimeRequest {
    return Object.assign(new AddEngineerMaterialAndExtraTimeRequest(), this.requestBody)
  }

  private get emergencyName(): string {
    return Shared.getEmergencyNameFromTypeId(this.item.forEmergencyTypeId)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }
}
</script>
