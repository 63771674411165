import Changes from '../Changes'

export default class AddBoreHoleLog {
  public jobId: string
  public pitId: string
  public boreHoleRunNumber: number
  public boringMethod: string
  public diameter: number
  public weather: string
  public remarks: string
  public groundLevel: string
  public changes: Changes[]
}
