import DatumControlRunLog from '@/models/siteInvestigation/DatumControlRunLog'
import { SIJobProductProgress } from '@/common/enums'
import CosmosJobsDbRecordBase from '../CosmosJobsDbRecordBase'

export default class DatumControlLog extends CosmosJobsDbRecordBase {
  public datumId: number
  public engineerVisitDetailId: string
  public status: SIJobProductProgress | string
  public name: string
  public datumControlRunLogs: DatumControlRunLog[]
  public abandonedReason: string
  public abandonedReasonNotes: string
}
