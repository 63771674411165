import { Environment, ConnectionStatus } from '@/common/environment'

export default class Loader {
  public static oidcSettings: any
  public static SetAppLoadingMessage(message: string, hideSpinner = false) {
    const textElem = document.getElementById('appLoadingMsg')
    if (textElem !== null) {
      textElem.innerText = message
    }

    const spinnerElem = document.getElementById('appLoadingSpinner')
    if (spinnerElem !== null) {
      spinnerElem.style.display = hideSpinner ? 'none' : 'block'
    }
  }

  public static OidcSettings(env: Environment): any {
    this.oidcSettings = {
      authority: env.IdentityServerBaseUrl,
      client_id: env.IdentityClientId,
      redirect_uri: `${window.location.protocol}//${window.location.host}/identity/callback`,
      response_type: env.IdentityResponseType,
      scope: env.IdentityScope,
      loadUserInfo: false,
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
      silent_redirect_uri: `${window.location.protocol}//${window.location.host}/identity/silent-renew`,
      automaticSilentRenew: true,
      silentRequestTimeout: 20000,
      accessTokenExpiringNotificationTime: 120,
      revokeAccessTokenOnSignout: true,
    }
    return this.oidcSettings
  }

  public static InitializeEnvironment() {
    Loader.SetAppLoadingMessage('Loading Environment Settings ...', false)
    const env = this.getEnvironmentFromDotEnv()
    env.ConnectionStatus = ConnectionStatus.Online
    return env
  }

  private static getEnvironmentFromDotEnv(): Environment {
    const env: Environment = {
      AudioAttachmentSize: parseInt(process.env.VUE_APP_AudioAttachmentSize || '0'),
      CallCentreApiBaseUrl: process.env.VUE_APP_CallCentreApiBaseUrl || '',
      WebhooksApiBaseUrl: process.env.VUE_APP_WebhooksApiBaseUrl || '',
      CETUserMinimumInvoicingSkill: parseInt(process.env.VUE_APP_CETUserMinimumInvoicingSkill || '0'),
      ClientAppUrl: process.env.VUE_APP_ClientAppUrl || '',
      ComplementaryColor: process.env.VUE_APP_ComplementaryColor || '',
      ConnectionStatus: parseInt(process.env.VUE_APP_ConnectionStatus || '0'),
      CustomerRequestedLogDelayId: process.env.VUE_APP_CustomerRequestedLogDelayId || '',
      DateFormat: process.env.VUE_APP_DateFormat || '',
      DateTimeFormat: process.env.VUE_APP_DateTimeFormat || '',
      DocumentAttachmentSize: parseInt(process.env.VUE_APP_DocumentAttachmentSize || '0'),
      EmergencyNotCoveredRepudiationId: parseInt(process.env.VUE_APP_EmergencyNotCoveredRepudiationId || '0'),
      Environment: process.env.VUE_APP_Environment || '',
      FroalaEditorKey: process.env.VUE_APP_FroalaEditorKey || '',
      GoogleCaptchaVerificationSiteKey: process.env.VUE_APP_GoogleCaptchaVerificationSiteKey || '',
      GoogleMapsJavascriptAPIKey: process.env.VUE_APP_GoogleMapsJavascriptAPIKey || '',
      HideAutoDeploymentAfterXMinutes: parseInt(process.env.VUE_APP_HideAutoDeploymentAfterXMinutes || '0'),
      IdentityClientId: process.env.VUE_APP_IdentityClientId || '',
      IdentityResponseType: process.env.VUE_APP_IdentityResponseType || '',
      IdentityScope: process.env.VUE_APP_IdentityScope || '',
      IdentityServerBaseUrl: process.env.VUE_APP_IdentityServerBaseUrl || '',
      LinkPhoneCallTimeout: parseInt(process.env.VUE_APP_LinkPhoneCallTimeout || '0'),
      LocateFindApi: process.env.VUE_APP_LocateFindApi || '',
      LocateRetrieveApi: process.env.VUE_APP_LocateRetrieveApi || '',
      LogoSize: parseInt(process.env.VUE_APP_LogoSize || '0'),
      PHPortalsBaseDomain: process.env.VUE_APP_PHPortalsBaseDomain || '',
      PrimaryColor: process.env.VUE_APP_PrimaryColor || '',
      RecordingStatusCallbackUrl: process.env.VUE_APP_RecordingStatusCallbackUrl || '',
      SecondaryColor: process.env.VUE_APP_SecondaryColor || '',
      StripePublishKey: process.env.VUE_APP_StripePublishKey || '',
      TimelineCardMinuteLimit: parseInt(process.env.VUE_APP_TimelineCardMinuteLimit || '0'),
      TimelineCardHourLimit: parseInt(process.env.VUE_APP_TimelineCardHourLimit || '0'),
      TimelineCardDayLimit: parseInt(process.env.VUE_APP_TimelineCardDayLimit || '0'),
      TOBAFileUrl: process.env.VUE_APP_TOBAFileUrl || '',
      TrialPitDrawingWebsite: process.env.VUE_APP_TrialPitDrawingWebsite || '',
      TwilioBusyActivitySid: process.env.VUE_APP_TwilioBusyActivitySid || '',
      TwilioDeploymentActivitySid: process.env.VUE_APP_TwilioDeploymentActivitySid || '',
      TwilioIdleActivitySid: process.env.VUE_APP_TwilioIdleActivitySid || '',
      TwilioOfflineActivitySid: process.env.VUE_APP_TwilioOfflineActivitySid || '',
      TwilioWrapUpActivitySid: process.env.VUE_APP_TwilioWrapUpActivitySid || '',
    }

    if (
      env === null ||
      (env.CallCentreApiBaseUrl || '').trim() === '' ||
      (env.IdentityServerBaseUrl || '').trim() === '' ||
      (env.DateFormat || '').trim() === '' ||
      !env.TimelineCardMinuteLimit ||
      !env.TimelineCardHourLimit ||
      !env.TimelineCardDayLimit ||
      !env.LogoSize ||
      env.LogoSize === 0 ||
      !env.AudioAttachmentSize ||
      env.AudioAttachmentSize === 0 ||
      !env.DocumentAttachmentSize ||
      env.DocumentAttachmentSize === 0 ||
      (env.PrimaryColor || '').trim() === '' ||
      (env.SecondaryColor || '').trim() === '' ||
      (env.ComplementaryColor || '').trim() === '' ||
      !env.CETUserMinimumInvoicingSkill ||
      env.CETUserMinimumInvoicingSkill === 0
    ) {
      Loader.SetAppLoadingMessage('Invalid Environment Settings', true)
      throw new Error('Invalid Environment Settings')
    }
    return env
  }
}
