<template>
  <div>
    <v-card v-if="localQA" class="mb-3 vulnerable-card-preview" :elevation="embedded ? '0' : undefined">
      <v-card-title class="pb-0">
        <v-layout>
          <v-flex xs11>
            <h3>{{ localQA.questionText }}</h3>
          </v-flex>
          <v-flex xs1 text-xs-right>
            <v-icon v-if="syncing" class="processing-icon" medium>sync</v-icon>
            <v-icon v-else-if="localQA.isHealthAffected !== null" medium dark color="success">check_circle</v-icon>
            <v-icon v-else-if="mandatory" medium dark color="orange">info</v-icon>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-radio-group v-model="localQA.isHealthAffected" @focus="editStarted" @change="editComplete()">
          <v-radio
            label="Yes"
            name="healthAffected"
            color="primary"
            :value="true"
            :disabled="getAcceptRejectQuestionCriteria"
          />
          <v-radio
            label="No"
            name="healthAffected"
            color="primary"
            :value="false"
            :disabled="getAcceptRejectQuestionCriteria"
          />
        </v-radio-group>
        <v-select
          v-if="localQA.level"
          v-model="localQA.level"
          :items="getLevel"
          label="Level"
          item-text="level"
          item-value="id"
          :disabled="getAcceptRejectQuestionCriteria"
          @focus="editStarted"
          @input="editComplete()"
        ></v-select>
        <v-textarea
          v-if="localQA.isHealthAffected"
          v-model="localQA.answerDetail"
          label="Details"
          name="healthAffectedDetails"
          :disabled="getAcceptRejectQuestionCriteria"
          @focus="editStarted"
          @blur="editComplete()"
        ></v-textarea>
        <div v-if="mandatory && localQA.isHealthAffected === null" class="answer-missing">
          an answer to this question is mandatory
        </div>
      </v-card-text>
      <v-progress-linear
        v-show="saving"
        color="primary"
        height="3"
        value="15"
        :indeterminate="true"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQA from '@/models/EmergencyQA'
import storeGetters from '@/storeGetters'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import { HealthAndSafetyLevel } from '@/common/enums'
import JobController from '@/api/jobController'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import AddJobAnswerModel from '@/models/requests/AddJobAnswerModel'
import eventBus from '@/common/bus'

interface IHealthAndSafetyLevel {
  id: string
  level: string
}

@Component({
  components: {},
})
export default class HealthAndSafetyQA extends Vue {
  @Prop() private healthAndSafetyQA: HealthAndSafetyQAModel | VulnerabilityQAModel
  @Prop() private getAcceptRejectQuestionCriteria: boolean
  @Prop() private embedded: boolean
  @Prop() public mandatory: boolean
  private localQA: HealthAndSafetyQAModel | VulnerabilityQAModel | null = null
  private inEdit = false
  private saving = false
  private syncing = false

  private created() {
    this.setLocalAnswer()
  }

  private get item(): HealthAndSafetyQAModel | VulnerabilityQAModel {
    return this.healthAndSafetyQA
  }

  // bind dropdown for level from enum
  private get getLevel(): IHealthAndSafetyLevel[] {
    let keys: any[]
    const levelItems: IHealthAndSafetyLevel[] = []

    if (this.item.type === 'HealthAndSafetyQA') {
      keys = Object.keys(HealthAndSafetyLevel).filter(Number)
      keys.forEach((element) => {
        const entity: any = {}
        entity.id = HealthAndSafetyLevel[element]
        entity.level = HealthAndSafetyLevel[element]
        if (entity.level === 'Low') {
          entity.level = 'Low H&S Risk - Potential Risk of Injury'
        } else if (entity.level === 'Medium') {
          entity.level = 'Medium H&S Risk - Imminent Risk of Serious Injury'
        } else if (entity.level === 'High') {
          entity.level = 'High H&S Risk - Immediate Danger to Life'
        }
        levelItems.push(entity)
      })
    } else if (this.item.type === 'VulnerabilityQA') {
      keys = Object.keys(HealthAndSafetyLevel).filter(Number)
      keys.forEach((element) => {
        const entity: any = {}
        entity.id = HealthAndSafetyLevel[element]
        entity.level = HealthAndSafetyLevel[element]
        levelItems.push(entity)
      })
    }

    return levelItems
  }

  @Watch('syncing')
  private syncingChanged(): void {
    this.$emit('setSyncing', this.syncing)
  }

  @Watch('item')
  private itemChanged(): void {
    if (!this.inEdit) {
      // user not editing (got new value so stop syncing)
      this.syncing = false
      if (this.item !== this.localQA) {
        // stored answer has been updated, update local version
        this.setLocalAnswer()
      }
    }
  }

  // set local answer as item of HealthAndSafetyQA or VulnerabilityQA
  private setLocalAnswer(): void {
    if (this.item) {
      this.localQA = { ...this.item }
    }
  }

  // on answer edit started
  private editStarted(): void {
    this.inEdit = true
    this.syncing = false
  }

  // on answer edit complete
  private editComplete(): void {
    if (!this.localQA) {
      return
    }
    this.inEdit = false

    if (this.localQA.isHealthAffected) {
      this.localQA.level =
        this.localQA.level && Object.values(HealthAndSafetyLevel).includes(this.localQA.level)
          ? this.localQA.level
          : HealthAndSafetyLevel[HealthAndSafetyLevel.Low]
    }

    if (!this.localQA.isHealthAffected) {
      this.localQA.level = null
      this.localQA.answerDetail = null
    }

    this.saving = true
    // set local answer to request answer model
    const req = new AddJobAnswerModel()
    req.id = this.localQA.id
    req.jobId = this.localQA.jobId
    req.isHealthAffected = this.localQA.isHealthAffected
    req.level = this.localQA.level
    req.answerDetail = this.localQA.answerDetail

    if (this.localQA.type === 'HealthAndSafetyQA') {
      this.saveHealthAndSafetyQA(req)
    } else if (this.localQA.type === 'VulnerabilityQA') {
      this.saveVulnerabilityQA(req)
    }
  }

  // save HealthAndSafety Question Answer
  private saveHealthAndSafetyQA(req: AddJobAnswerModel) {
    const self = this
    self.syncing = true
    JobController.SaveHealthAndSafetyQA(req)
      .then((res: string) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to save answer')
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        self.saving = false
      })
      .catch((err: any) => {
        self.saving = false
        eventBus.$emit('errorHandler', err, false)
      })
  }

  // save Vulnerability Question Answer
  private saveVulnerabilityQA(req: AddJobAnswerModel) {
    const self = this
    self.syncing = true
    JobController.SaveVulnerabilityQA(req)
      .then((res: string) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to save answer')
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        self.saving = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', err, false)
        self.saving = false
      })
  }
}
</script>

<style scoped>
.answer-missing {
  color: #ffcc80;
}
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.vulnerable-card-preview >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
