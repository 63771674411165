import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import ContractorRatePackageModel from '@/models/contractor/ContractorRatePackageModel'
import ClientRatePackageModel from '@/models/client/ClientRatePackageModel'
import ClientRatePackageUSModel from '@/models/client/ClientRatePackageUSModel'

export default class RateController {
  public static async SaveRatePackage(contractorRatePackage: ContractorRatePackageModel): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('Rate/SaveContractorRatePackage', contractorRatePackage)

    if (res.status === 200) {
      const result = res.data as string
      if (!result) {
        return ''
      }
      return result
    } else {
      return ''
    }
  }

  public static async GetAllContractorRatePackages(): Promise<ContractorRatePackageModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<ContractorRatePackageModel[]>(
      'Rate/GetAllContractorRatePackages'
    )
    return res.data ? res.data : []
  }

  public static async GetAllClientRatePackages(): Promise<ClientRatePackageModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<ClientRatePackageModel[]>('Rate/GetAllClientRatePackages')
    return res.data ? res.data : []
  }

  public static async SaveClientRatePackage(
    clientRatePackage: ClientRatePackageModel
  ): Promise<ClientRatePackageModel | null> {
    const res: AxiosResponse = await CallCentreApi.post('Rate/SaveClientRatePackage', clientRatePackage)
    return res.data ? res.data : null
  }

  public static async GetAllUSClientRatePackages(): Promise<ClientRatePackageUSModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<ClientRatePackageUSModel[]>('Rate/GetAllUSClientRatePackages')
    return res.data ? res.data : []
  }

  public static async SaveClientRatePackageUS(
    clientRatePackage: ClientRatePackageUSModel
  ): Promise<ClientRatePackageUSModel | null> {
    const res: AxiosResponse = await CallCentreApi.post('Rate/SaveUSClientRatePackage', clientRatePackage)
    return res.data ? res.data : null
  }

  public static async GetContractorRatePackageAsync(ratePackageId: string): Promise<ContractorRatePackageModel | null> {
    const res: AxiosResponse = await CallCentreApi.get<ContractorRatePackageModel | null>(
      'Rate/GetContractorRatePackageAsync?ratePackageId=' + ratePackageId
    )
    return res.data ? res.data : null
  }

  public static async GetClientRatePackageAsync(ratePackageId: string): Promise<ClientRatePackageModel | null> {
    const res: AxiosResponse = await CallCentreApi.get<ClientRatePackageModel | null>(
      'Rate/GetClientRatePackageAsync?ratePackageId=' + ratePackageId
    )
    return res.data ? res.data : null
  }

  public static async GetUSClientRatePackageAsync(documentId: string): Promise<ClientRatePackageUSModel> {
    const res: AxiosResponse = await CallCentreApi.get<ClientRatePackageUSModel>(
      'Rate/GetUSClientRatePackageAsync?documentId=' + encodeURIComponent(documentId)
    )
    return res.data ? res.data : []
  }
}
