import JobPermissions from '@/models/job/job-permissions'
import MasterRecord from '@/models/MasterRecord'
import { IJobState } from '@/vuex/states/interfaces/job-state'

export default class JobState implements IJobState {
  public masterRecords: MasterRecord[] = []
  public showClearEta = false
  public selectedJobId: string | null = null
  public jobPermissions: JobPermissions = new JobPermissions()
}
