<template>
  <div>
    <div class="mb-2"><h4>Job Visit Accepted or Rejected by Engineer</h4></div>
    <div v-if="item.processByUserName" class="mb-1">
      <label>Engineer: </label>
      <span>{{ item.processByUserName }}</span>
    </div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ item.status }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateVisitDetailForAcceptRejectJob from '@/models/requests/UpdateVisitDetailForAcceptRejectJob'

@Component
export default class UpdateVisitDetailForAcceptRejectJobLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateVisitDetailForAcceptRejectJob {
    return Object.assign(new UpdateVisitDetailForAcceptRejectJob(), this.requestBody)
  }
}
</script>
