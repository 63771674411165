import Confirmation from '@/components/Confirmation.vue'
import { Month, Operator, TimeSpanSlots } from '@/common/interfaces'
import moment from 'moment'
import JobCount from '@/models/claim/JobCount'
import TrackerCountModel from '@/models/claim/TrackerCountModel'
import store from '@/store'
declare let window: any
import eventBus from '@/common/bus'
import { JobType } from './enums'
import ComplaintModel from '@/models/claim/ComplaintModel'
import { getBaseUrl } from '@/api/CallCentreApiAxiosPlugin'
import { sanitize } from 'dompurify'
import Store from '@/store'
import Job from '@/models/Job'
import Emergency from '@/models/Emergency'

export function highlightPhrase(searchValue: string, displayText: string) {
  if (searchValue) {
    return displayText.replace(new RegExp(searchValue, 'gi'), (match) => `<span class="highlightText">${match}</span>`)
  }
  return displayText
}

export default class Shared {
  public static alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  public static titleList = ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms']

  // static titles
  public static titlePickupRate = '20 second call pickup rate'
  public static titleTotalCalls = 'Current calls'
  public static titleQueueCalls = 'Current calls in queue'
  public static titleLongestWait = 'Longest wait'
  public static titleTotalNumberOfOpenComplaint = 'Total number of open complaints'
  public static titleTotalNumberOfCloseComplaint = 'Total number of closed complaints'
  public static titleTotalOverdueActions = 'Total overdue actions'
  public static titleSettlementPaidMTD = 'Settlement paid MTD'
  public static titleTotalJobsLoggedInLast15Days = 'Total Jobs Logged in Last 15 Days'
  public static titleTop5Emergencies = 'Top 5 Emergency'
  public static titleContractorQueueCalls = 'Contractor calls in queue'

  // colors
  public static colorWhite = 'white'
  public static colorGreen = 'green'
  public static colorRed = 'red'
  public static colorPurple = 'purple'
  public static colorAmber = 'amber'
  public static colorBlue = 'blue'
  public static colorOrange = 'orange'
  public static colorDeepOrangeLighten2 = 'deep-orange lighten-2'

  // pagination
  public static rowsPerPageDefault = 10

  // validation delay
  public static validationDelay = 1000

  // enablement not required message
  public static enablementMessage = 'Enablement is not required'

  // globally defined confirmation popup
  public static confirmationPopup: Confirmation

  // offline tables name
  public static offlineJobRequestTable = 'jobRequests'

  // currency formater
  public static currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  public static currencyFormatterWithoutSymbol = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  // default LoadMore recordCount
  public static defaultLoadMoreRecordCount = 20

  public static dateFormatForDatePicker = 'YYYY-MM-DD'

  // snackbar
  public static snackbarTimeout = 3000

  // date time format for unit test cases
  public static DateTimeFormatPM = 'MM-DD-YYYY hh:mm P'
  public static DateTimeFormatAM = 'MM-DD-YYYY hh:mm A'

  public static jobTypeList: Array<{
    jobType: JobType
    jobTypeDescription: string
  }> = [
      { jobType: 'HE', jobTypeDescription: 'Home Emergency' },
      { jobType: 'US', jobTypeDescription: 'Underground Services' },
      { jobType: 'SI', jobTypeDescription: 'Site Investigation' },
    ]

  // set focus to first element which has validation error
  public static setValidationFocus(el: HTMLElement): void {
    const errorItem: any = el.querySelector('.error--text')
    if (errorItem) {
      errorItem.focus()
      if (errorItem.getElementsByTagName('input')[0]) {
        // focus on text box
        errorItem.getElementsByTagName('input')[0].focus()
      } else if (errorItem.getElementsByTagName('textarea')[0]) {
        // focus on text area
        errorItem.getElementsByTagName('textarea')[0].focus()
      }
    }
  }

  public static SortData(data: any, columnName: string): any {
    if (data != null) {
      data.sort((a: any, b: any) => {
        let nameA = String(a[columnName]).toLowerCase()
        let nameB = String(b[columnName]).toLowerCase()

        if (nameA === 'undefined') {
          nameA = ''
        }
        if (nameB === 'undefined') {
          nameB = ''
        }
        // sort string ascending
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return -1 // default return value (no sorting)
      })
    }

    return data
  }

  public static compare(a: any, b: any, isDesc: boolean) {
    if (!isDesc) {
      return a || b ? (!a ? this.before : !b ? this.after : this.compareTypes(a, b)) : this.noChange
    } else {
      return b || a ? (!b ? this.before : !a ? this.after : this.compareTypes(b, a)) : this.noChange
    }
  }

  public static getOperator(operatorId: number): string {
    const operatorItem: Operator | undefined = this.getOperatorList().find((e: Operator) => e.id === operatorId)
    if (operatorItem) {
      return operatorItem.name
    }
    return ''
  }

  // get month names in array
  public static getMonthList(): Month[] {
    return [
      { id: 1, name: 'Jan' },
      { id: 2, name: 'Feb' },
      { id: 3, name: 'Mar' },
      { id: 4, name: 'Apr' },
      { id: 5, name: 'May' },
      { id: 6, name: 'Jun' },
      { id: 7, name: 'Jul' },
      { id: 8, name: 'Aug' },
      { id: 9, name: 'Sep' },
      { id: 10, name: 'Oct' },
      { id: 11, name: 'Nov' },
      { id: 12, name: 'Dec' },
    ]
  }

  // get operators
  public static getOperatorList(): Operator[] {
    return [
      { id: 1, name: 'must be equal to' },
      { id: 2, name: 'must be greater than' },
      { id: 3, name: 'must be less than' },
      { id: 4, name: 'must be greater or equal to' },
      { id: 5, name: 'must be less or equal to' },
      { id: 6, name: 'must not be equal to' },
      { id: 7, name: 'must be in' },
      { id: 8, name: 'must not be in' },
    ]
  }

  // get timeSpanRange
  public static getTimeSpanRangeList(): TimeSpanSlots[] {
    return [
      { id: 1, text: '15 Minutes', value: 15 },
      { id: 2, text: '30 Minutes', value: 30 },
      { id: 3, text: '45 Minutes', value: 45 },
      { id: 4, text: '1 Hour', value: 60 },
      { id: 5, text: '1 Hour 15 Minutes', value: 75 },
      { id: 6, text: '1 Hour 30 Minutes', value: 90 },
      { id: 7, text: '1 Hour 45 Minutes', value: 105 },
      { id: 8, text: '2 Hours', value: 120 },
      { id: 9, text: '2 Hours 15 Minutes', value: 135 },
      { id: 10, text: '2 Hours 30 Minutes', value: 150 },
      { id: 11, text: '2 Hours 45 Minutes', value: 165 },
      { id: 12, text: '3 Hours', value: 180 },
    ]
  }

  public static getFormatedDate(dateValue: moment.Moment, format: string): string {
    if (dateValue) {
      if (moment.isMoment(dateValue) && dateValue.isValid()) {
        return dateValue.format(format)
      } else {
        return '--/--/----'
      }
    }
    return '--/--/----'
  }

  public static getSelectedItemFirstInList(data: any[], dataToBeCompared: any, prop: string): any[] {
    const selectedItems: any[] = data.filter((e) => dataToBeCompared.includes(e[prop]))
    const remainingItems: any[] = data.filter((e) => !dataToBeCompared.includes(e[prop]))
    return selectedItems.concat(remainingItems)
  }

  // Get minutes from second with "00:00" format
  public static getMinutesFromSecond(duration: number, defaultValue: string, length: number): string {
    return (new Array(length + 1).join(defaultValue) + duration).slice(-length)
  }

  public static setLoggedJobCount(jobCount: JobCount): any {
    const chartData: any[] = []
    // set key value as label property and its value according to passed complaint model
    if (jobCount) {
      const reverseDaysKeys = Object.keys(jobCount).filter((c) => c.indexOf('day') >= 0)
      const jobCounts = []
      reverseDaysKeys.forEach((element) => {
        jobCounts[element] = jobCount[element]
      })
      for (const key in jobCounts) {
        if (jobCount[key] >= 0) {
          const lastChar: any = key.split('day')[1]
          if (!isNaN(lastChar)) {
            const prop = Shared.formatDateForGraphLabels(new Date(new Date().setDate(new Date().getDate() - lastChar)))
            chartData[prop] = jobCount[key]
          }
        }
      }
    }
    return chartData
  }

  public static setChartDataInOrder(res: any) {
    const loggedJobs = this.setLoggedJobCount(res)
    const keys = Object.keys(loggedJobs).reverse()
    const objValues = 'values'
    const values = Object[objValues](loggedJobs).reverse()
    const list = []
    for (let i = 0; i < keys.length; i++) {
      list[keys[i]] = values[i]
    }
    return list
  }

  public static formatDateForGraphLabels(date: Date): string {
    const monthNames = this.getMonthList().map((m: Month) => m.name)
    const day: number = date.getDate()
    const monthIndex: number = date.getMonth()
    return day + ' ' + monthNames[monthIndex]
  }

  public static setChartData(complaint: TrackerCountModel): any[] {
    const chartData: any[] = []
    // set key value as label property and its value according to passed complaint model
    if (complaint) {
      const reverseDaysKeys = Object.keys(complaint)
        .reverse()
        .filter((c) => c.indexOf('day') >= 0) // get days in  reverse order from complaint
      const complaintModel = []
      reverseDaysKeys.forEach((element) => {
        complaintModel[element] = complaint[element]
      })
      for (const key in complaintModel) {
        if (complaint[key] >= 0) {
          const lastChar: number = parseInt(key.substr(key.length - 1), 10)
          if (!isNaN(lastChar)) {
            const prop = Shared.formatDateForGraphLabels(new Date(new Date().setDate(new Date().getDate() - lastChar)))
            chartData[prop] = complaint[key]
          }
        }
      }
    }
    return chartData
  }

  public static formatDuration(seconds = 0) {
    let minutes = Math.floor(seconds / 60)
    seconds = seconds % 60
    const hours = Math.floor(minutes / 60)
    minutes = minutes % 60
    return (
      (hours > 0 ? ('0' + hours).slice(-2) + ':' : '') + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
    )
  }

  public static getPieChartOptions(): object {
    return {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 15,
        },
      },
      tooltips: {
        backgroundColor: '#FFF',
        titleFontSize: 10,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 14,
        xPadding: 5,
        yPadding: 5,
        displayColors: false,
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      responsive: true,
      lineTension: 1,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              display: false,
              minRotation: 90,
            },
            stacked: true,
            position: 'right',
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
              beginAtZero: true,
            },
          },
        ],
      },
    }
  }

  public static getFormatedCurrency(value: number | undefined, isWithoutSymbol = false): string {
    if (isWithoutSymbol) {
      return this.currencyFormatterWithoutSymbol.format(value || 0)
    }
    return this.currencyFormatter.format(value || 0)
  }

  public static addOverFlowHiddenToDOM(currentElement: Element | null, className: string) {
    const el: any = currentElement ? currentElement.querySelector('.' + className) : null
    if (el) {
      const htmlElement = document.getElementsByTagName('html')
      if (htmlElement.length > 0) {
        htmlElement[0].style.overflow = 'hidden'
      }
    }
  }

  public static addOverFlowAutoToBody() {
    const htmlElement = document.getElementsByTagName('html')
    if (htmlElement.length > 0) {
      htmlElement[0].style.overflow = 'auto'
    }
  }

  public static getGoogle() {
    return (window as any).google
  }

  public static sortArrayByDate<T>(array: T[], prop: keyof T): T[] {
    // give result by last item as closest time from now
    return array.sort((a: any, b: any) => {
      a.createdAt = typeof a.createdAt === 'string' ? moment(a.createdAt) : a.createdAt
      b.createdAt = typeof b.createdAt === 'string' ? moment(b.createdAt) : b.createdAt
      return a.createdAt.isBefore(b[prop]) ? -1 : a.createdAt.isAfter(b[prop]) ? 1 : 0
    })
  }

  public static generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint:disable-next-line:no-bitwise
      const random = (Math.random() * 16) | 0
      // eslint:disable-next-line:no-bitwise
      const value = c === 'x' ? random : (random & 0x3) | 0x8
      return value.toString(16)
    })
  }

  public static IsNumeric(event: any): boolean {
    const charCode = String.fromCharCode(event.keyCode)
    if (/[0-9]/.test(charCode)) {
      return true
    } else {
      event.preventDefault()
      return false
    }
  }

  public static IsNumericDecimal(event: any, minumDecimalValue = 2): boolean {
    const charCode = event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 8) {
      event.preventDefault()
      return false
    } else {
      const value = event.target.value
      if (value.indexOf('.') >= 0 && value.indexOf('.') < value.length - minumDecimalValue) {
        event.preventDefault()
        return false
      } else {
        const countPoints = value.split('.').length - 1
        if (countPoints >= 1 && charCode === 46) {
          event.preventDefault()
          return false
        }
        return true
      }
    }
  }

  public static IsMaxLength(value: number, maxlength: number): boolean {
    if (value && value.toString().length >= maxlength) {
      return true
    }
    return false
  }

  public static jobIdValidation(event: any) {
    const charCode = String.fromCharCode(event.keyCode)
    if (/[a-zA-Z0-9-]/.test(charCode)) {
      return true
    } else {
      event.preventDefault()
      return
    }
  }

  public static alphanumericValidation(event: any) {
    const charCode = String.fromCharCode(event.keyCode)
    // alphanumeric validation, no special character is allowed
    if (/[a-zA-Z0-9]/.test(charCode)) {
      return true
    } else {
      event.preventDefault()
      return
    }
  }

  public static dateFormatToSaveDocumentOnAzure() {
    return 'MM/DD/YYYY HH:mm:ss A'
  }

  public static formatTimeSpanToString(timeSpan: string) {
    // ignore second. Format from 00:00:00 to 00:00.
    if (timeSpan) {
      const hour = Number(timeSpan.split(':')[0])
      const minutes = Number(timeSpan.split(':')[1])
      return (
        (hour === 0 ? '00' : hour < 10 ? '0' + hour : hour) +
        ':' +
        (minutes === 0 ? '00' : minutes < 10 ? '0' + minutes : minutes)
      )
    }
    return ''
  }

  public static passJobIdInHeader(jobId = '') {
    eventBus.$emit('showJobIdInHeader', jobId)
    if (!jobId) {
      Shared.addOverFlowAutoToBody()
    }
  }

  public static isUserHasJobRights(jobId: string, showErrorMessage = true) {
    const selectedJobTypes = store.Instance.state.SessionDetail.detailRecord.UserDetail.jobTypes
    const jobType = jobId.substr(0, 2)
    if (selectedJobTypes && selectedJobTypes.includes(jobType !== 'US' && jobType !== 'SI' ? 'HE' : jobType)) {
      return true
    }
    // prevent to access job
    if (showErrorMessage) {
      const jobAccessMessage = jobType === 'HE' || jobType === 'US' || jobType === 'SI' ? jobType + ' jobs' : 'this job'
      const message: string = 'You do not have permission for ' + jobAccessMessage
      eventBus.$emit('showSnackbar', message)
    }
    return false
  }

  public static insertSpaceInEnumName(text: string) {
    // insert space before each capital letter in the word.
    // if two capital letter came in a row, don't split it
    // Trim is used to remove initial/first space added in word started with capital letter.

    // This was originally done using a regex, but iOS doesn't like positive lookaheads so we do it the verbose way instead.

    const isUpperCase = (c) => c === c.toUpperCase() && c !== c.toLowerCase()
    const isLowerCase = (c) => c === c.toLowerCase() && c !== c.toUpperCase()

    let output = ''
    for (let x = 0; x < text.length; x++) {
      const char = text.charAt(x)

      // We only split on upper case characters.
      if (isUpperCase(char)) {
        const nextChar = text.charAt(x + 1)
        const lastChar = text.charAt(x - 1)

        // Don't split on all-caps words.
        if (isLowerCase(nextChar) || isLowerCase(lastChar)) {
          output += ' '
        }
      }
      output += char
    }
    return output.trim()
  }

  public static froalaConfig(toolbarButtonsRequired = false, url = '', params: any = null) {
    // url - pass the url from individual froala editor to get images from blob
    // params - example { jobId: 'HE-1111'}
    return {
      key: store.Instance.state.Environment.FroalaEditorKey,
      placeholderText: 'Edit Your Content Here!',
      heightMin: 400,
      heightMax: 400,
      quickInsertTags: [''],
      toolbarButtons: toolbarButtonsRequired
        ? [
          'fullscreen',
          'selectAll',
          'lineHeight',
          'undo',
          'redo',
          'bold',
          'italic',
          'underline',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'formatOL',
          'formatUL',
          'insertLink',
          'inlineClass',
          'paragraphStyle',
          'paragraphFormat',
          'insertImage',
        ]
        : undefined,
      imageInsertButtons: ['imageByURL', 'imageManager'],
      requestHeaders: url
        ? {
          Authorization: 'bearer ' + store.Instance.state.SessionDetail.accessToken,
        }
        : undefined,
      imageManagerLoadURL: url ? getBaseUrl() + url : undefined,
      imageManagerLoadMethod: url ? 'Get' : undefined,
      imageManagerLoadParams: params ? params : {},
      imageUploadRemoteUrls: false, // set to false to prevent upload images inserted by URL to custom server
    }
  }

  public static JobTypeLabel(name: string, jobType: JobType): string {
    switch (name.toLowerCase()) {
      case 'emergency':
        return jobType === 'US' ? 'Commodity' : 'Emergency'
      case 'emergency detail':
        return jobType === 'US' ? 'Commodity Detail' : 'Emergency Detail'
      case 'emergency definition':
        return jobType === 'US' ? 'Commodity Definition' : 'Emergency Definition'
      case 'emergency type':
        return jobType === 'US' ? 'Commodity Type' : 'Emergency Type'
      case 'policy limit':
        return jobType === 'US' ? 'Delegated Authority' : 'Policy Limit'
      case 'customer ref':
        return jobType === 'SI' ? 'Client Reference Number' : 'Customer Reference'
      default:
        return ''
    }
  }

  public static isComplaintOverdue(complaint: ComplaintModel): boolean {
    const isLate = (d: moment.Moment | null, s: moment.Moment | null) => {
      if (d === undefined || d === null || !d.isValid()) {
        return false
      }
      if (s !== undefined && s !== null && s.isValid()) {
        return false
      }
      return moment(d) < moment.utc().startOf('date')
    }
    return (
      isLate(complaint.initialResponseLetterDate, complaint.initialResponseLetterSent) ||
      isLate(complaint.fourWeekLetterDate, complaint.fourWeekLetterSent) ||
      isLate(complaint.eightWeekLetterDate, complaint.eightWeekLetterSent) ||
      isLate(complaint.summaryResolutionCommunicationDate, complaint.summaryResolutionCommunicationSent) ||
      isLate(complaint.finalResponseLetterDate, complaint.finalResponseLetterSent)
    )
  }

  public static roundedMinutes(originalDate: moment.Moment | null, minutesInterval: number) {
    if (originalDate) {
      const roundedETAFromMinute = Math.ceil(moment(originalDate).minute() / minutesInterval) * minutesInterval
      return moment(originalDate).minute(roundedETAFromMinute).second(0)
    }
    return null
  }

  public static sanitizeHTML(html: string) {
    return sanitize(html)
  }

  public static highlightPhrase = highlightPhrase

  public static getTimeSpanSlotsList(numberOfRows = 0, gapBetweenNumbers = 1): TimeSpanSlots[] {
    const list: TimeSpanSlots[] = []
    if (numberOfRows > 0) {
      for (let index = 0; index < numberOfRows; index++) {
        const newValue: number = index > 0 ? list[index - 1].value + gapBetweenNumbers : index
        list.push({
          id: index + 1,
          text: newValue < 10 ? '0' + newValue.toString() : newValue.toString(),
          value: newValue,
        })
      }
      return list
    }
    return []
  }

  public static getDateTimeInBSTZone(dateValue: moment.Moment): string {
    if (moment.isMoment(dateValue) && dateValue.isValid()) {
      const date = moment(dateValue).toDate()
      return new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        )
      ).toLocaleString('en-US', { timeZone: 'Europe/London' })
    }
    return '--/--/----'
  }

  public static convertTimeToMinutes(hourValue: number, minuteValue: number) {
    return hourValue ? hourValue * 60 + minuteValue : minuteValue
  }

  public static calculateMinutes(value: number) {
    const minutes = value % 60
    return minutes < 10 ? '0' + minutes : minutes
  }

  public static calculateHours(value: number) {
    const hours = Math.floor(value / 60)
    return hours < 10 ? '0' + hours : hours
  }

  public static getLoaderStyle(size: number) {
    return {
      height: size + 'px',
      width: size + 'px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      'margin-left': -1 * (size / 2) + 'px',
      'margin-top': -1 * (size / 2) + 'px',
    }
  }

  public static get selectedJob(): Job | null {
    return Store.Instance.getters['jobModule/selectedJob'];
  }

  public static getEmergencyName(emergencyId: string): string {
    if (this.selectedJob) {
      const emergency = this.selectedJob.emergencies.find((e: Emergency) => e.id === emergencyId)
      return emergency ? `${emergency.typeDescription}-${emergency.detailDescription}` : ''
    }
    return ''
  }

  public static getEmergencyNameFromTypeId(emergencyTypeId: number): string {
    if (this.selectedJob) {
      const emergency = this.selectedJob.emergencies.find((e: Emergency) => e.typeId === emergencyTypeId)
      return emergency ? `${emergency.typeDescription}-${emergency.detailDescription}` : ''
    }
    return ''
  }

  public static mapObject<T extends object, S>(destinationObj: T, sourceObj: S): T {
    Object.keys(destinationObj).forEach((key) => { destinationObj[key] = sourceObj[key] })
    return destinationObj
  }

  // return values for compare/sort
  private static before = -1
  private static after = 1
  private static noChange = 0
  private static compareTypes(a: any, b: any) {
    if (typeof a === 'string') {
      return a.localeCompare(b)
    }
    if (moment.isMoment(a) && a.isValid() && moment.isMoment(b) && a.isValid()) {
      return a === b ? this.noChange : a.isAfter(b) ? this.after : this.before
    }
    return a === b ? this.noChange : a < b ? this.before : this.after
  }
}
