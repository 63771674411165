<template>
  <v-menu offset-y :close-on-content-click="false" :nudge-width="380" :max-width="380" :z-index="999" fixed left>
    <template #activator="{ on }">
      <span class="notification-bell ma-0" v-on="on">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-badge overlap color="grey small-size-badge darken-2">
              <span v-if="filterPreviousJobsList.length" slot="badge" small>
                {{ filterPreviousJobsList.length }}
              </span>
              <v-avatar color="white" class="elevation-5" :size="34" v-on="on">
                <v-icon dark class="secondary--text" small>work</v-icon>
              </v-avatar>
            </v-badge>
          </template>
          <span>Previous Jobs</span>
        </v-tooltip>
      </span>
    </template>
    <v-card v-bar="{ useScrollbarPseudo: true }" class="notification-menu pa-3">
      <div class="inner-content view-panel">
        <v-list
          :style="filterPreviousJobsList && filterPreviousJobsList.length > 2 ? 'max-height: 225px !important' : ''"
        >
          <v-list-tile v-if="filterPreviousJobsList && filterPreviousJobsList.length === 0" class="unread-notification">
            <v-list-tile-content>
              <v-list-tile-sub-title class="caption norecord-found-label">
                No previous jobs found.
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-for="(item, ind) in filterPreviousJobsList" v-else :key="ind" class="unread-notification">
            <v-list-tile-content>
              <a :href="previousJobUrl(item.jobId)" target="_blank" class="notification-anchor">
                <v-list-tile-sub-title class="caption">
                  <v-layout>
                    <v-flex xs6>
                      <b>
                        Job Id:
                        <span class="secondary--text">{{ item.jobId }}</span>
                      </b>
                    </v-flex>
                    <v-flex xs6 text-xs-right>
                      <b class="grey--text">
                        Logged on
                        <span class="grey--text text--darken-3">
                          {{ getCreatedDateForDisplay(item.createdAt) }}
                        </span>
                      </b>
                    </v-flex>
                  </v-layout>
                </v-list-tile-sub-title>
                <div class="mt-2 grey--text text--darken-3 body-2">
                  <b v-for="(prevJob, index) in getPreviousJobsByJobId(item.jobId)" :key="index">
                    {{ prevJob.description }}{{ index === getPreviousJobsByJobId(item.jobId).length - 1 ? '' : ',' }}
                  </b>
                </div>
              </a>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import JobController from '@/api/jobController'
import Emergency from '@/models/Emergency'
import PreviousJobModel from '@/models/policyHolder/PreviousJobModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import eventBus from '@/common/bus'

@Component
export default class PreviousJobs extends Vue {
  @Prop() private jobId: string
  private previousJobsList: PreviousJobModel[] = []
  private filterPreviousJobsList: PreviousJobModel[] = []

  private created() {
    this.GetEmergencyDetailLevelQuestions()
  }

  private get jobItem(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private getPreviousJobsByJobId(jobId: string): PreviousJobModel[] {
    return this.previousJobsList.filter((e) => e.jobId === jobId)
  }

  @Watch('jobId')
  private jobIdChanged() {
    this.GetEmergencyDetailLevelQuestions()
  }

  private GetEmergencyDetailLevelQuestions(): void {
    const self = this
    if (self.jobItem) {
      JobController.GetBasicDetailOfPolicyHolderJobs(self.jobItem.clientLastName, self.jobItem.postCode)
        .then((res: PreviousJobModel[]) => {
          if (res && res.length > 0) {
            const jobs: PreviousJobModel[] = res.filter((e: PreviousJobModel) => e.jobId !== this.jobId)
            self.previousJobsList = jobs
            let jobId = ''
            self.filterPreviousJobsList = jobs.filter((e) => {
              if (e.jobId !== jobId) {
                jobId = e.jobId
                return true
              }
              return false
            })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading logged job(s) of policyholder, please try again', true)
        })
    }
  }

  private getCreatedDateForDisplay(createdAt: moment.Moment): string {
    if (createdAt) {
      return Shared.getFormatedDate(moment(createdAt), Store.Instance.state.Environment.DateFormat)
    }
    return ''
  }

  private previousJobUrl(jobId: string): string {
    return location.protocol + '//' + location.host + '/' + 'job' + '/' + jobId
  }
}
</script>

<style type="text/css" scoped>
.notification-menu.v-card {
  border-radius: 0;
  background-color: #eee;
  max-height: auto;
  position: relative;
  overflow: hidden;
}
/*removed the radius of card*/
.notification-menu >>> .v-list {
  padding: 0px;
  background-color: transparent;
}
/*Change bg color notification list*/
.notification-menu >>> .v-list .v-list__tile__content {
  width: 100%;
}
.notification-menu >>> .v-list > div {
  background-color: #e6e6e6;
  margin-bottom: 10px;
}
/*Add bg color in notification list item*/
.notification-menu >>> .v-list > div:last-child {
  margin-bottom: 0px;
}
.notification-menu >>> .v-list .v-list__tile {
  padding: 10px 10px;
  height: auto;
}
.notification-menu >>> .v-list .v-list__tile__avatar {
  min-width: 42px;
  max-width: 42px;
}
.v-badge >>> .v-badge__badge {
  top: 0;
  right: 0;
}
/*to set bedge icon position*/
.n-arrow {
  transition: all 0.3s linear;
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: dashed dashed solid;
  border-width: 0 8.5px 8.5px;
  position: absolute;
  left: 13px;
  top: 50px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.v-menu__activator.v-menu__activator--active .n-arrow {
  top: 37px;
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.notification-menu >>> .vb-dragger {
  right: 4px;
}
.notification-bell >>> .v-badge--overlap .v-badge__badge {
  top: -7px;
  right: -11px;
}
.notification-menu >>> .v-list--three-line .v-list__tile {
  height: 92px;
}
.notification-menu .notification-anchor,
.notification-menu >>> .v-tooltip {
  display: inline-block;
  width: 100%;
}
.notification-menu .v-list > .unread-notification {
  background-color: #fff;
}
.norecord-found-label {
  font-size: 16px !important;
  text-align: center;
}
.notification-bell >>> .small-size-badge.v-badge__badge {
  font-size: 11px;
  height: 22px;
  width: 22px;
  top: -9px;
  right: -9px;
}
</style>
