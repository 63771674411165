<template>
  <v-layout wrap>
    <v-flex v-if="item">
      <div class="small-text item-description">{{ item.subject }} ({{ documentTemplate }})</div>
      <div
        v-if="item.sentToNominee && job.nomineeClientTitle && job.nomineeClientForename && job.nomineeClientLastName"
        class="my-1"
      >
        Notification has been sent on
        <b>{{ item.to }}</b>
        to Nominee
        <b>{{ job.nomineeClientFullName }}</b>
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmailModel from '@/models/claim/EmailModel'
import storeGetters from '@/storeGetters'
import { DocumentTemplateType } from '@/common/enums'

@Component
export default class EmailCard extends TimeLineItemBase {
  private get item(): EmailModel | null {
    if (this.jobId && this.itemId) {
      return storeGetters.getJobEmails(this.jobId, this.itemId)
    }
    return null
  }

  private get documentTemplate(): string {
    if (this.jobId && this.itemId && this.item) {
      if (this.item.documentTemplateType) {
        switch (this.item.documentTemplateType.toString()) {
          case DocumentTemplateType[DocumentTemplateType.NewJobLogged]:
            return 'New Job Logged'
          case DocumentTemplateType[DocumentTemplateType.ContractorAssigned]:
            return 'Contractor Appointed'
          case DocumentTemplateType[DocumentTemplateType.EngineerOnTheWay]:
            return 'Engineer On The Way'
          case DocumentTemplateType[DocumentTemplateType.JobCompleted]:
            return 'Job Completed'
          case DocumentTemplateType[DocumentTemplateType.CustomerAppointedTradespeople]:
            return 'Customer Appointed Tradespeople'
          case DocumentTemplateType[DocumentTemplateType.FollowOnWork]:
            return 'Follow On Work'
          case DocumentTemplateType[DocumentTemplateType.DeploymentDelay]:
            return 'Deployment Delay'
          case DocumentTemplateType[DocumentTemplateType.PartRequestApproved]:
            return 'Part Request Approved'
          case DocumentTemplateType[DocumentTemplateType.SetETAOnReturnVisit]:
            return 'Set ETA On Return Visit'
          default:
            return this.item.documentTemplateType.toString()
        }
      }
    }
    return ''
  }
}
</script>

<style scoped>
.item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  word-break: break-all;
}
</style>
