<template>
  <v-layout wrap>
    <v-flex md8 lg9 xs8>
      <div class="small-text">{{ job.clientFullName }}</div>
      <div class="small-text">{{ job.secondaryClientFullName }}</div>
      <div v-if="job" class="small-text">
        <pre>{{ job.getAddress('\n') }}</pre>
      </div>
    </v-flex>
    <v-flex md4 lg3 xs4>
      <div class="text-xs-right">
        <img
          v-if="job.policyScheduleImageUrl"
          :src="job.policyScheduleImageUrl"
          alt=""
          width="100%"
          class="policy-img"
        />
      </div>
    </v-flex>
    <v-flex v-if="job.jobType !== 'SI'" xs12 mt-2>
      <div
        v-for="emergency in getEmergencyList"
        :key="emergency.id"
        class="fnol-emergency-details grey lighten-3 mb-1 pa-1"
      >
        <div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                <i class="img-content">
                  <img v-if="emergency.fileURL !== null && emergency.fileURL !== ''" :src="emergency.fileURL" />
                  <img v-else src="/img/emergency.svg" alt="" />
                </i>
                <span class="em-title-content">
                  <b>{{ emergency.detailDescription + ': ' }}</b>
                </span>
              </span>
            </template>
            <span>
              {{ emergency.typeDescription ? emergency.typeDescription : emergency.detailDescription }}
            </span>
          </v-tooltip>
          <span>{{ getFormattedDateTime(emergency.firstNoticedAt) }}</span>
        </div>
        <v-flex v-if="emergency.thirdPartyContractor" xs12 mt-1 mb-2 pl-0>
          <div class="third-party-contractor">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon color="secondary" class="md-icon mr-1" v-on="on">group</v-icon>
              </template>
              <span>Third Party Contractor</span>
            </v-tooltip>
            <span>
              {{ getThirdPartyContractor(emergency.thirdPartyContractor) }}
            </span>
          </div>
        </v-flex>
      </div>
    </v-flex>
    <v-flex v-if="job.additionalCostsAuthorised" class="my-1">
      <b class="green--text">Additional payment authorized by {{ job.policyScheme }}</b>
    </v-flex>
    <v-flex v-if="job.nomineeClientForename && job.nomineeClientLastName" class="my-1">
      <b>Nominee : {{ job.nomineeClientFullName }} ({{ getRelationWithNominee(job.relation) }})</b>
    </v-flex>
    <v-flex v-if="job.jobType == 'SI'" class="my-1">
      <v-tooltip bottom :disabled="!(siJobDelay && siJobDelay.notes)">
        <template #activator="{ on }">
          <span :class="siJobDelay ? 'red--text' : 'green--text'" v-on="on">
            {{ siJobDelay ? 'Delay: ' + siJobDelay.reason : 'No delay logged' }}
          </span>
        </template>
        <span>{{ siJobDelay ? siJobDelay.notes : '' }}</span>
      </v-tooltip>
    </v-flex>
    <v-flex v-if="job.jobType == 'US'" class="my-1">
      <v-tooltip bottom :disabled="!(usJobDelay && usJobDelay.notes)">
        <template #activator="{ on }">
          <span :class="usJobDelay ? 'red--text' : 'green--text'" v-on="on">
            {{ usJobDelay ? 'Delay: ' + usJobDelay.reason : 'No delay logged' }}
          </span>
        </template>
        <span>{{ usJobDelay ? usJobDelay.notes : '' }}</span>
      </v-tooltip>
    </v-flex>
    <v-flex v-if="job.commodityWorkType && job.jobType === 'US'" class="my-1">
      Commodity Work Type:
      <b>{{ job.commodityWorkType }}</b>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import moment from 'moment'
import Emergency from '@/models/Emergency'
import Shared from '@/common/shared'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import { RecordType } from '@/common/enums'
import storeMutations from '@/storeMutations'
import eventBus from '@/common/bus'

@Component
export default class FNOLCard extends TimeLineItemBase {
  private emergencies: Emergency[] = []
  private contractors: ContractorModel[] = []
  private nomineeRelationList: MasterRecord[] = []
  private get getEmergencyList(): Emergency[] {
    this.emergencies = []
    if (this.job) {
      this.job.emergencies
        .filter((e) => e.displayInTimeline)
        .forEach((emergency) => {
          this.emergencies.push(emergency)
        })
    }
    return this.emergencies
  }

  private get getNomineeRelations() {
    const nomineeRelations: MasterRecord[] = storeGetters
      .getNomineeRelations()
      .filter((e) => e.isDeleted === false || e.isDeleted)
    return nomineeRelations
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private created() {
    ContractorController.GetAllContractors()
      .then((res: ContractorModel[]) => {
        if (res) {
          this.contractors = res
        } else {
          this.contractors = []
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor list, please try again', true)
      })
    this.SetNomineeRelations()
  }

  private getThirdPartyContractor(id: string) {
    const contractor: ContractorModel | undefined = this.contractors.find((e) => e.id === id)
    return contractor !== undefined ? contractor.companyName : ''
  }

  private getRelationWithNominee(id: string) {
    if (this.getNomineeRelations) {
      const relation: MasterRecord | undefined = this.getNomineeRelations.find((e) => e.id === id)
      if (relation) {
        return relation.description
      } else {
        return null
      }
    }
  }

  // Set list of nominee relations.
  private SetNomineeRelations(): void {
    if (this.getNomineeRelations.length === 0) {
      const recordType: string = RecordType[RecordType.NomineeRelation]
      MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
        if (res) {
          storeMutations.setNomineeRelations(res)
          this.nomineeRelationList = this.getNomineeRelations.slice(0)
        }
      })
    } else {
      this.nomineeRelationList = this.getNomineeRelations.slice(0)
    }
  }

  // get delay for SI job type
  private get siJobDelay() {
    return storeGetters.getSIJobDelay(this.jobId)
  }

  // get delay for US job type
  private get usJobDelay() {
    return storeGetters.getUSJobDelay(this.jobId)
  }
}
</script>

<style scoped>
.fnol-emergency-details .img-content img {
  width: 14px;
  height: 16px;
}

.fnol-emergency-details > div {
  position: relative;
  padding-left: 5px;
  margin-bottom: 5px;
}

.fnol-emergency-details .img-content {
  position: relative;
  left: -5px;
  top: 4px;
  display: inline-block;
}

.fnol-emergency-details .em-title-content {
  font-size: 13px;
}
.policy-img {
  height: 120px;
  object-fit: contain;
}
</style>
