<template>
  <div>
    <div class="mb-2"><h4>Monitoring Visit Invoice</h4></div>
    <div v-if="item.visitIds.length > 0">
      <div v-for="(visitId, index) in item.visitIds" :key="index">
        <div v-if="visitDetail(visitId)" class="mb-1">
          <label>Visit Created At: </label>
          <span>{{ getFormattedDate(visitDetail(visitId).visitDate) }}</span>
        </div>
        <div v-if="visitDetail(visitId)" class="mb-1">
          <label>Cost: </label>
          <span>{{ getFormatedCurrency(visitDetail(visitId).cost) }}</span>
        </div>
        <v-divider v-if="index !== item.visitIds.length - 1" class="mb-1"></v-divider>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddMonitoringVisitInvoiceRequestModel from '@/models/siteInvestigation/requests/AddMonitoringVisitInvoiceRequestModel'
import MonitoringVisitLog from '@/models/siteInvestigation/MonitoringVisitLog'
import MonitoringDetail from '@/models/siteInvestigation/MonitoringDetail'
import moment from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class AddMonitoringVisitInvoiceLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddMonitoringVisitInvoiceRequestModel {
    return Object.assign(new AddMonitoringVisitInvoiceRequestModel(), this.requestBody)
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get monitoringRecord(): MonitoringDetail | null {
    return this.requestedJob ? this.requestedJob.monitoringDetail : null
  }

  private visitDetail(visitId: number): MonitoringVisitLog | undefined {
    return this.monitoringRecord
      ? this.monitoringRecord.monitoringVisitLogs.find((m) => m.visitId === visitId)
      : undefined
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>
