<template>
  <div>
    <div class="mb-2"><h4>Customer Appointed Tradespeople Invoice</h4></div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="getFileName" class="mb-1">
      <label>File Name: </label>
      <span>{{ getFileName }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddCATInvoiceImageModel from '@/models/requests/AddCATInvoiceImageModel'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class AddCATInvoiceImageLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddCATInvoiceImageModel {
    return Object.assign(new AddCATInvoiceImageModel(), this.requestBody)
  }

  private get getFileName(): string {
    return this.item.blobUrl.substring(this.item.blobUrl.lastIndexOf('/')).split('/')[1]
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get emergencyName(): string {
    if (this.requestedJob) {
      const catDetail = this.requestedJob.customerAppointedTrades.find((c) => c.id === this.item.id)

      if (catDetail) {
        const item = this.requestedJob.emergencies.find((e) => e.id === catDetail.emergencyId)
        return item ? `${item.typeDescription}-${item.detailDescription}` : ''
      }
    }
    return ''
  }
}
</script>
