export default class HealthSafetyWellbeingEnvironment {
  public name: string
  public position: string
  public manualHandlingAwareness: boolean | null
  public asbestosAwareness: boolean | null
  public workAtHeight: boolean | null
  public slipsTripsFallsAwareness: boolean | null
  public provideHealthSurveillanceOrMonitoring: boolean | null
  public riddorReportableIncidents: boolean | null
  public riddorReportableIncidentsMoreInfo: string

  public constructor() {
    this.manualHandlingAwareness = null
    this.asbestosAwareness = null
    this.workAtHeight = null
    this.slipsTripsFallsAwareness = null
    this.provideHealthSurveillanceOrMonitoring = null
    this.riddorReportableIncidents = null
  }
}
