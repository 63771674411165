<template>
  <div class="dashboard">
    <v-layout wrap :class="openJobView ? 'hide-dashboard' : 'show-dashboard'">
      <v-flex xs12>
        <v-container fluid grid-list-lg pt-0 pa-0>
          <v-layout wrap job-info request-info>
            <DashboardTopTile
              v-for="tile in dashboardTiles"
              :key="tile.name"
              v-bind="tile"
              dashboard-tile-view="FinancialSummary"
              @onTopTileFilter="onTileClicked(tile)"
            />
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 class="gridView mt-4">
        <v-layout wrap>
          <v-flex xs4 request-info>
            <h3 v-if="selectedTile">{{ selectedTile.title }} Jobs</h3>
          </v-flex>
          <v-layout flex xs12>
            <v-flex xs3>
              <ClientPolicyList allow-all="true" />
            </v-flex>
            <v-flex xs3 class="ml-3">
              <TradeList allow-all="true" :job-type="selectedJobType" @change="selectedTradeId = $event" />
            </v-flex>
            <v-flex xs6 class="text-xs-right ml-3">
              <div ref="search-job" class="search-job">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <v-flex v-if="clientPolicies.updateFinancial" class="dashboard-buttons">
                <template v-if="selectedDashboardColumn === jobFinancialSummaryWidget.ReadyForApproval">
                  <v-btn color="primary" class="ma-0 ml-2 mt-2" :disabled="isLoading" @click="onApproveClicked()">
                    Approve
                  </v-btn>
                  <v-btn color="primary" class="ma-0 ml-2 mt-2" :disabled="isLoading" @click="onRejectClicked()">
                    Reject
                  </v-btn>
                </template>
                <template v-if="selectedDashboardColumn === jobFinancialSummaryWidget.Disputed">
                  <v-btn
                    color="primary"
                    class="ma-0 ml-2 mt-2"
                    :disabled="isLoading"
                    @click="onApproveDisputedJobsClicked()"
                  >
                    Approve
                  </v-btn>
                  <v-btn
                    v-if="selectedJobType === 'HE'"
                    color="primary"
                    class="ma-0 ml-2 mt-2"
                    :disabled="isLoading"
                    @click="onRecalculateClicked()"
                  >
                    Re-Calculate
                  </v-btn>
                </template>
                <template v-if="selectedDashboardColumn === jobFinancialSummaryWidget.ToReview">
                  <v-btn
                    color="primary"
                    class="ma-0 ml-2 mt-2"
                    :disabled="isLoading"
                    @click="onReviewCompleteClicked()"
                  >
                    Review Complete
                  </v-btn>
                </template>
              </v-flex>
            </v-flex>
          </v-layout>
          <v-flex xs12 mt-2 class="elevation-1">
            <div class="job-list grey">
              <v-data-table
                ref="jobFinancialDataTable"
                v-model="selectedJobList"
                :headers="headers"
                :items="jobList"
                :loading="isLoading"
                :pagination.sync="pagination"
                item-key="selectedItemId"
                :select-all="hasRightForMarking"
                class="job-table jobFinancialDataTable"
                name="jobFinancialDataTable"
                :search="search"
              >
                <v-progress-linear slot="progress" :color="tileColorWithActiveFilter" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr :active="props.selected" @click="props.selected = !props.selected">
                    <td v-if="hasRightForMarking">
                      <v-checkbox :input-value="props.selected" color="primary" hide-details></v-checkbox>
                    </td>
                    <td>
                      <b>
                        <a
                          href="Javascript:void(0)"
                          name="openJobDetailButton"
                          class="secondary--text link-jobId"
                          @click="redirectToJob(props.item.jobId)"
                          @click.stop
                        >
                          {{ props.item.jobId }}
                        </a>
                      </b>
                      <span v-if="props.item.clientNotes && props.item.clientNotes.length > 0" class="pl-2">
                        <v-tooltip right content-class="yellow lighten-2 grey--text text--darken-4 body-2">
                          <template #activator="{ on }">
                            <v-icon class="icon-arrow md-icon" v-on="on">info</v-icon>
                          </template>
                          <span>
                            <div v-for="(note, index) in props.item.clientNotes" :key="index">
                              <h3 class="mb-2">Note {{ index + 1 }}:</h3>
                              <p class="mb-2">- {{ note }}</p>
                            </div>
                          </span>
                        </v-tooltip>
                      </span>
                    </td>
                    <td>{{ props.item.address }}</td>
                    <td>{{ props.item.policyName }}</td>
                    <td class="text-right">
                      {{
                        props.item.emergenciesCountInJob > 1
                          ? getFormattedCurrency(props.item.policyLimit) + ' x ' + props.item.emergenciesCountInJob
                          : getFormattedCurrency(props.item.policyLimit)
                      }}
                    </td>
                    <td
                      v-if="checkCETLoginUser"
                      class="text-right"
                      :class="props.item.policyLimitRemaining < 0 ? 'red--text' : ''"
                    >
                      <b>
                        {{ getPolicyLimitRemaining(props.item.policyLimitRemaining) }}
                      </b>
                    </td>
                    <td v-if="checkCETLoginUser" class="text-right">
                      {{ getFormattedCurrency(props.item.materialValue) }}
                    </td>
                    <td v-if="checkCETLoginUser" class="text-right">
                      {{ getFormattedCurrency(props.item.labourValue) }}
                    </td>
                    <td v-if="checkCETLoginUser" class="text-right handling-fee">
                      {{ getFormattedCurrency(props.item.handlingFee ? props.item.handlingFee : 0) }}
                    </td>
                    <td class="text-right">
                      <span v-if="!props.item.overrideTotalCost">
                        {{ getFormattedCurrency(props.item.totalCostIncFees) }}
                      </span>
                      <span v-else>
                        <v-tooltip v-if="props.item.materialValue || props.item.labourValue" right>
                          <template #activator="{ on }">
                            <v-icon class="ml-2 md-icon" v-on="on">info</v-icon>
                          </template>
                          <span>
                            Auto calculated amount was
                            {{ getFormattedCurrency(props.item.totalCostIncFees) }}
                          </span>
                        </v-tooltip>
                        {{ getFormattedCurrency(props.item.overrideTotalCost) }}
                      </span>
                    </td>
                    <td class="text-xs-center">{{ props.item.returnVisit }}</td>
                    <td v-if="selectedDashboardColumn === jobFinancialSummaryWidget.Disputed" class="text-xs-center">
                      {{ props.item.disputedReason }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <div v-if="isLoading" class="loader-content">
        <v-progress-circular
          class="loading-spinner"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-layout>

    <!-- dialog - dispute job -->
    <v-dialog
      v-if="showDisputeDialog"
      v-model="showDisputeDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Dispute Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDisputeDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-4 pt-4">
          <v-layout wrap>
            <v-flex xs12 pr-2>
              <v-text-field
                v-model="disputedReason"
                v-validate="'required'"
                label="Dispute Reason"
                required
                class="required"
                data-vv-scope="jobFinancialSummaryScope"
                data-vv-name="Dispute Reason"
                :error-messages="errors.collect('Dispute Reason')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showDisputeDialog = false">Close</v-btn>
          <v-btn
            color="primary"
            :loading="onSubmitLoading"
            :disabled="onSubmitLoading"
            class="mr-0"
            @click.native="rejectJobs"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>

    <PartialJobView ref="refPartialJobView" :job-id="selectedJobIdToExpand" @closeJobView="closeJobView" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import Shared from '@/common/shared'
import { JobFinancialSummaryWidget, FinancialRequestStatus, JobType } from '@/common/enums'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import DashboardController from '@/api/dashboardController'
import JobFinancialSummary from '@/models/claim/JobFinancialSummary'
import JobFinancialSummaryWidgetCount from '@/models/JobFinancialSummaryWidgetCount'
import Store from '@/store'
import moment from 'moment'
import InvoiceDetailModel from '@/models/claim/InvoiceDetailModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import eventBus from '../common/bus'
import ApproveListOfJobsModel, { ApproveJob } from '@/models/claim/ApproveListOfJobsModel'
import PartialJobView from '@/components/PartialJobView.vue'
import TradeList from '@/components/trade/TradeList.vue'
import ClientPolicyList from '@/components/client/ClientPolicyList.vue'
import ClientAuthPolicies from '@/models/auth/client-auth-policies'
import ContractorSpecialization from '@/components/ContractorSpecialization.vue'

@Component({
  components: {
    DashboardTopTile,
    DateTimePicker,
    PartialJobView,
    TradeList,
    ClientPolicyList,
  },
})
export default class JobFinancialSummaryDashboardNew extends Vue {
  @Prop({ default: 'HE' }) public selectedJobType
  public selectedTradeId: number | null = null
  public search = ''
  public pagination: any = {}
  public snackbar = false
  public snackbarTimeout = 3000
  public snackbarText = ''
  public financialRequestStatus = FinancialRequestStatus
  public isLoading = false
  public selectedTile: TopTileProps | null = null
  public jobList: JobFinancialSummary[] = []
  private jobFinancialSummaryWidgetCount: JobFinancialSummaryWidgetCount | null = null
  public selectedJobList: JobFinancialSummary[] = []
  private defaultTableHeaderColor = 'blue day-counts-section white--text'
  public tileColorWithActiveFilter: string = this.defaultTableHeaderColor
  public jobFinancialSummaryWidget = JobFinancialSummaryWidget
  private hubName = 'jobFinancialSummary'
  private signalRHub: SignalRHubConnection = new SignalRHubConnection(this.hubName + this.selectedClientId)

  private lastReCalculateSignalRCallAt: moment.Moment | null = null
  public showDisputeDialog = false
  public disputedReason = ''
  public onSubmitLoading = false
  private disputedReasonTableHeader: any = {
    text: 'Dispute Reason',
    value: 'disputedReason',
    align: 'center',
    class: this.defaultTableHeaderColor,
  }

  private updatedJobIndex = -1
  public openJobView = false
  public selectedJobIdToExpand = ''
  private tempJobIdList: string[] = [] // temp jobId list to manage joblist updation on signalr

  public get selectedDashboardColumn(): JobFinancialSummaryWidget {
    if (!this.selectedTile || !this.selectedTile.name) {
      return JobFinancialSummaryWidget.ReadyForApproval
    }
    return JobFinancialSummaryWidget[this.selectedTile.name]
  }

  get selectedClientId(): number | null {
    return this.$store.getters['clientModule/selectedClientId']
  }

  get selectedPolicyId(): number | null {
    return this.$store.getters['clientModule/selectedPolicyId']
  }

  public get clientPolicies(): ClientAuthPolicies {
    return this.$store.getters['authModule/clients']
  }

  public get hasRightForMarking(): boolean {
    return this.clientPolicies.updateFinancial && this.selectedDashboardColumn !== JobFinancialSummaryWidget.Outstanding
  }

  public get dashboardTiles(): TopTileProps[] {
    if (this.jobFinancialSummaryWidgetCount == null) {
      return [
        {
          title: '',
          value: 0,
          showChart: false,
          chartData: [],
          chartMaxValue: 100,
          backgroundColor: 'blue day-counts-section',
          textColor: Shared.colorWhite,
          showArrow: false,
          hasFilter: true,
          isFilterActive: false,
          name: '',
          infoValue: [],
        },
      ]
    }

    return [
      {
        title: 'Ready for Approvals',
        value: this.jobFinancialSummaryWidgetCount.totalCompletedJobs,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: 'blue day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive: this.selectedDashboardColumn === JobFinancialSummaryWidget.ReadyForApproval,
        name: JobFinancialSummaryWidget[JobFinancialSummaryWidget.ReadyForApproval],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready for approvals',
            value: this.jobFinancialSummaryWidgetCount.totalCompletedJobs,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormattedCurrency(this.jobFinancialSummaryWidgetCount.totalCompletedJobCost),
            displayValue: (v: number) => v,
          },
        ],
      },
      {
        title: 'Awaiting Review from CET',
        value: this.jobFinancialSummaryWidgetCount.totalToReviewJobs,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: 'purple day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive: this.selectedDashboardColumn === JobFinancialSummaryWidget.ToReview,
        name: JobFinancialSummaryWidget[JobFinancialSummaryWidget.ToReview],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready to review',
            value: this.jobFinancialSummaryWidgetCount.totalToReviewJobs,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormattedCurrency(this.jobFinancialSummaryWidgetCount.totalToReviewJobCost),
            displayValue: (v: number) => v,
          },
        ],
      },
      {
        title: 'Disputed',
        value: this.jobFinancialSummaryWidgetCount.totalDisputedJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'red day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive: this.selectedDashboardColumn === JobFinancialSummaryWidget.Disputed,
        name: JobFinancialSummaryWidget[JobFinancialSummaryWidget.Disputed],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs disputed',
            value: this.jobFinancialSummaryWidgetCount.totalDisputedJobs,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormattedCurrency(this.jobFinancialSummaryWidgetCount.totalDisputedJobCost),
            displayValue: (v: number) => v,
          },
        ],
      },
      {
        title: 'Ready for Invoice',
        value: this.jobFinancialSummaryWidgetCount.totalApprovedJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'orange day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive: this.selectedDashboardColumn === JobFinancialSummaryWidget.ReadyForInvoice,
        name: JobFinancialSummaryWidget[JobFinancialSummaryWidget.ReadyForInvoice],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready for Invoice',
            value: this.jobFinancialSummaryWidgetCount.totalApprovedJobs,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormattedCurrency(this.jobFinancialSummaryWidgetCount.totalApprovedJobCost),
            displayValue: (v: number) => v,
          },
        ],
      },
      {
        title: 'Outstanding',
        value: this.jobFinancialSummaryWidgetCount.totalOutstandingJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'green day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive: this.selectedDashboardColumn === JobFinancialSummaryWidget.Outstanding,
        name: JobFinancialSummaryWidget[JobFinancialSummaryWidget.Outstanding],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of outstanding Jobs',
            value: this.jobFinancialSummaryWidgetCount.totalOutstandingJobs,
            displayValue: (v: number) => v,
          },
        ],
      },
    ]
  }

  public get checkCETLoginUser(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail'
  }

  public get headers(): any[] {
    const headerClass = this.selectedTile
      ? this.selectedTile.backgroundColor + ' white--text'
      : this.defaultTableHeaderColor

    const headers = [
      { text: 'Job ID', value: 'jobId', class: headerClass },
      { text: 'Address', value: 'address', align: 'left', class: headerClass },
      {
        text: 'Policy Name',
        value: 'policyName',
        align: 'left',
        class: headerClass,
      },
      {
        text: 'Policy Limit',
        value: 'policyLimit',
        align: 'right',
        class: headerClass,
      },
    ]

    if (this.checkCETLoginUser) {
      headers.push(
        {
          text: 'Policy Limit Remaining',
          value: 'policyLimitRemaining',
          align: 'right',
          class: headerClass,
        },
        {
          text: 'Material Cost',
          value: 'materialValue',
          align: 'right',
          class: headerClass,
        },
        {
          text: 'Labour Cost',
          value: 'labourValue',
          align: 'right',
          class: headerClass,
        }
      )
    }

    headers.push(
      {
        text: 'Handling Fee',
        value: 'handlingFee',
        align: 'right',
        class: headerClass,
      },
      {
        text: 'Total Cost',
        value: 'overrideTotalCost',
        align: 'right',
        class: headerClass,
      },
      {
        text: 'Return Visit',
        value: 'returnVisit',
        align: 'center',
        class: headerClass,
      }
    )

    if (this.selectedDashboardColumn === JobFinancialSummaryWidget.Disputed) {
      headers.push({
        text: 'Dispute Reason',
        value: 'disputedReason',
        align: 'center',
        class: headerClass,
      })
    }

    return headers
  }

  @Watch('selectedClientId')
  public async onClientChanged(): Promise<void> {
    // assign new signalR hub name of insurer change
    await this.signalRHub.changeHubNameAndReconnect(this.hubName + this.selectedClientId + this.selectedJobType)
    await this.refreshDashboardTiles()
    await this.refreshJobList()
  }

  @Watch('selectedJobType')
  public async onJobTypeChange(): Promise<void> {
    await this.signalRHub.changeHubNameAndReconnect(this.hubName + this.selectedClientId + this.selectedJobType)
    await this.refreshDashboardTiles()
    await this.refreshJobList()
  }

  @Watch('selectedTradeId')
  public async onTradeIdChange(): Promise<void> {
    await this.refreshJobList()
  }

  @Watch('selectedPolicyId')
  public async onPolicyIdChange(): Promise<void> {
    await this.refreshJobList()
  }

  public getFormattedCurrency(value: number | undefined): string {
    return Shared.getFormatedCurrency(value)
  }

  public onApproveClicked(): void {
    if (!this.selectedJobList.length) {
      this.snackbarText = 'Please select at least one order to approve.'
      this.snackbar = true
      return
    }
    Shared.confirmationPopup.openCallback({
      message: 'Do you want to approve the selected order(s)?',
      positiveCallback: this.approveJobs.bind(this),
    })
  }

  public onRejectClicked(): void {
    if (!this.selectedJobList.length) {
      this.snackbarText = 'Please select at least one order to dispute.'
      this.snackbar = true
      return
    }

    this.disputedReason = ''
    this.showDisputeDialog = true
  }

  public onReviewCompleteClicked(): void {
    if (!this.selectedJobList.length) {
      this.snackbarText = 'Please select at least one order to complete the review of.'
      this.snackbar = true
      return
    }

    Shared.confirmationPopup.openCallback({
      message: 'Do you want to complete the review of the selected order(s)?',
      positiveCallback: this.reviewCompleteJobs.bind(this),
    })
  }

  public onRecalculateClicked(): void {
    if (!this.selectedJobList.length) {
      this.snackbarText = 'Please select at least one order to recalculate.'
      this.snackbar = true
      return
    }

    Shared.confirmationPopup.openCallback({
      message: 'Do you want to recalculate the selected order(s)?',
      positiveCallback: this.recalculateJobs.bind(this),
    })
  }

  public onApproveDisputedJobsClicked(): void {
    if (!this.selectedJobList.length) {
      this.snackbarText = 'Please select at least one order to dispute.'
      this.snackbar = true
      return
    }

    Shared.confirmationPopup.openCallback({
      message: 'Do you want to dispute the selected order(s)?',
      positiveCallback: this.confirmDisputeJob.bind(this),
    })
  }

  public async confirmDisputeJob(): Promise<void> {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    if (!listOfJobsModel) {
      return
    }

    try {
      const res = await DashboardController.confirmDisputeJob(listOfJobsModel)

      if (res) {
        this.selectedJobList = []
        this.isLoading = false
        this.snackbarText = 'selected disputed job(s) approved'
        this.snackbar = true
      } else {
        this.selectedJobList = []
        this.isLoading = false
      }
    } catch (err) {
      eventBus.$emit('errorHandler', 'Error submitting confirm dispute job request, please try again', true)
      this.selectedJobList = []
    } finally {
      this.isLoading = false
    }
  }

  public async approveJobs(): Promise<void> {
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    if (!listOfJobsModel) {
      return
    }

    try {
      this.isLoading = true
      const res = await DashboardController.ApproveForJobInvoice(listOfJobsModel)
      if (res) {
        this.selectedJobList = []
        this.snackbarText = 'selected job(s) approved'
        this.snackbar = true
      } else {
        this.selectedJobList = []
      }
    } catch {
      eventBus.$emit('errorHandler', 'Error submitting approve job invoice request, please try again', true)
      this.selectedJobList = []
    } finally {
      this.isLoading = false
    }
  }

  public async rejectJobs(): Promise<void> {
    if (!this.selectedClientId) {
      return
    }

    const result = await this.validate()
    if (result) {
      this.isLoading = true
      const listOfJobsModel = this.getListOfJobsToBeProcessed()
      if (listOfJobsModel == null) {
        return
      }

      listOfJobsModel.disputedReason = this.disputedReason
      try {
        const res = await DashboardController.RejectForJobInvoice(listOfJobsModel)
        if (res) {
          this.showDisputeDialog = false
          this.snackbarText = 'selected job(s) rejected'
          this.snackbar = true
        }
      } catch {
        eventBus.$emit('errorHandler', 'Error submitting reject job invoice request, please try again', true)
      } finally {
        this.selectedJobList = []
        this.isLoading = false
      }
    }
  }

  public async reviewCompleteJobs(): Promise<void> {
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    if (!listOfJobsModel) {
      return
    }
    try {
      this.isLoading = true
      const res = await DashboardController.CompleteJobReview(listOfJobsModel)

      if (res) {
        this.selectedJobList = []
        this.snackbarText = 'selected job(s) reviewed.'
        this.snackbar = true
      } else {
        this.selectedJobList = []
      }
    } catch {
      eventBus.$emit('errorHandler', 'Error submitting complete job review request, please try again', true)
      this.selectedJobList = []
    } finally {
      this.isLoading = false
    }
  }

  public async recalculateJobs(): Promise<void> {
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    if (!listOfJobsModel) {
      return
    }

    try {
      this.isLoading = true
      const res = await DashboardController.RecalculateInvoiceOfJobs(listOfJobsModel)
      if (res) {
        this.selectedJobList = []
        this.snackbarText = 'selected job(s) re-calculated. Price will be updated soon.'
        this.snackbar = true
      } else {
        this.selectedJobList = []
      }
    } catch {
      eventBus.$emit('errorHandler', 'Error submitting recalculate job invoice request, please try again', true)
      this.selectedJobList = []
    } finally {
      this.isLoading = false
    }
  }

  public getPolicyLimitRemaining(policyLimit: string): string {
    const tempPolicyLimit = policyLimit.split(',')
    if (tempPolicyLimit.length > 1) {
      const formattedPolicyLimit: string[] = []
      tempPolicyLimit.forEach((p) => {
        formattedPolicyLimit.push(this.getFormattedCurrency(Number(p)))
      })
      return formattedPolicyLimit.join(', ')
    } else {
      return this.getFormattedCurrency(Number(policyLimit))
    }
  }

  public async onTileClicked(tile: TopTileProps): Promise<void> {
    this.selectedTile = tile
    this.selectedJobList = []
    this.tileColorWithActiveFilter = tile.backgroundColor + ' white--text'
    await this.refreshJobList()
  }

  public redirectToJob(jobId: string): void {
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  public closeJobView(): void {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }

  private getListOfJobsToBeProcessed(): ApproveListOfJobsModel | null {
    if (!this.selectedClientId) {
      return null
    }

    const listOfJobsModel: ApproveListOfJobsModel = new ApproveListOfJobsModel()
    listOfJobsModel.insurerId = this.selectedClientId

    const jobList: ApproveJob[] = []
    this.selectedJobList.forEach((selectedJob) => {
      const job = this.jobList.find((e) => e.jobId === selectedJob.jobId)
      if (job) {
        jobList.push({
          jobId: selectedJob.jobId,
          clientInvoiceDetailId: selectedJob.clientInvoiceDetailId,
        })
      }
    })
    listOfJobsModel.jobList = jobList

    return listOfJobsModel
  }

  private checkIsAnyJobSelected(status: string): boolean {
    if (this.selectedJobList.length === 0) {
      this.snackbarText = 'Please select job(s) to ' + status + '.'
      this.snackbar = true
      return false
    }
    return true
  }

  private setSelectAllCheckboxStyle(colorToUpdate: string) {
    const asd = this.$el as any
    if (asd) {
      const el: HTMLElement | null = asd.querySelector('.v-datatable--select-all thead tr th')
      if (el) {
        el.className = colorToUpdate
      }
    }
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('jobFinancialSummaryScope')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private async onSignalRAddNewInvoice(
    insurerId: number,
    jobId: string,
    invoiceDetailId: string,
    updatedWidgetCounts: JobFinancialSummaryWidgetCount | null
  ): Promise<void> {
    if (insurerId !== this.selectedClientId) {
      return
    }

    this.tempJobIdList = [jobId]
    if (this.selectedJobType === 'SI' && this.selectedDashboardColumn === JobFinancialSummaryWidget.ReadyForApproval) {
      // if invoice filter activated and jobType is SI, get and push jobs to the list
      await this.getJobsFinancialDetails([jobId], invoiceDetailId)
    } else if (this.selectedDashboardColumn === JobFinancialSummaryWidget.Outstanding) {
      // if Outstanding job filter activated, push new job
      await this.getJobsFinancialDetails([jobId])
    }
    // update widget count
    if (this.selectedJobType === 'SI' && updatedWidgetCounts) {
      this.updateTileDataOnSignalR(updatedWidgetCounts)
    } else if (this.jobFinancialSummaryWidgetCount !== null) {
      this.jobFinancialSummaryWidgetCount.totalOutstandingJobs += 1
    }
  }

  private async onSignalRApproveForJobInvoice(
    insurerId: number,
    approveListOfJobs: string[],
    clientInvoiceDetailId: string,
    updatedWidgetCounts: JobFinancialSummaryWidgetCount
  ): Promise<void> {
    if (insurerId !== this.selectedClientId) {
      return
    }

    this.tempJobIdList = approveListOfJobs
    switch (this.selectedDashboardColumn) {
      case JobFinancialSummaryWidget.ReadyForApproval:
        // if same filter activated, remove selected jobs
        await Promise.all(
          approveListOfJobs.map(async (jobId) => {
            const selectedJob = this.jobList.find(
              (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
            )
            if (selectedJob) {
              this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
            } else {
              // insert if approved by to review
              await this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
            }
          })
        )
        break
      case JobFinancialSummaryWidget.ReadyForInvoice:
        // if invoice filter activated, get and push jobs to the list
        await this.getJobsFinancialDetails(approveListOfJobs, clientInvoiceDetailId)
        break
      case JobFinancialSummaryWidget.ToReview:
      case JobFinancialSummaryWidget.Disputed:
        // if same filter activated, remove selected jobs
        approveListOfJobs.forEach((jobId) => {
          const selectedJob = this.jobList.find((x: JobFinancialSummary) => x.jobId === jobId)
          if (selectedJob) {
            this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
          }
        })
        break
    }

    // update tiles data
    this.updateTileDataOnSignalR(updatedWidgetCounts)
    this.resetTempJobIdList()
  }

  private async onSignalRRejectForJobInvoice(
    insurerId: number,
    rejectListOfJobs: string[],
    clientInvoiceDetailId: string,
    updatedWidgetCounts: JobFinancialSummaryWidgetCount
  ): Promise<void> {
    if (insurerId !== this.selectedClientId) {
      return
    }

    this.tempJobIdList = rejectListOfJobs
    if (this.selectedDashboardColumn === JobFinancialSummaryWidget.ReadyForApproval) {
      // if same filter activated, remove selected jobs
      rejectListOfJobs.forEach((jobId) => {
        const selectedJob = this.jobList.find(
          (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
        )
        if (selectedJob) {
          this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
        }
      })
    } else if (this.selectedDashboardColumn === JobFinancialSummaryWidget.Disputed) {
      // if invoice filter activated, get and push jobs to the list
      await this.getJobsFinancialDetails(rejectListOfJobs, clientInvoiceDetailId)
    }
    // update tiles data
    this.updateTileDataOnSignalR(updatedWidgetCounts)
    this.resetTempJobIdList()
  }

  private async onSignalRUpdateInvoiceDetail(
    dataProcessAtUtc: moment.Moment,
    insurerId: number,
    jobId: string,
    invoiceDetail: InvoiceDetailModel,
    clientInvoiceDetailId: string,
    widgetCount: JobFinancialSummaryWidgetCount
  ): Promise<void> {
    if (insurerId !== this.selectedClientId) {
      return
    }

    // save last signalr call at date, to verify the sequence
    if (this.lastReCalculateSignalRCallAt === null) {
      this.lastReCalculateSignalRCallAt = dataProcessAtUtc
      // update tiles data
      this.updateTileDataOnSignalR(widgetCount)
    } else if (this.lastReCalculateSignalRCallAt < dataProcessAtUtc) {
      // update tiles data
      this.updateTileDataOnSignalR(widgetCount)
    }
    if (this.selectedJobType === 'HE') {
      // labour,material or handling fee is updated, get updated record using API call
      // added timeout to check if other action is required to perform on this job
      // if not then only cost should be updated
      setTimeout(async () => {
        this.updatedJobIndex = this.jobList.findIndex(
          (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
        )
        if (this.updatedJobIndex > -1) {
          const index = this.tempJobIdList.findIndex((j) => j === jobId)
          if (index === -1) {
            await this.getJobsFinancialDetails([jobId], clientInvoiceDetailId, true)
          }
        }
      }, 500)
    } else {
      // find job and update material and labour cost in job if same filter selected
      const jobToUpdate: JobFinancialSummary | undefined = this.jobList.find(
        (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
      )
      if (jobToUpdate) {
        jobToUpdate.materialValue = invoiceDetail.materialValue
        jobToUpdate.labourValue = invoiceDetail.labourValue
        // for SI job value of totalCostIncFees is same as labourValue
        if (this.selectedJobType === 'SI') {
          jobToUpdate.totalCostIncFees = invoiceDetail.labourValue
        }
        jobToUpdate.overrideTotalCost = invoiceDetail.overrideTotalCost ? invoiceDetail.overrideTotalCost : 0
        jobToUpdate.handlingFee = invoiceDetail.handlingFee
        // for U/S job, cost of Other Fee will always be 0, so update value of totalCostIncFees accordingly when cost is updated from timeline
        if (this.selectedJobType === 'US') {
          jobToUpdate.totalCostIncFees =
            invoiceDetail.materialValue + invoiceDetail.labourValue + invoiceDetail.handlingFee
        }
      }
    }
    this.resetTempJobIdList()
  }

  private async onSignalRCompletedJob(insurerId: number, jobId: string, clientInvoiceDetailId: string): Promise<void> {
    if (insurerId !== this.selectedClientId) {
      return
    }

    this.tempJobIdList = [jobId]
    if (this.selectedDashboardColumn === JobFinancialSummaryWidget.ReadyForApproval) {
      // if same filter activated, add selected job
      await this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
    } else if (this.selectedDashboardColumn === JobFinancialSummaryWidget.Outstanding) {
      // if same filter activated, remove selected job
      const selectedJob = this.jobList.find(
        (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
      )
      if (selectedJob) {
        this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
      }
    }

    // update widget count
    if (this.jobFinancialSummaryWidgetCount) {
      this.jobFinancialSummaryWidgetCount.totalCompletedJobs += 1
      if (this.selectedJobType === 'HE') {
        // if HE job, then update count for totalOutstandingJobs
        this.jobFinancialSummaryWidgetCount.totalOutstandingJobs -= 1
      }
    }
    this.resetTempJobIdList()
  }

  private async onSignalRAddToReview(
    insurerId: number,
    jobId: string,
    clientInvoiceDetailId: string,
    updatedWidgetCounts: JobFinancialSummaryWidgetCount
  ): Promise<void> {
    if (insurerId !== this.selectedClientId) {
      return
    }

    this.tempJobIdList = [jobId]
    if (this.selectedDashboardColumn === JobFinancialSummaryWidget.ToReview) {
      // if same filter activated, add selected job
      await this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
    } else if (this.selectedDashboardColumn === JobFinancialSummaryWidget.Outstanding) {
      // if same filter activated, remove selected job
      const selectedJob = this.jobList.find(
        (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
      )
      if (selectedJob) {
        this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
      }
    }
    // update tiles data
    this.updateTileDataOnSignalR(updatedWidgetCounts)
    this.resetTempJobIdList()
  }

  private onSignalRConfirmDisputeJob(
    insurerId: number,
    rejectListOfJobs: string[],
    clientInvoiceDetailId: string,
    updatedWidgetCounts: JobFinancialSummaryWidgetCount
  ) {
    if (insurerId !== this.selectedClientId) {
      return
    }

    this.tempJobIdList = rejectListOfJobs
    if (this.selectedDashboardColumn === JobFinancialSummaryWidget.Disputed) {
      // if same filter activated, remove selected jobs
      rejectListOfJobs.forEach((jobId) => {
        const selectedJob = this.jobList.find(
          (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
        )
        if (selectedJob) {
          this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
        }
      })
    } else if (this.selectedDashboardColumn === JobFinancialSummaryWidget.ReadyForApproval) {
      // if invoice filter activated, get and push jobs to the list
      this.getJobsFinancialDetails(rejectListOfJobs, clientInvoiceDetailId)
    }
    // update tiles data
    this.updateTileDataOnSignalR(updatedWidgetCounts)
    this.resetTempJobIdList()
  }

  private onSignalRExportJob(
    insurerId: number,
    exportedListOfJobs: string[],
    clientInvoiceDetailId: string,
    updatedWidgetCounts: JobFinancialSummaryWidgetCount
  ) {
    this.tempJobIdList = exportedListOfJobs
    exportedListOfJobs.forEach((jobId) => {
      const selectedJob = this.jobList.findIndex(
        (x) =>
          x.jobId === jobId &&
          (this.selectedJobType === 'SI' ? x.clientInvoiceDetailId === clientInvoiceDetailId : true)
      )
      if (selectedJob > -1) {
        this.jobList.splice(selectedJob, 1)
      }
    })
    this.updateTileDataOnSignalR(updatedWidgetCounts)
    this.resetTempJobIdList()
  }

  private registerSignalR() {
    this.signalRHub.addHandler('addNewInvoice', this.onSignalRAddNewInvoice.bind(this))
    this.signalRHub.addHandler('approveForJobInvoice', this.onSignalRApproveForJobInvoice.bind(this))
    this.signalRHub.addHandler('rejectForJobInvoice', this.onSignalRRejectForJobInvoice.bind(this))
    this.signalRHub.addHandler('updateInvoiceDetail', this.onSignalRUpdateInvoiceDetail.bind(this))
    this.signalRHub.addHandler('completedJob', this.onSignalRCompletedJob.bind(this))
    this.signalRHub.addHandler('addToReview', this.onSignalRAddToReview.bind(this))
    this.signalRHub.addHandler('confirmDisputeJob', this.onSignalRConfirmDisputeJob.bind(this))
    this.signalRHub.addHandler('exportJob', this.onSignalRExportJob.bind(this))
    this.signalRHub.connect()
  }

  private resetTempJobIdList() {
    // empty tempJobIdList array
    // wait till the jobList gets updated
    setTimeout(() => {
      this.tempJobIdList = []
    }, 1000)
  }

  private async getJobsFinancialDetails(
    jobsList: string[],
    invoiceDetailId = '',
    isFromUpdateInvoiceDetail = false
  ): Promise<void> {
    const jobList: ApproveJob[] = []
    jobsList.map((id) => jobList.push({ jobId: id, clientInvoiceDetailId: '' }))

    const listOfJobsModel: ApproveListOfJobsModel = new ApproveListOfJobsModel()
    listOfJobsModel.jobList = jobList

    try {
      const res = await DashboardController.GetFinancialSummaryJobs(listOfJobsModel)
      if (!res) {
        throw new Error('Unable to load financial summary jobs')
      }

      if (isFromUpdateInvoiceDetail) {
        // labour,material or handling fee is updated, find record and update into jobList
        const job = res.find((r) => r.clientInvoiceDetailId === invoiceDetailId)
        if (job) {
          this.jobList.splice(this.updatedJobIndex, 1, job)
          this.updatedJobIndex = -1
        }
      } else if (invoiceDetailId) {
        // find record using invoiceDetailId and push or update into joblist
        const job = res.find((r) => r.clientInvoiceDetailId === invoiceDetailId)
        if (job) {
          const index = this.jobList.findIndex(
            (j) => job.jobId === j.jobId && j.clientInvoiceDetailId === job.clientInvoiceDetailId
          )
          if (index === -1) {
            this.jobList.push(job)
          } else {
            this.jobList.splice(index, 1, job)
          }
        }
      }
      this.resetTempJobIdList()
    } catch (err) {
      eventBus.$emit('errorHandler', 'Error loading financial summary jobs, please try again', true)
    }
  }

  private updateTileDataOnSignalR(updatedWidgetCounts: JobFinancialSummaryWidgetCount) {
    this.jobFinancialSummaryWidgetCount = updatedWidgetCounts
  }

  private async refreshDashboardTiles(): Promise<void> {
    this.isLoading = true

    try {
      if (!this.selectedClientId) {
        this.jobFinancialSummaryWidgetCount = null
      } else {
        const res = await DashboardController.GetClientFinanceDashboardSummary(
          this.selectedClientId,
          this.selectedJobType
        )
        this.jobFinancialSummaryWidgetCount = res
      }
    } catch (err) {
      eventBus.$emit('errorHandler', 'Error loading financial summary jobs, please try again', true)
    } finally {
      this.isLoading = false
    }
  }

  private async refreshJobList(): Promise<void> {
    this.isLoading = true
    this.selectedJobList = []
    this.jobList = []
    if (!this.selectedClientId) {
      return
    }

    try {
      const res = await DashboardController.GetClientFinanceDashboardData(
        this.selectedClientId,
        this.selectedJobType,
        this.selectedDashboardColumn,
        this.selectedPolicyId,
        this.selectedTradeId
      )

      this.jobList = res
      this.pagination.page = 1
      this.setSelectAllCheckboxStyle(this.tileColorWithActiveFilter)
    } catch (err) {
      eventBus.$emit('errorHandler', 'Error loading dashboard data.', true)
    } finally {
      this.isLoading = false
    }
  }

  async created(): Promise<void> {
    this.pagination.rowsPerPage = Shared.rowsPerPageDefault

    await this.refreshDashboardTiles()
    this.selectedTile = this.dashboardTiles[0]
    await this.refreshJobList()

    // apply updated color to header
    this.setSelectAllCheckboxStyle(this.defaultTableHeaderColor)

    this.registerSignalR()
  }

  private mounted() {
    this.setSelectAllCheckboxStyle(this.defaultTableHeaderColor)
  }

  private destroyed() {
    this.signalRHub.disconnect()
  }
}
</script>

<style scoped>
.job-info >>> h2 {
  font-size: 38px;
  display: inline-block;
  vertical-align: top;
}

.job-info.request-info >>> .card {
  height: 78px !important;
  display: flex;
  flex-wrap: wrap;
}

.job-info.request-info >>> .card .card__text {
  display: none;
  align-self: flex-end;
}

.job-info.request-info >>> .card .card__title {
  align-self: flex-start;
}

@media only screen and (max-width: 1200px) {
  .job-info >>> h2 {
    font-size: 30px;
  }

  .job-info.request-info >>> .card {
    height: 100px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .job-info.request-info >>> .card .card__title {
    font-size: 16px;
  }
}

.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}

.gridView {
  border: 0px;
}

.gridView >>> thead th,
.gridView >>> tbody tr > td {
  padding: 0px 15px !important;
}

.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
}

.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}

.gridView tbody tr td:last-child {
  padding-left: 10px;
}

.gridView >>> .vue-star-rating-rating-text {
  margin-top: -2px;
}

.gridView >>> tbody tr:first-child td:last-child {
  text-align: right;
}

.search-job {
  display: inline-block;
  min-width: 450px;
  margin-right: 15px;
}

.job-table >>> .table thead > tr > th:nth-last-child(1) {
  min-width: 208px;
  text-align: center !important;
}

.job-table >>> .table thead > tr > th:nth-child(2) {
  width: 5%;
}

.job-table >>> .table thead > tr > th:nth-child(3) {
  min-width: 300px;
}

.job-table >>> .v-datatable thead tr.v-datatable__progress th.column {
  padding: 0px 0px !important;
}

.text-right {
  text-align: right;
}

.day-counts-section {
  padding: 15px 10px 8px;
}

.day-counts-section .count {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  padding-left: 5px;
}

.day-counts-section .icon {
  vertical-align: top;
}

.day-counts-section .icon img {
  width: 18px;
  padding-top: 2px !important;
}

.dashboard-buttons {
  display: inline-block;
}

.dashboard-buttons .btn {
  vertical-align: top;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #fff;
  z-index: 205;
  opacity: 0.2;
}

.job-list >>> tr.datatable__progress th {
  padding: 0px !important;
}

.icon-arrow {
  position: relative;
}

.hide-dashboard {
  visibility: hidden;
}

.show-dashboard {
  visibility: visible;
}

.dashboard >>> .v-card {
  margin-bottom: 15px !important;
}

.dashboard >>> .v-tabs .v-tabs__bar {
  background-color: #eeeeee;
  position: relative !important;
  width: auto !important;
  z-index: 1;
  top: 0px !important;
}

.dashboard >>> .v-tabs .v-tabs__div {
  width: auto !important;
}
</style>
