<template>
  <div>
    <v-dialog v-model="jobCompletionWarningDialog" max-width="450" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Job Completion Warning</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-btn" @click.native="$emit('closeJobCompletionWarningDialog')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-layout wrap>
            <v-flex xs12 px-1 mb-2 job-warning>
              <span class="grey--text text--darken-1 custom-text">
                This job cannot be closed due to open Engineer Request.
              </span>
              <div class="text-xs-center">
                <v-icon color="red">error_outline</v-icon>
              </div>
              <br />
              <span class="grey--text text--darken-1">
                Please review all visits and ensure you have made decisions about all open requests.
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component
export default class JobCompletionWarning extends Vue {
  private jobCompletionWarningDialog = true
}
</script>

<style scoped>
.custom-text {
  font-size: 21px;
  display: inline-block;
  margin-top: 5px;
}
.job-warning .v-icon {
  font-size: 90px;
}
</style>
