<template>
  <div>
    <div class="mb-2"><h4>Create Emergency</h4></div>
    <div v-if="item.emergencyTypeDesc" class="mb-1">
      <label>Emergency: </label>
      <span>{{ item.emergencyTypeDesc }}</span>
    </div>
    <div v-if="item.emergencyDetailDesc" class="mb-1">
      <label>Emergency Detail: </label>
      <span>{{ item.emergencyDetailDesc }}</span>
    </div>
    <div v-if="item.tradeDesc" class="mb-1">
      <label>Trade: </label>
      <span>{{ item.tradeDesc }}</span>
    </div>
    <div v-if="item.note" class="mb-1">
      <label>Note: </label>
      <span>{{ item.note }}</span>
    </div>
    <div v-if="item.firstNoticedAtUtc" class="mb-1">
      <label>First Noticed: </label>
      <span>{{ getFormattedDate(item.firstNoticedAtUtc) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddEmergency from '@/models/requests/AddEmergency'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class AddEmergencyLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddEmergency {
    return Object.assign(new AddEmergency(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>

<style scoped></style>
