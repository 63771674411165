import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import VIPCustomerModel from '@/models/vipcustomer/VIPCustomerModel'

export default class VIPCustomerController {
  public static async SaveVIPCustomer(customerModel: VIPCustomerModel | null): Promise<number | null> {
    const res: AxiosResponse = await CallCentreApi.post('VIPCustomer/SaveVIPCustomer', customerModel)
    return res.data ? res.data : null
  }

  public static async GetVIPCustomers(): Promise<VIPCustomerModel[]> {
    const res = await CallCentreApi.get<VIPCustomerModel[]>('VIPCustomer/GetVIPCustomers')
    return res.data ? res.data : []
  }

  public static async DeleteVIPCustomer(customerId: number): Promise<boolean> {
    const res = await CallCentreApi.post('VIPCustomer/DeleteVIPCustomer?customerId=' + customerId)
    return res.status >= 200 && res.status < 300
  }
}
