import EngineerRequestBase from './EngineerRequestBase'
import MaterialModel from '@/models/material/MaterialModel'

export default class EngineerMaterialRequest extends EngineerRequestBase {
  public materialName: string
  public materialCost: number | null
  public furtherInfo: string
  public additionalInfo: string
  public additionalMaterialRequestName: string[]
  public excludeFromCustomerApprovalUpdates: boolean
  public additionalMaterialRequest: MaterialModel[] // property to manage material list - required for front-end
}
