<template>
  <div>
    <div class="mb-2">
      <h4>
        Invoice Details Updated For
        <span v-if="requestBody.id">Visit</span>
        <span v-else>Job</span>
      </h4>
    </div>

    <div v-if="requestBody.id">
      <div v-if="engineerName !== ''" class="mb-1">
        <label>Engineer: </label>
        <span>{{ engineerName }}</span>
      </div>
      <div v-if="emergencyName" class="mb-1">
        <label>Emergency: </label>
        <span>{{ emergencyName }}</span>
      </div>
    </div>
    <div v-else>
      <div class="mb-1">
        <label>Invoice Recalculated: </label>
        <span>{{ requestBody.recalculateAll ? 'Yes' : 'No' }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import storeGetters from '@/storeGetters'
import EngineerModel from '@/models/contractor/EngineerModel'
import Emergency from '@/models/Emergency'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class UpdateInvoiceDetailLog extends Vue {
  @Prop() private requestBody: any

  private get emergencyName(): string {
    if (this.requestedJob && this.engineerVisitDetail) {
      const item = this.requestedJob.emergencies.find(
        (e: Emergency) => e.typeId === this.engineerVisitDetail!.forEmergencyTypeId
      )
      return item ? `${item.typeDescription} - ${item.detailDescription}` : ''
    }
    return ''
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get engineerVisitDetail(): EngineerVisitDetail | undefined {
    return this.requestedJob
      ? this.requestedJob.engineerVisitDetails.find((x) => x.id === this.requestBody.id)
      : undefined
  }

  private get engineerName(): string {
    return this.engineerVisitDetail
      ? `${this.engineerVisitDetail.engineerFirstName} ${this.engineerVisitDetail.engineerLastName}`
      : ''
  }
}
</script>
