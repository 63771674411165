import ComplaintModel from '@/models/claim/ComplaintModel'
import TrackerCountModel from '@/models/claim/TrackerCountModel'

export default class ComplaintTrackerModel {
  public complaints: ComplaintModel[]
  public openComplaint: TrackerCountModel
  public closeComplaint: TrackerCountModel
  public overdueComplaint: TrackerCountModel
  public paidMTD: TrackerCountModel
  public totalOpenComplaints: number
  public totalClosedComplaints: number
  public totalOverdueComplaints: number
  public totalPaidMTDComplaints: number

  public constructor() {
    this.complaints = []
    this.totalOpenComplaints = 0
    this.totalClosedComplaints = 0
    this.totalOverdueComplaints = 0
    this.totalPaidMTDComplaints = 0
  }
}
