import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { IComplaintState } from '../states/interfaces/complaint-state'
import ComplaintState from '../states/complaint-state'
import ComplaintSeverity from '@/models/complaints/complaint-severity'
import ComplaintController from '@/api/complaintController'

export const complaintState: IComplaintState = new ComplaintState()

export const getters: GetterTree<IComplaintState, any> = {
  complaintSeverities: (state) => state.complaintSeverities,
}

export const mutations: MutationTree<IComplaintState> = {
  setComplaintSeverities: (state, severities: ComplaintSeverity[]) => {
    state.complaintSeverities = severities
  },
}

export const actions: ActionTree<IComplaintState, any> = {
  async submitRetrieveComplaintSeverities(store) {
    if (store.state.complaintSeverities.length) {
      return
    }

    ComplaintController.GetComplaintSeverities().then((complaintSeverities) => {
      if (complaintSeverities) {
        const severities = Object.keys(complaintSeverities)
          .map(Number)
          .map((id) => new ComplaintSeverity(id, complaintSeverities[id]))
        store.commit('setComplaintSeverities', severities)
      }
    })
  },
}

export const complaintModule: Module<IComplaintState, any> = {
  state: complaintState,
  getters,
  mutations,
  actions,
  namespaced: true,
}
