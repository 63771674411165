<template>
  <div>
    <div class="mb-2"><h4>Engineer Runnig Late</h4></div>
    <div v-if="item.reasonForLate" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.reasonForLate }}</span>
    </div>
    <div v-if="getFormattedDate" class="mb-1">
      <label>Reach at: </label>
      <span>{{ getFormattedDate(item.newEtaTo) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddEngineerRunningLateRequest from '@/models/requests/AddEngineerRunningLateRequest'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class AddEngineerRunningLateRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddEngineerRunningLateRequest {
    return Object.assign(new AddEngineerRunningLateRequest(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
