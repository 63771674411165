<template>
  <div v-if="ready" class="twilio-dashboard">
    <v-container fluid grid-list-md pa-0>
      <v-layout wrap calls-info>
        <DashboardTopTile
          v-for="key in Object.keys(topTiles)"
          :key="key"
          v-bind="topTiles[key]"
          dashboard-tile-view="Twilio"
        />
      </v-layout>
      <v-layout wrap class="system-info syetsm-dashboard">
        <DashboardPanel v-for="key in Object.keys(panels)" :key="key" v-bind="panels[key]" flex-class="xs12 md6 lg3" />
      </v-layout>
      <v-layout wrap class="team-info mt-3">
        <DashboardTeam v-for="key in Object.keys(teams)" :key="key" :team="teams[key]" />
      </v-layout>
    </v-container>
  </div>
  <div v-else>
    <v-progress-circular
      class="loading-spinner"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Team } from '@/components/dashboard/team'
import DashboardController, { DashboardInitialData } from '@/api/dashboardController'
import { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import Dashboard from '@/views/Dashboard.vue'
import storeGetters from '@/storeGetters'
import { WorkerWithAdditions } from '@/components/dashboard/worker'

export default class TwilioDashboard extends Dashboard {
  public workers: WorkerWithAdditions[] = storeGetters.getWorkers()

  public async created() {
    this.childPage = 'SupervisorDashboard'
  }

  public get teams(): { [key: string]: Team } {
    const value: { [key: string]: Team } = {}
    for (const worker of this.workers) {
      const teamName = worker.attributes.teamName
      let team: Team = value[teamName]

      if (team == null) {
        team = {
          title: teamName,
          workers: [],
        }
        value[teamName] = team
      }

      team.workers.push(worker)
    }

    return value
  }
}
</script>

<style>
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.calls-info h2 {
  font-size: 38px;
  display: inline-block;
  vertical-align: top;
}
.system-info h2 {
  font-weight: normal;
  font-size: 24px;
}
.sys-call-info {
  display: flex;
  position: relative;
  align-items: center;
}
.sys-call-info .v-badge {
  position: ABSOLUTE;
  top: 2px;
  right: -6px;
  background-color: #f44336;
  height: 22px;
  width: 22px;
  text-align: CENTER;
  border-radius: 50%;
  z-index: 1;
  font-size: 12px;
  padding: 2px 0px;
}
.sys-call-info > label {
  flex: 2;
}
.sys-call-info > .sys-call-content {
  flex: 6;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sys-call-info {
  min-height: 57px;
}
.sys-call-content .num,
.sys-call-content .time {
  font-size: 30px;
  display: inline-block;
  height: 100%;
  line-height: 2;
  padding: 0px 5px;
}

@media only screen and (max-width: 1600px) {
  .sys-call-content .num,
  .sys-call-content .time {
    font-size: 20px !important;
    padding: 0px 3px !important;
  }
  .sys-call-content .num >>> .v-icon,
  .sys-call-content .time >>> .v-icon {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1200px) {
  .calls-info h2 {
    font-size: 24px;
  }
}
</style>
