import { AxiosError, AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import PaymentError from '@/models/PaymentError'
import StripeChargeDTO from '@/api/models/assistedPay/StripeChargeDTO'

export default class TokenizedPaymentController {
  public static async Pay(
    paymentToken: string,
    amountInPence: number,
    description: string,
    receiptEmail: string,
    jobId: string,
    postcode: string,
    cardholderName: string,
    cardBrand: string,
    paymentType: string
  ): Promise<StripeChargeDTO> {
    try {
      const model = {
        paymentToken,
        amountInPence,
        description,
        receiptEmail,
        jobId,
        postcode,
        cardholderName,
        cardBrand,
        paymentType,
      }

      const res: AxiosResponse = await CallCentreApi.post<StripeChargeDTO>('TokenizedPayment/Pay', model)

      return res.data
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response && err.response.data && err.response.data.ExceptionMessage) {
          throw new PaymentError(err.response.data.ExceptionMessage)
        }
      }

      throw err
    }
  }
}
