<template>
  <v-card v-if="getCustomerAppointedTradespeople" class="pt-0 px-2 pb-2">
    <v-card-title>
      <v-layout>
        <v-flex xs12>
          <h3 class="heading">
            <span class="teal--text">Customer Appointed Tradespeople</span>
            <span v-if="getCustomerAppointedTradespeople.invoiceImageUrl" class="teal--text">(Invoice)</span>
          </h3>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-layout wrap>
        <v-flex v-if="!getCustomerAppointedTradespeople.invoiceImageUrl" xs12>
          {{ getDisplayText }}
        </v-flex>
        <v-flex v-else xs12>
          <!-- bind invoice uploaded by customer -->
          <PictureUpload
            ref="pictureUploadRef"
            :job-id="jobId"
            :picture-list="getCATInvoiceUrls"
            :show-claim-thumb="false"
          ></PictureUpload>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import CustomerAppointedTradespeople from '@/models/claim/CustomerAppointedTradespeople'
import PictureUpload from '@/components/timeline/PictureUpload.vue'
import { ContactPreferenceEnum } from '@/common/enums'

@Component({
  components: { PictureUpload },
})
export default class CustomerAppointedTradespeoplePreview extends TimeLineItemBase {
  private get getCustomerAppointedTradespeople(): CustomerAppointedTradespeople | null {
    return storeGetters.getCustomerAppointedTradespeople(this.jobId, this.itemId)
  }

  private get getCATInvoiceUrls(): any[] {
    // get CAT Invoice Urls
    const urls: any[] = []
    urls.push({
      fileURL: this.getCustomerAppointedTradespeople!.invoiceImageUrl,
    })
    return urls
  }

  private get getDisplayText() {
    if (this.job && this.job.contactPreference === ContactPreferenceEnum[ContactPreferenceEnum.Call]) {
      return "Customer's contact preference is call. You need to manually uplaod the invoice receipt."
    } else if (this.getCustomerAppointedTradespeople && this.getCustomerAppointedTradespeople.invoiceUrlLinkExpired) {
      return 'No invoice has been received from the Customer. Job has been auto closed'
    } else {
      return 'A link has been sent to the customer to allow them to add their Appointed Trades Invoice'
    }
  }
}
</script>
