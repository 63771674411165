import { Moment } from 'moment'

export default class CancelAssignedJobContractorModel {
  public id
  public jobId
  public status
  public reasonType
  public reasonDescription
  public createdAt: Moment
  public isEmergencyCompleted: boolean
}
