<template>
  <div>
    <div class="mb-2"><h4>Assigned Job to Engineer</h4></div>
    <div v-if="engineerName" class="mb-1">
      <label>Engineer: </label>
      <span>{{ engineerName }}</span>
    </div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ item.status }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateContractorAppointedDetail from '@/models/requests/UpdateContractorAppointedDetail'

@Component
export default class UpdateContractorAppointedDetailLog extends Vue {
  @Prop() public requestBody: any

  private get item(): UpdateContractorAppointedDetail {
    return Object.assign(new UpdateContractorAppointedDetail(), this.requestBody)
  }

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.item.engineerId)
  }
}
</script>

<style scoped></style>
