import moment from 'moment'

export default class AcceptProvisionedUSVisit {
  public jobId: string
  public engineerId: string
  public contractorAppointedDetailId: string
  public etaFrom: moment.Moment | null
  public etaTo: moment.Moment | null
  public hasDelay: boolean
  public delayCode: string | null
  public repairEstimateIds: string[]

  public constructor() {
    this.jobId = ''
    this.engineerId = ''
    this.contractorAppointedDetailId = ''
    this.etaFrom = null
    this.etaTo = null
    this.hasDelay = false
    this.delayCode = null
  }
}
