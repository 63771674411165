import AddEmergency from '@/models/requests/AddEmergency'
import { ContactPreference } from '@/models/types'

export default class AddHEJobWithEmergencies {
  public clientTitle: string
  public clientForename: string
  public clientLastName: string
  public secondaryClientTitle: string | null
  public secondaryClientForename: string | null
  public secondaryClientLastName: string | null
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postCode: string
  public email: string
  public mobilePhone: string
  public contactPreference: ContactPreference
  public contactPreferenceId: number | null
  public policyNumber: string
  public customerRef: string
  public policyScheme: string
  public insurer: string
  public insuranceValue: number
  public policyScheduleId: number
  public emergencies?: AddEmergency[]
  public FNOLCallSid: string
  public nomineeClientTitle: string | null
  public nomineeClientForename: string | null
  public nomineeClientLastName: string | null
  public relation: string | null
  public nomineeMobilePhone: string | null
  public nomineeEmail: string | null
  public nomineeContactPreference: ContactPreference
  public nomineeContactPreferenceId: number | null
  public isStarredPolicy: boolean
  public dasPolicyHolderUNID: string
  public reasonForChoosingCallOption: string
  public nomineeReasonForChoosingCallOption: string
  public policyNumberNotPresentReason: string
  public reasonForCreatingDuplicateJob: string

  public constructor() {
    this.clientTitle = ''
    this.clientForename = ''
    this.clientLastName = ''
    this.policyNumber = ''
    this.email = ''
    this.mobilePhone = ''
    this.emergencies = []
    this.contactPreference = null
    this.contactPreferenceId = null
    this.FNOLCallSid = ''
    this.nomineeEmail = ''
    this.nomineeMobilePhone = ''
    this.nomineeContactPreference = null
    this.nomineeContactPreferenceId = null
    this.isStarredPolicy = false
    this.addressLine1 = ''
    this.addressLine2 = ''
    this.addressLine3 = ''
    this.addressLine4 = ''
    this.postCode = ''
    this.dasPolicyHolderUNID = ''
    this.reasonForChoosingCallOption = ''
    this.nomineeReasonForChoosingCallOption = ''
    this.policyNumberNotPresentReason = ''
    this.reasonForCreatingDuplicateJob = ''
  }
}
