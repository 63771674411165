export default class CommunicationPreferences {
  contractorId: string
  blindCopyEmailToEngineers: boolean
  engineers: {
    engineerId: string
    autodeployByEmail: boolean
    autodeployBySms: boolean
    autodeployByEngineerApp: boolean
    autodeployByWhatsApp: boolean
  }[]
}
