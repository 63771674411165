<template>
  <div>
    <v-layout v-if="getCustomerContactPreferenceModal && getCustomerContactPreferenceModal != undefined" wrap>
      <v-flex xs12>
        <ContactDetails
          ref="contactDetails"
          :contact-preference-id="getCustomerContactPreferenceModal.contactPreferenceId"
          :mobile-phone="getCustomerContactPreferenceModal.mobilePhone"
          :email="getCustomerContactPreferenceModal.email"
          :is-nominee="false"
          preferred-mode-title="Choose preferred mode of contact"
          :reason-for-call-option="getCustomerContactPreferenceModal.reasonForChoosingCallOption"
          @updateContactPreference="updateContactPreference"
        ></ContactDetails>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap>
          <div class="nominee-content">
            <v-flex xs12 text-xs-right>
              <v-btn
                flat
                color="primary"
                small
                class="mr-0 ml-0"
                :class="
                  getCustomerContactPreferenceModal.contactPreferenceId === contactPreference.Call
                    ? 'call-nominee-btn'
                    : 'nominee-btn'
                "
                @click="addNominee"
              >
                <span :class="hasNominee ? 'hide' : 'show'">
                  <v-icon small>add</v-icon>
                  Add nominee
                </span>
                <span :class="hasNominee ? 'show' : 'hide'">
                  <v-icon small>close</v-icon>
                  Remove nominee
                </span>
              </v-btn>
            </v-flex>
            <v-flex
              v-if="hasNominee"
              xs12
              :class="getCustomerContactPreferenceModal.contactPreferenceId === contactPreference.Call ? 'mt-4' : ''"
            >
              <v-layout wrap class="grey pa-3 lighten-4 elevation-1 nomineeDiv">
                <v-flex xs3 pr-2 py-0>
                  <v-combobox
                    v-model.trim="getCustomerContactPreferenceModal.nomineeClientTitle"
                    v-validate="'required'"
                    label="Title"
                    maxlength="20"
                    return-object
                    :items="clientTitleList"
                    required
                    name="Title"
                    :data-vv-scope="contactPreferenceScope"
                    data-vv-name="Title"
                    :error-messages="errors.collect('Title')"
                    class="required"
                  ></v-combobox>
                </v-flex>
                <v-flex xs5 px-2 py-0>
                  <v-text-field
                    v-model.trim="nomineeClientForename"
                    v-validate="'required'"
                    label="First Name"
                    name="FirstName"
                    maxlength="50"
                    :data-vv-scope="contactPreferenceScope"
                    class="required"
                    required
                    data-vv-name="First Name"
                    :error-messages="errors.collect('First Name')"
                    @blur="updateNomineeDetails"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4 py-0 pl-2>
                  <v-text-field
                    v-model.trim="nomineeClientLastName"
                    v-validate="'required'"
                    label="Last Name"
                    name="LastName"
                    maxlength="50"
                    :data-vv-scope="contactPreferenceScope"
                    class="required"
                    required
                    data-vv-name="Last Name"
                    :error-messages="errors.collect('Last Name')"
                    @blur="updateNomineeDetails"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 py-0>
                  <v-autocomplete
                    v-model="getCustomerContactPreferenceModal.relation"
                    v-validate="'required'"
                    label="Relation"
                    maxlength="20"
                    :items="isOnline ? nomineeRelationList : getNomineeRelationOffline"
                    item-value="id"
                    item-text="description"
                    :data-vv-scope="contactPreferenceScope"
                    data-vv-name="Relation"
                    :error-messages="errors.collect('Relation')"
                    :loading="isLoadingRelations"
                    @change="onChangeNomineeRelation"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-tile-content>
                        <v-list-tile-title
                          class="nomineeRelation"
                          :class="data.item.description === 'Configure' ? 'bold-select' : ''"
                        >
                          {{ data.item.description }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <ContactDetails
                    ref="nomineeContactDetails"
                    :contact-preference-id.sync="getCustomerContactPreferenceModal.nomineeContactPreferenceId"
                    :mobile-phone.sync="getCustomerContactPreferenceModal.nomineeMobilePhone"
                    :email.sync="getCustomerContactPreferenceModal.nomineeEmail"
                    :is-nominee="true"
                    preferred-mode-title="Choose preferred mode of nominee contact"
                    :reason-for-call-option="getCustomerContactPreferenceModal.nomineeReasonForChoosingCallOption"
                    @updateContactPreference="updateContactPreference"
                  ></ContactDetails>
                </v-flex>
              </v-layout>
            </v-flex>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureDropdown
        ref="configureDropdown"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import { ContactPreferenceEnum, RecordType } from '@/common/enums'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import { ContactPreference } from '@/models/types'
import Job from '@/models/Job'
import store from '@/store'
import storeGetters from '@/storeGetters'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'
import Shared from '@/common/shared'
import ContactDetails from '@/components/ContactDetails.vue'
import storeMutations from '@/storeMutations'

@Component({
  name: 'ContactPreferenceDetails',
  components: {
    PhoneNumber,
    ContactDetails,
    ConfigureDropdown,
  },
})
export default class ContactPreferenceDetails extends Vue {
  @Prop()
  private customerContactPreferenceModal: UpdateCustomerContactPreferenceModal

  private contactPreference = ContactPreferenceEnum
  private hasNominee = false
  private clientTitleList: string[] = ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms']
  private nomineeRelationList: MasterRecord[] = []
  private conPreId: number | null
  private contactPreferenceScope = 'contactPreferenceScope'
  private nomineeClientForename: string | null = null
  private nomineeClientLastName: string | null = null
  private configureDropdownType = ''
  private isLoadingRelations = false
  private configureDropdownDialog = false

  public async validateContactPreferenceStep(): Promise<boolean> {
    const self = this
    if (!self.customerContactPreferenceModal) {
      return false
    }
    let contactSMSValidation = true
    let contactCallValidation = true
    let nomineeContactSMSValidation = true
    let nomineeContactCallValidation = true
    let contactDetailValidation = true
    let nomineeContactDetailValidation = true
    let result = true

    result = await self.$validator.validateAll(this.contactPreferenceScope)

    const contactDetailSubs = this.$refs.contactDetails as ContactDetails
    contactDetailValidation = await contactDetailSubs.$validator.validateAll()
    // validate the contactPreference SMS
    if (self.customerContactPreferenceModal.contactPreferenceId === self.contactPreference.SMS) {
      const contactSMSValidationSub: any = contactDetailSubs.$refs.contactPreferenceSMS as PhoneNumber
      contactSMSValidation = (await contactSMSValidationSub.$validator.validateAll()) && contactSMSValidationSub.isValid
    }
    // validate the contactPreference Call
    if (self.customerContactPreferenceModal.contactPreferenceId === self.contactPreference.Call) {
      const contactCallValidationSub: any = contactDetailSubs.$refs.contactPreferenceCall as PhoneNumber
      contactCallValidation =
        (await contactCallValidationSub.$validator.validateAll()) && contactCallValidationSub.isValid
    }
    // validate the nominee
    if (this.hasNominee) {
      const nomineeContactDetailSubs = this.$refs.nomineeContactDetails as ContactDetails
      nomineeContactDetailValidation = await nomineeContactDetailSubs.$validator.validateAll()

      // validate the nominee contactPreference SMS
      if (self.customerContactPreferenceModal.nomineeContactPreferenceId === self.contactPreference.SMS) {
        const nomineeContactSMSValidationSub: any = nomineeContactDetailSubs.$refs.contactPreferenceSMS as PhoneNumber
        nomineeContactSMSValidation =
          (await nomineeContactSMSValidationSub.$validator.validateAll()) && nomineeContactSMSValidationSub.isValid
      }
      // validate the nominee contactPreference Call
      if (self.customerContactPreferenceModal.nomineeContactPreferenceId === self.contactPreference.Call) {
        const nomineeContactCallValidationSub: any = nomineeContactDetailSubs.$refs.contactPreferenceCall as PhoneNumber
        nomineeContactCallValidation =
          (await nomineeContactCallValidationSub.$validator.validateAll()) && nomineeContactCallValidationSub.isValid
      }
    }

    if (
      !result ||
      !contactDetailValidation ||
      !nomineeContactDetailValidation ||
      !contactSMSValidation ||
      !contactCallValidation ||
      !nomineeContactSMSValidation ||
      !nomineeContactCallValidation
    ) {
      Shared.setValidationFocus(self.$el as HTMLElement)
      return (result =
        result &&
        contactDetailValidation &&
        nomineeContactDetailValidation &&
        contactSMSValidation &&
        contactCallValidation &&
        nomineeContactSMSValidation &&
        nomineeContactCallValidation)
    }
    return true
  }

  public setDefaultValues() {
    this.hasNominee = false
    const contactDetailSubs = this.$refs.contactDetails as ContactDetails
    if (contactDetailSubs !== undefined) {
      contactDetailSubs.localMobilePhone = ''
      contactDetailSubs.localEmail = ''
      contactDetailSubs.localContactPreferenceId = null
      contactDetailSubs.localNote = ''
      setTimeout(() => {
        contactDetailSubs.$validator.errors.items = []
      }, 0)
    }
    const nomineeContactDetailSubs = this.$refs.nomineeContactDetails as ContactDetails
    if (nomineeContactDetailSubs !== undefined) {
      nomineeContactDetailSubs.localMobilePhone = ''
      nomineeContactDetailSubs.localEmail = ''
      nomineeContactDetailSubs.localNote = ''
      nomineeContactDetailSubs.localContactPreferenceId = null
      setTimeout(() => {
        nomineeContactDetailSubs.$validator.errors.items = []
      }, 0)
    }
  }

  @Watch('hasNominee')
  private isHasNominee() {
    this.removeNomineeDetail()
  }

  private removeNomineeDetail() {
    if (!this.hasNominee && this.getCustomerContactPreferenceModal) {
      this.getCustomerContactPreferenceModal.nomineeClientTitle = null
      this.getCustomerContactPreferenceModal.nomineeClientForename = null
      this.nomineeClientForename = null
      this.getCustomerContactPreferenceModal.nomineeClientLastName = null
      this.nomineeClientLastName = null
      this.getCustomerContactPreferenceModal.relation = null
      this.getCustomerContactPreferenceModal.nomineeMobilePhone = null
      this.getCustomerContactPreferenceModal.nomineeEmail = null
      this.getCustomerContactPreferenceModal.nomineeContactPreference = null
      this.getCustomerContactPreferenceModal.nomineeContactPreferenceId = null
    }
  }

  private get getCustomerContactPreferenceModal() {
    if (this.customerContactPreferenceModal) {
      this.nomineeClientForename = this.customerContactPreferenceModal.nomineeClientForename
      this.nomineeClientLastName = this.customerContactPreferenceModal.nomineeClientLastName
      return this.customerContactPreferenceModal
    }
    return undefined
  }

  // Check if userRole is Administrartor.
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private get getNomineeRelations() {
    const nomineeRelations: MasterRecord[] = storeGetters
      .getNomineeRelations()
      .filter((e) => e.isDeleted === false || e.isDeleted)
    return nomineeRelations
  }

  private mounted() {
    const contactPreferenceSMS = this.contactPreference[this.contactPreference.SMS] as ContactPreference
    const contactPreferenceEmail = this.contactPreference[this.contactPreference.Email] as ContactPreference
    const contactPreferenceCall = this.contactPreference[this.contactPreference.Call] as ContactPreference

    // set contactPreferenceId
    if (this.customerContactPreferenceModal.contactPreference === contactPreferenceSMS) {
      this.customerContactPreferenceModal.contactPreferenceId = this.contactPreference.SMS
    } else if (this.customerContactPreferenceModal.contactPreference === contactPreferenceEmail) {
      this.customerContactPreferenceModal.contactPreferenceId = this.contactPreference.Email
    } else if (this.customerContactPreferenceModal.contactPreference === contactPreferenceCall) {
      this.customerContactPreferenceModal.contactPreferenceId = this.contactPreference.Call
    }

    // set nomineeContactPreferenceId
    if (this.customerContactPreferenceModal.nomineeContactPreference === contactPreferenceSMS) {
      this.customerContactPreferenceModal.nomineeContactPreferenceId = this.contactPreference.SMS
    } else if (this.customerContactPreferenceModal.nomineeContactPreference === contactPreferenceEmail) {
      this.customerContactPreferenceModal.nomineeContactPreferenceId = this.contactPreference.Email
    } else if (this.customerContactPreferenceModal.nomineeContactPreference === contactPreferenceCall) {
      this.customerContactPreferenceModal.nomineeContactPreferenceId = this.contactPreference.Call
    }

    this.hasNominee = false
    if (
      this.customerContactPreferenceModal &&
      (this.customerContactPreferenceModal.nomineeContactPreferenceId ||
        this.customerContactPreferenceModal.nomineeMobilePhone ||
        this.customerContactPreferenceModal.nomineeEmail)
    ) {
      this.hasNominee = true
      if (this.nomineeRelationList.filter((x) => x.description !== 'Configure').length === 0) {
        this.SetNomineeRelations()
      }
    }
  }

  private addNominee() {
    this.hasNominee = !this.hasNominee
    if (this.hasNominee && this.nomineeRelationList.filter((x) => x.description !== 'Configure').length === 0) {
      this.SetNomineeRelations()
    }
  }

  private updateContactPreference(
    contactPreferenceId: number,
    email: string,
    mobilePhone: string,
    isForNominee: boolean,
    reasonForChoosingCallOption: string
  ) {
    if (this.getCustomerContactPreferenceModal) {
      this.removeNomineeDetail()

      if (isForNominee) {
        if (contactPreferenceId) {
          this.getCustomerContactPreferenceModal.nomineeContactPreferenceId = contactPreferenceId
          this.getCustomerContactPreferenceModal.nomineeContactPreference = this.contactPreference[
            contactPreferenceId
          ] as ContactPreference
        }
        this.getCustomerContactPreferenceModal.nomineeMobilePhone = mobilePhone
        this.getCustomerContactPreferenceModal.nomineeEmail = email
        this.getCustomerContactPreferenceModal.nomineeReasonForChoosingCallOption = reasonForChoosingCallOption
      } else {
        if (contactPreferenceId) {
          this.getCustomerContactPreferenceModal.contactPreferenceId = contactPreferenceId
          this.getCustomerContactPreferenceModal.contactPreference = this.contactPreference[
            contactPreferenceId
          ] as ContactPreference
        }
        this.getCustomerContactPreferenceModal.mobilePhone = mobilePhone
        this.getCustomerContactPreferenceModal.email = email
        this.getCustomerContactPreferenceModal.reasonForChoosingCallOption = reasonForChoosingCallOption
      }

      this.getCustomerContactPreferenceModal.nomineeClientForename = this.nomineeClientForename
      this.getCustomerContactPreferenceModal.nomineeClientLastName = this.nomineeClientLastName
      this.$emit('updateContactPreference', this.getCustomerContactPreferenceModal)
    }
  }

  private updateNomineeDetails() {
    if (this.getCustomerContactPreferenceModal) {
      this.getCustomerContactPreferenceModal.nomineeClientForename = this.nomineeClientForename
      this.getCustomerContactPreferenceModal.nomineeClientLastName = this.nomineeClientLastName
      this.$emit('updateContactPreference', this.getCustomerContactPreferenceModal)
    }
  }

  private onChangeNomineeRelation(selectedItems) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.NomineeRelation]
      this.configureDropdownDialog = true
    }
  }

  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    const deletedRecord: MasterRecord | undefined = this.nomineeRelationList.find((e) => e.isDeleted === true)
    this.nomineeRelationList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.nomineeRelationList.push(deletedRecord)
    }
    const recordType: string = RecordType[RecordType.NomineeRelation]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        storeMutations.setNomineeRelations(res)
        this.SetConfigure()
      }
    })
    if (this.getCustomerContactPreferenceModal) {
      this.getCustomerContactPreferenceModal.relation = ''
    }
  }

  // Set list of nominee relations.
  private SetNomineeRelations(): void {
    if (this.getNomineeRelations.length === 0) {
      const recordType: string = RecordType[RecordType.NomineeRelation]
      MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
        if (res) {
          storeMutations.setNomineeRelations(res)
          this.SetConfigure()
        }
      })
    } else {
      this.SetConfigure()
    }
  }

  private SetConfigure(): void {
    this.nomineeRelationList = this.getNomineeRelations.slice(0)
    if (this.isUserRoleAdministrator) {
      const relation: any = {}
      relation.description = 'Configure'
      this.nomineeRelationList.push(relation)
      this.isLoadingRelations = false
    }
  }

  private get getNomineeRelationOffline() {
    return this.nomineeRelationList.filter((x) => x.description !== 'Configure')
  }
}
</script>

<style type="text/css" scoped>
.radio-inline-custom {
  position: relative;
  padding-left: 33px;
}
.radio-inline-custom >>> .radio {
  position: absolute;
  left: 0px;
  top: 5px;
}
.radio-inline-custom >>> label {
  width: auto;
}
.radio-group-custom >>> .radio-group .error--text .input-group__messages {
  color: #ff5252 !important;
}
.radio-group-custom >>> .radio-group .input-group--error .input-group__details:before {
  background-color: #ff5252 !important;
}
.nominee-content {
  position: relative;
  min-height: 35px;
  width: 100%;
  padding-top: 10px;
}
.nominee-content .nominee-btn {
  position: absolute;
  top: -36px;
  right: 0px;
}
.nominee-content .call-nominee-btn {
  position: absolute;
  top: -15px;
  right: 0px;
}
.bold-select {
  font-weight: 600;
}
</style>
