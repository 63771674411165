<template>
  <div>
    <v-container fluid pa-0 grid-list-lg>
      <v-layout class="wrap">
        <v-flex xs3 py-0>
          <v-combobox
            v-model="clientTitle"
            v-validate="{ rules: { required: !isSIJob } }"
            label="Title"
            maxlength="20"
            return-object
            :items="clientTitleList"
            :required="!isSIJob"
            data-vv-name="Title"
            name="Title"
            :error-messages="errors.collect('Title')"
            :class="!isSIJob ? 'required' : ''"
            :disabled="isFieldEditable(passedClientTitle, 'clientTitle') || disabled"
            :search-input.sync="clientTitle"
            class="client-title"
            @blur="addressUpdated()"
          />
        </v-flex>
        <v-flex xs5 py-0>
          <v-text-field
            ref="forenameField"
            v-model.trim="firstName"
            v-validate="'required'"
            label="First Name"
            maxlength="50"
            required
            class="required"
            data-vv-name="First Name"
            name="FirstName"
            :error-messages="errors.collect('First Name')"
            :disabled="isFieldEditable(firstName, 'clientForename') || disabled"
            @blur="addressUpdated()"
          ></v-text-field>
        </v-flex>
        <v-flex xs4 py-0>
          <v-text-field
            v-model.trim="lastName"
            v-validate="'required'"
            label="Last Name"
            maxlength="50"
            required
            class="required"
            data-vv-name="Last Name"
            name="LastName"
            :error-messages="errors.collect('Last Name')"
            :disabled="isFieldEditable(passedLastName, 'clientLastName') || disabled"
            @blur="addressUpdated()"
          ></v-text-field>
        </v-flex>
        <template v-if="showSecondaryName">
          <v-flex xs3 py-0>
            <v-combobox
              v-model.trim="secondaryClientTitle"
              v-validate="'required'"
              label="Secondary Title"
              maxlength="20"
              return-object
              :items="clientTitleList"
              name="SecondaryTitle"
              required
              class="required"
              data-vv-name="Secondary Title"
              :error-messages="errors.collect('Secondary Title')"
              :disabled="isFieldEditable(passedSecondaryClientTitle, 'secondaryClientTitle') || disabled"
              @blur="addressUpdated()"
            />
          </v-flex>
          <v-flex xs5 py-0>
            <v-text-field
              ref="forenameField"
              v-model.trim="secondaryFirstName"
              v-validate="'required'"
              label="Secondary First Name"
              maxlength="50"
              name="SecondaryFirstName"
              class="required"
              data-vv-name="Secondary First Name"
              :error-messages="errors.collect('Secondary First Name')"
              :disabled="isFieldEditable(passedSecondaryFirstName, 'secondaryClientForename') || disabled"
              @blur="addressUpdated()"
            ></v-text-field>
          </v-flex>
          <v-flex xs4 py-0>
            <v-text-field
              v-model.trim="secondaryLastName"
              v-validate="'required'"
              label="Secondary Last Name"
              maxlength="50"
              name="SecondaryLastName"
              class="required"
              data-vv-name="Secondary Last Name"
              :error-messages="errors.collect('Secondary Last Name')"
              :disabled="isFieldEditable(passedSecondaryLastName, 'secondaryClientLastName') || disabled"
              @blur="addressUpdated()"
            ></v-text-field>
          </v-flex>
        </template>
        <v-flex xs12>
          <v-text-field
            v-if="isDisplayPolicyNumber"
            ref="policyNumberField"
            v-model.trim="policyNumber"
            v-validate="{ rules: { required: isPolicyNumberRequired } }"
            label="Policy Number"
            maxlength="50"
            class="displayPolicyText"
            :disabled="disabledPolicyNumberField"
            :required="isPolicyNumberRequired"
            :class="isPolicyNumberRequired ? 'required' : ''"
            data-vv-name="Policy Number"
            :error-messages="errors.collect('Policy Number')"
            @blur="addressUpdated()"
          ></v-text-field>
        </v-flex>
        <v-flex v-if="isDisplayPolicyNumber && isHEJob && isAssumedCoverPolicy && !policyNumber" xs12>
          <v-textarea
            v-model.trim="policyNumberNotPresentReason"
            v-validate="'required'"
            label="Policy Number Not Present Reason"
            required
            class="required policy-not-present-reason"
            data-vv-name="Reason"
            :error-messages="errors.collect('Reason')"
            rows="3"
            @blur="addressUpdated()"
          ></v-textarea>
        </v-flex>
        <v-flex v-if="isOnline" xs12 text-xs-right pa-0 class="change-address-btn">
          <v-btn
            flat
            color="primary"
            class="my-0 pa-0"
            :disabled="isFieldEditable(passedAddressLine1, 'addressLine1') || disabled"
            @click="activeAddressSearch = activeAddressSearch == true ? false : true"
          >
            <span :class="activeAddressSearch ? 'hide' : ' show'">
              <v-icon small>place</v-icon>
              <span class="v-align">Pick Address</span>
            </span>
            <span :class="activeAddressSearch ? 'show' : ' hide'">
              <v-icon small>close</v-icon>
              <span class="v-align">Close</span>
            </span>
          </v-btn>
        </v-flex>
        <v-flex xs12 maping-alert :class="activeAddressSearch ? 'show' : ' hide'">
          <v-autocomplete
            ref="searchAddressField"
            v-model="selectedAddressSearch"
            tabindex="0"
            label="Search address"
            :loading="searchAddressRunning"
            :items="findAddress"
            browser-autocomplete="off"
            :search-input.sync="searchAddress"
            item-text="Description"
            item-value="item"
            autofocus
            return-object
            :filter="customAddressFilter"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ data.item.Description }}
                </v-list-tile-title>
              </v-list-tile-content>
            </template>
          </v-autocomplete>
        </v-flex>

        <v-flex xs6>
          <v-text-field
            v-model.trim="addressLine1"
            v-validate="'required'"
            label="Address Line1"
            maxlength="100"
            required
            class="required"
            name="AddressLine1"
            data-vv-name="Address Line1"
            :error-messages="errors.collect('Address Line1')"
            :disabled="isFieldEditable(passedAddressLine1, 'addressLine1') || disabled"
            @blur="addressUpdated()"
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            v-model.trim="addressLine2"
            label="Address Line2"
            maxlength="100"
            :disabled="isFieldEditable(passedAddressLine2, 'addressLine2') || disabled"
            @blur="addressUpdated()"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model.trim="addressLine3"
            label="Address Line3"
            maxlength="100"
            :disabled="isFieldEditable(passedAddressLine3, 'addressLine3') || disabled"
            @blur="addressUpdated()"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model.trim="addressLine4"
            label="Address Line4"
            maxlength="100"
            :disabled="isFieldEditable(passedAddressLine4, 'addressLine4') || disabled"
            @blur="addressUpdated()"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model.trim="postCode"
            v-validate="'required'"
            label="Postcode"
            class="required"
            required
            maxlength="20"
            data-vv-name="Postcode"
            name="Postcode"
            :error-messages="errors.collect('Postcode')"
            :disabled="isFieldEditable(passedPostCode, 'postCode') || disabled"
            @blur="addressUpdated()"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PolicyHolderController from '@/api/policyHolderController'
import storeMutations from '@/storeMutations'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'

@Component({})
export default class CustomerAddress extends Vue {
  @Prop() private passedClientTitle: string
  @Prop() private passedFirstName: string
  @Prop() private passedLastName: string
  @Prop() private passedSecondaryClientTitle: string
  @Prop() private passedSecondaryFirstName: string
  @Prop() private passedSecondaryLastName: string
  @Prop() private passedPolicyNumber: string
  @Prop() private passedAddressLine1: string
  @Prop() private passedAddressLine2: string
  @Prop() private passedAddressLine3: string
  @Prop() private passedAddressLine4: string
  @Prop() private passedPostCode: string
  @Prop() private isDisplayPolicyNumber: boolean
  @Prop() private customerPrefilledDetails: any
  @Prop() private disabled: boolean
  @Prop({ default: false }) private isSIJob: boolean
  @Prop() private passedPolicyNumberNotPresentReason: string
  @Prop({ default: false }) private isHEJob: string
  @Prop({ default: false }) private isAssumedCoverPolicy: string

  private selectedAddressSearch: any = ''
  private clientTitle = ''
  private firstName = ''
  private lastName = ''
  private secondaryClientTitle = ''
  private secondaryFirstName = ''
  private secondaryLastName = ''
  private policyNumber = ''
  private addressLine1 = ''
  private addressLine2 = ''
  private addressLine3 = ''
  private addressLine4 = ''
  private postCode = ''
  private activeAddressSearch = false
  private findAddress: any = []
  private searchAddress = ''
  private waitForMoreInputTimeoutHandle: number | null = null
  private searchAddressRunning = false
  private clientTitleList: string[] = Shared.titleList
  private policyHolderController!: PolicyHolderController
  private showSecondaryName = false
  private policyNumberNotPresentReason = ''

  public setDefaultValues() {
    this.searchAddress = ''
    this.activeAddressSearch = false
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
  }

  @Watch('passedClientTitle')
  private passedClientTitleUpdated(): void {
    if (this.passedClientTitle) {
      this.clientTitle = this.passedClientTitle.trim()
    } else {
      this.clientTitle = ''
    }
  }

  @Watch('passedFirstName')
  private passedFirstNameUpdated(): void {
    if (this.passedFirstName) {
      this.firstName = this.passedFirstName.trim()
    } else {
      this.firstName = ''
    }
  }

  @Watch('passedLastName')
  private passedLastNameUpdated(): void {
    if (this.passedLastName) {
      this.lastName = this.passedLastName.trim()
    } else {
      this.lastName = ''
    }
  }

  @Watch('passedSecondaryClientTitle')
  private passedSecondaryClientTitleUpdated(): void {
    if (this.passedSecondaryClientTitle) {
      this.showSecondaryName = true
      this.secondaryClientTitle = this.passedSecondaryClientTitle.trim()
    } else {
      this.secondaryClientTitle = ''
    }
  }

  @Watch('passedSecondaryFirstName')
  private passedSecondaryFirstNameUpdated(): void {
    if (this.passedSecondaryFirstName) {
      this.showSecondaryName = true
      this.secondaryFirstName = this.passedSecondaryFirstName.trim()
    } else {
      this.secondaryFirstName = ''
    }
  }

  @Watch('passedSecondaryLastName')
  private passedSecondaryLastNameUpdated(): void {
    if (this.passedSecondaryLastName) {
      this.showSecondaryName = true
      this.secondaryLastName = this.passedSecondaryLastName.trim()
    } else {
      this.secondaryLastName = ''
    }
  }

  @Watch('passedPolicyNumber')
  private passedPolicyNumberUpdated(): void {
    if (this.passedPolicyNumber) {
      this.policyNumber = this.passedPolicyNumber.trim()
    } else {
      this.policyNumber = ''
    }
    this.policyNumberNotPresentReason = ''
  }

  @Watch('passedAddressLine1')
  private passedAddressLine1Updated(): void {
    if (this.passedAddressLine1) {
      this.addressLine1 = this.passedAddressLine1.trim()
    } else {
      this.addressLine1 = ''
    }
  }

  private addressUpdated() {
    this.$emit('update:passedClientTitle', this.clientTitle ? this.clientTitle.trim() : '')
    this.$emit('update:passedFirstName', this.firstName)
    this.$emit('update:passedLastName', this.lastName)
    this.$emit('update:passedSecondaryClientTitle', this.secondaryClientTitle)
    this.$emit('update:passedSecondaryFirstName', this.secondaryFirstName)
    this.$emit('update:passedSecondaryLastName', this.secondaryLastName)
    this.$emit('update:passedPolicyNumber', this.policyNumber)
    this.$emit('update:passedAddressLine1', this.addressLine1)
    this.$emit('update:passedAddressLine2', this.addressLine2)
    this.$emit('update:passedAddressLine3', this.addressLine3)
    this.$emit('update:passedAddressLine4', this.addressLine4)
    this.$emit('update:passedPostCode', this.postCode)
    this.$emit('update:passedPolicyNumberNotPresentReason', this.policyNumberNotPresentReason)
  }

  @Watch('passedAddressLine2')
  private passedAddressLine2Updated(): void {
    if (this.passedAddressLine2) {
      this.addressLine2 = this.passedAddressLine2.trim()
    } else {
      this.addressLine2 = ''
    }
  }

  @Watch('passedAddressLine3')
  private passedAddressLine3Updated(): void {
    if (this.passedAddressLine3) {
      this.addressLine3 = this.passedAddressLine3.trim()
    } else {
      this.addressLine3 = ''
    }
  }

  @Watch('passedAddressLine4')
  private passedAddressLine4Updated(): void {
    if (this.passedAddressLine4) {
      this.addressLine4 = this.passedAddressLine4.trim()
    } else {
      this.addressLine4 = ''
    }
  }

  @Watch('passedPostCode')
  private passedPostCodeUpdated(): void {
    if (this.passedPostCode) {
      this.postCode = this.passedPostCode.trim()
    } else {
      this.postCode = ''
    }
  }

  private get getLoqateApiKey() {
    return storeGetters.getLoqateApiKey()
  }

  private mounted(): void {
    this.policyHolderController = new PolicyHolderController()
    this.showSecondaryName = false
    this.getLoqateApiKeyValue()
    this.clientTitle = this.passedClientTitle
    this.firstName = this.passedFirstName
    this.lastName = this.passedLastName
    this.secondaryClientTitle = this.passedSecondaryClientTitle
    this.secondaryFirstName = this.passedSecondaryFirstName
    this.secondaryLastName = this.passedSecondaryLastName
    this.policyNumber = this.passedPolicyNumber
    this.addressLine1 = this.passedAddressLine1
    this.addressLine2 = this.passedAddressLine2
    this.addressLine3 = this.passedAddressLine3
    this.addressLine4 = this.passedAddressLine4
    this.postCode = this.passedPostCode
    if (this.secondaryClientTitle || this.secondaryFirstName) {
      this.showSecondaryName = true
    }
    this.policyNumberNotPresentReason = this.passedPolicyNumberNotPresentReason
  }

  @Watch('searchAddress')
  private addressVerification(text: string, id: string, isReSearch: boolean) {
    const self = this
    const item = this.findAddress.find((i) => i.Description === this.searchAddress)
    if (!isReSearch && (!this.searchAddress || item)) {
      return
    }
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }
    this.waitForMoreInputTimeoutHandle = window.setTimeout(() => {
      if (this.getLoqateApiKey) {
        this.searchAddressRunning = true
        const reSearchId = isReSearch ? id : ''
        PolicyHolderController.FindAddressFromLoqateApi(text, this.getLoqateApiKey, reSearchId).then((res: any) => {
          if (res && res.data.Items && res.data.Items.length > 0) {
            this.findAddress = res.data.Items
            this.findAddress.forEach((ele) => {
              ele.Description =
                ele.Type === 'Address' ? ele.Text + ', ' + ele.Description : ele.Description + ', ' + ele.Text
            })
            this.searchAddressRunning = false
            if (isReSearch) {
              ;(self.$refs.searchAddressField as any).focus()
              ;(self.$refs.searchAddressField as any).isMenuActive = true
            }
          }
        })
      }
    }, 300)
  }

  @Watch('selectedAddressSearch')
  private selectedAddressSearchChange(item: any) {
    const self = this
    if (item && item.Type !== 'Address') {
      this.addressVerification(item.Text, item.Id, true)
      return
    }
    if (item && self.getLoqateApiKey) {
      PolicyHolderController.RetrieveAddressLoqateApi(item.Id, self.getLoqateApiKey).then((res: any) => {
        if (res && res.data.Items && res.data.Items.length > 0) {
          const address = res.data.Items[0]
          if (!address.Error) {
            this.addressLine1 =
              (address.SubBuilding + ' ' + address.BuildingName).trim() +
              ((address.SubBuilding || address.BuildingName) && (address.BuildingNumber || address.Street)
                ? ', '
                : '') +
              (address.BuildingNumber + ' ' + address.Street).trim()
            this.addressLine2 = address.District
            this.addressLine3 = address.City
            this.addressLine4 = address.AdminAreaName
            this.postCode = address.PostalCode
            this.addressUpdated()
            ;(self.$refs.searchAddressField as any).blur()
          }
        }
      })
    }
  }

  private customAddressFilter(item: any, queryText: string, itemText: string) {
    return true
  }

  private getLoqateApiKeyValue(): void {
    if (!this.getLoqateApiKey) {
      PolicyHolderController.GetLoqateApiKey().then((res: string) => {
        storeMutations.updateLoqateApiKey(res)
      })
    }
  }

  private isFieldEditable(value: string, variableName: string): boolean {
    // if the customer validates, then prevent user from changing any details
    return this.customerPrefilledDetails && this.isDisplayPolicyNumber
      ? value && Object.keys(this.customerPrefilledDetails).includes(variableName)
        ? true
        : false
      : false
  }

  private get isPolicyNumberRequired(): boolean {
    return this.isHEJob && !this.isAssumedCoverPolicy ? true : false
  }

  private get disabledPolicyNumberField(): boolean {
    return !this.policyNumber ? false : this.isFieldEditable(this.passedPolicyNumber, 'policyNumber') || this.disabled
  }
}
</script>

<style scoped>
.change-address-btn {
  position: relative;
  z-index: 2;
}
.change-address-btn >>> .v-btn {
  position: absolute;
  right: 0;
  top: -4px;
  font-size: 12px;
  margin: 0px;
  min-width: auto;
}
.change-address-btn >>> .v-btn .v-btn__content {
  padding: 0px;
  padding-right: 8px;
}
.v-align {
  position: relative;
  top: 2px;
}
</style>
