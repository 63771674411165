import Product from './Product'
import MonitoringProduct from './MonitoringProduct'
import BulkPit from './BulkPit'

export default class DehydratedJobPackage {
  public packageInfo:
    | {
        packageId: number
        productList: Array<Partial<Product>>
        name: string | undefined
        description: string | undefined
        price: number | undefined
      }
    | undefined
  public extraProducts: Array<Partial<Product>>
  public bulkPitProducts: Array<Partial<BulkPit>>
  public monitoringProducts: MonitoringProduct[]
  public extraProductNotes: string
  public jobNotes: string
  public totalPrice: number
}
