import { JobVisitStatus } from '@/common/enums'
import moment from 'moment'

export default class UpdateContractorAppointedDetail {
  public id: string | undefined
  public jobId: string | undefined
  public engineerId: string | null
  public status: string
  public engineerAssignByIM: boolean

  public constructor()
  public constructor(id: string, jobId: string)
  public constructor(id?: string, jobId?: string) {
    this.id = id
    this.jobId = jobId
    this.status = JobVisitStatus[JobVisitStatus.Accepted]
    this.engineerAssignByIM = false
  }
}
