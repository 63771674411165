<template>
  <div v-if="item">
    <div class="mb-2"><h4>Contractor Rejected Auto Deployed Job</h4></div>
    <div v-if="getCancelAssignedJobContractorDetail" class="mb-1">
      <label>Contractor: </label>
      <span>{{ getCancelAssignedJobContractorDetail.companyName }}</span>
    </div>
    <div v-if="contractorUnavailableReason" class="mb-1">
      <label>Reason: </label>
      <span>{{ contractorUnavailableReason }}</span>
    </div>
    <div v-if="item.reasonDescription" class="mb-1">
      <label>Description: </label>
      <span>{{ item.reasonDescription }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContractorRejectAutoDeploymentJob from '@/models/contractor/ContractorRejectAutoDeploymentJobModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import storeGetters from '@/storeGetters'
import MasterRecord from '@/models/MasterRecord'
@Component
export default class ContractorRejectAutoDeploymentJobLog extends Vue {
  @Prop() private requestBody: any

  private get item(): ContractorRejectAutoDeploymentJob {
    return Object.assign(new ContractorRejectAutoDeploymentJob(), this.requestBody)
  }
  private get getCancelAssignedJobContractorDetail(): ContractorAppointedModel {
    return storeGetters.getContractorAppointedDetailById(this.item.jobId, this.item.contractorAppointedId)
  }

  private get contractorUnavailableReasons(): MasterRecord[] {
    const masterRecords: MasterRecord[] = this.$store.getters['jobModule/masterRecords']
    return masterRecords.filter((x) => x.type === 'ContractorUnavailableReason')
  }

  private contractorUnavailableReason(reasonId: string) {
    const reason = this.contractorUnavailableReasons.find((c: MasterRecord) => c.id === reasonId)
    return reason ? reason.description : ''
  }
}
</script>
