<template>
  <div>
    <v-card>
      <v-card-title class="pb-0">
        <v-container fluid grid-list-lg pa-0>
          <h3>Reports</h3>
          <v-layout wrap class="mx-0 mt-3 mb-1 align-center px-3 grey lighten-4">
            <v-flex xs2 xl2 py-0>
              <v-select v-model="bordereauInvoiceFilter" :items="['Bordereau', 'Invoice']" return-object label="Type" />
            </v-flex>
            <v-flex xs2 xl2 py-0>
              <DateTimePicker
                ref="dateTimePickerFrom"
                v-model="searchFromDate"
                :date-time.sync="searchFromDate"
                :is-static-location="false"
                place-holder-text="From"
                :is-current-time="false"
                :is-validation-required="false"
                :show-present-time-icon="false"
                :max-date="selectableDateTime(searchToDate)"
                :hide-time-picker="true"
                :display-format="dateFormat()"
              />
            </v-flex>
            <v-flex xs2 xl2 py-0>
              <DateTimePicker
                ref="dateTimePickerFrom"
                v-model="searchToDate"
                :date-time.sync="searchToDate"
                :is-static-location="false"
                place-holder-text="To"
                :is-current-time="false"
                :is-validation-required="false"
                :show-present-time-icon="false"
                :min-date="selectableDateTime(searchFromDate)"
                :hide-time-picker="true"
                :display-format="dateFormat()"
              />
            </v-flex>
            <v-flex xs6 xl6 class="text-xs-right" py-0 pr-0>
              <v-btn color="primary" class="btn-search" :disabled="loading" @click="searchBordereau">
                <v-icon class="mr-2">filter_list</v-icon>
                Search
              </v-btn>
              <v-btn :disabled="loading" class="btn-clear" @click="clearFilter">
                <v-icon class="mr-2">close</v-icon>
                Clear
              </v-btn>
              <v-btn color="primary" class="mr-0 btn-export" :disabled="loading" @click="openExportToExcelDialog">
                <v-icon class="mr-2">vertical_align_bottom</v-icon>
                Export
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="bordereauHeaders"
          :items="bordereauInvoiceFilter === 'Bordereau' ? bordereauList : invoiceList"
          :loading="loading"
          :pagination.sync="paginationLocal"
          class="elevation-1 gridView report-table"
        >
          <template slot="items" slot-scope="props">
            <td>
              {{ bordereauInvoiceFilter === 'Bordereau' ? props.item.bordereauName : props.item.id }}
            </td>
            <td>{{ getFormatedDate(props.item.createdAt) }}</td>
            <td v-if="bordereauInvoiceFilter === 'Bordereau'" class="text-xs-center">
              {{ props.item.numberOfJobs }}
            </td>
            <td class="text-xs-center">
              <b :class="getStatusColor(props.item.status)">
                {{ getStatusName(props.item.status) }}
              </b>
            </td>
            <td class="text-xs-right">
              {{
                getFormatedCurrency(
                  bordereauInvoiceFilter === 'Bordereau' ? props.item.amount : props.item.totalCostIncVAT
                )
              }}
            </td>
            <td class="text-xs-right">
              {{ getFormatedCurrency(props.item.amountPaid) }}
            </td>
            <td class="text-xs-right">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    v-if="
                      (bordereauInvoiceFilter === 'Bordereau' && props.item.documentURL) ||
                      (bordereauInvoiceFilter === 'Invoice' && props.item.pdfDetailUri)
                    "
                    color="primary"
                    flat
                    icon
                    :disabled="downloadDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    :loading="downloadPDFDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    class="my-3 btn-download"
                    v-on="on"
                    @click="
                      openPDFDocument(
                        props.index,
                        bordereauInvoiceFilter === 'Bordereau' ? props.item.documentURL : props.item.pdfDetailUri
                      )
                    "
                  >
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                </template>
                <span>Download (PDF)</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    v-if="props.item.sanalCSVUri"
                    color="primary"
                    flat
                    icon
                    :disabled="downloadDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    :class="
                      downloadDocumentRunning && downloadDocumentCurrentIndex === props.index
                        ? 'disable-custom-img'
                        : ''
                    "
                    :loading="downloadSanalCSVDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    class="my-3 btn-sanal-csv-download"
                    v-on="on"
                    @click="openSanalCSVDocument(props.index, props.item.sanalCSVUri)"
                  >
                    <img src="/img/csv-download.svg" alt="" class="custom-img" />
                  </v-btn>
                </template>
                <span>Download Sanal CSV</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    v-if="props.item.stranCSVUri"
                    color="primary"
                    flat
                    icon
                    :disabled="downloadDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    :class="
                      downloadDocumentRunning && downloadDocumentCurrentIndex === props.index
                        ? 'disable-custom-img'
                        : ''
                    "
                    :loading="downloadStranCSVDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    class="my-3 btn-stran-csv-download"
                    v-on="on"
                    @click="openStranCSVDocument(props.index, props.item.stranCSVUri)"
                  >
                    <img src="/img/csv-download.svg" alt="" class="custom-img" />
                  </v-btn>
                </template>
                <span>Download Stran CSV</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    v-if="props.item.bordereauCSVUri"
                    color="primary"
                    flat
                    icon
                    :disabled="downloadDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    :class="
                      downloadDocumentRunning && downloadDocumentCurrentIndex === props.index
                        ? 'disable-custom-img'
                        : ''
                    "
                    :loading="downloadBordereauCSVDocumentRunning && downloadDocumentCurrentIndex === props.index"
                    class="my-3 btn-bordereau-csv-download"
                    v-on="on"
                    @click="openBordereauCSVDocument(props.index, props.item.bordereauCSVUri)"
                  >
                    <img src="/img/csv-download.svg" alt="" class="custom-img" />
                  </v-btn>
                </template>
                <span>Download Bordereau CSV</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    flat
                    icon
                    class="my-3 btn-bordereau"
                    v-on="on"
                    @click="openBordereauDialog(props.item)"
                  >
                    <v-icon>folder_open</v-icon>
                  </v-btn>
                </template>
                <span>Open Bordereau</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="bordereauDialog"
      persistent
      max-width="1680px"
      content-class="v-dialog--scrollable"
      @keydown.esc="bordereauDialog = false"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title v-if="selectedBordereau.bordereauName">
            Bordereau Details ({{ selectedBordereau.bordereauName }})
          </v-toolbar-title>
          <v-toolbar-title v-else>Invoice Details ({{ selectedBordereau.id }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="bordereauDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-data-table
            :headers="bordereauJobDetailHeaders"
            :items="bordereauJobRows"
            :loading="isLoading"
            class="elevation-1 gridView popup-table"
            :pagination.sync="paginationJobRows"
          >
            <template slot="items" slot-scope="props">
              <td>
                <b>
                  <a href="javascript:void(0)" class="secondary--text">
                    {{ props.item.jobId }}
                  </a>
                </b>
              </td>
              <td>{{ props.item.policyName }}</td>
              <td class="address-col">{{ props.item.address }}</td>
              <td class="text-xs-center">{{ props.item.policyNumber }}</td>
              <td class="text-xs-center">
                <span v-for="(trades, index) in props.item.tradesEmployed" :key="index">
                  <span v-if="index !== 0">,</span>
                  {{ trades }}
                </span>
              </td>
              <td class="text-xs-center">
                {{ props.item.costAsFixedFee ? 'Yes' : 'No' }}
              </td>
              <td class="text-xs-right">
                {{ getFormatedCurrency(props.item.policyLimit) }}
              </td>
              <td class="text-xs-right">
                {{ getFormatedCurrency(props.item.totalCostIncVAT) }}
              </td>
              <td class="text-xs-center">{{ props.item.returnVisit }}</td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- export to excel dialog -->
    <v-dialog
      v-if="exportToExcelDialog"
      v-model="exportToExcelDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Export To Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="exportToExcelDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 pt-0 scroll-content-dialog">
          <v-container grid-list-xl class="pa-0">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="exportName" label="Export Name" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="exportToExcelDialog = false">Cancel</v-btn>
          <export-excel
            class="btn ml-2"
            :export-fields="exportToExcelHeaderFields"
            :fetch="exportBordereau"
            worksheet="CTP Details"
            :before-generate="startExportGeneration"
            :before-finish="stopExportGeneration"
            type="csv"
            :name="exportName ? exportName + '.xls' : getFileName"
          >
            <v-btn ref="ExportToExcel" color="primary" :loading="exportToExcelLoader" :disabled="exportToExcelLoader">
              <v-icon class="md-icon">get_app</v-icon>
              &nbsp; Export
            </v-btn>
          </export-excel>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /export to excel dialog -->
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import BordereauModel from '@/models/client/BordereauModel'
import { BordereauJobRow } from '@/models/claim/Bordereau'
import InvoiceController from '@/api/invoiceController'
import moment from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'
import excel from 'vue-excel-export'
import { InvoiceStatus } from '@/common/enums'
import DateTimePicker from '@/components/DateTimePicker.vue'
import DocumentController from '../api/documentController'
import Invoice from '../models/client/Invoice'
import eventBus from '@/common/bus'

@Component({
  components: { DateTimePicker, excel },
})
export default class ClientReports extends Vue {
  @Prop() private insurerId: number
  @Prop() private bordereauIdToOpenDetails: string

  private bordereauInvoiceFilter: 'Bordereau' | 'Invoice' = 'Bordereau'

  private bordereauDialog = false
  private bordereauJobDetailHeaders: any = [
    { text: 'JobId', value: 'jobId' },
    { text: 'Policy Name', value: 'policyName' },
    { text: 'Address', value: 'address' },
    { text: 'Policy Number', value: 'policyNumber', align: 'center' },
    { text: 'Trades Employed', value: 'tradesEmployed', sortable: false },
    { text: 'Cost as Fixed Fee?', value: 'costAsFixedFee', align: 'center' },
    { text: 'Policy Limit', value: 'policyLimit', align: 'right' },
    { text: 'Total Cost', value: 'totalCost', align: 'right' },
    { text: 'Return Visit', value: 'returnVisit', align: 'center' },
  ]
  private selectedBordereau: BordereauModel | Invoice = new BordereauModel()
  private bordereauList: BordereauModel[] = []
  private invoiceList: Invoice[] = []
  private bordereauJobRows: BordereauJobRow[] = []
  private paginationLocal: any = {}
  private paginationJobRows: any = {}
  private loading = false
  private isLoading = false
  private searchFromDate: moment.Moment = moment().subtract(30, 'd')
  private searchToDate: moment.Moment = moment()
  private exportToExcelDialog = false
  private exportToExcelHeaderFields: any = {
    'Bordereau / Invoice Name': 'name',
    'Created At': 'createdAt',
    'Number of Jobs': 'noOfJobs',
    Status: 'status',
    Amount: 'amount',
    'Amount Paid': 'amountPaid',
  }
  private exportName = ''
  private exportToExcelLoader = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private downloadDocumentCurrentIndex = -1
  private downloadDocumentRunning = false
  private downloadPDFDocumentRunning = false
  private downloadSanalCSVDocumentRunning = false
  private downloadStranCSVDocumentRunning = false
  private downloadBordereauCSVDocumentRunning = false

  private created() {
    this.setDefaultFilter()
    this.paginationLocal.page = 1
    this.paginationLocal.rowsPerPage = Shared.rowsPerPageDefault
    this.paginationJobRows.page = 1
    this.paginationJobRows.rowsPerPage = Shared.rowsPerPageDefault
    this.getInvoicesBordereaus()
    Vue.use(excel)
    this.openBordereauDetail()
  }

  private get bordereauHeaders() {
    return this.bordereauInvoiceFilter === 'Bordereau'
      ? [
          { text: 'Bordereau Name', value: 'bordereauName' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Number of Jobs', value: 'numberOfJobs', align: 'center' },
          { text: 'Status', value: 'status', align: 'center' },
          { text: 'Amount', value: 'amount', align: 'right' },
          { text: 'Paid', value: 'amountPaid', align: 'right' },
          {
            text: 'Action',
            value: 'action',
            class: 'action-col',
            align: 'center',
            sortable: false,
          },
        ]
      : [
          { text: 'Invoice Name', value: 'id' },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Status', value: 'status', align: 'center' },
          { text: 'Amount', value: 'totalCostIncVAT', align: 'right' },
          { text: 'Paid', value: 'amountPaid', align: 'right' },
          {
            text: 'Action',
            value: 'action',
            class: 'action-col',
            align: 'center',
            sortable: false,
          },
        ]
  }

  private setDefaultFilter() {
    this.searchFromDate = moment().subtract(30, 'd')
    this.searchToDate = moment()
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private getFormatedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getStatusName(status) {
    return Shared.insertSpaceInEnumName(status)
  }

  private getStatusColor(status: string): string {
    switch (status) {
      case InvoiceStatus[InvoiceStatus.Unpaid]:
        return 'red--text'
      case InvoiceStatus[InvoiceStatus.Paid]:
        return 'green--text'
      case InvoiceStatus[InvoiceStatus.PartlyPaid]:
        return 'orange--text'
      default:
        return ''
    }
  }

  @Watch('insurerId')
  private onInsurerIdChange() {
    if (this.insurerId) {
      this.setDefaultFilter()
      this.getInvoicesBordereaus()
    }
  }

  private async getInvoicesBordereaus() {
    this.loading = true
    await this.getBordereaus()
    await this.getInvoices()
    this.loading = false
  }

  private async getBordereaus() {
    const searchFromDate = Shared.getFormatedDate(moment(this.searchFromDate), Shared.dateFormatForDatePicker)
    const searchToDate = Shared.getFormatedDate(moment(this.searchToDate), Shared.dateFormatForDatePicker)

    try {
      const res = await InvoiceController.GetBordereaus(this.insurerId, searchFromDate, searchToDate)
      if (res) {
        this.bordereauList = res
      }
    } catch (err) {
      eventBus.$emit('errorHandler', 'Error loading bordereau list, please try again.', true)
      this.loading = false
    }
  }

  private async getInvoices() {
    const searchFromDate = Shared.getFormatedDate(moment(this.searchFromDate), Shared.dateFormatForDatePicker)
    const searchToDate = Shared.getFormatedDate(moment(this.searchToDate), Shared.dateFormatForDatePicker)

    try {
      const res = await InvoiceController.GetInvoices(this.insurerId, searchFromDate, searchToDate)
      if (res) {
        this.invoiceList = res
      }
    } catch (err) {
      eventBus.$emit('errorHandler', 'Error loading invoice list, please try again.', true)
      this.loading = false
    }
  }

  private async openBordereauDialog(item: BordereauModel | Invoice) {
    this.selectedBordereau = item
    if ((item as BordereauModel).bordereauName !== undefined) {
      await this.GetBordereauDetail()
    } else {
      if (!this.invoiceList || !this.invoiceList.length) {
        await this.getInvoices()
      }
      const invoice = this.invoiceList.find((i) => i.id === (item as Invoice).id)
      if (invoice) {
        const row: BordereauJobRow = {
          jobId: invoice.jobId,
          address: invoice.address,
          costAsFixedFee: invoice.costAsFixedFee,
          tradesEmployed: invoice.tradesEmployed,
          otherFeesTotal: invoice.otherFeesTotal,
          labourValue: invoice.labourValue,
          materialValue: invoice.materialValue,
          policyName: invoice.policyName,
          policyNumber: invoice.policyNumber,
          policyLimit: invoice.policyLimit,
          returnVisit: invoice.returnVisit,
          totalCost: invoice.totalCost,
          totalVAT: invoice.totalVAT,
          totalCostIncVAT: invoice.totalCostIncVAT,
        }
        this.bordereauJobRows = [row]
      }
    }
    this.bordereauDialog = true
  }

  private GetBordereauDetail() {
    this.isLoading = true
    this.bordereauJobRows = []
    InvoiceController.GetBordereauDetail(this.insurerId, (this.selectedBordereau as BordereauModel).bordereauName)
      .then((res) => {
        if (res) {
          this.bordereauJobRows = res
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading bordereau detail, please try again.', true)
        this.isLoading = false
      })
  }

  private searchBordereau() {
    this.getInvoicesBordereaus()
  }

  private clearFilter() {
    this.setDefaultFilter()
    this.getInvoicesBordereaus()
  }

  private openPDFDocument(index: number, url: string) {
    this.downloadPDFDocumentRunning = true
    this.openDocument(index, url)
  }

  private openBordereauCSVDocument(index: number, url: string) {
    this.downloadBordereauCSVDocumentRunning = true
    this.openDocument(index, url)
  }

  private openSanalCSVDocument(index: number, url: string) {
    this.downloadSanalCSVDocumentRunning = true
    this.openDocument(index, url)
  }

  private openStranCSVDocument(index: number, url: string) {
    this.downloadStranCSVDocumentRunning = true
    this.openDocument(index, url)
  }

  private openDocument(index: number, url: string) {
    this.downloadDocumentCurrentIndex = index
    this.downloadDocumentRunning = true
    DocumentController.GetSASTokenForBordereauInvoicingDocument(url)
      .then((res: string) => {
        if (res) {
          window.open(res, '_blank')
        } else {
          this.showMessage('Unable to download document.')
        }
        this.resetDownload()
      })
      .catch((err: any) => {
        this.showMessage('Unable to download document.')
        this.resetDownload()
      })
  }

  private resetDownload() {
    this.downloadDocumentRunning = false
    this.downloadPDFDocumentRunning = false
    this.downloadSanalCSVDocumentRunning = false
    this.downloadStranCSVDocumentRunning = false
    this.downloadPDFDocumentRunning = false
    this.downloadDocumentCurrentIndex = -1
  }

  private startExportGeneration() {
    this.exportToExcelLoader = true
  }

  private stopExportGeneration() {
    this.exportToExcelLoader = false
  }

  private async exportBordereau() {
    if (this.bordereauList && this.bordereauList.length > 0) {
      let excelData: any = {}
      const exportToExcelData: any = []
      this.bordereauList.forEach((element) => {
        excelData = {
          name: element.bordereauName,
          createdAt: Shared.getFormatedDate(moment(element.createdAt), Store.Instance.state.Environment.DateTimeFormat),
          noOfJobs: element.numberOfJobs,
          status: element.status,
          amount: element.amount,
          amountPaid: element.amountPaid,
        }
        exportToExcelData.push(excelData)
      })
      this.exportToExcelDialog = false
      this.showMessage('Data Exported Successfully.')
      return exportToExcelData
    } else {
      const exportToExcelData = []
      this.showMessage('No Data Available.')
      this.stopExportGeneration()
      return exportToExcelData
    }
  }

  private get getFileName() {
    return (
      'Bordereau (' +
      Shared.getFormatedDate(this.searchFromDate, Store.Instance.state.Environment.DateFormat) +
      ' - ' +
      Shared.getFormatedDate(this.searchToDate, Store.Instance.state.Environment.DateFormat) +
      ').xls'
    )
  }

  private openExportToExcelDialog() {
    this.exportName = ''
    this.exportToExcelDialog = true
  }

  private selectableDateTime(date: moment.Moment | null): string {
    if (date) {
      return moment(date).format(DateTimePicker.DATE_FORMAT)
    }
    return ''
  }

  private dateFormat(): string {
    return Store.Instance.state.Environment.DateFormat
  }

  private showMessage(message: string) {
    this.snackbar = true
    this.snackbarText = message
  }

  private openBordereauDetail() {
    // route name is 'clientManagementwithBordereauDetail', open bordereau detail dialog
    if (this.bordereauIdToOpenDetails) {
      const item: any = {}
      if (this.bordereauIdToOpenDetails.includes('BD')) {
        item.bordereauName = this.bordereauIdToOpenDetails
      } else {
        item.id = this.bordereauIdToOpenDetails
      }
      this.openBordereauDialog(item)
    }
  }
}
</script>

<style scoped>
.gridView >>> td.address-col {
  white-space: pre-wrap !important;
}
.popup-table {
  width: 100%;
}
.popup-table td,
.popup-table th,
.report-table td,
.report-table th {
  white-space: nowrap;
}
.gridView >>> table.v-table tbody td:first-child,
.gridView >>> table.v-table thead th:first-child {
  padding-left: 10px !important;
}
.custom-img {
  width: 20px;
}
.disable-custom-img {
  opacity: 0.5;
}
</style>
