<template>
  <span class="countdown">
    {{ countdownLabel }}
  </span>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CountdownTimer extends Vue {
  private counter: number | null = null
  private showSeconds = '00'
  private showMinutes = '00'
  private totalSeconds = 0

  public startCountdown(numberOfSeconds: number): void {
    if (!this.counter) {
      this.totalSeconds = numberOfSeconds
      this.counter = window.setInterval(this.updateRemainingTime, 1000)
    }
  }

  public clearCountdown(): void {
    if (this.counter) {
      window.clearInterval(this.counter)
      this.counter = null
    }
  }

  private get countdownLabel(): string {
    return this.showMinutes + ':' + this.showSeconds
  }

  private updateRemainingTime(): void {
    --this.totalSeconds
    this.showSeconds = this.pad(this.totalSeconds % 60)
    this.showMinutes = this.pad(parseInt((this.totalSeconds / 60).toString(), 10))

    if (this.totalSeconds === 0) {
      // emit event to say it's finished and clear the counter
      this.$emit('countdownEnded')
      this.clearCountdown()
    }
  }

  private pad(val: number): string {
    const valString = val + ''
    if (valString.length < 2) {
      return '0' + valString
    } else {
      return valString
    }
  }
}
</script>

<style type="text/css" scoped></style>
