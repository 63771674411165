<template>
  <v-layout>
    <v-flex xs12>
      <ContractorJobDetail
        v-if="showContractorJobDetail"
        ref="contractorJobDetail"
        parent-component="SIJobDetail"
        :contractor-job-detail-item="contractorJobDetail"
        @contractorJobDetailDialogClose="closeSIJobDetailDialog"
      ></ContractorJobDetail>
      <v-progress-circular
        v-else
        style="position: absolute; top: 400px; left: 50%"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import JobController from '@/api/jobController'
import ContractorJobDetail from '@/components/ContractorJobDetail.vue'
import storeGetters from '@/storeGetters'
import SIReportModel from '@/models/claim/SIReportModel'
import Job from '@/models/Job'
import eventBus from '@/common/bus'

@Component({
  name: 'SIJobDetail',
  components: {
    ContractorJobDetail,
  },
})
export default class SIJobDetail extends Vue {
  @Prop({ default: () => '' }) private jobId: string

  private contractorJobDetail: ContractorJobDetailModel | null = null

  public mounted() {
    this.onJobChange()
  }

  @Watch('jobId')
  private async onJobChange() {
    try {
      if (this.job === null && this.jobId !== '') {
        const jobFound = await JobController.GetJobDocuments(this.jobId)
        if (!jobFound) {
          this.closeSIJobDetailDialog()
          eventBus.$emit('errorHandler', 'Error loading job, please try again!', true)
        }
      }
      this.contractorJobDetail = new ContractorJobDetailModel()
      this.assignJobDetail()
      this.assignEmergenciesQAs()
      this.assignProductQAs()
      this.assignSIJobDelay()
    } catch (err) {
      this.closeSIJobDetailDialog()
      eventBus.$emit('errorHandler', 'Error loading job, please try again!', true)
    }
  }

  private get job(): Job | null {
    if (this.jobId !== '') {
      return storeGetters.getJob(this.jobId)
    }
    return null
  }

  public get showContractorJobDetail(): boolean {
    return !!(this.contractorJobDetail && this.contractorJobDetail.jobDetail)
  }

  private assignJobDetail() {
    if (this.job !== null && this.contractorJobDetail !== null) {
      this.contractorJobDetail.jobDetail = Object.assign(new Job(), this.job)
    }
  }

  private assignEmergenciesQAs() {
    if (this.job !== null && this.contractorJobDetail !== null) {
      this.contractorJobDetail.emergenciesQA = Object.assign([], this.job.emergenciesQAs)
    }
  }

  private assignProductQAs() {
    if (this.job !== null && this.contractorJobDetail !== null) {
      this.contractorJobDetail.productsQA = Object.assign([], this.job.productQAs)
    }
  }

  private assignSIJobDelay() {
    if (this.job !== null && this.contractorJobDetail !== null && this.job.siJobDelay != null) {
      this.contractorJobDetail.siJobDelay = Object.assign({}, this.job.siJobDelay)
    }
  }

  private closeSIJobDetailDialog() {
    this.$emit('closeSIJobDetailDialog')
    this.contractorJobDetail = null
  }
}
</script>
