<template>
  <div v-if="ready" class="dashboard">
    <v-container fluid pa-0>
      <div>
        <v-layout wrap>
          <v-flex xs12>
            <v-container fluid grid-list-md pt-0 pa-0>
              <v-layout wrap calls-info request-info>
                <DashboardTopTile
                  v-for="key in Object.keys(topTiles)"
                  :key="key"
                  v-bind="topTiles[key]"
                  dashboard-tile-view="IncidentManager"
                />
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex xs12 sm8 md9 class="pr-0 mt-3">
            <IncidentManagerTable v-if="true" table-name="incidentManagerDashboard"></IncidentManagerTable>
          </v-flex>
          <v-flex xs12 sm4 md3 pl-3 mt-5>
            <v-card class="elevation-1 mt-4 white white--text pa-2 client-logos-section">
              <v-card-text class="pa-1">
                <v-layout wrap>
                  <v-flex xs12 class="grey mb-3 lighten-4 pa-2">
                    <div class="subheading mb-2 secondary--text">
                      <b>The CET Way</b>
                      <span v-if="isUserRoleAdministrator" class="edit-btn">
                        <v-btn icon class="ma-0" small @click="onEditCETNews()">
                          <v-icon color="primary">edit</v-icon>
                        </v-btn>
                      </span>
                    </div>
                    <v-divider />
                    <div v-bar="{ useScrollbarPseudo: true }" class="pre-block">
                      <v-layout>
                        <pre class="body2 mb-0 mt-2 grey--text text--darken-1" v-html="sanitizeHTML(cetNotePreview)">{{
                          cetNotePreview
                        }}</pre>
                      </v-layout>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 mb-2>
                    <a href="#">
                      <img src="/img/IRISLogo.png" class="img-responsive" />
                    </a>
                  </v-flex>
                  <v-flex xs12 sm6 mb-2>
                    <a href="#">
                      <img src="/img/perkboxLogo.png" class="img-responsive" />
                    </a>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <a href="#">
                      <img src="/img/scorebuddyLogo.png" class="img-responsive" />
                    </a>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <a href="#">
                      <img src="/img/CETLogo.png" class="img-responsive" />
                    </a>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
    <v-dialog v-model="editCETNews" content-class="v-dialog--scrollable" max-width="650" persistent>
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>The CET Way</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="editCETNews = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-3">
          <v-layout>
            <v-flex xs12>
              <froala
                id="froala"
                v-model="cetWayNoteModel.note"
                data-vv-scope="templateformRef"
                data-vv-name="Email"
                :config="config"
                :tag="'textarea'"
              ></froala>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="editCETNews = false">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0"
            :disabled="cetNoteSubmitLoading"
            :loading="cetNoteSubmitLoading"
            @click="onSaveCETWayNote()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <v-progress-circular
      class="loading-spinner"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import UserModel from '@/models/user/UserModel'
import Dashboard from '@/views/Dashboard.vue'
import store from '@/store'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import DashboardController from '@/api/dashboardController'
import CETWayNoteModel from '@/models/claim/CETWayNoteModel'
import IncidentManagerTable from '@/components/tables/IncidentManagerTable.vue'
import Shared from '../common/shared'
import eventBus from '@/common/bus'

@Component({
  components: { DashboardTopTile, IncidentManagerTable },
})
export default class IncidentManagerDashboard extends Dashboard {
  private sanitizeHTML = Shared.sanitizeHTML
  private config: any = Shared.froalaConfig(true)
  private editCETNews = false
  private cetNotePreview = ''
  private cetWayNoteModel: CETWayNoteModel = new CETWayNoteModel()
  private cetNoteSubmitLoading = false
  private incidentManagerList: UserModel[] = []

  public async created() {
    this.getCETWayNote()
    SetThemeForJobType(this, undefined)
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private getCETWayNote() {
    DashboardController.GetCETWayNote().then((res: CETWayNoteModel | null) => {
      if (res) {
        this.cetWayNoteModel = res
        this.cetNotePreview = res.note
      }
    })
  }

  private onSaveCETWayNote() {
    this.cetNoteSubmitLoading = true
    DashboardController.SaveCETWayNote(this.cetWayNoteModel)
      .then((res: CETWayNoteModel | null) => {
        if (res) {
          this.editCETNews = false
          this.cetWayNoteModel = res
          this.cetNotePreview = res.note
          this.cetNoteSubmitLoading = false
        } else {
          this.editCETNews = false
          this.cetNoteSubmitLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving CET note, please try again', true)
        this.cetNoteSubmitLoading = false
      })
  }

  private onEditCETNews() {
    this.editCETNews = true
    this.cetWayNoteModel.note = this.cetNotePreview
  }
}
</script>

<style scoped>
.client-logos-section {
  height: calc(100% - 25px) !important;
}
.client-logos-section .subheading {
  position: relative;
}
.client-logos-section .edit-btn {
  position: absolute;
  right: 0px;
  top: -4px;
}
.client-logos-section .pre-block {
  height: 390px;
}
.team-member-img {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
}
.team-member-img.b-red img {
  border-color: #cf1e60;
}
.team-member-img.b-pink img {
  border-color: #ff8a65;
}
</style>
