<template>
  <div>
    <div class="mb-2"><h4>Additional Cost Authorization</h4></div>
    <div class="mb-1">
      <label>Cost Authorised: </label>
      <span>{{ item.additionalCostsAuthorised ? 'Yes' : 'No' }}</span>
    </div>
    <div v-if="authorizationReason" class="mb-1">
      <label>Authorisation Reason: </label>
      <span>{{ authorizationReason }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddAdditionalCostAuthorisedRequestModel from '@/models/requests/AddAdditionalCostAuthorisedRequestModel'
import MasterRecord from '@/models/MasterRecord'

@Component
export default class AddAdditionalCostAuthorisedRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddAdditionalCostAuthorisedRequestModel {
    return Object.assign(new AddAdditionalCostAuthorisedRequestModel(), this.requestBody)
  }

  private get additionalPaymentAuthorizationReasons(): MasterRecord[] {
    const masterRecords: MasterRecord[] = this.$store.getters['jobModule/masterRecords']
    return masterRecords.filter((x) => x.type === 'AdditionalPaymentAuthorizationReason')
  }

  private get authorizationReason(): string {
    const reason = this.additionalPaymentAuthorizationReasons.find(
      (c: MasterRecord) => c.id === this.item.additionalPaymentAuthorizationReason
    )
    return reason ? reason.description : ''
  }
}
</script>
