<template>
  <div>
    <v-card class="pt-0 px-2 pb-2 up-card customer-payment">
      <v-card-title>
        <h3 class="heading">Complaint Details</h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-2">
        <v-layout wrap>
          <v-flex xs12>
            <v-textarea
              v-model="complaintModel.complaintSummary"
              label="Summary of Complaint in the words of the customer"
              rows="5"
              :readonly="isComplaintResolved"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model.trim="complaintModel.email"
              v-validate="'required|max:50|email'"
              label="Contact Email"
              placeholder="Email"
              maxlength="50"
              required
              class="required"
              data-vv-scope="formReference"
              data-vv-name="Contact Email"
              :error-messages="errors.collect('Contact Email')"
              :readonly="isComplaintResolved"
            >
              >
            </v-text-field>
          </v-flex>
          <v-layout wrap>
            <v-flex xs12 class="radio-group-custom py-0">
              <h3 class="pb-3">Preferred mode of contact</h3>
              <v-radio-group
                v-model="contactPreferenceId"
                class="radio-group-input pt-1"
                :mandatory="false"
                :readonly="isComplaintResolved"
              >
                <v-layout wrap grey lighten-4 pt-1 pb-4 px-3>
                  <v-flex xs12 pb-0>
                    <v-layout
                      class="radio-inline-custom pr-1"
                      :class="contactPreferenceId !== contactPreference.Call ? '' : ' show-number'"
                    >
                      <v-radio color="primary" :value="contactPreference.Call" label="Call"></v-radio>
                      <PhoneNumber
                        ref="contactPreferenceCall"
                        :passed-phone-number="getCallMobilePhone"
                        :is-disabled="contactPreferenceId !== contactPreference.Call || isComplaintResolved"
                        :is-validation-required="true"
                        class="complaint"
                        @phoneNumberUpdated="phoneNumberUpdated"
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 mb-3>
                    <v-layout class="radio-inline-custom">
                      <v-radio color="primary" :value="contactPreference.Post" label="Post"></v-radio>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pt-3 mt-3 mb-2>
                    <v-layout class="radio-inline-custom">
                      <v-radio color="primary" :value="contactPreference.Client" label="Client"></v-radio>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-flex xs12>
            <v-container fluid grid-list-lg pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select
                    v-model="complaintModel.severity"
                    v-validate="'required'"
                    :items="complaintSeverityList"
                    label="What is the Severity of the complaint"
                    item-value="id"
                    required
                    class="required severity-dropdown"
                    data-vv-scope="formReference"
                    data-vv-name="Complaint Severity"
                    :error-messages="errors.collect('Complaint Severity')"
                    :readonly="isComplaintResolved"
                    :disabled="!isNewComplaint && !canChangeToFormal"
                    @change="escalationDateRequired"
                  >
                    <template #selection="{ item }">
                      <span class="complaint-severity-type">{{ item.description }}</span>
                      <span :class="'severity-icon ' + severityItemColor(item)">{{ severityPriority(item) }}</span>
                    </template>
                    <template #item="{ item }">
                      <v-list-tile class="severity-list">
                        <v-list-tile-content>
                          <v-list-tile-title :class="severityItemColor(item)">{{ item.description }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex v-if="complaintModel.id" xs12 lg6>
                  <v-autocomplete
                    v-model="complaintModel.responsiblePerson"
                    :items="userList"
                    item-text="displayValue"
                    item-value="id"
                    label="Who is responsible"
                    :readonly="isComplaintResolved"
                    :disabled="!canEditResponsiblePerson"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 :class="complaintModel.id ? 'lg6' : 'lg12'">
                  <v-select
                    v-model="complaintModel.complaintType"
                    v-validate="'required'"
                    :items="complaintTypeList"
                    item-text="description"
                    item-value="id"
                    label="What is the type of Complaint"
                    required
                    class="required"
                    data-vv-scope="formReference"
                    data-vv-name="Complaint Type"
                    :error-messages="errors.collect('Complaint Type')"
                    :readonly="isComplaintResolved"
                    @change="onComplaintTypeChange"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-tile>
                        <v-list-tile-content>
                          <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                            {{ data.item.description }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 lg6>
                  <v-text-field
                    v-model="complaintModel.reasonForDelay"
                    label="Delay Remarks"
                    :readonly="isComplaintResolved"
                  ></v-text-field>
                </v-flex>
                <!-- Document Upload for Complaint -->
                <ComplaintDocumentUpload
                  :id="complaintModel && complaintModel.id ? complaintModel.id : tempComplaintId"
                  ref="refComplaintDocumentUpload"
                  :job-id="jobId"
                  :is-complaint-logged="complaintModel && complaintModel.id ? true : false"
                  :document-list="complaintModel.files"
                  @deleteDocument="onDeleteDocument"
                  @uploadDocument="onUploadDocument"
                ></ComplaintDocumentUpload>
                <v-flex xs12 lg6>
                  <v-select
                    v-model="complaintModel.justified"
                    :items="yesNoOptions"
                    item-text="description"
                    item-value="description"
                    label="Justified"
                  ></v-select>
                </v-flex>
                <v-flex xs12 lg6>
                  <v-select
                    v-model="getConcernsRaisedList"
                    v-validate="'required'"
                    multiple
                    :items="concernsRaisedList"
                    label="Concerns Raised"
                    item-text="description"
                    item-value="id"
                    required
                    class="required"
                    data-vv-scope="formReference"
                    data-vv-name="Concerns Raised"
                    :error-messages="errors.collect('Concerns Raised')"
                    :readonly="isComplaintResolved"
                    @change="onConcernsRaisedChange(getConcernsRaisedList, $event)"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-checkbox
                            v-if="concernsRaisedIdCheckboxes.length > 0 && data.item.description !== 'Configure'"
                            v-model="concernsRaisedIdCheckboxes[concernsRaisedList.indexOf(data.item)].checked"
                            color="primary"
                          ></v-checkbox>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                            {{ data.item.description }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="complaintModel.concernsRaisedComment"
                    v-validate="{ required: isOtherConcernRaised }"
                    label="Concerns Raised Description"
                    rows="5"
                    required
                    :class="isOtherConcernRaised ? 'required' : ''"
                    data-vv-scope="formReference"
                    data-vv-name="Concerns Raised Description"
                    :error-messages="errors.collect('Concerns Raised Description')"
                    :readonly="isComplaintResolved"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 :class="complaintModel.isNoticeSent === 'Yes' ? 'lg6' : ''">
                  <v-select
                    v-model="complaintModel.isNoticeSent"
                    :items="yesNoOptions"
                    item-text="description"
                    item-value="description"
                    label="Has an 'On Notice' been sent"
                    :readonly="isComplaintResolved"
                  ></v-select>
                </v-flex>
                <v-flex v-if="complaintModel.isNoticeSent === 'Yes'" xs12 lg6>
                  <DateTimePicker
                    ref="noticeSentDate"
                    :date-time.sync="getNoticeSentDate"
                    :is-static-location="false"
                    :show-present-time-icon="false"
                    place-holder-text="Select date for notice been sent"
                    :disabled="isComplaintResolved"
                  />
                </v-flex>
                <v-flex xs12 :class="complaintModel.escalationRequired === 'Yes' ? 'lg6' : ''">
                  <v-select
                    v-model="complaintModel.escalationRequired"
                    :items="escalationOptions"
                    item-text="description"
                    item-value="description"
                    label="Is an escalation to client required?"
                    :disabled="isComplaintResolved"
                  ></v-select>
                </v-flex>
                <v-flex v-if="complaintModel.escalationRequired === 'Yes'" xs12 lg6>
                  <DateTimePicker
                    ref="escalationDate"
                    :date-time.sync="getEsacalationDate"
                    :is-static-location="false"
                    :show-present-time-icon="true"
                    :is-current-time="true"
                    place-holder-text="Select Escalation date"
                    :disabled="isComplaintResolved"
                  />
                </v-flex>
                <v-flex xs12 lg6>
                  <DateTimePicker
                    ref="refReceivedDate"
                    class="received-date"
                    :date-time.sync="getComplaintReceivedDate"
                    :is-static-location="false"
                    :show-present-time-icon="false"
                    place-holder-text="Complaint Received Date"
                    :disabled="isComplaintResolved || !canEditReceivedDate"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap class="grey lighten-4 px-3">
                <v-flex xs6>
                  <v-flex><h3>Regulatory Letters</h3></v-flex>
                  <v-flex class="letters-padding">Initial Response Letter</v-flex>
                  <v-flex class="letters-padding">Four Week Letter</v-flex>
                  <v-flex class="letters-padding">Eight Week Letter</v-flex>
                  <v-flex class="letters-padding">Summary Resolution Communication</v-flex>
                  <v-flex class="letters-padding">Final Response Letter</v-flex>
                </v-flex>
                <v-flex xs3>
                  <v-flex><h3>Required By</h3></v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="IRLDueDate"
                      :date-time.sync="regulatoryLetters.irl.date"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="FWLDueDate"
                      :date-time.sync="regulatoryLetters.fwl.date"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="EWLDueDate"
                      :date-time.sync="regulatoryLetters.ewl.date"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="SRCDueDate"
                      :date-time.sync="regulatoryLetters.src.date"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="FRLDueDate"
                      :date-time.sync="regulatoryLetters.frl.date"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                </v-flex>
                <v-flex xs3>
                  <v-flex><h3>Sent On</h3></v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="IRLSentDate"
                      :date-time.sync="regulatoryLetters.irl.sent"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="FWLSentDate"
                      :date-time.sync="regulatoryLetters.fwl.sent"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="EWLSentDate"
                      :date-time.sync="regulatoryLetters.ewl.sent"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="SRCSentDate"
                      :date-time.sync="regulatoryLetters.src.sent"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                  <v-flex py-0>
                    <DateTimePicker
                      ref="FRLSentDate"
                      :date-time.sync="regulatoryLetters.frl.sent"
                      :is-static-location="false"
                      :show-present-time-icon="false"
                      :hide-time-picker="true"
                      :min-date="formatDate(complaintModel.createdAt)"
                      display-format="DD/MM/YYYY"
                      :clearable="true"
                    />
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout wrap class="grey lighten-4 px-3">
                <v-flex xs12>
                  <v-textarea
                    v-model="complaintModel.agreedAction"
                    label="Agreed actions"
                    rows="3"
                    :readonly="isComplaintResolved"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model.trim="complaintModel.resolutionSummary"
                    v-validate="{
                      rules: {
                        required: !isComplaintResolved && complaintModel && complaintModel.isResolved ? true : false,
                      },
                    }"
                    label="Summary of resolution"
                    rows="3"
                    :readonly="isComplaintResolved"
                    required
                    data-vv-scope="formSubmitReference"
                    data-vv-name="Resolution summary"
                    :error-messages="errors.collect('Resolution summary')"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="getResolutionCodeList"
                    multiple
                    :items="resolutionCodeList"
                    label="Select resolution code"
                    item-text="description"
                    item-value="id"
                    :readonly="isComplaintResolved"
                    @change="onResolutionCodeChange(getResolutionCodeList, $event)"
                  >
                    >
                    <template slot="item" slot-scope="data">
                      <v-list-tile>
                        <v-list-tile-action>
                          <v-checkbox
                            v-if="resolutionCodeCheckboxes.length > 0 && data.item.description !== 'Configure'"
                            v-model="resolutionCodeCheckboxes[resolutionCodeList.indexOf(data.item)].checked"
                            color="primary"
                          ></v-checkbox>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                            {{ data.item.description }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 lg6>
                  <DateTimePicker
                    ref="complaintResolution"
                    :date-time.sync="getDateOfResolution"
                    :is-static-location="false"
                    :min="setMinDate"
                    :show-present-time-icon="false"
                    place-holder-text="Expected date of resolution"
                    :disabled="isComplaintResolved || !(getResolutionCodeList && getResolutionCodeList.length > 0)"
                  />
                </v-flex>
              </v-layout>
              <v-layout v-if="complaint.rootCause" wrap>
                <v-flex xs12 pb-2>
                  <v-select
                    v-model="complaintModel.rootCause"
                    :items="rootCauseList"
                    item-text="description"
                    item-value="id"
                    label="Complaint root cause"
                    readonly
                    @change="onComplaintRootCauseChange"
                  >
                    >
                    <template slot="item" slot-scope="data">
                      <v-list-tile>
                        <v-list-tile-content>
                          <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                            {{ data.item.description }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex v-if="complaint.rootCauseComment" xs12 pb-2>
                  <v-textarea
                    v-model="complaintModel.rootCauseComment"
                    label="Root Cause Description"
                    rows="3"
                    readonly
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout v-if="complaintModel.escalationReasonId" wrap>
                <v-flex xs12>
                  <v-select
                    v-model.trim="complaintModel.escalationReasonId"
                    v-validate="'required'"
                    label="Escalation Reason"
                    :items="complaintEscalationResonList"
                    item-text="description"
                    item-value="id"
                    readonly
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model.trim="complaintModel.escalationDescription"
                    rows="3"
                    label="Escalation Description"
                    readonly
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12>
                  <v-checkbox
                    v-model="complaintModel.isCompensationByCheque"
                    label="Compensation payment by cheque"
                    color="primary"
                    hide-details
                    :readonly="isComplaintResolved"
                    :disabled="isComplaintResolved"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                :class="
                  complaintPaymentShow
                    ? 'complaint-payment grey lighten-4 px-3 elevation-1'
                    : 'complaint-payment grey lighten-4 px-3 elevation-1 hide'
                "
              >
                <v-flex xs12>
                  <v-select
                    v-model="paymentReason"
                    label="Select Reason for payment"
                    :items="reasonForPaymentList"
                    item-text="description"
                    item-value="id"
                    return-object
                    required
                    class="required"
                    data-vv-scope="paymentDetails"
                    data-vv-name="Payment Reason"
                    :error-messages="errors.collect('Payment Reason')"
                    @change="onPaymentReasonChange($event)"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-tile-content>
                        <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                          {{ data.item.description }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="complaintModel.accountHolderFirstName"
                    v-validate="'required'"
                    label="First Name"
                    required
                    class="required"
                    data-vv-scope="paymentDetails"
                    data-vv-name="First Name"
                    :error-messages="errors.collect('First Name')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="complaintModel.accountHolderLastName"
                    v-validate="'required'"
                    label="Last Name"
                    required
                    class="required"
                    data-vv-scope="paymentDetails"
                    data-vv-name="Last Name"
                    :error-messages="errors.collect('Last Name')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="complaintModel.accountHolderAddress"
                    v-validate="'required'"
                    label="Address"
                    required
                    class="required"
                    data-vv-scope="paymentDetails"
                    data-vv-name="Address"
                    :error-messages="errors.collect('Address')"
                    rows="3"
                  ></v-textarea>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="complaintModel.accountNumber"
                    v-validate="'required'"
                    label="Account No"
                    required
                    class="required"
                    data-vv-scope="paymentDetails"
                    data-vv-name="Account No"
                    :error-messages="errors.collect('Account No')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="complaintModel.IFSCCode"
                    v-validate="'required'"
                    label="IFSC Code"
                    required
                    class="required"
                    data-vv-scope="paymentDetails"
                    data-vv-name="IFSC Code"
                    :error-messages="errors.collect('IFSC Code')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <div class="amount-icon">
                    <span>&pound;</span>
                    <v-text-field
                      v-model.number="complaintModel.compensationPaid"
                      v-validate="'required'"
                      label="Amount"
                      required
                      class="required"
                      data-vv-scope="paymentDetails"
                      data-vv-name="Amount"
                      :error-messages="errors.collect('Amount')"
                      @keypress="numberKeyValidation($event)"
                    ></v-text-field>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="complaintModel.lessonsLearned"
                    v-validate="'required'"
                    label="Lessons learned"
                    required
                    class="required"
                    data-vv-scope="paymentDetails"
                    data-vv-name="Lessons learned"
                    :error-messages="errors.collect('Lessons learned')"
                    rows="3"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 text-xs-right>
                  <v-btn
                    color="primary"
                    class="mr-0"
                    :loading="savePaymentLoading"
                    :disabled="savePaymentLoading"
                    @click="savePaymentDetails"
                  >
                    Pay
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout v-if="getJobComplaintNotes && getJobComplaintNotes.length > 0" wrap>
                <v-flex xs12 pt-0>
                  <v-divider class="mt-3" />
                </v-flex>
                <v-flex xs12 mb-2>
                  <h3>Complaint Notes ({{ getJobComplaintNotes.length }})</h3>
                </v-flex>
                <v-flex xs12>
                  <v-layout
                    v-for="(complaint, index) of getJobComplaintNotes"
                    :key="index"
                    wrap
                    grey
                    lighten-4
                    py-1
                    mb-3
                  >
                    <v-flex xs12 py-1>
                      <span>
                        <b>Title :</b>
                        {{ complaint.title }}
                      </span>
                    </v-flex>
                    <v-flex xs12 py-1>
                      <span>
                        <b>Emergency Type :</b>
                        {{ complaint.forEmergencyDetailDescription ? complaint.forEmergencyDetailDescription : 'All' }}
                      </span>
                    </v-flex>
                    <v-flex xs12 py-1>
                      <div class="yellow lighten-4 pa-2">
                        <b>Note :</b>
                        {{ complaint.description }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
        <div class="claim-btn-content grey lighten-3">
          <v-card wrap class="elevation-0 grey lighten-3">
            <v-card-actions xs12 class="complaint-btns">
              <template v-if="!isComplaintResolved">
                <div class="sticky-btn-content">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :loading="isLoading"
                    :disabled="isLoading || isComplaintResolved"
                    class="mr-0 save-btn"
                    @click.native="onComplaintSave()"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    color="primary"
                    dark
                    :class="showPaymentButton || isComplaintResolved ? 'mr-0' : 'hide mr-0'"
                    @click="makePayment()"
                  >
                    Make a Payment
                  </v-btn>
                </div>
                <div v-if="complaintModel.id" class="sticky-btn-content mt-3">
                  <v-btn
                    class="blue white--text"
                    :loading="loadListOfTemplatesInProgress"
                    :disabled="loadListOfTemplatesInProgress"
                    @click="createComplaintDocument()"
                  >
                    Create Document
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-tooltip top :disabled="complaintModel.resolutionSummary ? true : false">
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          class="green white--text mr-2"
                          :disabled="!complaintModel.resolutionSummary || loading"
                          :loading="loading"
                          v-on="on"
                          @click="openResolveComplaintDialog"
                        >
                          Resolve
                        </v-btn>
                      </div>
                    </template>
                    <span>summary of resolution required to mark complaint as resolved</span>
                  </v-tooltip>
                  <v-btn
                    class="primary white--text"
                    :disabled="isLoadingEscalateDocuments"
                    :loading="isLoadingEscalateDocuments"
                    @click="openComplaintEscalateDialog"
                  >
                    Escalate
                  </v-btn>
                </div>
              </template>
              <template v-if="isComplaintResolved && hasComplaintResolutionSkills">
                <v-btn
                  class="blue white--text"
                  :loading="loadListOfTemplatesInProgress"
                  :disabled="loadListOfTemplatesInProgress"
                  @click="createComplaintDocument()"
                >
                  Create Document
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="primary white--text"
                  :loading="isLoading"
                  :disabled="isLoading || !canReOpenComplaint"
                  @click="reOpenConfirmationDialog"
                >
                  Re-open Complaint
                </v-btn>
              </template>
            </v-card-actions>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureDropdown
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>
    <!-- create complaint document dialog -->
    <ReportDocumentTemplate
      v-if="showComplaintDocumentTemplateSelection"
      ref="refReportDocumentTemplate"
      :document-template-list="complaintDocumentTemplateList"
      @getDocumentTemplateText="getDocumentTemplateText"
      @closeDocumentTemplateDialog="closeDocumentTemplateDialog"
      @saveDocument="saveDocument"
      @showSnackBarWithMessage="showSnackBarWithMessage"
    ></ReportDocumentTemplate>
    <!-- resolve complaint dialog -->
    <v-dialog
      v-if="showResolveComplaintDialog"
      v-model="showResolveComplaintDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Resolve Complaint</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showResolveComplaintDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <span class="warning--text mb-1">
            <v-icon color="orange" class="md-icon pr-2">info</v-icon>
            Once complaint is resolved, you won't be able to edit it.
          </span>
          <v-flex xs12>
            <v-select
              v-model="rootCause"
              v-validate="'required'"
              :items="rootCauseList"
              item-text="description"
              item-value="id"
              label="Select Complaint Root Cause"
              required
              class="required"
              data-vv-scope="formResolveComplaint"
              data-vv-name="Complaint Root Cause"
              :error-messages="errors.collect('Complaint Root Cause')"
              :readonly="isComplaintResolved"
              @change="onComplaintRootCauseChange"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                      {{ data.item.description }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
          <v-textarea
            v-model="rootCauseComment"
            label="Root Cause Description"
            rows="5"
            data-vv-name="Root Cause Comment"
            :readonly="isComplaintResolved"
          ></v-textarea>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showResolveComplaintDialog = false">Cancel</v-btn>
          <v-btn color="primary" class="mr-0" :disabled="loading" :loading="loading" @click="resolveComplaint()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="complaintEscalateDialog" v-model="complaintEscalateDialog" persistent max-width="700">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Complaint Escalation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="complaintEscalateDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3">
          <v-flex xs12>
            <v-select
              v-model.trim="complaintEscalation.escalationReasonId"
              v-validate="'required'"
              label="Select Reason"
              :items="complaintEscalationResonList"
              item-text="description"
              item-value="id"
              data-vv-scope="formEscalationReference"
              data-vv-name="Escalation Reason"
              required
              class="required"
              :error-messages="errors.collect('Escalation Reason')"
              @change="onComplaintEscalationResonChange"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                      {{ data.item.description }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model.trim="complaintEscalation.escalationDescription"
              v-validate="'required'"
              rows="5"
              label="Description"
              required
              class="required"
              data-vv-scope="formEscalationReference"
              data-vv-name="Escalation Description"
              :error-messages="errors.collect('Escalation Description')"
            >
              >
            </v-textarea>
          </v-flex>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="complaintEscalateDialog = false">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0"
            :disabled="isLoadingEscalateDocuments"
            :loading="isLoadingEscalateDocuments"
            @click="onComplaintEscalation"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-if="showSnackbar" v-model="showSnackbar" top multi-line color="error" :timeout="snackbarTimeout">
      <h4>{{ snackbarText }}</h4>
      <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import ComplaintModel from '@/models/claim/ComplaintModel'
import storeGetters from '@/storeGetters'
import ComplaintController from '@/api/complaintController'
import moment, { Moment } from 'moment'
import store from '@/store'
import { RecordType, ComplaintContactPreferenceEnum } from '@/common/enums'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import Shared from '@/common/shared'
import { ComplaintContactPreference } from '@/models/types'
import UserModel from '@/models/user/UserModel'
import storeMutations from '@/storeMutations'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import JobNoteModel from '../../models/claim/JobNoteModel'
import VulnerabilityQAModel from '../../models/claim/VulnerabilityQAModel'
import ContractorAppointedModel from '../../models/claim/ContractorAppointedModel'
import Emergency from '@/models/Emergency'
import ComplaintDocumentUpload from '@/components/ComplaintDocumentUpload.vue'
import DocumentTemplateModel from '../../models/policy/DocumentTemplateModel'
import jsPDF from 'jspdf'
import AddComplaintEscalateRequest from '@/models/requests/AddComplaintEscalateRequest'
import ReopenComplaintRequest from '@/models/requests/ReopenComplaintRequest'
import AddCloseComplaintRequest from '../../models/requests/AddCloseComplaintRequest'
import AddComplaintPaymentRequest from '@/models/requests/AddComplaintPaymentRequest'
import eventBus from '@/common/bus'
import ReportDocumentTemplate from '@/components/ReportDocumentTemplate.vue'
import ComplaintSeverity from '@/models/complaints/complaint-severity'
import ComplaintAuthPolicies from '@/models/auth/complaint-auth-policies'

export enum RegulatoryLetterType {
  InitialResponseLetter,
  FourWeekLetter,
  EightWeekLetter,
}

@Component({
  components: {
    ConfigureDropdown,
    PhoneNumber,
    DateTimePicker,
    ComplaintDocumentUpload,
    ReportDocumentTemplate,
  },
})
export default class ComplaintCardPreview extends TimeLineItemBase {
  private resolutionCodeList: MasterRecord[] = []
  private reasonForPaymentList: MasterRecord[] = []
  private complaintTypeList: MasterRecord[] = []
  private showPaymentButton = false
  private complaintPaymentShow = false
  private resolutionDate: moment.Moment = moment(new Date())
  private complaintModel: ComplaintModel = new ComplaintModel()
  private isLoading = false
  private setMinDate: any = null
  private configureDropdownDialog = false
  private isComplaintResolved = false
  private configureDropdownType = ''
  private contactPreference = ComplaintContactPreferenceEnum
  private resolutionCodeCheckboxes: any = []
  private contactPreferenceId = 0
  private mobilePhone = ''
  private faultOptions: any[] = []
  private escalationOptions: any[] = []
  private yesNoOptions: any[] = []
  private noticeSentAt: moment.Moment = moment(new Date())
  private escalationDate: moment.Moment = moment(new Date())
  private tempComplaintId = ''
  private showComplaintDocumentTemplateSelection = false
  private complaintDocumentTemplateList: DocumentTemplateModel[] = []
  private loadListOfTemplatesInProgress = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private showSnackbar = false
  private marginsForPdf = { top: 10, bottom: 40, left: 30, width: 550 }
  private rootCauseList: MasterRecord[] = []
  private showResolveComplaintDialog = false
  private rootCause: string
  private rootCauseComment: string
  private complaintEscalation: AddComplaintEscalateRequest = new AddComplaintEscalateRequest()
  private complaintEscalateDialog = false
  private complaintEscalationResonList: MasterRecord[] = []
  private isLoadingEscalateDocuments = false
  private isEscalatedDocumentUploaded = false
  private loading = false
  private savePaymentLoading = false
  private concernsRaisedList: MasterRecord[] = []
  private isOtherConcernRaised = false
  private concernsRaisedIdCheckboxes: any = []
  private complaintReceivedDate: moment.Moment | null = null
  private selectedPaymentReason: MasterRecord | null = null

  private regulatoryLetters: {
    irl: { date: Moment | null; sent: Moment | null }
    fwl: { date: Moment | null; sent: Moment | null }
    ewl: { date: Moment | null; sent: Moment | null }
    src: { date: Moment | null; sent: Moment | null }
    frl: { date: Moment | null; sent: Moment | null }
  } = {
    irl: { date: null, sent: null },
    fwl: { date: null, sent: null },
    ewl: { date: null, sent: null },
    src: { date: null, sent: null },
    frl: { date: null, sent: null },
  }

  private get complaintPolicies(): ComplaintAuthPolicies {
    return this.$store.getters['authModule/complaints']
  }

  public get canReOpenComplaint(): boolean {
    return this.complaintPolicies.reOpen
  }

  public get canEditReceivedDate(): boolean {
    return this.complaintPolicies.changeReceivedDate
  }

  public get canEditResponsiblePerson(): boolean {
    return this.complaintPolicies.changeResponsiblePerson
  }

  public get canChangeToFormal(): boolean {
    return this.complaintPolicies.changeToFormal
  }

  private get userList(): UserModel[] {
    return storeGetters.getUsers()
  }

  private get getJobComplaintNotes(): JobNoteModel[] | null {
    return storeGetters.getJobComplaintNotes(this.jobId)
  }

  private get isNewComplaint(): boolean {
    return this.complaintModel.id === undefined || this.complaintModel.id === null
  }

  private get complaintSeverityList(): ComplaintSeverity[] {
    const severities = this.$store.getters['complaintModule/complaintSeverities']
    if (!this.isNewComplaint || this.canChangeToFormal) {
      return severities
    } else {
      return severities.filter((s) => s.id < 7)
    }
  }

  // get complaint types.
  private get getComplaintType(): MasterRecord[] {
    return this.complaintTypeList
  }

  private get complaint(): ComplaintModel | null {
    const complaint: ComplaintModel = storeGetters.getComplaintById(this.jobId, this.itemId)
    if (complaint) {
      return complaint
    }
    return new ComplaintModel()
  }

  private get getResolutionCodeList(): any {
    if (this.complaintModel.resolutionCode) {
      const selectedItem: any = []
      this.complaintModel.resolutionCode.forEach((element) => {
        selectedItem.push(this.resolutionCodeList.filter((e) => e.id === element))
      })
      if (selectedItem.length > 0) {
        selectedItem.forEach((item) => {
          if (item.length > 0) {
            this.resolutionCodeCheckboxes[this.resolutionCodeList.indexOf(item[0])].checked = true
          }
        })
      }
      return this.complaintModel.resolutionCode
    }
    return undefined
  }

  private set getResolutionCodeList(newValue: any) {
    if (this.complaintModel) {
      this.complaintModel.resolutionCode = newValue
      const descList: string[] = []
      this.resolutionCodeList
        .filter((e) => newValue.indexOf(e.id) > -1)
        .forEach((e) => {
          descList.push(e.description)
        })
      this.complaintModel.resolutionDescription = descList
    }
  }

  // get resolutionDate of complaint.
  private get getDateOfResolution(): moment.Moment {
    return this.resolutionDate
  }
  // set resolutionDate of complaint.
  private set getDateOfResolution(newValue: moment.Moment) {
    this.resolutionDate = newValue
  }

  private get getCallMobilePhone(): string {
    return this.mobilePhone
  }

  private set getCallMobilePhone(newValue: string) {
    this.mobilePhone = newValue
    if (newValue !== '') {
      this.contactPreferenceId = this.contactPreference.Call
    }
  }

  private get vulnerabilityQAItem(): VulnerabilityQAModel | undefined {
    if (this.jobId) {
      return storeGetters.getVulnerabilityQA(this.jobId)
    }
    return undefined
  }

  // get NoticeSent date of complaint.
  private get getNoticeSentDate(): moment.Moment {
    return this.noticeSentAt
  }
  // set NoticeSent data of complaint.
  private set getNoticeSentDate(newValue: moment.Moment) {
    this.noticeSentAt = newValue
  }

  // get Escalation date of complaint.
  private get getEsacalationDate(): moment.Moment {
    return this.escalationDate
  }
  // set Escalation date of complaint.
  private set getEsacalationDate(newValue: moment.Moment) {
    this.escalationDate = newValue
  }

  // get Received date of complaint.
  private get getComplaintReceivedDate(): moment.Moment | null {
    return this.complaintReceivedDate
  }
  // set Received date of complaint.
  private set getComplaintReceivedDate(newValue: moment.Moment | null) {
    this.complaintReceivedDate = newValue
  }

  private get paymentReason() {
    return this.selectedPaymentReason
  }

  private set paymentReason(value: MasterRecord | null) {
    if (value) {
      this.complaintModel.paymentReasonId = value.id
      this.complaintModel.paymentReason = value.description
    } else {
      this.complaintModel.paymentReasonId = undefined
      this.complaintModel.paymentReason = undefined
    }
    this.selectedPaymentReason = value
  }

  public get hasComplaintResolutionSkills(): boolean {
    if (store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
      return store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.complaintResolution >= 3
    }
    return false
  }

  // get root cause reasons
  private get getRootCauseReason(): MasterRecord[] {
    return this.rootCauseList
  }

  private get getConcernsRaisedList(): any {
    if (this.complaintModel.concernsRaisedIds) {
      const selectedItem: any = []
      this.complaintModel.concernsRaisedIds.forEach((element) => {
        selectedItem.push(this.concernsRaisedList.filter((e) => e.id === element))
      })
      if (selectedItem.length > 0) {
        selectedItem.forEach((item) => {
          if (item.length > 0) {
            this.concernsRaisedIdCheckboxes[this.concernsRaisedList.indexOf(item[0])].checked = true
          }
        })
      }
      const itemWithOtherOption = this.concernsRaisedList.find((x) => x.description.toLowerCase() === 'other')
      // required to validate comment filed if "other" is selected as option
      this.isOtherConcernRaised = itemWithOtherOption
        ? this.complaintModel.concernsRaisedIds.includes(itemWithOtherOption.id)
        : false
      return this.complaintModel.concernsRaisedIds
    }
    return undefined
  }

  private set getConcernsRaisedList(newValue: any) {
    if (this.complaintModel) {
      this.complaintModel.concernsRaisedIds = newValue
      const descList: string[] = []
      this.concernsRaisedList
        .filter((e) => newValue.indexOf(e.id) > -1)
        .forEach((e) => {
          descList.push(e.description)
        })
      this.complaintModel.concernsRaisedDescription = descList
    }
  }

  private severityPriority(severity: ComplaintSeverity) {
    if (severity.id <= 2) {
      return 'Low'
    } else if (severity.id <= 4) {
      return 'Medium'
    } else {
      return 'High'
    }
  }

  private severityItemColor(severity: ComplaintSeverity) {
    if (severity.id <= 2) {
      return 'grey--text'
    } else if (severity.id <= 4) {
      return 'orange--text'
    } else {
      return 'red--text'
    }
  }

  // Get list of PaymentReason.
  private async GetPaymentReasonList() {
    const recordType: string = RecordType[RecordType.PaymentReason]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        // TODO: show deleted record also if complaint is already logged using that record.
        this.reasonForPaymentList = res.filter((e) => e.isDeleted === false)
        this.addConfigureOption(this.reasonForPaymentList)
      }
    })
  }
  // Get list of ResolutionCode.
  private GetResolutionCodeList(): void {
    const recordType: string = RecordType[RecordType.ResolutionCode]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.resolutionCodeList = res.filter((e) => e.isDeleted === false)
        if (this.complaintModel && this.complaintModel.resolutionCode.length > 0) {
          this.complaintModel.resolutionCode.forEach((code) => {
            const item: MasterRecord | undefined = res.find((e) => e.isDeleted && code === e.id)
            if (item !== undefined) {
              this.resolutionCodeList.push(item)
            }
          })
        }
        this.addConfigureOption(this.resolutionCodeList)
        const resolutionCode = this.resolutionCodeList.filter((e: any) => e.description !== 'Configure')
        this.resolutionCodeCheckboxes = resolutionCode.map((code) => {
          return {
            checked: false,
          }
        })
      }
    })
  }
  // Get list of complaint type.
  private GetComplaintTypeList(): void {
    const recordType: string = RecordType[RecordType.ComplaintType]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        if (this.complaintModel.complaintType !== undefined) {
          this.complaintTypeList = res.filter(
            (e) => e.isDeleted === false || (e.isDeleted && this.complaintModel.complaintType === e.id)
          )
        } else {
          this.complaintTypeList = res.filter((e) => e.isDeleted === false)
        }
        this.addConfigureOption(this.complaintTypeList)
      }
    })
  }

  @Watch('complaint')
  private complaintChanged(): void {
    this.isLoading = false
    this.loading = false
    if (this.complaint !== this.complaintModel) {
      if (this.complaint) {
        this.complaintModel = { ...this.complaint }
      }
    }
    this.tempComplaintId = !this.complaintModel.id ? this.tempComplaintId : ''
    this.isComplaintResolved = this.complaintModel && this.complaintModel.isResolved ? true : false
  }

  private onComplaintSave() {
    this.SaveComplaint()
  }
  // save complaint to database.
  private SaveComplaint(isResolved = false) {
    this.complaintModel.jobId =
      this.complaintModel.jobId !== null && this.complaintModel.jobId !== undefined
        ? this.complaintModel.jobId
        : this.jobId
    this.complaintModel.createdAt =
      this.complaintModel.createdAt !== null && this.complaintModel.createdAt !== undefined
        ? this.complaintModel.createdAt
        : moment.utc(new Date())
    this.complaintModel.type =
      this.complaintModel.type !== null && this.complaintModel.type !== undefined
        ? this.complaintModel.type
        : 'Complaint'
    this.complaintModel.resolutionCode = this.complaintModel.resolutionCode.filter(
      (e: any) => e.description !== 'Configure'
    )
    this.complaintModel.complaintType =
      typeof this.complaintModel.complaintType === 'object' ? '' : this.complaintModel.complaintType
    const complaintTypeDescription = this.complaintTypeList.find((a) => a.id === this.complaintModel.complaintType)
    this.complaintModel.complaintTypeDescription = complaintTypeDescription ? complaintTypeDescription.description : ''
    if (this.contactPreferenceId) {
      this.complaintModel.contactPreference = this.contactPreference[
        this.contactPreferenceId
      ] as ComplaintContactPreference
    }
    if (this.contactPreferenceId === this.contactPreference.Call) {
      this.complaintModel.mobilePhone = this.getCallMobilePhone
    } else {
      this.complaintModel.mobilePhone = ''
    }
    if (this.complaintModel.isNoticeSent && this.complaintModel.isNoticeSent.toLowerCase() === 'yes') {
      this.complaintModel.noticeSentAt = moment.utc(this.getNoticeSentDate)
    } else {
      this.complaintModel.noticeSentAt = null
    }

    this.complaintModel.initialResponseLetterDate = this.regulatoryLetters.irl.date
    this.complaintModel.initialResponseLetterSent = this.regulatoryLetters.irl.sent
    this.complaintModel.fourWeekLetterDate = this.regulatoryLetters.fwl.date
    this.complaintModel.fourWeekLetterSent = this.regulatoryLetters.fwl.sent
    this.complaintModel.eightWeekLetterDate = this.regulatoryLetters.ewl.date
    this.complaintModel.eightWeekLetterSent = this.regulatoryLetters.ewl.sent
    this.complaintModel.summaryResolutionCommunicationDate = this.regulatoryLetters.src.date
    this.complaintModel.summaryResolutionCommunicationSent = this.regulatoryLetters.src.sent
    this.complaintModel.finalResponseLetterDate = this.regulatoryLetters.frl.date
    this.complaintModel.finalResponseLetterSent = this.regulatoryLetters.frl.sent
    if (this.getComplaintReceivedDate) {
      this.complaintModel.receivedDate = moment.utc(this.getComplaintReceivedDate)
    }

    if (this.complaintModel.escalationRequired && this.complaintModel.escalationRequired.toLowerCase() === 'yes') {
      this.complaintModel.escalationDate = moment.utc(this.getEsacalationDate)
    } else {
      this.complaintModel.escalationDate = null
    }
    if (this.getResolutionCodeList && this.getResolutionCodeList.length > 0) {
      this.complaintModel.dateOfResolution = moment.utc(this.getDateOfResolution)
    } else {
      this.complaintModel.dateOfResolution = null
    }

    // save policy name at a time of saving complaint to show in complaint dashboard
    if (this.job && this.job.policyScheme) {
      this.complaintModel.policyName = this.job.policyScheme
      this.complaintModel.policyNumber = this.job.policyNumber
      this.complaintModel.customerName = this.job.clientFullName
      this.complaintModel.postCode = this.job.postCode
      this.complaintModel.insurerName = this.job.insurer
      if (this.vulnerabilityQAItem) {
        this.complaintModel.isVulnerableCustomer = this.vulnerabilityQAItem.isHealthAffected ? true : false
      }

      const contractorNameList: string[] = []
      const tradeList: string[] = []
      // get contractor list by job emergencies
      this.job.emergencies.forEach((emergency: Emergency) => {
        const contractorAppointedDetails: ContractorAppointedModel[] = storeGetters.getContractorAppointedDetail(
          this.jobId,
          emergency.id
        )
        contractorAppointedDetails.forEach((contractorAppointed: ContractorAppointedModel) => {
          contractorNameList.push(contractorAppointed.companyName)
        })
        tradeList.push(emergency.tradeDescription)
      })
      if (contractorNameList.length > 0) {
        this.complaintModel.assignedContractors = contractorNameList.join(',')
      } else {
        this.complaintModel.assignedContractors = ''
      }

      if (tradeList.length > 0) {
        this.complaintModel.assignedTrades = tradeList.join(',')
      } else {
        this.complaintModel.assignedTrades = ''
      }
    }

    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.isLoading = !isResolved
          this.loading = isResolved
          const complaintDocumentUploadSub = this.$refs.refComplaintDocumentUpload as ComplaintDocumentUpload
          if (!this.complaintModel.id) {
            this.tempComplaintId = Shared.generateGuid()
            setTimeout(() => {
              if (complaintDocumentUploadSub && complaintDocumentUploadSub.selectedFileList.length > 0) {
                complaintDocumentUploadSub.uploadDocuments()
              } else {
                this.saveComplaint()
              }
            }, 0)
          } else {
            this.saveComplaint(false)
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validate(isComnplaintValidated = true): Promise<boolean> {
    let result = true
    // validate the Complaint Form
    const complaintFormValidation: boolean = await this.$validator.validateAll('formReference')
    let contactCallValidation = true
    // validate the contactPreference Call
    if (this.contactPreferenceId === this.contactPreference.Call) {
      const contactCallValidationSub: any = this.$refs.contactPreferenceCall as PhoneNumber
      contactCallValidation =
        (await contactCallValidationSub.$validator.validateAll()) && contactCallValidationSub.isValid
    }

    let resolveComplaintValidation = true
    if (this.showResolveComplaintDialog && isComnplaintValidated) {
      resolveComplaintValidation = await this.$validator.validateAll('formResolveComplaint')
    }

    let complaintResolvedValidation = true
    if (this.complaintModel.isResolved && !this.complaintModel.resolutionSummary) {
      complaintResolvedValidation = await this.$validator.validateAll('formSubmitReference')
    }

    let complaintEscalationValidation = true
    if (this.complaintEscalateDialog) {
      complaintEscalationValidation = await this.$validator.validateAll('formEscalationReference')
    }

    // set focus to non validate field
    if (
      !contactCallValidation ||
      !complaintFormValidation ||
      !complaintResolvedValidation ||
      !resolveComplaintValidation ||
      !complaintEscalationValidation
    ) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result =
        contactCallValidation &&
        complaintFormValidation &&
        complaintResolvedValidation &&
        resolveComplaintValidation &&
        complaintEscalationValidation
    }

    return result
  }

  private scrollToEnd() {
    const container: any = this.$el.querySelector('.container')
    container.scrollTop = container.scrollHeight
  }

  private makePayment() {
    this.GetPaymentReasonList()
    this.complaintPaymentShow = true
    this.scrollToEnd()
  }
  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on paymentreasonlist.
  private onPaymentReasonChange(event: any) {
    if (event === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.PaymentReason]
      this.configureDropdownDialog = true
    }
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on resolutiocodelist.
  private onResolutionCodeChange(selectedItems: any, items: any) {
    if (items[items.length - 1] === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.ResolutionCode]
      this.configureDropdownDialog = true
    } else if (selectedItems.length < items.length) {
      const selectedItem = this.resolutionCodeList.find((e) => e.id === items[items.length - 1])
      if (selectedItem !== undefined) {
        this.resolutionCodeCheckboxes[this.resolutionCodeList.indexOf(selectedItem)].checked = true
      }
    } else if (selectedItems.length > items.length) {
      const itemToRemoveFromCodeList = selectedItems.find((e) => !items.includes(e))
      const item = this.resolutionCodeList.find((e) => e.id === itemToRemoveFromCodeList)
      if (item !== undefined) {
        this.resolutionCodeCheckboxes[this.resolutionCodeList.indexOf(item)].checked = false
      }
    }
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on ComplaintType.
  private onComplaintTypeChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.ComplaintType]
      this.configureDropdownDialog = true
    }
  }

  // when dialog to configure type 'Severity','PaymentReason','ResolutionCode' is closed according list items should be updated.
  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    if (this.configureDropdownType === RecordType[RecordType.PaymentReason]) {
      const deletedRecord: MasterRecord | undefined = this.reasonForPaymentList.find((e) => e.isDeleted === true)
      this.reasonForPaymentList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.reasonForPaymentList.push(deletedRecord)
      }
      this.addConfigureOption(this.reasonForPaymentList)
    } else if (this.configureDropdownType === RecordType[RecordType.ComplaintType]) {
      this.complaintModel.complaintType = ''
      this.complaintModel.complaintTypeDescription = ''
      const deletedRecord: MasterRecord | undefined = this.complaintTypeList.find((e) => e.isDeleted === true)
      this.complaintTypeList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.complaintTypeList.push(deletedRecord)
      }
      this.addConfigureOption(this.complaintTypeList)
    } else if (this.configureDropdownType === RecordType[RecordType.ComplaintRootCause]) {
      this.rootCause = ''
      this.rootCauseComment = ''
      const deletedRecord: MasterRecord | undefined = this.rootCauseList.find((e) => e.isDeleted === true)
      this.rootCauseList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.rootCauseList.push(deletedRecord)
      }
      this.addConfigureOption(this.rootCauseList)
    } else if (this.configureDropdownType === RecordType[RecordType.ComplaintEscalationReason]) {
      this.complaintEscalation.escalationReason = ''
      this.complaintEscalation.escalationReasonId = ''
      const deletedRecord: MasterRecord | undefined = this.complaintEscalationResonList.find(
        (e) => e.isDeleted === true
      )
      this.complaintEscalationResonList = Items.slice(0)
      if (deletedRecord !== undefined) {
        this.complaintEscalationResonList.push(deletedRecord)
      }
      this.addConfigureOption(this.complaintEscalationResonList)
    } else {
      this.complaintModel.resolutionCode = []
      const deletedRecords: MasterRecord[] = this.resolutionCodeList.filter((e) => e.isDeleted === true)
      this.resolutionCodeList = Items.slice(0)
      deletedRecords.forEach((record) => {
        this.resolutionCodeList.push(record)
      })
      if (this.complaintModel) {
        this.complaintModel.resolutionCode = []
      }
      this.resolutionCodeCheckboxes = this.resolutionCodeList.map((code) => {
        return {
          checked: false,
        }
      })
    }
  }

  private addConfigureOption(list: any) {
    if (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    ) {
      const configure: any = {}
      configure.description = 'Configure'
      list.push(configure)
    }
  }

  private phoneNumberUpdated(phoneNumber: string): void {
    this.getCallMobilePhone = phoneNumber
  }

  private escalationDateRequired(index: string) {
    const selectIndex = parseInt(index, 0)
    if (selectIndex <= 6) {
      this.complaintModel.escalationRequired = 'No'
    } else {
      this.complaintModel.escalationRequired = 'Yes'
    }
  }

  private onDeleteDocument(fileName: string) {
    this.complaintModel.files = this.complaintModel.files.filter(
      (e) => e.substring(e.lastIndexOf('/') + 1) !== fileName
    )
    const complaint = Object.assign(new ComplaintModel(), this.complaintModel)
    storeMutations.addOrReplaceComplaint(complaint)
  }

  private onUploadDocument(fileURLs: string[]) {
    if (this.complaintModel.id) {
      // complaint already logged
      this.complaintModel.files.push(...fileURLs)
      const complaint = Object.assign(new ComplaintModel(), this.complaintModel)
      storeMutations.addOrReplaceComplaint(complaint)
      // close complaint template
      this.showComplaintDocumentTemplateSelection = false
      const refReportDocumentTemplate = this.$refs.refReportDocumentTemplate as ReportDocumentTemplate
      if (refReportDocumentTemplate) {
        refReportDocumentTemplate.generatePDFInProcess = false
      }
      if (this.isEscalatedDocumentUploaded) {
        const closeComplaint: AddCloseComplaintRequest = new AddCloseComplaintRequest()
        closeComplaint.complaintId = this.complaintModel.id
        closeComplaint.jobId = this.complaintModel.jobId
        ComplaintController.AddCloseComplaintRequest(closeComplaint)
          .then((res) => {
            if (res) {
              this.complaintModel.isResolved = true
              this.isComplaintResolved = true
            }
            this.isEscalatedDocumentUploaded = false
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error submiting close complaint request, please try again', true)
            this.isLoading = false
            this.isEscalatedDocumentUploaded = false
          })
      }
    } else {
      this.saveComplaint(true, fileURLs)
    }
  }

  private saveComplaint(isNewComplaint = true, fileURLs: string[] = []) {
    let complaint: ComplaintModel = new ComplaintModel()
    if (isNewComplaint) {
      // complaint not logged, call method to save complaint
      complaint = Object.assign(new ComplaintModel(), this.complaintModel)
      complaint.id = this.tempComplaintId
      if (fileURLs.length > 0) {
        complaint.files = fileURLs
      }
    } else {
      complaint = this.complaintModel
    }

    ComplaintController.SaveComplaint(complaint)
      .then((res: boolean) => {
        if (res) {
          this.showPaymentButton = true
        }
        this.tempComplaintId = ''
        this.showResolveComplaintDialog = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving complaint, please try again', true)
        this.isLoading = false
        this.loading = false
        this.tempComplaintId = ''
      })
  }

  private createComplaintDocument() {
    this.createDocument()
  }

  private getDocumentTemplateText(documentTemplateId: number) {
    const self = this
    const refReportDocumentTemplate = this.$refs.refReportDocumentTemplate as ReportDocumentTemplate
    ComplaintController.GetComplaintDocument(self.jobId, self.complaintModel.id, documentTemplateId)
      .then((res: DocumentTemplateModel) => {
        if (refReportDocumentTemplate) {
          refReportDocumentTemplate.handleTemplateResponse(res, documentTemplateId)
        }
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error loading template detail, please try again', true)
        if (refReportDocumentTemplate) {
          refReportDocumentTemplate.loadTemplatesInProgress = false
        }
      })
  }

  private showSnackBarWithMessage(message: string) {
    this.snackbarText = message
    this.showSnackbar = true
  }

  private saveDocument(documentTemplateId: number, templateText: string) {
    const self = this
    self.generateReport('complaint', 'Complaint', documentTemplateId, templateText)
  }

  private generateReport(id: string, desc: string, documentTemplateId: number, templateText: string) {
    const refReportDocumentTemplate = this.$refs.refReportDocumentTemplate as ReportDocumentTemplate
    if (refReportDocumentTemplate) {
      refReportDocumentTemplate.generatePDFInProcess = true
    }
    const pdf = new jsPDF('p', 'pt', 'a4')
    // set font size
    pdf.setFontSize(18)
    // generate pdf from html
    pdf.fromHTML(
      templateText,
      this.marginsForPdf.left,
      this.marginsForPdf.top,
      { width: this.marginsForPdf.width },
      (dispose) => {
        // set header and footer
        this.headerFooterFormatting(pdf, pdf.internal.getNumberOfPages(), desc)
      },
      this.marginsForPdf
    )
    this.saveGeneratedPDFToAzure(pdf, documentTemplateId)
  }

  private saveGeneratedPDFToAzure(pdf: jsPDF, documentTemplateId: number) {
    let templateBlob = new Blob()
    templateBlob = pdf.output('blob')
    const myFile = this.convertBlobToFile(templateBlob, 'complaint_template_' + documentTemplateId + '.pdf')
    const fr = new FileReader()
    fr.readAsDataURL(myFile)
    fr.addEventListener('load', async () => {
      // save file to local filelist.
      const fileSelected: any = {}
      fileSelected.fileName = myFile.name
      fileSelected.fileURL = myFile
      fileSelected.fileType = myFile.name.substr(myFile.name.lastIndexOf('.') + 1)
      const complaintDocumentUpload = this.$refs.refComplaintDocumentUpload as ComplaintDocumentUpload
      await complaintDocumentUpload.uploadSingleDocument(fileSelected, true)
    })
  }

  private convertBlobToFile = (blob: Blob, fileName: string): File => {
    const tempBlob: any = blob
    tempBlob.lastModifiedDate = new Date()
    tempBlob.name = fileName
    return blob as File
  }

  private headerFooterFormatting(doc, totalPages, desc) {
    for (let i = totalPages; i >= 1; i--) {
      doc.setPage(i)
      this.header(doc, desc)
      this.footer(doc, i, totalPages)
      doc.page++
    }
  }

  private header(doc, desc) {
    // set style for header
    // doc.setFontSize(20);
    // doc.setTextColor('#009688');
    // doc.setFontStyle('normal');
    // doc.text(desc + " Report", this.marginsForPdf.left + 190, 30 );
    // doc.setLineCap(2);
    // doc.line(3, 50, this.marginsForPdf.width + 43, 50); // horizontal line
  }

  private footer(doc, pageNumber, totalPages) {
    // set style for footer
    const str = 'Page ' + pageNumber + ' of ' + totalPages
    doc.setLineCap(1)
    doc.setFontSize(10)
    doc.text(str, this.marginsForPdf.left, doc.internal.pageSize.height - 20)
  }

  private resolveComplaint() {
    this.complaintModel.isResolved = true
    this.complaintModel.rootCause = this.rootCause
    this.complaintModel.rootCauseComment = this.rootCauseComment
    const rootCauseDescription = this.rootCauseList.find((e) => e.id === this.complaintModel.rootCause)
    this.complaintModel.rootCauseDescription = rootCauseDescription ? rootCauseDescription.description : ''
    this.SaveComplaint(true)
  }

  private openComplaintEscalateDialog() {
    if (this.complaintEscalationResonList.length === 0) {
      this.getComplaintEscalationResonList()
    }
    this.complaintEscalation = new AddComplaintEscalateRequest()
    if (this.complaintModel.escalationReasonId) {
      this.complaintEscalation.isEscalated = this.complaintModel.isEscalated
      this.complaintEscalation.escalationReasonId = this.complaintModel.escalationReasonId
      this.complaintEscalation.escalationReason = this.complaintModel.escalationReason
      this.complaintEscalation.escalationDescription = this.complaintModel.escalationDescription
    }
    this.complaintEscalateDialog = true
  }

  // Get list of complaint type.
  private getComplaintEscalationResonList(): void {
    const recordType: string = RecordType[RecordType.ComplaintEscalationReason]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.complaintEscalationResonList = res.filter((e) => e.isDeleted === false)
      }
      this.addConfigureOption(this.complaintEscalationResonList)
    })
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on ComplaintEscalationReason.
  private onComplaintEscalationResonChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.ComplaintEscalationReason]
      this.configureDropdownDialog = true
    }
  }

  private onComplaintEscalation() {
    this.validate().then((result: boolean) => {
      if (result) {
        this.isLoadingEscalateDocuments = true
        this.complaintEscalation.complaintId = this.complaintModel.id
        this.complaintEscalation.jobId = this.complaintModel.jobId
        const complaintEscalate = this.complaintEscalationResonList.find(
          (c) => c.id === this.complaintEscalation.escalationReasonId
        )
        this.complaintEscalation.escalationReason = complaintEscalate ? complaintEscalate.description : ''
        this.complaintEscalation.isEscalated = true
        ComplaintController.AddComplaintEscalateRequest(this.complaintEscalation)
          .then((res) => {
            this.isEscalatedDocumentUploaded = true
            this.createEscalateDocuments()
            this.complaintEscalateDialog = false
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error submiting escalate complaint request, please try again', true)
            this.isLoadingEscalateDocuments = false
          })
      }
    })
  }

  private createEscalateDocuments() {
    this.createDocument(true)
  }

  private createDocument(fromEscalateDocument = false) {
    const self = this
    if (!(self.job && self.job.insurerId)) {
      return
    }
    self.isLoadingEscalateDocuments = fromEscalateDocument
    self.loadListOfTemplatesInProgress = !fromEscalateDocument
    self.showComplaintDocumentTemplateSelection = false
    setTimeout(() => {
      self.$validator.errors.items = []
    }, 0)
    self.complaintDocumentTemplateList = []
    if (fromEscalateDocument) {
      ComplaintController.GetComplaintEscalationDocuments(self.job.insurerId)
        .then((res: { [key: number]: string } | null) => {
          this.handleDocumentList(res, fromEscalateDocument)
        })
        .catch((e) => {
          eventBus.$emit('errorHandler', 'Error loading complaint escalation document(s), please try again', true)
          this.stopLoader()
        })
    } else {
      ComplaintController.GetListOfComplaintDocuments(self.job.insurerId)
        .then((res: { [key: number]: string } | null) => {
          this.handleDocumentList(res, fromEscalateDocument)
        })
        .catch((e) => {
          eventBus.$emit('errorHandler', 'Error loading complaint document list, please try again', true)
          this.stopLoader()
        })
    }
  }

  private async savePaymentDetails() {
    const paymentFormValidation: boolean = await this.$validator.validateAll('paymentDetails')

    if (!paymentFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      return
    }

    if (!this.complaintModel.paymentReasonId || !this.complaintModel.paymentReason) {
      return
    }

    const request: AddComplaintPaymentRequest = {
      complaintId: this.complaintModel.id,
      jobId: this.jobId,
      paymentReasonId: this.complaintModel.paymentReasonId,
      paymentReason: this.complaintModel.paymentReason,
      accountHolderFirstName: this.complaintModel.accountHolderFirstName,
      accountHolderLastName: this.complaintModel.accountHolderLastName,
      accountHolderAddress: this.complaintModel.accountHolderAddress,
      accountNumber: this.complaintModel.accountNumber,
      IFSCCode: this.complaintModel.IFSCCode,
      compensationPaid: this.complaintModel.compensationPaid,
      lessonsLearned: this.complaintModel.lessonsLearned,
    }
    this.savePaymentLoading = true
    try {
      const res = await ComplaintController.SavePaymentInfo(request)
    } catch (err) {
      eventBus.$emit('errorHandler', 'Unable to add payment details: ' + err, true)
    }
    this.savePaymentLoading = false
  }

  private handleDocumentList(res: { [key: number]: string } | null, fromEscalateDocument: boolean) {
    const self = this
    if (res) {
      const values = 'values'
      const keyList = Object.keys(res)
      const valueList = Object[values](res)
      for (let index = 0; index < keyList.length; index++) {
        const complaintDocumentTemplate: any = {}
        complaintDocumentTemplate.templateId = Number(keyList[index])
        complaintDocumentTemplate.name = valueList[index]
        self.complaintDocumentTemplateList.push(complaintDocumentTemplate)
      }
      self.showComplaintDocumentTemplateSelection = true
    } else {
      self.showSnackBarWithMessage('No Template(s) Found')
    }
    this.stopLoader()
  }

  private stopLoader() {
    this.isLoadingEscalateDocuments = false
    this.loadListOfTemplatesInProgress = false
  }

  private reOpenConfirmationDialog() {
    const reopenComplaint: ReopenComplaintRequest = new ReopenComplaintRequest()
    reopenComplaint.complaintId = this.complaintModel.id
    reopenComplaint.jobId = this.complaintModel.jobId
    Shared.confirmationPopup.open(
      'Are you sure you want to reopen this complaint?',
      '',
      '',
      'No',
      'Yes',
      this,
      'reOpenComplaint',
      reopenComplaint
    )
  }

  private reOpenComplaint(reopenComplaint: ReopenComplaintRequest) {
    this.isLoading = true
    ComplaintController.AddReopenComplaintRequest(reopenComplaint)
      .then((res) => {
        if (res) {
          this.isComplaintResolved = false
          this.complaintModel.isEscalated = false
          this.complaintModel.isResolved = false
          this.complaintModel.rootCause = ''
          this.complaintModel.escalationReasonId = ''
          this.complaintModel.escalationReason = ''
          this.complaintModel.escalationDescription = ''
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting reopen complaint request, please try again', true)
        this.isLoading = false
      })
  }

  // Get list of Root Cause Reasons.
  private GetRootCauseList(): void {
    const recordType: string = RecordType[RecordType.ComplaintRootCause]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        if (this.complaintModel.rootCause !== undefined) {
          this.rootCauseList = res.filter(
            (e) => e.isDeleted === false || (e.isDeleted && this.complaintModel.rootCause === e.id)
          )
        } else {
          this.rootCauseList = res.filter((e) => e.isDeleted === false)
        }
        this.addConfigureOption(this.rootCauseList)
      }
    })
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on RootCauseReason.
  private onComplaintRootCauseChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.ComplaintRootCause]
      this.configureDropdownDialog = true
    }
  }

  // Get list of Concerns Raised.
  private GetConcernsRaisedList(): void {
    const recordType: string = RecordType[RecordType.ConcernsRaised]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.concernsRaisedList = res.filter((e) => e.isDeleted === false)
        if (
          this.complaintModel &&
          this.complaintModel.concernsRaisedIds &&
          this.complaintModel.concernsRaisedIds.length > 0
        ) {
          this.complaintModel.concernsRaisedIds.forEach((id) => {
            const item: MasterRecord | undefined = res.find((e) => e.isDeleted && id === e.id)
            if (item !== undefined) {
              this.concernsRaisedList.push(item)
            }
          })
        }
        this.addConfigureOption(this.concernsRaisedList)
        const concernsRaisedId = this.concernsRaisedList.filter((e: any) => e.description !== 'Configure')
        this.concernsRaisedIdCheckboxes = concernsRaisedId.map((id) => {
          return {
            checked: false,
          }
        })
      }
    })
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on concernsRaisedList.
  private onConcernsRaisedChange(selectedItems: any, items: any) {
    const itemWithOtherOption = this.concernsRaisedList.find((x) => x.description.toLowerCase() === 'other')
    this.isOtherConcernRaised = itemWithOtherOption ? items.includes(itemWithOtherOption.id) : false
    if (items[items.length - 1] === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.ConcernsRaised]
      this.configureDropdownDialog = true
    } else if (selectedItems.length < items.length) {
      const selectedItem = this.concernsRaisedList.find((e) => e.id === items[items.length - 1])
      if (selectedItem !== undefined) {
        this.concernsRaisedIdCheckboxes[this.concernsRaisedList.indexOf(selectedItem)].checked = true
      }
    } else if (selectedItems.length > items.length) {
      const itemToRemoveFromCodeList = selectedItems.find((e) => !items.includes(e))
      const item = this.concernsRaisedList.find((e) => e.id === itemToRemoveFromCodeList)
      if (item !== undefined) {
        this.concernsRaisedIdCheckboxes[this.concernsRaisedList.indexOf(item)].checked = false
      }
    }
  }

  private formatDate(date: Date | string | moment.Moment) {
    return Shared.getFormatedDate(moment(date), DateTimePicker.DATE_FORMAT)
  }

  private openResolveComplaintDialog() {
    this.validate(false)
      .then((result: boolean) => {
        if (result) {
          if (this.rootCauseList.length === 0) {
            this.GetRootCauseList()
          }
          this.rootCause = this.complaintModel.rootCause ? this.complaintModel.rootCause : ''
          this.rootCauseComment = this.complaintModel.rootCauseComment ? this.complaintModel.rootCauseComment : ''
          setTimeout(() => {
            this.$validator.errors.items = []
          }, 0)
          this.showResolveComplaintDialog = true
        }
      })
      .catch((e) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private closeDocumentTemplateDialog() {
    this.showComplaintDocumentTemplateSelection = false
    this.isEscalatedDocumentUploaded = false
  }

  private updatetLetterRequiredDate(type: string): moment.Moment | null {
    if (this.complaint) {
      const complaintReceivedDate: moment.Moment = this.getComplaintReceivedDate
        ? moment(this.getComplaintReceivedDate)
        : this.complaint.createdAt
      let date: moment.Moment = complaintReceivedDate
      if (type === RegulatoryLetterType[RegulatoryLetterType.InitialResponseLetter]) {
        // set IRL date as 3 working days after complaint received date
        switch (complaintReceivedDate.weekday()) {
          case 0:
            date = moment(complaintReceivedDate).add(4, 'days')
            break
          case 1:
          case 2:
            date = moment(complaintReceivedDate).add(3, 'days')
            break
          default:
            date = moment(complaintReceivedDate).add(5, 'days')
            break
        }
        return date
      } else if (type === RegulatoryLetterType[RegulatoryLetterType.FourWeekLetter]) {
        // set 4WL date as 4 weeks after complaint received date
        date = moment(complaintReceivedDate).add(4, 'weeks')
        // If 4 weeks is a Saturday or Sunday, date should be next working day
        return date.weekday() === 0 ? date.add(1, 'days') : date.weekday() === 6 ? date.add(2, 'days') : date
      } else if (type === RegulatoryLetterType[RegulatoryLetterType.EightWeekLetter]) {
        // set 8WL date as 8 weeks after complaint received date
        date = moment(complaintReceivedDate).add(8, 'weeks')
        // If 8 weeks is a Saturday or Sunday, date should be next working day
        return date.weekday() === 0 ? date.add(1, 'days') : date.weekday() === 6 ? date.add(2, 'days') : date
      }
    }
    return null
  }

  @Watch('getComplaintReceivedDate')
  private onComplaintReceivedDateChange(newValue: moment.Moment | null, oldValue: moment.Moment | null) {
    if ((newValue && oldValue && newValue.isSame(oldValue)) || oldValue === null) {
      return
    }
    this.regulatoryLetters.irl.date = this.updatetLetterRequiredDate(
      RegulatoryLetterType[RegulatoryLetterType.InitialResponseLetter]
    )
    this.regulatoryLetters.fwl.date = this.updatetLetterRequiredDate(
      RegulatoryLetterType[RegulatoryLetterType.FourWeekLetter]
    )
    this.regulatoryLetters.ewl.date = this.updatetLetterRequiredDate(
      RegulatoryLetterType[RegulatoryLetterType.EightWeekLetter]
    )
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private created() {
    this.$store.dispatch('complaintModule/submitRetrieveComplaintSeverities')

    this.GetResolutionCodeList()
    this.GetComplaintTypeList()
    this.GetConcernsRaisedList()
    this.setMinDate = moment(new Date())
    if (this.complaint && this.complaint.jobId) {
      this.complaintModel = { ...this.complaint }
      if (this.complaintModel && this.complaintModel.dateOfResolution) {
        this.resolutionDate = this.complaintModel.dateOfResolution
      }
      if (
        this.complaintModel &&
        this.complaintModel.contactPreference === ComplaintContactPreferenceEnum[ComplaintContactPreferenceEnum.Call]
      ) {
        this.getCallMobilePhone = this.complaintModel.mobilePhone
      }
      this.isComplaintResolved = this.complaintModel.isResolved
      this.showPaymentButton = true

      if (this.complaint) {
        if (this.complaintModel && this.complaintModel.receivedDate) {
          this.complaintReceivedDate = moment(this.complaintModel.receivedDate)
        }

        if (this.complaintModel && this.complaintModel.paymentReasonId && this.complaintModel.paymentReason) {
          this.GetPaymentReasonList().then(() => {
            const reasonObject = this.reasonForPaymentList.find((r) => r.id === this.complaintModel.paymentReasonId)
            this.paymentReason =
              reasonObject ||
              ({
                id: this.complaintModel.paymentReasonId,
                description: this.complaintModel.paymentReason,
              } as MasterRecord)
            this.complaintPaymentShow = true
          })
        }

        this.regulatoryLetters = {
          irl: {
            date:
              this.complaint.initialResponseLetterDate && this.complaint.initialResponseLetterDate.isValid()
                ? moment(this.complaint.initialResponseLetterDate)
                : this.updatetLetterRequiredDate(RegulatoryLetterType[RegulatoryLetterType.InitialResponseLetter]),
            sent:
              this.complaint.initialResponseLetterSent && this.complaint.initialResponseLetterSent.isValid()
                ? moment(this.complaint.initialResponseLetterSent)
                : null,
          },
          fwl: {
            date:
              this.complaint.fourWeekLetterDate && this.complaint.fourWeekLetterDate.isValid()
                ? moment(this.complaint.fourWeekLetterDate)
                : this.updatetLetterRequiredDate(RegulatoryLetterType[RegulatoryLetterType.FourWeekLetter]),
            sent:
              this.complaint.fourWeekLetterSent && this.complaint.fourWeekLetterSent.isValid()
                ? moment(this.complaint.fourWeekLetterSent)
                : null,
          },
          ewl: {
            date:
              this.complaint.eightWeekLetterDate && this.complaint.eightWeekLetterDate.isValid()
                ? moment(this.complaint.eightWeekLetterDate)
                : this.updatetLetterRequiredDate(RegulatoryLetterType[RegulatoryLetterType.EightWeekLetter]),
            sent:
              this.complaint.eightWeekLetterSent && this.complaint.eightWeekLetterSent.isValid()
                ? moment(this.complaint.eightWeekLetterSent)
                : null,
          },
          src: {
            date:
              this.complaint.summaryResolutionCommunicationDate &&
              this.complaint.summaryResolutionCommunicationDate.isValid()
                ? moment(this.complaint.summaryResolutionCommunicationDate)
                : null,
            sent:
              this.complaint.summaryResolutionCommunicationSent &&
              this.complaint.summaryResolutionCommunicationSent.isValid()
                ? moment(this.complaint.summaryResolutionCommunicationSent)
                : null,
          },
          frl: {
            date:
              this.complaint.finalResponseLetterDate && this.complaint.finalResponseLetterDate.isValid()
                ? moment(this.complaint.finalResponseLetterDate)
                : null,
            sent:
              this.complaint.finalResponseLetterSent && this.complaint.finalResponseLetterSent.isValid()
                ? moment(this.complaint.finalResponseLetterSent)
                : null,
          },
        }
      }

      if (this.complaintModel && this.complaintModel.noticeSentAt) {
        this.noticeSentAt = moment(this.complaintModel.noticeSentAt)
      }

      if (this.complaintModel.escalationReasonId) {
        this.getComplaintEscalationResonList()
      }

      if (this.complaintModel.rootCause) {
        this.GetRootCauseList()
      }
    } else {
      this.resolutionDate = moment(new Date())

      // set justified to no for new complaint
      this.complaintModel.justified = 'No'
      if (this.job) {
        this.complaintModel.email = this.job.email
        this.getCallMobilePhone = this.job.mobilePhone
      }
      this.complaintReceivedDate = moment(new Date())
    }
    if (this.getCallMobilePhone !== '' && this.getCallMobilePhone !== null && this.getCallMobilePhone !== undefined) {
      this.contactPreferenceId = this.contactPreference.Call
    } else if (
      this.complaintModel.contactPreference === ComplaintContactPreferenceEnum[ComplaintContactPreferenceEnum.Post]
    ) {
      this.contactPreferenceId = this.contactPreference.Post
    } else if (
      this.complaintModel.contactPreference === ComplaintContactPreferenceEnum[ComplaintContactPreferenceEnum.Client]
    ) {
      this.contactPreferenceId = this.contactPreference.Client
    }

    this.escalationOptions = [{ description: 'Yes' }, { description: 'No' }, { description: 'N/A' }]
    this.faultOptions = [
      { description: 'Terms & Conditions' },
      { description: 'Call Centre Issue' },
      { description: 'Supplier Issue' },
    ]
    this.yesNoOptions = [{ description: 'Yes' }, { description: 'No' }]
  }
}
</script>

<style scoped>
.amount-icon {
  position: relative;
  padding-left: 25px;
}

.amount-icon span {
  position: absolute;
  color: #929090;
  left: 5px;
  top: 26px;
  font-weight: 600;
  font-size: 18px;
}

.uploadCarousel-btn {
  position: absolute !important;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.bold-select {
  font-weight: 600;
}

.radio-group-custom >>> .v-input--radio-group .v-input__control {
  width: 100%;
}
.radio-inline-custom {
  position: relative;
  padding-left: 33px;
}

.radio-inline-custom >>> .v-radio {
  position: absolute;
  left: 0px;
  top: 5px;
}

.radio-inline-custom >>> .v-text-field {
  padding-top: 0px;
  margin-top: 0;
}

.radio-inline-custom >>> label {
  width: auto;
}

.radio-inline-custom >>> .v-input input {
  color: #e91e63 !important;
  font-weight: 600;
}

.radio-inline-custom >>> .v-text-field:not(.radio) {
  padding-left: 50px;
}

.complaint-severity-type,
.severity-icon {
  position: absolute;
}

.severity-icon {
  right: 27px;
  font-size: 14px;
}

.severity-list >>> .v-list__tile {
  padding: 0px;
}

/*PhoneNumber Component*/
.internal-phone-number.complaint {
  width: 100%;
}
.internal-phone-number {
  padding-left: 50px !important;
  position: relative;
}

.internal-phone-number >>> .call-number-txt {
  max-width: calc(100% - 50px);
  display: inline-block;
  padding-left: 0 !important;
  width: 100%;
}

.internal-phone-number >>> .selectedCountry {
  max-width: 50px;
  display: inline-block;
  position: static;
  padding-left: 0 !important;
  vertical-align: top;
}
.internal-phone-number.complaint >>> .validation {
  position: absolute;
  right: 2px;
  top: -4px;
  z-index: 3;
}
.resolve-info {
  position: relative;
  left: 5px;
  bottom: 3px;
}
.letters-padding {
  padding-top: 24px !important;
  padding-bottom: 20px !important;
}
.customer-payment {
  margin-bottom: 50px;
}
.claim-btn-content >>> .complaint-btns.v-card__actions {
  flex-wrap: wrap;
}
.complaint-btns .sticky-btn-content {
  display: flex;
  width: 100%;
}
.customer-payment {
  margin-bottom: 100px;
}
</style>
