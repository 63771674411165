import { Moment } from 'moment'
import { RequestStatus } from './RequestStatus'
import UpdateClientTemplateModel from './UpdateClientTemplateModel'

export default class ClientTemplateReleaseModel {
  public id: string
  public createdAt: Moment
  public createdByName: string
  public createdByNote: string
  public status: RequestStatus
  public errorDetail: string
  public request: UpdateClientTemplateModel
}
