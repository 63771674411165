<template>
  <div>
    <ClientList />
    <ClientReports
      v-if="selectedClientId"
      :insurer-id="selectedClientId"
      :bordereau-id-to-open-details="bordereauIdToOpenDetails"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ClientReports from '@/components/ClientReports.vue'
import ClientList from '@/components/client/ClientList.vue'

@Component({
  components: { ClientList, ClientReports },
})
export default class ClientReportsDashboard extends Vue {
  bordereauIdToOpenDetails = ''

  get selectedClientId(): number {
    return this.$store.getters['clientModule/selectedClientId']
  }

  set selectedClientId(clientId: number) {
    this.$store.dispatch('clientModule/submitSetSelectedClient', clientId)
  }

  mounted(): void {
    this.bordereauIdToOpenDetails = this.$route.params.bordereauName || ''
    const clientId = this.$route.params.insurerId
    if (clientId) {
      this.selectedClientId = parseInt(clientId)
    }
  }
}
</script>

<style scoped></style>
