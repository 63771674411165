<template>
  <div>
    <div class="mb-2">
      <h4>{{ isDocumentMetadataUpdated ? 'Update' : 'Uploaded' }} Job Document</h4>
    </div>
    <div v-if="isDocumentMetadataUpdated">
      <div v-if="items.documentName" class="mb-1">
        <label>Document Name: </label>
        <span>{{ items.documentName }}</span>
      </div>
      <div v-if="items.documentType" class="mb-1">
        <label>Document Type: </label>
        <span>{{ items.documentType }}</span>
      </div>
      <div v-if="items.note" class="mb-1">
        <label>Note: </label>
        <span>{{ items.note }}</span>
      </div>
      <div class="mb-1">
        <label>Available For Engineer: </label>
        <span>{{ items.availableForEngineer ? 'Yes' : 'No' }}</span>
      </div>
    </div>
    <div v-for="(item, index) in items.documents" v-else :key="index">
      <div v-if="item.documentName" class="mb-1">
        <label>Document Name: </label>
        <span>{{ item.documentName }}</span>
      </div>
      <div v-if="item.documentType" class="mb-1">
        <label>Document Type: </label>
        <span>{{ item.documentType }}</span>
      </div>
      <div v-if="item.note" class="mb-1">
        <label>Note: </label>
        <span>{{ item.note }}</span>
      </div>
      <div class="mb-1">
        <label>Available For Engineer: </label>
        <span>{{ item.availableForEngineer ? 'Yes' : 'No' }}</span>
      </div>
      <div v-if="item.uploadedByUserName" class="mb-1">
        <label>Uploaded By: </label>
        <span>{{ item.uploadedByUserName }}</span>
      </div>
      <div v-if="getFormattedDate(item.uploadedAt)" class="mb-1">
        <label>Uploaded At: </label>
        <span>{{ getFormattedDate(item.uploadedAt) }}</span>
      </div>
      <v-divider v-show="index !== items.documents.length - 1" class="mb-1"></v-divider>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UploadedJobDocumentModel from '@/models/UploadedJobDocumentModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import { RequestType } from '@/common/enums'

@Component
export default class UploadJobDocumentLog extends Vue {
  @Prop() private requestBody: any
  @Prop() private requestType: string

  private get isDocumentMetadataUpdated() {
    return this.requestType === RequestType[RequestType.UpdateJobDocumentMetadata]
  }

  private get items(): UploadedJobDocumentModel {
    return Object.assign(new UploadedJobDocumentModel(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }
}
</script>
