<template>
  <div class="pa-3 mt-1">
    <v-form>
      <v-layout wrap>
        <v-flex xs12 mb-2>
          <v-switch
            v-model="hasDelayLocal"
            color="primary"
            hide-details
            label="Log Delay"
            class="delay-switch"
            :readonly="isReadOnly"
            @change="hasDelayChanged"
          ></v-switch>
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="delayCodeLocal"
            v-validate="'required'"
            :items="delayCodeList"
            item-text="description"
            item-value="id"
            label="Delay Code"
            :disabled="!hasDelayLocal"
            :required="hasDelayLocal"
            :class="hasDelayLocal"
            data-vv-scope="frmDelayCode"
            data-vv-name="Delay Code"
            :error-messages="errors.collect('Delay Code')"
            class="delaycode-list"
            :readonly="isReadOnly"
            @change="onDelayCodeChange"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : 'delay-code'">
                    {{ data.item.description }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-select>
        </v-flex>
      </v-layout>
    </v-form>
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <DelayCodeConfiguration
        ref="configureDropdown"
        record-type="DelayCode"
        @closeConfigModal="onConfigureDropdownDialogClose"
      />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import MasterRecord from '@/models/MasterRecord'
import { RecordType } from '@/common/enums'
import MasterRecordController from '@/api/masterRecordController'
import Shared from '@/common/shared'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import store from '@/store'
import eventBus from '@/common/bus'
import DelayCodeConfiguration from '@/components/DelayCodeConfiguration.vue'

@Component({
  components: { ConfigureDropdown, DelayCodeConfiguration },
})
export default class DelayCodes extends Vue {
  @Prop() private hasDelay: boolean
  @Prop() private delayCode: string
  @Prop({ default: false }) private availabilityOutsideSLA: boolean
  @Prop({ default: false }) private isReadOnly: boolean
  private hasDelayLocal = false
  private delayCodeLocal: string | null = null
  private delayCodeList: MasterRecord[] = []
  private configureDropdownDialog = false

  private created() {
    this.SetDelayCodeList()
    this.hasDelayLocal = this.hasDelay
    this.delayCodeLocal = this.delayCode
  }

  private mounted() {
    this.setCustomerRequestedDelayLog()
  }

  public get getDelayCodeList(): MasterRecord[] {
    const delayCodes: MasterRecord[] = storeGetters
      .getDelayCodes()
      .filter((e) => e.isDeleted === false || (e.isDeleted && (this.delayCode ? this.delayCode === e.id : false)))
    return delayCodes
  }

  @Watch('hasDelay')
  @Watch('delayCode')
  private delayCodeChanged() {
    this.hasDelayLocal = this.hasDelay
    this.delayCodeLocal = this.delayCode
  }

  private async hasDelayChanged(): Promise<void> {
    if (this.hasDelayLocal) {
      if (this.delayCode) {
        this.delayCodeLocal = this.delayCode
      } else {
        this.delayCodeLocal = this.delayCodeList && this.delayCodeList.length > 0 ? this.delayCodeList[0].id : null
      }
      try {
        const result = await this.validate()
        if (result) {
          this.$emit('updateDelayCode', this.hasDelayLocal, this.delayCodeLocal)
        }
      } catch (err) {
        eventBus.$emit('validationErrorHandler')
      }
    } else {
      this.delayCodeLocal = null
      this.$emit('updateDelayCode', this.hasDelayLocal, this.delayCodeLocal)
    }
  }

  // Set list of delay code.
  private SetDelayCodeList(): void {
    if (this.getDelayCodeList.length === 0) {
      const recordType: string = RecordType[RecordType.DelayCode]
      MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
        if (res) {
          storeMutations.setDelayCodes(res)
          this.SetConfigure()
        }
      })
    } else {
      this.SetConfigure()
    }
  }

  private SetConfigure(): void {
    this.delayCodeList = this.getDelayCodeList.slice(0)
    if (this.isUserRoleAdministrator) {
      const delayCodeConfigure: any = {}
      delayCodeConfigure.description = 'Configure'
      this.delayCodeList.push(delayCodeConfigure)
    }
  }

  // when dialog to configure type 'DelayCode' is closed according list items should be updated.
  private onConfigureDropdownDialogClose(Items: MasterRecord[]) {
    this.configureDropdownDialog = false
    const deletedRecord: MasterRecord | undefined = this.delayCodeList.find((e) => e.isDeleted === true)
    this.delayCodeList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.delayCodeList.push(deletedRecord)
    }
    const recordType: string = RecordType[RecordType.DelayCode]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        storeMutations.setDelayCodes(res)
        this.SetConfigure()
        this.delayCodeLocal = this.delayCode
      }
    })
  }

  // when 'configure' is selected as an option from dropdown, user can perform add/update/delete actions on DelayCode.
  private onDelayCodeChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownDialog = true
    } else {
      this.$emit('updateDelayCode', this.hasDelayLocal, selectedItems)
    }
  }

  public async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmDelayCode')

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  // Check if userRole is Administrator
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private setCustomerRequestedDelayLog() {
    if (this.availabilityOutsideSLA) {
      const delayCode: MasterRecord | undefined = this.delayCodeList.find(
        (d) => d.id === store.Instance.state.Environment.CustomerRequestedLogDelayId
      )
      if (delayCode) {
        this.delayCodeLocal = delayCode.id
        this.$emit('updateDelayCode', this.hasDelayLocal, this.delayCodeLocal)
      }
    }
  }
}
</script>

<style scoped>
.bold-select {
  font-weight: 600;
}
</style>
