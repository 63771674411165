import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import ReportDetail from '@/models/ReportDetail'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import AddUpdateDrainageReport from '@/models/requests/AddUpdateDrainageReportModel'

export default class ReportController {
  public static async GetReportStatus(jobId: string): Promise<ReportDetail> {
    const res: AxiosResponse = await CallCentreApi.get('Report/GetReportStatus?JobId=' + encodeURIComponent(jobId))

    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async BuildReport(reportDetail: ReportDetail): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Report/BuildReport', reportDetail)
    return res.status >= 200 && res.status < 300
  }

  public static async SendReport(jobId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Report/SendReport?JobId=' + encodeURIComponent(jobId))
    return res.status >= 200 && res.status < 300
  }

  public static async GetDrainageReportTemplates(insurerId: number): Promise<any> {
    const res = await CallCentreApi.get('Report/GetDrainageReportTemplates?insurerId=' + insurerId)
    return res.data ? res.data : []
  }

  public static async GetDrainageReport(
    jobId: string,
    templateId: number,
    documentId: string
  ): Promise<DocumentTemplateModel> {
    const res: AxiosResponse = await CallCentreApi.get<DocumentTemplateModel>(
      'Report/GetDrainageReport?jobId=' + jobId + '&templateId=' + templateId + '&documentId=' + documentId
    )
    return res.data
  }

  public static async SaveDrainageReport(addUpdateDrainageReport: AddUpdateDrainageReport): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Report/SaveDrainageReport', addUpdateDrainageReport)
    return res.status >= 200 && res.status < 300
  }
}
