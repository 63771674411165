import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import AddComplaintDocumentsModel from '@/models/requests/AddComplaintDocumentsModel'
import DeleteComplaintDocumentModel from '@/models/requests/DeleteComplaintDocumentModel'
import ComplaintModel from '@/models/claim/ComplaintModel'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import AddComplaintEscalateRequest from '@/models/requests/AddComplaintEscalateRequest'
import AddCloseComplaintRequest from '@/models/requests/AddCloseComplaintRequest'
import ReopenComplaintRequest from '@/models/requests/ReopenComplaintRequest'
import AddComplaintPaymentRequest from '@/models/requests/AddComplaintPaymentRequest'

export default class ComplaintController {
  public static async GetComplaintSeverities(): Promise<{
    [key: number]: string
  }> {
    const res: AxiosResponse = await CallCentreApi.get<DocumentTemplateModel>('Complaint/GetComplaintSeverities')
    return res.data
  }

  public static async AddComplaintDocuments(addComplaintDocumentModel: AddComplaintDocumentsModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Complaint/AddComplaintDocuments', addComplaintDocumentModel)
    return res.status >= 202 && res.status < 300
  }

  public static async DeleteComplaintDocument(deleteComplaintDocument: DeleteComplaintDocumentModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Complaint/DeleteComplaintDocument', deleteComplaintDocument)
    return res.status >= 202 && res.status < 300
  }

  public static async SaveComplaint(complaint: ComplaintModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Complaint/SaveComplaint', complaint)
    return res.status >= 200 && res.status < 300
  }

  public static async GetListOfComplaintDocuments(insurerId: number): Promise<{ [key: number]: string } | null> {
    const res: AxiosResponse = await CallCentreApi.get<{
      [key: number]: string
    }>('Complaint/GetListOfComplaintDocuments?insurerId=' + insurerId)
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async GetComplaintDocument(
    jobId: string,
    complaintId: string,
    templateId: number
  ): Promise<DocumentTemplateModel> {
    const res: AxiosResponse = await CallCentreApi.get<DocumentTemplateModel>(
      'Complaint/GetComplaintDocument?jobId=' + jobId + '&complaintId=' + complaintId + '&templateId=' + templateId
    )
    return res.data
  }

  public static async AddComplaintEscalateRequest(
    clientUserModel: AddComplaintEscalateRequest
  ): Promise<AddComplaintEscalateRequest | string> {
    const res: AxiosResponse = await CallCentreApi.post('Complaint/AddComplaintEscalateRequest', clientUserModel)
    return res.status >= 200 && res.status < 300 ? res.data : res.statusText
  }

  public static async GetComplaintEscalationDocuments(insurerId: number): Promise<any> {
    const res = await CallCentreApi.get('Complaint/GetComplaintEscalationDocuments?insurerId=' + insurerId)
    return res.data ? res.data : []
  }

  public static async GetEscalationDocument(
    jobId: string,
    complaintId: string,
    templateId: number
  ): Promise<DocumentTemplateModel> {
    const res = await CallCentreApi.get(
      'Complaint/GetEscalationDocument?jobId=' + jobId + '&complaintId=' + complaintId + '&templateId=' + templateId
    )
    return res.data ? res.data : []
  }

  public static async AddCloseComplaintRequest(
    clientUserModel: AddCloseComplaintRequest
  ): Promise<AddCloseComplaintRequest | string> {
    const res: AxiosResponse = await CallCentreApi.post('Complaint/AddCloseComplaintRequest', clientUserModel)
    return res.status >= 200 && res.status < 300 ? res.data : res.statusText
  }

  public static async AddReopenComplaintRequest(
    clientUserModel: ReopenComplaintRequest
  ): Promise<ReopenComplaintRequest | string> {
    const res: AxiosResponse = await CallCentreApi.post('Complaint/AddReopenComplaintRequest', clientUserModel)
    return res.status >= 200 && res.status < 300 ? res.data : res.statusText
  }

  public static async SavePaymentInfo(model: AddComplaintPaymentRequest): Promise<AddComplaintPaymentRequest | string> {
    const res: AxiosResponse = await CallCentreApi.post('Complaint/SavePaymentInfo', model)

    return res.status >= 200 && res.status < 300 ? res.data : res.statusText
  }
}
