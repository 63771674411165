import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import { RequestStatus, EngineeringRequestCategoryType } from '@/models/types'
import EngineerRequestBase from './engineerRequest/EngineerRequestBase'
import { IMRequestProcessStatus } from '@/common/enums'
import moment from 'moment'

export default class EngineerRequestModel extends CosmosJobsDbRecordBase {
  public forEmergencyTypeId: number
  public forEmergencyDetailId: number
  public requestStatus: RequestStatus
  public rejectReason: string
  public engineerRequests: EngineerRequestBase[]
  public assignedUserId: string
  public assignedStatus: IMRequestProcessStatus
  public assignedAt: moment.Moment | null
  public completionNote: string
  public completedAt: moment.Moment | null
  public requestCategory: EngineeringRequestCategoryType | null
  public engineerOnSite: moment.Moment | null
  public engineerVisitDetailId: string
  public contractorMaterialsValue: number
  public contractorTimeValue: number
  public contractorExtraVisitValue: number
}
