<template>
  <div>
    <div class="mb-2"><h4>Escalate Job Request</h4></div>
    <div v-if="item.escalationReason" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.escalationReason }}</span>
    </div>
    <div v-if="item.escalationDescription" class="mb-1">
      <label>Description: </label>
      <span>{{ item.escalationDescription }}</span>
    </div>
    <div v-if="item.channelId" class="mb-1">
      <label>Notification sent to channel: </label>
      <span>{{ item.channelId }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddEscalateJobRequest from '@/models/requests/AddEscalateJobRequestModel'

@Component
export default class AddEscalateJobRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddEscalateJobRequest {
    return Object.assign(new AddEscalateJobRequest(), this.requestBody)
  }
}
</script>
