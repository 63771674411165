<template>
  <div>
    <div v-show="loader">
      <v-progress-circular
        :width="2"
        :size="50"
        indeterminate
        color="primary"
        :style="getLoaderStyle(50)"
      ></v-progress-circular>
    </div>
    <v-card v-show="!loader" class="pt-0 px-2 pb-2">
      <v-card-title>
        <v-layout>
          <v-flex xs12>
            <h3 class="heading">
              <span class="teal--text">{{ getSoftFixedEmergency.type }}</span>
            </h3>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="getSoftFixedType">
          <span class="softfix-type">
            <b>Type:</b>
            <span>{{ getSoftFixedType() }}</span>
          </span>
        </div>
        <div v-if="getSoftFixedEmergency.description" class="mt-1">
          <span class="softfix-type">
            <b>Description:</b>
            <span>{{ getSoftFixedEmergency.description }}</span>
          </span>
        </div>
        <div v-if="getSoftFixedEmergency.description && getUser" class="mt-2 text-xs-right">
          <em>Accepted by {{ getUser.displayValue }} &nbsp;</em>
          <v-avatar class="mr-2 avatar">
            <img :src="getUser.profileImageUrlWithUnknownFallback" alt="Logged user" />
          </v-avatar>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import SoftFixedEmergency from '@/models/claim/SoftFixedEmergencyModel'
import MasterRecordController from '@/api/masterRecordController'
import { RecordType } from '@/common/enums'
import MasterRecord from '@/models/MasterRecord'
import UserModel from '@/models/user/UserModel'
import Shared from '@/common/shared'

@Component
export default class SoftFixPreview extends TimeLineItemBase {
  private softFixedEmergencyTypeList: MasterRecord[] = []
  private loader = true

  private get getSoftFixedEmergency(): SoftFixedEmergency {
    return storeGetters.getSoftFixedEmergencyDetailById(this.jobId, this.itemId)
  }

  private created() {
    this.getSoftFixedEmergencyTypeList()
  }

  // Get list of complaint types.
  private getSoftFixedEmergencyTypeList(): void {
    const recordType: string = RecordType[RecordType.SoftFixedEmergencyTitle]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.softFixedEmergencyTypeList = res
      }
    })
  }

  private getSoftFixedType(): string | null | undefined {
    if (this.softFixedEmergencyTypeList) {
      const getComplaintType: MasterRecord | undefined = this.softFixedEmergencyTypeList.find((c) =>
        this.getSoftFixedEmergency ? c.id === this.getSoftFixedEmergency.title : false
      )
      if (getComplaintType) {
        this.loader = false
        return getComplaintType.description
      } else {
        return null
      }
    }
  }

  private get getUsers(): UserModel[] {
    return storeGetters.getUsers()
  }

  private get getUser(): UserModel | undefined {
    return this.getUsers.find((x) => x.id === this.getSoftFixedEmergency.softFixedBy)
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style scoped>
pre {
  font-family: inherit;
  white-space: pre-line;
}
.softfix-type {
  font-size: 15px !important;
  font-weight: normal;
}
.user-icon {
  font-size: 19px;
  background-color: #e0dbdb;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 9px;
}
.v-avatar {
  height: 32px !important;
  width: 32px !important;
}
</style>
