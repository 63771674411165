<template>
  <v-layout v-if="getCustomerAppointedTradespeople" wrap>
    <v-flex v-if="!getCustomerAppointedTradespeople.invoiceImageUrl" xs12>
      <b class="red--text">Awaiting Customer's Invoice</b>
    </v-flex>
    <v-flex v-else xs12>
      <b class="green--text">Customer's Invoice Uploaded</b>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import CustomerAppointedTradespeople from '@/models/claim/CustomerAppointedTradespeople'

@Component
export default class CustomerAppointedTradespeopleCard extends TimeLineItemBase {
  private get getCustomerAppointedTradespeople(): CustomerAppointedTradespeople | null {
    return storeGetters.getCustomerAppointedTradespeople(this.jobId, this.itemId)
  }
}
</script>
