import EngineerRequestBase from './EngineerRequestBase'
import moment from 'moment'

export default class EngineerReturnVisitRequest extends EngineerRequestBase {
  public returnVisitType: string
  public returnVisitAt: moment.Moment | null
  public reasonForReturnVisit: string
  public customerSignatureUrl: string | null
  public returnVisitDuration: number

  public constructor() {
    super()
    this.customerSignatureUrl = null
    this.returnVisitAt = null
  }
}
