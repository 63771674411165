<template>
  <v-flex xs12 class="pa-2 pl-3">
    <v-snackbar
      :value="errorState"
      absolute
      :multi-line="true"
      class="mt-5"
      auto-height
      color="red lighten-2"
      top
      center
    >
      {{ errorMessage }}
      <v-btn color="white" flat @click="clearErrorMessage">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <span v-if="showInputFields">
      <CardTextField
        label="Card number"
        :detail="details.cardNumber"
        :disabled="retrievalDisabled"
        @retrieve="retrieveCardNumber"
      />
      <CardTextField
        label="Expiry date"
        :detail="details.expiryDate"
        :disabled="retrievalDisabled"
        @retrieve="retrieveExpiryDate"
      />
      <CardTextField label="CVC" :detail="details.cvc" :disabled="retrievalDisabled" @retrieve="retrieveCVC" />
      <div class="text-right">
        <v-btn color="primary" class="mr-0 btn-payment" :disabled="cancelDisabled" @click="() => cancel(false)">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="mr-0 btn-payment"
          :disabled="retrievalIncomplete || isPaymentDetailConfirmationPending"
          @click="completeRetrieval"
        >
          Confirm
        </v-btn>
      </div>
      <div v-if="isPaymentDetailConfirmationPending" class="orange--text"
        >Waiting for payment details to be confirmed</div
      >
    </span>
    <div v-else class="text-center ma-3">
      <span class="complete-text font-weight-bold"> &check; &nbsp; Payment details acquired </span>
      <div>
        <v-btn
          v-if="$store.getters['agentAssistModule/active']"
          color="secondary"
          class="secondary-cancel-button mt-3"
          @click="() => cancel(true)"
        >
          Start Again
        </v-btn>
      </div>
    </div>
  </v-flex>
</template>

<script lang="ts">
import CardTextField from './AssistedPayTextField.vue'
import AgentAssistController from '@/api/AgentAssistController'
import TwilioController from '@/api/twilioController'
import { AssistedPayCaptureType } from '@/common/enums'
import AssistedPaySignalRWatcher from './AssistedPaySignalRWatcher'
import CardDetail from '@/models/twilio/assistedPay/CardDetail'
import TwilioUpdateDTO from '@/api/models/assistedPay/TwilioUpdateDTO'
import TwilioTokenDTO from '@/api/models/assistedPay/TwilioTokenDTO'
import TwilioCardBrandDTO from '@/api/models/assistedPay/TwilioCardBrandDTO'
import AssistedPayResponse from '@/api/models/assistedPay/AssistedPayResponse'
import Vue from 'vue'
import { FormatDate, FormatPaymentCard, FormatCVC } from '@/common/StringFormatting'

export default Vue.extend({
  components: {
    CardTextField,
  },
  props: {
    callSid: {
      type: String,
      required: true,
    },
    showCompleted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      details: {
        cardNumber: new CardDetail({
          format: FormatPaymentCard,
          type: AssistedPayCaptureType.CardNumber,
        }),
        expiryDate: new CardDetail({
          format: FormatDate,
          type: AssistedPayCaptureType.ExpiryDate,
        }),
        cvc: new CardDetail({
          format: FormatCVC,
          type: AssistedPayCaptureType.CVC,
        }),
      },
      customerCallSid: null as string | null | undefined,
      paymentSid: null as string | null | undefined,
      signalRWatcher: null as AssistedPaySignalRWatcher | null,
      errorMessage: '',
      detailBeingUpdated: null as CardDetail | null,
      isPaymentDetailConfirmationPending: false,
    }
  },
  computed: {
    retrievalIncomplete: function () {
      return !this.details.cardNumber.isSet || !this.details.expiryDate.isSet || !this.details.cvc.isSet
    },
    errorState: function () {
      return !!this.errorMessage
    },
    retrievalDisabled: function () {
      return !!this.detailBeingUpdated || !this.paymentSid
    },
    cancelDisabled: function () {
      return !this.paymentSid
    },
    complete: function () {
      return !!this.$store.getters['agentAssistModule/token']
    },
    showInputFields: function () {
      return (
        !this.$store.getters['agentAssistModule/token'] &&
        !this.$store.getters['agentAssistModule/complete'] &&
        !this.showCompleted
      )
    },
  },
  watch: {
    detailBeingUpdated(newValue: CardDetail | null, oldValue: CardDetail | null) {
      if (oldValue) {
        oldValue.awaiting = false
      }
      if (newValue) {
        newValue.awaiting = true
      }
    },
  },
  mounted() {
    if (this.$store.getters['agentAssistModule/active']) {
      this.initiate()
    }
  },
  async destroyed() {
    if (this.paymentSid && this.customerCallSid) {
      await AgentAssistController.Cancel(this.customerCallSid, this.paymentSid)
    }

    if (this.signalRWatcher) {
      this.signalRWatcher.off('updateReceived', this.handleTwilioUpdate)
      this.signalRWatcher.off('tokenReceived', this.handleTwilioToken)
      this.signalRWatcher.off('brandReceived', this.handleCardBrand)
      this.signalRWatcher.disconnect()
    }
  },
  methods: {
    initiate: async function () {
      const callSidResponse = await TwilioController.RetrieveCustomerCallSid(this.callSid)
      if (callSidResponse.callSid) {
        this.customerCallSid = callSidResponse.callSid

        const assistedPayResponse = await AgentAssistController.Initiate(this.customerCallSid)
        if (assistedPayResponse.success) {
          this.paymentSid = assistedPayResponse.payload
          this.$emit('inProgressChanged', true)
          this.signalRWatcher = new AssistedPaySignalRWatcher()
          this.signalRWatcher.on('updateReceived', this.handleTwilioUpdate)
          this.signalRWatcher.on('tokenReceived', this.handleTwilioToken)
          this.signalRWatcher.on('brandReceived', this.handleCardBrand)
        } else {
          this.errorMessage =
            'Unable to start assisted payment. Please take details manually. Error message: ' +
            assistedPayResponse.payload
        }
      } else {
        this.errorMessage =
          'Unable to retrieve customer callSid. Please take details manually. Exception: ' + callSidResponse.exception
      }
    },
    retrieveCardNumber: async function () {
      if (this.paymentSid && this.customerCallSid) {
        const assistedPayResponse = await this.details.cardNumber.update(this.customerCallSid, this.paymentSid)
        this.updateProgressState(assistedPayResponse, this.details.cardNumber)
      }
    },
    retrieveExpiryDate: async function () {
      if (this.paymentSid && this.customerCallSid) {
        const assistedPayResponse = await this.details.expiryDate.update(this.customerCallSid, this.paymentSid)
        this.updateProgressState(assistedPayResponse, this.details.expiryDate)
      }
    },
    retrieveCVC: async function () {
      if (this.paymentSid && this.customerCallSid) {
        const assistedPayResponse = await this.details.cvc.update(this.customerCallSid, this.paymentSid)
        this.updateProgressState(assistedPayResponse, this.details.cvc)
      }
    },
    updateProgressState: function (result: AssistedPayResponse, detail: CardDetail) {
      if (result.success) {
        this.detailBeingUpdated = detail
      } else {
        this.errorMessage = 'Unable to start retrieving details. Exception: ' + result.payload
      }
    },
    cancel: async function (reset: boolean) {
      if (this.paymentSid && this.customerCallSid) {
        await AgentAssistController.Cancel(this.customerCallSid, this.paymentSid)

        this.details.cardNumber.reset()
        this.details.expiryDate.reset()
        this.details.cvc.reset()
        this.$store.commit('agentAssistModule/reset')
        this.paymentSid = null
        this.detailBeingUpdated = null
        this.$emit('inProgressChanged', false)

        if (reset) {
          this.$emit('clearErrorMessage')
          this.initiate()
        }
      }
    },
    completeRetrieval: async function () {
      if (this.paymentSid && this.customerCallSid) {
        const assistedPayResponse = await AgentAssistController.Complete(this.customerCallSid, this.paymentSid)

        if (assistedPayResponse.success) {
          // Waiting for SignalR callback response.
          this.isPaymentDetailConfirmationPending = true
        } else {
          this.errorMessage = 'Unable to complete assisted payment. Exception: ' + assistedPayResponse.payload
        }
      }
    },
    clearErrorMessage: function () {
      this.errorMessage = ''
    },
    handleTwilioUpdate: function (error: string, payload: TwilioUpdateDTO) {
      if (error) {
        this.errorMessage = 'An error occurred: ' + error
        if (this.detailBeingUpdated) {
          this.detailBeingUpdated.reset()
          this.detailBeingUpdated = null
        }
      } else {
        if (this.detailBeingUpdated && this.detailBeingUpdated.type === payload.captureType) {
          this.detailBeingUpdated.setValue(payload.newValue)
          if (!payload.partialResult) {
            this.detailBeingUpdated.isSet = true
            this.detailBeingUpdated = null
          }
        }
      }
    },
    handleTwilioToken: async function (error: string | null, payload: TwilioTokenDTO) {
      this.isPaymentDetailConfirmationPending = false
      if (error) {
        this.errorMessage = 'An error occurred: ' + error
      } else {
        if (payload.token) {
          this.$store.commit('agentAssistModule/token', payload.token)
          this.$store.commit('agentAssistModule/last4', this.details.cardNumber.value.slice(-4))
        } else {
          this.errorMessage = 'An invalid payment token was returned! Please try again'
          await this.cancel(true)
        }
      }
    },
    handleCardBrand: async function (error: string, payload: TwilioCardBrandDTO) {
      if (error) {
        this.errorMessage = 'An error occurred: ' + error
      } else {
        if (payload.brand) {
          this.$store.commit('agentAssistModule/brand', payload.brand)
        } else {
          this.errorMessage = 'An invalid card brand was returned! Please try again'
          await this.cancel(true)
        }
      }
    },
  },
})
</script>

<style scoped>
.complete-text {
  font-size: 16px;
}

.secondary-cancel-button {
  border-radius: 28px;
  font-size: 11px;
}
</style>
