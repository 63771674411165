import UpdateEngineerRequestStatus from '@/models/requests/UpdateEngineerRequestStatus'
import UpdateEmergencyAcceptedOrRejected from '@/models/requests/UpdateEmergencyAcceptedOrRejected'
import UpdateJobPolicySchedule from '../requests/UpdateJobPolicySchedule'
export default class EscalateCTPResponseModel {
  public extraAmountNeedToPay: number
  public useExtraBalance: boolean
  public recordType: string
  public acceptRejectEmergency: UpdateEmergencyAcceptedOrRejected | null
  public updateEngineerRequest: UpdateEngineerRequestStatus | null
  public updateJobPolicySchedule: UpdateJobPolicySchedule | null

  public constructor() {
    this.acceptRejectEmergency = null
    this.updateEngineerRequest = null
    this.updateJobPolicySchedule = null
  }
}
