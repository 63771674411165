<template>
  <div v-if="financialSummary" class="question-title-desc">
    <div class="small-text">
      Job will be invoiced at &pound;{{ financialSummary.invoice ? financialSummary.invoice.totalCost : 0 }}
    </div>
    <div class="small-text">
      Job will cost &pound;{{ financialSummary.purchase ? financialSummary.purchase.totalCost : 0 }}
    </div>

    <v-layout class="mt-2" wrap>
      <v-flex lg7 xs12>
        <div class="small-text text-gray">Awaiting PO and Invoice</div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import FinancialSummaryModel from '@/models/claim/FinancialSummaryModel'
import storeGetters from '@/storeGetters'
import TimeLineItemBase from '@/components/TimeLineItemBase'

@Component
export default class FinancialSummaryCard extends TimeLineItemBase {
  private get financialSummary(): FinancialSummaryModel | null {
    return storeGetters.getFinancialSummary(this.jobId)
  }
}
</script>
