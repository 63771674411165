import moment from 'moment'

export default class OtherJobFeeDetailModel {
  public berAmount: number
  public alternativeAccommodationAmount: number
  public temporaryHeatersAmount: number
  public notes: string
  public otherFeesDetailCreatedAt: moment.Moment | null

  public constructor() {
    this.berAmount = 0
    this.alternativeAccommodationAmount = 0
    this.temporaryHeatersAmount = 0
    this.notes = ''
    this.otherFeesDetailCreatedAt = null
  }
}
