<template>
  <div class="questions-list-content">
    <EmergencyQAEditControl
      v-for="item in items"
      :key="item.id"
      :job-id="jobId"
      :emergency-q-a-id="item.id"
      :is-job-completed="isJobCompleted"
      @setSyncing="setSyncing"
    />

    <div class="claim-btn-content grey lighten-3">
      <v-card wrap class="elevation-0 grey lighten-3">
        <v-card-actions xs12>
          <v-spacer></v-spacer>
          <v-btn
            v-if="job.status !== 'Completed'"
            :class="showHideButtonCriteria ? 'green white--text' : isLoading ? 'grey' : ''"
            :disabled="getCompleteJobButtonCriteria || isLoading || isSyncing"
            :loading="isLoading"
            @click="onCompleteHelpline"
          >
            Complete Job
          </v-btn>
          <v-btn
            v-else-if="!clientClaimSubmitted"
            :class="showHideButtonCriteria ? 'green white--text' : isLoading ? 'grey' : ''"
            :disabled="!showHideButtonCriteria || isLoading"
            :loading="isLoading"
            @click="onResendClientEmail"
          >
            Resend Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQA from '@/models/EmergencyQA'
import storeGetters from '@/storeGetters'

import EmergencyQAEditControl from '@/components/timeline/EmergencyQAEditControl.vue'
import store from '@/store'
import JobController from '@/api/jobController'
import Shared from '@/common/shared'
import { DocumentTemplateType } from '@/common/enums'
import SendClientEmail from '@/models/requests/SendClientEmail'
import AddRequestToCompleteHelplineJobModel from '../../models/requests/AddRequestToCompleteHelplineJobModel'
import eventBus from '@/common/bus'
import { computed } from 'vue'
import { useTimelineStore } from '@/pinia/timeline'

@Component({
  components: { EmergencyQAEditControl },
})
export default class PolicyWideQAPreview extends TimeLineItemBase {
  private isLoading = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private syncing = false

  public clientClaimSubmitted = computed(() => useTimelineStore().cards.find((x) => x.type === 'ClientClaimSubmitted'))

  // isSyncing disable complete buttton untill sync is not completed
  private get isSyncing(): boolean {
    return this.syncing
  }

  private setSyncing(sync: boolean) {
    this.syncing = sync
  }

  private get items(): EmergencyQA[] {
    if (this.jobId) {
      return storeGetters.getPolicyWideQAs(this.jobId).sort((a, b) => a.orderByForDisplay - b.orderByForDisplay)
    }
    return []
  }

  private get isUserRoleSupervisor(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Finance')
    )
  }

  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private get isUserRoleIM(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'IncidentManager'
    )
  }

  // Complete Button Criteria As Per Role
  private get showHideButtonCriteria(): boolean {
    return this.isUserRoleSupervisor ||
      this.isUserRoleAdministrator ||
      this.isUserRoleIM ||
      this.isUserRoleClientManager
      ? true
      : false
  }

  private get isJobCompleted(): boolean {
    if (this.job) {
      if (this.job.status === 'Completed') {
        return true
      }
    }
    return false
  }

  private onCompleteHelpline() {
    this.isLoading = true
    const completeJobHelplineModel: AddRequestToCompleteHelplineJobModel = new AddRequestToCompleteHelplineJobModel()
    completeJobHelplineModel.jobId = this.jobId
    completeJobHelplineModel.helplinePolicyId = this.job && this.job.helplinePolicy ? this.job.helplinePolicy.id : ''
    JobController.CompleteHelplineJob(completeJobHelplineModel)
      .then((res: boolean) => {
        if (res) {
          this.snackbarText = 'Job Completed Successfully.'
          this.snackbar = true
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting complete helpline job request, please try again', true)
        this.isLoading = false
      })
  }

  private onResendClientEmail() {
    this.isLoading = true
    const clientEmailModel: SendClientEmail = new SendClientEmail()
    clientEmailModel.jobId = this.jobId
    clientEmailModel.documentTemplateType = DocumentTemplateType.HelplineCompleted
    clientEmailModel.accessibleRecords = {
      JobDetail: this.jobId,
      HelplinePolicy: this.job && this.job.helplinePolicy ? this.job.helplinePolicy.id : '',
    }
    JobController.ResendClientEmail(clientEmailModel)
      .then((res: boolean) => {
        if (res) {
          this.isLoading = false
          this.snackbarText = 'Email Sent Successfully.'
          this.snackbar = true
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting resend client email request, please try again', true)
        this.isLoading = false
      })
  }

  private get getUserAbilityToCompleteJob() {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.canAcceptRejectEmergency
    )
  }

  // Complete-Job Button Criteria As Per Role
  private get getCompleteJobButtonCriteria(): boolean {
    if (this.isUserRoleSupervisor || this.isUserRoleClientManager) {
      if (this.getUserAbilityToCompleteJob) {
        return false
      } else {
        const result: boolean = this.criteriaToCompleteJob() === true ? false : true
        return result
      }
    } else if (this.isUserRoleAdministrator) {
      if (this.getUserAbilityToCompleteJob) {
        return false
      } else {
        const result: boolean = this.criteriaToCompleteJob() === true ? false : true
        return result
      }
    } else if (this.isUserRoleIM) {
      if (this.getUserAbilityToCompleteJob) {
        return false
      } else {
        const result: boolean = this.criteriaToCompleteJob() === true ? false : true
        return result
      }
    } else {
      return true
    }
  }

  // criteria to complete job
  private criteriaToCompleteJob(): boolean {
    let mandatoryQA = true
    let invalidQA = true

    // All mandatory questions have been answered.
    this.items.forEach((element: EmergencyQA) => {
      if (!element.isValid && element.validationStatus === 'missing') {
        mandatoryQA = false
        return
      }
    })

    // There are no invalid responses to any questions.
    this.items.forEach((element: EmergencyQA) => {
      if (!element.isValid && element.validationStatus === 'invalid') {
        invalidQA = false
        return
      }
    })

    // Check all criteria to complete job
    if (mandatoryQA && invalidQA) {
      return true
    }

    return false
  }

  private get isUserRoleClientManager(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  @Watch('job.status')
  public JobCompleted() {
    if (this.job && this.job.status === 'Completed') {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.questions-list-content {
  position: relative;
  padding-bottom: 50px;
}
.input-group--text-field label {
  position: absolute;
  top: 18px;
  left: 0;
  white-space: inherit;
  word-wrap: break-word;
  height: auto;
  line-height: 1.4;
  max-width: 100%;
}
</style>
