import CosmosAdminDbRecordBase from '../CosmosAdminDbRecordBase'
import CookieSettings from './cookie-settings'
import ScriptTagSettings from './script-tag-settings'
export default class CookieRecord extends CosmosAdminDbRecordBase {
  public name = ''
  public description = ''
  public settings: CookieSettings[] = []
  public scripts: ScriptTagSettings[] = []
  public isDefault = false
}
