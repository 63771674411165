<template>
  <div v-if="item">
    <div class="mb-2">
      <h4>{{ getLogTitle }}</h4>
    </div>
    <div v-if="item.companyName" class="mb-1">
      <label>Contractor: </label>
      <span>{{ item.companyName }}</span>
    </div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="item.status === jobVisitStatus[jobVisitStatus.Unavailable]">
      <div class="mb-1">
        <label>Reason: </label>
        <span>{{ item.reasonType }}</span>
      </div>
      <div v-if="item.reasonDescription" class="mb-1">
        <label>Description: </label>
        <span>{{ item.reasonDescription }}</span>
      </div>
    </div>
    <div v-else>
      <div v-if="item.etaFrom" class="mb-1">
        <label>Date From: </label>
        <span>{{ getFormattedDate(item.etaFrom) }}</span>
      </div>
      <div v-if="item.etaTo" class="mb-1">
        <label>Date To: </label>
        <span>{{ getFormattedDate(item.etaTo) }}</span>
      </div>
      <div v-if="item.additionalTravelTime" class="mb-1">
        <label>Additional Travel Time: </label>
        <span>{{ getFormatedTravelTime(item.additionalTravelTime) }}</span>
        <span>(Charge: {{ getFormatedCurrency(item.additionalCharge) }})</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddContractorAppointedModel from '@/models/requests/AddContractorAppointedModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import ContractorModel from '@/models/contractor/ContractorModel'
import { JobVisitStatus } from '@/common/enums'

@Component
export default class AddContractorAppointedDetailLog extends Vue {
  @Prop() private requestBody: any
  private jobVisitStatus = JobVisitStatus

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.item.emergencyId)
  }

  private get item(): AddContractorAppointedModel {
    return Object.assign(new AddContractorAppointedModel(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }

  private get getLogTitle() {
    if (this.item.status === JobVisitStatus[JobVisitStatus.Unavailable]) {
      return 'Contractor unavailable'
    } else if (this.item.isContractorReAttend) {
      return 'Contractor re-appointed on job'
    } else {
      return 'Contractor appointed on job'
    }
  }

  private getFormatedTravelTime(travelTime: string) {
    return Shared.formatTimeSpanToString(travelTime)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>

<style scoped></style>
