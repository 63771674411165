import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import MaterialModel from '@/models/material/MaterialModel'

export default class MaterialController {
  public static async SaveMaterial(materialModel: MaterialModel | null): Promise<MaterialModel | null> {
    const res: AxiosResponse = await CallCentreApi.post('Material/SaveMaterial', materialModel)
    return res.data ? res.data : null
  }

  public static async GetMaterials(): Promise<MaterialModel[]> {
    const res = await CallCentreApi.get<MaterialModel[]>('Material/GetMaterials')
    return res.data ? res.data : []
  }

  public static async DeleteMaterial(materialId: string): Promise<boolean> {
    const res = await CallCentreApi.delete('Material/DeleteMaterial?materialId=' + materialId)
    return res.status >= 200 && res.status < 300
  }
}
