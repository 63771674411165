<template>
  <div class="taskboard-wrapper">
    <div v-show="!loading.jobs" class="jobs-taskboard">
      <v-layout wrap class="scrollmenu pa-0">
        <v-flex v-for="(item, index) in getJobs" :key="index" xs3>
          <USJobStatusBar :key="index" ref="jobStatusBar" :record="item" @filterDelay="filterDelay"></USJobStatusBar>
        </v-flex>
      </v-layout>
    </div>
    <div v-show="loading.jobs">
      <v-progress-circular
        style="position: absolute; top: 400px; left: 50%"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import USJobStatusBar from '@/components/USJobStatusBar.vue'
import { USJobStatus } from '@/common/enums'
import USDashboardRecordModel from '@/models/claim/USDashboardRecordModel'
import UndergroundServicesController from '@/api/undergroundServicesController'
import moment, { Moment } from 'moment'
import IUSJobStatusBarModel from '@/models/claim/IUSJobStatusBarModel'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import { log } from 'util'

const DEFAULT_ETA_COLORS = {
  overdue: 'red',
  nearing: 'orange',
  ok: 'grey',
}

const PROGRESS_ETA_COLORS = {
  overdue: 'red',
  nearing: 'orange',
  ok: 'blue',
}

@Component({
  name: 'USJobDashboard',
  components: { USJobStatusBar },
})
export default class USJobDashboard extends Vue {
  public loading: Record<string, boolean> = { jobs: false }
  public usJobManagementHub: SignalRHubConnection = new SignalRHubConnection('usJobDashboard')
  private selectedDelayReason = 'All'
  private usJobs: IUSJobStatusBarModel[] = [
    {
      index: 1,
      status: USJobStatus[USJobStatus.New],
      items: [],
      headerClass: 'red--text pb-0',
    },
    {
      index: 2,
      status: USJobStatus[USJobStatus.VisitBooked],
      items: [],
      headerClass: 'blue--text',
    },
    {
      index: 3,
      status: USJobStatus[USJobStatus.AwaitingReport],
      items: [],
      headerClass: 'orange--text',
    },
    {
      index: 4,
      status: USJobStatus[USJobStatus.AwaitingClosure],
      items: [],
      headerClass: 'orange--text',
    },
  ]
  private reportDocuments: USDashboardRecordModel[] = []

  public async created() {
    this.usJobManagementHub.addHandler('usJobMessage', this.handleJobUpdate)
    this.usJobManagementHub.connect()
  }

  public async mounted() {
    this.$set(this.loading, 'jobs', true)
    await this.getUSJobs()
    this.$set(this.loading, 'jobs', false)
  }

  public destroyed() {
    if (this.usJobManagementHub != null) {
      this.usJobManagementHub.disconnect()
    }
  }

  public get getJobs(): any {
    return this.usJobs.sort((a, b) => a.index - b.index)
  }

  private async handleJobUpdate(jobId: string) {
    const response = await UndergroundServicesController.GetUSJobs(jobId)

    if (response && response.length) {
      const index = this.reportDocuments.findIndex((item) => item.id === jobId)
      if (index >= 0) {
        this.reportDocuments.splice(index, 1, response[0])
      } else {
        this.reportDocuments.push(response[0])
      }
      this.setUSJobs()
    }
  }

  private async getUSJobs() {
    const response = await UndergroundServicesController.GetUSJobs()
    this.reportDocuments = response || []
    this.setUSJobs()
  }

  private setUSJobs(): void {
    const self = this
    self.usJobs.forEach((element) => {
      const items = self.reportDocuments.filter(
        (x) =>
          x.status === element.status &&
          (x.status === USJobStatus[USJobStatus.New] && this.selectedDelayReason !== 'All'
            ? x.delayCode === this.selectedDelayReason
            : true)
      )
      const usJobs = self.sort(items)
      element.items = self.updateETAColors(usJobs)
    })
  }

  private sort(jobs: USDashboardRecordModel[]) {
    // sort jobs data eta vise
    jobs.sort((a, b) => {
      a.etaFrom = typeof a.etaFrom === 'string' ? moment(a.etaFrom) : a.etaFrom
      b.etaFrom = typeof b.etaFrom === 'string' ? moment(b.etaFrom) : b.etaFrom

      if (a.etaFrom && b.etaFrom && a.etaFrom.isBefore(b.etaFrom)) {
        return -1
      } else if (a.etaFrom && b.etaFrom && a.etaFrom.isAfter(b.etaFrom)) {
        return 1
      } else {
        return 0
      }
    })

    return jobs
  }

  private updateETAColors(jobs: USDashboardRecordModel[]) {
    jobs.map((x) => {
      let color
      switch (x.status) {
        case USJobStatus[USJobStatus.VisitBooked]:
          color = PROGRESS_ETA_COLORS[this.getETAStatus(x.etaTo)]
          break
        default:
          color = DEFAULT_ETA_COLORS[this.getETAStatus(x.etaFrom)]
          break
      }
      x.dashboardDisplayData = { etaColor: color }
      return x
    })
    return jobs
  }

  private getETAStatus(eta: moment.Moment | null | undefined) {
    if (eta) {
      const now = moment()
      const minutes = now.diff(eta, 'minutes')

      if (minutes > 0) {
        return 'overdue'
      } else if (minutes <= 0 && minutes >= -30) {
        return 'nearing'
      }
    }

    return 'ok'
  }

  private filterDelay(delayReason: string) {
    this.selectedDelayReason = delayReason
    this.setUSJobs()
  }
}
</script>

<style scoped>
.taskboard-wrapper {
  position: relative;
}
.all-jobs.open-block .jobs-taskboard {
  display: none;
}
.scrollmenu {
  overflow: auto;
  padding: 15px;
}
.jobs-taskboard .layout > .flex {
  padding: 4px;
}
.task-header {
  padding: 10px 4px;
}
.scrollmenu .flex.xs3 {
  max-width: 25%;
}
</style>
