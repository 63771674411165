<template>
  <v-layout wrap>
    <v-flex v-if="getCancellationReason" xs12>
      <label class="red--text"><b>Cancellation Reason:</b></label>
      <span>{{ getCancellationReason }}</span>
    </v-flex>
    <v-flex v-if="job.reasonDescription" xs12 mt-1>
      <label><b>Reason Description:</b></label>
      <span>{{ job.reasonDescription }}</span>
    </v-flex>
    <v-flex v-if="job.jobCompletedAtUtc" xs12 mt-1>
      <div v-if="job.cancellationReason" class="small-text mb-2">
        <b>Cancelled at:</b>
        {{ getFormattedDate(job.jobCompletedAtUtc) }}
      </div>
      <div v-else class="small-text mb-2">
        <b>Completed at:</b>
        {{ getFormattedDate(job.jobCompletedAtUtc) }}
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import { RecordType } from '@/common/enums'
import storeGetters from '@/storeGetters'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'

@Component
export default class JobFinancialSummaryCard extends TimeLineItemBase {
  private jbCancellationReasonList: MasterRecord[] = []

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private created() {
    if (this.job && this.job.cancellationReason) {
      this.GetCancellationReasonList()
    }
  }
  // Get list of cancellation reason.
  private GetCancellationReasonList(): void {
    const recordType: string = RecordType[RecordType.JobCancellationReason]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.jbCancellationReasonList = res
      }
    })
  }

  private get getCancellationReason(): string | null | undefined {
    if (this.jbCancellationReasonList.length > 0) {
      const id = this.job && this.job.cancellationReason ? this.job.cancellationReason : ''
      const reason: MasterRecord | undefined = this.jbCancellationReasonList.find((c) => c.id === id)
      if (reason) {
        return reason.description
      } else {
        return null
      }
    }
    return ''
  }
}
</script>
