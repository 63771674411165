import { Validator } from 'vee-validate'

export const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g

export default function loadCustomValidators(): void {
  Validator.extend('emails', {
    getMessage() {
      return `All emails must be valid and split by commas or semicolons`
    },
    validate(value) {
      let emails: string[]
      if (value.includes(';')) {
        if (value.includes(',')) {
          return false
        }
        emails = value.split(';')
      } else {
        emails = value.split(',')
      }

      return emails
        .map((email) => email.trim())
        .filter(Boolean)
        .every((email) => email.match(emailRegex) !== null)
    },
  })
}
