import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import PolicySchedule from '@/models/policySchedule/policySchedule'

export default class PolicySchedulesController {
  public static async GetPolicyScheduleByDeliveryNumber(phoneNumber: string): Promise<PolicySchedule | null> {
    const { status, data } = await CallCentreApi.get<PolicySchedule>(
      `policy-schedules/by-delivery-number/${phoneNumber}`
    )
    return status >= 200 && status < 300 ? data : null
  }
}
