import moment from 'moment'

export default class UpdateCustomerPaymentDetail {
  public id: string
  public jobId: string
  public transactionDate: moment.Moment | null
  public balanceTransactionId: string
  public isTransactionCompleted: boolean
  public chargeId: string
}
