import ClientAuthPolicies from './client-auth-policies'
import ComplaintAuthPolicies from './complaint-auth-policies'
import ContractorAuthPolicies from './contractor-auth-policies'
import JobAuthPolicies from './job-auth-policies'

export default class AuthPolicies {
  public clients = new ClientAuthPolicies()
  public complaints = new ComplaintAuthPolicies()
  public contractors = new ContractorAuthPolicies()
  public jobs = new JobAuthPolicies()
}
