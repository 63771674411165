<template>
  <div>
    <v-flex>
      <v-expansion-panel>
        <v-expansion-panel-content v-for="(script, index) in cookieRecord.scripts" :key="`CookieScript-${index + 1}`">
          <template #header>
            <h4 class="text-uppercase">Script {{ index + 1 }}</h4>
          </template>
          <div class="cookie-script">
            <v-tooltip class="cookie-script cookie-script--remove" top>
              <template #activator="{ on }">
                <v-btn icon flat color="primary" small v-on="on" @click="removeCookieScript(index)">
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>Remove Script</span>
            </v-tooltip>
            <v-layout row wrap class="pa-3">
              <v-flex xs12>
                <v-text-field
                  v-validate="scriptsValidation(script)"
                  label="Source"
                  hint="The source of the script"
                  :value="script.source"
                  required
                  :data-vv-name="`cookieScriptSource ${index + 1}`"
                  :data-vv-scope="validationScope"
                  :error-messages="errors.collect(`cookieScriptSource ${index + 1}`)"
                  class="mb-3"
                  @input="setCookieRecordScripts($event, index, 'source')"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-expansion-panel>
                  <v-expansion-panel-content class="expansion-header">
                    <template #header>
                      <h4 class="text-uppercase">Attributes</h4>
                    </template>
                    <v-flex v-for="(att, attIndex) in script.attributes" :key="`${att.key}-${attIndex}`">
                      <div class="cookie-attribute">
                        <v-tooltip class="cookie-attribute--close" top>
                          <template #activator="{ on }">
                            <v-btn
                              icon
                              flat
                              color="primary"
                              small
                              v-on="on"
                              @click="removeCookieAttribute(index, attIndex)"
                            >
                              <v-icon>close</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove Attribute</span>
                        </v-tooltip>
                        <v-layout row wrap>
                          <v-flex xs6>
                            <v-text-field
                              v-validate="'required'"
                              label="Attribute Name"
                              hint="The name of the attribute associated with the script"
                              :value="att.Key"
                              required
                              :data-vv-name="`cookieScriptAttibuteKey ${attIndex + 1}`"
                              :data-vv-scope="validationScope"
                              :error-messages="errors.collect(`cookieScriptAttibuteKey ${attIndex + 1}`)"
                              class="mb-3 pa-3"
                              @input="updateScriptAttributes($event, index, attIndex, 'Key')"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs6>
                            <v-text-field
                              v-validate="'required'"
                              label="Attribute Value"
                              hint="The value of the attribute associated with the script"
                              :value="att.Value"
                              required
                              :data-vv-name="`cookieScriptAttibuteValue ${attIndex + 1}`"
                              :data-vv-scope="validationScope"
                              :error-messages="errors.collect(`cookieScriptAttibuteValue ${attIndex + 1}`)"
                              class="mb-3 pa-3"
                              @input="updateScriptAttributes($event, index, attIndex, 'Value')"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn @click="addCookieAttribute(index)">Add Attribute</v-btn>
                    </v-flex>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-validate="scriptsValidation(script)"
                  label="Body"
                  hint="The body of the script"
                  :value="script.body"
                  required
                  :data-vv-name="`cookieBody ${index + 1}`"
                  :data-vv-scope="validationScope"
                  :error-messages="errors.collect(`cookieBody ${index + 1}`)"
                  class="mb-3"
                  @input="setCookieRecordScripts($event, index, 'body')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-flex>
    <v-flex xs12>
      <v-btn @click="addCookieScript()">Add Script</v-btn>
    </v-flex>
  </div>
</template>

<script lang="ts">
import CookieRecord from '@/models/cookies/cookie-record'
import ScriptTagSettings from '@/models/cookies/script-tag-settings'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CookieConfigurationScripts extends Vue {
  @Prop({ default: '' })
  public validationScope: string

  private get cookieRecord(): CookieRecord {
    return this.$store.getters['cookieModule/cookieRecord']
  }

  public async validateAll(): Promise<boolean> {
    return await this.$validator.validateAll(this.validationScope)
  }

  private addCookieScript() {
    this.$store.dispatch('cookieModule/submitNewCookieScript')
  }

  private removeCookieScript(index: number) {
    this.$store.dispatch('cookieModule/submitRemoveCookieScript', index)
  }

  private addCookieAttribute(index: number) {
    this.$store.dispatch('cookieModule/submitNewCookieAttribute', index)
  }

  private removeCookieAttribute(scriptIndex: number, attributeIndex: number) {
    this.$store.dispatch('cookieModule/submitRemoveCookieAttribute', {
      scriptIndex,
      attributeIndex,
    })
  }

  private updateScriptAttributes(value: any, scriptIndex: number, index: number, fieldName: string) {
    this.$store.dispatch('cookieModule/submitUpdateScriptAttributes', {
      value,
      scriptIndex,
      index,
      fieldName,
    })
  }

  private setCookieRecordScripts(value: any, index: number, fieldName: string) {
    this.$store.dispatch('cookieModule/submitUpdateCookieScripts', {
      value,
      index,
      fieldName,
    })
  }

  // Needs fixed - Will be done via a vee validate custom helper.
  private scriptsValidation(script: ScriptTagSettings): string {
    const isInvalid: boolean =
      script.source !== null && !script.source.length && script.body !== null && !script.body.length
    return isInvalid ? 'required' : ''
  }
}
</script>

<style lang="scss">
.cookie-script {
  margin-bottom: 5px;
  padding: 5px;
  position: relative;

  &--remove {
    position: absolute;
    top: -10px;
    right: 10px;
  }
}

.cookie-attribute {
  position: relative;

  &--close {
    position: absolute;
    top: 0px;
    right: 5px;
  }
}
</style>
