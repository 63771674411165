export type ICommand = 'UpdateSetting' | 'SendConfig'
export class Settings {
  public primaryColor = '#1C698C'
  public secondaryColor = '#E5EEF2'
  public complementaryColor = 'C9004B'
  public logoURL = ''
  public favIconURL = ''
  public headingStep1 = ''
  public descriptionStep1 = ''
  public headingStep2 = ''
  public descriptionStep2 = ''
  public headingStep3 = ''
  public descriptionStep3 = ''
  public headingStep4 = ''
  public descriptionStep4 = ''
  public headingStep5 = ''
  public descriptionStep5 = ''
  public headingStep6 = ''
  public descriptionStep6 = ''
  public headingStep7 = ''
  public descriptionStep7 = ''
  public fallBackStarPolicy = -1
  public policySchedules: number[] = []
  public topEmergencies: number[] = [1]
  public privacyPolicyText = ''
  public legalNoticeText = ''
  public cookieNoticeText = ''
  public errorColor = ''
  public inputTextColor = ''
}

export interface IUpdateSetting {
  name: string
  value: any
}

export interface ICommandMessage {
  command: ICommand
  data: IUpdateSetting | Settings | string
}
