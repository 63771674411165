export interface Skill {
  key: string
  display: string
}

export const SkillModelKeys: Skill[] = [
  {
    key: 'fnol',
    display: 'FNOL',
  },
  {
    key: 'deployment',
    display: 'Deployment',
  },
  {
    key: 'workInProgress',
    display: 'Work in Progress',
  },
  {
    key: 'invoicing',
    display: 'Invoicing',
  },
  {
    key: 'system',
    display: 'System',
  },
  {
    key: 'complaintResolution',
    display: 'Complaint Resolution',
  },
]
export default class SkillModel {
  public fnol = 0
  public deployment = 0
  public workInProgress = 0
  public invoicing = 0
  public system = 0
  public complaintResolution = 0
}
