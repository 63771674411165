<template>
  <div>
    <div class="mb-2"><h4>Job Delay</h4></div>
    <div class="mb-1">
      <label>Reason: </label>
      <span>{{ item.reason }}</span>
    </div>
    <div class="mb-1">
      <label>Note: </label>
      <span>{{ item.notes }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpsertSIJobDelay from '@/models/siteInvestigation/requests/UpsertSIJobDelay'

@Component
export default class UpdateSIJobDelayLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpsertSIJobDelay {
    return Object.assign(new UpsertSIJobDelay(), this.requestBody)
  }
}
</script>
