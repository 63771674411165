import { DocumentTemplateType } from '@/common/enums'
export default class SendJobSMS {
  public jobId: string
  public documentTemplateType: DocumentTemplateType

  public accessibleRecords: {}

  // to be replaced
  public emergencyId: string
  public contractorAppointedDetailId: string

  public documentTemplateId?: number

  public sendToNominee: boolean
}
