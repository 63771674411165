import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { JobType } from '@/models/types'
import UploadedDocumentTypeModel from '@/models/UploadedDocumentTypeModel'
import UploadedJobDocumentDetail from '@/models/UploadedJobDocumentDetailModel'
import UploadedJobDocumentModel from '@/models/UploadedJobDocumentModel'
import DrainageVideoSource from '@/models/DrainageVideoSource'

export default class DocumentController {
  public static async GetUploadedDocumentTypes(jobType: JobType): Promise<UploadedDocumentTypeModel[]> {
    const res: AxiosResponse = await CallCentreApi.get('Document/GetUploadedDocumentTypes?jobType=' + jobType)
    return res.data
  }

  public static async GetSASTokenForRecordingUrl(recordingUrl: string): Promise<string | null> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Document/GetSASTokenForRecordingUrl?recordingUrl=' + encodeURIComponent(recordingUrl)
    )
    return res.data
  }


  public static async GetTranscript(recordingUrl: string): Promise<string | null> {
    const res: AxiosResponse = await CallCentreApi.get('Document/GetTranscript?recordingUrl=' + recordingUrl)
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async GetAllUploadedJobDocument(jobId: string): Promise<UploadedJobDocumentDetail[]> {
    const res: AxiosResponse = await CallCentreApi.get<string>('Document/GetAllUploadedJobDocument?jobId=' + jobId)
    return res.data
  }

  public static async GetTokenForUploadJobDocument(jobId: string, documentName: string): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get<string>(
      'Document/GetTokenForUploadJobDocument?jobId=' + jobId + '&documentName=' + documentName
    )
    return res.data
  }

  public static async AddUploadJobDocumentRequest(
    uploadedJobDocumentModel: UploadedJobDocumentModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Document/AddUploadJobDocumentRequest',
      uploadedJobDocumentModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateJobDocumentMetadata(
    uploadedJobDocumentModel: UploadedJobDocumentDetail,
    jobId: string
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Document/UpdateJobDocumentMetadata?jobId=' + jobId,
      uploadedJobDocumentModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async DeleteUploadedJobDocument(
    jobId: string,
    documentName: string,
    documentUrl: string,
    documentType: string,
    documentTypeId: number
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(
      'Document/DeleteUploadedJobDocument?jobId=' +
      jobId +
      '&documentName=' +
      encodeURIComponent(documentName) +
      '&documentUrl=' +
      encodeURIComponent(documentUrl) +
      '&documentType=' +
      encodeURIComponent(documentType) +
      '&documentTypeId=' +
      documentTypeId
    )
    return res.status >= 200 && res.status < 300
  }

  public static async GetSASTokenToUploadComplaintDocument(
    jobId: string,
    documentName: string
  ): Promise<string | null> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Document/GetSASTokenToUploadComplaintDocument?jobId=' + jobId + '&documentName=' + documentName
    )
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async GetSASTokenToWriteClientImage(clientId: string, fileURL: string): Promise<string | null> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Document/GetSASTokenToWriteClientImage?clientId=' + clientId + '&fileURL=' + fileURL
    )
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async GetSASTokenForBordereauInvoicingDocument(documentUrl: string): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Document/GetSASTokenForBordereauInvoicingDocument?documentUrl=' + documentUrl
    )
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async GetSASTokensForCCTVRunVideo(jobId: string, controlLogId: string): Promise<DrainageVideoSource[]> {
    const res: AxiosResponse = await CallCentreApi.get(
      `Document/GetSASTokensForCCTVRunVideo?jobId=${jobId}&controlLogId=${controlLogId}`
    )

    return res.status >= 200 && res.status < 300 ? res.data : []
  }
}
