<template>
  <div class="dashboard fill-height lighten-3 dynamic-dashboard" :class="isEditMode ? 'open-sidebar' : ''">
    <span v-if="dashboardId && ready" class="dashboard-edit">
      <v-btn
        color="primary"
        :class="isEditMode ? 'hide' : 'show'"
        class="elevation-1"
        :disabled="isLoading"
        large
        icon
        @click="toggleEditMode(isEditMode)"
      >
        <v-icon>edit</v-icon>
      </v-btn>
    </span>
    <v-container fluid pa-0 fill-height class="drop-dontant">
      <v-layout v-show="ready" wrap>
        <div class="sidebar">
          <div class="dashboard-drag-search">
            <v-card-title v-if="dashboardId" class="sidebar-header grey lighten-4 py-2">
              <h3 v-if="!updateDashboardName" class="grey--text">
                {{ dashboardName }}
              </h3>
              <v-text-field
                v-else
                id="dashboardName"
                v-model="dashboardName"
                placeholder="Dashboard Name"
                :hide-details="true"
              ></v-text-field>
              <v-spacer v-if="!updateDashboardName"></v-spacer>
              <v-btn v-if="!updateDashboardName" icon small class="ma-0" @click="updateDashboardName = true">
                <v-icon small class="primary--text">edit</v-icon>
              </v-btn>
              <v-btn v-if="updateDashboardName" icon small class="ma-0" @click="updateDashboardName = false">
                <v-icon small class="primary--text">close</v-icon>
              </v-btn>
            </v-card-title>
            <div class="px-3">
              <v-text-field
                id="testing"
                v-model="searchInput"
                name="input-1"
                label="Search"
                append-icon="search"
              ></v-text-field>
            </div>
          </div>
          <v-navigation-drawer stateless value="true" :hide-overlay="true">
            <v-list v-for="(element, index) in filteredDynamicElementsList" :key="index">
              <v-list-tile
                :disabled="currentElementId === element.id && isLoading"
                :class="
                  checkIsElementExist(element.id)
                    ? 'compnent-active grey lighten-3'
                    : isLoading
                    ? 'disable-list-tile'
                    : ''
                "
                @click="addDynamicElement(element)"
              >
                <v-list-tile-title>{{ element.text }}</v-list-tile-title>
                <v-progress-circular
                  v-if="currentElementId === element.id && isLoading"
                  indeterminate
                  :width="3"
                  color="primary"
                ></v-progress-circular>
                <v-icon v-if="checkIsElementExist(element.id)">check</v-icon>
              </v-list-tile>
            </v-list>
            <span v-if="filteredDynamicElementsList.length === 0" class="empty-data">No Data Found</span>
          </v-navigation-drawer>
          <div class="text-xs-right sidebar-actions grey lighten-4 pt-2">
            <v-btn
              v-if="isEditMode"
              color="primary"
              :disabled="isLoading || !isElementsExists"
              @click="dashboardDeleteConfiramtion = true"
            >
              Delete
            </v-btn>
            <v-btn
              v-if="isEditMode"
              color="primary"
              :disabled="isLoading || !isElementsExists"
              @click="toggleEditMode(isEditMode)"
            >
              Save
            </v-btn>
          </div>
        </div>
        <v-flex xs12 fill-height>
          <grid-layout
            :layout.sync="dynamicLayout"
            :col-num="12"
            :row-height="30"
            :is-draggable="isEditMode"
            :is-resizable="false"
            :is-mirrored="false"
            :vertical-compact="false"
            :margin="[15, 14]"
            :use-css-transforms="false"
            class="grey lighten-5"
          >
            <div :class="isEditMode ? 'edit-mode-grid' : ''">
              <grid-item
                v-for="(item, index) in dynamicLayout"
                :key="index"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                class="dragble-box"
              >
                <div
                  v-if="
                    (item.i === dynamicComponentsEnum.CallPickupRate ||
                      item.i === dynamicComponentsEnum.CurrentCalls ||
                      item.i === dynamicComponentsEnum.CurrentCallsInQueue ||
                      item.i === dynamicComponentsEnum.LongestWait ||
                      item.i === dynamicComponentsEnum.ContractorCallsInQueue) &&
                    item.elementBody
                  "
                  class="card-small-tiles"
                >
                  <v-btn
                    v-if="isEditMode"
                    icon
                    class="close-tile elevation-1"
                    small
                    @click.native="removeDynamicElement(item.i)"
                  >
                    <v-icon small>close</v-icon>
                  </v-btn>
                  <DashboardTopTile v-bind="topTiles[item.i]" :dashboard-tile-view="'DynamicDashboardSmallTile'" />
                </div>
                <div
                  v-else-if="
                    (item.i === dynamicComponentsEnum.TotalOpenComplaint ||
                      item.i === dynamicComponentsEnum.TotalCloseComplaint ||
                      item.i === dynamicComponentsEnum.TotalOverdueAction ||
                      item.i === dynamicComponentsEnum.SettlementPaidMTD) &&
                    item.elementBody
                  "
                >
                  <v-btn
                    v-if="isEditMode"
                    icon
                    class="close-tile elevation-1"
                    small
                    @click.native="removeDynamicElement(item.i)"
                  >
                    <v-icon small>close</v-icon>
                  </v-btn>
                  <DashboardTopTile
                    v-bind="dashboardComplaintTopTiles[item.i]"
                    :dashboard-tile-view="'DynamicDashboardMediumTile'"
                  />
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.UndeployedJobHistoryTable" class="table-layout-grid">
                  <div
                    v-if="item.elementBody"
                    class="white elevation-1 custom-table-layout pb-4 undeployed-job-gridview"
                  >
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <UndeployedEmergencyTable
                      v-if="ready"
                      :items="item.elementBody"
                      table-name="undeployedEmergencyDashboard"
                    ></UndeployedEmergencyTable>
                  </div>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.UnlinkedPhoneCallsTable" class="table-layout-grid">
                  <div
                    v-if="item.elementBody"
                    class="white elevation-1 custom-table-layout pb-4 unlinked-phone-gridview"
                  >
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <UnlinkedPhoneCallsTable
                      v-if="ready"
                      :items="item.elementBody"
                      table-name="unlinkedPhoneCallsDashboard"
                    ></UnlinkedPhoneCallsTable>
                  </div>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.ManagerSupervisorTable" class="table-layout-grid">
                  <div
                    v-if="item.elementBody"
                    class="white elevation-1 custom-table-layout pb-4 manager-superviour-gridview large-size-row-table"
                  >
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <ManagerSupervisorTable
                      v-if="ready"
                      :workers="item.elementBody.workers"
                      table-name="manageSupervisorDashboard"
                    ></ManagerSupervisorTable>
                  </div>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.IncidentManagerTable" class="table-layout-grid">
                  <div
                    v-if="item.elementBody"
                    class="white elevation-1 pa-3 custom-table-layout pb-4 incident-manager-gridview large-size-row-table"
                  >
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <IncidentManagerTable
                      v-if="true"
                      table-name="incidentManagerDashboard"
                      :incident-manager-list-items="item.elementBody"
                    ></IncidentManagerTable>
                  </div>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.OutstandingComplaintsTable" class="table-layout-grid">
                  <div
                    v-if="item.elementBody"
                    class="white elevation-1 custom-table-layout pb-4 pa-3 outstanding-complaints-gridview large-size-row-table"
                  >
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <OutstandingComplaintsTable
                      table-name="outstandingComplaintsDashboard"
                      :complaints="complaintListForDashBoard.complaints"
                      :loading-complaint-list="false"
                      :ready="ready"
                    ></OutstandingComplaintsTable>
                  </div>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.JobHistoryTable" class="table-layout-grid">
                  <div class="white elevation-1 custom-table-layout pb-4 px-3 job-history-gridview">
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <JobHistoryTable v-if="true" table-name="jobDashboard"></JobHistoryTable>
                  </div>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.TotalJobsLoggedInLast15Days">
                  <v-flex v-if="item.elementBody" class="dyanamic-dashboard-chart">
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <v-card class="elevation-1 blue white--text pa-0">
                      <v-card-title class="px-2 pt-1 pb-0">
                        {{ titleTotalJobsLoggedInLast15Days }}
                      </v-card-title>
                      <v-card-text class="pa-0">
                        <DashboardChart
                          :chart-data="item.elementBody"
                          :chart-type="chartTypeEnum[chartTypeEnum.Line]"
                        />
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.Top5Emergency">
                  <v-flex v-if="item.elementBody">
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <v-card class="elevation-1 white white--grey pa-0 piechart-jobdeshboard">
                      <v-card-title class="px-2 pt-2 pb-0 subheading">
                        {{ titleTop5Emergencies }}
                      </v-card-title>
                      <v-card-text class="pa-0">
                        <div class="chart-pie chart-top-info">
                          <canvas id="pieChartElement" ref="chartElement" :width="50" :height="33"></canvas>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </div>
                <div v-else-if="item.i === dynamicComponentsEnum.DashboardContractorPanel">
                  <v-btn
                    v-if="isEditMode"
                    icon
                    class="close-tile elevation-1"
                    small
                    @click="removeDynamicElement(item.i)"
                  >
                    <v-icon small>close</v-icon>
                  </v-btn>
                  <DashboardContractorPanel v-bind="contractorPanel" flex-class="flex syetsm-dashboard system-info" />
                </div>
                <div
                  v-else-if="
                    item.elementBody &&
                    item.elementBody &&
                    item.i ===
                      getUniqueInsurerId(dynamicComponentsEnum.DashboardInsurerPanel, item.elementBody.insurerId)
                  "
                >
                  <v-flex v-if="item.elementBody">
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <DashboardPanel
                      v-bind="panels[item.elementBody.insurerId]"
                      flex-class="flex syetsm-dashboard system-info"
                    />
                  </v-flex>
                </div>
                <div v-else-if="item.elementBody && item.elementBody && item.i === item.elementBody.clientTemplateId">
                  <v-flex v-if="item.elementBody">
                    <v-btn
                      v-if="isEditMode"
                      icon
                      class="close-tile elevation-1"
                      small
                      @click="removeDynamicElement(item.i)"
                    >
                      <v-icon small>close</v-icon>
                    </v-btn>
                    <DashboardPanel
                      v-bind="clientPortalpanels[item.elementBody.clientTemplateId]"
                      flex-class="flex syetsm-dashboard system-info"
                    />
                  </v-flex>
                </div>
                <div v-else-if="item.i !== 0" class="error-block">
                  <div><span>Something went wrong</span></div>
                </div>
              </grid-item>
            </div>
          </grid-layout>
        </v-flex>
      </v-layout>
      <div v-show="!ready">
        <v-progress-circular
          class="loading-spinner"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-container>
    <!-- save popup -->
    <v-dialog v-model="showSaveDashboardDialog" max-width="650" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Save Dashboard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showSaveDashboardDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-text-field
            v-model.trim="dashboardName"
            v-validate="'required'"
            label="Dashboard Title"
            required
            class="required"
            data-vv-scope="dynamicDashboardform"
            data-vv-name="Dashboard Title"
            :error-messages="errors.collect('Dashboard Title')"
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showSaveDashboardDialog = false">Close</v-btn>
          <v-btn color="primary" class="mr-0" :disabled="isLoading" :loading="isLoading" @click="onSaveDashboard">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Insurer selection popup -->
    <v-dialog
      v-if="showSelectInsurerOption"
      v-model="showSelectInsurerOption"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Select Insurer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showSelectInsurerOption = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-autocomplete
            v-model="selectedInsurer"
            v-validate="'required'"
            :items="insurerList"
            item-value="id"
            item-text="name"
            label="Select"
            return-object
            data-vv-scope="frmDynamicDashboard"
            data-vv-name="Insurer"
            :error-messages="errors.collect('Insurer')"
          ></v-autocomplete>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showSelectInsurerOption = false">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0"
            :disabled="isLoading"
            :loading="isLoading"
            @click="getInsurerPanelData(selectedInsurer)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Insurer portal selection popup -->
    <v-dialog
      v-if="showSelectInsurerPortalOption"
      v-model="showSelectInsurerPortalOption"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Select Insurer Portal</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showSelectInsurerPortalOption = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-autocomplete
            v-model="selectedInsurerPortal"
            v-validate="'required'"
            :items="insurerPortalList"
            item-value="id"
            item-text="subDomain"
            label="Select"
            return-object
            data-vv-scope="frmDynamicDashboardInsurerPortal"
            data-vv-name="InsurerPortal"
            :error-messages="errors.collect('InsurerPortal')"
          ></v-autocomplete>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showSelectInsurerPortalOption = false">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0"
            :disabled="isLoading"
            :loading="isLoading"
            @click="getInsurerPortalPanelData(selectedInsurerPortal)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dashboard delete confirmation popup -->
    <v-dialog v-if="dashboardDeleteConfiramtion" v-model="dashboardDeleteConfiramtion" max-width="425">
      <v-card>
        <v-card-title class="mb-3">
          <h3>Are you sure you want to delete a dashboard?</h3>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat="flat" @click="dashboardDeleteConfiramtion = false">cancel</v-btn>
          <v-btn color="primary" :disabled="isLoading" :loading="isLoading" @click="deleteDashboard()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar to display message -->
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import DashboardController from '@/api/dashboardController'
import JobHeader from '@/models/JobHeader'
import JobDashboardModel from '@/models/claim/JobDashboardModel'
import { Chart, ChartConfiguration } from 'chart.js'
import JobCount from '@/models/claim/JobCount'
import VueGridLayout, { GridLayout, GridItem } from 'vue-grid-layout'
import { DynamicComponents } from '@/common/enums'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import UndeployedEmergencyTable from '@/components/tables/UndeployedEmergencyTable.vue'
import UnlinkedPhoneCallsTable from '@/components/tables/UnlinkedPhoneCallsTable.vue'
import IncidentManagerTable from '@/components/tables/IncidentManagerTable.vue'
import OutstandingComplaintsTable from '@/components/tables/OutstandingComplaintsTable.vue'
import UnlinkedPhoneCallsModel from '@/models/claim/UnlinkedPhoneCallsModel'
import CustomDashboardModel from '@/models/customDashboard/CustomDashboardModel'
import CustomComponent from '@/models/customDashboard/CustomComponentModel'
import DashboardChart from '@/components/dashboard/DashboardChart.vue'
import CustomDashboardController from '@/api/customDashboardController'
import ManagerSupervisorTable from '@/components/tables/ManagerSupervisorTable.vue'
import JobHistoryTable from '@/components/tables/JobHistoryTable.vue'
import { WorkerWithAdditions } from '@/components/dashboard/worker'
import UserController from '@/api/userController'
import UserModel from '@/models/user/UserModel'
import ComplaintModel from '@/models/claim/ComplaintModel'
import store from '@/store'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import ComplaintTrackerModel from '@/models/claim/ComplaintTrackerModel'
import InsurerModel from '@/models/policy/InsurerModel'
import { InsurerStats } from '@/signalr/twilioEventModels/dashboardState'
import eventBus from '@/common/bus'
import InsurerStatsModel from '@/models/InsurerStatsModel'
import SignalRHub from '@/components/SignalRHub.vue'
import { TopLevelCallStats } from '@/signalr/twilioEventModels/events'
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue'
import DashboardContractorPanel from '@/components/dashboard/DashboardContractorPanel.vue'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import ClientPortalStatsModel from '@/models/ClientPortalStatsModel'
import '../../public/assets/css/customDashboard.css'
import { ChartType } from '@/common/enums'
import InsurerController from '@/api/InsurerController'

@Component({
  components: {
    GridLayout,
    GridItem,
    DashboardTopTile,
    UndeployedEmergencyTable,
    UnlinkedPhoneCallsTable,
    ManagerSupervisorTable,
    IncidentManagerTable,
    OutstandingComplaintsTable,
    JobHistoryTable,
    DashboardChart,
    DashboardPanel,
    DashboardContractorPanel,
  },
})
export default class DynamicDashboard extends SignalRHub {
  private isLoading = false
  private ready = false
  private dynamicElementsList: any = []
  private dynamicLayout: CustomComponent[] = [{ x: 0, y: 0, w: 0, h: 0, i: 0, elementBody: null }]
  private customDashboardModel: CustomDashboardModel = new CustomDashboardModel()
  private dynamicComponentsEnum = DynamicComponents
  private isEditMode = false
  private gradient: any = null
  private loggedJobs: any = {}
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = ''
  private showSaveDashboardDialog = false
  private updateDashboardName = false
  private dashboardName = ''
  private isElementsExists = false
  private titleTotalJobsLoggedInLast15Days: string = Shared.titleTotalJobsLoggedInLast15Days
  private titleTop5Emergencies: string = Shared.titleTop5Emergencies
  private showSelectInsurerOption = false
  private insurerList: InsurerModel[] = []
  private selectedInsurer = ''
  private searchInput = ''
  private filteredDynamicElementsList: CustomComponent[] = []
  private dashboardId = ''
  private dashboardDeleteConfiramtion = false
  private numberToGenerateUniqueInsurerId = 100000
  private currentElementId = 0
  private axisY = 0
  private isTop5EmergencyChartExists = false
  private top5EmergencyChart: Chart | null = null
  private top5EmergencyChartData: any[]
  private showSelectInsurerPortalOption = false
  private insurerPortalList: ClientTemplateModel[] = []
  private selectedInsurerPortal = ''
  private loggedByUserId = ''
  private chartTypeEnum = ChartType

  private addHandlerToSignalR(data: CustomDashboardModel) {
    const topTileData = data.customComponents.findIndex(
      (c) =>
        c.i === DynamicComponents.CallPickupRate ||
        c.i === DynamicComponents.CurrentCalls ||
        c.i === DynamicComponents.CurrentCallsInQueue ||
        c.i === DynamicComponents.LongestWait ||
        c.i === DynamicComponents.ContractorCallsInQueue
    )

    if (topTileData !== -1) {
      this.addTopLevelCallStatsHandler()
    }
    const insurerData = data.customComponents.findIndex((c) => c.i === DynamicComponents.DashboardInsurerPanel)

    if (insurerData !== -1) {
      this.addInsurerStatsHandler()
      this.addInsurerAnnouncingCountHandler()
    }

    const contractorData = data.customComponents.findIndex((c) => c.i === DynamicComponents.DashboardContractorPanel)
    if (contractorData !== -1) {
      this.addContractorStatsHandler()
      this.addContractorAnnouncingCountHandler()
    }

    if (topTileData !== -1 || insurerData !== -1) {
      this.addDailyCallStatsHandler()
      this.addCallCountHandler()
      this.addUpdateTwilioTopTilesCountHandler()
      this.addUpdateJobCountHandler()
      this.dashboardSignalRHub.connect()
    }

    if (
      data.customComponents.findIndex(
        (c) =>
          c.i === DynamicComponents.TotalOpenComplaint ||
          c.i === DynamicComponents.TotalCloseComplaint ||
          c.i === DynamicComponents.TotalOverdueAction ||
          c.i === DynamicComponents.SettlementPaidMTD ||
          c.i === DynamicComponents.OutstandingComplaintsTable
      ) !== -1
    ) {
      this.addUpsertComplaintHandler()
      this.complaintDashboardSignalRHub.connect()
    }

    if (data.customComponents.findIndex((c) => c.i === DynamicComponents.InsurerPortalPanel) !== -1) {
      this.addClientPortalStats()
      this.addClientPortalJobCount()
      this.addClientPortalCallCount()
      this.addClientPortalAnnouncingCount()
      this.clientPortalPanelHub.connect()
    }
  }

  private created() {
    this.loggedByUserId = ''
    this.childPage = 'DynamicDashboard'
    // make api call and bind new dashboard data
    this.fillDynamicElementList()
    this.dashboardId = this.$route.params.dashboardId !== undefined ? this.$route.params.dashboardId : ''
    if (!this.dashboardId) {
      this.ready = true
      this.isEditMode = true
    } else {
      CustomDashboardController.GetCustomDashboardData(this.dashboardId)
        .then((res: CustomDashboardModel | null) => {
          if (res) {
            this.loggedByUserId = res.loggedByUserId
            this.addHandlerToSignalR(res)
            res.customComponents.forEach((element: any) => {
              if (
                element.i === this.dynamicComponentsEnum.CallPickupRate ||
                element.i === this.dynamicComponentsEnum.CurrentCalls ||
                element.i === this.dynamicComponentsEnum.CurrentCallsInQueue ||
                element.i === this.dynamicComponentsEnum.LongestWait ||
                element.i === this.dynamicComponentsEnum.ContractorCallsInQueue
              ) {
                // tiles
                this.createSmallTile(element.x, element.y, element.w, element.h, element.i, element.elementBody)
              } else if (
                element.i === this.dynamicComponentsEnum.TotalOpenComplaint ||
                element.i === this.dynamicComponentsEnum.TotalCloseComplaint ||
                element.i === this.dynamicComponentsEnum.TotalOverdueAction ||
                element.i === this.dynamicComponentsEnum.SettlementPaidMTD
              ) {
                // tiles
                this.createMediumTile(element.x, element.y, element.w, element.h, element.i, element.elementBody)
              } else if (element.i === this.dynamicComponentsEnum.UndeployedJobHistoryTable) {
                // get Undeployed Job Dashboard Data
                this.createUndeployedJobHistoryTable(
                  element.x,
                  element.y,
                  element.w,
                  element.h,
                  element.i,
                  element.elementBody
                )
              } else if (element.i === this.dynamicComponentsEnum.UnlinkedPhoneCallsTable) {
                // get Unlinked PhoneCalls Table Data
                this.createUnlinkedPhoneCallsTable(
                  element.x,
                  element.y,
                  element.w,
                  element.h,
                  element.i,
                  element.elementBody
                )
              } else if (element.i === this.dynamicComponentsEnum.ManagerSupervisorTable) {
                // get Manager Supervisor Table Data
                this.createManagerSupervisorTable(
                  element.x,
                  element.y,
                  element.w,
                  element.h,
                  element.i,
                  element.elementBody
                )
              } else if (element.i === this.dynamicComponentsEnum.IncidentManagerTable) {
                // get Incident Manager Table Data
                this.createIncidentManagerTable(
                  element.x,
                  element.y,
                  element.w,
                  element.h,
                  element.i,
                  element.elementBody
                )
              } else if (element.i === this.dynamicComponentsEnum.OutstandingComplaintsTable) {
                // get Outstanding Complaints Table Data
                this.createOutstandingComplaintsTable(
                  element.x,
                  element.y,
                  element.w,
                  element.h,
                  element.i,
                  element.elementBody
                )
              } else if (element.i === this.dynamicComponentsEnum.JobHistoryTable) {
                // get Job History Table Data
                this.getJobHistoryTableData(element.x, element.y, element.w, element.h, element.i)
              } else if (element.i === this.dynamicComponentsEnum.TotalJobsLoggedInLast15Days) {
                // get total Jobs Logged In Last 15 Days data
                this.createTotalJobsLoggedInLast15DaysTile(
                  element.x,
                  element.y,
                  element.w,
                  element.h,
                  element.i,
                  element.elementBody
                )
              } else if (element.i === this.dynamicComponentsEnum.Top5Emergency) {
                // get top 5 emergency counts
                this.createTop5EmergencyTile(element.x, element.y, element.w, element.h, element.i, element.elementBody)
              } else if (element.i === this.dynamicComponentsEnum.DashboardInsurerPanel) {
                // get insurer call statistics data
                if (this.isEditMode) {
                  if (this.getInsurers.length === 0) {
                    this.setInsuerList()
                  } else {
                    this.insurerList = this.getInsurers
                  }
                }
                // wait till particular insurer call statistics loads.
                setTimeout(() => {
                  this.createDashboardInsurerPanel(
                    element.x,
                    element.y,
                    element.w,
                    element.h,
                    element.i,
                    element.elementBody
                  )
                }, 0)
              } else if (element.i === this.dynamicComponentsEnum.InsurerPortalPanel) {
                // get Brand specific panel data
                if (this.isEditMode) {
                  if (this.getInsurerPortal.length === 0) {
                    this.setInsurerPortalList()
                  } else {
                    this.insurerPortalList = this.getInsurerPortal
                  }
                }
                // wait till particular insurer portal panel loads.
                setTimeout(() => {
                  this.createDashboardInsurerPortalPanel(
                    element.x,
                    element.y,
                    element.w,
                    element.h,
                    element.i,
                    element.elementBody
                  )
                }, 0)
              } else if (element.i === this.dynamicComponentsEnum.DashboardContractorPanel) {
                this.createDashboardContractorPanel(
                  element.x,
                  element.y,
                  element.w,
                  element.h,
                  element.i,
                  element.elementBody
                )
              }
            })
            this.isElementsExists = true
            this.dashboardName = res.name
            this.ready = true
          } else {
            this.$router.replace('/')
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading custom dashboard data, please try again', true)
        })
    }
  }

  @Watch('searchInput')
  private onSearchInputChanged(newValue: any) {
    const self = this
    if (newValue === '') {
      this.filteredDynamicElementsList = this.dynamicElementsList
    }
    const searchItems: any | undefined = self.dynamicElementsList.filter((e: any) =>
      e.text.toLowerCase().includes(newValue.toLowerCase())
    )
    self.filteredDynamicElementsList = searchItems.length > 0 ? searchItems : []
  }

  private fillDynamicElementList() {
    this.dynamicElementsList = [
      {
        text: Shared.titlePickupRate,
        id: this.dynamicComponentsEnum.CallPickupRate,
        isVisible: false,
      },
      {
        text: Shared.titleTotalCalls,
        id: this.dynamicComponentsEnum.CurrentCalls,
        isVisible: false,
      },
      {
        text: Shared.titleQueueCalls,
        id: this.dynamicComponentsEnum.CurrentCallsInQueue,
        isVisible: false,
      },
      {
        text: Shared.titleLongestWait,
        id: this.dynamicComponentsEnum.LongestWait,
        isVisible: false,
      },
      {
        text: Shared.titleContractorQueueCalls,
        id: this.dynamicComponentsEnum.ContractorCallsInQueue,
        isVisible: false,
      },
      {
        text: Shared.titleTotalNumberOfOpenComplaint,
        id: this.dynamicComponentsEnum.TotalOpenComplaint,
        isVisible: false,
      },
      {
        text: Shared.titleTotalNumberOfCloseComplaint,
        id: this.dynamicComponentsEnum.TotalCloseComplaint,
        isVisible: false,
      },
      {
        text: Shared.titleTotalOverdueActions,
        id: this.dynamicComponentsEnum.TotalOverdueAction,
        isVisible: false,
      },
      {
        text: Shared.titleTotalJobsLoggedInLast15Days,
        id: this.dynamicComponentsEnum.TotalJobsLoggedInLast15Days,
        isVisible: false,
      },
      {
        text: Shared.titleTop5Emergencies,
        id: this.dynamicComponentsEnum.Top5Emergency,
        isVisible: false,
      },
      {
        text: 'Incident Manager Table',
        id: this.dynamicComponentsEnum.IncidentManagerTable,
        isVisible: false,
      },
      {
        text: 'Outstanding Complaints Table',
        id: this.dynamicComponentsEnum.OutstandingComplaintsTable,
        isVisible: false,
      },
      {
        text: 'Job History Table',
        id: this.dynamicComponentsEnum.JobHistoryTable,
        isVisible: false,
      },
      {
        text: 'Unlinked PhoneCalls Table',
        id: this.dynamicComponentsEnum.UnlinkedPhoneCallsTable,
        isVisible: false,
      },
      {
        text: 'Manager Supervisor Table',
        id: this.dynamicComponentsEnum.ManagerSupervisorTable,
        isVisible: false,
      },
      {
        text: 'Undeployed Emergency Table',
        id: this.dynamicComponentsEnum.UndeployedJobHistoryTable,
        isVisible: false,
      },
      {
        text: 'Insurer Call Statistics',
        id: this.dynamicComponentsEnum.DashboardInsurerPanel,
        isVisible: false,
      },
      {
        text: 'Brand Specific Widget',
        id: this.dynamicComponentsEnum.InsurerPortalPanel,
        isVisible: true,
      },
      {
        text: 'Contractor Calls',
        id: this.dynamicComponentsEnum.DashboardContractorPanel,
        isVisible: false,
      },
    ]

    // bind elements as per rights
    let tempElement: any[] = []
    Store.Instance.state.SessionDetail.detailRecord.UserDetail.dashboardUserRights.forEach((userRights) => {
      if (userRights.name === 'unlinkedPhoneCallsDashboard') {
        tempElement = this.dynamicElementsList.filter(
          (x) => x.id === this.dynamicComponentsEnum.UnlinkedPhoneCallsTable
        )
        this.makeComponentAvailableForDragAndDrap(tempElement)
      } else if (userRights.name === 'incidentmanagerdashboard') {
        tempElement = this.dynamicElementsList.filter(
          (x) =>
            x.id === this.dynamicComponentsEnum.IncidentManagerTable ||
            x.id === this.dynamicComponentsEnum.CallPickupRate ||
            x.id === this.dynamicComponentsEnum.CurrentCalls ||
            x.id === this.dynamicComponentsEnum.CurrentCallsInQueue ||
            x.id === this.dynamicComponentsEnum.LongestWait ||
            x.id === this.dynamicComponentsEnum.ContractorCallsInQueue
        )
        this.makeComponentAvailableForDragAndDrap(tempElement)
      } else if (userRights.name === 'dashboard' || userRights.name === 'thirdPartyDashboard') {
        tempElement = this.dynamicElementsList.filter(
          (x) =>
            x.id === this.dynamicComponentsEnum.DashboardInsurerPanel ||
            x.id === this.dynamicComponentsEnum.CallPickupRate ||
            x.id === this.dynamicComponentsEnum.CurrentCalls ||
            x.id === this.dynamicComponentsEnum.CurrentCallsInQueue ||
            x.id === this.dynamicComponentsEnum.LongestWait ||
            x.id === this.dynamicComponentsEnum.ContractorCallsInQueue
        )
        this.makeComponentAvailableForDragAndDrap(tempElement)
      } else if (userRights.name === 'undeployedEmergencyDashboard') {
        tempElement = this.dynamicElementsList.filter(
          (x) => x.id === this.dynamicComponentsEnum.UndeployedJobHistoryTable
        )
        this.makeComponentAvailableForDragAndDrap(tempElement)
      } else if (userRights.name === 'managerSupervisorDashboard') {
        tempElement = this.dynamicElementsList.filter(
          (x) =>
            x.id === this.dynamicComponentsEnum.ManagerSupervisorTable ||
            x.id === this.dynamicComponentsEnum.DashboardInsurerPanel ||
            x.id === this.dynamicComponentsEnum.CallPickupRate ||
            x.id === this.dynamicComponentsEnum.CurrentCalls ||
            x.id === this.dynamicComponentsEnum.CurrentCallsInQueue ||
            x.id === this.dynamicComponentsEnum.LongestWait ||
            x.id === this.dynamicComponentsEnum.DashboardContractorPanel ||
            x.id === this.dynamicComponentsEnum.ContractorCallsInQueue
        )
        this.makeComponentAvailableForDragAndDrap(tempElement)
      } else if (userRights.name === 'jobDashboard') {
        tempElement = this.dynamicElementsList.filter(
          (x) =>
            x.id === this.dynamicComponentsEnum.JobHistoryTable ||
            x.id === this.dynamicComponentsEnum.TotalJobsLoggedInLast15Days ||
            x.id === this.dynamicComponentsEnum.Top5Emergency
        )
        this.makeComponentAvailableForDragAndDrap(tempElement)
      } else if (userRights.name === 'complaintTracker') {
        tempElement = this.dynamicElementsList.filter(
          (x) =>
            x.id === this.dynamicComponentsEnum.OutstandingComplaintsTable ||
            x.id === this.dynamicComponentsEnum.TotalOpenComplaint ||
            x.id === this.dynamicComponentsEnum.TotalCloseComplaint ||
            x.id === this.dynamicComponentsEnum.TotalOverdueAction
        )
        this.makeComponentAvailableForDragAndDrap(tempElement)
      }
    })
    this.dynamicElementsList = this.dynamicElementsList.filter((x) => x.isVisible === true)
    this.filteredDynamicElementsList = this.dynamicElementsList
  }

  private makeComponentAvailableForDragAndDrap(elementList: any[]) {
    elementList.forEach((element) => {
      element.isVisible = true
    })
  }

  private checkIsElementExist(id) {
    const index: number = this.dynamicLayout.findIndex((e) => e.i === id)
    return index !== -1 ? true : false
  }

  private async addDynamicElement(element: any) {
    const dynamicElementId = Number(element.id)
    this.currentElementId = dynamicElementId

    // check if element already exists
    let isElementExists = 0
    if (
      this.dynamicLayout &&
      this.dynamicLayout.length > 0 &&
      dynamicElementId !== this.dynamicComponentsEnum.DashboardInsurerPanel
    ) {
      isElementExists = this.dynamicLayout.filter((x: CustomComponent) => x.i === dynamicElementId).length
      if (isElementExists > 0) {
        this.showMessage('Component already exists.')
        return
      }
    }

    // calculate and create element at max y
    this.calulateMaxY()

    if (
      dynamicElementId === this.dynamicComponentsEnum.CallPickupRate ||
      dynamicElementId === this.dynamicComponentsEnum.CurrentCalls ||
      dynamicElementId === this.dynamicComponentsEnum.CurrentCallsInQueue ||
      dynamicElementId === this.dynamicComponentsEnum.LongestWait ||
      dynamicElementId === this.dynamicComponentsEnum.ContractorCallsInQueue
    ) {
      // tiles
      this.isElementsExists = true
      await this.getTwilioDashboardTopTileData(0, this.axisY, 3, 3, dynamicElementId)
    } else if (
      dynamicElementId === this.dynamicComponentsEnum.TotalOpenComplaint ||
      dynamicElementId === this.dynamicComponentsEnum.TotalCloseComplaint ||
      dynamicElementId === this.dynamicComponentsEnum.TotalOverdueAction ||
      dynamicElementId === this.dynamicComponentsEnum.SettlementPaidMTD
    ) {
      // tiles
      this.isElementsExists = true
      await this.getComplaintTopTileData(0, this.axisY, 3, 3, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.UndeployedJobHistoryTable) {
      // get Undeployed Job Dashboard Data
      this.isElementsExists = true
      this.getUndeployedJobDashboardData(0, this.axisY, 8, 19.6, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.UnlinkedPhoneCallsTable) {
      // get Unlinked PhoneCalls Table Data
      this.isElementsExists = true
      this.getUnlinkedPhoneCallsTableData(0, this.axisY, 8, 16.5, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.ManagerSupervisorTable) {
      // get Manager Supervisor Table Data
      this.isElementsExists = true
      this.getManagerSupervisorTableData(0, this.axisY, 8, 19.5, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.IncidentManagerTable) {
      // get Incident Manager Table Data
      this.isElementsExists = true
      this.getIncidentManagerTableData(0, this.axisY, 8, 16.5, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.OutstandingComplaintsTable) {
      // get Outstanding Complaints Table Data
      this.isElementsExists = true
      this.getOutstandingComplaintsTableData(0, this.axisY, 8, 17.7, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.JobHistoryTable) {
      // get Job History Table Data
      this.isElementsExists = true
      this.getJobHistoryTableData(0, this.axisY, 9, 16, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.TotalJobsLoggedInLast15Days) {
      // get total Jobs Logged In Last 15 Days data
      this.isElementsExists = true
      this.getJobLoggedInLast15Day(0, this.axisY, 3.89, 3.66, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.Top5Emergency) {
      // get top 5 emergency counts
      this.isElementsExists = true
      this.getTop5Emergencies(0, this.axisY, 3.83, 10, dynamicElementId)
    } else if (dynamicElementId === this.dynamicComponentsEnum.DashboardInsurerPanel) {
      // get insurer call statistics data
      this.isElementsExists = true
      this.getInsurerList()
    } else if (dynamicElementId === this.dynamicComponentsEnum.InsurerPortalPanel) {
      // get Brand specific panel data
      this.isElementsExists = true
      this.getInsurerPortalList()
    } else if (dynamicElementId === this.dynamicComponentsEnum.DashboardContractorPanel) {
      this.isElementsExists = true
      this.createDashboardContractorPanel(0, this.axisY, 3, 8.6, dynamicElementId, null)
    }
  }

  private removeDynamicElement(dynamicElementId: number) {
    const elementList: CustomComponent[] = this.dynamicLayout.filter((x: CustomComponent) => x.i !== dynamicElementId)
    if (elementList.length === 1) {
      this.isElementsExists = false
    }
    this.dynamicLayout = elementList
    if (this.isTop5EmergencyChartExists) {
      if (dynamicElementId === this.dynamicComponentsEnum.Top5Emergency) {
        this.isTop5EmergencyChartExists = false
      } else {
        this.generateChart()
      }
    }
  }

  private toggleEditMode(isEditMode: boolean) {
    if (this.isEditMode) {
      if (this.dynamicLayout && this.dynamicLayout.length > 0) {
        if (this.dashboardName && this.dashboardId) {
          this.onSaveDashboard()
        } else {
          this.dashboardName = ''
          this.$validator.errors.items = []
          this.showSaveDashboardDialog = true // open popup if layout is new
        }
      }
    } else {
      this.isEditMode = !this.isEditMode
    }
  }

  private async validate(): Promise<boolean> {
    let result = true
    const isValidate: boolean = await this.$validator.validateAll('dynamicDashboardform')
    // set focus to non validate field
    if (!isValidate) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = isValidate
    }
    return result
  }

  private onSaveDashboard() {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.currentElementId = 0
          this.isLoading = true
          this.customDashboardModel = new CustomDashboardModel()
          this.customDashboardModel.name = this.dashboardName
          this.customDashboardModel.id = this.dashboardId
          let dynamicLayoutTemp = JSON.parse(JSON.stringify(this.dynamicLayout))
          dynamicLayoutTemp = dynamicLayoutTemp.filter((x: any) => x.i !== 0)
          // create request for insurer statistics and brand specific widget
          dynamicLayoutTemp.forEach((element: CustomComponent) => {
            // request for insurer statistics
            if (element.i > this.numberToGenerateUniqueInsurerId && !element.i.toString().includes('-')) {
              if (element.elementBody && element.elementBody.insurerId) {
                const insurer: any = {}
                insurer.insurerId = element.elementBody.insurerId
                insurer.insurerName = element.elementBody.insurerName
                element.elementBody = insurer
                element.i = this.getOriginalInsurerId(element.i, insurer.insurerId) // calculate actual insurer id
              }
            }
            // request for brand specific widget
            if (element.i.toString().includes('-')) {
              if (element.elementBody && element.elementBody.clientTemplateId) {
                const insurerPortal: any = {}
                insurerPortal.clientTemplateId = element.elementBody.clientTemplateId
                element.elementBody = insurerPortal
                element.i = this.dynamicComponentsEnum.InsurerPortalPanel
              }
            }
          })
          this.customDashboardModel.customComponents = dynamicLayoutTemp
          this.customDashboardModel.loggedByUserId = this.loggedByUserId
          CustomDashboardController.SaveCustomDashboard(this.customDashboardModel)
            .then((res: string | null) => {
              if (res && res !== null) {
                this.showSaveDashboardDialog = false
                this.isEditMode = false
                this.isLoading = false
                if (!this.dashboardId) {
                  this.$router.replace(res)
                }
                eventBus.$emit('addRemoveDashboardInNavigationMenu', this.dashboardName, res, false)
              } else {
                this.isLoading = false
                this.showMessage('Error while saving custom dashboard.')
              }
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error saving custom dashboard detail, please try again', true)
              this.isLoading = false
            })
        }
      })
      .catch((e) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async getTwilioDashboardTopTileData(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number
  ) {
    this.isLoading = true
    CustomDashboardController.GetTwilioDashboardTopTileData(dynamicElementId)
      .then((res: any) => {
        if (res) {
          this.createSmallTile(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading dashboard top tile data, please try again', true)
        this.isLoading = false
      })
  }

  private createSmallTile(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    if (this.topLevelStats === null) {
      this.callTopLevelCallStats({
        slaRate: 0,
        slaRateHistorical: [],
        currentCallsCount: 0,
        currentCallsCountHistorical: [],
        callsInQueueCount: 0,
        callsInQueueCountHistorical: [],
        longestWaitingCallAge: 0,
        longestWaitingCallAgeHistorical: [],
        updateReason: '',
        updateType: 'TopLevelCallStats',
        dataFetchedAt: '',
      })
    }

    if (this.topLevelStats && res) {
      switch (dynamicElementId) {
        case this.dynamicComponentsEnum.CallPickupRate:
          this.topLevelStats.slaRate = res.value
          this.topLevelStats.slaRateHistorical = res.chartData
          this.dashboardCount.averageSlaRateToday = res.additionalValues
          break
        case this.dynamicComponentsEnum.CurrentCalls:
          this.topLevelStats.currentCallsCount = res.value
          this.topLevelStats.currentCallsCountHistorical = res.chartData
          this.dashboardCount.totalCalls = res.additionalValues[0]
          this.dashboardCount.totalLoggedJobs = res.additionalValues[1]
          this.dashboardCount.totalDeployedJobs = res.additionalValues[2]
          break
        case this.dynamicComponentsEnum.CurrentCallsInQueue:
          this.topLevelStats.callsInQueueCount = res.value
          this.topLevelStats.callsInQueueCountHistorical = res.chartData
          this.dashboardCount.highestTaskCountInQueue = res.additionalValues
          break
        case this.dynamicComponentsEnum.LongestWait:
          this.topLevelStats.longestWaitingCallAge = res.value
          this.topLevelStats.longestWaitingCallAgeHistorical = res.chartData
          this.dashboardCount.longestTaskWaitingAge = res.additionalValues
          break
        case this.dynamicComponentsEnum.ContractorCallsInQueue:
          this.dashboardCount.totalContractorCallInQueue = res.value
          this.dashboardCount.longestContractorCallInQueue = res.additionalValues
          break
        default:
          throw new Error('no data found')
      }
    }
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: {},
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
  }

  private async getComplaintTopTileData(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number
  ) {
    this.isLoading = true
    CustomDashboardController.GetComplaintTopTile(dynamicElementId)
      .then((res: any) => {
        if (res) {
          this.createMediumTile(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading complaint top tile data, please try again', true)
        this.isLoading = false
      })
  }

  private createMediumTile(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    if (this.complaintListForDashBoard === null) {
      this.complaintListForDashBoard = new ComplaintTrackerModel()
    }
    switch (dynamicElementId) {
      case this.dynamicComponentsEnum.TotalOpenComplaint:
        this.complaintListForDashBoard.totalOpenComplaints = res.value
        this.complaintListForDashBoard.openComplaint = res.chartData
        break
      case this.dynamicComponentsEnum.TotalCloseComplaint:
        this.complaintListForDashBoard.totalClosedComplaints = res.value
        this.complaintListForDashBoard.closeComplaint = res.chartData
        break
      case this.dynamicComponentsEnum.TotalOverdueAction:
        this.complaintListForDashBoard.totalOverdueComplaints = res.value
        this.complaintListForDashBoard.overdueComplaint = res.chartData
        break
      case this.dynamicComponentsEnum.SettlementPaidMTD:
        this.complaintListForDashBoard.totalPaidMTDComplaints = res.value
        this.complaintListForDashBoard.paidMTD = res.chartData
        break
      default:
        throw new Error('no data found')
    }

    const item: CustomComponent = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: {},
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
  }

  private getUndeployedJobDashboardData(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number
  ) {
    this.isLoading = true

    DashboardController.GetUndeployedJobEmergencies()
      .then((res: JobHeader[]) => {
        if (res) {
          this.createUndeployedJobHistoryTable(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading undeployed emergency data, please try again', true)
        this.isLoading = false
      })
  }

  private createUndeployedJobHistoryTable(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    const undeployedJobItems: any = res
      ? res.filter((x) => x.undeployedEmergencies && x.undeployedEmergencies.length > 0 && x.undeployedEmergencies[0])
      : []
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: undeployedJobItems,
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private getUnlinkedPhoneCallsTableData(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number
  ) {
    this.isLoading = true

    DashboardController.GetUnlinkedPhoneCallsDashboardData('')
      .then((res: UnlinkedPhoneCallsModel[] | null) => {
        if (res) {
          this.createUnlinkedPhoneCallsTable(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading unlinked phone call data, please try again', true)
        this.isLoading = false
      })
  }

  private createUnlinkedPhoneCallsTable(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    const unlinkedPhoneCallsItems: any = res
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: unlinkedPhoneCallsItems,
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private getManagerSupervisorTableData(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number
  ) {
    this.isLoading = true
    CustomDashboardController.GetManagerSupervisorTableData()
      .then((res: WorkerWithAdditions[] | null) => {
        if (res) {
          this.createManagerSupervisorTable(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading supervisor data, please try again', true)
        this.isLoading = false
      })
  }

  private createManagerSupervisorTable(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    const workersData: Worker[] = res
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: workersData,
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private getIncidentManagerTableData(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number
  ) {
    this.isLoading = true
    const incidentManagerList: UserModel[] = storeGetters.getIncidentManagers()
    this.createIncidentManagerTable(left, top, width, height, dynamicElementId, incidentManagerList)
  }

  private createIncidentManagerTable(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    const incidentManagerList: UserModel[] = res
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: incidentManagerList,
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private getOutstandingComplaintsTableData(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number
  ) {
    this.isLoading = true
    CustomDashboardController.GetOutstandingComplaints()
      .then((res: ComplaintModel[] | null) => {
        if (res) {
          this.createOutstandingComplaintsTable(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading complaint(s), please try again', true)
        this.isLoading = false
      })
  }

  private createOutstandingComplaintsTable(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    const complaints: ComplaintModel[] = res
    if (complaints && complaints.length > 0) {
      complaints.forEach((element) => {
        const getUser = this.userList.find((u) => u.id === element.responsiblePerson)
        if (getUser) {
          element.responsiblePersonName = getUser.displayValue
          element.teamName = getUser.teamName ? getUser.teamName : ''
        }
      })
    }

    if (this.complaintListForDashBoard === null) {
      this.complaintListForDashBoard = new ComplaintTrackerModel()
    }
    this.complaintListForDashBoard.complaints = complaints
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: {},
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private getJobHistoryTableData(left: number, top: number, width: number, height: number, dynamicElementId: number) {
    this.isLoading = true
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: null,
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private getInsurerList() {
    this.selectedInsurer = ''
    if (this.insurerList.length > 0) {
      this.isLoading = false
      this.showSelectInsurerOption = true
      return
    }
    // get insurer list
    if (this.getInsurers.length === 0) {
      this.setInsuerList()
    } else {
      this.insurerList = this.getInsurers
    }
    this.showSelectInsurerOption = true
  }

  private getInsurerPortalList() {
    this.selectedInsurerPortal = ''
    if (this.insurerPortalList.length > 0) {
      this.isLoading = false
      this.showSelectInsurerPortalOption = true
      return
    }
    // get insurer list
    if (this.getInsurerPortal.length === 0) {
      this.setInsurerPortalList(true)
    } else {
      this.insurerPortalList = this.getInsurerPortal
      this.showSelectInsurerPortalOption = true
    }
  }

  private getInsurerPanelData(selectedInsurer: InsurerModel) {
    let isElementExists = 0
    isElementExists = this.dynamicLayout.filter(
      (x: CustomComponent) =>
        x.i === this.getUniqueInsurerId(this.dynamicComponentsEnum.DashboardInsurerPanel, selectedInsurer.id)
    ).length
    if (isElementExists > 0) {
      this.showMessage('Element already exists.')
      return
    }

    this.validateInsurerPanel()
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          CustomDashboardController.GetTwilioDashboardPanelData(selectedInsurer.id, selectedInsurer.name)
            .then((res: InsurerStatsModel | null) => {
              if (res) {
                this.calulateMaxY()
                this.createDashboardInsurerPanel(
                  0,
                  this.axisY,
                  3,
                  8.6,
                  this.dynamicComponentsEnum.DashboardInsurerPanel,
                  res
                )
              } else {
                this.isLoading = false
                this.showMessage()
              }
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error loading dashboard panel data, please try again', true)
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validateInsurerPanel(): Promise<boolean> {
    let result = true
    const insurerValidation: boolean = await this.$validator.validateAll('frmDynamicDashboard')
    // set focus to non validate field
    if (!insurerValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = insurerValidation
    }
    return result
  }

  private getInsurerPortalPanelData(selectedInsurerPortal: ClientTemplateModel) {
    let isElementExists = 0
    isElementExists = this.dynamicLayout.filter(
      (x: CustomComponent) => x.i.toString() === selectedInsurerPortal.id
    ).length
    if (isElementExists > 0) {
      this.showMessage('Element already exists.')
      return
    }

    this.validateInsurerPortalPanel()
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          CustomDashboardController.GetInsurerPortalPanelData(selectedInsurerPortal.id)
            .then((res: ClientPortalStatsModel | null) => {
              if (res) {
                this.calulateMaxY()
                this.createDashboardInsurerPortalPanel(
                  0,
                  this.axisY,
                  3,
                  8.6,
                  this.dynamicComponentsEnum.InsurerPortalPanel,
                  res
                )
              } else {
                this.isLoading = false
                this.showMessage()
              }
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error loading insurer panel data, please try again', true)
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validateInsurerPortalPanel(): Promise<boolean> {
    let result = true
    const insurerValidation: boolean = await this.$validator.validateAll('frmDynamicDashboardInsurerPortal')
    // set focus to non validate field
    if (!insurerValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = insurerValidation
    }
    return result
  }

  private createDashboardInsurerPanel(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    this.callInsurerStats(res.insurerStats)
    this.addIndivualInsurerCallStats(res.insurerStats.insurerId, res.insurerHourlyCallStats, res.insurerCounts)
    this.showSelectInsurerOption = false
    // need to generate unique id to restrict effect of drag and drop on another conponent with same enum value
    const itemId = this.getUniqueInsurerId(this.dynamicComponentsEnum.DashboardInsurerPanel, res.insurerStats.insurerId)
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: itemId,
      elementBody: {
        insurerId: res.insurerStats.insurerId,
        insurerName: res.insurerStats.insurerName,
      },
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private createDashboardContractorPanel(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    if (res && res.contractorStats) {
      this.callContractorStats(res.contractorStats)
    }
    this.addContractorHourlyStats(res.contractorHourlyCallStats)
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: {},
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private createDashboardInsurerPortalPanel(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    this.callClientPortalStats([res.clientPortalStats.clientTemplateId], res.clientPortalStats)
    this.addIndivualClientPortalCallStats(
      res.clientPortalStats.clientTemplateId,
      res.clientPortalHourlyCallStats,
      res.clientPortalCount
    )
    this.showSelectInsurerPortalOption = false
    // need to generate unique id to restrict effect of drag and drop on another conponent with same enum value
    const itemId = res.clientPortalStats.clientTemplateId
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: itemId,
      elementBody: {
        clientTemplateId: res.clientPortalStats.clientTemplateId,
        subDomain: res.clientPortalStats.subDomain,
      },
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private get getUsers(): UserModel[] {
    return storeGetters.getUsers()
  }

  private get getInsurers(): InsurerModel[] {
    return storeGetters.getInsurerList()
  }

  private get getInsurerPortal(): ClientTemplateModel[] {
    return storeGetters.getInsurerPortalList()
  }

  private setInsuerList() {
    InsurerController.GetInsurerList()
      .then((res: InsurerModel[]) => {
        if (res) {
          storeMutations.setInsurerList(res)
          this.insurerList = res.filter((i) => !i.isDelete)
          this.isLoading = false
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading insurer list, please try again', true)
        this.isLoading = false
      })
  }

  private setInsurerPortalList(isInEditMode?: boolean) {
    CustomDashboardController.GetInsurerPortalList()
      .then((res: ClientTemplateModel[]) => {
        if (res) {
          storeMutations.setInsurerPortalList(res)
          this.insurerPortalList = res
          if (isInEditMode) {
            if (this.insurerPortalList.length > 0) {
              this.showSelectInsurerPortalOption = true
            } else {
              this.showMessage('No brand available.')
            }
          }
          this.isLoading = false
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading insurer portal list, please try again', true)
        this.isLoading = false
      })
  }

  private getJobLoggedInLast15Day(left: number, top: number, width: number, height: number, dynamicElementId: number) {
    this.isLoading = true
    CustomDashboardController.GetJobLoggedInLast15Day()
      .then((res: JobCount | null) => {
        if (res) {
          this.createTotalJobsLoggedInLast15DaysTile(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading jobs logged in last 15 days, please try again', true)
        this.isLoading = false
      })
  }

  private createTotalJobsLoggedInLast15DaysTile(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: Shared.setChartDataInOrder(res),
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
  }

  private getTop5Emergencies(left: number, top: number, width: number, height: number, dynamicElementId: number) {
    this.isLoading = true
    CustomDashboardController.GetTop5LoggedEmergency()
      .then((res: any | null) => {
        if (res) {
          this.createTop5EmergencyTile(left, top, width, height, dynamicElementId, res)
        } else {
          this.isLoading = false
          this.showMessage()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading top 5 logged emergency, please try again', true)
        this.isLoading = false
      })
  }

  private createTop5EmergencyTile(
    left: number,
    top: number,
    width: number,
    height: number,
    dynamicElementId: number,
    res: any
  ) {
    this.top5EmergencyChartData = res
    const topEmergency: any = []
    topEmergency.push(res)
    const item = {
      x: left,
      y: top,
      w: width,
      h: height,
      i: dynamicElementId,
      elementBody: topEmergency,
    }
    this.dynamicLayout.push(item)
    this.scrollBottom()
    this.isLoading = false
    this.ready = true
    this.generateChart()
  }

  private getUniqueInsurerId(InsurerCallStatisticsId: number, InsurerId: number) {
    return InsurerCallStatisticsId + this.numberToGenerateUniqueInsurerId + InsurerId
  }

  private getOriginalInsurerId(InsurerCallStatisticsId: number, InsurerId: number) {
    return InsurerCallStatisticsId - this.numberToGenerateUniqueInsurerId - InsurerId
  }

  private generateChart() {
    setTimeout(() => {
      this.top5EmergencyChart = new Chart('pieChartElement', this.PieChartConfiguration())
      this.isTop5EmergencyChartExists = true
    }, 0)
  }

  private PieChartConfiguration(): ChartConfiguration {
    const values = 'values'
    return {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: Object[values](this.top5EmergencyChartData),
            backgroundColor: ['#7CB342', '#29B6F6', '#FB8C00', '#E53935', '#00897B'],
          },
        ],
        labels: Object.keys(this.top5EmergencyChartData),
      },
      options: Shared.getPieChartOptions(),
    }
  }

  private deleteDashboard() {
    if (this.dashboardId) {
      this.isLoading = true
      CustomDashboardController.DeleteCustomDashboard(this.dashboardId).then((res: boolean | null) => {
        if (res) {
          const currentRoute = this.$route.path
          eventBus.$emit('addRemoveDashboardInNavigationMenu', this.dashboardName, currentRoute, true)
          this.$router.replace('/')
          this.isLoading = false
        } else {
          this.isLoading = false
          this.showMessage('Error while deleting dashboard')
        }
      })
    } else {
      eventBus.$emit('validationErrorHandler', 'Cannot delete unsaved custom dashboard.')
      this.isLoading = false
    }
  }

  private calulateMaxY() {
    this.axisY = 0
    const maxYElement = this.dynamicLayout.reduce((prev, current) => (prev.y > current.y ? prev : current))
    if (maxYElement) {
      if (maxYElement.y > 0) {
        this.axisY = maxYElement.y + maxYElement.h
      }
    }
    return this.axisY
  }

  private scrollBottom() {
    if (this.isEditMode) {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth',
        })
      }, 100)
    }
  }

  private showMessage(messageText?: string) {
    if (messageText) {
      this.saveSnackbarText = messageText
      this.saveSnackbar = true
      return
    }
    this.saveSnackbarText = 'No data available.'
    this.saveSnackbar = true
    return
  }
}
</script>
