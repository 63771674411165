import eventBus from '@/common/bus'
import { sleep } from '@/common/sleep'
import { useSignalRStore } from '@/pinia/signal-r'
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr'

export enum ConnectionStatus {
  connecting = 'connecting',
  connected = 'connected',
  disconnecting = 'disconnecting',
  disconnected = 'disconnected',
}
export type ConnectionState = `${ConnectionStatus}`

interface IHubMethodHandler {
  name: string
  handler: (...args: any[]) => void | Promise<void>
}

export default class SignalRHubConnection {
  private readonly maxRetries: number = 3

  private hubName: string
  private hubConnection: HubConnection | null = null
  private handlers: Map<string, IHubMethodHandler>
  private _status: ConnectionState

  public constructor(hubName: string) {
    this.hubName = hubName
    this.handlers = new Map()
    this._status = ConnectionStatus.disconnected
  }

  private async build() {
    const hubUrl = await useSignalRStore().getHubUrl(this.hubName)

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => useSignalRStore().getToken(this.hubName),
      })
      .configureLogging(LogLevel.Warning)
      .build()

    this.handlers.forEach((handler) => {
      this.hubConnection!.on(handler.name, handler.handler)
    })

    this.hubConnection.on('notificationGroupRemoved', () => {
      // Not handling this event write a warning in the console, but we don't need it.
    })

    this.hubConnection.onclose(async () => {
      this._status = ConnectionStatus.disconnected
    })
  }

  public get status(): ConnectionState {
    return this._status
  }

  public async connect(): Promise<void> {
    if (this.hubConnection === null) {
      await this.build()
    }

    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      this._status = ConnectionStatus.connecting

      try {
        await this.hubConnection!.start()
        this._status = ConnectionStatus.connected
        return
      } catch (err) {
        this._status = ConnectionStatus.disconnected
        eventBus.$emit('errorHandler', `SignalR hub ${this.hubName} failed to connect`, false, '', err)
        useSignalRStore().refreshConnection(this.hubName)
        await sleep(attempt * 2)
      }
    }

    // Display error message to the user only when we give up retrying
    eventBus.$emit('errorHandler', `SignalR hub ${this.hubName} failed to connect`, true)
  }

  public async disconnect() {
    if (this.hubConnection === null) {
      return
    }

    try {
      this._status = ConnectionStatus.disconnecting
      await this.hubConnection.stop()
      this._status = ConnectionStatus.disconnected
    } catch (err) {
      eventBus.$emit('errorHandler', `SignalR hub ${this.hubName} failed to disconnect`, err)
    }
  }

  public addHandler(name: string, handler: (...args: any[]) => void) {
    if (this.handlers.get(name)) {
      return
    }

    this.handlers.set(name, { name, handler })

    if (this.hubConnection) {
      this.hubConnection.on(name, handler)
    }
  }

  public removeHandler(name: string) {
    if (this.hubConnection) {
      this.hubConnection.off(name)
    }

    this.handlers.delete(name)
  }

  public runHandler(name: string, ...args: any[]) {
    const handler = this.handlers.get(name)
    if (handler) {
      handler.handler.apply(this, args)
    }
  }

  public async changeHubNameAndReconnect(hubName: string) {
    await this.disconnect()
    this.hubName = hubName
    await this.build()
    await this.connect()
  }
}
