<template>
  <v-flex xs12>
    <div class="bg-white pa-3">
      <v-layout row wrap>
        <v-flex xs12>
          <h3>Drainage Report</h3>
        </v-flex>
      </v-layout>
      <v-divider class="mt-2 mb-3"></v-divider>
      <v-layout row wrap>
        <v-flex v-if="getDrainageReport" xs12>
          <div class="gridView mt-2 drainage-report-gridview">
            <v-data-table
              :headers="headers"
              :items="drainageReportData"
              class="elevation-1"
              item-key="id"
              hide-actions
              :loading="generatePDFInProcess || isLoading"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>{{ props.item.name }}</td>
                  <td>
                    {{ props.item.createdAt ? getFormattedDate(props.item.createdAt) : '-' }}
                  </td>
                  <td>
                    {{ props.item.updatedAt ? getFormattedDate(props.item.updatedAt) : '-' }}
                  </td>
                  <td align="right">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn icon flat color="primary" class="mr-0 edit-btn" v-on="on">
                          <v-icon class="md-icon" @click="editDrainageReport(props.item)">edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          flat
                          class="download-btn"
                          color="primary"
                          :disabled="generatePDFInProcess"
                          v-on="on"
                          @click="downloadDrainageReport(props.item)"
                        >
                          <v-icon class="md-icon">get_app</v-icon>
                        </v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-flex>
        <v-flex v-else xs12>
          <div class="text-xs-right">
            <v-btn
              class="blue white--text generate-report"
              :disabled="isLoading"
              :loading="isLoading"
              @click="generateDrainageReport()"
            >
              Generate Report
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <!-- Drainage Report Document template -->
    <ReportDocumentTemplate
      v-if="showDrainageDocumentTemplateSelection"
      ref="refReportDocumentTemplate"
      :document-template-list="drainageDocumentTemplateList"
      :job-id="jobId"
      @getDocumentTemplateText="getDocumentTemplateText"
      @closeDocumentTemplateDialog="closeDocumentTemplateDialog"
      @saveDocument="saveDocument"
      @showSnackBarWithMessage="showSnackBarWithMessage"
    ></ReportDocumentTemplate>
    <v-snackbar v-if="showSnackbar" v-model="showSnackbar" bottom left :timeout="snackbarTimeout">
      {{ snackbarText }}
      <v-btn color="secondary" flat @click.native="showSnackbar = false">Close</v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import Shared from '@/common/shared'
import ComplaintModel from '@/models/claim/ComplaintModel'
import storeGetters from '@/storeGetters'
import moment from 'moment'
import Store from '@/store'
import jsPDF from 'jspdf'
import ReportDocumentTemplate from '@/components/ReportDocumentTemplate.vue'
import DrainageReportModel from '@/models/claim/DrainageReportModel'
import ReportController from '@/api/reportController'
import AddUpdateDrainageReportModel from '@/models/requests/AddUpdateDrainageReportModel'
import eventBus from '@/common/bus'

@Component({
  components: { ReportDocumentTemplate },
})
export default class DrainageReportPreview extends TimeLineItemBase {
  private showDrainageDocumentTemplateSelection = false
  private drainageDocumentTemplateList: DocumentTemplateModel[] = []
  private snackbarText = ''
  private showSnackbar = false
  private snackbarTimeout = 3000
  private isLoading = false
  private loadTemplatesInProgress = false
  private generatePDFInProcess = false
  private complaintModel: ComplaintModel = new ComplaintModel()
  private marginsForPdf = { top: 10, bottom: 40, left: 30, width: 550 }
  private headers: any = [
    { text: 'Name', value: 'name', sortable: false },
    { text: 'Created At', value: 'createdAt', sortable: false },
    { text: 'Updated At', value: 'updatedAt', sortable: false },
    { text: '', value: '', sortable: false },
  ]
  private drainageReportData: DrainageReportModel[] = []

  private get getDrainageReport(): DrainageReportModel | null {
    return storeGetters.getDrainageReport(this.jobId)
  }

  private created() {
    if (this.getDrainageReport) {
      this.drainageReportData.push(this.getDrainageReport)
    }
  }

  private generateDrainageReport() {
    this.getDocumentTemplateList()
  }

  private getDocumentTemplateList(isEditReport = false) {
    const self = this
    if (!(self.job && self.job.insurerId)) {
      return
    }
    self.isLoading = true
    self.showDrainageDocumentTemplateSelection = false
    setTimeout(() => {
      self.$validator.errors.items = []
    }, 0)
    self.drainageDocumentTemplateList = []

    ReportController.GetDrainageReportTemplates(self.job.insurerId)
      .then((res: { [key: number]: string } | null) => {
        if (res) {
          const values = 'values'
          const keyList = Object.keys(res)
          const valueList = Object[values](res)
          for (let index = 0; index < keyList.length; index++) {
            const complaintDocumentTemplate: any = {}
            complaintDocumentTemplate.templateId = Number(keyList[index])
            complaintDocumentTemplate.name = valueList[index]
            self.drainageDocumentTemplateList.push(complaintDocumentTemplate)
          }
          if (!isEditReport) {
            self.showDrainageDocumentTemplateSelection = true
          }
        } else {
          self.showSnackBarWithMessage('No Template(s) Found')
        }
        this.isLoading = false
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error loading report template(s), please try again', true)
        this.isLoading = false
      })
  }

  private closeDocumentTemplateDialog() {
    this.showDrainageDocumentTemplateSelection = false
  }

  private showSnackBarWithMessage(message: string) {
    this.snackbarText = message
    this.showSnackbar = true
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private async editDrainageReport(item: DrainageReportModel) {
    await this.getDocumentTemplateList(true)
    this.isLoading = true
    this.showDrainageDocumentTemplateSelection = true
    setTimeout(() => {
      const refReportDocumentTemplate = this.$refs.refReportDocumentTemplate as ReportDocumentTemplate
      if (refReportDocumentTemplate) {
        refReportDocumentTemplate.selectedDocumentTemplate = item.templateId
        refReportDocumentTemplate.templateText = item.htmlBody
      }
    }, 0)
    this.isLoading = false
  }

  private getDocumentTemplateText(documentTemplateId: number) {
    const self = this
    const refReportDocumentTemplate = this.$refs.refReportDocumentTemplate as ReportDocumentTemplate
    ReportController.GetDrainageReport(
      self.jobId,
      documentTemplateId,
      self.getDrainageReport ? self.getDrainageReport.id : ''
    )
      .then((res: DocumentTemplateModel) => {
        if (refReportDocumentTemplate) {
          refReportDocumentTemplate.handleTemplateResponse(res, documentTemplateId)
        }
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error loading template detail, please try again', true)
        if (refReportDocumentTemplate) {
          refReportDocumentTemplate.loadTemplatesInProgress = false
        }
      })
  }

  private saveDocument(documentTemplateId: number, templateText: string) {
    const self = this
    const template = this.drainageDocumentTemplateList.find((e) => e.templateId === documentTemplateId)
    const drainageReport: AddUpdateDrainageReportModel = new AddUpdateDrainageReportModel()
    drainageReport.jobId = this.jobId
    drainageReport.id = this.getDrainageReport && this.getDrainageReport.id ? this.getDrainageReport.id : ''
    drainageReport.templateId = documentTemplateId
    drainageReport.htmlBody = templateText
    drainageReport.name = template ? template.name : ''
    drainageReport.updatedAt = drainageReport.id ? moment() : null
    const refReportDocumentTemplate = this.$refs.refReportDocumentTemplate as ReportDocumentTemplate
    if (refReportDocumentTemplate) {
      refReportDocumentTemplate.generatePDFInProcess = true
    }
    ReportController.SaveDrainageReport(drainageReport)
      .then((res: boolean) => {
        if (refReportDocumentTemplate) {
          refReportDocumentTemplate.generatePDFInProcess = false
        }
        if (res) {
          this.showDrainageDocumentTemplateSelection = false
          this.showSnackBarWithMessage(
            drainageReport.id ? 'Report Detail Updated Successfully!' : 'Report Generated Successfully!'
          )
        } else {
          this.showSnackBarWithMessage('Something Went Wrong!')
        }
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error saving drainage report, please try again', true)
        this.showSnackBarWithMessage('Something Went Wrong!')
        if (refReportDocumentTemplate) {
          refReportDocumentTemplate.generatePDFInProcess = false
        }
      })
  }

  private downloadDrainageReport(item: DrainageReportModel) {
    this.generatePDFInProcess = true
    const pdf = new jsPDF('p', 'pt', 'a4')
    // set font size
    pdf.setFontSize(18)
    // generate pdf from html
    pdf.fromHTML(
      item.htmlBody,
      this.marginsForPdf.left,
      this.marginsForPdf.top,
      { width: this.marginsForPdf.width },
      (dispose) => {
        // set header and footer
        this.headerFooterFormatting(pdf, pdf.internal.getNumberOfPages(), 'Drainage')
        // generate pdf
        pdf.save(item.name + '_' + this.jobId)
      },
      this.marginsForPdf
    )
    this.generatePDFInProcess = false
  }

  private headerFooterFormatting(doc, totalPages, desc) {
    for (let i = totalPages; i >= 1; i--) {
      doc.setPage(i)
      this.header(doc, desc)
      this.footer(doc, i, totalPages)
      doc.page++
    }
  }

  private header(doc, desc) {
    // set style for header
    // doc.setFontSize(20);
    // doc.setTextColor('#009688');
    // doc.setFontStyle('normal');
    // doc.text(desc + " Report", this.marginsForPdf.left + 190, 30 );
    // doc.setLineCap(2);
    // doc.line(3, 50, this.marginsForPdf.width + 43, 50); // horizontal line
  }

  private footer(doc, pageNumber, totalPages) {
    // set style for footer
    const str = 'Page ' + pageNumber + ' of ' + totalPages
    doc.setLineCap(1)
    doc.setFontSize(10)
    doc.text(str, this.marginsForPdf.left, doc.internal.pageSize.height - 20)
  }
}
</script>

<style scoped>
@media only screen and (max-width: 1435px) {
  .drainage-report-gridview >>> thead th {
    word-break: break-all;
    white-space: normal;
  }
  .drainage-report-gridview >>> thead th:first-child {
    width: 100px;
    min-width: 100px;
  }
  .drainage-report-gridview >>> thead th:nth-child(2) {
    width: 96px;
    min-width: 96px;
  }
  .drainage-report-gridview >>> thead th:nth-child(3) {
    width: 102px;
    min-width: 102px;
  }
  .drainage-report-gridview >>> thead th:nth-child(4) {
    width: 120px;
    min-width: 120px;
  }
}
</style>
