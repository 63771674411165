<template>
  <div v-if="ready" class="dashboard">
    <v-container fluid pa-0>
      <v-layout wrap>
        <v-flex lg7 class="m-supvr-lcol-outer">
          <v-container fluid px-0 pt-1>
            <v-layout wrap>
              <v-flex xs12 mb-3>
                <v-container fluid grid-list-md pt-0 pa-0>
                  <v-layout wrap calls-info request-info>
                    <DashboardTopTile
                      v-for="key in Object.keys(topLeftTiles)"
                      :key="key"
                      v-bind="topLeftTiles[key]"
                      dashboard-tile-view="MangerSupervisorLeft"
                    />
                  </v-layout>
                </v-container>
                <span class="surge">
                  <span class="surge-inner">
                    <span class="surge-text grey--text">
                      <b>Surge</b>
                    </span>
                    <v-switch
                      v-model="inSurgeLocal"
                      hide-details
                      color="secondary"
                      class="pt-0 mt-0"
                      @change="updateSurge"
                    ></v-switch>
                  </span>
                </span>
              </v-flex>
              <v-flex xs12>
                <ManagerSupervisorTable
                  v-if="ready"
                  table-name="manageSupervisorDashboard"
                  :is-from-main-dash-board="true"
                  :workers="workers"
                ></ManagerSupervisorTable>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
        <v-flex lg5 class="m-supvr-rcol-outer">
          <v-container fluid grid-list-md pt-0 pr-0 class="m-supvr-rcol">
            <v-layout wrap calls-info>
              <DashboardTopTile
                v-for="key in Object.keys(topTiles)"
                :key="key"
                v-bind="topTiles[key]"
                dashboard-tile-view="MangerSupervisorRight"
              />
            </v-layout>
            <v-layout wrap class="system-info syetsm-dashboard">
              <DashboardPanel
                v-for="key in Object.keys(panels)"
                :key="key"
                v-bind="panels[key]"
                flex-class="xs12 md6"
              />
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  <div v-else>
    <v-progress-circular
      class="loading-spinner"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import Chart from 'chart.js'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import DashboardController from '@/api/dashboardController'
import { UpdateSurge } from '@/signalr/twilioEventModels/events'
import Dashboard from '@/views/Dashboard.vue'
import Shared from '@/common/shared'
import ManagerSupervisorTable from '@/components/tables/ManagerSupervisorTable.vue'

@Component({
  components: { DashboardTopTile, ManagerSupervisorTable },
})
export default class ManagerSupervisorDashboard extends Dashboard {
  private teamList: string[] = []
  private activeIndex = 0
  private inSurgeLocal = false

  public async created() {
    this.childPage = 'ManagerSupervisorDashboard'
    this.inSurgeLocal = await this.getSurgeAsync()
  }

  private updateSurge() {
    DashboardController.UpdateSurgeStatus(this.inSurgeLocal)
  }

  private async getSurgeAsync() {
    return await Promise.resolve(await DashboardController.GetSurgeStatus())
  }

  private get topLeftTiles(): { [k: string]: TopTileProps } {
    return {
      pickupRate: {
        title: 'OutStanding Urgent\nRequests',
        value: 0, // OutStanding urgent Requests data to be displayed-: TODO
        showChart: false,
        chartData: [],
        backgroundColor: Shared.colorGreen,
        textColor: Shared.colorWhite,
        showArrow: false,
        infoValue: [],
      },
      totalCalls: {
        title: 'Missed Urgent\nRequests this hour',
        value: 0, // Miseed urgent Requests this hour data to be displayed-: TODO
        showChart: false,
        chartData: [],
        backgroundColor: Shared.colorDeepOrangeLighten2,
        textColor: Shared.colorWhite,
        showArrow: false,
        infoValue: [],
      },
    }
  }
}
</script>

<style scoped>
.teamname-supervisor {
  position: relative;
}
.status-menu-content {
  position: relative;
}
.status-menu-content >>> .menu {
  position: absolute;
  right: -5px;
  top: 10px;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.surge {
  position: absolute;
}
.surge-inner {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.surge-inner .surge-text {
  margin-right: 10px;
}
@media only screen and (max-width: 1263px) {
  .m-supvr-rcol {
    padding-left: 0;
  }
}
</style>
