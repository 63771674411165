import moment from 'moment'
import ContactNumber from '@/models/contractor/ContactNumber'
export default class AddContractorAppointedModel {
  public id: string | null
  public jobId: string
  public forEmergencyTypeId: number
  public forEmergencyDetailId: number
  public status: string
  public contractorId: string
  public etaFrom: moment.Moment | null
  public etaTo: moment.Moment | null
  public companyName: string
  public contactNumbers: ContactNumber[]
  public reasonType: string
  public reasonDescription: string
  public isContractorReAttend: boolean
  public emergencyId: string
  public isHealthAffected: boolean | null
  public healthAndSafetyLevel: string | null
  public isVulnerability: boolean | null
  public vulnerabilityLevel: string | null
  public tradeId: number
  public hasDelay: boolean
  public delayCode: string | null
  public isUnavailable: boolean
  public additionalCharge: number
  public isInCoverageArea: boolean
  public engineerId?: string
  public additionalTravelTime: string

  public constructor() {
    this.etaFrom = moment()
    this.etaTo = moment()
    this.hasDelay = false
    this.delayCode = null
  }
}
