import moment from 'moment'

export default class MonitoringVisitLog {
  public static nextId = 0
  public visitId: number
  public visitDate: moment.Moment
  public reportSentDate: moment.Moment
  public invoicedDate: moment.Moment | null
  public cost: number

  public constructor(log?: MonitoringVisitLog) {
    this.visitId = MonitoringVisitLog.nextId++
    this.visitDate = moment()
    this.reportSentDate = moment()
    this.invoicedDate = null

    if (log) {
      this.visitId = log.visitId
      this.visitDate = moment(log.visitDate)
      this.reportSentDate = moment(log.reportSentDate)
      this.invoicedDate = log.invoicedDate ? moment(log.invoicedDate) : null
      this.cost = log.cost
    }
  }
}
