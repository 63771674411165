<template>
  <div v-if="item">
    <div class="mb-2"><h4>Update Trial Pit Borehole Details</h4></div>
    <div v-if="item.boreHoleRunNumber" class="mb-1">
      <label>BoreHole Run Number: </label>
      <span>{{ item.boreHoleRunNumber }}</span>
    </div>
    <div v-if="item.boringMethod" class="mb-1">
      <label>Boring Method: </label>
      <span>{{ item.boringMethod }}</span>
    </div>
    <div v-if="item.diameter" class="mb-1">
      <label>Diameter: </label>
      <span>{{ item.diameter }}</span>
    </div>
    <div v-if="item.weather" class="mb-1">
      <label>Weather: </label>
      <span>{{ item.weather }}</span>
    </div>
    <div v-if="item.remarks" class="mb-1">
      <label>Remarks: </label>
      <span>{{ item.remarks }}</span>
    </div>
    <div v-if="item.groundLevel" class="mb-1">
      <label>Ground Level: </label>
      <span>{{ item.groundLevel }}</span>
    </div>
    <template v-if="item.changes.length > 0">
      <v-divider class="mb-1"></v-divider>
      <div v-for="(changeItem, index) in item.changes" :key="index">
        <div v-if="changeItem.depth" class="mb-1">
          <label>Depth: </label>
          <span>{{ changeItem.depth }}</span>
        </div>
        <div v-if="changeItem.thickness" class="mb-1">
          <label>Thikness: </label>
          <span>{{ changeItem.thickness }}</span>
        </div>
        <div v-if="changeItem.testType" class="mb-1">
          <label>Test: </label>
          <span>{{ changeItem.testType }}</span>
        </div>
        <div v-if="changeItem.testResult" class="mb-1">
          <label>Result: </label>
          <span>{{ changeItem.testResult }}</span>
        </div>
        <div v-if="changeItem.legend" class="mb-1">
          <label>Legend: </label>
          <span>{{ changeItem.legend }}</span>
        </div>
        <div v-if="changeItem.soilDescription" class="mb-1">
          <label>Soil Description: </label>
          <span>{{ changeItem.soilDescription }}</span>
        </div>
        <v-divider v-if="index !== item.changes.length - 1" class="mb-1"></v-divider>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddBoreHoleLog from '@/models/siteInvestigation/requests/AddBoreHoleLog'

@Component
export default class UpdateTrialPitBoreholeLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddBoreHoleLog {
    return Object.assign(new AddBoreHoleLog(), this.requestBody)
  }
}
</script>
