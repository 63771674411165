<template>
  <v-stepper v-model="stepIndex" non-linear class="elevation-0">
    <v-stepper-header>
      <v-stepper-step
        :step="1"
        :editable="stepIndex > 1"
        :complete="step1validated"
        class="enquiry-step1"
        @click.native.stop="switchToStep(1)"
      >
        Policy Details
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        :step="2"
        :editable="step1validated && stepIndex > 2 && !isPolicyEnquiryToggleOn && !isHelpLinePolicy"
        :complete="step2validated"
        class="enquiry-step2"
        @click.native.stop="switchToStep(2)"
      >
        Emergency Details
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        :step="3"
        :editable="step1validated && step2validated && stepIndex > 3"
        :complete="wizardSubmitStatus === 'submitted'"
        class="enquiry-step3"
        @click.native.stop="switchToStep(3)"
      >
        {{ getStep3Header }}
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-content step="1">
      <v-form autocomplete="off">
        <v-container fluid pa-0 grid-list-lg>
          <v-layout wrap>
            <v-layout
              v-if="
                !policySelected &&
                !manualPick &&
                isOnline &&
                (!isCustomPolicySearchFeatureFlagEnabled || !useCustomSearch)
              "
              wrap
            >
              <v-flex xs12 mapping-alert>
                <v-autocomplete
                  id="cmbPolicySearch"
                  ref="cmbPolicySearch"
                  v-model="selectedSearchId"
                  v-validate="'required'"
                  tabindex="0"
                  :label="policySearchLabel"
                  :loading="isOmnisearchRunning"
                  :items="searchResults"
                  browser-autocomplete="off"
                  :search-input.sync="searchValue"
                  item-text="displayValue"
                  item-value="id"
                  autofocus
                  :filter="customFilter"
                  :disabled="policySearchDisabled"
                  required
                  class="required"
                  :data-vv-scope="step1formRef"
                  data-vv-name="Search policy"
                  :error-messages="errors.collect('Search policy')"
                  data-vv-validate-on="input"
                >
                  <template slot="item" slot-scope="data">
                    <v-list-tile-content>
                      <v-list-tile-title
                        :class="data.item.id === 'manualSelect' ? 'starred-outer secondary--text' : ''"
                        v-html="
                          sanitizeHTML(
                            data.item.id === 'manualSelect'
                              ? 'Select Policy Manually'
                              : highlightPhrase(data.item.displayValue)
                          )
                        "
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout v-else wrap>
              <div v-if="isCustomPolicySearchFeatureFlagEnabled && useCustomSearch">
                <CustomPolicySearch
                  ref="customPolicySearchRef"
                  @assumedCoverPolicySelected="setAssumedCoverPolicy($event)"
                  @policySelected="retrieveCustomPolicy($event.clientId, $event.path)"
                />
              </div>
              <div v-else>
                <v-flex v-if="getPreviouslySearchedValue" xs12 grey lighten-4 grey--text pt-3 ml-2>
                  {{ getPreviouslySearchedValue }}
                </v-flex>
                <v-flex xs12 py-0 mb-2 style="padding-left: 0px; padding-right: 0px">
                  <v-flex>
                    <PolicySelector
                      v-if="(isOnline && manualPick && policies) || (!isOnline && offlinePolicies)"
                      id="policySelector"
                      ref="policySelectorRef"
                      v-validate="'required'"
                      style="padding-top: 10px; padding-bottom: 0px; padding-left: 0px"
                      type="number"
                      required
                      :policy-tree="isOnline ? policies : offlinePolicies"
                      :error-messages="policySelected ? [] : ['A policy must be selected']"
                      :data-vv-scope="step1formRef"
                      data-vv-name="Selected Policy"
                      :selected-policy="selectedPolicy"
                      :pre-selected-policy="preSelectedPolicyScheduleId"
                      class="mb-4"
                      :bind-only-starred-policies="isOnline"
                      @update:selectedPolicy="updatePolicySelection"
                    />
                    <v-select
                      v-else
                      id="selectedPolicyDropdown"
                      v-model="claimModel.policyScheduleId"
                      v-validate="'required'"
                      :disabled="true"
                      type="number"
                      :placeholder="claimModel.policyScheme"
                      data-vv-name="Selected Policy"
                    />
                    <v-alert
                      v-model="showDisclaimer"
                      color="warning"
                      icon="priority_high"
                      dismissible
                      class="warning-mapping"
                    >
                      DISCLAIMER: {{ disclaimerMessage }}
                    </v-alert>
                  </v-flex>
                </v-flex>
                <v-flex v-if="isCustomPolicySearchFeatureFlagEnabled && policyValidationStatuses.length">
                  <v-alert v-model="policySelected" class="mb-5 mt-0 mx-2" color="warning" icon="priority_high" outline>
                    <div v-for="(status, index) in policyValidationStatuses" :key="`validationStatus-${index}`">
                      <div name="PolicyValidationErrorMessage"> {{ status.message }}</div>
                    </div>
                  </v-alert>
                </v-flex>
                <v-flex xs12 py-0>
                  <CustomerAddress
                    ref="addCustomerAddress"
                    :passed-client-title.sync="getClientTitle"
                    :passed-first-name.sync="getFirstName"
                    :passed-last-name.sync="getLastName"
                    :passed-policy-number.sync="getPolicyNumber"
                    :passed-secondary-client-title.sync="getSecondaryClientTitle"
                    :passed-secondary-first-name.sync="getSecondaryFirstName"
                    :passed-secondary-last-name.sync="getSecondaryLastName"
                    :passed-address-line1.sync="getAddressLine1"
                    :passed-address-line2.sync="getAddressLine2"
                    :passed-address-line3.sync="getAddressLine3"
                    :passed-address-line4.sync="getAddressLine4"
                    :passed-post-code.sync="getPostCode"
                    :is-display-policy-number="true"
                    :customer-prefilled-details="customerPrefilledDetails"
                    :passed-policy-number-not-present-reason.sync="getPolicyNumberNotPresentReason"
                    :is-h-e-job="true"
                    :is-assumed-cover-policy="!policyValidation.isCovered"
                  ></CustomerAddress>
                </v-flex>
              </div>
            </v-layout>
          </v-layout>
        </v-container>
      </v-form>
    </v-stepper-content>
    <v-stepper-content step="2" class="pb-0">
      <v-form autocomplete="off">
        <v-container class="pb-0 pt-2">
          <v-layout>
            <v-flex>
              <div class="policy-logo">
                <img v-if="policyUrl && policyUrl !== null && policyUrl !== ''" :src="policyUrl" />
                <img v-else src="/img/dummy-logo.jpeg" />
              </div>
            </v-flex>
            <v-flex class="emergency-info-table">
              <v-layout wrap class="shadow-outer emergency-table">
                <PropertyCard
                  :size="4"
                  title="PH Name"
                  :detail="
                    (claimModel.clientTitle ? claimModel.clientTitle : '') +
                    ' ' +
                    claimModel.clientForename +
                    ' ' +
                    claimModel.clientLastName
                  "
                  :other-detail="
                    (claimModel.secondaryClientTitle ? claimModel.secondaryClientTitle + ' ' : '') +
                    (claimModel.secondaryClientForename ? claimModel.secondaryClientForename + ' ' : '') +
                    (claimModel.secondaryClientLastName ? claimModel.secondaryClientLastName : '')
                  "
                />
                <PropertyCard
                  :size="4"
                  title="Policy Number"
                  :detail="claimModel.policyNumber ? '#' + claimModel.policyNumber : ''"
                />
                <PropertyCard :size="4" title="PostCode" :detail="claimModel.postCode" />
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-container class="pb-0 px-0">
              <div class="perilItems">
                <AddHEEmergency
                  v-if="wizardLoadState === 'loaded'"
                  ref="addEmergency"
                  v-model="claimModel.emergencies[0]"
                  :disabled="isPolicyEnquiryToggleOn || isHelpLinePolicy"
                  :add-emergency-index="0"
                  :effective-date="effectiveDate"
                />
                <div v-else>Loading dropdown values, please wait ...</div>
              </div>
              <div class="link-switch mb-2">
                <span>
                  <v-switch
                    v-if="isPolicyEnquiryOrClaimAllowed"
                    v-model="isPolicyEnquiryToggleOn"
                    label="Enquiry Only"
                    color="primary"
                    small
                    hide-details
                  ></v-switch>
                  <v-switch
                    v-else-if="isPolicyEnquiryToggleOn"
                    color="primary"
                    label="Enquiry Only"
                    small
                    :input-value="isPolicyEnquiryToggleOn"
                    disabled
                    hide-details
                  ></v-switch>
                </span>
              </div>
            </v-container>
          </v-layout>
          <v-layout v-if="filterPreviousJobsList.length > 0" wrap>
            <v-flex xs12 px-2>
              <v-textarea
                v-model.trim="claimModel.reasonForCreatingDuplicateJob"
                v-validate="'required|max:2000'"
                label="Reason for creating duplicate job"
                maxlength="2000"
                required
                class="required"
                :data-vv-scope="step2formRef"
                data-vv-name="Reason for creating duplicate job"
                name="ReasonForCreatingDuplicateJob"
                :error-messages="errors.collect('Reason for creating duplicate job')"
                :disabled="isPolicyEnquiryToggleOn || isHelpLinePolicy"
              ></v-textarea>
            </v-flex>
            <h3 class="mb-2 mt-2">{{ filterPreviousJobsList.length }} Job(s) found in last 30 days..</h3>
            <v-flex xs12 elevation-1 px-2 pt-2 pb-0 mb-2>
              <v-layout v-for="item in filterPreviousJobsList" :key="item.jobId" wrap grey lighten-3 mb-2 py-2 px-3>
                <v-flex xs6 mb-2>
                  <b class="grey--text text--darken-1">
                    Job ID:
                    <a href="Javascript:void(0)" class="secondary--text" @click="redirectToJob(item.jobId)">
                      {{ item.jobId }}
                    </a>
                  </b>
                </v-flex>
                <v-flex v-if="item.createdAt" xs6 text-xs-right>
                  <b class="grey--text text--darken-1">Logged on {{ getFormattedDate(item.createdAt) }}</b>
                </v-flex>
                <v-flex xs12>
                  <b v-for="(prevJob, index) in getPreviousJobsByJobId(item.jobId)" :key="index">
                    {{
                      prevJob.description +
                      (prevJob.secondaryDescription ? ' (' + prevJob.secondaryDescription + ')' : '')
                    }}{{ index === getPreviousJobsByJobId(item.jobId).length - 1 ? '' : ',' }}
                  </b>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-stepper-content>
    <v-stepper-content step="3">
      <v-form autocomplete="off">
        <v-container>
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap class="shadow-outer">
                <PropertyCard
                  :size="4"
                  title="PH Name"
                  :detail="
                    (claimModel.clientTitle ? claimModel.clientTitle : '') +
                    ' ' +
                    claimModel.clientForename +
                    ' ' +
                    claimModel.clientLastName
                  "
                  :other-detail="
                    (claimModel.secondaryClientTitle ? claimModel.secondaryClientTitle + ' ' : '') +
                    (claimModel.secondaryClientForename ? claimModel.secondaryClientForename + ' ' : '') +
                    (claimModel.secondaryClientLastName ? claimModel.secondaryClientLastName : '')
                  "
                />
                <PropertyCard
                  :size="4"
                  title="Policy Number"
                  :detail="claimModel.policyNumber ? '#' + claimModel.policyNumber : ''"
                />
                <PropertyCard :size="4" title="PostCode" :detail="claimModel.postCode" />
              </v-layout>
            </v-flex>
            <v-flex xs12 pt-4>
              <v-layout v-if="!isPolicyEnquiryToggleOn" wrap>
                <v-flex xs12>
                  <ContactPreferenceDetails
                    ref="contactPreferenceDetails"
                    :customer-contact-preference-modal="customerContactPreferenceModal"
                    @updateContactPreference="updateContactPreference"
                  ></ContactPreferenceDetails>
                </v-flex>
              </v-layout>
              <v-layout v-else wrap mt-3>
                <v-flex>
                  <v-autocomplete
                    v-model="enquiryModel.enquiryCategory"
                    v-validate="'required'"
                    :items="isOnline ? enquiryCategories : getEnquiryCategoriesOffline"
                    label="Enquiry Category"
                    item-value="description"
                    item-text="description"
                    required
                    class="required"
                    :loading="isLoadingEnquiryCategory"
                    :data-vv-scope="step3formRef"
                    data-vv-name="Enquiry Category"
                    :error-messages="validationMessage('Enquiry Category')"
                    @change="onChangeCategory($event)"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-tile-content>
                        <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                          {{ data.item.description }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model.trim="enquiryModel.enquiryDescription"
                    v-validate="'required'"
                    label="Enquiry for"
                    required
                    class="required"
                    :data-vv-scope="step3formRef"
                    data-vv-name="Enquiry"
                    :error-messages="errors.collect('Enquiry')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-stepper-content>
    <v-dialog v-model="configureDropdownDialog" max-width="820" persistent content-class="v-dialog--scrollable">
      <ConfigureDropdown
        ref="configureDropdown"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialog"
      ></ConfigureDropdown>
    </v-dialog>
  </v-stepper>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator'
import AddHEEmergency from '@/components/AddHEEmergency.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import PropertyCard from '@/components/PropertyCard.vue'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import DialogWizard from '@/common/DialogWizard'
import SearchItemModel from '@/models/policyHolder/SearchItemModel'
import PolicyHolderController from '@/api/policyHolderController'
import MasterRecordController from '@/api/masterRecordController'
import JobController from '@/api/jobController'
import PolicyScheduleController from '@/api/policyScheduleController'
import InsurerController from '../api/InsurerController'
import moment from 'moment'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'
import { ConnectionStatus } from '@/common/environment'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import AddEmergency from '@/models/requests/AddEmergency'
import Store from '@/store'
import PolicyModel from '@/models/policy/PolicyModel'
import { InsurerBrandList, SelectedPolicy } from '@/models/policy/PolicyLists'
import { ContactPreferenceEnum, EnquiryType, OfflineJobRequestType, RecordType } from '@/common/enums'
import { JobType } from '@/models/types'
import AddJobWithPolicyEnquiry from '@/models/requests/AddJobWithPolicyEnquiry'
import MasterRecord from '@/models/MasterRecord'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import CustomerAddress from '@/components/CustomerAddress.vue'
import ContactPreferenceDetails from '@/components/ContactPreferenceDetails.vue'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'
import InsurerPhoneNumberModel from '@/models/client/InsurerPhoneNumberModel'
import AddHelplineJobRequestModel from '@/models/requests/AddHelplineJobRequestModel'
import PolicySelector from '@/components/PolicySelector.vue'
import PreviousJobModel from '@/models/policyHolder/PreviousJobModel'
import store from '@/store'
import BrandPolicyModel from '@/models/policy/BrandPolicyModel'
import CustomPolicySearch from '@/components/claim-wizard/CustomPolicySearch.vue'
import PolicyValidation from '@/models/policy/PolicyValidation'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import AssumedCover from '@/models/policy/AssumedCover'
import JobPolicyDetails, { PolicyDetails } from '@/models/policy/JobPolicyDetails'

@Component({
  name: 'AddHEClaimWizard',
  components: {
    AddHEEmergency,
    PropertyCard,
    ConfigureDropdown,
    CustomerAddress,
    ContactPreferenceDetails,
    PolicySelector,
    CustomPolicySearch,
  },
})
export default class AddHEClaimWizard extends DialogWizard {
  public get getStep3Header(): string {
    return this.isPolicyEnquiryToggleOn ? 'POLICY ENQUIRY' : 'CONTACT PREFERENCES'
  }

  public get getClientTitle(): string {
    return this.claimModel.clientTitle
  }

  public set getClientTitle(newValue: string) {
    this.claimModel.clientTitle = newValue
  }

  public get getFirstName(): string {
    return this.claimModel.clientForename
  }

  public set getFirstName(newValue: string) {
    this.claimModel.clientForename = newValue
  }

  public get getLastName(): string {
    return this.claimModel.clientLastName
  }

  public set getLastName(newValue: string) {
    this.claimModel.clientLastName = newValue
  }

  public get getSecondaryClientTitle(): string | null {
    return this.claimModel.secondaryClientTitle
  }

  public set getSecondaryClientTitle(newValue: string | null) {
    this.claimModel.secondaryClientTitle = newValue
  }

  public get getSecondaryFirstName(): string | null {
    return this.claimModel.secondaryClientForename
  }

  public set getSecondaryFirstName(newValue: string | null) {
    this.claimModel.secondaryClientForename = newValue
  }

  public get getSecondaryLastName(): string | null {
    return this.claimModel.secondaryClientLastName
  }

  public set getSecondaryLastName(newValue: string | null) {
    this.claimModel.secondaryClientLastName = newValue
  }

  public get getPolicyNumber(): string {
    return this.claimModel.policyNumber
  }

  public set getPolicyNumber(newValue: string) {
    this.claimModel.policyNumber = newValue
  }

  public get getAddressLine1(): string {
    return this.claimModel.addressLine1
  }

  public set getAddressLine1(newValue: string) {
    this.claimModel.addressLine1 = newValue
  }

  public get getAddressLine2(): string {
    return this.claimModel.addressLine2
  }

  public set getAddressLine2(newValue: string) {
    this.claimModel.addressLine2 = newValue
  }

  public get getAddressLine3(): string {
    return this.claimModel.addressLine3
  }

  public set getAddressLine3(newValue: string) {
    this.claimModel.addressLine3 = newValue
  }

  public get getAddressLine4(): string {
    return this.claimModel.addressLine4
  }

  public set getAddressLine4(newValue: string) {
    this.claimModel.addressLine4 = newValue
  }

  public get getPostCode(): string {
    return this.claimModel.postCode
  }

  public set getPostCode(newValue: string) {
    this.claimModel.postCode = newValue
  }

  public get policySelected(): boolean {
    return !!this.selectedPolicy.policy
  }

  public get getPolicyNumberNotPresentReason(): string {
    return this.claimModel.policyNumberNotPresentReason
  }

  public set getPolicyNumberNotPresentReason(newValue: string) {
    this.claimModel.policyNumberNotPresentReason = newValue
  }

  public get isHelpLinePolicy(): boolean {
    return !!this.selectedBrandPolicy && this.selectedBrandPolicy.isHelplinePolicy
  }

  public get policySearchLabel(): string {
    return 'Search by name, policy number, address line 1 or postcode'
  }

  public stepIndex = 1

  private sanitizeHTML = Shared.sanitizeHTML
  private wizardWasOpenedFromCall = false
  @Prop() private offlineDB: any
  public step1formRef = 'ahecw_step1form'
  public step2formRef = 'ahecw_step2form'
  public step3formRef = 'ahecw_step3form'
  public step1validated = false
  public step2validated = false
  private step3validated = false
  public claimModel: AddHEJobWithEmergencies = new AddHEJobWithEmergencies()
  public enquiryModel: AddJobWithPolicyEnquiry = new AddJobWithPolicyEnquiry()
  public wizardSubmitStatus: 'none' | 'submitting' | 'submitted' = 'none'
  public wizardLoadState: 'unloaded' | 'loading' | 'loaded' = 'unloaded'
  private waitForMoreInputTimeoutHandle: number | null = null
  private wizardVisibilityTimeoutHandlerId: number | null = null
  public searchValue = ''
  public searchResults: SearchItemModel[] = []
  public selectedSearchId = ''
  private jobType: JobType = 'HE'
  private contactPreference = ContactPreferenceEnum
  public policySearchDisabled: boolean = Store.Instance.state.Environment.ConnectionStatus !== ConnectionStatus.Online
  public configureDropdownDialog = false
  public configureDropdownType = ''
  public isLoadingEnquiryCategory = false
  public preSelectedPolicyScheduleId: null | number = null
  public enquiryCategories: MasterRecord[] = []
  private isAssumedCover = false
  private localSearchValue = '' // Local variable to get searchvalue when starred policy is selected.
  public policyUrl: string | null = ''
  public customerContactPreferenceModal: UpdateCustomerContactPreferenceModal =
    new UpdateCustomerContactPreferenceModal()
  private customerExistingPhoneNumbersList: InsurerPhoneNumberModel[] = []
  private helpLinePolicyModel: AddHelplineJobRequestModel = new AddHelplineJobRequestModel()
  public selectedPolicy: SelectedPolicy = {
    insurer: null,
    brand: null,
    policy: null,
  }
  public manualPick = false
  public policies: InsurerBrandList[] | null = null
  public offlinePolicies: InsurerBrandList[] | null = null
  private policyList: BrandPolicyModel[] = []
  private offlinePolicyList: BrandPolicyModel[] = []
  private prevPolicyId: number | undefined = undefined
  public disclaimerMessage = ''
  public showDisclaimer = false
  private previousJobsList: PreviousJobModel[] = []
  public filterPreviousJobsList: PreviousJobModel[] = []
  private previousLastName = ''
  private previousPostcode = ''
  public customerPrefilledDetails: any = new Object() // variable to check if the customer validates
  public effectiveDate: Date | null = null

  public useCustomSearch = false
  private insurerMapping: Record<number, string> = {
    1: 'RSA',
    2: 'LV',
    3: 'II',
    4: 'OC',
  }
  private policyValidation: PolicyValidation = new PolicyValidation()

  public isOmnisearchRunning = false
  public isPolicyEnquiryToggleOn: boolean | null = false

  // Retrieves all policies in the system
  public async getPolicies() {
    this.isOmnisearchRunning = true
    this.policySearchDisabled = true

    // policy list for offline usage
    this.offlinePolicies = await PolicyScheduleController.GetPolicySchedule(false, this.jobType)
    this.offlinePolicyList = []
    if (this.offlinePolicies && this.offlinePolicies.length > 0) {
      const policies = this.offlinePolicies.map((b) => b.brands && b.brands.map((p) => p.policies))
      policies.forEach((policy) => {
        if (policy) {
          const mappedPolicies = policy.flatMap((p) => p)
          mappedPolicies.forEach((p) => {
            this.offlinePolicyList.push(p)
          })
        }
      })
    } else {
      this.offlinePolicies = []
    }

    if (navigator.onLine) {
      this.policies = this.offlinePolicies.map((schedule) => ({
        ...schedule,
        brands:
          schedule.brands &&
          schedule.brands.map((brand) => ({
            ...brand,
            policies: brand.policies.filter((policy) => policy.isStarred),
          })),
      }))

      this.policyList = []
      if (this.policies && this.policies.length > 0) {
        const policies = this.policies.map((b) => b.brands && b.brands.map((p) => p.policies))
        policies.forEach((policy) => {
          if (policy) {
            const mappedPolicies = policy.flatMap((p) => p)
            mappedPolicies.forEach((p) => {
              this.policyList.push(p)
            })
          }
        })
      } else {
        this.policies = []
      }
    }

    this.isOmnisearchRunning = false
    this.policySearchDisabled = Store.Instance.state.Environment.ConnectionStatus !== ConnectionStatus.Online
  }

  public clearForm() {
    // Trigger a reset of the form
    this.wizardVisibilityChanged(true)
  }

  public switchToStep(step: number) {
    // don't move wizard if submitting/submitted
    if (this.wizardSubmitStatus !== 'none') {
      return
    }
    if (step > this.stepIndex) {
      this.validateStep(this.stepIndex)
        .then((result: [number, string, boolean]) => {
          const passed: boolean = result[2]
          switch (result[1]) {
            case this.step1formRef:
              this.step1validated = passed
              break
            case this.step2formRef:
              this.step2validated = passed
              break
            case this.step3formRef:
              this.step3validated = passed
              break
          }
          if (passed) {
            switch (step) {
              case 3:
                if (this.isPolicyEnquiryToggleOn) {
                  this.getCategories()
                }
                this.wizardPageChanged('Submit')
                break
              case 2:
                // get previous connected job list
                this.getPreviousJobList()
                if (this.manualPick) {
                  this.getPolicyUrl()
                }
                break
              default:
                this.wizardPageChanged('Next')
            }
            this.stepIndex = step
          } else {
            this.stepIndex = result[0]
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    } else {
      this.stepIndex = step
      this.wizardPageChanged('Next')
    }
  }

  public async processCurrentPage(): Promise<void> {
    try {
      const result = await this.validateStep(this.stepIndex)
      const passed: boolean = result[2]
      switch (result[1]) {
        case this.step1formRef:
          this.step1validated = passed
          if (passed) {
            this.wizardPageChanged('Next')
            if (this.isHelpLinePolicy) {
              this.wizardPageChanged('Submit')
              this.stepIndex = 3
            } else if (!this.isPolicyEnquiryOrClaimAllowed && this.isPolicyEnquiryToggleOn) {
              this.getCategories()
              this.wizardPageChanged('Submit')
              this.stepIndex = 3
            } else {
              this.stepIndex = 2
              // get previous connected job list
              this.getPreviousJobList()
              if (this.manualPick) {
                this.getPolicyUrl()
              }
            }
          }
          break
        case this.step2formRef:
          this.step2validated = passed
          if (passed) {
            if (this.isPolicyEnquiryToggleOn) {
              this.getCategories()
            }
            this.wizardPageChanged('Submit')
            this.stepIndex = 3
          }
          break
        case this.step3formRef:
          this.step3validated = passed
          if (passed) {
            if (this.isHelpLinePolicy) {
              this.addHelplinePolicy()
            } else if (!this.isPolicyEnquiryToggleOn) {
              if (!this.step2validated) {
                this.stepIndex = 2
                this.wizardPageChanged('Next')
              } else {
                this.saveClaim()
              }
            } else {
              this.savePolicyEnquiry()
            }
          }
          break
      }
    } catch (err: unknown) {
      eventBus.$emit('validationErrorHandler')
    }
  }

  @Watch('selectedSearchId')
  protected searchItemSelected() {
    const id = this.selectedSearchId
    if (!id) {
      return
    }

    const searchItem: SearchItemModel | undefined = this.searchResults.find((e) => e.id === id)
    if (!searchItem) {
      return
    }
    this.isOmnisearchRunning = true
    if (searchItem.id === 'manualSelect') {
      this.isAssumedCover = true
      this.manualPick = true
      this.isOmnisearchRunning = false
      this.searchValue = this.localSearchValue
      searchItem.displayValue = this.localSearchValue
      const cmbPolicySearch: any = this.$el.querySelector('#cmbPolicySearch')
      setTimeout(() => {
        cmbPolicySearch.blur()
      }, 100)
    } else {
      this.isAssumedCover = false
      this.loadClaimFromSearchResult(searchItem)
      this.localSearchValue = this.searchValue
    }
  }

  @Watch('selectedPolicy')
  protected policyChanged() {
    if (!this.selectedBrandPolicy) {
      this.policyValidation.isCovered = false
      return
    }

    if (this.selectedPolicy && this.selectedPolicy.policy) {
      // We need to set the toggle to check if enquiry is the only option (No claim can be sent).
      // If enquiry is the only option, the toggle will be disabled, so the user cannot change the value.
      this.isPolicyEnquiryToggleOn = this.isPolicyEnquiryOnlyAllowed

      if (this.prevPolicyId && this.selectedPolicy.policy.id !== this.prevPolicyId) {
        if (this.selectedBrandPolicy.disclaimer && this.selectedBrandPolicy.disclaimer.trim() !== '') {
          this.disclaimerMessage = this.selectedBrandPolicy.disclaimer
          this.showDisclaimer = true
        }
        // if the policy schedule has changed, clear the policy number field, as it will need to be confirmed
        this.claimModel.policyNumber = ''
      }

      this.policyValidation.isCovered = !this.selectedBrandPolicy.isStarred
      this.prevPolicyId = this.selectedPolicy.policy.id
    } else {
      this.policyValidation.isCovered = true
    }
  }

  // Policy is set up to allow enquiries only. No claims. If this is true, then a disabled toggle
  // will appear on step 2, forcing the user to submit an enquiry, rather than a claim.
  public get isPolicyEnquiryOnlyAllowed(): boolean {
    return !!this.selectedBrandPolicy && this.selectedBrandPolicy.enquiryType === EnquiryType.Default
  }

  // Policy is set up for claims OR enquiries. If this is true, then a toggle
  // will appear on step 2 to allow the user to decide to send through an enquiry or a claim.
  public get isPolicyEnquiryOrClaimAllowed(): boolean {
    return !!this.selectedBrandPolicy && this.selectedBrandPolicy.enquiryType === EnquiryType.On
  }

  public get selectedBrandPolicy() {
    if (this.selectedPolicy && this.selectedPolicy.policy) {
      return navigator.onLine
        ? this.policyList.find((p) => p.id === (this.selectedPolicy.policy || {}).id)
        : this.offlinePolicyList.find((p) => p.id === (this.selectedPolicy.policy || {}).id)
    }
    return null
  }

  @Watch(DialogWizard.WizardVisiblePropName)
  protected async wizardVisibilityChanged(newValue: boolean) {
    if (newValue) {
      Shared.passJobIdInHeader()
      // get policies
      if (!this.policies || this.policies.length === 0) {
        await this.getPolicies()
      }

      // set nominee relation in store to use it in offline mode
      this.setNomineeRelationListInStore()

      // set nominee relation in store to use it in offline mode
      this.setEnquiryCategoriesListInStore()

      this.$emit('showUploadDocument', false)

      this.enquiryModel = new AddJobWithPolicyEnquiry()
      this.helpLinePolicyModel = new AddHelplineJobRequestModel()
      this.isAssumedCover = false

      const addEmergencySub = this.$refs.addEmergency as AddHEEmergency
      if (addEmergencySub !== undefined) {
        addEmergencySub.resetAddEmergency()
      }

      if (this.wizardLoadState === 'loaded') {
        // wizard loaded, push initial empty emergency on
        this.claimModel.emergencies = this.claimModel.emergencies || []
        this.claimModel.emergencies.push(new AddEmergency())
      } else if (this.wizardLoadState === 'unloaded') {
        // wizard not loaded yet, get items
        this.wizardLoadState = 'loading'
        this.addDefaultEmergency()
      }

      if (this.wizardWasOpenedFromCall) {
        this.wizardWasOpenedFromCall = false
        return
      }

      //  wizard shown, reset form
      this.claimModel = new AddHEJobWithEmergencies()

      this.addDefaultEmergency()

      const sub = this.$refs.contactPreferenceDetails as ContactPreferenceDetails
      if (sub !== undefined) {
        sub.setDefaultValues()
      }
      this.customerContactPreferenceModal = new UpdateCustomerContactPreferenceModal()

      const customerAddress = this.$refs.addCustomerAddress as CustomerAddress
      if (customerAddress !== undefined) {
        customerAddress.setDefaultValues()
      }

      this.updateContentHeight(550)
      this.wizardPageChanged('Next')
      this.stepIndex = 1
      this.step1validated = false
      this.step2validated = false
      this.step3validated = false
      this.wizardSubmitStatus = 'none'
      if (!this.claimModel.policyScheduleId) {
        this.selectedSearchId = ''
      }
      this.isOmnisearchRunning = false
      this.searchValue = ''
      this.searchResults = []
      this.prevPolicyId = undefined

      this.preSelectedPolicyScheduleId = null

      this.manualPick = false
      this.selectedPolicy = {
        insurer: null,
        brand: null,
        policy: null,
      }

      this.previousPostcode = ''
      this.previousLastName = ''
      if (!navigator.onLine) {
        const policySelector = this.$refs.policySelectorRef as PolicySelector
        if (policySelector !== undefined) {
          policySelector.setDefaultValues()
        }
      }

      if (this.wizardVisibilityTimeoutHandlerId) {
        window.clearTimeout(this.wizardVisibilityTimeoutHandlerId)
      }
      this.wizardVisibilityTimeoutHandlerId = window.setTimeout(() => {
        this.$validator.errors.items = []
        if (navigator.onLine) {
          this.setDefaultFocus()
        }

        setTimeout(() => {
          this.addManualSelectItem(this.searchResults)
        }, 500)
      }, 0)
    } else {
      this.wizardWasOpenedFromCall = false
    }
    this.customerPrefilledDetails = new Object() //  reset customerPrefilledDetails value on clear form
    this.policyValidation.isCovered = true

    const customPolicySearch = this.$refs.customPolicySearchRef as CustomPolicySearch
    if (customPolicySearch !== undefined) {
      customPolicySearch.clearCustomPolicyForm()
    }
  }

  @Watch('searchValue')
  protected searchValueChanged(newValue: string, oldValue: string) {
    if (newValue === '') {
      return
    }
    if (newValue === this.localSearchValue) {
      return
    }
    if (newValue === 'Select Policy Manually') {
      return
    }
    const cmbPolicySearch = this.$refs.cmbPolicySearch as any

    if (cmbPolicySearch.selectedItem && cmbPolicySearch.selectedItem.displayValue === newValue) {
      return
    }
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }
    this.waitForMoreInputTimeoutHandle = window.setTimeout(() => {
      // waited long enough, do the search
      if (this.searchValue) {
        this.isOmnisearchRunning = true
        PolicyHolderController.Search(this.searchValue)
          .then((items: SearchItemModel[]) => {
            this.addManualSelectItem(items)
            this.searchResults = items
            this.isOmnisearchRunning = false
          })
          .catch((err: any) => {
            this.searchResults = []
            this.isOmnisearchRunning = false
            eventBus.$emit('errorHandler', 'Error loading policyholder list, please try again', true)
          })
      }
    }, 300)
  }

  private mounted() {
    eventBus.$on('openHEWizardForPH', async (searchItemModel: SearchItemModel) => {
      if (searchItemModel) {
        this.wizardWasOpenedFromCall = false
        await this.wizardVisibilityChanged(true)
        this.loadClaimFromSearchResult(searchItemModel)
        this.selectedSearchId = 'manualSelect'
        this.isOmnisearchRunning = false
        this.manualPick = true
        this.searchValue = ''
        this.localSearchValue = ''
        this.wizardWasOpenedFromCall = true
      }
    })
    eventBus.$on('policyScheduleIdHEFilter', (policyScheduleId: number | null) => {
      if (policyScheduleId) {
        this.preSelectedPolicyScheduleId = policyScheduleId
      }
    })
  }

  private async loadClaimFromSearchResult(searchItemModel: SearchItemModel): Promise<void> {
    this.retrieveCustomPolicy(searchItemModel.insurer.toString(), searchItemModel.path)
  }

  public onChangeCategory(item: any) {
    if (item === 'Configure') {
      const element: any = document.getElementsByClassName('application')
      if (element && element.length > 0) {
        element[0].classList.add('enquiry-category-dialog')
      }
      this.configureDropdownType = 'Category'
      this.configureDropdownDialog = true
    }
  }

  private getCategories() {
    // Get all categories and add one Item for Configuration
    this.setEnquiryCategoriesListInStore(true)
  }

  public onConfigureDropdownDialog(items: MasterRecord[]) {
    // update category items
    this.configureDropdownDialog = false
    this.configureDropdownType = ''
    this.enquiryModel.enquiryCategory = ''
    this.enquiryCategories = items.slice(0)
    const category: any = []
    category.description = 'Configure'
    this.enquiryCategories.push(category)
  }

  private addDefaultEmergency(): void {
    // we have all dropdown data required to create an emergency
    // now add an initial one
    this.claimModel.emergencies = this.claimModel.emergencies || []
    if (!this.claimModel.emergencies.length) {
      this.claimModel.emergencies.push(new AddEmergency())
    }
    this.wizardLoadState = 'loaded'
  }

  private addManualSelectItem(items: SearchItemModel[]) {
    this.localSearchValue = this.searchValue
    const manualSelectItem: any = {
      id: 'manualSelect',
      manualSelect: this.localSearchValue ? this.localSearchValue : 'Select policy manually',
    }
    if (items) {
      items.push(manualSelectItem)
      this.searchResults = items
    }
  }
  // Custom filter for search policy autocomplete
  public customFilter(item: SearchItemModel, queryText: string, itemText: string) {
    return true
  }

  private setContactPreferenceDetail() {
    Object.keys(this.customerContactPreferenceModal).forEach(
      (key) => (this.claimModel[key] = this.customerContactPreferenceModal[key])
    )

    // reset email on sms/call selection
    if (
      this.claimModel.contactPreference === this.contactPreference[this.contactPreference.SMS] ||
      this.claimModel.contactPreference === this.contactPreference[this.contactPreference.Call]
    ) {
      this.claimModel.email = ''
    }
    // reset mobilephone on email selection
    if (this.claimModel.contactPreference === this.contactPreference[this.contactPreference.Email]) {
      this.claimModel.mobilePhone = ''
    }
    // reset reasonForChoosingCallOption on SMS/Email selection
    if (this.claimModel.contactPreference !== this.contactPreference[this.contactPreference.Call]) {
      this.claimModel.reasonForChoosingCallOption = ''
    }
    // nominee - reset email on sms/call selection
    if (
      this.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.SMS] ||
      this.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.Call]
    ) {
      this.claimModel.nomineeEmail = ''
    }
    // nominee - reset mobilephone on email selection
    if (this.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.Email]) {
      this.claimModel.nomineeMobilePhone = null
    }
    // nominee - reset reasonForChoosingCallOption on SMS/Email selection
    if (this.claimModel.nomineeContactPreference !== this.contactPreference[this.contactPreference.Call]) {
      this.claimModel.nomineeReasonForChoosingCallOption = ''
    }
    // reset Policy Number Not Present Reason
    if (!this.policyValidation.isCovered && this.getPolicyNumber) {
      this.getPolicyNumberNotPresentReason = ''
    }
  }

  private async saveClaim(): Promise<void> {
    this.setContactPreferenceDetail()
    // ignore if currently submitting or submitted
    if (this.wizardSubmitStatus !== 'none') {
      return
    }
    this.wizardBackEndOperation(true)

    // set the call sid if we're on a call
    this.claimModel.FNOLCallSid = storeGetters.getCurrentCallSid()

    // check weather starred policy selected
    this.claimModel.isStarredPolicy = this.isAssumedCover

    // check store for existing phone numbers to compare
    this.customerExistingPhoneNumbersList = storeGetters.getCustomersPhoneNumber()
    if (
      navigator.onLine &&
      !this.checkInitialNetworkConnection() &&
      this.customerExistingPhoneNumbersList.length === 0
    ) {
      try {
        const res = await InsurerController.GetInsurerPhoneNumbers()

        if (res && res.length > 0) {
          storeMutations.setCustomersPhoneNumber(res)
          this.customerExistingPhoneNumbersList = res
        } else {
          eventBus.$emit('errorHandler', 'Error loading insurer phone numbers, please try again', true)
        }
      } catch (err: unknown) {
        eventBus.$emit('errorHandler', 'Error loading insurer phone numbers, please try again', true)
      }
    }

    await this.saveJobClaim()
  }

  private async saveJobClaim(): Promise<void> {
    if (this.claimModel.mobilePhone) {
      if (this.checkIsNumberAlreadyAssigned(this.claimModel.mobilePhone)) {
        // show error
        this.wizardBackEndOperation(false)
        eventBus.$emit('errorHandler', 'Provided contact number already in use', true)
        this.wizardSubmitStatus = 'none'
        return
      }
    }

    if (this.claimModel.nomineeMobilePhone) {
      if (this.checkIsNumberAlreadyAssigned(this.claimModel.nomineeMobilePhone)) {
        // show error
        this.wizardBackEndOperation(false)
        eventBus.$emit('errorHandler', 'Provided nominee contact number already in use', true)
        this.wizardSubmitStatus = 'none'
        return
      }
    }

    // Restrict to add multiple emergencies at a time of job creation
    let isNullEmergency = false
    if (this.claimModel.emergencies && this.claimModel.emergencies.length > 0) {
      if (
        this.claimModel.emergencies.length === 1 &&
        (!this.claimModel.emergencies[0].emergencyDetailId || !this.claimModel.emergencies[0].tradeId)
      ) {
        isNullEmergency = true
      } else {
        const emergency: AddEmergency | undefined = this.claimModel.emergencies.find(
          (e) => e.emergencyDetailId !== null && e.tradeId !== undefined
        )
        if (emergency) {
          this.claimModel.emergencies = [emergency]
        } else {
          isNullEmergency = true
        }
      }
    } else {
      isNullEmergency = true
    }

    if (isNullEmergency) {
      this.wizardBackEndOperation(false)
      this.wizardSubmitStatus = 'none'
      eventBus.$emit('showSnackbar', 'Job cannot be logged with null emergency.')
      return
    }

    if (navigator.onLine && !this.checkInitialNetworkConnection()) {
      try {
        const res = await JobController.SaveClaim(this.claimModel)

        this.wizardSubmitStatus = 'submitted'
        this.wizardBackEndOperation(false)
        // submitted ok, route to it
        if (!res) {
          eventBus.$emit('errorHandler', 'error submitting job (SaveClaim), please try again', true)
          this.wizardSubmitStatus = 'none'
          return
        }
        this.wizardWasOpenedFromCall = false
        this.sendWizardCompleteEvent()
        this.$router.push({
          name: 'job',
          params: {
            jobId: res,
          },
        })

        if (this.isCustomPolicySearchFeatureFlagEnabled) {
          this.$store.dispatch('policyModule/updatePolicyDetails', res)
        }
      } catch (err: unknown) {
        this.wizardBackEndOperation(false)
        this.wizardSubmitStatus = 'none'
        eventBus.$emit('errorHandler', err, false)
      }
    } else {
      this.saveOfflineData(OfflineJobRequestType.Emergency, this.claimModel)
    }
  }

  private checkIsNumberAlreadyAssigned(phoneNumber: string): boolean {
    return this.customerExistingPhoneNumbersList.some((x) => x.phoneNumber === phoneNumber)
  }

  public validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  // set default focus
  private setDefaultFocus() {
    const cmbPolicySearch: any = this.$el.querySelector('#cmbPolicySearch')
    cmbPolicySearch.focus()
  }

  private async validateStep(step: number): Promise<[number, string, boolean]> {
    let scope = ''
    switch (step) {
      case 1:
        scope = this.step1formRef
        break
      case 2:
        scope = this.step2formRef
        break
      case 3:
        scope = this.step3formRef
        break
      default:
        return [step, scope, false]
    }

    let result: boolean = await this.$validator.validateAll(scope)

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    } else {
      let result2 = true
      let firstNoticedValidation = true

      if (step === 1) {
        const subs = this.$refs.addCustomerAddress as CustomerAddress
        result2 = await subs.$validator.validateAll()
        if (!result2) {
          Shared.setValidationFocus(this.$el as HTMLElement)
          result = result2
        }
      }
      if (step === 2 && !this.isPolicyEnquiryToggleOn) {
        const subs = this.$refs.addEmergency as AddHEEmergency
        result2 = await subs.$validator.validateAll()
        // validate the date picker of firstNoticed
        const dateTimePickerSub = subs.$refs.dateTimePicker as DateTimePicker
        firstNoticedValidation = await dateTimePickerSub.$validator.validateAll()
        if (!result2 || !firstNoticedValidation) {
          Shared.setValidationFocus(this.$el as HTMLElement)
          result = result2 && firstNoticedValidation
        }
      }
      if (step === 3 && !this.isPolicyEnquiryToggleOn) {
        const sub3 = this.$refs.contactPreferenceDetails as ContactPreferenceDetails
        result = await sub3.validateContactPreferenceStep()
      }
    }
    return [step, scope, result]
  }

  private savePolicyEnquiry() {
    // ignore if currently submitting or submitted
    if (this.wizardSubmitStatus !== 'none') {
      return
    }
    this.wizardBackEndOperation(true)
    const enquiryModel: AddJobWithPolicyEnquiry = Object.assign(this.enquiryModel, this.claimModel)
    // get the current call sid
    enquiryModel.FNOLCallSid = storeGetters.getCurrentCallSid()

    // check weather starred policy selected
    enquiryModel.isStarredPolicy = this.isAssumedCover

    if (navigator.onLine && !this.checkInitialNetworkConnection()) {
      JobController.SavePolicyEnquiry(enquiryModel)
        .then((res: string | null) => {
          this.wizardSubmitStatus = 'submitted'
          this.wizardBackEndOperation(false)
          // submitted ok, route to it
          if (!res) {
            eventBus.$emit('errorHandler', 'error submitting job, please try again', true)
            this.wizardSubmitStatus = 'none'
            return
          }

          this.sendWizardCompleteEvent()
          this.$router.push({
            name: 'job',
            params: {
              jobId: res,
            },
          })
        })
        .catch((err: any) => {
          this.wizardBackEndOperation(false)
          this.wizardSubmitStatus = 'none'
          eventBus.$emit('errorHandler', err, false)
        })
    } else {
      this.saveOfflineData(OfflineJobRequestType.PolicyEnquiry, enquiryModel)
    }
  }

  private addHelplinePolicy() {
    // ignore if currently submitting or submitted
    if (this.wizardSubmitStatus !== 'none') {
      return
    }
    this.wizardBackEndOperation(true)
    this.setContactPreferenceDetail()
    const tempObject = Object.assign({}, this.claimModel)
    delete tempObject.emergencies
    const helpLinePolicyModel: AddHelplineJobRequestModel = Object.assign(this.helpLinePolicyModel, tempObject)

    // get the current call sid
    helpLinePolicyModel.FNOLCallSid = storeGetters.getCurrentCallSid()

    // check weather starred policy selected
    helpLinePolicyModel.isStarredPolicy = this.isAssumedCover

    if (navigator.onLine && !this.checkInitialNetworkConnection()) {
      JobController.AddHelplinePolicyRequest(helpLinePolicyModel)
        .then((res: string | null) => {
          this.wizardSubmitStatus = 'submitted'
          this.wizardBackEndOperation(false)
          // submitted ok, route to it
          if (!res) {
            eventBus.$emit('errorHandler', 'error submitting job, please try again', true)
            this.wizardSubmitStatus = 'none'
            return
          }

          this.sendWizardCompleteEvent()
          this.$router.push({
            name: 'job',
            params: {
              jobId: res,
            },
          })
        })
        .catch((err: any) => {
          this.wizardBackEndOperation(false)
          this.wizardSubmitStatus = 'none'
          eventBus.$emit('errorHandler', err, false)
        })
    } else {
      this.saveOfflineData(OfflineJobRequestType.HelpLine, helpLinePolicyModel)
    }
  }

  private getPolicyUrl() {
    const policyId: number = this.claimModel.policyScheduleId
    PolicyScheduleController.GetPolicyById(policyId)
      .then((res: PolicyModel | null) => {
        if (res === null) {
          this.policyUrl = ''
          return
        }
        this.policyUrl = res.policyBasicDetail.fileURL !== null ? res.policyBasicDetail.fileURL : ''
      })
      .catch((err: any) => {
        this.policyUrl = ''
        eventBus.$emit('errorHandler', 'Error loading policy detail, please try again', true)
      })
  }

  public updateContactPreference(updateCustomerContactPreferenceModel: UpdateCustomerContactPreferenceModal) {
    this.customerContactPreferenceModal = updateCustomerContactPreferenceModel
  }

  public updatePolicySelection(newSelected: SelectedPolicy) {
    this.selectedPolicy = newSelected
    if (this.selectedPolicy && this.selectedPolicy.policy) {
      this.claimModel.policyScheduleId = this.selectedPolicy.policy.id
      this.claimModel.policyScheme = this.selectedPolicy.policy.name
      this.claimModel.insurer = this.selectedPolicy.insurer ? this.selectedPolicy.insurer.name : ''
      const policySelector: any = this.$el.querySelector('#policySelector')
      setTimeout(() => {
        policySelector.blur()
      }, 100)
    } else {
      this.selectedPolicy = { insurer: null, brand: null, policy: null }
    }
  }

  public get getPreviouslySearchedValue() {
    if (this.localSearchValue && this.localSearchValue.trim() !== '' && this.localSearchValue.trim()) {
      return this.localSearchValue
    }
    return ''
  }
  // Check if userRole is Administrator.
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  public get getEnquiryCategoriesOffline() {
    return this.enquiryCategories.filter((x) => x.description !== 'Configure')
  }

  private getPreviousJobList() {
    if (
      this.previousLastName.toLowerCase() !== this.claimModel.clientLastName.toLowerCase() ||
      this.previousPostcode.toLowerCase() !== this.claimModel.postCode.toLowerCase()
    ) {
      this.previousLastName = this.claimModel.clientLastName
      this.previousPostcode = this.claimModel.postCode
      this.previousJobsList = []
      this.filterPreviousJobsList = []
      JobController.GetBasicDetailOfPolicyHolderJobs(this.claimModel.clientLastName, this.claimModel.postCode, 30)
        .then((res: PreviousJobModel[]) => {
          if (res && res.length > 0) {
            this.previousJobsList = res
            let jobId = ''
            this.filterPreviousJobsList = res.filter((e) => {
              if (e.jobId !== jobId) {
                jobId = e.jobId
                return true
              }
              return false
            })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading policy holder detail, please try again', true)
        })
    }
  }

  private saveOfflineData(requestType: OfflineJobRequestType, offlineData: any) {
    if (this.offlineDB.objectStoreNames.contains(Shared.offlineJobRequestTable)) {
      const jobObjectStore = this.offlineDB
        .transaction(Shared.offlineJobRequestTable, 'readwrite')
        .objectStore(Shared.offlineJobRequestTable)
      const timestamp: Date = new Date()
      const offlineTimestamp: string = timestamp.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })

      let requestBody: any = {}
      requestBody = offlineData
      requestBody.OfflineTimestamp = offlineTimestamp
      requestBody.requestType = requestType

      const addRequest = jobObjectStore.add(JSON.parse(JSON.stringify(requestBody)))
      addRequest.onsuccess = (event) => {
        this.wizardSubmitStatus = 'submitted'
        this.sendWizardCompleteEvent()
        eventBus.$emit('errorHandler', 'Job saved succesfully in offline mode.', true, 'green')
      }

      addRequest.onerror = (error) => {
        eventBus.$emit('errorHandler', 'error submitting job (SaveClaim), please try again', true)
        this.wizardSubmitStatus = 'none'
      }
    } else {
      /* there's an issue with the database because the store should exist (should have been created on the onupgradeneeded), but we cannot create the store here */
      eventBus.$emit('errorHandler', 'No local store has been created. Please refresh the page and try again', true)
      this.wizardSubmitStatus = 'none'
    }
  }

  private checkInitialNetworkConnection(): boolean {
    // if offline job filling in progress and suddenly internet connection re-estamblished,
    // save job in offline mode to avoid authorization error
    const offlineUserRole: string | null = localStorage.getItem('offlineUserRole')
    if (offlineUserRole) {
      return offlineUserRole.toLowerCase() === 'unknown'
    }
    return false
  }

  private setNomineeRelationListInStore() {
    const nomineeRelations: MasterRecord[] = storeGetters
      .getNomineeRelations()
      .filter((e) => e.isDeleted === false || e.isDeleted)
    if (nomineeRelations.length === 0) {
      MasterRecordController.GetMasterRecords(RecordType[RecordType.NomineeRelation]).then((res: MasterRecord[]) => {
        if (res) {
          storeMutations.setNomineeRelations(res)
        }
      })
    }
  }

  public getFormattedDate(createdAt: moment.Moment | Date): string {
    if (createdAt) {
      return Shared.getFormatedDate(moment(createdAt), Store.Instance.state.Environment.DateTimeFormat)
    }
    return ''
  }

  public getPreviousJobsByJobId(jobId: string): PreviousJobModel[] {
    return this.previousJobsList.filter((e) => e.jobId === jobId)
  }

  public redirectToJob(jobId: string) {
    eventBus.$emit('redirectToJob', jobId)
    Shared.passJobIdInHeader(jobId)
  }

  private setEnquiryCategoriesListInStore(showLoader = false) {
    if (this.enquiryCategories.length === 0) {
      const enquiryCategories: MasterRecord[] = storeGetters.getEnquiryCategories().filter((e) => e.isDeleted === false)
      if (enquiryCategories.length === 0) {
        this.isLoadingEnquiryCategory = showLoader
        MasterRecordController.GetMasterRecords('Category')
          .then((res: MasterRecord[]) => {
            if (res) {
              storeMutations.setEnquiryCategories(res)
              this.enquiryCategories = res.filter((e) => e.isDeleted === false)
              this.setConfigureOption()
              this.isLoadingEnquiryCategory = false
            }
          })
          .catch((err: any) => {
            this.isLoadingEnquiryCategory = false
            eventBus.$emit('errorHandler', 'Error loading enquiry categories, please try again', true)
          })
      } else {
        this.enquiryCategories = enquiryCategories
        this.setConfigureOption()
      }
    } else {
      this.setConfigureOption()
    }
  }

  private setConfigureOption() {
    const hasConfigureButton: boolean = this.enquiryCategories.filter((x) => x.description === 'Configure').length > 0
    if (this.isUserRoleAdministrator && !hasConfigureButton) {
      const category: any = []
      category.description = 'Configure'
      this.enquiryCategories.push(category)
    }
  }

  private destroyed() {
    Shared.passJobIdInHeader()
  }

  public highlightPhrase(displayText: string) {
    return Shared.highlightPhrase(this.searchValue, displayText)
  }

  public get isCustomPolicySearchFeatureFlagEnabled(): boolean {
    return !!this.$ld.variation('sfi-735-rsa-policy-search')
  }

  public setAssumedCoverPolicy(assumedCover: AssumedCover) {
    this.claimModel.policyScheduleId = Number(assumedCover.id)

    this.claimModel.policyScheme = assumedCover.name
    this.policyValidation.isCovered = false

    this.setSelectedPolicy(Number(assumedCover.clientId), Number(assumedCover.id), assumedCover.name)
    this.useCustomSearch = false
  }

  public async retrieveCustomPolicy(clientId: string | null, path: string | null) {
    if (!clientId || !path) {
      this.$store.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar('Cannot retrieve Policy. ClientId or ResourcePath missing.')
      )
      return
    }
    const response = await PolicyHolderController.RetrieveCustomerPolicy(clientId, path)

    if (!response) {
      this.$store.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar('No policy returned from API.'))
      return
    }

    // Set Policy Response
    const { id, customer, customer2, policyDetail, schedule } = response

    const policyScheduleId = schedule ? Number(schedule.id) : null
    const convertedClientId = schedule ? Number(schedule.clientId) : null
    const schemeCode = (schedule && schedule.name) || ''

    if (customer) {
      if (customer.name) {
        this.claimModel.clientTitle = customer.name.title
        this.claimModel.clientForename = customer.name.forename
        this.claimModel.clientLastName = customer.name.surname
      }

      if (customer.address) {
        this.getAddressLine1 = customer.address.line1
        this.getAddressLine2 = customer.address.line2
        this.getAddressLine3 = customer.address.line3
        this.getAddressLine4 = customer.address.line4
        this.getPostCode = customer.address.postcode
        this.claimModel.postCode = customer.address.postcode
      }

      this.claimModel.mobilePhone = customer.mobilePhone
      this.setMobileContactDetails(customer.mobilePhone)
    }

    if (customer2 && customer2.name) {
      this.claimModel.secondaryClientTitle = customer2.name.title
      this.claimModel.secondaryClientForename = customer2.name.forename
      this.claimModel.secondaryClientLastName = customer2.name.surname
    }

    if (policyDetail) {
      this.claimModel.policyNumber = policyDetail.policyNumber.toString()
      this.claimModel.customerRef = policyDetail.customerReference ? policyDetail.customerReference.toString() : ''
      this.claimModel.insuranceValue = policyDetail.limit || 0
      this.effectiveDate = policyDetail.effectiveDate
    }

    this.claimModel.policyScheme = schemeCode
    this.claimModel.policyScheduleId = policyScheduleId || -1
    this.claimModel.dasPolicyHolderUNID = id

    // CustomerAddress component depends on this for some hacky validation
    for (const key in this.claimModel) {
      if (this.claimModel[key]) {
        this.customerPrefilledDetails[key] = true
      }
    }

    this.setSelectedPolicy(convertedClientId, policyScheduleId, schemeCode)

    if (this.isCustomPolicySearchFeatureFlagEnabled) {
      const jobPolicyDetails: JobPolicyDetails = new JobPolicyDetails()
      jobPolicyDetails.policyDetails = new PolicyDetails()

      jobPolicyDetails.clientId = convertedClientId
      jobPolicyDetails.policyDetails.policyNumber = policyDetail.policyNumber.toString()
      jobPolicyDetails.policyDetails.clientPolicyId = id
      jobPolicyDetails.policyDetails.effectiveDate = policyDetail.effectiveDate
      jobPolicyDetails.policyDetails.expirationDate = policyDetail.expirationDate

      this.$store.dispatch('policyModule/selectJobPolicyDetails', jobPolicyDetails)
    }

    this.policyUrl = schedule ? schedule.LogoUrl : ''

    this.claimModel.insurer = this.insurerMapping[schedule ? schedule.clientId : -1] || ''

    if (policyScheduleId && this.policySelected) {
      this.claimModel.policyScheduleId = policyScheduleId
    } else {
      this.manualPick = true
    }
    this.isAssumedCover = schedule ? schedule.isAssumedCover : false

    this.policyValidation.isCovered = !!policyDetail.isPolicyCovered || (!!schedule && !schedule.isAssumedCover)
    this.policyValidation.isPaid = policyDetail.isPolicyPaid

    if (this.$refs.forenameField) {
      ;(this.$refs.forenameField as any).focus()
    }
    this.useCustomSearch = false
  }

  private setSelectedPolicy(clientId: number | null, policyScheduleId: number | null, policyName: string | null) {
    let isPolicyExist = false
    if (navigator.onLine) {
      isPolicyExist = this.policyList.some((x) => x.id === policyScheduleId)
    } else {
      isPolicyExist = this.offlinePolicyList.some((x) => x.id === policyScheduleId)
    }

    if (!isPolicyExist && policyScheduleId && policyName) {
      this.selectedPolicy.policy = {
        id: policyScheduleId,
        name: policyName,
      }
    }

    try {
      const insurer = (this.offlinePolicies || []).find((i) => i.id === clientId)
      if (insurer && insurer.brands) {
        // finds the first brand which contains the selected policy
        const brand = insurer.brands.find((b) => !!b.policies.find((p) => p.id === policyScheduleId))
        if (brand && brand.policies) {
          const policy = brand.policies.find((p) => p.id === policyScheduleId)
          if (policy) {
            this.policyValidation.isCovered = !policy.isStarred

            this.selectedPolicy = {
              insurer: { id: insurer.id, name: insurer.name },
              brand: {
                id: brand.id,
                name: brand.subDomain,
                defaultPolicy: brand.fallBackStarPolicy,
              },
              policy: { id: policy.id, name: policy.name },
            }
          }
        }
      }
    } catch (error) {
      eventBus.$emit('validationErrorHandler', 'Error loading selected policy detail, please try again')
    }
  }

  public get policyValidationStatuses(): {
    value: boolean | null
    message: string
  }[] {
    return [
      {
        value: this.policyValidation.isCovered,
        message:
          'This is an assumed cover policy, you should create a job even if you cant find the customer in the validation database.',
      },
      {
        value: this.policyValidation.isPaid,
        message: 'Not Paid',
      },
      // We only want to filter by boolean and not null values
    ].filter((x) => x.value === false)
  }

  private setMobileContactDetails(mobilePhone: string | null) {
    if (mobilePhone) {
      this.customerContactPreferenceModal.mobilePhone = mobilePhone
      this.customerContactPreferenceModal.contactPreferenceId = this.contactPreference.SMS
    } else {
      this.customerContactPreferenceModal.contactPreferenceId = null
      this.customerContactPreferenceModal.mobilePhone = ''
    }
  }
}
</script>

<style>
.starred-outer {
  font-weight: 700;
}
</style>

<style scoped>
.v-dialog__content .v-dialog >>> .v-card,
.application .theme--light.v-stepper,
.theme--light .v-stepper {
  background: #fafafa;
}
.v-dialog >>> .v-stepper {
  background: inherit;
}
.v-dialog .v-stepper >>> .v-stepper__header {
  box-shadow: none;
  text-transform: uppercase;
}
.v-dialog .v-stepper >>> .v-stepper__content {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
}
.application .theme--light.v-stepper .v-stepper__step--editable:hover,
.theme--light .v-stepper .v-stepper__step--editable:hover {
  background: none;
}
.shadow-outer {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.enquiry-step3 {
  width: 240px;
}

.bold-select {
  font-weight: 600;
}
.link-switch >>> .v-input--selection-controls {
  padding: 0;
  margin-top: 4px;
}
.v-form > .container > .layout:only-child {
  margin: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-text-field__details {
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  font-size: 12px;
  min-height: 26px;
  overflow: hidden;
  position: relative;
  width: 100%;
  align-items: center;
}
.policy-logo {
  width: 118px;
  height: 100%;
  webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.policy-logo img {
  padding: 0px 15px;
  width: 100%;
  margin: auto;
  position: ABSOLUTE;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.emergency-info-table {
  width: 100%;
  padding-left: 15px;
}
.emergency-info-table .shadow-outer.emergency-table {
  height: 100%;
}

.policy-status {
  margin: 0 8px 20px 8px;
  border: 2px solid red;
}
</style>
