<template>
  <div>
    <div class="mb-2"><h4>Job Complete</h4></div>
    <div v-if="item.jobStatus" class="mb-1">
      <label>Status: </label>
      <span>
        {{ item.jobStatus === 'Cancelled' ? 'Cancelled' : 'Completed' }}
      </span>
    </div>
    <div v-if="item.totalPaidAmount" class="mb-1">
      <label>Total Paid Amount: </label>
      <span>{{ getFormatedCurrency(item.totalPaidAmount) }}</span>
    </div>
    <div v-if="item.processAtUtc" class="mb-1">
      <label>Process At: </label>
      <span>{{ getFormattedDate(item.processAtUtc) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddCloseJobRequestModel from '@/models/requests/AddCloseJobRequestModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class AddCloseJobRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddCloseJobRequestModel {
    return Object.assign(new AddCloseJobRequestModel(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>
