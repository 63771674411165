<template>
  <v-layout v-if="getOtherFeesDetail" wrap>
    <v-flex mt-1 xs12>
      <span>
        BER Amount:
        <b>{{ getFormatedCurrency(getOtherFeesDetail.berAmount) }}</b>
      </span>
    </v-flex>
    <v-flex mt-1 xs12>
      <span>
        Alternative Accommodation Amount:
        <b>
          {{ getFormatedCurrency(getOtherFeesDetail.alternativeAccommodationAmount) }}
        </b>
      </span>
    </v-flex>
    <v-flex mt-1 xs12>
      <span>
        Temporary Heaters Amount:
        <b>
          {{ getFormatedCurrency(getOtherFeesDetail.temporaryHeatersAmount) }}
        </b>
      </span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import OtherJobFeeDetailModel from '@/models/claim/OtherJobFeeDetailModel'
import Shared from '@/common/shared'
@Component
export default class OtherFeesDetailCard extends TimeLineItemBase {
  private get getOtherFeesDetail(): OtherJobFeeDetailModel | null {
    if (this.job) {
      const inviceDetail =
        this.job.clientInvoiceDetails && this.job.clientInvoiceDetails.length > 0
          ? this.job.clientInvoiceDetails.find((c) => c.id === this.itemId)
          : undefined
      return inviceDetail ? inviceDetail.otherJobFeeDetail : new OtherJobFeeDetailModel()
    }
    return null
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>
