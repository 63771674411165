<template>
  <div>
    <v-card class="mb-3 enablement">
      <v-card-title class="pb-0">
        <v-layout>
          <v-flex xs11>
            <h3>Enablement</h3>
          </v-flex>
          <v-flex v-if="syncing" xs1 text-xs-right>
            <v-icon v-if="syncing" class="processing-icon" medium>sync</v-icon>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-container class="pt-3">
        <v-switch
          v-model="localEnablement"
          color="primary"
          :disabled="isNotesDisabled || syncing || saving"
          label="Enablement Required?"
          @change="editComplete"
        ></v-switch>
        <v-textarea
          v-if="enablement"
          v-model="requirementNotes"
          v-validate="'required'"
          label="Please enter the requirement notes..."
          data-vv-name="Enablement Requirement Notes"
          :error-messages="errors.collect('Enablement Requirement Notes')"
          :disabled="isNotesDisabled || syncing || saving"
          class="requirementNotes"
          @focus="editStarted"
          @blur="onSave"
        ></v-textarea>
      </v-container>
      <v-progress-linear
        v-show="saving"
        color="primary"
        height="3"
        value="15"
        :indeterminate="true"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SiteInvestigationController from '@/api/siteInvestigationController'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import ChangeSIEnablementModel from '@/models/requests/ChangeSIEnablementModel'
import eventBus from '@/common/bus'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import Emergency from '@/models/Emergency'

@Component
export default class EnablementTriggerCard extends Vue {
  // getters
  public get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private get enablement(): boolean {
    return this.job ? this.job.enablementTriggeredAt !== null : false
  }

  public get emergency(): Emergency | null {
    if (this.emergencyId && this.job && this.job.emergencies) {
      const item = this.job.emergencies.find((e) => e.id === this.emergencyId)
      if (item) {
        return item
      }
    }
    return null
  }

  private get isNotesDisabled(): boolean {
    let result = false
    if (this.job && this.emergency && this.emergency.isEmergencyAccepted) {
      result = true
    }
    return result
  }

  // props
  @Prop() private jobId: string
  @Prop() private emergencyId: string

  // variables
  private inEdit = false
  private saving = false
  private syncing = false

  private requirementNotes = ''
  private localEnablement = false

  // methods
  public async validate() {
    return this.$validator.validateAll()
  }

  private created() {
    this.setLocalAnswer()
  }

  @Watch('syncing')
  private syncingChanged(): void {
    this.$emit('setSyncing', this.syncing)
  }

  @Watch('job.enablementTriggeredAt')
  @Watch('job.enablementNotes')
  private jobChanged(): void {
    if (!this.inEdit) {
      // user not editing (got new value so stop syncing)
      this.syncing = false
      if (
        this.localEnablement !== this.enablement ||
        (this.job && this.job.enablementNotes !== this.requirementNotes)
      ) {
        this.setLocalAnswer()
      }
    }
  }

  // set local answer as provided in job
  private async setLocalAnswer() {
    this.localEnablement = this.enablement
    this.requirementNotes = this.job ? this.job.enablementNotes : ''
    await this.$validator.reset()
  }

  // on answer edit started
  private editStarted(): void {
    this.inEdit = true
    this.syncing = false
  }

  // on blur save notes
  private async onSave() {
    // if no value give validation error
    const validate: boolean = await this.validate()
    if (!validate) {
      return
    }

    // if same as old value do not update
    if (this.job && this.requirementNotes === this.job.enablementNotes) {
      return
    }

    this.inEdit = false
    this.saving = true

    const req = new ChangeSIEnablementModel()
    req.jobId = this.jobId
    req.enablementRequired = this.localEnablement
    req.requirementNotes = this.requirementNotes
    // set local answer to request answer model
    this.SaveEnablement(req)
  }

  // on answer edit complete
  private async editComplete() {
    this.inEdit = false
    this.saving = true
    const req = new ChangeSIEnablementModel()
    req.jobId = this.jobId
    req.enablementRequired = this.localEnablement
    // set local answer to request answer model
    this.SaveEnablement(req)
  }

  // save Enablement Answer
  private SaveEnablement(model: ChangeSIEnablementModel) {
    if (this.isNotesDisabled) {
      this.saving = false
      return
    }

    SiteInvestigationController.Enablement(model)
      .then((res: boolean) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to update enablement!', true)
          this.syncing = false
          this.setLocalAnswer()
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        this.saving = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', err, false)
        this.saving = false
        this.setLocalAnswer()
      })
  }
}
</script>

<style scoped>
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.enablement >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
