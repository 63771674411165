<template>
  <div class="user">
    <div>
      <v-card-title primary-title class="pa-0 mb-2">
        <h3 class="mb-0">Call Queue List</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model.trim="search"
          append-icon="search"
          label="Search"
          class="mt-0 pt-0"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <div>
        <template v-if="callList && callList.length > 0">
          {{ startTimer() }}
        </template>
        <v-data-table
          :headers="headers"
          :items="callList"
          :loading="isLoading"
          :search="search"
          hide-actions
          :pagination.sync="pagination"
          class="gridView call-queue-list-table"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.fromNumber }}</td>
            <td>{{ props.item.policyName }}</td>
            <td>
              {{ props.item.callType === 'WorkInProgress' ? 'Work In Progress' : props.item.callType }}
            </td>
            <td class="text-xs-center">{{ props.item.position }}</td>
            <td class="text-xs-center">
              <div class="setpriorty-colum">
                <a
                  :class="showNumber && currentIndex === props.index ? 'hide' : 'show'"
                  @click="editPriority(props.index)"
                >
                  <b>{{ props.item.priority }}</b>
                </a>
                <span class="setpriority" :class="showNumber && currentIndex === props.index ? 'show' : 'hide'">
                  <span>
                    <v-text-field
                      id="testing"
                      v-model="props.item.priority"
                      name="input-1"
                      hide-details
                      :value="props.item.priority"
                      @blur="changePriority(props.index, props.item.priority)"
                    ></v-text-field>
                  </span>
                </span>
                <span class="btn-content">
                  <v-tooltip v-if="props.item.position != 1" top>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        color="secondary"
                        class="ma-0"
                        flat
                        small
                        v-on="on"
                        @click="changePriority(props.index, 999)"
                      >
                        <v-icon small>arrow_upward</v-icon>
                      </v-btn>
                    </template>
                    <span>Move top</span>
                  </v-tooltip>
                  <v-tooltip v-if="props.item.position != callList.length" top>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        color="secondary"
                        class="ma-0"
                        flat
                        small
                        v-on="on"
                        @click="changePriority(props.index, 1)"
                      >
                        <v-icon small>arrow_downward</v-icon>
                      </v-btn>
                    </template>
                    <span>Move bottom</span>
                  </v-tooltip>
                </span>
              </div>
            </td>
            <td class="text-xs-center">{{ timerCounter(props.item) }}</td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import UserModel from '@/models/user/UserModel'
import UserController from '@/api/userController'
import StarRating from 'vue-star-rating'
import QueueDashboardModel from '@/models/twilio/QueueDashboardModel'
import DashboardController from '@/api/dashboardController'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import Shared from '../common/shared'
import { Moment } from 'moment'

@Component({
  components: { StarRating },
})
export default class QueueDashboard extends Vue {
  private headers: any = []
  private isLoading = false
  private search = ''
  private pagination: any = {}
  private showNumber = false
  private currentIndex = -1
  private callList: QueueDashboardModel[] = []
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('queueDashboard')

  // Timer
  private isRunning = false
  private timerMinutes = 0
  private timerSecondes = 1
  private timeCounter = 0
  private timer: any = null

  private async created() {
    this.isLoading = true
    this.headers = [
      { text: 'ID', value: 'id' },
      { text: 'Number From', value: 'fromNumber' },
      { text: 'Policy Name', value: 'policyName' },
      { text: 'Type', value: 'callType' },
      { text: 'Position in Queue', value: 'position', align: 'center' },
      { text: 'Priority', value: 'priority', align: 'center' },
      { text: 'Waiting time', value: 'dateCreated', align: 'center' },
    ]
    this.pagination.rowsPerPage = -1 // rowsPerPage = -1 will show all records in data grid
    this.pagination.sortBy = 'position'
    await DashboardController.GetQueueDashboardData().then((res: QueueDashboardModel[]) => {
      this.callList = res
      this.sortCallList()
      this.isLoading = false
    })

    this.signalRHub.addHandler('addCallInQueue', (newCall: QueueDashboardModel) => {
      this.callList.push(newCall)
      this.sortCallList()
    })

    this.signalRHub.addHandler('removeCallFromQueue', (callId: string) => {
      const index: number = this.callList.findIndex((c) => c.id === callId)
      if (index !== -1) {
        this.callList.splice(index, 1)
        this.sortCallList()
      }
    })

    this.signalRHub.addHandler('updateCallPriority', (callId: string, newPriority: number) => {
      const call = this.callList.find((c) => c.id === callId)
      if (call) {
        call.priority = newPriority
        this.sortCallList()
      }
    })

    this.signalRHub.connect()
    this.timeCounter = this.timerMinutes * 60 + this.timerSecondes
  }

  private destroyed() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }

  private sortCallList() {
    if (this.callList) {
      this.callList.sort((a, b) => {
        if (a.priority < b.priority || (a.priority === b.priority && a.dateCreated > b.dateCreated)) {
          return 1
        } else if (a.dateCreated > b.dateCreated) {
          return 0
        }
        return -1
      })

      for (let i = 0; i < this.callList.length; i++) {
        this.callList[i].position = i + 1
      }
    }
  }

  private editPriority(index: any) {
    if (this.currentIndex === index) {
      this.showNumber = !this.showNumber
    } else {
      this.currentIndex = index
      if (!this.showNumber) {
        this.showNumber = true
      }
    }
  }

  private async changePriority(index: number, newPriority: number) {
    if (!this.callList[index]) {
      return
    }
    this.showNumber = false
    await DashboardController.UpdateCallPriority(this.callList[index].id, Number(newPriority))
  }

  private timerCounter(item: QueueDashboardModel) {
    if (this.timeCounter === 1 && item.dateCreated) {
      const currentDateTime = new Date()
      const requestAddedAtTime = new Date(item.dateCreated.toString())
      const diff = currentDateTime.getTime() - requestAddedAtTime.getTime()
      item.diffInSecond = Math.round(diff / 1000)
    }
    return Shared.formatDuration(this.timeCounter + item.diffInSecond)
  }

  private startTimer() {
    this.timeCounter = 1
    this.isRunning = true
    if (!this.timer) {
      this.timer = setInterval(() => {
        if (this.timeCounter > 0) {
          this.timeCounter++
        } else {
          clearInterval(this.timer)
          this.resetTimer()
        }
      }, 1000)
    }
  }

  private stopTimer() {
    this.isRunning = false
    clearInterval(this.timer)
    this.timer = null
  }

  private resetTimer() {
    this.stopTimer()
    this.timeCounter = 0
    this.timerSecondes = 0
    this.timerMinutes = 0
  }
}
</script>

<style scoped>
.vue-star-rating >>> .vue-star-rating-rating-text {
  margin-top: 1px;
}
.setpriority {
  max-width: 150px;
}
.setpriority > span:nth-child(2) {
  display: inline-block;
  width: 70px;
  text-align: center;
}
.setpriority > span:not(:nth-child(2)) {
  display: inline-block;
  width: 40px;
}
.setpriority > span >>> .v-input input {
  text-align: center;
}
.setpriorty-colum .v-btn-content {
  display: none;
  position: ABSOLUTE;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.setpriorty-colum:hover .v-btn-content {
  display: inline-block;
}
.setpriorty-colum {
  position: relative;
}
.setpriorty-colum >>> .v-input {
  padding-top: 0px;
}
.user >>> .v-table thead > tr > th:last-child {
  width: 200px;
}
.setpriorty-colum > a {
  padding: 5px 5px;
}
.setpriority >>> input {
  font-size: 14px;
}
</style>
