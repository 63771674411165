<template>
  <div>
    <v-card v-for="docType in testingProgress.requiredTestingDocuments" :key="docType" class="mb-4">
      <v-card-title>
        <h3>{{ titleForType(docType) }}</h3>
        <v-spacer />
        <v-icon v-if="missingDocument(docType)" medium dark color="orange">info</v-icon>
        <v-icon v-else medium dark color="success">check_circle</v-icon>
      </v-card-title>
    </v-card>
    <DocumentList
      :job-id="jobId"
      :job-type="job.jobType"
      :excluded-document-types="excludeNonTestingDocumentTypes"
    ></DocumentList>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import DocumentList from '@/components/DocumentList.vue'
import { isNumber } from 'util'
import { SIProductType, UploadedDocumentTypeEnum, TestingDocumentTypeEnum } from '@/common/enums'
import Pit from '@/models/siteInvestigation/Pit'
import TestingProgressDetail from '@/models/siteInvestigation/TestingProgressDetail'
import storeGetters from '@/storeGetters'
import Product from '@/models/siteInvestigation/Product'

@Component({
  components: { DocumentList },
})
export default class TestingPreview extends TimeLineItemBase {
  private get excludeNonTestingDocumentTypes() {
    const allDocs = Object.keys(UploadedDocumentTypeEnum)
      .map((t) => parseInt(t, 10))
      .filter((t) => !isNaN(t))
    const testDocs = Object.keys(TestingDocumentTypeEnum)
      .map((t) => parseInt(t, 10))
      .filter((t) => !isNaN(t))

    return allDocs.filter((t) => !testDocs.includes(t))
  }

  private missingDocument(type: UploadedDocumentTypeEnum): boolean {
    if (!this.testingProgress) {
      return false
    }
    return this.testingProgress && this.testingProgress.missingTestingDocuments
      ? this.testingProgress.missingTestingDocuments.includes(type)
      : true
  }

  private titleForType(type: UploadedDocumentTypeEnum): string {
    switch (type) {
      case UploadedDocumentTypeEnum.LabSummary:
        return 'Lab Summary'
      case UploadedDocumentTypeEnum.TreeRoots:
        return 'Tree Roots'
      default:
        return ''
    }
  }

  private get testingProgress(): TestingProgressDetail | null {
    return storeGetters.getTestingProgress(this.jobId)
  }
}
</script>
