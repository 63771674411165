<template>
  <div v-if="item">
    <div class="mb-2"><h4>Update Trial Pit Details</h4></div>
    <div v-for="(pitTestItem, index) in item.pitTests" :key="index">
      <div v-if="pitTestItem.name" class="mb-1">
        <label>Test Name: </label>
        <span>{{ pitTestItem.name }}</span>
      </div>
      <div v-if="pitTestItem.quantity" class="mb-1">
        <label>Quantity: </label>
        <span>{{ pitTestItem.quantity }}</span>
      </div>
      <div v-if="pitTestItem.notes" class="mb-1">
        <label>Test Notes: </label>
        <span>{{ pitTestItem.notes }}</span>
      </div>
      <v-divider class="mb-1"></v-divider>
    </div>
    <div v-if="item.notes" class="mb-1">
      <label>Pit Notes: </label>
      <span>{{ item.notes }}</span>
    </div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ getStatusName(item.status) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateTrialPitDetail from '@/models/siteInvestigation/requests/UpdateTrialPitDetail'
import Shared from '../../common/shared'

@Component
export default class UpdateSITrialPitDetailsLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateTrialPitDetail {
    return Object.assign(new UpdateTrialPitDetail(), this.requestBody)
  }

  private getStatusName(status) {
    return Shared.insertSpaceInEnumName(status)
  }
}
</script>
