import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import OverrideInvoiceCostRequestModel from '@/models/requests/OverrideInvoiceCostRequestModel'
import { JobType } from '@/common/enums'
import ExportToBordereau from '@/models/requests/bulkRequests/ExportToBordereau'
import BordereauModel from '@/models/client/BordereauModel'
import { BordereauJobRow } from '@/models/claim/Bordereau'
import ExportToInvoice from '@/models/requests/ExportToInvoice'
import Invoice from '@/models/client/Invoice'

export default class InvoiceController {
  public static async GetInvoiceReceiptURL(chargeId: string): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get<string>('Invoice/GetInvoiceReceiptURL?chargeId=' + chargeId)
    return res.data
  }

  public static async OverrideClientInvoiceCost(
    overrideInvoiceCostRequest: OverrideInvoiceCostRequestModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<OverrideInvoiceCostRequestModel>(
      'Invoice/OverrideClientInvoiceCost',
      overrideInvoiceCostRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async OverrideContractorInvoiceCost(
    overrideInvoiceCostRequest: OverrideInvoiceCostRequestModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<OverrideInvoiceCostRequestModel>(
      'Invoice/OverrideContractorInvoiceCost',
      overrideInvoiceCostRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async GenerateBordereauReference(jobType: JobType): Promise<string | null> {
    const res: AxiosResponse = await CallCentreApi.get<string>('Invoice/GenerateBordereauReference?jobType=' + jobType)
    const success = res.status >= 200 && res.status < 300
    return success ? res.data : null
  }

  public static async GenerateInvoiceReference(jobId: string, clientId: number) {
    const res: AxiosResponse = await CallCentreApi.get<string>(
      `Invoice/GenerateInvoiceReference?jobId=${jobId}&clientId=${clientId}`
    )
    const success = res.status >= 200 && res.status < 300
    return success ? res.data : null
  }

  public static async ConfirmBordereau(model: ExportToBordereau): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<ExportToBordereau>('Invoice/ConfirmBordereau', model)
    return res.status >= 200 && res.status < 300
  }

  public static async ConfirmInvoice(model: ExportToInvoice): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<ExportToInvoice>('Invoice/ConfirmInvoice', model)
    return res.status >= 200 && res.status < 300
  }

  public static async GetBordereaus(
    clientId: number,
    bordereauFrom: string,
    bordereauTo: string
  ): Promise<BordereauModel[]> {
    const res: AxiosResponse<BordereauModel[]> = await CallCentreApi.get<BordereauModel[]>(
      'Invoice/GetBordereaus?clientId=' + clientId + '&bordereauFrom=' + bordereauFrom + '&bordereauTo=' + bordereauTo
    )
    return res.status >= 200 && res.status < 300 ? res.data : []
  }

  public static async GetInvoices(clientId: number, invoiceFrom: string, invoiceTo: string): Promise<Invoice[]> {
    const res: AxiosResponse<Invoice[]> = await CallCentreApi.get<Invoice[]>(
      'Invoice/GetInvoices?clientId=' + clientId + '&invoiceFrom=' + invoiceFrom + '&invoiceTo=' + invoiceTo
    )    
    return res.status >= 200 && res.status < 300 ? res.data : []
  }

  public static async GetBordereauDetail(clientId: number, bordereauId: string): Promise<BordereauJobRow[]> {
    const res: AxiosResponse<BordereauJobRow[]> = await CallCentreApi.get<BordereauJobRow[]>(
      'Invoice/GetBordereauDetail?clientId=' + clientId + '&bordereauId=' + bordereauId
    )
    return res.status >= 200 && res.status < 300 ? res.data : []
  }

  public static async GetInvoicesByJob(clientId: number, jobId: string): Promise<Invoice[] | null> {
    const res: AxiosResponse<Invoice[]> = await CallCentreApi.get<Invoice[]>(
      'Invoice/GetInvoicesByJob?clientId=' + clientId + '&jobId=' + jobId
    )
    return res.status >= 200 && res.status < 300 ? res.data : []
  }
}
