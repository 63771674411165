var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-layout',[(_vm.getRequestData)?_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"mb-2"},[_c('v-layout',[_c('v-flex',{attrs:{"xs5":""}},[_c('label',[_vm._v("Contractor:")]),_c('span',[_vm._v(_vm._s(_vm.getRequestData.engineerRequests[0].contractor))]),_vm._v("   ")]),_c('v-flex',{attrs:{"xs5":""}},[_c('label',[_vm._v("Emergency Type:")]),_c('span',[_vm._v(_vm._s(_vm.emergencyTypeName))])])],1)],1),_c('div',{staticClass:"mb-2"},[_c('v-layout',[_c('v-flex',{attrs:{"xs5":""}},[_c('label',[_vm._v("Engineer Name:")]),_c('span',[_vm._v(_vm._s(_vm.getRequestData.engineerRequests[0].engineer))]),_vm._v("   "),_c('b',{staticClass:"primary--text phone-number"},[_vm._v(" ( "),_c('a',{attrs:{"href":"Javascript:void(0)"}},[_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v("call")]),_vm._v(" "+_vm._s(_vm.getRequestData.engineerRequests[0].engineerPhoneNumber)+" ")],1),_vm._v(" ) ")])]),_c('v-flex',{attrs:{"xs5":""}},[_c('label',[_vm._v("Emergency Details:")]),_c('span',[_vm._v(_vm._s(_vm.emergencyDetailName))])])],1)],1),_c('div',[_c('v-layout',[_c('v-flex',{attrs:{"xs5":""}},[_c('label',[_vm._v("Customer Name:")]),_c('span',[_vm._v(_vm._s(_vm.getRequestData.engineerRequests[0].customer))])]),_c('v-spacer'),_c('v-flex',{attrs:{"xs4":"","text-xs-right":""}},[_c('div',{staticClass:"action-engineer"},[_c('div',[(
                    _vm.isUserSupervisor &&
                    _vm.getRequestData.assignedStatus === _vm.iMRequestProcessStatus[_vm.iMRequestProcessStatus.Assigned]
                  )?_c('span',{staticClass:"primary--text"},[_vm._v(" Assigned To: "+_vm._s(_vm.getAssignedUserName(_vm.getRequestData.assignedUserId))+" ")]):_vm._e()]),(_vm.isUserSupervisor)?_c('div',{staticClass:"supervisor-assign text-xs-right"},[(_vm.getRequestData.assignedStatus === _vm.iMRequestProcessStatus[_vm.iMRequestProcessStatus.Pending])?_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary","disabled":_vm.activeIndex === _vm.index,"small":""},on:{"click":function($event){return _vm.openAssignJobDialog(_vm.getRequestData, _vm.index)}}},[_vm._v(" Assign ")]):_vm._e(),(_vm.getRequestData.assignedStatus === _vm.iMRequestProcessStatus[_vm.iMRequestProcessStatus.Assigned])?_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary","disabled":_vm.activeIndex === _vm.index,"small":""},on:{"click":function($event){return _vm.openAssignJobDialog(_vm.getRequestData, _vm.index)}}},[_vm._v(" Reassign ")]):_vm._e(),(_vm.getRequestData.assignedStatus === _vm.iMRequestProcessStatus[_vm.iMRequestProcessStatus.Assigned])?_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.openCompleteJobDialog(_vm.getRequestData)}}},[_vm._v(" Complete ")]):_vm._e()],1):_c('div',[(_vm.getRequestData.assignedStatus === _vm.iMRequestProcessStatus[_vm.iMRequestProcessStatus.Pending])?_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary","disabled":_vm.isLoadingLocal && _vm.activeIndex === _vm.index,"loading":_vm.isLoadingLocal && _vm.activeIndex === _vm.index,"small":""},on:{"click":function($event){return _vm.assignJob(_vm.getRequestData, _vm.index)}}},[_vm._v(" Assign To Me ")]):(
                    _vm.getRequestData.assignedStatus === _vm.iMRequestProcessStatus[_vm.iMRequestProcessStatus.Assigned] &&
                    _vm.isAssignedUserLoggedIn(_vm.getRequestData.assignedUserId)
                  )?_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.openCompleteJobDialog(_vm.getRequestData)}}},[_vm._v(" Complete ")]):_c('span',{staticClass:"primary--text"},[_vm._v(" Assigned To: "+_vm._s(_vm.getAssignedUserName(_vm.getRequestData.assignedUserId))+" ")])],1)])])],1)],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }