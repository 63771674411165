import CosmosAdminDbRecordBase from '@/models/CosmosAdminDbRecordBase'
export default class MaterialModel extends CosmosAdminDbRecordBase {
  public name: string
  public isAdditionalInfoRequired: boolean
  public isExcludedFromClientInvoicing: boolean
  public excludeFromCustomerApprovalUpdates: boolean
  public isCostExcludedFromContractorFixedFee: boolean

  public constructor() {
    super()
    this.name = ''
  }
}
