<template>
  <div>
    <div class="mb-2"><h4>Engineer Job Visit Status</h4></div>
    <div v-if="getEngineerVisitStatus" class="mb-1">
      <label>Status: </label>
      <span>{{ getEngineerVisitStatus }}</span>
    </div>
    <div v-if="item.status === engineerJobVisitStatus[engineerJobVisitStatus.PolicyHolderAbsent]" class="mb-1">
      <label>Customer Contacted?: </label>
      <span>{{ item.isCustomerContacted ? 'Yes' : 'No' }}</span>
    </div>
    <div v-if="getFormattedDate" class="mb-1">
      <label>Process at: </label>
      <span>{{ getFormattedDate(item.processAt) }}</span>
    </div>
    <div v-if="item.reason" class="mb-1">
      <label>Abandoned Reason: </label>
      <span>{{ item.reason }}</span>
    </div>
    <div v-if="item.notes" class="mb-1">
      <label>Abandoned Notes: </label>
      <span>
        <pre>{{ item.notes }}</pre>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateEngineerJobVisitStatus from '@/models/requests/UpdateEngineerJobVisitStatus'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import { EngineerJobVisitStatus } from '@/common/enums'

@Component
export default class UpdateEngineerJobVisitStatusLog extends Vue {
  @Prop() private requestBody: any
  private engineerJobVisitStatus = EngineerJobVisitStatus

  private get item(): UpdateEngineerJobVisitStatus {
    return Object.assign(new UpdateEngineerJobVisitStatus(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }

  private get getEngineerVisitStatus() {
    if (this.item.status === EngineerJobVisitStatus[EngineerJobVisitStatus.OnTheWay]) {
      return 'On The Way'
    } else if (this.item.status === EngineerJobVisitStatus[EngineerJobVisitStatus.RunningLate]) {
      return 'Running Late'
    } else if (this.item.status === EngineerJobVisitStatus[EngineerJobVisitStatus.OnSite]) {
      return 'On Site'
    } else if (this.item.status === EngineerJobVisitStatus[EngineerJobVisitStatus.PolicyHolderAbsent]) {
      return 'Customer Not Present'
    } else if (this.item.status === EngineerJobVisitStatus[EngineerJobVisitStatus.JobStarted]) {
      return 'Job Started'
    } else {
      return this.item.status
    }
  }
}
</script>
