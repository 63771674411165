<template>
  <div>
    <div class="mb-2"><h4>Assign Engineer Request</h4></div>
    <div class="mb-1">
      <label>Assigned to: </label>
      <span>{{ userName }}</span>
    </div>
    <div v-if="requestCategory" class="mb-1">
      <label>Request category: </label>
      <span>{{ requestCategory }}</span>
    </div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AssignEngineerRequestModel from '@/models/requests/AssignEngineerRequestModel'
import storeGetters from '@/storeGetters'
import UserModel from '@/models/user/UserModel'
import { EngineerRequestType } from '@/common/enums'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class AssignEngineerRequestLog extends Vue {
  @Prop() private requestBody: any

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get users(): UserModel[] {
    return storeGetters.getUsers()
  }

  private get userName(): string {
    const user: UserModel | undefined = this.users.find((u: UserModel) => u.id === this.item.userId)
    return user ? user.displayValue : '-- (UnAssigned)'
  }

  private get item(): AssignEngineerRequestModel {
    return Object.assign(new AssignEngineerRequestModel(), this.requestBody)
  }

  private get requestCategory(): string {
    const engineerRequest = this.requestedJob
      ? this.requestedJob.engineerRequests.find((x) => x.id === this.item.documentId)
      : undefined
    if (engineerRequest && engineerRequest.requestCategory) {
      switch (engineerRequest.requestCategory) {
        case EngineerRequestType[EngineerRequestType.NewJob]:
          return 'New Job'
        case EngineerRequestType[EngineerRequestType.PolicyHolderNotPresent]:
          return 'Customer Not Present'
        case EngineerRequestType[EngineerRequestType.RejectVisit]:
          return 'Reject Visit'
        case EngineerRequestType[EngineerRequestType.AbandonedVisit]:
          return 'Abandoned Visit'
        case EngineerRequestType[EngineerRequestType.Material]:
          return engineerRequest.requestCategory
        case EngineerRequestType[EngineerRequestType.Time]:
          return engineerRequest.requestCategory
        case EngineerRequestType[EngineerRequestType.ReturnVisit]:
          return 'Return Visit'
        case EngineerRequestType[EngineerRequestType.VisitComplete]:
          return 'Visit Complete'
      }
      return ''
    }
    return ''
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies()
  }

  private get emergencyName(): string {
    if (this.requestedJob) {
      const engineerRequest = this.requestedJob.engineerRequests.find((x) => x.id === this.item.documentId)
      if (engineerRequest) {
        const emergency = this.emergencyTypes.find((e) => e.emergencyId === engineerRequest.forEmergencyTypeId)
        return emergency ? emergency.description : ''
      }
    }
    return ''
  }
}
</script>
