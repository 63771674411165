import CosmosJobsDbRecordBase from '../CosmosJobsDbRecordBase'
import RepairEstimateProductModel from './RepairEstimateProductModel'

export default class RepairEstimateModel extends CosmosJobsDbRecordBase {
  public products: RepairEstimateProductModel[]
  public estimateName: string

  public constructor() {
    super()
    if (!this.products) {
      this.products = []
    }
  }
}
