<template>
  <div v-if="item">
    <div class="mb-2"><h4>Update Datum Details</h4></div>
    <div v-if="datumName" class="mb-1">
      <label>Name: </label>
      <span>{{ datumName }}</span>
    </div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ item.status }}</span>
    </div>
    <template v-if="item.datumControlRunLogs.length > 0">
      <v-divider class="mb-1"></v-divider>
      <div v-for="(datumControlRunItem, index) in item.datumControlRunLogs" :key="index">
        <div v-if="datumControlRunItem.pitId" class="mb-1">
          <label>Pit Id: </label>
          <span>{{ datumControlRunItem.pitId }}</span>
        </div>
        <div v-if="datumControlRunItem.note" class="mb-1">
          <label>Notes: </label>
          <span>{{ datumControlRunItem.note }}</span>
        </div>
        <v-divider v-if="index !== item.datumControlRunLogs.length - 1" class="mb-1"></v-divider>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import DatumControlLog from '@/models/siteInvestigation/DatumControlLog'

@Component
export default class UpdateDatumDetailsLog extends Vue {
  @Prop() private requestBody: any

  private get item(): DatumControlLog {
    return Object.assign(new DatumControlLog(), this.requestBody)
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.item.jobId)
  }

  private get datumName() {
    const isValid = this.job && this.job.sIJobDetail && this.job.sIJobDetail.jobPackage
    const datumControl = isValid
      ? this.job!.sIJobDetail!.jobPackage.extraProducts.find((e) => e.id === this.item.datumId)
      : undefined
    return datumControl ? datumControl.name : ''
  }
}
</script>
