<template>
  <div>
    <div class="mb-2"><h4>Update Client Invoice Detail</h4></div>
    <div v-if="insurerName" class="mb-1">
      <label>Insurer: </label>
      <span>{{ insurerName }}</span>
    </div>
    <div v-if="getClientInvoiceStatus" class="mb-1">
      <label>Status: </label>
      <span>{{ getClientInvoiceStatus }}</span>
    </div>
    <div v-if="item.disputedReason" class="mb-1">
      <label>Dispute Reason: </label>
      <span>{{ item.disputedReason }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateClientInvoiceDetailStatusModel from '@/models/requests/UpdateClientInvoiceDetailStatusModel'
import storeGetters from '@/storeGetters'
import { ClientInvoiceStatus } from '@/common/enums'
import InsurerModel from '@/models/policy/InsurerModel'
import storeMutations from '@/storeMutations'
import InsurerController from '@/api/InsurerController'
import eventBus from '@/common/bus'

@Component
export default class UpdateClientInvoiceDetailLog extends Vue {
  @Prop() private requestBody: any

  private insurerList: InsurerModel[] = []

  private created() {
    if (this.getInsurers.length === 0) {
      this.setInsuerList()
    } else {
      this.insurerList = this.getInsurers
    }
  }

  private get item(): UpdateClientInvoiceDetailStatusModel {
    return Object.assign(new UpdateClientInvoiceDetailStatusModel(), this.requestBody)
  }

  private get getInsurers(): InsurerModel[] {
    return storeGetters.getInsurerList()
  }

  private setInsuerList() {
    InsurerController.GetInsurerList()
      .then((res: InsurerModel[]) => {
        if (res) {
          storeMutations.setInsurerList(res)
          this.insurerList = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading insurer list, please try again', true)
      })
  }

  private get getClientInvoiceStatus() {
    if (this.item.status === ClientInvoiceStatus[ClientInvoiceStatus.ReadyForApproval]) {
      return 'Ready For Approval'
    } else if (this.item.status === ClientInvoiceStatus[ClientInvoiceStatus.ToReview]) {
      return 'To Review'
    } else {
      return this.item.status
    }
  }

  private get insurerName(): string {
    const insurer = this.insurerList.find((e) => e.id === this.item.insurerId)
    return insurer ? insurer.name : ''
  }
}
</script>
