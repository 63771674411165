<template>
  <div class="dashboard">
    <v-container fluid class="pa-0" :class="openJobView ? 'hide-dashboard' : 'show-dashboard'">
      <div class="filter">
        <v-layout wrap>
          <v-spacer></v-spacer>
          <v-flex class="text-xs-right px-2">
            <v-autocomplete
              v-model="selectedETAFilterType"
              :items="getETAFilterTypes"
              item-text="description"
              item-value="id"
              label="ETA Type"
              :disabled="isFilterDisabled"
            ></v-autocomplete>
          </v-flex>
          <v-flex class="text-xs-right px-2">
            <v-autocomplete
              v-model="selectedJobEscalationType"
              :items="getJobEscalationTypes"
              item-text="description"
              item-value="id"
              label="Escalation Type"
              name="searchByEscalationType"
              :disabled="isFilterDisabled"
              class="escalation-type"
            ></v-autocomplete>
          </v-flex>
          <v-flex class="text-xs-right px-2">
            <v-select
              v-model="selectedEscalationReason"
              :items="escalationReasonList"
              item-text="description"
              item-value="description"
              label="Escalation Reason"
              name="searchByEscalationReason"
              :loading="getEscalationReasonLoading"
              class="escalation-reason"
              :disabled="isFilterDisabled || selectedJobEscalationType !== escalationTypeEnum.Escalated"
            ></v-select>
          </v-flex>
          <v-flex class="text-xs-right px-2">
            <v-autocomplete
              v-model="selectedTeamName"
              :items="userTeam"
              item-text="teamName"
              item-value="id"
              label="Search team"
              name="searchByTeam"
              :loading="getTeamRunning"
              :disabled="isFilterDisabled"
            ></v-autocomplete>
          </v-flex>
          <v-flex class="text-xs-right px-2">
            <v-autocomplete
              v-model="selectedContractor"
              :items="contractors"
              item-text="companyName"
              item-value="id"
              label="Search contractor"
              name="searchByContractor"
              :loading="getContractorRunning"
              :disabled="isFilterDisabled"
            ></v-autocomplete>
          </v-flex>
          <v-flex class="text-xs-right px-2">
            <v-text-field
              v-model="searchValue"
              append-icon="search"
              label="Search by jobid"
              single-line
              hide-details
              name="searchByJobId"
              :disabled="isFilterDisabled"
              @keypress="jobIdValidation($event)"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap calls-info undeployed-jobcount mb-3>
          <v-flex xs12>
            <v-card class="elevation-0 pa-2 grey lighten-3 white--text">
              <v-card-title class="pa-0 grey--text grey--text text--darken-2">
                <b>Outstanding Trade Jobs</b>
              </v-card-title>
              <v-card-text v-if="jobsTradeList.length > 0" class="pa-1">
                <div>
                  <span v-for="(jobTrade, i) in jobsTradeList" :key="i">
                    <v-chip
                      v-if="jobTrade.tradeId === 0"
                      label
                      class="grey grey--text text--darken-4"
                      :class="currentIndex === -1 ? 'lighten-1' : 'lighten-2'"
                      @click="getTradeWiseJobs('All', 0, '')"
                    >
                      All &nbsp;
                      <b>{{ jobTrade.tradeCount }}</b>
                    </v-chip>
                    <v-chip
                      v-else
                      label
                      class="grey grey--text text--darken-2"
                      :class="currentIndex === i ? 'lighten-1' : 'lighten-2'"
                      @click="getTradeWiseJobs('', jobTrade.tradeId, i)"
                    >
                      {{ getTradeName(jobTrade.tradeId) }} &nbsp;
                      <b>{{ jobTrade.tradeCount }}</b>
                    </v-chip>
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
      <v-flex xs12 mt-2 class="elevation-1">
        <v-data-table
          :headers="headers"
          :items="missedJobItems"
          :total-items="missedJobItemsCount"
          :loading="isLoading"
          class="gridView"
          :class="isFilterDisabled ? 'disable-missedjob-table-actions' : ''"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPageItems"
        >
          <template slot="items" slot-scope="props">
            <tr :active="props.selected" @click="props.selected = !props.selected"></tr>
            <tr>
              <td>
                <a
                  href="javascript:void(0)"
                  class="primary--text"
                  name="openJobDetailButton"
                  @click.prevent="redirectToJob(props.item.jobId)"
                >
                  <b>{{ props.item.jobId }}</b>
                </a>
              </td>
              <td>{{ props.item.address }}</td>
              <td>
                {{ props.item.companyName ? props.item.companyName : '-' }}
              </td>
              <td>
                {{ props.item.engineerName ? props.item.engineerName : '-' }}
              </td>
              <td>
                {{ getFormattedDateTime(props.item.etaFrom) + ' To ' + getFormattedDateTime(props.item.etaTo) }}
              </td>
              <td>{{ props.item.status }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
    <PartialJobView
      ref="refPartialJobView"
      :job-id="selectedJobIdToExpand"
      @closeJobView="closeJobView"
    ></PartialJobView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Store from '@/store'
import Shared from '@/common/shared'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import { EscalationTypeEnum, ETAFilterForMissedJob, RecordType } from '@/common/enums'
import TradeModel from '@/models/policyHolder/TradeModel'
import ContractorModel from '@/models/contractor/ContractorModel'
import MissedJobDataModel from '@/models/claim/MissedJobDataModel'
import MissedJobDetailModel from '@/models/claim/MissedJobDetailModel'
import MissedJobDetailDataModel from '@/models/claim/MissedJobDetailDataModel'
import MissedJobSearchAndFilterModel from '@/models/claim/MissedJobSearchAndFilterModel'
import UserController from '@/api/userController'
import DashboardController from '@/api/dashboardController'
import ContractorController from '@/api/contractorController'
import PolicyHolderController from '@/api/policyHolderController'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import PartialJobView from '@/components/PartialJobView.vue'
import MasterRecord from '../models/MasterRecord'
import MasterRecordController from '../api/masterRecordController'
import eventBus from '@/common/bus'

interface IDropdownType {
  id: number
  description: string
}

@Component({
  name: 'MissedJobsDashboard',
  components: { PartialJobView },
})
export default class MissedJobsDashboard extends Vue {
  private isLoading = false
  private headers: any[]
  private pagination: any = {}
  private rowsPerPageItems: number[] = [5, 10, 25]
  private rowsPerPage = 0
  private pageNumber = 0
  private sortByField = ''
  private sortOrder = ''
  private userTeam: string[] = []
  private getTeamRunning = false
  private selectedTeamName = ''
  private selectedContractor = ''
  private contractors: ContractorModel[] = []
  private selectedJobEscalationType: EscalationTypeEnum = EscalationTypeEnum.All
  private getContractorRunning = false
  private missedJobItems: MissedJobDetailModel[] = []
  private missedJobItemsCount = 0
  private jobsTradeList: any = []
  private trades: TradeModel[] = []
  private selectedTradeId = 0
  private searchValue = ''
  private waitForMoreInputTimeoutHandle: number | null = null
  private currentIndex = -1
  private dataFetchingInProcess = false
  private engineerName = ''
  private snackbar = false
  private snackbarTimeout = 6000
  private snackbarText = ''
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('missedJobDashboardHub')
  // open job view
  private openJobView = false
  private selectedJobIdToExpand = ''
  // escalation reason
  private getEscalationReasonLoading = false
  private selectedEscalationReason = ''
  private escalationReasonList: MasterRecord[] = []
  private escalationTypeEnum = EscalationTypeEnum
  private selectedETAFilterType: ETAFilterForMissedJob = ETAFilterForMissedJob.AllOpenJobs

  private created() {
    this.headers = [
      { text: 'Job Id', value: 'jobId', align: 'left' },
      { text: 'Address', value: 'address', align: 'left' },
      { text: 'Contractor Name', value: 'companyName', align: 'left' },
      { text: 'Engineer Name', value: 'engineerName', align: 'left' },
      { text: 'ETA', value: 'etaFrom', align: 'left' },
      { text: 'Status', value: 'status', align: 'left' },
    ]
    this.pagination.page = 1
    this.pagination.rowsPerPage = Shared.rowsPerPageDefault
    this.pagination.sortBy = ''
    this.pagination.descending = ''
    // get trade list
    this.getTradeList()
    // get team name list
    this.getTeamName()
    // get all contractors
    this.getAllContractors()
    // get missed jobs list
    this.dataFetchingInProcess = true
    this.GetMissedJobsTableData()
    // Get list of escalation reason.
    this.getEscalationReasonList()
    // bind signalr hub
    this.bindSignalRHub()
  }

  private destroyed() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }

  private get getJobEscalationTypes(): IDropdownType[] {
    const jobEscalationTypeItems = [
      { id: 0, description: 'All' },
      { id: 1, description: 'Escalated' },
      { id: 2, description: 'Standard' },
    ]
    return jobEscalationTypeItems
  }

  private get getETAFilterTypes(): IDropdownType[] {
    const jobETAFilterTypeItems = [
      { id: 0, description: 'All Open Jobs' },
      { id: 1, description: 'Missed Jobs' },
    ]
    return jobETAFilterTypeItems
  }

  private getTeamName() {
    const self = this
    this.getTeamRunning = true
    UserController.GetUserTeams().then((res: string[]) => {
      if (res.length > 0) {
        self.userTeam = res
        // add extra label for all selected contractor
        const allTeamOption = 'All'
        const unKnownTeamOption = 'Unknown'
        self.userTeam.unshift(unKnownTeamOption)
        self.userTeam.unshift(allTeamOption)
        self.selectedTeamName = self.userTeam[0]
      }
      this.getTeamRunning = false
    })
  }

  private getAllContractors() {
    this.getContractorRunning = true
    ContractorController.GetAllContractors()
      .then((res: ContractorModel[]) => {
        if (res) {
          this.contractors = res
          if (res.length > 1) {
            // add label for customer appointed tradespeople records
            const addCATlabel: ContractorModel = new ContractorModel()
            addCATlabel.companyName = 'Customer Appointed Tradespeople'
            addCATlabel.id = 'CustomerAppointedTradespeople'
            this.contractors.unshift(addCATlabel)
            // add extra label for all selected contractor
            const addDummyLabel: ContractorModel = new ContractorModel()
            addDummyLabel.companyName = 'All'
            addDummyLabel.id = ''
            this.contractors.unshift(addDummyLabel)
            this.selectedContractor = ''
          }
        } else {
          this.contractors = []
        }
        this.getContractorRunning = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor list, please try again', true)
        this.getContractorRunning = false
      })
  }

  private getTradeList() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.trades = res.filter((e) => e.jobType === 'HE')
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.trades = trades.filter((e) => e.jobType === 'HE')
    }
  }

  private getEscalationReasonList(): void {
    this.getEscalationReasonLoading = true
    const recordType: string = RecordType[RecordType.JobEscalationReason]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res && res.length > 0) {
        this.escalationReasonList = res.filter((e) => e.isDeleted === false)
        // add extra label for all escalation reason
        const addDummyLabel: MasterRecord = new MasterRecord()
        addDummyLabel.description = 'All'
        addDummyLabel.id = ''
        this.escalationReasonList.unshift(addDummyLabel)
        this.selectedEscalationReason = this.escalationReasonList[0].description
      }
      this.getEscalationReasonLoading = false
    })
  }

  @Watch('selectedJobEscalationType')
  @Watch('selectedTeamName')
  @Watch('selectedContractor')
  @Watch('selectedEscalationReason')
  @Watch('selectedETAFilterType')
  private GetfilteredMissedJobsTableData() {
    if (!this.dataFetchingInProcess) {
      this.GetMissedJobsTableData()
    }
  }

  @Watch('searchValue')
  private searchValueChanged(newValue: string) {
    this.searchValue = newValue.toUpperCase()
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }
    const self = this
    this.waitForMoreInputTimeoutHandle = window.setTimeout(() => {
      this.pagination.page = 1
      this.pageNumber = 1
      this.GetMissedJobsTableData()
    }, 1500)
  }

  private GetMissedJobsTableData() {
    const self = this
    self.isLoading = true
    DashboardController.GetMissedJobDashboardData(this.getFilteredModel())
      .then((res: MissedJobDataModel | null) => {
        if (res) {
          self.missedJobItems = res.missedJobDetails
          self.missedJobItemsCount = res.totalRecordCount
          self.jobsTradeList = res.tradeFilters
          self.resetTradeSelection()
        }
        self.resetAllLoaders()
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading missed job(s), please try again', false)
        this.snackbarText = 'Error in loading jobs!'
        this.snackbar = true
        self.resetAllLoaders()
      })
  }

  private resetTradeSelection() {
    this.currentIndex = -1
    this.selectedTradeId = 0
  }

  @Watch('pagination')
  private paginationChange() {
    if (this.missedJobItems.length === 1) {
      // restrict api call when only one record in the list
      return
    }

    if (this.isFilterDisabled) {
      this.snackbarText = 'Jobs loading in progress!'
      this.snackbar = true
      return
    }

    this.rowsPerPage = this.pagination.rowsPerPage
    this.pageNumber = this.pagination.page
    this.sortByField = this.pagination.sortBy === null ? '' : this.pagination.sortBy
    if (this.pagination.descending && this.sortByField) {
      this.sortOrder = 'desc'
    } else if (!this.pagination.descending && this.sortByField) {
      this.sortOrder = 'asc'
    } else {
      this.sortOrder = ''
    }
    if (!this.dataFetchingInProcess) {
      this.GetMissedJobTradeFilterData()
    }
  }

  private getTradeWiseJobs(type: string, tradeId: number, index: number) {
    if (this.isFilterDisabled) {
      this.snackbarText = 'Jobs loading in progress!'
      this.snackbar = true
      return
    }
    this.currentIndex = type === 'All' ? -1 : index
    this.selectedTradeId = tradeId
    this.GetMissedJobTradeFilterData()
  }

  private GetMissedJobTradeFilterData() {
    // this api will be called when user filtered via trade, sorting and pagination.
    const self = this
    self.isLoading = true
    DashboardController.GetMissedJobTradeFilterData(self.getFilteredModel())
      .then((res: MissedJobDetailDataModel | null) => {
        if (res) {
          self.missedJobItems = res.missedJobDetails
          self.missedJobItemsCount = res.totalRecordCount
        }
        self.resetAllLoaders()
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading jobs, please try again', false)
        self.snackbarText = 'Error in loading jobs!'
        self.snackbar = true
        self.resetAllLoaders()
      })
  }

  private getFilteredModel(): MissedJobSearchAndFilterModel {
    const self = this
    const missedJobSearchAndFilterModel: MissedJobSearchAndFilterModel = new MissedJobSearchAndFilterModel()
    missedJobSearchAndFilterModel.pageNumber = self.pageNumber
    missedJobSearchAndFilterModel.pageSize = self.rowsPerPage
    missedJobSearchAndFilterModel.search = self.searchValue
    missedJobSearchAndFilterModel.sortBy = self.sortByField
    missedJobSearchAndFilterModel.sortOrder = self.sortOrder
    missedJobSearchAndFilterModel.contractorId = self.selectedContractor
    missedJobSearchAndFilterModel.teamName = self.selectedTeamName.toLowerCase() === 'all' ? '' : self.selectedTeamName
    missedJobSearchAndFilterModel.tradeId = self.selectedTradeId
    missedJobSearchAndFilterModel.escalationType = self.selectedJobEscalationType
    missedJobSearchAndFilterModel.escalationReason =
      self.selectedJobEscalationType === EscalationTypeEnum.Escalated
        ? self.selectedEscalationReason.toLowerCase() === 'all'
          ? ''
          : self.selectedEscalationReason
        : ''
    missedJobSearchAndFilterModel.etaFilter = self.selectedETAFilterType
    return missedJobSearchAndFilterModel
  }

  private resetAllLoaders() {
    this.getTeamRunning = false
    this.getContractorRunning = false
    this.isLoading = false
    this.dataFetchingInProcess = false
    this.getEscalationReasonLoading = false
  }

  private getTradeName(tradeId: number): string {
    const trade: TradeModel | undefined = this.trades.find((e) => e.tradeId === tradeId)
    if (trade) {
      return trade.description
    }
    return ''
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get isFilterDisabled() {
    if (
      this.getTeamRunning ||
      this.getContractorRunning ||
      this.jobsTradeList.length < 1 ||
      this.isLoading ||
      this.getEscalationReasonLoading
    ) {
      return true
    }
    return false
  }

  private bindSignalRHub() {
    // signalr for add-update requests
    this.signalRHub.addHandler(
      'updateContractorAppointedDetail',
      async (
        jobId: string,
        contractorAppointedDetailId: string,
        status: string,
        address: string,
        companyName: string,
        engineerName: string,
        etaFrom: moment.Moment | null,
        etaTo: moment.Moment | null
      ) => {
        if (jobId && contractorAppointedDetailId && this.missedJobItems.length > 0) {
          this.replaceJobFromMissedJobList(
            jobId,
            contractorAppointedDetailId,
            status,
            address,
            companyName,
            engineerName,
            etaFrom,
            etaTo
          )
        }
      }
    )

    // signalr for remove requests on complete
    this.signalRHub.addHandler(
      'removeContractorAppointedDetail',
      async (jobId: string, contractorAppointedDetailId: string) => {
        if (jobId && contractorAppointedDetailId) {
          this.removeJobFromMissedJobList(jobId, contractorAppointedDetailId)
        }
      }
    )
    this.signalRHub.connect()
  }

  private replaceJobFromMissedJobList(
    jobId: string,
    contractorAppointedDetailId: string,
    status: string,
    address: string,
    companyName: string,
    engineerName: string,
    etaFrom: moment.Moment | null,
    etaTo: moment.Moment | null
  ) {
    const missedJobVisitIndex: number = this.missedJobItems.findIndex(
      (c: MissedJobDetailModel) => c.jobId === jobId && c.contractorAppointedDetailId === contractorAppointedDetailId
    )
    if (missedJobVisitIndex !== -1) {
      const missedJobUpdatedVisitModel: MissedJobDetailModel = new MissedJobDetailModel()
      missedJobUpdatedVisitModel.jobId = jobId
      missedJobUpdatedVisitModel.contractorAppointedDetailId = contractorAppointedDetailId
      missedJobUpdatedVisitModel.status = status
      missedJobUpdatedVisitModel.address = address
      missedJobUpdatedVisitModel.companyName = companyName
      missedJobUpdatedVisitModel.engineerName = engineerName
      missedJobUpdatedVisitModel.etaFrom = etaFrom
      missedJobUpdatedVisitModel.etaTo = etaTo
      // replace job data
      this.missedJobItems.splice(missedJobVisitIndex, 1, missedJobUpdatedVisitModel)
      // show snackbar of job visit updated
      this.snackbarText = 'job ' + jobId + ' has been updated!'
      this.snackbar = true
    }
  }

  private removeJobFromMissedJobList(jobId: string, contractorAppointedDetailId: string) {
    if (jobId && this.missedJobItems.length > 0) {
      const missedJobVisitIndex: number = this.missedJobItems.findIndex(
        (c: MissedJobDetailModel) => c.jobId === jobId && c.contractorAppointedDetailId === contractorAppointedDetailId
      )
      if (missedJobVisitIndex !== -1) {
        this.missedJobItems.splice(missedJobVisitIndex, 1)
        this.missedJobItemsCount -= 1
        // show snackbar of job visit removed
        this.snackbarText = 'Job ' + jobId + ' has been updated and pulled out!'
        this.snackbar = true
      }
    }
  }

  private jobIdValidation(event: any) {
    Shared.jobIdValidation(event)
  }

  private redirectToJob(jobId: string) {
    if (!Shared.isUserHasJobRights(jobId)) {
      return
    }
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.gridView >>> .v-table thead > tr > th:first-child {
  min-width: 100px !important;
}
.gridView >>> .v-table thead > tr > th:last-child {
  min-width: 150px !important;
}
.gridView {
  border: 0px;
}
.search-btn {
  max-width: 100px;
}
.disable-missedjob-table-actions >>> .v-datatable__actions,
.disable-missedjob-table-actions >>> th.column {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none;
}
.hide-dashboard {
  visibility: hidden;
}
.show-dashboard {
  visibility: visible;
}
.dashboard >>> .v-card {
  margin-bottom: 15px !important;
}
</style>
