<template>
  <v-card v-if="jobId" flat>
    <v-card-title>
      <h3 v-if="showHeader">Linked Phone Call</h3>
      <v-spacer></v-spacer>
      <v-btn v-if="recordingUrl && canPlayCall" color="primary" class="play-btn" @click.native="getRecordingUrl">
        <v-icon>play_circle_outline</v-icon>
        &nbsp;Play Call
      </v-btn>
      <v-btn
        v-if="callSid"
        :loading="isLoading"
        :disabled="isLoading"
        color="primary"
        class="transcript-btn"
        @click.native="getTranscriptLog"
      >
        <v-icon>perm_phone_msg</v-icon>
        &nbsp;{{ showTranscriptLog ? 'Hide' : 'See' }} Transcript
      </v-btn>
      <div v-if="!callSid && !recordingUrl">No call log or transcript found</div>
    </v-card-title>
    <v-card-text v-if="showTranscriptLog && transcriptLog.length > 0" class="grey lighten-3">
      <div v-bar="{ useScrollbarPseudo: true }" class="scroll-content">
        <div class="mb-3"></div>
        <div class="call-chat">
          <v-list class="grey lighten-3">
            <template>
              <div v-for="(transcript, index) in transcriptLog" :key="index">
                <v-list-tile avatar class="mb-4" :class="transcript.Speaker === 1 ? 'im-chat' : 'client-chat'">
                  <v-list-tile-content
                    class="elevation-1 pa-2 lighten-4"
                    :class="getTranscriptTextColor(transcript.Speaker)"
                  >
                    <v-list-tile-sub-title class="black--text">
                      {{ transcript.Words }}
                      <div class="grey--text text--darken-1 text-xs-right mt-1">
                        {{ getFullTime(transcript.StartTime) }} -
                        {{ getFullTime(transcript.EndTime) }}
                      </div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </div>
            </template>
          </v-list>
        </div>
      </div>
    </v-card-text>
    <v-snackbar v-if="snackbar" v-model="snackbar" :timeout="3000" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import CallTranscribeModel from '@/models/claim/CallTranscribeModel'
import Store from '@/store'
import DocumentController from '@/api/documentController'

@Component
export default class LinkedPhoneCallDetails extends Vue {
  @Prop() private recordingUrl: string
  @Prop() private callSid: string
  @Prop() private jobId: string
  @Prop({ default: true }) private showHeader: boolean
  private isLoading = false
  private showTranscriptLog = false
  private transcriptLog: CallTranscribeModel[] = []
  private snackbar = false
  private snackbarText = ''

  private getRecordingUrl(): void {
    if (!this.recordingUrl) {
      return
    }
    DocumentController.GetSASTokenForRecordingUrl(this.recordingUrl)
      .then((url: string | null) => {
        if (url) {
          window.open(url, '_blank')
        }
      })
      .catch((error) => {
        // do nothing
      })
  }

  private get canPlayCall() {
    return Store.Instance.state.SessionDetail.canPlayCall
  }

  private getTranscriptLog() {
    // hide transcript
    if (this.showTranscriptLog) {
      this.showTranscriptLog = !this.showTranscriptLog
      return
    }

    // if already exists, show existing log
    if (this.transcriptLog.length > 0) {
      this.showTranscriptLog = true
      return
    }

    this.isLoading = true
    this.transcriptLog = []
    DocumentController.GetTranscript(this.recordingUrl)
      .then((res: string | null) => {
        if (res !== null) {
          axios
            .get(res)
            .then((ress: any) => {
              if (ress.status === 200) {
                this.transcriptLog = ress.data as CallTranscribeModel[]
                this.showTranscriptLog = !this.showTranscriptLog
              }
              this.isLoading = false
            })
            .catch((error) => {
              this.clearData('The specified transcript does not exist.')
            })
        } else {
          this.clearData('Unable to get specified transcript.')
        }
      })
      .catch((err) => {
        this.clearData('Unable to get specified transcript.')
      })
  }

  private clearData(message: string) {
    this.snackbar = true
    this.snackbarText = message
    this.isLoading = false
  }

  private getTranscriptTextColor(speaker: number): string {
    // Note: considered speaker 1 as a IM, always at right hand side
    if (speaker === 1) {
      return 'blue'
    } else if (speaker === 2) {
      return 'green'
    } else if (speaker === 3) {
      return 'red'
    } else {
      return 'yellow'
    }
  }

  private getFullTime(duration: number) {
    const miliSeconds: number = 1000 * duration
    const minutes: number = Math.floor((miliSeconds / 60000).toFixed(2) as any)
    const seconds: any = ((miliSeconds % 60000) / 1000).toFixed(2)
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }
}
</script>

<style scoped>
.call-chat >>> .v-list__tile--avatar {
  height: auto;
  width: calc(100% - 56px);
}
.call-chat >>> .v-list__tile__content {
  border-radius: 5px;
  flex: initial;
  position: relative;
  overflow: visible;
}
.call-chat >>> .v-list__tile__avatar {
  align-self: flex-start;
}
.call-chat >>> .v-list__tile__sub-title {
  white-space: normal;
}
.call-chat >>> .v-list__tile .v-avatar img {
  width: 100%;
}
.call-chat .im-chat >>> .v-list__tile {
  flex-direction: row-reverse;
}
.call-chat .im-chat >>> .v-list__tile .v-avatar {
  margin-left: auto;
}
.call-chat .im-chat >>> .v-list__tile__title,
.call-chat .im-chat >>> .v-list__tile__sub-title {
  text-align: right;
}
.call-chat .im-chat >>> .v-list__tile--avatar {
  margin-left: auto;
}
.call-chat >>> .v-list__tile__content:after {
  content: '';
  position: absolute;
  right: -14px;
  top: -18px;
  border: 14px solid;
  border-color: transparent;
  border-bottom-color: #bbdefb;
  border-left-width: 7px;
  border-right-width: 10px;
  transform: rotate(45deg);
}
.call-chat .client-chat >>> .v-list__tile__content:after {
  left: -11px;
  right: auto;
  transform: rotate(-170deg);
  top: -2px;
}
.call-chat .client-chat >>> .v-list__tile__content.green:after {
  border-bottom-color: #c8e6c9;
}
.call-chat .client-chat >>> .v-list__tile__content.red:after {
  border-bottom-color: #ffcdd2;
}
.call-chat .client-chat >>> .v-list__tile__content.yellow:after {
  border-bottom-color: #fff9c4;
}
</style>
