<template>
  <div>
    <div class="mb-2"><h4>Policy Enquiry Details</h4></div>
    <div v-if="item.clientTitle" class="mb-1">
      <label>Title: </label>
      <span>{{ item.clientTitle }}</span>
    </div>
    <div v-if="item.clientForename" class="mb-1">
      <label>First Name: </label>
      <span>{{ item.clientForename }}</span>
    </div>
    <div v-if="item.clientLastName" class="mb-1">
      <label>Last Name: </label>
      <span>{{ item.clientLastName }}</span>
    </div>

    <div v-if="item.secondaryClientTitle" class="mb-1">
      <label>Secondary Policy Holder Title: </label>
      <span>{{ item.secondaryClientTitle }}</span>
    </div>
    <div v-if="item.secondaryClientForename" class="mb-1">
      <label>Secondary Policy Holder First Name: </label>
      <span>{{ item.secondaryClientForename }}</span>
    </div>
    <div v-if="item.secondaryClientLastName" class="mb-1">
      <label>Secondary Policy Holder Last Name: </label>
      <span>{{ item.secondaryClientLastName }}</span>
    </div>

    <div v-if="item.addressLine1" class="mb-1">
      <label>Address Line1: </label>
      <span>{{ item.addressLine1 }}</span>
    </div>
    <div v-if="item.addressLine2" class="mb-1">
      <label>Address Line2: </label>
      <span>{{ item.addressLine2 }}</span>
    </div>
    <div v-if="item.addressLine3" class="mb-1">
      <label>Address Line3: </label>
      <span>{{ item.addressLine3 }}</span>
    </div>
    <div v-if="item.addressLine4" class="mb-1">
      <label>Address Line4: </label>
      <span>{{ item.addressLine4 }}</span>
    </div>
    <div v-if="item.postCode" class="mb-1">
      <label>Postcode: </label>
      <span>{{ item.postCode }}</span>
    </div>

    <div v-if="item.policyNumber" class="mb-1">
      <label>Policy Number: </label>
      <span>{{ item.policyNumber }}</span>
    </div>
    <div v-if="item.policyScheduleId" class="mb-1">
      <label>PolicyScheduleId: </label>
      <span>{{ item.policyScheduleId }}</span>
    </div>

    <div v-if="item.enquiryCategory" class="mb-1">
      <label>Enquiry Category: </label>
      <span>{{ item.enquiryCategory }}</span>
    </div>
    <div v-if="item.enquiryDescription" class="mb-1">
      <label>Enquiry Description: </label>
      <span>{{ item.enquiryDescription }}</span>
    </div>

    <div v-if="item.FNOLCallSid" class="mb-1">
      <label>FNOL Call Sid: </label>
      <span>{{ item.FNOLCallSid }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddHEJobWithEmergencies from '@/models/requests/AddHEJobWithEmergencies'
import AddJobWithPolicyEnquiry from '@/models/requests/AddJobWithPolicyEnquiry'

@Component
export default class AddJobWithPolicyEnquiryLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddJobWithPolicyEnquiry {
    return Object.assign(new AddJobWithPolicyEnquiry(), this.requestBody)
  }
}
</script>

<style scoped></style>
