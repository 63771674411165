<template>
  <div class="dashboard">
    <v-layout wrap>
      <v-flex class="mt-2">
        <v-container class="text-right contractor-search" fluid pr-2 pl-0 pt-0 pb-2>
          <v-autocomplete
            v-model="selectedContractorId"
            v-validate="'required'"
            label="Search contractor"
            required
            class="required"
            :loading="getContractorEngineersLoading"
            :items="contractors"
            item-text="companyName"
            item-value="id"
            data-vv-scope="frmContractor"
            data-vv-name="Search contractor"
            :error-messages="errors.collect('Search contractor')"
            :data-vv-delay="validationDelay"
            :disabled="getContractorEngineersLoading"
          ></v-autocomplete>
        </v-container>
        <v-container fluid pr-2 pl-0 pt-0 mt-5>
          <v-layout wrap>
            <v-flex>
              <ContractorFinancialSummary
                :contractor-id="selectedContractorId"
                :is-visit-summary-data-loading="getContractorEngineersLoading"
                :engineers="engineers"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import ContractorFinancialSummary from '@/components/ContractorFinancialSummary.vue'
import Shared from '../common/shared'
import EngineerController from '../api/engineerController'
import EngineerModel from '../models/contractor/EngineerModel'
import ContractorController from '../api/contractorController'
import ContractorModel from '../models/contractor/ContractorModel'
import eventBus from '@/common/bus'

@Component({ components: { ContractorFinancialSummary } })
export default class ContractorPODashboard extends Vue {
  private getContractorEngineersLoading = true
  private engineers: EngineerModel[] = []
  private contractors: ContractorModel[] = []
  private selectedContractorId = ''
  private validationDelay = Shared.validationDelay

  private async mounted() {
    const res = await ContractorController.GetAllContractors()
    if (res.length) {
      this.contractors = res
      this.selectedContractorId = this.contractors[0].id
    }
    this.getContractorEngineersLoading = false
  }

  private getContractorEngineers(contractorId: string) {
    this.getContractorEngineersLoading = true
    EngineerController.GetContractorEngineers(contractorId)
      .then((res: EngineerModel[]) => {
        this.engineers = res
        this.getContractorEngineersLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading engineers, please try again', true)
        this.getContractorEngineersLoading = true
      })
  }

  @Watch('this.selectedContractorId')
  private refreshEngineers() {
    this.getContractorEngineersLoading = true
    this.getContractorEngineers(this.selectedContractorId)
    this.getContractorEngineersLoading = false
  }
}
</script>

<style scoped>
.v-autocomplete {
  width: 100%;
  float: right;
}
.contractor-search {
  position: absolute;
  right: 24px;
  top: 16px;
  min-width: 300px;
  width: 30%;
  z-index: 2;
}
</style>
