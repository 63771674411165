<template>
  <v-layout wrap>
    <v-flex md8 lg9 xs8 mb-2>
      <div class="small-text">{{ getSoftFixedEmergency.description }}</div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import SoftFixedEmergency from '@/models/claim/SoftFixedEmergencyModel'
@Component
export default class SoftFixCard extends TimeLineItemBase {
  private get getSoftFixedEmergency(): SoftFixedEmergency {
    return storeGetters.getSoftFixedEmergencyDetailById(this.jobId, this.itemId)
  }
}
</script>
