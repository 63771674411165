import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import HeWizardState from '../states/he-wizard-state'
import { IHeWizardState } from '../states/interfaces/he-wizard-state'

const heWizardState: IHeWizardState = new HeWizardState()

const getters: GetterTree<IHeWizardState, any> = {
  isHeWizardVisible: (state) => state.isHeWizardVisible,
  isDuplicateJobOpen: (state) => state.isDuplicateJobOpen,
}

const mutations: MutationTree<IHeWizardState> = {
  setIsHeWizardVisible: (state: IHeWizardState, isVisible: boolean) => {
    state.isHeWizardVisible = isVisible
  },
  setIsDuplicateJobOpen: (state: IHeWizardState, isDuplicateJobOpen: boolean) => {
    state.isDuplicateJobOpen = isDuplicateJobOpen
  },
}

const actions: ActionTree<IHeWizardState, any> = {
  async setHeWizardVisible({ commit }, isVisible: boolean) {
    commit('setIsHeWizardVisible', isVisible)
  },
  setIsDuplicateJobOpen({ commit }, isOpen: boolean) {
    commit('setIsDuplicateJobOpen', isOpen)
  },
}

export const heWizardModule: Module<IHeWizardState, any> = {
  state: heWizardState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
