import { AxiosResponse } from 'axios'
import MasterRecord from '@/models/MasterRecord'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'

export default class MasterRecordController {
  public static async SaveMasterRecord(model: MasterRecord): Promise<MasterRecord> {
    const res = await CallCentreApi.post<MasterRecord>('MasterRecord/SaveMasterRecord', model)
    return res.data
  }

  public static async GetMasterRecords(recordType: string): Promise<MasterRecord[]> {
    const res = await CallCentreApi.get<MasterRecord[]>('MasterRecord/GetMasterRecords?recordType=' + recordType)
    return res.data ? res.data : []
  }

  public static async DeleteMasterRecord(recordId: string, recordType: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(
      'MasterRecord/DeleteMasterRecord?recordId=' + recordId + '&recordType=' + recordType
    )
    return res.status >= 200 && res.status < 300
  }
}
