<template>
  <div>
    <v-dialog v-model="templateListDialog" max-width="650" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>WIP Customer Notification</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-btn" @click.native="$emit('closeWIPTemplateDialog')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-layout wrap mb-1>
            <v-flex xs12>
              <v-select
                v-model="addWIPCustomerNotification.documentTemplateId"
                :items="templateList"
                item-text="templateName"
                item-value="templateId"
                label="Select Template"
                class="templateList"
                :loading="getTemplatesLoading"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-close" flat="flat" @click.native="$emit('closeWIPTemplateDialog')">
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-submit"
            :disabled="isLoading || getTemplatesLoading || !addWIPCustomerNotification.documentTemplateId"
            :loading="isLoading"
            @click="sendWIPCustomerNotification"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import DashboardController from '@/api/dashboardController'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import AddWIPCustomerNotificationModel from '@/models/requests/AddWIPCustomerNotificationModel'
import eventBus from '@/common/bus'

export interface ITemplateList {
  templateId: number
  templateName: string
}

@Component
export default class WIPTemplateList extends Vue {
  @Prop() private jobId: string
  @Prop() private contractorAppointedDetailId: string

  private templateListDialog = true
  private addWIPCustomerNotification: AddWIPCustomerNotificationModel = new AddWIPCustomerNotificationModel()
  private templateList: ITemplateList[] = []
  private isLoading = false
  private getTemplatesLoading = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText: string | null = ''

  private created() {
    this.getTemplatesLoading = true
    DashboardController.GetWorkInProgressTemplates(this.jobId)
      .then((res: any) => {
        if (res && Object.keys(res).length > 0) {
          for (let index = 0; index < Object.keys(res).length; index++) {
            const template: ITemplateList = {
              templateId: Number(Object.keys(res)[index]),
              templateName: Object.values(res)[index] as any,
            }
            this.templateList.push(template)
          }
          if (this.templateList.length === 1) {
            this.addWIPCustomerNotification.documentTemplateId = this.templateList[0].templateId
          }
        } else {
          this.templateList = []
        }
        this.getTemplatesLoading = false
      })
      .catch((err: any) => {
        this.getTemplatesLoading = false
        this.templateList = []
        eventBus.$emit('errorHandler', 'Error loading WorkInProgress Templates, please try again', true)
      })
  }

  private sendWIPCustomerNotification() {
    if (!this.addWIPCustomerNotification.documentTemplateId) {
      return
    }
    this.isLoading = true
    this.addWIPCustomerNotification.contractorAppointedDetailId = this.contractorAppointedDetailId
    this.addWIPCustomerNotification.jobId = this.jobId
    DashboardController.SendWIPCustomerNotification(this.addWIPCustomerNotification)
      .then((res: boolean) => {
        this.snackbarText = res ? 'Customer notification sent successfully.' : 'Something went wrong. Please try again.'
        this.snackbar = true
        if (res) {
          // added timeout to display snackbar message
          setTimeout(() => {
            this.$emit('closeWIPTemplateDialog')
          }, 700)
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        this.isLoading = false
        eventBus.$emit('errorHandler', err.response.statusText, true)
      })
  }
}
</script>
