import moment from 'moment'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import ContactNumber from '@/models/contractor/ContactNumber'
import { ContactPreferenceEnum } from '@/common/enums'
import AutoDeploymentEngineer from './AutoDeploymentEngineer'
export default class ContractorAppointedModel extends CosmosJobsDbRecordBase {
  public forEmergencyTypeId: number
  public forEmergencyDetailId: number
  public contractorId: string
  public engineerId: string | null
  public status: string
  public etaFrom: moment.Moment | null
  public etaTo: moment.Moment | null
  public companyName: string
  public customerName: string
  public contactNumbers: ContactNumber[]
  public address: string
  public postcode: string
  public latitude: number | null
  public longitude: number | null
  public reasonType: string
  public reasonDescription: string
  public isContractorReAttend: boolean
  public totalVisit: number
  public visitNumber: number
  public additionalCharge: number
  public emergencyId: string
  public isHealthAffected: boolean | null
  public healthAndSafetyLevel: string | null
  public isVulnerability: boolean | null
  public vulnerabilityLevel: string | null
  public tradeId: number
  public hasDelay: boolean
  public delayCode: string | null
  public callRecordingUrl: string | null
  public isUnavailable: boolean
  public engineerName: string
  public engineerContactNumber: string
  public visitStartedAt: moment.Moment | null
  public visitCompletedAt: moment.Moment | null
  public addressLine3: string
  public customerContactPreference: string
  public customerContactPreferenceValue: string
  public jobExpiredForEngineers: AutoDeploymentEngineer[]
  public activeJobEngineer: AutoDeploymentEngineer
  public jobOfferedToEngineersInFuture: AutoDeploymentEngineer[]
  public loggedByTeamName: string
  public escalateJob: boolean
  public dashboardDisplayData?: { etaColor: string }
  public engineerOnTheWayAt: moment.Moment | null
  public insurerId: number
  public schemePriority: number
  public visitPreviouslyAbandoned: boolean
  public previousEngineerVisitId: string
  public additionalTravelTime: string
  public contractorAutoDeploymentExpiredAt: moment.Moment | null
  public policyScheduleId: number | null
  public sessionSid: string
  public escalationReason: string

  public constructor() {
    super()
    this.visitStartedAt = null
    this.visitCompletedAt = null
    this.escalateJob = false
    this.engineerOnTheWayAt = null
    this.contractorAutoDeploymentExpiredAt = null
  }
}
