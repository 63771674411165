import Job from '@/models/Job'
import EmergencyQA from '@/models/EmergencyQA'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import Emergency from '@/models/Emergency'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import VisitHealthAndSafetyProcess from '../claim/VisitHealthAndSafetyProcessModel'
import BoilerBreakdownQA from '../claim/BoilerBreakdownQA'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import JobDelay from '@/models/JobDelay'
import VulnerabilityQA from '../VulnerabilityQA'
import HealthAndSafetyQAModel from '../claim/HealthAndSafetyQAModel'
import AccessNoteModel from '../claim/AccessNoteModel'

export default class ContractorJobDetailModel {
  public jobDetail: Job
  public emergenciesQA: EmergencyQA[]
  public productsQA: EmergencyQA[]
  public picturesUpload: PictureUploadModel[]
  public engineerRequests: EngineerRequestModel[]
  public emergencyDetail: Emergency
  public engineerVisitDetails: EngineerVisitDetail | null
  public healthSafetyQAs: VisitHealthAndSafetyProcess[]
  public boilerBreakdownQA: BoilerBreakdownQA | null
  public siJobDelay: JobDelay | null
  public usJobDelay: JobDelay | null
  public contractorAppointedDetail: ContractorAppointedModel | null
  public emergencyPolicyDefinition: string | null
  public vulnerabilityWarning: VulnerabilityQA
  public healthAndSafetyQA: HealthAndSafetyQAModel | null
  public accessNotes: AccessNoteModel | null

  public constructor() {
    if (!this.emergenciesQA) {
      this.emergenciesQA = []
    }
    if (!this.productsQA) {
      this.productsQA = []
    }
    if (!this.picturesUpload) {
      this.picturesUpload = []
    }
    if (!this.engineerRequests) {
      this.engineerRequests = []
    }
    if (!this.engineerVisitDetails) {
      this.engineerVisitDetails = null
    }
    if (!this.healthSafetyQAs) {
      this.healthSafetyQAs = []
    }
    if (!this.healthAndSafetyQA) {
      this.healthAndSafetyQA = null
    }
    if (!this.accessNotes) {
      this.accessNotes = null
    }
    if (!this.boilerBreakdownQA) {
      this.boilerBreakdownQA = null
    }
    if (!this.siJobDelay) {
      this.siJobDelay = null
    }
    if (!this.usJobDelay) {
      this.usJobDelay = null
    }
    if (!this.contractorAppointedDetail) {
      this.contractorAppointedDetail = null
    }
  }

  // gets the jobs address
  public getAddress(spacer: string): string {
    let output = ''
    const lines = [
      this.jobDetail.addressLine1,
      this.jobDetail.addressLine2,
      this.jobDetail.addressLine3,
      this.jobDetail.addressLine4,
      this.jobDetail.postCode,
    ]
    for (const line of lines) {
      if (line && line.trim() !== '') {
        if (output.length > 0) {
          output += spacer
        }
        output += line.trim()
      }
    }
    return output
  }
}
