import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import { JobNoteType } from '@/common/enums'

export default class JobNoteModel extends CosmosJobsDbRecordBase {
  public noteType: JobNoteType
  public title: string
  public description: string
  public loggedByUserId: string
  public loggedByUserName: string
  public forEmergencyDetailId: number
  public forEmergencyDetailDescription: string | null
  public callRecordingUrl: string
}
