<template>
  <div :id="tableName" class="dashboard">
    <v-layout v-show="ready" wrap grid-view ctp-dashboard :class="openJobView ? 'hide-dashboard' : 'show-dashboard'">
      <v-card-title class="px-0 pb-0 pt-0">
        <h3>Customer To Pay</h3>
        <v-spacer></v-spacer>
        <div class="grey filter-content pa-2 pl-3 mt-2 lighten-3 mb-2">
          <v-container fluid class="grid-list-lg pa-0">
            <v-layout wrap class="filter-col-content">
              <v-flex col1>
                <v-text-field
                  v-model="paymentFilter.customerName"
                  label="Search customer name"
                  prepend-inner-icon="search"
                ></v-text-field>
              </v-flex>
              <v-flex col1>
                <v-autocomplete
                  v-model="paymentFilter.selectedInsurerId"
                  :items="insurers"
                  item-text="name"
                  item-value="id"
                  label="Select insurer"
                  single-line
                  class="insurerlist"
                  @change="getPolicyList($event)"
                ></v-autocomplete>
              </v-flex>
              <v-flex col1>
                <v-select
                  v-model="paymentFilter.paymentPolicy"
                  :items="polices"
                  item-text="policyBasicDetail.name"
                  item-value="policyBasicDetail.policyId"
                  label="Search policy"
                  single-line
                  class="policiesList"
                  :loading="policySearchRunning"
                ></v-select>
              </v-flex>
              <v-flex col2>
                <v-select
                  v-model="paymentFilter.paymentStatus"
                  :items="paymentStatusList"
                  item-value="id"
                  item-text="paymentStatus"
                  label="Payment Status"
                  class="paymentStatus"
                ></v-select>
              </v-flex>
              <v-flex date-content col2>
                <DateTimePicker
                  ref="dateTimePickerFrom"
                  v-model="paymentFilter.paymentFrom"
                  :date-time.sync="paymentFilter.paymentFrom"
                  :is-static-location="false"
                  place-holder-text="From"
                  :is-current-time="false"
                  :is-validation-required="false"
                  :show-present-time-icon="false"
                  :max-date="selectableDateTime(paymentFilter.paymentTo)"
                  :hide-time-picker="true"
                  :display-format="dateFormat()"
                />
                <span v-if="paymentFilter.paymentFrom" class="cleardate" @click="paymentFilter.paymentFrom = null">
                  <v-icon>close</v-icon>
                </span>
              </v-flex>
              <v-flex date-content col2>
                <DateTimePicker
                  ref="dateTimePickerTo"
                  v-model="paymentFilter.paymentTo"
                  :date-time.sync="paymentFilter.paymentTo"
                  :is-static-location="false"
                  place-holder-text="To"
                  :is-current-time="false"
                  :is-validation-required="false"
                  :show-present-time-icon="false"
                  :max-date="maxPaymentToDateTime"
                  :min-date="selectableDateTime(paymentFilter.paymentFrom)"
                  :hide-time-picker="true"
                  :display-format="dateFormat()"
                />
                <span v-if="paymentFilter.paymentTo" class="cleardate" @click="paymentFilter.paymentTo = null">
                  <v-icon>close</v-icon>
                </span>
              </v-flex>
              <v-flex col2>
                <v-switch
                  v-model="paymentFilter.paymentByPayoutDate"
                  name="paymentByPayoutDate"
                  color="primary"
                  :label="paymentByPayoutDateText"
                  class="paymentByPayoutDate"
                ></v-switch>
              </v-flex>
              <v-flex text-xs-right pt-3 mt-1 filter-action>
                <v-btn
                  color="primary"
                  class="mt-2 search-btn"
                  small
                  :loading="isLoading"
                  :disabled="isLoading"
                  @click="GetPaymentList(paymentFilter, false)"
                >
                  <v-icon>filter_list</v-icon>
                  &nbsp; Search
                </v-btn>
                <v-btn color="primary--text" class="mt-2 clearFilters-btn" small @click="clearFilters">
                  <v-icon>clear</v-icon>
                  &nbsp; Clear
                </v-btn>
                <v-btn
                  v-if="canViewFinance"
                  ref="export-button"
                  slot="activator"
                  color="primary"
                  class="mt-2"
                  :disabled="isLoading"
                  small
                  @click="openExportToExcelDialog"
                >
                  <v-icon class="md-icon">get_app</v-icon>
                  &nbsp; Export
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-card-title>
      <v-flex xs12 mt-2 class="elevation-1">
        <v-data-table
          :headers="headers"
          :items="paymentList"
          :pagination.sync="pagination"
          :loading="isLoading"
          :total-items="totalPaymentCount"
          :rows-per-page-items="rowsPerPageItems"
          item-key="chargeId"
        >
          <template slot="items" slot-scope="props">
            <tr
              :active="props.selected"
              class="lighten-5"
              :class="props.item.colourCode"
              @click="props.expanded = !props.expanded"
            >
              <td>
                <a
                  href="Javascript:void(0)"
                  name="openJobDetailButton"
                  class="primary--text link-jobId"
                  @click="redirectToJob(props.item.jobId)"
                >
                  {{ props.item.jobId }}
                </a>
              </td>
              <td>{{ props.item.cardHolderName }}</td>
              <td>{{ props.item.postcode }}</td>
              <td>{{ props.item.cardBrand }}</td>
              <td>{{ props.item.statementDescription }}</td>
              <td>{{ props.item.policyName }}</td>
              <td>{{ getFormattedDateTime(props.item.createdAt) }}</td>
              <td>{{ props.item.paymentStatus }}</td>
              <td :class="props.item.net > 0 ? 'text-xs-right' : 'text-xs-center'">
                <b>
                  {{ props.item.net > 0 ? getFormatedCurrency(props.item.net) : '-' }}
                </b>
              </td>
              <td :class="props.item.fee > 0 ? 'text-xs-right' : 'text-xs-center'">
                <b>
                  {{ props.item.fee > 0 ? getFormatedCurrency(props.item.fee) : '-' }}
                </b>
              </td>
              <td class="text-xs-right">
                <b>{{ getFormatedCurrency(props.item.amount) }}</b>
              </td>
              <td class="text-xs-center">
                <b>
                  {{ props.item.amountRefunded > 0 ? getFormatedCurrency(props.item.amountRefunded * -1) : '-' }}
                </b>
              </td>
              <td class="text-xs-right">
                <b class="secondary--text">
                  {{
                    getFormatedCurrency(props.item.amount - (props.item.amountRefunded ? props.item.amountRefunded : 0))
                  }}
                </b>
              </td>
              <td :class="!props.item.payoutCreatedAt ? 'text-xs-center' : 'payoutat-col'">
                {{ props.item.payoutCreatedAt ? getFormattedDateTime(props.item.payoutCreatedAt) : '-' }}
              </td>
              <td class="text-xs-center">
                <a
                  v-if="props.item.chargeId"
                  class="payment-receipt"
                  @click.stop="redirectToPaymentReceipt(props.item.chargeId)"
                >
                  <v-icon>email</v-icon>
                </a>
              </td>
            </tr>
          </template>
          <template slot="expand" slot-scope="props">
            <v-card flat>
              <v-card-text
                v-if="props.item.ctpRefundHistory && props.item.ctpRefundHistory.length > 0"
                class="refund-detail-table"
              >
                <template>
                  <v-data-table
                    :headers="refundTableHeaders"
                    :items="props.item.ctpRefundHistory"
                    hide-actions
                    class="elevation-1"
                  >
                    <template slot="items" slot-scope="props" class="text-xs-center">
                      <td>{{ getFormattedDateTime(props.item.createdAt) }}</td>
                      <td>
                        {{ getFormatedCurrency(props.item.amountRefunded * -1) }}
                      </td>
                      <td>{{ props.item.reason }}</td>
                      <td>{{ props.item.description }}</td>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
              <v-card-text v-else class="refund-detail-table">No Refund(s) Found</v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <div v-show="!ready">
      <v-progress-circular class="loading-spinner" :width="2" :size="50" indeterminate color="primary">
      </v-progress-circular>
    </div>

    <!-- export to excel dialog -->
    <v-dialog
      v-if="exportToExcelDialog"
      v-model="exportToExcelDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Export To Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="exportToExcelDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 pt-0 scroll-content-dialog">
          <v-container grid-list-xl class="pa-0">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model.trim="exportName" label="Export Name" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="exportToExcelDialog = false">Cancel</v-btn>
          <export-excel
            class="btn ml-2"
            :export-fields="exportToExcelHeaderFields"
            :fetch="exportCTP"
            worksheet="CTP Details"
            :before-generate="startExportGeneration"
            :before-finish="stopExportGeneration"
            type="xls"
            :name="exportName ? exportName + '.xls' : 'ctpList.xls'"
          >
            <v-btn ref="ExportToExcel" color="primary" :loading="exportToExcelLoader" :disabled="exportToExcelLoader">
              <v-icon class="md-icon">get_app</v-icon>
              &nbsp; Export
            </v-btn>
          </export-excel>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /export to excel dialog -->

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
    <PartialJobView ref="refPartialJobView" :job-id="selectedJobIdToExpand" @closeJobView="closeJobView">
    </PartialJobView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import DateTimePicker from '@/components/DateTimePicker.vue'
import PaymentController from '@/api/paymentController'
import InvoiceController from '@/api/invoiceController'
import PolicyScheduleController from '@/api/policyScheduleController'
import CTPPayment from '@/models/claim/CTPPaymentModel'
import InsurerModel from '../../models/policy/InsurerModel'
import CTPPaymentDetails from '../../models/claim/CTPPaymentDetailsModel'
import PolicyModel from '../../models/policy/PolicyModel'
import DashboardController from '@/api/dashboardController'
import excel from 'vue-excel-export'
import InsurerController from '@/api/InsurerController'
import CTPRefundHistoryModel from '../../models/claim/CTPRefundHistoryModel'
import eventBus from '@/common/bus'
import PartialJobView from '@/components/PartialJobView.vue'
import ClientAuthPolicies from '@/models/auth/client-auth-policies'

@Component({
  components: { DateTimePicker, excel, PartialJobView },
})
export default class CTPTable extends Vue {
  @Prop() private tableName: string
  private paymentList: CTPPaymentDetails[] = []
  private pagination: any = {}
  private headers: any = []
  private ready = false
  private maxPaymentToDateTime = ''
  private rowsPerPageItems: number[] = [5, 10, 25]
  private paymentStatusList: string[] = ['All', 'Uncaptured', 'Succeeded', 'Refunded', 'Payout']
  private paymentFilter: {
    customerName: string
    selectedInsurerId: number
    paymentPolicy: number
    paymentFrom: moment.Moment | null
    paymentTo: moment.Moment | null
    paymentStatus: string
    rows: number | null
    paymentByPayoutDate: boolean
  } = {
    customerName: '',
    selectedInsurerId: 0,
    paymentPolicy: 0,
    paymentFrom: null,
    paymentTo: null,
    paymentStatus: 'Uncaptured',
    rows: null,
    paymentByPayoutDate: false,
  }
  private totalPaymentCount = 0
  private sortOrder = ''
  private insurers: InsurerModel[] = []
  private isLoading = false
  private polices: PolicyModel[] = []
  private policySearchRunning = false
  private showDatePickerFrom = false
  private showDatePickerTo = false
  private exportToExcelDialog = false
  private exportToExcelHeaderFields: any = {
    Name: 'given_name',
    'Job ID': 'job_id',
    'Customer Name': 'cust_name',
    PostCode: 'cust_postcode',
    Brand: 'brand',
    Emergency: 'emergency',
    Policy: 'policy',
    'Date Created': 'date_created',
    Status: 'status',
    'Net(£)': 'net',
    'Fee(£)': 'fee',
    'Paid(£)': 'amount',
    'Refunded(£)': 'amountRefunded',
    'Total(£)': 'totalAmount',
    'Payout Created': 'payoutCreatedAt',
  }
  private exportToExcelLoader = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private exportToExcelData: any = []
  private maxExportDate = moment().format(DateTimePicker.DATE_FORMAT)
  private exportName = ''
  private exportList: any[]
  private refundTableHeaders: any = []
  private openJobView = false
  private selectedJobIdToExpand = ''

  private created() {
    this.headers = [
      { text: 'JobId', value: 'jobId', align: 'left', sortable: true },
      {
        text: 'Name',
        value: 'cardHolderName',
        align: 'left',
        class: 'severity-col',
        sortable: true,
      },
      {
        text: 'PostCode',
        value: 'postcode',
        align: 'left',
        class: 'severity-col',
        sortable: true,
      },
      {
        text: 'Brand',
        value: 'cardBrand',
        align: 'left',
        class: 'severity-col',
        sortable: true,
      },
      {
        text: 'Emergency',
        value: 'statementDescription',
        align: 'left',
        sortable: true,
      },
      { text: 'Policy', value: 'policyName', align: 'left', sortable: true },
      {
        text: 'Created At',
        value: 'createdAt',
        align: 'left',
        class: 'date-col',
        sortable: true,
      },
      {
        text: 'Status',
        value: 'paymentStatus',
        align: 'left',
        sortable: false,
      },
      { text: 'Net', value: 'net', align: 'right', sortable: true },
      { text: 'Fee', value: 'fee', align: 'right', sortable: true },
      { text: 'Paid', value: 'amount', align: 'right', sortable: true },
      {
        text: 'Refunded',
        value: 'amountRefunded',
        align: 'center',
        sortable: true,
      },
      { text: 'Total', value: 'totalAmount', align: 'center', sortable: false },
      {
        text: 'Payout At',
        value: 'payoutCreatedAt',
        align: 'left',
        class: 'payoutat-col',
        sortable: true,
      },
      {
        text: 'Receipt',
        value: '',
        align: 'left',
        class: 'center',
        sortable: false,
      },
    ]
    this.refundTableHeaders = [
      {
        text: 'Created At',
        value: 'createdAt',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Amount Refunded',
        value: 'amountRefunded',
        align: 'center',
        sortable: true,
      },
      { text: 'Reason', value: 'reason', align: 'center', sortable: true },
      {
        text: 'Description',
        value: 'description',
        align: 'center',
        sortable: true,
      },
    ]
    this.pagination.page = 1
    this.pagination.rowsPerPage = 10
    this.pagination.sortBy = ''
    this.pagination.descending = ''
    Vue.use(excel)
  }

  private mounted() {
    this.ready = false
    this.clearFilters()
    this.getInsurerList()
  }

  private get canViewFinance(): boolean {
    return (this.$store.getters['authModule/clients'] as ClientAuthPolicies).readFinancial
  }

  private async openExportToExcelDialog() {
    this.exportName = ''
    await this.GetPaymentList(this.paymentFilter, false)
    this.exportToExcelDialog = true
  }

  private startExportGeneration() {
    this.exportToExcelLoader = true
  }

  private stopExportGeneration() {
    this.exportToExcelLoader = false
  }

  private async GenerateExcelForCTP(from: moment.Moment | null, to: moment.Moment | null, status: string) {
    this.startExportGeneration()
    const filter: {
      customerName: string
      selectedInsurerId: number
      paymentPolicy: number
      paymentFrom: moment.Moment | null
      paymentTo: moment.Moment | null
      paymentStatus: string
      rows: number | null
    } = {
      customerName: this.paymentFilter.customerName,
      selectedInsurerId: this.paymentFilter.selectedInsurerId,
      paymentPolicy: this.paymentFilter.paymentPolicy,
      paymentFrom: from,
      paymentTo: to,
      paymentStatus: status,
      rows: this.totalPaymentCount,
    }
    await this.GetPaymentList(filter, true)
  }

  private async exportCTP() {
    await this.GenerateExcelForCTP(
      this.paymentFilter.paymentFrom,
      this.paymentFilter.paymentTo,
      this.paymentFilter.paymentStatus
    )
    if (this.exportList && this.exportList.length > 0) {
      let excelData: any = {}
      this.exportToExcelData = []
      this.exportList.forEach((element) => {
        excelData = {
          given_name: this.exportName,
          job_id: element.jobId,
          cust_name: element.cardHolderName,
          cust_postcode: element.postcode,
          brand: element.cardBrand,
          emergency: element.statementDescription,
          policy: element.policyName,
          date_created: this.getFormattedDateTime(element.createdAt),
          status: element.paymentStatus,
          net: element.net > 0 ? this.getFormatedCurrency(element.net, true) : '-',
          fee: element.fee > 0 ? this.getFormatedCurrency(element.fee, true) : '-',
          amount: this.getFormatedCurrency(element.amount, true),
          amountRefunded: element.amountRefunded ? this.getFormatedCurrency(element.amountRefunded * -1, true) : '-',
          totalAmount: this.getFormatedCurrency(
            element.amount - (element.amountRefunded ? element.amountRefunded : 0),
            true
          ),
          payoutCreatedAt: element.payoutCreatedAt ? this.getFormattedDateTime(element.payoutCreatedAt) : '-',
        }
        this.exportToExcelData.push(excelData)

        if (element.ctpRefundHistory && element.ctpRefundHistory.length > 0) {
          element.ctpRefundHistory.forEach((childElement: CTPRefundHistoryModel) => {
            excelData = {
              given_name: this.exportName,
              job_id: element.jobId,
              cust_name: element.cardHolderName,
              cust_postcode: element.postcode,
              brand: element.cardBrand,
              emergency: element.statementDescription,
              policy: element.policyName,
              date_created: this.getFormattedDateTime(childElement.createdAt),
              status: 'Refunded',
              net: element.net > 0 ? this.getFormatedCurrency(element.net, true) : '-',
              fee: element.fee > 0 ? this.getFormatedCurrency(element.fee, true) : '-',
              amount: '-',
              amountRefunded: this.getFormatedCurrency(childElement.amountRefunded * -1, true),
              totalAmount: '-',
              payoutCreatedAt: element.payoutCreatedAt ? this.getFormattedDateTime(element.payoutCreatedAt) : '-',
            }
            this.exportToExcelData.push(excelData)
          })
        }
      })
      this.exportToExcelDialog = false
      this.snackbar = true
      this.snackbarText = 'Data Exported Successfully.'
      return this.exportToExcelData
    } else {
      this.exportToExcelData = []
      this.snackbar = true
      this.snackbarText = 'No Data Available.'
      this.stopExportGeneration()
      return this.exportToExcelData
    }
  }

  private getInsurerList() {
    const self = this
    self.ready = false
    InsurerController.GetInsurerList()
      .then((res: InsurerModel[]) => {
        self.insurers = res.filter((i) => !i.isDelete)
        self.ready = true
      })
      .catch((err: any) => {
        self.ready = true
        eventBus.$emit('errorHandler', 'Error loading insurer list, please try again', true)
      })
  }

  private getPolicyList(selectedInsurerId: number) {
    if (!selectedInsurerId) {
      return
    }
    const self = this
    self.policySearchRunning = true
    const insurerId = selectedInsurerId ? selectedInsurerId : 0
    PolicyScheduleController.GetPoliciesByInsurerId(insurerId)
      .then((res: PolicyModel[]) => {
        // get only published policies
        self.polices = res.filter(
          (p: PolicyModel) => p.policyBasicDetail.isNewPolicyPublished && !p.policyBasicDetail.isDeleted
        )
        self.policySearchRunning = false
        self.paymentFilter.paymentPolicy = 0
      })
      .catch((err: any) => {
        self.policySearchRunning = false
        eventBus.$emit('errorHandler', 'Error loading policy detail, please try again', true)
      })
  }

  private async GetPaymentList(paymentFilter, isExport: boolean) {
    const self = this
    this.isLoading = true
    PaymentController.GetPaymentList(
      self.pagination.page,
      self.pagination.rowsPerPage,
      self.pagination.sortBy,
      self.sortOrder,
      self.paymentFilter.customerName,
      self.paymentFilter.selectedInsurerId,
      self.paymentFilter.paymentPolicy,
      self.paymentFilter.paymentFrom ? self.paymentFilter.paymentFrom.toISOString() : '',
      self.paymentFilter.paymentTo ? self.paymentFilter.paymentTo.toISOString() : '',
      self.paymentFilter.paymentStatus,
      self.paymentFilter.paymentByPayoutDate
    )
      .then((res: CTPPayment | null) => {
        self.ready = true
        this.isLoading = false
        if (res) {
          self.totalPaymentCount = res.totalPaymentCount
          self.paymentList = res.paymentDetailsList
          self.exportList = res.paymentDetailsList
        }
      })
      .catch((err) => {
        this.ready = true
        eventBus.$emit('errorHandler', 'Error loading payment list, please try again', true)
      })
    self.ready = true
    this.isLoading = false
  }

  private redirectToPaymentReceipt(chargeId: string) {
    this.isLoading = true
    InvoiceController.GetInvoiceReceiptURL(chargeId)
      .then((res: string) => {
        if (res) {
          window.open(res, '_blank')
        }
        this.isLoading = false
      })
      .catch((err) => {
        eventBus.$emit('errorHandler', 'Error loading invoice receipt, please try again', true)
        this.isLoading = false
      })
  }

  @Watch('pagination')
  private async paginationChange() {
    this.isLoading = true
    if (this.pagination.descending && (this.pagination.sortBy === null ? '' : this.pagination.sortBy)) {
      this.sortOrder = 'desc'
    } else if (!this.pagination.descending && (this.pagination.sortBy === null ? '' : this.pagination.sortBy)) {
      this.sortOrder = 'asc'
    } else {
      this.sortOrder = ''
    }
    await this.GetPaymentList(this.paymentFilter, false)
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getFormatedCurrency(value: number, isWithoutSymbol = false) {
    return Shared.getFormatedCurrency(value, isWithoutSymbol)
  }

  private clearFilters() {
    this.maxPaymentToDateTime = moment().format(DateTimePicker.DATE_FORMAT)
    this.paymentFilter = {
      customerName: '',
      selectedInsurerId: 0,
      paymentPolicy: 0,
      paymentFrom: moment().subtract(7, 'd'),
      paymentTo: moment(),
      paymentStatus: 'Uncaptured',
      rows: null,
      paymentByPayoutDate: false,
    }
    this.isLoading = false
  }

  private selectableDateTime(date: moment.Moment | null): string {
    if (date) {
      return moment(date).format(DateTimePicker.DATE_FORMAT)
    }
    return ''
  }

  private dateFormat(): string {
    return Store.Instance.state.Environment.DateFormat
  }

  private get paymentByPayoutDateText(): string {
    return 'Filter By ' + (this.paymentFilter.paymentByPayoutDate ? 'Payout' : 'Transaction') + ' Date'
  }

  private redirectToJob(jobId: string) {
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.gridView tbody tr td:last-child {
  padding-left: 0px !important;
}

.gridView {
  border: 0px;
}

.ctp-dashboard >>> .v-card__title {
  width: 100%;
}

.ctp-dashboard >>> .v-card__title h3 {
  width: 100%;
}

.filter-content {
  width: 100%;
}

.date-content {
  padding-right: 30px !important;
  position: relative;
}

.date-content .cleardate {
  position: absolute;
  right: 55px;
  top: 28px;
  cursor: pointer;
}

.filter-col-content > .col1 {
  width: 30%;
}

.filter-col-content > .col2 {
  width: 23%;
}

.filter-col-content > .filter-action {
  width: 215px;
}

.gridView >>> thead tr th:nth-child(11),
.gridView >>> tbody tr td.payoutat-col:nth-child(11) {
  padding-left: 15px !important;
  width: 140px;
}

.refund-detail-table {
  text-align: center;
  border: 1px solid lightgray;
}

.hide-dashboard {
  visibility: hidden;
}

.show-dashboard {
  visibility: visible;
}

.dashboard >>> .v-card {
  margin-bottom: 15px !important;
}
</style>
