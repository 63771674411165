<template>
  <v-card v-if="item" class="pt-0 px-2 pb-2">
    <v-card-title>
      <v-layout>
        <v-flex xs12>
          <h3 class="heading">
            {{ item.subject }}
            <span class="teal--text">({{ documentTemplate }})</span>
          </h3>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <pre class="email-body" v-html="sanitizeHTML(item.body)"></pre>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import { DocumentTemplateType } from '@/common/enums'
import EmailModel from '@/models/claim/EmailModel'

@Component
export default class EmailPreview extends TimeLineItemBase {
  private get item(): EmailModel | null {
    if (this.jobId && this.itemId) {
      return storeGetters.getJobEmails(this.jobId, this.itemId)
    }
    return null
  }

  private get documentTemplate(): string {
    if (this.jobId && this.itemId && this.item) {
      if (this.item.documentTemplateType) {
        switch (this.item.documentTemplateType.toString()) {
          case DocumentTemplateType[DocumentTemplateType.NewJobLogged]:
            return 'New Job Logged'
          case DocumentTemplateType[DocumentTemplateType.ContractorAssigned]:
            return 'Contractor Appointed'
          case DocumentTemplateType[DocumentTemplateType.EngineerOnTheWay]:
            return 'Engineer On The Way'
          case DocumentTemplateType[DocumentTemplateType.JobCompleted]:
            return 'Job Completed'
          case DocumentTemplateType[DocumentTemplateType.CustomerAppointedTradespeople]:
            return 'Customer Appointed Tradespeople'
          case DocumentTemplateType[DocumentTemplateType.FollowOnWork]:
            return 'Follow On Work'
          case DocumentTemplateType[DocumentTemplateType.DeploymentDelay]:
            return 'Deployment Delay'
          case DocumentTemplateType[DocumentTemplateType.PartRequestApproved]:
            return 'Part Request Approved'
          case DocumentTemplateType[DocumentTemplateType.SetETAOnReturnVisit]:
            return 'Set ETA On Return Visit'
          default:
            return this.item.documentTemplateType.toString()
        }
      }
    }
    return ''
  }
}
</script>

<style scoped>
pre {
  font-family: inherit;
  white-space: pre-line;
}
</style>
