import { SIProductType, JobType } from '@/common/enums'
import ProductAttribute from './ProductAttribute'
import ProductExtra from './ProductExtra'

export default class Product {
  public id: number
  public name: string
  public description: string
  public basePrice = 0
  public productTypeId: SIProductType
  public unit: string
  public jobTypeId: JobType
  public productAttributes?: ProductAttribute[]
  public productExtra?: ProductExtra
  public productChildLinks?: Product[]
  public quantity = 1
  public pitId: string
  public notes: string
  public isPriceUpdated: boolean // property to check product price updated or not - required for front-end
  public isDeleted: boolean

  // copy another product
  constructor(product?: Product) {
    if (product) {
      this.id = product.id
      this.name = product.name
      this.description = product.description
      this.basePrice = product.basePrice
      this.productTypeId = product.productTypeId
      this.unit = product.unit
      this.jobTypeId = product.jobTypeId
      this.productAttributes = product.productAttributes
      this.productExtra = product.productExtra
      this.productChildLinks = product.productChildLinks
      this.notes = product.notes
      this.isPriceUpdated = false
      this.isDeleted = product.isDeleted
    }
  }
}
