export class ManagerSupervisorDashboardCount {
  public totalLoggedJobs: number
  public totalDeployedJobs: number
  public totalCalls: number
  public highestTaskCountInQueue: number
  public longestTaskWaitingAge: number
  public averageSlaRateToday: number
  public insurerCounts: { [key: number]: InsurerCount }
  public totalContractorCallInQueue: number
  public longestContractorCallInQueue: number

  public constructor() {
    this.totalLoggedJobs = 0
    this.totalDeployedJobs = 0
    this.totalCalls = 0
    this.highestTaskCountInQueue = 0
    this.longestTaskWaitingAge = 0
    this.averageSlaRateToday = 0
    this.insurerCounts = []
    this.totalContractorCallInQueue = 0
    this.longestContractorCallInQueue = 0
  }
}

export class InsurerCount {
  public totalCalls: number
  public totalLoggedJobs: number
  public totalDeployedJobs: number
}
