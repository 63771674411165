import Axios, { AxiosError, AxiosResponse } from 'axios'
import CallCentreApi, { getBaseUrl } from '@/api/CallCentreApiAxiosPlugin'
import SessionDetail from '@/models/auth/SessionDetail'
import UserModel from '@/models/user/UserModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import StoreMutations from '@/storeMutations'
import eventBus from '@/common/bus'

export default class SessionController {
  public static async LoadSession(accessToken: string): Promise<void> {
    const baseUrl = getBaseUrl()
    const url = baseUrl + 'session/detail'
    const res = await Axios.get(url, {
      validateStatus: (s) => true,
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Authorization: 'bearer ' + accessToken,
      },
    })

    if (res.status !== 200) {
      throw new Error('failed to get session data', { cause: res.status })
    }
    const sd: SessionDetail = Object.assign(new SessionDetail(), res.data, { accessToken })

    let u: any
    switch (sd.detailRecordType) {
      case 'UserDetail':
        u = Object.assign(new UserModel(), sd.detailRecord.UserDetail)
        sd.detailRecord.UserDetail = u
        break
      case 'EngineerDetail':
        u = Object.assign(new EngineerModel(), sd.detailRecord.EngineerDetail)
        sd.detailRecord.EngineerDetail = u
        break
    }

    if (sd.detailRecordType === 'EngineerDetail') {
      // api call to get logged in contractor status
      const contractorStautUrl = baseUrl + 'Contractor/GetContractorStatus'
      const response: AxiosResponse = await Axios.get(contractorStautUrl, {
        validateStatus: (s) => true,
        headers: {
          'Cache-Control': 'no-cache, no-store',
          Authorization: 'bearer ' + accessToken,
        },
      })

      if (response.status >= 200 && response.status < 300) {
        StoreMutations.setContractorRegistrationStatus(response.data)
      }
      StoreMutations.setSessionDetail(sd)
    } else {
      StoreMutations.setSessionDetail(sd)
    }
  }

  public static ClearSession(): void {
    StoreMutations.clearSessionDetail()
  }

  public static async AddUserToNotificationsForContractor(contractorId: string): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.post('Session/AddUserToNotificationsForContractor', {
        contractorId,
      })
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static async AddUserToNotificationsForJob(jobId: string): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.post('Session/AddUserToNotificationsForJob', { jobId })
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static async RemoveUserFromNotificationsForJob(jobId: string): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.post('Session/RemoveUserFromNotificationsForJob', { jobId })
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static handleUnexpectedStatusResponse(err: AxiosError): void {
    // api exceptions are handle at backend, so not saved into azure appinsight from front-end
    eventBus.$emit('errorHandler', err, false)
  }

  public static async GetProfileImageUploadUrl(fileName: string): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get('Session/GetProfileImageUploadUrl?fileName=' + fileName)
    return res.data ? res.data : ''
  }

  public static async AddUserToNotificationsForClient(insurerId: number): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.get(
        'Session/AddUserToNotificationsForClient?insurerId=' + insurerId
      )
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static async RemoveUserFromNotificationsForClient(insurerId: number): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.get(
        'Session/RemoveUserFromNotificationsForClient?insurerId=' + insurerId
      )
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static async AddUserToNotificationHub(): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.get('Session/AddUserToNotificationHub')
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static async RemoveUserFromNotificationHub(): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.get('Session/RemoveUserFromNotificationHub')
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static async AddUserToEngineerLiveLocationUpdate(engineerId: string): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.get(
        'Session/AddUserToEngineerLiveLocationUpdate?engineerId=' + engineerId
      )
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }

  public static async RemoveUserFromEngineerLiveLocationUpdate(engineerId: string): Promise<boolean> {
    try {
      const res: AxiosResponse = await CallCentreApi.get(
        'Session/RemoveUserFromEngineerLiveLocationUpdate?engineerId=' + engineerId
      )
      return res.status >= 200 && res.status < 300
    } catch (err) {
      this.handleUnexpectedStatusResponse(err as AxiosError)
      return false
    }
  }
}
