import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import SitePackage from '@/models/siteInvestigation/SitePackage'
import { SIProductType } from '@/common/enums'
import Product from '@/models/siteInvestigation/Product'
import SubsidenceConsultantModel from '@/models/SubsidenceConsultantModel'
import ClaimManagementCompanyModel from '@/models/ClaimManagementCompanyModel'
import SIReportModel from '@/models/claim/SIReportModel'
import ContractorModel from '@/models/contractor/ContractorModel'
import ChangeSIEnablementModel from '@/models/requests/ChangeSIEnablementModel'
import AddEmergencyAnswer from '@/models/requests/AddEmergencyAnswer'
import MonitoringVisitLog from '@/models/siteInvestigation/MonitoringVisitLog'
import MonitoringDetail from '@/models/siteInvestigation/MonitoringDetail'
import IVSDetailModel from '@/models/IVSDetailModel'
import UpsertSIJobDelay from '@/models/siteInvestigation/requests/UpsertSIJobDelay'
import AddBoreHoleLog from '@/models/siteInvestigation/requests/AddBoreHoleLog'
import UpdateTrialPitDetail from '@/models/siteInvestigation/requests/UpdateTrialPitDetail'
import SICCTVLogConfiguration from '@/models/siteInvestigation/SICCTVLogConfiguration'
import CCTVControlLogModel from '@/models/siteInvestigation/CCTVControlLog'
import AddCCTVControlRunLogModel from '@/models/siteInvestigation/requests/AddCCTVControlRunLogModel'
import UpdateDatumControlLog from '@/models/siteInvestigation/requests/UpdateDatumControlLog'
import SIBoreHoleLogConfiguration from '@/models/siteInvestigation/SIBoreHoleLogConfiguration'
import UpdateWaterMainsTest from '@/models/siteInvestigation/requests/UpdateWaterMainsTest'
import DeleteWaterMainsTestRecord from '@/models/siteInvestigation/requests/DeleteWaterMainsTestRecord'
import AddSIProductVerificationRequest from '@/models/siteInvestigation/requests/AddSIProductVerificationRequest'
import AddTrialPitDrawingModel from '@/models/siteInvestigation/requests/AddTrialPitDrawingModel'
import AddSIRevisitRequest from '@/models/requests/AddSIRevisitRequest'
import DeleteCCTVControlRunLogModel from '@/models/requests/DeleteCCTVControlRunLogModel'
import DeleteBoreholeLogModel from '@/models/requests/DeleteBoreholeLogModel'
import AddAbandonedSIProductRequest from '@/models/siteInvestigation/requests/AddAbandonedSIProductRequest'
import CompleteWaterMainsTestRecord from '@/models/siteInvestigation/requests/CompleteWaterMainsTestRecord'
import AddMonitoringVisitInvoiceRequestModel from '@/models/siteInvestigation/requests/AddMonitoringVisitInvoiceRequestModel'
import UpsertRepairEstimateRequestModel from '@/models/undergroundServices/UpsertRepairEstimateRequestModel'
import DeleteRepairEstimateModel from '@/models/undergroundServices/DeleteRepairEstimateModel'
import SIDashboardReportModel from '@/models/claim/SIDashboardReportModel'
import ReportBuilderDataModel from '@/models/siteInvestigation/ReportBuilderDataModel'

export default class SiteInvestigationController {
  public static async GetSitePackages(claimManagementCompanyId: number): Promise<SitePackage[]> {
    const res: AxiosResponse<SitePackage[]> = await CallCentreApi.get('SiteInvestigation/GetSitePackages', {
      params: { claimManagementCompanyId },
    })
    return res.data ? res.data : []
  }

  public static async GetProductsByPackage(packageId: number, productTypeId?: SIProductType): Promise<Product[]> {
    const params: any = { packageId }
    if (productTypeId) {
      params.productTypeId = productTypeId
    }
    const res: AxiosResponse<Product[]> = await CallCentreApi.get('SiteInvestigation/GetProductsByPackage', { params })
    return res.data ? res.data : []
  }

  public static async GetProducts(
    claimsManagementCompanyId: number,
    overrideParentLinks?: boolean,
    productTypeId?: SIProductType
  ): Promise<Product[]> {
    const params: any = { claimsManagementCompanyId }
    if (productTypeId) {
      params.productTypeId = productTypeId
    }
    if (overrideParentLinks) {
      params.overrideParentLinks = overrideParentLinks
    }
    const res: AxiosResponse<Product[]> = await CallCentreApi.get('SiteInvestigation/GetProducts', { params })
    return res.data ? res.data : []
  }

  public static async GetSubsidenceConsultants(): Promise<SubsidenceConsultantModel[]> {
    const res: AxiosResponse<SubsidenceConsultantModel[]> = await CallCentreApi.get<SubsidenceConsultantModel[]>(
      'SiteInvestigation/GetSubsidenceConsultants'
    )
    return res.data || []
  }

  public static async GetClaimManagementCompanies(): Promise<ClaimManagementCompanyModel[]> {
    const res: AxiosResponse<ClaimManagementCompanyModel[]> = await CallCentreApi.get<ClaimManagementCompanyModel[]>(
      'SiteInvestigation/GetClaimManagementCompanies'
    )
    return res.data || []
  }

  public static async GetSIJobs(
    recordCount: number,
    contractorId: string,
    status: string,
    claimManagementCompany: string,
    jobType: string,
    delayReason: string,
    postVisitOutstandingDocument: string
  ): Promise<SIDashboardReportModel | null> {
    const url = `SiteInvestigation/GetSIDashboardReports`
    const params: any = { recordCount, contractorId, status, claimManagementCompany, jobType, delayReason, postVisitOutstandingDocument }
    const response: AxiosResponse = await CallCentreApi.get(url, { params })

    return response.data || null
  }

  public static async GetSIJobDetail(jobId: string): Promise<SIReportModel[]> {
    const url = `SiteInvestigation/GetReportDocuments`
    const params: any = { jobId }
    const response: AxiosResponse = await CallCentreApi.get(url, { params })

    return response.data || []
  }

  public static async GetContractors(): Promise<ContractorModel[]> {
    const response: AxiosResponse<ContractorModel[]> = await CallCentreApi.get(`SiteInvestigation/GetContractors`)

    return response.data || []
  }

  public static async Enablement(req: ChangeSIEnablementModel): Promise<boolean> {
    const response: AxiosResponse = await CallCentreApi.post(`SiteInvestigation/Enablement`, req)

    return response.status >= 200 && response.status < 300
  }

  public static async SaveProductQA(model: AddEmergencyAnswer): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/AddProductAnswer', model)
    return res.data ? res.data.requestId : ''
  }

  public static async UpdateMonitoringDetails(monitoringDetail: MonitoringDetail): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/UpdateMonitoringDetails', monitoringDetail)
    return res.status >= 200 && res.status < 300
  }

  public static async UpsertMonitoringVisit(
    id: string,
    jobId: string,
    monitoringVisitLog: MonitoringVisitLog
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/UpsertMonitoringVisit?id=' + id + '&jobId=' + jobId,
      monitoringVisitLog
    )
    return res.status >= 200 && res.status < 300
  }

  public static async DeleteMonitoringVisit(id: string, jobId: string, visitId: number): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/DeleteMonitoringVisit?id=' + id + '&jobId=' + jobId + '&visitId=' + visitId
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateIVSDetails(model: IVSDetailModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/UpdateIVSDetails', model)
    return res.status >= 200 && res.status < 300
  }

  public static async GetReportBuilderSelectorData(jobId: string): Promise<ReportBuilderDataModel | null> {
    const res: AxiosResponse = await CallCentreApi.get('SiteInvestigation/GetReportBuilderSelectorData?jobId=' + jobId)
    return res.data || null
  }

  public static async UpsertSIJobDelay(model: UpsertSIJobDelay): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/UpsertJobDelay', model)
    return res.status >= 200 && res.status < 300
  }

  public static async UpsertSIVisitTrialPitBoreHoleLogs(addBoreHoleLog: AddBoreHoleLog): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/UpsertSIVisitTrialPitBoreHoleLogs',
      addBoreHoleLog
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateSIVisitTrialPit(updateTrialPitDetail: UpdateTrialPitDetail): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/UpdateSIVisitTrialPit', updateTrialPitDetail)
    return res.status >= 200 && res.status < 300
  }

  public static async GetCCTVLogConfiguration(): Promise<SICCTVLogConfiguration | null> {
    const res: AxiosResponse<SICCTVLogConfiguration | null> = await CallCentreApi.get(
      'SiteInvestigation/GetCCTVLogConfiguration'
    )
    return res.status === 200 && res.data ? res.data : null
  }

  public static async UpsertCCTVControlRunLogs(cctvControlRunLog: AddCCTVControlRunLogModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/UpsertCCTVControlRunLogs', cctvControlRunLog)
    return res.status >= 200 && res.status < 300
  }

  public static async AddUpdateCCTVControlLogs(cctvControlLog: CCTVControlLogModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/AddUpdateCCTVControlLogs', cctvControlLog)
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateDatumControlLog(model: UpdateDatumControlLog): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/UpdateDatumControlLog', model)
    return res.status >= 200 && res.status < 300
  }

  public static async GetBoreHoleLogConfiguration(): Promise<SIBoreHoleLogConfiguration | null> {
    const res: AxiosResponse<SIBoreHoleLogConfiguration | null> = await CallCentreApi.get(
      'SiteInvestigation/GetBoreHoleLogConfiguration'
    )
    return res.status >= 200 && res.status < 300 ? res.data : null
  }

  public static async AddAbandonedSIProductRequest(
    addAbandonedSIProductRequest: AddAbandonedSIProductRequest
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/AddAbandonedSIProductRequest',
      addAbandonedSIProductRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpdateWaterMainsTest(updateWaterMainsTest: UpdateWaterMainsTest): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/AddUpdateWaterMainsTest',
      updateWaterMainsTest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async DeleteWaterMainsTest(deleteWaterMainsTest: DeleteWaterMainsTestRecord): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/DeleteWaterMainsTest', deleteWaterMainsTest)
    return res.status >= 200 && res.status < 300
  }

  public static async AddSIProductVerificationRequest(
    addSIProductVerificationRequest: AddSIProductVerificationRequest
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/AddSIProductVerificationRequest',
      addSIProductVerificationRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UploadTrialPitDrawing(trialPitDrawing: AddTrialPitDrawingModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/UploadTrialPitDrawing', trialPitDrawing)
    return res.status >= 200 && res.status < 300
  }

  public static async AddSIRevisitRequest(model: AddSIRevisitRequest): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/AddSIRevisitRequest', model)
    return res.status >= 200 && res.status < 300
  }

  public static async DeleteCCTVControlRunLog(deleteCCTVControlRunLog: DeleteCCTVControlRunLogModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/DeleteCCTVControlRunLog',
      deleteCCTVControlRunLog
    )
    return res.status >= 200 && res.status < 300
  }

  public static async DeleteBoreholeLog(deleteBoreholeLog: DeleteBoreholeLogModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('SiteInvestigation/DeleteBoreholeLog', deleteBoreholeLog)
    return res.status >= 200 && res.status < 300
  }

  public static async SaveWaterMainsTestRecord(
    saveWaterOrCompleteMainTestRecord: CompleteWaterMainsTestRecord
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/SaveWaterMainsTestRecord',
      saveWaterOrCompleteMainTestRecord
    )
    return res.status >= 200 && res.status < 300
  }

  public static async MonitoringVisitInvoice(
    monitoringVisitInvoiceRequest: AddMonitoringVisitInvoiceRequestModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/AddMonitoringVisitInvoiceDetail',
      monitoringVisitInvoiceRequest
    )
    return res.status >= 200 && res.status < 300
  }

  public static async UpsertRepairEstimateRequestAsync(
    upsertRepairEstimateRequestModel: UpsertRepairEstimateRequestModel
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/UpsertRepairEstimateRequestAsync',
      upsertRepairEstimateRequestModel
    )
    return res.status >= 200 && res.status < 300
  }

  public static async DeleteRepairEstimateAsync(deleteRepairEstimate: DeleteRepairEstimateModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'SiteInvestigation/DeleteRepairEstimateAsync',
      deleteRepairEstimate
    )
    return res.status >= 200 && res.status < 300
  }
}
