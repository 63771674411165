import CosmosJobsDbRecordBase from '../CosmosJobsDbRecordBase'
import { SIJobProductProgress } from '@/common/enums'
import CCTVControlRunLog from './CCTVControlRunLogModel'

export default class CCTVControlLogModel extends CosmosJobsDbRecordBase {
  public forEngineerVisitDetailId: string
  public status: SIJobProductProgress | string
  public cctvControlRunLogs: CCTVControlRunLog[]
  public notes: string
  public abandonedReason: string
  public abandonedReasonNotes: string
  public isCCTVDrainageVerified: boolean
}
