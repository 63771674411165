import RepairEstimateProductModel from './RepairEstimateProductModel'

export default class UpsertRepairEstimateRequestModel {
  public id: string
  public jobId: string
  public products: RepairEstimateProductModel[]

  public constructor() {
    if (!this.products) {
      this.products = []
    }
  }
}
