<template>
  <div v-if="ready">
    <v-container fluid grid-list-md pa-0>
      <v-layout wrap calls-info>
        <DashboardTopTile
          v-for="key in Object.keys(topTiles)"
          :key="key"
          v-bind="topTiles[key]"
          dashboard-tile-view="ThirdPartyDashboard"
        />
      </v-layout>
      <v-layout wrap class="system-info syetsm-dashboard">
        <DashboardPanel v-for="key in Object.keys(panels)" :key="key" v-bind="panels[key]" flex-class="xs12 md6 lg3" />
      </v-layout>
      <v-layout wrap class="team-info mt-3">
        <DashboardTeam v-for="key in Object.keys(teams)" :key="key" :team="teams[key]" />
      </v-layout>
    </v-container>
  </div>
  <div v-else>
    <v-progress-circular
      class="loading-spinner"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import DashboardController, { DashboardInitialData } from '@/api/dashboardController'
import {
  UpdateSurge,
  DailyCallStats,
  TopLevelCallStats,
  HourlyCallStats,
  UpdateTwilioTopTilesCount,
  AddCallCount,
} from '@/signalr/twilioEventModels/events'
import Store from '@/store'
import { Worker, WorkerWithAdditions } from '@/components/dashboard/worker'
import Dashboard from '@/views/Dashboard.vue'
import TwilioController from '@/api/twilioController'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import { InsurerStats } from '@/signalr/twilioEventModels/dashboardState'
import {
  WorkerReservationEvents,
  WorkerActivityChanged,
  WorkerAttributesChanged,
  WorkerCreated,
  WorkerDeleted,
} from '@/signalr/twilioEventModels/workerEvent'
import { InsurerAnnouncingUpdate } from '@/signalr/twilioEventModels/insurerAnnouncingUpdate'
import { Team } from '@/components/dashboard/team'
import DashboardTeam from '@/components/dashboard/DashboardTeam.vue'
import DashboardPanel, { PanelProps } from '@/components/dashboard/DashboardPanel.vue'
import { ManagerSupervisorDashboardCount } from '@/models/twilio/ManagerSupervisorDashboardCount'
import SignalRHub from '@/components/SignalRHub.vue'
import storeGetters from '@/storeGetters'

@Component({
  components: { DashboardTopTile, DashboardTeam, DashboardPanel },
})
export default class ThirdPartyDashboard extends Dashboard {
  public workers: WorkerWithAdditions[] = storeGetters.getWorkers()
  public async created() {
    this.childPage = 'ThirdPartyDashboard'
  }

  public get teams(): { [key: string]: Team } {
    const value: { [key: string]: Team } = {}
    for (const worker of this.workers) {
      const teamName = worker.attributes.teamName
      let team: Team = value[teamName]

      if (team == null) {
        team = {
          title: teamName,
          workers: [],
        }
        value[teamName] = team
      }

      team.workers.push(worker)
    }

    return value
  }
}
</script>
