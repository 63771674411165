<template>
  <div>
    <v-layout row wrap align-center xs12 style="justify-content: flex-end">
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
        style="max-width: 300px"
      ></v-text-field>
    </v-layout>
    <v-data-table
      :headers="tableHeaders"
      class="elevation-1 gridView popup-table"
      :items="clientJobs"
      item-key="jobId"
      :search="search"
      :loading="loadingData"
    >
      <template slot="items" slot-scope="props">
        <td>
          <b>{{ props.item.jobId }}</b>
        </td>
        <td>{{ props.item.customerName }}</td>
        <td>{{ props.item.address }}</td>
        <td>
          <v-chip
            v-for="(emergency, index) in props.item.emergencies"
            :key="`${emergency.id}-${index}`"
            class="emergency-chip"
            small
            color="teal"
            text-color="white"
          >
            <v-avatar :size="26">
              <img v-if="emergency.iconUrl" :src="emergency.iconUrl" :alt="emergency.description" />
              <img v-else src="/img/emergency.svg" :alt="emergency.description" />
            </v-avatar>
            {{ emergency.description }}
          </v-chip>
        </td>
        <td>
          <v-chip
            v-for="(visit, index) in props.item.visits"
            :key="`${visit.id}-${index}`"
            small
            outline
            class="visit-chip"
          >
            <span @click="openContractorJobDetailModal(props.item.jobId, visit.contractorAppointedId)">
              {{ visit.description }}
            </span>
          </v-chip>
        </td>
        <td>
          <span>{{ formatDateRange(props.item.nextEta) }}</span>
        </td>
        <td>{{ props.item.status }}</td>
        <td>
          <v-btn class="white--text" color="primary" @click="openEventStream(props.item.jobId)">
            Show Event Stream
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <v-dialog
      v-if="showEventStream"
      v-model="showEventStream"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <JobAuditLog :job-id="jobId" :is-request-stream="true" @closeModal="showEventStream = false"></JobAuditLog>
    </v-dialog>
    <v-dialog
      v-if="showJobDetailModal"
      v-model="showJobDetailModal"
      max-width="1000"
      persistent
      content-class="v-dialog--scrollable contractorjob-details-dialog"
    >
      <ContractorJobDetail
        :contractor-job-detail-item="selectedContractorDetail"
        :show-engineer-details="false"
        :show-load-more="true"
        @contractorJobDetailDialogClose="closeContractorJobDetailModal"
      ></ContractorJobDetail>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import JobAuditLog from '@/components/JobAuditLog.vue'
import ContractorController from '@/api/contractorController'
import eventBus from '@/common/bus'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import ContractorJobDetail from '@/components/ContractorJobDetail.vue'
import ClientJobSummary from '@/models/client/enhanced-access/client-job-summary'
import EnhancedClientController from '@/api/enhancedClientController'
import DateRange from '@/models/general/date-range'

@Component({
  components: {
    JobAuditLog,
    ContractorJobDetail,
  },
})
export default class EnhancedAccessJobView extends Vue {
  private tableHeaders = [
    { text: 'Job Id', value: 'jobId', width: 150 },
    { text: 'Customer Name', value: 'customerName' },
    { text: 'Address', value: 'address', width: '200' },
    { text: 'Emergencies', value: 'trade' },
    { text: 'Visits', value: 'visitId' },
    { text: 'Next ETA', value: 'nextEta', width: '250' },
    { text: 'Status', value: 'status' },
    { text: '', value: '' },
  ]

  private loadingData = false
  private clientJobs: ClientJobSummary[] = []
  private showEventStream = false
  private jobId = ''
  private selectedContractorDetail: ContractorJobDetailModel | null = null
  private showJobDetailModal = false
  private search = ''

  public get selectedClientId(): number {
    return this.$store.getters['clientModule/selectedClientId']
  }

  @Watch('selectedClientId')
  private async onSelectedlientIdChange() {
    this.clientJobs = []
    if (!this.selectedClientId) {
      return
    }
    this.loadingData = true
    this.clientJobs = (await EnhancedClientController.GetJobs(this.selectedClientId)) || []
    this.loadingData = false
  }

  private async openEventStream(jobId: string) {
    this.$store.dispatch('jobRequestModule/submitRetrieveEnhancedJobRequests', jobId)
    this.jobId = jobId
    this.showEventStream = true
  }

  private openContractorJobDetailModal(jobId: string, contractorAppointedDetailId: string): void {
    this.showJobDetailModal = true
    ContractorController.GetContractorJobDetailWithEmergencyDefinition(jobId, contractorAppointedDetailId)
      .then((res: ContractorJobDetailModel | null) => {
        if (res) {
          this.selectedContractorDetail = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor jobDetail, please try again', false)
        this.showJobDetailModal = false
      })
  }

  private closeContractorJobDetailModal() {
    this.selectedContractorDetail = null
    this.showJobDetailModal = false
  }

  private formatDateRange(range: DateRange): string {
    if (!range) {
      return 'N/a'
    }
    const from = range.from && range.from.isValid() ? range.from.format('DD/MM/YYYY HH:mm') : '?'
    const to = range.to && range.to.isValid() ? range.to.format('HH:mm') : '?'
    return `${from}—${to}`
  }

  private created() {
    this.onSelectedlientIdChange()
  }
}
</script>

<style scoped></style>

<style lang="scss">
.visit-chip .v-chip__content {
  cursor: pointer;
}
</style>
