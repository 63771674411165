import { AxiosResponse } from 'axios'
import JobEscalationReasonModel from '@/models/claim/JobEscalationReasonModel'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'

export default class JobEscalationReasonController {
  public static async SaveJobEscalationReason(model: JobEscalationReasonModel): Promise<JobEscalationReasonModel> {
    const res = await CallCentreApi.post<JobEscalationReasonModel>('JobEscalationReason/SaveJobEscalationReason', model)
    return res.data
  }

  public static async GetJobEscalationReasons(): Promise<JobEscalationReasonModel[]> {
    const res = await CallCentreApi.get<JobEscalationReasonModel[]>('JobEscalationReason/GetJobEscalationReasons')
    return res.data ? res.data : []
  }

  public static async DeleteJobEscalationReason(recordId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(
      'JobEscalationReason/DeleteJobEscalationReason?recordId=' + recordId
    )
    return res.status >= 200 && res.status < 300
  }
}
