<template>
  <div>
    <div class="mb-2">
      <h4>{{ getRequestTitle }}</h4>
    </div>
    <div v-if="isDeleteComplaintDocument" class="mb-1">
      <label>Document Name: </label>
      <span>{{ getImageId(item.documentURL) }}</span>
    </div>
    <div v-for="fileURL in item.documentURLs" v-else :key="fileURL" class="mb-1">
      <label>Document Name: </label>
      <span>{{ getImageId(fileURL) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddComplaintDocumentsModel from '@/models/requests/AddComplaintDocumentsModel'
import DeleteComplaintDocumentModel from '@/models/requests/DeleteComplaintDocumentModel'
import { RequestType } from '@/common/enums'

@Component
export default class AddDeleteComplaintDocumentLog extends Vue {
  @Prop() private requestBody: any
  @Prop() private requestType: string

  private get item(): DeleteComplaintDocumentModel | AddComplaintDocumentsModel {
    return Object.assign({}, this.requestBody)
  }

  private get isDeleteComplaintDocument() {
    return this.requestType === RequestType[RequestType.DeleteComplaintDocument]
  }

  private get getRequestTitle() {
    return this.requestType === RequestType[RequestType.DeleteComplaintDocument]
      ? 'Delete Complaint Document'
      : this.requestType === RequestType[RequestType.AddComplaintDocuments]
      ? 'Add Complaint Documents'
      : ''
  }

  private getImageId(imageUrl: string) {
    return imageUrl.substring(imageUrl.lastIndexOf('/')).replace('/', '')
  }
}
</script>
