<template>
  <iframe title="Trial Pit Drawing Wrapper" :src="targetUrl" scrolling="no" :style="`height: ${frameHeight}px`" />
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'

const WindowRequests = {
  GetTrialPitJson: 'GetTrialPitJson',
  SetTrialPitJson: 'SetTrialPitJson',
  GetTrialPitImage: 'GetTrialPitImage',
}

interface ExpectedWindowResponse {
  type: 'SendTrialPitJson' | 'SendTrialPitImage' | 'TrialPitResize'
  data: string | number
}

export interface ITrialPitDrawing {
  getTrialPitJson: () => Promise<string>
  setTrialPitJson: (json: string) => void
  getTrialPitImage: () => Promise<string>
}

/**
 *  Component that wraps the Trial Pit Drawing site in an iFrame
 *  and provides access to the drawing tools functionality
 *
 *  USAGE: import TrialPitDrawingWrapper from this file, and the ITrialPitDrawing interface
 *  Reference the TrialPitDrawingWrapper element and cast to ITrialPitDrawing
 *  Call methods from there to interact with trial pit drawing control
 */
@Component({})
export default class TrialPitDrawingWrapper extends Vue implements ITrialPitDrawing {
  private targetUrl: string = Store.Instance.state.Environment.TrialPitDrawingWebsite

  private childWindow: Window | null = null
  private frameHeight = 200

  public async getTrialPitJson(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.childWindow === null) {
        throw new Error("Unable to call 'getTrialPitJson' as 'childWindow' is null")
      }

      // setup event listener for type we are expecting back
      window.addEventListener(
        'message',
        (ev) => {
          if (ev.origin === this.targetUrl) {
            const response: ExpectedWindowResponse = ev.data
            if (response.type === 'SendTrialPitJson') {
              resolve(response.data.toString())
            }
          }
        },
        {
          once: true,
        }
      )

      // call the sub window
      this.childWindow.postMessage({ type: WindowRequests.GetTrialPitJson }, this.targetUrl)

      // setup timeout to reject if not completed in waitTime seconds
      const waitTime = 5000
      setTimeout(() => {
        reject(`No response in ${waitTime / 1000} seconds`)
      }, waitTime)
    })
  }

  public setTrialPitJson(json: string) {
    if (this.childWindow === null) {
      throw new Error("Unable to call 'setTrialPitJson' as 'childWindow' is null")
    }

    // call the sub window
    this.childWindow.postMessage({ type: WindowRequests.SetTrialPitJson, data: json }, this.targetUrl)
  }

  public getTrialPitImage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.childWindow === null) {
        throw new Error("Unable to call 'getTrialPitImage' as 'childWindow' is null")
      }

      // setup event listener for type we are expecting back
      window.addEventListener(
        'message',
        (ev) => {
          if (ev.origin === this.targetUrl) {
            const response: ExpectedWindowResponse = ev.data
            if (response.type === 'SendTrialPitImage') {
              resolve(response.data.toString())
            }
          }
        },
        {
          once: true,
        }
      )

      // call the sub window
      this.childWindow.postMessage({ type: WindowRequests.GetTrialPitImage }, this.targetUrl)

      // setup timeout to reject if not completed in waitTime seconds
      const waitTime = 5000
      setTimeout(() => {
        reject(`No response in ${waitTime / 1000} seconds`)
      }, waitTime)
    })
  }

  public checkIsTrialPitDrawingLoaded(hasMinimumHeight = false) {
    return new Promise<boolean>((resolve, reject) => {
      window.addEventListener('message', (ev) => {
        if (ev.origin === this.targetUrl) {
          const response: ExpectedWindowResponse = ev.data
          if (response.type === 'TrialPitResize' && typeof response.data === 'number') {
            // verify if iframe is loaded or not
            if (hasMinimumHeight && response.data > 0) {
              resolve(true)
            }
            this.frameHeight = response.data
          }
        }
      })
    })
  }

  private mounted() {
    this.childWindow = (this.$el as HTMLIFrameElement).contentWindow
    this.checkIsTrialPitDrawingLoaded()
  }
}
</script>
