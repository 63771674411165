import { ContactPreference } from '@/models/types'

export default class UpdateCustomerContactPreferenceModal {
  public jobId: string
  public contactPreference: ContactPreference
  public contactPreferenceId: number | null
  public mobilePhone: string
  public email: string

  public nomineeClientTitle: string | null
  public nomineeClientForename: string | null
  public nomineeClientLastName: string | null
  public relation: string | null
  public nomineeContactPreference: ContactPreference
  public nomineeContactPreferenceId: number | null
  public nomineeMobilePhone: string | null
  public nomineeEmail: string | null

  public reasonForChoosingCallOption: string
  public nomineeReasonForChoosingCallOption: string | null

  public constructor() {
    this.email = ''
    this.mobilePhone = ''
    this.nomineeMobilePhone = ''
    this.nomineeEmail = ''
    this.contactPreferenceId = null
    this.contactPreference = null
    this.nomineeContactPreferenceId = null
    this.reasonForChoosingCallOption = ''
    this.nomineeReasonForChoosingCallOption = ''
  }
}
