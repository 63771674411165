<template>
  <div>
    <div class="mb-2"><h4>Customer to Pay</h4></div>
    <div v-if="item.cardHolderName" class="mb-1">
      <label>CardHolder Name: </label>
      <span>{{ item.cardHolderName }}</span>
    </div>
    <div v-if="item.addressLine1" class="mb-1">
      <label>Address Line1: </label>
      <span>{{ item.addressLine1 }}</span>
    </div>
    <div v-if="item.addressLine2" class="mb-1">
      <label>Address Line2: </label>
      <span>{{ item.addressLine2 }}</span>
    </div>
    <div v-if="item.city" class="mb-1">
      <label>City: </label>
      <span>{{ item.city }}</span>
    </div>
    <div v-if="item.state" class="mb-1">
      <label>State: </label>
      <span>{{ item.state }}</span>
    </div>
    <div v-if="item.country" class="mb-1">
      <label>Country: </label>
      <span>{{ item.country }}</span>
    </div>
    <div v-if="item.postcode" class="mb-1">
      <label>Post Code: </label>
      <span>{{ item.postcode }}</span>
    </div>
    <div v-if="item.amount" class="mb-1">
      <label>Amount: </label>
      <span>{{ item.amount }}</span>
    </div>
    <div v-if="item.statementDescription" class="mb-1">
      <label>Statement Description: </label>
      <span>{{ item.statementDescription }}</span>
    </div>
    <div v-if="item.cardBrand" class="mb-1">
      <label>Card Brand: </label>
      <span>{{ item.cardBrand }}</span>
    </div>
    <div v-if="item.cardLastFourDigit" class="mb-1">
      <label>Card Last 4 Digit: </label>
      <span>{{ item.cardLastFourDigit }}</span>
    </div>
    <div class="mb-1">
      <label>Customer Paid: </label>
      <span>{{ item.isTransactionCompleted ? 'Yes' : 'No' }}</span>
    </div>
    <div v-if="getPaymentDate" class="mb-1">
      <label>Payment Date: </label>
      <span>{{ getPaymentDate }}</span>
    </div>
    <div class="mb-1">
      <label>Email: </label>
      <span>{{ item.cardHolderEmail ? item.cardHolderEmail : '-' }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddCustomerToPay from '@/models/requests/AddCustomerToPay'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'

@Component
export default class AddCustomerToPayLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddCustomerToPay {
    return Object.assign(new AddCustomerToPay(), this.requestBody)
  }

  private get getPaymentDate(): string {
    if (this.item && this.item.transactionDate) {
      return Shared.getFormatedDate(moment(this.item.transactionDate), Store.Instance.state.Environment.DateFormat)
    } else {
      return '--/--/----'
    }
  }
}
</script>

<style scoped></style>
