<template>
  <v-card v-if="contractor" class="tradeAppointedPreview">
    <v-container
      v-if="getAssignedContractor && getAssignedContractor.status === jobVisitStatus[jobVisitStatus.AutoDeploying]"
    >
      <v-layout grey lighten-4 pa-2>
        <v-flex class="xs12">
          <v-layout wrap>
            <v-flex xs12>
              <div class="engineer-map grey lighten-5">
                <v-icon
                  v-show="!showFullScreenMap"
                  ref="fullScreenIcon"
                  class="fullScreenIcon"
                  :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
                  @click="toggleFullScreenMap"
                >
                  fullscreen
                </v-icon>
                <v-icon
                  v-show="showFullScreenMap"
                  ref="fullScreenIcon"
                  class="fullScreenIcon"
                  :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
                  @click="toggleFullScreenMap"
                >
                  fullscreen_exit
                </v-icon>
                <div id="customMap" ref="mapView"></div>
              </div>
              <div>
                <v-progress-linear
                  v-model="progressBarEngAssignValue"
                  :active="progressBarEngAssignVisibility"
                  :indeterminate="progressBarEngAssignIndeterminate"
                  :query="progressBarEngAssignQuery"
                  :color="progressBarEngAssignCurrentColor"
                ></v-progress-linear>
                <div class="my-2">
                  <b>{{ progressEngAssignInfoMessage }}</b>
                </div>
              </div>
              <v-snackbar
                v-model="autodeployRunningMessage"
                class="mt-5 pt-5 pl-5 ml-3 engineer-snackbar"
                :timeout="0"
                top
                color="error"
              >
                <b>
                  Auto Deploying {{ autoDeploymentRunningTo }}
                  <span class="dot-animation">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </b>
              </v-snackbar>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container v-else fluid grid-list-lg>
      <div v-if="getAssignedContractor && getAssignedContractor.callRecordingUrl && canPlayCall" class="text-xs-right">
        <v-btn @click.native="getRecordingUrl">
          <v-icon>play_circle_outline</v-icon>
          &nbsp;Play Call
        </v-btn>
      </div>
      <v-layout row map-main wrap>
        <v-flex md12 lg6 pl-0>
          <div class="card-image">
            <img :src="contractor.fileURL ? contractor.fileURL : '/img/unknownuser.png'" class="img-responsive" />
          </div>
        </v-flex>
        <v-flex md12 lg6 pr-0>
          <div class="card-stacked">
            <div class="map">
              <Map
                :map-id="new Date().getTime().toString()"
                :source-latitude="contractor.latitude"
                :source-longitude="contractor.longitude"
                :destination-latitude="jobLatitude"
                :destination-longitude="jobLongitude"
                :zoom-level="5.5"
                :coverage="contractor.coverage"
                :contractor-availability="contractor.contractorAvailability"
                :plot-contractor-open-job-addresses="contractorOpenJobAddresses"
                :show-marker="true"
                style="height: 180px"
              ></Map>
            </div>
          </div>
        </v-flex>
      </v-layout>
      <v-card-title class="pt-3 pb-1 px-0">
        <v-container fluid grid-list-md class="pa-0 mt-1">
          <v-layout row wrap>
            <v-flex xs12 md8 lg9 xl10 class="py-0">
              <h3 class="mb-0">{{ contractor.companyName }}</h3>
              <span class="ratings">
                <star-rating
                  :star-size="20"
                  :rating="contractor.rating"
                  :show-rating="false"
                  :read-only="true"
                  :increment="0.01"
                  active-color="orange"
                ></star-rating>
              </span>
              <div class="mt-1">
                <h5 class="pb-0">{{ contractor.description }}</h5>
              </div>
            </v-flex>
            <!-- set static image for contractor status -->
            <v-flex
              v-if="getPreferredContractor(contractor) && getPreferredContractor(contractor) !== 'None'"
              xs12
              md4
              lg3
              xl2
              text-xs-right
              pt-0
            >
              <img
                :src="'/img/' + getPreferredContractor(contractor) + '-Badge.png'"
                alt
                class="img-responsive badges-img"
              />
            </v-flex>
            <v-flex xs12 class="py-0">
              <v-divider xs12 class="mb-2"></v-divider>
              <div v-show="contractor.address" class="location-detail mb-2">
                <v-icon>location_on</v-icon>
                <span>{{ contractor.address }}</span>
              </div>
              <div v-if="timeSlots.length > 0" class="location-detail mb-2">
                <v-icon>access_time</v-icon>
                <span class="available-time">
                  Weekdays -
                  <template v-for="(item, index) in timeSlots">
                    <span v-if="item.slotId > 0" :key="item.slotId">
                      {{ new Date(item.startTime).getHours() }} to
                      {{ new Date(item.endTime).getHours() === 0 ? 24 : new Date(item.endTime).getHours()
                      }}{{ index === timeSlots.length - 1 ? '' : ',' }}&nbsp;
                    </span>
                  </template>
                </span>
              </div>
              <template v-if="contractor.contactNumbers">
                <div v-for="(contact, index) in contractor.contactNumbers" :key="index" class="location-detail mb-2">
                  <v-icon>local_phone</v-icon>
                  <span>
                    <span class="grey--text text--darken-2">
                      <a href="#" class="number" @click="onCallContractor(contact.phoneNumber, contractor.id)">
                        {{ contact.phoneNumber }}
                      </a>
                      <span class="caption grey--text">- {{ contact.label ? contact.label : 'Phone number' }}</span>
                    </span>
                  </span>
                </div>
              </template>
              <div v-if="contractor.email" class="location-detail mb-2">
                <v-icon>email</v-icon>
                <a v-if="contractor.email" class="available-time" :href="'mailto:' + contractor.email">
                  {{ contractor.email }}
                </a>
              </div>
              <div v-show="contractor.website" class="location-detail mb-2">
                <v-icon>public</v-icon>
                <span>
                  <a :href="contractor.website" target="_blank" title="Website" class="website">
                    {{ contractor.website }}
                  </a>
                </span>
              </div>
              <div
                v-show="todaySlots.length > 0 && !getAssignedContractor && !isThirdPartyContractor"
                class="location-detail"
              >
                <i>
                  <img src="/./img/user-time.svg" class="img-icon" />
                </i>
                <span v-if="isContractorAvailable()" class="green--text">
                  Contractor is working within customer availability.
                </span>
                <span v-else class="red--text">Contractor's working time is different from customer availability.</span>
              </div>
              <div v-if="emergency.engineerSiteSLA" class="grey lighten-4 pa-2 mt-2">
                <b class="error--text">SLA cut off time : {{ getSLACutOffTime }}</b>
              </div>
              <v-divider
                v-if="getAssignedContractor && getAssignedContractor.additionalTravelTime && !isThirdPartyContractor"
                xs12
              ></v-divider>
              <div
                v-if="getAssignedContractor && getAssignedContractor.additionalTravelTime && !isThirdPartyContractor"
                wrap
              >
                <v-flex xs12 py-1>
                  <b>Additonal Travel Time:</b>
                  <b class="secondary--text">
                    &nbsp;
                    {{ getFormatedTravelTime(getAssignedContractor.additionalTravelTime) }}
                    (&pound;{{ getAssignedContractor.additionalCharge }})
                  </b>
                </v-flex>
              </div>

              <div class="job-information pt-1">
                <v-divider xs12></v-divider>
                <v-container fluid class="grid-list-md px-0 py-2">
                  <v-layout wrap>
                    <v-flex xs12>
                      <h3 class="title">Work summary</h3>
                    </v-flex>
                    <v-flex xs6>
                      <div class="grey lighten-4 blue--text fill-height pa-2 job-info">
                        <b>Total Visits today</b>
                        <span class="count">
                          {{ contractor.totalVisitsToday }}
                        </span>
                      </div>
                    </v-flex>
                    <v-flex xs6>
                      <div class="grey lighten-4 green--text pa-2 job-info open-job">
                        <b>Total Open Jobs</b>
                        <span class="count">
                          <v-btn
                            v-if="getOpenJobs && getOpenJobs.length"
                            icon
                            flat
                            small
                            class="ma-0 map-btn"
                            @click="plotContractorOpenJobAddress(contractor.id)"
                          >
                            <v-icon small color="grey">place</v-icon>
                          </v-btn>
                          <v-menu left offset-y content-class="job-count-menu">
                            <template #activator="{ on }">
                              <span v-on="on">
                                <span>{{ totalOpenJobCount }}</span>
                                <v-icon v-if="getOpenJobs.length" class="md-icon">keyboard_arrow_down</v-icon>
                              </span>
                            </template>
                            <v-list>
                              <template v-if="getOpenJobs.length">
                                <v-list-tile v-for="(openJob, index) in getOpenJobs" :key="index">
                                  <v-list-tile-title class="body-1">
                                    <img
                                      :src="openJob.img ? openJob.img : '/img/emergency.svg'"
                                      class="emergancy-icon"
                                    />
                                    {{ openJob.key }} ({{ openJob.value }})
                                  </v-list-tile-title>
                                </v-list-tile>
                              </template>
                            </v-list>
                          </v-menu>
                        </span>
                      </div>
                    </v-flex>
                    <v-flex xs6>
                      <div class="grey lighten-4 deep-orange--text fill-height pa-2 job-info">
                        <b>Total engineers</b>
                        <span class="count">{{ totalEngineerCount }}</span>
                        <div v-if="getEngineers && getEngineers.length" class="grey--text mt-1">
                          <span v-if="getEngineers.length">
                            {{ emergencyEngineerType }} ({{ emergencyEngineerCount }})
                            <br />
                          </span>
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>

              <div v-if="isContractorHasAutoDeployment && !hideAutoDeploymentButton" wrap>
                <v-divider class="mt-3" xs12></v-divider>
                <v-flex xs12 pa-0 mt-2>
                  <span class="location-detail green--text">
                    <v-icon>settings</v-icon>
                    <b>
                      Ability to accept auto deployment of jobs
                      {{ autoDeploymentOnText(item.autoDeploymentType) }}
                    </b>
                  </span>
                </v-flex>
              </div>

              <v-divider
                v-if="
                  (!isThirdPartyContractor && isContractorUnavailable) || isContractorReAttend || isContractorCancelled
                "
                xs12
              ></v-divider>
              <div v-if="(!isThirdPartyContractor && isContractorUnavailable) || isContractorCancelled" wrap>
                <v-flex xs12>
                  <h3 class="mb-2">
                    {{ isContractorUnavailable ? 'Contractor Unavailable' : 'Contractor Rejected' }}
                  </h3>
                  <div class="mb-2">
                    <b>Reason:</b>
                    <br />
                    <span>{{ getAssignedContractor.reasonType }}</span>
                  </div>
                  <div v-if="getAssignedContractor.reasonDescription">
                    <b>Description:</b>
                    <br />
                    <span>{{ getAssignedContractor.reasonDescription }}</span>
                  </div>
                </v-flex>
              </div>
              <div v-else-if="!isThirdPartyContractor && isContractorReAttend" wrap>
                <v-flex xs12>
                  <h3 class="mb-2">Contractor Re-attend</h3>
                  <div>
                    <b>Reason:</b>
                    <br />
                    <span>{{ getAssignedContractor.reasonDescription }}</span>
                  </div>
                </v-flex>
              </div>

              <!-- Auto Deployment -->
              <div v-if="showAutoDeploymentButton" class="mt-3" wrap>
                <v-flex xs12 py-0 text-xs-right>
                  <span>
                    <v-btn
                      color="primary"
                      class="mr-0 auto-deployment-btn"
                      :disabled="manualAutoDeploymentRunning"
                      :loading="manualAutoDeploymentRunning"
                      @click="runManualAutoDeployment()"
                    >
                      Auto Deploy
                      {{ autoDeploymentOnText(item.autoDeploymentType) }}
                    </v-btn>
                  </span>
                </v-flex>
              </div>
              <!-- Call Contractor -->
              <div v-show="showCallContractorButton" :key="activeTileIndex" class="btn-block mt-3">
                <v-layout wrap>
                  <v-flex xs12 py-0 text-xs-right>
                    <v-menu
                      offset-y
                      left
                      :close-on-content-click="false"
                      :nudge-width="320"
                      :max-width="320"
                      :z-index="999"
                      fixed
                    >
                      <template #activator="{ on }">
                        <v-btn
                          color="primary"
                          class="callContractor-btn"
                          v-on="on"
                          @click="showAllContactNumber = true"
                        >
                          Call {{ item.companyName }}
                        </v-btn>
                      </template>
                      <v-card>
                        <div v-if="showAllContactNumber" class="view-panel contact-list">
                          <v-list-tile>
                            <v-list-tile-content>
                              <span class="name primary--text mb-2">
                                {{ contractor.companyName }}
                                <span class="grey--text">(Contractor)</span>
                              </span>
                              <template v-if="contractor.contactNumbers">
                                <div v-for="(contact, index) in contractor.contactNumbers" :key="index">
                                  <span class="grey--text text--darken-2">
                                    <a
                                      href="#"
                                      class="number"
                                      @click="onCallButtonClick(contact.phoneNumber, contractor.id)"
                                    >
                                      {{ contact.phoneNumber }}
                                    </a>
                                    <span class="caption grey--text">
                                      -
                                      {{ contact.label ? contact.label : 'Phone number' }}
                                    </span>
                                  </span>
                                </div>
                              </template>
                            </v-list-tile-content>
                          </v-list-tile>
                        </div>
                      </v-card>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </div>
              <div class="btn-block" wrap>
                <v-flex xs12 py-0 text-xs-right>
                  <!-- Assign job to third party contractor -->
                  <span v-show="isCallButtonClicked && isThirdPartyContractor">
                    <v-btn
                      color="primary"
                      class="mr-0 warmTransfer-btn"
                      :disabled="isLoading"
                      :loading="isLoading"
                      @click="assignContractor"
                    >
                      Warm Transfer Complete
                    </v-btn>
                  </span>
                  <!-- Assign job Contractor | Contractor Unavailable -->
                  <span
                    v-show="
                      (!isContractorAssigned && isCallButtonClicked && !isThirdPartyContractor) ||
                      (isCallButtonClicked &&
                        getAssignedContractor &&
                        getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Unavailable] &&
                        ((getAssignedContractor && !getAssignedContractor.isUnavailable) ||
                          getIsSkillLevelAboveRequired))
                    "
                  >
                    <v-btn color="primary" class="mr-0 assignContractor-btn" @click="assignContractor">
                      Assign to {{ item.companyName }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mr-0"
                      @click=";(actionDialog = true), (isUnavailableButtonClicked = true)"
                    >
                      {{ item.companyName }} unavailable
                    </v-btn>
                  </span>
                  <!-- Change ETA -->
                  <span
                    v-show="
                      !isThirdPartyContractor &&
                      isContractorAssigned &&
                      (getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Pending] ||
                        getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Accepted] ||
                        getAssignedContractor.status === jobVisitStatus[jobVisitStatus.InProgress])
                    "
                  >
                    <v-btn color="primary" class="mr-0 changeContractorETA-btn" @click="changeContractorETA">
                      Change {{ item.companyName }} ETA
                    </v-btn>
                  </span>
                  <!-- Re Attend Contractor -->
                  <span
                    v-show="
                      !isThirdPartyContractor &&
                      isContractorAssigned &&
                      !isContractorCancelled &&
                      !isContractorUnavailable &&
                      !isSIJob
                    "
                  >
                    <v-btn color="primary" class="mr-0 reAttendContractor-btn" @click="reAttendContractor">
                      Re-Attend {{ item.companyName }}
                    </v-btn>
                  </span>
                  <!-- Cancel Assigne contractor-->
                  <span
                    v-show="
                      !isThirdPartyContractor &&
                      !isContractorCancelled &&
                      !isContractorUnavailable &&
                      getAssignedContractor &&
                      getAssignedContractor.status !== jobVisitStatus[jobVisitStatus.InProgress] &&
                      getAssignedContractor.status !== jobVisitStatus[jobVisitStatus.Completed]
                    "
                  >
                    <v-btn
                      color="primary"
                      class="mr-0"
                      @click=";(actionDialog = true), (isCancelContractorButtonClicked = true)"
                    >
                      Cancel
                    </v-btn>
                  </span>
                  <!-- Clear ETA -->
                  <span
                    v-show="
                      !isThirdPartyContractor &&
                      isContractorAssigned &&
                      getAssignedContractor.visitNumber > 1 &&
                      (getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Pending] ||
                        getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Accepted] ||
                        getAssignedContractor.status === jobVisitStatus[jobVisitStatus.InProgress])
                    "
                  >
                    <v-btn color="primary" class="mr-0" @click="clearEtaConfirmation">Clear ETA</v-btn>
                  </span>
                  <!-- Contractor Assign Engineer-->
                  <span
                    v-if="
                      !isThirdPartyContractor &&
                      isContractorAssigned &&
                      getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Pending] &&
                      !isSIJob
                    "
                  >
                    <v-btn
                      color="primary"
                      class="mr-0"
                      :disabled="loaderOnGettingEngineerList"
                      :loading="loaderOnGettingEngineerList"
                      @click.stop="openEngineerAssignDialog"
                    >
                      Assign Engineer
                    </v-btn>
                  </span>
                </v-flex>
              </div>
              <v-dialog v-model="actionDialog" max-width="650" persistent content-class="v-dialog--scrollable">
                <v-card>
                  <v-toolbar card dark color="primary">
                    <v-toolbar-title v-if="isAssignButtonClicked">Set ETA</v-toolbar-title>
                    <v-toolbar-title v-else-if="isChangeETAButtonClicked">Change ETA</v-toolbar-title>
                    <v-toolbar-title v-else-if="isUnavailableButtonClicked">Contractor Unavailable</v-toolbar-title>
                    <v-toolbar-title v-else-if="isReattendButtonClicked">Re-attend</v-toolbar-title>
                    <v-toolbar-title v-else-if="isCancelContractorButtonClicked">Cancel Contractor</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text class="scroll-content-dialog px-4 pt-4">
                    <v-layout v-if="isAssignButtonClicked || isChangeETAButtonClicked || isReattendButtonClicked" wrap>
                      <v-flex xs6 pr-2>
                        <DateTimePicker
                          ref="ETAfromdateTimePicker"
                          :date-time.sync="contractorAppointed.etaFrom"
                          :is-static-location="false"
                          place-holder-text="ETA From"
                          :min-date="minETADate"
                          :min-time="minETATime"
                          :is-validation-required="true"
                          :allowed-dates="getDatesFromCustomerAvailability"
                          :allowed-minutes-step="true"
                        />
                      </v-flex>
                      <v-flex xs6 pl-2>
                        <DateTimePicker
                          ref="ETATodateTimePicker"
                          :date-time.sync="contractorAppointed.etaTo"
                          :is-static-location="false"
                          place-holder-text="ETA To"
                          :min-date="minETAToDate"
                          :min-time="minETAToTime"
                          :max-date="maxETAToDate"
                          :max-time="maxETAToTime"
                          :is-validation-required="true"
                          :allowed-dates="getDatesFromCustomerAvailability"
                          :allowed-minutes-step="true"
                        />
                      </v-flex>
                      <v-flex v-if="isWrongDateRange || isWrongTimeRange" xs12 mb-2>
                        <span v-if="isWrongDateRange" class="error--text">To date must be greater than From date.</span>
                        <span v-if="isWrongTimeRange" class="error--text">
                          Customer is not available within contractor ETA.
                        </span>
                      </v-flex>
                      <v-flex v-if="!availabilityOutsideSLA" xs12 mb-2>
                        <span class="warning--text">You are not allowed to deploy outside the SLA cut off time.</span>
                      </v-flex>
                      <v-flex v-if="isSIJob && isAssignButtonClicked" xs12 pr-0 pt-0>
                        <v-autocomplete
                          v-model="selectedEngineer"
                          v-validate="'required'"
                          :items="engineerList"
                          label="Select Engineer"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ maxHeight: 'auto' }"
                          chips
                          required
                          class="required"
                          data-vv-scope="select-contractor-engineer"
                          data-vv-name="Select Engineer"
                          :error-messages="errors.collect('Select Engineer')"
                        >
                          <template slot="selection" slot-scope="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              :selected="data.selected"
                              class="chip--select-multi"
                            >
                              <v-avatar>
                                <img
                                  v-if="data.item.profileImageThumbnailUrl"
                                  alt=""
                                  :src="data.item.profileImageThumbnailUrl"
                                />
                                <img v-else alt="" src="/img/unknownuser.png" />
                              </v-avatar>
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template slot="item" slot-scope="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-tile-content v-text="data.name"></v-list-tile-content>
                            </template>
                            <template v-else>
                              <v-list-tile-avatar>
                                <img
                                  v-if="data.item.profileImageThumbnailUrl"
                                  alt=""
                                  :src="data.item.profileImageThumbnailUrl"
                                />
                                <img v-else alt="" src="/img/unknownuser.png" />
                              </v-list-tile-avatar>
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  {{ data.item.name }}
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                      <v-flex v-if="contractor" xs6 class="pr-1">
                        <v-select
                          v-model="travelTimeHour"
                          :items="travelTimeHourList"
                          label="Travel Time (Hours)"
                          item-text="text"
                          item-value="value"
                          class="travelTimeHour"
                        ></v-select>
                      </v-flex>
                      <v-flex v-if="contractor" xs6 class="pl-1">
                        <v-select
                          v-model="travelTimeMinute"
                          :items="travelTimeMinuteList"
                          label="Travel Time (Minutes)"
                          item-text="text"
                          item-value="value"
                          class="travelTimeMinute"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 grey lighten-3>
                        <DelayCodes
                          :has-delay="contractorAppointed.hasDelay"
                          :delay-code="contractorAppointed.delayCode"
                          :availability-outside-s-l-a="availabilityOutsideSLA"
                          @updateDelayCode="onDelayCodeChange"
                        ></DelayCodes>
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="isReattendButtonClicked" wrap>
                      <v-flex xs12>
                        <v-textarea
                          v-model="contractorAppointed.reasonDescription"
                          v-validate="'required'"
                          label="Reason"
                          required
                          class="required"
                          data-vv-scope="formReference"
                          data-vv-name="Reattend reason"
                          :error-messages="errors.collect('Reattend reason')"
                          rows="5"
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="isUnavailableButtonClicked || isCancelContractorButtonClicked" wrap>
                      <v-flex xs12>
                        <v-select
                          v-model="contractorAppointed.reasonType"
                          v-validate="'required'"
                          :items="contractorUnavailableReasons"
                          label="Reason"
                          item-value="description"
                          item-text="description"
                          required
                          class="required"
                          data-vv-scope="formReference"
                          data-vv-name="Description"
                          :error-messages="errors.collect('Description')"
                          @change="onReasonChange($event, isUnavailableButtonClicked)"
                        >
                          <template slot="item" slot-scope="data">
                            <v-list-tile-content>
                              <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                                {{ data.item.description }}
                              </v-list-tile-title>
                            </v-list-tile-content>
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex xs12>
                        <v-textarea
                          v-model="contractorAppointed.reasonDescription"
                          label="Description"
                          rows="5"
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="px-4">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat="flat" @click.native="closeDialog">Close</v-btn>
                    <v-btn
                      color="primary"
                      class="mr-0 assignedContactor-btn"
                      :disabled="isLoading"
                      :loading="isLoading"
                      @click="assignedContractorToJob"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-if="configureUnavailableReasonDialog"
                v-model="configureUnavailableReasonDialog"
                max-width="800"
                persistent
                content-class="v-dialog--scrollable"
              >
                <ConfigureUnavailableReason
                  :record-type="recordType[recordType.ContractorUnavailableReason]"
                  @CloseConfigureUnavailableReasonDialog="onConfigureUnavailableReasonDialogClose"
                ></ConfigureUnavailableReason>
              </v-dialog>
              <!-- Assign Engineer dialog -->
              <v-dialog
                v-if="openAssignEngineerDialog"
                v-model="openAssignEngineerDialog"
                max-width="450"
                persistent
                content-class="v-dialog--scrollable"
              >
                <v-card>
                  <v-toolbar card dark color="primary">
                    <v-toolbar-title>Assign Engineer</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openAssignEngineerDialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text class="scroll-content-dialog px-4 pt-2">
                    <v-layout wrap>
                      <v-flex xs12 pr-2>
                        <v-autocomplete
                          v-model="selectedEngineer"
                          v-validate="'required'"
                          :items="engineerList"
                          label="Select Engineer"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ maxHeight: 'auto' }"
                          chips
                          required
                          class="required"
                          data-vv-scope="selectedEngineer"
                          data-vv-name="Select Engineer"
                          :error-messages="errors.collect('Select Engineer')"
                        >
                          <template slot="selection" slot-scope="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              :selected="data.selected"
                              class="chip--select-multi"
                            >
                              <v-avatar>
                                <img
                                  v-if="data.item.profileImageThumbnailUrl"
                                  :src="data.item.profileImageThumbnailUrl"
                                />
                                <img v-else src="/img/unknownuser.png" />
                              </v-avatar>
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template slot="item" slot-scope="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-tile-content v-text="data.name"></v-list-tile-content>
                            </template>
                            <template v-else>
                              <v-list-tile-avatar>
                                <img
                                  v-if="data.item.profileImageThumbnailUrl"
                                  :src="data.item.profileImageThumbnailUrl"
                                />
                                <img v-else src="/img/unknownuser.png" />
                              </v-list-tile-avatar>
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  {{ data.item.name }}
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="px-4">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat="flat" @click.native="openAssignEngineerDialog = false">Close</v-btn>
                    <v-btn
                      color="primary"
                      class="mr-0"
                      :disabled="loaderOnAssignEngineer"
                      :loading="loaderOnAssignEngineer"
                      @click.native="assignEngineerToEmergency"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
            <v-snackbar v-if="saveSnackbarText" v-model="saveSnackbar" :timeout="3000" :bottom="true" :left="true">
              {{ saveSnackbarText }}
              <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
            </v-snackbar>
          </v-layout>
        </v-container>
      </v-card-title>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment, { Moment, isMoment } from 'moment'
import Shared from '@/common/shared'
import StarRating from 'vue-star-rating'
import Map from '@/components/Map.vue'
import storeGetters from '@/storeGetters'
import ContractorModel from '@/models/contractor/ContractorModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import AddContractorAppointedModel from '@/models/requests/AddContractorAppointedModel'
import DelayController from '@/api/delayController'
import JobController from '@/api/jobController'
import DelayResult from '@/models/delay/DelayResult'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import TimeSlot from '@/models/claim/TimeSlot'
import { JobVisitStatus, RecordType, EngineerJobVisitStatus, AutoDeploymentTypeEnum } from '@/common/enums'
import store from '@/store'
import eventBus from '@/common/bus'
import Emergency from '@/models/Emergency'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import CallParameters from '@/models/twilio/CallParameters'
import DelayCodes from '@/components/timeline/DelayCodes.vue'
import AddThirdPartyContractorDetail from '@/models/requests/AddThirdPartyContractorDetail'
import ContractorController from '@/api/contractorController'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import storeMutations from '@/storeMutations'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import EngineerController from '@/api/engineerController'
import UpdateContractorAppointedDetail from '@/models/requests/UpdateContractorAppointedDetail'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'
import CancelAssignedJobContractorModel from '@/models/requests/CancelAssignedJobContractorModel'
import ConfigureUnavailableReason from '@/components/ConfigureUnavailableReason.vue'
import ContractorUnavailableReasonModel from '@/models/contractor/ContractorUnavailableReasonModel'
import ContractorUnavailableReasonController from '@/api/ContractorUnavailableReasonController'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import AutoDeploymentEngineer from '../../models/claim/AutoDeploymentEngineer'
declare let google: any
import Job from '@/models/Job'
import DocumentController from '@/api/documentController'
import { TimeSpanSlots } from '@/common/interfaces'
import JobNoteModel from '@/models/claim/JobNoteModel'
import AddNewJobNote from '@/models/requests/AddNewJobNote'

@Component({
  components: {
    StarRating,
    Map,
    DateTimePicker,
    DelayCodes,
    ConfigureUnavailableReason,
  },
})
export default class CallContractorDetailView extends Vue {
  @Prop() private item: ContractorModel | null
  @Prop() private bindFromProp: boolean
  @Prop() private emergency: Emergency
  @Prop() private jobId: string
  @Prop() private assignedContractors: ContractorAppointedModel[]
  @Prop() private activeTileIndex: number
  @Prop() private jobLatitude: number
  @Prop() private jobLongitude: number
  @Prop() private isThirdPartyContractor: boolean
  @Prop() private engineerVisitDetail: EngineerVisitDetail
  @Prop() private jobAddress: string
  @Prop({ default: false })
  private isContractorSelectedViaSearchResult: boolean
  @Prop({ default: false }) private isUnAssignedContractor: boolean
  private contractorAppointed: AddContractorAppointedModel = new AddContractorAppointedModel()
  private minETADate = ''
  private minETAToDate = ''
  private minETAToTime = ''
  private etaFromOldValue: moment.Moment | null = null
  private etaToOldValue: moment.Moment | null = null
  private isLoading = false
  private actionDialog = false
  private showAllContactNumber = false
  private isCallButtonClicked = false
  private isAssignButtonClicked = false
  private isUnavailableButtonClicked = false
  private isReattendButtonClicked = false
  private isChangeETAButtonClicked = false
  private isCancelContractorButtonClicked = false
  private isNewContractorAssigned = false
  private jobVisitStatus = JobVisitStatus
  private recordType = RecordType
  private todayDate: Date = new Date()
  private contractorOpenJobAddresses: ContractorAppointedModel[] = []
  private totalOpenJobs: any = []
  private totalOpenJobCount = 0
  private totalEngineers: any = []
  private totalEngineerCount = 0
  private trades: TradeModel[] = []
  private emergencyEngineerType = ''
  private emergencyEngineerCount = 0
  private isWrongDateRange = false
  private engineerJobVisitStatus = EngineerJobVisitStatus
  private openAssignEngineerDialog = false
  private engineerList: any[] = []
  private selectedEngineer: string | null = ''
  private minFirstAssignedAtDate = ''
  private configureUnavailableReasonDialog = false
  private contractorUnavailableReasons: ContractorUnavailableReasonModel[] = []
  private saveSnackbar = false
  private saveSnackbarText = ''
  private loaderOnGettingEngineerList = false
  private loaderOnAssignEngineer = false
  private isWrongTimeRange = false
  private minutesInterval = 15

  private progressBarEngAssignValue = 0
  private resultMap: any = null

  private progressBarEngAssignInterval
  private progressBarEngAssignVisibility = true
  private progressBarEngAssignQuery = false
  private progressBarEngAssignIndeterminate = false
  private progressEngAssignInfoMessage = ''
  private progressBarEngAssignCurrentColor = 'green'
  private autodeployRunningMessage = true
  private manualAutoDeploymentRunning = false
  private showFullScreenMap = false
  private progressSlideInterval = 1

  private availabilityOutsideSLA = false
  private minETATime = ''
  private maxETAToDate = ''
  private maxETAToTime = ''
  private tempMaxTime = ''
  // this hub require to get engineer data when engineer availability changed from App
  private engineerAvailabilityHub: SignalRHubConnection = new SignalRHubConnection('engineerAvailabilityHub')
  private skillNeededToDeployAJob = 5
  private isContractorHasAutoDeployment = false
  private autoDeploymentRunningTo = ''
  private isAppointContractorDialogOpen = false
  private travelTimeHourList: TimeSpanSlots[] = Shared.getTimeSpanSlotsList(9, 1)
  private travelTimeMinuteList: TimeSpanSlots[] = Shared.getTimeSpanSlotsList(4, 15)
  private travelTimeHour: number = this.travelTimeHourList[0].value
  private travelTimeMinute: number = this.travelTimeMinuteList[0].value

  private userName = `${store.Instance.state.SessionDetail.detailRecord.UserDetail.firstName} ${store.Instance.state.SessionDetail.detailRecord.UserDetail.lastName}`

  public stopAutoDeploymentLoader() {
    this.manualAutoDeploymentRunning = false
  }

  private async created() {
    if (
      !(
        this.getAssignedContractor &&
        this.getAssignedContractor.status === this.jobVisitStatus[this.jobVisitStatus.AutoDeploying]
      )
    ) {
      const self = this
      self.minETADate = moment().format(DateTimePicker.DATE_FORMAT)
      if (!this.isContractorCancelled) {
        this.GetContractorUnavailableReasons()
      }
      this.setETA()
      if (this.emergencyTypes.length === 0) {
        this.setEmergencies()
      }
      this.getTradeList()
      this.minFirstAssignedAtDate = moment().format(DateTimePicker.DATE_FORMAT)
      this.engineerAvailabilityHub.addHandler(
        'availabilityChanged',
        async (engineerId: string, outsideFromDate: moment.Moment, outsideToDate: moment.Moment) => {
          if (this.engineerList.length === 0) {
            return // message not processed, nothing to update
          }
          // refresh item
          if (engineerId) {
            const engineer: EngineerModel | undefined = this.engineerList.find((e) => e.id === engineerId)
            if (engineer && this.getAssignedContractor) {
              if (outsideFromDate && outsideFromDate !== null) {
                engineer.outsideFromDate = moment(outsideFromDate)
              }
              if (outsideToDate && outsideToDate !== null) {
                engineer.outsideToDate = moment(outsideToDate)
              }
              const isEngineerAvailable = this.checkEngineerAvailability(
                this.getAssignedContractor.etaFrom,
                this.getAssignedContractor.etaTo,
                engineer
              )
              ;(engineer as any).disabled = !isEngineerAvailable // set disabled property based on engineer availability
              this.engineerList = [...this.engineerList]
            }
          }
        }
      )
      this.engineerAvailabilityHub.connect()
    }
  }

  private mounted() {
    this.minFirstAssignedAtDate = moment().format(DateTimePicker.DATE_FORMAT)
    if (
      this.getAssignedContractor &&
      this.getAssignedContractor.status === this.jobVisitStatus[this.jobVisitStatus.AutoDeploying]
    ) {
      this.initMap()
      if (this.getAssignedContractor.contractorAutoDeploymentExpiredAt) {
        this.autoDeploymentRunningTo = 'To Contractor'
        this.queryEngineerasssign(
          this.getAssignedContractor.companyName,
          this.getPassedSeconds(
            this.getAssignedContractor.createdAt,
            this.getAssignedContractor.contractorAutoDeploymentExpiredAt
          )
        )
      }
    } else {
      // verify contractor's autodeployment status
      if (this.item) {
        this.hasAutoDeploymentOn(this.item)
      }
    }

    if (this.isSIJob) {
      this.isCallButtonClicked = true
      if (this.contractor && this.contractor.id) {
        this.GetContractorEngineers(this.contractor.id)
      }
    }
  }

  private initMap() {
    this.resultMap = new google.maps.Map(document.getElementById('customMap'), {
      center: new google.maps.LatLng(51.509865, -0.118092),
      zoom: 8,
    })

    const icons = {
      currentEngineer: { icon: '/img/engineerAutoDeployRunning.png' },
      upComingEngineer: { icon: '/img/engineerAutoDeployUpComing.png' },
      previousEngineer: { icon: '/img/engineerAutoDeployCompleted.png' },
      jobAddressIcon: { icon: '/img/map-marker-red.png' },
    }

    const bound = new google.maps.LatLngBounds()

    const markers: any = []

    // create job marker
    let markerObj = {
      position: new google.maps.LatLng(this.jobLatitude, this.jobLongitude),
      type: 'jobAddressIcon',
      infoWindowContent: this.jobAddress,
    }
    markers.push(markerObj)

    // create marker for active engineer
    if (this.getAssignedContractor && this.getAssignedContractor.activeJobEngineer) {
      markerObj = {
        position: new google.maps.LatLng(
          this.getAssignedContractor.activeJobEngineer.latitude,
          this.getAssignedContractor.activeJobEngineer.longitude
        ),
        type: 'currentEngineer',
        infoWindowContent: this.getAssignedContractor.activeJobEngineer.engineerName,
      }
      markers.push(markerObj)
    }

    // create marker for connection to upcoming engineer(s)
    if (
      this.getAssignedContractor &&
      this.getAssignedContractor.jobOfferedToEngineersInFuture &&
      this.getAssignedContractor.jobOfferedToEngineersInFuture.length > 0
    ) {
      this.getAssignedContractor.jobOfferedToEngineersInFuture.forEach((engineer) => {
        markerObj = {
          position: new google.maps.LatLng(engineer.latitude, engineer.longitude),
          type: 'upComingEngineer',
          infoWindowContent: engineer.engineerName,
        }
        markers.push(markerObj)
      })
    }

    // create marker previously connected engineer(s)
    if (
      this.getAssignedContractor &&
      this.getAssignedContractor.jobExpiredForEngineers &&
      this.getAssignedContractor.jobExpiredForEngineers.length > 0
    ) {
      this.getAssignedContractor.jobExpiredForEngineers.forEach((engineer) => {
        markerObj = {
          position: new google.maps.LatLng(engineer.latitude, engineer.longitude),
          type: 'previousEngineer',
          infoWindowContent: engineer.engineerName,
        }
        markers.push(markerObj)
      })
    }

    // create markers
    for (const marker of markers) {
      this.createMarker(marker.position, icons[marker.type].icon, marker.infoWindowContent)
      bound.extend(marker.position)
    }

    this.resultMap.setCenter(bound.getCenter())
    this.resultMap.fitBounds(bound)

    setTimeout(() => {
      if (this.getAssignedContractor && this.getAssignedContractor.activeJobEngineer) {
        const flightPlanCoordinates = [
          { lat: this.jobLatitude, lng: this.jobLongitude },
          {
            lat: this.getAssignedContractor.activeJobEngineer.latitude,
            lng: this.getAssignedContractor.activeJobEngineer.longitude,
          },
        ]

        const lineArrowSymbol = {
          path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          strokeOpacity: 1,
          scale: 3,
        }
        const lineSymbol = {
          path: 'M 0,-0.5 0,0.5',
          strokeOpacity: 1,
          strokeWeight: 3,
          scale: 4,
        }

        const navlinks: any = document.querySelectorAll('img[src="/img/map-marker-red.png"]')
        navlinks[1].parentNode.classList.add('CustomMapMarker')

        // create line between job and engineer location
        const flightPath = new google.maps.Polyline({
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: '#009688', // primary color
          strokeOpacity: 0,
          strokeWeight: 2,
          icons: [
            {
              icon: lineArrowSymbol,
              offset: '100%',
            },
            {
              icon: lineSymbol,
              offset: '0',
              repeat: '15px',
            },
          ],
        })

        this.animate(flightPath)

        flightPath.setMap(this.resultMap)

        this.setEngineerDetails(this.getAssignedContractor.activeJobEngineer)
      }
    }, 1000)
  }

  private animate(line) {
    let count = 0
    window.setInterval(() => {
      count = (count + 1) % 200
      const icons = line.get('icons')
      icons[0].offset = count / 2 + '%'
      line.set('icons', icons)
    }, 100)
  }

  private getPassedSeconds(offeredAt: moment.Moment, expiredAt: moment.Moment) {
    // set progress bar for engineer update
    offeredAt = moment(offeredAt)
    expiredAt = moment(expiredAt)
    const processDuration = moment.duration(expiredAt.diff(offeredAt))
    const seconds = processDuration.asSeconds()
    this.progressSlideInterval = 100 / seconds
    const now = moment(new Date())
    const duration = moment.duration(now.diff(offeredAt))
    const passedSeconds = duration.asSeconds()
    clearInterval(this.progressBarEngAssignInterval)
    return passedSeconds
  }

  private setEngineerDetails(activeJobEngineer: AutoDeploymentEngineer) {
    this.autoDeploymentRunningTo = 'To Engineer'
    this.queryEngineerasssign(
      activeJobEngineer.engineerName,
      this.getPassedSeconds(activeJobEngineer.offeredAt, activeJobEngineer.expiredAt)
    )
  }

  private createMarker(markerPosition: any, markerIcon: any, infoWindowContent: string) {
    const marker = new google.maps.Marker({
      position: markerPosition,
      icon: markerIcon,
      map: this.resultMap,
      animation: google.maps.Animation.DROP,
    })

    const infowindow = new google.maps.InfoWindow({
      content: infoWindowContent,
    })

    google.maps.event.addListener(marker, 'click', () => {
      infowindow.open(this.resultMap, marker)
    })
    return marker
  }

  private queryEngineerasssign(engineerName: string | null, passedSeconds: number) {
    if (engineerName === null) {
      return
    }
    // check offeredAt and expiredAt of AutoDeploymentEngineer
    this.progressBarEngAssignIndeterminate = true
    this.progressBarEngAssignVisibility = true
    this.progressBarEngAssignValue = passedSeconds
    this.progressBarEngAssignIndeterminate = false
    this.progressBarEngAssignInterval = setInterval(() => {
      if (this.progressBarEngAssignValue >= 100) {
        clearInterval(this.progressBarEngAssignInterval)
        this.progressBarEngAssignVisibility = false
        this.progressEngAssignInfoMessage = 'Auto deployment finished...!'
      }
      this.progressBarEngAssignValue += this.progressSlideInterval
      this.progressEngAssignInfoMessage = 'Waiting for ' + engineerName + ' to accept the emergency.'
    }, 1000)
  }

  private get contractor(): ContractorModel | null {
    if (this.bindFromProp) {
      return this.item
    } else {
      return new ContractorModel()
    }
  }

  public get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  public get isSIJob(): boolean {
    return this.job!.jobType === 'SI' || this.job!.jobType === 'US'
  }

  private get isContractorAssigned(): boolean {
    return this.getAssignedContractor &&
      this.contractor &&
      this.contractor.id === this.getAssignedContractor.contractorId
      ? true
      : false
  }

  private get isContractorUnavailable(): boolean {
    return this.getAssignedContractor &&
      this.getAssignedContractor.status === JobVisitStatus[JobVisitStatus.Unavailable]
      ? true
      : false
  }
  private get isContractorCancelled(): boolean {
    return this.getAssignedContractor && this.getAssignedContractor.status === JobVisitStatus[JobVisitStatus.Cancelled]
      ? true
      : false
  }

  private get isContractorReAttend(): boolean {
    return this.getAssignedContractor &&
      this.getAssignedContractor.isContractorReAttend &&
      this.getAssignedContractor.reasonDescription
      ? true
      : false
  }

  private get getAssignedContractor(): ContractorAppointedModel | undefined {
    const assignedContractor = this.assignedContractors.find(
      (c) => c.contractorId === (this.item ? this.item.id : null)
    )
    return this.job!.jobType === 'US'
      ? this.isUnAssignedContractor
        ? undefined
        : assignedContractor
      : assignedContractor
  }

  private get timeSlots(): TimeSlot[] {
    if (this.contractor && this.contractor.contractorAvailability) {
      const availability: ContractorTradeAvailabilityModel | undefined = this.contractor.contractorAvailability.find(
        (e: ContractorTradeAvailabilityModel) => e.tradeId === this.emergency.tradeId
      )
      if (availability && availability.timeSlot && availability.timeSlot.length > 0) {
        return this.getAvailability(availability.timeSlot)
      } else {
        return []
      }
    }
    return []
  }

  private get getDatesFromCustomerAvailability(): string[] {
    const dateFormat: string = Shared.dateFormatForDatePicker
    const allowedDates: string[] = []
    const timeSlots = this.customerAvailability.timeSlot || []
    if (timeSlots.length > 0) {
      const firstDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 1)
      if (firstDateTimeSlots.length > 0) {
        Shared.SortData(firstDateTimeSlots, 'startTime')
        allowedDates.push(Shared.getFormatedDate(moment(firstDateTimeSlots.map((c) => c.startTime)[0]), dateFormat))
      }
      const secondDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 2)
      if (secondDateTimeSlots.length > 0) {
        Shared.SortData(secondDateTimeSlots, 'startTime')
        allowedDates.push(Shared.getFormatedDate(moment(secondDateTimeSlots.map((c) => c.startTime)[0]), dateFormat))
      }
      const thirdDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 3)
      if (thirdDateTimeSlots.length > 0) {
        Shared.SortData(thirdDateTimeSlots, 'startTime')
        allowedDates.push(Shared.getFormatedDate(moment(thirdDateTimeSlots.map((c) => c.startTime)[0]), dateFormat))
      }
      return allowedDates
    }
    return []
  }

  private getAvailability(timeSlots: TimeSlot[]): TimeSlot[] {
    const availability: TimeSlot[] = []
    let isNewSlot = true
    let item: TimeSlot | null = null
    let slotId = 0
    let availableSlots: TimeSlot[] = []
    availableSlots = Object.assign([], availableSlots, timeSlots)
    availableSlots.forEach((slot: TimeSlot) => {
      if (isNewSlot) {
        item = new TimeSlot()
        this.setTimeSlot(item, slot)
        availability.push(item)
        isNewSlot = false
        slotId = item.slotId
      } else {
        if (item) {
          if (slot.slotId - slotId === 1) {
            item.endTime = slot.endTime
            slotId = slot.slotId
          } else {
            item = new TimeSlot()
            this.setTimeSlot(item, slot)
            availability.push(item)
            slotId = slot.slotId
          }
        }
      }
    })

    return availability
  }

  private setTimeSlot(newSlot: TimeSlot, existingSlot: TimeSlot) {
    newSlot.slotId = existingSlot.slotId
    newSlot.slot = existingSlot.slot
    newSlot.isDayTime = existingSlot.isDayTime
    newSlot.startTime = existingSlot.startTime
    newSlot.endTime = existingSlot.endTime
  }

  private setETA() {
    this.setNearestMinutes()
    if (
      this.getAssignedContractor &&
      this.contractor &&
      this.contractor.id === this.getAssignedContractor.contractorId &&
      this.getAssignedContractor.etaFrom &&
      this.getAssignedContractor.etaTo
    ) {
      this.contractorAppointed.etaFrom = moment(this.getAssignedContractor.etaFrom)
      this.contractorAppointed.etaTo = moment(this.getAssignedContractor.etaTo)
      this.contractorAppointed.hasDelay = this.getAssignedContractor.hasDelay
      this.contractorAppointed.delayCode = this.getAssignedContractor.delayCode
      this.contractorAppointed.additionalTravelTime = this.getAssignedContractor.additionalTravelTime
    }
  }

  private setNearestMinutes() {
    const etaFrom = moment()
    const etaTo = moment()
    const roundedETAFromMinute = Math.ceil(moment(etaFrom).minute() / this.minutesInterval) * this.minutesInterval
    this.contractorAppointed.etaFrom = moment(etaFrom).minute(roundedETAFromMinute).second(0)
    const roundedETAToMinute = Math.ceil(moment(etaTo).minute() / this.minutesInterval) * this.minutesInterval
    this.contractorAppointed.etaTo = moment(etaTo).minute(roundedETAToMinute).second(0)
  }

  @Watch('contractor')
  private contractorChanged() {
    this.setETA()

    // reset all event of contractor when contractor tile view change
    this.resetAllAction()
    this.isCallButtonClicked = this.isSIJob

    if (this.isSIJob && this.contractor && this.contractor.id) {
      this.GetContractorEngineers(this.contractor.id)
    }

    // verify contractor's autodeployment status
    if (this.item) {
      this.hasAutoDeploymentOn(this.item)
    }
  }

  private async validate(): Promise<boolean> {
    if (this.isUnavailableButtonClicked || this.isCancelContractorButtonClicked || this.isReattendButtonClicked) {
      return this.validateReasonType()
    }

    if (this.isSIJob && this.isAssignButtonClicked) {
      return this.validateAssignEngineer('select-contractor-engineer')
    }

    if (this.isWrongDateRange || this.isWrongTimeRange) {
      return false
    }

    const subs = this.$refs.ETAfromdateTimePicker as DateTimePicker
    const subs1 = this.$refs.ETATodateTimePicker as DateTimePicker
    const result: boolean = await subs.$validator.validateAll()
    const result1: boolean = await subs1.$validator.validateAll()

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    if (result === false) {
      return result
    } else {
      return result1
    }
  }

  private async validateReasonType(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('formReference')

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private get vulnerabilityQAItem(): VulnerabilityQAModel {
    return storeGetters.getVulnerabilityQA(this.jobId)
  }

  private get healthAndSafetyQAItem(): HealthAndSafetyQAModel {
    return storeGetters.getHealthAndSafetyQAForEmergency(this.jobId, this.emergency.id)
  }

  private assignedContractorToJob() {
    const self = this
    setTimeout(() => {
      this.validate()
        .then((result: boolean) => {
          if (result) {
            this.isLoading = true
            if (this.isCancelContractorButtonClicked) {
              let previousContractorAppointedId = ''
              if (this.getAssignedContractor) {
                previousContractorAppointedId = this.getAssignedContractor.id
              }
              const cancelAssignedJobContractorModel: CancelAssignedJobContractorModel =
                new CancelAssignedJobContractorModel()
              cancelAssignedJobContractorModel.id = previousContractorAppointedId
              cancelAssignedJobContractorModel.jobId = this.jobId
              cancelAssignedJobContractorModel.status = JobVisitStatus[JobVisitStatus.Cancelled]
              cancelAssignedJobContractorModel.reasonType = self.contractorAppointed.reasonType
              cancelAssignedJobContractorModel.reasonDescription = self.contractorAppointed.reasonDescription
              cancelAssignedJobContractorModel.createdAt = moment.utc(new Date())
              const reason: ContractorUnavailableReasonModel | undefined = self.contractorUnavailableReasons.find(
                (e) => e.description === self.contractorAppointed.reasonType
              )
              if (reason) {
                cancelAssignedJobContractorModel.isEmergencyCompleted = reason.isEmergencyCompleted
              }
              JobController.CancelAssignedJobContractor(cancelAssignedJobContractorModel)
                .then((res: boolean) => {
                  if (res) {
                    self.isLoading = false
                    self.closeDialog()
                  }
                })
                .catch((err: any) => {
                  eventBus.$emit(
                    'errorHandler',
                    'Error submitting cancel assigned contractor request, please try again',
                    true
                  )
                  self.isLoading = false
                  self.closeDialog()
                })
            } else {
              let contractorAppointed: AddContractorAppointedModel =
                self.getAssignedContractor && self.getAssignedContractor.id
                  ? Object.assign(new AddContractorAppointedModel(), self.getAssignedContractor)
                  : new AddContractorAppointedModel()
              if (
                self.getAssignedContractor &&
                self.getAssignedContractor.status === JobVisitStatus[JobVisitStatus.Unavailable] &&
                ((self.getAssignedContractor && !self.getAssignedContractor.isUnavailable) ||
                  this.getIsSkillLevelAboveRequired)
              ) {
                contractorAppointed = new AddContractorAppointedModel()
              }

              contractorAppointed.isHealthAffected = this.healthAndSafetyQAItem.isHealthAffected
              contractorAppointed.healthAndSafetyLevel = this.healthAndSafetyQAItem.level
              contractorAppointed.isVulnerability = this.vulnerabilityQAItem.isHealthAffected
              contractorAppointed.vulnerabilityLevel = this.vulnerabilityQAItem.level

              if (!contractorAppointed.id) {
                contractorAppointed.jobId = self.jobId
                contractorAppointed.forEmergencyTypeId = self.emergency.typeId
                contractorAppointed.forEmergencyDetailId = self.emergency.detailId
                contractorAppointed.emergencyId = self.emergency.id
                contractorAppointed.tradeId = self.emergency.tradeId
                if (self.item) {
                  contractorAppointed.contractorId = self.item.id
                  contractorAppointed.companyName = self.item.companyName
                  contractorAppointed.contactNumbers = self.item.contactNumbers
                }
              }

              // set contractor unavailable reason
              if (self.isUnavailableButtonClicked) {
                contractorAppointed.reasonType = self.contractorAppointed.reasonType
                contractorAppointed.reasonDescription = self.contractorAppointed.reasonDescription
                contractorAppointed.etaFrom = self.isUnavailableButtonClicked ? null : contractorAppointed.etaFrom
                contractorAppointed.etaTo = self.isUnavailableButtonClicked ? null : contractorAppointed.etaTo

                const reason: ContractorUnavailableReasonModel | undefined = self.contractorUnavailableReasons.find(
                  (e) => e.description === contractorAppointed.reasonType
                )
                if (reason) {
                  contractorAppointed.isUnavailable = reason.isUnavailable
                }
              }

              // manage status of request
              if (self.isAssignButtonClicked) {
                contractorAppointed.status = JobVisitStatus[JobVisitStatus.Pending]
              } else if (self.isUnavailableButtonClicked) {
                contractorAppointed.status = JobVisitStatus[JobVisitStatus.Unavailable]
              } else if (self.isReattendButtonClicked) {
                contractorAppointed.etaFrom = self.contractorAppointed.etaFrom
                contractorAppointed.etaTo = self.contractorAppointed.etaTo
                contractorAppointed.status = JobVisitStatus[JobVisitStatus.Pending]
                contractorAppointed.isContractorReAttend = true
                contractorAppointed.reasonDescription = self.contractorAppointed.reasonDescription
                contractorAppointed.id = null
                contractorAppointed.engineerId = undefined
              }

              // assign contractor to job
              if (self.isAssignButtonClicked || self.isChangeETAButtonClicked) {
                contractorAppointed.etaFrom = self.contractorAppointed.etaFrom
                contractorAppointed.etaTo = self.contractorAppointed.etaTo
                contractorAppointed.hasDelay = self.contractorAppointed.hasDelay
                contractorAppointed.delayCode = self.contractorAppointed.delayCode
              }

              if (this.contractor) {
                contractorAppointed.additionalTravelTime =
                  this.travelTimeHour !== 0 || this.travelTimeMinute !== 0
                    ? this.getFormatedTravelTime(this.travelTimeHour + ':' + this.travelTimeMinute)
                    : ''
                contractorAppointed.isInCoverageArea = this.contractor.isNearByContractor ? false : true
              }

              if (this.isSIJob && this.selectedEngineer) {
                contractorAppointed.engineerId = this.selectedEngineer
              }

              // remove previousEngineerVisitId property as not required while assigning contractor
              delete (contractorAppointed as any).previousEngineerVisitId
              JobController.AddContractorAppointedDetail(contractorAppointed)
                .then((res: boolean) => {
                  if (res) {
                    self.isLoading = false
                    this.isCallButtonClicked = false
                    self.closeDialog()
                  }
                  if (!self.isUnavailableButtonClicked) {
                    // TODO: check that this only works when a contractor is assigned (not for cancellations, missed calls etc.)
                    eventBus.$emit('returnToQueue')
                  }
                })
                .catch((err: any) => {
                  self.isLoading = false
                  self.closeDialog()
                  eventBus.$emit(
                    'errorHandler',
                    'Error submitting Add Contractor AppointedDetail request, please try again',
                    true
                  )
                })
            }
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }, 0)
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  // Get list of contractor unavailable reason
  private GetContractorUnavailableReasons(): void {
    ContractorUnavailableReasonController.GetContractorUnavailableReasons().then(
      (res: ContractorUnavailableReasonModel[]) => {
        if (res) {
          if (this.contractorAppointed.reasonType !== undefined) {
            this.contractorUnavailableReasons =
              res && res.length > 0
                ? res.filter(
                    (e) => e.isDeleted === false || (e.isDeleted && this.contractorAppointed.reasonType === e.id)
                  )
                : []
          } else {
            this.contractorUnavailableReasons = res && res.length > 0 ? res.filter((e) => e.isDeleted === false) : []
          }
          if (this.isUserRoleAdministrator) {
            const reason: ContractorUnavailableReasonModel = new ContractorUnavailableReasonModel()
            reason.description = 'Configure'
            this.contractorUnavailableReasons.push(reason)
          }
        }
      }
    )
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on unavailable reason drop down.
  private onReasonChange(description: any, isUnavailableButtonClicked: boolean) {
    if (description === 'Configure') {
      this.configureUnavailableReasonDialog = true
    }
  }

  private closeDialog() {
    this.actionDialog = false
    setTimeout(() => {
      this.resetAllAction()
      this.contractorAppointed.reasonDescription = ''
      this.contractorAppointed.reasonType = ''
    }, 400)
    if (this.isAppointContractorDialogOpen) {
      eventBus.$emit('appointContractorDialogOpen', false)
      this.isAppointContractorDialogOpen = false
    }
  }

  private resetAllAction() {
    this.isAssignButtonClicked = false
    this.isUnavailableButtonClicked = false
    this.isReattendButtonClicked = false
    this.isChangeETAButtonClicked = false
    this.isCancelContractorButtonClicked = false
    this.isNewContractorAssigned = false
    this.isCancelContractorButtonClicked = false
    this.totalOpenJobCount = 0
    this.totalEngineerCount = 0
  }

  private onConfigureUnavailableReasonDialogClose(items: any[]) {
    this.configureUnavailableReasonDialog = false
    const deletedRecord: ContractorUnavailableReasonModel | undefined = this.contractorUnavailableReasons.find(
      (e) => e.isDeleted === true
    )
    this.contractorUnavailableReasons = items.slice(0)
    if (deletedRecord !== undefined) {
      this.contractorUnavailableReasons.push(deletedRecord)
    }
    if (this.contractorAppointed) {
      this.contractorAppointed.reasonType = ''
    }
    if (this.isUserRoleAdministrator) {
      const reason: ContractorUnavailableReasonModel = new ContractorUnavailableReasonModel()
      reason.description = 'Configure'
      this.contractorUnavailableReasons.push(reason)
    }
  }

  private assignContractor() {
    if (this.isThirdPartyContractor) {
      const self = this
      const thirdPartyContractorAppointed: AddThirdPartyContractorDetail = new AddThirdPartyContractorDetail()
      thirdPartyContractorAppointed.jobId = self.jobId
      thirdPartyContractorAppointed.forEmergencyTypeId = self.emergency.typeId
      thirdPartyContractorAppointed.forEmergencyDetailId = self.emergency.detailId
      thirdPartyContractorAppointed.emergencyId = self.emergency.id
      thirdPartyContractorAppointed.status = JobVisitStatus[JobVisitStatus.Pending]

      if (self.item) {
        thirdPartyContractorAppointed.contractorId = self.item.id
        thirdPartyContractorAppointed.companyName = self.item.companyName
        thirdPartyContractorAppointed.contactNumbers = self.item.contactNumbers
      }
      this.isLoading = true
      JobController.AddThirdPartyContractorDetail(thirdPartyContractorAppointed)
        .then((res: boolean) => {
          if (res) {
            this.isLoading = false
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error assigning job to third party contractor, please try again', true)
          this.isLoading = false
        })
    } else {
      // check job is already assign to any contractor?
      if (this.getOldJobContractorIfExists) {
        Shared.confirmationPopup.open(
          'This job is already assigned to another contractor, do you like to cancel previous job and assign new one?',
          '',
          '',
          'No',
          'Yes',
          this,
          'openSetETADialog',
          true
        )
      } else {
        this.openSetETADialog(false)
      }
    }
  }

  private get getOldJobContractorIfExists() {
    return (
      this.assignedContractors &&
      this.assignedContractors.find(
        (c) =>
          c.contractorId !== (this.item ? this.item.id : null) &&
          c.status !== JobVisitStatus[JobVisitStatus.Unavailable]
      )
    )
  }

  public get hasDeploymentSkills(): boolean {
    if (store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
      return store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.deployment >= 4
    }
    return false
  }

  private get getIsSkillLevelAboveRequired(): boolean {
    return store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.deployment === this.skillNeededToDeployAJob
  }

  private roundedMinutes(originalDate: moment.Moment | null, minutesInterval: number) {
    if (originalDate) {
      const roundedETAFromMinute = Math.ceil(moment(originalDate).minute() / minutesInterval) * minutesInterval
      return moment(originalDate).minute(roundedETAFromMinute).second(0)
    }
    return null
  }

  private openSetETADialog(isAssign: boolean) {
    if (isAssign) {
      this.isNewContractorAssigned = true
    }
    const emergencyCreatedDate = this.emergency.firstUpdatedAt
      ? moment(this.emergency.firstUpdatedAt)
      : moment(this.emergency.createdAt)
    if (emergencyCreatedDate) {
      this.contractorAppointed.etaFrom = this.roundedMinutes(moment(), 15)
      this.minETADate = moment(emergencyCreatedDate).format(DateTimePicker.DATE_FORMAT)
      const firstNoticedAtTime: moment.Moment = moment(
        emergencyCreatedDate.format(DateTimePicker.TIME_FORMAT),
        DateTimePicker.TIME_FORMAT
      )
      const currentTime: moment.Moment = moment(moment().format(DateTimePicker.TIME_FORMAT), DateTimePicker.TIME_FORMAT)
      if (moment(firstNoticedAtTime).isBefore(currentTime)) {
        this.minETATime = moment(emergencyCreatedDate).format(DateTimePicker.TIME_FORMAT)
      }
    }

    this.applyCalculatedDelay()

    // inside sla set eta To Date accordingly
    if (this.emergency.engineerSiteSLA) {
      // engineerSiteSLA hrs added for emergency, set etaToDate according to engineerSiteSLA hrs
      const etaToDate: moment.Moment = moment(emergencyCreatedDate).clone().add(this.emergency.engineerSiteSLA, 'hours')
      const roundedETAFromMinute = Math.ceil(moment(etaToDate).minute() / -15) * -15
      this.contractorAppointed.etaTo = etaToDate.minute(roundedETAFromMinute).second(0)
      this.maxETAToDate =
        !this.hasDeploymentSkills && this.contractorAppointed.etaTo
          ? moment(this.contractorAppointed.etaTo).format(DateTimePicker.DATE_FORMAT)
          : ''
      this.maxETAToTime =
        !this.hasDeploymentSkills && this.contractorAppointed.etaTo
          ? moment(this.contractorAppointed.etaTo).format(DateTimePicker.TIME_FORMAT)
          : ''
      this.tempMaxTime = this.maxETAToTime
    } else {
      // engineerSiteSLA hrs not added for emergency
      this.contractorAppointed.etaTo = this.roundedMinutes(moment(), 15)
    }

    this.actionDialog = true
    this.isAssignButtonClicked = true
    this.isAppointContractorDialogOpen = true
    eventBus.$emit('appointContractorDialogOpen', true)
  }

  private changeContractorETA() {
    // check customer availability slots outside of the SLA or not
    this.applyCalculatedDelay()

    this.setNearestMinutes()
    this.setAdditionalTravelTime(this.contractorAppointed.additionalTravelTime)
    this.actionDialog = true
    this.isChangeETAButtonClicked = true
    this.isCancelContractorButtonClicked = false
  }

  private onCallButtonClick(contactNumber: string, contractorId: string) {
    this.isCallButtonClicked = true
    this.showAllContactNumber = false

    // put together the parameters and emit the event
    const callParameters = new CallParameters()
    callParameters.to = contactNumber
    callParameters.jobId = this.jobId
    callParameters.contractorId = contractorId
    callParameters.emergencyId = this.emergency.id
    eventBus.$emit('callIconClick', callParameters)
  }

  private get customerAvailability(): CustomerAvailabilityModel {
    return storeGetters.getCustomerAvailability(this.jobId)
  }

  private get todaySlots(): TimeSlot[] {
    if (
      this.customerAvailability &&
      this.customerAvailability.timeSlot &&
      this.customerAvailability.timeSlot.length > 0
    ) {
      return this.getAvailability(
        Shared.SortData(
          this.customerAvailability.timeSlot.filter(
            (a: TimeSlot) =>
              new Date(a.startTime.toString()).getDate() === this.todayDate.getDate() &&
              new Date(a.startTime.toString()).getHours() >= this.todayDate.getHours()
          ),
          'startTime'
        )
      )
    } else {
      return []
    }
  }

  private get getSLACutOffTime() {
    const firstUpdatedDate = this.emergency.firstUpdatedAt
      ? moment(this.emergency.firstUpdatedAt)
      : moment(this.emergency.createdAt)
    const slaCutOffTime = moment(firstUpdatedDate).clone().add(this.emergency.engineerSiteSLA, 'hours')
    return Shared.getFormatedDate(moment(slaCutOffTime), store.Instance.state.Environment.DateTimeFormat)
  }

  private isContractorAvailable(): boolean {
    if (this.contractor && this.contractor.contractorAvailability) {
      const contractorAvailability: ContractorTradeAvailabilityModel | undefined =
        this.contractor.contractorAvailability.find(
          (e: ContractorTradeAvailabilityModel) => e.tradeId === this.emergency.tradeId
        )
      let valid = false
      if (contractorAvailability !== undefined) {
        for (const timeSlot of contractorAvailability.timeSlot) {
          const availability: any = this.todaySlots.find((e) => e.slot === timeSlot.slot)
          if (availability !== undefined) {
            valid = true
            break
          } else {
            valid = false
          }
        }
      }
      return valid
    }
    return false
  }
  private onCallContractor(contactNumber: string, contractorId: string) {
    // put the call parameters together and pass them over
    const callParameters = new CallParameters()
    callParameters.to = contactNumber
    callParameters.jobId = this.jobId
    callParameters.contractorId = contractorId
    callParameters.emergencyId = this.emergency.id
    eventBus.$emit('callIconClick', callParameters)
  }

  private onDelayCodeChange(hasDelay: boolean, delayCode: string | null) {
    this.contractorAppointed.hasDelay = hasDelay
    this.contractorAppointed.delayCode = delayCode
  }

  private plotContractorOpenJobAddress(contractorId: string) {
    if (this.contractor && contractorId) {
      this.contractorOpenJobAddresses = []
      ContractorController.plotContractorOpenJobs(contractorId)
        .then((res: ContractorAppointedModel[] | null) => {
          if (res) {
            this.contractorOpenJobAddresses = res
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading contractor open job(s), please try again', true)
        })
    }
  }

  private get getOpenJobs() {
    const self = this
    self.totalOpenJobCount = 0
    self.totalOpenJobs = []
    if (self.item) {
      if (self.item.totalOpenJobs) {
        let emergencyToDisplay: EmergencyModel | undefined
        Object.keys(self.item.totalOpenJobs).forEach((key) => {
          if (self.item) {
            const tempObject: any = {}
            tempObject.key = key
            tempObject.value = self.item.totalOpenJobs[key]

            emergencyToDisplay = this.emergencyTypes.find((x) => x.emergencyId === Number(tempObject.key))
            if (emergencyToDisplay !== undefined) {
              tempObject.img = emergencyToDisplay.fileURL
              tempObject.key = emergencyToDisplay.description
            }

            self.totalOpenJobCount += tempObject.value
            self.totalOpenJobs.push(tempObject)
          }
        })
      }
    }
    return self.totalOpenJobs
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies()
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        storeMutations.setEmergencies(res)
      }
    })
  }

  private get getEngineers() {
    const self = this
    self.totalEngineerCount = 0
    self.totalEngineers = []
    if (self.item) {
      if (self.item.totalEngineers) {
        Object.keys(self.item.totalEngineers).forEach((key) => {
          if (self.item) {
            const tempObject: any = {}
            tempObject.key = key
            tempObject.value = self.item.totalEngineers[key]
            self.totalEngineerCount += tempObject.value

            const tradsToDisplay: TradeModel | undefined = self.trades.find((x) => x.tradeId === Number(tempObject.key))
            if (tradsToDisplay !== undefined) {
              self.emergencyEngineerType = tradsToDisplay.description
              self.emergencyEngineerCount = tempObject.value
            }

            self.totalEngineers.push(tempObject)
          }
        })
      }
    }
    return self.totalEngineers
  }

  private getTradeList() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.trades = res
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.trades = trades
    }
  }

  @Watch('contractorAppointed.etaFrom')
  @Watch('contractorAppointed.etaTo')
  private onETAChange(newValue: moment.Moment | null, oldValue: moment.Moment | null) {
    this.isWrongDateRange = false
    this.isWrongTimeRange = false
    const slaCutOffTime = moment(this.emergency.createdAt).clone().add(this.emergency.engineerSiteSLA, 'hours')
    if (this.contractorAppointed.etaFrom && this.contractorAppointed.etaTo) {
      const etaFromDate = this.contractorAppointed.etaFrom
      const etaToDate = this.contractorAppointed.etaTo
      const emergencyCreatedDate = this.emergency.firstUpdatedAt
        ? moment(this.emergency.firstUpdatedAt)
        : moment(this.emergency.createdAt)
      if (emergencyCreatedDate) {
        // set minETATime based on date selection
        if (
          moment(etaFromDate).format(DateTimePicker.DATE_FORMAT) ===
          moment(emergencyCreatedDate).format(DateTimePicker.DATE_FORMAT)
        ) {
          const firstNoticedAtTime: moment.Moment = moment(
            emergencyCreatedDate.format(DateTimePicker.TIME_FORMAT),
            DateTimePicker.TIME_FORMAT
          )
          const currentTime: moment.Moment = moment(
            moment().format(DateTimePicker.TIME_FORMAT),
            DateTimePicker.TIME_FORMAT
          )
          if (moment(firstNoticedAtTime).isBefore(currentTime)) {
            this.minETATime = moment(emergencyCreatedDate).format(DateTimePicker.TIME_FORMAT)
          }
        } else {
          this.minETATime = ''
        }
        // set maxETAToTime based on date selection
        if (!this.hasDeploymentSkills) {
          this.maxETAToTime =
            moment(etaToDate).format(DateTimePicker.DATE_FORMAT) === this.maxETAToDate ? this.tempMaxTime : ''
        } else {
          this.maxETAToTime = ''
        }
      }

      this.minETAToDate = moment(etaFromDate).format(DateTimePicker.DATE_FORMAT)
      this.minETAToTime =
        moment(etaFromDate).format(DateTimePicker.DATE_FORMAT) === moment(etaToDate).format(DateTimePicker.DATE_FORMAT)
          ? moment(etaFromDate).format(DateTimePicker.TIME_FORMAT)
          : ''
      if (moment(etaFromDate) > moment(etaToDate)) {
        if (this.etaFromOldValue === oldValue) {
          this.contractorAppointed.etaTo = this.hasDeploymentSkills
            ? this.contractorAppointed.etaFrom
            : moment(etaFromDate) < slaCutOffTime
            ? this.contractorAppointed.etaFrom
            : this.contractorAppointed.etaTo
          this.isWrongDateRange = !this.hasDeploymentSkills && moment(etaFromDate) > slaCutOffTime ? true : false
        } else if (this.etaToOldValue === oldValue) {
          this.isWrongDateRange = true
        }
      } else {
        // check customer is available within contractor ETA
        let validETAFromCount = 0
        let validETAToCount = 0
        const dateFormat: string = Shared.dateFormatForDatePicker
        const timeSlots = this.customerAvailability.timeSlot
        if (this.customerAvailability.timeSlot) {
          // check is ETAFrom Date Valid compared to customerAvailability
          for (const timeSlot of timeSlots) {
            if (etaFromDate.isSame(timeSlot.startTime)) {
              validETAFromCount = validETAFromCount + 1
            } else if (etaFromDate.isBetween(timeSlot.startTime, timeSlot.endTime)) {
              validETAFromCount = validETAFromCount + 1
            }
          }
          this.isWrongTimeRange = validETAFromCount >= 1 ? false : true
          if (!this.isWrongTimeRange) {
            // check is ETATo Date Valid compared to customerAvailability
            for (const timeSlot of timeSlots) {
              if (etaToDate.isSame(timeSlot.endTime) || etaToDate.isSame(timeSlot.startTime)) {
                validETAToCount = validETAToCount + 1
              } else if (etaToDate.isBetween(timeSlot.startTime, timeSlot.endTime)) {
                validETAToCount = validETAToCount + 1
              }
            }
            this.isWrongTimeRange = validETAToCount >= 1 ? false : true
          }
        } else {
          this.isWrongTimeRange = false
        }
      }
      this.etaFromOldValue = this.contractorAppointed.etaFrom
      this.etaToOldValue = this.contractorAppointed.etaTo
    }
  }

  private reAttendContractor() {
    // check customer availability slots outside of the SLA or not
    this.applyCalculatedDelay()
    this.setNearestMinutes()
    this.setAdditionalTravelTime(this.contractorAppointed.additionalTravelTime)
    this.actionDialog = true
    this.isReattendButtonClicked = true
  }

  private async clearEtaConfirmation() {
    Shared.confirmationPopup.openCallback({
      message: 'Are you sure you want to clear the ETA for this visit?',
      positiveCallback: this.clearEta,
      positiveText: 'Clear',
      negativeText: 'Cancel',
    })
  }

  private async clearEta() {
    if (this.getAssignedContractor) {
      JobController.ClearEta({
        jobId: this.emergency.jobId,
        contractorAppointedDetailId: this.getAssignedContractor.id,
      })
        .then(async (res: boolean) => {
          if (!res) {
            alert('error clearing eta, please try again')
            this.isLoading = false
            return
          }
          this.isLoading = false

          await this.createJobNote(
            'ETA Cleared',
            `Previous ETA Cleared for ${this.emergency.jobId} by ${this.userName} on ${Shared.getFormatedDate(
              moment(),
              store.Instance.state.Environment.DateTimeFormat
            )}. Previous ETA was ${Shared.getFormatedDate(
              this.contractorAppointed.etaFrom!,
              store.Instance.state.Environment.DateTimeFormat
            )} to ${Shared.getFormatedDate(
              this.contractorAppointed.etaTo!,
              store.Instance.state.Environment.DateTimeFormat
            )}`
          ).then(() => {
            this.$store.dispatch('jobModule/showClearEtaDialog', true)
          })
        })
        .catch((err: any) => {
          this.isLoading = false
          eventBus.$emit('errorHandler', 'Error clearing ETA, please try again', true)
        })
    }
  }

  private async createJobNote(title: string, description: string) {
    const jobNote = new AddNewJobNote()
    jobNote.jobId = this.emergency.jobId
    jobNote.noteType = 'Miscellaneous'
    jobNote.title = title
    jobNote.description = description
    jobNote.loggedByUserId = store.Instance.state.SessionDetail.detailRecord.UserDetail.id
    jobNote.loggedByUserName = this.userName
    jobNote.forEmergencyDetailId = this.contractorAppointed.forEmergencyDetailId
    jobNote.forEmergencyDetailDescription = this.emergency.detailDescription
    jobNote.callSid = ''

    await JobController.AddJobNote(jobNote)
      .then((res: boolean) => {
        if (!res) {
          alert('error adding job note')
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving job note, please try again', true)
      })
  }

  private applyCalculatedDelay(): void {
    this.availabilityOutsideSLA = false
    DelayController.CalculateDelay(this.emergency.jobId, this.emergency.id)
      .then((res: DelayResult) => {
        this.contractorAppointed.hasDelay = res.hasDelay
        this.contractorAppointed.delayCode = res.delayCode
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', err)
      })
  }

  private getRecordingUrl(): void {
    if (!this.getAssignedContractor || !this.getAssignedContractor.callRecordingUrl) {
      return
    }
    DocumentController.GetSASTokenForRecordingUrl(this.getAssignedContractor.callRecordingUrl)
      .then((url: string | null) => {
        if (url) {
          window.open(url, '_blank')
        }
      })
      .catch((error) => {
        // do nothing
      })
  }

  private get canPlayCall() {
    return store.Instance.state.SessionDetail.canPlayCall
  }

  private openEngineerAssignDialog() {
    this.loaderOnGettingEngineerList = true
    this.selectedEngineer = ''
    let contractorId = ''
    contractorId = this.contractor && this.contractor.id ? this.contractor.id : ''
    if (!contractorId) {
      // show message for no engineer found
      return
    } else {
      if (this.engineerList.length > 0) {
        this.loaderOnGettingEngineerList = false
        this.openAssignEngineerDialog = true
      } else {
        this.GetContractorEngineers(contractorId)
      }
    }
  }

  private GetContractorEngineers(contractorId: string) {
    EngineerController.GetContractorEngineers(contractorId)
      .then((res: EngineerModel[]) => {
        // check engineers availability
        const engineerList: EngineerModel[] = res.filter((e) => e.engineerAvailability !== null)
        this.engineerList = engineerList.filter(
          (e: EngineerModel) =>
            e.engineerAvailability.findIndex((t: EngineerAvailabilityModel) => t.tradeId === this.emergency.tradeId) >
              -1 || e.id === null
        )
        // if availability found, add engineer into list
        if (this.engineerList.length > 0) {
          this.engineerList.forEach((element) => {
            if (this.getAssignedContractor) {
              const isEngineerAvailable = this.checkEngineerAvailability(
                this.getAssignedContractor.etaFrom,
                this.getAssignedContractor.etaTo,
                element
              )
              element.disabled = !isEngineerAvailable // set disabled property based on engineer availability
            }
            element.name = element.firstName + ' ' + element.lastName
            element.isSelected = false
          })
          this.openAssignEngineerDialog = this.isSIJob ? false : true
        } else {
          this.saveSnackbar = true
          this.saveSnackbarText = 'Engineer(s) not available.'
        }
        this.loaderOnGettingEngineerList = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading engineer list, please try again', true)
        this.loaderOnGettingEngineerList = false
      })
  }

  private assignEngineerToEmergency() {
    this.validateAssignEngineer('selectedEngineer')
      .then((result: boolean) => {
        if (result) {
          this.loaderOnAssignEngineer = true
          const updateContractorAppointedDetail: UpdateContractorAppointedDetail = new UpdateContractorAppointedDetail()
          updateContractorAppointedDetail.id =
            this.getAssignedContractor && this.getAssignedContractor.id ? this.getAssignedContractor.id : ''
          updateContractorAppointedDetail.engineerId = this.selectedEngineer
          updateContractorAppointedDetail.jobId = this.jobId
          updateContractorAppointedDetail.status = JobVisitStatus[JobVisitStatus.Accepted]
          updateContractorAppointedDetail.engineerAssignByIM = true
          EngineerController.AssignEngineerToJob(updateContractorAppointedDetail)
            .then((res: boolean) => {
              this.loaderOnAssignEngineer = false
              if (res) {
                this.openAssignEngineerDialog = false
              }
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error assigning job to an engineer, please try again', true)
              this.loaderOnAssignEngineer = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validateAssignEngineer(scope: string): Promise<boolean> {
    let result = true
    const validation: boolean = await this.$validator.validateAll(scope)
    // set focus to non validate field
    if (!validation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = validation
    }
    return result
  }

  private checkEngineerAvailability(etaFrom: moment.Moment | null, etaTo: moment.Moment | null, engineer: any) {
    // check engineer availability compared to job eta.
    let isEngineerAvailable = false
    if (etaFrom && etaTo) {
      if (engineer) {
        if (
          engineer.outsideToDate &&
          engineer.outsideFromDate &&
          engineer.outsideToDate.isValid() &&
          engineer.outsideFromDate.isValid()
        ) {
          const etaFromDate: string = etaFrom.toISOString().slice(0, 10)
          const outsideFromDate: string = engineer.outsideFromDate.toISOString().slice(0, 10)
          const outsideToDate: string = engineer.outsideToDate.toISOString().slice(0, 10)
          if (etaFromDate === outsideFromDate || etaFromDate === outsideToDate) {
            isEngineerAvailable = false
          } else {
            isEngineerAvailable = etaFrom.isBetween(engineer.outsideFromDate, engineer.outsideToDate) ? false : true
          }
        } else {
          isEngineerAvailable = true
        }
      }
    }
    return isEngineerAvailable
  }

  private destroyed() {
    if (this.engineerAvailabilityHub != null) {
      this.engineerAvailabilityHub.disconnect()
    }
  }

  private runManualAutoDeployment() {
    const self = this

    // restrict auto deployment if job logged before 30 minutes
    if (this.hideAutoDeploymentButton) {
      self.saveSnackbar = true
      self.saveSnackbarText =
        'Auto deployment duration has been over, kindly refresh the page and call contractor manually'
      return
    }

    const isUnavailable: boolean = this.getAssignedContractor && this.getAssignedContractor.isUnavailable ? true : false
    if (self.item) {
      this.manualAutoDeploymentRunning = true
      this.$emit(
        'runManualAutoDeployment',
        self.jobId,
        self.emergency.id,
        self.item.id,
        self.isContractorSelectedViaSearchResult,
        isUnavailable,
        self.item.autoDeploymentType
      )
    }
  }

  private toggleFullScreenMap() {
    this.showFullScreenMap = !this.showFullScreenMap
    const mapView: any = this.$refs.mapView
    if (mapView) {
      if (this.showFullScreenMap) {
        mapView.classList.add('fullScreen')
        this.toggleFullScreenIcon('hide')
        setTimeout(() => {
          ;(this.$refs.fullScreenIcon as any).$el.classList.remove('hide')
        }, 800)
      } else {
        mapView.classList.remove('fullScreen')
        setTimeout(() => {
          this.toggleFullScreenIcon('show')
        }, 800)
      }
      this.$nextTick()
    }
  }

  private toggleFullScreenIcon(displayStyle: string) {
    const fullScreenIcons: any = document.getElementsByClassName('fullScreenIcon')
    if (fullScreenIcons && fullScreenIcons.length > 0) {
      for (const item of fullScreenIcons) {
        if (displayStyle === 'show') {
          item.classList.remove('hide')
        } else {
          item.classList.add('hide')
        }
      }
    }
  }

  private hasAutoDeploymentOn(item: ContractorModel) {
    this.isContractorHasAutoDeployment = false
    // check is auto deployment set or not
    if (
      item.autoDeploymentType === undefined &&
      item.autoDeploymentType === AutoDeploymentTypeEnum[AutoDeploymentTypeEnum.NoAutodeployment]
    ) {
      this.isContractorHasAutoDeployment = false
      return
    }

    // if auto deployment set to 24 hours return true
    if (item.autoDeployAlwaysActive) {
      this.isContractorHasAutoDeployment = true
      return
    }

    // if manual time selected, check the auto deployment time to toggle between call contractor and auto deployment button
    const currentTime = moment(new Date())
    const beginningTime = item.autoDeployStartTime ? moment(item.autoDeployStartTime, 'h:mma') : null
    const endTime = item.autoDeployEndTime ? moment(item.autoDeployEndTime, 'h:mma') : null
    if (beginningTime && endTime) {
      if (beginningTime <= endTime) {
        if (beginningTime.isSameOrBefore(currentTime) && currentTime.isSameOrBefore(endTime)) {
          this.isContractorHasAutoDeployment = true
          return
        }
      } else {
        if (currentTime.isSameOrAfter(beginningTime) && currentTime.isSameOrAfter(endTime)) {
          this.isContractorHasAutoDeployment = true
          return
        }
        if (currentTime.isSameOrBefore(beginningTime) && currentTime.isSameOrBefore(endTime)) {
          this.isContractorHasAutoDeployment = true
          return
        }
      }
    }
  }

  private autoDeploymentOnText(autoDeploymentType: string): string {
    if (autoDeploymentType !== undefined) {
      if (autoDeploymentType === AutoDeploymentTypeEnum[AutoDeploymentTypeEnum.AutodeployToEngineer]) {
        return 'to engineers'
      } else {
        return 'to contractor'
      }
    }
    return ''
  }

  private allowedMinutes(value: number) {
    return value % 15 === 0
  }

  private getFormatedTravelTime(travelTime: string) {
    return Shared.formatTimeSpanToString(travelTime)
  }

  private setAdditionalTravelTime(travelTime: string) {
    this.travelTimeHour = travelTime ? Number(travelTime.split(':')[0]) : this.travelTimeHourList[0].value
    this.travelTimeMinute = travelTime ? Number(travelTime.split(':')[1]) : this.travelTimeMinuteList[0].value
  }

  private get showAutoDeploymentButton(): boolean {
    const result =
      !this.isThirdPartyContractor &&
      ((this.getAssignedContractor &&
        this.getIsSkillLevelAboveRequired &&
        this.isContractorHasAutoDeployment &&
        (!this.isContractorAssigned ? true : false)) ||
        (!this.getAssignedContractor && this.isContractorHasAutoDeployment))
    return result ? result && !this.hideAutoDeploymentButton : result
  }

  private get hideAutoDeploymentButton() {
    return (
      moment.duration(moment(new Date()).diff(this.emergency!.createdAt)).asMinutes() >
      store.Instance.state.Environment.HideAutoDeploymentAfterXMinutes
    )
  }

  private get showCallContractorButton(): boolean {
    return this.showAutoDeploymentButton
      ? !this.hideAutoDeploymentButton
        ? this.job && this.job.emergencies.length > 1
          ? true
          : false
        : true
      : ((!this.isContractorAssigned || this.isThirdPartyContractor) && !this.isCallButtonClicked) ||
          (this.isContractorAssigned &&
            !this.isCallButtonClicked &&
            (this.getAssignedContractor
              ? this.getAssignedContractor.status === JobVisitStatus[JobVisitStatus.Unavailable]
              : false) &&
            ((this.getAssignedContractor && !this.getAssignedContractor.isUnavailable) ||
              this.getIsSkillLevelAboveRequired))
  }

  private getPreferredContractor(contractor: ContractorModel) {
    const availability: ContractorTradeAvailabilityModel | undefined =
      contractor.contractorAvailability && contractor.contractorAvailability.length > 0
        ? contractor.contractorAvailability.find((c) => c.tradeId === this.emergency.tradeId)
        : undefined
    return availability &&
      availability.preferredContractor &&
      availability.preferredContractor.toString() !== 'Unverified'
      ? availability.preferredContractor
      : contractor.preferredContractor
  }
}
</script>

<style scoped>
.tradeAppointedPreview {
  font-size: 14px;
  line-height: 18px;
  color: #414141;
  padding-bottom: 7px;
  position: relative;
}
.tradeAppointedPreview .v-divider {
  flex: none;
  margin: 6px 0px;
  width: 100%;
}
.tradeAppointedPreview .location-detail {
  position: relative;
  padding-left: 30px;
}
.tradeAppointedPreview .location-detail span {
  font-size: 14px;
  color: #414141;
  padding: 0px;
}
.tradeAppointedPreview .location-detail .v-icon,
.tradeAppointedPreview .location-detail .img-icon {
  font-size: 20px;
  position: absolute;
  left: 0px;
}
.tradeAppointedPreview .location-detail a.mobile,
.tradeAppointedPreview .location-detail a:hover {
  color: #fd8603;
}
.tradeAppointedPreview h3 {
  font-size: 20px !important;
  padding-bottom: 8px;
  font-weight: 400;
}
.tradeAppointedPreview h5 {
  color: #999999;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
  font-weight: 400;
}
.tradeAppointedPreview .mapMain {
  width: 100%;
  margin: 0 auto !important;
}
.card-image,
.card-stacked {
  width: 100%;
  max-height: 180px;
  position: relative;
  overflow: hidden;
}
.tradeAppointedPreview .card-image img,
.tradeAppointedPreview .card-stacked .map {
  height: 100%;
  width: 100%;
  border: 0px;
}
.card-image,
.card-image img {
  min-height: 180px;
  max-height: 180px;
}
.card-image img {
  object-fit: cover;
  width: 100%;
}
.tradeAppointedPreview .show-icons {
  display: flex;
  flex-wrap: wrap;
}
.available-time > span {
  padding: 0px !important ;
}
.img-icon {
  max-width: 20px;
  left: 2px;
}
.bold-select {
  font-weight: 600;
}
.badges-img {
  width: 100px;
}
/*Job Count*/
.job-info {
  position: relative;
  height: 100%;
  padding-right: 45px !important;
}
.count {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 14px;
  font-weight: 600;
}
.open-job .count {
  right: 0;
}
.job-count-menu >>> .v-list__tile {
  height: 38px;
}
.map-btn {
  height: 20px;
  margin-left: 5px !important;
  width: 20px;
  line-height: 0px;
}
.contact-list >>> .v-list {
  font-size: 15px;
  position: relative;
}
.contact-list >>> .v-list .action {
  position: absolute;
  right: 0px;
}
.contact-list >>> .v-list .action a {
  height: 30px;
  width: 30px;
  display: inline-flex;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}
.contact-list >>> .v-list .action a i {
  margin: auto;
}
.contact-list >>> .v-list > div:not(:last-child) {
  border-bottom: 1px solid #d8d1d1;
}
.contact-list >>> .v-list .name {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 3px;
}
.contact-list >>> .v-list__tile {
  height: auto !important;
  padding: 10px 10px !important;
}
.number {
  color: #4a4a4a;
}
.number:hover {
  color: #c9004b;
}
.emergancy-icon {
  position: absolute;
  left: 0px;
  top: 2px;
  width: 17px;
}
.job-count-menu .v-list__tile__title {
  position: relative;
  padding-left: 30px;
}
.open-job.job-info {
  padding-right: 65px !important;
}
/* dot animation start */
.dot-animation {
  display: inline-block;
  width: 100px;
  height: 10px;
}
.dot-animation > span {
  height: 5px;
  width: 5px;
  display: inline-block;
  border-radius: 50%;
  margin: 0px 2px;
  background-color: #fff;
}
.dot-animation > span {
  animation: 1s blink infinite;
}
.dot-animation > span:nth-child(2) {
  animation-delay: 250ms;
}
.dot-animation > span:nth-child(3) {
  animation-delay: 500ms;
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
/* dot animation end */
.engineer-snackbar.v-snack {
  z-index: 2;
}
.engineer-map >>> .CustomMapMarker {
  position: relative;
  opacity: 1 !important;
  overflow: visible !important;
}
.engineer-map >>> .CustomMapMarker:after {
  content: '';
  display: block;
  width: 100px;
  height: 100px;
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 6px 3px #f93c11;
  position: absolute;
  left: -38px;
  top: -20px;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@media (max-width: 1279px) {
  .btn-block .v-btn {
    width: 100%;
    margin-left: 0px !important;
  }
  .tradeAppointedPreview .mapMain > .flex {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .open-job.job-info {
    padding-right: 60px !important;
  }
  .open-job.job-info .map-btn {
    margin-left: 0 !important;
  }
  .open-job.job-info >>> .v-menu .v-menu__activator span span {
    margin-right: 0 !important;
  }
}
.engineer-map {
  width: 100%;
  height: 600px;
  position: relative;
}
.engineer-map > div {
  height: 100%;
  width: 100%;
}
</style>
