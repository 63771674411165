<template>
  <div :id="tableName">
    <v-layout wrap grid-view>
      <v-flex xs7 request-info>
        <h3>Incident Manager</h3>
      </v-flex>
      <v-flex v-if="isUserRoleAdministrator" xs5 pl-3>
        <div class="search-supervisor">
          <v-autocomplete
            v-model="selectedIncidentManager"
            :items="incidentManagerList"
            item-text="displayValue"
            item-value="id"
            label="Incident Manager"
            return-object
            :loading="isLoading"
            hide-details
            @change="onIncidentManagerChange($event)"
          ></v-autocomplete>
        </div>
      </v-flex>
      <v-flex mt-2 elevation-1>
        <div class="incident-manager-list">
          <v-data-table :headers="headers" :items="incidentManagerData" item-key="name" hide-actions :search="search">
            <template slot="items" slot-scope="props">
              <tr
                :active="props.selected"
                :class="setClassForSelectedIM(props.item.name)"
                @click="props.selected = !props.selected"
              >
                <td class="text-center font-weight-bold secondary--text">
                  {{ props.item.rank }}
                </td>
                <td :class="setBorderClass(props.item.id)">
                  <img :src="getImageUrl(props.item.id)" alt="" />
                </td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.teamName }}</td>
                <td class="text-center">{{ props.item.taskAnswered }}</td>
                <td class="text-center">{{ props.item.taskMissed }}</td>
                <td class="text-center">{{ props.item.jobLogged }}</td>
                <td class="text-center">
                  {{ props.item.complimentsReceived }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { SetThemeForJobType } from '@/common/themes'
import UserModel from '@/models/user/UserModel'
import storeGetters from '@/storeGetters'
import store from '@/store'
import DashboardController from '@/api/dashboardController'
import IncidentManagerModel from '@/models/claim/IncidentManagerModel'
import IncidentManagerShiftTimeModel from '@/models/user/IncidentManagerShiftTimeModel'
import IncidentManagerDashboardDataModel from '@/models/claim/IncidentManagerDashboardDataModel'
import { Worker } from '@/components/dashboard/worker'
import storeMutations from '../../storeMutations'

@Component
export default class IncidentManagerTable extends Vue {
  private search = ''
  private headers: any = []
  private sortable = false
  private editCETNews = false
  private selectedIncidentManager: UserModel | null = null
  private incidentManagerData: IncidentManagerModel[] = []
  private isLoading = false
  private workers: Worker[] = storeGetters.getWorkers()
  @Prop() private ready: boolean
  @Prop() private tableName: string

  public async created() {
    this.headers = [
      { text: 'Rank', value: 'rank', sortable: false, align: 'center' },
      { text: '', value: 'userImage', sortable: false, class: 'team-img-head' },
      { text: 'User', value: 'name', sortable: false, align: 'left' },
      { text: 'Team', value: 'teamName', sortable: false, align: 'left' },
      {
        text: 'Tasks Answered',
        value: 'taskAnswered',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Tasks Missed',
        value: 'taskMissed',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Jobs Logged',
        value: 'jobLogged',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Compliments Received',
        value: 'complimentsReceived',
        sortable: false,
        align: 'center',
      },
    ]
    if (this.incidentManagerList && this.incidentManagerList.length > 0) {
      this.selectedIncidentManager = this.incidentManagerList[0]
      this.getIncidentManagerDashboardData(this.selectedIncidentManager.id)
    }
    this.updateStoreWorkers()
  }

  private async updateStoreWorkers(): Promise<void> {
    const workers = await DashboardController.GetWorkers()
    if (workers) {
      storeMutations.setWorkers(workers)
      this.workers = workers
    }
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private get isUserRoleIM(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'IncidentManager'
    )
  }

  private get getUserName(): string {
    return store.Instance.state.SessionDetail.userName
  }

  private getIncidentManagerDashboardData(incidentManagerId: string) {
    DashboardController.GetIncidentManagerDashboardData(incidentManagerId).then(
      (res: IncidentManagerDashboardDataModel | null) => {
        if (res) {
          this.incidentManagerData = res.incidentManagers
        }
      }
    )
  }

  private get incidentManagerList(): UserModel[] {
    return storeGetters.getIncidentManagers()
  }

  private mounted() {
    SetThemeForJobType(this, undefined)
  }

  private getWorkerById(id: string) {
    return this.workers.filter((x) => x.attributes.userId === id)
  }

  private isInShift(id: string) {
    const currWorker = this.workers.find((x) => x.attributes.userId === id)
    if (currWorker && currWorker.attributes.shiftStartTime && currWorker.attributes.shiftEndTime) {
      if (
        moment().isBetween(moment(currWorker.attributes.shiftStartTime), moment(currWorker.attributes.shiftEndTime))
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  private setBorderClass(id: string): string {
    // get shift from id
    const workerFilter = this.getWorkerById(id)
    const currWorker = workerFilter[0]
    if (currWorker) {
      if (currWorker.activityName !== 'Offline' && currWorker.activityName !== 'Idle' && this.isInShift(id)) {
        return 'team-member-img-amber'
      } else if (currWorker.activityName !== 'Offline' && !this.isInShift(id)) {
        return 'team-member-img-amber'
      } else if (currWorker.activityName === 'Offline' && this.isInShift(id)) {
        return 'team-member-img-red'
      } else if (currWorker.activityName === 'Idle' && this.isInShift(id)) {
        return 'team-member-img-green'
      } else {
        return 'team-member-img'
      }
    } else {
      return 'team-member-img'
    }
  }

  private setClassForSelectedIM(name: string) {
    if (
      (this.isUserRoleIM && this.getUserName === name) ||
      (this.isUserRoleAdministrator &&
        this.selectedIncidentManager &&
        this.selectedIncidentManager.displayValue === name)
    ) {
      return 'selected-manager'
    } else {
      return ''
    }
  }

  private onIncidentManagerChange(incidentManager: IncidentManagerModel) {
    this.getIncidentManagerDashboardData(incidentManager.id)
  }

  private get getUsers(): UserModel[] {
    return storeGetters.getUsers()
  }

  private getImageUrl(userId: string): string {
    if (userId) {
      const user: UserModel | undefined = this.getUsers.find((e: UserModel) => e.id === userId)
      if (user) {
        return user.profileThumbnailUrlWithUnknownFallback
      }
    }
    return '/img/unknownuser.png'
  }
}
</script>

<style scoped>
.system-info h2 {
  font-weight: normal;
  font-size: 24px;
}
.team-member-img {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
}
.team-member-img-green {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img-green img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #00c01a;
  border-radius: 50%;
}
.team-member-img-red {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img-red img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #ff0000;
  border-radius: 50%;
}
.team-member-img-amber {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img-amber img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #ffbb00;
  border-radius: 50%;
}
.team-member-img.b-red img {
  border-color: #cf1e60;
}
.team-member-img.b-pink img {
  border-color: #ff8a65;
}
.gridView {
  border: 0px;
}
.incident-manager-list >>> .v-table thead > tr > th:nth-child(1) {
  width: 12px;
  padding-right: 24px !important;
}
.incident-manager-list >>> .v-table thead > tr > th:nth-child(2) {
  min-width: 80px;
}
.incident-manager-list >>> .v-table thead > tr > th:nth-child(3) {
  min-width: 250px;
}
.incident-manager-list >>> .v-table thead > tr > th:nth-child(4) {
  min-width: 150px;
}
.incident-manager-list >>> .v-table tbody > tr.selected-manager {
  background: #e8e8e8;
}
.incident-manager-list >>> .v-table tbody > tr.selected-manager td {
  font-weight: 600;
}
</style>
