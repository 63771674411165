<template>
  <v-layout wrap class="mb-2">
    <v-flex v-if="complaint.severity" xs12>
      <span>
        Complaint Severity:
        <b>{{ complaint.severity }}</b>
      </span>
    </v-flex>
    <v-flex v-if="getComplaintType" xs12 mt-1>
      <span>
        Complaint Type:
        <b>{{ getComplaintType }}</b>
      </span>
    </v-flex>
    <v-flex v-if="complaint && complaint.resolvedByUserName" xs12 mt-1>
      <span>
        Agent Name:
        <b>{{ complaint.resolvedByUserName }}</b>
      </span>
    </v-flex>
    <v-flex v-if="complaint && complaint.isEscalated" xs12 mt-1>
      <span>
        Complaint Escalated:
        <b>{{ complaint.isEscalated ? 'Yes' : 'No' }}</b>
      </span>
    </v-flex>
    <v-flex v-if="complaint" xs12 mt-1>
      <span>
        Complaint Resolved:
        <b>{{ complaint.isResolved ? 'Yes' : 'No' }}</b>
      </span>
    </v-flex>
    <v-flex v-if="complaint && complaint.isResolved" xs12 mt-1>
      <span>
        Resolved Date:
        <b>
          {{ complaint.resolvedDate ? getFormattedDateTime(complaint.resolvedDate) : '-' }}
        </b>
      </span>
    </v-flex>
    <v-flex v-if="complaint && complaint.reopenedDate && !complaint.isResolved" xs12 mt-1>
      <br />
      <span>(Reopened {{ getFormattedDateTime(complaint.reopenedDate) }})</span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import ComplaintModel from '@/models/claim/ComplaintModel'
import { RecordType } from '@/common/enums'
import storeGetters from '@/storeGetters'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import Shared from '../../common/shared'
import Store from '@/store'
import moment, { Moment } from 'moment'

@Component
export default class ComplaintCard extends TimeLineItemBase {
  private complaintSeverityList: any = []
  private complaintTypeList: MasterRecord[] = []

  private get complaint(): ComplaintModel | null {
    return storeGetters.getComplaintById(this.jobId, this.itemId)
  }

  private created() {
    this.GetComplaintTypeList()
  }
  // Get list of complaint types.
  private GetComplaintTypeList(): void {
    const recordType: string = RecordType[RecordType.ComplaintType]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.complaintTypeList = res
      }
    })
  }

  private get getComplaintType(): string | null | undefined {
    if (this.complaintTypeList) {
      const getComplaintType: MasterRecord | undefined = this.complaintTypeList.find((c) =>
        this.complaint ? c.id === this.complaint.complaintType : false
      )
      if (getComplaintType) {
        return getComplaintType.description
      }
    }
    return null
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
