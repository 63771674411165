<template>
  <v-card v-if="weatherForecast != null" id="weather">
    <div id="display">
      <div id="top">
        <div class="location">{{ locationTitle }}</div>
        <div class="time">{{ formatDate(selectedItem.forecastDate) }}</div>
        <div class="status">
          {{ selectedItem.daytimeForecast.weatherTypeDescription }}
        </div>
      </div>

      <div id="left-information">
        <img
          :src="getThumbnail(selectedItem.daytimeForecast.weatherTypeCode)"
          :alt="selectedItem.daytimeForecast.weatherTypeDescription"
          class="thumbnail"
        />
        <span class="temperature">
          {{ selectedItem.daytimeForecast.temperature }}
        </span>
        <span class="unit">°C</span>
      </div>

      <div id="right-information">
        <read-more
          more-str="Read more..."
          :text="selectedItem.regionalText"
          link="#"
          less-str="Read less"
          :max-chars="50"
          class="regionalTextForecast"
        ></read-more>
        <span>
          Precipitation:
          {{ selectedItem.daytimeForecast.precipitationProbability }}%
        </span>
        <br />
        <span>Humidity: {{ selectedItem.daytimeForecast.humidity }}%</span>
        <br />
        <span>Wind speed: {{ selectedItem.daytimeForecast.windSpeed }} km/h</span>
      </div>

      <div id="forecast">
        <ul>
          <template v-for="(f, i) in weatherForecast.forecastItems">
            <li v-if="i < 5" :key="i" :class="{ selectedDay: selectedIndex === i }" @click="selectedIndex = i">
              <div>
                {{ dayOfWeek(f.forecastDate) }}
                <br />
                {{ shortDate(f.forecastDate) }}
              </div>
              <img
                :src="getThumbnail(f.daytimeForecast.weatherTypeCode)"
                :alt="f.daytimeForecast.weatherTypeDescription"
              />
              <div>
                <b>{{ f.daytimeForecast.temperature }}°</b>
                {{ f.nighttimeForecast.temperature }}°
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { WeatherForecastModel, DayWeatherForecast, ForecastItem } from '@/models/WeatherForecastModel'
import moment from 'moment'
import ReadMore from 'vue-read-more'
import storeGetters from '@/storeGetters'
import Job from '@/models/Job'

@Component
export default class WeatherWidget extends Vue {
  @Prop() private jobId: string

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private get weatherForecast(): WeatherForecastModel | null {
    return this.job ? this.job.weatherForecast : null
  }

  private get locationTitle(): string | null {
    if (this.job) {
      let out = this.job.addressLine3 || ''
      out = out.trim()
      out = out ? out + ' ' : out
      out += this.job.addressLine4 || ''
      return out
    }
    return ''
  }

  private selectedIndex = 0
  private get selectedItem(): any {
    if (!this.weatherForecast) {
      return null
    }
    return this.weatherForecast.forecastItems[this.selectedIndex]
  }

  private created(): void {
    Vue.use(ReadMore)
  }

  private formatDate(date: Date) {
    return moment(String(date)).format('DD MMM YYYY')
  }

  private shortDate(date: Date) {
    return moment(String(date)).format('DD MMM')
  }

  private dayOfWeek(date: Date) {
    return moment(String(date)).format('dddd').substring(0, 3)
  }

  private getThumbnail(weatherTypeCode: string) {
    switch (weatherTypeCode.toLowerCase()) {
      case 'na':
        return '/img/weather/no-data.png'
      case '0':
        return '/img/weather/clear-night.svg'
      case '1':
        return '/img/weather/sunny-day.svg'
      case '2':
        return '/img/weather/partly-cloudy-night.svg'
      case '3':
        return '/img/weather/sunny-intervals.svg'
      case '4':
        return '/img/weather/no-data.png'
      case '5':
        return '/img/weather/mist.svg'
      case '6':
        return '/img/weather/fog.svg'
      case '7':
        return '/img/weather/cloudy.svg'
      case '8':
        return '/img/weather/overcast.svg'
      case '9':
        return '/img/weather/light-rain-shower-night.svg'
      case '10':
        return '/img/weather/light-rain-shower-day.svg'
      case '11':
        return '/img/weather/drizzle.svg'
      case '12':
        return '/img/weather/light-rain.svg'
      case '13':
        return '/img/weather/heavy-rain-shower-night.svg'
      case '14':
        return '/img/weather/heavy-rain-shower-day.svg'
      case '15':
        return '/img/weather/heavy-rain.svg'
      case '16':
        return '/img/weather/sleet-shower-night.svg'
      case '17':
        return '/img/weather/sleet-shower-day.svg'
      case '18':
        return '/img/weather/sleet.svg'
      case '19':
        return '/img/weather/hail-shower-night.svg'
      case '20':
        return '/img/weather/hail-shower-day.svg'
      case '21':
        return '/img/weather/hail.svg'
      case '22':
        return '/img/weather/light-snow-shower-night.svg'
      case '23':
        return '/img/weather/light-snow-shower-day.svg'
      case '24':
        return '/img/weather/light-snow.svg'
      case '25':
        return '/img/weather/heavy-snow-shower-night.svg'
      case '26':
        return '/img/weather/heavy-snow-shower-day.svg'
      case '27':
        return '/img/weather/heavy-snow.svg'
      case '28':
        return '/img/weather/thunder-shower-night.svg'
      case '29':
        return '/img/weather/thunder-shower-day.svg'
      case '30':
        return '/img/weather/thunder.svg'
    }
  }
}
</script>

<style scoped>
li:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

li.selectedDay {
  background-color: #bdbdbd;
}

.regionalTextForecast p {
  margin-bottom: 0px;
}

.regionalTextForecast {
  margin-bottom: 10px;
}

#weather {
  margin: auto;
  width: 100%;
}

#display {
  padding: 10px;
}

#top {
  margin-bottom: 10px;
}

#top .location {
  font-size: 18px;
  line-height: 1.2;
}

#top .time {
  font-size: 14px;
  line-height: 2;
}

#top .status {
  font-size: 12px;
  line-height: 1.4;
}

#left-information {
  color: #212121;
  width: 100px;
  float: left;
  display: block;
}

#left-information .thumbnail {
  float: left;
  height: 64px;
  width: 64px;
}

#left-information .temperature {
  float: left;
  padding-left: 5px;
  padding-top: 15px;
  font-size: 36px;
}

#left-information .unit {
  float: left;
  margin-top: 20px;
  font-size: 10px;
}

#right-information {
  float: right;
  line-height: 1.5;
  max-width: 70%;
}

#forecast {
  padding-top: 10px;
  clear: both;
}

#forecast ul li {
  display: inline-block;
  height: 120px;
  width: 20%;
  text-align: center;
  font-size: 12px;
}
#forecast ul {
  padding-left: 0;
}
@media (max-width: 1199px) {
  #forecast ul li img {
    max-width: 40px;
  }
}
</style>
