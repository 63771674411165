<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>{{ JobTypeLabel('emergency detail') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.native="closeEmergencyDetailDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-3 scroll-content-dialog">
      <v-form autocomplete="off">
        <v-layout wrap>
          <v-flex xs12>
            <v-select
              v-model="localEmergencyId"
              v-validate="'required'"
              :items="emergencies"
              :label="'Select ' + JobTypeLabel('emergency')"
              :disabled="localEmergencyId ? true : false"
              item-text="description"
              item-value="emergencyId"
              class="required"
              required
              data-vv-scope="frmEmergencyDetail"
              :data-vv-name="JobTypeLabel('emergency')"
              :error-messages="errors.collect(JobTypeLabel('emergency'))"
            />
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="tradeId"
              v-validate="'required'"
              :items="localTradeList"
              item-value="tradeId"
              item-text="description"
              label="Trade"
              class="required trade-items"
              required
              data-vv-scope="frmEmergencyDetail"
              data-vv-name="Trade"
              :error-messages="errors.collect('Trade')"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title
                      :class="getClassName(data.item.tradeId)"
                      v-html="data.item.description"
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model.trim="description"
              v-validate="'required|max:50'"
              :label="JobTypeLabel('emergency detail')"
              maxlength="50"
              data-vv-scope="frmEmergencyDetail"
              :data-vv-name="JobTypeLabel('emergency detail')"
              :error-messages="errors.collect(JobTypeLabel('emergency detail'))"
              class="required"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="integrationReference"
              v-validate="'required'"
              :items="productCategories"
              item-value="id"
              item-text="description"
              label="Integration Reference"
              class="trade-items"
              data-vv-scope="frmEmergencyDetail"
              data-vv-name="Integration"
              :error-messages="errors.collect('Integration')"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title
                      :class="getClassName(data.item.tradeId)"
                      v-html="data.item.description"
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3 pb-2">
      <v-spacer></v-spacer>
      <v-btn flat="flat" @click.native="closeEmergencyDetailDialog">Cancel</v-btn>
      <v-btn
        color="primary"
        class="mr-0"
        :disabled="isLoading"
        :loading="isLoading"
        @click.native="saveEmergencyDetail"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import Shared from '@/common/shared'
import { JobType } from '../common/enums'
import eventBus from '@/common/bus'
import { ProductCategory } from '@/models/ProductCategory'
import { defaultProspectSoftCategory, prospectSoftCategories } from '@/data/prospectSoftCategories'

@Component
export default class EmergencyDetail extends Vue {
  @Prop() private emergencies: EmergencyModel[]
  @Prop() private trades: TradeModel[]
  @Prop() private emergencyId: number
  @Prop() private emergencyDetail: any
  @Prop({ default: 'HE' }) private jobType: JobType

  private description = ''
  private tradeId: number | null = null
  private localEmergencyId: number | null = null
  private localEmergencyDetail: EmergencyDetailModel = new EmergencyDetailModel()
  private isLoading = false
  private localTradeList: TradeModel[] = []
  private productCategories: ProductCategory[] = prospectSoftCategories
  private integrationReference: string = defaultProspectSoftCategory.id

  @Watch('localEmergencyId')
  protected setLocalEmergencyId(newValue: number | null) {
    this.localEmergencyId = newValue
  }

  @Watch('localEmergencyDetail')
  protected setLocalEmergencyDetail(newValue: EmergencyDetailModel) {
    this.localEmergencyDetail = newValue
    if (this.localEmergencyDetail === null || this.localEmergencyDetail === undefined) {
      this.localEmergencyDetail = new EmergencyDetailModel()
    }
    if (this.localEmergencyDetail.emergencyDetailId !== undefined) {
      this.description = this.localEmergencyDetail.description
      this.tradeId = this.localEmergencyDetail.tradeId
      this.integrationReference = this.localEmergencyDetail.integrationReference || defaultProspectSoftCategory.id
    }
    this.localTradeList = this.trades.filter((t) => !t.isDeleted || (t.isDeleted && t.tradeId === this.tradeId))
  }

  private created() {
    this.localEmergencyId = this.emergencyId
    this.localEmergencyDetail = this.emergencyDetail
  }

  private closeEmergencyDetailDialog() {
    this.$emit('closeEmergencyDetail')
  }

  private saveEmergencyDetail() {
    this.validate()
      .then((result: boolean) => {
        if (result && this.localEmergencyId) {
          this.isLoading = true
          const emergencyDetail: EmergencyDetailModel = new EmergencyDetailModel()
          emergencyDetail.typeId = this.localEmergencyId
          emergencyDetail.emergencyDetailId = this.localEmergencyDetail.emergencyDetailId || 0
          emergencyDetail.description = this.description
          emergencyDetail.tradeId = this.tradeId
          emergencyDetail.integrationReference = this.integrationReference
          this.$emit('saveEmergencyDetail', emergencyDetail)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private JobTypeLabel(name: string) {
    return Shared.JobTypeLabel(name, this.jobType)
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmEmergencyDetail')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private getClassName(tradeId: number) {
    const trade = this.trades.find((e) => e.tradeId === tradeId)
    return trade && trade.isDeleted ? 'deleted-item' : ''
  }
}
</script>
