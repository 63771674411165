<template>
  <div>
    <div class="mb-2">
      <h4>Abandoned {{ productType }}</h4>
    </div>
    <div v-if="item.abandonedReason" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.abandonedReason }}</span>
    </div>
    <div v-if="item.abandonedReasonNotes" class="mb-1">
      <label>Notes: </label>
      <span>{{ item.abandonedReasonNotes }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddAbandonedSIProductRequest from '@/models/siteInvestigation/requests/AddAbandonedSIProductRequest'
import { SIProductType } from '@/common/enums'

@Component
export default class AddAbandonedSIProductRequestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddAbandonedSIProductRequest {
    return Object.assign(new AddAbandonedSIProductRequest(), this.requestBody)
  }

  private get productType() {
    switch (this.item.siProductType as SIProductType) {
      case SIProductType.CCTV:
        return 'CCTV Control'
      case SIProductType.Datum:
        return 'Datum Control'
      case SIProductType.WaterMains:
        return 'Water Test Control'
      case SIProductType.Pit:
        return 'Trial Pit Control'
      default:
        return ''
    }
  }
}
</script>
