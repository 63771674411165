import moment from 'moment'

export default class UnlinkedPhoneCallsModel {
  public recordingId: string
  public callDateTime: moment.Moment
  public from: string
  public to: string
  public duration: number
  public recordingUrl: string
  public callSid: string
  public policyScheduleName: string

  public constructor() {
    this.callDateTime = moment()
  }
}
