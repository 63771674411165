import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { IAuthState } from '../states/interfaces/auth-state'
import AuthState from '../states/auth-state'
import AuthorizationController from '@/api/authorizationController'
import AuthPolicies from '@/models/auth/auth-policies'

const authState: IAuthState = new AuthState()

const getters: GetterTree<IAuthState, any> = {
  complaints: (state) => state.policies.complaints,
  contractors: (state) => state.policies.contractors,
  clients: (state) => state.policies.clients,
  policies: (state) => state.policies,
}

const mutations: MutationTree<IAuthState> = {
  setAuthPolicies: (state, policies: AuthPolicies) => {
    state.policies = policies
  },
}

const actions: ActionTree<IAuthState, any> = {
  async submitGetAuthPolicies({ commit }) {
    const policies = await AuthorizationController.GetUserPolicies()
    commit('setAuthPolicies', policies || new AuthPolicies())
  },
}

export const authModule: Module<IAuthState, any> = {
  state: authState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
