import { Vue, Prop } from 'vue-property-decorator'

export default abstract class DialogWizard extends Vue {
  public static WizardPageChangedEventName = 'wizardPageChanged'
  public static WizardCompleteEventName = 'wizardComplete'
  public static WizardVisiblePropName = 'wizardVisible'
  public static WizardBackEndOperationEventName = 'wizardBackEndOperation'

  @Prop() public wizardVisible: boolean

  public abstract processCurrentPage(): void
  protected abstract wizardVisibilityChanged(newValue: boolean): void

  protected wizardPageChanged(value: string): void {
    this.$emit(DialogWizard.WizardPageChangedEventName, value)
  }

  protected wizardBackEndOperation(value: boolean): void {
    this.$emit(DialogWizard.WizardBackEndOperationEventName, value)
  }

  protected updateContentHeight(value: number) {
    this.$emit('updateContentHeight', value)
  }

  protected sendWizardCompleteEvent(): void {
    this.$emit(DialogWizard.WizardCompleteEventName)
  }
}
