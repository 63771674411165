<template>
  <div class="taskboard-wrapper">
    <div class="filter">
      <v-layout wrap>
        <v-spacer></v-spacer>
        <v-flex xs12 py-0 class="text-xs-right">
          <span class="search-contractor-jobs">
            <v-autocomplete
              v-model="selectedJobType"
              :items="getJobtypes"
              item-text="name"
              item-value="id"
              hide-details
              label="Job Type"
              class="job-type"
              :loading="loading.jobType"
              name="search-contractor-jobs"
              @change="filterJobsBySelectedJobType($event)"
            ></v-autocomplete>
          </span>
          <span class="search-contractor-jobs pl-2">
            <v-autocomplete
              v-model="selectedContractor"
              :items="contractors"
              item-text="companyName"
              item-value="id"
              hide-details
              label="Search contractor"
              :loading="loading.contractors"
              name="search-contractor-jobs"
              class="search-contractorJobs"
              @change="filterJobsBySelectedContractor($event)"
            ></v-autocomplete>
          </span>
          <span class="search-contractor-jobs pl-2">
            <v-autocomplete
              v-model="selectedClaimManagementCompany"
              :items="claimManagementCompanies"
              item-text="description"
              item-value="description"
              hide-details
              label="Claim Management Company"
              :loading="loading.claimManagementCompanies"
              name="search-contractor-jobs"
              class="search-claimManagementCompany"
              @change="filterJobsBySelectedClaimManagementCompany($event)"
            ></v-autocomplete>
          </span>
        </v-flex>
      </v-layout>
    </div>
    <div v-show="!loading.jobs" class="jobs-taskboard">
      <v-layout wrap class="scrollmenu pa-0">
        <v-flex v-for="(item, index) in getJobs" :key="index" xs2>
          <JobStatusBar
            :key="index"
            ref="jobStatusBar"
            :record="item"
            @filterDelay="filterDelay"
            @filterVisitCompleted="filterVisitCompleted"
          ></JobStatusBar>
          <div v-if="showLoadMoreButton(item)" class="task-action text-xs-center grey lighten-2 px-2">
            <v-progress-linear v-if="activeIndex === index" :indeterminate="true" height="4"></v-progress-linear>
            <v-btn color="primary" class="btn-loadMore" flat small block @click="loadMoreJobs(item, index)">
              <b>Load More</b>
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div v-show="loading.jobs">
      <v-progress-circular
        style="position: absolute; top: 400px; left: 50%"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import JobStatusBar from '@/components/JobStatusBar.vue'
import {
  SIJobStatus,
  PostVisitActionsTypeEnum,
  SIProductType,
  UploadedDocumentTypeEnum,
  SIJobType,
} from '@/common/enums'
import OutstandingJobModel from '@/models/OutstandingJobModel'
import SIReportModel from '@/models/claim/SIReportModel'
import DashboardController from '@/api/dashboardController'
import SiteInvestigationController from '@/api/siteInvestigationController'
import moment, { Moment } from 'moment'
import ContractorModel from '@/models/contractor/ContractorModel'
import ISIJobStatusBarModel from '@/models/ISIJobStatusBarModel'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import SessionController from '@/api/sessionController'
import { log } from 'util'
import ClaimManagementCompanyModel from '../models/ClaimManagementCompanyModel'
import SIDashboardReportModel from '@/models/claim/SIDashboardReportModel'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'

const DEFAULT_ETA_COLORS = {
  overdue: 'red',
  nearing: 'orange',
  ok: 'grey',
}

const PROGRESS_ETA_COLORS = {
  overdue: 'red',
  nearing: 'orange',
  ok: 'blue',
}

@Component({
  name: 'SIJobDashboard',
  components: { JobStatusBar },
})
export default class SIJobDashboard extends Vue {
  public loading: Record<string, boolean> = {
    jobs: false,
    contractors: false,
    claimManagementCompanies: false,
    jobType: false,
  }
  public contractors: ContractorModel[] = []
  public claimManagementCompanies: ClaimManagementCompanyModel[] = []
  public selectedContractor = ''
  public selectedClaimManagementCompany = ''
  public sIJobManagementHub: SignalRHubConnection = new SignalRHubConnection('siJobDashboard')
  private selectedFilteredVisitStatus: string = PostVisitActionsTypeEnum[PostVisitActionsTypeEnum.All]
  private selectedDelayReason = 'All'
  private recordCount = 0
  private defaultLoadMoreRecordCount: number = Shared.defaultLoadMoreRecordCount
  private activeIndex = -1
  private selectedJobType = ''

  private siJobs: ISIJobStatusBarModel[] = [
    {
      index: 1,
      status: SIJobStatus[SIJobStatus.New],
      items: [],
      headerClass: 'red--text pb-0',
      totalJobsCount: 0,
    },
    {
      index: 2,
      status: SIJobStatus[SIJobStatus.CustomerNotified],
      items: [],
      headerClass: 'blue--text',
      totalJobsCount: 0,
    },
    {
      index: 3,
      status: SIJobStatus[SIJobStatus.VisitBooked],
      items: [],
      headerClass: 'orange--text',
      totalJobsCount: 0,
    },
    {
      index: 4,
      status: SIJobStatus[SIJobStatus.Testing],
      items: [],
      headerClass: 'green--text',
      totalJobsCount: 0,
    },
    {
      index: 5,
      status: SIJobStatus[SIJobStatus.ReportProvided],
      items: [],
      headerClass: 'orange--text',
      totalJobsCount: 0,
    },
    {
      index: 6,
      status: SIJobStatus[SIJobStatus.Monitoring],
      items: [],
      headerClass: 'blue--text',
      totalJobsCount: 0,
    },
  ]
  private reportDocuments: SIDashboardReportModel = new SIDashboardReportModel()

  public async created() {
    this.sIJobManagementHub.addHandler('job-message', this.handleJobUpdate)
    this.sIJobManagementHub.connect()
  }

  public async mounted() {
    this.$set(this.loading, 'jobs', true)
    await this.getContractors()
    await this.getClaimManagementCompanies()
    await this.getSIJobs()
    this.$set(this.loading, 'jobs', false)
  }

  public destroyed() {
    if (this.sIJobManagementHub != null) {
      this.sIJobManagementHub.disconnect()
    }
  }

  public get getJobs(): any {
    return this.siJobs.sort((a, b) => a.index - b.index)
  }

  private get getJobtypes() {
    const jobTypes: any = [{ id: '0', name: 'All' }]
    // convert si job type enum into an array
    for (const [propertyKey, propertyValue] of Object.entries(SIJobType)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue
      }
      jobTypes.push({
        id: propertyValue,
        name: Shared.insertSpaceInEnumName(propertyKey),
      })
    }
    this.selectedJobType = '0'
    return jobTypes
  }

  public async getContractors() {
    this.$set(this.loading, 'contractors', true)

    const response = await SiteInvestigationController.GetContractors()
    this.contractors = response || []

    if (this.contractors.length > 1) {
      // add extra label for all selected contractor
      const addDummyLabel: ContractorModel = new ContractorModel()
      addDummyLabel.companyName = 'All'
      addDummyLabel.id = '0'
      this.contractors.unshift(addDummyLabel)
      this.selectedContractor = '0'
    } else {
      if (this.contractors.length) {
        this.selectedContractor = this.contractors[0].id
      }
    }

    this.$set(this.loading, 'contractors', false)
  }

  public async getClaimManagementCompanies() {
    this.$set(this.loading, 'claimManagementCompanies', true)

    try {
      const isDataExists = true
      const response = await SiteInvestigationController.GetClaimManagementCompanies()
      this.claimManagementCompanies = response || []

      const addDummyLabel: ClaimManagementCompanyModel = new ClaimManagementCompanyModel()
      addDummyLabel.description = 'All'
      addDummyLabel.id = 0
      this.claimManagementCompanies.unshift(addDummyLabel)
      this.selectedClaimManagementCompany = 'All'
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading claim management company list, please try again', true)
    }

    this.$set(this.loading, 'claimManagementCompanies', false)
  }

  private async handleJobUpdate(jobId: string, oldStatus: string, newStatus: string) {
    const index = this.reportDocuments.siReportItems.findIndex((item) => item.id === jobId)
    let response: SIReportModel[] = []
    if (newStatus === SIJobStatus[SIJobStatus.Closed] && index !== -1) {
      // all the planned monitoring visit is added and invoiced, remove job from dashboard
      this.reportDocuments.siReportItems.splice(index, 1)
    } else {
      response = await SiteInvestigationController.GetSIJobDetail(jobId)
      if (response && response.length > 0) {
        if (index >= 0) {
          this.reportDocuments.siReportItems.splice(index, 1, response[0])
        } else {
          this.reportDocuments.siReportItems.push(response[0])
        }
      }
    }
    // update count based on status
    this.updateTotalJobsCount(response && response.length > 0 ? response[0] : null, oldStatus, newStatus)
    this.setSIJobs()
  }

  private async getSIJobs(status = '') {
    const response: SIDashboardReportModel | null = await this.getSIJobsData(status)
    if (
      this.activeIndex !== -1 &&
      response &&
      response.siReportItems.length > 0 &&
      this.reportDocuments.siReportItems.length > 0
    ) {
      response.siReportItems.forEach((job) => {
        const index = this.reportDocuments.siReportItems.findIndex((e) => e.id === job.id)
        if (index === -1) {
          this.reportDocuments.siReportItems.push(job)
        }
      })
    } else {
      this.reportDocuments = response || new SIDashboardReportModel()
    }
    this.setSIJobs()
  }

  private async getSIJobsData(status = '') {
    return await SiteInvestigationController.GetSIJobs(
      this.recordCount, // current record count
      this.selectedContractor === '0' ? '' : this.selectedContractor, // selected contractor
      status, // column in which load more button is clicked
      this.selectedClaimManagementCompany === 'All' ? '' : this.selectedClaimManagementCompany, // selected client mngt company
      this.selectedJobType === '0' ? '' : this.selectedJobType, // selected job type
      this.selectedDelayReason === 'All' ? '' : this.selectedDelayReason, // selected delay reason
      this.selectedFilteredVisitStatus === PostVisitActionsTypeEnum[PostVisitActionsTypeEnum.All]
        ? ''
        : this.selectedFilteredVisitStatus // selected visit status
    )
  }

  private setSIJobs(): void {
    const self = this
    self.siJobs.forEach((element) => {
      const toBeVerified = (item: SIReportModel, itemType: SIProductType) =>
        item.itemsToVerify &&
        item.itemsToVerify.includes(itemType) &&
        (!item.verifiedItems || (item.verifiedItems && !item.verifiedItems.includes(itemType)))

      let itemFilter: (item: SIReportModel) => boolean = () => true

      switch (this.selectedFilteredVisitStatus) {
        case PostVisitActionsTypeEnum.TrialPitOutstanding:
          itemFilter = (x) => toBeVerified(x, SIProductType.Pit)
          break
        case PostVisitActionsTypeEnum.CCTVOutstanding:
          itemFilter = (x) => toBeVerified(x, SIProductType.CCTV)
          break
        case PostVisitActionsTypeEnum.DatumOutstanding:
          itemFilter = (x) => toBeVerified(x, SIProductType.Datum)
          break
        case PostVisitActionsTypeEnum.WaterMainsOutstanding:
          itemFilter = (x) => toBeVerified(x, SIProductType.WaterMains)
          break
        case PostVisitActionsTypeEnum.TreeRootsOutstanding:
          itemFilter = (x) =>
            x.missingTestingDocuments ? x.missingTestingDocuments.includes(UploadedDocumentTypeEnum.TreeRoots) : false
          break
        case PostVisitActionsTypeEnum.LabSummaryOustanding:
          itemFilter = (x) =>
            x.missingTestingDocuments ? x.missingTestingDocuments.includes(UploadedDocumentTypeEnum.LabSummary) : false
          break
        case PostVisitActionsTypeEnum.Verified:
          itemFilter = (x) => x.isVerified && x.testingComplete
          break
      }

      const items = self.reportDocuments.siReportItems.filter(
        (x) =>
          x.status === element.status &&
          (self.selectedContractor === '0' || self.selectedContractor === x.contractorId) &&
          (self.selectedClaimManagementCompany === 'All' ||
            self.selectedClaimManagementCompany === x.claimManagementCompany) &&
          (x.status === SIJobStatus[SIJobStatus.Testing] &&
          this.selectedFilteredVisitStatus !== PostVisitActionsTypeEnum.All
            ? itemFilter(x)
            : true) &&
          (x.status === SIJobStatus[SIJobStatus.New] && this.selectedDelayReason !== 'All'
            ? x.delayCode === this.selectedDelayReason
            : true) &&
          (this.selectedJobType !== '0' ? (x.jobType !== null ? x.jobType === this.selectedJobType : false) : true)
      )
      const siJobs = self.sort(items)
      element.items = self.updateETAColors(siJobs)
      element.totalJobsCount = this.getJobsTotalCount(element.status)
    })

    this.dataLoaded()
  }

  private getJobsTotalCount(status: string): number {
    switch (status) {
      case SIJobStatus[SIJobStatus.New]:
        return this.reportDocuments.totalNewJobsCount
      case SIJobStatus[SIJobStatus.CustomerNotified]:
        return this.reportDocuments.totalCustomerNotifiedJobsCount
      case SIJobStatus[SIJobStatus.VisitBooked]:
        return this.reportDocuments.totalVisitBookedJobsCount
      case SIJobStatus[SIJobStatus.Testing]:
        return this.reportDocuments.totalTestingJobsCount
      case SIJobStatus[SIJobStatus.ReportProvided]:
        return this.reportDocuments.totalReportProvidedJobsCount
      case SIJobStatus[SIJobStatus.Monitoring]:
        return this.reportDocuments.totalMonitoringJobsCount
      default:
        return 0
    }
  }

  private sort(jobs: SIReportModel[]) {
    // sort jobs data eta vise
    jobs.sort((a, b) => {
      a.etaFrom = typeof a.etaFrom === 'string' ? moment(a.etaFrom) : a.etaFrom
      b.etaFrom = typeof b.etaFrom === 'string' ? moment(b.etaFrom) : b.etaFrom

      if (a.etaFrom && b.etaFrom && a.etaFrom.isBefore(b.etaFrom)) {
        return -1
      } else if (a.etaFrom && b.etaFrom && a.etaFrom.isAfter(b.etaFrom)) {
        return 1
      } else {
        return 0
      }
    })

    return jobs
  }

  private updateETAColors(jobs: SIReportModel[]) {
    jobs.map((x) => {
      let color

      switch (x.status) {
        case SIJobStatus[SIJobStatus.VisitBooked]:
          color = PROGRESS_ETA_COLORS[this.getETAStatus(x.etaTo)]
          break
        case SIJobStatus[SIJobStatus.Monitoring]:
          color = 'blue'
          break
        default:
          color = DEFAULT_ETA_COLORS[this.getETAStatus(x.etaFrom)]
          break
      }

      x.dashboardDisplayData = { etaColor: color }
      return x
    })
    return jobs
  }

  private getETAStatus(eta: moment.Moment | null | undefined) {
    if (eta) {
      const now = moment()
      const minutes = now.diff(eta, 'minutes')

      if (minutes > 0) {
        return 'overdue'
      } else if (minutes <= 0 && minutes >= -30) {
        return 'nearing'
      }
    }

    return 'ok'
  }

  private filterVisitCompleted(visitCompletedStatus: string) {
    this.selectedFilteredVisitStatus = visitCompletedStatus
    this.getSIJobs()
  }

  private filterDelay(delayReason: string) {
    this.selectedDelayReason = delayReason
    this.getSIJobs()
  }

  private dataLoaded() {
    this.$set(this.loading, 'jobs', false)
    this.$set(this.loading, 'contractors', false)
    this.$set(this.loading, 'claimManagementCompanies', false)
    this.$set(this.loading, 'jobType', false)
    this.activeIndex = -1
    this.recordCount = 0
  }

  private filterJobsBySelectedContractor(selectedContractor = '0') {
    this.selectedContractor = selectedContractor
    this.$set(this.loading, 'contractors', true)
    this.getSIJobs()
  }

  private filterJobsBySelectedClaimManagementCompany(selectedClaimManagementCompany = '') {
    this.selectedClaimManagementCompany = selectedClaimManagementCompany
    this.$set(this.loading, 'claimManagementCompanies', true)
    this.getSIJobs()
  }

  private filterJobsBySelectedJobType(selectedJobType = '0') {
    this.selectedJobType = selectedJobType
    this.$set(this.loading, 'jobType', true)
    this.getSIJobs()
  }

  private loadMoreJobs(jobStatusBarItem: ISIJobStatusBarModel, index: number) {
    if (this.activeIndex === index) {
      return
    }
    this.activeIndex = index
    this.recordCount = jobStatusBarItem.items.length
    this.getSIJobs(jobStatusBarItem.status)
  }

  private showLoadMoreButton(jobStatusBarItem: ISIJobStatusBarModel) {
    return (
      jobStatusBarItem.items.length > 0 &&
      jobStatusBarItem.totalJobsCount >= this.defaultLoadMoreRecordCount &&
      jobStatusBarItem.items.length !== jobStatusBarItem.totalJobsCount &&
      (jobStatusBarItem.status === SIJobStatus[SIJobStatus.New] ? this.selectedDelayReason === 'All' : true) &&
      (jobStatusBarItem.status === SIJobStatus[SIJobStatus.Testing]
        ? this.selectedFilteredVisitStatus === PostVisitActionsTypeEnum[PostVisitActionsTypeEnum.All]
        : true)
    )
  }

  private updateTotalJobsCount(item: SIReportModel | null, oldStatus: string, newStatus: string) {
    if (
      (this.selectedContractor === '0' || (item ? this.selectedContractor === item.contractorId : true)) &&
      (this.selectedClaimManagementCompany === 'All' ||
        (item ? this.selectedClaimManagementCompany === item.claimManagementCompany : true))
    ) {
      // for cancelled job new status will be 'closed', so no need to increase count
      if (newStatus !== SIJobStatus[SIJobStatus.Closed]) {
        // as per new status, increase count
        this.increaseTotalJobCount(newStatus)
      }
      // for New job oldStatus and newStatus will be same, so no need to decrease count
      if (newStatus !== oldStatus) {
        // as per old status, decrease count
        this.reduceTotalJobCount(oldStatus)
      }
    }
  }

  private increaseTotalJobCount(status: string) {
    switch (status) {
      case SIJobStatus[SIJobStatus.New]:
        this.reportDocuments.totalNewJobsCount = this.reportDocuments.totalNewJobsCount + 1
        break
      case SIJobStatus[SIJobStatus.CustomerNotified]:
        this.reportDocuments.totalCustomerNotifiedJobsCount = this.reportDocuments.totalCustomerNotifiedJobsCount + 1
        break
      case SIJobStatus[SIJobStatus.VisitBooked]:
        this.reportDocuments.totalVisitBookedJobsCount = this.reportDocuments.totalVisitBookedJobsCount + 1
        break
      case SIJobStatus[SIJobStatus.Testing]:
        this.reportDocuments.totalTestingJobsCount = this.reportDocuments.totalTestingJobsCount + 1
        break
      case SIJobStatus[SIJobStatus.ReportProvided]:
        this.reportDocuments.totalReportProvidedJobsCount = this.reportDocuments.totalReportProvidedJobsCount + 1
        break
      case SIJobStatus[SIJobStatus.Monitoring]:
        this.reportDocuments.totalMonitoringJobsCount = this.reportDocuments.totalMonitoringJobsCount + 1
        break
      default:
        break
    }
  }

  private reduceTotalJobCount(status: string) {
    switch (status) {
      case SIJobStatus[SIJobStatus.New]:
        this.reportDocuments.totalNewJobsCount =
          this.reportDocuments.totalNewJobsCount > 0 ? this.reportDocuments.totalNewJobsCount - 1 : 0
        break
      case SIJobStatus[SIJobStatus.CustomerNotified]:
        this.reportDocuments.totalCustomerNotifiedJobsCount =
          this.reportDocuments.totalCustomerNotifiedJobsCount > 0
            ? this.reportDocuments.totalCustomerNotifiedJobsCount - 1
            : 0
        break
      case SIJobStatus[SIJobStatus.VisitBooked]:
        this.reportDocuments.totalVisitBookedJobsCount =
          this.reportDocuments.totalVisitBookedJobsCount > 0 ? this.reportDocuments.totalVisitBookedJobsCount - 1 : 0
        break
      case SIJobStatus[SIJobStatus.Testing]:
        this.reportDocuments.totalTestingJobsCount =
          this.reportDocuments.totalTestingJobsCount > 0 ? this.reportDocuments.totalTestingJobsCount - 1 : 0
        break
      case SIJobStatus[SIJobStatus.ReportProvided]:
        this.reportDocuments.totalReportProvidedJobsCount =
          this.reportDocuments.totalReportProvidedJobsCount > 0
            ? this.reportDocuments.totalReportProvidedJobsCount - 1
            : 0
        break
      case SIJobStatus[SIJobStatus.Monitoring]:
        this.reportDocuments.totalMonitoringJobsCount =
          this.reportDocuments.totalMonitoringJobsCount > 0 ? this.reportDocuments.totalMonitoringJobsCount - 1 : 0
        break
      default:
        break
    }
  }
}
</script>

<style scoped>
.search-contractor-jobs {
  display: inline-block;
  width: 300px;
}
.taskboard-wrapper {
  position: relative;
}
.all-jobs.open-block .jobs-taskboard {
  display: none;
}
.scrollmenu {
  overflow: auto;
  padding: 15px;
}
.jobs-taskboard .layout > .flex {
  padding: 4px;
}
.task-header {
  padding: 10px 4px;
}
</style>
