<template>
  <v-layout wrap>
    <v-flex xs12>
      <div v-if="datumControlLogData.length > 0">
        <v-expansion-panel
          v-for="item in datumControlLogData"
          :key="item.datumId"
          class="trialpit-accordion"
          :class="getClassName(item.status)"
        >
          <v-expansion-panel-content class="grey lighten-4 mb-2">
            <h3 slot="header">{{ item.name }}</h3>
            <v-card class="grey lighten-5">
              <v-card-text>
                <v-data-table
                  v-if="item.datumControlRunLogs"
                  :headers="headers"
                  :items="item.datumControlRunLogs"
                  hide-actions
                  class="gridView gridView-full-width"
                >
                  <template slot="items" slot-scope="props">
                    <td>
                      <v-select v-model="props.item.pitId" :items="getPitIds"></v-select>
                    </td>
                    <td class="text-xs-right">
                      <v-text-field
                        v-model="props.item.note"
                        v-validate="'required'"
                        :data-vv-scope="'datumControlScope' + item.id"
                        :data-vv-name="'note' + props.index + item.id"
                        :error-messages="validationMessage('note' + props.index + item.id)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-tooltip v-if="!isDatumAbandoned(item)" bottom>
                        <template #activator="{ on }">
                          <v-btn
                            flat
                            icon
                            class="mr-0 btn-remove"
                            color="primary"
                            v-on="on"
                            @click.prevent="removeDatumControlRunLogs(item, props.item)"
                          >
                            <v-icon dark color="primary">close</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove Row</span>
                      </v-tooltip>
                    </td>
                  </template>
                </v-data-table>
                <span v-else class="pa-2">No Data Found</span>
                <div v-if="isAllowed(item)" class="text-xs-right mt-2">
                  <v-btn
                    v-if="!isDatumAbandoned(item)"
                    class="mr-0 btn-add"
                    icon
                    color="primary"
                    small
                    @click="addDatumControlRunLogs(item)"
                  >
                    <v-icon dark class="white--text" small>add</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
            <v-divider />
            <v-flex v-if="item.datumControlRunLogs.length >= 1" class="px-3">
              <div v-if="item && isDatumAbandoned(item)" class="grey lighten-4 mt-2 mb-3 abandon-detail">
                <div class="pb-1">
                  <label><b>Abandon Reason:</b></label>
                  <span class="ml-1">{{ item.abandonedReason }}</span>
                </div>
                <div v-if="item.abandonedReasonNotes">
                  <label><b>Abandon Note:</b></label>
                  <span class="ml-1">{{ item.abandonedReasonNotes }}</span>
                </div>
              </div>
              <div v-else class="text-xs-right">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="btn-save"
                  :loading="isLoading && updateDatumControlLog.id == item.id"
                  @click="onSave(item)"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="item.status !== siJobProductProgress[siJobProductProgress.Finished]"
                  color="green"
                  class="btn-save white--text"
                  :loading="datumCompleteLoading && updateDatumControlLog.id == item.id"
                  @click="onSave(item, true)"
                >
                  Complete
                </v-btn>
                <v-btn color="red" class="white--text btn-abandonDatums" @click.native="openAbandonReasonDialog(item)">
                  Abandon
                </v-btn>
              </div>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </div>
      <span v-else>No Data Found</span>
    </v-flex>
    <AbandonSiteDataDialog
      v-if="showAbandonReasonDialog"
      ref="abandonSiteDataDialog"
      :record-type="abandonRecordType"
      :abandon-reason-details="abandonReasonDetails"
      @abandonReasonSave="onAbandonReasonSave"
      @closeAbandonReasonDialog="showAbandonReasonDialog = false"
    ></AbandonSiteDataDialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import storeGetters from '@/storeGetters'
import DatumControlLog from '@/models/siteInvestigation/DatumControlLog'
import SiteInvestigationController from '@/api/siteInvestigationController'
import UpdateDatumControlLog from '@/models/siteInvestigation/requests/UpdateDatumControlLog'
import DatumControlRunLog from '@/models/siteInvestigation/DatumControlRunLog'
import Job from '@/models/Job'
import { SIProductType, SIJobProductProgress, RecordType } from '@/common/enums'
import Shared from '@/common/shared'
import AbandonSiteDataDialog from '@/components/siteInvestigation/AbandonSiteDataDialog.vue'
import AddAbandonedSIProductRequest from '@/models/siteInvestigation/requests/AddAbandonedSIProductRequest'

@Component({
  components: { AbandonSiteDataDialog },
})
export default class Datums extends Vue {
  @Prop() public jobId: string
  @Prop() private engineerVisitDetailId: string
  private headers: any = [
    {
      text: 'Pit Id',
      align: 'left',
      sortable: false,
      value: 'pitId',
      class: 'pitId-col',
    },
    {
      text: 'Notes',
      align: 'left',
      value: 'note',
      sortable: false,
      class: 'note-col',
    },
    { text: '', value: '', sortable: false, class: 'close-col' },
  ]
  private datumControlLogData: DatumControlLog[] = []
  private updateDatumControlLog: UpdateDatumControlLog = new UpdateDatumControlLog()
  private isLoading = false
  private datumCompleteLoading = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private siJobProductProgress = SIJobProductProgress
  private showAbandonReasonDialog = false
  private abandonRecordType: string = RecordType[RecordType.AbandonDatumReason]
  private abandonReasonDetails: AddAbandonedSIProductRequest = new AddAbandonedSIProductRequest()

  private created() {
    this.setDatumData()
  }

  private setDatumData() {
    this.datumControlLogData = this.datumControlLogs
      ? JSON.parse(JSON.stringify(this.datumControlLogs.sort((a, b) => a.datumId - b.datumId)))
      : []
    this.datumControlLogData.forEach((datum) => {
      datum.datumControlRunLogs.forEach((runLog) => {
        if (runLog.note === null) {
          datum.datumControlRunLogs.splice(datum.datumControlRunLogs.indexOf(runLog))
        }
      })
    })
  }

  private get datumControlLogs(): DatumControlLog[] | [] {
    return storeGetters.getDatumControlLogDetail(this.jobId, this.engineerVisitDetailId)
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private get datumProducts() {
    if (this.job!.sIJobDetail!.jobPackage!.extraProducts) {
      return this.job!.sIJobDetail!.jobPackage!.extraProducts.filter((e) => e.productTypeId === SIProductType.Datum)
    }
    return []
  }

  private getDatum(id: number) {
    return this.datumProducts.find((d) => d.id === id)
  }

  private get getPitIds() {
    if (this.job!.sIJobDetail!.jobPackage!.packageInfo!) {
      const productIds = this.job!.sIJobDetail!.jobPackage!.packageInfo!.productList!.map((x) => x.pitId)
      const extraProduct = this.job!.sIJobDetail!.jobPackage!.extraProducts!.filter((x) => x.pitId !== undefined).map(
        (p) => p.pitId
      )
      return productIds.concat(extraProduct) || []
    } else if (this.job!.sIJobDetail!.jobPackage!.extraProducts) {
      const extraProduct = this.job!.sIJobDetail!.jobPackage!.extraProducts!.filter((x) => x.pitId !== undefined).map(
        (p) => p.pitId
      )
      return extraProduct
    }
    return []
  }

  private isAllowed(item: DatumControlLog): boolean {
    const datum = this.getDatum(item.datumId)
    if (datum && datum.quantity && item.datumControlRunLogs.length < datum.quantity) {
      return true
    }
    return false
  }

  private addDatumControlRunLogs(item: DatumControlLog) {
    if (this.isAllowed(item)) {
      const datumControlRunLog = new DatumControlRunLog()
      item.datumControlRunLogs.push(datumControlRunLog)
    }
  }

  private removeDatumControlRunLogs(item: DatumControlLog, datumControlRunLog: DatumControlRunLog): void {
    const index = item.datumControlRunLogs.indexOf(datumControlRunLog)
    item.datumControlRunLogs.splice(index, 1)
  }

  private async validate(id: string): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('datumControlScope' + id)
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private onSave(item: DatumControlLog, fromComplete = false) {
    this.validate(item.id).then((result: boolean) => {
      if (result) {
        this.isLoading = !fromComplete
        this.datumCompleteLoading = fromComplete
        this.updateDatumControlLog = new UpdateDatumControlLog()
        this.updateDatumControlLog.id = item.id
        this.updateDatumControlLog.datumId = item.datumId
        this.updateDatumControlLog.jobId = this.jobId
        this.updateDatumControlLog.datumControlRunLogs = item.datumControlRunLogs
        this.updateDatumControlLog.engineerVisitDetailId = item.engineerVisitDetailId
        this.updateDatumControlLog.status =
          item.status === SIJobProductProgress[SIJobProductProgress.Finished]
            ? SIJobProductProgress[SIJobProductProgress.Finished]
            : fromComplete
            ? SIJobProductProgress[SIJobProductProgress.Finished]
            : SIJobProductProgress[SIJobProductProgress.InProgress]
        SiteInvestigationController.UpdateDatumControlLog(this.updateDatumControlLog)
          .then((res) => {
            if (!res) {
              this.setDatumData()
            } else {
              this.showMessage('Datum detail saved successfully')
              this.$validator.errors.items = []
            }
            this.isLoading = false
            this.datumCompleteLoading = false
          })
          .catch((error) => {
            this.setDatumData()
            this.isLoading = false
            this.datumCompleteLoading = false
          })
      }
    })
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' field is required.') : message
  }

  private showMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private getClassName(status: string): string {
    if (status === SIJobProductProgress[SIJobProductProgress.Finished]) {
      return 'finished'
    } else if (status === SIJobProductProgress[SIJobProductProgress.Abandoned]) {
      return 'lightcoral'
    }
    return ''
  }

  private openAbandonReasonDialog(item: DatumControlLog) {
    this.abandonReasonDetails.jobId = this.jobId
    this.abandonReasonDetails.id = item.id
    this.abandonReasonDetails.forEngineerVisitDetailId = item.engineerVisitDetailId
    this.abandonReasonDetails.siProductType = SIProductType[SIProductType.Datum]
    this.showAbandonReasonDialog = true
  }

  private async onAbandonReasonSave(result: boolean) {
    this.showAbandonReasonDialog = false
    if (result) {
      this.showMessage('Datums has been abandoned')
    } else {
      this.showMessage('Something went wrong')
    }
  }

  private isDatumAbandoned(item: DatumControlLog): boolean {
    return item.status.toString() === SIJobProductProgress[SIJobProductProgress.Abandoned]
  }

  @Watch('datumControlLogs', { deep: true })
  private datumControlLogsChange() {
    this.setDatumData()
  }
}
</script>

<style scoped>
.gridView >>> .pitId-col {
  width: 20%;
}
.gridView >>> .note-col {
  width: 70%;
}
.gridView >>> .close-col {
  width: 10%;
}
.trialpit-accordion.finished >>> .v-expansion-panel__header {
  background: #d5f5d5;
}
.trialpit-accordion.lightcoral >>> .v-expansion-panel__header {
  background: #f7cccc;
}
</style>
