export default class MapLocationModel {
  public id: string
  public type: string
  public coordinates: any[]

  public constructor() {
    if (!this.coordinates) {
      this.coordinates = []
    }
  }
}
