<template>
  <div>
    <div id="healthAndSafety">
      <div class="pl-1" style="display: flex; width: 100%; height: auto; align-items: center">
        <h3 class="mb-2 primary--text mr-0" style="width: 90%">Job Summary</h3>
        <v-btn
          ref="btnGenPDF"
          color="primary mt-0"
          :disabled="generatePDFInProgress"
          :loading="generatePDFInProgress"
          @click="
            () => {
              generateReport('healthAndSafety', 'Health and Safety')
            }
          "
        >
          Generate PDF
        </v-btn>
      </div>
      <div class="grey lighten-4 pa-2">
        <div class="pb-1 mb-2">
          <b>Job ID:</b>
          <span v-if="getHealthSafetyQA && getHealthSafetyQA.jobId">
            {{ getHealthSafetyQA.jobId }}
          </span>
          <span v-else>---</span>
        </div>
        <div class="pb-1 mb-2">
          <b>Emergency Type:</b>
          <span v-if="emergency && emergency.typeDescription">
            {{ emergency.typeDescription }}
          </span>
          <span v-else>---</span>
        </div>
        <div class="pb-1 mb-2">
          <b>Emergency Detail:</b>
          <span v-if="emergency && emergency.detailDescription">
            {{ emergency.detailDescription }}
          </span>
          <span v-else>---</span>
        </div>
        <div class="pb-1 mb-2">
          <b>Contractor Name:</b>
          <span v-if="engineerVisitDetail && engineerVisitDetail.companyName">
            {{ engineerVisitDetail.companyName }}
          </span>
          <span v-else>---</span>
        </div>
        <div class="pb-1 mb-2">
          <b>Engineer Name:</b>
          <span>{{ engineerName }}</span>
        </div>
        <div class="pb-1 mb-2">
          <b>H&S Completion Date:</b>
          <span v-if="getHealthSafetyQA && getHealthSafetyQA.createdAt">
            {{ getFormattedDate(getHealthSafetyQA.createdAt) }}
          </span>
          <span v-else>---</span>
        </div>
        <div class="pb-1 mb-2">
          <b>Job Address:</b>
          <span v-if="getHealthSafetyQA && getHealthSafetyQA.jobAddress">
            {{ getHealthSafetyQA.jobAddress }}
          </span>
          <span v-else>---</span>
        </div>
      </div>

      <h3 class="mb-2 primary--text pt-2">Assessment questions and answers:</h3>
      <div v-if="getHealthSafetyQA && getHealthSafetyQA.assessmentQAs" class="grey lighten-4 pa-2">
        <div v-for="(assessmentQA, index) in getHealthSafetyQA.assessmentQAs" :key="index">
          <div class="pt-2">
            <b>Question:</b>
            {{ assessmentQA.text }}
          </div>
          <div>
            <b>Answer: </b>
            <span v-if="assessmentQA.answer === 'true'">Yes</span>
            <span v-else>No</span>
          </div>
          <div v-if="assessmentQA.additionalQuestion">
          <b>Additional Question: </b>
          <span>{{ assessmentQA.additionalQuestion.text }}</span>
          <div>
            <b>Answer: </b>
            <span v-if="assessmentQA.additionalQuestion.answer === 'true'">Yes</span>
            <span v-else>No</span>
          </div>
          <div>
            <b>Note: </b>
            <span v-if="assessmentQA.additionalQuestion.note">{{ assessmentQA.additionalQuestion.note }}</span>
            <span v-else>No notes added</span>
          </div>
          </div>
         
        </div>
      </div>
      <div v-else class="grey lighten-4 pa-2">
        <h3 class="mb-2 primary--text pt-2">On-site assessment questions and answers:</h3>
      <div v-if="getHealthSafetyQA && getHealthSafetyQA.onSiteAssessmentQAs" class="grey lighten-4 pa-2">
        <div v-for="(onSiteQA, index) in getHealthSafetyQA.onSiteAssessmentQAs" :key="index">
          <div class="pt-2">
            <b>Que:</b>
            {{ onSiteQA.text }}? ({{ onSiteQA.toolTip }})
          </div>
          <div>
            <b>Ans:</b>
            <span v-if="onSiteQA.answer === 'true'">Yes</span>
            <span v-else>No</span>
          </div>
          <div>
            <b>Note:</b>
            <span v-if="onSiteQA.note">{{ onSiteQA.note }}</span>
            <span v-else>No Notes Added</span>
          </div>
        </div>
      </div>
      <div v-else class="grey lighten-4 pa-2">
        <span>---</span>
      </div>

      <!-- style='page-break-before: always; margin-top:100px;' -->
      <h3 class="mb-2 primary--text pt-4">Final assessment questions and answers:</h3>
      <div v-if="getHealthSafetyQA && getHealthSafetyQA.finalAssessmentQAs" class="grey lighten-4 pa-2">
        <div v-for="(finalQA, index) in getHealthSafetyQA.finalAssessmentQAs" :key="'final-' + index">
          <div class="pt-2">
            <b>Que:</b>
            {{ finalQA.text }}
          </div>
          <div>
            <b>Ans:</b>
            <span v-if="finalQA.answer === 'true'">Yes</span>
            <span v-else>No</span>
          </div>
          <div>
            <b>Note:</b>
            <span v-if="finalQA.note">{{ finalQA.note }}</span>
            <span v-else>No Notes Added</span>
          </div>
        </div>
      </div>
      <div v-else class="grey lighten-4 pa-2">
        <span>---</span>
      </div>
      </div>

      <div v-if="getHealthSafetyQA && getHealthSafetyQA.hasSignOff && getHealthSafetyQA.signOffSignatureURL">
        <h3 class="pb-2 pt-4 primary--text">Engineer's Signature:</h3>
        <img :src="getHealthSafetyQA.signOffSignatureURL" class="profile-img" style="width: auto; height: 50px" />
      </div>

      <div class="pb-1 mb-2 mt-3">
        <b>Engineer Name: </b>
        <span>{{ engineerName }}</span>
      </div>

      <div class="pb-1 mb-2">
        <b>H&S Completion Date: </b>
        <span v-if="getHealthSafetyQA && getHealthSafetyQA.createdAt">
          {{ getFormattedDate(getHealthSafetyQA.createdAt) }}
        </span>
        <span v-else>---</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import VisitHealthAndSafetyProcess from '@/models/claim/VisitHealthAndSafetyProcessModel'
import moment, { Moment } from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'
import jsPDF from 'jspdf'
import EmergencyModel from '../models/policyHolder/EmergencyModel'
import EngineerVisitDetail from '../models/claim/EngineerVisitDetailModel'
import EngineerModel from '../models/contractor/EngineerModel'

@Component
export default class HealthSafetyQAReport extends Vue {
  @Prop() private getHealthSafetyQA: VisitHealthAndSafetyProcess
  @Prop() private emergency: EmergencyModel
  @Prop() private engineerVisitDetail: EngineerVisitDetail

  private generatePDFInProgress = false
  private marginsForPdf = { top: 70, bottom: 40, left: 30, width: 550 }

  private getFormattedDate(date: Moment): string {
    if (date) {
      return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
    }
    return ''
  }

  private generateReport(id: string, desc: string) {
    this.generatePDFInProgress = true
    const pdf = new jsPDF('p', 'pt', 'a4')
    // set font size
    pdf.setFontSize(18)
    // generate pdf from html
    pdf.fromHTML(
      document.getElementById(id),
      this.marginsForPdf.left,
      this.marginsForPdf.top,
      { width: this.marginsForPdf.width },
      (dispose) => {
        // set header and footer
        this.headerFooterFormatting(pdf, pdf.internal.getNumberOfPages(), desc)
        // hide loader
        this.generatePDFInProgress = false
        // generate pdf
        pdf.save(id + '_' + this.getHealthSafetyQA.engineerVisitDetailId)
      },
      this.marginsForPdf
    )
  }

  private headerFooterFormatting(doc, totalPages, desc) {
    for (let i = totalPages; i >= 1; i--) {
      doc.setPage(i)
      this.header(doc, desc)
      this.footer(doc, i, totalPages)
      doc.page++
    }
  }

  private header(doc, desc) {
    // set style for header
    doc.setFontSize(20)
    doc.setTextColor('#009688')
    doc.setFontStyle('normal')
    doc.text(desc + ' Report', this.marginsForPdf.left + 150, 30)
    doc.setLineCap(2)
    doc.line(3, 50, this.marginsForPdf.width + 43, 50) // horizontal line
  }

  private footer(doc, pageNumber, totalPages) {
    // set style for footer
    const str = 'Page ' + pageNumber + ' of ' + totalPages
    doc.setLineCap(1)
    doc.setFontSize(10)
    doc.text(str, this.marginsForPdf.left, doc.internal.pageSize.height - 20)
  }

  public get engineerName(): string {
    const exists =
      this.engineerVisitDetail &&
      this.engineerVisitDetail.engineerFirstName !== null &&
      this.engineerVisitDetail.engineerLastName !== null
    return exists ? `${this.engineerVisitDetail.engineerFirstName} ${this.engineerVisitDetail.engineerLastName}` : 'N/A'
  }
}
</script>
