<template>
  <v-layout wrap>
    <v-flex v-if="!isAddUpdateMode && cctvControlDistanceLocal">
      <v-layout wrap>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Distance (m):</b></label>
          <span class="ml-1">{{ cctvControlDistanceLocal.distance }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Code:</b></label>
          <span class="ml-1">{{ cctvControlDistanceLocal.code }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Clock Ref At:</b></label>
          <span class="ml-1">{{ cctvControlDistanceLocal.clockRefAt }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Clock Ref To:</b></label>
          <span class="ml-1">{{ cctvControlDistanceLocal.clockRefTo }}</span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Intrusion (%):</b></label>
          <span class="ml-1">
            {{ cctvControlDistanceLocal.intrusionInPercentage }}
          </span>
        </v-flex>
        <v-flex xl4 lg6 xs12 mb-1>
          <label><b>Intrusion (mm):</b></label>
          <span class="ml-1">{{ cctvControlDistanceLocal.intrusionInMM }}</span>
        </v-flex>
        <v-flex xs12 mb-1>
          <label><b>Remarks:</b></label>
          <span class="ml-1">{{ cctvControlDistanceLocal.remarks }}</span>
        </v-flex>
        <v-flex v-if="getSnapshotUrls.length > 0" xs12 mb-1 class="defectsnapshotImage">
          <label><b>Defect Snapshot:</b></label>
          <PictureUpload :job-id="jobId" :picture-list="getSnapshotUrls" :show-claim-thumb="false"></PictureUpload>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="isAddUpdateMode">
      <v-layout wrap>
        <v-flex xs4 pr-1>
          <v-text-field
            v-model.number="cctvControlDistanceLocal.distance"
            v-validate="{
              rules: {
                required: true,
                decimal: 2,
                min_value: getMinDistanceValue,
              },
            }"
            name="Distance"
            label="Distance (m)"
            required
            class="required"
            data-vv-name="Distance"
            :error-messages="errors.collect('Distance')"
            @keypress="numberKeyValidation($event)"
          ></v-text-field>
        </v-flex>
        <v-flex xs4 pl-1 pr-1>
          <v-autocomplete
            v-model="cctvControlDistanceLocal.code"
            v-validate="'required'"
            :items="cctvLogConfiguration && cctvLogConfiguration.codes ? cctvLogConfiguration.codes : []"
            label="Code"
            required
            class="required"
            data-vv-name="Code"
            :error-messages="errors.collect('Code')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs4 pr-1>
          <v-autocomplete
            v-model="cctvControlDistanceLocal.clockRefAt"
            :items="cctvLogConfiguration && cctvLogConfiguration.clockRefAtTo ? cctvLogConfiguration.clockRefAtTo : []"
            label="Clock Ref At"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs4 pl-1 pr-1>
          <v-autocomplete
            v-model="cctvControlDistanceLocal.clockRefTo"
            :items="cctvLogConfiguration && cctvLogConfiguration.clockRefAtTo ? cctvLogConfiguration.clockRefAtTo : []"
            label="Clock Ref To"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs4 pr-1>
          <v-autocomplete
            v-model="cctvControlDistanceLocal.intrusionInPercentage"
            :items="
              cctvLogConfiguration && cctvLogConfiguration.intrusionInPercentage
                ? cctvLogConfiguration.intrusionInPercentage
                : []
            "
            label="Intrusion(%)"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs4 pl-1>
          <v-autocomplete
            v-model="cctvControlDistanceLocal.intrusionInMM"
            :items="
              cctvLogConfiguration && cctvLogConfiguration.intrusionInMM ? cctvLogConfiguration.intrusionInMM : []
            "
            label="Intrusion(MM)"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 pl-1>
          <v-textarea v-model="cctvControlDistanceLocal.remarks" rows="3" label="Remarks"></v-textarea>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import CCTVControlDistance from '@/models/siteInvestigation/CCTVControlDistance'
import SICCTVLogConfiguration from '@/models/siteInvestigation/SICCTVLogConfiguration'
import PictureUpload from '@/components/timeline/PictureUpload.vue'

@Component({
  components: { PictureUpload },
})
export default class CCTVControlRunDistance extends Vue {
  public cctvControlDistanceLocal: CCTVControlDistance | null = new CCTVControlDistance()

  @Prop({ default: null }) public cctvControlDistance: CCTVControlDistance
  @Prop({ default: false }) public isAddUpdateMode: boolean
  @Prop({ default: () => [] })
  public cctvLogConfiguration: SICCTVLogConfiguration
  @Prop({ default: () => [] }) public allCCTVRunLogDistance: number[]
  @Prop({ default: '' }) private jobId: string

  @Watch('cctvControlDistance')
  public updateData() {
    this.cctvControlDistanceLocal = this.cctvControlDistance
  }

  private mounted() {
    this.updateData()
  }

  private get getSnapshotUrls() {
    // get defect snapshotUrls
    const urls: any[] = []
    if (this.cctvControlDistanceLocal!.defectSnapshotURL) {
      urls.push({ fileURL: this.cctvControlDistanceLocal!.defectSnapshotURL })
    }
    return urls
  }

  private get getMinDistanceValue() {
    // get minimum value from already added distances, as it should not be less than from already added distances
    return this.allCCTVRunLogDistance.length > 0 ? Math.min.apply(Math, this.allCCTVRunLogDistance) : 0
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }
}
</script>

<style scoped></style>
