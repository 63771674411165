<template>
  <v-container v-if="callAlert.hariSummary" class="wrapper">
    <div v-if="callAnswered" class="close-modal" @click="closeModal()"> <v-icon color="red">close</v-icon> </div>
    <v-layout
      v-if="showDetails"
      row
      class="px-4 py-4"
      :class="policyMatchSuccess ? 'policy-score-match--success' : 'policy-score-match--failed'"
    >
      <v-layout column>
        <div>{{ isWIPCall ? 'JobId' : 'Policy' }}</div>
        <div>Surname</div>
        <div>Postcode</div>
      </v-layout>
      <v-layout column>
        <div class="font-weight-bold text-uppercase">{{ isWIPCall ? 'Matched' : 'Found' }}</div>
        <div class="font-weight-bold user-detail">{{ calculatedCallScore.surname }}%</div>
        <div class="font-weight-bold user-detail">{{ calculatedCallScore.postcode }}%</div>
      </v-layout>
      <v-layout column>
        <div class="user-detail">{{ callAlert.hariSummary.policyReference }}</div>
        <div class="user-detail">{{ callAlert.hariSummary.surname }}</div>
        <div class="user-detail">{{ callAlert.hariSummary.postcode }}</div>
      </v-layout>
      <v-layout column class="align-end">
        <div
          class="circle-icon mr-1"
          :class="policyMatchSuccess ? 'text-success' : 'text-error'"
          :style="`border-color: ${policyMatchSuccess ? 'green' : 'red'}`"
        >
          {{ calculatedCallScore.overallMatch }}
        </div>
        <h6 class="text-uppercase">Overall Score</h6>
      </v-layout>
    </v-layout>
    <v-layout v-else row class="px-4 py-4 justify-center policy-score-match--failed">
      <h3 class="text-error">{{ errorText }}</h3>
    </v-layout>
    <v-layout class="px-4" column>
      <div class="font-weight-bold">{{ callAlert.clientDetails }}</div>
      <div class="font-weight-bold">{{ callAlert.hariSummary.callType }}</div>
      <div class="font-weight-bold">{{ callAlert.hariSummary.policyScheduleName }}</div>
      <div>"{{ callAlert.hariSummary.callReason }}"</div>
    </v-layout>

    <v-layout v-if="callAlert.incomingAlertType !== 'Callback Request'" class="pa-4">
      <v-layout class="align-center justify-center">
        <div class="intro-message">{{ callAlert.additionalDetails }}</div>
      </v-layout>
      <v-layout class="align-center justify-end">
        <div v-if="showAnswerButton" class="circle-icon call-vibration" @click="answerCall"
          ><v-icon color="green">call</v-icon></div
        ></v-layout
      >
    </v-layout>
    <v-layout v-else class="pa-4">
      <div
        :class="policyMatchSuccess ? 'text-success' : 'text-error'"
        class="circle-icon call-vibration"
        @click="initiateCallback"
        ><v-btn color="green" @click="answerCall">Start Call</v-btn></div
      >
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import eventBus from '@/common/bus'
import CallTaskAlert from '@/models/twilio/CallTaskAlert'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IncomingCallModal extends Vue {
  @Prop() public callAlert: CallTaskAlert

  public showDetails = false
  public policyMatchSuccess = false
  public showAnswerButton = true
  public isWIPCall = false
  public callAnswered = false

  public mounted() {
    if (this.callAlert.hariSummary) {
      this.policyMatchSuccess = this.callAlert.hariSummary.isMatchSuccessful
      this.showDetails = this.policyMatchSuccess || !!this.callAlert.hariSummary.jobId
      this.isWIPCall = this.callAlert.hariSummary.callType === 'WorkInProgress'
    }
  }

  public get calculatedCallScore() {
    const { hariSummary } = this.callAlert

    return {
      postcode: hariSummary ? Math.floor(hariSummary.postcodeMatchScore * 100) : 0,
      surname: hariSummary ? Math.floor(hariSummary.surnameMatchScore * 100) : 0,
      overallMatch: hariSummary ? Math.floor(hariSummary.overallMatchScore * 100) : 0,
    }
  }

  public answerCall() {
    eventBus.$emit('answerIncomingCall')
    this.showAnswerButton = false
    this.callAnswered = true
  }

  public closeModal() {
    this.$emit('closeModal')
  }

  public initiateCallback() {
    eventBus.$emit('initiateCustomerCallback')
  }

  public get errorText() {
    const { hariSummary } = this.callAlert
    if (!hariSummary) {
      return 'Not Found'
    }
    const callTypeSubject = this.isWIPCall ? 'existing claim' : 'policy'
    return `No ${callTypeSubject} found`
  }
}
</script>

<style css scoped>
.wrapper {
  background: white;
  position: absolute;
  top: 64px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 3px solid grey;
  text-align: left;
  line-height: 1.75;
  padding: 0;
  min-width: 500px;
  width: auto;
  border-radius: 10px;
  z-index: 999;
}

.circle-icon {
  height: 60px;
  width: 60px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid;
  border-radius: 50%;
  font-weight: bold;
}

.policy-score-match--success {
  background: #0080005c;
}

.policy-score-match--failed {
  background: pink;
}

.text-success {
  color: green;
}

.text-error {
  color: red;
}

.call-vibration {
  animation-name: spaceboots;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: pointer;
  border-color: green;
}

.intro-message {
  text-align: justify;
  width: 100%;
  max-width: 380px;
  padding-right: 20px;
}

.close-modal {
  position: absolute;
  right: 0;
  padding: 5px;
}

.close-modal:hover {
  background: #ff000030;
  cursor: pointer;
}

.user-detail {
  min-height: calc(100% / 3);
}
</style>
