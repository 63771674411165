<template>
  <v-layout wrap class="mb-2">
    <div v-if="vulnerabilityQAItem.isHealthAffected === true">
      <v-flex v-if="vulnerabilityQAItem.level" xs12 mb-2>
        <span>
          Level :
          <b class="secondary--text">{{ vulnerabilityQAItem.level }}</b>
        </span>
      </v-flex>
      <v-flex v-if="vulnerabilityQAItem.answerDetail" xs12>
        <div class="affected-details">
          <span>Details :</span>
          <span class="affected-description">
            {{ vulnerabilityQAItem.answerDetail }}
          </span>
        </div>
      </v-flex>
    </div>
    <div v-else-if="vulnerabilityQAItem.isHealthAffected === false">
      <v-flex xs12 mb-2>
        <span>No Vulnerability</span>
      </v-flex>
    </div>
    <div v-else>
      <v-flex xs12 mb-2>
        <span>Not answered yet.</span>
      </v-flex>
    </div>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
@Component
export default class VulnerabilityQACard extends TimeLineItemBase {
  private get vulnerabilityQAItem(): VulnerabilityQAModel {
    return storeGetters.getVulnerabilityQA(this.jobId)
  }
}
</script>

<style scoped>
.affected-description {
  padding-left: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.affected-details {
  position: relative;
}
.affected-details span:first-child {
  position: absolute;
  left: 0px;
}
</style>
