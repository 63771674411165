<template>
  <v-layout wrap>
    <v-flex md8 lg9 xs8>
      <div>
        <div class="mb-1">
          <b>
            <span v-if="!isContractorAcepted">Provisional</span>
            From:
          </b>
          <span class="eta-date-from">
            {{ getFormattedDate(getAssignedContractor.etaFrom) }}
          </span>
        </div>
        <div class="mb-1">
          <b>
            <span v-if="!isContractorAcepted">Provisional</span>
            To:
          </b>
          <span class="eta-date-to">
            {{ getFormattedDate(getAssignedContractor.etaTo) }}
          </span>
        </div>
        <div class="mb-1">
          <b>
            <span v-if="!isContractorAcepted">Provisional</span>
            Engineer:
          </b>
          <span class="eta-engineer-name">
            {{ getAssignedContractor.engineerName }}
          </span>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import ContractorAppointedModel from '../../models/claim/ContractorAppointedModel'
import { JobVisitStatus } from '@/common/enums'

@Component
export default class ConfirmDateCard extends TimeLineItemBase {
  private getFormattedDate(date: string): string {
    // get date from Preview, format is fine
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  public get getAssignedContractor(): ContractorAppointedModel {
    if (this.jobId && this.itemId) {
      return storeGetters.getContractorAppointedDetailById(this.jobId, this.itemId)
    }
    return new ContractorAppointedModel()
  }

  public get isContractorAcepted(): boolean {
    return this.getAssignedContractor.status === JobVisitStatus[JobVisitStatus.Accepted]
  }
}
</script>
