<template>
  <v-card v-if="financialSummary" class="pt-0 px-2 pb-2 up-card mb-3 financial-info">
    <v-layout wrap>
      <v-flex xs12>
        <h3 class="py-3 pl-2">Financial Summary</h3>
        <v-divider></v-divider>
        <div>
          <v-layout row wrap class="px-2 py-3">
            <v-flex xs12>
              <h3 class="sub-title">Invoice to RSA</h3>
              <table>
                <tr>
                  <td>One call Out</td>
                  <td></td>
                  <td>
                    <span>
                      &pound;
                      {{ financialSummary.invoice ? item.invoice.oneCallOutCost : 0 }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Aditional Time Authorized</td>
                  <td></td>
                  <td>
                    <span>
                      &pound;
                      {{ financialSummary.invoice ? item.invoice.additionalTimeCost : 0 }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>New Parts</td>
                  <td></td>
                  <td>
                    <span>
                      &pound;
                      {{ financialSummary.invoice ? item.invoice.newPartsCost : 0 }}
                    </span>
                  </td>
                </tr>
              </table>
            </v-flex>
            <v-flex xs-12>
              <div class="text-xs-right mt-3 total-financial pa-2">
                <span><b>Total &nbsp; &nbsp; &nbsp;</b></span>
                <span>
                  <span>
                    <b>
                      &pound;
                      {{ financialSummary.invoice ? item.invoice.totalCost : 0 }}
                    </b>
                  </span>
                </span>
              </div>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="px-2 py-2">
            <v-flex xs12>
              <h3 class="sub-title">Purchase Order to Stuart Barr</h3>
              <table>
                <tr>
                  <td>One call Out</td>
                  <td></td>
                  <td>
                    <span>
                      &pound;
                      {{ financialSummary.purchase ? item.purchase.oneCallOutCost : 0 }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Aditional Time Authorized</td>
                  <td></td>
                  <td>
                    <span>
                      &pound;
                      {{ financialSummary.purchase ? item.purchase.additionalTimeCost : 0 }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>New Parts</td>
                  <td></td>
                  <td>
                    <span>
                      &pound;
                      {{ financialSummary.purchase ? item.purchase.newPartsCost : 0 }}
                    </span>
                  </td>
                </tr>
              </table>
            </v-flex>
            <v-flex xs-12>
              <div class="text-xs-right mt-3 total-financial pa-2">
                <span><b>Total &nbsp; &nbsp; &nbsp;</b></span>
                <span>
                  <span>
                    <b>
                      &pound;
                      {{ financialSummary.purchase ? item.purchase.totalCost : 0 }}
                    </b>
                  </span>
                </span>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import FinancialSummaryModel from '@/models/claim/FinancialSummaryModel'
import storeGetters from '@/storeGetters'
import TimeLineItemBase from '@/components/TimeLineItemBase'
@Component
export default class FinancialSummaryPreview extends TimeLineItemBase {
  private get financialSummary(): FinancialSummaryModel | null {
    return storeGetters.getFinancialSummary(this.jobId)
  }
}
</script>

<style scoped>
.financial-info table {
  width: 100%;
}
.financial-info table tr td:last-child {
  text-align: right;
}
.total {
  background-color: #eee;
}
.total-financial {
  background-color: #e0f2f1;
}
.sub-title {
  color: #009688;
}
</style>
