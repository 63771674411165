<template>
  <div>
    <v-container class="grid-list-lg pa-0">
      <v-layout wrap>
        <v-flex xs12 mb-1>
          <v-text-field
            v-if="enableSearchContractor"
            id="searchContractor"
            v-model="contractorSearch"
            label="Search Contractor"
            prepend-icon="search"
            hide-details
          ></v-text-field>
        </v-flex>
        <template v-if="contractorSearchList.length > 0 && !getThirdPartyContractor">
          <v-flex
            v-for="(item, index) in contractorSearchList"
            :key="'Contractor' + index"
            xs12
            sm6
            xl4
            @click="clickContractorTileView(item, index, true)"
          >
            <CallContractorTileView
              :item="item"
              :selected="activeSearchTileIndex === index"
              :assigned-contractors="getAssignedContractors"
              :trade-id="emergency.tradeId"
            ></CallContractorTileView>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorModel from '@/models/contractor/ContractorModel'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import CallContractorTileView from '@/components/timeline/CallContractorTileView.vue'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import Emergency from '@/models/Emergency'
import storeGetters from '@/storeGetters'

@Component({
  components: {
    CallContractorTileView,
  },
})
export default class CallContractorSearchView extends Vue {
  @Prop() private jobId: string
  @Prop() private emergencyId: string
  @Prop() private contractorSearchList: ContractorModel[]
  @Prop() private enableSearchContractor: boolean
  @Prop() private activeSearchTileIndex: number
  @Prop() private isThirdPartyContractor: boolean
  @Prop() private thirdPartyContractor: ContractorModel | null
  @Prop() private emergency: Emergency | null
  private contractorSearch = ''

  private get getThirdPartyContractor() {
    this.isThirdPartyContractor =
      this.emergency !== undefined &&
      this.emergency !== null &&
      this.emergency.thirdPartyContractor !== '' &&
      this.emergency.thirdPartyContractor !== null &&
      this.emergency.thirdPartyContractor !== undefined
        ? true
        : false
    return this.thirdPartyContractor
  }

  private get getAssignedContractors(): ContractorAppointedModel[] {
    return storeGetters.getContractorAppointedDetail(this.jobId, this.emergencyId).sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      }
      return 0
    })
  }

  private clickContractorTileView(item: ContractorModel, index: number, isSearch: boolean) {
    this.$emit('clickContractorTileView', item, index, isSearch, true)
  }

  @Watch('contractorSearch')
  private onSearchInputChanged(newValue: any) {
    this.$emit('onSearchInputChanged', newValue)
  }
}
</script>

<style scoped></style>
