import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import InvoiceDetailModel from '@/models/claim/InvoiceDetailModel'
import { ContractorInvoiceStatus } from '@/common/enums'
export default class ContractorInvoiceDetailModel extends CosmosJobsDbRecordBase {
  public address: string
  public clientNotes: string[]
  public contractorAppointedDetailIds: string[]
  public contractorId: string
  public disputedReason: string
  public status: ContractorInvoiceStatus
  public trades: number[]
  public invoiceDetail: InvoiceDetailModel
  public originalAdditionalCharge: number
  public additionalCharge: number
  public overrideAdditionalChargeReason: string
  public ratePackageId: string
}
