import CosmosJobsDbRecordBase from '../CosmosJobsDbRecordBase'
import { SIJobProductProgress } from '@/common/enums'
import WaterTestModel from './WaterTestModel'

export default class WaterMainsTest extends CosmosJobsDbRecordBase {
  public engineerVisitDetailId: string
  public productId: number
  public status: SIJobProductProgress | string
  public waterTests: WaterTestModel[]
  public abandonedReason: string
  public abandonedReasonNotes: string
  public periodListeningTest: number
  public isLeakDetected: boolean
  public isNoiseHeardFromListeningStick: boolean
}
