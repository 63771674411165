<template>
  <div>
    <div class="mb-2"><h4>Cancel Job Request</h4></div>
    <div v-if="cancelJobReason" class="mb-1">
      <label>Reason: </label>
      <span>{{ cancelJobReason(item.cancellationReason) }}</span>
    </div>
    <div v-if="item.reasonDescription" class="mb-1">
      <label>Description: </label>
      <span>{{ item.reasonDescription }}</span>
    </div>
    <div v-if="item.processAtUtc" class="mb-1">
      <label>Process At: </label>
      <span>{{ getFormattedDate(item.processAtUtc) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddRequestForCancelJobModel from '@/models/requests/AddRequestForCancelJobModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import MasterRecord from '@/models/MasterRecord'

@Component
export default class AddRequestForCancelJobLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddRequestForCancelJobModel {
    return Object.assign(new AddRequestForCancelJobModel(), this.requestBody)
  }

  private get jobCancellationReasons(): MasterRecord[] {
    const masterRecords: MasterRecord[] = this.$store.getters['jobModule/masterRecords']
    return masterRecords.filter((x) => x.type === 'JobCancellationReason')
  }

  private cancelJobReason(reasonId: string) {
    const reason = this.jobCancellationReasons.find((c: MasterRecord) => c.id === reasonId)
    return reason ? reason.description : ''
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
