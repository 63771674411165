<template>
  <v-layout class="peril-repeat" wrap>
    <v-flex v-if="value" xs12>
      <v-layout wrap>
        <v-flex xs6 pr-2>
          <v-select
            v-model="value.commodityDetailId"
            v-validate="'required'"
            :items="commodityDetailsFiltered"
            item-value="emergencyDetailId"
            item-text="description"
            label="Commodity"
            class="required commodity"
            required
            data-vv-name="Commodity"
            :error-messages="errors.collect('Commodity')"
          ></v-select>
        </v-flex>
        <v-flex xs6 pr-2 pl-2>
          <v-select
            v-model="value.commodityWorkType"
            v-validate="'required'"
            :items="getCommodityWorkTypes"
            item-value="description"
            item-text="description"
            label="Commodity Detail"
            class="required commodityDetail"
            required
            data-vv-name="Commodity Detail"
            :error-messages="errors.collect('Commodity Detail')"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model.trim="value.excessValue"
            v-validate="'numeric'"
            label="Excess Value"
            required
            name="ExcessValue"
            data-vv-name="Excess Value"
            :error-messages="errors.collect('Excess Value')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-textarea v-model.trim="value.note" label="Notes"></v-textarea>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import AddCommodity from '@/models/requests/AddCommodity'
import VeeValidate from 'vee-validate'
import PolicyHolderController from '@/api/policyHolderController'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import Store from '@/store'
import Shared from '@/common/shared'
import { JobType } from '@/models/types'
import { CommodityWorkType } from '@/common/enums'
import eventBus from '@/common/bus'

interface ICommodityWorkType {
  id: number
  description: string
}

@Component({
  name: 'AddDrainageCommodity',
})
export default class AddDrainageCommodity extends Vue {
  @Prop() public value: AddCommodity // do not change name, this is required for v-model databinding to work!

  private commodityDetails: EmergencyDetailModel[] = []
  private jobType: JobType = 'US'

  public resetAddCommodity() {
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
  }

  private async created() {
    this.getCommodityDetails()
  }

  private getCommodityDetails(): void {
    PolicyHolderController.GetEmergencyDetails(this.jobType)
      .then((res: EmergencyDetailModel[]) => {
        if (res) {
          this.commodityDetails = res.filter((c) => !c.isDeleted)
        } else {
          this.commodityDetails = []
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading emergency detail list, please try again', true)
        this.commodityDetails = []
      })
  }

  private get commodityDetailsFiltered(): EmergencyDetailModel[] {
    if (this.commodityDetails.length === 1) {
      // select commodity if has only one record
      this.value.commodityDetailId = this.commodityDetails[0].emergencyDetailId
    }
    return Array.from(this.commodityDetails)
  }

  @Watch('value.commodityDetailId')
  private commodityDetailIdChanged() {
    const item = this.commodityDetails.find((e) => e.emergencyDetailId === this.value.commodityDetailId)
    this.value.commodityDetailDesc = item ? item.description : ''
  }

  @Watch('value')
  private commodityUpdated() {
    this.$emit('input', this.value)
  }

  private get getCommodityWorkTypes(): ICommodityWorkType[] {
    const commodityWorkTypesItems = [
      { id: 0, description: CommodityWorkType[CommodityWorkType.Response] },
      { id: 1, description: CommodityWorkType[CommodityWorkType.Repair] },
    ]
    return commodityWorkTypesItems
  }
}
</script>

<style scoped>
.policy-logo img {
  width: 100px;
  border: 1px solid #bdbbbb;
  padding: 0px 15px;
}
.peril-repeat >>> .v-messages__wrapper .v-messages__message {
  padding-bottom: 5px;
}
</style>
