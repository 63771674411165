<template>
  <div>
    <div class="mb-2">
      <h4>{{ item.id ? 'Update' : 'Add' }} Trial Pit Drawing</h4>
    </div>
    <div v-if="item.testDetails">
      <div class="mb-2"><h4>Test Details</h4></div>
      <div v-if="item.testDetails.testId" class="mb-1">
        <label>Test Id: </label>
        <span>{{ item.testDetails.testId }}</span>
      </div>
      <div v-if="item.testDetails.testDate" class="mb-1">
        <label>Test Date: </label>
        <span>{{ getFormattedDate(item.testDetails.testDate) }}</span>
      </div>
      <div v-if="item.testDetails.actualTestDate" class="mb-1">
        <label>Actual Test Date: </label>
        <span>{{ getFormattedDate(item.testDetails.actualTestDate) }}</span>
      </div>
      <div v-if="item.testDetails.testTime" class="mb-1">
        <label>Test Time: </label>
        <span>{{ getFormattedTime(item.testDetails.testTime) }}</span>
      </div>
      <div v-if="item.testDetails.actualTestTime" class="mb-1">
        <label>Actual Test Time: </label>
        <span>{{ getFormattedTime(item.testDetails.actualTestTime) }}</span>
      </div>
      <div v-if="item.testDetails.testWeather" class="mb-1">
        <label>Test Weather: </label>
        <span>{{ item.testDetails.testWeather }}</span>
      </div>
      <div v-if="item.testDetails.testExcavationMethod" class="mb-1">
        <label>Test Excavation Method: </label>
        <span>{{ item.testDetails.testExcavationMethod }}</span>
      </div>
    </div>
    <div v-if="item.pitDetail">
      <v-divider class="mb-1"></v-divider>
      <div class="mb-2"><h4>Pit Details</h4></div>
      <div v-if="item.pitDetail.width" class="mb-1">
        <label>Width(mm): </label>
        <span>{{ item.pitDetail.width }}</span>
      </div>
      <div v-if="item.pitDetail.height" class="mb-1">
        <label>Height(mm): </label>
        <span>{{ item.pitDetail.height }}</span>
      </div>
      <div v-if="item.pitDetail.depth" class="mb-1">
        <label>Depth(mm): </label>
        <span>{{ item.pitDetail.depth }}</span>
      </div>
      <div v-if="item.pitDetail.groundLevel" class="mb-1">
        <label>Ground Level(mm): </label>
        <span>{{ item.pitDetail.groundLevel }}</span>
      </div>
      <div v-if="item.pitDetail.groundLevelLabel" class="mb-1">
        <label>Ground Level Label: </label>
        <span>{{ item.pitDetail.groundLevelLabel }}</span>
      </div>
      <div v-if="item.pitDetail.aboveGroundMaterial" class="mb-1">
        <label>Above Ground Material: </label>
        <span>{{ item.pitDetail.aboveGroundMaterial }}</span>
      </div>
      <div v-if="item.pitDetail.strataBelowRef" class="mb-1">
        <label>Strata Below Ref: </label>
        <span>{{ item.pitDetail.strataBelowRef }}</span>
      </div>
    </div>
    <div v-if="item.leftLayers.length > 0">
      <v-divider class="mb-1"></v-divider>
      <div class="mb-2"><h4>Left Layer Details</h4></div>
      <div v-for="(layer, index) in item.leftLayers" :key="index">
        <div v-if="layer.label" class="mb-1">
          <label>Label: </label>
          <span>{{ layer.label }}</span>
        </div>
        <div v-if="layer.layerType" class="mb-1">
          <label>Layer Type: </label>
          <span>{{ layer.layerType }}</span>
        </div>
        <div v-if="layer.topStep" class="mb-1">
          <label>Top Step(mm): </label>
          <span>{{ layer.topStep }}</span>
        </div>
        <div v-if="layer.layerDepth" class="mb-1">
          <label>Layer Depth(mm): </label>
          <span>{{ layer.layerDepth }}</span>
        </div>
        <div v-if="layer.bottomStep" class="mb-1">
          <label>Bottom Step(mm): </label>
          <span>{{ layer.bottomStep }}</span>
        </div>
        <div v-if="layer.edgeType" class="mb-1">
          <label>Edge Type: </label>
          <span>{{ layer.edgeType }}</span>
        </div>
        <div v-if="layer.layerDivider" class="mb-1">
          <label>Layer Divider: </label>
          <span>{{ layer.layerDivider }}</span>
        </div>
        <div v-if="layer.horizontalMeasurement" class="mb-1">
          <label>Horizontal Measurement: </label>
          <span>{{ layer.horizontalMeasurement }}</span>
        </div>
        <div v-if="layer.verticalMeasurement" class="mb-1">
          <label>Vertical Measurement: </label>
          <span>{{ layer.verticalMeasurement }}</span>
        </div>
        <v-divider v-if="item.leftLayers.length - 1 !== index" class="mb-1 mr-2 ml-2"></v-divider>
      </div>
    </div>
    <div v-if="item.rightLayers.length > 0">
      <v-divider class="mb-1"></v-divider>
      <div class="mb-2"><h4>Right Layer Details</h4></div>
      <div v-for="(layer, index) in item.rightLayers" :key="index">
        <div v-if="layer.label" class="mb-1">
          <label>Label: </label>
          <span>{{ layer.label }}</span>
        </div>
        <div v-if="layer.layerType" class="mb-1">
          <label>Layer Type: </label>
          <span>{{ layer.layerType }}</span>
        </div>
        <div v-if="layer.topStep" class="mb-1">
          <label>Top Step(mm): </label>
          <span>{{ layer.topStep }}</span>
        </div>
        <div v-if="layer.layerDepth" class="mb-1">
          <label>Layer Depth(mm): </label>
          <span>{{ layer.layerDepth }}</span>
        </div>
        <div v-if="layer.bottomStep" class="mb-1">
          <label>Bottom Step(mm): </label>
          <span>{{ layer.bottomStep }}</span>
        </div>
        <div v-if="layer.edgeType" class="mb-1">
          <label>Edge Type: </label>
          <span>{{ layer.edgeType }}</span>
        </div>
        <div v-if="layer.rootsObserved" class="mb-1">
          <label>Roots Observed: </label>
          <span>{{ layer.rootsObserved }}</span>
        </div>
        <div v-if="layer.rootsMaxDiameter" class="mb-1">
          <label>Roots Max Diameter(mm): </label>
          <span>{{ layer.rootsMaxDiameter }}</span>
        </div>
        <div v-if="layer.layerDivider" class="mb-1">
          <label>Layer Divider: </label>
          <span>{{ layer.layerDivider }}</span>
        </div>
        <div v-if="layer.horizontalMeasurement" class="mb-1">
          <label>Horizontal Measurement: </label>
          <span>{{ layer.horizontalMeasurement }}</span>
        </div>
        <div v-if="layer.verticalMeasurement" class="mb-1">
          <label>Vertical Measurement: </label>
          <span>{{ layer.verticalMeasurement }}</span>
        </div>
        <v-divider v-if="item.rightLayers.length - 1 !== index" class="mb-1 mr-2 ml-2"></v-divider>
      </div>
    </div>
    <div v-if="item.features.length > 0">
      <v-divider class="mb-1"></v-divider>
      <div class="mb-2"><h4>Feature Details</h4></div>
      <div v-for="(layer, index) in item.features" :key="index">
        <div v-if="layer.type" class="mb-1">
          <label>Type: </label>
          <span>{{ layer.type }}</span>
        </div>
        <div v-if="layer.label" class="mb-1">
          <label>Label: </label>
          <span>{{ layer.label }}</span>
        </div>
        <div v-if="layer.alignment" class="mb-1">
          <label>Alignment: </label>
          <span>{{ layer.alignment }}</span>
        </div>
        <div v-if="layer.orientation" class="mb-1">
          <label>Orientation: </label>
          <span>{{ layer.orientation }}</span>
        </div>
        <div v-if="layer.position" class="mb-1">
          <label>Position: </label>
          <span>{{ layer.position }}</span>
        </div>
        <div v-if="layer.shape" class="mb-1">
          <label>Shape: </label>
          <span>{{ layer.shape }}</span>
        </div>
        <div v-if="layer.y1" class="mb-1">
          <label>Y1: </label>
          <span>{{ layer.y1 }}</span>
        </div>
        <div v-if="layer.y2" class="mb-1">
          <label>Y2: </label>
          <span>{{ layer.y2 }}</span>
        </div>
        <div v-if="layer.x1" class="mb-1">
          <label>X1: </label>
          <span>{{ layer.x1 }}</span>
        </div>
        <div v-if="layer.x2" class="mb-1">
          <label>X2: </label>
          <span>{{ layer.x2 }}</span>
        </div>
        <div v-if="layer.depth" class="mb-1">
          <label>Depth(mm): </label>
          <span>{{ layer.depth }}</span>
        </div>
        <div v-if="layer.width" class="mb-1">
          <label>Width(mm): </label>
          <span>{{ layer.width }}</span>
        </div>
        <div v-if="layer.height" class="mb-1">
          <label>Height(mm): </label>
          <span>{{ layer.height }}</span>
        </div>
        <v-divider v-if="item.features.length - 1 !== index" class="mb-1 mr-2 ml-2"></v-divider>
      </div>
    </div>
    <div v-if="item.tests.length > 0">
      <v-divider class="mb-1"></v-divider>
      <div class="mb-2"><h4>Tests</h4></div>
      <div v-for="(test, index) in item.tests" :key="index">
        <div v-if="test.testType" class="mb-1">
          <label>Test Type: </label>
          <span>{{ test.testType }}</span>
        </div>
        <div v-if="test.distanceFromWall" class="mb-1">
          <label>Distance From Wall(mm): </label>
          <span>{{ test.distanceFromWall }}</span>
        </div>
        <div v-if="test.depthOfSample" class="mb-1">
          <label>Depth of Sample: </label>
          <span>{{ test.depthOfSample }}</span>
        </div>
        <div v-if="test.testResults" class="mb-1">
          <label>Test Results: </label>
          <span>{{ test.testResults }}</span>
        </div>
        <v-divider v-if="item.tests.length - 1 !== index" class="mb-1 mr-2 ml-2"></v-divider>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddTrialPitDrawingModel from '@/models/siteInvestigation/requests/AddTrialPitDrawingModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class UploadTrialPitDrawingLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddTrialPitDrawingModel {
    return Object.assign(new AddTrialPitDrawingModel(), this.requestBody)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }

  private getFormattedTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
