<template>
  <v-flex :class="flexClass">
    <v-card :class="'elevation-0 white--text pa-0 ' + colorClass" :style="{ backgroundColor: 'purple' }">
      <v-card-text class="pa-0">
        <div class="card-top-content">
          <h2 class="pa-2">{{ title }}</h2>
          <div class="day-counts-section"></div>
        </div>
        <div class="syetsm-dashboard-colum">
          <DashboardChart :chart-data="chartData" :chart-type="chartTypeEnum[chartTypeEnum.Line]" />
          <DashboardPanelLine
            v-for="panelLine of panelLines"
            :key="panelLine.title"
            v-bind="panelLine"
            :custom-color="panelLine.hasLightColor ? 'customColors' : ''"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DashboardChart from '@/components/dashboard/DashboardChart.vue'
import DashboardPanelLine, { PanelLineProps } from '@/components/dashboard/DashboardPanelLine.vue'
import { ChartType } from '@/common/enums'

export interface ContractorPanelProps {
  title: string
  chartData: number[]
  panelLines: PanelLineProps[]
  color?: string
  colorClass?: string
}

@Component({
  name: 'DashboardContractorPanel',
  components: {
    DashboardChart,
    DashboardPanelLine,
  },
})
export default class DashboardContractorPanel extends Vue {
  @Prop() private title: string
  @Prop() private chartData: number[]
  @Prop() private panelLines: PanelLineProps[]
  @Prop({ default: '' }) private colorClass?: string
  @Prop() private flexClass: string
  private chartTypeEnum = ChartType
}
</script>

<style scoped>
.infocalls- h2 {
  font-size: 38px;
  display: inline-block;
  vertical-align: top;
}
.system-info h2 {
  font-weight: normal;
  font-size: 18px;
  width: 52%;
  white-space: inherit;
}
.system-info.syetsm-dashboard h2 {
  width: calc(100% - 196px);
}
.system-info.syetsm-dashboard .v-card {
  height: 100% !important;
  display: flex;
}
.sys-call-info {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}
.sys-call-info .v-badge {
  position: ABSOLUTE;
  top: 2px;
  right: -6px;
  background-color: #f44336;
  height: 22px;
  width: 22px;
  text-align: CENTER;
  border-radius: 50%;
  z-index: 1;
  font-size: 12px;
  padding: 2px 0px;
}
.sys-call-info > label {
  flex: 2;
  display: flex;
  margin: auto;
}
.sys-call-info > .sys-call-content {
  flex: 6;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
}
.sys-call-info {
  min-height: 57px;
}
.sys-call-content .num,
.sys-call-content .time {
  font-size: 30px;
  display: inline-block;
  height: 100%;
  line-height: normal;
  padding: 0px 5px;
}
.syetsm-dashboard.system-info h2 {
  display: inline-block;
}
.syetsm-dashboard.system-info .day-counts-section {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
}
.syetsm-dashboard.system-info .day-counts-section > span {
  height: 35px;
  width: 60px;
  display: inline-block;
  padding: 7px 0px;
  margin: 0px 2px;
}
.syetsm-dashboard.system-info .day-counts-section > span:last-child {
  margin-right: 0px;
}
.syetsm-dashboard.system-info .day-counts-section .count {
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
}
.syetsm-dashboard.system-info .day-counts-section .v-icon {
  font-size: 15px;
  line-height: 1.4;
  position: relative;
  top: 1px;
}
.syetsm-dashboard.system-info .v-card__text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.customColor {
  display: inline-block;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.customColors {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.syetsm-dashboard-colum >>> .v-icon,
.card-small-tile >>> .v-icon {
  vertical-align: middle;
}
.card-small-tile >>> .v-icon {
  vertical-align: middle;
}
@media only screen and (max-width: 1683px) {
  .m-supvr-rcol .system-info.syetsm-dashboard h2 {
    display: block !important;
    width: 100% !important;
  }
  .m-supvr-rcol .syetsm-dashboard.system-info .day-counts-section {
    padding-left: 5px;
  }
  .m-supvr-rcol .sys-call-info > .sys-call-content {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1263px) {
  .m-supvr-rcol .system-info.syetsm-dashboard h2 {
    display: inline-block !important;
    width: calc(100% - 210px) !important;
  }
}
</style>
