<template>
  <div>
    <v-progress-circular
      v-if="isLoading"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <v-layout v-else wrap>
      <v-card class="pt-0 px-2 pb-2">
        <v-card-title>
          <v-layout>
            <v-flex xs12>
              <h3 class="heading">
                <span class="teal--text">Financial Summary</span>
              </h3>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout v-if="jobSummaryData" wrap pa-2 blue-grey lighten-4 class="jobSummaryData">
            <v-flex xs5 mb-1>
              <label><b>Job Created At:</b></label>
            </v-flex>
            <v-flex xs7>
              <span>&nbsp;{{ jobSummaryData.jobCreatedAt ? getFormattedDate(jobSummaryData.jobCreatedAt) : '' }}</span>
            </v-flex>
            <v-flex xs5 mb-1>
              <label><b>Policy:</b></label>
            </v-flex>
            <v-flex xs7 class="text-xs-left">
              <span>&nbsp;{{ jobSummaryData.policyName }}</span>
            </v-flex>
            <v-flex xs5 mb-1>
              <label><b>Assumed Cover Status:</b></label>
            </v-flex>
            <v-flex xs7>
              <span>&nbsp;{{ jobSummaryData.assumedCoverStatus }}</span>
            </v-flex>
            <v-flex xs5 mb-1>
              <label><b>Validated:</b></label>
            </v-flex>
            <v-flex xs7>
              <span>&nbsp;{{ jobSummaryData.validated }}</span>
            </v-flex>
            <v-flex xs5 mb-1>
              <label><b>Number of Emergencies:</b></label>
            </v-flex>
            <v-flex xs7>
              <span>&nbsp;{{ jobSummaryData.numberOfEmergencies }}</span>
            </v-flex>
            <v-flex xs5 mb-1>
              <label><b>Number of Visits:</b></label>
            </v-flex>
            <v-flex xs7>
              <span>&nbsp;{{ jobSummaryData.numberOfVisits }}</span>
            </v-flex>
            <v-flex xs5 mb-1>
              <label><b>Trades Used:</b></label>
            </v-flex>
            <v-flex xs7>
              <span>&nbsp;{{ jobSummaryData.tradesUsed }}</span>
            </v-flex>
          </v-layout>
          <v-flex v-if="jobFinancialInvoiceItems && jobFinancialInvoiceItems.length > 0" xs12 mt-5>
            <v-data-table
              :headers="jobFinancialFeesHeaders"
              :items="jobFinancialInvoiceItems"
              hide-actions
              class="elevation-1 gridView jobFinancialFees"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>{{ props.item.invoiceName }}</td>
                  <td>{{ getFormattedPlainDate(props.item.invoiceDate) }}</td>
                  <td>{{ props.item.invoiceFor }}</td>
                  <td>{{ props.item.invoiceStatus }}</td>
                  <td class="text-xs-right">
                    {{ props.item.sales != null ? getFormatedCurrency(props.item.sales) : '-' }}
                  </td>
                  <td class="text-xs-right">
                    {{ props.item.costs != null ? getFormatedCurrency(props.item.costs) : '-' }}
                  </td>
                </tr>
              </template>
              <template slot="footer">
                <td class="text-xs-right" :colspan="4">
                  <strong>Total</strong>
                </td>
                <td class="text-xs-right pr-2">
                  <b>
                    {{ getFormatedCurrency(jobFinancialInvoiceItems.reduce((a, b) => a + b.sales, 0)) }}
                  </b>
                </td>
                <td class="text-xs-right pr-2">
                  <b>
                    {{ getFormatedCurrency(jobFinancialInvoiceItems.reduce((a, b) => a + b.costs, 0)) }}
                  </b>
                </td>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex>
            <v-data-table
              :headers="invoicePdfHeaders"
              :items="invoicePdfItems"
              hide-actions
              class="elevation-1 gridView mt-5 invoicePdfTable"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>{{ props.item.policyName }}</td>
                  <td>{{ getFormattedDate(props.item.createdAt) }}</td>
                  <td>{{ getFormatedCurrency(props.item.totalCost) }}</td>
                  <td>
                    <v-btn icon @click.native="openPDFDocument(props.index, props.item.pdfDetailUri)">
                      <v-icon>picture_as_pdf</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-layout>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import JobController from '@/api/jobController'
import eventBus from '@/common/bus'
import FinancialSummaryCardDetailModel from '@/models/FinancialSummaryCardDetailModel'
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import TimeLineItemBase from '../TimeLineItemBase'
import FinancialSummaryInvoiceCardDetailModel from '@/models/FinancialSummaryInvoiceCardDetailModel'
import InvoiceController from '@/api/invoiceController'
import Invoice from '@/models/client/Invoice'
import DocumentController from '@/api/documentController'

@Component
export default class JobFinancialSummaryPreview extends TimeLineItemBase {
  private invoicePdfHeaders: any = []
  private jobFinancialInvoiceItems: FinancialSummaryInvoiceCardDetailModel[] = []
  private invoicePdfItems: Invoice[] | null = null
  private isLoading = true
  private jobSummaryData: FinancialSummaryCardDetailModel | null = null
  private downloadDocumentCurrentIndex = -1
  private downloadDocumentRunning = false
  private downloadPDFDocumentRunning = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''

  private get jobFinancialFeesHeaders(): any[] {
    const result: any[] = [
      { text: '', value: 'invoiceName', sortable: false },
      { text: '', value: 'invoiceDate', sortable: false },
      { text: '', value: 'invoiceFor', sortable: false },
      { text: '', value: 'invoiceStatus', sortable: false },
      { text: 'Sales', value: 'sales', align: 'right', sortable: false },
      { text: 'Costs', value: 'cost', align: 'right', sortable: false },
    ]
    return result
  }

  private created() {
    this.invoicePdfHeaders = [
      { text: 'Name', value: 'invoiceName', sortable: false },
      { text: 'Created At', value: 'invoiceCreatedAt', sortable: false },
      { text: 'Amount', value: 'invoiceAmount', sortable: false },
      { text: '', value: 'invoicePdf', sortable: false },
    ]

    this.getFinancialSummaryPreviewData()
  }

  private getFinancialSummaryPreviewData() {
    this.isLoading = true

    JobController.GetFinancialSummaryCardDetail(this.jobId)
      .then((res: FinancialSummaryCardDetailModel | null) => {
        if (res) {
          this.jobSummaryData = res
          this.jobFinancialInvoiceItems = this.jobSummaryData.financialSummaries
          this.getInvoicePdfs()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading job financial summary details', true)
        this.isLoading = false
      })
  }

  private getInvoicePdfs() {
    if (this.jobSummaryData === null) {
      this.invoicePdfItems = []
      this.isLoading = false
      return
    }

    InvoiceController.GetInvoicesByJob(this.jobSummaryData.clientId, this.jobId)
      .then((res: Invoice[] | null) => {
        if (res) {
          this.invoicePdfItems = res
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading job financial invoices', true)
        this.isLoading = false
      })
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getFormattedPlainDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private openPDFDocument(index: number, url: string) {
    this.downloadPDFDocumentRunning = true
    this.openDocument(index, url)
  }

  private openDocument(index: number, url: string) {
    this.downloadDocumentCurrentIndex = index
    this.downloadDocumentRunning = true
    DocumentController.GetSASTokenForBordereauInvoicingDocument(url)
      .then((res: string) => {
        if (res) {
          window.open(res, '_blank')
        } else {
          this.showMessage('Unable to download document.')
        }
        this.resetDownload()
      })
      .catch((err: any) => {
        this.showMessage('Unable to download document.')
        this.resetDownload()
      })
  }

  private resetDownload() {
    this.downloadDocumentRunning = false
    this.downloadPDFDocumentRunning = false
    this.downloadDocumentCurrentIndex = -1
  }

  private showMessage(message: string) {
    this.snackbar = true
    this.snackbarText = message
  }
}
</script>

<style scoped>
.jobFinancialFees .invoicePdfTable >>> table.v-table tbody td,
.jobFinancialFees .invoicePdfTable >>> table.v-table tbody th {
  font-size: 13px;
  padding: 0 22px 0 10px !important;
  height: 30px;
}
.jobFinancialFees .invoicePdfTable >>> table.v-table thead tr,
.jobFinancialFees .invoicePdfTable >>> table.v-table thead tr th {
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 22px 0 10px !important;
  height: 30px;
  font-size: 12px !important;
}
</style>
