import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import eventBus from '@/common/bus'

export interface ICreateSignalRConnection {
  url: string
  accessToken: string
  ttl: number
}

export default class SignalRController {
  public static async Connect(hubName: string): Promise<ICreateSignalRConnection | null> {
    try {
      const res = await CallCentreApi.post<ICreateSignalRConnection>('signalr/connect', {
        hubName,
      })
      if (res.status !== 200) {
        return null
      }
      return res.data
    } catch (err: unknown) {
      eventBus.$emit('errorHandler', err, false)
      return null
    }
  }
}
