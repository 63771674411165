<template>
  <v-dialog
    v-if="showDocumentTemplateDialog"
    v-model="showDocumentTemplateDialog"
    max-width="900"
    persistent
    content-class="v-dialog--scrollable"
  >
    <v-card>
      <v-toolbar card dark color="primary">
        <v-toolbar-title>Document Template</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="close-btn" @click="closeDocumentTemplateDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text class="scroll-content-dialog px-4 pt-4">
        <v-layout wrap>
          <v-flex xs12 pr-2>
            <v-select
              v-model="selectedDocumentTemplate"
              label="Select template name"
              maxlength="100"
              :items="documentTemplateList"
              item-text="name"
              item-value="templateId"
              :loading="loadTemplatesInProgress"
              @change="onTemplateChange($event)"
            ></v-select>
          </v-flex>
          <v-flex xs12>
            <froala
              id="froala"
              v-model="templateText"
              v-validate="'required'"
              froala
              data-vv-scope="templateformRef"
              class="required"
              :required="true"
              :error-messages="ValidateEditor('Document Template')"
              data-vv-name="Document Template"
              :config="config"
              :tag="'textarea'"
            ></froala>
            <div class="input-group__details">
              <div class="input-group__messages input-group__error input-group--error error--text">
                <span v-if="ValidateEditor('Document Template')">
                  {{ ValidateEditor('Document Template') }}
                </span>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" flat="flat" @click.native="closeDocumentTemplateDialog">Close</v-btn>
        <v-btn
          color="primary"
          :loading="generatePDFInProcess"
          :disabled="generatePDFInProcess || selectedDocumentTemplate === -1"
          class="mr-0 saveDocument-btn"
          @click="saveDocument"
        >
          Save As Document
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'

@Component
export default class ReportDocumentTemplate extends Vue {
  public generatePDFInProcess = false
  public loadTemplatesInProgress = false
  public selectedDocumentTemplate = -1
  public templateText = ''

  @Prop() private documentTemplateList: DocumentTemplateModel[]
  @Prop({ default: '' }) private jobId: string
  private showDocumentTemplateDialog = true
  private previouslySelectedDocumentTemplate = -1
  private templateTextTemp = ''

  private get config(): any {
    return this.jobId
      ? Shared.froalaConfig(true, 'Document/GetFroalaImages', {
          jobId: this.jobId,
        })
      : Shared.froalaConfig(true)
  }

  public handleTemplateResponse(res: DocumentTemplateModel, documentTemplateId: number) {
    const self = this
    self.previouslySelectedDocumentTemplate = documentTemplateId
    if (res) {
      // bind froala editor
      self.templateText = res.emailText ? res.emailText : ''
      self.templateTextTemp = self.templateText
    }
    if (!res || !self.templateText) {
      this.$emit('showSnackBarWithMessage', 'Template Is Empty')
    }
    self.loadTemplatesInProgress = false
  }

  private onTemplateChange(documentTemplateId: number) {
    const froalaEditor = document.getElementById('froala') as any
    const editorValue = froalaEditor.value
    if (editorValue && documentTemplateId && this.templateText !== this.templateTextTemp) {
      Shared.confirmationPopup.open(
        'Are you sure, your changes will be lost?',
        '',
        '',
        'No',
        'Yes',
        this,
        'confirmChangesToApply',
        documentTemplateId,
        'cancelForTemplateChange'
      )
    } else {
      this.confirmChangesToApply(documentTemplateId)
    }
  }

  private closeDocumentTemplateDialog() {
    this.$emit('closeDocumentTemplateDialog')
  }

  private cancelForTemplateChange(documentTemplateId: number) {
    setTimeout(() => {
      this.selectedDocumentTemplate = this.previouslySelectedDocumentTemplate
    }, 0)
  }

  private confirmChangesToApply(documentTemplateId: number) {
    // open froala editor and bind template into that model below the template dropdown
    const self = this
    if (!documentTemplateId) {
      this.$emit('showSnackBarWithMessage', 'Kindly select Template')
      return
    }
    self.loadTemplatesInProgress = true
    this.$emit('getDocumentTemplateText', documentTemplateId)
  }

  private async validateTemplate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('templateformRef')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private ValidateEditor(label: string): string {
    const message: string = this.$validator.errors.collect(label)[0]
    return message
  }

  private saveDocument() {
    const self = this
    self
      .validateTemplate()
      .then((result: boolean) => {
        if (result) {
          if (!self.templateText) {
            this.$emit('showSnackBarWithMessage', 'Template cannot be blank')
            return
          }
          this.$emit('saveDocument', this.selectedDocumentTemplate, this.templateText)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }
}
</script>

<style scoped></style>
