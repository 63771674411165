<template>
  <v-layout wrap>
    <v-flex v-if="!fulfilledCutOff" xs12>
      <v-alert :value="true" color="red lighten-5 red--text" type="error" class="custom-warning pa-2">
        <span>Cut-off period doesn't comply for this emergency.</span>
      </v-alert>
    </v-flex>
    <v-flex v-if="!isEmergencyCovered" xs12>
      <v-alert :value="true" color="red lighten-5 red--text" type="error" class="custom-warning pa-2">
        <span>Emergency not covered in policy schedule.</span>
      </v-alert>
    </v-flex>
    <v-flex v-for="item in keyItems" :key="item.id" xs12>
      <span>
        {{ item.questionText }}:
        <b>{{ item.answer }} {{ item.answer !== '' ? item.unit : '' }}</b>
      </span>
    </v-flex>
    <v-flex
      v-if="
        !emergency.isEmergencyAccepted &&
        emergency.repudiationDescription !== null &&
        emergency.repudiationDescription !== ''
      "
      xs12
      mt-1
    >
      <label class="red--text"><b>Reject Claim:</b></label>
      <span>{{ emergency.repudiationDescription }}</span>
    </v-flex>
    <v-flex
      v-if="!emergency.isEmergencyAccepted && emergency.acceptRejectNote !== null && emergency.acceptRejectNote !== ''"
      xs12
      mt-1
    >
      <label class="red--text"><b>Additional Info:</b></label>
      <v-tooltip top max-width="500">
        <template #activator="{ on }">
          <span class="repudiation-description" v-on="on">
            {{ emergency.acceptRejectNote }}
          </span>
        </template>
        <span>{{ emergency.acceptRejectNote }}</span>
      </v-tooltip>
    </v-flex>
    <v-flex
      v-else-if="
        emergency.isEmergencyAccepted && emergency.acceptRejectNote !== null && emergency.acceptRejectNote !== ''
      "
      xs12
      mt-1
    >
      <label class="green--text"><b>Accept Claim:</b></label>
      <span>{{ emergency.acceptRejectNote }}</span>
    </v-flex>
    <v-flex
      v-if="
        (emergency.isEmergencyAccepted ||
          emergency.isEmergencySoftFixed ||
          (!emergency.isEmergencyAccepted &&
            emergency.repudiationDescription !== null &&
            emergency.repudiationDescription !== '')) &&
        emergency.emergencyCompletedAt
      "
      xs12
      mt-1
    >
      <label
        v-if="
          emergency.isEmergencyAccepted &&
          (emergency.repudiationDescription === null || emergency.repudiationDescription === '')
        "
      >
        <b>Accepted at:</b>
      </label>
      <label
        v-if="
          emergency.isEmergencySoftFixed &&
          (emergency.repudiationDescription === null || emergency.repudiationDescription === '')
        "
      >
        <b>Soft Fixed at:</b>
      </label>
      <label v-else-if="!emergency.isEmergencyAccepted">
        <b>Rejected at:</b>
      </label>
      <span>{{ getFormattedDateTime(emergency.emergencyCompletedAt) }}</span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQA from '@/models/EmergencyQA'
import storeGetters from '@/storeGetters'
import moment from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'

@Component
export default class EmergencyQACard extends TimeLineItemBase {
  private get items(): EmergencyQA[] {
    return storeGetters.getQAsForEmergency(this.jobId, this.emergencyId)
  }

  private get keyItems(): EmergencyQA[] {
    return this.items.filter((e) => e.keyQuestion)
  }

  // Check cut-off period comply with emergency or not.
  private get fulfilledCutOff(): boolean {
    let fulfilledCutOff = false

    if (this.emergency) {
      const createdAt = moment(this.emergency.createdAt)
      const duration = moment.duration(createdAt.diff(this.emergency.firstNoticedAt))
      const hours = duration.asHours()
      if (this.emergency.cutOffHours === null || hours <= this.emergency.cutOffHours) {
        fulfilledCutOff = true
      }
    }

    return fulfilledCutOff
  }

  private get isEmergencyCovered(): boolean {
    if (this.emergency) {
      return this.emergency.isCovered
    }
    return false
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>

<style scoped>
.custom-warning {
  border-top: 0px;
}
.custom-warning >>> .v-icon {
  color: #f44336 !important;
}
.repudiation-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  word-break: break-all;
}
</style>
