<template>
  <div class="clientrate elevation-0">
    <v-layout wrap>
      <v-flex xs12>
        <v-card class="elevation-1">
          <v-card-title v-if="!showRateTemplateInDialog">
            <h3>Client Rate</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <v-container fluid grid-list-lg pa-0>
              <v-layout wrap class="align-center">
                <v-flex xs7>
                  <div class="newindicator-chip search-field">
                    <v-autocomplete
                      v-if="!isAccessToCreateRatePackage"
                      v-model="selectedRatePackage"
                      v-validate="'required'"
                      label="Search package"
                      return-object
                      :items="getClientRatePackageList"
                      item-text="packageName"
                      item-value="id"
                      data-vv-scope="frmClientRate"
                      data-vv-name="Search package"
                      :error-messages="errors.collect('Search package')"
                      class="selectedRatePackage"
                      :disabled="fromJobCompletionScreen"
                      @change="onClientRatePackageChange($event)"
                    ></v-autocomplete>
                    <v-combobox
                      v-if="isAccessToCreateRatePackage"
                      v-model="selectedRatePackage"
                      v-validate="'required'"
                      label="Search package"
                      return-object
                      :items="getClientRatePackageList"
                      item-text="packageName"
                      item-value="id"
                      data-vv-scope="frmClientRate"
                      data-vv-name="Search package"
                      :error-messages="errors.collect('Search package')"
                      :data-vv-delay="validationDelay"
                      :disabled="fromJobCompletionScreen"
                      @change="onClientRatePackageChange($event)"
                    ></v-combobox>
                    <v-chip color="secondary" class="white--text" small disabled :value="isNewRatePackage">New</v-chip>
                  </div>
                </v-flex>
                <v-flex xs5 class="fee-switch py-0">
                  <v-switch
                    v-model="clientRatePackageModel.isFixedFeePolicy"
                    name="fixedFeePolicy"
                    color="primary"
                    label="Fixed Fee Policy"
                    class="pt-0 mt-0"
                    :readonly="isUserRoleClientManager"
                    @change="onFixedFeePolicyValueChange"
                  ></v-switch>
                </v-flex>
              </v-layout>
              <v-layout wrap mt-0>
                <v-flex xs12>
                  <v-container fluid grid-list-lg pa-0 pb-4>
                    <v-layout wrap>
                      <v-flex xs12 lg7 pb-0>
                        <v-card class="elevation-1 height-100 fees-card">
                          <v-card-title class="py-0">
                            <v-layout wrap class="align-center">
                              <v-flex xs12 my-3>
                                <h3>Handling Fees</h3>
                              </v-flex>
                            </v-layout>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-container fluid grid-list-xl pa-0>
                              <v-layout wrap>
                                <v-flex xs6 py-0>
                                  <div class="fixed-price">
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.claimHandling"
                                      label="Claim Handling"
                                      maxlength="9"
                                      :readonly="isUserRoleClientManager"
                                      name="ClaimHandling"
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                    <v-switch
                                      v-model="clientRatePackageModel.isClaimHandlingVATable"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      name="isClaimHandlingVATable"
                                    ></v-switch>
                                  </div>
                                </v-flex>
                                <v-flex xs6 py-0>
                                  <div class="fixed-price">
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.postDeploymentRepudiatedFee"
                                      label="Repudiated Fee (Post Deployment)"
                                      maxlength="9"
                                      :readonly="isUserRoleClientManager"
                                      name="postDeploymentRepudiatedFee"
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                    <v-switch
                                      v-model="clientRatePackageModel.isPostDeploymentRepudiatedFeeVATable"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      name="isPostDeploymentRepudiatedFeeVATable"
                                    ></v-switch>
                                  </div>
                                </v-flex>
                                <v-flex xs6 py-0>
                                  <div class="fixed-price">
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.preDeploymentCancelledFee"
                                      label="Cancelled Fee (Pre Deployment)"
                                      maxlength="9"
                                      :readonly="isUserRoleClientManager"
                                      name="preDeploymentCancelledFee"
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                    <v-switch
                                      v-model="clientRatePackageModel.isPreDeploymentCancelledFeeVATable"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      name="isPreDeploymentCancelledFeeVATable"
                                    ></v-switch>
                                  </div>
                                </v-flex>
                                <v-flex xs6 py-0>
                                  <div class="fixed-price">
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.abortedVisit"
                                      label="Aborted Visit"
                                      maxlength="5"
                                      :readonly="isUserRoleClientManager"
                                      name="abortedVisit"
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                    <v-switch
                                      v-model="clientRatePackageModel.isAbortedVisitVATable"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      name="isAbortedVisitVATable"
                                    ></v-switch>
                                  </div>
                                </v-flex>
                                <v-flex xs6 py-0>
                                  <div class="fixed-price">
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.postDeploymentCancelledFee"
                                      label="Cancelled Fee (Post Deployment)"
                                      maxlength="5"
                                      :readonly="isUserRoleClientManager"
                                      name="postDeploymentCancelledFee"
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                    <v-switch
                                      v-model="clientRatePackageModel.isPostDeploymentCancelledFeeVATable"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      name="isPostDeploymentCancelledFeeVATable"
                                    ></v-switch>
                                  </div>
                                </v-flex>
                                <v-flex xs6 py-0>
                                  <div class="fixed-price">
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.softFix"
                                      label="Soft Fix"
                                      maxlength="5"
                                      :readonly="isUserRoleClientManager"
                                      name="softFix"
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                    <v-switch
                                      v-model="clientRatePackageModel.isSoftFixVATable"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      name="isSoftFixVATable"
                                    ></v-switch>
                                  </div>
                                </v-flex>
                                <v-flex xs6 py-0>
                                  <div class="fixed-price">
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.preDeploymentRepudiatedFee"
                                      label="Repudiated Fee (Pre Deployment)"
                                      maxlength="5"
                                      :readonly="isUserRoleClientManager"
                                      name="preDeploymentRepudiatedFee"
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                    <v-switch
                                      v-model="clientRatePackageModel.isPreDeploymentRepudiatedFeeVATable"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      name="isPreDeploymentRepudiatedFeeVATable"
                                    ></v-switch>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                      <v-flex lg5 xs12 pb-0 class="right-fee-col">
                        <v-card class="elevation-1 height-100 fees-card">
                          <v-card-title class="py-0">
                            <v-layout wrap class="align-center fixed-fee">
                              <v-flex xs12 my-3>
                                <h3>Other Fees</h3>
                              </v-flex>
                            </v-layout>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-layout wrap>
                              <v-flex xs12>
                                <v-layout wrap class="align-center">
                                  <v-flex xs6>
                                    <label>BER included in Fixed Fee?</label>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-switch
                                      v-model="clientRatePackageModel.clientFixedFeeRates.berIncluded"
                                      name="berIncluded"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      :disabled="!getIsAnyFixedFeeTradeSelected"
                                    ></v-switch>
                                  </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap class="align-center">
                                  <v-flex xs6>
                                    <label>Alternative Accommodation included in Fixed Fee?</label>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-switch
                                      v-model="
                                        clientRatePackageModel.clientFixedFeeRates.alternativeAccommodationIncluded
                                      "
                                      name="alternativeAccommodationIncluded"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      :disabled="!getIsAnyFixedFeeTradeSelected"
                                    ></v-switch>
                                  </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap class="align-center">
                                  <v-flex xs6>
                                    <label>Materials included in Fixed Fee?</label>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-switch
                                      v-model="clientRatePackageModel.clientFixedFeeRates.materialsIncluded"
                                      name="materialsIncluded"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      :disabled="!getIsAnyFixedFeeTradeSelected"
                                    ></v-switch>
                                  </v-flex>
                                  <v-flex xs4>
                                    <v-text-field
                                      v-model.number="clientRatePackageModel.clientFixedFeeRates.materialUplift"
                                      name="otherFeeMaterialUplift"
                                      class="prepend-icon-image percent pt-0 mt-0"
                                      append-icon="close"
                                      maxlength="5"
                                      :readonly="isUserRoleClientManager"
                                      :disabled="
                                        !getIsAnyFixedFeeTradeSelected ||
                                        !clientRatePackageModel.clientFixedFeeRates.materialsIncluded
                                      "
                                      @keypress="numberKeyValidation($event)"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap class="align-center">
                                  <v-flex xs6>
                                    <label>Heaters included in Fixed Fee?</label>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-switch
                                      v-model="clientRatePackageModel.clientFixedFeeRates.heatersIncluded"
                                      name="heatersIncluded"
                                      color="primary"
                                      :readonly="isUserRoleClientManager"
                                      :disabled="!getIsAnyFixedFeeTradeSelected"
                                    ></v-switch>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
                <v-flex xs12>
                  <v-data-table
                    :headers="headers"
                    :items="clientRatePackageModel.clientTradeRates"
                    item-key="tradeId"
                    hide-actions
                    class="elevation-1 client-rate-table"
                    expand
                  >
                    <template slot="headers" slot-scope="props">
                      <tr>
                        <th
                          v-for="header in props.headers"
                          :key="header.text"
                          :class="header.class"
                          :align="header.align"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </template>
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td>
                          <v-select
                            v-model="props.item.tradeId"
                            v-validate="'required'"
                            :items="getTrades"
                            item-value="tradeId"
                            item-text="description"
                            :loading="getTradesRunning"
                            data-vv-scope="frmClientRate"
                            :data-vv-name="'Trade' + props.index"
                            :name="'Trade' + props.index"
                            :error-messages="validationMessage('Trade' + props.index, props.item)"
                            :readonly="isUserRoleClientManager"
                            class="tradeList"
                          >
                            <template slot="item" slot-scope="data">
                              <v-list-tile>
                                <v-list-tile-content>
                                  <v-list-tile-title
                                    :class="getClass(data.item.tradeId)"
                                    v-html="data.item.description"
                                  ></v-list-tile-title>
                                </v-list-tile-content>
                              </v-list-tile>
                            </template>
                          </v-select>
                        </td>
                        <td>
                          <div class="fixed-price">
                            <v-text-field
                              v-model.number="props.item.fixedRate"
                              maxlength="8"
                              class="prepend-icon-image"
                              prepend-icon="close"
                              :disabled="!props.item.useFixedRate"
                              :readonly="isUserRoleClientManager"
                              name="fixedRate"
                              @keypress="numberKeyValidation($event)"
                            ></v-text-field>
                            <v-switch
                              v-model="props.item.useFixedRate"
                              color="primary"
                              label=""
                              value=""
                              :readonly="isUserRoleClientManager"
                              name="useFixedRate"
                            ></v-switch>
                          </div>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="props.item.visit1"
                            maxlength="8"
                            class="prepend-icon-image"
                            prepend-icon="close"
                            :readonly="isUserRoleClientManager"
                            name="visit1"
                            @keypress="numberKeyValidation($event)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="props.item.visit2"
                            maxlength="8"
                            class="prepend-icon-image"
                            prepend-icon="close"
                            :readonly="isUserRoleClientManager"
                            name="visit2"
                            @keypress="numberKeyValidation($event)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="props.item.visit3"
                            maxlength="8"
                            class="prepend-icon-image"
                            prepend-icon="close"
                            :readonly="isUserRoleClientManager"
                            name="visit3"
                            @keypress="numberKeyValidation($event)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.isContainMaterials"
                            color="primary"
                            :disabled="props.item.useFixedRate"
                            :readonly="isUserRoleClientManager"
                            name="isContainMaterials"
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="props.item.materialUplift"
                            maxlength="5"
                            class="prepend-icon-image percent"
                            append-icon="close"
                            :disabled="props.item.useFixedRate"
                            :readonly="isUserRoleClientManager"
                            name="materialUplift"
                            @keypress="numberKeyValidation($event)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-tooltip v-if="!isUserRoleClientManager && !fromJobCompletionScreen" bottom>
                            <template #activator="{ on }">
                              <v-btn
                                v-if="clientRatePackageModel.clientTradeRates.length > 1"
                                flat
                                icon
                                class="mr-0 btn-remove"
                                color="primary"
                                v-on="on"
                                @click.prevent="removeAdditionalRate(props.item)"
                              >
                                <v-icon dark color="primary">close</v-icon>
                              </v-btn>
                            </template>
                            <span>Remove Row</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div class="text-xs-right mt-2">
                    <v-tooltip v-if="!isUserRoleClientManager && !fromJobCompletionScreen" bottom>
                      <template #activator="{ on }">
                        <v-btn class="mr-0 btn-add" icon color="primary" small v-on="on" @click="addAdditionalRate">
                          <v-icon dark class="white--text" small>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Row</span>
                    </v-tooltip>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isUserRoleClientManager && !fromJobCompletionScreen"
              :disabled="isLoading"
              :loading="isLoading"
              color="primary"
              class="btn-saveOrAssignRatePackage"
              @click="SaveOrAssignRatePackage"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
          {{ saveSnackbarText }}
          <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import ClientRatePackageModel from '@/models/client/ClientRatePackageModel'
import RateController from '@/api/rateController'
import Shared from '@/common/shared'
import Store from '@/store'
import { UserRole } from '@/models/user/UserModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import ClientTradeRateModel from '@/models/client/ClientTradeRateModel'
import JobController from '@/api/jobController'
import ClientFixedFeeRateModel from '@/models/client/ClientFixedFeeRateModel'
import eventBus from '@/common/bus'

@Component
export default class ClientRate extends Vue {
  @Prop() private policyId: number
  @Prop() private policyName: string
  @Prop() private ratePackageId: string
  @Prop() private allClientRatePackageList: ClientRatePackageModel[]
  @Prop() private showRateTemplateInDialog: boolean
  @Prop() private selectedRatePackageInPolicy: ClientRatePackageModel | string | null
  @Prop({ default: false }) private fromJobCompletionScreen: boolean

  private clientRatePackageList: ClientRatePackageModel[] = []
  private clientRatePackageModel: ClientRatePackageModel = new ClientRatePackageModel()
  private oldClientRatePackageModel: ClientRatePackageModel = new ClientRatePackageModel()
  private selectedRatePackage: ClientRatePackageModel | string | null = null
  private trades: TradeModel[] = []
  private getTradesRunning = false
  private isLoading = false
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = 'Rate saved successfully.'
  private isNewRatePackage = false
  private headers: any = []
  private previouslySelectedRatePackage: ClientRatePackageModel = new ClientRatePackageModel()
  private validationDelay = Shared.validationDelay

  private created() {
    this.headers = [
      {
        text: 'Trade',
        value: 'tradeId',
        sortable: false,
        align: 'left',
        class: 'contract-type-cul',
      },
      { text: 'Fixed Fee', value: '', sortable: false, align: 'left' },
      { text: 'Visit #1', value: 'visit1', align: 'left', sortable: false },
      {
        text: 'Visit #2',
        value: 'visit2',
        align: 'left',
        sortable: false,
        class: '',
      },
      {
        text: 'Visit #3',
        value: 'visit3',
        align: 'left',
        class: 'team-member-status',
        sortable: false,
      },
      {
        text: 'Materials',
        value: 'isContainMaterials',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Materials Uplift',
        value: 'materialUplift',
        align: 'left',
        sortable: false,
      },
      { text: '', value: '', sortable: false },
    ]
    this.getTradeList()
  }

  private addIdToClientTradeRateList() {
    // add id to clientTradeRates, required to prevent user from select deleted trade, if previously not selected
    for (let index = 0; index < this.clientRatePackageModel.clientTradeRates.length; index++) {
      const element = this.clientRatePackageModel.clientTradeRates[index]
      element.id = index + 1
    }
  }

  @Watch('allClientRatePackageList')
  private allClientRatePackageListChange() {
    this.getClientRatePackages()
  }

  private mounted() {
    this.onRatePackageIdChanged(this.ratePackageId)
    this.selectedRatePackageInPolicyChange()
  }

  @Watch('selectedRatePackageInPolicy')
  private selectedRatePackageInPolicyChange() {
    this.getClientRatePackages()
    setTimeout(() => {
      this.selectedRatePackage = this.selectedRatePackageInPolicy
    }, 0)
    if (this.selectedRatePackageInPolicy && typeof this.selectedRatePackage === 'object') {
      this.clientRatePackageModel = JSON.parse(JSON.stringify(this.selectedRatePackageInPolicy))
      this.addIdToClientTradeRateList()
      this.oldClientRatePackageModel = JSON.parse(JSON.stringify(this.clientRatePackageModel))
      this.previouslySelectedRatePackage = JSON.parse(JSON.stringify(this.selectedRatePackageInPolicy))
    }
  }

  // filter rate packages by ratePackageId from all contractor rate packages
  @Watch('ratePackageId')
  private onRatePackageIdChanged(ratePackageId: string) {
    const clientRatePackageList: ClientRatePackageModel[] = this.getAllClientRatePackageList.filter(
      (a: ClientRatePackageModel) =>
        (a.id === this.ratePackageId && a.forCustomPackagePolicyId === this.policyId) ||
        a.forCustomPackagePolicyId === 0
    )
    this.clientRatePackageList = clientRatePackageList
    if (ratePackageId && this.clientRatePackageList) {
      const clientRatePackage: ClientRatePackageModel | undefined = this.clientRatePackageList.find(
        (e: ClientRatePackageModel) => e.id === ratePackageId
      )
      if (clientRatePackage) {
        this.selectedRatePackage = clientRatePackage
        this.clientRatePackageModel = JSON.parse(JSON.stringify(clientRatePackage))
        this.addIdToClientTradeRateList()
        this.oldClientRatePackageModel = JSON.parse(JSON.stringify(this.clientRatePackageModel))
      }
    } else {
      this.selectedRatePackage = null
      this.clientRatePackageModel = new ClientRatePackageModel()
      this.oldClientRatePackageModel = new ClientRatePackageModel()
    }
  }

  private get getAllClientRatePackageList(): ClientRatePackageModel[] {
    return this.allClientRatePackageList
  }

  private get getClientRatePackageList(): ClientRatePackageModel[] {
    return this.clientRatePackageList
  }

  // return true, if going to assign rate package
  // return false, if create/update rate template
  private get isAccessToCreateRatePackage(): boolean {
    return this.isUserRoleClientManager ? false : this.policyId ? false : true
  }

  // this method return true if any changes done in rates, otherwise return false
  private getChanges(): boolean {
    if (JSON.stringify(this.oldClientRatePackageModel) !== JSON.stringify(this.clientRatePackageModel)) {
      return true
    }
    return false
  }

  private getTradeList() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.trades = res.filter((e) => e.jobType === 'HE')
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.trades = trades.filter((e) => e.jobType === 'HE')
    }
  }

  // client rate package dropdown change event
  private onClientRatePackageChange(event: any) {
    if (this.fromJobCompletionScreen) {
      return
    }
    if (event && event.id) {
      // select rate package from list
      this.isNewRatePackage = false

      this.clientRatePackageModel.id = event.id
      this.clientRatePackageModel.packageName = event.packageName
      this.clientRatePackageModel.claimHandling = event.claimHandling
      this.setClientRatePackageModelValue(event)
      this.clientRatePackageModel.forCustomPackagePolicyId = event.forCustomPackagePolicyId
      this.addIdToClientTradeRateList()
      this.oldClientRatePackageModel = JSON.parse(JSON.stringify(this.clientRatePackageModel))
    } else {
      // creating new rate package
      const packageName = event
      this.clientRatePackageModel = new ClientRatePackageModel()
      if (this.selectedRatePackage && typeof this.selectedRatePackage === 'object') {
        this.setClientRatePackageModelValue(this.selectedRatePackage)
      }
      this.clientRatePackageModel.packageName = packageName
      this.isNewRatePackage = true
    }
  }

  private setClientRatePackageModelValue(model: any) {
    this.clientRatePackageModel.claimHandling = model.claimHandling
    this.clientRatePackageModel.isClaimHandlingVATable = model.isClaimHandlingVATable
    this.clientRatePackageModel.softFix = model.softFix
    this.clientRatePackageModel.isSoftFixVATable = model.isSoftFixVATable
    this.clientRatePackageModel.abortedVisit = model.abortedVisit
    this.clientRatePackageModel.isAbortedVisitVATable = model.isAbortedVisitVATable
    this.clientRatePackageModel.preDeploymentCancelledFee = model.preDeploymentCancelledFee
    this.clientRatePackageModel.isPreDeploymentCancelledFeeVATable = model.isPreDeploymentCancelledFeeVATable
    this.clientRatePackageModel.postDeploymentCancelledFee = model.postDeploymentCancelledFee
    this.clientRatePackageModel.isPostDeploymentCancelledFeeVATable = model.isPostDeploymentCancelledFeeVATable
    this.clientRatePackageModel.preDeploymentRepudiatedFee = model.preDeploymentRepudiatedFee
    this.clientRatePackageModel.isPreDeploymentRepudiatedFeeVATable = model.isPreDeploymentRepudiatedFeeVATable
    this.clientRatePackageModel.postDeploymentRepudiatedFee = model.postDeploymentRepudiatedFee
    this.clientRatePackageModel.isPostDeploymentRepudiatedFeeVATable = model.isPostDeploymentRepudiatedFeeVATable
    this.clientRatePackageModel.isFixedFeePolicy = model.isFixedFeePolicy
    this.clientRatePackageModel.clientFixedFeeRates = model.clientFixedFeeRates
      ? model.clientFixedFeeRates
      : new ClientFixedFeeRateModel()
    this.clientRatePackageModel.clientTradeRates = model.clientTradeRates
  }

  // filter rate packages by client from all client rate packages
  private getClientRatePackages() {
    const self = this
    if (self.getAllClientRatePackageList) {
      const clientRatePackageList: ClientRatePackageModel[] = self.getAllClientRatePackageList.filter(
        (a: ClientRatePackageModel) => a.forCustomPackagePolicyId === this.policyId || a.forCustomPackagePolicyId === 0
      )
      self.clientRatePackageList = JSON.parse(JSON.stringify(clientRatePackageList))
    }
  }

  private async validate(): Promise<boolean> {
    const isSelectedTradeDeleted: boolean = this.checkIsTradeDeleted()
    const result: boolean = await this.$validator.validateAll('frmClientRate')
    // set focus to non validate field
    if (!result || isSelectedTradeDeleted) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result && !isSelectedTradeDeleted
  }

  private validationMessage(label: string, item: any) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (message) {
      return (message = 'The ' + errorMessage[0] + ' is required.')
    } else {
      const clientTradeRate = this.oldClientRatePackageModel.clientTradeRates.findIndex(
        (c) => c.tradeId === item.tradeId && item.id === c.id
      )
      if (clientTradeRate === -1) {
        const trade = this.trades.find((e) => e.isDeleted && e.tradeId === item.tradeId)
        return trade ? (message = 'The selected trade is deleted.') : (message = '')
      }
    }
  }

  // save rate template or assign rate package to client
  private SaveOrAssignRatePackage() {
    this.SaveRatePackage()
  }

  // this method will call when create or update rate template
  private SaveRatePackage() {
    this.getClientRatePackages()
    const self = this
    setTimeout(() => {
      this.validate()
        .then((result: boolean) => {
          if (result && self.clientRatePackageModel) {
            self.isLoading = true
            let isCreatingCustomRate = false
            if (this.policyId) {
              // assign rate package to policy

              // if got any changes in package rate then create custom package rate for that client
              if (self.clientRatePackageModel && self.getChanges()) {
                const ratePackage: ClientRatePackageModel | undefined = self.getClientRatePackageList.find(
                  (p: ClientRatePackageModel) => p.forCustomPackagePolicyId !== 0
                )
                if (ratePackage && ratePackage.id) {
                  // modifying existing custom rate package
                  self.clientRatePackageModel.id = ratePackage.id
                  self.clientRatePackageModel.forCustomPackagePolicyId = ratePackage.forCustomPackagePolicyId
                } else {
                  // creating new custom rate package
                  self.clientRatePackageModel.id = ''
                  self.clientRatePackageModel.forCustomPackagePolicyId = self.policyId
                  isCreatingCustomRate = true
                  self.getAllClientRatePackageList.push(self.clientRatePackageModel)
                }

                // custom package rate name will be client name plus custome rate
                self.clientRatePackageModel.packageName = self.policyName + ' custom rate'
                self.selectedRatePackage = self.clientRatePackageModel
              }
            } else {
              // create/update rate template
              self.clientRatePackageModel.forCustomPackagePolicyId = 0
            }
            const upliftAmount: number = this.clientRatePackageModel.clientFixedFeeRates.materialUplift
            this.clientRatePackageModel.clientFixedFeeRates.materialUplift = upliftAmount ? upliftAmount : 0
            RateController.SaveClientRatePackage(self.clientRatePackageModel)
              .then((res: ClientRatePackageModel | null) => {
                self.isLoading = false
                if (res) {
                  if (this.policyId) {
                    if (isCreatingCustomRate) {
                      self.clientRatePackageModel = res
                      self.clientRatePackageList.push(self.clientRatePackageModel)
                      const index = this.getAllClientRatePackageList.findIndex(
                        (c) =>
                          c.packageName === res.packageName &&
                          c.forCustomPackagePolicyId === res.forCustomPackagePolicyId
                      )
                      if (index !== -1) {
                        this.getAllClientRatePackageList[index] = res
                      }
                    } else {
                      const clientRatePackage: ClientRatePackageModel =
                        this.getAllClientRatePackageList.find(
                          (e: ClientRatePackageModel) => e.id === self.clientRatePackageModel.id
                        ) || new ClientRatePackageModel()
                      Object.assign(clientRatePackage, self.clientRatePackageModel)
                    }
                    const oldClientRatePackageModelObj: ClientRatePackageModel | undefined =
                      this.getAllClientRatePackageList.find(
                        (e: ClientRatePackageModel) =>
                          e.id === this.previouslySelectedRatePackage.id && e.forCustomPackagePolicyId === 0
                      )
                    if (oldClientRatePackageModelObj) {
                      Object.assign(oldClientRatePackageModelObj, this.previouslySelectedRatePackage)
                    }
                  } else {
                    if (self.isNewRatePackage) {
                      self.clientRatePackageModel = res
                      if (this.clientRatePackageModel.clientTradeRates.filter((c) => c.tradeId).length > 0) {
                        this.addIdToClientTradeRateList()
                        this.oldClientRatePackageModel = JSON.parse(JSON.stringify(this.clientRatePackageModel))
                      }
                      self.clientRatePackageList.push(self.clientRatePackageModel)
                      self.getAllClientRatePackageList.push(self.clientRatePackageModel)
                      self.isNewRatePackage = false
                    } else {
                      const clientRatePackage: ClientRatePackageModel =
                        this.getAllClientRatePackageList.find(
                          (e: ClientRatePackageModel) => e.id === self.clientRatePackageModel.id
                        ) || new ClientRatePackageModel()
                      Object.assign(clientRatePackage, self.clientRatePackageModel)
                    }
                    this.getClientRatePackages()
                  }
                  this.$emit('update:allClientRatePackageList', self.getAllClientRatePackageList)
                  if (this.showRateTemplateInDialog) {
                    this.$emit('saveRatePackage', this.clientRatePackageModel)
                  } else {
                    self.saveSnackbar = true
                  }
                }
              })
              .catch((err: any) => {
                self.isLoading = false
                eventBus.$emit('errorHandler', 'Error submitting client rate package, please try again', true)
              })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }, 0)
  }

  // add additional rate
  private addAdditionalRate(): void {
    const cr: ClientTradeRateModel = new ClientTradeRateModel()
    if (this.clientRatePackageModel.clientTradeRates.length > 0) {
      cr.id =
        Math.max.apply(
          Math,
          this.clientRatePackageModel.clientTradeRates.map((e) => e.id)
        ) + 1 //  add id to newly added rate
    }
    this.clientRatePackageModel.clientTradeRates.push(cr)
  }

  // remove additional rate
  private removeAdditionalRate(clientRate: ClientTradeRateModel): void {
    const index = this.clientRatePackageModel.clientTradeRates.indexOf(clientRate)
    this.clientRatePackageModel.clientTradeRates.splice(index, 1)
  }

  private get isUserRoleClientManager(): boolean {
    return (
      (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager') ||
      Store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail'
    )
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private onFixedFeePolicyValueChange() {
    if (this.clientRatePackageModel.clientTradeRates) {
      this.clientRatePackageModel.clientTradeRates.map(
        (e) => (e.useFixedRate = this.clientRatePackageModel.isFixedFeePolicy ? true : false)
      )
      this.clientRatePackageModel.clientTradeRates = [...this.clientRatePackageModel.clientTradeRates]
    }
  }

  private get getIsAnyFixedFeeTradeSelected() {
    if (this.clientRatePackageModel.clientTradeRates) {
      return this.clientRatePackageModel.clientTradeRates.filter((e) => e.useFixedRate).length > 0 ? true : false
    }
    return false
  }

  private getClass(tradeId: number) {
    const trade = this.trades.find((e) => e.tradeId === tradeId)
    return trade && trade.isDeleted ? 'deleted-item' : ''
  }

  private checkIsTradeDeleted() {
    let isSelectedTradeDeleted = false
    for (const clientTradeRate of this.clientRatePackageModel.clientTradeRates) {
      const trade = this.trades.find((tr) => tr.tradeId === clientTradeRate.tradeId)
      const oldTradeRate = this.oldClientRatePackageModel.clientTradeRates.find(
        (e) => e.id === clientTradeRate.id && e.tradeId === clientTradeRate.tradeId
      )
      isSelectedTradeDeleted = oldTradeRate ? false : trade && trade.isDeleted ? true : false
      if (isSelectedTradeDeleted) {
        break
      }
    }
    return isSelectedTradeDeleted
  }

  private get getTrades() {
    return this.trades.filter(
      (e) =>
        !e.isDeleted ||
        (e.isDeleted
          ? this.oldClientRatePackageModel.clientTradeRates.findIndex((i) => i.tradeId === e.tradeId) !== -1
          : false)
    )
  }
}
</script>

<style scoped>
.client-rate-table >>> .contract-type-cul {
  min-width: 210px;
}
.client-rate-table >>> .rm-row-cul {
  min-width: 10px;
}
.client-rate-table :-ms-input-placeholder {
  font-size: 14px;
}
.client-rate-table :-moz-placeholder {
  font-size: 14px;
}
.client-rate-table ::-webkit-input-placeholder {
  font-size: 14px;
}
.client-rate-table .input-group--select .input-group__selections__comma,
.client-rate-table >>> .input-group--text-field input,
.client-rate-table >>> .input-group--text-field textarea {
  font-size: 14px;
}
.client-rate-table >>> table.v-table tbody td:first-child,
.client-rate-table >>> table.v-table tbody td:not(:first-child),
.client-rate-table >>> table.v-table tbody th:first-child,
.client-rate-table >>> table.v-table tbody th:not(:first-child),
.client-rate-table >>> table.v-table thead td:first-child,
.client-rate-table >>> table.v-table thead td:not(:first-child),
.client-rate-table >>> table.v-table thead th:first-child,
.client-rate-table >>> table.v-table thead th:not(:first-child) {
  padding: 0px 12px;
}
.client-rate-table >>> .input-group--select .input-group__selections__comma {
  font-size: 13px;
}
.clientrate >>> input[type='number']::-webkit-inner-spin-button,
.clientrate >>> input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.client-rate-table >>> .input-group {
  padding-top: 5px;
}
.client-rate-table >>> .v-input--checkbox {
  position: relative;
  top: 8px;
}
.ratetabs >>> .v-tabs__bar .v-tabs__div > .v-tabs__item--active {
  background-color: #fff !important;
  color: #e91e63 !important;
}
.newindicator-chip >>> .input-group__input .input-group__selections__comma {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.newindicator-chip >>> .input-group__selections {
  padding-right: 0px;
}
.clientrate .prepend-icon-image >>> i.v-icon {
  position: relative;
  color: #fff !important;
  height: 0px !important;
}
.client-rate-table >>> .v-table tr:hover .prepend-icon-image i.v-icon {
  color: #eee !important;
}
.clientrate .prepend-icon-image >>> i.v-icon::after {
  content: '';
  background-image: url('/img/pound-sterling.svg');
  background-size: cover;
  position: absolute;
  left: 5px;
  top: -7px;
  height: 16px;
  width: 16px;
}
.clientrate .prepend-icon-image.percent >>> i.v-icon::after {
  background-image: url('/img/percent.svg') !important;
}
.fixed-price {
  position: relative;
}
.fixed-price >>> .v-input--switch {
  position: absolute;
  right: 0;
  top: 2px;
  width: auto;
}
.fixed-price >>> .v-input--switch .v-input__slot {
  margin-bottom: 0;
}
.fixed-price >>> .v-input--switch .v-input__slot .v-input--selection-controls__input {
  margin: 0;
}
.fixed-price >>> input {
  padding-right: 50px;
}
.client-rate-table >>> table.v-table thead th:not(:first-child) {
  min-width: 150px;
}
.client-rate-table >>> table.v-table thead th:last-child {
  min-width: 50px !important;
}
.client-rate-table >>> table tbody .v-input {
  padding: 5px 0;
  margin: 0;
}
.client-rate-table >>> table tbody .v-input--checkbox .v-input__control {
  margin: 0 auto;
}
.fixed-fee >>> .v-input--switch.v-input--selection-controls {
  justify-content: flex-end;
}
.right-fee-col >>> .v-input--switch .v-input__slot {
  margin-bottom: 0 !important;
}
.right-fee-col >>> .v-input--switch {
  margin-bottom: 0 !important;
  justify-content: center;
}
.fixed-fee >>> .v-input--switch .v-input__slot {
  margin: 14px 0 8px 0 !important;
}
.fee-switch >>> .v-input--switch .v-input--selection-controls__input {
  order: 2;
  margin: 0 0 0 15px !important;
}
.fee-switch >>> .v-input__slot {
  margin-bottom: 0 !important;
}
.height-100 {
  height: 100%;
}
.fees-card .fixed-price >>> label {
  padding-right: 30px;
}
.search-field {
  max-width: 50%;
}
.fees-card {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
