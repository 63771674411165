<template>
  <v-flex md4 lg2 mb-3>
    <div class="team-divider">
      <v-card class="grey lighten-3 elevation-0">
        <v-card-title class="pa-2 py-3">
          <h3 class="team-title text-xs-center pb-0 mb-0">{{ team.title }}</h3>
          <span data-v-43b78cc0="" class="teambadge text-xs-center">
            {{ getTotalOnlineWorker }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-bar="{ useScrollbarPseudo: true }">
          <div>
            <v-container class="pa-0 mt-3">
              <div v-for="(group, i) in workersSplit" :key="i">
                <v-layout v-if="group.length === 3" row class="three-item text-xs-center">
                  <v-flex v-for="worker in group" :key="worker.sid" xs4>
                    <dashboard-worker
                      :worker="worker"
                      :show-all-worker="showAllWorker"
                      @showOfflineWorkers="showOfflineWorkers"
                    />
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="group.length === 2" row class="two-item text-xs-center">
                  <v-flex v-for="worker in group" :key="worker.sid" xs6>
                    <dashboard-worker
                      :worker="worker"
                      :show-all-worker="showAllWorker"
                      @showOfflineWorkers="showOfflineWorkers"
                    />
                  </v-flex>
                </v-layout>
                <v-layout v-else class="text-xs-center">
                  <v-flex v-for="worker in group" :key="worker.sid" xs12>
                    <dashboard-worker
                      :worker="worker"
                      :show-all-worker="showAllWorker"
                      @showOfflineWorkers="showOfflineWorkers"
                    />
                  </v-flex>
                </v-layout>
              </div>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import DashboardWorker from './DashboardWorker.vue'
import { Team } from './team'
import { WorkerWithAdditions } from './worker'
import Shared from '@/common/shared'

@Component({
  name: 'DashboardTeam',
  components: {
    'dashboard-worker': DashboardWorker,
  },
})
export default class DashboardTeam extends Vue {
  @Prop({ required: true })
  public team: Team
  public allWorkers: WorkerWithAdditions[] = []
  public showAllWorker = false

  public showOfflineWorkers() {
    this.showAllWorker = true
    this.showWorkersByGroup()
  }

  private get getTotalOnlineWorker() {
    return this.team.workers.filter((x) => x.activityName !== 'Offline').length
  }

  public get workersSplit(): WorkerWithAdditions[][] {
    return this.showWorkersByGroup()
  }

  public showWorkersByGroup(): WorkerWithAdditions[][] {
    if (!this.showAllWorker) {
      this.team.workers = Shared.SortData(this.team.workers, 'activityName')
    }
    this.allWorkers = this.team.workers

    if (this.showAllWorker) {
      this.allWorkers = this.team.workers
    } else {
      this.allWorkers = this.allWorkers.filter((x) => x.activityName !== 'Offline')
      const offlineWorkers = this.team.workers.filter((x) => x.activityName === 'Offline')
      if (offlineWorkers.length >= 1) {
        const dummyWorkerForShowMore: any = {}
        dummyWorkerForShowMore.activityName = 'DisplayOfflineUsers'
        this.allWorkers.push(dummyWorkerForShowMore)
      }
    }

    const groups: WorkerWithAdditions[][] = []
    let nextThreeGroup: WorkerWithAdditions[] = []
    let nextTwoGroup: WorkerWithAdditions[] = []
    for (const worker of this.allWorkers) {
      if (nextThreeGroup.length < 3) {
        nextThreeGroup.push(worker)
      } else if (nextTwoGroup.length < 2) {
        nextTwoGroup.push(worker)
      } else {
        groups.push(nextThreeGroup)
        groups.push(nextTwoGroup)

        nextThreeGroup = []
        nextTwoGroup = []

        nextThreeGroup.push(worker)
      }
    }

    if (nextThreeGroup.length > 0) {
      groups.push(nextThreeGroup)
    }
    if (nextTwoGroup.length > 0) {
      groups.push(nextTwoGroup)
    }

    return groups
  }

  public get onlineWorkerCount() {
    if (this.team && this.team.workers) {
      return this.team.workers.filter((e) => e.activityName !== 'Offline').length
    } else {
      return 0
    }
  }
}
</script>

<style scoped>
.teaminfo {
  display: inline-block;
  max-height: 55px;
  max-width: 55px;
}
.teaminfo img {
  max-width: 100%;
  border: 3px solid #4caf50;
  border-radius: 50%;
}
.teaminfo.b-red img {
  border-color: #f44336;
}
.teaminfo.b-orange img {
  border-color: #ff9800;
}
.teaminfo.offline img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.three-item > .flex:first-child {
  text-align: left;
}
.three-item > .flex:last-child {
  text-align: right;
}
.three-item,
.two-item {
  text-align: center;
}
.three-item > .flex {
  display: inline-block;
}
.three-item .teaminfo,
.two-item .teaminfo {
  max-height: 53px;
  max-width: 53px;
}
.three-item .teaminfo img,
.two-item .teaminfo img {
  width: 100%;
}
.team-title {
  margin-bottom: 15px;
  color: #808080;
  font-size: 16px;
  font-weight: 600;
}

.two-item > div:first-child .teaminfo {
  margin-left: 34%;
}
.two-item > div:last-child .teaminfo {
  margin-right: 25%;
}

.call-badge-content .call-badge {
  position: absolute;
  background-color: #4caf50;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  top: -7px;
  right: -6px;
  text-align: center;
  padding: 2px 0px;
}

.call-badge-content .call-badge i {
  color: #fff;
  font-size: 14px;
}

.teambadge {
  background-color: #4caf50;
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  width: 30px;
  display: inline-block;
  padding: 5px 0;
  position: absolute;
  top: 11px;
  right: 12px;
}

.team-divider {
  max-width: 300px;
  margin: auto;
  height: 100%;
}

.team-divider >>> .v-card {
  height: 100% !important;
}
.team-divider >>> .v-card__title {
  min-height: 52px;
}
.team-divider >>> .vb-content {
  max-height: 280px !important;
  height: 280px !important;
  overflow-y: auto !important;
}
</style>
