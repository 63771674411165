<template>
  <v-card v-if="material">
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Material</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="Close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text class="pa-3 scroll-content-dialog">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            id="materialNameField"
            v-model="material.name"
            v-validate="'required'"
            label="Material Name"
            required
            class="required"
            data-vv-scope="frmMaterial"
            data-vv-name="Material Name"
            :error-messages="errors.collect('Material Name')"
            name="materialName"
          />
        </v-flex>
        <v-flex xs12>
          <v-checkbox
            v-model="material.isAdditionalInfoRequired"
            :value="material.isAdditionalInfoRequired"
            label="Additional Information Required"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-flex>
        <v-flex xs12 mt-1>
          <v-checkbox
            v-model="material.isExcludedFromClientInvoicing"
            :value="material.isExcludedFromClientInvoicing"
            label="Exclude from Client Invoice"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-flex>
        <v-flex xs12 mt-1>
          <v-checkbox
            v-model="material.isCostExcludedFromContractorFixedFee"
            :value="material.isCostExcludedFromContractorFixedFee"
            label="Cost Excluded from Contractor Fixed Fee"
            color="primary"
            hide-details
            name="isCostExcludedFromContractorFixedFee"
          ></v-checkbox>
        </v-flex>
        <v-flex xs12 mt-1>
          <v-checkbox
            v-model="material.excludeFromCustomerApprovalUpdates"
            :value="material.excludeFromCustomerApprovalUpdates"
            label="Exclude from Updates to Customer after Approval"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn class="btn-close" @click.native="Close">Close</v-btn>
      <v-btn :disabled="isLoading" :loading="isLoading" color="primary" class="mr-0 submit-btn" @click.native="Save">
        submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import MaterialModel from '@/models/material/MaterialModel'
import MaterialController from '@/api/materialController'
import eventBus from '@/common/bus'

@Component
export default class Material extends Vue {
  @Prop() private materialItem: MaterialModel | null
  @Prop() private showMaterialDialog: boolean
  private isLoading = false

  @Watch('showMaterialDialog')
  private showMaterialDialogChange() {
    if (this.showMaterialDialog) {
      const materialNameField: any = this.$el.querySelector('#materialNameField')
      materialNameField.focus()
    }
    this.$validator.errors.items = []
  }

  private get material(): MaterialModel | null {
    return Object.assign({}, this.materialItem)
  }

  private Close() {
    this.$validator.errors.items = []
    this.$emit('OnMaterialDialogClose')
  }

  private Save() {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          MaterialController.SaveMaterial(this.material)
            .then((res: MaterialModel | null) => {
              if (res !== null) {
                let materialDetail: MaterialModel = new MaterialModel()
                materialDetail = Object.assign({}, res)
                this.$emit('OnMaterialSave', materialDetail)
              }
              this.isLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error saving material detail, please try again', true)
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validate(): Promise<boolean> {
    let result = true
    const materialFormValidation: boolean = await this.$validator.validateAll('frmMaterial')

    // set focus to non validate field
    if (!materialFormValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = materialFormValidation
    }

    return result
  }
}
</script>
