<template>
  <div>
    <v-progress-circular
      v-show="!isLoaded"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <CallContractorDetailView
      v-if="
        ((getIsThirdPartyContractor && getAssignedThirdPartyContractor !== null) ||
          getAssignedContractor !== undefined) &&
        isLoaded
      "
      :bind-from-prop="true"
      :item="getContractorDetails"
      :emergency="emergency"
      :job-id="jobId"
      :job-latitude="job.latitude"
      :job-longitude="job.longitude"
      :assigned-contractors="getAssignedContractors"
      :is-third-party-contractor="getIsThirdPartyContractor"
      :engineer-visit-detail="getEngineerVisitDetail"
      :job-address="job.getAddress(', ')"
    ></CallContractorDetailView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment, { Moment, isMoment } from 'moment'
import storeGetters from '@/storeGetters'
import JobController from '@/api/jobController'
import CallContractorDetailView from '@/components/timeline/CallContractorDetailView.vue'
import { RecordType } from '@/common/enums'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import Emergency from '@/models/Emergency'
import ThirdPartyAppointedContractor from '@/models/claim/ThirdPartyAppointedContractor'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import { EngineerJobVisitStatus } from '@/common/enums'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'

@Component({
  components: { CallContractorDetailView },
})
export default class TradeAppointedPreview extends TimeLineItemBase {
  private isComponentLoaded = false
  private contractor: ContractorModel | null
  private assignedContractors: ContractorAppointedModel[] = []

  private get isLoaded(): boolean {
    return this.isComponentLoaded
  }

  private get getIsThirdPartyContractor() {
    return this.emergency !== undefined &&
      this.emergency !== null &&
      this.emergency.thirdPartyContractor !== '' &&
      this.emergency.thirdPartyContractor !== null
      ? true
      : false
  }

  private mounted() {
    if (this.getAssignedContractor) {
      this.getContractor(this.getAssignedContractor.contractorId)
    }
    if (this.getAssignedThirdPartyContractor) {
      this.getContractor(this.getAssignedThirdPartyContractor.contractorId)
    }
  }
  private get getAssignedContractors(): ContractorAppointedModel[] | null {
    if (this.getAssignedContractor) {
      this.assignedContractors = []
      this.assignedContractors.push(this.getAssignedContractor)
    }
    if (this.getAssignedThirdPartyContractor) {
      this.assignedContractors = []
      this.assignedContractors.push(this.getAssignedThirdPartyContractor)
    }
    return this.assignedContractors
  }

  private get getAssignedContractor(): ContractorAppointedModel {
    return storeGetters.getContractorAppointedDetailById(this.jobId, this.itemId)
  }

  private get getEngineerVisitDetail(): EngineerVisitDetail | undefined {
    let visitDetail: EngineerVisitDetail | undefined
    if (this.job && this.getAssignedContractor) {
      visitDetail = this.job.engineerVisitDetail.find(
        (e) =>
          e.contractorAppointedDetailId === this.getAssignedContractor.id &&
          e.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed]
      )
      if (visitDetail !== undefined) {
        return visitDetail
      }
    }
    return undefined
  }

  private get getContractorDetails() {
    return this.contractor
  }

  private get getAssignedThirdPartyContractor(): ThirdPartyAppointedContractor | null | any {
    return storeGetters.getThirdPartyAppointedContractor(this.jobId)
  }

  private getContractor(contractorId: string) {
    const self = this
    ContractorController.GetJobContractorDetails(contractorId)
      .then((res: ContractorModel | null) => {
        if (res) {
          self.contractor = res
          self.isComponentLoaded = true
        } else {
          self.contractor = new ContractorModel()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor job detail, please try again', true)
      })
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>
