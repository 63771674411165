<template>
  <div :id="tableName" class="dashboard">
    <div>
      <v-layout wrap :class="openJobView ? 'hide-dashboard' : 'show-dashboard'">
        <v-flex class="gridView">
          <v-layout wrap undeployed-jobcount>
            <v-flex xs12 request-info>
              <v-card-title class="px-0 pt-0">
                <h3 v-if="!isFromMainDashBoard" class="pt-4">Undeployed Emergencies</h3>
                <v-switch
                  v-model="includeAutoDeployingJobs"
                  color="primary"
                  small
                  label="Auto-Deploying Jobs"
                  :class="[!isFromMainDashBoard ? 'pl-4' : '', 'mt-4']"
                  hide-details
                ></v-switch>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchValueLocal"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  class="mr-4 fix-width50"
                ></v-text-field>
                <v-autocomplete
                  v-model="selectedTeamName"
                  label="Search team"
                  maxlength="50"
                  :items="teamList"
                  :loading="getteamRunning"
                  hide-details
                  class="fix-width50"
                ></v-autocomplete>
              </v-card-title>
            </v-flex>
            <v-flex xs12>
              <v-card class="elevation-0 grey lighten-3 white--text">
                <v-card-title class="px-2 pb-0 pt-2 grey--text grey--text text--darken-4">
                  Undeployed Trade Jobs
                </v-card-title>
                <v-card-text v-if="undeployedJobTrades.length > 0" class="pa-1">
                  <div>
                    <v-chip
                      label
                      class="grey grey--text text--darken-4"
                      :class="currentIndex === -1 ? 'lighten-1' : 'lighten-2'"
                      @click="onFilterByAllTrades"
                    >
                      All &nbsp;
                      <b>
                        {{ undeployedJobTrades.reduce((a, b) => a + b.jobCount, 0) }}
                      </b>
                    </v-chip>
                    <span v-for="(undeployedJobTrade, i) in undeployedJobTrades" :key="i">
                      <v-chip
                        label
                        class="grey grey--text text--darken-2"
                        :class="currentIndex === i ? 'lighten-1' : 'lighten-2'"
                        @click="onFilterByTrade(undeployedJobTrade.trade, i)"
                      >
                        {{ undeployedJobTrade.trade }} &nbsp;
                        <b>{{ undeployedJobTrade.jobCount }}</b>
                      </v-chip>
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 mt-4 class="elevation-1">
              <div class="table-responsive">
                <v-data-table
                  :headers="headers"
                  :items="jobFilteredItems"
                  :loading="isLoading"
                  class="custom-data-table"
                  :pagination.sync="paginationLocal"
                  :search="searchValueLocal"
                  :custom-filter="customFilter"
                  :custom-sort="customSort"
                >
                  <template #items="props">
                    <tr :active="props.selected" @click="props.selected = !props.selected"></tr>
                    <tr>
                      <td>
                        <a v-if="isFromMainDashBoard" class="primary--text" @click="redirectToJob(props.item.id)">
                          <b>{{ props.item.id }}</b>
                        </a>
                        <a v-else class="primary--text">
                          <b>{{ props.item.id }}</b>
                        </a>
                        <span class="pl-1 grey--text">
                          {{ props.item.indexCount }}
                        </span>
                      </td>
                      <td>{{ props.item.undeployedEmergencies[0].vulnerabilityLevel || '-' }}</td>
                      <td>{{ props.item.policyNumber }}</td>
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.loggedByTeamName }}</td>
                      <td>{{ props.item.postCode }}</td>
                      <td>{{ props.item.addressLine3 }}</td>
                      <td>
                        {{ props.item.undeployedEmergencies[0].tradesDescription }}
                      </td>
                      <td class="text-xs-center">
                        <span v-if="props.item.undeployedEmergencies[0].isAutoDeploymentRunning">
                          <span class="blink_text">Auto Deploying</span>
                          ({{ props.item.undeployedEmergencies[0].deploymentAttempt }})
                        </span>
                        <span v-else>
                          {{ props.item.undeployedEmergencies[0].deploymentAttempt }}
                        </span>
                      </td>
                      <td>
                        {{
                          props.item.undeployedEmergencies[0].loggedAt
                            ? getFormattedDateTime(props.item.undeployedEmergencies[0].loggedAt)
                            : getFormattedDateTime(props.item.createdAt)
                        }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.lastVisitByUserName }}
                      </td>
                      <td>
                        {{ props.item.lastVisitAt ? getFormattedDateTime(props.item.lastVisitAt) : null }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <div class="snakbars-alert">
      <v-snackbar v-model="showSnackbar" :bottom="true" :left="true" :timeout="snackbarTimeout" class="pa-0">
        <div v-if="deploymentStatusSnackbar.length > 0" class="job-notification pt-3">
          <div
            v-for="(item, i) in deploymentStatusSnackbar"
            :key="i"
            class="white--text grey darken-4"
            :class="item.items.length > 0 ? 'mb-3 pa-2' : 'pa-0'"
          >
            <div v-if="item.items.length > 0">
              <h3 class="secondary--text">
                <b>{{ getDeploymentStatus(item.type) }}</b>
              </h3>
              <div v-for="(jobHeader, j) in item.items" :key="j">
                <v-divider class="my-2 grey darken-3"></v-divider>
                <span>{{ jobHeader }}</span>
              </div>
            </div>
          </div>
        </div>
        <v-btn color="pink" flat @click="onSnackbarClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
    </div>
    <PartialJobView :job-id="selectedJobIdToExpand" @closeJobView="closeJobView"></PartialJobView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import JobHeader from '@/models/JobHeader'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import JobController from '@/api/jobController'
import UserController from '@/api/userController'
import UndeployedEmergency from '@/models/contractor/UndeployedEmergency'
import PartialJobView from '@/components/PartialJobView.vue'

export enum DeploymentType {
  NewEmergency,
  ContractorAppointed,
  CancelAppointedContractor,
  CancelJob,
  RemoveEmergency,
}

@Component({
  name: 'UndeployedEmergencyTable',
  components: { PartialJobView },
})
export default class UndeployedEmergencyTable extends Vue {
  @Prop() private items: JobHeader[]
  @Prop() public tableName: string
  @Prop() public isLoading: boolean
  @Prop({ default: false }) public isFromMainDashBoard: boolean
  public headers: any[]
  public searchValueLocal = ''
  public paginationLocal: any = {}
  public jobFilteredItems: JobHeader[] = []
  public undeployedJobTrades: any = []
  private jobHeaderData: JobHeader[] = []
  private jobItems: JobHeader[] = []
  public currentIndex = -1
  public showSnackbar = false
  public snackbarTimeout = 10000
  public teamList: string[] = []
  public getteamRunning = false
  public selectedTeamName = ''
  private tempJobHeader: JobHeader = new JobHeader()
  private allItems: JobHeader[] = []
  public deploymentStatusSnackbar: any = []
  private deploymentType = DeploymentType
  private signalRHub: SignalRHubConnection = new SignalRHubConnection('undeployedEmergency')
  // open job view
  public openJobView = false
  public selectedJobIdToExpand = ''

  public includeAutoDeployingJobs = false

  private async created() {
    this.headers = [
      { text: 'JobId', value: 'id', align: 'left' },
      {
        text: 'Vuln.',
        value: 'vulnerabilityLevel',
        align: 'left',
      },
      { text: 'Policy Number', value: 'policyNumber', align: 'left' },
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Team', value: 'loggedByTeamName', align: 'left' },
      {
        text: 'Postcode',
        value: 'postCode',
        align: 'left',
        class: 'severity-col',
      },
      { text: 'Address', value: 'addressLine3', align: 'left' },
      { text: 'Trade', value: '', align: 'left', sortable: false },
      {
        text: 'Deployment Attempts',
        value: '',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Logged At',
        value: 'createdAt',
        align: 'left',
        class: 'date-col',
      },
      {
        text: 'Last Opened By',
        value: 'lastVisitByUserName',
        align: 'center',
        class: 'date-col',
      },
      {
        text: 'Last Opened At',
        value: 'lastVisitAt',
        align: 'left',
        class: 'date-col',
      },
    ]

    this.paginationLocal.page = 1
    this.paginationLocal.rowsPerPage = Shared.rowsPerPageDefault
    Object.assign(this.jobHeaderData, this.items)
    this.getTeamList()

    this.signalRHub.addHandler(
      'updateUndeployedEmergency',
      async (jobId: string, isEmergencyRemoved: boolean, isLastVisitUpdated: boolean | undefined) => {
        if (jobId) {
          await JobController.GetJobHeader(jobId).then((data: JobHeader | null) => {
            const jobHeader = Object.assign(new JobHeader(), data)
            if (JSON.stringify(this.tempJobHeader) === JSON.stringify(jobHeader)) {
              return // prevent execution of code in case of multiple signalR call
            }
            if (jobHeader) {
              jobHeader.undeployedEmergencies =
                jobHeader.undeployedEmergencies === null ? [] : jobHeader.undeployedEmergencies
              const jobHeaderItem: JobHeader | undefined = this.jobHeaderData.find((e) => e.id === jobHeader.id)
              if (jobHeaderItem && isEmergencyRemoved) {
                // emergency is removed from job
                this.removeJobHeaderData(
                  jobId,
                  jobHeaderItem,
                  jobHeader,
                  this.deploymentType[this.deploymentType.RemoveEmergency]
                )
                return
              }
              if (jobHeaderItem && isLastVisitUpdated) {
                this.updateJobLastVisit(jobHeader)
                return
              }
              if (
                jobHeaderItem !== undefined &&
                jobHeaderItem.undeployedEmergencies.length === jobHeader.undeployedEmergencies.length
              ) {
                for (let index = 0; index < jobHeader.undeployedEmergencies.length; index++) {
                  if (
                    jobHeaderItem.undeployedEmergencies[index].emergencyId ===
                    jobHeader.undeployedEmergencies[index].emergencyId
                  ) {
                    if (
                      jobHeaderItem.undeployedEmergencies[index].deploymentAttempt !==
                      jobHeader.undeployedEmergencies[index].deploymentAttempt
                    ) {
                      // deploymentAttempt changed
                      jobHeaderItem.undeployedEmergencies[index].deploymentAttempt =
                        jobHeader.undeployedEmergencies[index].deploymentAttempt
                    }
                    jobHeaderItem.undeployedEmergencies[index].isAutoDeploymentRunning =
                      jobHeader.undeployedEmergencies[index].isAutoDeploymentRunning
                  }
                }
                const jobHeaderItemIndex = this.jobHeaderData.findIndex((e) => e.id === jobHeader.id)
                if (jobHeaderItemIndex !== -1) {
                  this.jobHeaderData[jobHeaderItemIndex].undeployedEmergencies = jobHeaderItem.undeployedEmergencies
                  this.jobHeaderData = [...this.jobHeaderData]
                }
                return
              } else {
                if (jobHeaderItem !== undefined) {
                  if (jobHeader.undeployedEmergencies.length > jobHeaderItem.undeployedEmergencies.length) {
                    // job is already logged and new emergncy is added.
                    this.updateExistingJobData(jobId, jobHeader, this.deploymentType[this.deploymentType.NewEmergency])
                  } else {
                    // job is already logged and contractor is appointed for that emergency, now emergency is deployed..
                    this.removeJobHeaderData(
                      jobId,
                      jobHeaderItem,
                      jobHeader,
                      this.deploymentType[this.deploymentType.ContractorAppointed]
                    )
                  }
                } else {
                  // new job is logged so push to data binded in grid.
                  this.addNewJobHeaderData(jobId, jobHeader, this.deploymentType[this.deploymentType.NewEmergency])
                }
              }
            }
            // manage temp varible to prevent execution of code multiple times
            this.tempJobHeader = Object.assign(new JobHeader(), jobHeader)
          })
        }
      }
    )

    this.signalRHub.addHandler('cancelUndeployedEmergency', async (jobId: string) => {
      if (jobId) {
        await JobController.GetJobHeader(jobId).then((data: JobHeader | null) => {
          const jobHeader = Object.assign(new JobHeader(), data)
          if (JSON.stringify(this.tempJobHeader) === JSON.stringify(jobHeader)) {
            return // prevent execution of code in case of multiple signalR call
          }
          if (jobHeader) {
            jobHeader.undeployedEmergencies =
              jobHeader.undeployedEmergencies === null ? [] : jobHeader.undeployedEmergencies
            const jobHeaderItem: JobHeader | undefined = this.jobHeaderData.find((e) => e.id === jobHeader.id)
            if (
              jobHeaderItem !== undefined &&
              jobHeaderItem.undeployedEmergencies.length === jobHeader.undeployedEmergencies.length
            ) {
              for (let index = 0; index < jobHeader.undeployedEmergencies.length; index++) {
                if (
                  jobHeaderItem.undeployedEmergencies[index].deploymentAttempt !==
                    jobHeader.undeployedEmergencies[index].deploymentAttempt &&
                  jobHeaderItem.undeployedEmergencies[index].emergencyId ===
                    jobHeader.undeployedEmergencies[index].emergencyId
                ) {
                  // deploymentAttempt changed
                  jobHeaderItem.undeployedEmergencies[index].deploymentAttempt =
                    jobHeader.undeployedEmergencies[index].deploymentAttempt
                }
              }
              const jobHeaderItemIndex = this.jobHeaderData.findIndex((e) => e.id === jobHeader.id)
              if (jobHeaderItemIndex !== -1) {
                this.jobHeaderData[jobHeaderItemIndex].undeployedEmergencies = jobHeaderItem.undeployedEmergencies
                this.jobHeaderData = [...this.jobHeaderData]
              }
              return
            } else {
              if (jobHeaderItem !== undefined) {
                if (jobHeader.undeployedEmergencies.length > jobHeaderItem.undeployedEmergencies.length) {
                  // appointed contractor cancelled for job, add emergency back to unDeployed
                  this.updateExistingJobData(
                    jobId,
                    jobHeader,
                    this.deploymentType[this.deploymentType.CancelAppointedContractor]
                  )
                } else {
                  // job is cancelled, remove all undeployed emergency
                  this.removeJobHeaderData(
                    jobId,
                    jobHeaderItem,
                    jobHeader,
                    this.deploymentType[this.deploymentType.CancelJob]
                  )
                }
              } else {
                // appointed contractor cancelled and no record for same job exist.
                this.addNewJobHeaderData(
                  jobId,
                  jobHeader,
                  this.deploymentType[this.deploymentType.CancelAppointedContractor]
                )
              }
            }
          }
          // manage temp varible to prevent execution of code multiple times
          this.tempJobHeader = Object.assign(new JobHeader(), jobHeader)
        })
      }
    })

    this.signalRHub.connect()
  }

  private setDeploymentStatusSnackbarData(deploymentType, jobHeaderData) {
    const index = this.deploymentStatusSnackbar.findIndex((e) => e.type === deploymentType)
    if (index !== -1) {
      this.deploymentStatusSnackbar[index].items = jobHeaderData
    } else {
      this.deploymentStatusSnackbar.push({
        type: deploymentType,
        items: jobHeaderData,
      })
    }
  }

  private addNewJobHeaderData(jobId: string, jobHeader: JobHeader, type: string) {
    if (jobHeader.undeployedEmergencies.length === 0) {
      return
    }
    const undeployedJobs: string[] = []
    undeployedJobs.push(
      jobId + ' - ' + jobHeader.name + ' (' + jobHeader.undeployedEmergencies[0].tradesDescription + ')'
    )
    this.setDeploymentStatusSnackbarData(type, undeployedJobs)
    this.showSnackbar = true
    setTimeout(() => {
      undeployedJobs.splice(
        undeployedJobs.indexOf(
          jobId + ' - ' + jobHeader.name + ' (' + jobHeader.undeployedEmergencies[0].tradesDescription + ')'
        ),
        1
      )
      this.setDeploymentStatusSnackbarData(type, undeployedJobs)
    }, this.snackbarTimeout)
    const jobHeaderIndex = this.jobHeaderData.findIndex((e) => e.id === jobHeader.id)
    if (jobHeaderIndex === -1) {
      this.jobHeaderData.push(jobHeader)
    }
  }

  private updateExistingJobData(jobId: string, jobHeader: JobHeader, type: string) {
    const itemIndex = this.jobHeaderData.findIndex((e) => e.id === jobHeader.id)
    if (itemIndex !== -1) {
      this.jobHeaderData[itemIndex].undeployedEmergencies = jobHeader.undeployedEmergencies
      this.jobHeaderData = [...this.jobHeaderData]
    }
    const undeployedJobs: string[] = []
    undeployedJobs.push(
      jobId +
        ' - ' +
        jobHeader.name +
        ' (' +
        jobHeader.undeployedEmergencies[jobHeader.undeployedEmergencies.length - 1].tradesDescription +
        ')'
    )
    this.setDeploymentStatusSnackbarData(type, undeployedJobs)
    this.showSnackbar = true
    setTimeout(() => {
      undeployedJobs.splice(
        undeployedJobs.indexOf(
          jobId +
            ' - ' +
            jobHeader.name +
            ' (' +
            jobHeader.undeployedEmergencies[jobHeader.undeployedEmergencies.length - 1].tradesDescription +
            ')'
        ),
        1
      )
      this.setDeploymentStatusSnackbarData(type, undeployedJobs)
    }, this.snackbarTimeout)
  }

  private updateJobLastVisit(jobHeader: JobHeader) {
    const itemIndex = this.jobHeaderData.findIndex((e) => e.id === jobHeader.id)
    if (itemIndex !== -1) {
      this.jobHeaderData[itemIndex].lastVisitByUserName = jobHeader.lastVisitByUserName
      this.jobHeaderData[itemIndex].lastVisitAt = jobHeader.lastVisitAt
      this.jobHeaderData = [...this.jobHeaderData]
    }
  }

  private removeJobHeaderData(jobId: string, jobHeaderItem: JobHeader, jobHeader: JobHeader, type: string) {
    let undeployedEmergencies: UndeployedEmergency[] = []
    if (jobHeader.undeployedEmergencies.length === 0) {
      // job is cancelled
      undeployedEmergencies = jobHeaderItem.undeployedEmergencies
    } else {
      // emergency deployed
      undeployedEmergencies = jobHeaderItem.undeployedEmergencies.filter((item) =>
        jobHeader.undeployedEmergencies.find((e) => e.emergencyId !== item.emergencyId)
      )
    }
    const jobHeaderItemIndex = this.jobHeaderData.findIndex((e) => e.id === jobHeader.id)
    if (jobHeaderItemIndex !== -1) {
      this.jobHeaderData[jobHeaderItemIndex].undeployedEmergencies = jobHeader.undeployedEmergencies
      this.jobHeaderData = [...this.jobHeaderData]
    }
    if (jobHeader.undeployedEmergencies.length === 0 && jobHeaderItemIndex !== -1) {
      this.jobHeaderData.splice(jobHeaderItemIndex, 1)
    }
    const deployedJobs: string[] = []
    undeployedEmergencies.forEach((emergency) => {
      deployedJobs.push(jobId + ' - ' + jobHeader.name + ' (' + emergency.tradesDescription + ')')
      this.setDeploymentStatusSnackbarData(type, deployedJobs)
    })
    this.showSnackbar = true
    setTimeout(() => {
      undeployedEmergencies.forEach((emergency) => {
        deployedJobs.splice(
          deployedJobs.indexOf(jobId + ' - ' + jobHeader.name + ' (' + emergency.tradesDescription + ')'),
          1
        )
        this.setDeploymentStatusSnackbarData(type, deployedJobs)
      })
    }, this.snackbarTimeout)
  }

  public getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  public redirectToJob(jobId: string) {
    if (!Shared.isUserHasJobRights(jobId)) {
      return
    }
    // open timeline with relative card open.
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  public customFilter(items, searchValue, filter) {
    if (this.tableName === 'undeployedEmergencyDashboard') {
      if (searchValue !== '') {
        searchValue = searchValue.toString().toLowerCase()
        return items.filter(
          (row) =>
            filter(row.id, searchValue) ||
            filter(row.policyNumber, searchValue) ||
            filter(row.name, searchValue) ||
            filter(row.postCode, searchValue) ||
            filter(row.undeployedEmergencies[0].tradesDescription, searchValue) ||
            filter(row.createdAt ? this.getFormattedDateTime(row.createdAt) : '', searchValue)
        )
      } else {
        return items
      }
    }
  }

  public customSort(items: JobHeader[], sortBy: string, isDescending: boolean) {
    const sortDirection = isDescending ? -1 : 1

    if (sortBy === 'vulnerabilityLevel') {
      const priorities = ['High', 'Medium', 'Low', 'None']

      return items.sort((a: JobHeader, b: JobHeader) => {
        const aVal = a.undeployedEmergencies[0].vulnerabilityLevel || 'None'
        const bVal = b.undeployedEmergencies[0].vulnerabilityLevel || 'None'

        return sortDirection * (priorities.indexOf(aVal) - priorities.indexOf(bVal))
      })
    }

    return items.sort((a: JobHeader, b: JobHeader) => {
      const aVal = a[sortBy] || ''
      const bVal = b[sortBy] || ''

      return isNaN(aVal) || isNaN(bVal)
        ? sortDirection * String(aVal).localeCompare(bVal)
        : sortDirection * (aVal - bVal)
    })
  }

  private getRowWiseEmergencyData(items: JobHeader[]) {
    const filteredData = items.filter((e) => e.undeployedEmergencies && e.undeployedEmergencies.length > 1)
    filteredData.forEach((jobHeaderItem: any) => {
      const index = items.findIndex((e) => e.id === jobHeaderItem.id)
      if (index !== -1) {
        items.splice(index, 1)
        let counter: number = jobHeaderItem.undeployedEmergencies.length
        jobHeaderItem.undeployedEmergencies.forEach((item) => {
          const jobHeaderItemObj = Object.assign({}, jobHeaderItem)
          jobHeaderItemObj.undeployedEmergencies = []
          jobHeaderItemObj.indexCount = '(' + counter-- + ' of ' + jobHeaderItem.undeployedEmergencies.length + ')'
          if (counter >= 0) {
            jobHeaderItemObj.undeployedEmergencies.push(item)
            items.splice(index, 0, jobHeaderItemObj)
          }
        })
      }
    })
  }

  private getUndeployedJobTrades(items: JobHeader[]) {
    this.undeployedJobTrades = []
    let jobTrades = items.map((obj) => obj.undeployedEmergencies && obj.undeployedEmergencies[0].tradesDescription)
    jobTrades = jobTrades.filter((v, i) => jobTrades.indexOf(v) === i)
    jobTrades.forEach((tradeDescription) => {
      if (tradeDescription !== null && tradeDescription !== undefined) {
        const obj: any = {}
        obj.trade = tradeDescription
        obj.jobCount = items.filter(
          (e) => e.undeployedEmergencies && e.undeployedEmergencies[0].tradesDescription === tradeDescription
        ).length
        this.undeployedJobTrades.push(obj)
      }
    })
    return this.undeployedJobTrades
  }

  public onFilterByAllTrades() {
    this.currentIndex = -1

    if (this.selectedTeamName === 'All') {
      this.jobFilteredItems = this.allItems
      return
    }

    this.setDefaultData(this.jobItems)

    if (this.selectedTeamName === 'Unknown') {
      this.jobFilteredItems = this.jobItems.filter((e) => !e.loggedByTeamName)
      return
    }

    this.jobFilteredItems = this.jobItems.filter((e) => e.loggedByTeamName === this.selectedTeamName)
  }

  public onFilterByTrade(tradeDescription = '', index = 0) {
    this.currentIndex = index

    if (this.selectedTeamName === 'All') {
      this.jobFilteredItems = this.allItems.filter(
        (e) => e.undeployedEmergencies && e.undeployedEmergencies[0].tradesDescription === tradeDescription
      )
      return
    }

    this.setDefaultData(this.jobItems)

    if (this.selectedTeamName === 'Unknown') {
      this.jobFilteredItems = this.jobItems.filter(
        (e) =>
          !e.loggedByTeamName &&
          e.undeployedEmergencies &&
          e.undeployedEmergencies[0].tradesDescription === tradeDescription
      )
      return
    }

    this.jobFilteredItems = this.jobItems.filter(
      (e) =>
        e.loggedByTeamName === this.selectedTeamName &&
        e.undeployedEmergencies &&
        e.undeployedEmergencies[0].tradesDescription === tradeDescription
    )
  }

  public onSnackbarClose() {
    this.showSnackbar = false
    this.deploymentStatusSnackbar = []
  }

  private getTeamList() {
    this.getteamRunning = true
    UserController.GetUserTeams().then((res: string[]) => {
      if (res.length > 0) {
        this.teamList = res
        const allTeamOption = 'All'
        const unKnownTeamOption = 'Unknown'
        this.teamList.unshift(unKnownTeamOption)
        this.teamList.unshift(allTeamOption)
        this.selectedTeamName = this.teamList[0]
      }
      this.getteamRunning = false
    })
  }

  @Watch('jobHeaderData')
  @Watch('selectedTeamName')
  @Watch('includeAutoDeployingJobs')
  private resetGridData() {
    let items = JSON.parse(JSON.stringify(this.jobHeaderData))
    // filter jobs by include auto-deploying jobs flag
    items = items.filter((j) =>
      this.includeAutoDeployingJobs
        ? j.undeployedEmergencies[0].isAutoDeploymentRunning
        : !j.undeployedEmergencies[0].isAutoDeploymentRunning
    )
    this.getRowWiseEmergencyData(items)
    this.allItems = JSON.parse(JSON.stringify(items))
    let currentTrade = ''
    currentTrade = this.currentIndex !== -1 ? this.undeployedJobTrades[this.currentIndex].trade : ''
    if (this.selectedTeamName === 'All') {
      if (this.currentIndex === -1) {
        this.jobItems = items
        this.jobFilteredItems = this.jobItems
      } else {
        this.jobItems = items
        const tradeDescription = this.undeployedJobTrades[this.currentIndex]
        this.jobFilteredItems = this.jobItems.filter(
          (e) => e.undeployedEmergencies && e.undeployedEmergencies[0].tradesDescription === tradeDescription.trade
        )
        this.setDefaultData(this.jobItems)
      }
    } else if (this.selectedTeamName === 'Unknown') {
      if (this.currentIndex === -1) {
        this.jobItems = items.filter((e) => !e.loggedByTeamName)
        this.jobFilteredItems = this.jobItems
      } else {
        this.jobItems = items.filter((e) => !e.loggedByTeamName)
        const tradeDescription = this.undeployedJobTrades[this.currentIndex]
        this.jobFilteredItems = this.jobItems.filter(
          (e) => e.undeployedEmergencies && e.undeployedEmergencies[0].tradesDescription === tradeDescription.trade
        )
        this.setDefaultData(this.jobItems)
      }
    } else {
      if (this.currentIndex === -1) {
        this.jobItems = items.filter((e) => e.loggedByTeamName === this.selectedTeamName)
        this.jobFilteredItems = this.jobItems
      } else {
        this.jobItems = items.filter((e) => e.loggedByTeamName === this.selectedTeamName)
        const tradeDescription = this.undeployedJobTrades[this.currentIndex]
        this.jobFilteredItems = this.jobItems.filter(
          (e) => e.undeployedEmergencies && e.undeployedEmergencies[0].tradesDescription === tradeDescription.trade
        )
        this.setDefaultData(this.jobItems)
      }
    }
    this.getUndeployedJobTrades(this.jobItems)
    if (this.currentIndex !== -1) {
      const index = this.undeployedJobTrades.findIndex((t) => t.trade === currentTrade)
      this.currentIndex = index
    }
  }

  private setDefaultData(items: JobHeader[]) {
    if (this.jobFilteredItems.length === 0) {
      this.jobFilteredItems = items
    } else {
      return
    }
  }

  public getDeploymentStatus(type: string) {
    if (type === this.deploymentType[this.deploymentType.NewEmergency]) {
      return 'New emergency added for job: '
    } else if (type === this.deploymentType[this.deploymentType.ContractorAppointed]) {
      return 'Contractor assigned for job: '
    } else if (type === this.deploymentType[this.deploymentType.CancelAppointedContractor]) {
      return 'Appointed contractor cancelled for job: '
    } else if (type === this.deploymentType[this.deploymentType.CancelJob]) {
      return 'Cancelled job: '
    } else if (type === this.deploymentType[this.deploymentType.RemoveEmergency]) {
      return 'Emergency removed for job: '
    }
    return ''
  }

  private destroyed() {
    this.signalRHub.disconnect()
    Shared.passJobIdInHeader()
  }

  public closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.gridView >>> table > thead > tr > th:nth-child(2) {
  width: 140px;
}
.gridView >>> table > thead > tr > th:nth-child(1) {
  min-width: 130px;
  width: 130px;
}
.gridView >>> table > thead > tr > th:nth-child(3) {
  min-width: 200px;
}
.gridView >>> table > thead > tr > th:nth-child(5) {
  width: 200px;
}
.gridView {
  border: 0px;
}
.custom-data-table {
  background-color: #000;
}
.custom-data-table >>> thead > tr > th:last-child {
  min-width: 150px;
}
.custom-data-table >>> thead > tr > th:nth-last-child(2) {
  min-width: 300px;
}
.job-notification {
  width: 100%;
}
.job-notification h3 {
  font-weight: normal;
  padding-right: 30px;
}
.job-notification > span {
  font-size: 18px;
  display: inline-block;
  margin-top: 5px;
}
.undeployed-jobcount >>> .v-chip,
.undeployed-jobcount >>> .v-chip .v-chip__content {
  cursor: pointer;
}
.snakbars-alert >>> .v-snack__content {
  height: auto !important;
  position: relative;
  padding: 15px;
  padding-top: 20px;
}
.snakbars-alert >>> .v-btn {
  position: absolute;
  top: 0px;
  right: 5px;
}
.snakbars-alert >>> .v-snack__wrapper {
  min-width: 400px;
}
.undeployed-jobcount .v-card {
  min-height: 75px;
}
.fix-width50 {
  width: 50px;
}
.hide-dashboard {
  visibility: hidden;
}
.show-dashboard {
  visibility: visible;
}
.blink_text {
  animation-name: blinker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes blinker {
  0% {
    opacity: 1;
    color: orange;
    font-weight: bold;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
    color: orange;
    font-weight: bold;
  }
}
</style>
