<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Set ETA</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text class="px-4 pt-4 eta-detail-dialog-content">
      <v-layout class="scoll-vb-content">
        <v-progress-circular
          v-if="isLoading"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
          :style="getLoaderStyle(50)"
        ></v-progress-circular>
        <v-layout v-else xs12 wrap>
          <v-flex v-if="isMaterialPriceNotIncluded" xs12 mb-2>
            <div class="common-error-message">
              <div class="red pa-2 d-block lighten-4">
                <span class="error-materialPrice">
                  <b>Cannot set ETA, follow-on visit includes a parts request with no price detail.</b>
                </span>
              </div>
            </div>
          </v-flex>
          <v-flex xs6 pr-2>
            <DateTimePicker
              ref="ETAfromdateTimePicker"
              :date-time.sync="contractorAppointed.etaFrom"
              :is-static-location="false"
              place-holder-text="ETA From"
              :min-date="minFromDate"
              :min-time="minFromTime"
              :max-date="maxFromDate"
              :max-time="maxFromTime"
              :is-validation-required="true"
              :allowed-minutes-step="true"
            />
          </v-flex>
          <v-flex xs6 pl-2>
            <DateTimePicker
              ref="ETATodateTimePicker"
              :date-time.sync="contractorAppointed.etaTo"
              :is-static-location="false"
              place-holder-text="ETA To"
              :min-date="minToDate"
              :min-time="minToTime"
              :max-date="maxToDate"
              :max-time="maxToTime"
              :is-validation-required="true"
              :allowed-minutes-step="true"
            />
          </v-flex>
          <v-flex v-if="toDateIsAfterFromDateError || datesHavePassedError" xs12 mb-2>
            <span v-if="toDateIsAfterFromDateError" class="error--text">To date must be greater than From date.</span>
            <span v-if="datesHavePassedError" class="error--text">ETA dates have already passed.</span>
          </v-flex>
          <v-flex xs6 class="pr-1">
            <v-select
              v-model="travelTimeHour"
              :items="travelTimeHourList"
              label="Travel Time (Hours)"
              item-text="text"
              item-value="value"
              class="travelTimeHour"
            ></v-select>
          </v-flex>
          <v-flex xs6 class="pl-1">
            <v-select
              v-model="travelTimeMinute"
              :items="travelTimeMinuteList"
              label="Travel Time (Minutes)"
              item-text="text"
              item-value="value"
              class="travelTimeMinute"
            ></v-select>
          </v-flex>
          <v-flex xs12 grey lighten-3>
            <DelayCodes
              :has-delay="contractorAppointed.hasDelay"
              :delay-code="contractorAppointed.delayCode"
              @updateDelayCode="onDelayCodeChange"
            />
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card-text>
    <v-card-actions class="px-4">
      <v-spacer></v-spacer>
      <v-btn color="primary" flat="flat" @click="closeDialog">Close</v-btn>
      <v-btn
        color="primary"
        class="mr-0"
        :disabled="isSubmitETALoading || !validate || isMaterialPriceNotIncluded"
        :loading="isSubmitETALoading"
        @click="submit"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DateTimePicker from './DateTimePicker.vue'
import ContractorAppointedModel from '../models/claim/ContractorAppointedModel'
import JobController from '@/api/jobController'
import AddContractorAppointedModel from '../models/requests/AddContractorAppointedModel'
import moment from 'moment'
import DelayCodes from './timeline/DelayCodes.vue'
import { TimeSpanSlots } from '@/common/interfaces'
import Shared from '../common/shared'
import ContractorController from '@/api/contractorController'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import EngineerRequestBase from '@/models/claim/engineerRequest/EngineerRequestBase'
import { EngineerRequestType } from '@/common/enums'
import eventBus from '@/common/bus'

@Component({
  components: {
    DateTimePicker,
    DelayCodes,
  },
})
export default class ETADialogContent extends Vue {
  @Prop() public contractorAppointedDetail: ContractorAppointedModel | null
  private isSubmitETALoading = false
  private travelTimeHourList: TimeSpanSlots[] = Shared.getTimeSpanSlotsList(9, 1)
  private travelTimeMinuteList: TimeSpanSlots[] = Shared.getTimeSpanSlotsList(4, 15)
  private travelTimeHour: number = this.travelTimeHourList[0].value
  private travelTimeMinute: number = this.travelTimeMinuteList[0].value
  private contractorAppointed: ContractorAppointedModel | null = new ContractorAppointedModel()
  private isLoading = false
  private isMaterialPriceNotIncluded = false

  private created() {
    if (this.contractorAppointedDetail) {
      this.checkAnyMaterialRequestAwaitingPrice()
    }
  }

  private mounted() {
    this.setContractorAppointedDetail()
  }

  @Watch('contractorAppointedDetail')
  private onContractorAppointedDetailChange() {
    this.setContractorAppointedDetail()
  }

  private setContractorAppointedDetail() {
    if (this.contractorAppointedDetail) {
      this.contractorAppointed = Object.assign(new ContractorAppointedModel(), this.contractorAppointedDetail)
      this.travelTimeHour = this.travelTimeHourList[0].value
      this.travelTimeMinute = this.travelTimeMinuteList[0].value
    }
  }

  private get toDateIsAfterFromDateError(): boolean {
    if (!this.contractorAppointed || !this.contractorAppointed.etaFrom || !this.contractorAppointed.etaTo) {
      return false
    }
    return this.contractorAppointed.etaFrom.isAfter(this.contractorAppointed.etaTo)
  }

  private get datesHavePassedError(): boolean {
    if (!this.contractorAppointed) {
      return false
    }
    let before = false
    if (this.contractorAppointed.etaFrom) {
      before = this.contractorAppointed.etaFrom.isBefore(moment(), 'hour')
    }
    if (this.contractorAppointed.etaTo) {
      before = before || this.contractorAppointed.etaTo.isBefore(moment(), 'hour')
    }
    return before
  }

  private allowedMinutes(value: number) {
    return value % 15 === 0
  }

  // eta from getters
  private get minFromDate(): string {
    return moment().format(DateTimePicker.DATE_FORMAT)
  }

  private get minFromTime(): string {
    if (!this.contractorAppointed || !this.contractorAppointed.etaFrom) {
      return ''
    }
    return this.contractorAppointed.etaFrom.isSame(moment(), 'day') ? moment().format(DateTimePicker.TIME_FORMAT) : ''
  }

  private get maxFromDate(): string {
    return moment().add('2', 'weeks').format(DateTimePicker.DATE_FORMAT)
  }

  private get maxFromTime(): string {
    return ''
  }

  // eta to getters
  private get minToDate(): string {
    if (!this.contractorAppointed || !this.contractorAppointed.etaFrom) {
      return this.minFromDate
    }
    return this.contractorAppointed.etaFrom.format(DateTimePicker.DATE_FORMAT)
  }

  private get minToTime(): string {
    if (!this.contractorAppointed || !this.contractorAppointed.etaTo) {
      return ''
    }
    if (!this.contractorAppointed.etaFrom) {
      return this.contractorAppointed.etaTo.isSame(moment(), 'day') ? moment().format(DateTimePicker.TIME_FORMAT) : ''
    } else {
      return this.contractorAppointed.etaTo.isSame(this.contractorAppointed.etaFrom, 'day')
        ? this.contractorAppointed.etaFrom.format(DateTimePicker.TIME_FORMAT)
        : ''
    }
  }

  private get maxToDate(): string {
    return this.maxFromDate
  }

  private get maxToTime(): string {
    return this.maxFromTime
  }

  private get validate(): boolean {
    return this.contractorAppointed !== null &&
      this.contractorAppointed.etaFrom &&
      this.contractorAppointed.etaTo &&
      this.contractorAppointed.etaFrom.isValid() &&
      this.contractorAppointed.etaTo.isValid() &&
      !this.toDateIsAfterFromDateError &&
      !this.datesHavePassedError
      ? true
      : false
  }

  private async submit() {
    if (!this.contractorAppointed || !this.validate || this.isMaterialPriceNotIncluded) {
      return
    }
    this.isSubmitETALoading = true
    const appointed = Object.assign(new AddContractorAppointedModel(), {
      id: this.contractorAppointed.id,
      jobId: this.contractorAppointed.jobId,
      etaFrom: this.contractorAppointed.etaFrom,
      etaTo: this.contractorAppointed.etaTo,
      hasDelay: this.contractorAppointed.hasDelay,
      delayCode: this.contractorAppointed.delayCode,
      additionalCharge: this.contractorAppointed.additionalCharge,
      emergencyId: this.contractorAppointed.emergencyId,
      engineerId: null,
      contractorId: this.contractorAppointed.contractorId,
      additionalTravelTime:
        this.travelTimeHour !== 0 || this.travelTimeMinute !== 0
          ? this.getFormattedTravelTime(this.travelTimeHour + ':' + this.travelTimeMinute)
          : '',
    })
    const res = await JobController.AddContractorAppointedDetail(appointed)
    if (res) {
      await JobController.GetJobDocument(this.contractorAppointed.jobId, this.contractorAppointed.id)
      this.$emit('close')
    }
    this.isSubmitETALoading = false
  }

  private closeDialog() {
    this.$emit('close')
  }

  private onDelayCodeChange(hasDelay: boolean, delayCode: string | null) {
    if (!this.contractorAppointed) {
      return
    }
    this.contractorAppointed.hasDelay = hasDelay
    this.contractorAppointed.delayCode = delayCode
  }

  @Watch('contractorAppointed.etaFrom')
  private onETAFromChange() {
    if (!this.contractorAppointed || !this.contractorAppointed.etaFrom) {
      return
    }
    if (
      !this.contractorAppointed.etaTo ||
      !this.contractorAppointed.etaTo.isValid() ||
      this.contractorAppointed.etaTo.isBefore(this.contractorAppointed.etaFrom)
    ) {
      this.contractorAppointed.etaTo = this.contractorAppointed.etaFrom
    }
  }

  private getFormattedTravelTime(travelTime: string) {
    return Shared.formatTimeSpanToString(travelTime)
  }

  private checkAnyMaterialRequestAwaitingPrice() {
    this.isLoading = true
    ContractorController.CheckAnyMaterialRequestAwaitingPrice(
      this.contractorAppointedDetail!.jobId,
      this.contractorAppointedDetail!.previousEngineerVisitId
    )
      .then((res: boolean) => {
        this.isMaterialPriceNotIncluded = res
        this.isLoading = false
      })
      .catch((err: any) => {
        this.isLoading = false
        eventBus.$emit('errorHandler', 'Error checking if material request awaiting price, please try again!', true)
        this.$emit('close')
      })
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }
}
</script>

<style scoped>
.eta-detail-dialog-content >>> .scoll-vb-content {
  min-height: 300px;
}
.common-error-message {
  color: #ff5252 !important;
}
</style>
