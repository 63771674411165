import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { ICookieState } from '@/vuex/states/interfaces/cookie-state.d'
import CookieState from '../states/cookie-state'
import CookieRecord from '@/models/cookies/cookie-record'
import CookieSettings from '@/models/cookies/cookie-settings'
import ScriptTagSettings from '@/models/cookies/script-tag-settings'
import KeyValuePair from '@/models/key-value-pair'
import InsurerController from '@/api/InsurerController'

export const cookieState: ICookieState = new CookieState()

export const cookieGetters: GetterTree<ICookieState, any> = {
  cookieRecord: (state) => state.selectedCookieRecord,
  cookieRecords: (state) => state.cookieRecords,
  cookiePlaceholders: (state) => {
    const regex = RegExp('{{([^}}]+)}}', 'g')
    const placeholders: string[] = []

    // Not able to have - while(exists = regex.exec(value)) due to linting rules
    const updatePlaceholders = (value: string) => {
      if (value && value.length > 0) {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const exists = regex.exec(value)
          if (!exists) {
            break
          }
          placeholders.push(exists[1])
        }
      }
    }

    state.selectedCookieRecord.scripts.forEach((x: ScriptTagSettings) => {
      updatePlaceholders(x.source)
      updatePlaceholders(x.body)

      if (x.attributes) {
        x.attributes.forEach((att) => updatePlaceholders(att.key))
      }
    })

    return [...new Set(placeholders)]
  },
}

export const mutations: MutationTree<ICookieState> = {
  setCookieRecord: (state, cookieRecord: CookieRecord) => {
    state.selectedCookieRecord = JSON.parse(JSON.stringify(cookieRecord))
  },
  setNewCookieSetting: (state, cookieSettings: CookieSettings = new CookieSettings()) => {
    state.selectedCookieRecord.settings.push(cookieSettings)
  },
  setRemoveCookieSetting: (state, index: number) => {
    state.selectedCookieRecord.settings.splice(index, 1)
  },
  setNewCookieScript: (state) => {
    state.selectedCookieRecord.scripts.push(new ScriptTagSettings())
  },
  setRemoveCookieScript: (state, index: number) => {
    state.selectedCookieRecord.scripts.splice(index, 1)
  },
  // Needs Fixed - Need to set the default value as an empty array in cosmos.
  // Will require changes in JRP for length check rather than null check.
  setNewCookieAttribute: (state, scriptIndex: number) => {
    const attributes = state.selectedCookieRecord.scripts[scriptIndex].attributes
    if (!attributes) {
      state.selectedCookieRecord.scripts[scriptIndex].attributes = []
    }
    state.selectedCookieRecord.scripts[scriptIndex].attributes.push(new KeyValuePair<string, string>('', ''))
  },
  setRemoveCookieAttribute: (state, indexes: { scriptIndex: number; attributeIndex: number }) => {
    state.selectedCookieRecord.scripts[indexes.scriptIndex].attributes.splice(indexes.attributeIndex, 1)
  },
  setUpdateCookieScripts: (state, script: { value: any; index: number; fieldName: string }) => {
    state.selectedCookieRecord.scripts[script.index][script.fieldName] = script.value
  },
  setScriptAttributes: (state, att: { value: any; scriptIndex: number; index: number; fieldName: string }) => {
    state.selectedCookieRecord.scripts[att.scriptIndex].attributes[att.index][att.fieldName] = att.value
  },
  setCookieName: (state, cookieName: string) => {
    state.selectedCookieRecord.name = cookieName
  },
  setCookieRecords: (state, cookieRecords: CookieRecord[]) => {
    state.cookieRecords = cookieRecords
  },
  setCookieDescription: (state, cookieDescription: string) => {
    state.selectedCookieRecord.description = cookieDescription
  },
  setUpdateCookieSettings: (state, setting: { value: any; index: number; fieldName: string }) => {
    state.selectedCookieRecord.settings[setting.index][setting.fieldName] = setting.value
  },
  setRemoveCookieRecord: (state, index: number) => {
    state.cookieRecords.splice(index, 1)
  },
}

export const actions: ActionTree<ICookieState, any> = {
  submitCookieRecord({ commit }, cookieRecord: CookieRecord) {
    commit('setCookieRecord', cookieRecord)
  },
  submitNewCookieSetting({ commit }) {
    commit('setNewCookieSetting')
  },
  submitRemoveCookieSetting({ commit }, index: number) {
    commit('setRemoveCookieSetting', index)
  },
  submitNewCookieScript({ commit }) {
    commit('setNewCookieScript')
  },
  submitRemoveCookieScript({ commit }, index: number) {
    commit('setRemoveCookieScript', index)
  },
  submitNewCookieAttribute({ commit }, scriptIndex: number) {
    commit('setNewCookieAttribute', scriptIndex)
  },
  submitRemoveCookieAttribute({ commit }, indexes: { scriptIndex: number; attributeIndex: number }) {
    commit('setRemoveCookieAttribute', indexes)
  },
  submitUpdateCookieScripts({ commit }, script: { value: any; index: number; fieldName: string }) {
    commit('setUpdateCookieScripts', script)
  },
  submitUpdateScriptAttributes(
    { commit },
    attribute: {
      value: any
      scriptIndex: number
      index: number
      fieldName: string
    }
  ) {
    commit('setScriptAttributes', attribute)
  },
  submitCookieName({ commit }, cookieName: string) {
    commit('setCookieName', cookieName)
  },
  submitCookieDescription({ commit }, cookieDescription: string) {
    commit('setCookieDescription', cookieDescription)
  },
  submitUpdateCookieRecordSettings({ commit }, setting: { value: any; index: number; fieldName: string }) {
    commit('setUpdateCookieSettings', setting)
  },
  submitNewPlaceholderSettings({ commit, state, getters }) {
    getters.cookiePlaceholders.forEach((ph) => {
      if (!state.selectedCookieRecord.settings.filter((s) => s.id === ph).length) {
        const newCookieSetting = new CookieSettings()
        newCookieSetting.id = ph
        commit('setNewCookieSetting', newCookieSetting)
      }
    })
  },
  async submitRetrieveCookieRecords({ commit }) {
    InsurerController.RetrieveCookieRecords().then((cookieRecords: CookieRecord[]) => {
      commit('setCookieRecords', cookieRecords)
    })
  },
  async submitSaveCookieRecord({ state, commit }) {
    return new Promise((resolve, reject) => {
      InsurerController.UpsertCookieRecord(state.selectedCookieRecord).then((cookieRecord: CookieRecord) => {
        if (cookieRecord.id.length > 0) {
          const cookieRecords: CookieRecord[] = JSON.parse(JSON.stringify(state.cookieRecords))
          const index = cookieRecords.findIndex((x) => x.id === cookieRecord.id)

          index > -1 ? cookieRecords.splice(index, 1, cookieRecord) : cookieRecords.push(cookieRecord)
          commit('setCookieRecords', cookieRecords)
          resolve(true)
        }
        reject()
      })
    })
  },
}

export const cookieModule: Module<ICookieState, any> = {
  state: cookieState,
  actions,
  mutations,
  getters: cookieGetters,
  namespaced: true,
}
