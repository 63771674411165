export default class CCTVControlDistance {
  public distance: number | null
  public code: string
  public clockRefAt: number
  public clockRefTo: number
  public intrusionInPercentage: number
  public intrusionInMM: number
  public remarks: string
  public defectSnapshotURL: string
  public id: number

  public constructor() {
    this.distance = null
  }
}
