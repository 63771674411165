<template>
  <div>
    <div class="mb-2"><h4>Update job FNOL call recording URL</h4></div>
    <div v-if="item.fnolCallRecordingUrl" class="mb-1">
      <label>FNOL Call Recording Url: </label>
      <span>{{ item.fnolCallRecordingUrl }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateJobFNOLCallRecordingUrl from '@/models/requests/UpdateJobFNOLCallRecordingUrl'

@Component
export default class UpdateJobFNOLCallRecordingUrlLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateJobFNOLCallRecordingUrl {
    return Object.assign(new UpdateJobFNOLCallRecordingUrl(), this.requestBody)
  }
}
</script>
