<template>
  <v-autocomplete
    v-model="selectedClientId"
    v-validate="'required'"
    label="Search client"
    required
    class="required client-list"
    :items="clients"
    :loading="loading"
    item-text="name"
    item-value="id"
    :disabled="loading"
  >
    <template slot="item" slot-scope="data">
      <v-list-tile-content>
        <v-list-tile-title :class="{ 'deleted-item': data.item.isDelete }">
          {{ data.item.name }}
        </v-list-tile-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import InsurerModel from '@/models/policy/InsurerModel'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ClientList extends Vue {
  get loading(): boolean {
    return this.$store.getters['clientModule/loading']
  }

  get clients(): InsurerModel[] {
    return this.$store.getters['clientModule/clients']
  }

  get selectedClientId(): number | null {
    return this.$store.getters['clientModule/selectedClientId']
  }

  set selectedClientId(clientId: number | null) {
    this.$store.dispatch('clientModule/submitSetSelectedClient', clientId)
  }

  created(): void {
    this.$store.dispatch('clientModule/submitRetrieveClients')
  }
}
</script>
