<template>
  <v-layout wrap>
    <v-card class="pt-0 px-2 pb-2">
      <v-card-title>
        <v-layout>
          <v-flex xs12>
            <h3 class="heading">
              <span class="teal--text">Other Fees Detail</span>
            </h3>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout wrap class="other-fee-detail">
          <v-flex xs12>
            <v-text-field
              v-model.number="otheFeesDetail.berAmount"
              label="BER Amount"
              prepend-icon="close"
              class="prepend-icon-image"
              name="BERAmount"
              maxlength="10"
              @keypress="numberKeyValidation($event)"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model.number="otheFeesDetail.alternativeAccommodationAmount"
              label="Alternative Accommodation Amount"
              prepend-icon="close"
              class="prepend-icon-image"
              name="AlternativeAccommodationAmount"
              maxlength="10"
              @keypress="numberKeyValidation($event)"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model.number="otheFeesDetail.temporaryHeatersAmount"
              label="Temporary Heaters Amount"
              prepend-icon="close"
              class="prepend-icon-image"
              name="TemporaryHeatersAmount"
              maxlength="10"
              @keypress="numberKeyValidation($event)"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model.trim="otheFeesDetail.notes"
              v-validate="'required'"
              label="Notes"
              rows="5"
              name="Notes"
              required
              class="required"
              data-vv-scope="otherFeesScope"
              data-vv-name="Notes"
              :error-messages="errors.collect('Notes')"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <div class="text-xs-right">
              <v-btn
                v-if="!isJobCompletedOrCancelled"
                color="primary"
                class="mr-0 save-otherFeeDetail"
                :disabled="isLoading"
                :loading="isLoading"
                @click="saveOtherFeesDetail"
              >
                Submit
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import OtherJobFeeDetailModel from '@/models/claim/OtherJobFeeDetailModel'
import Shared from '@/common/shared'
import SaveOtherFeesDetailModel from '@/models/requests/SaveOtherFeesDetailModel'
import JobController from '@/api/jobController'
import moment from 'moment'
import eventBus from '@/common/bus'

@Component
export default class OtherFeesDetailPreview extends TimeLineItemBase {
  private otheFeesDetail: OtherJobFeeDetailModel = new OtherJobFeeDetailModel()
  private isLoading = false
  private saveSnackbarTimeout: number = Shared.snackbarTimeout
  private saveSnackbar = false
  private saveSnackbarText = ''

  private get getOtherFeesDetail(): OtherJobFeeDetailModel | null {
    if (this.job) {
      const inviceDetail =
        this.job.clientInvoiceDetails && this.job.clientInvoiceDetails.length > 0
          ? this.job.clientInvoiceDetails.find((c) => c.id === this.itemId)
          : undefined
      return inviceDetail ? inviceDetail.otherJobFeeDetail : new OtherJobFeeDetailModel()
    }
    return null
  }

  private created() {
    this.otheFeesDetail = this.getOtherFeesDetail
      ? Object.assign(new OtherJobFeeDetailModel(), this.getOtherFeesDetail)
      : new OtherJobFeeDetailModel()
  }

  private saveOtherFeesDetail() {
    this.validate('otherFeesScope')
      .then((result: boolean) => {
        if (result) {
          const otherJobFeeDetail = Object.assign(new OtherJobFeeDetailModel(), this.otheFeesDetail)

          const saveOtherFeesDetailModel: SaveOtherFeesDetailModel = new SaveOtherFeesDetailModel()
          saveOtherFeesDetailModel.jobId = this.jobId
          saveOtherFeesDetailModel.berAmount = otherJobFeeDetail.berAmount ? otherJobFeeDetail.berAmount : 0
          saveOtherFeesDetailModel.alternativeAccommodationAmount = otherJobFeeDetail.alternativeAccommodationAmount
            ? otherJobFeeDetail.alternativeAccommodationAmount
            : 0
          saveOtherFeesDetailModel.temporaryHeatersAmount = otherJobFeeDetail.temporaryHeatersAmount
            ? otherJobFeeDetail.temporaryHeatersAmount
            : 0
          saveOtherFeesDetailModel.notes = otherJobFeeDetail.notes
          saveOtherFeesDetailModel.otherFeesDetailCreatedAt = otherJobFeeDetail.otherFeesDetailCreatedAt
            ? otherJobFeeDetail.otherFeesDetailCreatedAt
            : moment()

          this.isLoading = true
          JobController.SaveOtherFeesDetail(saveOtherFeesDetailModel)
            .then((res: boolean) => {
              this.isLoading = false
              this.showSnackBar(res ? 'Other Fees Detail Saved Successfully.' : 'Something went wrong.')
            })
            .catch((err: any) => {
              this.showSnackBar('Something went wrong.')
              this.isLoading = false
              eventBus.$emit('errorHandler', 'Error saving other fees detail, please try again', false)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validate(scope: string): Promise<boolean> {
    let result = true
    const validation: boolean = await this.$validator.validateAll(scope)

    if (!validation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = validation
    }
    return result
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private showSnackBar(text: string) {
    this.saveSnackbar = true
    this.saveSnackbarText = text
  }

  private get isJobCompletedOrCancelled() {
    return this.job &&
      this.job.status !== 'Completed' &&
      (this.job.status !== 'Cancelled' || !this.job.cancellationReason)
      ? false
      : true
  }
}
</script>

<style scoped>
.other-fee-detail .prepend-icon-image >>> i.v-icon {
  position: relative;
  color: #fff !important;
  height: 0px !important;
}
.other-fee-detail .prepend-icon-image >>> i.v-icon::after {
  content: '';
  background-image: url('/img/pound-sterling.svg');
  background-size: cover;
  position: absolute;
  left: 5px;
  top: -7px;
  height: 16px;
  width: 16px;
}
</style>
