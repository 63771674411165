<template>
  <div>
    <div class="mb-2"><h4>Overwrite Contractor Invoice Cost</h4></div>
    <div v-if="contractorCompanyName" class="mb-1">
      <label>Contractor: </label>
      <span>{{ contractorCompanyName }}</span>
    </div>
    <div v-if="item.overrideCost && item.invoiceCostType" class="mb-1">
      <label>Cost Updated For: </label>
      <span>{{ item.invoiceCostType }}</span>
    </div>
    <div v-if="contractorInvoiceDetail && contractorInvoiceDetail.invoiceDetail" class="mb-1">
      <label>Original Cost: </label>
      <span>
        {{
          getFormatedCurrency(
            contractorInvoiceDetail.invoiceDetail.materialCost + contractorInvoiceDetail.invoiceDetail.labourCost
          )
        }}
      </span>
    </div>
    <div v-if="item.overrideCost" class="mb-1">
      <label>Overwrite Cost: </label>
      <span>{{ getFormatedCurrency(item.overrideCost) }}</span>
    </div>
    <div v-if="item.overrideReason" class="mb-1">
      <label>Overwrite Reason: </label>
      <span>{{ item.overrideReason }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import OverrideInvoiceCostRequestModel from '@/models/requests/OverrideInvoiceCostRequestModel'
import ContractorInvoiceDetailModel from '@/models/claim/ContractorInvoiceDetailModel'
import Shared from '@/common/shared'
import Job from '@/models/Job'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class OverrideContractorInvoiceCostLog extends Vue {
  @Prop() private requestBody: any

  private get item(): OverrideInvoiceCostRequestModel {
    return Object.assign(new OverrideInvoiceCostRequestModel(), this.requestBody)
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get contractorInvoiceDetail(): ContractorInvoiceDetailModel | null {
    if (this.requestedJob && this.requestedJob.contractorInvoiceDetails.length > 0) {
      const invoiceDetail = this.requestedJob.contractorInvoiceDetails.find((e) => e.id === this.item.id)
      return invoiceDetail ? invoiceDetail : null
    }
    return null
  }

  private get contractorCompanyName(): string {
    if (this.contractorInvoiceDetail && this.requestedJob && this.requestedJob.contractorAppointedDetails.length > 0) {
      const appointedDetailId = this.contractorInvoiceDetail.contractorAppointedDetailIds[0]
      const contractorDetail = this.requestedJob.contractorAppointedDetails.find((e) => e.id === appointedDetailId)
      return contractorDetail ? contractorDetail.companyName : ''
    }
    return ''
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>
