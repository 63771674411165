<template>
  <v-flex :class="'xs' + size">
    <v-card raised class="policy-information">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <pre><b>{{detail}}</b></pre>
        <pre v-if="otherDetail"><b>{{otherDetail}}</b></pre>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class PropertyCard extends Vue {
  @Prop() private size: number
  @Prop() private title: string
  @Prop() private detail: string
  @Prop() private otherDetail: string
}
</script>

<style scoped>
.v-card__title {
  padding-bottom: 0;
}
.v-card {
  box-shadow: none;
  height: 100% !important;
}
.policy-information .v-card__text,
.policy-information .v-card__title {
  flex: 0 0 auto;
}
.flex {
  background-color: #ffffff;
  border-radius: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.flex:nth-child(3) {
  border-right: 0;
}
pre {
  font-family: inherit;
  font-weight: 600;
  white-space: inherit;
}
</style>
