<template>
  <v-layout wrap>
    <v-flex v-if="item">
      <div v-if="!item.sentToNominee" class="small-text item-description">
        {{ item.body }}
      </div>
      <div
        v-if="item.sentToNominee && job.nomineeClientTitle && job.nomineeClientForename && job.nomineeClientLastName"
        class="my-1"
      >
        Notification has been sent on
        <b>{{ item.to }}</b>
        to Nominee
        <b>{{ job.nomineeClientFullName }}</b>
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import SMSModel from '@/models/claim/SMSModel'
import storeGetters from '@/storeGetters'
@Component
export default class SMSCard extends TimeLineItemBase {
  private get item(): SMSModel | null {
    if (this.jobId && this.itemId) {
      return storeGetters.getJobSmsTexts(this.jobId, this.itemId)
    }
    return null
  }
}
</script>

<style scoped>
.item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  word-break: break-all;
}
</style>
