import moment from 'moment'
import TradeFeeDetailModel from './TradeFeeDetailModel'
import CTPJobCardDetailModel from './CTPJobCardDetailModel'
import CompleteJobDataModel from './CompleteJobDataModel'

export default class JobSummaryCardModel {
  public jobId: string
  public jobLoggedAt: moment.Moment
  public tradeFeeDetails: TradeFeeDetailModel[]
  public ctpJobCardDetails: CTPJobCardDetailModel[]
  public completeJobData: CompleteJobDataModel
  constructor() {
    this.tradeFeeDetails = []
    this.ctpJobCardDetails = []
  }
}
