<template>
  <div @click.stop="showCardPreview()">
    <p class="mb-1">
      <span v-if="unableToCompleteReason">{{ unableToCompleteReason }}</span>
      <span v-else> Your next action is to complete the job </span>
    </p>
    <div class="question-title-desc">
      <v-btn color="green white--text" large :ripple="true" class="ml-0" :disabled="!!unableToCompleteReason">
        <v-icon small>work</v-icon>
        &nbsp;&nbsp; Complete Job
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'

@Component
export default class JobCompleteConfirmationCard extends TimeLineItemBase {
  showCardPreview() {
    this.$emit('cardClicked', this.cardIndex)
  }

  get unableToCompleteReason(): string | null {
    return this.$store.getters['getUnableToCompleteReason'](this.jobId)
  }
}
</script>

<style scoped>
.chip-btn .v-chip {
  font-size: 12px;
  font-weight: bold;
}
</style>
