import SIVisitPitTestDetail from '../SIVisitPitTestDetail'
import { SIJobProductProgress } from '@/common/enums'

export default class UpdateTrialPitDetail {
  public id: string
  public jobId: string
  public pitId: string
  public pitTests: SIVisitPitTestDetail
  public trialPitDrawingId: string
  public notes: string
  public status: string
}
