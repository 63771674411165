<template>
  <div>
    <div class="mb-2"><h4>Update Engineer Request</h4></div>
    <div v-if="item.requestStatus" class="mb-1">
      <label>Request Status</label>
      <span>
        <pre>{{ item.requestStatus }}</pre>
      </span>
    </div>
    <div v-if="item.rejectReason" class="mb-1">
      <label>Reject Reason</label>
      <span>
        <pre>{{ item.rejectReason }}</pre>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateEngineerRequestStatus from '@/models/requests/UpdateEngineerRequestStatus'

@Component
export default class UpdateEngineerRequestStatusLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateEngineerRequestStatus {
    return Object.assign(new UpdateEngineerRequestStatus(), this.requestBody)
  }
}
</script>

<style scoped>
pre {
  display: inherit;
  font-family: inherit;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
