import { AnswerType, OperatorType } from '@/common/enums'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import storeMutations from '@/storeMutations'
import { JobType } from './types'
export type ValidationResult = 'unknown' | 'valid' | 'missing' | 'unanswered'

export default class ProductQA extends CosmosJobsDbRecordBase {
  // properties gathered from DB //
  public productId: number
  public jobType: JobType
  public insurerId: number

  public questionId: number // the id of the question from the policy holder db
  public questionText: string // question text to display
  public orderBy: number // order id for this question within its group (from db)

  public answer: string // answer given (as string)
  public answerType: AnswerType // type the answer should be
  public additionalValues: string | null // if answerType === AnswerType.Dropdown the possible values, otherwise null
  public unit: string | null // if answerType === AnswerType.Number the possible values, otherwise null

  public keyQuestion: boolean
  public mandatory: boolean
  public note: string | null

  // eslint:disable-next-line:variable-name
  public __validationErrorMessage: string

  public constructor() {
    super()
    // set defaults
    this.additionalValues = null
    this.orderBy = 0
    this.answerType = AnswerType.Text
    this.keyQuestion = false
    this.mandatory = false
  }

  // computed properties

  // if the answer type is dropdown returns the
  // list of possible options gathered fom the additionalValues new line
  // seperated string. otherwise returns an empty list.
  public get dropdownOptions(): string[] {
    if (this.answerType === AnswerType.Dropdown && this.additionalValues) {
      const items = this.additionalValues.split('\n')
      if (items && items.length > 0) {
        for (let i = 0, l = items.length; i < l; i++) {
          items[i] = items[i].trim()
        }
        return items
      }
    }
    return []
  }

  public get isValid(): boolean {
    switch (this.validationStatus) {
      case 'unknown':
      case 'valid':
        return true
      case 'missing':
      case 'unanswered':
      default:
        return false
    }
  }

  public get validationErrorMessage(): string {
    if (!this.isValid && this.__validationErrorMessage) {
      return this.__validationErrorMessage
    }
    return ''
  }

  public setValidationErrorMessage(message: string) {
    storeMutations.updateProductValidationErrorMessage(this.jobId, this.id, message)
  }

  // checks if the given answer is valid based on the
  // comparison rule and returns the result
  public get validationStatus(): ValidationResult {
    // validate based on comparison method and madatory flag

    // mandatory check
    if (!this.answer && this.mandatory) {
      this.setValidationErrorMessage('an answer to this question is mandatory')
      return 'missing'
    }

    if (!this.answer) {
      return 'unanswered'
    }

    return 'valid'
  }
}
