<template>
  <v-layout wrap>
    <v-card class="pt-0 px-2 pb-2 reopen-job-card">
      <v-card-title>
        <v-layout>
          <v-flex xs12>
            <h3 class="heading">
              <span class="teal--text">Job Reopen Reason Detail</span>
            </h3>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12>
            <v-select
              v-model="reopenReasonId"
              v-validate="'required'"
              :items="reopenReasonTypeList"
              item-text="description"
              item-value="id"
              label="Reason For Reopen"
              required
              class="required reopen-reason-list"
              data-vv-scope="frmReopenReason"
              data-vv-name="Reason"
              :error-messages="errors.collect('Reason')"
              @change="onReasonTypeChange"
            >
              <template slot="item" slot-scope="data">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                      {{ data.item.description }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model.trim="reopenDescription"
              v-validate="'required'"
              label="Description"
              rows="5"
              name="Description"
              required
              class="required"
              data-vv-scope="frmReopenReason"
              data-vv-name="Description"
              :error-messages="errors.collect('Description')"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <div class="text-xs-right">
              <v-btn
                color="primary"
                class="mr-0 btn-submit"
                :disabled="isLoading"
                :loading="isLoading"
                @click="reopenJob"
              >
                Submit
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="700"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureDropdown
        ref="refConfigureDropdownDialog"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import eventBus from '@/common/bus'
import MasterRecord from '@/models/MasterRecord'
import { RecordType } from '@/common/enums'
import MasterRecordController from '@/api/masterRecordController'
import store from '@/store'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import Shared from '@/common/shared'
import JobController from '@/api/jobController'
import AddReopenJobRequest from '@/models/requests/AddReopenJobRequestModel'

@Component({
  components: { ConfigureDropdown },
})
export default class JobReopenPreview extends TimeLineItemBase {
  private reopenReasonId = ''
  private reopenDescription = ''
  private reopenReasonTypeList: MasterRecord[] = []
  private isLoading = false
  private configureDropdownDialog = false
  private configureDropdownType = ''

  private created() {
    this.getreopenReasonTypeList()
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  // Get list of reopen reason type.
  private getreopenReasonTypeList(): void {
    const recordType: string = RecordType[RecordType.JobReopenReason]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.reopenReasonTypeList = res.filter((e) => e.isDeleted === false)
        this.addConfigureOption()
      }
    })
  }

  private onReasonTypeChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.JobReopenReason]
      this.configureDropdownDialog = true
    }
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmReopenReason')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private reopenJob() {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          const reopenReason: MasterRecord | undefined = this.reopenReasonTypeList.find(
            (x) => x.id === this.reopenReasonId
          )

          const addReopenJobRequest: AddReopenJobRequest = new AddReopenJobRequest()
          addReopenJobRequest.jobId = this.jobId
          addReopenJobRequest.reopenReason = reopenReason ? reopenReason.description : ''
          addReopenJobRequest.reopenDescription = this.reopenDescription

          this.isLoading = true
          JobController.ReopenJobAsync(addReopenJobRequest)
            .then((res: boolean) => {
              eventBus.$emit(
                'showSnackbar',
                res ? 'Job reopen request added successfully' : 'something went wrong, please try again'
              )
              this.isLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error submitting job reopen request', true)
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private onConfigureDropdownDialogClose(Items: MasterRecord[]) {
    this.configureDropdownDialog = false
    this.configureDropdownType = ''
    this.reopenReasonId = ''
    const deletedRecord: MasterRecord | undefined = this.reopenReasonTypeList.find((e) => e.isDeleted === true)
    this.reopenReasonTypeList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.reopenReasonTypeList.push(deletedRecord)
    }
    this.addConfigureOption()
  }

  private addConfigureOption() {
    if (this.isUserRoleAdministrator) {
      const reopeneReasonConfigure: any = {}
      reopeneReasonConfigure.description = 'Configure'
      this.reopenReasonTypeList.push(reopeneReasonConfigure)
    }
  }
}
</script>

<style scoped>
.bold-select {
  font-weight: 600;
}
.reopen-job-card {
  width: 100%;
}
</style>
