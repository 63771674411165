<template>
  <div class="contractorrate">
    <v-card class="elevation-1">
      <v-card-title v-if="!fromJobCompletionScreen" xs12 px-3 pt-3 pb-0>
        <h3>Contractor Rate</h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-container fluid grid-list-lg py-0>
          <v-layout wrap>
            <v-flex xs4>
              <div class="newindicator-chip">
                <v-autocomplete
                  v-if="!isAccessToCreateRatePackage"
                  v-model="selectedRatePackage"
                  v-validate="'required'"
                  label="Search package"
                  return-object
                  :loading="getRatePackageRunning"
                  :items="getContractorRatePackageList"
                  item-text="packageName"
                  item-value="id"
                  data-vv-scope="frmContractorRate"
                  data-vv-name="Search package"
                  :error-messages="errors.collect('Search package')"
                  :disabled="fromJobCompletionScreen"
                  class="rate-package"
                  @change="onContractorRatePackageChange($event)"
                ></v-autocomplete>
                <v-combobox
                  v-if="isAccessToCreateRatePackage"
                  v-model="selectedRatePackage"
                  v-validate="'required'"
                  label="Search package"
                  return-object
                  :loading="getRatePackageRunning"
                  :items="getContractorRatePackageList"
                  item-text="packageName"
                  item-value="id"
                  data-vv-scope="frmContractorRate"
                  data-vv-name="Search package"
                  :error-messages="errors.collect('Search package')"
                  :data-vv-delay="validationDelay"
                  :disabled="fromJobCompletionScreen"
                  @change="onContractorRatePackageChange($event)"
                ></v-combobox>
                <v-chip color="secondary" class="white--text" small disabled :value="isNewRatePackage">New</v-chip>
              </div>
            </v-flex>
            <v-flex class="mt-1" xs2>
              <v-switch
                v-model="isHasFixedContractorRate"
                name="fixedRate"
                color="primary"
                hide-details
                label="Fixed Rate"
                @change="onContractorFixedRatedChange($event)"
              ></v-switch>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                v-model.number="contractorRatePackageModel.materialUplift"
                label="Materials uplift(%)"
                maxlength="5"
                :readonly="isUserRoleContractor"
                @keypress="numberKeyValidation($event)"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                v-model.number="contractorRatePackageModel.abortedVisit"
                label="Aborted Visit"
                maxlength="5"
                @keypress="numberKeyValidation($event)"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap mt-0>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="contractorRatePackageModel.contractorRates"
                item-key="tradeId"
                hide-actions
                class="elevation-1 contractor-rate-table"
                expand
              >
                <template slot="headers" slot-scope="props">
                  <tr>
                    <th v-for="header in props.headers" :key="header.text" :class="header.class" :align="header.align">
                      {{ header.text.replace('-Callout', '') }}
                    </th>
                  </tr>
                </template>
                <template slot="items" slot-scope="props">
                  <tr>
                    <td>
                      <v-select
                        v-model="props.item.tradeId"
                        v-validate="'required'"
                        :items="getTrades"
                        item-value="tradeId"
                        item-text="description"
                        :readonly="isUserRoleContractor"
                        :loading="getTradesRunning"
                        data-vv-scope="frmContractorRate"
                        :data-vv-name="'Trade' + props.index"
                        :error-messages="validateTradeList('Trade' + props.index, props.item)"
                        class="tradeList"
                      >
                        <template slot="item" slot-scope="data">
                          <v-list-tile>
                            <v-list-tile-content>
                              <v-list-tile-title
                                :class="getClass(data.item.tradeId)"
                                v-html="data.item.description"
                              ></v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                        </template>
                      </v-select>
                    </td>
                    <td class="minimum-width">
                      <div class="fixed-price">
                        <v-text-field
                          v-model.number="props.item.fixedRateAmount"
                          v-validate="{
                            rules: { required: props.item.hasFixedTradeRate },
                          }"
                          maxlength="8"
                          required
                          class="prepend-icon-image required fixedRateAmount"
                          prepend-icon="close"
                          :disabled="!props.item.hasFixedTradeRate"
                          data-vv-scope="frmContractorRate"
                          :data-vv-name="'fixedRateAmount' + props.index"
                          :error-messages="validationMessage('fixedRateAmount' + props.index)"
                          name="fixedRateAmount"
                          @keypress="numberKeyValidation($event)"
                        ></v-text-field>
                        <v-switch
                          v-model="props.item.hasFixedTradeRate"
                          name="tableFixedRate"
                          color="primary"
                        ></v-switch>
                      </div>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item.insideM25"
                        :disabled="isUserRoleContractor || props.item.hasFixedTradeRate"
                        color="primary"
                        class="isFixedCostApplied"
                      ></v-checkbox>
                    </td>
                    <td class="call-row">
                      <v-text-field
                        v-model.number="props.item.calloutDayPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="call-row">
                      <v-text-field
                        v-model.number="props.item.calloutNightPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="call-row">
                      <v-text-field
                        v-model.number="props.item.calloutWeekendPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="call-row">
                      <v-text-field
                        v-model.number="props.item.calloutHolidayPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="call-row">
                      <v-text-field
                        v-model.number="props.item.calloutChristmasHolidayPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="follow-row">
                      <v-text-field
                        v-model.number="props.item.followOnDayPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="follow-row">
                      <v-text-field
                        v-model.number="props.item.followOnNightPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="follow-row">
                      <v-text-field
                        v-model.number="props.item.followOnWeekendPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="follow-row">
                      <v-text-field
                        v-model.number="props.item.followOnHolidayPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td class="follow-row">
                      <v-text-field
                        v-model.number="props.item.followOnChristmasHolidayPrice"
                        maxlength="8"
                        :readonly="isUserRoleContractor"
                        :disabled="props.item.hasFixedTradeRate"
                        class="isFixedCostApplied"
                        @keypress="numberKeyValidation($event)"
                      ></v-text-field>
                    </td>
                    <td v-if="!isUserRoleContractor" class="follow-row">
                      <v-tooltip v-if="!fromJobCompletionScreen" bottom>
                        <template #activator="{ on }">
                          <v-btn
                            v-if="contractorRatePackageModel.contractorRates.length > 1"
                            flat
                            icon
                            class="mr-0 btn-remove"
                            color="primary"
                            v-on="on"
                            @click.prevent="removeAdditionalRate(props.item)"
                          >
                            <v-icon dark color="primary">close</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove Row</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div v-if="!isUserRoleContractor && !fromJobCompletionScreen" class="text-xs-right">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn class="mr-0 mt-2 btn-add" icon color="primary" small v-on="on" @click="addAdditionalRate">
                      <v-icon dark class="white--text" small>add</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Row</span>
                </v-tooltip>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!fromJobCompletionScreen"
          :disabled="isLoading"
          :loading="isLoading"
          color="primary"
          class="btn-saveOrAssignRatePackage"
          @click="SaveOrAssignRatePackage"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import ContractorRateModel from '@/models/contractor/ContractorRateModel'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import ContractorRatePackageModel from '@/models/contractor/ContractorRatePackageModel'
import RateController from '@/api/rateController'
import Shared from '@/common/shared'
import ContractorController from '@/api/contractorController'
import Store from '@/store'
import eventBus from '@/common/bus'

@Component
export default class ContractorRate extends Vue {
  @Prop() private contractorId: string
  @Prop() private contractorName: string
  @Prop() private ratePackageId: string
  @Prop() private allContractorRatePackageList: ContractorRatePackageModel[]
  @Prop({ default: false }) private fromJobCompletionScreen: boolean

  private contractorRatePackageList: ContractorRatePackageModel[] = []
  private contractorRatePackageModel: ContractorRatePackageModel = new ContractorRatePackageModel()
  private oldContractorRatePackageModel: ContractorRatePackageModel = new ContractorRatePackageModel()
  private selectedRatePackage: ContractorRatePackageModel | string | null = null
  private trades: TradeModel[] = []
  private getRatePackageRunning = false
  private getTradesRunning = false
  private isLoading = false
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = 'Rate saved successfully.'
  private isNewRatePackage = false
  private headers: any = []
  private validationDelay = Shared.validationDelay
  private isAllTheTradesHasFixedRate = false

  private created() {
    this.headers = [
      {
        text: 'Trade',
        value: 'tradeId',
        sortable: false,
        align: 'left',
        class: 'contract-type-cul',
      },
      {
        text: 'Fixed Rate',
        value: 'hasFixedTradeRate',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Inside M25?',
        value: 'insideM25',
        align: 'left',
        sortable: false,
      },
      {
        text: '8am-6pm-Callout',
        value: 'calloutDayPrice',
        sortable: false,
        align: 'left',
        class: 'call-cell',
      },
      {
        text: '6pm-8am-Callout',
        value: 'calloutNightPrice',
        align: 'left',
        sortable: false,
        class: 'call-cell',
      },
      {
        text: 'Weekend-Callout',
        value: 'calloutWeekendPrice',
        align: 'left',
        sortable: false,
        class: 'call-cell',
      },
      {
        text: 'Holiday-Callout',
        value: 'calloutHolidayPrice',
        align: 'left',
        sortable: false,
        class: 'call-cell',
      },
      {
        text: 'Christmas-Callout',
        value: 'calloutChristmasHolidayPrice',
        align: 'left',
        sortable: false,
        class: 'call-cell',
      },
      {
        text: '8am-6pm',
        value: 'followOnDayPrice',
        align: 'left',
        sortable: false,
        class: 'folllow-cell',
      },
      {
        text: '6pm-8am',
        value: 'followOnNightPrice',
        align: 'left',
        sortable: false,
        class: 'folllow-cell',
      },
      {
        text: 'Weekend',
        value: 'followOnWeekendPrice',
        align: 'left',
        sortable: false,
        class: 'folllow-cell',
      },
      {
        text: 'Holiday',
        value: 'FollowOnHolidayPrice',
        align: 'left',
        sortable: false,
        class: 'folllow-cell',
      },
      {
        text: 'Christmas',
        value: 'followOnChristmasHolidayPrice',
        align: 'left',
        sortable: false,
        class: 'folllow-cell',
      },
      { text: '', value: '', sortable: false, class: 'rm-row-cul' },
    ]

    this.getContractorRatePackages()
    this.getTradeList()
    if (this.isUserRoleContractor) {
      this.headers.pop()
    }
  }

  private addIdToContractorRateList() {
    // add id to contractorTradeRates, required to prevent user from select deleted trade, if previously not selected
    for (let index = 0; index < this.contractorRatePackageModel.contractorRates.length; index++) {
      const element = this.contractorRatePackageModel.contractorRates[index]
      element.id = index + 1
    }
  }

  private destroyed() {
    this.$validator.errors.items = []
  }

  private get getAllContractorRatePackageList() {
    return this.allContractorRatePackageList
  }

  private get isUserRoleContractor(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private get getContractorRatePackageList(): ContractorRatePackageModel[] {
    return this.contractorRatePackageList
  }

  // return true, if going to assign rate package
  // return false, if create/update rate template
  private get isAccessToCreateRatePackage(): boolean {
    return this.contractorId ? false : true
  }

  // this method return true if any changes done in rates, otherwise return false
  private getChanges(): boolean {
    if (JSON.stringify(this.oldContractorRatePackageModel) !== JSON.stringify(this.contractorRatePackageModel)) {
      return true
    }
    return false
  }

  private getTradeList() {
    PolicyHolderController.GetTrades()
      .then((res: TradeModel[]) => {
        if (res) {
          this.trades = res.filter((e) => e.jobType === 'HE')
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
      })
  }

  // contractor rate package dropdown change event
  private onContractorRatePackageChange(event: any) {
    if (this.fromJobCompletionScreen) {
      return
    }
    if (event && event.id) {
      // select rate package from list
      this.isNewRatePackage = false
      this.contractorRatePackageModel.id = event.id
      this.contractorRatePackageModel.packageName = event.packageName
      this.contractorRatePackageModel.materialUplift = event.materialUplift
      this.contractorRatePackageModel.abortedVisit = event.abortedVisit
      this.contractorRatePackageModel.sequence = event.sequence
      this.contractorRatePackageModel.contractorRates = event.contractorRates
      this.contractorRatePackageModel.forCustomPackageContractorId = event.forCustomPackageContractorId
      this.addIdToContractorRateList()
      this.oldContractorRatePackageModel = JSON.parse(JSON.stringify(this.contractorRatePackageModel))
    } else {
      // creating new rate package
      const packageName = event
      this.contractorRatePackageModel = new ContractorRatePackageModel()
      if (this.selectedRatePackage && typeof this.selectedRatePackage === 'object') {
        this.contractorRatePackageModel.materialUplift = this.selectedRatePackage.materialUplift
        this.contractorRatePackageModel.abortedVisit = this.selectedRatePackage.abortedVisit
        this.contractorRatePackageModel.sequence = this.selectedRatePackage.sequence
        this.contractorRatePackageModel.contractorRates = this.selectedRatePackage.contractorRates
      }
      this.contractorRatePackageModel.packageName = packageName
      this.isNewRatePackage = true
    }
  }

  // filter rate packages by contractor from all contractor rate packages
  private getContractorRatePackages() {
    const self = this
    if (self.getAllContractorRatePackageList) {
      self.getRatePackageRunning = true
      const contractorRatePackageList: ContractorRatePackageModel[] = self.allContractorRatePackageList.filter(
        (a: ContractorRatePackageModel) =>
          (a.id === self.ratePackageId && a.forCustomPackageContractorId === this.contractorId) ||
          a.forCustomPackageContractorId === null
      )
      self.contractorRatePackageList = JSON.parse(JSON.stringify(contractorRatePackageList))
      if (self.ratePackageId) {
        const contractorRatePackage: ContractorRatePackageModel | undefined = self.contractorRatePackageList.find(
          (e: ContractorRatePackageModel) => e.id === self.ratePackageId
        )
        if (contractorRatePackage) {
          self.selectedRatePackage = contractorRatePackage
          self.contractorRatePackageModel = JSON.parse(JSON.stringify(contractorRatePackage))
          this.addIdToContractorRateList()
          self.oldContractorRatePackageModel = JSON.parse(JSON.stringify(self.contractorRatePackageModel))
        }
      }
      self.getRatePackageRunning = false
    }
  }

  @Watch('allContractorRatePackageList')
  private allContractorRatePackageListChange() {
    this.getContractorRatePackages()
  }

  // filter rate packages by ratePackageId from all contractor rate packages
  @Watch('ratePackageId')
  private onRatePackageIdChanged(ratePackageId: string) {
    const contractorRatePackageList: ContractorRatePackageModel[] = this.allContractorRatePackageList.filter(
      (a: ContractorRatePackageModel) =>
        (a.id === this.ratePackageId && a.forCustomPackageContractorId === this.contractorId) ||
        a.forCustomPackageContractorId === null
    )
    this.contractorRatePackageList = contractorRatePackageList
    if (ratePackageId && this.contractorRatePackageList) {
      const contractorRatePackage: ContractorRatePackageModel | undefined = this.contractorRatePackageList.find(
        (e: ContractorRatePackageModel) => e.id === ratePackageId
      )
      if (contractorRatePackage) {
        this.selectedRatePackage = contractorRatePackage
        this.contractorRatePackageModel = JSON.parse(JSON.stringify(contractorRatePackage))
        this.addIdToContractorRateList()
        this.oldContractorRatePackageModel = JSON.parse(JSON.stringify(this.contractorRatePackageModel))
      }
    } else {
      this.selectedRatePackage = null
      this.contractorRatePackageModel = new ContractorRatePackageModel()
      this.oldContractorRatePackageModel = new ContractorRatePackageModel()
    }
  }

  private async validate(): Promise<boolean> {
    const isSelectedTradeDeleted: boolean = this.checkIsTradeDeleted()
    const result: boolean = await this.$validator.validateAll('frmContractorRate')
    // set focus to non validate field
    if (!result || isSelectedTradeDeleted) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result && !isSelectedTradeDeleted
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private validateTradeList(label: string, item: any) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (message) {
      return (message = 'The ' + errorMessage[0] + ' is required.')
    } else {
      const contractorRate = this.oldContractorRatePackageModel.contractorRates.findIndex(
        (c) => c.tradeId === item.tradeId && item.id === c.id
      )
      if (contractorRate === -1) {
        const trade = this.trades.find((e) => e.isDeleted && e.tradeId === item.tradeId)
        return trade ? (message = 'The selected trade is deleted.') : (message = '')
      }
    }
  }

  // save rate template or assign rate package to contractor
  private SaveOrAssignRatePackage() {
    if (this.contractorId) {
      // assign rate package to contractor
      this.AssignRatePackageToContractor()
    } else {
      // create/update rate template
      this.SaveRatePackage()
    }
  }

  // this method will call when create or update rate template
  private SaveRatePackage() {
    const self = this
    setTimeout(() => {
      this.validate()
        .then((result: boolean) => {
          if (result && self.contractorRatePackageModel) {
            self.isLoading = true
            RateController.SaveRatePackage(self.contractorRatePackageModel)
              .then((res: string) => {
                self.isLoading = false
                if (res && res !== '') {
                  if (self.isNewRatePackage) {
                    self.contractorRatePackageModel.id = res
                    self.contractorRatePackageModel.forCustomPackageContractorId = null
                    if (this.contractorRatePackageModel.contractorRates.filter((c) => c.tradeId).length > 0) {
                      this.addIdToContractorRateList()
                      this.oldContractorRatePackageModel = JSON.parse(JSON.stringify(this.contractorRatePackageModel))
                    }
                    self.contractorRatePackageList.push(self.contractorRatePackageModel)
                    self.getAllContractorRatePackageList.push(self.contractorRatePackageModel)
                    self.isNewRatePackage = false
                  } else {
                    const contractorRatePackage: ContractorRatePackageModel =
                      this.getAllContractorRatePackageList.find(
                        (e: ContractorRatePackageModel) => e.id === self.contractorRatePackageModel.id
                      )  || new ContractorRatePackageModel()
                    Object.assign(contractorRatePackage, self.contractorRatePackageModel)
                  }
                  this.getContractorRatePackages()
                  this.$emit('update:allContractorRatePackageList', self.getAllContractorRatePackageList)
                  self.saveSnackbar = true
                }
              })
              .catch((err: any) => {
                eventBus.$emit('errorHandler', 'Error saving rate package detail, please try again', true)
                self.isLoading = false
              })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }, 0)
  }

  // this method will call when assign rate package to contractor
  private AssignRatePackageToContractor() {
    const self = this
    setTimeout(() => {
      this.validate()
        .then((result: boolean) => {
          if (result && self.contractorRatePackageModel) {
            self.isLoading = true
            let isCreatingCustomRate = false

            // if got any changes in package rate then create custom package rate for that contractor
            if (
              self.contractorRatePackageModel &&
              !self.contractorRatePackageModel.forCustomPackageContractorId &&
              self.getChanges()
            ) {
              const ratePackage: ContractorRatePackageModel | undefined = self.getContractorRatePackageList.find(
                (p: ContractorRatePackageModel) => p.forCustomPackageContractorId != null
              )
              if (ratePackage && ratePackage.id) {
                // modifying existing custom rate package
                self.contractorRatePackageModel.id = ratePackage.id
                self.contractorRatePackageModel.forCustomPackageContractorId = ratePackage.forCustomPackageContractorId
              } else {
                // creating new custom rate package
                self.contractorRatePackageModel.id = ''
                self.contractorRatePackageModel.forCustomPackageContractorId = self.contractorId
                isCreatingCustomRate = true
              }

              // custom package rate name will be contractor name plus custome rate
              self.contractorRatePackageModel.packageName = self.contractorName + ' custom rate'
              self.selectedRatePackage = self.contractorRatePackageModel
            }

            ContractorController.AssignRatePackageToContractor(self.contractorRatePackageModel, self.contractorId)
              .then((res: string) => {
                self.isLoading = false
                if (res && res !== '') {
                  if (isCreatingCustomRate) {
                    self.contractorRatePackageModel.id = res
                    self.contractorRatePackageList.push(self.contractorRatePackageModel)
                    self.getAllContractorRatePackageList.push(self.contractorRatePackageModel)
                  }
                  this.$emit('update:ratePackageId', res)
                  this.saveSnackbar = true
                }
              })
              .catch((err: any) => {
                eventBus.$emit('errorHandler', 'Error assigning rate package to contractor, please try again', true)
                self.isLoading = false
              })
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    }, 0)
  }

  // add additional rate
  private addAdditionalRate(): void {
    const cr: ContractorRateModel = new ContractorRateModel()
    cr.hasFixedTradeRate = this.isHasFixedContractorRate
    if (this.contractorRatePackageModel.contractorRates.length > 0) {
      cr.id =
        Math.max.apply(
          Math,
          this.contractorRatePackageModel.contractorRates.map((e) => e.id)
        ) + 1 //  add id to newly added rate
    }
    this.contractorRatePackageModel.contractorRates.push(cr)
  }

  // remove additional rate
  private removeAdditionalRate(contractorRate: ContractorRateModel): void {
    const index = this.contractorRatePackageModel.contractorRates.indexOf(contractorRate)
    this.contractorRatePackageModel.contractorRates.splice(index, 1)
  }

  private numberKeyValidation(event: any) {
    Shared.IsNumericDecimal(event)
  }

  private onContractorFixedRatedChange(event) {
    if (this.contractorRatePackageModel.contractorRates) {
      this.contractorRatePackageModel.contractorRates.map((e) => (e.hasFixedTradeRate = event))
      this.contractorRatePackageModel.contractorRates = [...this.contractorRatePackageModel.contractorRates]
    }
  }

  private get isHasFixedContractorRate() {
    if (
      this.contractorRatePackageModel &&
      this.contractorRatePackageModel.contractorRates &&
      this.contractorRatePackageModel.contractorRates.length > 0
    ) {
      this.isAllTheTradesHasFixedRate =
        this.contractorRatePackageModel.contractorRates.filter((x) => !x.hasFixedTradeRate).length > 0 ? false : true
      return this.isAllTheTradesHasFixedRate
    }
    return false
  }

  private set isHasFixedContractorRate(newValue) {
    this.isAllTheTradesHasFixedRate = newValue
  }

  private getClass(tradeId: number) {
    const trade = this.trades.find((e) => e.tradeId === tradeId)
    return trade && trade.isDeleted ? 'deleted-item' : ''
  }

  private checkIsTradeDeleted() {
    let isSelectedTradeDeleted = false
    for (const contractorRate of this.contractorRatePackageModel.contractorRates) {
      const trade = this.trades.find((tr) => tr.tradeId === contractorRate.tradeId)
      const oldTradeRate = this.oldContractorRatePackageModel.contractorRates.find(
        (e) => e.id === contractorRate.id && e.tradeId === contractorRate.tradeId
      )
      isSelectedTradeDeleted = oldTradeRate ? false : trade && trade.isDeleted ? true : false
      if (isSelectedTradeDeleted) {
        break
      }
    }
    return isSelectedTradeDeleted
  }

  private get getTrades() {
    return this.trades.filter(
      (e) =>
        !e.isDeleted ||
        (e.isDeleted
          ? this.oldContractorRatePackageModel.contractorRates.findIndex((i) => i.tradeId === e.tradeId) !== -1
          : false)
    )
  }
}
</script>

<style scoped>
.contractor-rate-table >>> .contract-type-cul {
  min-width: 210px;
}
.contractor-rate-table >>> .type-cul {
  min-width: 190px;
}
.contractor-rate-table >>> .rm-row-cul {
  min-width: 10px;
}
.contractor-rate-table :-ms-input-placeholder {
  font-size: 14px;
}
.contractor-rate-table :-moz-placeholder {
  font-size: 14px;
}
.contractor-rate-table ::-webkit-input-placeholder {
  font-size: 14px;
}
.contractor-rate-table .v-select .v-select__selection--comma,
.contractor-rate-table >>> .v-text-field input,
.contractor-rate-table >>> .v-text-field textarea {
  font-size: 14px;
}
.contractor-rate-table >>> table.v-table tbody td:first-child,
.contractor-rate-table >>> table.v-table tbody td:not(:first-child),
.contractor-rate-table >>> table.v-table tbody th:first-child,
.contractor-rate-table >>> table.v-table tbody th:not(:first-child),
.contractor-rate-table >>> table.v-table thead td:first-child,
.contractor-rate-table >>> table.v-table thead td:not(:first-child),
.contractor-rate-table >>> table.v-table thead th:first-child,
.contractor-rate-table >>> table.v-table thead th:not(:first-child) {
  padding: 0px 12px;
}
.contractor-rate-table >>> .v-select .v-select__selection--comma {
  font-size: 13px;
}

.contractor-rate-table >>> .v-input {
  padding-top: 5px;
  margin: 0;
}
.contractor-rate-table >>> .v-input .v-input__control .v-input__slot {
  margin-bottom: 5px;
}
.contractor-rate-table >>> .v-input--checkbox {
  position: relative;
}
.contractor-rate-table >>> .v-input--checkbox .v-input__slot {
  margin-bottom: 0;
  margin-top: 5px;
}
.contractor-rate-table >>> .v-input--checkbox .v-input__control {
  width: 100%;
}
.contractor-rate-table >>> .v-input--checkbox .v-input__control .v-input--selection-controls__input {
  margin: 0 auto;
}
.contractor-rate-table >>> table.v-table thead th.rm-row-cul {
  background: #eaeaea !important;
}
.contractor-rate-table >>> table.v-table thead th.call-cell {
  background: #f5f5f5 !important;
}
.contractor-rate-table >>> table.v-table thead th.folllow-cell {
  background: #eaeaea !important;
}
.contractor-rate-table >>> table.v-table tbody td.call-row {
  background: #f5f5f5 !important;
}
.contractor-rate-table >>> table.v-table tbody td.follow-row {
  background: #eaeaea !important;
}
.contractor-rate-table >>> table.v-table tbody tr:hover {
  background: #fff !important;
}
.contractor-rate-table .prepend-icon-image >>> i.v-icon {
  position: relative;
  color: #fff !important;
  height: 0px !important;
}
.contractor-rate-table .prepend-icon-image >>> i.v-icon::after {
  content: '';
  background-image: url('/img/pound-sterling.svg');
  background-size: cover;
  position: absolute;
  left: 5px;
  top: -7px;
  height: 16px;
  width: 16px;
}
.fixed-price {
  position: relative;
}
.fixed-price >>> .v-input--switch {
  position: absolute;
  right: 0;
  top: 2px;
  width: auto;
}
.fixed-price >>> .v-input--switch .v-input__slot {
  margin-bottom: 0;
}
.fixed-price >>> .v-input--switch .v-input__slot .v-input--selection-controls__input {
  margin: 0;
}
.fixed-price >>> input {
  padding-right: 50px;
}
.minimum-width {
  min-width: 170px;
}
</style>
