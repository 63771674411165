<template>
  <v-flex px-2 pt-2>
    <div
      class="example-full"
      :class="['dropzone-area ', dragging ? 'dropzone-over' : '']"
      @dragstart="dragging = true"
      @dragover="dragging = true"
      @dragleave="dragging = false"
    >
      <div class="text-center p-5 dropped-div-area select-file" @click="openFileSelectionWindow">
        <div>
          <h4>
            Drop files here to upload
            <br />
            or
          </h4>

          <label ref="selectFileToUpload" color="primary" :for="name" style="display: none">Select Files</label>
          <v-btn color="primary" class="mr-0 upload-btn">Select Files</v-btn>
        </div>
      </div>
      <file-upload
        ref="fileUpload"
        v-model="files"
        class="btn btn-primary dropdown-toggle"
        :extensions="extensions"
        :multiple="multiple"
        :size="getAttachmentSize"
        :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
        :drop="drop"
        name="file"
        data-vv-scope="formReference"
        @input-filter="onFileUpload"
      ></file-upload>
    </div>
    <div class="image-upload-list">
      <div class="gridView mt-2 v-data-table--fixed-header">
        <div class="input-group__details">
          <div class="input-group__messages input-group__error input-group--error error--text">
            <span>{{ fileValidationMessage(errors, fileErrorMessage) }}</span>
          </div>
        </div>
        <v-data-table :headers="headers" :items="uploadedDocuments" class="elevation-1 upload-image-table" hide-actions>
          <template slot="items" slot-scope="props">
            <td class="img text-xs-center px-0">
              <v-avatar slot="activator" size="40px" class="elevation-2">
                <img :src="documentExtentionType(props.item.documentName)" />
              </v-avatar>
            </td>
            <td>{{ props.item.documentName }}</td>
            <td class="upload-select-document">
              <v-select
                v-model="props.item.documentTypeId"
                v-validate="'required'"
                :items="documentTypes"
                item-text="description"
                item-value="id"
                label="Select Document Type"
                data-vv-scope="formReference"
                :data-vv-name="'DocumentType' + props.index"
                :error-messages="validationMessage('DocumentType' + props.index)"
                single-line
                :disabled="loading"
              ></v-select>
            </td>
            <td class="text-xs-center">
              <v-checkbox
                v-model="props.item.availableForEngineer"
                primary
                hide-details
                :disabled="loading"
                color="primary"
                class="d-inline-block"
              ></v-checkbox>
            </td>
            <td class="upload-select-document text-xs-center">
              <v-menu bottom left :close-on-content-click="false" :nudge-width="350" offset-x>
                <template #activator="{ on }">
                  <v-btn
                    slot="activator"
                    icon
                    color="primary"
                    :flat="!props.item.note ? true : false"
                    small
                    :disabled="loading"
                    class="add-note"
                    v-on="on"
                  >
                    <v-icon class="md-icon" :color="!props.item.note ? 'primary' : ''" dark>notes</v-icon>
                  </v-btn>
                </template>
                <v-card class="px-2 pb-2">
                  <v-textarea
                    v-model.trim="props.item.note"
                    label="Note"
                    hide-details
                    class="document-note"
                  ></v-textarea>
                </v-card>
              </v-menu>
            </td>
            <td class="text-xs-right action">
              <v-btn
                flat
                icon
                color="primary"
                class="elevation-0 ma-0 delete-btn"
                :disabled="loading"
                @click="deleteDocument(props.item)"
              >
                <v-icon color="primary">delete</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import store from '@/store'
import Shared from '@/common/shared'
import UploadedDocumentType from '@/models/UploadedDocumentTypeModel'
import UploadedJobDocumentDetail from '@/models/UploadedJobDocumentDetailModel'
import moment from 'moment'
import Store from '@/store'
import UserModel from '@/models/user/UserModel'
import storeGetters from '@/storeGetters'
import UploadedJobDocumentModel from '@/models/UploadedJobDocumentModel'
import eventBus from '@/common/bus'
import DocumentController from '@/api/documentController'
import FileUpload from 'vue-upload-component'
declare let AzureStorage: any

@Component({
  name: 'UploadDocument',
  components: {
    FileUpload,
  },
})
export default class UploadDocument extends Vue {
  public uploadedDocuments: UploadedJobDocumentDetail[] = []
  @Prop() private jobId: string
  @Prop() private documentTypes: UploadedDocumentType[]
  @Prop({ default: false }) private isInWizardStep: boolean
  @Prop({ default: false }) private isAnyDocumentsUploaded: number
  @Prop({ default: false }) private isLoading: boolean

  private fileName: any = ''
  private fileUrl = ''
  private fileErrorMessage = ''
  private loading = false
  private headers: any = [
    { text: '', align: 'left', sortable: false, value: '' },
    { text: 'Document Name', value: 'documentName' },
    { text: 'Document Type', value: 'documentType' },
    { text: 'For Engineer', value: 'availableForEngineer', align: 'center' },
    { text: 'Note', value: 'note', sortable: false, align: 'center' },
    { text: 'Action', sortable: false, value: '', align: 'right' },
  ]
  private successfullyUploadedDocumentList: UploadedJobDocumentDetail[] = []
  private count = 0
  private allowedExt: string[] = ['jpg', 'jpeg', 'png', 'txt', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'mp4', 'avi']
  private name = 'file'
  private files: any = []
  private extensions: string = this.allowedExt.join(',')
  private minSize = 1024
  private multiple = true
  private drop = true
  private thread = 3
  private dragging = false

  public async uploadDocuments() {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.successfullyUploadedDocumentList = []
          this.loading = true
          if (this.isInWizardStep) {
            eventBus.$emit('documentUploadingStarted', true)
          } else {
            this.$emit('update:isLoading', true)
          }
          this.uploadedDocuments.forEach((document) => {
            this.uploadSingleDocument(document)
          })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private openFileSelectionWindow() {
    const file = this.$refs.selectFileToUpload as any
    file.click()
  }

  private isUploadedFileSizeValid(uploadedFile: any): boolean {
    let returnFlag = true
    if (uploadedFile) {
      const maxFileSize = this.getAttachmentSize
      // convert uploadedFileSize in kb
      const uploadedFileSize = uploadedFile.size / 1024
      returnFlag = !(uploadedFileSize > maxFileSize)
    }
    return returnFlag
  }

  private isUploadedFileExtensionsValid(uploadedFile: any): number {
    let returnFlag = 0
    if (uploadedFile) {
      const uploadedFileName = uploadedFile.name
      let fileExtension = uploadedFileName.substr(uploadedFileName.lastIndexOf('.') + 1, uploadedFileName.length)
      if (fileExtension) {
        fileExtension = fileExtension.toLowerCase()
        const matchingExtension = this.allowedExt.filter((ext) => ext.toLowerCase() === fileExtension)
        returnFlag = matchingExtension.length
      }
    }
    return returnFlag
  }

  private isUploadedFileNameExist(uploadedFile: any): any {
    const existingFile: any = this.uploadedDocuments.find((e) => e.documentName === uploadedFile.name)
    return existingFile
  }

  private onFileUpload(newFile: any, oldFile: any) {
    const self = this
    self.dragging = false
    const fileError = self.$validator.errors.items.find((a) => a.field === 'file')
    if (fileError) {
      fileError.msg = ''
    }

    if (newFile && !oldFile) {
      const file = newFile.file
      const fileName = file.name.split(' ').join('_')
      if (fileName.lastIndexOf('.') <= 0) {
        return
      }
      const isValidFileExtension: number = self.isUploadedFileExtensionsValid(file)
      if (isValidFileExtension === 0) {
        self.fileErrorMessage =
          "Uploaded file's extension is not supported. Supported extensions are " + this.allowedExt.join(',') + '.'
        return
      }
      const isValidFileSize: boolean = self.isUploadedFileSizeValid(file)
      if (!isValidFileSize) {
        self.fileErrorMessage = 'The file size must be less than ' + self.getAttachmentSize / 1024 + ' MB.'
        return
      }

      const isFileNameExist = self.isUploadedFileNameExist(file)
      if (isFileNameExist) {
        self.fileValidationMessage(null, 'File with name ' + fileName + ' is already exist.')
        return
      } else {
        self.fileErrorMessage = ''
        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.addEventListener('load', () => {
          if (!self.fileErrorMessage) {
            self.fileUrl = fr.result as string
            // save file to local filelist.
            const fileSelectedData: UploadedJobDocumentDetail = new UploadedJobDocumentDetail()
            fileSelectedData.documentName = fileName
            fileSelectedData.documentUrl = file
            if (this.documentTypes.length === 1) {
              fileSelectedData.documentType = this.documentTypes[0].description
            } else {
              fileSelectedData.documentType = ''
            }
            fileSelectedData.uploadedByUserName =
              Store.Instance.state.SessionDetail.detailRecord.UserDetail.displayValue
            const date = Shared.getFormatedDate(moment(new Date()), Shared.dateFormatToSaveDocumentOnAzure())
            fileSelectedData.uploadedAt = date
            self.uploadedDocuments.push(fileSelectedData)
            self.fileName = ''
            self.fileUrl = ''
          }
        })
      }
    }
  }

  private documentExtentionType(fileName: string) {
    let fileType = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase()
    if (
      fileType === 'pdf' ||
      fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'xls' ||
      fileType === 'xlsx' ||
      fileType === 'txt' ||
      fileType === 'png' ||
      fileType === 'jpg' ||
      fileType === 'jpeg' ||
      fileType === 'avi' ||
      fileType === 'mp4'
    ) {
      fileType = fileType === 'jpeg' ? 'jpg' : fileType === 'xlsx' ? 'xls' : fileType === 'docx' ? 'doc' : fileType
      return '/img/' + fileType + '.svg'
    }
    return '/img/file.svg'
  }

  private get getAttachmentSize(): number {
    return store.Instance.state.Environment.DocumentAttachmentSize
  }

  // return validation message on file selection if any.
  private fileValidationMessage(err: any, fileErrorMessage: any) {
    if (err && err.collect('file').length > 0) {
      const message: string = err.collect('file')[0]
      this.fileErrorMessage = message
      return message === 'The file field must be a valid file.'
        ? 'Only PDF, XLS, DOC, JPEG, JPG, PNG, TXT, MP4 or AVI files are allowed.'
        : message
    } else if (fileErrorMessage !== '') {
      this.fileErrorMessage = fileErrorMessage
      return this.fileErrorMessage
    }
  }

  private deleteDocument(document: any) {
    Shared.confirmationPopup.open(
      'Are you sure you want to delete this document?',
      '',
      '',
      'No',
      'Yes',
      this,
      'DeleteDocumentFromJob',
      document
    )
  }

  private DeleteDocumentFromJob(document: UploadedJobDocumentDetail): void {
    const index = this.uploadedDocuments.indexOf(document)
    this.uploadedDocuments.splice(index, 1)
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('formReference')
    // check validation error for file(size more than 5 MB, file type - check for allowed Extension), if any found error, remove it before submit
    if (this.$validator.errors.items.length === 1 && this.$validator.errors.items[0].field === 'file') {
      this.$validator.errors.items = []
      this.fileErrorMessage = ''
      return true
    }
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private getDocumentTypeDescription(id: number) {
    const documentType = this.documentTypes.find((d) => d.id === id)
    return documentType ? documentType.description : ''
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private async uploadSingleDocument(document: UploadedJobDocumentDetail) {
    const guid: string =
      Shared.generateGuid() + '.' + document.documentName.substr(document.documentName.lastIndexOf('.') + 1)
    if (document.note) {
      document.note = document.note.replace(/\n/gi, ' ') // replace ↵ from string
    }
    await DocumentController.GetTokenForUploadJobDocument(this.jobId, guid)
      .then((res: string) => {
        if (res !== '') {
          const self = this
          const blobUri = res.split('documents')[0]
          const sasToken = res.split('?')[1]
          const containerName = 'documents'
          const blobService = AzureStorage.Blob.createBlobServiceWithSas(blobUri, sasToken)
          const customBlockSize = (document.documentUrl as any).size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512
          blobService.singleBlobPutThresholdInBytes = customBlockSize
          document.documentType = this.getDocumentTypeDescription(document.documentTypeId)
          const metadataValue = {
            documentType: document.documentType,
            documentTypeId: document.documentTypeId,
            uploadedByUserName: document.uploadedByUserName,
            uploadedAt: document.uploadedAt,
            documentName: document.documentName,
            note: document.note as string | undefined,
            availableForEngineer: document.availableForEngineer,
            includeInReport: false,
            order: '0',
          }
          if (!document.note) {
            delete metadataValue.note
          }
          blobService.createBlockBlobFromBrowserFile(
            containerName,
            guid,
            document.documentUrl,
            { blockSize: customBlockSize, metadata: metadataValue },
            (error, result, response) => {
              if (error) {
                // show error message with some delay
                this.saveUploadedDocuments()
              } else {
                document.documentUrl = ''
                // push document in successful array
                this.successfullyUploadedDocumentList.push(document)
                if (this.uploadedDocuments.length === this.successfullyUploadedDocumentList.length) {
                  this.addUploadJobDocumentRequest(null)
                }
              }
              this.count++
            }
          )
        } else {
          this.saveUploadedDocuments()
        }
      })
      .catch((err: any) => {
        this.saveUploadedDocuments()
      })
  }

  private saveUploadedDocuments() {
    if (this.successfullyUploadedDocumentList.length > 0) {
      this.addUploadJobDocumentRequest('Error in Uploading Document!')
    } else {
      this.showErrorMessage('Error in Uploading Document!')
    }
    this.loading = false
  }

  private addUploadJobDocumentRequest(error: string | null) {
    const uploadedJobDocumentModel: UploadedJobDocumentModel = new UploadedJobDocumentModel()
    uploadedJobDocumentModel.jobId = this.jobId
    uploadedJobDocumentModel.documents = this.uploadedDocuments
    DocumentController.AddUploadJobDocumentRequest(uploadedJobDocumentModel)
      .then((res: boolean) => {
        if (res) {
          this.successfullyUploadedDocumentList = []
          if (error !== null) {
            this.showErrorMessage(error)
          }
          if (this.count === this.uploadedDocuments.length) {
            setTimeout(
              () => {
                this.$emit('documentUploadComplete')
              },
              error ? 2000 : 0
            )
          }
        }
        this.loading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting uploaded document(s), please try again', true)
        this.loading = false
      })
  }

  @Watch('uploadedDocuments')
  private uploadedDocumentsLengthCheck() {
    const self = this
    const isAnyDocumentsUploaded: boolean = self.uploadedDocuments.length > 0 ? true : false
    if (self.isInWizardStep) {
      eventBus.$emit('checkIsAnyDocumentsUploaded', isAnyDocumentsUploaded)
    } else {
      this.$emit('update:isAnyDocumentsUploaded', isAnyDocumentsUploaded)
    }
  }

  private mounted(): void {
    eventBus.$off('uploadDocuments').$on('uploadDocuments', () => {
      const self = this
      if (self.isInWizardStep && self.uploadedDocuments.length > 0) {
        self.uploadDocuments()
      }
    })
  }

  private showErrorMessage(error: string) {
    // show error message
    if (error) {
      eventBus.$emit('errorHandler', error, true)
    }
  }
}
</script>

<style scoped>
.uploaded-files span {
  position: relative;
  margin-right: 10px;
  display: inline-flex;
  border-radius: 50%;
  height: 60px;
  border: 2px solid #f1f1f1;
  width: 60px;
  vertical-align: text-bottom;
  margin-bottom: 10px;
  cursor: pointer;
}
.uploaded-files span img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.uploaded-files span.filedoc img {
  height: 40px;
  width: 40px;
  margin: auto;
}
.uploaded-files span >>> .v-btn {
  position: absolute;
  top: -8px;
  right: -13px;
  height: 20px;
  width: 20px;
}
.upload-image-table .img {
  width: 60px;
}
.upload-select-document {
  width: 272px;
}
.action {
  width: 70px;
}
.image-upload-list {
  min-height: 375px;
}
.upload-image-table >>> thead {
  display: block;
}
.upload-image-table >>> tbody {
  display: block;
  width: 100%;
}
.upload-image-table >>> tbody tr td {
  width: 100%;
}
.upload-image-table >>> thead tr > th:first-child,
.upload-image-table >>> tbody tr td:first-child {
  width: 8%;
}
.upload-image-table >>> thead tr > th:nth-child(2),
.upload-image-table >>> tbody tr td:nth-child(2) {
  width: 24%;
  word-break: break-all;
  white-space: normal;
}
.upload-image-table >>> thead tr th:nth-child(3),
.upload-image-table >>> tbody tr td:nth-child(3) {
  width: 26%;
  white-space: nowrap;
}
.upload-image-table >>> thead tr th:nth-child(4),
.upload-image-table >>> tbody tr td:nth-child(4) {
  width: 16%;
}
.upload-image-table >>> thead tr th:nth-child(5),
.upload-image-table >>> tbody tr td:nth-child(5) {
  width: 13%;
}
.upload-image-table >>> thead tr th:last-child,
.upload-image-table >>> tbody tr td:last-child {
  width: 10%;
}
div.example-full {
  background-color: #ffff;
  border: 1px dashed gray;
  border-radius: 20px;
  cursor: pointer;
  height: 150px;
}
div.dropzone-area.dropzone-over {
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px dashed #2e94c4;
}
div.dropzone-area > div.dropped-div-area {
  margin-top: 4%;
}
.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}
.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
}
.example-full label.dropdown-item {
  margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}
.example-full .filename {
  margin-bottom: 0.3rem;
}
.example-full .btn-is-option {
  margin-top: 0.25rem;
}
.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}
.example-full .edit-image img {
  max-width: 100%;
}
.example-full .edit-image-tool {
  margin-top: 0.6rem;
}
.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}
.example-full .footer-status {
  padding-top: 0.4rem;
}
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
div.margin-top-1 {
  margin-top: 1%;
}
</style>
