import UserModel from '../user/UserModel'
import EngineerModel from '../contractor/EngineerModel'
import ClientUserDetailModel from '@/models/client/ClientUserDetailModel'

export class DetailRecord {
  public UserDetail: UserModel
  public EngineerDetail: EngineerModel
  public ClientUserDetail: ClientUserDetailModel
}

export type DetailRecordType = 'UserDetail' | 'EngineerDetail' | 'ClientUserDetail'

export default class SessionDetail {
  public userName: string
  public userEmail: string
  public identityProviderUserId: string
  public detailRecordType: DetailRecordType
  public detailRecordId: string
  public detailRecord: DetailRecord
  public accessToken: string

  public constructor() {
    this.userName = 'Not Logged In'
    this.userEmail = ''
    this.accessToken = ''
  }

  public get canPlayCall(): boolean {
    return (
      this.detailRecordType === 'UserDetail' &&
      (this.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        this.detailRecord.UserDetail.roleName === 'Administrator' ||
        this.detailRecord.UserDetail.roleName === 'Supervisor' ||
        this.detailRecord.UserDetail.roleName === 'Finance' ||
        this.detailRecord.UserDetail.roleName === 'ClientManager' ||
        this.detailRecord.UserDetail.skills.deployment >= 4 ||
        this.detailRecord.UserDetail.skills.complaintResolution >= 4)
    )
  }

  public get canEscalateJob(): boolean {
    return (
      this.detailRecordType === 'UserDetail' &&
      (this.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        this.detailRecord.UserDetail.roleName === 'Administrator' ||
        this.detailRecord.UserDetail.roleName === 'Supervisor' ||
        this.detailRecord.UserDetail.roleName === 'Finance' ||
        this.detailRecord.UserDetail.roleName === 'ClientManager' ||
        this.detailRecord.UserDetail.skills.workInProgress >= 1)
    )
  }

  public get canCancelJob(): boolean {
    return (
      this.detailRecordType === 'UserDetail' &&
      (this.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        this.detailRecord.UserDetail.roleName === 'Administrator' ||
        this.detailRecord.UserDetail.roleName === 'Supervisor' ||
        this.detailRecord.UserDetail.roleName === 'Finance' ||
        this.detailRecord.UserDetail.roleName === 'ClientManager' ||
        this.detailRecord.UserDetail.skills.fnol >= 3 ||
        this.detailRecord.UserDetail.skills.workInProgress >= 3)
    )
  }

  public get canUpdateSIPackage(): boolean {
    return (
      this.detailRecordType === 'UserDetail' &&
      (this.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        this.detailRecord.UserDetail.roleName === 'Administrator' ||
        this.detailRecord.UserDetail.roleName === 'Supervisor' ||
        this.detailRecord.UserDetail.roleName === 'ClientManager' ||
        this.detailRecord.UserDetail.roleName === 'Finance')
    )
  }

  public get canSeeEngineerPhoneNumber(): boolean {
    return (
      this.detailRecordType === 'UserDetail' &&
      (this.detailRecord.UserDetail.skills.workInProgress === 5 || this.detailRecord.UserDetail.skills.deployment === 5)
    )
  }

  public get canRemoveEmergency(): boolean {
    return (
      this.detailRecordType === 'UserDetail' &&
      (this.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        this.detailRecord.UserDetail.roleName === 'Administrator' ||
        this.detailRecord.UserDetail.roleName === 'Supervisor' ||
        this.detailRecord.UserDetail.skills.fnol >= 2)
    )
  }

  public get canUpdateInvoice(): boolean {
    return this.detailRecordType === 'UserDetail' && this.detailRecord.UserDetail.skills.invoicing >= 3
  }

  public get userRoleDescription(): string {
    switch (this.detailRecordType) {
      case 'EngineerDetail':
        return 'Contractor/Engineer' // TODO: add is admin flag to engineer record, based on this either can admin contractor details or not
      case 'UserDetail':
        switch (this.detailRecord.UserDetail.roleName) {
          case 'IncidentManager':
            return 'Incident Manager'
          default:
            return this.detailRecord.UserDetail.roleName
        }
      case 'ClientUserDetail':
        if (this.isAffinityPartner) {
          return 'Affinity Partner'
        }
        return 'Client'
      default:
        return 'Unknown'
    }
  }

  public get userProfileImageUrl(): string {
    switch (this.detailRecordType) {
      case 'EngineerDetail':
        return this.detailRecord.EngineerDetail.profileImageUrl
          ? this.detailRecord.EngineerDetail.profileImageUrl
          : '/img/unknownuser.png'
      case 'UserDetail':
        return this.detailRecord.UserDetail.profileImageUrlWithUnknownFallback
      case 'ClientUserDetail':
        return this.detailRecord.ClientUserDetail.profileImageUrl
          ? this.detailRecord.ClientUserDetail.profileImageUrl
          : '/img/unknownuser.png'
      default:
        return '/img/unknownuser.png'
    }
  }

  public get canAmendOnOffSiteTimes(): boolean {
    return this.detailRecordType === 'UserDetail' && this.detailRecord.UserDetail.skills.workInProgress >= 2
  }

  public get isAffinityPartner(): boolean {
    return this.detailRecordType === 'ClientUserDetail' && this.detailRecord.ClientUserDetail.isAffinityPartner
  }
}
