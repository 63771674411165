<template>
  <div>
    <div class="mb-2"><h4>Update Job Policy Schedule</h4></div>
    <div v-if="item.insurerName" class="mb-1">
      <label>Insurer: </label>
      <span>{{ item.insurerName }}</span>
    </div>
    <div v-if="item.policyName" class="mb-1">
      <label>Policy: </label>
      <span>{{ item.policyName }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateJobPolicySchedule from '@/models/requests/UpdateJobPolicySchedule'

@Component
export default class UpdateJobPolicyScheduleLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateJobPolicySchedule {
    return Object.assign(new UpdateJobPolicySchedule(), this.requestBody)
  }
}
</script>
