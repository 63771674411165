import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { IJobState } from '../states/interfaces/job-state'
import JobState from '../states/job-state'
import MasterRecordController from '@/api/masterRecordController'
import MasterRecord from '@/models/MasterRecord'
import JobPermissions from '@/models/job/job-permissions'
import JobsController from '@/api/jobs-controller'

const jobState: IJobState = new JobState()

const getters: GetterTree<IJobState, any> = {
  masterRecords: (state) => state.masterRecords,
  // Temporary until we move jobs into job module
  allJobs: (state, getters, rootState) => rootState.Jobs,
  showClearEta: (state, getters, rootState) => state.showClearEta,
  selectedJobId: (state) => state.selectedJobId,
  jobPermissions: (state) => state.jobPermissions,
}

const mutations: MutationTree<IJobState> = {
  updateMasterRecord: (state: IJobState, record: MasterRecord) => {
    const index = state.masterRecords.findIndex((x) => x.id === record.id)
    index > -1 ? state.masterRecords.splice(index, 1, record) : state.masterRecords.push(record)
  },
  showClearEta: (state: IJobState, show: boolean) => {
    state.showClearEta = show
  },
  setSelectedJobId: (state: IJobState, jobId: string) => {
    state.selectedJobId = jobId
  },
  setJobPermissions: (state: IJobState, permissions: JobPermissions) => {
    state.jobPermissions = permissions
  },
}

const actions: ActionTree<IJobState, any> = {
  async submitRetrieveMasterRecords({ commit, state }, recordType: string) {
    // Records already exist in state
    if (state.masterRecords.find((x) => x.type === recordType)) {
      return
    }
    const masterRecords = await MasterRecordController.GetMasterRecords(recordType)
    masterRecords.forEach((record) => commit('updateMasterRecord', record))
  },
  async showClearEtaDialog({ commit }, show: boolean) {
    commit('showClearEta', show)
  },
  async selectJob({ commit }, jobId: string) {
    const apiPermissions = await JobsController.GetJobPermissionsAsync(jobId)

    const jobPermissions = new JobPermissions()
    jobPermissions.canChangeContactDetails = apiPermissions.canChangeContactDetails
    jobPermissions.canTakeManualPayments = apiPermissions.canTakeManualPayments

    commit('setSelectedJobId', jobId)
    commit('setJobPermissions', jobPermissions)
  },
}

export const jobModule: Module<IJobState, any> = {
  state: jobState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
