<template>
  <div v-if="item">
    <div class="mb-2"><h4>Remove contractor from job</h4></div>
    <div v-if="getCancelAssignedJobContractorDetail" class="mb-1">
      <label>Contractor: </label>
      <span>{{ getCancelAssignedJobContractorDetail.companyName }}</span>
    </div>
    <div v-if="item.status" class="mb-1">
      <label>Status: </label>
      <span>{{ item.status }}</span>
    </div>
    <div v-if="item.reasonType" class="mb-1">
      <label>Reason: </label>
      <span>{{ item.reasonType }}</span>
    </div>
    <div v-if="item.reasonDescription" class="mb-1">
      <label>Description: </label>
      <span>{{ item.reasonDescription }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import CancelAssignedJobContractorModel from '@/models/requests/CancelAssignedJobContractorModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import storeGetters from '@/storeGetters'

@Component
export default class CancelAssignedJobContractorLog extends Vue {
  @Prop() private requestBody: any

  private get item(): CancelAssignedJobContractorModel {
    return Object.assign(new CancelAssignedJobContractorModel(), this.requestBody)
  }

  private get getCancelAssignedJobContractorDetail(): ContractorAppointedModel {
    return storeGetters.getContractorAppointedDetailById(this.item.jobId, this.item.id)
  }
}
</script>

<style scoped></style>
