import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import { AxiosResponse } from 'axios'
import ClientTemplateReleaseModel from '@/models/client/ClientTemplateReleaseModel'
import ClientTemplateDeploymentModel from '@/models/client/ClientTemplateDeploymentModel'
import PublishClientTemplateRequestStatusModel from '@/models/client/PublishClientTemplateRequestStatusModel'
import UpdateClientTemplateRequestStatusModel from '@/models/client/UpdateClientTemplateRequestStatusModel'
import CreateNewClientTemplateRequestModel from '@/models/client/CreateNewClientTemplateRequestModel'
import PublishClientTemplateRequestModel from '@/models/client/PublishClientTemplateRequestModel'
import UpdateClientTemplateRequest from '@/models/client/UpdateClientTemplateRequest'
import InsurerPhoneNumberModel from '@/models/client/InsurerPhoneNumberModel'
import InsurerModel from '@/models/policy/InsurerModel'
import ClientJobModel from '@/models/client/ClientJobModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import Shared from '@/common/shared'
import CookieRecord from '@/models/cookies/cookie-record'
import eventBus from '@/common/bus'
export type ClientTemplateResponseCode = 'Success' | 'BadRequest' | 'Conflict' | 'Error'

export default class InsurerController {
  public static async CreateNewClientTemplate(
    req: CreateNewClientTemplateRequestModel
  ): Promise<ClientTemplateResponseCode> {
    const res: AxiosResponse<boolean | null> = await CallCentreApi.post<boolean>('Insurer/CreateNewClientTemplate', req)

    if (res.status === 400) {
      return 'BadRequest'
    } else if (res.status === 409) {
      return 'Conflict'
    } else if (res.status === 200 && res.data) {
      return 'Success'
    } else {
      return 'Error'
    }
  }

  public static async UpdateClientTemplate(
    req: UpdateClientTemplateRequest
  ): Promise<boolean> {
    const res = await CallCentreApi.post<boolean>('Insurer/UpdateClientTemplate', req)
    return res && res.status === 200 && res.data;
  }

  public static async PublishClientTemplate(req: PublishClientTemplateRequestModel): Promise<string | null> {
    const res = await CallCentreApi.post<string>('Insurer/PublishClientTemplate', req)
    if (res && res.status === 200) {
      return res.data
    }
    return null
  }

  public static async GetClientTemplateDeployments(clientTemplateId: string): Promise<ClientTemplateDeploymentModel[]> {
    const res: AxiosResponse<ClientTemplateDeploymentModel[]> = await CallCentreApi.get(
      'Insurer/GetClientTemplateDeployments?id=' + encodeURIComponent(clientTemplateId)
    )
    if (res && res.status === 200) {
      return res.data
    }
    return []
  }

  public static async GetClientTemplateReleaseHistory(clientTemplateId: string): Promise<ClientTemplateReleaseModel[]> {
    const res: AxiosResponse<ClientTemplateReleaseModel[]> = await CallCentreApi.get(
      'Insurer/GetClientTemplateReleaseHistory?id=' + encodeURIComponent(clientTemplateId)
    )
    if (res.status === 200) {
      return res.data
    }
    return []
  }

  public static async GetPublishClientTemplateRequestStatus(
    id: string
  ): Promise<PublishClientTemplateRequestStatusModel | null> {
    const res: AxiosResponse<PublishClientTemplateRequestStatusModel> = await CallCentreApi.get(
      'Insurer/GetPublishClientTemplateRequestStatus?id=' + encodeURIComponent(id)
    )
    if (res && res.status === 200) {
      return res.data
    }
    return null
  }

  public static async GetUpdateClientTemplateRequestStatus(
    id: string
  ): Promise<UpdateClientTemplateRequestStatusModel | null> {
    const res: AxiosResponse<UpdateClientTemplateRequestStatusModel> = await CallCentreApi.get(
      'Insurer/GetUpdateClientTemplateRequestStatus?id=' + encodeURIComponent(id)
    )
    if (res && res.status === 200) {
      return res.data
    }
    return null
  }

    public static async GetClientTemplate(id: string): Promise<ClientTemplateModel | null> {
        const res = await CallCentreApi.get('Insurer/GetClientTemplate?id=' + encodeURIComponent(id));
        return res.status === 200 ? res.data : null;
    }

    public static async GetClientTemplateByInsurer(insurerId: number): Promise<ClientTemplateModel[]> {
        const res = await CallCentreApi.get('Insurer/GetClientTemplateByInsurer?insurerId=' + insurerId);
        return res.status === 200 ? res.data : [];
    }

  public static async GetInsurerPhoneNumbers(): Promise<InsurerPhoneNumberModel[]> {
    const res = await CallCentreApi.get<InsurerPhoneNumberModel[]>('Insurer/GetInsurerPhoneNumbers')
    if (res.status === 200) {
      const result = res.data as InsurerPhoneNumberModel[]
      if (!result) {
        return []
      }
      return result
    } else {
      return []
    }
  }

  public static async SaveInsurerPhoneNumber(
    oldPhoneNumber: string,
    insurerPhoneNumber: InsurerPhoneNumberModel
  ): Promise<boolean> {
    const res = await CallCentreApi.post<InsurerPhoneNumberModel>(
      'Insurer/SaveInsurerPhoneNumber?oldPhoneNumber=' + encodeURIComponent(oldPhoneNumber),
      insurerPhoneNumber
    )
    return res.status >= 200 && res.status < 300
  }

  public static async DeleteInsurerPhoneNumber(phoneNumber: string): Promise<boolean> {
    const res = await CallCentreApi.delete(
      'Insurer/DeleteInsurerPhoneNumber?phoneNumber=' + encodeURIComponent(phoneNumber)
    )
    return res.status >= 200 && res.status < 300
  }

  public static async GetInsurerList(): Promise<InsurerModel[]> {
    const res = await CallCentreApi.get<InsurerModel[]>('Insurer/GetInsurerList')
    return res.data ? Shared.SortData(res.data, 'name') : []
  }

  public static async SaveInsurer(insurerModel: InsurerModel): Promise<InsurerModel> {
    const res = await CallCentreApi.post<InsurerModel>('Insurer/SaveInsurer', insurerModel)
    return res.data
  }

  public static async DeleteInsurer(insurerId: number): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete('Insurer/DeleteInsurer?insurerId=' + insurerId)
    return res.status >= 200 && res.status < 300
  }

  public static async GetInsurerById(insurerId: number): Promise<InsurerModel | null> {
    const res: AxiosResponse = await CallCentreApi.get('Insurer/GetInsurerById?insurerId=' + insurerId)
    if (res && res.status === 200) {
      return res.data
    }
    return null
  }

  public static async GetAllClientJobs(
    insurerId: number,
    visitType = '',
    recordCount = 0,
    status = ''
  ): Promise<ClientJobModel | null> {
    const res: AxiosResponse<ClientJobModel | null> = await CallCentreApi.get<ClientJobModel | null>(
      'Insurer/GetAllClientJobs?insurerId=' +
        insurerId +
        '&recordCount=' +
        recordCount +
        '&status=' +
        status +
        '&visitType=' +
        visitType
    )
    return res.data
  }

  public static async GetSearchedClientJob(
    insurerId: number,
    jobId: string,
    visitType: string
  ): Promise<ContractorAppointedModel[]> {
    const res: AxiosResponse<ContractorAppointedModel[]> = await CallCentreApi.get<ContractorAppointedModel[]>(
      'Insurer/GetSearchedClientJob?insurerId=' + insurerId + '&jobId=' + jobId + '&visitType=' + visitType
    )
    if (res.status === 200) {
      const result = res.data as ContractorAppointedModel[]
      return result ? result : []
    } else {
      return []
    }
  }

  public static async RetrieveCookieRecords(): Promise<CookieRecord[]> {
    try {
      const res: AxiosResponse<CookieRecord[]> = await CallCentreApi.get('Insurer/RetrieveCookieRecords')
      return res && res.status === 200 ? res.data : []
    } catch (err) {
      eventBus.$emit('errorHandler', err, true)
      return []
    }
  }

  public static async UpsertCookieRecord(record: CookieRecord): Promise<CookieRecord> {
    try {
      const res: AxiosResponse<CookieRecord> = await CallCentreApi.post('Insurer/UpsertCookieRecord', record)
      return res && res.status === 200 ? res.data : new CookieRecord()
    } catch (err) {
      eventBus.$emit('errorHandler', err, true)
      return new CookieRecord()
    }
  }
}
