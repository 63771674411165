<template>
  <div class="dashboard">
    <v-container fluid class="pa-0">
      <v-layout wrap>
        <v-flex sm12 class="gridView">
          <v-layout wrap>
            <v-flex xs12>
              <v-layout>
                <v-flex pr-3 class="filter-date">
                  <v-autocomplete
                    v-model="searchDirectory"
                    :items="directories"
                    label="Select Month"
                    class="directory-list"
                    @change="onDirectoryChange"
                  ></v-autocomplete>
                </v-flex>
                <v-flex pr-3 class="filter-date">
                  <DateTimePicker
                    ref="dateTimePickerFrom"
                    v-model="searchFromDateTime"
                    :date-time.sync="searchFromDateTime"
                    :is-static-location="false"
                    place-holder-text="From"
                    :is-current-time="false"
                    :is-validation-required="false"
                    :show-present-time-icon="false"
                    :min-date="minDate"
                    :max-date="maxDate"
                  />
                  <span v-if="searchFromDateTime" class="cleardate clear-from-date" @click="clearFromDate">
                    <v-icon>close</v-icon>
                  </span>
                </v-flex>
                <v-flex px-3 class="filter-date">
                  <DateTimePicker
                    ref="dateTimePickerTo"
                    v-model="searchToDateTime"
                    :date-time.sync="searchToDateTime"
                    :is-static-location="false"
                    place-holder-text="To"
                    :is-current-time="false"
                    :is-validation-required="false"
                    :show-present-time-icon="false"
                    :min-date="minSearchToDate"
                    :max-date="maxDate"
                  />
                  <span v-if="searchToDateTime" class="cleardate clear-to-date" @click="clearToDate">
                    <v-icon>close</v-icon>
                  </span>
                </v-flex>
                <v-flex px-3>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    name="search-value"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 mt-2>
              <v-data-table
                :headers="headers"
                :items="unlinkedPhoneCallsItems"
                :loading="isLoading"
                :search="searchCriteria"
                :custom-filter="customFilter"
                item-key="name"
                class="unlinked-calls-table elevation-1"
                :pagination.sync="paginationLocal"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="recording-img text-center">
                      <img :src="getFileExtention(props.item.recordingId)" class="img-responsive mt-1" />
                    </td>
                    <td>{{ getFormattedDateTime(props.item.callDateTime) }}</td>
                    <td>{{ props.item.from }}</td>
                    <td>{{ props.item.to }}</td>
                    <td>{{ calculateMinutes(props.item.duration) }}</td>
                    <td class="text-xs-center">
                      {{ props.item.policyScheduleName ? props.item.policyScheduleName : '-' }}
                    </td>
                    <td class="text-xs-center">
                      <v-tooltip v-if="canPlayCall" top>
                        <template #activator="{ on }">
                          <v-btn icon class="play-call" v-on="on" @click.native="playAudio(props.item.recordingUrl)">
                            <v-icon color="primary">play_circle_outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Play</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn icon class="assign-job" v-on="on" @click.native="openAssignPopup(props.item)">
                            <v-icon color="primary">work_outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Assign</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            class="mark-as-noise"
                            v-on="on"
                            @click.native="markAsNoiseButtonPress(props.item)"
                          >
                            <v-icon color="primary">phonelink_erase</v-icon>
                          </v-btn>
                        </template>
                        <span>Mark as Noise</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-if="phonecallAssign"
      v-model="phonecallAssign"
      content-class="v-dialog--scrollable"
      max-width="650"
      persistent
    >
      <PhoneCallJobsAssign
        :job-item-list="jobList"
        :unlinked-phone-call="unlinkedPhoneCallDetail"
        :is-data-loaded="isDataLoaded"
        @onJobAssignClose="closeJobAssign"
      ></PhoneCallJobsAssign>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DashboardController from '@/api/dashboardController'
import UnlinkedPhoneCallsModel from '@/models/claim/UnlinkedPhoneCallsModel'
import moment, { Moment } from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'
import PhoneCallJobsAssign from '@/components/PhoneCallJobsAssign.vue'
import JobHeader from '@/models/JobHeader'
import DateTimePicker from '@/components/DateTimePicker.vue'
import DocumentController from '@/api/documentController'
import eventBus from '@/common/bus'

@Component({
  name: 'UnlinkedPhoneCallsTable',
  components: { PhoneCallJobsAssign, DateTimePicker },
})
export default class UnlinkedPhoneCallsTable extends Vue {
  @Prop() private tableName: string
  private isLoading: boolean
  private headers: any[]
  private search = ''
  private paginationLocal: any = {}
  private phonecallAssign = false
  private dataItems: any = []
  private showAudio = false
  private jobList: JobHeader[] = []
  private unlinkedPhoneCallDetail: UnlinkedPhoneCallsModel | null = null
  private isDataLoaded = false
  private unlinkedPhoneCallsItems: UnlinkedPhoneCallsModel[] = []
  private searchFromDateTime: moment.Moment | null = null
  private searchToDateTime: moment.Moment | null = null
  private searchCriteria: any = {}
  private searchDirectory = ''
  private directories: string[] = []
  private minDate = ''
  private maxDate = ''
  private minSearchToDate = ''
  private tempUnlinkedPhoneCallsItems: UnlinkedPhoneCallsModel[] = [] // temp variable to manage UTC time for data

  private created() {
    this.headers = [
      { text: '', value: 'recordingId', sortable: false, align: 'center' },
      { text: 'Date & time', value: 'callDateTime', align: 'left' },
      { text: 'From number', value: 'from', align: 'left' },
      { text: 'To number', value: 'to', align: 'left' },
      { text: 'Duration', value: 'duration', align: 'left' },
      {
        text: 'Policy Schedule Name',
        value: 'policyScheduleName',
        align: 'center',
        class: 'policy-schedule-name',
      },
      { text: 'Actions', sortable: false, value: 'action', align: 'center' },
    ]
    this.paginationLocal.page = 1
    this.paginationLocal.rowsPerPage = Shared.rowsPerPageDefault
    this.getUnlinkedCallDirectories()
    this.getUnlinkedPhoneCallsDashboardData('')
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  // Calculate and fomate minutes and second
  private calculateMinutes(second: number): string {
    if (second) {
      const minutes = Math.floor(second / 60)
      const seconds = second - minutes * 60

      const calculatedMinutes = Shared.getMinutesFromSecond(minutes, '0', 2)
      const calculatedSeconds = Shared.getMinutesFromSecond(seconds, '0', 2)
      const calculatedDuration = calculatedMinutes + ':' + calculatedSeconds

      return calculatedDuration
    }
    return ''
  }

  private markAsNoise(unlinkedPhoneCallDetails: UnlinkedPhoneCallsModel): void {
    DashboardController.MarkAsNoise(unlinkedPhoneCallDetails.recordingId, unlinkedPhoneCallDetails.recordingUrl)
      .then((res: boolean) => {
        if (res) {
          const index = this.unlinkedPhoneCallsItems.indexOf(unlinkedPhoneCallDetails)
          this.unlinkedPhoneCallsItems.splice(index, 1)
          // remove item from tempUnlinkedPhoneCallsItems variable
          const tempCallItemIndex = this.tempUnlinkedPhoneCallsItems.indexOf(unlinkedPhoneCallDetails)
          this.tempUnlinkedPhoneCallsItems.splice(tempCallItemIndex, 1)
        }
      })
      .catch((error) => {
        // do nothing
      })
  }

  private markAsNoiseButtonPress(unlinkedPhoneCallDetails: UnlinkedPhoneCallsModel) {
    Shared.confirmationPopup.open(
      'Are you sure you want to mark this call as noise?',
      '',
      '',
      '',
      'Mark as Noise',
      this,
      'markAsNoise',
      unlinkedPhoneCallDetails
    )
  }

  private playAudio(recordingUrl: string): void {
    if (!recordingUrl) {
      return
    }

    DocumentController.GetSASTokenForRecordingUrl(recordingUrl)
      .then((url: string | null) => {
        if (url) {
          window.open(url, '_blank')
        }
      })
      .catch((error) => {
        // do nothing
      })
  }

  private openAssignPopup(unlinkedPhoneCallDetails: UnlinkedPhoneCallsModel) {
    if (!unlinkedPhoneCallDetails) {
      return
    }

    this.isDataLoaded = false
    this.phonecallAssign = true
    this.jobList = []
    // find call detail from tempUnlinkedPhoneCallsItems to get callDteTime in UTC
    const unlinkedPhoneCallItem = this.tempUnlinkedPhoneCallsItems.find(
      (c) => c.recordingId === unlinkedPhoneCallDetails.recordingId
    )
    if (unlinkedPhoneCallItem) {
      this.unlinkedPhoneCallDetail = unlinkedPhoneCallItem
    }
    DashboardController.GetJobsData(unlinkedPhoneCallDetails.from, unlinkedPhoneCallDetails.to)
      .then((res: JobHeader[] | null) => {
        if (res) {
          this.jobList = res
        }
        this.isDataLoaded = true
      })
      .catch((err: any) => {
        this.isDataLoaded = true
        eventBus.$emit('errorHandler', 'Error loading job list, please try again', true)
      })
  }

  private closeJobAssign(IsJobAssigned: boolean, recordingId: string) {
    this.jobList = []
    this.unlinkedPhoneCallDetail = null
    this.phonecallAssign = false

    if (IsJobAssigned && recordingId) {
      const index = this.unlinkedPhoneCallsItems.map((item) => item.recordingId).indexOf(recordingId)
      this.unlinkedPhoneCallsItems.splice(index, 1)
      // remove item from tempUnlinkedPhoneCallsItems variable
      const tempCallItemIndex = this.tempUnlinkedPhoneCallsItems.map((item) => item.recordingId).indexOf(recordingId)
      this.tempUnlinkedPhoneCallsItems.splice(tempCallItemIndex, 1)
    }
  }

  private assignJob() {
    this.jobList = []
    if (this.unlinkedPhoneCallDetail) {
      const index: number = this.unlinkedPhoneCallsItems.indexOf(this.unlinkedPhoneCallDetail)
      if (index !== -1) {
        this.unlinkedPhoneCallsItems.splice(index, 1)
      }
      // remove item from tempUnlinkedPhoneCallsItems variable
      const tempCallItemIndex = this.tempUnlinkedPhoneCallsItems.indexOf(this.unlinkedPhoneCallDetail)
      if (tempCallItemIndex !== -1) {
        this.tempUnlinkedPhoneCallsItems.splice(tempCallItemIndex, 1)
      }
    }

    this.unlinkedPhoneCallDetail = null
    this.phonecallAssign = false
  }

  private getFileExtention(recordingId: string): string {
    if (recordingId) {
      const recordingIdLower = recordingId.toLowerCase()
      const wavFileExtension = 'wav'
      const mp3FileExtension = 'mp3'
      const wavFile = recordingIdLower.includes('.' + wavFileExtension)
      const mp3File = recordingIdLower.includes('.' + mp3FileExtension)

      if (wavFile === true) {
        return '/img/' + wavFileExtension + '.svg'
      } else if (mp3File === true) {
        return '/img/' + mp3FileExtension + '.svg'
      }
    }
    return ''
  }

  private customFilter(items, search, filter) {
    const attributes = 'item'
    let filteredFromTimeItems: UnlinkedPhoneCallsModel[] = []
    // filter with from date
    if (this.searchFromDateTime) {
      items =
        items && items.length > 0 ? items.filter((x) => x.callDateTime.isSameOrAfter(this.searchFromDateTime)) : []
      filteredFromTimeItems = items
    }
    // filter with to date
    if (this.searchToDateTime) {
      const searchToDateTime: moment.Moment = this.searchToDateTime
      // if to date selected and (from date not selected or has no data)
      if (filteredFromTimeItems.length === 0 || !this.searchFromDateTime) {
        items = items.filter((x) => x.callDateTime.isSameOrBefore(searchToDateTime))
      } else if (filteredFromTimeItems.length > 0) {
        // if date to selected and date from has data
        // save traversing of entier list again
        items = filteredFromTimeItems.filter((x) => x.callDateTime.isSameOrBefore(searchToDateTime))
      }
    }

    // if filtered with search bar
    if (this.search !== '') {
      this.search = this.search.toString().toLowerCase()
      return items.filter(
        (row) =>
          filter(this.getFormattedDateTime(row.callDateTime), this.search) ||
          filter(row.from, this.search) ||
          filter(row.to, this.search) ||
          filter(this.calculateMinutes(row.duration), this.search) ||
          filter(row.recordingId, this.search) ||
          filter(row.policyScheduleName, this.search)
      )
    } else {
      return items
    }
  }

  private get canPlayCall() {
    return Store.Instance.state.SessionDetail.canPlayCall
  }

  private clearFromDate() {
    this.searchFromDateTime = null
  }

  private clearToDate() {
    this.searchToDateTime = null
  }

  private getUnlinkedCallDirectories() {
    DashboardController.GetUnlinkedCallDirectories()
      .then((res: string[] | null) => {
        if (res && res.length > 0) {
          this.directories = res
          const date = new Date()
          this.searchDirectory = date.toLocaleString('default', { month: 'short' }) + '-' + date.getFullYear()
          const index = this.directories.findIndex((d) => d === this.searchDirectory)
          if (index === -1) {
            this.searchDirectory = ''
          }
          if (this.searchDirectory !== '') {
            this.setMinMaxDate(this.searchDirectory)
          }
        } else {
          this.directories = []
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading directory list, please try again', true)
      })
  }

  private onDirectoryChange($event: string) {
    if ($event) {
      this.searchFromDateTime = null
      this.searchToDateTime = null
      this.search = ''
      this.getUnlinkedPhoneCallsDashboardData($event)
    }
  }

  // Get Unlinked Phone Calls Data
  private getUnlinkedPhoneCallsDashboardData(searchDirectory: string) {
    this.isLoading = true
    if (searchDirectory !== '') {
      this.setMinMaxDate(searchDirectory)
    }
    DashboardController.GetUnlinkedPhoneCallsDashboardData(searchDirectory)
      .then((res: UnlinkedPhoneCallsModel[] | null) => {
        if (res) {
          this.tempUnlinkedPhoneCallsItems = JSON.parse(JSON.stringify(res))
          this.unlinkedPhoneCallsItems = JSON.parse(JSON.stringify(res))
          // updated UTC to BST directly into the response to avoid errors on date filters
          this.unlinkedPhoneCallsItems.forEach((item) => {
            item.callDateTime = moment(new Date(Shared.getDateTimeInBSTZone(moment(item.callDateTime))))
          })
        } else {
          this.unlinkedPhoneCallsItems = []
          this.tempUnlinkedPhoneCallsItems = []
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading unlinked phone call data, please try again', true)
        this.isLoading = false
      })
  }

  private setMinMaxDate(selectedDirectory: string) {
    const startDate = '01-' + selectedDirectory
    const endDate = this.getMonthEndDate(selectedDirectory) + selectedDirectory + ' 23:59'
    this.minDate = moment(new Date(startDate)).format(DateTimePicker.DATE_FORMAT)
    this.maxDate = moment(new Date(endDate)).format(DateTimePicker.DATE_TIME_FORMAT)
    this.searchFromDateTime = moment(new Date(startDate))
    this.searchToDateTime = moment(new Date(endDate))
  }

  private getMonthEndDate(monthYearValue: string) {
    // this function will return the last date of the month
    monthYearValue = '1 ' + monthYearValue // return date object
    const date = new Date(monthYearValue)
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  @Watch('searchFromDateTime')
  private onSearchFromDateTimeChange() {
    this.minSearchToDate = this.searchFromDateTime
      ? moment(this.searchFromDateTime).format(DateTimePicker.DATE_FORMAT)
      : ''
    this.searchToDateTime =
      this.searchFromDateTime && this.searchToDateTime && this.searchFromDateTime > this.searchToDateTime
        ? this.searchFromDateTime
        : this.searchToDateTime
  }
}
</script>

<style scoped>
.gridView >>> .v-table thead > tr > th:first-child {
  min-width: 100px !important;
}
.gridView >>> .v-table thead > tr > th:last-child {
  min-width: 150px !important;
}
.gridView {
  border: 0px;
}
.recording-img img {
  width: 35px;
}
.scroll-content-dialog {
  max-height: 400px;
}
.filter-date {
  position: relative;
}
.filter-date .cleardate {
  position: ABSOLUTE;
  top: 0;
  right: 50px;
  top: 20px;
  cursor: pointer;
}
.unlinked-calls-table >>> table.v-table thead th.policy-schedule-name {
  width: 200px !important;
}
</style>
