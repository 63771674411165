<template>
  <div>
    <div class="mb-2"><h4>Update job note call recording URL</h4></div>
    <div v-if="item.noteId" class="mb-1">
      <label>Note Id: </label>
      <span>{{ item.noteId }}</span>
    </div>
    <div v-if="item.callRecordingUrl" class="mb-1">
      <label>Call recording URL: </label>
      <span>{{ item.callRecordingUrl }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateJobNoteCallRecordingUrl from '@/models/requests/UpdateJobNoteCallRecordingUrl'

@Component
export default class UpdateJobNoteCallRecordingUrlLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateJobNoteCallRecordingUrl {
    return Object.assign(new UpdateJobNoteCallRecordingUrl(), this.requestBody)
  }
}
</script>
