export default class ClientFixedFeeRateModel {
  public berIncluded: boolean
  public alternativeAccommodationIncluded: boolean
  public materialsIncluded: boolean
  public heatersIncluded: boolean
  public materialUplift: number

  public constructor() {
    this.materialUplift = 0
    this.berIncluded = false
    this.alternativeAccommodationIncluded = false
    this.materialsIncluded = false
    this.heatersIncluded = false
  }
}
