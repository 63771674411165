import Product from '@/models/siteInvestigation/Product'
import PitTest from '@/models/siteInvestigation/PitTest'

export default class Pit extends Product {
  public pitId = ''
  public boreDepth: number | null = null
  public pitTests: PitTest[] = []

  constructor(product?: Product) {
    super(product)
  }
}
