import SIReportModel from '@/models/claim/SIReportModel'
import CosmosJobsDbRecordBase from '../CosmosJobsDbRecordBase'

export default class SIDashboardReportModel extends CosmosJobsDbRecordBase {
  public siReportItems: SIReportModel[]
  public totalNewJobsCount: number
  public totalCustomerNotifiedJobsCount: number
  public totalVisitBookedJobsCount: number
  public totalTestingJobsCount: number
  public totalReportProvidedJobsCount: number
  public totalMonitoringJobsCount: number

  public constructor() {
    super()
    this.siReportItems = []
    this.totalNewJobsCount = 0
    this.totalCustomerNotifiedJobsCount = 0
    this.totalVisitBookedJobsCount = 0
    this.totalTestingJobsCount = 0
    this.totalReportProvidedJobsCount = 0
    this.totalMonitoringJobsCount = 0
  }
}
