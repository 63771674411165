import { AxiosError, AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { AssistedPayCaptureType } from '@/common/enums'
import AssistedPayResponse from '@/api/models/assistedPay/AssistedPayResponse'

//see -> https://www.twilio.com/docs/voice/api/payment-resource?code-sample=code-update-a-pay-session&code-language=C%23&code-sdk-version=6.x
export default class AgentAssistController {
  public static async Initiate(callSid: string): Promise<AssistedPayResponse> {
    try {
      const res: AxiosResponse = await CallCentreApi.post('AgentAssist/Initiate?callSid=' + callSid, null)

      const response: AssistedPayResponse = {
        success: res.status >= 200 && res.status < 300,
        payload: res.data,
      }

      return response
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response && err.response.data && err.response.data.ExceptionMessage) {
          return {
            success: false,
            payload: err.response.data.ExceptionMessage,
          }
        }
      }

      throw err
    }
  }

  public static async Update(
    callSid: string,
    paymentSid: string,
    captureType: AssistedPayCaptureType
  ): Promise<AssistedPayResponse> {
    const model = {
      callSid,
      paymentSid,
      captureType,
    }
    try {
      const res: AxiosResponse = await CallCentreApi.post('AgentAssist/Update', model)

      const response: AssistedPayResponse = {
        success: res.status >= 200 && res.status < 300,
        payload: res.data as string,
      }

      return response
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response && err.response.data && err.response.data.ExceptionMessage) {
          return {
            success: false,
            payload: err.response.data.ExceptionMessage,
          }
        }
      }

      throw err
    }
  }

  public static async Complete(callSid: string, paymentSid: string): Promise<AssistedPayResponse> {
    const model = {
      callSid,
      paymentSid,
    }
    try {
      const res: AxiosResponse = await CallCentreApi.post('AgentAssist/Complete', model)

      const response: AssistedPayResponse = {
        success: res.status >= 200 && res.status < 300,
        payload: res.data as string,
      }

      return response
    } catch (err) {
      const message =
        err instanceof AxiosError && err.response && err.response.data && err.response.data.ExceptionMessage
          ? err.response.data.ExceptionMessage
          : 'Failed to confirm payment details. Please try again'
      return {
        success: false,
        payload: message,
      }
    }
  }

  public static async Cancel(callSid: string, paymentSid: string): Promise<AssistedPayResponse> {
    const model = {
      callSid,
      paymentSid,
    }

    try {
      const res: AxiosResponse = await CallCentreApi.post('AgentAssist/Cancel', model)

      const response: AssistedPayResponse = {
        success: res.status >= 200 && res.status < 300,
        payload: res.data as string,
      }

      return response
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response && err.response.data && err.response.data.ExceptionMessage) {
          return {
            success: false,
            payload: err.response.data.ExceptionMessage,
          }
        }
      }

      throw err
    }
  }
}
