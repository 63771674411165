<template>
  <div>
    <div class="mb-2"><h4>Remove Emergency From Job</h4></div>
    <div v-if="item.typeDescription" class="mb-1">
      <label>Emergency: </label>
      <span>{{ item.typeDescription }}</span>
    </div>
    <div v-if="item.detailDescription" class="mb-1">
      <label>Emergency Detail: </label>
      <span>{{ item.detailDescription }}</span>
    </div>
    <div v-if="removeEmergencyReason" class="mb-1">
      <label>Reason: </label>
      <span>{{ removeEmergencyReason }}</span>
    </div>
    <div v-if="item.description" class="mb-1">
      <label>Reason Description: </label>
      <span>{{ item.description }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import RemoveEmergencyFromJobModel from '@/models/claim/RemoveEmergencyFromJobModel'
import MasterRecord from '@/models/MasterRecord'

@Component
export default class RemoveEmergencyFromJobLog extends Vue {
  @Prop() private requestBody: any

  private get item(): RemoveEmergencyFromJobModel {
    return Object.assign(new RemoveEmergencyFromJobModel(), this.requestBody)
  }

  private get removeEmergencyFromJobReasons(): MasterRecord[] {
    const masterRecords: MasterRecord[] = this.$store.getters['jobModule/masterRecords']
    return masterRecords.filter((x) => x.type === 'RemoveEmergencyFromJobReason')
  }

  private removeEmergencyReason(reasonId: string) {
    const reason = this.removeEmergencyFromJobReasons.find((c: MasterRecord) => c.id === reasonId)
    return reason ? reason.description : ''
  }
}
</script>
