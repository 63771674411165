<template>
  <div>
    <div class="mb-2"><h4>Engineer Visit Complete</h4></div>
    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="getReachedOnSiteTime" class="mb-1">
      <label>Reached on Site at: </label>
      <span>{{ getReachedOnSiteTime(item.timeOnSite) }}</span>
    </div>
    <div v-if="getVisitCompletedTime" class="mb-1">
      <label>Visit Completed at: </label>
      <span>{{ getVisitCompletedTime(item.visitCompleted) }}</span>
    </div>
    <div v-if="item.customerSignatureUrl" class="mb-1">
      <label>Customer Signature URL: </label>
      <span>{{ item.customerSignatureUrl }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddEngineerVisitCompleteModel from '@/models/requests/AddEngineerVisitCompleteModel'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class AddEngineerVisitCompleteLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddEngineerVisitCompleteModel {
    return Object.assign(new AddEngineerVisitCompleteModel(), this.requestBody)
  }

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.item.emergencyId)
  }

  private getReachedOnSiteTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getVisitCompletedTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
