<template>
  <div class="dashboard">
    <v-layout wrap>
      <v-container fluid pr-2 pl-0 pt-1>
        <div wrap class="text-right insurer-search claim-mgmt-company-dropdown">
          <v-autocomplete
            v-model="selectedClaimManagementCompany"
            :items="claimManagementCompanies"
            item-text="description"
            item-value="id"
            hide-details
            label="Search Claim Management Company"
            :loading="loading.claimManagementCompanies"
            class="pr-3 claim-management-company-select"
          ></v-autocomplete>
          <v-autocomplete
            v-model="selectedInsurerId"
            label="Search Insurer"
            class="insurer-select"
            :items="insurers"
            :loading="loading.insurers"
            item-text="name"
            item-value="id"
            hide-details
            :menu-props="{
              maxWidth: '30%',
              minWidth: '30%',
            }"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile-content>
                <v-list-tile-title :class="{ 'bold-select': data.item.id === -1, 'deleted-item': data.item.isDelete }">
                  {{ data.item.name }}
                </v-list-tile-title>
              </v-list-tile-content>
            </template>
          </v-autocomplete>
        </div>
      </v-container>
      <v-flex class="mt-5">
        <v-container fluid pr-2 pl-0 pt-1>
          <v-layout wrap>
            <v-flex>
              <JobFinancialSummaryDashboardSI
                v-if="insurers.length > 0 && claimManagementCompanies.length > 0"
                :selected-insurer-id="selectedInsurerId"
                :claims-management-company-sales-ledgers="claimsManagementCompanySalesLedgers"
                :selected-claim-management-company="selectedClaimManagementCompany"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import JobFinancialSummaryDashboardSI from '@/views/JobFinancialSummaryDashboardSI.vue'
import InsurerModel from '@/models/policy/InsurerModel'
import InsurerController from '@/api/InsurerController'
import ClaimManagementCompanyModel from '@/models/ClaimManagementCompanyModel'
import SiteInvestigationController from '@/api/siteInvestigationController'
import eventBus from '@/common/bus'
import { ClaimsManagementCompanySalesLedger } from '@/common/interfaces'

@Component({ components: { JobFinancialSummaryDashboardSI } })
export default class ClientInvoiceDashboardSI extends Vue {
  private selectedInsurerId: number | null = null
  private insurers: InsurerModel[] = []
  private claimsManagementCompanySalesLedgers: ClaimsManagementCompanySalesLedger[] = []
  private selectedClaimManagementCompany = -1
  private claimManagementCompanies: ClaimManagementCompanyModel[] = []
  private loading: object = {
    insurers: false,
    claimManagementCompanies: false,
  }

  private mounted() {
    this.getClaimManagementCompanies()
    this.getInsurers()
  }

  private async getInsurers() {
    this.$set(this.loading, 'insurers', true)
    try {
      const response = await InsurerController.GetInsurerList()
      if (response && response.length > 0) {
        this.insurers = response

        const addDummyLabel: InsurerModel = new InsurerModel()
        addDummyLabel.name = 'All'
        addDummyLabel.id = 0
        this.insurers.unshift(addDummyLabel)
        this.selectedInsurerId = this.insurers[0].id
      }
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading insurer list.', true)
    }
    this.$set(this.loading, 'insurers', false)
  }

  private async getClaimManagementCompanies() {
    this.$set(this.loading, 'claimManagementCompanies', true)
    try {
      const response = await SiteInvestigationController.GetClaimManagementCompanies()
      if (response && response.length > 0) {
        this.claimManagementCompanies = response

        this.claimManagementCompanies.forEach((company: ClaimManagementCompanyModel) => {
          this.claimsManagementCompanySalesLedgers.push({
            id: company.id,
            salesLedgerCodes: company.claimsManagementCompanySalesLedgers,
          })
        })
        const addDummyLabel: ClaimManagementCompanyModel = new ClaimManagementCompanyModel()
        addDummyLabel.description = 'All'
        addDummyLabel.id = 0
        this.claimManagementCompanies.unshift(addDummyLabel)
        this.selectedClaimManagementCompany = this.claimManagementCompanies[0].id
      }
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading claim management company list.', true)
    }
    this.$set(this.loading, 'claimManagementCompanies', false)
  }
}
</script>

<style scoped>
.insurer-search {
  position: absolute;
  right: 24px;
  top: 16px;
  min-width: 300px;
  width: 30%;
  z-index: 2;
}
.insurer-search.claim-mgmt-company-dropdown {
  min-width: 600px;
  width: 50%;
}
.insurer-search.claim-mgmt-company-dropdown >>> .v-select {
  width: 300px;
  display: inline-block;
}
</style>
