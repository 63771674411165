import moment from 'moment'

export default class AddUpdateDrainageReport {
  public id: string
  public jobId: string
  public templateId: number
  public name: string
  public htmlBody: string
  public updatedAt: moment.Moment | null
}
