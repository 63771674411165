import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import GasSafetyDetail from './GasSafetyDetailModel'

export default class BoilerBreakdownQA extends CosmosJobsDbRecordBase {
  public visitId: string
  public makeQuestion: string
  public makeAnswer: string
  public modelQuestion: string
  public modelAnswer: string
  public gcNumberQuestion: string
  public gcNumberAnswer: string
  public gasSafetyDetails: GasSafetyDetail | null  
  public installationYearQuestion: string
  public installationYearAnswer: string
  public yearOfLastServiceQuestion: string
  public yearOfLastServiceAnswer: string
}
