import moment from 'moment'

export default class AddSIRevisitRequest {
  public jobId: string
  public engineerVisitDetailId: string
  public returnVisitETAFrom: moment.Moment
  public returnVisitETATo: moment.Moment
  public returnVisitReason: string
  public returnVisitNote: string
  public engineerId: string
}
