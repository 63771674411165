import TimeSlot from '@/models/claim/TimeSlot'

export default class EngineerAvailabilityModel {
  public tradeId: number
  public description: string
  public timeSlot: TimeSlot[]
  public constructor() {
    if (!this.timeSlot) {
      this.timeSlot = []
    }
  }
}
