import { EngineerJobVisitStatus } from '@/common/enums'
import moment from 'moment'

export default class UpdateEngineerJobToComplete {
  public id: string
  public jobId: string
  public status: string
  public customerName: string
  public processAt: moment.Moment | null
  public visitNote: string

  public constructor() {
    this.processAt = null
  }
}
