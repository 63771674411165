<template>
  <v-card>
    <v-toolbar card dark color="primary">
      <v-toolbar-title>Emergency Question</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.native="closeEmergencyQuestionDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-3 pb-3 mt-2 scroll-content-dialog">
      <v-form autocomplete="off">
        <v-layout wrap>
          <v-flex v-if="getEmergencyQuestionModel.id" xs12>
            <v-alert :value="true" color="warning" icon="priority_high" outline class="border_zeroalert px-0 pb-1 pt-0">
              It is important that the meaning of the question is not altered.
            </v-alert>
          </v-flex>
          <v-flex v-if="hasValidation" xs12>
            <v-alert :value="true" color="warning" icon="priority_high" outline class="border_zeroalert px-0 pb-1 pt-0">
              Validation and additional Information may be affected on changing answer type and additional values.
            </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model.trim="getEmergencyQuestionModel.text"
              v-validate="'required|max:500'"
              label="Question"
              maxlength="500"
              required
              class="required"
              data-vv-scope="frmEmergencyQuestion"
              data-vv-name="Question"
              :error-messages="errors.collect('Question')"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="getEmergencyQuestionModel.answerTypeId"
              v-validate="'required'"
              :items="getAnswertype"
              label="Answertype"
              item-text="description"
              item-value="id"
              required
              class="required"
              data-vv-scope="frmEmergencyQuestion"
              data-vv-name="Answertype"
              :error-messages="errors.collect('Answertype')"
            ></v-select>
          </v-flex>
          <v-flex v-if="getEmergencyQuestionModel.answerTypeId === answerType.Dropdown" xs12>
            <v-textarea
              v-model.trim="getEmergencyQuestionModel.additionalValues"
              v-validate="'required|max:2000'"
              label="Additional Values"
              maxlength="2000"
              hint="Enter each option in separate line"
              required
              class="required"
              data-vv-scope="frmEmergencyQuestion"
              data-vv-name="Additional Values"
              :error-messages="errors.collect('Additional Values')"
            ></v-textarea>
          </v-flex>
          <v-flex v-if="getEmergencyQuestionModel.answerTypeId === answerType.Number" xs12>
            <v-text-field
              v-model.trim="getEmergencyQuestionModel.unit"
              v-validate="'max:20'"
              label="Unit"
              maxlength="20"
              data-vv-scope="frmEmergencyQuestion"
              data-vv-name="Unit"
              :error-messages="errors.collect('Unit')"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model.trim="getEmergencyQuestionModel.note"
              label="Note"
              hide-details
              rows="3"
              name="questionNote"
              maxlength="300"
            ></v-textarea>
          </v-flex>
          <v-flex v-if="jobType === 'HE'" xs12 mt-4>
            <AddEmergencyAgainstAnswer
              ref="addEmergencyAgainstAnswerRef"
              :get-emergency-question-model="getEmergencyQuestionModel"
              :third-party-detail="thirdPartyDetail"
            ></AddEmergencyAgainstAnswer>
          </v-flex>
          <v-flex xs12 mt-4>
            <EmergencyAssignTypesAgainstAnswer
              ref="tradeEmergencyAnswerComparison"
              title="Assign Trade Type based on Answer"
              emergency-answer-comparison-for="Trade"
              :get-emergency-question-model="getEmergencyQuestionModel"
              :trades="trades"
              :answer-type="answerType"
              add-button-tooltip-text="Add trade against answer"
              duplicate-question-error-message="Selected operator and comparison value pair already exist."
              @isHasDuplicateTrade="isHasDuplicateTrade"
            ></EmergencyAssignTypesAgainstAnswer>
          </v-flex>

          <v-flex xs12 mt-4>
            <EmergencyAssignTypesAgainstAnswer
              ref="asbestosEmergencyAnswerComparison"
              title="Assign Asbestos Risk based on Answer"
              emergency-answer-comparison-for="Asbestos"
              :get-emergency-question-model="getEmergencyQuestionModel"
              :answer-type="answerType"
              add-button-tooltip-text="Add Asbestos Risk against answer"
              duplicate-question-error-message="Selected operator and comparison value pair already exist."
              @isHasDuplicateAsbestos="isHasDuplicateAsbestos"
            ></EmergencyAssignTypesAgainstAnswer>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn flat="flat" @click.native="closeEmergencyQuestionDialog">Cancel</v-btn>
      <v-btn
        class="mr-0"
        color="primary"
        :disabled="isLoading"
        :loading="isLoading"
        @click.native="saveEmergencyQuestion"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import { AnswerType, OperatorType, AsbestosEffectLevel, JobType } from '@/common/enums'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import { Operator } from '@/common/interfaces'
import QuestionTradeAssignmentModel from '@/models/policy/QuestionTradeAssignmentModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import PolicyQuestionModel from '@/models/policy/PolicyQuestionModel'
import QuestionAsbestosEffectAssignmentModel from '@/models/policy/QuestionAsbestosEffectAssignmentModel'
import EmergencyAssignTypesAgainstAnswer from '@/components/EmergencyAssignTypesAgainstAnswer.vue'
import AddEmergencyAgainstAnswer from '@/components/AddEmergencyAgainstAnswer.vue'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'
import eventBus from '@/common/bus'

interface IAnswertype {
  id: number
  description: string
}

@Component({
  components: { EmergencyAssignTypesAgainstAnswer, AddEmergencyAgainstAnswer },
})
export default class EmergencyQuestion extends Vue {
  @Prop() private item: EmergencyQuestionModel
  @Prop() private trades: TradeModel[]
  @Prop() private submitedPolicyQuestionList: PolicyQuestionModel[]
  @Prop() private jobType: JobType
  @Prop() private thirdPartyDetail: EmergencyThirdPartyTrade[]
  private answerType = AnswerType
  private isLoading = false
  private hasDuplicateQuestionTrades = false
  private hasDuplicateQuestionAsbestosEffect = false
  private effectLevel = AsbestosEffectLevel

  private get getEmergencyQuestionModel(): EmergencyQuestionModel {
    return this.item
  }

  private get getAnswertype(): IAnswertype[] {
    const keys: any[] = Object.keys(this.answerType).filter(Number)
    const answerTypeItems: IAnswertype[] = []
    keys.forEach((element) => {
      const answerTypeEntity: any = {}
      answerTypeEntity.id = Number(element)
      answerTypeEntity.description = AnswerType[element]
      answerTypeItems.push(answerTypeEntity)
    })
    return answerTypeItems
  }

  private get hasValidation(): boolean {
    const policyQuestion: PolicyQuestionModel | undefined = this.submitedPolicyQuestionList.find(
      (e: PolicyQuestionModel) => e.questionId === this.getEmergencyQuestionModel.id
    )
    if (policyQuestion && (policyQuestion.requiredForValidation || policyQuestion.hasComment)) {
      return true
    } else {
      return false
    }
  }

  private closeEmergencyQuestionDialog() {
    if (this.getEmergencyQuestionModel.questionTrades && this.getEmergencyQuestionModel.questionTrades.length > 0) {
      for (let index = this.getEmergencyQuestionModel.questionTrades.length - 1; index >= 0; index--) {
        if (
          !this.getEmergencyQuestionModel.questionTrades[index].operatorId &&
          !this.getEmergencyQuestionModel.questionTrades[index].comparisonValue &&
          !this.getEmergencyQuestionModel.questionTrades[index].tradeId
        ) {
          this.getEmergencyQuestionModel.questionTrades.splice(index, 1)
        }
      }
    }

    if (
      this.getEmergencyQuestionModel.questionAsbestosEffects &&
      this.getEmergencyQuestionModel.questionAsbestosEffects.length > 0
    ) {
      for (let index = this.getEmergencyQuestionModel.questionAsbestosEffects.length - 1; index >= 0; index--) {
        if (
          !this.getEmergencyQuestionModel.questionAsbestosEffects[index].operatorId &&
          !this.getEmergencyQuestionModel.questionAsbestosEffects[index].comparisonValue &&
          !this.getEmergencyQuestionModel.questionAsbestosEffects[index].effectLevel
        ) {
          this.getEmergencyQuestionModel.questionAsbestosEffects.splice(index, 1)
        }
      }
    }
    this.$emit('closeEmergencyQuestion')
  }

  private saveEmergencyQuestion() {
    const self = this
    this.validate()
      .then((result: boolean) => {
        if (result && !self.hasDuplicateQuestionTrades && !self.hasDuplicateQuestionAsbestosEffect) {
          this.isLoading = true
          // set additionalValues as null if answer type is other than dropdown
          if (this.getEmergencyQuestionModel.answerTypeId !== AnswerType.Dropdown) {
            this.getEmergencyQuestionModel.additionalValues = null
          }

          // set unit as null if answer type is other than number
          if (this.getEmergencyQuestionModel.answerTypeId !== AnswerType.Number) {
            this.getEmergencyQuestionModel.unit = null
          }

          this.getEmergencyQuestionModel.operatorId = this.getEmergencyQuestionModel.conditionalEmergencyTypeId
            ? this.getEmergencyQuestionModel.operatorId
            : null
          this.getEmergencyQuestionModel.conditionalEmergencyDetailId = this.getEmergencyQuestionModel
            .conditionalEmergencyTypeId
            ? this.getEmergencyQuestionModel.conditionalEmergencyDetailId
            : null
          this.getEmergencyQuestionModel.comparisonValue = this.getEmergencyQuestionModel.conditionalEmergencyTypeId
            ? this.getEmergencyQuestionModel.comparisonValue
            : ''
          this.$emit('saveEmergencyQuestion', this.getEmergencyQuestionModel)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmEmergencyQuestion')
    // validate trade answers
    let validateTradeAnswer = true
    const subs1 = this.$refs.tradeEmergencyAnswerComparison as EmergencyAssignTypesAgainstAnswer
    validateTradeAnswer = await subs1.$validator.validateAll()
    // validate asbestos answers
    let validateAsbestosAnswer = true
    const subs2 = this.$refs.asbestosEmergencyAnswerComparison as EmergencyAssignTypesAgainstAnswer
    validateAsbestosAnswer = await subs2.$validator.validateAll()
    // validate emergency assignment
    let validateEmergencyAssignment = true
    if (this.jobType === 'HE') {
      const subs3 = this.$refs.addEmergencyAgainstAnswerRef as AddEmergencyAgainstAnswer
      validateEmergencyAssignment = await subs3.validate()
    }

    // set focus to non validate field
    if (!result || !validateTradeAnswer || !validateAsbestosAnswer || !validateEmergencyAssignment) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result && validateTradeAnswer && validateAsbestosAnswer && validateEmergencyAssignment
  }

  private isHasDuplicateTrade(duplicateInTrade: boolean) {
    return (this.hasDuplicateQuestionTrades = duplicateInTrade)
  }

  private isHasDuplicateAsbestos(duplicateInAsbestos: boolean) {
    return (this.hasDuplicateQuestionAsbestosEffect = duplicateInAsbestos)
  }
}
</script>

<style scoped>
.border_zeroalert.v-alert {
  border: 0px !important;
}
</style>
