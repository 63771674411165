<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    :nudge-width="300"
    :max-width="300"
    :z-index="999"
    fixed
    :position-y="56"
  >
    <template #activator="{ on }">
      <v-btn icon large class="notification-bell" v-on="on">
        <v-btn icon>
          <v-badge overlap color="secondary">
            <v-avatar :color="warningColor" :size="34">
              <v-icon dark small>warning</v-icon>
            </v-avatar>
          </v-badge>
        </v-btn>
        <span class="n-arrow"></span>
      </v-btn>
    </template>
    <v-card class="notification-menu">
      <div class="inner-content view-panel">
        <v-list two-line>
          <v-list-tile v-if="getIsVipCustomer && showHideWarning(job)">
            <v-list-tile-avatar color="blue" class="icon-img vip-customer" :size="32">
              <img src="/img/vip-customer.svg" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <span>
                <v-list-tile-sub-title>
                  <b class="blue--text">VIP Customer</b>
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                  <b class="grey--text text--lighten-1">
                    {{
                      (job && job.clientTitle ? job.clientTitle + ' ' : '') +
                      job.clientForename +
                      ' ' +
                      job.clientLastName
                    }}
                  </b>
                </v-list-tile-sub-title>
              </span>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-if="vulnerabilityQAItem && showHideWarning(vulnerabilityQAItem)">
            <v-list-tile-avatar :color="getColor(vulnerabilityQAItem)" class="icon-img" :size="32">
              <img src="/img/shield.svg" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <span>
                <v-list-tile-sub-title>
                  <b :class="getColor(vulnerabilityQAItem) + '--text'">Vulnerable Customer</b>
                </v-list-tile-sub-title>
              </span>
            </v-list-tile-content>
          </v-list-tile>
          <template v-for="(item, index) in healthAndSafetyQAItem">
            <v-list-tile v-if="item && showHideWarning(item)" :key="index">
              <v-list-tile-avatar :color="getColor(item)" class="icon-img" :size="32">
                <img src="/img/health-care.svg" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <span>
                  <v-list-tile-sub-title>
                    <b :class="getColor(item) + '--text'">Health and Safety Issue</b>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <b class="grey--text text--lighten-1">
                      {{ getEmergencyName(item.forEmergencyTypeId) }}
                    </b>
                  </v-list-tile-sub-title>
                </span>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <v-list-tile v-if="customerToPay && showHideWarning(customerToPay)">
            <v-list-tile-avatar color="blue" class="icon-img" :size="32">
              <img src="/img/autorized-customer.svg" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <span>
                <v-list-tile-sub-title>
                  <b class="blue--text">Authorized Customer</b>
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                  <b class="grey--text text--lighten-1">
                    {{ customerToPay.cardHolderName }}
                  </b>
                </v-list-tile-sub-title>
              </span>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-if="complaint && showHideWarning(complaint)">
            <v-list-tile-avatar :color="getSeverityColor(complaint)" :size="32">
              <v-icon dark small>announcement</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <span>
                <v-list-tile-sub-title>
                  <b :class="getSeverityColor(complaint) + '--text'">Complaint</b>
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                  <b v-if="getComplaintType(complaint)" class="grey--text text--lighten-1">
                    {{ getComplaintType(complaint) + ' - Severity ' + complaint.severity }}
                  </b>
                  <b v-else class="grey--text text--lighten-1">
                    {{ 'Severity ' + complaint.severity }}
                  </b>
                </v-list-tile-sub-title>
              </span>
            </v-list-tile-content>
          </v-list-tile>
          <template v-for="(item, index) in asbestosItems">
            <v-list-tile v-if="item" :key="index">
              <v-list-tile-avatar :color="getAsbestosEffectColor(item)" class="icon-img" :size="32">
                <img class="asbestos-icon" src="/img/asbestos.svg" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <span>
                  <v-list-tile-sub-title>
                    <b :class="getAsbestosEffectColor(item) + '--text'">Asbestos Issue</b>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <b v-if="!item.forEmergencyTypeId && !item.forEmergencyDetailId" class="grey--text text--lighten-1">
                      Policy Level
                    </b>
                    <b v-if="item.forEmergencyTypeId" class="grey--text text--lighten-1">
                      {{ getEmergencyName(item.forEmergencyTypeId) }}
                    </b>
                    <b v-if="item.forEmergencyDetailId" class="grey--text text--lighten-1">
                      {{ getEmergencyName(item.forEmergencyTypeId, item.forEmergencyDetailId) }}
                    </b>
                  </v-list-tile-sub-title>
                </span>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="escalationDetails && showHideWarning(escalationDetails)"
            class="escalation-Details"
            @click="openJobEscalationCard"
          >
            <v-list-tile-avatar color="red" :size="32">
              <v-icon dark small>forward</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <span>
                <v-list-tile-sub-title>
                  <b class="grey--text text--lighten-1">Job Escalated</b>
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                  <b class="grey--text text--lighten-1">
                    {{ escalationDetails.escalationReason }}
                  </b>
                </v-list-tile-sub-title>
              </span>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-if="job && job.jobType === 'US' && showHideWarning(job)">
            <v-list-tile-avatar color="orange" class="icon-img vip-customer" :size="32">
              <img src="/img/pounds-symbol.svg" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <span>
                <v-list-tile-sub-title>
                  <b class="warning--text">
                    Outstanding Excess Amount:
                    {{ getFormatedCurrency(excessValue - totalPaidAmount) }}
                  </b>
                </v-list-tile-sub-title>
              </span>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import storeGetters from '@/storeGetters'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import { HealthAndSafetyLevel, RecordType, AsbestosEffectLevel } from '@/common/enums'
import Emergency from '@/models/Emergency'
import Job from '@/models/Job'
import ComplaintModel from '@/models/claim/ComplaintModel'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import EmergencyQA from '@/models/EmergencyQA'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import JobEscalationDetail from '@/models/claim/JobEscalationDetailModel'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'

@Component
export default class JobWarnings extends Vue {
  @Prop() private jobId: string
  private colors: string[] = []
  private complaintTypeList: MasterRecord[] = []
  private greyColor = 'grey'
  private orangeColor = 'orange'
  private redColor = 'red'
  private blueColor = 'blue'
  private totalPaidAmount = 0

  private created() {
    this.GetComplaintTypeList()
  }

  // Get list of complaint types.
  private GetComplaintTypeList(): void {
    const recordType: string = RecordType[RecordType.ComplaintType]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        this.complaintTypeList = res
      }
    })
  }

  // get vulnerabilityQA
  private get vulnerabilityQAItem(): VulnerabilityQAModel | undefined {
    if (this.jobId) {
      return storeGetters.getVulnerabilityQA(this.jobId)
    }
    return undefined
  }

  // get healthAndSafetyQA
  private get healthAndSafetyQAItem(): HealthAndSafetyQAModel[] {
    if (this.jobId) {
      return storeGetters.getHealthAndSafetyQA(this.jobId)
    }
    return []
  }

  private get asbestosItems(): EmergencyQA[] {
    if (this.jobId) {
      const job: Job | null = storeGetters.getJob(this.$route.params.jobId)
      let item: EmergencyQA[] = []
      if (job) {
        job.emergenciesQAs.forEach((emergencyQA) => {
          if (emergencyQA.asbestosEffectLevel) {
            const emergencyWiseRecord: EmergencyQA = item.filter(
              (x) => x.forEmergencyDetailId === null && x.forEmergencyTypeId === emergencyQA.forEmergencyTypeId
            )[0]

            const emergencyDetailWiseRecord: EmergencyQA = item.filter(
              (x) =>
                x.forEmergencyDetailId === emergencyQA.forEmergencyDetailId &&
                x.forEmergencyTypeId === emergencyQA.forEmergencyTypeId
            )[0]

            if (emergencyWiseRecord) {
              // emergency level questions
              if (emergencyQA.asbestosEffectLevel > emergencyWiseRecord.asbestosEffectLevel) {
                item = item.filter((x) => x.forEmergencyTypeId !== emergencyWiseRecord.forEmergencyTypeId)
                item.push(emergencyQA)
              }
            } else if (emergencyDetailWiseRecord) {
              // questions inside same emergency
              if (emergencyQA.asbestosEffectLevel > emergencyDetailWiseRecord.asbestosEffectLevel) {
                item = item.filter((x) => x.forEmergencyTypeId !== emergencyDetailWiseRecord.forEmergencyTypeId)
                item.push(emergencyQA)
              }
            } else {
              // policy level question
              item.push(emergencyQA)
            }
          }
        })
        return item
      }
    }
    return []
  }

  // get Complaint
  private get complaint(): ComplaintModel | undefined {
    if (this.jobId) {
      const loggedComplaints = storeGetters.getComplaints(this.jobId)
      const maxSeverity = Math.max.apply(
        Math,
        loggedComplaints.filter((c) => !c.isResolved).map((s) => Number(s.severity))
      )
      return loggedComplaints.find((c) => Number(c.severity) === maxSeverity)
    }
    return undefined
  }

  // get is Customer VIP Customer or not
  private get getIsVipCustomer(): boolean {
    if (this.job) {
      return this.job.isVIPCustomer ? true : false
    }
    return false
  }

  // get CustomerToPay
  private get customerToPay(): CustomerToPayModel | undefined {
    if (this.jobId) {
      return storeGetters.getAuthorisedCustomerToPay(this.jobId)
    }
    return undefined
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  // get emergemncy name by typeId
  private getEmergencyName(forEmergencyTypeId: number, forEmergencyDetailId?: number): string {
    if (this.job) {
      if (forEmergencyDetailId) {
        const detailItem = this.job.emergencies.find((e) => e.detailId === forEmergencyDetailId)
        if (detailItem) {
          return detailItem.detailDescription
        }
      }
      const item = this.job.emergencies.find((e) => e.typeId === forEmergencyTypeId)
      if (item) {
        return item.typeDescription
      }
    }
    return ''
  }

  // method for show/hide warning
  private showHideWarning(item: any): boolean {
    if (item && item.isHealthAffected === true) {
      return true
    } else if (item && item.severity !== '' && item.severity !== undefined) {
      return true
    } else if (item && item.isVIPCustomer) {
      return true
    } else if (item && item.isTransactionCompleted) {
      return true
    } else if (item && item.jobType === 'US' && this.isOutstandingExcessAmount) {
      return true
    } else if (item && item.escalationReason) {
      return true
    }

    return false
  }

  // get color based on level (gray for low, orange for medium, red for high)
  private getColor(item: VulnerabilityQAModel | HealthAndSafetyQAModel): string {
    if (item && item.isHealthAffected === true) {
      if (item.level === HealthAndSafetyLevel[HealthAndSafetyLevel.Low]) {
        return this.greyColor
      } else if (item.level === HealthAndSafetyLevel[HealthAndSafetyLevel.Medium]) {
        return this.orangeColor
      } else if (item.level === HealthAndSafetyLevel[HealthAndSafetyLevel.High]) {
        return this.redColor
      }
    }
    return this.greyColor
  }

  private getAsbestosEffectColor(item: EmergencyQA): string {
    if (item) {
      if (item.asbestosEffectLevel === AsbestosEffectLevel.Low) {
        return this.greyColor
      } else if (item.asbestosEffectLevel === AsbestosEffectLevel.Medium) {
        return this.orangeColor
      } else if (item.asbestosEffectLevel === AsbestosEffectLevel.High) {
        return this.redColor
      }
    }
    return this.greyColor
  }

  // get color based on complaint severity (gray for 1-3, orange for 4-7, red for 8-10)
  private getSeverityColor(item: ComplaintModel): string {
    if (item && item.severity) {
      if (item.severity === '1' || item.severity === '2' || item.severity === '3') {
        return this.greyColor
      } else if (item.severity === '4' || item.severity === '5' || item.severity === '6') {
        return this.orangeColor
      } else if (item.severity === '7' || item.severity === '8' || item.severity === '9' || item.severity === '10') {
        return this.redColor
      }
    }
    return this.greyColor
  }

  private getComplaintType(): string | null | undefined {
    if (this.complaintTypeList) {
      const getComplaintType: MasterRecord | undefined = this.complaintTypeList.find((c) =>
        this.complaint ? c.id === this.complaint.complaintType : false
      )
      if (getComplaintType) {
        return getComplaintType.description
      } else {
        return null
      }
    }
  }

  private get escalationDetails(): JobEscalationDetail | undefined {
    if (this.jobId) {
      return storeGetters.getJobActiveEscalationDetails(this.jobId)
    }
    return undefined
  }

  private openJobEscalationCard() {
    eventBus.$emit('showJobEscalateCard', this.escalationDetails!.id)
  }

  private get warningColor(): string {
    this.colors = []
    if (this.vulnerabilityQAItem && this.vulnerabilityQAItem.isHealthAffected === true) {
      this.colors.push(this.getColor(this.vulnerabilityQAItem))
    }
    if (this.healthAndSafetyQAItem) {
      this.healthAndSafetyQAItem.forEach((element: HealthAndSafetyQAModel) => {
        if (element && element.isHealthAffected === true) {
          this.colors.push(this.getColor(element))
        }
      })
    }
    if (this.complaint && this.complaint.severity) {
      this.colors.push(this.getSeverityColor(this.complaint))
    }
    if (this.getIsVipCustomer) {
      this.colors.push(this.blueColor)
    }
    if (this.asbestosItems) {
      this.asbestosItems.forEach((element: EmergencyQA) => {
        if (element && element.asbestosEffectLevel && element.asbestosEffectLevel > 0) {
          this.colors.push(this.getAsbestosEffectColor(element))
        }
      })
    }
    if (this.customerToPay && this.customerToPay.isTransactionCompleted) {
      this.colors.push(this.blueColor)
    }
    if (this.escalationDetails) {
      this.colors.push(this.redColor)
    }
    if (this.job!.jobType === 'US' && this.isOutstandingExcessAmount) {
      this.colors.push(this.orangeColor)
    }
    if (this.colors && this.colors.length > 0) {
      if (this.colors.find((e: string) => e === this.redColor)) {
        return this.redColor
      } else if (this.colors.find((e: string) => e === this.orangeColor)) {
        return this.orangeColor
      } else if (this.colors.find((e: string) => e === this.blueColor)) {
        return this.blueColor
      }
    }
    return this.greyColor
  }

  private get excessValue(): number {
    const emergency = this.job!.emergencies[this.job!.emergencies.length - 1]
    return emergency && emergency.excessValue ? emergency.excessValue : 0
  }

  private get isOutstandingExcessAmount(): boolean {
    const filteredCustomerToPay =
      this.job && this.job.customerToPay && this.job.customerToPay.length > 0
        ? this.job.customerToPay.filter((x) => x.isTransactionCompleted === true)
        : []
    this.totalPaidAmount =
      filteredCustomerToPay.length > 0
        ? filteredCustomerToPay.map((y) => y.amount).reduce((prev, next) => prev + next)
        : 0
    return this.totalPaidAmount < this.excessValue
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>

<style type="text/css" scoped>
.notification-menu.v-card {
  border-radius: 0;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
/*removed the radius of card*/
.notification-menu >>> .v-list {
  padding: 0px;
  background-color: transparent;
}
/*Change bg color notification list*/
.notification-menu >>> .v-list .v-list__tile__content {
  width: 100%;
}
.notification-menu >>> .v-list > div {
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
}
/*Add bg color in notification list item*/
.notification-menu >>> .v-list > div:last-child {
  margin-bottom: 0px;
}
.notification-menu >>> .v-list .v-list__tile {
  padding: 0px 10px;
}
.notification-menu >>> .v-list .v-list__tile__avatar {
  min-width: 42px;
  max-width: 42px;
}
.v-badge >>> .v-badge__badge {
  top: 0;
  right: 0;
}
/*to set bedge icon position*/
.n-arrow {
  transition: all 0.3s linear;
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: dashed dashed solid;
  border-width: 0 8.5px 8.5px;
  position: absolute;
  left: 13px;
  top: 50px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.v-menu__activator.v-menu__activator--active .n-arrow {
  top: 37px;
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.notification-menu >>> .vb-dragger {
  right: 4px;
}
.notification-bell >>> .v-badge--overlap .v-badge__badge {
  top: -7px;
  right: -11px;
}
.notification-menu >>> .v-list--three-line .v-list__tile {
  height: 92px;
}
.icon-img >>> img {
  max-width: 24px;
}
.vip-customer >>> img {
  max-width: 19px;
}
.icon-img >>> img.asbestos-icon {
  max-width: 28px;
}
.notification-bell >>> .v-badge--overlap {
  top: -2px;
}
</style>
