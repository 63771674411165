import store from '@/store'
import { commandNames } from '../commands/command-names'
import CommandHandler from '../interfaces/command-handler'
import router from '@/router'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import StoreMutations from '@/storeMutations'
import Job from '@/models/Job'

export default class CreateEnquiryHandler implements CommandHandler {
  public readonly commandName = commandNames.createEnquiryCommand
  async execute(value: any): Promise<void> {
    const job = Object.assign(new Job(), value)
    await store.Instance.dispatch('heWizardModule/setHeWizardVisible', false)

    if (!job) {
      await store.Instance.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar('No enquiry passed from wizard, cannot navigate to enquiry')
      )
      return
    }

    StoreMutations.addOrReplaceJob(job)

    router.push({
      name: 'job',
      params: {
        jobId: job.id,
      },
    })
  }
}
