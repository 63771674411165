<template>
  <div
    v-if="model && model.detailComponent"
    v-bar="{ useScrollbarPseudo: true }"
    class="inner-content"
    :class="model && model.detailComponent ? 'contentShadow' : ''"
  >
    <v-layout wrap class="px-3 pt-3">
      <v-flex xs12 class="text-xs-right mb-1">
        <v-layout>
          <v-flex v-if="showSendQuestionModeLinkIcon" text-xs-left>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn flat icon color="secondry" class="ml-0 question-link" v-on="on" @click="sendQuestionLinkClicked">
                  <img src="/img/question-link.svg" alt="" />
                  <span class="link-count">
                    {{ getSendQuestionModeLinkCount }}
                  </span>
                </v-btn>
              </template>
              <span>Send Questions Link</span>
            </v-tooltip>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex>
            <v-btn flat icon color="primary" :disabled="getIsAutoDeploymentRunning" @click="refreshDataClicked">
              <v-icon dark color="primary">refresh</v-icon>
            </v-btn>
            <v-btn flat icon color="primary" :disabled="getIsAutoDeploymentRunning" @click="closePreviewClicked">
              <v-icon dark color="primary">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <div class="main-inner-content">
          <div>
            <v-layout>
              <v-flex xs12>
                <component
                  :is="model.detailComponent"
                  :job-id="model.forJobId"
                  :emergency-id="model.forEmergencyId"
                  :item-id="model.forItemId"
                />
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineCardModel from '@/models/TimeLineCardModel'

import FNOLPreview from '@/components/timeline/FNOLPreview.vue'
import EmergencyQAPreview from '@/components/timeline/EmergencyQAPreview.vue'
import CustomerAvailabilityPreview from '@/components/timeline/CustomerAvailabilityPreview.vue'
import CallContractorPreview from '@/components/timeline/CallContractorPreview.vue'
import PictureUploadPreview from '@/components/timeline/PictureUploadPreview.vue'
import FinancialSummaryPreview from '@/components/timeline/FinancialSummaryPreview.vue'
import CustomerToPayPreview from '@/components/timeline/CustomerToPayPreview.vue'
import JobCompletePreview from '@/components/timeline/JobCompletePreview.vue'
import JobNotePreview from '@/components/timeline/JobNotePreview.vue'
import SMSPreview from '@/components/timeline/SMSPreview.vue'
import EmailPreview from '@/components/timeline/EmailPreview.vue'
import VulnerabilityQAPreview from '@/components/timeline/VulnerabilityQAPreview.vue'
import SiUsJobQuestionsPreview from '@/components/timeline/SiUsJobQuestionsPreview.vue'
import EnablementNotesPreview from '@/components/timeline/EnablementNotesPreview.vue'
import IVSProcessPreview from '@/components/timeline/IVSProcessPreview.vue'
import IVSCompletePreview from '@/components/timeline/IVSCompletePreview.vue'
import ComplaintCardPreview from '@/components/timeline/ComplaintCardPreview.vue'
import TradeAppointedPreview from '@/components/timeline/TradeAppointedPreview.vue'
import SoftFixPreview from '@/components/timeline/SoftFixPreview.vue'
import EngineerVisitPreview from '@/components/timeline/EngineerVisitPreview.vue'
import JobCompleteConfirmationPreview from '@/components/timeline/JobCompleteConfirmationPreview.vue'
import PolicyWideQAPreview from '@/components/timeline/PolicyWideQAPreview.vue'
import ConfirmDatePreview from './timeline/ConfirmDatePreview.vue'
import JobPackagePreview from '@/components/timeline/JobPackagePreview.vue'
import MonitoringDetailPreview from '@/components/timeline/MonitoringDetailPreview.vue'
import TestingPreview from '@/components/timeline/TestingPreview.vue'
import BuildReportPreview from '@/components/timeline/BuildReportPreview.vue'
import SiteDataPreview from '@/components/timeline/SiteDataPreview.vue'
import storeGetters from '@/storeGetters'
import Job from '@/models/Job'
import { ContactPreferenceEnum, DocumentTemplateType } from '@/common/enums'
import eventBus from '@/common/bus'
import JobDelayPreview from '@/components/timeline/JobDelayPreview.vue'
import FailedToContactCallPreview from '@/components/timeline/FailedToContactCallPreview.vue'
import DrainageReportPreview from '@/components/timeline/DrainageReportPreview.vue'
import RepairEstimatesPreview from '@/components/timeline/RepairEstimatesPreview.vue'
import OtherFeesDetailPreview from '@/components/timeline/OtherFeesDetailPreview.vue'
import CustomerAppointedTradespeoplePreview from '@/components/timeline/CustomerAppointedTradespeoplePreview.vue'
import SIJobCompleteConfirmationPreview from '@/components/timeline/SIJobCompleteConfirmationPreview.vue'
import JobFinancialSummaryPreview from '@/components/timeline/JobFinancialSummaryPreview.vue'
import JobEscalationPreview from '@/components/timeline/JobEscalationPreview.vue'
import JobReopenPreview from '@/components/timeline/JobReopenPreview.vue'
import ClientClaimSubmittedPreview from '@/components/timeline/ClientClaimSubmitted/ClientClaimSubmittedPreview.vue'
import AutoDeploymentCardPreview from '@/components/timeline/AutoDeployment/AutoDeploymentCardPreview.vue'

@Component({
  components: {
    FNOLPreview,
    EmergencyQAPreview,
    CustomerAvailabilityPreview,
    CallContractorPreview,
    PictureUploadPreview,
    FinancialSummaryPreview,
    CustomerToPayPreview,
    JobCompletePreview,
    JobNotePreview,
    SMSPreview,
    EmailPreview,
    VulnerabilityQAPreview,
    ComplaintCardPreview,
    TradeAppointedPreview,
    SoftFixPreview,
    EngineerVisitPreview,
    JobCompleteConfirmationPreview,
    PolicyWideQAPreview,
    ConfirmDatePreview,
    JobPackagePreview,
    SiUsJobQuestionsPreview,
    EnablementNotesPreview,
    MonitoringDetailPreview,
    TestingPreview,
    BuildReportPreview,
    IVSProcessPreview,
    IVSCompletePreview,
    JobDelayPreview,
    SiteDataPreview,
    FailedToContactCallPreview,
    DrainageReportPreview,
    OtherFeesDetailPreview,
    CustomerAppointedTradespeoplePreview,
    SIJobCompleteConfirmationPreview,
    JobFinancialSummaryPreview,
    JobEscalationPreview,
    RepairEstimatesPreview,
    JobReopenPreview,
    ClientClaimSubmittedPreview,
    AutoDeploymentCardPreview,
  },
})
export default class TimeLinePreview extends Vue {
  @Prop() public model: TimeLineCardModel

  // get job detail
  private get job(): Job | null {
    return storeGetters.getJob(this.model.forJobId)
  }

  private get getIsAutoDeploymentRunning() {
    if (this.job) {
      const emergencyWithAutoDeploymentRunning = this.job.emergencies.filter((e) => e.autoDeploymentRunning === true)
      return emergencyWithAutoDeploymentRunning.length > 0 && this.model.detailComponent === 'TradeAppointedPreview'
        ? true
        : false
    }
    return false
  }

  private closePreviewClicked(): void {
    this.$emit('closePreview')
  }

  private refreshDataClicked(): void {
    this.$emit('refreshData')
  }

  private get showSendQuestionModeLinkIcon() {
    if (
      this.job &&
      this.job.jobType === 'HE' &&
      this.job.contactPreference === ContactPreferenceEnum[ContactPreferenceEnum.SMS] &&
      this.model.detailComponent === 'EmergencyQAPreview'
    ) {
      if (this.job.status !== 'Completed' && this.job.status !== 'Cancelled' && !this.job.cancellationReason) {
        const acceptedEmergencies = this.job.emergencies.filter(
          (e) => e.isEmergencyAccepted || e.ctpCompletedForRejectedEmergency
        )
        return acceptedEmergencies.length === this.job.emergencies.length ? false : true
      }
      return false
    }
    return false
  }

  private get getSendQuestionModeLinkCount() {
    return this.job && this.job.SmsTexts.length > 0
      ? this.job.SmsTexts.filter(
          (e) =>
            (e.documentTemplateType || '').toString() ===
            DocumentTemplateType[DocumentTemplateType.SendQuestionModePortalLink]
        ).length
      : 0
  }

  private sendQuestionLinkClicked(): void {
    eventBus.$emit('sendQuestionLink')
  }
}
</script>

<style scoped>
.contentShadow {
  height: 100%;
  width: 100%;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.main-inner-content {
  height: 100% !important;
  position: relative;
  background: #f5f5f6;
  max-height: 100%;
  padding-bottom: 15px;
}
.question-link {
  position: relative;
}
.question-link .link-count {
  position: absolute;
  top: -7px;
  right: -1px;
  height: 15px;
  width: 15px;
  background-color: #616161;
  color: #ffffff;
  font-size: 11px;
  border-radius: 50%;
  line-height: 1.2;
}
/* .main-inner-content .layout{display:inherit;} */
</style>
