<template>
  <div>
    <div class="mb-2"><h4>Accept Provisioned Visit</h4></div>
    <div v-if="contractorCompanyName" class="mb-1">
      <label>Contractor: </label>
      <span>{{ contractorCompanyName }}</span>
    </div>
    <div v-if="item.etaFrom" class="mb-1">
      <label>Date From: </label>
      <span>{{ getFormattedDate(item.etaFrom) }}</span>
    </div>
    <div v-if="item.etaTo" class="mb-1">
      <label>Date To: </label>
      <span>{{ getFormattedDate(item.etaTo) }}</span>
    </div>
    <div v-if="engineerName" class="mb-1">
      <label>Engineer: </label>
      <span>{{ engineerName }}</span>
    </div>
    <div v-if="item.repairEstimateIds && item.repairEstimateIds.length > 0" class="mb-1">
      <label>Included Repair Estimation: </label>
      <span>{{ repairEstimates.join(', ') }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AcceptProvisionedVisit from '@/models/requests/AcceptProvisionedVisit'
import AcceptProvisionedUSVisit from '@/models/requests/AcceptProvisionedUSVisit'
import { RequestType } from '@/common/enums'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class AcceptProvisionedVisitLog extends Vue {
  @Prop() private request: any
  @Prop() private requestType: string

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get item(): AcceptProvisionedVisit | AcceptProvisionedUSVisit {
    return this.requestType === RequestType[RequestType.AcceptProvisionedVisit]
      ? Object.assign(new AcceptProvisionedVisit(), this.request)
      : Object.assign(new AcceptProvisionedUSVisit(), this.request)
  }

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.item.engineerId)
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get contractorCompanyName(): string {
    if (this.requestedJob) {
      const contractorDetail = this.requestedJob.contractorAppointedDetails.find(
        (e) => e.id === this.item.contractorAppointedDetailId
      )
      return contractorDetail ? contractorDetail.companyName : ''
    }
    return ''
  }

  private get repairEstimates() {
    const repairEstimates = this.requestedJob!.repairEstimates.filter(
      (r) => (this.item as AcceptProvisionedUSVisit).repairEstimateIds.findIndex((e) => e === r.id) !== -1
    )
    return repairEstimates.map((r) => r.estimateName)
  }
}
</script>
