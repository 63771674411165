<template>
  <div class="dashboard">
    <v-layout wrap :class="openJobView ? 'hide-dashboard' : 'show-dashboard'">
      <v-flex xs12>
        <v-container fluid grid-list-lg pt-0 pa-0>
          <v-layout wrap job-info request-info>
            <DashboardTopTile
              v-for="key in Object.keys(getTopTiles)"
              :key="key"
              :active-filter-color="tileColorWithActiveFilter === getTopTiles[key].backgroundColor ? true : false"
              v-bind="getTopTiles[key]"
              dashboard-tile-view="FinancialSummary"
              @onTopTileFilter="onTopTileFilter"
            />
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 class="gridView mt-4">
        <v-layout wrap>
          <v-flex xs4 request-info>
            <h3>
              {{
                getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForApproval]
                  ? 'Ready For Approvals'
                  : getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ToReview]
                  ? 'Awaiting Review from CET'
                  : getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.Disputed]
                  ? 'Disputed'
                  : getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForInvoice]
                  ? 'Ready for Invoice'
                  : 'Outstanding'
              }}
              Jobs
            </h3>
          </v-flex>
          <v-flex v-if="getJobListLength > 0" class="text-xs-right search-controls">
            <div ref="search-job" class="search-job">
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
              </v-text-field>
            </div>
            <template v-if="clientPolicies.updateFinancial">
              <v-btn
                v-if="
                  getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForApproval]
                "
                color="primary"
                class="ma-0 ml-2 mt-2 approveJob-btn"
                :disabled="isLoading"
                @click="openConfirmationForJobApprove(financialRequestStatus[financialRequestStatus.Approve])"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.Disputed]"
                color="primary"
                class="ma-0 ml-2 mt-2"
                :disabled="isLoading"
                @click="onConfirmDisputingJob"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="
                  getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForApproval]
                "
                color="primary"
                class="ma-0 ml-2 mt-2"
                :disabled="isLoading"
                @click="openJobRejectReasonDialogue()"
              >
                Reject
              </v-btn>
              <v-btn
                v-if="getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ToReview]"
                color="primary"
                class="ma-0 ml-2 mt-2"
                :disabled="isLoading"
                @click="openConfirmationForJobToReview(financialRequestStatus[financialRequestStatus.ReviewComplete])"
              >
                Review Complete
              </v-btn>
              <v-btn
                v-if="
                  getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.Disputed] &&
                  selectedJobType === 'HE'
                "
                color="primary"
                class="ma-0 ml-2 mt-2"
                :disabled="isLoading"
                @click="openConfirmationForJobRecalculate(financialRequestStatus[financialRequestStatus.ReCalculate])"
              >
                Re-Calculate
              </v-btn>
              <template
                v-if="
                  getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.ReadyForInvoice] &&
                  selectedJobList.length > 0 &&
                  selectedInsurerId
                "
              >
                <v-btn
                  v-if="selectedJobList.length > 1"
                  color="primary"
                  class="ma-0 ml-2 mt-2 export-bordereau"
                  @click="openBordereauDialog"
                >
                  Preview Bordereau
                </v-btn>
                <v-btn v-else color="primary" class="ma-0 ml-2 mt-2 export-bordereau" @click="openInvoiceDialog">
                  Preview Invoice
                </v-btn>
              </template>
            </template>
          </v-flex>
          <v-flex xs12 mt-2 class="elevation-1">
            <div class="job-list grey">
              <v-data-table
                ref="jobFinancialDataTable"
                :key="jobList.length"
                v-model="selectedJobList"
                :headers="headers"
                :items="jobList"
                :loading="isLoading"
                :pagination.sync="pagination"
                item-key="selectedItemId"
                :select-all="hasRightForMarking"
                class="job-table jobFinancialDataTable"
                name="jobFinancialDataTable"
                :search="search"
              >
                <v-progress-linear slot="progress" :color="tileColorWithActiveFilter" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr :active="props.selected" @click="props.selected = !props.selected">
                    <td v-if="hasRightForMarking">
                      <v-checkbox :input-value="props.selected" color="primary" hide-details></v-checkbox>
                    </td>
                    <td>
                      <b>
                        <a
                          href="Javascript:void(0)"
                          name="openJobDetailButton"
                          class="secondary--text link-jobId"
                          @click="redirectToJob(props.item.jobId)"
                          @click.stop
                        >
                          {{ props.item.jobId }}
                        </a>
                      </b>
                      <span v-if="props.item.clientNotes && props.item.clientNotes.length > 0" class="pl-2">
                        <v-tooltip right content-class="yellow lighten-2 grey--text text--darken-4 body-2">
                          <template #activator="{ on }">
                            <v-icon class="icon-arrow md-icon" v-on="on">info</v-icon>
                          </template>
                          <span>
                            <div v-for="(note, index) in props.item.clientNotes" :key="index">
                              <h3 class="mb-2">Note {{ index + 1 }}:</h3>
                              <p class="mb-2">- {{ note }}</p>
                            </div>
                          </span>
                        </v-tooltip>
                      </span>
                    </td>
                    <td>{{ props.item.address }}</td>
                    <td>{{ props.item.policyName }}</td>
                    <td class="text-right">
                      {{
                        props.item.emergenciesCountInJob > 1
                          ? getFormatedCurrency(props.item.policyLimit) + ' x ' + props.item.emergenciesCountInJob
                          : getFormatedCurrency(props.item.policyLimit)
                      }}
                    </td>
                    <td
                      v-if="checkCETLoginUser"
                      class="text-right"
                      :class="props.item.policyLimitRemaining < 0 ? 'red--text' : ''"
                    >
                      <b>
                        {{ getPolicyLimitRemaining(props.item.policyLimitRemaining) }}
                      </b>
                    </td>
                    <td v-if="checkCETLoginUser" class="text-right">
                      {{ getFormatedCurrency(props.item.materialValue) }}
                    </td>
                    <td v-if="checkCETLoginUser" class="text-right">
                      {{ getFormatedCurrency(props.item.labourValue) }}
                    </td>
                    <td v-if="checkCETLoginUser" class="text-right handling-fee">
                      {{ getFormatedCurrency(props.item.handlingFee ? props.item.handlingFee : 0) }}
                    </td>
                    <td class="text-right">
                      <span v-if="!props.item.overrideTotalCost">
                        {{ getFormatedCurrency(props.item.totalCostIncFees) }}
                      </span>
                      <span v-else>
                        <v-tooltip v-if="props.item.materialValue || props.item.labourValue" right>
                          <template #activator="{ on }">
                            <v-icon class="ml-2 md-icon" v-on="on">info</v-icon>
                          </template>
                          <span>
                            Auto calculated amount was
                            {{ getFormatedCurrency(props.item.totalCostIncFees) }}
                          </span>
                        </v-tooltip>
                        {{ getFormatedCurrency(props.item.overrideTotalCost) }}
                      </span>
                    </td>
                    <td class="text-xs-center">{{ props.item.returnVisit }}</td>
                    <td
                      v-if="getCurrentSelectedFilter === jobFinancialSummaryWidget[jobFinancialSummaryWidget.Disputed]"
                      class="text-xs-center"
                    >
                      {{ props.item.disputedReason }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <div v-if="isLoading" class="loader-content">
        <v-progress-circular class="loading-spinner" :width="2" :size="50" indeterminate color="primary">
        </v-progress-circular>
      </div>
    </v-layout>
    <!-- dialog - dispute job -->
    <v-dialog
      v-if="showDisputeDialog"
      v-model="showDisputeDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Dispute Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDisputeDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-4 pt-4">
          <v-layout wrap>
            <v-flex xs12 pr-2>
              <v-text-field
                v-model="disputedReason"
                v-validate="'required'"
                label="Dispute Reason"
                required
                class="required"
                data-vv-scope="jobFinancialSummaryScope"
                data-vv-name="Dispute Reason"
                :error-messages="errors.collect('Dispute Reason')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click.native="showDisputeDialog = false">Close</v-btn>
          <v-btn
            color="primary"
            :loading="onSubmitLoading"
            :disabled="onSubmitLoading"
            class="mr-0"
            @click.native="rejectJobs"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>

    <!-- export to bordereau dialog -->
    <v-dialog
      v-if="exportToBordereauDialog"
      v-model="exportToBordereauDialog"
      max-width="1000"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Export to Bordereau</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="closeBordereauDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text>
          <v-container class="py-0" wrap>
            <v-flex xs12>
              <span>
                Create a new Bordereau with {{ selectedJobList.length }} job{{
                  selectedJobList.length != 1 ? 's' : ''
                }}?
              </span>
            </v-flex>
            <v-layout xs12 wrap>
              <v-flex xs4>
                <v-container class="px-0">
                  <span v-if="selectedBordereauId" class="bordereau-id">
                    {{ selectedBordereauId }}
                  </span>
                  <v-progress-linear v-else height="4" indeterminate />
                </v-container>
              </v-flex>
              <v-flex xs4>
                <v-combobox
                  v-model="salesLedgerCode"
                  v-validate="'required'"
                  :label="salesLedgerCodeList.length > 0 ? 'Select Sales Ledger Code' : 'Create Sales Ledger Code'"
                  :items="salesLedgerCodeList"
                  maxlength="20"
                  required
                  class="required salesLedgerCode"
                  data-vv-scope="jobFinancialSummaryScope"
                  data-vv-name="Sales Ledger Code"
                  :error-messages="errors.collect('Sales Ledger Code')"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <BordereauPreviewTable
                :selected-job-list="selectedJobList"
                :selected-bordereau-id="selectedBordereauId"
              />
            </v-flex>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <template>
            <v-btn color="primary" flat @click="closeBordereauDialog">Cancel</v-btn>
            <v-btn
              class="create-bordereau"
              color="primary"
              :disabled="bordereauGenerateLoader"
              :loading="bordereauConfirmLoader"
              @click="confirmCreateBordereau"
            >
              Create
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- export to invoice dialog -->
    <v-dialog
      v-if="exportToInvoiceDialog"
      v-model="exportToInvoiceDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Export to Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="closeInvoiceDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog">
          <v-container class="py-0">
            <v-flex xs12>
              <span>
                Create a new Invoice for job
                {{ selectedJobList.length ? selectedJobList[0].jobId : '' }}?
              </span>
            </v-flex>
            <v-layout xs12 wrap>
              <v-flex xs4>
                <v-container class="px-0 pb-0">
                  <span v-if="selectedBordereauId" class="bordereau-id">
                    {{ selectedBordereauId }}
                  </span>
                  <v-progress-linear v-else height="4" indeterminate />
                </v-container>
              </v-flex>
              <v-flex xs4>
                <v-combobox
                  v-model="salesLedgerCode"
                  v-validate="'required'"
                  :label="salesLedgerCodeList.length > 0 ? 'Select Sales Ledger Code' : 'Create Sales Ledger Code'"
                  :items="salesLedgerCodeList"
                  maxlength="20"
                  required
                  class="required salesLedgerCode"
                  data-vv-scope="jobFinancialSummaryScope"
                  data-vv-name="Sales Ledger Code"
                  :error-messages="errors.collect('Sales Ledger Code')"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <BordereauPreviewTable
                :selected-job-list="selectedJobList"
                :selected-bordereau-id="selectedBordereauId"
              />
            </v-flex>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <template>
            <v-btn color="primary" flat @click="closeInvoiceDialog">Cancel</v-btn>
            <v-btn
              class="create-bordereau"
              color="primary"
              :disabled="bordereauGenerateLoader"
              :loading="bordereauConfirmLoader"
              @click="confirmCreateInvoice"
            >
              Create
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PartialJobView ref="refPartialJobView" :job-id="selectedJobIdToExpand" @closeJobView="closeJobView">
    </PartialJobView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import Chart from 'chart.js'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import Shared from '@/common/shared'
import { JobFinancialSummaryWidget, FinancialRequestStatus, JobType } from '@/common/enums'
import SignalRHubConnection, { ConnectionState } from '@/signalr/SignalRHubConnection'
import JobFinancialSummaryDataModel from '@/models/claim/JobFinancialSummaryDataModel'
import DashboardController from '@/api/dashboardController'
import JobFinancialSummary from '@/models/claim/JobFinancialSummary'
import JobFinancialSummaryWidgetCount from '@/models/JobFinancialSummaryWidgetCount'
import JobController from '@/api/jobController'
import Store from '@/store'
import moment from 'moment'
import InvoiceDetailModel from '@/models/claim/InvoiceDetailModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import eventBus from '../common/bus'
import InvoiceController from '@/api/invoiceController'
import Bordereau from '../models/claim/Bordereau'
import ExportToBordereau from '@/models/requests/bulkRequests/ExportToBordereau'
import ExportToInvoice from '@/models/requests/ExportToInvoice'
import InsurerSalesLedgerModel from '@/models/policy/InsurerSalesLedgerModel'
import BordereauPreviewTable from '@/components/tables/BordereauPreviewTable.vue'
import ApproveListOfJobsModel, { ApproveJob } from '@/models/claim/ApproveListOfJobsModel'
import PartialJobView from '@/components/PartialJobView.vue'
import ClientAuthPolicies from '@/models/auth/client-auth-policies'

@Component({
  components: {
    DashboardTopTile,
    DateTimePicker,
    BordereauPreviewTable,
    PartialJobView,
  },
})
export default class JobFinancialSummaryDashboard extends Vue {
  @Prop() private selectedInsurerId: number
  @Prop() private insurerSalesLedgers: InsurerSalesLedgerModel[]
  @Prop({ default: 'HE' }) private selectedJobType: JobType
  private search = ''
  private pagination: any = {}
  private headers: any = []
  private isSorting = false
  private surge = ''
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private financialRequestStatus = FinancialRequestStatus
  private isLoading = false
  private jobList: JobFinancialSummary[] = []
  private jobFinancialSummaryWidgetCount: JobFinancialSummaryWidgetCount | null = null
  private selectedJobList: JobFinancialSummary[] = []
  private defaultTableHeaderColor = 'blue day-counts-section white--text'
  private tileColorWithActiveFilter: string = this.defaultTableHeaderColor
  private jobFinancialSummaryWidget = JobFinancialSummaryWidget
  private hubName = 'jobFinancialSummary'
  private signalRHub: SignalRHubConnection = new SignalRHubConnection(this.hubName + '0HE')
  private selectedTileName: string = this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
  private lastReCalculateSignalRCallAt: moment.Moment | null = null
  private showDisputeDialog = false
  private disputedReason = ''
  private onSubmitLoading = false
  private disputedReasonTableHeader: any = {
    text: 'Dispute Reason',
    value: 'disputedReason',
    align: 'center',
    class: this.defaultTableHeaderColor,
  }

  private selectedBordereauId: string | null = null
  private exportToBordereauDialog = false
  private exportToInvoiceDialog = false
  private bordereauGenerateLoader = false
  private bordereauConfirmLoader = false
  private salesLedgerCodeList: string[] = []
  private salesLedgerCode = ''
  private updatedJobIndex = -1
  private openJobView = false
  private selectedJobIdToExpand = ''
  private tempJobIdList: string[] = [] // temp jobId list to manage joblist updation on signalr

  private async created() {
    // this parameter set to show first tile color on table header
    const headerColor: string = this.defaultTableHeaderColor
    this.headers = [
      { text: 'JobId', value: 'jobId', class: headerColor },
      { text: 'Address', value: 'address', align: 'left', class: headerColor },
      {
        text: 'Policy Name',
        value: 'policyName',
        align: 'left',
        class: headerColor,
      },
      {
        text: 'Policy Limit',
        value: 'policyLimit',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Policy Limit Remaining',
        value: 'policyLimitRemaining',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Material Cost',
        value: 'materialValue',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Labour Cost',
        value: 'labourValue',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Handling Fee',
        value: 'handlingFee',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Total Cost',
        value: 'overrideTotalCost',
        align: 'right',
        class: headerColor,
      },
      {
        text: 'Return Visit',
        value: 'returnVisit',
        align: 'center',
        class: headerColor,
      },
    ]
    if (!this.checkCETLoginUser) {
      // remove three column headers for non-CET user
      let index = this.headers.findIndex((x) => x.value === 'policyLimitRemaining')
      this.headers.splice(index, 1)
      index = this.headers.findIndex((x) => x.value === 'materialValue')
      this.headers.splice(index, 1)
      index = this.headers.findIndex((x) => x.value === 'labourValue')
      this.headers.splice(index, 1)
    }
    this.pagination.rowsPerPage = Shared.rowsPerPageDefault
    // get job list
    this.getJobList()
    // handle signalR
    this.handleSignalR()
  }

  private destroyed() {
    this.signalRHub.disconnect()
  }

  private get getJobListLength() {
    return this.jobList.length
  }

  private handleSignalR() {
    // signalR on adding new jobs and SI job/visit invoice
    this.signalRHub.addHandler(
      'addNewInvoice',
      (
        insurerId: number,
        jobId: string,
        invoiceDetailId: string,
        updatedWidgetCounts: JobFinancialSummaryWidgetCount | null
      ) => {
        if (insurerId === this.selectedInsurerId) {
          this.tempJobIdList = [jobId]
          if (
            this.selectedJobType === 'SI' &&
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if invoice filter activated and jobType is SI, get and push jobs to the list
            this.getJobsFinancialDetails([jobId], invoiceDetailId)
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Outstanding]
          ) {
            // if Outstanding job filter activated, push new job
            this.getJobsFinancialDetails([jobId])
          }
          // update widget count
          if (this.selectedJobType === 'SI' && updatedWidgetCounts) {
            this.updateTileDataOnSignalR(updatedWidgetCounts)
          } else if (this.jobFinancialSummaryWidgetCount !== null) {
            this.jobFinancialSummaryWidgetCount.totalOutstandingJobs += 1
          }
        }
      }
    )

    // signalR on approving a jobs
    this.signalRHub.addHandler(
      'approveForJobInvoice',
      (
        insurerId: number,
        approveListOfJobs: string[],
        clientInvoiceDetailId: string,
        updatedWidgetCounts: JobFinancialSummaryWidgetCount
      ) => {
        if (insurerId === this.selectedInsurerId) {
          this.tempJobIdList = approveListOfJobs
          if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if same filter activated, remove selected jobs
            approveListOfJobs.forEach((jobId) => {
              const selectedJob = this.jobList.find(
                (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (selectedJob) {
                this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
              } else {
                // insert if approved by to review
                this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
              }
            })
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice]
          ) {
            // if invoice filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(approveListOfJobs, clientInvoiceDetailId)
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview] ||
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]
          ) {
            // if same filter activated, remove selected jobs
            approveListOfJobs.forEach((jobId) => {
              const selectedJob = this.jobList.find((x: JobFinancialSummary) => x.jobId === jobId)
              if (selectedJob) {
                this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
              }
            })
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
          this.resetTempJobIdList()
        }
      }
    )

    // signalR on rejcting a jobs
    this.signalRHub.addHandler(
      'rejectForJobInvoice',
      (
        insurerId: number,
        rejectListOfJobs: string[],
        clientInvoiceDetailId: string,
        updatedWidgetCounts: JobFinancialSummaryWidgetCount
      ) => {
        if (insurerId === this.selectedInsurerId) {
          this.tempJobIdList = rejectListOfJobs
          if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if same filter activated, remove selected jobs
            rejectListOfJobs.forEach((jobId) => {
              const selectedJob = this.jobList.find(
                (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (selectedJob) {
                this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
              }
            })
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]
          ) {
            // if invoice filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(rejectListOfJobs, clientInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
          this.resetTempJobIdList()
        }
      }
    )

    // signalR for re-calculate, update material and labour cost
    this.signalRHub.addHandler(
      'updateInvoiceDetail',
      (
        dataProcessAtUtc: moment.Moment,
        insurerId: number,
        jobId: string,
        invoiceDetail: InvoiceDetailModel,
        clientInvoiceDetailId: string,
        widgetCount: JobFinancialSummaryWidgetCount
      ) => {
        if (insurerId === this.selectedInsurerId) {
          // save last signalr call at date, to verify the sequence
          if (this.lastReCalculateSignalRCallAt === null) {
            this.lastReCalculateSignalRCallAt = dataProcessAtUtc
            // update tiles data
            this.updateTileDataOnSignalR(widgetCount)
          } else if (this.lastReCalculateSignalRCallAt < dataProcessAtUtc) {
            // update tiles data
            this.updateTileDataOnSignalR(widgetCount)
          }
          if (this.selectedJobType === 'HE') {
            // labour,material or handling fee is updated, get updated record using API call
            // added timeout to check if other action is required to perform on this job
            // if not then only cost should be updated
            setTimeout(() => {
              this.updatedJobIndex = this.jobList.findIndex(
                (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (this.updatedJobIndex > -1) {
                const index = this.tempJobIdList.findIndex((j) => j === jobId)
                if (index === -1) {
                  this.getJobsFinancialDetails([jobId], clientInvoiceDetailId, true)
                }
              }
            }, 500)
          } else {
            // find job and update material and labour cost in job if same filter selected
            const jobToUpdate: JobFinancialSummary | undefined = this.jobList.find(
              (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
            )
            if (jobToUpdate) {
              jobToUpdate.materialValue = invoiceDetail.materialValue
              jobToUpdate.labourValue = invoiceDetail.labourValue
              // for SI job value of totalCostIncFees is same as labourValue
              if (this.selectedJobType === 'SI') {
                jobToUpdate.totalCostIncFees = invoiceDetail.labourValue
              }
              jobToUpdate.overrideTotalCost = invoiceDetail.overrideTotalCost ? invoiceDetail.overrideTotalCost : 0
              jobToUpdate.handlingFee = invoiceDetail.handlingFee
              // for U/S job, cost of Other Fee will always be 0, so update value of totalCostIncFees accordingly when cost is updated from timeline
              if (this.selectedJobType === 'US') {
                jobToUpdate.totalCostIncFees =
                  invoiceDetail.materialValue + invoiceDetail.labourValue + invoiceDetail.handlingFee
              }
            }
          }
          this.resetTempJobIdList()
        }
      }
    )

    // signalR on completing jobs
    this.signalRHub.addHandler('completedJob', (insurerId: number, jobId: string, clientInvoiceDetailId: string) => {
      if (insurerId === this.selectedInsurerId) {
        this.tempJobIdList = [jobId]
        if (this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]) {
          // if same filter activated, add selected job
          this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
        } else if (
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Outstanding]
        ) {
          // if same filter activated, remove selected job
          const selectedJob = this.jobList.find(
            (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
          )
          if (selectedJob) {
            this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
          }
        }

        // update widget count
        if (this.jobFinancialSummaryWidgetCount) {
          this.jobFinancialSummaryWidgetCount.totalCompletedJobs += 1
          if (this.selectedJobType === 'HE') {
            // if HE job, then update count for totalOutstandingJobs
            this.jobFinancialSummaryWidgetCount.totalOutstandingJobs -= 1
          }
        }
        this.resetTempJobIdList()
      }
    })

    // signalR on outstanding to Toreview
    this.signalRHub.addHandler(
      'addToReview',
      (
        insurerId: number,
        jobId: string,
        clientInvoiceDetailId: string,
        updatedWidgetCounts: JobFinancialSummaryWidgetCount
      ) => {
        if (insurerId === this.selectedInsurerId) {
          this.tempJobIdList = [jobId]
          if (this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview]) {
            // if same filter activated, add selected job
            this.getJobsFinancialDetails([jobId], clientInvoiceDetailId)
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Outstanding]
          ) {
            // if same filter activated, remove selected job
            const selectedJob = this.jobList.find(
              (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
            )
            if (selectedJob) {
              this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
            }
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
          this.resetTempJobIdList()
        }
      }
    )

    // signalR on rejcting a jobs
    this.signalRHub.addHandler(
      'confirmDisputeJob',
      (
        insurerId: number,
        rejectListOfJobs: string[],
        clientInvoiceDetailId: string,
        updatedWidgetCounts: JobFinancialSummaryWidgetCount
      ) => {
        if (insurerId === this.selectedInsurerId) {
          this.tempJobIdList = rejectListOfJobs
          if (this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]) {
            // if same filter activated, remove selected jobs
            rejectListOfJobs.forEach((jobId) => {
              const selectedJob = this.jobList.find(
                (x: JobFinancialSummary) => x.jobId === jobId && x.clientInvoiceDetailId === clientInvoiceDetailId
              )
              if (selectedJob) {
                this.jobList.splice(this.jobList.indexOf(selectedJob), 1)
              }
            })
          } else if (
            this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
          ) {
            // if invoice filter activated, get and push jobs to the list
            this.getJobsFinancialDetails(rejectListOfJobs, clientInvoiceDetailId)
          }
          // update tiles data
          this.updateTileDataOnSignalR(updatedWidgetCounts)
          this.resetTempJobIdList()
        }
      }
    )

    this.signalRHub.addHandler(
      'exportJob',
      (
        insurerId: number,
        exportedListOfJobs: string[],
        clientInvoiceDetailId: string,
        updatedWidgetCounts: JobFinancialSummaryWidgetCount
      ) => {
        if (insurerId === this.selectedInsurerId) {
          this.tempJobIdList = exportedListOfJobs
          exportedListOfJobs.forEach((jobId) => {
            const selectedJob = this.jobList.findIndex(
              (x) =>
                x.jobId === jobId &&
                (this.selectedJobType === 'SI' ? x.clientInvoiceDetailId === clientInvoiceDetailId : true)
            )
            if (selectedJob > -1) {
              this.jobList.splice(selectedJob, 1)
            }
          })
          this.updateTileDataOnSignalR(updatedWidgetCounts)
          this.resetTempJobIdList()
        }
      }
    )

    this.signalRHub.connect()
  }

  private resetTempJobIdList() {
    // empty tempJobIdList array
    // wait till the jobList gets updated
    setTimeout(() => {
      this.tempJobIdList = []
    }, 1000)
  }

  private getJobsFinancialDetails(jobsList: string[], invoiceDetailId = '', isFromUpdateInvoiceDetail = false) {
    const jobList: ApproveJob[] = []
    jobsList.map((id) => jobList.push({ jobId: id, clientInvoiceDetailId: '' }))

    const listOfJobsModel: ApproveListOfJobsModel = new ApproveListOfJobsModel()
    listOfJobsModel.jobList = jobList

    DashboardController.GetFinancialSummaryJobs(listOfJobsModel)
      .then((res: JobFinancialSummary[] | null) => {
        if (res) {
          if (isFromUpdateInvoiceDetail) {
            // labour,material or handling fee is updated, find record and update into jobList
            const job = res.find((r) => r.clientInvoiceDetailId === invoiceDetailId)
            if (job) {
              this.jobList.splice(this.updatedJobIndex, 1, job)
              this.updatedJobIndex = -1
            }
          } else if (invoiceDetailId) {
            // find record using invoiceDetailId and push or update into joblist
            const job = res.find((r) => r.clientInvoiceDetailId === invoiceDetailId)
            if (job) {
              const index = this.jobList.findIndex(
                (j) => job.jobId === j.jobId && j.clientInvoiceDetailId === job.clientInvoiceDetailId
              )
              if (index === -1) {
                this.jobList.push(job)
              } else {
                this.jobList.splice(index, 1, job)
              }
            }
          }
          this.resetTempJobIdList()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary jobs, please try again', true)
      })
  }

  private closeBordereauDialog() {
    this.exportToBordereauDialog = false
    this.salesLedgerCode = ''
  }

  private closeInvoiceDialog() {
    this.exportToInvoiceDialog = false
    this.salesLedgerCode = ''
  }

  private updateTileDataOnSignalR(updatedWidgetCounts: JobFinancialSummaryWidgetCount) {
    this.jobFinancialSummaryWidgetCount = updatedWidgetCounts
  }

  private mounted() {
    this.setSelectAllCheckboxStyle(this.defaultTableHeaderColor)
  }

  @Watch('selectedInsurerId')
  private async onInsurerChange() {
    // assign new signalR hub name of insurer change
    await this.signalRHub.changeHubNameAndReconnect(this.hubName + this.selectedInsurerId + this.selectedJobType)
    this.getJobList()
  }

  @Watch('selectedJobType')
  private async onJobTypeChange() {
    await this.signalRHub.changeHubNameAndReconnect(this.hubName + this.selectedInsurerId + this.selectedJobType)
    this.getJobList()
  }

  private getJobList() {
    this.isLoading = true
    const self = this

    // uncheck previously selected jobs
    self.selectedJobList = []

    // select first tile automatic
    this.selectedTileName = this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
    // apply updated color to header
    self.updateHeaderColor(self.defaultTableHeaderColor)

    DashboardController.GetJobFinancialSummaryData(self.selectedInsurerId, self.selectedJobType)
      .then((res: JobFinancialSummaryDataModel | null) => {
        if (res) {
          self.jobList = res.jobs
          self.jobFinancialSummaryWidgetCount = res.jobFinancialSummaryWidgetCount
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading financial summary jobs, please try again', true)
        this.isLoading = false
      })
  }

  private get getCurrentSelectedFilter(): string {
    return this.selectedTileName
  }

  private async getJobListWithFilter(filterValue: string) {
    this.isLoading = true
    const res = await DashboardController.GetJobFinancialSummaryWidgetData(
      this.selectedInsurerId,
      filterValue,
      this.selectedJobType
    )
    if (res) {
      this.jobList = res
      this.pagination.page = 1
      // apply updated color to header
      this.updateHeaderColor(this.tileColorWithActiveFilter)
    }
    this.isLoading = false
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private openConfirmationForJobApprove(status: string) {
    this.checkIsAnyJobSelected(status).then((result: boolean) => {
      if (result) {
        this.openConfirmationPopup(status.toLowerCase())
      }
    })
  }

  private openJobRejectReasonDialogue() {
    this.checkIsAnyJobSelected('reject').then((result: boolean) => {
      if (result) {
        this.disputedReason = ''
        this.showDisputeDialog = true
      }
    })
  }

  private openConfirmationForJobToReview(status: string) {
    this.checkIsAnyJobSelected('complete the review').then((result: boolean) => {
      if (result) {
        this.openConfirmationPopup(status.toLowerCase(), 'complete the review of')
      }
    })
  }

  private openConfirmationForJobRecalculate(status: string) {
    this.checkIsAnyJobSelected('re-calculate').then((result: boolean) => {
      if (result) {
        this.openConfirmationPopup(status.toLowerCase(), 're-calculate')
      }
    })
  }

  private async checkIsAnyJobSelected(status: string): Promise<boolean> {
    if (this.selectedJobList.length === 0) {
      this.snackbarText = 'Please select job(s) to ' + status + '.'
      this.snackbar = true
      return false
    }
    return true
  }

  private openConfirmationPopup(actionMethod: string, displayValue?: string) {
    Shared.confirmationPopup.open(
      'Do you want to ' + (displayValue ? displayValue : actionMethod.toLowerCase()) + ' selected job(s)?',
      '',
      '',
      'No',
      'Yes',
      this,
      actionMethod + 'Jobs',
      actionMethod
    )
  }

  private onConfirmDisputingJob() {
    if (this.selectedJobList.length === 0) {
      this.snackbarText = 'Please select invoice(s) to dispute.'
      this.snackbar = true
      return
    }
    Shared.confirmationPopup.open(
      'Do you want to dispute the selected invoice(s)?',
      '',
      '',
      'No',
      'Yes',
      this,
      'confirmDisputeJob',
      ''
    )
  }

  private confirmDisputeJob() {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    DashboardController.confirmDisputeJob(listOfJobsModel)
      .then((res: boolean) => {
        if (res) {
          this.selectedJobList = []
          this.isLoading = false
          this.snackbarText = 'selected disputed job(s) approved'
          this.snackbar = true
        } else {
          this.selectedJobList = []
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting conform dispute job request, please try again', true)
        this.selectedJobList = []
        this.isLoading = false
      })
  }

  private approveJobs() {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    DashboardController.ApproveForJobInvoice(listOfJobsModel)
      .then((res: boolean) => {
        if (res) {
          this.selectedJobList = []
          this.isLoading = false
          this.snackbarText = 'selected job(s) approved'
          this.snackbar = true
        } else {
          this.selectedJobList = []
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting approve job invoice request, please try again', true)
        this.selectedJobList = []
        this.isLoading = false
      })
  }

  private rejectJobs() {
    const self = this
    self.validate().then((result: boolean) => {
      if (result) {
        this.isLoading = true
        const listOfJobsModel = this.getListOfJobsToBeProcessed()
        listOfJobsModel.disputedReason = this.disputedReason
        DashboardController.RejectForJobInvoice(listOfJobsModel)
          .then((res: boolean) => {
            if (res) {
              this.selectedJobList = []
              this.isLoading = false
              this.showDisputeDialog = false
              this.snackbarText = 'selected job(s) rejected'
              this.snackbar = true
            } else {
              this.selectedJobList = []
              this.isLoading = false
            }
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error submitting reject job invoice request, please try again', true)
            this.selectedJobList = []
            this.isLoading = false
          })
      }
    })
  }

  private getListOfJobsToBeProcessed(): ApproveListOfJobsModel {
    const listOfJobsModel: ApproveListOfJobsModel = new ApproveListOfJobsModel()
    listOfJobsModel.insurerId = this.selectedInsurerId

    const jobList: ApproveJob[] = []
    this.selectedJobList.forEach((selectedJob) => {
      const job = this.jobList.find((e) => e.jobId === selectedJob.jobId)
      if (job) {
        jobList.push({
          jobId: selectedJob.jobId,
          clientInvoiceDetailId: selectedJob.clientInvoiceDetailId,
        })
      }
    })
    listOfJobsModel.jobList = jobList

    return listOfJobsModel
  }

  private openBordereauDialog() {
    if (
      this.selectedJobType === 'SI' &&
      this.getCurrentSelectedFilter ===
        this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice] &&
      this.selectedJobList.length !== 1
    ) {
      this.snackbarText = 'Each invoice needs to be exported individually for SI job.'
      this.snackbar = true
      return
    }
    this.selectedBordereauId = null
    this.exportToBordereauDialog = true
    this.createBordereauReference()
    this.getSalesLedgerCodeList()
  }

  private openInvoiceDialog() {
    this.selectedBordereauId = null
    this.exportToInvoiceDialog = true
    this.createInvoiceReference()
    this.getSalesLedgerCodeList()
  }

  private async createBordereauReference() {
    if (
      this.getCurrentSelectedFilter !==
        this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice] ||
      (this.clientPolicies.updateFinancial && this.selectedJobList.length < 2)
    ) {
      return
    }
    this.bordereauGenerateLoader = true

    try {
      const reference = await InvoiceController.GenerateBordereauReference(this.selectedJobType)
      if (!reference) {
        throw new Error('')
      }
      this.selectedBordereauId = reference
    } catch (e) {
      eventBus.$emit(
        'errorHandler',
        'An error occurred generating the Bordereau reference, please try again later.',
        true
      )
      this.exportToBordereauDialog = false
      this.selectedBordereauId = null
    }

    this.bordereauGenerateLoader = false
  }

  private async createInvoiceReference() {
    if (
      this.getCurrentSelectedFilter !==
        this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice] ||
      !this.clientPolicies.updateFinancial ||
      this.selectedJobList.length !== 1
    ) {
      return
    }
    this.bordereauGenerateLoader = true

    try {
      const reference = await InvoiceController.GenerateInvoiceReference(
        this.selectedJobList[0].jobId,
        this.selectedInsurerId
      )
      if (!reference) {
        throw new Error('')
      }
      this.selectedBordereauId = reference
    } catch (e) {
      eventBus.$emit(
        'errorHandler',
        'An error occurred generating the Invoice reference, please try again later.',
        true
      )
      this.exportToInvoiceDialog = false
      this.selectedBordereauId = null
    }

    this.bordereauGenerateLoader = false
  }

  private async confirmCreateBordereau() {
    this.validate().then(async (result: boolean) => {
      if (result) {
        if (!this.selectedBordereauId) {
          return
        }
        this.bordereauConfirmLoader = true
        const bordereauRequest: ExportToBordereau = {
          jobInvoiceIdPairs: this.selectedJobList.map((j) => ({
            Key: j.jobId,
            Value: j.clientInvoiceDetailId,
          })),
          bordereauId: this.selectedBordereauId,
          clientId: this.selectedInsurerId.toString(),
          jobType: this.selectedJobType,
          salesLedgerCode: this.salesLedgerCode,
        }
        try {
          const res = await InvoiceController.ConfirmBordereau(bordereauRequest)
          if (!res) {
            throw new Error('')
          }
          if (
            this.getCurrentSelectedFilter ===
            this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice]
          ) {
            if (this.jobFinancialSummaryWidgetCount) {
              const total: number = this.selectedJobList
                .map((j) => j.totalCost)
                .reduce((prev: number, curr: number) => prev + curr, 0)
              this.jobFinancialSummaryWidgetCount.totalApprovedJobCost -= total
              this.jobFinancialSummaryWidgetCount.totalApprovedJobs -= this.selectedJobList.length
            }
            this.jobList = this.jobList.filter((j) => !this.selectedJobList.includes(j))
            this.selectedJobList = []
          }
          this.selectedBordereauId = null
        } catch (e) {
          eventBus.$emit('errorHandler', 'An error occurred creating the bordereau, please try again later.', true)
          this.exportToBordereauDialog = false
        } finally {
          this.exportToBordereauDialog = false
          this.bordereauConfirmLoader = false
        }
      }
    })
  }

  private async confirmCreateInvoice() {
    const validation = await this.validate()
    if (validation) {
      if (!this.selectedBordereauId) {
        return
      }
      this.bordereauConfirmLoader = true
      const invoiceRequest: ExportToInvoice = {
        jobId: this.selectedJobList[0].jobId,
        clientInvoiceDetailId: this.selectedJobList[0].clientInvoiceDetailId,
        invoiceId: this.selectedBordereauId,
        clientId: this.selectedInsurerId.toString(),
        salesLedgerCode: this.salesLedgerCode,
      }
      try {
        const res = await InvoiceController.ConfirmInvoice(invoiceRequest)
        if (!res) {
          throw new Error('')
        }
        if (
          this.getCurrentSelectedFilter ===
          this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice]
        ) {
          if (this.jobFinancialSummaryWidgetCount) {
            const total: number = this.selectedJobList
              .map((j) => j.totalCost)
              .reduce((prev: number, curr: number) => prev + curr, 0)
            this.jobFinancialSummaryWidgetCount.totalApprovedJobCost -= total
            this.jobFinancialSummaryWidgetCount.totalApprovedJobs -= this.selectedJobList.length
          }
          this.jobList = this.jobList.filter((j) => !this.selectedJobList.includes(j))
          this.selectedJobList = []
        }
        this.selectedBordereauId = null
      } catch (e) {
        eventBus.$emit('errorHandler', 'An error occurred creating the invoice, please try again later.', true)
      } finally {
        this.exportToInvoiceDialog = false
        this.bordereauConfirmLoader = false
      }
    }
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('jobFinancialSummaryScope')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private recalculateJobs() {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    DashboardController.RecalculateInvoiceOfJobs(listOfJobsModel)
      .then((res: boolean) => {
        if (res) {
          this.selectedJobList = []
          this.isLoading = false
          this.snackbarText = 'selected job(s) re-calculated. Price will be updated soon.'
          this.snackbar = true
        } else {
          this.selectedJobList = []
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting recalculate job invoice request, please try again', true)
        this.selectedJobList = []
        this.isLoading = false
      })
  }

  private reviewcompleteJobs() {
    this.isLoading = true
    const listOfJobsModel = this.getListOfJobsToBeProcessed()
    DashboardController.CompleteJobReview(listOfJobsModel)
      .then((res: boolean) => {
        if (res) {
          this.selectedJobList = []
          this.isLoading = false
          this.snackbarText = 'selected job(s) reviewed.'
          this.snackbar = true
        } else {
          this.selectedJobList = []
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting complete job review request, please try again', true)
        this.selectedJobList = []
        this.isLoading = false
      })
  }

  private onTopTileFilter(color: string) {
    const values = 'values'
    let elementName = ''
    let isSameFilterClicked = false
    Object[values](this.getTopTiles).forEach((element) => {
      if (element.backgroundColor === color) {
        if (element.isFilterActive) {
          isSameFilterClicked = true
          this.selectedTileName = element.name as string
        } else {
          this.selectedJobList = []
          element.isFilterActive = true
          this.tileColorWithActiveFilter = color + ' white--text'
          elementName = element.name as string
          this.selectedTileName = element.name as string
        }
      } else {
        element.isFilterActive = false
      }
    })

    // return of same filter clicked
    if (isSameFilterClicked) {
      return
    }

    // bind new job list as per selected tile
    if (elementName) {
      this.getJobListWithFilter(elementName).catch(() => {
        eventBus.$emit('errorHandler', 'Filter failed loading', true)
      })
    }
  }

  private updateHeaderColor(colorToUpdate: string) {
    // remove the disputed Reason header from the table
    const index = this.headers.findIndex((x) => x.value === 'disputedReason')
    if (this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]) {
      if (!(index !== -1)) {
        this.headers.push(this.disputedReasonTableHeader)
      }
    } else {
      if (index !== -1) {
        this.headers.splice(index, 1)
      }
    }

    // apply the selected color to the table
    this.headers.forEach((header) => {
      header.class = colorToUpdate
    })

    // set checkbox styling
    this.setSelectAllCheckboxStyle(colorToUpdate)
  }

  private setSelectAllCheckboxStyle(colorToUpdate: string) {
    const asd = this.$el as any
    if (asd) {
      const el: HTMLElement | null = asd.querySelector('.v-datatable--select-all thead tr th')
      if (el) {
        el.className = colorToUpdate
      }
    }
  }

  private get getTopTiles(): { [k: string]: TopTileProps } {
    if (this.jobFinancialSummaryWidgetCount == null) {
      return {}
    }
    return {
      jobsReadyForApprovals: {
        title: 'Ready for Approvals',
        value: this.jobFinancialSummaryWidgetCount.totalCompletedJobs,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: 'blue day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForApproval],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready for approvals',
            value:
              this.jobFinancialSummaryWidgetCount.totalCompletedJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalCompletedJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalCompletedJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalCompletedJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      jobsReadyForReview: {
        title: 'Awaiting Review from CET',
        value: this.jobFinancialSummaryWidgetCount.totalToReviewJobs,
        showChart: false,
        chartData: [],
        chartMaxValue: 100,
        backgroundColor: 'purple day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ToReview],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready to review',
            value:
              this.jobFinancialSummaryWidgetCount.totalToReviewJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalToReviewJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalToReviewJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalToReviewJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      disputedJobs: {
        title: 'Disputed',
        value: this.jobFinancialSummaryWidgetCount.totalDisputedJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'red day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Disputed],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs disputed',
            value:
              this.jobFinancialSummaryWidgetCount.totalDisputedJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalDisputedJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalDisputedJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalDisputedJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      jobsReadyForInvoice: {
        title: 'Ready for Invoice',
        value: this.jobFinancialSummaryWidgetCount.totalApprovedJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'orange day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.ReadyForInvoice],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of jobs ready for Invoice',
            value:
              this.jobFinancialSummaryWidgetCount.totalApprovedJobs != null
                ? this.jobFinancialSummaryWidgetCount.totalApprovedJobs
                : 0,
            displayValue: (v: number) => v,
          },
          {
            type: 'img',
            path: '',
            alt: 'Total of material and labour cost',
            value: this.getFormatedCurrency(
              this.jobFinancialSummaryWidgetCount.totalApprovedJobCost != null
                ? this.jobFinancialSummaryWidgetCount.totalApprovedJobCost
                : 0
            ),
            displayValue: (v: number) => v,
          },
        ],
      },
      outstandingJobs: {
        title: 'Outstanding',
        value: this.jobFinancialSummaryWidgetCount.totalOutstandingJobs,
        showChart: false,
        chartData: [],
        backgroundColor: 'green day-counts-section',
        textColor: Shared.colorWhite,
        showArrow: false,
        hasFilter: true,
        isFilterActive:
          this.selectedTileName === this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Outstanding]
            ? true
            : false,
        name: this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Outstanding],
        infoValue: [
          {
            type: 'icon',
            path: 'work',
            alt: 'Number of outstanding Jobs',
            value:
              this.jobFinancialSummaryWidgetCount.totalOutstandingJobs !== null
                ? this.jobFinancialSummaryWidgetCount.totalOutstandingJobs
                : 0,
            displayValue: (v: number) => v,
          },
        ],
      },
    }
  }

  public get checkCETLoginUser(): boolean {
    if (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
      return true
    }
    return false
  }

  private getPolicyLimitRemaining(policyLimit: string) {
    const tempPolicyLimit = policyLimit.split(',')
    if (tempPolicyLimit.length > 1) {
      const formattedPolicyLimit: string[] = []
      tempPolicyLimit.forEach((p) => {
        formattedPolicyLimit.push(this.getFormatedCurrency(Number(p)))
      })
      return formattedPolicyLimit.join(', ')
    } else {
      return this.getFormatedCurrency(Number(policyLimit))
    }
  }

  private dateFormat(): string {
    return Store.Instance.state.Environment.DateFormat
  }

  private selectableDateTime(date: moment.Moment | null): string {
    if (date) {
      return moment(date).format(DateTimePicker.DATE_FORMAT)
    }
    return ''
  }

  private getSalesLedgerCodeList() {
    // get Sales Ledger Code List
    this.salesLedgerCodeList = []
    if (this.insurerSalesLedgers && this.insurerSalesLedgers.length > 0) {
      this.insurerSalesLedgers.map((x) => this.salesLedgerCodeList.push(x.code))
      this.salesLedgerCode = this.salesLedgerCodeList.length === 1 ? this.salesLedgerCodeList[0] : ''
    }
  }

  private get clientPolicies(): ClientAuthPolicies {
    return this.$store.getters['authModule/clients']
  }

  private get hasRightForMarking() {
    return (
      this.clientPolicies.updateFinancial &&
      this.getCurrentSelectedFilter !== this.jobFinancialSummaryWidget[this.jobFinancialSummaryWidget.Outstanding]
    )
  }

  private redirectToJob(jobId: string) {
    this.selectedJobIdToExpand = jobId
    this.openJobView = true
    Shared.passJobIdInHeader(this.selectedJobIdToExpand)
  }

  private closeJobView() {
    this.openJobView = false
    this.selectedJobIdToExpand = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.job-info >>> h2 {
  font-size: 38px;
  display: inline-block;
  vertical-align: top;
}

.job-info.request-info >>> .card {
  height: 78px !important;
  display: flex;
  flex-wrap: wrap;
}

.job-info.request-info >>> .card .card__text {
  display: none;
  align-self: flex-end;
}

.job-info.request-info >>> .card .card__title {
  align-self: flex-start;
}

@media only screen and (max-width: 1200px) {
  .job-info >>> h2 {
    font-size: 30px;
  }

  .job-info.request-info >>> .card {
    height: 100px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .job-info.request-info >>> .card .card__title {
    font-size: 16px;
  }
}

.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}

.gridView {
  border: 0px;
}

.gridView >>> thead th,
.gridView >>> tbody tr > td {
  padding: 0px 15px !important;
}

.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
}

.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}

.gridView tbody tr td:last-child {
  padding-left: 10px;
}

.gridView >>> .vue-star-rating-rating-text {
  margin-top: -2px;
}

.gridView >>> tbody tr:first-child td:last-child {
  text-align: right;
}

.search-job {
  display: inline-block;
  min-width: 450px;
  margin-right: 15px;
}

.job-table >>> .table thead > tr > th:nth-last-child(1) {
  min-width: 208px;
  text-align: center !important;
}

.job-table >>> .table thead > tr > th:nth-child(2) {
  width: 5%;
}

.job-table >>> .table thead > tr > th:nth-child(3) {
  min-width: 300px;
}

.job-table >>> .v-datatable thead tr.v-datatable__progress th.column {
  padding: 0px 0px !important;
}

.text-right {
  text-align: right;
}

.day-counts-section {
  padding: 15px 10px 8px;
}

.day-counts-section .count {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  padding-left: 5px;
}

.day-counts-section .icon {
  vertical-align: top;
}

.day-counts-section .icon img {
  width: 18px;
  padding-top: 2px !important;
}

.search-controls >>> .btn {
  vertical-align: top;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #fff;
  z-index: 205;
  opacity: 0.2;
}

.job-list >>> tr.datatable__progress th {
  padding: 0px !important;
}

.icon-arrow {
  position: relative;
}

.bordereau-id {
  font-size: 22px;
  font-weight: 600;
  padding-left: 5px;
}

.hide-dashboard {
  visibility: hidden;
}

.show-dashboard {
  visibility: visible;
}

.dashboard >>> .v-card {
  margin-bottom: 15px !important;
}

.dashboard >>> .v-tabs .v-tabs__bar {
  background-color: #eeeeee;
  position: relative !important;
  width: auto !important;
  z-index: 1;
  top: 0px !important;
}

.dashboard >>> .v-tabs .v-tabs__div {
  width: auto !important;
}
</style>
