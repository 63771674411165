<template>
  <div>
    <div class="mb-2">
      <h4>Job Visit Note</h4>
    </div>

    <div v-if="requestBody.id">
      <div v-if="emergencyName" class="mb-1">
        <label>Emergency: </label>
        <span>{{ emergencyName }}</span>
      </div>
      <div v-if="engineerName" class="mb-1">
        <label>Engineer: </label>
        <span>{{ engineerName }}</span>
      </div>
      <div v-if="requestBody.visitNote" class="mb-1">
        <label>Visit Note: </label>
        <span>{{ requestBody.visitNote }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import Emergency from '@/models/Emergency'
import RequestedJob from '@/models/requests/base/requested-job'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AddEngineerJobVisitNoteLog extends Vue {
  @Prop() private requestBody: any

  private get emergencyName(): string {
    if (this.requestedJob && this.engineerVisitDetail) {
      const item = this.requestedJob.emergencies.find(
        (e: Emergency) => e.typeId === this.engineerVisitDetail!.forEmergencyTypeId
      )
      return item ? `${item.typeDescription} - ${item.detailDescription}` : ''
    }
    return ''
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get engineerVisitDetail(): EngineerVisitDetail | undefined {
    return this.requestedJob
      ? this.requestedJob.engineerVisitDetails.find((x) => x.id === this.requestBody.id)
      : undefined
  }

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.requestBody.id)
  }
}
</script>
