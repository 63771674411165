<template>
  <v-dialog v-model="clearEtaDialogVisible" max-width="650" persistent content-class="v-dialog--scrollable">
    <v-card v-if="job">
      <v-toolbar card dark color="primary">
        <v-toolbar-title v-if="isNoCallButtonClicked">Clear ETA Note</v-toolbar-title>
        <v-toolbar-title v-else>Call Customer</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider />
      <v-card-text class="scroll-content-dialog px-4 pt-4">
        <v-layout v-if="isNoCallButtonClicked" wrap>
          <v-textarea
            ref="noCallReasonRef"
            v-model="noCallReason"
            v-validate="'required'"
            label="Reason"
            rows="5"
            class="required"
          ></v-textarea>
        </v-layout>
        <v-layout v-else wrap>
          <v-flex>
            <span class="primary--text">{{ job.clientFullName }}</span>
            <div v-if="job.mobilePhone && job.mobilePhone.length > 0" class="my-2">
              <v-icon class="pink--text">local_phone</v-icon>
              <span>
                <span class="grey--text text--darken-2">
                  <a href="#" class="number" @click="onCallCustomer(job.mobilePhone)">
                    {{ job.mobilePhone }}
                  </a>
                </span>
              </span>
            </div>
            <div v-else class="my-2">
              <span>No number available for customer.</span>
            </div>
            <span class="red--text">
              If no call to the customer is required, you will be asked to leave a note on the next page.
            </span>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-4">
        <v-spacer></v-spacer>
        <v-btn
          v-if="job.mobilePhone && job.mobilePhone.length > 0 && !isNoCallButtonClicked"
          color="primary"
          flat="flat"
          @click.native="closeClearEtaDialog"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          class="mr-0 assignedContactor-btn"
          @click="isNoCallButtonClicked ? saveNoCallReason() : noCallSelected()"
        >
          {{ isNoCallButtonClicked ? 'Submit' : 'No Call' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import storeGetters from '@/storeGetters'
import Job from '@/models/Job'
import CallParameters from '@/models/twilio/CallParameters'
import eventBus from '@/common/bus'
import AddNewJobNote from '@/models/requests/AddNewJobNote'
import store from '@/store'
import JobController from '@/api/jobController'

@Component({})
export default class ClearEtaDialog extends Vue {
  @Prop() private jobId: string
  private isNoCallButtonClicked = false
  private noCallReason = ''

  private closeClearEtaDialog() {
    this.isNoCallButtonClicked = false
    this.$store.dispatch('jobModule/showClearEtaDialog', false)
  }

  private get clearEtaDialogVisible() {
    return this.$store.getters['jobModule/showClearEta']
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private onCallCustomer(contactNumber: string) {
    const callParameters = new CallParameters()
    callParameters.to = contactNumber
    callParameters.jobId = this.jobId
    callParameters.emergencyId = ''
    eventBus.$emit('callIconClick', callParameters)
  }

  private noCallSelected() {
    this.isNoCallButtonClicked = true
  }

  private saveNoCallReason() {
    const input = this.$refs.noCallReasonRef as HTMLElement
    if (!this.noCallReason) {
      input.focus()
      return
    }

    this.createJobNote('ETA Cleared - No Call Reason', this.noCallReason)
    this.closeClearEtaDialog()
  }

  private async createJobNote(title: string, description: string) {
    const jobNote = new AddNewJobNote()
    jobNote.jobId = this.jobId
    jobNote.noteType = 'Miscellaneous'
    jobNote.title = title
    jobNote.description = description
    jobNote.loggedByUserId = store.Instance.state.SessionDetail.detailRecord.UserDetail.id
    jobNote.loggedByUserName = `${store.Instance.state.SessionDetail.detailRecord.UserDetail.firstName} ${store.Instance.state.SessionDetail.detailRecord.UserDetail.lastName}`
    jobNote.callSid = ''

    await JobController.AddJobNote(jobNote)
      .then((res: boolean) => {
        if (!res) {
          alert('error adding job note')
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving job note, please try again', true)
      })
  }
}
</script>

<style scoped>
.number {
  color: #4a4a4a;
}
.number:hover {
  color: #c9004b;
}
</style>
