<template>
  <div class="jobCompletePreview inner-content">
    <v-progress-circular
      v-if="!isLoaded"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <v-layout v-else wrap class="px-2 pt-3">
      <v-flex xs12>
        <v-card class="pa-3">
          <v-layout wrap>
            <v-flex xs12 px-2>
              <div class="my-3">
                <div v-if="job.status === 'Completed'" class="text-xs-center successfull-msg center-content">
                  <v-icon color="green" class="mb-3">check_circle</v-icon>
                  <h1 class="pb-4">Congratulations !</h1>
                  <h3 class="pb-4">Your job is successfully completed.</h3>
                </div>
                <div v-if="job.status === 'Cancelled'" class="text-xs-center successfull-msg center-content">
                  <v-icon color="error" class="mb-3">cancel</v-icon>
                  <h3 class="pb-4">Your job is cancelled.</h3>
                </div>
                <v-divider></v-divider>
                <!-- Job start & End time with duration -->
                <v-layout py-2 wrap>
                  <v-flex v-if="job.createdAt" xs6 class="small-text" mb-2>
                    <b>Start Time:</b>
                    {{ getFormattedDate(job.createdAt) }}
                  </v-flex>
                  <v-flex v-if="job.jobCompletedAtUtc" xs6 class="small-text" mb-2>
                    <b>End Time:</b>
                    {{ getFormattedDate(job.jobCompletedAtUtc) }}
                  </v-flex>
                  <v-flex v-if="getJobDurationTime" xs12 class="small-text" mb-2>
                    <b>Duration:</b>
                    {{ getJobDurationTime }}
                  </v-flex>
                  <v-flex xs12 mb-2>
                    <v-divider v-if="canSeePromoterScore && job.jobType === 'HE'"></v-divider>
                  </v-flex>
                  <v-flex v-if="canSeePromoterScore && job.jobType === 'HE'" xs12>
                    <v-card class="elevation-0" card-box-shadow-none>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-layout row wrap class="mt-1 mb-1">
                            <v-flex xs12 mb-1>
                              <span class="v-sup"><b>Recommendation:</b></span>
                              <star-rating
                                v-if="job.recommendationScore !== null"
                                :star-size="20"
                                class="d-inline-block pl-2"
                                :rating="job.recommendationScore / 2"
                                :max-rating="5"
                                active-color="#e7711b"
                                :increment="0.1"
                                :fixed-points="1"
                                :read-only="true"
                              ></star-rating>
                            </v-flex>
                            <v-flex xs12 mb-1>
                              <span class="v-sup"><b>Service Quality:</b></span>
                              <star-rating
                                v-if="job.serviceQualityScore !== null"
                                :star-size="20"
                                class="d-inline-block pl-2"
                                :rating="job.serviceQualityScore / 2"
                                :max-rating="5"
                                active-color="#e7711b"
                                :increment="0.1"
                                :fixed-points="1"
                                :read-only="true"
                              ></star-rating>
                            </v-flex>
                            <v-flex xs12>
                              <span><b>Quality Comments:</b></span>
                              {{ job.qualityComments ? job.qualityComments : '' }}
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-divider v-if="getIsRecordExists"></v-divider>
                <!-- bind pictures with emergency name -->
                <v-layout
                  v-for="emergency in getEmergencyList"
                  :key="emergency.id"
                  :class="getIsRecordExists ? 'left-content-wrap pb-2 wrap mt-3' : ''"
                >
                  <v-flex v-if="getEmergencyWiseAfterJobPicture(emergency.typeId).length > 0">
                    <v-flex xs12 mb-2 class="d-flex">
                      <h3>{{ emergency.detailDescription }}</h3>
                      <div class="signechar-img">
                        <img :src="getCustomerSignature(emergency.typeId)" />
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <PictureUpload
                        :job-id="jobId"
                        :picture-list="getEmergencyWiseAfterJobPicture(emergency.typeId)"
                      ></PictureUpload>
                    </v-flex>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout wrap>
                  <!-- bind contractor invoice detail -->
                  <v-flex
                    v-if="job && !isJobTypeSI && getContractorInvoiceDetails && getContractorInvoiceDetails.length > 0"
                    xs12
                  >
                    <h4 class="mb-2 mt-3">Contractor Cost</h4>
                    <v-expansion-panel class="data-accordion elevation-0">
                      <template v-for="(contractorInvoiceDetail, i) in getContractorInvoiceDetails">
                        <v-expansion-panel-content
                          v-if="contractorInvoiceDetail.invoiceDetail"
                          :key="i"
                          class="grey lighten-5 mb-3 elevation-1 contractorInvoiceDetails"
                        >
                          <v-layout slot="header" wrap class="align-center">
                            <v-flex xs10>
                              <b class="primary--text mt-1 d-inline-block">
                                {{ getContractorCompanyName(contractorInvoiceDetail.contractorAppointedDetailIds[0]) }}
                              </b>
                              <span>
                                (
                                {{ getEmergencyTypeDescription(contractorInvoiceDetail.contractorAppointedDetailIds) }}
                                )
                              </span>
                              <span
                                v-if="
                                  showContractorRateButton(
                                    contractorInvoiceDetail.status,
                                    contractorInvoiceDetail.ratePackageId
                                  )
                                "
                                class="ml-1"
                              >
                                <v-tooltip top>
                                  <template #activator="{ on }">
                                    <v-btn
                                      icon
                                      small
                                      class="ma-0 view-contractor-rate"
                                      v-on="on"
                                      @click.stop="
                                        openContractorRateDialog(
                                          contractorInvoiceDetail.ratePackageId,
                                          contractorInvoiceDetail.contractorId
                                        )
                                      "
                                    >
                                      <v-icon small class="primary--text view-rate-icon">rate_review</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View Rate Detail</span>
                                </v-tooltip>
                              </span>
                            </v-flex>
                            <v-flex xs2 text-xs-left text-lg-right>
                              <b class="primary--text">
                                {{
                                  getFormatedCurrency(
                                    contractorInvoiceDetail.invoiceDetail.materialCost +
                                      contractorInvoiceDetail.invoiceDetail.labourCost +
                                      contractorInvoiceDetail.additionalCharge
                                  )
                                }}
                              </b>
                            </v-flex>
                          </v-layout>

                          <v-card v-if="contractorInvoiceDetail.invoiceDetail" class="grey lighten-5">
                            <v-card-text>
                              <v-flex xs12 text-xs-right history-price>
                                <b class="grey--text text--darken-2">Labour</b>
                                :
                                <b
                                  :class="
                                    !contractorInvoiceDetail.invoiceDetail.overrideLabourCostReason
                                      ? 'primary--text'
                                      : 'secondary--text'
                                  "
                                >
                                  <v-tooltip bottom>
                                    <template #activator="{ on }">
                                      <v-badge overlap color="grey small-size-badge darken-2">
                                        <v-avatar :size="35" v-on="on">
                                          {{ getFormatedCurrency(contractorInvoiceDetail.invoiceDetail.labourCost) }}
                                        </v-avatar>
                                      </v-badge>
                                    </template>
                                    <span>
                                      {{
                                        getOverwriteReason(
                                          contractorInvoiceDetail.invoiceDetail.overrideLabourCostReason
                                        )
                                      }}
                                    </span>
                                  </v-tooltip>
                                </b>
                                <v-btn
                                  v-if="canOverwriteContractorCost(contractorInvoiceDetail.status)"
                                  icon
                                  class="ma-0 btn-overrideLabourCost"
                                  small
                                  @click="
                                    openOverWriteCostDialog(
                                      contractorInvoiceDetail.invoiceDetail.labourCost,
                                      contractorInvoiceDetail,
                                      invoiceCostTypeEnum[invoiceCostTypeEnum.Labour]
                                    )
                                  "
                                >
                                  <v-icon class="primary--text" small>edit</v-icon>
                                </v-btn>
                              </v-flex>

                              <v-flex xs12 text-xs-right history-price>
                                <b class="grey--text text--darken-2">Material</b>
                                :
                                <b
                                  :class="
                                    !contractorInvoiceDetail.invoiceDetail.overrideMaterialCostReason
                                      ? 'primary--text'
                                      : 'secondary--text'
                                  "
                                >
                                  <v-tooltip bottom>
                                    <template #activator="{ on }">
                                      <v-badge overlap color="grey small-size-badge darken-2">
                                        <v-avatar :size="35" v-on="on">
                                          {{ getFormatedCurrency(contractorInvoiceDetail.invoiceDetail.materialCost) }}
                                        </v-avatar>
                                      </v-badge>
                                    </template>
                                    <span>
                                      {{
                                        getOverwriteReason(
                                          contractorInvoiceDetail.invoiceDetail.overrideMaterialCostReason
                                        )
                                      }}
                                    </span>
                                  </v-tooltip>
                                </b>
                                <v-btn
                                  v-if="canOverwriteContractorCost(contractorInvoiceDetail.status)"
                                  icon
                                  class="ma-0 btn-overrideMaterialCost"
                                  small
                                  @click="
                                    openOverWriteCostDialog(
                                      contractorInvoiceDetail.invoiceDetail.materialCost,
                                      contractorInvoiceDetail,
                                      invoiceCostTypeEnum[invoiceCostTypeEnum.Material]
                                    )
                                  "
                                >
                                  <v-icon class="primary--text" small>edit</v-icon>
                                </v-btn>
                              </v-flex>

                              <v-flex xs12 text-xs-right history-price>
                                <b class="grey--text text--darken-2">Travel</b>
                                :
                                <b
                                  :class="
                                    !contractorInvoiceDetail.overrideAdditionalChargeReason
                                      ? 'primary--text'
                                      : 'secondary--text'
                                  "
                                >
                                  <v-tooltip bottom>
                                    <template #activator="{ on }">
                                      <v-badge overlap color="grey small-size-badge darken-2">
                                        <v-avatar :size="35" v-on="on">
                                          {{ getFormatedCurrency(contractorInvoiceDetail.additionalCharge) }}
                                        </v-avatar>
                                      </v-badge>
                                    </template>
                                    <span>
                                      {{ getOverwriteReason(contractorInvoiceDetail.overrideAdditionalChargeReason) }}
                                    </span>
                                  </v-tooltip>
                                </b>
                                <v-btn
                                  v-if="canOverwriteContractorCost(contractorInvoiceDetail.status)"
                                  icon
                                  class="ma-0 btn-overrideAdditionalCharge"
                                  small
                                  @click="
                                    openOverWriteCostDialog(
                                      contractorInvoiceDetail.additionalCharge,
                                      contractorInvoiceDetail,
                                      invoiceCostTypeEnum[invoiceCostTypeEnum.Travel]
                                    )
                                  "
                                >
                                  <v-icon class="primary--text" small>edit</v-icon>
                                </v-btn>
                              </v-flex>

                              <v-flex v-if="contractorInvoiceDetail.disputedReason" xs12 mt-2>
                                <v-divider class="my-2"></v-divider>
                              </v-flex>
                              <v-flex v-if="contractorInvoiceDetail.disputedReason" xs12 red--text text--darken-2 mt-2>
                                <b>Dispute Reason</b>
                              </v-flex>
                              <v-flex v-if="contractorInvoiceDetail.disputedReason" xs12 mt-1 grey--text text--darken-2>
                                {{ contractorInvoiceDetail.disputedReason }}
                              </v-flex>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </template>
                    </v-expansion-panel>
                  </v-flex>
                  <!-- bind client invoice detail -->
                  <v-flex v-if="getClientInvoiceDetail && getClientInvoiceDetail.length > 0" xs12>
                    <h4 class="mb-2 mt-3">Client Cost</h4>
                    <template v-if="job && !isJobTypeSI">
                      <v-expansion-panel class="data-accordion elevation-0">
                        <template v-for="(clientInvoiceDetail, i) in getClientInvoiceDetail">
                          <v-expansion-panel-content
                            v-if="clientInvoiceDetail.invoiceDetail"
                            :key="i"
                            class="grey lighten-5 mb-3 elevation-1"
                          >
                            <v-layout slot="header" wrap class="align-center">
                              <v-flex xs10>
                                <b class="primary--text mt-1 d-inline-block">
                                  {{ clientInvoiceDetail.policyName }}
                                </b>
                                <span
                                  v-if="
                                    showClientRateButton(clientInvoiceDetail.status, clientInvoiceDetail.ratePackageId)
                                  "
                                  class="ml-1"
                                >
                                  <v-tooltip top>
                                    <template #activator="{ on }">
                                      <v-btn
                                        icon
                                        small
                                        class="ma-0 view-client-rate"
                                        v-on="on"
                                        @click.stop="openClientRateDialog(clientInvoiceDetail.ratePackageId)"
                                      >
                                        <v-icon small class="primary--text view-rate-icon">rate_review</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>View Rate Detail</span>
                                  </v-tooltip>
                                </span>
                              </v-flex>
                              <v-flex xs2 text-xs-left text-lg-right>
                                <b class="primary--text">
                                  {{ getFormatedCurrency(getTotalClientCost(clientInvoiceDetail.id)) }}
                                </b>
                              </v-flex>
                            </v-layout>
                            <v-card v-if="clientInvoiceDetail.invoiceDetail" class="grey lighten-5">
                              <v-card-text>
                                <v-flex xs12 text-xs-right history-price>
                                  <b class="grey--text text--darken-2">Labour</b>
                                  :
                                  <b
                                    :class="
                                      !clientInvoiceDetail.invoiceDetail.overrideLabourValueReason
                                        ? 'primary--text'
                                        : 'secondary--text'
                                    "
                                  >
                                    <v-tooltip bottom>
                                      <template #activator="{ on }">
                                        <v-badge overlap color="grey small-size-badge darken-2">
                                          <v-avatar :size="35" v-on="on">
                                            {{ getFormatedCurrency(clientInvoiceDetail.invoiceDetail.labourValue) }}
                                          </v-avatar>
                                        </v-badge>
                                      </template>
                                      <span>
                                        {{
                                          getOverwriteReason(
                                            clientInvoiceDetail.invoiceDetail.overrideLabourValueReason
                                          )
                                        }}
                                      </span>
                                    </v-tooltip>
                                  </b>
                                  <v-btn
                                    v-if="canOverwriteClientCost(clientInvoiceDetail.status)"
                                    icon
                                    class="ma-0 btn-overrideLabourForClient"
                                    small
                                    @click="
                                      openOverWriteCostDialog(
                                        clientInvoiceDetail.invoiceDetail.labourValue,
                                        clientInvoiceDetail,
                                        invoiceCostTypeEnum[invoiceCostTypeEnum.Labour]
                                      )
                                    "
                                  >
                                    <v-icon class="primary--text" small>edit</v-icon>
                                  </v-btn>
                                </v-flex>

                                <v-flex xs12 text-xs-right history-price>
                                  <b class="grey--text text--darken-2">Material</b>
                                  :
                                  <b
                                    :class="
                                      !clientInvoiceDetail.invoiceDetail.overrideMaterialValueReason
                                        ? 'primary--text'
                                        : 'secondary--text'
                                    "
                                  >
                                    <v-tooltip bottom>
                                      <template #activator="{ on }">
                                        <v-badge overlap color="grey small-size-badge darken-2">
                                          <v-avatar :size="35" v-on="on">
                                            {{ getFormatedCurrency(clientInvoiceDetail.invoiceDetail.materialValue) }}
                                          </v-avatar>
                                        </v-badge>
                                      </template>
                                      <span>
                                        {{
                                          getOverwriteReason(
                                            clientInvoiceDetail.invoiceDetail.overrideMaterialValueReason
                                          )
                                        }}
                                      </span>
                                    </v-tooltip>
                                  </b>
                                  <v-btn
                                    v-if="canOverwriteClientCost(clientInvoiceDetail.status)"
                                    icon
                                    class="ma-0 btn-overrideMaterialForClient"
                                    small
                                    @click="
                                      openOverWriteCostDialog(
                                        clientInvoiceDetail.invoiceDetail.materialValue,
                                        clientInvoiceDetail,
                                        invoiceCostTypeEnum[invoiceCostTypeEnum.Material]
                                      )
                                    "
                                  >
                                    <v-icon class="primary--text" small>edit</v-icon>
                                  </v-btn>
                                </v-flex>

                                <v-flex xs12 text-xs-right history-price>
                                  <b class="grey--text text--darken-2">Handling Fee</b>
                                  :
                                  <b
                                    :class="
                                      !clientInvoiceDetail.invoiceDetail.overrideHandlingFeeReason
                                        ? 'primary--text'
                                        : 'secondary--text'
                                    "
                                  >
                                    <v-tooltip bottom>
                                      <template #activator="{ on }">
                                        <v-badge overlap color="grey small-size-badge darken-2">
                                          <v-avatar :size="35" v-on="on">
                                            {{ getFormatedCurrency(clientInvoiceDetail.invoiceDetail.handlingFee) }}
                                          </v-avatar>
                                        </v-badge>
                                      </template>
                                      <span>
                                        {{
                                          getOverwriteReason(
                                            clientInvoiceDetail.invoiceDetail.overrideHandlingFeeReason
                                          )
                                        }}
                                      </span>
                                    </v-tooltip>
                                  </b>
                                  <v-btn
                                    v-if="canOverwriteClientCost(clientInvoiceDetail.status)"
                                    icon
                                    class="ma-0 btn-overrideHandlingFeeForClient"
                                    small
                                    @click="
                                      openOverWriteCostDialog(
                                        clientInvoiceDetail.invoiceDetail.handlingFee,
                                        clientInvoiceDetail,
                                        invoiceCostTypeEnum[invoiceCostTypeEnum.HandlingFee]
                                      )
                                    "
                                  >
                                    <v-icon class="primary--text" small>edit</v-icon>
                                  </v-btn>
                                </v-flex>

                                <v-flex
                                  xs12
                                  text-xs-right
                                  history-price
                                  :class="
                                    canOverwriteClientCost(clientInvoiceDetail.status)
                                      ? 'other-fees'
                                      : 'other-fees-responsive'
                                  "
                                >
                                  <b class="grey--text text--darken-2">Other Fee</b>
                                  :
                                  <b class="primary--text">
                                    <span>
                                      {{ getFormatedCurrency(getOtherFeesTotal(clientInvoiceDetail.id)) }}
                                    </span>
                                  </b>
                                </v-flex>

                                <v-flex v-if="clientInvoiceDetail.disputedReason" xs12 mt-2>
                                  <v-divider class="my-2"></v-divider>
                                </v-flex>
                                <v-flex v-if="clientInvoiceDetail.disputedReason" xs12 red--text text--darken-2 mt-2>
                                  <b>Dispute Reason</b>
                                </v-flex>
                                <v-flex v-if="clientInvoiceDetail.disputedReason" xs12 mt-1 grey--text text--darken-2>
                                  {{ clientInvoiceDetail.disputedReason }}
                                </v-flex>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </template>
                      </v-expansion-panel>
                    </template>
                    <template v-if="job && isJobTypeSI && getClientInvoiceDetailSI">
                      <v-layout wrap grey pa-2 lighten-4 mb-2 primary--text>
                        <v-flex xs10>
                          <b class="mt-1 d-inline-block pb-1">
                            {{ getClientInvoiceDetailSI.policyName }}
                          </b>
                        </v-flex>
                        <v-flex xs2 text-xs-left text-lg-right pt-1>
                          <b
                            :class="
                              !getClientInvoiceDetailSI.invoiceDetail.overrideLabourValueReason
                                ? 'primary--text'
                                : 'secondary--text'
                            "
                          >
                            <v-tooltip top>
                              <template #activator="{ on }">
                                <span v-on="on">
                                  <b class="primary--text">
                                    {{ getFormatedCurrency(getClientInvoiceDetailSI.invoiceDetail.labourValue) }}
                                  </b>
                                </span>
                              </template>
                              <span>
                                {{
                                  getOverwriteReason(getClientInvoiceDetailSI.invoiceDetail.overrideLabourValueReason)
                                }}
                              </span>
                            </v-tooltip>
                          </b>
                          <v-btn
                            v-if="canOverwriteClientCost(getClientInvoiceDetailSI.status)"
                            icon
                            class="ma-0 btn-si-overrideLabourForClient"
                            small
                            @click="
                              openOverWriteCostDialog(
                                getClientInvoiceDetailSI.invoiceDetail.labourValue,
                                getClientInvoiceDetailSI,
                                invoiceCostTypeEnum[invoiceCostTypeEnum.Labour]
                              )
                            "
                          >
                            <v-icon class="primary--text" small>edit</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-flex>
                  <!-- bind CTP detail -->
                  <v-flex v-if="job && !isJobTypeSI" xs12>
                    <h4 class="mb-2 mt-2">CTP</h4>
                    <v-layout wrap grey pa-2 lighten-4 mb-2 primary--text>
                      <v-flex xs10>
                        <b class="mt-1 d-inline-block pt-1">Total Paid Amount:</b>
                      </v-flex>
                      <v-flex xs2 text-xs-left text-lg-center pt-1>
                        <b>
                          {{ getTotalPaidAmount ? getFormatedCurrency(getTotalPaidAmount) : getFormatedCurrency(0) }}
                        </b>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <div v-if="showInvoiceButton" class="text-xs-right pt-4">
                  <v-btn
                    color="primary"
                    class="mr-0 mb-0 btn-invoice"
                    :disabled="
                      isUpdateInvoiceLoading ||
                      getClientInvoiceDetail.filter(
                        (c) => c.status.toString() === clientInvoiceStatus[clientInvoiceStatus.Accepted]
                      ).length === getClientInvoiceDetail.length
                    "
                    :loading="isUpdateInvoiceLoading"
                    @click="updateInvoiceStatus"
                  >
                    Create Sales Order
                  </v-btn>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showOverwriteCostDialog" max-width="450" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>
            Overwrite
            {{ job && job.jobType !== 'SI' ? invoiceCostType : '' }} Cost
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="onCancelOverwriteCost">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-layout wrap mb-2 primary--text>
            <v-flex class="prepenpound-icon grey lighten-3 px-2">
              <span class="primary--text headlinefont title pound-icon">&pound; &nbsp;</span>
              <v-text-field
                v-model="overwriteCost"
                v-validate="'min_value:0|decimal:2'"
                prepend-icon="close"
                name="input-1"
                :label="invoiceCostType + ' Cost Overwrite'"
                data-vv-scope="frmJobComplete"
                data-vv-name="Cost"
                :error-messages="errors.collect('Cost')"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                v-model="overwriteReason"
                v-validate="'required'"
                label="Description"
                class="required"
                required
                data-vv-scope="frmJobComplete"
                data-vv-name="Description"
                :error-messages="errors.collect('Description')"
                name="description"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" class="btn-cancelOverwriteCost" @click.native="onCancelOverwriteCost">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-save"
            :disabled="isLoading || !overwriteCost || overwriteCost === 0"
            :loading="isLoading"
            @click="onOverwriteInvoiceCost"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- client rate package dialog -->
    <v-dialog
      v-if="clientRateTemplateDialog && clientRatePackageList.length > 0"
      v-model="clientRateTemplateDialog"
      max-width="1200"
      persistent
      content-class="v-dialog--scrollable full-card-width"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Client Rate</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-rate-template-dialog" @click.native="clientRateTemplateDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-card class="py-0">
            <ClientRate
              ref="clientRateRef"
              :from-job-completion-screen="true"
              :all-client-rate-package-list.sync="clientRatePackageList"
              :policy-id="job && job.policyScheduleId ? job.policyScheduleId : ''"
              :rate-package-id.sync="ratePackageId"
              :selected-rate-package-in-policy="selectedRatePackage"
              :show-rate-template-in-dialog="true"
            ></ClientRate>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- contractor rate package dialog -->
    <v-dialog
      v-if="contractorRateTemplateDialog && contractorRatePackageList.length > 0"
      v-model="contractorRateTemplateDialog"
      max-width="1200"
      persistent
      content-class="v-dialog--scrollable full-card-width"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Contractor Rate</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-rate-template-dialog" @click.native="contractorRateTemplateDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-card class="py-0">
            <ContractorRate
              ref="contractorRateRef"
              :from-job-completion-screen="true"
              :all-contractor-rate-package-list.sync="contractorRatePackageList"
              :contractor-id="contractorId"
              :rate-package-id.sync="ratePackageId"
            ></ContractorRate>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import Emergency from '@/models/Emergency'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import PictureUpload from '@/components/timeline/PictureUpload.vue'
import { JobVisitStatus, ClientInvoiceStatus, ContractorInvoiceStatus, InvoiceCostType } from '@/common/enums'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import ContractorInvoiceDetailModel from '@/models/claim/ContractorInvoiceDetailModel'
import ClientInvoiceDetailModel from '@/models/claim/ClientInvoiceDetailModel'
import InvoiceDetailModel from '@/models/claim/InvoiceDetailModel'
import OverrideInvoiceCostRequestModel from '@/models/requests/OverrideInvoiceCostRequestModel'
import DocumentController from '@/api/documentController'
import InvoiceController from '@/api/invoiceController'
import StarRating from 'vue-star-rating'
import store from '@/store'
import DashboardController from '@/api/dashboardController'
import ApproveListOfJobsModel, { ApproveJob } from '@/models/claim/ApproveListOfJobsModel'
import eventBus from '@/common/bus'
import JobController from '@/api/jobController'
import ClientCostDetailModel from '@/models/client/ClientCostDetailModel'
import ClientRatePackageModel from '@/models/client/ClientRatePackageModel'
import RateController from '@/api/rateController'
import ClientRate from '@/components/ClientRate.vue'
import ContractorRate from '@/components/ContractorRate.vue'
import ContractorRatePackageModel from '@/models/contractor/ContractorRatePackageModel'
import ClientFixedFeeRateModel from '@/models/client/ClientFixedFeeRateModel'
import ComplaintAuthPolicies from '@/models/auth/complaint-auth-policies'

@Component({
  components: { PictureUpload, StarRating, ClientRate, ContractorRate },
})
export default class JobCompletePreview extends TimeLineItemBase {
  private emergencies: Emergency[] = []
  private customerSignatureURL: any = []
  private showOverwriteCostDialog = false
  private overwriteCost = 0
  private overwriteReason = ''
  private isLoading = false
  private overrideInvoiceCostModel: OverrideInvoiceCostRequestModel = new OverrideInvoiceCostRequestModel()
  private recordType = ''
  private clientInvoiceStatus = ClientInvoiceStatus
  private contractorInvoiceStatus = ContractorInvoiceStatus
  private isUpdateInvoiceLoading = false
  private invoiceCostType = ''
  private invoiceCostTypeEnum = InvoiceCostType
  private isLoaded = true
  private ratePackageId = ''
  private selectedRatePackage: ClientRatePackageModel | null = null
  private clientRatePackageList: ClientRatePackageModel[] = []
  private contractorRatePackageList: ContractorRatePackageModel[] = []
  private contractorId = ''
  private clientRateTemplateDialog = false
  private contractorRateTemplateDialog = false
  private allContractorRatePackageList: ContractorRatePackageModel[] = []
  private totalClientCostDetail: ClientCostDetailModel[] = []

  private get getIsRecordExists(): boolean {
    let isRecordExists = false
    for (const emergency of this.getEmergencyList) {
      const jobPictureList = this.engineerAfterJobPictureList.filter((e) => e.forEmergencyTypeId === emergency.typeId)
      isRecordExists = jobPictureList.length > 0 ? true : false
      if (isRecordExists) {
        break
      }
    }
    return isRecordExists
  }

  private get isJobTypeSI(): boolean {
    return this.job && this.job.jobType === 'SI' ? true : false
  }

  private get getAppointedContractors(): ContractorAppointedModel[] {
    return this.job ? this.job.contractorAppointed : []
  }

  public get checkCETLoginUser(): boolean {
    if (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
      return (
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.invoicing >=
        Store.Instance.state.Environment.CETUserMinimumInvoicingSkill
      )
    }
    return false
  }

  private get complaintPolicies(): ComplaintAuthPolicies {
    return this.$store.getters['authModule/complaints']
  }

  private get canSeePromoterScore(): boolean {
    return this.complaintPolicies.seePromoterScore
  }

  private get getEmergencyList(): Emergency[] {
    this.emergencies = []
    if (this.job) {
      this.job.emergencies
        .filter((e) => e.displayInTimeline)
        .forEach((emergency) => {
          this.emergencies.push(emergency)
        })
    }
    return this.emergencies
  }

  private getCustomerSignature(emergencyTypeId: number) {
    const fileURL = this.customerSignatureURL.find((e) => e.emergencyTypeId === Number(emergencyTypeId))
    if (fileURL) {
      return fileURL.signatureURL
    }
  }

  private get engineerAfterJobPictureList(): PictureUploadModel[] {
    if (this.job) {
      return this.job.claimPictures.filter((a: PictureUploadModel) => a.uploadedBy === 'EngineerAfterJob')
    }
    return []
  }

  private get getContractorInvoiceDetails(): ContractorInvoiceDetailModel[] {
    if (this.job && this.job.contractorInvoiceDetail.length > 0) {
      const invoiceDetailList = JSON.parse(JSON.stringify(this.job.contractorInvoiceDetail))
      return Shared.SortData(invoiceDetailList, 'createdAt')
    }
    return []
  }

  private get getClientInvoiceDetail(): ClientInvoiceDetailModel[] {
    if (this.job && this.job.clientInvoiceDetails && this.job.clientInvoiceDetails.length > 0) {
      const invoiceDetailList = JSON.parse(JSON.stringify(this.job.clientInvoiceDetails))
      return Shared.SortData(invoiceDetailList, 'createdAt')
    }
    return []
  }

  private get getClientInvoiceDetailSI(): ClientInvoiceDetailModel | null {
    if (this.job && this.job.clientInvoiceDetails && this.job.clientInvoiceDetails.length > 0) {
      const invoiceDetail = this.job.clientInvoiceDetails.find(
        (c) => c.description.toLowerCase() !== 'ivs' && c.description.toLowerCase() !== 'monitoring'
      )
      return invoiceDetail ? invoiceDetail : null
    }
    return null
  }

  private get getTotalPaidAmount(): number {
    if (this.getClientInvoiceDetail && this.getClientInvoiceDetail.length > 0) {
      return this.getClientInvoiceDetail.reduce((a, b) => a + (b.totalCTPPaidAmount ? b.totalCTPPaidAmount : 0), 0)
    }
    return 0
  }

  private get getJobDurationTime(): string {
    if (this.job && this.job.jobCompletedAtUtc !== null && this.job.createdAt !== null) {
      const startDate = moment(this.job.createdAt)
      const endDate = moment(this.job.jobCompletedAtUtc)
      const duration = moment.duration(endDate.diff(startDate))
      const seconds = duration.seconds()
      const minutes = duration.minutes()
      const hours = duration.hours()
      const days = duration.days()
      if (days > 0) {
        return days + ' Days ' + (hours > 0 ? hours + ' Hours ' : '') + (minutes > 0 ? minutes + ' Minutes' : '')
      } else if (hours > 0) {
        return hours + ' Hours ' + (minutes > 0 ? minutes + ' Minutes' : '')
      } else if (minutes > 0) {
        return minutes + ' Minutes ' + (seconds > 0 ? seconds + ' Seconds' : '')
      }
    }
    return ''
  }

  private get showInvoiceButton() {
    return this.job && this.getClientInvoiceDetail && this.getClientInvoiceDetail.length > 0
      ? this.job.jobType === 'HE' &&
          store.Instance.state.SessionDetail.canUpdateInvoice &&
          this.getClientInvoiceDetail.filter(
            (c) =>
              c.status.toString() === this.clientInvoiceStatus[this.clientInvoiceStatus.ReadyForApproval] ||
              c.status.toString() === this.clientInvoiceStatus[this.clientInvoiceStatus.Accepted]
          ).length > 0
      : false
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private canOverwriteClientCost(status: string): boolean {
    if (this.checkCETLoginUser) {
      return (
        status !== this.clientInvoiceStatus[this.clientInvoiceStatus.Accepted] &&
        status !== this.clientInvoiceStatus[this.clientInvoiceStatus.ExportedToBordereau]
      )
    }
    return false
  }

  private canOverwriteContractorCost(status: string): boolean {
    if (this.checkCETLoginUser) {
      return status === this.contractorInvoiceStatus[this.contractorInvoiceStatus.Accepted] ? false : true
    }
    return false
  }

  private getContractorCompanyName(appointedDetailId: string): string {
    const contractorDetail = this.getAppointedContractors.find((e) => e.id === appointedDetailId)
    if (contractorDetail) {
      return contractorDetail.companyName
    }
    return ''
  }

  private getEmergencyWiseAfterJobPicture(emergencyTypeId: number) {
    return this.engineerAfterJobPictureList.filter((e) => e.forEmergencyTypeId === emergencyTypeId)
  }

  private async GetSASTokenForBlob(url: string) {
    let fileURL = ''
    fileURL = await DocumentController.GetSASTokenForRecordingUrl(url).then((res: string | null) => {
      if (res && typeof res === 'string') {
        return res
      } else {
        return ''
      }
    })
    return fileURL
  }

  private getFormatedCurrency(value: number) {
    return value > 0 ? Shared.getFormatedCurrency(value) : 0
  }

  private async validate(): Promise<boolean> {
    let result = true
    const overwriteCostValidation: boolean = await this.$validator.validateAll('frmJobComplete')

    // set focus to non validate field
    if (!overwriteCostValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = overwriteCostValidation
    }

    return result
  }

  private openOverWriteCostDialog(
    originalPrice: number,
    invoiceDetailModel: ContractorInvoiceDetailModel | ClientInvoiceDetailModel,
    invoiceCostType: InvoiceCostType
  ) {
    this.invoiceCostType = invoiceCostType as any
    this.$validator.errors.items = []
    this.overrideInvoiceCostModel = new OverrideInvoiceCostRequestModel()
    this.overrideInvoiceCostModel.jobId = this.jobId
    this.overrideInvoiceCostModel.id = invoiceDetailModel.id
    this.overwriteCost = originalPrice
    this.recordType = invoiceDetailModel.type
    this.showOverwriteCostDialog = true
  }

  private onOverwriteInvoiceCost() {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.overrideInvoiceCostModel.overrideCost = this.overwriteCost
          this.overrideInvoiceCostModel.overrideReason = this.overwriteReason
          this.overrideInvoiceCostModel.invoiceCostType = this.invoiceCostTypeEnum[this.invoiceCostType]
          if (this.recordType === 'ContractorInvoiceDetail') {
            this.overwriteContractorCost()
          } else {
            this.overwriteClientCost()
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private overwriteContractorCost() {
    this.isLoading = true
    InvoiceController.OverrideContractorInvoiceCost(this.overrideInvoiceCostModel)
      .then((res: boolean) => {
        this.handleCostOverwriteResponse(res)
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating contractor invoice cost, please try again', true)
        this.handleCostOverwriteResponse(false)
      })
  }

  private overwriteClientCost() {
    this.isLoading = true
    InvoiceController.OverrideClientInvoiceCost(this.overrideInvoiceCostModel)
      .then((res: boolean) => {
        this.handleCostOverwriteResponse(res)
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating client invoice cost, please try again', true)
        this.handleCostOverwriteResponse(false)
      })
  }

  private handleCostOverwriteResponse(res: boolean) {
    if (res) {
      this.showOverwriteCostDialog = false
      this.overwriteCost = 0
      this.overwriteReason = ''
      this.invoiceCostType = ''
      this.overrideInvoiceCostModel = new OverrideInvoiceCostRequestModel()
    }
    this.isLoading = false
  }

  private onCancelOverwriteCost() {
    this.handleCostOverwriteResponse(true)
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
  }

  private getEmergencyTypeDescription(appointedDetailIds: string[]): string {
    const emergencyTypes: string[] = []
    appointedDetailIds.forEach((id) => {
      const contractorDetail = this.getAppointedContractors.find((e) => e.id === id)
      if (contractorDetail) {
        const emergency = this.getEmergencyList.find((e) => e.typeId === contractorDetail.forEmergencyTypeId)
        if (emergency) {
          emergencyTypes.push(emergency.typeDescription)
        }
      }
    })
    return emergencyTypes.join(', ')
  }

  private async GetClientCostDetailAsync() {
    this.isLoaded = false
    JobController.GetClientCostDetailAsync(this.jobId)
      .then((res: ClientCostDetailModel[]) => {
        if (res) {
          this.totalClientCostDetail = res
        }
        this.isLoaded = true
      })
      .catch((err) => {
        this.totalClientCostDetail = []
        eventBus.$emit('showSnackbar', 'something went wrong, please try again')
        this.isLoaded = true
      })
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private updateInvoiceStatus() {
    Shared.confirmationPopup.open(
      'Creating an invoice on this job will bypass the approval process and mark this job as ready to invoice, are you sure?',
      '',
      '',
      'No',
      'Yes',
      this,
      'confirmUpdateInvoiceStatus',
      ''
    )
  }

  private confirmUpdateInvoiceStatus() {
    this.isUpdateInvoiceLoading = true
    const readyForApprovalJobs = this.getClientInvoiceDetail.filter(
      (c) => c.status.toString() !== ClientInvoiceStatus[ClientInvoiceStatus.Accepted]
    )
    const model: ApproveJob = new ApproveJob()
    model.jobId = this.jobId
    model.clientInvoiceDetailId =
      readyForApprovalJobs && readyForApprovalJobs.length === 1 ? readyForApprovalJobs[0].id : ''
    DashboardController.ApproveJobInvoice(model)
      .then((res: boolean) => {
        this.isUpdateInvoiceLoading = false
        eventBus.$emit('showSnackbar', res ? 'Job is ready for invoice' : 'something went wrong, please try again')
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating invoice detail status', true)
        this.isUpdateInvoiceLoading = false
      })
  }

  private getOverwriteReason(reason: string): string {
    return reason ? 'Overwrite Reason: ' + reason : 'Original Cost'
  }

  private showContractorRateButton(status: string, ratePackageId: string): boolean {
    if (
      !this.isJobTypeSI &&
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      status !== this.contractorInvoiceStatus[this.contractorInvoiceStatus.Accepted] &&
      ratePackageId
    ) {
      return Store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.invoicing >= 2
    }
    return false
  }

  private showClientRateButton(status: string, ratePackageId: string): boolean {
    if (
      !this.isJobTypeSI &&
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      status !== this.clientInvoiceStatus[this.clientInvoiceStatus.Accepted] &&
      ratePackageId
    ) {
      return Store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.invoicing >= 2
    }
    return false
  }

  private getClientRatePackage() {
    RateController.GetClientRatePackageAsync(this.ratePackageId)
      .then((res: ClientRatePackageModel | null) => {
        if (res) {
          // initialize with ClientFixedFeeRateModel model if clientFixedFeeRates value is set to null
          if (res && res.clientFixedFeeRates === null) {
            res.clientFixedFeeRates = new ClientFixedFeeRateModel()
          }
          this.clientRatePackageList = [res]
          this.selectedRatePackage = res
          this.clientRateTemplateDialog = true
        } else {
          eventBus.$emit('showSnackbar', 'Error loading client rate package.')
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading client rate package.', true)
      })
  }

  private openClientRateDialog(ratePackageId: string) {
    if (ratePackageId) {
      this.ratePackageId = ratePackageId
      if (this.clientRatePackageList.length === 0) {
        this.getClientRatePackage()
      } else {
        this.clientRateTemplateDialog = true
      }
    }
  }

  private getContractorRatePackage() {
    RateController.GetContractorRatePackageAsync(this.ratePackageId)
      .then((res: ContractorRatePackageModel | null) => {
        if (res) {
          this.contractorRatePackageList = [res]
          this.allContractorRatePackageList.push(res)
          this.contractorRateTemplateDialog = true
        } else {
          eventBus.$emit('showSnackbar', 'Error loading contractor rate package.')
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor rate package.', true)
      })
  }

  private openContractorRateDialog(ratePackageId: string, contractorId: string) {
    this.contractorRatePackageList = []
    this.contractorId = contractorId
    if (ratePackageId) {
      this.ratePackageId = ratePackageId
      const ratePackage = this.allContractorRatePackageList.find((c) => c.id === this.ratePackageId)
      if (ratePackage) {
        this.contractorRatePackageList = [ratePackage]
        this.contractorRateTemplateDialog = true
      } else {
        this.getContractorRatePackage()
      }
    }
  }

  private getOtherFeesTotal(invoiceDetailId: string): number {
    const costDetail =
      this.totalClientCostDetail && this.totalClientCostDetail.length > 0
        ? this.totalClientCostDetail.find((c) => c.clientInvoiceDetailId === invoiceDetailId)
        : undefined
    return costDetail ? costDetail.otherFeesTotal : 0
  }

  private getTotalClientCost(invoiceDetailId: string): number {
    const costDetail =
      this.totalClientCostDetail && this.totalClientCostDetail.length > 0
        ? this.totalClientCostDetail.find((c) => c.clientInvoiceDetailId === invoiceDetailId)
        : undefined
    return costDetail ? costDetail.totalClientCost : 0
  }

  private created() {
    if (this.job && this.job.engineerVisitDetail.length > 0) {
      this.job.engineerVisitDetail.forEach((visitDetail) => {
        if (visitDetail.customerSignatureFileURL) {
          const url: string = visitDetail.customerSignatureFileURL.substring(
            visitDetail.customerSignatureFileURL.indexOf(this.jobId)
          )
          this.GetSASTokenForBlob(url).then((res) => {
            const data = { emergencyTypeId: visitDetail.forEmergencyTypeId, signatureURL: res }
            this.customerSignatureURL.push(data)
          })
        }
      })
    }

    // get total client cost
    if (!this.isJobTypeSI) {
      this.GetClientCostDetailAsync()
    }
  }
}
</script>

<style scoped>
.successfull-msg .v-icon {
  font-size: 80px;
}
.successfull-msg .center-content h1 {
  font-size: 36px;
  font-weight: normal;
}
.successfull-msg .center-content h3 {
  font-size: 22px;
  font-weight: normal;
}
.successfull-msg h1 {
  font-size: 36px;
  font-weight: normal;
}
.successfull-msg h3 {
  font-size: 22px;
  font-weight: normal;
}
.signechar-img img {
  width: 120px;
}
.left-content-wrap h3 {
  font-size: 14px;
  line-height: 20px;
}
.left-content-wrap .d-flex {
  align-items: center;
  justify-content: space-between;
}
.signechar-img {
  border: 1px solid #ededed;
  max-width: 90px;
  padding: 0;
  text-align: center;
}
.signechar-img img {
  width: 40px;
  height: 30px;
}
.img-block {
  text-align: center;
  cursor: pointer;
}
.img-block .up-images {
  border: 1px solid #ededed;
}
.img-block img {
  height: 100px;
  width: 100%;
  cursor: pointer;
  object-fit: contain;
  display: inherit;
}
.img-title {
  font-weight: 600;
  font-size: 12px;
}
.picture-craousel >>> .carousel__right .v-btn .v-btn__content .v-icon,
.picture-craousel >>> .carousel__left .v-btn .v-btn__content .v-icon {
  color: #fff;
  background-color: #e0e0e0;
  border-radius: 50%;
  font-size: 38px !important;
}
.craousel-dialog-close {
  top: 8px;
  right: 8px;
}
.prepenpound-icon >>> .v-input .v-icon {
  color: #eeeeee !important;
}
.prepenpound-icon {
  position: relative;
}
.pound-icon {
  top: 19px;
  position: ABSOLUTE;
  left: 15px;
  z-index: 1;
}
.icon-img {
  width: 22px;
}
.contractor-cost {
  position: relative;
}
/* .history-price{position:absolute; right: 0px; } */
.history-price-client {
  position: absolute;
  right: 8px;
  top: 33px;
}
.edit-btn {
  pointer-events: none;
}
.vue-star-rating >>> .vue-star-rating-rating-text {
  margin-top: 1px;
}
.v-sup {
  vertical-align: super;
}
.other-fees {
  padding-right: 68px;
}
.other-fees span {
  right: 55px;
  position: absolute;
}
.other-fees-responsive {
  padding-right: 41px;
}
.other-fees-responsive span {
  right: 22px;
  position: absolute;
}
.view-rate-icon {
  font-size: 25px !important;
}
@media (max-width: 1279px) {
  .successfull-msg .center-content h1 {
    font-size: 32px;
    line-height: 36px;
  }
  .successfull-msg h3 {
    font-size: 15px;
    line-height: 23px;
  }
  .successfull-msg h1 {
    font-size: 32px;
    line-height: 36px;
  }
  .successfull-msg h3 {
    font-size: 19px;
    line-height: 23px;
  }
  .job-complete-preview >>> .v-card > .layout > .flex {
    padding: 0 5px !important;
  }
}
@media (max-width: 1199px) {
  .successfull-msg .center-content h1 {
    font-size: 28px;
    line-height: 32px;
  }
  .img-block img {
    height: 70px;
  }
  .history-price,
  .history-price-client {
    position: static;
  }
}
</style>
