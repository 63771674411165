import { EngineerRequestStatus } from '@/common/enums'
import EngineerRequestBase from '../claim/engineerRequest/EngineerRequestBase'

export default class AddEngineerRequest {
  public jobId: string
  public forEmergencyTypeId: number
  public forEmergencyDetailId: number
  public engineerVisitDetailId: string
  public companyName: string
  public requestStatus: EngineerRequestStatus
  public engineerRequest: EngineerRequestBase[]
}
