import moment, { Moment } from 'moment'

export default class WaterTestModel {
  public testNumber: number
  public startTime: Moment | null
  public pressureAtZeroMinutes: number
  public pressureAtFiveMinutes: number
  public pressureAtTenMinutes: number
  public testNote = ''

  public constructor() {
    this.testNumber = 0
    this.startTime = null
  }
}
