import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import OnSiteAssessmentQA from './OnSiteAssessmentQAModel'
import FinalAssessmentQA from './FinalAssessmentQAModel'
import AssessmentQA from './AssessmentQA'

export default class VisitHealthAndSafetyProcess extends CosmosJobsDbRecordBase {
  public forEmergencyTypeId: number
  public forEmergencyDetailId: number
  public engineerVisitDetailId: string
  public jobAddress: string
  public jobInfo: string
  public jobInfoByClient: string
  public otherPeopleWorking: string
  public onSiteAssessmentQAs: OnSiteAssessmentQA[]
  public finalAssessmentQAs: FinalAssessmentQA[]
  public assessmentQAs: AssessmentQA[]
  public signOffSignatureURL: string
  public hasSignOff: boolean
}
