<template>
  <v-layout wrap>
    <v-flex v-if="items">
      <div class="small-text item-description" v-html="sanitize(items.description)"></div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import JobNoteModel from '@/models/claim/JobNoteModel'
import storeGetters from '@/storeGetters'

@Component
export default class JobNoteCard extends TimeLineItemBase {
  private get items(): JobNoteModel | null {
    if (this.jobId && this.itemId) {
      return storeGetters.getJobNote(this.jobId, this.itemId)
    }
    return null
  }

  private sanitize(content){
    return this.sanitizeHTML(content)
  }
}
</script>

<style scoped>
.item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  word-break: break-all;
}
</style>
