<template>
  <div class="internal-phone-number">
    <v-container class="pa-0">
      <v-layout row>
        <v-flex xs12 class="contact-number">
          <v-select
            v-model="selectedCountry"
            :items="countries"
            class="selectedCountry pt-0"
            append-icon=""
            hide-details
            label="Code"
            single-line
            :disabled="isDisabled"
            @change="changedCountryValue"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.cc || 'INT' }}
            </template>
            <template slot="item" slot-scope="data">
              <v-list-tile-avatar>
                <div :class="'flag flag-' + data.item.code" class="test"></div>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-sub-title
                  v-text="data.item.name + (data.item.cc ? ' (' + data.item.cc + ')' : '')"
                ></v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
          </v-select>
          <v-text-field
            v-if="isValidationRequired"
            ref="txtNumber"
            v-model="phoneNumber"
            v-validate="'required'"
            single-line
            class="call-number-txt pt-0 mt-0 required"
            label="Enter Number"
            :disabled="isDisabled"
            required
            data-vv-name="Contact Number"
            name="ContactNumber"
            :error-messages="NumberValidationMessage('Contact Number')"
            @input="phoneNumberUpdated"
          ></v-text-field>
          <v-text-field
            v-else
            ref="txtNumber"
            v-model="phoneNumber"
            single-line
            hide-details
            class="call-number-txt pt-0 mt-0 not-required"
            :disabled="isDisabled"
            label="Enter Number"
            @input="phoneNumberUpdated"
          ></v-text-field>
          <v-btn
            v-mouse-hold="deleteHoldConfig"
            icon
            small
            flat
            color="grey"
            class="backspace-icon ma-0"
            @click.native="deleteDigit"
          >
            <v-icon small>backspace</v-icon>
          </v-btn>
        </v-flex>
        <v-flex class="text-xs-right validation">
          <v-tooltip v-if="isValid || validationMessage" bottom color="grey">
            <template #activator="{ on }">
              <v-icon v-if="isValid" small color="green" v-on="on">check_circle</v-icon>
              <v-icon v-else-if="validationMessage" small color="red" v-on="on">error</v-icon>
            </template>
            <span v-if="validationMessage">{{ validationMessage }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-layout class="hide">
        <v-flex class="conuntry-list">
          <v-list v-for="(item, index) in countries" :key="index" tow-line>
            <v-list-tile>
              <v-list-tile-avatar>
                <div :class="'flag flag-' + item.code"></div>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-sub-title v-text="item.name + ' (' + item.cc + ')'"></v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
          </v-list>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import { MouseHoldConfig } from '@/directives/mouseHold'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'

enum PhoneNumberType {
  FixedLine = 0,
  Mobile = 1,
  // In some regions (e.g. the USA), it is impossible to distinguish between fixed-line and
  // mobile numbers by looking at the phone number itself.
  FixedLineOrMobile = 2,
  // Freephone lines
  TollFree = 3,
  PremiumRate = 4,
  // The cost of this call is shared between the caller and the recipient, and is hence typically
  // less than PREMIUM_RATE calls. See // http://en.wikipedia.org/wiki/Shared_Cost_Service for
  // more information.
  SharedCost = 5,
  // Voice over IP numbers. This includes TSoIP (Telephony Service over IP).
  VOIP = 6,
  // A personal number is associated with a particular person, and may be routed to either a
  // MOBILE or FIXED_LINE number. Some more information can be found here:
  // http://en.wikipedia.org/wiki/Personal_Numbers
  PersonalNumber = 7,
  Pager = 8,
  // Used for "Universal Access Numbers" or "Company Numbers". They may be further routed to
  // specific offices, but allow one number to be used for a company.
  UAN = 9,
  // A phone number is of type UNKNOWN when it does not fit any of the known patterns for a
  // specific region.
  Unknown = 10,
  Emergency = 27,
  Voicemail = 28,
  ShortCode = 29,
  StandardRate = 30,
}

@Component
export default class PhoneNumber extends Vue {
  @Prop() public passedPhoneNumber: string
  @Prop() public isDisabled: boolean
  @Prop() public isValidationRequired: boolean
  @Prop() public addDigits: string
  @Prop({ default: -1 }) public index: number
  @Prop({ default: false }) private validateNumberForSMS: boolean

  private phoneNumber = ''

  // list of available countries (when adding to this list, make sure to add them to the style section)
  private countries: any[] = [
    { name: 'Great Britain', cc: '+44', code: 'gb' },
    { name: 'Ireland', cc: '+353', code: 'ie' },
    { name: 'International', code: 'eu' },
  ]

  private selectedCountry: any = this.countries[0]

  private util: any = PhoneNumberUtil.getInstance()

  private isValid = false
  private validationMessage = ''
  private deleteHoldConfig: MouseHoldConfig

  @Watch('addDigits')
  private addDigitsChanged() {
    if (this.addDigits) {
      this.phoneNumber += this.addDigits
      this.$emit('digits-added')
      this.validateNumber()
    }
  }

  @Watch('passedPhoneNumber')
  private passedPhoneNumberUpdated(): void {
    let removePrefix = false
    if (this.passedPhoneNumber) {
      if (this.passedPhoneNumber !== this.formattedPhoneNumber) {
        this.phoneNumber = this.passedPhoneNumber.trim()
        removePrefix = true
        this.emitPhoneNumberUpdated(this.phoneNumber)
      }
    } else {
      this.phoneNumber = ''
      this.emitPhoneNumberUpdated(this.phoneNumber)
    }
    this.setDefaultCountry(removePrefix)
    this.validateNumber()
  }

  private changedCountryValue(value: any): void {
    this.selectedCountry = value
    this.validateNumber()
    this.emitPhoneNumberUpdated(this.phoneNumber)
  }

  private phoneNumberUpdated(): void {
    this.validateNumber()
  }

  @Watch('formattedPhoneNumber')
  private formattedPhoneNumberChanged() {
    this.emitPhoneNumberUpdated(this.formattedPhoneNumber)
  }

  public get formattedPhoneNumber() {
    try {
      const phoneNumberObject: any = this.util.parseAndKeepRawInput(this.phoneNumber, this.selectedCountry.code)
      return this.util.format(phoneNumberObject, PhoneNumberFormat.INTERNATIONAL).replace(/[ (-)]/g, '')
    } catch (error) {
      // ignore this error
    }
    return this.phoneNumber
  }

  private created() {
    this.deleteHoldConfig = {
      held: () => {
        this.phoneNumber = ''
        this.emitPhoneNumberUpdated(this.phoneNumber)
      },
      ticked: () => {
        this.deleteDigit()
      },
      tickInterval: 200,
    }
  }

  private validateNumber(): void {
    this.isValid = false
    this.validationMessage = ''

    // get the number in national format and display it
    if (this.selectedCountry) {
      if (this.selectedCountry.cc) {
        // try validating the full number and recalculating the national format
        try {
          const phoneNumberObject: any = this.util.parseAndKeepRawInput(this.phoneNumber, this.selectedCountry.code)
          this.phoneNumber = this.util
            .format(
              phoneNumberObject,
              this.phoneNumber.startsWith('+') ? PhoneNumberFormat.INTERNATIONAL : PhoneNumberFormat.NATIONAL
            )
            .replace(/( |\(|\))/g, '')
          if (this.util.isPossibleNumber(phoneNumberObject)) {
            // see if it's valid
            if (this.util.isValidNumber(phoneNumberObject)) {
              this.isValid = true
              this.validationMessage = PhoneNumberType[this.util.getNumberType(phoneNumberObject)] // TODO: format the phone number type nicely
            } else {
              this.isValid = false
              this.validationMessage = 'Invalid number'
            }
          } else {
            this.isValid = false
            this.validationMessage = 'Invalid length'
          }
        } catch (error) {
          if (!(error as Error).toString().endsWith('to be a phone number')) {
            throw error
          }
        }
      }
    } else {
      this.isValid = false
      this.validationMessage = 'Invalid country'
    }
  }

  private setDefaultCountry(trimPhoneNumber = false): void {
    // check if we can get a country code out of it
    if (!this.phoneNumber) {
      this.selectedCountry = this.countries[0]
    } else if (this.phoneNumber.startsWith('+')) {
      const matchCountryCodes: string = this.countries
        .filter((x) => x.cc)
        .map((x) => x.cc.replace('+', '\\+'))
        .join('|')
      const matchPhoneNumber = matchCountryCodes + '\\d+'
      if (RegExp(matchPhoneNumber).test(this.phoneNumber)) {
        const matches = this.phoneNumber.match(matchCountryCodes)
        if (matches && matches.length > 0) {
          const index = this.countries.map((e) => e.cc).indexOf(matches[0])
          if (index >= 0) {
            this.selectedCountry = this.countries[index]
          } else {
            this.selectedCountry = this.countries[0]
          }
          if (trimPhoneNumber) {
            this.phoneNumber = this.phoneNumber.substr(this.selectedCountry.cc.length)
          }
        } else {
          this.selectedCountry = this.countries[0]
        }
      } else {
        this.selectedCountry = this.countries[this.countries.length - 1]
      }
    }
  }

  private mounted() {
    if (this.passedPhoneNumber) {
      this.phoneNumber = this.passedPhoneNumber.trim().replace(/[^0-9]/g, '')
      this.setDefaultCountry()
      this.validateNumber()
    }
  }

  private NumberValidationMessage(label: string) {
    if (!this.isDisabled) {
      let message: string = this.$validator.errors.collect(label)[0]
      if (message) {
        const errorMessage = label.split(/(\d+)/)
        return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
      } else {
        if (this.validationMessage === 'Invalid length' || this.validationMessage === 'Invalid number') {
          return (message = 'The Contact Number is not valid.')
        }
        if (this.validationMessage === 'Invalid country') {
          return (message = 'The Country Code is required.')
        }
        if (this.validateNumberForSMS && this.phoneNumber) {
          const phoneNumberObject: any = this.util.parseAndKeepRawInput(this.phoneNumber, this.selectedCountry.code)
          if (this.util.getNumberType(phoneNumberObject) !== PhoneNumberType.Mobile) {
            this.isValid = false
            return (message = 'SMS is allowed for mobile number only.')
          }
        }
      }
    }
  }

  private deleteDigit(): void {
    if (this.phoneNumber && this.phoneNumber.length > 0) {
      this.phoneNumber = this.phoneNumber.slice(0, -1)
    }
    this.emitPhoneNumberUpdated(this.phoneNumber)
  }

  private emitPhoneNumberUpdated(phoneNumber: string) {
    if (this.index > -1) {
      this.$emit('phoneNumberUpdated', phoneNumber, this.index)
    } else {
      this.$emit('phoneNumberUpdated', phoneNumber)
    }
  }
}
</script>

<style scoped>
.internal-phone-number {
  padding-left: 50px;
}
.internal-phone-number >>> .contact-number {
  position: relative;
  border-bottom: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
.internal-phone-number >>> .contact-number .input-group .v-text-field__details {
  opacity: 1;
  padding-right: 0px;
}
.internal-phone-number >>> .call-number-txt {
  max-width: calc(100% - 50px);
  display: inline-block;
  padding-left: 0;
  width: 100%;
}
.internal-phone-number >>> .selectedCountry {
  max-width: 50px;
  display: inline-block;
  position: static;
  padding-left: 0;
  vertical-align: top;
}
.internal-phone-number >>> .selectedCountry .input-group__selections {
  display: inline-block;
  position: relative;
  top: 5px;
}
.internal-phone-number >>> .v-text-field input,
.internal-phone-number >>> .selectedCountry .v-select__slot,
.internal-phone-number >>> .validation,
.internal-phone-number >>> .contact-number .v-text-field__slot {
  opacity: 1;
}
.show-number .internal-phone-number >>> .input-group input,
.show-number .internal-phone-number >>> .selectedCountry .v-select__slot,
.show-number .internal-phone-number >>> .validation,
.show-number .internal-phone-number >>> .contact-number .v-text-field__slot {
  opacity: 1;
}
.internal-phone-number >>> .backspace-icon {
  display: none;
}
.internal-phone-number >>> .validation {
  position: absolute;
  right: 2px;
  top: 4px;
  z-index: 3;
}
.internal-phone-number >>> .validation .v-icon {
  z-index: 999;
  position: relative;
}
.internal-phone-number >>> .contact-number .v-text-field label {
  top: 0px;
  left: 2px;
  font-size: 15px;
}

.validation {
  position: absolute;
  right: 30px;
  top: 6px;
}

.selectedCountry {
  font-size: 16px;
}

.selectedCountry >>> .input-group--select__autocomplete {
  max-width: 40px;
}

i.v-icon.material-icons.input-group__append-icon.input-group__icon-cb {
  width: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.flag {
  display: inline-block;
  width: 16px;
  height: 11px;
  background: url(/img/flags.png) no-repeat;
}

.flag.flag-gb {
  background-position: -144px -44px;
}

.flag.flag-ie {
  background-position: -48px -66px;
}

.flag.flag-eu {
  background-position: -16px -44px;
}

.contact-number {
  padding-left: 50px;
  padding-bottom: 5px;
  padding-right: 55px;
}

.contact-number >>> .v-input__control .v-input__slot:after,
.contact-number >>> .v-input__control .v-input__slot:before {
  opacity: 1;
}

.selectedCountry {
  font-size: 15px;
  position: absolute;
  left: 0px;
  padding-left: 8px;
  margin-top: 0px !important;
}

.call-number-txt >>> label {
  top: 0px;
}
.selectedCountry >>> .v-select__selections {
  color: #b9b9b9 !important;
}

.contact-number >>> .v-text-field__slot label {
  top: 5px !important;
  font-size: 14px;
}
.internal-phone-number >>> .v-tooltip {
  z-index: 999;
  position: relative;
}
</style>
