export default class InsurerPhoneNumberModel {
  public insurerId: number
  public label: string
  public phoneNumber: string

  public constructor() {
    this.label = ''
    this.phoneNumber = ''
  }
}
