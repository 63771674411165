<template>
  <div> {{ value }} </div>
</template>

<script lang="ts">
import TimeLineItemBase from '@/components/TimeLineItemBase'
import { useTimelineStore } from '@/pinia/timeline'
import { Component } from 'vue-property-decorator'

@Component
export default class ClientClaimSubmittedCard extends TimeLineItemBase {
  public get value(): string {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card && card.payload ? card.payload : 'Client Claim has been successfully submitted'
  }
}
</script>
