import ContractorAppointedModel from './claim/ContractorAppointedModel'
import CustomerAvailabilityModel from './claim/CustomerAvailabilityModel'
import moment from 'moment'

export default class ContractorOfferedJobModel {
  public contractorAppointedModel: ContractorAppointedModel
  public timeDuration: number // Time duration left to accept/reject job
  public emergencyTypeDescription: string
  public emergencyDetailDescription: string
  public engineerSiteSLA: moment.Moment
  public customerAvailability: CustomerAvailabilityModel
  public firstUpdatedAt: moment.Moment
}
