<template>
  <div>
    <v-card v-if="job">
      <v-card-title class="pr-1" :class="job.customerContactPreferenceValue ? 'icon-space' : ''">
        <h4>
          Job:
          <a
            href="Javascript:void(0)"
            class="secondary--text"
            @click="$emit('redirectToJob', isJobTypeHE() ? job.jobId : job.id)"
          >
            {{ titleGenerator(job) }}
          </a>
        </h4>
        <v-spacer></v-spacer>
        <h4 class="job-code grey--text text--darken-1">
          {{ job.addressLine3 ? job.addressLine3 + ', ' + job.postcode : job.postcode }}
        </h4>
        <span v-if="job.customerContactPreferenceValue && job.customerContactPreference" class="preference-icon">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                primary
                small
                class="ma-0 ml-1 btn-contactPreference"
                v-on="on"
                @click="onContactPreferenceIconClick"
              >
                <v-icon class="md-icon" color="primary">
                  {{ getCustomerContactPreference(job.customerContactPreference) }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ job.customerContactPreferenceValue }}</span>
          </v-tooltip>
        </span>
      </v-card-title>
      <v-card-text class="py-2">
        <slot name="JobCardDetails"></slot>
      </v-card-text>
      <v-card-actions class="py-1" :class="job.dashboardDisplayData ? job.dashboardDisplayData.etaColor : 'grey'">
        <div class="eta-info">
          <v-icon small class="white--text">date_range</v-icon>
          <span>
            ETA:
            <span class="eta-date-from">
              {{ getETADateFormat(job.etaFrom) }}
            </span>
            <span class="eta-date-to">
              <span>&nbsp;To</span>
              {{ getETADateFormat(job.etaTo) }}
            </span>
          </span>
        </div>
      </v-card-actions>
    </v-card>
    <WIPTemplateList
      v-if="showWIPTemplateListDialog"
      ref="refWIPTemplateList"
      :job-id="job.jobId"
      :contractor-appointed-detail-id="job.id"
      @closeWIPTemplateDialog="showWIPTemplateListDialog = false"
    ></WIPTemplateList>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ContactPreferenceEnum } from '@/common/enums'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import moment, { Moment } from 'moment'
import Shared from '@/common/shared'
import store from '@/store'
import WIPTemplateList from '@/components/WIPTemplateList.vue'
@Component({
  components: { WIPTemplateList },
})
export default class JobSummaryCard extends Vue {
  @Prop({ default: () => undefined }) public job: ContractorAppointedModel

  /**
   * default: renders 'Job: <jobId>'
   * pass a function (job) => string to modify the card title.
   */
  @Prop({ default: () => (job: ContractorAppointedModel) => job.jobId })
  public titleGenerator: (job: ContractorAppointedModel) => string

  private customerContactPreference = ContactPreferenceEnum

  private showWIPTemplateListDialog = false

  public get hasETA(): boolean | null {
    return this.job.etaFrom && this.job.etaFrom.isValid() && this.job.etaTo && this.job.etaTo.isValid()
  }

  private getCustomerContactPreference(contactPreference: string) {
    if (contactPreference === this.customerContactPreference[this.customerContactPreference.Email]) {
      return 'email'
    } else if (contactPreference === this.customerContactPreference[this.customerContactPreference.Call]) {
      return 'call'
    } else if (contactPreference === this.customerContactPreference[this.customerContactPreference.SMS]) {
      return 'sms'
    }
    return ''
  }

  private getETADateFormat(date: moment.Moment | null): string | null {
    if (date) {
      return Shared.getFormatedDate(moment(date), store.Instance.state.Environment.DateTimeFormat)
    }
    return null
  }

  private onContactPreferenceIconClick() {
    if (
      this.job.customerContactPreference !== this.customerContactPreference[this.customerContactPreference.Call] &&
      this.isJobTypeHE()
    ) {
      this.showWIPTemplateListDialog = true
    }
  }

  private isJobTypeHE() {
    const jobType = this.job.jobId.substr(0, 2)
    const jobTypeList = ['SI', 'US']
    return jobTypeList.includes(jobType) ? false : true
  }
}
</script>

<style scoped>
.v-card {
  margin-bottom: 15px;
}
.v-card:last-child {
  margin-bottom: 0;
}
.v-card >>> .v-card__title {
  padding: 6px 12px 4px;
  border-bottom: 1px solid #e0e0e0;
}
.v-card >>> .v-card__text {
  padding: 4px 12px 6px;
}
.v-card .details-row {
  position: relative;
  padding-left: 20px;
}
.v-card .details-row i {
  position: absolute;
  left: -5px;
  top: 0px;
  font-size: 22px;
}
.v-card >>> .v-card__title.icon-space {
  padding-right: 25px !important;
}
/* .v-card >>> .v-card__actions{padding: 8px 4px;}  */
.eta-info {
  font-size: 13px;
  vertical-align: top;
  position: relative;
  padding-left: 30px;
  margin: 0;
  color: #fff;
}
.eta-info >>> .v-icon {
  vertical-align: text-bottom;
  position: absolute;
  left: 8px;
  top: 3px;
}
.preference-icon {
  position: absolute;
  right: 0px;
}
</style>
