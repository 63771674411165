export default class SIVisitPitTestDetail {
  public id: number
  public name: string
  public quantity: number
  public notes: string
  public isSelected: boolean

  constructor() {
    this.isSelected = false
    this.quantity = 0
  }
}
