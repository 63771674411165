import { Moment } from 'moment'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'

export default class IVSDetailModel extends CosmosJobsDbRecordBase {
  public notes: string
  public cost: number
  public isInvoiced: boolean
  public completionDate: Moment | null
  public status: string
  public constructor() {
    super()
    // set defaults
    this.notes = ''
    this.cost = 0
    this.isInvoiced = false
    this.completionDate = null
  }
}
