<template>
  <div class="dashboard">
    <v-container fluid pa-0>
      <div v-show="ready">
        <v-layout wrap>
          <v-flex sm8 md7 lg8 class="gridView">
            <JobHistoryTable v-if="true" table-name="jobDashboard"></JobHistoryTable>
          </v-flex>
          <v-flex sm4 md5 lg4 mt-3 pt-5 pl-4>
            <v-container fluid class="grid-list-lg pt-1 pa-0">
              <v-layout wrap calls-info request-info>
                <v-flex xs12 py-0 mb-4>
                  <v-card class="elevation-1 blue white--text pa-0">
                    <v-card-title class="px-2 pt-1 pb-0">
                      {{ titleTotalJobsLoggedInLast15Days }}
                    </v-card-title>
                    <v-card-text class="pa-0">
                      <DashboardChart
                        v-if="loggedJobs"
                        :chart-data="loggedJobs"
                        :chart-type="chartTypeEnum[chartTypeEnum.Line]"
                      />
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 mb-1 py-0>
                  <v-card class="elevation-1 white white--grey pa-0 piechart-jobdeshboard">
                    <v-card-title class="px-2 pt-2 pb-0 subheading">
                      {{ titleTop5Emergencies }}
                    </v-card-title>
                    <v-card-text class="pa-0">
                      <DashboardChart
                        v-if="isTop5DataAvailable && loggedEmergencies && loggedEmergencies.length > 0"
                        :chart-data="loggedEmergencies"
                        :chart-type="chartTypeEnum[chartTypeEnum.Pie]"
                      />
                      <div v-else class="data-not-availble-msg grey--text text--lighten-0 title">
                        Data not available
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </div>
      <div v-show="!ready">
        <v-progress-circular
          class="loading-spinner"
          :width="2"
          :size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import Shared from '@/common/shared'
import JobHistoryTable from '@/components/tables/JobHistoryTable.vue'
import CustomDashboardController from '@/api/customDashboardController'
import DashboardChart from '@/components/dashboard/DashboardChart.vue'
import { ChartType } from '@/common/enums'
import eventBus from '@/common/bus'

@Component({
  components: { JobHistoryTable, DashboardChart },
})
export default class JobDashboard extends Vue {
  private ready = false
  private loggedEmergencies: any = []
  private loggedJobs: any = []
  private titleTotalJobsLoggedInLast15Days: string = Shared.titleTotalJobsLoggedInLast15Days
  private titleTop5Emergencies: string = Shared.titleTop5Emergencies
  private isTop5DataAvailable = false
  private chartTypeEnum = ChartType

  private created() {
    this.getTotalLoggedJobsInLast15Days()
    this.getTop5Emergencies()
  }

  private getTotalLoggedJobsInLast15Days() {
    CustomDashboardController.GetJobLoggedInLast15Day()
      .then((res: any | null) => {
        if (res) {
          this.loggedJobs = Shared.setChartDataInOrder(res)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading jobs logged in last 15 days, please try again', true)
      })
  }

  private getTop5Emergencies() {
    CustomDashboardController.GetTop5LoggedEmergency()
      .then((res: any | null) => {
        if (res) {
          this.loggedEmergencies = []
          this.loggedEmergencies.push(res)
          this.isTop5DataAvailable = true
          this.ready = true
        } else {
          this.loggedEmergencies = []
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading top 5 logged emergency, please try again', true)
      })
  }
}
</script>

<style scoped>
.calls-info h2 {
  font-size: 38px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  right: 0px;
  top: 10px;
  right: 8px;
}
.calls-info.request-info >>> .v-card {
  height: 145px !important;
  display: flex;
  flex-wrap: wrap;
}
.calls-info.request-info >>> .v-card.piechart-jobdeshboard {
  height: 425px !important;
}
.calls-info.request-info >>> .v-card.piechart-jobdeshboard .v-card__text {
  height: calc(100% - 45px);
  padding: 0 10px !important;
}
.calls-info.request-info >>> .v-card .v-card__title {
  width: calc(100% - 115px);
  display: flex;
  align-items: flex-start;
}
.gridView tbody tr td:last-child {
  padding-left: 0px !important;
}
.gridView >>> table > thead > tr > th:nth-child(2) {
  width: 120px;
}
.gridView >>> table > thead > tr > th:nth-child(1) {
  width: 100px;
}
.gridView >>> table > thead > tr > th:nth-child(3) {
  width: 50%;
}
.calls-info.request-info >>> .v-card .v-card__text {
  display: flex;
  align-self: flex-end;
}
@media only screen and (max-width: 1200px) {
  .calls-info h2 {
    font-size: 30px;
  }
}
.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}
.gridView {
  border: 0px;
}
.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
}
.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}
.chart-line {
  width: 100%;
  position: relative;
  display: flex;
}
.data-not-availble-msg {
  margin: auto;
}
.v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
  width: 100% !important;
  max-height: 370px !important;
  object-fit: contain;
}
.search-options {
  min-width: 50px;
  max-width: 50px;
  position: absolute;
  top: -3px;
  right: 0px;
}
.job-dashboard-seach {
  position: relative;
}
@media only screen and (max-width: 1600px) {
  .v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 1300px) {
  .v-card.piechart-jobdeshboard .chart-line .chartjs-render-monitor {
    margin-top: 50px;
  }
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.search-content >>> .v-input__append-inner {
  padding-right: 40px;
  cursor: default;
}
.search-options >>> .v-select .v-input__slot:after,
.search-options >>> .v-select .v-input__slot:before {
  content: '';
  display: none;
}
.search-options >>> .v-select .v-icon {
  position: relative;
  right: 0px;
}
.search-options >>> .v-select__selections {
  visibility: hidden;
  display: none;
}
</style>
