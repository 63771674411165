<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import {
  WorkerActivityChanged,
  WorkerAttributesChanged,
  WorkerCreated,
  WorkerReservationEvents,
  WorkerDeleted,
} from '@/signalr/twilioEventModels/workerEvent'
import { InsurerAnnouncingUpdate } from '@/signalr/twilioEventModels/insurerAnnouncingUpdate'
import { ContractorAnnouncingUpdate } from '@/signalr/twilioEventModels/contractorAnnouncingUpdate'
import {
  DailyCallStats,
  TopLevelCallStats,
  UpdateSurge,
  AddCallCount,
  UpdateTwilioTopTilesCount,
  HourlyCallStats,
  InsurerHourlyCallStats,
} from '@/signalr/twilioEventModels/events'
import { InsurerStats, ClientPortalStats, ContractorStats } from '@/signalr/twilioEventModels/dashboardState'
import { Worker, WorkerWithAdditions } from '@/components/dashboard/worker'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import RequestProcessedRecord from '@/signalr/jobModels/requestProcessedRecord'
import JobController from '@/api/jobController'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import ComplaintModel from '@/models/claim/ComplaintModel'
import ComplaintTrackerModel from '@/models/claim/ComplaintTrackerModel'
import UserModel from '@/models/user/UserModel'
import moment from 'moment'
import { ManagerSupervisorDashboardCount, InsurerCount } from '@/models/twilio/ManagerSupervisorDashboardCount'
import { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import Shared from '@/common/shared'
import { PanelProps } from '@/components/dashboard/DashboardPanel.vue'
import { ContractorPanelProps } from '@/components/dashboard/DashboardContractorPanel.vue'
import ClientPortalJobCount from '@/models/twilio/ClientPortalJobCount'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'

export type SignalRChildPage =
  | 'SupervisorDashboard'
  | 'ManagerSupervisorDashboard'
  | 'DynamicDashboard'
  | 'ThirdPartyDashboard'

@Component
export default class SignalRHub extends Vue {
  public childPage: SignalRChildPage
  // TODO: expose connected status, with button to reconnect if needed
  public dashboardSignalRHub: SignalRHubConnection = new SignalRHubConnection('dashboards')
  public complaintDashboardSignalRHub: SignalRHubConnection = new SignalRHubConnection('complaintDashboard')
  public clientPortalPanelHub: SignalRHubConnection = new SignalRHubConnection('clientPortalPanel')
  public workers: WorkerWithAdditions[] = []
  public workerActivityUpdatedAt: { [key: string]: number } = {}
  public workerAttributesUpdatedAt: { [key: string]: number } = {}
  public todaysCallStats: DailyCallStats | null = null
  public topLevelStats: TopLevelCallStats | null = null
  public longestWaitTimerInterval: number | null = null
  public insurerStatsIntervals: { [key: number]: { longestNew: number | null; longestExisting: number | null } } = {}
  public insurerAnnouncingUpdatedAt: { [key: number]: number } = {}
  public hourlyInsurerCallStats: Array<HourlyCallStats['individualInsurerStats']> = []
  public hourlyClientPortalCallStats: Array<HourlyCallStats['individualInsurerStats']> = []
  public hourlyContractorCallStats: Array<HourlyCallStats['contractorStats']> = []
  public inSurge = false
  public insurerStats: Array<InsurerStats & { announcingCount: number }> = []
  public clientPortalStats: Array<ClientPortalStats & { announcingCount: number }> = []
  public dashboardCount: ManagerSupervisorDashboardCount = new ManagerSupervisorDashboardCount()
  public complaintListForDashBoard: ComplaintTrackerModel | null = null
  public complaintDashboardSelectedJobType = 'All'
  public clientPortalJobCount: { [key: string]: InsurerCount } = {}

  public contractorAnnouncingUpdatedAt: moment.Moment | null = null
  public contractorStats: (ContractorStats & { announcingCount: number }) | null = null
  public contractorStatsInterval: { longestNew: number | null; longestExisting: number | null } = {
    longestNew: null,
    longestExisting: null,
  }

  public destroyed() {
    if (this.dashboardSignalRHub != null) {
      this.dashboardSignalRHub.disconnect()
    }
    if (this.complaintDashboardSignalRHub != null) {
      this.complaintDashboardSignalRHub.disconnect()
    }
    if (this.clientPortalPanelHub != null) {
      this.clientPortalPanelHub.disconnect()
    }
  }

  public get userList(): UserModel[] {
    return storeGetters.getUsers()
  }

  public get hourlySlaRate(): number[] {
    if (this.childPage === 'DynamicDashboard') {
      return this.topLevelStats ? this.topLevelStats.slaRateHistorical : []
    } else {
      return this.hourlyStats.map((stats) => stats.slaRate)
    }
  }

  public get hourlyTotalCalls(): number[] {
    if (this.childPage === 'DynamicDashboard') {
      return this.topLevelStats ? this.topLevelStats.currentCallsCountHistorical : []
    } else {
      return this.hourlyStats.map((stats) => stats.totalCalls)
    }
  }

  public get hourlyMeanCallsInQueue(): number[] {
    if (this.childPage === 'DynamicDashboard') {
      return this.topLevelStats ? this.topLevelStats.callsInQueueCountHistorical : []
    } else {
      return this.hourlyStats.map((stats) => stats.meanCallsInQueue)
    }
  }

  public get hourlyMeanTaskAcceptanceTime(): number[] {
    if (this.childPage === 'DynamicDashboard') {
      return this.topLevelStats ? this.topLevelStats.longestWaitingCallAgeHistorical : []
    } else {
      return this.hourlyStats.map((stats) => stats.meanTaskAcceptanceTime)
    }
  }

  public get hourlyStats(): HourlyCallStats[] {
    if (this.todaysCallStats == null) {
      return []
    }
    const data = this.todaysCallStats.stats
    return Object.keys(data)
      .map((k) => +k)
      .sort((a, b) => a - b)
      .map((k) => data[k])
  }

  public get hourlyIndividualStats(): Array<HourlyCallStats['individualInsurerStats']> {
    if (this.childPage === 'DynamicDashboard') {
      return this.hourlyInsurerCallStats
    } else {
      return this.hourlyStats.map((stats) => stats.individualInsurerStats)
    }
  }

  public get hourlyClientPortalIndividualStats(): Array<HourlyCallStats['individualInsurerStats']> {
    return this.hourlyClientPortalCallStats
  }

  public get hourlyGroupedStats(): Array<HourlyCallStats['groupedInsurerStats']> {
    return this.hourlyStats.map((stats) => stats.groupedInsurerStats)
  }

  public get topTiles(): { [k: string]: TopTileProps } {
    if (this.topLevelStats == null) {
      return {}
    }

    return {
      1: {
        title: Shared.titlePickupRate,
        value: this.topLevelStats.slaRate,
        displayValue: (v) => {
          return v + '%'
        },
        showChart: true,
        chartData: this.hourlySlaRate,
        chartMaxValue: 100,
        backgroundColor: Shared.colorBlue,
        textColor: Shared.colorWhite,
        showArrow: false,
        infoValue: [
          {
            type: 'img',
            path: '/img/phone-call-outcoming.svg',
            alt: 'Overall 20 second call pick up for the day',
            value: this.dashboardCount.averageSlaRateToday,
          },
        ],
      },
      2: {
        title: Shared.titleTotalCalls,
        value: this.topLevelStats.currentCallsCount,
        showChart: true,
        chartData: this.hourlyTotalCalls,
        backgroundColor: Shared.colorRed,
        textColor: Shared.colorWhite,
        showArrow: false,
        infoValue: [
          {
            type: 'icon',
            path: 'call',
            alt: 'Total calls received',
            propName: 'callCount',
            value: this.dashboardCount.totalCalls,
          },
          {
            type: 'icon',
            path: 'work',
            alt: 'Total jobs logged',
            propName: 'jobCount',
            value: this.dashboardCount.totalLoggedJobs,
          },
          {
            type: 'icon',
            path: 'build',
            alt: 'Total emergencies deployed',
            propName: 'emergencyDeployedCount',
            value: this.dashboardCount.totalDeployedJobs,
          },
        ],
      },
      3: {
        title: Shared.titleQueueCalls,
        value: this.topLevelStats.callsInQueueCount,
        showChart: true,
        chartData: this.hourlyMeanCallsInQueue,
        backgroundColor: Shared.colorPurple,
        textColor: Shared.colorWhite,
        showArrow: true,
        infoValue: [
          {
            type: 'img',
            path: '/img/callqueue.svg',
            alt: 'Highest number of calls in queue for the day',
            propName: 'queueCount',
            value: this.dashboardCount.highestTaskCountInQueue,
          },
        ],
      },
      4: {
        title: Shared.titleLongestWait,
        value: this.topLevelStats.longestWaitingCallAge ? this.topLevelStats.longestWaitingCallAge : 0,
        displayValue: (v) => Shared.formatDuration(v),
        showChart: true,
        chartData: this.hourlyMeanTaskAcceptanceTime,
        backgroundColor: Shared.colorAmber,
        textColor: Shared.colorWhite,
        showArrow: false,
        infoValue: [
          {
            type: 'img',
            path: '/img/callwaiting.svg',
            alt: 'Longest wait (in minutes) for the day',
            propName: 'callWaitingAge',
            value: this.dashboardCount.longestTaskWaitingAge,
          },
        ],
      },
      20: {
        title: Shared.titleContractorQueueCalls,
        value: this.dashboardCount.totalContractorCallInQueue ? this.dashboardCount.totalContractorCallInQueue : 0,
        displayValue: (v) => Shared.formatDuration(v),
        showChart: false,
        chartData: [],
        backgroundColor: Shared.colorGreen,
        textColor: Shared.colorWhite,
        showArrow: true,
        infoValue: [
          {
            type: 'img',
            path: '/img/callwaiting.svg',
            alt: 'Longest wait (in minutes) for the day',
            propName: 'longestContractorCallInQueue',
            value: this.dashboardCount.longestContractorCallInQueue,
          },
        ],
        showDisplayValueInTitle: false,
      },
    }
  }

  public get panels(): { [k: string]: PanelProps } {
    const panels: { [k: number]: PanelProps } = {}
    let index = 0
    const color = ['teal', 'cyan', 'light-blue', 'blue-grey', 'teal', 'cyan', 'light-blue', 'blue-grey']
    for (const insurer of this.insurerStats) {
      let lineChartData: number[]
      let announcingChartData: number[]
      let newChartData: number[]
      let existingChartData: number[]

      // Show individual stats for RSA or LV
      if (
        insurer.insurerId &&
        insurer.insurerId === -1 &&
        (this.childPage === 'SupervisorDashboard' ||
          this.childPage === 'ManagerSupervisorDashboard' ||
          this.childPage === 'ThirdPartyDashboard')
      ) {
        lineChartData = this.hourlyGroupedStats.map((stats) => stats.totalCalls)
        announcingChartData = this.hourlyGroupedStats.map((stats) => stats.meanCallsInAnnouncing)
        newChartData = this.hourlyGroupedStats.map((stats) => stats.totalNewCalls)
        existingChartData = this.hourlyGroupedStats.map((stats) => stats.totalExistingCalls)
      } else {
        lineChartData = this.hourlyIndividualStats.map((stats) =>
          stats[insurer.insurerId] ? stats[insurer.insurerId].totalCalls : 0
        )
        announcingChartData = this.hourlyIndividualStats.map((stats) =>
          stats[insurer.insurerId] ? stats[insurer.insurerId].meanCallsInAnnouncing : 0
        )
        newChartData = this.hourlyIndividualStats.map((stats) =>
          stats[insurer.insurerId] ? stats[insurer.insurerId].totalNewCalls : 0
        )
        existingChartData = this.hourlyIndividualStats.map((stats) =>
          stats[insurer.insurerId] ? stats[insurer.insurerId].totalExistingCalls : 0
        )
      }

      panels[insurer.insurerId.toString()] = {
        title: insurer.insurerName || insurer.insurerId,
        chartData: lineChartData,
        colorClass: color[index] + ' lighten-2',
        insurerCount: this.dashboardCount.insurerCounts[insurer.insurerId],
        panelLines: [
          {
            title: 'Calls in announcing',
            value: insurer.announcingCount,
            chartData: announcingChartData,
            showArrow: false,
          },
          {
            title: 'New',
            value: insurer.newCalls ? insurer.newCalls.currentCount : 0,
            duration: Shared.formatDuration(insurer.newCalls ? insurer.newCalls.longestWaitingAge : 0),
            chartData: newChartData,
            showArrow: true,
            availableWorkers: 0,
            colorClass: color[index] + ' lighten-3',
          },
          {
            title: 'Existing',
            value: insurer.existingCalls ? insurer.existingCalls.currentCount : 0,
            duration: Shared.formatDuration(insurer.existingCalls ? insurer.existingCalls.longestWaitingAge : 0),
            chartData: existingChartData,
            showArrow: true,
            availableWorkers: 0,
          },
        ],
      }
      index++
      if (index === color.length) {
        index = 0
      }
    }
    return panels
  }

  // Network stats
  public get contractorPanel(): ContractorPanelProps {
    if (this.contractorStats) {
      let lineChartData: number[]
      let announcingChartData: number[]
      let newChartData: number[]
      let existingChartData: number[]

      lineChartData = this.hourlyContractorCallStats.map((stats) => stats.totalCalls)
      announcingChartData = this.hourlyContractorCallStats.map((stats) => stats.meanCallsInAnnouncing)
      newChartData = this.hourlyContractorCallStats.map((stats) => stats.totalNewCalls)
      existingChartData = this.hourlyContractorCallStats.map((stats) => stats.totalExistingCalls)

      const contractorPanel = {
        title: 'Contractor Calls',
        chartData: lineChartData,
        colorClass: 'purple lighten-2',
        panelLines: [
          {
            title: 'Calls in announcing',
            value: this.contractorStats.announcingCount,
            chartData: announcingChartData,
            showArrow: false,
          },
          {
            title: 'Deployment',
            value: this.contractorStats.newCalls ? this.contractorStats.newCalls.currentCount : 0,
            duration: Shared.formatDuration(
              this.contractorStats.newCalls ? this.contractorStats.newCalls.longestWaitingAge : 0
            ),
            chartData: newChartData,
            showArrow: true,
            availableWorkers: 0,
            colorClass: 'purple lighten-3',
          },
          {
            title: 'Work in Progress',
            value: this.contractorStats.existingCalls ? this.contractorStats.existingCalls.currentCount : 0,
            duration: Shared.formatDuration(
              this.contractorStats.existingCalls ? this.contractorStats.existingCalls.longestWaitingAge : 0
            ),
            chartData: existingChartData,
            showArrow: true,
            availableWorkers: 0,
          },
        ],
      }

      return contractorPanel
    }
    return { title: 'Contractor Calls', chartData: [], panelLines: [] }
  }

  public get clientPortalpanels(): { [k: string]: PanelProps } {
    const panels: { [k: number]: PanelProps } = {}
    for (const insurer of this.clientPortalStats) {
      let lineChartData: number[]
      let announcingChartData: number[]
      let newChartData: number[]
      let existingChartData: number[]

      lineChartData = this.hourlyClientPortalIndividualStats.map((stats) =>
        stats[insurer.clientTemplateId] ? stats[insurer.clientTemplateId].totalCalls : 0
      )
      announcingChartData = this.hourlyClientPortalIndividualStats.map((stats) =>
        stats[insurer.clientTemplateId] ? stats[insurer.clientTemplateId].meanCallsInAnnouncing : 0
      )
      newChartData = this.hourlyClientPortalIndividualStats.map((stats) =>
        stats[insurer.clientTemplateId] ? stats[insurer.clientTemplateId].totalNewCalls : 0
      )
      existingChartData = this.hourlyClientPortalIndividualStats.map((stats) =>
        stats[insurer.clientTemplateId] ? stats[insurer.clientTemplateId].totalExistingCalls : 0
      )

      panels[insurer.clientTemplateId.toString()] = {
        title: insurer.subDomain || insurer.clientTemplateId,
        chartData: lineChartData,
        color: insurer.insurerTileColor ? insurer.insurerTileColor : 'teal',
        insurerCount: this.clientPortalJobCount[insurer.clientTemplateId],
        panelLines: [
          {
            title: 'Calls in announcing',
            value: insurer.announcingCount,
            chartData: announcingChartData,
            showArrow: false,
          },
          {
            title: 'New',
            value: insurer.newCalls ? insurer.newCalls.currentCount : 0,
            duration: Shared.formatDuration(insurer.newCalls ? insurer.newCalls.longestWaitingAge : 0),
            chartData: newChartData,
            showArrow: true,
            availableWorkers: 0,
            color: insurer.insurerTileColor ? insurer.insurerTileColor : 'teal',
            hasLightColor: true,
          },
          {
            title: 'Existing',
            value: insurer.existingCalls ? insurer.existingCalls.currentCount : 0,
            duration: Shared.formatDuration(insurer.existingCalls ? insurer.existingCalls.longestWaitingAge : 0),
            chartData: existingChartData,
            showArrow: true,
            availableWorkers: 0,
          },
        ],
      }
    }
    return panels
  }

  public get getComplaintByJobType(): ComplaintModel[] {
    if (this.complaintListForDashBoard) {
      return this.complaintListForDashBoard.complaints.filter((item: ComplaintModel) => {
        const jobType = item.jobId.split('-')
        if (this.complaintDashboardSelectedJobType === 'All') {
          return true
        } else if (jobType.length === 1 && this.complaintDashboardSelectedJobType === 'HE') {
          return true
        } else {
          return jobType[0] === this.complaintDashboardSelectedJobType
        }
      })
    }
    return []
  }

  public get dashboardComplaintTopTiles(): { [k: string]: TopTileProps } {
    if (this.complaintListForDashBoard == null) {
      return {}
    }

    return {
      5: {
        title: Shared.titleTotalNumberOfOpenComplaint,
        value: this.complaintListForDashBoard.totalOpenComplaints,
        showChart: this.complaintDashboardSelectedJobType === 'All',
        chartData: Shared.setChartData(this.complaintListForDashBoard.openComplaint),
        chartMaxValue: 100,
        backgroundColor: 'blue',
        textColor: 'white',
        showArrow: true,
      },
      6: {
        title: Shared.titleTotalNumberOfCloseComplaint,
        value: this.complaintListForDashBoard.totalClosedComplaints,
        showChart: this.complaintDashboardSelectedJobType === 'All',
        chartData: Shared.setChartData(this.complaintListForDashBoard.closeComplaint),
        backgroundColor: 'orange',
        textColor: 'white',
        showArrow: true,
      },
      7: {
        title: Shared.titleTotalOverdueActions,
        value: this.complaintListForDashBoard.totalOverdueComplaints,
        showChart: this.complaintDashboardSelectedJobType === 'All',
        chartData: Shared.setChartData(this.complaintListForDashBoard.overdueComplaint),
        backgroundColor: 'red',
        textColor: 'white',
        showArrow: true,
      },
      8: {
        title: Shared.titleSettlementPaidMTD,
        value: this.complaintListForDashBoard.totalPaidMTDComplaints,
        displayValue: (v) => {
          return '£ ' + v
        },
        showChart: this.complaintDashboardSelectedJobType === 'All',
        chartData: Shared.setChartData(this.complaintListForDashBoard.paidMTD),
        backgroundColor: 'green',
        textColor: 'white',
        showArrow: true,
      },
    }
  }

  public addTopLevelCallStatsHandler() {
    this.dashboardSignalRHub.addHandler('TopLevelCallStats', this.callTopLevelCallStats)
  }
  public callTopLevelCallStats(stats: TopLevelCallStats) {
    const currentDate = this.topLevelStats && Date.parse(this.topLevelStats.dataFetchedAt)
    const newDate = Date.parse(stats.dataFetchedAt)

    if (!(currentDate && currentDate > newDate)) {
      this.topLevelStats = stats
      // add contractor call in current call;
      this.topLevelStats.currentCallsCount = stats.currentCallsCount + this.dashboardCount.totalContractorCallInQueue
      if (this.longestWaitTimerInterval != null) {
        window.clearInterval(this.longestWaitTimerInterval)
      }
      if (this.topLevelStats.longestWaitingCallAge != null && this.topLevelStats.callsInQueueCount !== 0) {
        const longestTime = Number(this.topLevelStats.longestWaitingCallAge)
        this.longestWaitTimerInterval = window.setInterval(() => {
          if (this.topLevelStats != null && this.topLevelStats.longestWaitingCallAge != null) {
            this.topLevelStats.longestWaitingCallAge =
              longestTime + Math.floor((Date.now() - Date.parse(this.topLevelStats.dataFetchedAt)) / 1000)
          }
        }, 1000)
      }
    }
  }

  public addContractorStatsHandler() {
    this.dashboardSignalRHub.addHandler('ContractorStats', this.callContractorStats)
  }
  public callContractorStats(stats: ContractorStats) {
    let storedStats = this.contractorStats
    if (storedStats != null && Date.parse(storedStats.dataFetchedAt) > Date.parse(stats.dataFetchedAt)) {
      // Older data, skip it
      return
    }

    if (storedStats != null) {
      storedStats.announcingCountHistorical = stats.announcingCountHistorical
      storedStats.newCalls = stats.newCalls
      storedStats.existingCalls = stats.existingCalls
      storedStats.updateReason = stats.updateReason
      storedStats.updateType = stats.updateType
      storedStats.dataFetchedAt = stats.dataFetchedAt
    } else {
      storedStats = { announcingCount: 0, ...stats }
      this.contractorStats = storedStats
    }

    let intervals = this.contractorStatsInterval
    if (intervals == null) {
      this.contractorStatsInterval = {
        longestNew: null,
        longestExisting: null,
      }

      intervals = this.contractorStatsInterval
    }

    window.clearInterval(intervals.longestNew || undefined)
    window.clearInterval(intervals.longestExisting || undefined)
    intervals.longestNew = null
    intervals.longestExisting = null

    if (storedStats.newCalls && storedStats.newCalls.currentCount > 0) {
      intervals.longestNew = window.setInterval(() => {
        if (storedStats && storedStats.newCalls) {
          storedStats.newCalls.longestWaitingAge = Math.floor(
            (Date.now() - Date.parse(storedStats.newCalls.longestWaitingCreatedAt)) / 1000
          )
        }
      }, 1000)
    }

    if (storedStats.existingCalls && storedStats.existingCalls.currentCount > 0) {
      intervals.longestExisting = window.setInterval(() => {
        if (storedStats && storedStats.existingCalls) {
          storedStats.existingCalls.longestWaitingAge = Math.floor(
            (Date.now() - Date.parse(storedStats.existingCalls.longestWaitingCreatedAt)) / 1000
          )
        }
      }, 1000)
    }
  }

  public addContractorHourlyStats(event: InsurerHourlyCallStats) {
    const values = 'values'

    if (this.hourlyContractorCallStats.length <= 0) {
      for (const item of Object[values](event)) {
        const stats: { [key: number]: InsurerHourlyCallStats } = {}
        this.hourlyContractorCallStats.push(item)
      }
    }
  }

  public addInsurerStatsHandler() {
    this.dashboardSignalRHub.addHandler('InsurerStats', this.callInsurerStats)
  }
  public callInsurerStats(stats: InsurerStats) {
    let storedStats = this.insurerStats.find((i) => i.insurerId === stats.insurerId)
    if (storedStats != null && Date.parse(storedStats.dataFetchedAt) > Date.parse(stats.dataFetchedAt)) {
      // Older data, skip it
      return
    }

    if (storedStats != null) {
      storedStats.insurerName = stats.insurerName
      storedStats.announcingCountHistorical = stats.announcingCountHistorical
      storedStats.newCalls = stats.newCalls
      storedStats.existingCalls = stats.existingCalls
      storedStats.updateReason = stats.updateReason
      storedStats.updateType = stats.updateType
      storedStats.dataFetchedAt = stats.dataFetchedAt
    } else {
      storedStats = { announcingCount: 0, ...stats }
      this.insurerStats.push(storedStats)
    }

    let intervals = this.insurerStatsIntervals[storedStats.insurerId]
    if (intervals == null) {
      this.insurerStatsIntervals[storedStats.insurerId] = {
        longestNew: null,
        longestExisting: null,
      }

      intervals = this.insurerStatsIntervals[storedStats.insurerId]
    }

    window.clearInterval(intervals.longestNew || undefined)
    window.clearInterval(intervals.longestExisting || undefined)
    intervals.longestNew = null
    intervals.longestExisting = null

    if (storedStats.newCalls && storedStats.newCalls.currentCount > 0) {
      intervals.longestNew = window.setInterval(() => {
        if (storedStats && storedStats.newCalls) {
          storedStats.newCalls.longestWaitingAge = Math.floor(
            (Date.now() - Date.parse(storedStats.newCalls.longestWaitingCreatedAt)) / 1000
          )
        }
      }, 1000)
    }

    if (storedStats.existingCalls && storedStats.existingCalls.currentCount > 0) {
      intervals.longestExisting = window.setInterval(() => {
        if (storedStats && storedStats.existingCalls) {
          storedStats.existingCalls.longestWaitingAge = Math.floor(
            (Date.now() - Date.parse(storedStats.existingCalls.longestWaitingCreatedAt)) / 1000
          )
        }
      }, 1000)
    }
  }

  public addWorkerReservationEventsHandler() {
    this.dashboardSignalRHub.addHandler('WorkerReservationEvents', (stats: WorkerReservationEvents) => {
      const tempWorkers = storeGetters.getWorkers()
      if (tempWorkers) {
        const worker = tempWorkers.find((w) => w.id === stats.workerSid)
        if (worker) {
          const workerIndex = tempWorkers.indexOf(worker)
          worker.missedCalls = stats.badEventsCount
          tempWorkers[workerIndex] = worker
          storeMutations.setWorkers(tempWorkers)
        }
      }
    })
  }

  public addWorkerCreatedHandler() {
    this.dashboardSignalRHub.addHandler('WorkerCreated', (event: WorkerCreated) => {
      this.addWorkers([event.worker])
    })
  }

  public addWorkerDeletedHandler() {
    this.dashboardSignalRHub.addHandler('WorkerDeleted', (event: WorkerDeleted) => {
      const tempWorkers = storeGetters.getWorkers()
      if (tempWorkers) {
        const index = tempWorkers.findIndex((w) => w.id === event.workerSid)
        if (index > -1) {
          tempWorkers.splice(index, 1)
          storeMutations.setWorkers(tempWorkers)
        }
      }
    })
  }

  public addWorkerActivityChangedHandler() {
    this.dashboardSignalRHub.addHandler('WorkerActivityChanged', (event: WorkerActivityChanged) => {
      if (this.workerActivityUpdatedAt[event.workerSid] != null) {
        if (this.workerActivityUpdatedAt[event.workerSid] > Date.parse(event.dataFetchedAt)) {
          return
        } else {
          this.workerActivityUpdatedAt[event.workerSid] = Date.parse(event.dataFetchedAt)
        }
      } else {
        this.workerActivityUpdatedAt[event.workerSid] = Date.parse(event.dataFetchedAt)
      }
      const tempWorkers = storeGetters.getWorkers()
      if (tempWorkers) {
        const worker = tempWorkers.find((w) => w.id === event.workerSid)
        if (worker != null) {
          const workerIndex = tempWorkers.indexOf(worker)
          worker.activityId = event.activitySid
          worker.activityName = event.activityName
          tempWorkers[workerIndex] = worker
          storeMutations.setWorkers(tempWorkers)
        }
      }
    })
  }

  public addWorkerAttributesChangedHandler() {
    this.dashboardSignalRHub.addHandler('WorkerAttributesChanged', (event: WorkerAttributesChanged) => {
      if (this.workerAttributesUpdatedAt[event.workerSid] != null) {
        if (this.workerAttributesUpdatedAt[event.workerSid] > Date.parse(event.dataFetchedAt)) {
          return
        } else {
          this.workerAttributesUpdatedAt[event.workerSid] = Date.parse(event.dataFetchedAt)
        }
      } else {
        this.workerAttributesUpdatedAt[event.workerSid] = Date.parse(event.dataFetchedAt)
      }
      const tempWorkers = storeGetters.getWorkers()
      if (tempWorkers) {
        const worker = tempWorkers.find((w) => w.id === event.workerSid)
        if (worker != null) {
          const index = tempWorkers.indexOf(worker)
          worker.attributes = event.workerAttributes
          if (index !== -1) {
            tempWorkers[index] = worker
          }
          storeMutations.setWorkers(tempWorkers)
        }
      }
    })
  }

  public addContractorAnnouncingCountHandler() {
    this.dashboardSignalRHub.addHandler('ContractorAnnouncingCount', this.callContractorAnnouncingUpdate)
  }
  public callContractorAnnouncingUpdate(event: ContractorAnnouncingUpdate) {
    if (this.contractorStats) {
      this.contractorStats.announcingCount = event.announcingCount
    }
  }

  public addInsurerAnnouncingCountHandler() {
    this.dashboardSignalRHub.addHandler('InsurerAnnouncingCount', this.callInsurerAnnouncingUpdate)
  }
  public callInsurerAnnouncingUpdate(event: InsurerAnnouncingUpdate) {
    const announcingUpdatedAt = this.insurerAnnouncingUpdatedAt[event.insurerId]
    if (announcingUpdatedAt == null) {
      this.insurerAnnouncingUpdatedAt[event.insurerId] = Date.parse(event.dataFetchedAt)
    } else if (announcingUpdatedAt > Date.parse(event.dataFetchedAt)) {
      return
    }

    const insurer = this.insurerStats.find((i) => i.insurerId === event.insurerId)
    if (insurer != null) {
      insurer.announcingCount = event.announcingCount
    }
  }

  public addDailyCallStatsHandler() {
    this.dashboardSignalRHub.addHandler('DailyCallStats', this.callDailyCallStats)
  }
  public callDailyCallStats(event: DailyCallStats) {
    this.todaysCallStats = event
  }

  public addIndivualInsurerCallStats(insureId: number, event: InsurerHourlyCallStats, insurerCount: InsurerCount) {
    const values = 'values'
    if (this.hourlyInsurerCallStats.length > 0) {
      for (let i = 0; i < Object[values](event).length; i++) {
        const stats = this.hourlyInsurerCallStats[i]
        stats[insureId] = event[i]
        this.hourlyInsurerCallStats.splice(i, 1, stats)
      }
    } else {
      for (const item of Object[values](event)) {
        const stats: { [key: number]: InsurerHourlyCallStats } = {}
        stats[insureId] = item
        this.hourlyInsurerCallStats.push(stats)
      }
    }
    this.dashboardCount.insurerCounts[insureId] = insurerCount
  }

  public addIndivualClientPortalCallStats(
    clientTemplateId: number,
    event: InsurerHourlyCallStats,
    clientPortalCount: InsurerCount
  ) {
    const values = 'values'
    if (this.hourlyClientPortalCallStats.length > 0) {
      for (let i = 0; i < Object[values](event).length; i++) {
        const stats = this.hourlyClientPortalCallStats[i]
        stats[clientTemplateId] = event[i]
        this.hourlyClientPortalCallStats.splice(i, 1, stats)
      }
    } else {
      for (const item of Object[values](event)) {
        const stats: { [key: number]: InsurerHourlyCallStats } = {}
        stats[clientTemplateId] = item
        this.hourlyClientPortalCallStats.push(stats)
      }
    }
    this.clientPortalJobCount[clientTemplateId] = clientPortalCount
  }

  public addUpdateSurgeHandler() {
    this.dashboardSignalRHub.addHandler('UpdateSurge', (event: UpdateSurge) => {
      this.inSurge = event.inSurge
    })
  }

  public addCallCountHandler() {
    this.dashboardSignalRHub.addHandler('AddCallCount', (event: AddCallCount) => {
      this.dashboardCount.totalCalls = this.dashboardCount.totalCalls + 1
      const totalCall = this.dashboardCount.insurerCounts[event.insurerId].totalCalls
      this.dashboardCount.insurerCounts[event.insurerId].totalCalls = totalCall + 1
    })
  }

  public addUpdateTwilioTopTilesCountHandler() {
    this.dashboardSignalRHub.addHandler('UpdateTwilioTopTilesCount', (event: UpdateTwilioTopTilesCount) => {
      if (event.totalContractorCallInQueue === 0 && event.longestContractorCallInQueue === 0) {
        this.dashboardCount.averageSlaRateToday = event.slaRate
        if (this.dashboardCount.highestTaskCountInQueue < event.highestCallQueueCount) {
          this.dashboardCount.highestTaskCountInQueue = event.highestCallQueueCount
        }
        if (this.dashboardCount.longestTaskWaitingAge < event.longestTaskWaitingAge) {
          this.dashboardCount.longestTaskWaitingAge = event.longestTaskWaitingAge
        }
      } else {
        // add and remove contractor call from total calls
        const self = this
        if (self.topLevelStats) {
          if (self.dashboardCount.totalContractorCallInQueue > event.totalContractorCallInQueue) {
            self.topLevelStats.currentCallsCount -=
              self.dashboardCount.totalContractorCallInQueue - event.totalContractorCallInQueue
            if (self.topLevelStats.currentCallsCount < 0) {
              self.topLevelStats.currentCallsCount = 0
            }
          } else if (self.dashboardCount.totalContractorCallInQueue < event.totalContractorCallInQueue) {
            self.topLevelStats.currentCallsCount +=
              event.totalContractorCallInQueue - self.dashboardCount.totalContractorCallInQueue
          }
        }
        this.dashboardCount.totalContractorCallInQueue = event.totalContractorCallInQueue

        if (this.dashboardCount.longestContractorCallInQueue < event.longestContractorCallInQueue) {
          this.dashboardCount.longestContractorCallInQueue = event.longestContractorCallInQueue
        }
      }
    })
  }

  public addUpdateJobCountHandler() {
    this.dashboardSignalRHub.addHandler(
      'UpdateJobCount',
      (totalJobLogged: number, totalJobDeployed: number, insurerJobLoggedCount: {}, insurerJobDeployedCount: {}) => {
        this.dashboardCount.totalLoggedJobs = totalJobLogged
        this.dashboardCount.totalDeployedJobs = totalJobDeployed
        Object.keys(insurerJobLoggedCount).forEach((key) => {
          const insureId = Number(key)
          if (this.dashboardCount.insurerCounts[insureId]) {
            this.dashboardCount.insurerCounts[insureId].totalLoggedJobs = insurerJobLoggedCount[insureId]
          }
        })
        Object.keys(insurerJobDeployedCount).forEach((key) => {
          const insureId = Number(key)
          if (this.dashboardCount.insurerCounts[insureId]) {
            this.dashboardCount.insurerCounts[insureId].totalDeployedJobs = insurerJobDeployedCount[insureId]
          }
        })
      }
    )
  }

  public addWorkers(createdWorkers: Worker[]) {
    const workers: WorkerWithAdditions[] = createdWorkers.map((w) => ({ missedCalls: 0, ...w }))
    const tempWorkers: WorkerWithAdditions[] = []
    for (const worker of workers) {
      if (tempWorkers) {
        tempWorkers.push(worker)
      }
    }
    this.workers = tempWorkers
    storeMutations.setWorkers(tempWorkers)
  }

  public addUpsertComplaintHandler() {
    this.complaintDashboardSignalRHub.addHandler(
      'upsertComplaint',
      async (
        jobId: string,
        requestId: string,
        requestType: string,
        processedItem: RequestProcessedRecord,
        success: boolean
      ) => {
        await JobController.GetJobDocument(jobId, processedItem.id).then((data: CosmosJobsDbRecordBase | null) => {
          const complaint = Object.assign(new ComplaintModel(), data)
          if (complaint) {
            if (!this.complaintListForDashBoard) {
              this.complaintListForDashBoard = new ComplaintTrackerModel()
            }
            if (!this.complaintListForDashBoard.complaints) {
              this.complaintListForDashBoard.complaints = []
            }
            const getUser = this.userList.find((u) => u.id === complaint.responsiblePerson)
            if (getUser) {
              complaint.responsiblePersonName = getUser.displayValue
              complaint.teamName = getUser.teamName ? getUser.teamName : ''
            }
            const index: number = this.complaintListForDashBoard.complaints.findIndex(
              (c: ComplaintModel) => c.id === complaint.id
            )

            if (index === -1 && complaint.isResolved) {
              return
            }
            if (index === -1 && !complaint.isResolved) {
              this.complaintListForDashBoard.complaints.push(complaint)
            } else {
              if (complaint.isResolved) {
                this.complaintListForDashBoard.complaints.splice(index, 1, complaint)
                this.complaintListForDashBoard.totalClosedComplaints += 1
                if (
                  this.complaintListForDashBoard.closeComplaint &&
                  this.complaintListForDashBoard.closeComplaint.day0
                ) {
                  this.complaintListForDashBoard.closeComplaint.day0 =
                    this.complaintListForDashBoard.totalClosedComplaints
                }
                if (Shared.isComplaintOverdue(complaint)) {
                  this.complaintListForDashBoard.totalOverdueComplaints -= 1
                  if (
                    this.complaintListForDashBoard.overdueComplaint &&
                    this.complaintListForDashBoard.overdueComplaint.day0
                  ) {
                    this.complaintListForDashBoard.overdueComplaint.day0 =
                      this.complaintListForDashBoard.totalOverdueComplaints
                  }
                }
              } else {
                const oldRecord = this.complaintListForDashBoard.complaints[index]

                const currentIsOverdue = Shared.isComplaintOverdue(complaint)
                const pastWasOverdue = Shared.isComplaintOverdue(oldRecord)
                if (pastWasOverdue && !currentIsOverdue) {
                  this.complaintListForDashBoard.totalOverdueComplaints -= 1
                } else if (!pastWasOverdue && currentIsOverdue) {
                  this.complaintListForDashBoard.totalOverdueComplaints += 1
                }

                if (
                  this.complaintListForDashBoard.overdueComplaint &&
                  this.complaintListForDashBoard.overdueComplaint.day0
                ) {
                  this.complaintListForDashBoard.overdueComplaint.day0 =
                    this.complaintListForDashBoard.totalOverdueComplaints
                }
                this.complaintListForDashBoard.complaints.splice(index, 1, complaint)
              }
            }
            if (this.complaintListForDashBoard.openComplaint && this.complaintListForDashBoard.openComplaint.day0) {
              this.complaintListForDashBoard.openComplaint.day0 = this.complaintListForDashBoard.complaints.filter(
                (x: ComplaintModel) => !x.isResolved
              ).length
            }
            this.complaintListForDashBoard.totalOpenComplaints = this.complaintListForDashBoard.complaints.filter(
              (x: ComplaintModel) => !x.isResolved
            ).length
          }
        })
      }
    )
  }

  public addClientPortalStats() {
    this.clientPortalPanelHub.addHandler('clientPortalStats', this.callClientPortalStats)
  }
  public callClientPortalStats(clientTemplateIds: string[], stats: ClientPortalStats) {
    for (const id of clientTemplateIds) {
      let storedStats = this.clientPortalStats.find((i) => i.clientTemplateId === id)
      if (storedStats != null && Date.parse(storedStats.dataFetchedAt) > Date.parse(stats.dataFetchedAt)) {
        // Older data, skip it
        return
      }

      if (storedStats != null) {
        storedStats.announcingCountHistorical = stats.announcingCountHistorical
        storedStats.newCalls = stats.newCalls
        storedStats.existingCalls = stats.existingCalls
        storedStats.updateReason = stats.updateReason
        storedStats.updateType = stats.updateType
        storedStats.dataFetchedAt = stats.dataFetchedAt
      } else {
        storedStats = { announcingCount: 0, ...stats }
        this.clientPortalStats.push(storedStats)
      }

      let intervals = this.insurerStatsIntervals[storedStats.clientTemplateId]
      if (intervals == null) {
        this.insurerStatsIntervals[storedStats.clientTemplateId] = {
          longestNew: null,
          longestExisting: null,
        }
        intervals = this.insurerStatsIntervals[storedStats.clientTemplateId]
      }

      window.clearInterval(intervals.longestNew || undefined)
      window.clearInterval(intervals.longestExisting || undefined)
      intervals.longestNew = null
      intervals.longestExisting = null

      if (storedStats.newCalls && storedStats.newCalls.currentCount > 0) {
        intervals.longestNew = window.setInterval(() => {
          if (storedStats && storedStats.newCalls) {
            storedStats.newCalls.longestWaitingAge = Math.floor(
              (Date.now() - Date.parse(storedStats.newCalls.longestWaitingCreatedAt)) / 1000
            )
          }
        }, 1000)
      }

      if (storedStats.existingCalls && storedStats.existingCalls.currentCount > 0) {
        intervals.longestExisting = window.setInterval(() => {
          if (storedStats && storedStats.existingCalls) {
            storedStats.existingCalls.longestWaitingAge = Math.floor(
              (Date.now() - Date.parse(storedStats.existingCalls.longestWaitingCreatedAt)) / 1000
            )
          }
        }, 1000)
      }
    }
  }

  public addClientPortalJobCount() {
    this.clientPortalPanelHub.addHandler('clientPortalJobCount', (newCounts: ClientPortalJobCount[]) => {
      if (newCounts && newCounts.length > 0) {
        for (const item of newCounts) {
          const clientPortal = this.clientPortalJobCount[item.clientTemplateId]
          if (clientPortal) {
            clientPortal.totalLoggedJobs = item.totalJobLogged
            clientPortal.totalDeployedJobs = item.totalJobDeployed
          }
        }
      }
    })
  }

  public addClientPortalCallCount() {
    this.clientPortalPanelHub.addHandler('clientPortalCallCount', (ids: string[]) => {
      if (ids && ids.length > 0) {
        for (const id of ids) {
          const clientPortal = this.clientPortalJobCount[id]
          if (clientPortal) {
            clientPortal.totalCalls += 1
          }
        }
      }
    })
  }

  public addClientPortalAnnouncingCount() {
    this.clientPortalPanelHub.addHandler(
      'clientPortalAnnouncingCount',
      (updateAnnouncingCount: { [key: string]: number }) => {
        const keys = Object.keys(updateAnnouncingCount)
        for (const key of keys) {
          const clientPortal = this.clientPortalStats.find((c: ClientPortalStats) => c.clientTemplateId === key)
          if (clientPortal) {
            clientPortal.announcingCount = updateAnnouncingCount[key]
          }
        }
      }
    )
  }
}
</script>
