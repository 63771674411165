import Emergency from '@/models/Emergency'
import JobNoteModel from '@/models/claim/JobNoteModel'
import TimeLineCardModel from '@/models/TimeLineCardModel'
import Job from '@/models/Job'
import { TimeLineHelper } from './TimeLineHelper'
import Shared from '@/common/shared'
import { EngineerJobVisitStatus, CommodityWorkType } from '@/common/enums'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import moment, { Moment } from 'moment'
import EmailModel from '@/models/claim/EmailModel'
import { JobVisitStatus } from './../../../common/enums'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import ComplaintModel from '@/models/claim/ComplaintModel'
import { useLdClient } from '@/plugins/ld-user-plugin'

class USTimeLineHelper extends TimeLineHelper {
  public generateCards(job: Job): TimeLineCardModel[] {
    const cards: TimeLineCardModel[] = []

    // fnol card
    cards.push(
      new TimeLineCardModel({
        title: 'New Job',
        colour: 'purple',
        icon: 'email',
        createdDate: job.createdAt,
        forJobId: job.id,
        cardBodyComponent: 'FNOLCard',
        detailComponent: 'FNOLPreview',
      })
    )

    job.emergencies
      .filter((e) => e.displayInTimeline)
      .forEach((emergency) => {
        const emergencyDate = new Date(emergency.createdAt.toString())
        // bind pending or cancelled trade appointed cards
        const appointedGeneralContractors: ContractorAppointedModel[] = job.contractorAppointed.filter(
          (c) => c.emergencyId === emergency.id && c.status !== JobVisitStatus[JobVisitStatus.Unavailable]
        )
        const sortedContractors = Shared.sortArrayByDate(appointedGeneralContractors, 'createdAt')
        sortedContractors.forEach((appointedContractor) => {
          if (
            appointedContractor.status !== JobVisitStatus[JobVisitStatus.AutoDeploying] ||
            this.isLegacyAutoDeploymentEnabled
          )
            cards.push(
              new TimeLineCardModel({
                title: appointedContractor.companyName,
                status: appointedContractor.status,
                colour: appointedContractor.status === JobVisitStatus[JobVisitStatus.Cancelled] ? 'red' : 'green',
                icon: appointedContractor.status === JobVisitStatus[JobVisitStatus.Cancelled] ? 'block' : 'check',
                createdDate: appointedContractor.createdAt,
                forJobId: appointedContractor.jobId,
                forItemId: appointedContractor.id,
                forEmergencyId: appointedContractor.emergencyId,
                cardBodyComponent: 'TradeAppointedCard',
                detailComponent: 'TradeAppointedPreview',
                cardClass: '',
              })
            )
        })

        // If commodityWorkType is Response - add call contractor button if engineer visit is completed and repair estimation detail is added
        // If commodityWorkType is Repair - add call contractor button if repair estimation detail is added
        const isContractorUnassigned = !appointedGeneralContractors.find(
          (c) =>
            c.status === JobVisitStatus[JobVisitStatus.Pending] ||
            c.status === JobVisitStatus[JobVisitStatus.Unavailable] ||
            c.status === JobVisitStatus[JobVisitStatus.InProgress] ||
            c.status === JobVisitStatus[JobVisitStatus.Completed] ||
            c.status === JobVisitStatus[JobVisitStatus.Accepted] ||
            c.status === JobVisitStatus[JobVisitStatus.AutoDeploying]
        )
        if (
          !(job.status === 'Cancelled' || job.cancellationReason) &&
          (this.isCommodityWorkTypeResponse(job)
            ? this.showCardForResponseVisit(job, isContractorUnassigned)
            : job.repairEstimates && job.repairEstimates.length > 0 && isContractorUnassigned)
        ) {
          cards.push(
            new TimeLineCardModel({
              title: '',
              colour: 'yellow',
              icon: 'call',
              createdDate: this.getCallContractorCardDate(job, emergencyDate),
              forJobId: job.id,
              forItemId: '',
              forEmergencyId: emergency.id,
              cardBodyComponent: 'CallContractorCard',
              detailComponent: 'CallContractorPreview',
              cardClass: 'flatCard',
            })
          )
        }

        if (job.contractorAppointed.length) {
          cards.push(
            new TimeLineCardModel({
              title: 'Questions',
              colour: 'yellow',
              icon: 'forum',
              createdDate: this.getQuestionsCreatedDate(job),
              forJobId: job.jobId,
              forEmergencyId: emergency.id,
              cardBodyComponent: 'SiUsJobQuestionsCard',
              detailComponent: 'SiUsJobQuestionsPreview',
              cardFooterComponent: '',
            })
          )

          // VulnerabilityQA Card
          if (job.vulnerabilityQA && emergency.isEmergencyAccepted) {
            cards.push(
              new TimeLineCardModel({
                title: 'Vulnerability Question',
                colour: 'yellow',
                icon: 'forum',
                createdDate: this.getQuestionsCreatedDate(job).add(5, 'seconds'),
                forJobId: job.vulnerabilityQA.jobId,
                forItemId: job.vulnerabilityQA.id,
                cardBodyComponent: 'VulnerabilityQACard',
                detailComponent: 'VulnerabilityQAPreview',
                cardFooterComponent: '',
              })
            )
          }

          if (emergency.isEmergencyAccepted) {
            let showOtherCards = false
            if (job.enablementTriggeredAt !== null) {
              if (job.enablementCompletedAt !== null) {
                showOtherCards = true
              }
            } else {
              showOtherCards = true
            }

            if (showOtherCards) {
              // Show Confirm Date card when there is pending or accepted contractor available and all emergencies have been accepted
              job.contractorAppointed
                .filter((cad) => {
                  return (
                    cad.status === JobVisitStatus[JobVisitStatus.Pending] ||
                    cad.status === JobVisitStatus[JobVisitStatus.Accepted]
                  )
                })
                .forEach((contractor) => {
                  cards.push(
                    new TimeLineCardModel({
                      title: 'Confirm Date',
                      colour: 'green',
                      icon: 'info',
                      createdDate: this.getEnablementNotesDate(job),
                      forJobId: job.id,
                      forEmergencyId: emergency.id,
                      forItemId: contractor.id,
                      status: contractor.status,
                      cardBodyComponent: 'ConfirmDateCard',
                      detailComponent: 'ConfirmDatePreview',
                    })
                  )
                })
            }
          }
        }
      })

    if (job.engineerVisitDetail && job.engineerVisitDetail.length > 0) {
      job.engineerVisitDetail.forEach((engineerVisitDetail: EngineerVisitDetail) => {
        // bind Engineer Visit Detail
        cards.push(
          this.bindEngineerVisitCard(engineerVisitDetail, job.emergencies[job.emergencies.length - 1], job.jobType)
        )
      })

      // bind site data card
      const firstEngineerVisit: EngineerVisitDetail | undefined = job.engineerVisitDetail[0]
      if (firstEngineerVisit) {
        // bind only when commodityWorkType is Response
        if (
          firstEngineerVisit.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed] &&
          job.cctvControlLog &&
          this.isCommodityWorkTypeResponse(job)
        ) {
          cards.push(this.bindSiteDataCard(job, firstEngineerVisit))
        }
      }

      const lastEngineerVisit: EngineerVisitDetail | undefined =
        job.engineerVisitDetail[job.engineerVisitDetail.length - 1]
      if (lastEngineerVisit) {
        // report card
        if (
          lastEngineerVisit &&
          lastEngineerVisit.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed] &&
          (!this.isContractorDeploymentPending(cards, job) || job.drainageReport)
        ) {
          cards.push(
            new TimeLineCardModel({
              title: 'Report',
              colour: 'purple',
              icon: 'report',
              createdDate: moment(moment(lastEngineerVisit.createdAt)).add(5, 'seconds'),
              forJobId: job.id,
              cardBodyComponent: 'DrainageReportCard',
              detailComponent: 'DrainageReportPreview',
            })
          )
        }
      }
    }

    // Customer To Pay Card
    if (job.customerToPay && job.customerToPay.length > 0) {
      job.customerToPay.forEach((element: CustomerToPayModel) => {
        if (element.emergencyId === job.emergencies[0].id) {
          cards.push(this.bindCustomerToPayCard(element.jobId, element))
        }
      })
    }
    // Complaint Card
    if (job.complaint && job.complaint.length > 0) {
      job.complaint.forEach((complaint: ComplaintModel) => {
        cards.push(this.bindComplaintCard(job, complaint))
      })
    }

    // Email Card
    if (job.Emails && job.Emails.length > 0) {
      job.Emails.forEach((email: EmailModel) => {
        cards.push(this.addEmailCardToList(email))
      })
    }
    // Job Note Card
    if (job.jobNotes && job.jobNotes.length > 0) {
      job.jobNotes.forEach((note: JobNoteModel) => {
        cards.push(this.bindJobNoteCard(job, note))
      })
    }
    // Job Delay Card
    if (job.usJobDelay) {
      cards.push(this.bindJobDelayCard(job, job.usJobDelay))
    }

    // picture upload card
    const jobClaimPictures = Object.assign([], job.claimPictures)
    const sortedClaimPictures = jobClaimPictures ? Shared.sortArrayByDate(jobClaimPictures, 'createdAt') : []
    if (sortedClaimPictures && sortedClaimPictures.length > 0) {
      cards.push(this.bindPictureUploadCard(sortedClaimPictures))
    }

    // repair estimate card
    if (job && job.repairEstimates && job.repairEstimates.length > 0) {
      cards.push(
        new TimeLineCardModel({
          title: 'Repair Estimate',
          colour: 'blue',
          icon: 'home_repair_service',
          createdDate:
            job.repairEstimates && job.repairEstimates.length > 0
              ? job.repairEstimates[job.repairEstimates.length - 1].createdAt
              : moment(),
          forJobId: job.id,
          forItemId: '',
          forEmergencyId: '',
          cardBodyComponent: 'RepairEstimatesCard',
          detailComponent: 'RepairEstimatesPreview',
          cardFooterComponent: '',
        })
      )
    }

    // add card to allow user to complete the job
    if (
      (job.cancellationReason ? true : !this.isContractorDeploymentPending(cards, job)) &&
      this.showCompleteJobConfirmationCard(job)
    ) {
      cards.push(this.bindJobCompleteConfirmationCard(job))
    }
    // Job Complete Card
    if (job.status === 'Completed' || job.status === 'Cancelled') {
      cards.push(this.bindJobCompleteCard(job))
      // Job Financial Summary Card
      cards.push(this.bindJobFinancialSummaryCard(job))
    }

    return cards
  }

  public displaySelectedEmergencyCard(job: Job, card: any) {
    let result = false
    if (
      card.cardBodyComponent === 'FNOLCard' ||
      card.cardBodyComponent === 'CallContractorCard' ||
      card.cardBodyComponent === 'TradeAppointedCard' ||
      card.cardBodyComponent === 'JobNoteCard' ||
      (card.cardBodyComponent === 'SiUsJobQuestionsCard' && job.contractorAppointed.length > 0) ||
      card.cardBodyComponent === 'VulnerabilityQACard' ||
      card.cardBodyComponent === 'ConfirmDateCard' ||
      card.cardBodyComponent === 'DrainageReportCard' ||
      card.cardBodyComponent === 'JobDelayCard' ||
      card.cardBodyComponent === 'CustomerToPayCard' ||
      card.cardBodyComponent === 'EngineerVisitCard' ||
      card.cardBodyComponent === 'PictureUploadCard' ||
      card.cardBodyComponent === 'RepairEstimatesCard' ||
      card.cardBodyComponent === 'JobCompleteConfirmationCard' ||
      card.cardBodyComponent === 'JobCompleteCard' ||
      card.cardBodyComponent === 'JobFinancialSummaryCard' ||
      card.cardBodyComponent === 'ComplaintCard'
    ) {
      result = true
    } else if (card.cardBodyComponent === 'SiteDataCard') {
      result = this.showSiteDataCard(job)
    }
    return result
  }

  public showCompleteJobConfirmationCard(job: Job): boolean {
    if (job && job.status === 'Completed') {
      // check if job status is Completed
      return false
    } else if (job && job.cancellationReason && job.status !== 'Completed') {
      // check if job status is cancelled or cancellation reason is added
      return job.status === 'Cancelled' ? false : true
    } else if (job && job.drainageReport) {
      // check if job drainage report is generated
      return true
    }
    return false
  }

  public getCallContractorCardDate(job: Job, emergencyDate: Date): Moment {
    if (job.repairEstimates && job.repairEstimates.length > 0) {
      // find max date from engineerVisitDetail and repairEstimate records and set as created date based on CommodityWorkType
      const engineerVisitDetailDates = job.engineerVisitDetail.map((x) => x.createdAt)
      const repairEstimatesDates = job.repairEstimates.map((x) => x.createdAt)
      const dates = this.isCommodityWorkTypeResponse(job)
        ? moment(moment.max(engineerVisitDetailDates)) > moment(moment.max(repairEstimatesDates))
          ? engineerVisitDetailDates
          : repairEstimatesDates
        : repairEstimatesDates
      return moment(moment.max(dates)).add(5, 'seconds')
    }
    if (job.contractorAppointed.length) {
      const dates: moment.Moment[] = job.contractorAppointed
        .filter((c) => c.status !== JobVisitStatus[JobVisitStatus.Unavailable])
        .map((x) => x.createdAt)
      return moment(moment.max(dates)).add(5, 'seconds')
    }
    return moment(emergencyDate).add(5, 'seconds')
  }

  public getQuestionsCreatedDate(job: Job): Moment {
    const dates: moment.Moment[] = job.contractorAppointed
      .filter((c) => c.status !== JobVisitStatus[JobVisitStatus.Unavailable])
      .map((x) => x.createdAt)
    return moment(moment.min(dates)).add(10, 'seconds')
  }

  public getEnablementNotesDate(job: Job): Moment {
    const dates: moment.Moment[] = job.contractorAppointed
      .filter((c) => c.status !== JobVisitStatus[JobVisitStatus.Unavailable])
      .map((x) => x.createdAt)
    return job.repairEstimates && job.repairEstimates.length > 0
      ? this.isCommodityWorkTypeResponse(job)
        ? moment(moment.max(dates))
        : moment(moment.max(dates)).add(15, 'seconds')
      : moment(moment.min(dates)).add(15, 'seconds')
  }

  // show site data card
  private showSiteDataCard(job: Job): boolean {
    if (job && job.engineerVisitDetail.length > 0) {
      let isSiteDataLoaded = false
      for (const visit of job.engineerVisitDetail) {
        if (job.cctvControlLog !== null) {
          if (job.cctvControlLog.forEngineerVisitDetailId === visit.id) {
            isSiteDataLoaded = true
            break
          }
        }
      }
      return isSiteDataLoaded
    }
    return false
  }

  private showCardForResponseVisit(job: Job, isContractorUnassigned: boolean) {
    let showCallContractorCard = false
    if (job && job.repairEstimates && job.repairEstimates.length > 0) {
      // check if only one contractor appointed detail record with status 'Completed' exist or any contractor is assigned or not yet
      showCallContractorCard =
        job.contractorAppointed.length === 1 &&
        job.contractorAppointed[0].status === JobVisitStatus[JobVisitStatus.Completed]
          ? true
          : isContractorUnassigned
          ? true
          : false
    } else {
      showCallContractorCard = isContractorUnassigned
    }
    return showCallContractorCard
  }

  private isCommodityWorkTypeResponse(job: Job) {
    return job.commodityWorkType.toString() === CommodityWorkType[CommodityWorkType.Response]
  }

  private isContractorDeploymentPending(cards: TimeLineCardModel[], job: Job) {
    const contractorAppointedList = job.contractorAppointed.filter(
      (c) =>
        c.status === JobVisitStatus[JobVisitStatus.Pending] ||
        c.status === JobVisitStatus[JobVisitStatus.InProgress] ||
        c.status === JobVisitStatus[JobVisitStatus.Accepted] ||
        c.status === JobVisitStatus[JobVisitStatus.AutoDeploying]
    )
    return (
      contractorAppointedList.length > 0 || cards.filter((c) => c.cardBodyComponent === 'CallContractorCard').length > 0
    )
  }

  private get isLegacyAutoDeploymentEnabled(): boolean {
    const ldClient = useLdClient()
    return ldClient !== undefined ? !ldClient.variation('fnol-401-multiple-contractor-notifications') : false
  }
}

const usTimeLineHelper: USTimeLineHelper = new USTimeLineHelper()
export default usTimeLineHelper
