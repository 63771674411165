<template>
  <div class="pb-2">
    <v-card class="pt-0 px-2 pb-2 up-card">
      <v-layout wrap class="clt-avb-card">
        <v-flex xs12>
          <h3 class="pt-3 pb-1 pl-2">Customer Availability</h3>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 class="mb-2">
                <TimeSlotPicker
                  :header-text="getFirstPickedDate"
                  :date-selection="true"
                  :filter-by-date="true"
                  :filter-slot-by-time="getIsFirstFilterSlotByTime"
                  slot-type="Today"
                  :model="customerAvailability"
                  :slot-items.sync="firstDateTimeSlots"
                  @updateSlotItems="onSlotSelectionChange"
                  @updateDayTwoTimeSlot="updateDayTwoTimeSlot"
                ></TimeSlotPicker>
              </v-flex>

              <v-flex
                v-if="
                  !hasSecondSlotAvailabilities &&
                  secondDateTimeSlots.length === 0 &&
                  thirdDateTimeSlots.length === 0 &&
                  firstDateTimeSlots.length > 0
                "
                xs12
                class="text-xs-right py-0"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      flat
                      color="primary"
                      class="primary--text grey lighten-3 ma-0 elevation-0 add-second-slot"
                      v-on="on"
                      @click="showSecondSlotAvailabilities"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>More Availability Slots</span>
                </v-tooltip>
              </v-flex>
              <v-flex
                v-if="hasSecondSlotAvailabilities || secondDateTimeSlots.length > 0 || thirdDateTimeSlots.length > 0"
                xs12
                class="mb-2"
              >
                <TimeSlotPicker
                  :header-text="getSecondPickedDate"
                  :date-selection="true"
                  :filter-by-date="true"
                  :filter-slot-by-time="getIsSecondFilterSlotByTime"
                  slot-type="Tomorrow"
                  :model="customerAvailability"
                  :slot-items.sync="secondDateTimeSlots"
                  @updateSlotItems="onSlotSelectionChange"
                ></TimeSlotPicker>
              </v-flex>

              <v-flex
                v-if="!hasThirdSlotAvailabilities && thirdDateTimeSlots.length === 0 && secondDateTimeSlots.length > 0"
                xs12
                class="text-xs-right py-0"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      flat
                      color="primary"
                      class="primary--text grey lighten-3 ma-0 elevation-0 add-third-slot"
                      v-on="on"
                      @click="showThirdSlotAvailabilities"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>More Availability Slots</span>
                </v-tooltip>
              </v-flex>
              <v-flex v-if="hasThirdSlotAvailabilities || thirdDateTimeSlots.length > 0" xs12>
                <TimeSlotPicker
                  :header-text="getThirdPickedDate"
                  :date-selection="true"
                  :filter-by-date="true"
                  :filter-slot-by-time="getIsThirdFilterSlotByTime"
                  slot-type="DayAfterTomorrow"
                  :model="customerAvailability"
                  :slot-items.sync="thirdDateTimeSlots"
                  @updateSlotItems="onSlotSelectionChange"
                ></TimeSlotPicker>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-card>
    <v-layout class="mt-3 elevation-1">
      <v-flex xs12 white>
        <DelayCodes
          :has-delay="customerAvailability.hasDelay"
          :delay-code="customerAvailability.delayCode"
          @updateDelayCode="onDelayCodeChange"
        ></DelayCodes>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeSlot from '@/models/claim/TimeSlot'
import moment from 'moment'
import JobController from '@/api/jobController'
import Shared from '@/common/shared'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import Store from '@/store'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import AddCustomerAvailabilityTimeSlot from '@/models/requests/AddCustomerAvailabilityTimeSlot'
import TimeSlotPicker from '@/components/TimeSlotPicker.vue'
import DelayCodes from '@/components/timeline/DelayCodes.vue'
import eventBus from '@/common/bus'

@Component({
  components: { TimeSlotPicker, DelayCodes },
})
export default class CustomerAvailabilityPreview extends TimeLineItemBase {
  private firstDateTimeSlots: TimeSlot[] = []
  private secondDateTimeSlots: TimeSlot[] = []
  private thirdDateTimeSlots: TimeSlot[] = []
  private firstPickedDate = ''
  private secondPickedDate = ''
  private thirdPickedDate = ''
  private hasDelay: boolean
  private delayCode: string | null
  private hasSecondSlotAvailabilities = false
  private hasThirdSlotAvailabilities = false
  private customerAvailabilityModel: CustomerAvailabilityModel = new CustomerAvailabilityModel()

  private created() {
    this.setCustomerAvailability(true)
  }

  private setCustomerAvailability(fillDefaultDates: boolean) {
    this.customerAvailability = Object.assign({}, this.getCustomerAvailability)
    if (
      this.customerAvailability &&
      this.customerAvailability.timeSlot &&
      this.customerAvailability.timeSlot.length > 0
    ) {
      this.customerAvailability.timeSlot.forEach((e: TimeSlot) => {
        e.startTime = new Date(e.startTime)
        e.endTime = new Date(e.endTime)
      })
    }

    if (!this.customerAvailability.timeSlot) {
      this.customerAvailability.timeSlot = []
    }

    if (fillDefaultDates) {
      // fill default dates as today, tomorrow and day after tomorrow
      this.fillDefaultDates()
    }

    this.firstDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 1)
    if (this.firstDateTimeSlots.length > 0) {
      Shared.SortData(this.firstDateTimeSlots, 'startTime')
      this.getFirstPickedDate = Shared.getFormatedDate(
        moment(this.firstDateTimeSlots.map((c) => c.startTime)[0]),
        Store.Instance.state.Environment.DateFormat
      )
    }
    this.secondDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 2)
    if (this.secondDateTimeSlots.length > 0) {
      Shared.SortData(this.secondDateTimeSlots, 'startTime')
      this.getSecondPickedDate = Shared.getFormatedDate(
        moment(this.secondDateTimeSlots.map((c) => c.startTime)[0]),
        Store.Instance.state.Environment.DateFormat
      )
    }
    this.thirdDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 3)
    if (this.thirdDateTimeSlots.length > 0) {
      Shared.SortData(this.thirdDateTimeSlots, 'startTime')
      this.getThirdPickedDate = Shared.getFormatedDate(
        moment(this.thirdDateTimeSlots.map((c) => c.startTime)[0]),
        Store.Instance.state.Environment.DateFormat
      )
    }
  }

  private fillDefaultDates() {
    const tempDate: Date = new Date()
    this.firstPickedDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
    tempDate.setDate(tempDate.getDate() + 1)
    this.secondPickedDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
    tempDate.setDate(tempDate.getDate() + 1)
    this.thirdPickedDate = Shared.getFormatedDate(moment(tempDate), Store.Instance.state.Environment.DateFormat)
  }

  @Watch('getCustomerAvailability')
  private onCustomerAvailabilityChange() {
    this.setCustomerAvailability(false)
  }

  private get getCustomerAvailability(): CustomerAvailabilityModel {
    return storeGetters.getCustomerAvailability(this.jobId)
  }

  private get customerAvailability(): CustomerAvailabilityModel {
    return this.customerAvailabilityModel
  }

  private set customerAvailability(newValue: CustomerAvailabilityModel) {
    this.customerAvailabilityModel = newValue
  }

  private get getFirstPickedDate(): string {
    return this.firstPickedDate
  }

  private set getFirstPickedDate(newValue: string) {
    this.firstPickedDate = newValue
  }

  private get getIsFirstFilterSlotByTime(): boolean {
    return this.isTodayDate(this.getFirstPickedDate)
  }

  private get getSecondPickedDate(): string {
    return this.secondPickedDate
  }

  private set getSecondPickedDate(newValue: string) {
    this.secondPickedDate = newValue
  }

  private get getIsSecondFilterSlotByTime(): boolean {
    return this.isTodayDate(this.getSecondPickedDate)
  }

  private get getThirdPickedDate(): string {
    return this.thirdPickedDate
  }

  private set getThirdPickedDate(newValue: string) {
    this.thirdPickedDate = newValue
  }

  private get getIsThirdFilterSlotByTime(): boolean {
    return this.isTodayDate(this.getThirdPickedDate)
  }

  private isTodayDate(date: string): boolean {
    if (date === Shared.getFormatedDate(moment(new Date()), Store.Instance.state.Environment.DateFormat)) {
      return true
    }
    return false
  }

  private onSlotSelectionChange(slotItems: TimeSlot[], model: CustomerAvailabilityModel, slotType: string) {
    if (slotType === 'Today') {
      slotItems.forEach((slot: TimeSlot) => {
        slot.availabilityOrder = 1
      })
      // this.firstDateTimeSlots = slotItems;
      this.firstDateTimeSlots = this.filterSlots(slotItems, slotType)
    } else if (slotType === 'Tomorrow') {
      slotItems.forEach((slot: TimeSlot) => {
        slot.availabilityOrder = 2
      })
      this.secondDateTimeSlots = this.filterSlots(slotItems, slotType)
      // this.secondDateTimeSlots = slotItems;
    } else if (slotType === 'DayAfterTomorrow') {
      slotItems.forEach((slot: TimeSlot) => {
        slot.availabilityOrder = 3
      })
      this.thirdDateTimeSlots = this.filterSlots(slotItems, slotType)
      // this.thirdDateTimeSlots = slotItems;
    }

    this.save()
  }

  private filterSlots(slotItems: TimeSlot[], slotType: string): TimeSlot[] {
    if (slotItems.length === 0) {
      return slotItems
    }
    // check is today date
    let slotItemsTemp: TimeSlot[] = slotItems
    slotItemsTemp = slotItemsTemp.filter((x) => x.slotId !== -1 && x.slotId !== 0)
    let selectedDate = new Date()
    if (slotItemsTemp.length > 0) {
      selectedDate = new Date(slotItemsTemp[0].startTime)
    }
    const todayDate = new Date()

    // update selected date
    if (slotType === 'Today') {
      this.getFirstPickedDate = Shared.getFormatedDate(
        moment(slotItemsTemp.map((c) => c.startTime)[0]),
        Store.Instance.state.Environment.DateFormat
      )
    } else if (slotType === 'Tomorrow') {
      this.getSecondPickedDate = Shared.getFormatedDate(
        moment(slotItemsTemp.map((c) => c.startTime)[0]),
        Store.Instance.state.Environment.DateFormat
      )
    } else if (slotType === 'DayAfterTomorrow') {
      this.getThirdPickedDate = Shared.getFormatedDate(
        moment(slotItemsTemp.map((c) => c.startTime)[0]),
        Store.Instance.state.Environment.DateFormat
      )
    }

    // if selected date is not today return with the existing slots
    if (selectedDate && selectedDate.setHours(0, 0, 0, 0) !== todayDate.setHours(0, 0, 0, 0)) {
      return slotItems
    }

    // if today's date selected
    const timeSlotsAsPerCurrentTime: TimeSlot[] = []
    const tempDate: Date = new Date()
    const length = 24
    for (let i = tempDate.getHours(); i < 24; i++) {
      const slot: TimeSlot = new TimeSlot()
      slot.slotId = i + 1
      slot.slot = i + ' to ' + (i + 1)
      slot.startTime = new Date(todayDate.setHours(i))
      slot.endTime = new Date(todayDate.setHours(i + 1))
      slot.isDayTime = i > 7 && i < 17 ? true : false
      slot.availabilityOrder = slotType === 'Today' ? 1 : slotType === 'Tomorrow' ? 2 : 3
      timeSlotsAsPerCurrentTime.push(slot)
    }

    const slots: TimeSlot[] = []
    for (const slot of slotItems) {
      if (slot.slotId > 0) {
        const sameSlot: TimeSlot | undefined = timeSlotsAsPerCurrentTime.find((x: TimeSlot) => x.slotId === slot.slotId)
        if (sameSlot) {
          slots.push(sameSlot)
        }
      }
    }

    return slotItems
  }

  private onDelayCodeChange(hasDelay: boolean, delayCode: string | null) {
    this.customerAvailability.hasDelay = hasDelay
    this.customerAvailability.delayCode = delayCode
    this.save()
  }

  private save() {
    const self = this
    let availableTimeSlots: TimeSlot[] = this.firstDateTimeSlots
      .concat(this.secondDateTimeSlots)
      .concat(this.thirdDateTimeSlots)
    // remove All and Daytime slots
    availableTimeSlots = availableTimeSlots.filter((a: TimeSlot) => a.slotId > 0)
    this.customerAvailability.timeSlot = availableTimeSlots
    const ca = new AddCustomerAvailabilityTimeSlot()
    ca.id = this.itemId
    ca.jobId = this.jobId
    ca.timeSlot = availableTimeSlots
    ca.hasDelay = this.customerAvailability.hasDelay
    ca.delayCode = this.customerAvailability.delayCode

    JobController.UpdateCustomerAvailability(ca)
      .then((res: boolean) => {
        eventBus.$emit(
          'showSnackbar',
          res ? 'Customer availability detail updated successfully.' : 'Something went wrong, please try again'
        )
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error updating customer availability, please try again', true)
      })
  }

  private showSecondSlotAvailabilities() {
    this.hasSecondSlotAvailabilities = !this.hasSecondSlotAvailabilities
  }

  private showThirdSlotAvailabilities() {
    this.hasThirdSlotAvailabilities = !this.hasThirdSlotAvailabilities
  }

  private updateDayTwoTimeSlot(timeSlots: TimeSlot[]) {
    this.hasSecondSlotAvailabilities = timeSlots.length > 0
    this.secondDateTimeSlots = timeSlots
  }
}
</script>

<style scoped>
.clt-avb-card >>> .v-card {
  background-color: #f5f5f6;
}
.clt-avb-card >>> .container.grid-list-md {
  padding: 8px;
}
.v-card__title .v-icon,
.v-card__title .date-title {
  margin-top: 25px;
}
.clt-avb-card >>> .v-chip--removable .v-chip__content {
  padding: 0 4px 0 12px;
}
</style>
