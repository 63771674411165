<template>
  <v-container class="elevation-1 scrollable-table-wrapper" px-0 py-0>
    <table>
      <thead>
        <tr>
          <th>rownumber</th>
          <th>bordereauinvoicenumber</th>
          <th>bordereauinvoicedate</th>
          <th>policyno</th>
          <th>name</th>
          <th>address1</th>
          <th>address2</th>
          <th>address3</th>
          <th>county</th>
          <th>postcode</th>
          <th>incidentdate</th>
          <th>cetleadno</th>
          <th>cover</th>
          <th>lvproductcode</th>
          <th>repaircompleted</th>
          <th>firstinstructiontrade</th>
          <th>secondinstructiontrade</th>
          <th>firstinstructiondescription</th>
          <th>partsfitted</th>
          <th>vatable</th>
          <th>nonvatable</th>
          <th>vatrate</th>
          <th>vat</th>
          <th>grandtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, ind) in selectedJobList" :key="ind">
          <td>{{ ind + 1 }}</td>
          <td>{{ selectedBordereauId }}</td>
          <td>{{ getFormatedDate(nowDate()) }}</td>
          <td>{{ row.policyNumber }}</td>
          <td>{{ row.policyHolderName }}</td>
          <td>{{ row.addressLine1 }}</td>
          <td>{{ row.addressLine2 }}</td>
          <td>{{ row.addressLine3 }}</td>
          <td>{{ row.addressLine4 }}</td>
          <td>{{ row.postCode }}</td>
          <td>{{ getFormatedDate(row.jobLoggedDate) }}</td>
          <td>{{ row.jobId }}</td>
          <td>{{ row.policyName }}</td>
          <td>{{ row.tradeTypeShortCode }}</td>
          <td>{{ getFormatedDate(row.firstEmergencyVisitCompleteDate) }}</td>
          <td>{{ row.firstEmergencyTradeName }}</td>
          <td>{{ row.secondEmergencyTradeName }}</td>
          <td>{{ row.firstEmergencyDescription }}</td>
          <td></td>
          <td>{{ getFormatedCurrency(row.vatable) }}</td>
          <td>{{ getFormatedCurrency(row.nonVatable) }}</td>
          <td>20%</td>
          <td>{{ getFormatedCurrency(row.totalVAT) }}</td>
          <td>{{ getFormatedCurrency(row.totalCostIncFeesAndVAT) }}</td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import JobFinancialSummary from '@/models/claim/JobFinancialSummary'
import moment from 'moment'
import Store from '@/store'
@Component({})
export default class BordereauPreviewTable extends Vue {
  @Prop({ default: [] }) private selectedJobList: JobFinancialSummary[]
  @Prop({ default: '' }) private selectedBordereauId: string

  private nowDate() {
    return moment()
  }

  private getFormatedDate(date: moment.Moment | null): string {
    if (date === null || date === undefined) {
      return ''
    }
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }
}
</script>

<style scoped>
table >>> td,
th {
  border-left: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}
table >>> thead th,
table >>> tbody tr > td {
  text-align: left;
  padding: 0px 8px !important;
}
.scrollable-table-wrapper {
  overflow: auto;
  max-height: 50vh;
}
</style>
