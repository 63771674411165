<template>
  <div class="home">
    <IncidentManagerDashboard />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import IncidentManagerDashboard from '@/views/IncidentManagerDashboard.vue'

@Component({
  components: { IncidentManagerDashboard },
})
export default class Home extends Vue {
  public mounted() {
    SetThemeForJobType(this, undefined)
  }
}
</script>
