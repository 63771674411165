<template>
  <v-layout wrap>
    <v-flex v-if="getTrialPitDetail.length > 0" xs12 class="mb-1">
      <b>
        Trial Pit
        <span v-if="getVerificationData && getVerificationData('Pit')" class="green--text test-verify">(Verified)</span>
      </b>
    </v-flex>
    <v-flex v-if="getCCTVControlLogDetail" xs12 class="mb-1">
      <b>
        CCTV Logs
        <span v-if="job && job.jobType === 'US' && getCCTVControlLogDetail.isCCTVDrainageVerified" class="green--text">
          (Verified)
        </span>
        <span v-else-if="getVerificationData && getVerificationData('CCTV')" class="green--text">(Verified)</span>
      </b>
    </v-flex>
    <v-flex v-if="getWaterMainsTestDetail" xs12 class="mb-1">
      <b>
        Water mains test
        <span v-if="getVerificationData && getVerificationData('WaterMains')" class="green--text">(Verified)</span>
      </b>
    </v-flex>
    <v-flex v-if="getDatumControlLogDetail.length > 0" xs12 class="mb-1">
      <b>
        Datums
        <span v-if="getVerificationData && getVerificationData('Datum')" class="green--text">(Verified)</span>
      </b>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '../../storeGetters'
import CCTVControlLogModel from '../../models/siteInvestigation/CCTVControlLog'
import SIVisitTrialPit from '../../models/siteInvestigation/SIVisitTrialPit'
import DatumControlLog from '../../models/siteInvestigation/DatumControlLog'
import WaterMainsTest from '../../models/siteInvestigation/WaterMainsTest'
import { SIProductType } from '../../common/enums'

@Component
export default class SiteDataCard extends TimeLineItemBase {
  private get getCCTVControlLogDetail(): CCTVControlLogModel | null {
    return storeGetters.getCCTVControlLogDetail(this.jobId, this.itemId)
  }

  private get getTrialPitDetail(): SIVisitTrialPit[] | [] {
    return storeGetters.getTrialPitDetail(this.jobId, this.itemId)
  }

  private get getDatumControlLogDetail(): DatumControlLog[] | [] {
    return storeGetters.getDatumControlLogDetail(this.jobId, this.itemId)
  }

  private get getWaterMainsTestDetail(): WaterMainsTest | null {
    return storeGetters.getWaterMainsTestDetail(this.jobId, this.itemId)
  }

  private getVerificationData(productType: string): boolean {
    const item: SIProductType = SIProductType[productType]
    const job = storeGetters.getJob(this.jobId)
    return job && job.sIJobDetail && job.sIJobDetail.verifiedItems
      ? job.sIJobDetail.verifiedItems.includes(item)
      : false
  }
}
</script>

<style scoped></style>
