<template>
  <div>
    <div class="mb-2"><h4>Escalate CTP Process</h4></div>
    <div v-if="item.description" class="mb-1">
      <label>Description: </label>
      <span>{{ item.description }}</span>
    </div>
    <div v-if="item.escalateBy" class="mb-1">
      <label>Escalated By: </label>
      <span>{{ item.escalateBy }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import AddRequestToEscalateCTPProcessModel from '@/models/requests/AddRequestToEscalateCTPProcessModel'

@Component
export default class AddRequestToEscalateCTPProcessLog extends Vue {
  @Prop() private requestBody: any

  private get item(): AddRequestToEscalateCTPProcessModel {
    return Object.assign(new AddRequestToEscalateCTPProcessModel(), this.requestBody)
  }
}
</script>
