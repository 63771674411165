var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-text-field',{attrs:{"label":"Upload Files","loading":_vm.uploadDocumentInProgress,"append-icon":"attach_file","hide-details":true,"readonly":_vm.isDisabled,"name":"select-file"},on:{"click":_vm.pickFile},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}}),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:({ rules: { required: false, size: _vm.getAttachmentSize } }),expression:"{ rules: { required: false, size: getAttachmentSize } }"}],ref:"fileUpload",staticStyle:{"display":"none"},attrs:{"type":"file","name":"file","multiple":"","disabled":_vm.isDisabled,"data-vv-scope":"formReference"},on:{"change":_vm.onFilePicked}}),_c('div',{staticClass:"input-group__details"},[_c('div',{staticClass:"input-group__messages input-group__error input-group--error error--text"},[_c('span',[_vm._v(_vm._s(_vm.fileValidationMessage(_vm.errors, _vm.fileErrorMessage)))])])]),_c('div',{staticClass:"complaint-files mt-3"},_vm._l((_vm.selectedFileList),function(file){return _c('span',{key:file.fileName,staticClass:"elevation-1"},[_c('div',{staticClass:"image-action hover-content-section"},[(typeof file.fileURL === 'string')?_c('span',[(
              file.fileType.toLowerCase() === 'jpg' ||
              file.fileType.toLowerCase() === 'jpeg' ||
              file.fileType.toLowerCase() === 'png'
            )?_c('img',{attrs:{"src":file.fileURL},on:{"click":function($event){_vm.uploadCarousel = true}}}):_c('img',{attrs:{"src":_vm.documentTypeWiseThumbnail(file.fileType.toLowerCase())}})]):_c('span',[_c('img',{attrs:{"src":_vm.documentTypeWiseThumbnail(file.fileType.toLowerCase())}})]),_c('div',{staticClass:"grey lighten-3 hover-item"},[(!_vm.isDisabled)?_c('v-btn',{staticClass:"elevation-0 upload-img-action-btn ma-0 btn-remove",attrs:{"icon":"","small":"","flat":"","color":"primary"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.removeFile(file)}}},[_c('v-icon',{staticClass:"md-icon"},[_vm._v("delete")])],1):_vm._e(),(_vm.showDownloadButton(file.fileURL))?_c('v-btn',{staticClass:"elevation-0 upload-img-action-btn ma-0 btn-download",attrs:{"icon":"","flat":"","small":"","color":"primary"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(file.fileURL)}}},[_c('v-icon',{staticClass:"md-icon"},[_vm._v("get_app")])],1):_vm._e()],1)])])}),0),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.uploadCarousel),callback:function ($$v) {_vm.uploadCarousel=$$v},expression:"uploadCarousel"}},[_c('v-card',[_c('v-btn',{staticClass:"uploadCarousel-btn white",attrs:{"icon":"","flat":"","color":"primary"},on:{"click":function($event){_vm.uploadCarousel = false}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-carousel',{attrs:{"cycle":false}},_vm._l((_vm.selectedFileList.filter(
            (e) =>
              (typeof e.fileURL === 'string' && e.fileType.toLowerCase() === 'png') ||
              e.fileType.toLowerCase() === 'jpg' ||
              e.fileType.toLowerCase() === 'jpeg'
          )),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.fileURL}})}),1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"bottom":true,"left":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"flat":"","color":"secondary"},nativeOn:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }