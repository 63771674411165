<template>
  <div>
    <div class="mb-2"><h4>Contractor Auto Deployment</h4></div>
    <div class="mb-1">
      <label>Emergency Name: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="companyName(request.contractorId)" class="mb-1">
      <label>Company Name: </label>
      <span>{{ companyName(request.contractorId) }}</span>
    </div>
    <div class="mb-1">
      <label>Started At: </label>
      <span>{{ getFormattedDate(request.autoDeploymentStartedAt) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import moment from 'moment'

@Component
export default class ContractorAutoDeploymentLog extends Vue {
  @Prop() private requestBody: any

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.requestBody.emergencyId)
  }

  private companyName(contractorId): string {
    const contractorDetail = storeGetters.getContractorAppointedDetailByContractorId(
      this.requestBody.jobId,
      contractorId
    )
    return contractorDetail ? contractorDetail.companyName : ''
  }
}
</script>

<style scoped>
pre {
  display: inherit;
  font-family: inherit;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
