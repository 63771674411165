<template>
  <div class="mb-0 failedToContactCallCard">
    <div class="card-inner-detail">
      <template v-if="getUnavailableContractors && getUnavailableContractors.length > 0">
        <v-tooltip v-for="(item, j) in getUnavailableContractors" :key="j" content-class="newcustom-tooltip" right>
          <template #activator="{ on }">
            <v-avatar color="indigo" size="42" v-on="on">
              <img src="/img/miss_call.svg" class="icon-img" @click="onMissedCallClick(item)" />
            </v-avatar>
          </template>
          <span>
            <span>
              <label>Reason:</label>
              <span>{{ item.reasonType }}</span>
            </span>
            <br />
            <template v-if="item.reasonDescription">
              <span>
                <label>Description:</label>
                <span>{{ item.reasonDescription }}</span>
              </span>
              <br />
            </template>
            <span>
              <label>Contractor:</label>
              <span>{{ item.companyName }}</span>
            </span>
            <br />
            <span>
              <label>Call At:</label>
              <span>{{ getFormattedDate(item.createdAt) }}</span>
            </span>
          </span>
        </v-tooltip>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import CallLogModel from '@/models/claim/CallLogModel'
import TimelineCard from '@/components/timeline/TimelineCard.vue'
import storeGetters from '@/storeGetters'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { JobVisitStatus } from '@/common/enums'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import eventBus from '@/common/bus'

@Component
export default class FailedToContactCallCard extends TimeLineItemBase {
  private get getUnavailableContractors(): ContractorAppointedModel[] {
    return storeGetters
      .getContractorAppointedDetail(this.jobId, this.emergencyId)
      .filter((c) => c.status === JobVisitStatus[JobVisitStatus.Unavailable])
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private onMissedCallClick(item: ContractorAppointedModel) {
    this.$emit('cardClicked', this.cardIndex)
    setTimeout(() => {
      eventBus.$emit('showLinkedContractorCall', item)
    }, 0)
  }
}
</script>

<style scoped>
.failedToContactCallCard .v-avatar {
  margin-right: 10px;
}
.failedToContactCallCard .v-card {
  box-shadow: none;
  background-color: transparent;
}
.icon-img {
  max-width: 20px;
}
</style>
