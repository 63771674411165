import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import CTPPayment from '@/models/claim/CTPPaymentModel'
import AddCustomerToPay from '@/models/requests/AddCustomerToPay'
import UpdateCustomerPaymentDetail from '@/models/requests/UpdateCustomerPaymentDetail'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import ErrorResponseModel from '@/models/ErrorResponseModel'
import PaymentError from '@/models/PaymentError'

export default class PaymentController {
  public static async AddCustomerToPayDetail(cust: AddCustomerToPay): Promise<ErrorResponseModel | null> {
    const res: AxiosResponse<ErrorResponseModel> = await CallCentreApi.post('Payment/AddCustomerToPayDetail', cust)
    return res.data ? res.data : null
  }

  public static async UpdateCustomerPaymentDetail(
    cust: UpdateCustomerPaymentDetail
  ): Promise<ErrorResponseModel | null> {
    const res: AxiosResponse<ErrorResponseModel> = await CallCentreApi.post('Payment/UpdateCustomerPaymentDetail', cust)
    return res.data ? res.data : null
  }

  public static async ApproveCTPTriggerRequest(model: EscalateCTPResponseModel, jobId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<EscalateCTPResponseModel>(
      'Payment/ApproveCTPTriggerRequest?jobId=' + jobId,
      model
    )
    return res.status >= 200 && res.status < 300
  }

  public static async GetPaymentList(
    pageNumber: number,
    pageSize: number,
    sortByField: string,
    sortOrder: string,
    customerName: string,
    insurerId: number,
    paymentPolicy: number,
    paymentFrom: string,
    paymentTo: string,
    paymentStatus: string,
    paymentByPayoutDate: boolean
  ): Promise<CTPPayment | null> {
    const res: AxiosResponse = await CallCentreApi.get<CTPPayment>(
      'Payment/GetPayments?pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize +
        '&sortByField=' +
        sortByField +
        '&sortOrder=' +
        sortOrder +
        '&customerName=' +
        encodeURIComponent(customerName) +
        '&insurerId=' +
        insurerId +
        '&paymentFrom=' +
        paymentFrom +
        '&paymentTo=' +
        paymentTo +
        '&paymentStatus=' +
        paymentStatus +
        '&policyId=' +
        paymentPolicy +
        '&paymentByPayoutDate=' +
        paymentByPayoutDate
    )
    return res.data ? res.data : null
  }

  public static async CreatePaymentIntent(
    jobId: string,
    amount: number,
    postCode: string,
    clientName: string,
    clientEmail: string,
    cardBrand: string,
    paymentMethodId: string,
    description: string,
    paymentType: string
  ) {
    try {
      const res: AxiosResponse = await CallCentreApi.post<any>('Payment/CreatePaymentIntent', {
        jobId,
        amount,
        postCode,
        clientName,
        clientEmail,
        cardBrand,
        paymentMethodId,
        description,
        paymentType,
      })

      // Throw an error if the request is not successful and use the message provided by the server, if any.
      if (res.status !== 200) {
        if (res.data && res.data.message) {
          throw new PaymentError(res.data.message)
        }

        throw Error()
      }
      return res.data
    } catch (err) {
      // Re-throw any specific error thrown within the try block
      if (err instanceof PaymentError) {
        throw err
      }

      throw Error('Unable to create the Payment Intent. Please contact Support.')
    }
  }
}
