<template>
  <div>
    <div class="mb-2">
      <h4>Engineer Request Detail</h4>
    </div>

    <div v-if="emergencyName" class="mb-1">
      <label>Emergency: </label>
      <span>{{ emergencyName }}</span>
    </div>

    <div v-if="getRequestList.length">
      <div v-for="(req, index) in getRequestList" :key="index" class="mb-1">
        <label>{{ req.text }}</label>
        <span>
          {{ req.value }}
          <span v-if="req.text === headerCost">&pound;</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EngineerRequestType } from '@/common/enums'
import moment from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'

export interface RequestDetails {
  text: string
  value: string | string[]
}

@Component
export default class AddEngineerRequestLog extends Vue {
  @Prop() private requestBody: any
  g
  private counter = 0
  private requestDetails: RequestDetails[] = []
  private headerRequestStatus = 'Request Status: '
  private headerRequestType = 'Request Type: '
  private headerAddress = 'Address: '
  private headerLoggedVia = 'Logged Via: '
  private headerContractor = 'Contractor: '
  private headerEngineer = 'Engineer: '
  private headerMaterialName = 'Materials: '
  private headerCost = 'Cost: '
  private headerExtraMinutes = 'Extra Time: '
  private headerReturnVisitType = 'Return Visit Type: '
  private headerReturnVisitAt = 'Return Visit At: '
  private headerRequestReason = 'Reason: '
  private headerRequestInfo = 'Additional Information: '
  private headerRequestLoggedBy = 'Request Logged By: '
  private headerReturnVisitDuration = 'Return Visit Duration'
  private headerExcludeFromCustomerApprovalUpdates = 'Exclude from Customer Updates after Approval: '

  private get emergencyName(): string {
    return Shared.getEmergencyNameFromTypeId(this.requestBody.forEmergencyTypeId)
  }

  @Watch('requestBody')
  private getRequestDetails() {
    const requestBody: any = this.requestBody
    let printRequestStatus = false
    let printRequestLoggedBy = false

    for (const requestDetail of requestBody.engineerRequest) {
      switch (requestDetail.requestType) {
        case EngineerRequestType[EngineerRequestType.NewJob]:
          this.fillRequestList(this.headerRequestType, 'New Job')
          this.fillRequestList(this.headerAddress, requestDetail.address)
          this.fillRequestList(this.headerLoggedVia, requestDetail.loggedVia)
          break
        case EngineerRequestType[EngineerRequestType.PolicyHolderNotPresent]:
          this.fillRequestList(this.headerRequestType, 'Customer Not Present')
          this.fillRequestList(this.headerContractor, requestDetail.contractor)
          this.fillRequestList(this.headerEngineer, requestDetail.engineer)
          break
        case EngineerRequestType[EngineerRequestType.RejectVisit]:
          this.fillRequestList(this.headerRequestType, 'Reject Visit')
          break
        case EngineerRequestType[EngineerRequestType.AbandonedVisit]:
          this.fillRequestList(this.headerRequestType, 'Abandoned Visit')
          this.fillRequestList(this.headerContractor, requestDetail.contractor)
          this.fillRequestList(this.headerEngineer, requestDetail.engineer)
          break
        case EngineerRequestType[EngineerRequestType.Material]:
          this.fillRequestList(this.headerRequestType, requestDetail.requestType)
          this.fillRequestList(this.headerMaterialName, requestDetail.materialName)
          this.fillRequestList(this.headerCost, requestDetail.materialCost)
          this.fillRequestList(this.headerRequestReason, requestDetail.furtherInfo)
          if (requestDetail.additionalInfo) {
            this.fillRequestList(this.headerRequestInfo, requestDetail.additionalInfo)
          }
          this.fillRequestList(
            this.headerExcludeFromCustomerApprovalUpdates,
            requestDetail.excludeFromCustomerApprovalUpdates ? 'Yes' : 'No'
          )
          printRequestStatus = true
          printRequestLoggedBy = true
          break
        case EngineerRequestType[EngineerRequestType.Time]:
          this.fillRequestList(this.headerRequestType, requestDetail.requestType)
          this.fillRequestList(this.headerExtraMinutes, this.calculateMinutes(requestDetail.extraMinutes))
          printRequestStatus = true
          printRequestLoggedBy = true
          break
        case EngineerRequestType[EngineerRequestType.ReturnVisit]:
          this.fillRequestList(this.headerRequestType, 'Return Visit')
          this.fillRequestList(this.headerReturnVisitType, requestDetail.returnVisitType)
          this.fillRequestList(this.headerReturnVisitAt, this.getFormattedDate(requestDetail.returnVisitAt))
          this.fillRequestList(this.headerRequestReason, requestDetail.reasonForReturnVisit)
          this.fillRequestList(this.headerReturnVisitDuration, Shared.formatDuration(requestDetail.returnVisitDuration))
          printRequestStatus = true
          printRequestLoggedBy = true
          break
        case EngineerRequestType[EngineerRequestType.VisitComplete]:
          this.fillRequestList(this.headerRequestType, 'Visit Complete')
          this.fillRequestList(this.headerContractor, requestDetail.contractor)
          this.fillRequestList(this.headerEngineer, requestDetail.engineer)
          break
      }
    }

    if (requestBody.requestStatus && printRequestStatus) {
      this.fillRequestList(this.headerRequestStatus, requestBody.requestStatus)
    }

    if (printRequestLoggedBy) {
      this.fillRequestList(this.headerRequestLoggedBy, requestBody.isRequestAddedByIM ? 'Incident Manager' : 'Engineer')
    }
  }

  private fillRequestList(text: string, value: any) {
    let requestDetailsLocal: any = {}
    requestDetailsLocal.text = text
    requestDetailsLocal.value = value
    this.requestDetails.push(requestDetailsLocal)
    requestDetailsLocal = {}
  }

  private get getRequestList(): RequestDetails[] {
    return this.requestDetails
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  // Calculate and fomate minutes and second
  private calculateMinutes(second: number): string {
    return Shared.formatDuration(second)
  }

  private created() {
    this.getRequestDetails()
  }
}
</script>
