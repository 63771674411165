<template>
  <v-menu offset-y left :close-on-content-click="true" :nudge-width="200" content-class="auth-header">
    <template #activator="{ on }">
      <v-btn icon large v-on="on">
        <v-avatar size="32px">
          <img :src="userIconUrl" :alt="userName" color="primary" />
        </v-avatar>
      </v-btn>
    </template>
    <v-card class="login-menu">
      <v-list class="clearfix">
        <div class="profile-outer-block">
          <div class="profile-detail-block">
            <div class="profile-img-block">
              <a href="Javascript:void(0)" title="Edit">
                <v-badge color="primary" overlap>
                  <v-icon v-if="isOnline" slot="badge" dark @click="pickFile()">edit</v-icon>
                  <v-avatar size="85px">
                    <img :src="userIconUrl" :alt="userName" color="primary" />
                    <input
                      ref="image"
                      v-validate="{
                        rules: {
                          required: false,
                          image: true,
                          size: LogoSize,
                          mimes: ['image/jpeg', 'image/jpg', 'image/png'],
                          ext: ['jpg', 'jpeg', 'png'],
                        },
                      }"
                      type="file"
                      style="display: none"
                      accept="image/*"
                      name="image"
                      @change="onFilePicked"
                    />
                    {{ imageValidationMessage(errors) }}
                  </v-avatar>
                </v-badge>
              </a>
            </div>
            <div class="profile-right">
              <h4>{{ userName }}</h4>
              <h5 class="secondary--text">{{ userRoleDescription }}</h5>
              <a
                :style="userEmailColourStyle"
                :href="mailToLink"
                @mouseenter="updateHoverState(true)"
                @mouseleave="updateHoverState(false)"
              >
                {{ userEmail }}
              </a>
            </div>
          </div>
        </div>
      </v-list>
      <v-divider light></v-divider>
      <v-card-actions v-if="isOnline" class="bottom-panel">
        <router-link v-if="isUserRoleContractor" to="contractorProfile">
          <i class="material-icons">settings</i>
          &nbsp;Settings
        </router-link>
        <v-btn v-else flat>
          <i class="material-icons">settings</i>
          &nbsp;Settings
        </v-btn>
        <v-btn v-if="isLoggedIn" flat :disabled="loggingOut" :loading="loggingOut" @click="logout">
          <i class="material-icons">power_settings_new</i>
          &nbsp;Logout
        </v-btn>
        <!-- <a v-else :href="userSettingsUrl" target="_blank" title="Settings" class="waves-effect"><i class="material-icons">settings</i>Settings</a>
      <a href="#" v-if="isLoggedIn" @click="logout" title="Logout" class="waves-effect"><i class="material-icons">power_settings_new</i>Logout</a> -->
      </v-card-actions>
    </v-card>
    <v-dialog v-if="showErrorModal" v-model="showErrorModal" max-width="400" content-class="info-dialog" persistent>
      <v-card>
        <v-card-title class="">
          <h3 class="grey--text text--darken-3">{{ showErrorModalText }}</h3>
        </v-card-title>
        <v-card-actions class="text-center pa-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="m-auto" @click="logout">Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'
import { Action } from 'vuex-class'
import TwilioController from '@/api/twilioController'
import eventBus from '@/common/bus'
import SessionController from '@/api/sessionController'
declare let AzureStorage: any

@Component({})
export default class AuthHeader extends Vue {
  @Prop() private imageSnackbar: boolean
  @Prop() private imageSnackbarText: string
  private hoverState = false
  private loggingOut = false
  private incrementCount = 0
  private interval: any = null
  private oldImageUrl = ''
  private newImageUrl: string = this.oldImageUrl

  private showErrorModal = false
  private showErrorModalText = ''
  @Action('signOutOidc') private signOutOidc

  get userName(): string {
    return Store.Instance.state.SessionDetail.userName
  }

  get userRoleDescription(): string {
    const userRoleDescription: string = Store.Instance.state.SessionDetail.userRoleDescription
    // save user role to user at a time of offline mode
    // offlineUserRole required to check connection status on job submit while status changes in between
    if (userRoleDescription) {
      if (userRoleDescription !== 'Unknown') {
        localStorage.setItem('userRole', userRoleDescription)
        localStorage.setItem('offlineUserRole', userRoleDescription)
      } else {
        localStorage.setItem('offlineUserRole', userRoleDescription)
      }
    }
    return userRoleDescription
  }

  get userIconUrl(): string {
    return Store.Instance.state.SessionDetail.userProfileImageUrl
  }

  get isLoggedIn(): boolean {
    return Store.Instance.state.SessionDetail.accessToken !== ''
  }

  get userProfileUrl(): string {
    // TODO: remove user profile, add photo upload button
    return ''
    // return Store.Instance.state.Environment.Prospect365MyPortalBaseUrl + "/About.aspx";
  }

  get userSettingsUrl(): string {
    // TODO: remove user settings, link to something else instead? (update profile for B2C?)
    return ''
    // return Store.Instance.state.Environment.Prospect365MyPortalBaseUrl + "/Settings/";
  }

  get userEmail(): string {
    return Store.Instance.state.SessionDetail.userEmail
  }

  get mailToLink(): string {
    return 'mailto:' + this.userEmail
  }

  private mounted() {
    eventBus.$on('refreshUserProfileImage', () => {
      this.refreshUserProfileImage()
    })
  }

  private logout() {
    this.loggingOut = true
    this.showErrorModal = false
    if (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
      TwilioController.LogoutWorker()
        .then((result: boolean) => {
          if (result) {
            this.signOutOidc()
          } else {
            eventBus.$emit('errorHandler', 'There was an error logging you out, please try again.', true)
          }
        })
        .catch((error) => {
          eventBus.$emit('errorHandler', 'There was an error logging you out, please try again.', true)
        })
    } else {
      this.signOutOidc()
    }
    this.loggingOut = false
  }

  private get isUserRoleContractor(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private get userEmailColourStyle(): string {
    if (this.hoverState) {
      return 'color:' + this.$vuetify.theme.secondary + ';'
    }

    return ''
  }

  private updateHoverState(state: boolean) {
    this.hoverState = !this.hoverState
  }

  private get LogoSize(): number {
    return Store.Instance.state.Environment.LogoSize
  }

  private pickFile() {
    if (this.interval) {
      window.clearInterval(this.interval)
      this.incrementCount = 0
    }
    const logoError = this.$validator.errors.items.find((a) => a.field === 'image')
    if (logoError) {
      logoError.msg = ''
    }
    const image = this.$refs.image as any
    image.click()
  }

  private onFilePicked(event: any) {
    this.$emit('update:imageSnackbarText', '')
    const file: any = event.target.files[0]
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1)
    if (file.size / 1048576 > 1) {
      return
    }
    if (fileType.toLowerCase() !== 'png' && fileType.toLowerCase() !== 'jpg' && fileType.toLowerCase() !== 'jpeg') {
      return
    }
    if (file !== undefined) {
      const fileName = file.name.split(' ').join('_')
      if (fileName.lastIndexOf('.') <= 0) {
        return
      }
      const fr: any = new FileReader()
      fr.readAsDataURL(file)
      fr.addEventListener('load', () => {
        this.GetSASTokenForProfileImage(file, fileName)
      })
    }
  }

  private get getExistingImageUrl() {
    this.newImageUrl = this.oldImageUrl
    return this.newImageUrl
  }

  private get isUserRoleIncidentManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'IncidentManager'
    )
  }

  private get isRecordTypeUserDetail() {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail !== null
    )
  }

  private get isRecordTypeEngineerDetail() {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.EngineerDetail !== null
    )
  }

  private get isRecordTypeClientUserDetail() {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.ClientUserDetail !== null
    )
  }

  private GetSASTokenForProfileImage(file: any, fileName: string) {
    SessionController.GetProfileImageUploadUrl(fileName)
      .then((res: string) => {
        if (res !== '') {
          const self = this
          const blobUri = res.split('/original')[0]
          const sasToken = res.split('?')[1]
          const containerName = 'original'
          const blobService = AzureStorage.Blob.createBlobServiceWithSas(blobUri, sasToken)
          const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512
          blobService.singleBlobPutThresholdInBytes = customBlockSize
          const recordType = this.isRecordTypeUserDetail
            ? 'UserDetail/'
            : this.isRecordTypeEngineerDetail
            ? 'EngineerDetail/'
            : this.isRecordTypeClientUserDetail
            ? 'ClientUserDetail/'
            : ''
          fileName = res.substring(res.indexOf(recordType), res.indexOf('?'))
          blobService.createBlockBlobFromBrowserFile(
            containerName,
            fileName,
            file,
            { blockSize: customBlockSize },
            (error, result, response) => {
              if (error) {
                self.$emit('update:imageSnackbarText', 'Error Uploading Image.')
              } else {
                this.refreshUserProfileImage(true)
                self.$emit('update:imageSnackbarText', 'Image Uploaded Successfully.')
              }
              self.$emit('update:imageSnackbar', true)
              const img = self.$refs.image as any
              img.value = null
            }
          )
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading user profile image, please try again', true)
      })
  }

  // return validation message on image selection if any.
  private imageValidationMessage(err: any) {
    if (err && err.collect('image').length > 0) {
      let message: string = err.collect('image')[0]
      message = message === 'The image field must be an image.' ? 'Only JPEG, JPG, PNG files are allowed.' : message
      this.$emit('update:imageSnackbarText', message)
      this.$emit('update:imageSnackbar', message !== '' ? true : false)
      const image = this.$refs.image as any
      image.value = null
    }
  }

  private refreshUserProfileImage(isUpdatedFromHeader = false) {
    const self = this
    // set interval of 10 seconds to fetch a new profile image url
    self.interval = window.setInterval(async () => {
      self.incrementCount = self.incrementCount + 1
      self.oldImageUrl = Store.Instance.state.SessionDetail.userProfileImageUrl
      if (self.incrementCount > 6) {
        // only keep polling for upto a minute
        window.clearInterval(self.interval)
        self.incrementCount = 0
      } else {
        if (self.incrementCount > 1 && self.userIconUrl === self.getExistingImageUrl) {
          // profile image changed, clear the interval
          window.clearInterval(self.interval)
          self.incrementCount = 0
        } else {
          // call to fetch a new profile image url using accessToken
          const userAccessToken = Store.Instance.state.SessionDetail.accessToken
          await SessionController.LoadSession(userAccessToken)
          // if engineer is logged in, update profile image in kanban board
          if (isUpdatedFromHeader && this.isUserRoleContractor) {
            eventBus.$emit('engineerProfileImageUpdated')
          }
        }
      }
    }, 10000)
  }
}
</script>

<style type="text/css" scoped>
.login-menu {
  border-radius: 0;
} /*removed the radius of card*/
.profile-detail-block {
  display: flex;
  align-items: center;
  padding: 15px;
} /*to create column structure*/
.profile-detail-block .profile-img-block {
  position: relative;
  margin: 0 10px 0 0;
} /*styling of login card*/
.profile-detail-block h4 {
  font-size: 16px;
  line-height: 20px;
} /*styling of login card*/
.profile-detail-block h5 {
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 0;
} /*styling of login card*/
.bottom-panel {
  position: static;
  display: flex;
  justify-content: space-between;
  padding: 7px;
} /*styling of login card*/
.bottom-panel a {
  border-radius: 0;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 10px 7px 7px;
} /*styling of login card*/
.bottom-panel a i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-size: 25px;
  line-height: 20px;
} /*styling of login card*/
.profile-right {
  text-align: left;
} /*styling of login card*/
.profile-right a {
  display: inline-block;
  color: #607d8b;
  font-size: 12px;
  line-height: 16px;
} /*styling of login card*/
.v-badge >>> .v-badge__badge {
  top: 0;
  right: 0;
} /*to set bedge icon position*/
.auth-header.v-menu__content {
  z-index: 204 !important;
}
</style>
