import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import CustomComponent from './CustomComponentModel'

export default class CustomDashboardModel extends CosmosJobsDbRecordBase {
  public name: string // name of custom dashboard
  public customComponents: CustomComponent[] // array of custom component
  public loggedByUserId: string // user id of custom dashboard created user

  public constructor() {
    super()
  }
}
