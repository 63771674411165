<template>
  <div>
    <v-card v-if="item" class="pt-0 px-2 pb-2">
      <v-card-title>
        <v-layout>
          <v-flex xs12>
            <h3 class="heading">
              Escalation Details
              <span :class="getJobEscalationStatusColor" class="escalation-Details">
                ({{ getJobEscalationStatus }})
              </span>
            </h3>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-flex v-if="item.escalationReason" xs12>
          <label><b>Escalation Reason:</b></label>
          <span>{{ item.escalationReason }}</span>
        </v-flex>
        <v-flex v-if="item.escalationDescription" xs12>
          <label><b>Escalation Description:</b></label>
          <span>{{ item.escalationDescription }}</span>
        </v-flex>
        <v-flex v-if="item.deEscalationDescription" xs12 class="pt-2 deEscalationDescription">
          <v-divider class="pb-2"></v-divider>
          <label><b>De-Escalation Description:</b></label>
          <span>{{ item.deEscalationDescription }}</span>
        </v-flex>
        <v-flex v-if="item.deEscalationDescription && item.deEscalatedAt" xs12>
          <label><b>De-Escalated At:</b></label>
          <span>{{ getFormatedDate(item.deEscalatedAt) }}</span>
        </v-flex>
        <v-flex v-if="!item.deEscalationDescription && canEscalateJob" xs12>
          <div class="text-xs-right">
            <v-btn color="primary" class="btn-deEscalate" @click="showDeEscalateJobDialog">De-Escalate</v-btn>
          </div>
        </v-flex>
      </v-card-text>
    </v-card>

    <!-- De-Escalate job dialog -->
    <v-dialog v-model="openDeEscalateJobDialog" max-width="450" persistent content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>De-Escalate Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="openDeEscalateJobDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-textarea
            v-model="deEscalationDescription"
            v-validate="'required'"
            label="Description"
            required
            class="required"
            data-vv-scope="frmDeEscalateReason"
            data-vv-name="Job De-Escalate Description"
            name="JobDeEscalateDescription"
            :error-messages="errors.collect('Job De-Escalate Description')"
          ></v-textarea>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat="flat"
            class="btn-cancelDeEscalateJob"
            @click.native="openDeEscalateJobDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-saveDeEscalateJob"
            :disabled="isLoading"
            :loading="isLoading"
            @click="deEscalateJob()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import AddDeEscalateJobRequest from '@/models/requests/AddDeEscalateJobRequestModel'
import JobEscalationDetail from '@/models/claim/JobEscalationDetailModel'
import JobController from '@/api/jobController'
import Shared from '@/common/shared'
import Store from '@/store'
import moment, { isMoment } from 'moment'
import eventBus from '@/common/bus'

@Component
export default class JobEscalationPreview extends TimeLineItemBase {
  private isLoading = false
  private deEscalationDescription = ''
  private openDeEscalateJobDialog = false

  private get item(): JobEscalationDetail | undefined {
    return this.job!.jobEscalationDetails.find((x) => x.id === this.itemId)
  }

  private get canEscalateJob(): boolean {
    return Store.Instance.state.SessionDetail.canEscalateJob
  }

  private showDeEscalateJobDialog() {
    this.deEscalationDescription = ''
    this.$validator.errors.items = []
    this.openDeEscalateJobDialog = true
  }

  private deEscalateJob() {
    this.validate('frmDeEscalateReason')
      .then((result: boolean) => {
        if (result) {
          const addDeEscalateJobRequest: AddDeEscalateJobRequest = new AddDeEscalateJobRequest()
          addDeEscalateJobRequest.jobId = this.jobId
          addDeEscalateJobRequest.description = this.deEscalationDescription
          this.isLoading = true
          JobController.DeEscalateJobAsync(addDeEscalateJobRequest)
            .then((res: boolean) => {
              if (res) {
                this.openDeEscalateJobDialog = false
              }
              this.isLoading = false
            })
            .catch((err: any) => {
              eventBus.$emit('errorHandler', 'Error submitting DeEscalate Job Request, please try again', true)
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validate(frmValidation: string): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(frmValidation)
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private get getJobEscalationStatusColor(): string {
    if (this.item && this.item.deEscalationDescription) {
      return 'blue--text'
    }
    return 'red--text'
  }

  private get getJobEscalationStatus(): string {
    return this.isJobDeEscalated ? 'De-Escalated' : 'Escalated'
  }

  private get isJobDeEscalated(): boolean {
    const selectedCard = this.job!.jobEscalationDetails.find((x) => x.id === this.itemId)
    return selectedCard && selectedCard.deEscalationDescription ? true : false
  }

  private getFormatedDate(createdAt: moment.Moment): string {
    if (createdAt) {
      return Shared.getFormatedDate(moment(createdAt), Store.Instance.state.Environment.DateTimeFormat)
    }
    return ''
  }
}
</script>

<style scoped></style>
