export default class BankAccountDetail {
  public name: string
  public accountNumber: string
  public preferredReference: string
  public sortCode: string
  public accountHolderName: string
  public bankAccountContactDetail: BankAccountContactDetail
  public constructor() {
    this.bankAccountContactDetail = new BankAccountContactDetail()
  }
}

export class BankAccountContactDetail {
  public contactName: string
  public contactNumber: string
  public contactEmail: string
}
