import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import UndeployedEmergency from './contractor/UndeployedEmergency'
import { Moment } from 'moment'

export default class JobHeader extends CosmosJobsDbRecordBase {
  public policyNumber: string
  public name: string
  public postCode: string
  public keywords: string[]
  public address: string
  public phoneNumber: string
  public nomineePhoneNumber: string
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public loggedByTeamName: string
  public undeployedEmergencies: UndeployedEmergency[]
  public lastVisitByUserName: string
  public lastVisitAt: Moment

  public constructor() {
    super()
    this.undeployedEmergencies = []
  }
}
