import { render, staticRenderFns } from "./SidedrawMenu.vue?vue&type=template&id=f9d49072&scoped=true"
import script from "./SidedrawMenu.vue?vue&type=script&lang=ts"
export * from "./SidedrawMenu.vue?vue&type=script&lang=ts"
import style0 from "./SidedrawMenu.vue?vue&type=style&index=0&id=f9d49072&prod&lang=css"
import style1 from "./SidedrawMenu.vue?vue&type=style&index=1&id=f9d49072&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9d49072",
  null
  
)

export default component.exports