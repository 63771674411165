<template>
  <div>
    <a v-if="jobItem.email && !jobItem.nomineeEmail" :href="'mailto:' + jobItem.email">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-avatar color="white" :size="35" v-on="on">
            <v-icon color="secondary">email</v-icon>
          </v-avatar>
        </template>
        <span>{{ jobItem.email }}</span>
      </v-tooltip>
    </a>
    <a v-if="!jobItem.email && jobItem.nomineeEmail" :href="'mailto:' + jobItem.nomineeEmail">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-avatar color="white" :size="35" v-on="on">
            <v-icon color="secondary">email</v-icon>
          </v-avatar>
        </template>
        <span>{{ jobItem.nomineeEmail }}</span>
      </v-tooltip>
    </a>
    <v-menu
      v-else-if="jobItem.email && jobItem.nomineeEmail"
      offset-y
      :close-on-content-click="false"
      :nudge-width="250"
      :max-width="250"
      :z-index="999"
      fixed
    >
      <template #activator="{ on }">
        <span class="ma-0 pt-2" v-on="on">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-badge overlap color="grey small-size-badge darken-2">
                <v-avatar color="white" class="elevation-5" :size="35" v-on="on">
                  <v-icon dark class="secondary--text" small>email</v-icon>
                </v-avatar>
              </v-badge>
            </template>
            <span>Email</span>
          </v-tooltip>
          <span class="n-arrow"></span>
        </span>
      </template>
      <v-card>
        <div class="view-panel contact-list">
          <v-list two-line>
            <v-list-tile>
              <v-list-tile-content>
                <span class="name primary--text">
                  {{ jobItem.clientFullName }}
                </span>
                <span class="grey--text text--darken-2">
                  {{ jobItem.email }}
                </span>
                <span class="action">
                  <a :href="'mailto:' + jobItem.email" class="elevation-1">
                    <v-icon btn small color="primary">email</v-icon>
                  </a>
                </span>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content>
                <span class="name primary--text">
                  {{ jobItem.nomineeClientFullName }}
                  <span class="grey--text">(Nominee)</span>
                </span>
                <span class="grey--text text--darken-2">
                  {{ jobItem.nomineeEmail }}
                </span>
                <span class="action">
                  <a :href="'mailto:' + jobItem.nomineeEmail" class="elevation-1">
                    <v-icon btn small color="primary">email</v-icon>
                  </a>
                </span>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'

@Component
export default class CustomerEmail extends Vue {
  @Prop() private jobId: string

  private get jobItem(): Job | null {
    return storeGetters.getJob(this.jobId)
  }
}
</script>

<style type="text/css" scoped>
.contact-list >>> .v-list {
  font-size: 15px;
  position: relative;
}
.contact-list >>> .v-list .action {
  position: absolute;
  right: 0px;
}
.contact-list >>> .v-list .action a {
  height: 30px;
  width: 30px;
  display: inline-flex;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}
.contact-list >>> .v-list .action a i {
  margin: auto;
}
.contact-list >>> .v-list > div:not(:last-child) {
  border-bottom: 1px solid #d8d1d1;
}
.contact-list >>> .v-list .name {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 3px;
}
</style>
