<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { HourlyCallStats } from '@/signalr/twilioEventModels/events'
import DashboardTopTile from '@/components/dashboard/DashboardTopTile.vue'
import DashboardTeam from '@/components/dashboard/DashboardTeam.vue'
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue'
import { WorkerWithAdditions } from '@/components/dashboard/worker'
import DashboardController, { DashboardInitialData } from '@/api/dashboardController'
import { PanelProps } from '@/components/dashboard/DashboardPanel.vue'
import Shared from '@/common/shared'
import SignalRHub from '@/components/SignalRHub.vue'

@Component({
  components: {
    DashboardTopTile,
    DashboardTeam,
    DashboardPanel,
  },
})
export default class Dashboard extends SignalRHub {
  public ready = false

  public async mounted() {
    this.addTopLevelCallStatsHandler()
    this.addInsurerStatsHandler()
    this.addWorkerReservationEventsHandler()
    this.addWorkerCreatedHandler()
    this.addWorkerDeletedHandler()
    this.addWorkerActivityChangedHandler()
    this.addWorkerAttributesChangedHandler()
    this.addInsurerAnnouncingCountHandler()
    this.addDailyCallStatsHandler()
    this.addUpdateSurgeHandler()
    this.addCallCountHandler()
    this.addUpdateTwilioTopTilesCountHandler()
    this.addUpdateJobCountHandler()
    this.dashboardSignalRHub.connect()

    const data: DashboardInitialData =
      this.childPage === 'ThirdPartyDashboard'
        ? await DashboardController.GetThirdPartyDashboardData()
        : this.childPage === 'ManagerSupervisorDashboard'
        ? await DashboardController.GetManagerSupervisorDashboardData()
        : await DashboardController.GetSupervisorDashboardData()

    this.dashboardSignalRHub.runHandler('TopLevelCallStats', data.callStats)

    for (const stats of data.insurerStats) {
      this.dashboardSignalRHub.runHandler('InsurerStats', stats)
    }
    for (const key in data.announcingBreakdown.individualCounts) {
      if (!Object.prototype.hasOwnProperty.call(data.announcingBreakdown.individualCounts, key)) {
        continue
      }
      const stats = data.announcingBreakdown.individualCounts[key]
      this.dashboardSignalRHub.runHandler('InsurerAnnouncingUpdate', {
        insurerId: +key,
        announcingCount: stats,
        updateReason: 'Initial Data',
        updateType: 'InsurerStats',
        dataFetchedAt: data.insurerStats[0].dataFetchedAt,
      })
    }

    this.addWorkers(data.workers)

    if (data.workerReservationEvents !== null) {
      for (const stats of data.workerReservationEvents) {
        this.dashboardSignalRHub.runHandler('WorkerReservationEvents', stats)
      }
    }

    this.dashboardSignalRHub.runHandler('DailyCallStats', data.todaysCallStats)

    this.inSurge = data.inSurge

    this.dashboardCount = data.dashboardCount

    this.ready = true
  }
}
</script>
