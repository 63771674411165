import { SIProductType } from '@/common/enums'

export default class AddAbandonedSIProductRequest {
  public id: string
  public jobId: string
  public forEngineerVisitDetailId: string
  public abandonedReason: string
  public abandonedReasonNotes: string
  public siProductType: SIProductType | string
}
