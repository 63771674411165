<template>
  <div>
    <div class="mb-2">
      <h4>Send Job SMS {{ item.sendToNominee ? '(Nominee)' : '' }}</h4>
    </div>
    <div v-if="documentTemplateName" class="mb-1">
      <label>Document template type: </label>
      <span>{{ documentTemplateName }}</span>
    </div>
    <div v-if="item.emergencyName" class="mb-1">
      <label>Emergency name: </label>
      <span>{{ emergencyName }}</span>
    </div>
    <div v-if="contractorAppointedDetail" class="mb-1">
      <label>Company name: </label>
      <span>{{ contractorAppointedDetail.companyName }}</span>
    </div>
    <div v-if="item.documentTemplateType === documentTemplateType.WorkInProgress" class="mb-1">
      <label>Is WIP Customer Notification?: </label>
      <span>Yes</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import SendJobSMS from '@/models/requests/SendJobSMS'
import storeGetters from '@/storeGetters'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { DocumentTemplateType } from '@/common/enums'
import Shared from '@/common/shared'

@Component
export default class SendJobSMSLog extends Vue {
  @Prop() private requestBody: any
  private documentTemplateType = DocumentTemplateType

  private get emergencyName(): string {
    return Shared.getEmergencyName(this.item.emergencyId)
  }

  private get item(): SendJobSMS {
    return Object.assign(new SendJobSMS(), this.requestBody)
  }

  private get contractorAppointedDetail(): ContractorAppointedModel {
    return storeGetters.getContractorAppointedDetailById(this.item.jobId, this.item.contractorAppointedDetailId)
  }

  private get documentTemplateName() {
    return DocumentTemplateType[this.item.documentTemplateType].toString()
  }
}
</script>
