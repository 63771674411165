<template>
  <div>
    <v-card>
      <v-toolbar card dark color="primary">
        <v-toolbar-title>Trade</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon color="primary" @click="closeTradeModel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="scroll-content-dialog">
        <v-container fluid grid-list-lg pa-0>
          <v-layout wrap>
            <v-flex xs12 py-0>
              <v-text-field
                v-model.trim="trade.description"
                v-validate="'required'"
                label="Trade Name"
                required
                class="required"
                data-vv-name="Trade Name"
                :error-messages="errors.collect('Trade Name')"
                data-vv-scope="tradeformRef"
                maxlength="20"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 py-0>
              <v-text-field
                v-model.trim="trade.shortCode"
                v-validate="'alpha'"
                label="Trade Code"
                name="trade-code"
                data-vv-name="Trade Code"
                :error-messages="errors.collect('Trade Code')"
                data-vv-scope="tradeformRef"
                maxlength="3"
              ></v-text-field>
            </v-flex>
            <v-flex v-if="getEmergencyTypeList.length > 0" xs12 py-0>
              <v-select
                v-model="trade.emergencyIds"
                v-validate="'required'"
                label="Search Emergency"
                required
                class="required"
                multiple
                :items="emergencyTypeList"
                item-text="description"
                item-value="emergencyId"
                data-vv-scope="tradeformRef"
                data-vv-name="Search Emergency"
                :error-messages="errors.collect('Search Emergency')"
                @change="onEmergencyChange($event)"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-checkbox
                        v-model="emergencyCheckboxes[emergencyTypeList.indexOf(data.item)].checked"
                        color="primary"
                      ></v-checkbox>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title
                        :class="data.item.isDeleted ? 'deleted-item' : ''"
                        v-html="data.item.description"
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-select>
            </v-flex>
            <v-flex v-if="getEmergencyDetailList.length > 0" xs12 py-0>
              <v-select
                v-model="trade.emergencyDetailIds"
                v-validate="'required'"
                label="Search EmergencyDetails"
                required
                class="required"
                multiple
                :items="selectedemergencyDetailList"
                item-text="description"
                item-value="emergencyDetailId"
                data-vv-scope="tradeformRef"
                data-vv-name="Search EmergencyDetail"
                :error-messages="errors.collect('Search EmergencyDetail')"
                @change="onEmergencyDetailChange($event)"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-checkbox
                        v-model="emergencyDetailCheckboxes[selectedemergencyDetailList.indexOf(data.item)].checked"
                        color="primary"
                      ></v-checkbox>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title
                        :class="data.item.isDeleted ? 'deleted-item' : ''"
                        v-html="data.item.description"
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-select>
            </v-flex>
            <v-flex v-if="availableDelayCodes.length > 0" xs12 py-0>
              <v-select
                v-model="trade.delayCodes"
                v-validate="'required'"
                label="Select Justified Delay Codes"
                required
                class="required"
                multiple
                :items="availableDelayCodes"
                item-text="description"
                item-value="id"
                data-vv-scope="tradeformRef"
                data-vv-name="TradeDelayCode"
                :error-messages="errors.collect('TradeDelayCode')"
              ></v-select>
            </v-flex>
            <v-flex xs12 py-0>
              <v-textarea
                v-model="trade.disclaimer"
                v-validate="'max:2000'"
                label="Disclaimer"
                data-vv-name="Disclaimer"
                :error-messages="errors.collect('Disclaimer')"
                class="disclaimer"
              ></v-textarea>
            </v-flex>
            <v-flex v-if="!tradeItem.tradeId" xs12 py-0>
              <v-select
                v-model="selectedJobType"
                :items="getJobTypes"
                label="Job Type"
                item-text="jobTypeDescription"
                item-value="jobType"
                data-vv-scope="tradeformRef"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-3">
        <v-spacer></v-spacer>
        <v-btn color="primary" flat="flat" @click="closeTradeModel">Cancel</v-btn>
        <v-btn color="primary" :loading="isLoading" :disabled="isLoading" class="mr-0" @click="saveTrade">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import { JobType } from '@/models/types'
import eventBus from '@/common/bus'
import storeGetters from '@/storeGetters'
import DelayCode from '@/models/delay/DelayCode'
import DelayController from '@/api/delayController'
import TradeDelay from '@/models/delay/TradeDelay'

interface IJobTypeEnum {
  jobType: JobType
  jobTypeDescription: string
}

@Component
export default class Trade extends Vue {
  @Prop() private tradeItem: TradeModel
  @Prop() private emergencyTypes: EmergencyModel[]
  @Prop() private emergencyDetails: EmergencyDetailModel[]
  @Prop() private selectedEmergencyDetails: EmergencyDetailModel[]

  private isLoading = false
  private emergencyTypeList: EmergencyModel[] = []
  private emergencyDetailList: EmergencyDetailModel[] = []
  private selectedemergencyDetailList: EmergencyDetailModel[] = []
  private selectedJobType: JobType = 'HE'
  private emergencyCheckboxes: any = []
  private emergencyDetailCheckboxes: any = []

  private get trade(): TradeModel | null {
    this.emergencyTypeList = this.emergencyTypes
    this.emergencyDetailList = this.emergencyDetails.filter(
      (e) =>
        !e.isDeleted ||
        (e.isDeleted &&
          this.selectedEmergencyDetails.findIndex((t) => t.emergencyDetailId === e.emergencyDetailId) !== -1)
    )
    this.selectedemergencyDetailList = this.selectedEmergencyDetails
    return this.tradeItem
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('tradeformRef')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private onEmergencyChange(emergencyIds: number[]) {
    this.selectedemergencyDetailList = []
    for (const item of this.emergencyDetailList) {
      for (const emergency of emergencyIds) {
        if (item.typeId === emergency) {
          this.selectedemergencyDetailList.push(item)
        }
      }
    }
    if (this.trade != null) {
      const emergecyIdList: any = emergencyIds
      this.trade.emergencyDetailList = this.trade.emergencyDetailList.filter((e) =>
        this.trade != null ? emergecyIdList.includes(e.typeId) : true
      )
      this.trade.emergencyDetailIds = this.trade.emergencyDetailList.map((e) => e.emergencyDetailId)
    }
    this.emergencyTypeList =
      this.trade !== null
        ? Shared.getSelectedItemFirstInList(this.emergencyTypeList, emergencyIds, 'emergencyId')
        : this.emergencyTypeList
    this.selectedemergencyDetailList =
      this.trade !== null
        ? Shared.getSelectedItemFirstInList(
            this.selectedemergencyDetailList,
            this.trade.emergencyDetailIds,
            'emergencyDetailId'
          )
        : this.selectedemergencyDetailList
  }

  private onEmergencyDetailChange(emergencyDetailIds: number[]) {
    if (this.trade !== null) {
      this.trade.emergencyDetailList = []
      for (const emergencydetail of this.emergencyDetailList) {
        for (const emergencydetailId of emergencyDetailIds) {
          if (emergencydetail.emergencyDetailId === emergencydetailId) {
            this.trade.emergencyDetailList.push(emergencydetail)
          }
        }
      }
    }
    this.emergencyTypeList =
      this.trade !== null
        ? Shared.getSelectedItemFirstInList(this.emergencyTypeList, this.trade.emergencyIds, 'emergencyId')
        : this.emergencyTypeList
    this.selectedemergencyDetailList =
      this.trade !== null
        ? Shared.getSelectedItemFirstInList(this.selectedemergencyDetailList, emergencyDetailIds, 'emergencyDetailId')
        : this.selectedemergencyDetailList
  }

  private closeTradeModel() {
    this.$validator.errors.items = []
    this.selectedJobType = 'HE'
    this.$emit('OnTradeSave', this.trade, false)
  }

  private get getJobTypes(): IJobTypeEnum[] {
    return Shared.jobTypeList
  }

  private async saveTrade(): Promise<void> {
    const isValid = await this.validate()
    if (isValid && this.trade !== null) {
      this.isLoading = true
      this.trade.jobType = this.tradeItem.jobType ? this.tradeItem.jobType : this.selectedJobType
      try {
        const res = await PolicyHolderController.SaveTrade(this.trade)
        this.isLoading = false
        if (res) {
          this.$validator.errors.items = []
          if (this.trade) {
            this.trade.tradeId = res
            await this.updateTradeDelay()
          }
          this.$emit('OnTradeSave', this.trade, true)
        }
      } catch (err) {
        this.isLoading = false
        eventBus.$emit('errorHandler', 'Error saving trade detail, please try again', true)
      }
    }
  }

  private get getEmergencyTypeList() {
    this.emergencyTypeList = this.emergencyTypes.filter(
      (e) =>
        !e.isDeleted ||
        (e.isDeleted && this.trade && this.trade.emergencyIds.findIndex((t) => t === e.emergencyId) !== -1)
    )
    this.emergencyTypeList =
      this.trade !== null
        ? Shared.getSelectedItemFirstInList(this.emergencyTypeList, this.tradeItem.emergencyIds, 'emergencyId')
        : this.emergencyTypeList
    this.emergencyCheckboxes = this.emergencyTypeList.map((code) => {
      return {
        checked: false,
      }
    })
    this.tradeItem.emergencyIds.forEach((t) => {
      const index = this.emergencyTypeList.findIndex((e) => e.emergencyId === t)
      if (index !== -1) {
        this.emergencyCheckboxes[index].checked = index !== -1 ? true : false
      }
    })
    return this.emergencyTypes
  }

  private get getEmergencyDetailList() {
    this.selectedemergencyDetailList = this.selectedemergencyDetailList.filter((e) =>
      e.isDeleted
        ? this.trade && this.trade.emergencyDetailIds.findIndex((t) => t === e.emergencyDetailId) !== -1
        : true
    )
    this.emergencyDetailCheckboxes = this.selectedemergencyDetailList.map((code) => {
      return {
        checked: false,
      }
    })
    this.tradeItem.emergencyDetailIds.forEach((t) => {
      const index = this.selectedemergencyDetailList.findIndex((e) => e.emergencyDetailId === t)
      if (index !== -1) {
        this.emergencyDetailCheckboxes[index].checked = index !== -1 ? true : false
      }
    })
    return this.selectedemergencyDetailList
  }

  private updateTradeDelay() {
    if (this.trade) {
      let tradeDelay = this.tradeDelays.filter((x) => x.id === this.trade!.tradeId.toString())[0]
      if (!tradeDelay) {
        tradeDelay = new TradeDelay()
        tradeDelay.id = this.trade.tradeId.toString()
      }
      tradeDelay.delayCodes = this.trade.delayCodes
      this.$store.dispatch('updateTradeDelay', tradeDelay)
    }
  }

  private get tradeDelays(): TradeDelay[] {
    return storeGetters.getTradeDelays()
  }

  private get availableDelayCodes(): DelayCode[] {
    const allDelayCodes = storeGetters.getDelayCodes() as DelayCode[]
    return allDelayCodes.filter((x) => !x.isDeleted)
  }
}
</script>
