import moment from 'moment'

export default class PolicyRouteToMarketModel {
  public policyRouteToMarketId: number
  public routeToMarket: string
  public productCode: string
  public insurerId: number
  public policyId: number
  public productItems: PolicyRouteToMarketModel[]
  public dummyId: number
  public isLoading: boolean

  public constructor() {
    this.dummyId = 1
  }
}
