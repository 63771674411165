<template>
  <v-layout flex-col align-center gap-2>
    <div class="wrapper">
      <img :src="image ? image : '/img/unknownuser.png'" class="image rounded-full elevation-1" />

      <v-layout flex-col justify-space-between class="buttons">
        <v-btn label icon color="secondary" class="ma-0">
          <label class="pa-2 cursor-pointer">
            <v-icon>edit</v-icon>

            <input
              ref="image"
              v-validate="{
                rules: {
                  required: false,
                  image: true,
                  size: profileImageSize,
                  mimes: ['image/jpeg', 'image/jpg', 'image/png'],
                  ext: validExtensions,
                },
              }"
              type="file"
              class="hide"
              accept="image/*"
              name="logo"
              data-vv-scope="profileImage"
              @change="onImageChanged"
            />
          </label>
        </v-btn>

        <v-btn v-if="image" icon color="error" class="ma-0 pa-2" @click.native.stop="clear">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-layout>
    </div>

    <div class="error--text">
      {{ validationMessage(errors) }}
    </div>
  </v-layout>
</template>

<script lang="ts">
import Store from '@/store'
import { ErrorBag } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ProfileImage extends Vue {
  @Prop({ default: '' }) public image
  @Prop({ default: null }) public file: File | null

  public validExtensions = ['jpg', 'jpeg', 'png']

  public get profileImageSize() {
    return Store.Instance.state.Environment.LogoSize
  }

  public clear() {
    ;(this.$refs.image as HTMLInputElement).value = ''
    this.$emit('update:image', '')
    this.$emit('update:file', null)
  }

  public validationMessage(err: ErrorBag) {
    if (err.collect('logo').length > 0) {
      const message: string = err.collect('logo')[0]
      this.clear()
      return message === 'The logo field must be an image.' ? 'Only JPEG, JPG, PNG files are allowed.' : message
    }
  }

  public onImageChanged(event) {
    const file = event.target.files[0] as File
    if (!file || file.size / 1048576 > 1) {
      return
    }
    const ext = (file.name.split('.').at(-1) || '').toLowerCase()
    if (!this.validExtensions.includes(ext)) {
      return
    }

    const fr = new FileReader()
    fr.addEventListener('load', () => {
      this.$emit('update:image', fr.result)
    })
    fr.readAsDataURL(file)
    this.$emit('update:file', file)
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
}

.image {
  background-color: #fff;
  border: 1rem solid white;
  object-fit: cover;
  height: 150px;
  width: 150px;
}

.buttons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transform: scale(0.9);
  transition: all var(--transition-duration);
}
*:hover > .buttons {
  opacity: 1;
  transform: none;
}
</style>
