export default class AddJobWithPolicyEnquiry {
  public clientTitle: string
  public clientForename: string
  public clientLastName: string
  public secondaryClientTitle: string | null
  public secondaryClientForename: string | null
  public secondaryClientLastName: string | null
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postCode: string

  public policyNumber: string
  public policyScheduleId: number
  public isStarredPolicy: boolean

  public enquiryDescription: string
  public enquiryCategory: string

  public FNOLCallSid: string
  public policyNumberNotPresentReason: string
}
