import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'

export default class EmergencyController {
  public static async SaveEmergency(emergency: EmergencyModel): Promise<EmergencyModel> {
    const res = await CallCentreApi.post<EmergencyModel>('Emergency/SaveEmergency', emergency)
    return res.data
  }

  public static async DeleteEmergency(emergencyId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(
      'Emergency/DeleteEmergency?emergencyId=' + encodeURIComponent(emergencyId)
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SaveEmergencyDetail(emergencyDetail: EmergencyDetailModel): Promise<EmergencyDetailModel> {
    const res = await CallCentreApi.post<EmergencyDetailModel>('Emergency/SaveEmergencyDetail', emergencyDetail)
    return res.data
  }

  public static async DeleteEmergencyDetail(emergencyDetailId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete(
      'Emergency/DeleteEmergencyDetail?emergencyDetailId=' + encodeURIComponent(emergencyDetailId)
    )
    return res.status >= 200 && res.status < 300
  }
}
