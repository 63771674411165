<template>
  <v-card v-if="item" class="pt-0 px-2 pb-2">
    <v-card-title>
      <v-layout>
        <v-flex xs12>
          <h3 class="heading">
            {{ item.direction == 'outbound-api' ? 'Outgoing SMS' : 'Incoming SMS' }}

            <span v-if="documentTemplate" class="teal--text">({{ documentTemplate }})</span>
          </h3>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <pre>{{ item.body }}</pre>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import SMSModel from '@/models/claim/SMSModel'
import storeGetters from '@/storeGetters'
import { DocumentTemplateType } from '@/common/enums'

@Component
export default class SMSPreview extends TimeLineItemBase {
  private get item(): SMSModel | null {
    if (this.jobId && this.itemId) {
      return storeGetters.getJobSmsTexts(this.jobId, this.itemId)
    }
    return null
  }

  private get documentTemplate(): string {
    if (this.jobId && this.itemId && this.item) {
      if (this.item.documentTemplateType) {
        switch (this.item.documentTemplateType.toString()) {
          case DocumentTemplateType[DocumentTemplateType.NewJobLogged]:
            return 'New Job Logged'
          case DocumentTemplateType[DocumentTemplateType.ContractorAssigned]:
            return 'Contractor Appointed'
          case DocumentTemplateType[DocumentTemplateType.EngineerOnTheWay]:
            return 'Engineer On The Way'
          case DocumentTemplateType[DocumentTemplateType.JobCompleted]:
            return 'Job Completed'
          case DocumentTemplateType[DocumentTemplateType.JobCompletionRecommendationScore]:
            return 'Job Completion Recommendation Score'
          case DocumentTemplateType[DocumentTemplateType.JobCompletionServiceQualityScore]:
            return 'Job Completion Service Quality Score'
          case DocumentTemplateType[DocumentTemplateType.JobCompletionQualityComments]:
            return 'Job Completion Quality Comments'
          case DocumentTemplateType[DocumentTemplateType.CustomerAppointedTradespeople]:
            return 'Customer Appointed Tradespeople'
          case DocumentTemplateType[DocumentTemplateType.FollowOnWork]:
            return 'Follow On Work'
          case DocumentTemplateType[DocumentTemplateType.DeploymentDelay]:
            return 'Deployment Delay'
          case DocumentTemplateType[DocumentTemplateType.PartRequestApproved]:
            return 'Part Request Approved'
          case DocumentTemplateType[DocumentTemplateType.SetETAOnReturnVisit]:
            return 'Set ETA On Return Visit'
          case DocumentTemplateType[DocumentTemplateType.CancelOnMyWay]:
            return 'Cancel On My Way'
          case DocumentTemplateType[DocumentTemplateType.FNOLEffortScore]:
            return 'FNOL Effort Score'
          case DocumentTemplateType[DocumentTemplateType.WIPEffortScore]:
            return 'WIP Effort Score'
          case DocumentTemplateType[DocumentTemplateType.EffortScoreComments]:
            return 'Effort Score Comments'
          default:
            return this.item.documentTemplateType.toString()
        }
      }
    }
    return ''
  }
}
</script>

<style scoped>
pre {
  font-family: inherit;
  white-space: pre-line;
}
</style>
