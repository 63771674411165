import moment from 'moment'
import { InvoiceStatus } from '@/common/enums'

export default class BordereauModel {
  public clientId: string
  public bordereauName: string
  public numberOfJobs: number
  public status: string
  public amount: number
  public amountPaid: number
  public documentURL: string
  public sanalCSVUri: string
  public stranCSVUri: string
  public bordereauCSVUri: string
  public createdAt: moment.Moment
}
