<template>
  <div v-if="ready" class="dashboard">
    <v-layout wrap>
      <v-flex xs12>
        <v-container fluid grid-list-lg pt-0 pa-0>
          <v-layout wrap calls-info>
            <DashboardTopTile
              v-for="key in Object.keys(dashboardComplaintTopTiles)"
              v-bind="dashboardComplaintTopTiles[key]"
              :key="key"
              class="complaint-tracker-chart"
              dashboard-tile-view="ComplaintTracker"
            />
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex v-if="getComplaint.length" class="gridView mt-4">
        <v-layout wrap pr-2 pl-0 pt-1>
          <OutstandingComplaintsTable
            table-name="outstandingComplaintsDashboard"
            :complaints="getComplaint"
            :loading-complaint-list="loadingComplaintList"
            :ready="ready"
            :complaint-dashboard-selected-job-type.sync="complaintDashboardSelectedJobType"
          >
          </OutstandingComplaintsTable>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
  <div v-else>
    <v-progress-circular
      class="loading-spinner"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import DashboardController from '@/api/dashboardController'
import DashboardTopTile, { TopTileProps } from '@/components/dashboard/DashboardTopTile.vue'
import ComplaintTrackerModel from '@/models/claim/ComplaintTrackerModel'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import OutstandingComplaintsTable from '@/components/tables/OutstandingComplaintsTable.vue'
import SignalRHub from '@/components/SignalRHub.vue'
import ComplaintModel from '@/models/claim/ComplaintModel'

@Component({
  components: { DashboardTopTile, OutstandingComplaintsTable },
})
export default class ComplaintTracker extends SignalRHub {
  private ready = false
  private loadingComplaintList = false

  private async created() {
    this.GetComplaintTrackingDetail()
    this.addUpsertComplaintHandler()
    this.complaintDashboardSignalRHub.connect()
  }

  // Get complaints for dashboard
  private GetComplaintTrackingDetail(): void {
    const self = this
    this.loadingComplaintList = true
    DashboardController.GetComplaintTrackingDetail().then((res: ComplaintTrackerModel | null) => {
      self.complaintListForDashBoard = res
      if (self.complaintListForDashBoard) {
        self.complaintListForDashBoard.complaints.forEach((element) => {
          const getUser = self.userList.find((u) => u.id === element.responsiblePerson)
          if (getUser) {
            element.responsiblePersonName = getUser.displayValue
            element.teamName = getUser.teamName ? getUser.teamName : ''
          }
        })
      }
      self.loadingComplaintList = false
      self.ready = true
    })
  }

  private mounted() {
    SetThemeForJobType(this, undefined)
  }

  private get getComplaint(): ComplaintModel[] {
    return this.getComplaintByJobType.filter((x: ComplaintModel) => !x.isResolved)
  }
}
</script>

<style scoped>
.team-member-img {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
}
.team-member-img.b-red img {
  border-color: #cf1e60;
}
.team-member-img.b-pink img {
  border-color: #ff8a65;
}
.gridView {
  border: 0px;
}

.complaint-tracker-chart.dashboardtop-tile >>> .chart-line {
  width: 100%;
  max-height: 100px;
  overflow: visible;
  position: absolute;
  bottom: -5px;
  left: 0px;
  right: 0px;
}
.complaint-tracker-chart.dashboardtop-tile >>> .chart-line .complaint-tracker-chart.dashboardtop-tile >>> .chart-line {
  margin-top: 0px !important;
}
</style>
