import Product from '@/models/siteInvestigation/Product'

export default class PitTest extends Product {
  public notes = ''

  constructor(product?: Product) {
    super(product)
    this.quantity = product!.quantity ? product!.quantity : 1
  }
}
