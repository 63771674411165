<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 md6 lg8>
        <h3 class="sub-title mt-4">Below listed are the selected questions</h3>
      </v-flex>
      <v-flex xs12 md6 lg4>
        <v-autocomplete
          v-if="!isHelplinePolicy && questionTypeList.length > 0"
          v-model="selectedQuestionType"
          :items="questionTypeList"
          label="Question Types"
          item-text="name"
          item-value="id"
          hide-details
          class="pr-1"
          @change="OnChangeQuestionType($event)"
        ></v-autocomplete>
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-actions
      class="mt-3 gridView em-questionList tbody-scroll-table"
    >
      <template slot="headers" slot-scope="props">
        <tr>
          <th
            v-for="(header, index) in props.headers"
            :key="index"
            class="column"
            :class="header.text === 'Mandatory' || header.text === 'Validation' ? 'text-xs-center' : 'text-xs-left'"
          >
            {{ header.text }}
          </th>
          <th v-if="!isUserRoleClientManager" class="column text-xs-center em-que-action">Actions</th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr :key="itemKey(props.item)" class="sortableRow" :active="props.item.mandatory">
          <td class="px-1 text-xs-left" style="width: 0.1%">
            <v-tooltip top>
              <template #activator="{ on }">
                <span class="sortHandle icon" style="cursor: move" v-on="on">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#757575">
                    <circle cy="6.5" cx="9.5" r="1.5"></circle>
                    <circle cy="6.5" cx="14.5" r="1.5"></circle>
                    <circle cy="12.5" cx="9.5" r="1.5"></circle>
                    <circle cy="12.5" cx="14.5" r="1.5"></circle>
                    <circle cy="18.5" cx="9.5" r="1.5"></circle>
                    <circle cy="18.5" cx="14.5" r="1.5"></circle>
                  </svg>
                </span>
              </template>
              <span>Drag to change order</span>
            </v-tooltip>
          </td>
          <td class="text-xs-center">
            <v-checkbox
              color="primary"
              hide-details
              :input-value="props.item.mandatory"
              :disabled="isUserRoleClientManager"
              @click.native="onMandatoryChange(props.item)"
            ></v-checkbox>
          </td>
          <td>{{ props.item.overWrittenText }}</td>
          <td>{{ props.item.entityName }}</td>
          <td class="text-xs-center">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon v-show="props.item.requiredForValidation" color="primary" class="px-1" v-on="on">
                  check_circle
                </v-icon>
              </template>
              <span>Validation Applied</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon v-show="props.item.hasComment" color="primary" class="px-1" v-on="on">info</v-icon>
              </template>
              <span>Additional Information Added</span>
            </v-tooltip>
            <v-icon v-show="!props.item.requiredForValidation && !props.item.hasComment" color="primary" class="px-1">
              remove
            </v-icon>
          </td>
          <td v-if="!isUserRoleClientManager" class="text-xs-center actions">
            <v-tooltip v-show="!props.item.keyQuestion" top>
              <template #activator="{ on }">
                <v-icon left class="mr-2 dark" v-on="on" @click="onKeyQuestionClick(props.item)">star_border</v-icon>
              </template>
              <span>Mark as key question</span>
            </v-tooltip>
            <v-tooltip v-show="props.item.keyQuestion" top>
              <template #activator="{ on }">
                <v-icon left class="mr-2 dark" v-on="on" @click="onKeyQuestionClick(props.item)">star</v-icon>
              </template>
              <span>Remove from key question</span>
            </v-tooltip>
            <v-icon left class="mr-2" @click="editQuestion(props.item)">edit</v-icon>
            <v-icon left class="mr-0" @click="onDeleteClick(props.item)">delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      content-class="dialog-content v-dialog--scrollable"
      max-width="600px"
      persistent
    >
      <v-card v-if="policyQuestion">
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Edit selected question</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="showDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-3 scroll-content-dialog">
          <v-form autocomplete="off">
            <v-layout wrap>
              <EditPolicyQuestion
                ref="editPolicyQuestion"
                :question="policyQuestion"
                :repudiations="repudiations"
                @setQuestionDetail="setQuestionDetail"
              ></EditPolicyQuestion>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat class="mr-2" @click="showDialog = false">Close</v-btn>
          <v-btn color="primary" class="ml-0" @click="saveQuestion">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import eventBus from '@/common/bus'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import PolicyQuestionModel from '@/models/policy/PolicyQuestionModel'
import Shared from '@/common/shared'
import Store from '@/store'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import { Operator } from '@/common/interfaces'
import EditPolicyQuestion from '@/components/EditPolicyQuestion.vue'
import RepudiationModel from '@/models/policyHolder/RepudiationModel'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import { OperatorType } from '@/common/enums'

@Component({
  components: { EditPolicyQuestion },
})
export default class EmergencyQuestionList extends Vue {
  @Prop() private policyQuestions: PolicyQuestionModel[]
  @Prop() private policyId: number
  @Prop() private isHelplinePolicy: boolean

  private items: PolicyQuestionModel[] = []
  private allItems: PolicyQuestionModel[] = []
  private headers: any = []
  private showDialog = false
  private policyQuestion: PolicyQuestionModel | null = null
  private currentItemKey = 0
  private Sortable = require('sortablejs')
  private itemKeys = new WeakMap()
  private questionTypeList: string[] = []
  private selectedQuestionType = ''
  private repudiations: RepudiationModel[] = []
  private defaultSelectedItem = 'All'
  private selectedItem: string = this.defaultSelectedItem
  private selectedRemovedEntity = ''

  public setList(questionList: PolicyQuestionModel[]): void {
    // Initially assign items to original all items
    this.items = questionList
    if (this.items.length === 0) {
      this.allItems = []
    } else {
      this.allItems = []
      Object.assign(this.allItems, this.items)
    }
  }

  private created(): void {
    this.items = this.policyQuestions
    this.headers = [
      { text: '', value: 'drag', sortable: false },
      { text: 'Mandatory', value: 'mandatory', sortable: false },
      { text: 'Selected questions', value: 'overWrittenText', sortable: false },
      { text: 'Question type', value: 'entityName', sortable: false },
      { text: 'Validation', value: 'requiredForValidation', sortable: false },
    ]
    PolicyHolderController.GetAllRepudiations()
      .then((res: RepudiationModel[]) => {
        if (res) {
          this.repudiations = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading repudiation list, please try again', true)
      })
  }

  private mounted(): void {
    const self = this

    this.Sortable = new this.Sortable(this.$el.getElementsByTagName('tbody')[0], {
      draggable: '.sortableRow',
      handle: '.sortHandle',
      onEnd: this.dragReorder,
    })

    // update selected emergency question list, when question set as selected and being updated
    eventBus.$on('editEmergencyQuestion', (question: EmergencyQuestionModel) => {
      const index = this.allItems.findIndex(
        (e: PolicyQuestionModel) => e.questionId === question.id && e.entityType === question.entityType
      )
      if (index !== -1 && question.isSelected) {
        const policyQuestion: PolicyQuestionModel = this.allItems[index]
        policyQuestion.overWrittenText = question.text
        policyQuestion.question = question
        policyQuestion.entityName = question.entityName
      }
    })

    // on question select/de-select
    eventBus
      .$off('addOrRemoveEmergencyQuestion')
      .$on('addOrRemoveEmergencyQuestion', (question: EmergencyQuestionModel) => {
        this.selectedRemovedEntity = ''
        if (!question.isSelected) {
          this.filterAndRemoveItem(question)
          this.selectedRemovedEntity = question.entityName
        } else {
          const policyQuestion: PolicyQuestionModel = new PolicyQuestionModel()
          policyQuestion.id = ''
          policyQuestion.policyId = self.policyId ? self.policyId : 0
          policyQuestion.overWrittenText = question.text
          policyQuestion.entityType = question.entityType
          policyQuestion.question = question
          policyQuestion.entityName = question.entityName
          policyQuestion.orderBy =
            1 +
            Math.max.apply(
              Math,
              this.items.map((o: PolicyQuestionModel) => o.orderBy)
            )
          policyQuestion.questionId = question.id
          setTimeout(() => {
            self.allItems.push(policyQuestion)
            if (this.selectedItem === this.defaultSelectedItem) {
              this.items = this.allItems
            } else {
              this.items = this.allItems.filter(
                (v) => v.entityName.toLowerCase().trim() === this.selectedItem.toLowerCase().trim()
              )
            }
            self.items = self.sortItemsByAscendingOrder(self.items)
          }, 0)
        }
      })

    // on deleting a question
    eventBus.$off('deleteEmergencyQuestion').$on('deleteEmergencyQuestion', (question: EmergencyQuestionModel) => {
      this.filterAndRemoveItem(question)
    })
  }

  @Watch('items')
  private getQuestionTypeList(newValue: PolicyQuestionModel[]): string[] {
    // get question type list from total questionlist items to show in drop down for filter
    if (this.allItems.length > 0) {
      const allItemName: string = this.defaultSelectedItem
      this.questionTypeList = [...new Set(this.allItems.map((c) => c.entityName))]
      this.questionTypeList.sort()
      this.questionTypeList.unshift(allItemName)
      if (this.items.length === this.allItems.length) {
        this.selectedQuestionType = allItemName
        this.selectedItem = allItemName
      }
    } else {
      this.questionTypeList = []
    }
    // reset question type dropdown when selected question type length is 0.
    if (this.items.length === 0 && this.selectedItem === this.selectedRemovedEntity) {
      this.items = this.allItems
      this.selectedItem = this.defaultSelectedItem
    }
    this.$emit('updateEmergencyQuestion', this.allItems)
    return this.questionTypeList
  }

  private itemKey(item: PolicyQuestionModel) {
    if (!this.itemKeys.has(item)) {
      this.itemKeys.set(item, ++this.currentItemKey)
    }
    return this.itemKeys.get(item)
  }

  private dragReorder(event: any): void {
    const movedItem = this.items.splice(event.oldIndex, 1)[0]
    this.items.splice(event.newIndex, 0, movedItem)
    this.arrangeOrdersInFilteredAndMainItems()
  }

  private onMandatoryChange(question: PolicyQuestionModel): void {
    const selectedItem: PolicyQuestionModel | undefined = this.items.find(
      (e: PolicyQuestionModel) =>
        e.policyId === question.policyId && e.questionId === question.questionId && e.entityType === question.entityType
    )
    if (selectedItem) {
      Vue.set(selectedItem, 'mandatory', !selectedItem.mandatory)
    }
  }

  private onKeyQuestionClick(question: PolicyQuestionModel): void {
    const selectedItem: PolicyQuestionModel | undefined = this.items.find(
      (e: PolicyQuestionModel) =>
        e.policyId === question.policyId && e.questionId === question.questionId && e.entityType === question.entityType
    )
    if (selectedItem) {
      Vue.set(selectedItem, 'keyQuestion', !selectedItem.keyQuestion)
    }
  }

  private onDeleteClick(question: PolicyQuestionModel): void {
    Shared.confirmationPopup.open('', '', 'question', '', '', this, 'removeItem', question)
  }

  private editQuestion(question: PolicyQuestionModel): void {
    this.policyQuestion = new PolicyQuestionModel()
    this.policyQuestion = Object.assign({}, this.policyQuestion, question) as PolicyQuestionModel
    if (
      this.policyQuestion &&
      this.policyQuestion.comparisonValue &&
      (this.policyQuestion.operatorId === OperatorType.In || this.policyQuestion.operatorId === OperatorType.NotIn)
    ) {
      const arrayItems: string[] = this.policyQuestion.comparisonValue.split(',')
      Vue.set(this.policyQuestion, 'comparisonValue', arrayItems)
    }
    this.showDialog = true
  }

  private saveQuestion(): void {
    const editPolicyQuestion: EditPolicyQuestion = this.$refs.editPolicyQuestion as EditPolicyQuestion
    editPolicyQuestion.saveQuestion()
  }

  private setQuestionDetail(question: PolicyQuestionModel): void {
    const selectedItem: PolicyQuestionModel | undefined = this.items.find(
      (e: PolicyQuestionModel) =>
        e.policyId === question.policyId && e.questionId === question.questionId && e.entityType === question.entityType
    )
    if (selectedItem) {
      Vue.set(selectedItem, 'overWrittenText', question.overWrittenText)
      Vue.set(selectedItem, 'requiredForValidation', question.requiredForValidation)
      Vue.set(selectedItem, 'operatorId', question.operatorId)
      Vue.set(selectedItem, 'comparisonValue', question.comparisonValue)
      Vue.set(selectedItem, 'hasComment', question.hasComment)
      Vue.set(selectedItem, 'forCommentOperatorId', question.forCommentOperatorId)
      Vue.set(selectedItem, 'forCommentComparisonValue', question.forCommentComparisonValue)
      Vue.set(selectedItem, 'commentLabel', question.commentLabel)
      Vue.set(selectedItem, 'repudiationId', question.repudiationId)
    }
    this.showDialog = false
  }

  private removeItem(question: PolicyQuestionModel): void {
    const index: number = this.items.indexOf(question)
    this.items.splice(index, 1)
    eventBus.$emit('removeEmergencyQuestionSelection', question)
    this.allItems = this.allItems.filter((c) => c.questionId !== question.questionId)
    this.allItems = this.sortItemsByAscendingOrder(this.allItems)
    if (this.selectedItem === this.defaultSelectedItem) {
      this.items = this.allItems
    } else {
      this.items = this.allItems.filter(
        (v) => v.entityName.toLowerCase().trim() === this.selectedItem.toLowerCase().trim()
      )
    }
  }

  private arrangeOrdersInFilteredAndMainItems(): void {
    this.items = this.sortItemsByAscendingOrder(this.items)
    // Arrange order of All Items according to filtered items
    const questionIdsOfFilteredItems: number[] = this.items.map((c) => c.questionId)
    const self = this
    let allItemsIndex = 0
    let filteredItemIndex = 0
    this.allItems.forEach((singleItem: PolicyQuestionModel) => {
      if (
        singleItem &&
        questionIdsOfFilteredItems.find((c) => singleItem !== undefined && c === singleItem.questionId)
      ) {
        const getItem: PolicyQuestionModel | undefined = self.items.find(
          (v) => v.questionId === questionIdsOfFilteredItems[filteredItemIndex]
        )
        if (getItem !== undefined) {
          self.allItems[allItemsIndex] = getItem
          ++filteredItemIndex
        }
      }
      ++allItemsIndex
    })
    // sort the orders of arranged allItems array
    this.allItems = this.sortItemsByAscendingOrder(this.allItems)
  }

  private sortItemsByAscendingOrder(itemsArray: PolicyQuestionModel[]): PolicyQuestionModel[] {
    let orderNo = 0
    itemsArray.forEach((e: PolicyQuestionModel) => {
      e.orderBy = ++orderNo
    })
    return itemsArray
  }

  // filter by question type from all question list
  private OnChangeQuestionType(item: string) {
    const getQTListOfCurrentQuestionList: string[] = [...new Set(this.items.map((c) => c.entityName))]
    // check if filterd question type value is already filtered or not
    if (!item.toLowerCase) {
      return
    }
    if (
      getQTListOfCurrentQuestionList.length === 1 &&
      getQTListOfCurrentQuestionList[0].toLowerCase().trim() === item.toLowerCase().trim()
    ) {
      return
    }
    // delete all mapItemKeys first to set new for filtered list
    this.items.forEach((element) => {
      this.itemKeys.delete(element)
    })
    this.currentItemKey = 0
    if (item === this.defaultSelectedItem) {
      this.items = this.allItems
    } else {
      this.items = this.allItems.filter((v) => v.entityName.toLowerCase().trim() === item.toLowerCase().trim())
    }
    this.selectedItem = item
  }

  private get isUserRoleClientManager(): boolean {
    return (
      (Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager') ||
      Store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail'
    )
  }

  private filterAndRemoveItem(question: EmergencyQuestionModel) {
    const self = this
    if (question.policyId !== 0) {
      // when question is policy wide
      const item: PolicyQuestionModel | undefined = self.allItems.find(
        (e: PolicyQuestionModel) =>
          e.policyId === question.policyId && e.questionId === question.id && e.entityType === question.entityType
      )
      if (item) {
        self.removeItem(item)
      }
    }
    const newItem: PolicyQuestionModel | undefined = self.allItems.find(
      (e: PolicyQuestionModel) => e.questionId === question.id && e.entityType === question.entityType
    )
    if (newItem) {
      self.removeItem(newItem)
    }
  }
}
</script>

<style scoped>
.em-questionList >>> td .v-input--checkbox {
  width: 25px;
  margin: 0 auto;
}
.em-questionList >>> td .v-input--checkbox .input-group--selection-controls__ripple {
  width: 25px;
}
.actions {
  width: 146px;
}
.actions .v-icon {
  cursor: pointer;
}
.gridView td:last-child,
.custom-table td:nth-last-of-type(2) {
  text-align: center;
}
.gridView >>> tbody tr {
  border-bottom: 0px !important;
}
.v-dialog-content .input-group label {
  font-size: 14px;
}
.em-que-action {
  min-width: 150px;
}
.em-questionList thead tr th:nth-of-type(4) {
  min-width: 300px;
}
.tbody-scroll-table >>> table {
  width: 100%;
}
.tbody-scroll-table >>> tbody {
  display: block;
  max-height: 500px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
}
.tbody-scroll-table >>> tbody tr,
.tbody-scroll-table >>> thead,
.tbody-scroll-table >>> tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.tbody-scroll-table >>> tbody::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.tbody-scroll-table >>> tbody::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 3px;
}
.em-questionList.tbody-scroll-table table thead tr th:nth-child(1) {
  width: 0% !important;
}
.em-questionList.tbody-scroll-table table thead tr th:nth-child(3) {
  width: 48%;
}
.em-questionList.tbody-scroll-table table thead tr th:nth-child(2) {
  width: 15% !important;
}
.em-questionList.tbody-scroll-table table tbody tr td:nth-child(3) {
  width: 48%;
}
.em-questionList.tbody-scroll-table table tbody tr td:nth-child(2) {
  width: 15% !important;
}
@media (max-width: 1263px) {
  .em-questionList.tbody-scroll-table table thead tr th:nth-child(3) {
    width: 35%;
  }
  .em-questionList.tbody-scroll-table table tbody tr td:nth-child(3) {
    width: 35%;
  }
}
</style>
