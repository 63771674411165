<template>
  <v-layout class="peril-repeat" wrap>
    <v-flex v-if="value" xs12>
      <v-layout wrap>
        <v-flex xs12>
          <v-select
            v-model.trim="value.noteType"
            :items="noteTypes"
            label="Type"
            item-text="description"
            item-value="id"
            class="note-types"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model.trim="value.title"
            v-validate="'required'"
            label="Title"
            required
            class="required"
            data-vv-name="Title"
            name="Title"
            :error-messages="validationMessage('Title')"
          ></v-text-field>
        </v-flex>
        <v-flex v-if="jobItem && !jobItem.helplinePolicy" xs12>
          <v-select
            v-model.trim="value.forEmergencyDetailId"
            v-validate="'required'"
            :label="jobTypeLabel('emergency detail')"
            :items="jobEmergencyDetailList"
            item-text="description"
            item-value="emergencyDetailId"
            :data-vv-name="jobTypeLabel('emergency detail')"
            required
            class="required emergency-Detail"
            :error-messages="validationMessage(jobTypeLabel('emergency detail'))"
            @input="selectedEmergencyDetail"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model.trim="value.description"
            v-validate="'required'"
            label="Description"
            required
            class="required"
            rows="5"
            data-vv-name="Description"
            name="Description"
            :error-messages="validationMessage('Description')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import AddNewJobNote from '@/models/requests/AddNewJobNote'
import VeeValidate from 'vee-validate'
import JobController from '@/api/jobController'
import Shared from '@/common/shared'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import { JobNoteTypes, IJobNoteType } from '@/common/enums'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import Emergency from '@/models/Emergency'

@Component
export default class AddJobNote extends Vue {
  @Prop() private value: AddNewJobNote
  private jobEmergencyDetails: EmergencyDetailModel[] = []
  private defaultDescription = 'All'

  private jobTypeLabel(name: string) {
    return Shared.JobTypeLabel(name, this.jobItem ? this.jobItem.jobType : 'HE')
  }

  private get noteTypes(): IJobNoteType[] {
    return JobNoteTypes
  }
  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private get jobItem(): Job | null {
    if (this.value) {
      return storeGetters.getJob(this.value.jobId)
    }
    return null
  }

  private get jobEmergencyDetailList(): EmergencyDetailModel[] {
    if (this.jobItem && this.jobItem.emergencies) {
      this.jobItem.emergencies.forEach((element: Emergency, index: number) => {
        if (this.value) {
          const emergencyDetailModel: EmergencyDetailModel = new EmergencyDetailModel()
          emergencyDetailModel.emergencyDetailId = element.detailId
          emergencyDetailModel.description = element.detailDescription
          this.jobEmergencyDetails.push(emergencyDetailModel)
        }
      })
      // distinct emergency details passed in jobnote model dialog
      this.jobEmergencyDetails = this.jobEmergencyDetails.filter(
        (item, index, array) =>
          array.map((mapObj) => mapObj.emergencyDetailId).indexOf(item.emergencyDetailId) === index
      )
      if (this.jobEmergencyDetails.length > 1) {
        const allItemModel: EmergencyDetailModel = new EmergencyDetailModel()
        allItemModel.description = this.defaultDescription
        allItemModel.emergencyDetailId = 0
        this.jobEmergencyDetails.push(allItemModel)
      }
      // sort by description property of jobEmergencyDetails
      this.jobEmergencyDetails = this.jobEmergencyDetails.sort((a: EmergencyDetailModel, b: EmergencyDetailModel) =>
        a.description > b.description ? 1 : b.description > a.description ? -1 : 0
      )
    }
    return this.jobEmergencyDetails
  }

  private selectedEmergencyDetail(emergencyDetailId: number) {
    if (emergencyDetailId) {
      this.value.forEmergencyDetailId = emergencyDetailId
      const emergencyDetail: EmergencyDetailModel | undefined = this.jobEmergencyDetails.find(
        (c) => c.emergencyDetailId === emergencyDetailId
      )
      if (emergencyDetail) {
        this.value.forEmergencyDetailDescription = emergencyDetail.description
      }
    } else {
      this.value.forEmergencyDetailDescription = this.defaultDescription
    }
  }
}
</script>
