<template>
  <div class="dashboard">
    <v-switch v-model="filterAutodeploying" label="Auto-Deploying Jobs" hide-details color="primary" />

    <div class="search-fields">
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details clearable />
      <v-autocomplete v-model="team" label="Filter by team" maxlength="50" :items="teams" hide-details />
    </div>

    <h3 class="font-weight-medium">Trades</h3>
    <div class="filters my-2 gap-2">
      <label v-for="[name, count] in trades" :key="name" class="filter">
        <input v-model="trade" type="radio" class="sr-only" :value="name" :name="name" />
        {{ name }} <span class="count">{{ count }}</span>
      </label>
    </div>

    <v-data-table
      :headers="headers"
      :items="filtered"
      :pagination.sync="pagination"
      :search="search"
      :custom-filter="searchFilter"
      :custom-sort="sort"
      must-sort
      class="elevation-1 table no-wrap"
    >
      <template #items="{ item: { job, emergency, index, total } }">
        <td class="">
          <a class="font-weight-bold" @click="openJobView(job.jobId)">{{ job.jobId }}</a>
          &nbsp; <span class="text--secondary">{{ total > 1 ? `(${index + 1} of ${total})` : '' }}</span>
        </td>

        <td>{{ emergency.vulnerabilityLevel || '-' }}</td>

        <td>{{ job.policyNumber }}</td>

        <td>{{ job.name }}</td>

        <td>{{ job.loggedByTeamName }}</td>

        <td>{{ job.address.postCode }}</td>

        <td>{{ job.address.addressLine3 }}</td>

        <td>{{ emergency.tradesDescription }}</td>

        <td class="text-xs-center">
          <span v-if="emergency.isAutoDeploymentRunning">
            <span class="blink_text">Auto Deploying</span>
            ({{ emergency.deploymentAttempt }})
          </span>

          <span v-else>{{ emergency.deploymentAttempt }}</span>
        </td>

        <td>{{ emergency.loggedAtUtc ? formatDateTime(emergency.loggedAtUtc) : formatDateTime(job.createdAtUtc) }}</td>

        <td>{{ job.escalationReason }}</td>

        <td>{{ job.lastVisitByUserName }}</td>

        <td>{{ job.lastVisitUtc ? formatDateTime(job.lastVisitUtc) : null }}</td>
      </template>
    </v-data-table>

    <Notifications v-if="!expandedJob" ref="notifications" />

    <PartialJobView :job-id="expandedJob" @closeJobView="closeJobView" />
  </div>
</template>

<script lang="ts">
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { formatDateTime } from '@/common/datetime'
import { VulnerabilityLevelEnum } from '@/common/enums'
import Shared from '@/common/shared'
import PartialJobView from '@/components/PartialJobView.vue'
import Notifications from '@/components/tables/UndeployedEmergencyTableV2/UDENotifications.vue'
import { UndeployedEmergency, UndeployedJob } from '@/models/dashboards/UndeployedEmergencies'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import { Component, Vue, Watch } from 'vue-property-decorator'

type EmergencyMap = {
  emergency: UndeployedEmergency
  job: UndeployedJob
  index: number
  total: number
}

@Component({
  name: 'UndeployedEmergencyTableV2',
  components: { Notifications, PartialJobView },
})
export default class UndeployedEmergencyTableV2 extends Vue {
  public formatDateTime = formatDateTime
  public signalRHub: SignalRHubConnection = new SignalRHubConnection('undeployedEmergency')

  $refs: {
    notifications: Notifications
  }

  public pagination = {
    page: 1,
    rowsPerPage: Shared.rowsPerPageDefault,
  }
  public headers = [
    { text: 'JobId', value: 'jobId' },
    { text: 'Vuln.', value: 'vulnerabilityLevel' },
    { text: 'Policy Number', value: 'policyNumber' },
    { text: 'Name', value: 'name' },
    { text: 'Team', value: 'loggedByTeamName' },
    { text: 'Postcode', value: 'postCode' },
    { text: 'Address', value: 'addressLine3' },
    { text: 'Trade', value: 'tradesDescription' },
    { text: 'Deployment Attempts', value: 'deploymentAttempt' },
    { text: 'Logged At', value: 'createdAtUtc' },
    { text: 'Escalation Reason', value: 'escalationReason' },
    { text: 'Last Opened By', value: 'lastVisitByUserName' },
    { text: 'Last Opened At', value: 'lastVisitUtc' },
  ]

  private jobs: UndeployedJob[] = []
  private emergencyMap: EmergencyMap[] = []
  public filtered: EmergencyMap[] = []

  public expandedJob = ''

  public filterAutodeploying = false
  public search = ''

  public trades = new Map<string, number>()
  public trade = 'All'

  public teams: string[] = []
  public team = 'All'

  created() {
    this.setupSignalR()
    this.populateData()
  }

  destroyed() {
    this.cleanUpSignalR()
    Shared.passJobIdInHeader()
  }

  @Watch('trade', { immediate: true })
  @Watch('team')
  @Watch('filterAutodeploying')
  private onFiltersUpdated() {
    const filtered = this.emergencyMap
      .filter((item) => this.team === 'All' || item.job.loggedByTeamName === this.team)
      .filter((item) => this.filterAutodeploying === item.emergency.isAutoDeploymentRunning)

    this.updateTrades(filtered)
    this.filtered = filtered.filter((item) => this.trade === 'All' || item.emergency.tradesDescription === this.trade)
  }

  private setupSignalR() {
    this.signalRHub.addHandler(
      'updateUndeployedEmergency',
      (jobId: string, isEmergencyRemoved: boolean, isLastVisitUpdated: boolean) => {
        this.populateData()
        this.$refs.notifications.addNotification(
          jobId,
          isEmergencyRemoved ? 'Emergency removed' : isLastVisitUpdated ? 'Last visit updated' : 'Emergency updated'
        )
      }
    )

    this.signalRHub.addHandler('cancelUndeployedEmergency', (jobId: string) => {
      this.populateData()
      this.$refs.notifications.addNotification(jobId, 'Emergency cancelled')
    })

    this.signalRHub.connect()
  }

  private cleanUpSignalR() {
    this.signalRHub.removeHandler('updateUndeployedEmergency')
    this.signalRHub.removeHandler('cancelUndeployedEmergency')
    this.signalRHub.disconnect()
  }

  private async getData(): Promise<UndeployedJob[]> {
    const res = await CallCentreApi.get<UndeployedJob[]>('dashboards/undeployed-emergencies')
    return res.data ? res.data : []
  }

  private async populateData() {
    this.jobs = await this.getData()
    this.updateEmergencies()
    this.updateTeams()
    this.onFiltersUpdated()
  }

  private updateEmergencies() {
    this.emergencyMap.length = 0

    for (const job of this.jobs) {
      for (const [index, emergency] of job.undeployedEmergencies.entries()) {
        this.emergencyMap.push({ emergency, job, index, total: job.undeployedEmergencies.length })
      }
    }
  }

  private updateTrades(items: EmergencyMap[]) {
    this.trades.clear()
    this.trades.set('All', items.length)

    items.forEach(({ emergency }) => {
      const trade = emergency.tradesDescription
      this.trades.set(trade, (this.trades.get(trade) || 0) + 1)
    })

    if (!this.trades.has(this.trade)) {
      this.trade = 'All'
    }
  }

  private updateTeams() {
    const uniqueTeams = this.jobs.map((job) => job.loggedByTeamName).filter(Boolean)
    this.teams = Array.from(new Set(uniqueTeams))
    this.teams.unshift('All')
  }

  public searchFilter(items: EmergencyMap[], query: string, filter: (target: string, query: string) => boolean) {
    if (!query) {
      return items
    }

    return items.filter(
      ({ job, emergency }) =>
        filter(job.jobId, query) ||
        filter(job.policyNumber, query) ||
        filter(job.name, query) ||
        filter(job.address.addressLine3, query) ||
        filter(job.address.postCode, query) ||
        filter(emergency.tradesDescription, query) ||
        filter(job.createdAtUtc ? this.formatDateTime(job.createdAtUtc) : '', query)
    )
  }

  public sort(items: EmergencyMap[], column: string, isDescending: boolean) {
    if (!column) {
      return items
    }

    const direction = isDescending ? -1 : 1

    return items.sort((a: EmergencyMap, b: EmergencyMap) => {
      let aVal = a.job[column] || a.job.address[column] || a.emergency[column]
      let bVal = b.job[column] || b.job.address[column] || b.emergency[column]

      if (column === 'vulnerabilityLevel') {
        aVal = VulnerabilityLevelEnum[aVal] || 0
        bVal = VulnerabilityLevelEnum[bVal] || 0
      }

      if (isNaN(aVal) || isNaN(bVal)) {
        return direction * String(aVal || '').localeCompare(bVal || '')
      }
      return direction * (aVal - bVal)
    })
  }

  public openJobView(jobId) {
    this.expandedJob = jobId
    Shared.passJobIdInHeader(this.expandedJob)
  }

  public closeJobView() {
    this.expandedJob = ''
    Shared.passJobIdInHeader()
  }
}
</script>

<style scoped>
.search-fields {
  display: flex;
  gap: 1rem;
  justify-content: end;

  > * {
    flex-grow: 0;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.35rem;
}

.filter {
  align-items: center;
  background-color: #e0e0e0;
  display: flex;
  gap: 0.6rem;
  padding-left: 1rem;
  transition: background-color 0.15s;

  &:has(input:focus, input:hover) {
    background-color: #d0d0d0;
  }
  &:has(input:focus) {
    outline: 1px solid #0005;
  }
  &:has(input:checked) {
    background-color: #607d8c;
    color: white;
  }

  .count {
    background-color: #0001;
    font-weight: bold;
    padding: 0.6rem 1rem;
  }
}

.table >>> .v-table th[role='columnheader'] {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 20px;
}
</style>
