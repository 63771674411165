<template>
  <v-card v-if="productData" class="mb-3 monitoring-product-card elevation-2">
    <v-card-title>
      <h3>
        {{ productTitle }} -
        <UpdateProductPriceDialog
          ref="updateProductPriceDialogRef"
          :price-label="`£${productData.basePrice}`"
          :is-price-updated.sync="productData.isPriceUpdated"
          :product-price.sync="productBasePrice"
          :is-disabled="disabled"
          @editProductPrice="
            () => {
              openEditProductPriceDialog(productData)
            }
          "
          @productPriceUpdated="onProductPriceUpdated"
        ></UpdateProductPriceDialog>
      </h3>
      <v-spacer />
      <v-btn v-if="!disabled" icon @click="deleteProduct">
        <v-icon>delete</v-icon>
      </v-btn>
    </v-card-title>
    <v-flex xs12 px-0>
      <v-layout wrap>
        <v-flex v-for="(productAttribute, index) in productAttributes" :key="index" xs6 class="px-3">
          <v-text-field
            :id="productAttribute.attributeName"
            v-model="productData.productAttributes[index].attributeValue"
            v-validate="'required'"
            :label="productAttribute.displayName"
            class="required"
            type="number"
            :error-messages="$validator.errors.collect(productAttribute.displayName)"
            :data-vv-name="productAttribute.displayName"
            :disabled="disabled || productAttribute.isDisabled"
            required
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-container v-if="productChildLinks.length" class="pa-3 test-block">
      <v-flex xs12>
        <h4 class="pb-2">Extras:</h4>
        <v-flex v-for="product in productChildLinks" :key="product.name">
          <v-layout wrap pb-2>
            <v-flex xs6>
              <v-layout wrap xs12>
                <v-flex xs8>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-switch
                        ref="productChild"
                        :input-value="isChildSelected(product)"
                        class="pt-1 switch-toggle"
                        color="primary"
                        :label="product.name"
                        :disabled="disabled"
                        v-on="on"
                        @change="toggleChildProduct(product)"
                      ></v-switch>
                    </template>
                    <span>{{ product.description }}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex xs4 px-4 mt-1>
                  <UpdateProductPriceDialog
                    :price-label="`£${product.basePrice}`"
                    :is-price-updated.sync="product.isPriceUpdated"
                    :product-price.sync="productBasePrice"
                    :is-disabled="disabled || !isChildSelected(product)"
                    @editProductPrice="
                      () => {
                        openEditProductPriceDialog(product, true)
                      }
                    "
                    @productPriceUpdated="onProductPriceUpdated"
                  ></UpdateProductPriceDialog>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="isChildSelected(product)" :ref="product.id + ':data'" xs6>
              <v-layout wrap xs12>
                <v-flex xs6 px-3>
                  <v-text-field
                    v-model.number="product.quantity"
                    v-validate="`required|min_value:1`"
                    label="Quantity"
                    class="required"
                    :error-messages="$validator.errors.collect(product.name)"
                    :data-vv-name="product.name"
                    type="number"
                    :hint="product.unit"
                    :disabled="disabled"
                    required
                    @input="updateChild(product)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-flex>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import Pit from '@/models/siteInvestigation/Pit'
import Product from '@/models/siteInvestigation/Product'
import PitTest from '@/models/siteInvestigation/PitTest'
import ProductAttribute from '@/models/siteInvestigation/ProductAttribute'
import MonitoringProduct from '@/models/siteInvestigation/MonitoringProduct'
import UpdateProductPriceDialog from '@/components/UpdateProductPriceDialog.vue'

@Component({
  components: {
    UpdateProductPriceDialog,
  },
})
export default class MonitoringProductCard extends Vue {
  @Prop({ default: undefined }) public value: MonitoringProduct
  @Prop({ default: false }) public disabled: boolean
  @Prop({ default: '' }) public productTitle: string

  public productData: MonitoringProduct | null = null

  // update product price
  private productBasePrice = 0
  private selectedProductForPriceUpdate: Product | null = null
  private isUpdateChildLink = false

  public async validateProductCard(): Promise<boolean> {
    const result = await this.$validator.validateAll()
    return result
  }

  public mounted() {
    this.updateData()
    if (this.productData && this.productData.productAttributes && !this.disabled) {
      const productAttributes: ProductAttribute[] = []
      this.productData.productAttributes.forEach((a) => {
        const productAttribute = Object.assign(new ProductAttribute(), a)
        if (!productAttribute.isDisabled) {
          productAttribute.attributeValue = ''
        }
        productAttributes.push(productAttribute)
      })
      this.productData.productAttributes = productAttributes
      this.productData.selectedChildProducts = []
    }
  }

  @Watch('value', { deep: true })
  public updateData() {
    this.productData = this.value
  }

  @Watch('productData.productAttributes', { deep: true })
  @Watch('productData.selectedChildProducts', { deep: true })
  public localUpdateData() {
    if (this.disabled || this.productData === null) {
      return
    }
    this.emitInput()
  }

  private deleteProduct() {
    this.$emit('delete-product', this.productData)
  }

  private emitInput() {
    this.$emit('input', this.productData)
  }

  private get productAttributes(): ProductAttribute[] {
    if (this.productData && this.productData.productAttributes) {
      return this.productData.productAttributes.map((productAttribute) =>
        Object.assign(new ProductAttribute(), productAttribute)
      )
    }
    return []
  }

  private get productChildLinks(): Product[] {
    if (this.productData && this.productData.productChildLinks) {
      return this.productData.productChildLinks
    }
    return []
  }

  private isChildSelected(product: Product) {
    if (this.productData) {
      return this.productData.selectedChildProducts.map((x) => x.id).includes(product.id)
    }
    return false
  }

  private toggleChildProduct(product: Product) {
    if (this.productData) {
      const index = this.productData.selectedChildProducts.map((x) => x.id).indexOf(product.id)
      if (index === -1) {
        product.quantity = 1
        this.productData.selectedChildProducts.push(product)
      } else {
        this.productData.selectedChildProducts.splice(index, 1)
      }
      this.$validator.errors.remove(product.name)
    }
  }

  private updateChild(product: Product) {
    if (this.productData) {
      const index = this.productData.selectedChildProducts.map((x) => x.id).indexOf(product.id)
      if (index !== -1) {
        this.productData.selectedChildProducts[index].quantity = product.quantity
        this.emitInput()
      }
    }
  }

  private destroyed() {
    this.productData = null
  }

  private openEditProductPriceDialog(item: Product, isUpdateChildLink = false) {
    this.productBasePrice = item.basePrice
    this.selectedProductForPriceUpdate = item
    this.isUpdateChildLink = isUpdateChildLink
  }

  private onProductPriceUpdated(price: number) {
    if (this.selectedProductForPriceUpdate) {
      if (this.productData && this.selectedProductForPriceUpdate && this.isUpdateChildLink) {
        // update price for monitoring product child link
        const index = this.productData.selectedChildProducts
          .map((x) => x.id)
          .indexOf(this.selectedProductForPriceUpdate.id)
        if (index !== -1) {
          this.productData.selectedChildProducts[index].basePrice = price
          this.productData.selectedChildProducts[index].isPriceUpdated = true
        }
        const prouctChildIndex = this.productData.productChildLinks!.findIndex(
          (e) => e.id === this.selectedProductForPriceUpdate!.id
        )
        if (prouctChildIndex !== -1) {
          this.productData.productChildLinks![prouctChildIndex].basePrice = price
          this.productData.productChildLinks![prouctChildIndex].isPriceUpdated = true
        }
      } else {
        // update price for monitoring product
        this.selectedProductForPriceUpdate.basePrice = price
        this.selectedProductForPriceUpdate.isPriceUpdated = true
      }
    }
    this.isUpdateChildLink = false
  }
}
</script>

<style scoped>
.v-dialog .monitoring-product-card {
  background: white !important;
}
.monitoring-product-card .test-block >>> .v-input {
  padding-top: 0;
  margin-top: 0;
}
</style>
