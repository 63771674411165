<template>
  <v-form>
    <v-container fluid class="pa-0 grid-list-md">
      <v-layout wrap>
        <v-flex xs12>
          <v-text-field
            v-model.trim="bankDetail.name"
            v-validate="'required'"
            label="Bank Name"
            required
            class="required"
            :error-messages="errors.collect('Bank Name')"
            data-vv-name="Bank Name"
            name="bankName"
            :data-vv-scope="formRef"
          ></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-text-field v-model.trim="bankDetail.preferredReference" label="Preferred Reference"></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-text-field
            v-model.trim="bankDetail.accountNumber"
            v-validate="'required|numeric|max:8'"
            label="Account Number"
            required
            class="required"
            :error-messages="errors.collect('Account Number')"
            :data-vv-scope="formRef"
            data-vv-name="Account Number"
          ></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-text-field
            v-model.trim="bankDetail.sortCode"
            v-validate="'required|numeric|length:6'"
            label="Sort Code"
            required
            class="required"
            :error-messages="errors.collect('Sort Code')"
            :data-vv-scope="formRef"
            data-vv-name="Sort Code"
          ></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-text-field
            v-model.trim="bankDetail.accountHolderName"
            v-validate="'required'"
            label="Account Holder Name "
            required
            class="required"
            :error-messages="errors.collect('Account Holder Name')"
            :data-vv-scope="formRef"
            data-vv-name="Account Holder Name"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 class="is-bank-contact-different">
          <v-checkbox
            v-model.trim="isBankContactDifferent"
            color="primary"
            hide-details
            :label="`If Different from above. Account Department Information Address`"
            @change="onContactDiffChange"
          ></v-checkbox>
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model.trim="bankDetail.bankAccountContactDetail.contactName"
            v-validate="{
              rules: { required: isBankContactDifferent ? true : false },
            }"
            :disabled="!isBankContactDifferent"
            :class="isBankContactDifferent ? 'required' : ''"
            name="contactName"
            label="Contact Name"
            :error-messages="errors.collect('Contact Name')"
            :data-vv-scope="formRef"
            data-vv-name="Contact Name"
          ></v-text-field>
        </v-flex>
        <v-flex xs4>
          <PhoneNumber
            ref="contractorContactNumber"
            :passed-phone-number="bankDetail.bankAccountContactDetail.contactNumber"
            :is-validation-required="isBankContactDifferent"
            :is-disabled="!isBankContactDifferent"
            class="bank-phone-number"
            @phoneNumberUpdated="phoneNumberUpdated"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model.trim="bankDetail.bankAccountContactDetail.contactEmail"
            v-validate="{
              rules: {
                required: isBankContactDifferent ? true : false,
                email: true,
              },
            }"
            :disabled="!isBankContactDifferent"
            :class="isBankContactDifferent ? 'required' : ''"
            class="contact-email"
            label="Contact Email"
            :error-messages="errors.collect('Contact Email')"
            :data-vv-scope="formRef"
            data-vv-name="Contact Email"
            name="contactEmail"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 class="text-xs-right mt-3">
          <v-divider class="mb-2"></v-divider>
          <v-btn
            v-if="!isUserRoleContractor"
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            class="ma-0 btn-save-bank-detail"
            @click="saveBankDetail"
          >
            Save
          </v-btn>
        </v-flex>
        <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
          {{ saveSnackbarText }}
          <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
        </v-snackbar>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import BankAccountDetail, { BankAccountContactDetail } from '@/models/contractor/BankAccountDetailModel'
import Shared from '@/common/shared'
import ContractorController from '@/api/contractorController'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import store from '@/store'
import eventBus from '@/common/bus'

@Component({
  components: { PhoneNumber },
})
export default class ContractorBankDetails extends Vue {
  @Prop() private contractorId: string
  @Prop() private contractorBankDetail: BankAccountDetail
  @Prop({ default: true }) private isContractorReviewed: boolean
  private isBankContactDifferent = false
  private bankDetail: BankAccountDetail = new BankAccountDetail()
  private isLoading = false
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private formRef = 'frmBankDetail'
  private saveSnackbarText = 'Bank details saved successfully.'

  private created() {
    // bind bank details
    this.setBankDetail()
  }

  // Check if userRole is contractor
  private get isUserRoleContractor(): boolean {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail' && this.isContractorReviewed
  }

  @Watch('contractorId')
  @Watch('contractorBankDetail')
  private onContractorBankDetailChange() {
    this.setBankDetail()
    this.$validator.errors.items = []
  }

  private setBankDetail() {
    this.bankDetail = new BankAccountDetail()
    this.isBankContactDifferent = false
    if (this.contractorBankDetail) {
      this.bankDetail = JSON.parse(JSON.stringify(this.contractorBankDetail))
      if (
        this.bankDetail.bankAccountContactDetail &&
        (this.bankDetail.bankAccountContactDetail.contactName != null ||
          this.bankDetail.bankAccountContactDetail.contactNumber != null ||
          this.bankDetail.bankAccountContactDetail.contactEmail != null)
      ) {
        this.isBankContactDifferent = true
      }
    }
  }

  private async validate(): Promise<boolean> {
    const self = this
    let result = true
    let bankDetailValidation = true
    let contactNumberValidation = true
    bankDetailValidation = await self.$validator.validateAll(this.formRef)
    if (this.isBankContactDifferent) {
      const contactNumberValidationSub: any = this.$refs.contractorContactNumber as PhoneNumber
      contactNumberValidation =
        (await contactNumberValidationSub.$validator.validateAll()) && contactNumberValidationSub.isValid
    }
    result = bankDetailValidation && contactNumberValidation
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private async saveBankDetail() {
    this.validate().then((result) => {
      if (result) {
        this.isLoading = true
        ContractorController.SaveContractorBankDetail(this.bankDetail, this.contractorId)
          .then((res: boolean) => {
            if (res) {
              this.isLoading = false
              this.saveSnackbar = true
              this.$emit('updateBankDetail', this.bankDetail)
            }
          })
          .catch((err: any) => {
            eventBus.$emit('errorHandler', 'Error saving contractor bank detail, please try again.', true)
            this.isLoading = false
          })
      }
    })
  }

  private phoneNumberUpdated(phoneNumber: string): void {
    this.bankDetail.bankAccountContactDetail.contactNumber = phoneNumber
  }

  private onContactDiffChange() {
    if (!this.isBankContactDifferent) {
      this.bankDetail.bankAccountContactDetail = new BankAccountContactDetail()
    }
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
  }
}
</script>

<style scoped>
.bank-phone-number.internal-phone-number {
  padding-left: 0px;
  margin-top: 16px;
  position: relative;
}
</style>
