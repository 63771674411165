import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import IDashboardsState from '../states/interfaces/dashboards-state'
import DashboardsState from '../states/dashboards-state'
import DashboardListItemModel from '@/models/dashboards/dashboard-list-item-model'
import DashboardsController from '@/api/dashboards-controller'

export const dashboardsState: IDashboardsState = new DashboardsState()

export const dashboardsGetters: GetterTree<IDashboardsState, any> = {
  loading: (state) => state.loadingDashboards,
  loadingDashboards: (state) => state.loadingDashboards,
  dashboards: (state) => state.dashboards,
}

export const mutations: MutationTree<IDashboardsState> = {
  setLoadingDashboards: (state, loading: boolean) => {
    state.loadingDashboards = loading
  },
  setDashboards: (state, dashboards: DashboardListItemModel[]) => {
    state.dashboards = [...dashboards]
  },
}

export const actions: ActionTree<IDashboardsState, any> = {
  async retrieveDashboards({ commit }) {
    commit('setLoadingDashboards', true)
    const dashboards = await DashboardsController.GetDashboardsAsync()
    commit('setDashboards', dashboards)
    commit('setLoadingDashboards', false)
  },
}

export const dashboardsModule: Module<IDashboardsState, any> = {
  state: dashboardsState,
  actions,
  mutations,
  getters: dashboardsGetters,
  namespaced: true,
}
