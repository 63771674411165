<template>
  <div v-if="item">
    <div class="mb-2"><h4>Contractor Accepted Auto Deployed Job</h4></div>
    <div v-if="appointedContractor" class="mb-1">
      <label>Contractor: </label>
      <span>{{ appointedContractor.companyName }}</span>
    </div>
    <div v-if="item.etaFrom" class="mb-1">
      <label>ETA From: </label>
      <span>{{ getFormattedDate(item.etaFrom) }}</span>
    </div>
    <div v-if="item.etaTo" class="mb-1">
      <label>ETA To: </label>
      <span>{{ getFormattedDate(item.etaTo) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContractorAcceptAutoDeploymentJob from '@/models/contractor/ContractorAcceptAutoDeploymentJobModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import storeGetters from '@/storeGetters'
import Store from '@/store'
import Shared from '@/common/shared'
import moment from 'moment'
@Component
export default class ContractorAcceptAutoDeploymentJobLog extends Vue {
  @Prop() private requestBody: any

  private get item(): ContractorAcceptAutoDeploymentJob {
    return Object.assign(new ContractorAcceptAutoDeploymentJob(), this.requestBody)
  }
  private get appointedContractor(): ContractorAppointedModel {
    return storeGetters.getContractorAppointedDetailById(this.item.jobId, this.item.contractorAppointedId)
  }
  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }
}
</script>
