import JobHeader from '@/models/JobHeader'
import JobCount from '@/models/claim/JobCount'

export default class JobDashboardModel {
  public jobs: JobHeader[]
  public emergencyCount: [string, number]
  public totalJobCount: number
  public jobCount: JobCount

  public constructor() {
    if (!this.jobs) {
      this.jobs = []
    }
  }
}
