import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import SignalRController, { ICreateSignalRConnection } from '@/api/signalRController'
import eventBus from '@/common/bus'
import { AxiosResponse } from 'axios'
import { defineStore } from 'pinia'
import { reactive } from 'vue'

type Connection = ICreateSignalRConnection & {
  created?: Date
}

type SignalRConfiguration = {
  endpoint: string
}

export const useSignalRStore = defineStore('SignalR', () => {
  const connections = reactive<{ [key: string]: Connection | null }>({})
  let loadingConfig: Promise<AxiosResponse<SignalRConfiguration, any>> | null = null
  let endpoint: string | null = null

  function isConnectionExpired(connection: Connection) {
    if (!connection.created) {
      return true
    }

    const diff = Date.now() - connection.created.getTime()
    const seconds = Math.floor(diff / 1000)
    return seconds > connection.ttl - 300 // 5 minute skew for clock drift.
  }

  async function refreshConnection(hubName: string) {
    connections[hubName] = await SignalRController.Connect(hubName)
    if (connections[hubName] !== null) {
      connections[hubName]!.created = new Date()
    }
  }

  async function getConnection(hubName: string) {
    if (!connections[hubName] || isConnectionExpired(connections[hubName]!)) {
      await refreshConnection(hubName)
    }

    return connections[hubName]
  }

  async function getConfig() {
    try {
      // Separate the Promise to avoid dupe network requests
      if (!loadingConfig) {
        loadingConfig = CallCentreApi.get<SignalRConfiguration>('signalr/configuration')
      }
      const config = await loadingConfig
      endpoint = config.data.endpoint
    } catch (err) {
      eventBus.$emit('errorHandler', 'Failed to get SignalR configuration', false, '', err)
    }
  }

  return {
    connections,
    refreshConnection,

    getHubUrl: async (hubName: string) => {
      if (!endpoint) {
        await getConfig()
      }

      return `${endpoint}/client/?hub=${hubName}`
    },

    getToken: async (hubName: string) => {
      const connection = await getConnection(hubName)
      if (connection === null) {
        return ''
      }

      return connection.accessToken
    },
  }
})
