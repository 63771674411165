<template>
  <div v-if="getWorkers" :id="tableName" class="dashboard">
    <v-container fluid pt-1 px-0>
      <v-layout wrap>
        <v-flex xs6 request-info>
          <h3 v-if="!isFromMainDashBoard" class="pt-4">Manager Supervisor</h3>
        </v-flex>
        <v-flex xs6>
          <div class="search-supervisor">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              class="mt-0 pt-0"
            ></v-text-field>
          </div>
        </v-flex>
        <v-flex xs12 mt-2 class="gridView">
          <div class="supervisor-list">
            <v-data-table
              :headers="headers"
              :items="getAllWorker"
              class="elevation-1"
              :search="search"
              :custom-filter="customFilter"
              :pagination.sync="pagination"
            >
              <template slot="headers" slot-scope="props">
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="[
                      'column sortable',
                      pagination.descending ? 'desc' : 'asc',
                      header.value === pagination.sortBy ? 'active' : '',
                    ]"
                    @click="changeSort(header.value)"
                  >
                    {{ header.text }}
                    <v-icon small>arrow_upward</v-icon>
                  </th>
                  <th
                    v-if="teamList.length > 0"
                    :class="[
                      'column sortable teamname-supervisor',
                      pagination.descending ? 'desc' : 'asc',
                      'teamname' === pagination.sortBy ? 'active' : '',
                    ]"
                  >
                    <v-icon small @click="changeSort('teamName')">arrow_upward</v-icon>
                    <v-select
                      v-model="selectedTeamName"
                      :items="teamList"
                      placeholder="Team Name"
                      item-text="teamName"
                      item-value="teamName"
                    ></v-select>
                  </th>
                  <th v-else>Team Name</th>
                </tr>
              </template>
              <template slot="items" slot-scope="props">
                <tr :active="props.selected" @click="props.selected = !props.selected">
                  <td class="team-member-img" :class="props.item.activityName === 'Offline' ? 'b-grey' : ''">
                    <img :src="getImageUrl(props.item.attributes.userId)" alt />
                  </td>
                  <td>{{ props.item.attributes.firstName }}</td>
                  <td>{{ props.item.attributes.lastName }}</td>
                  <td>{{ props.item.activityName }}</td>
                  <td class="justify-center layout px-0">
                    <div
                      v-if="props.item.activityName === 'Busy' && !onCall && !supervisorCallDetails"
                      class="action-btn-team"
                    >
                      <v-btn small @click.native="joinConference(props.item.id, false)">Barge</v-btn>
                      <v-btn small @click.native="joinConference(props.item.id, true)">Listen</v-btn>
                      <v-btn small @click.native="coach(props.item.id)">Coach</v-btn>
                    </div>
                    <div
                      v-if="
                        onCall &&
                        supervisorCallDetails &&
                        supervisorCallDetails.incidentManagerWorkerSid == props.item.id
                      "
                      class="action-btn-team"
                    >
                      <v-btn small @click.native="leaveConference">Leave</v-btn>
                    </div>
                    <div v-else-if="props.item.activityName === 'Idle' || props.item.activityName === 'BackOffice'">
                      <v-btn
                        v-if="props.item.activityName === 'Idle'"
                        @click="updateWorkerQueueStatus(props.item.id, false)"
                      >
                        Take out of Queue
                      </v-btn>
                      <v-btn
                        v-if="props.item.activityName === 'BackOffice'"
                        @click="updateWorkerQueueStatus(props.item.id, true)"
                      >
                        Put back into Queue
                      </v-btn>
                    </div>
                  </td>
                  <td>
                    <star-rating
                      :star-size="14"
                      :rating="props.item.attributes.rating"
                      :max-rating="5"
                      active-color="#e7711b"
                      :increment="0.1"
                      :fixed-points="1"
                      :read-only="true"
                    ></star-rating>
                  </td>
                  <td>{{ props.item.attributes.teamName }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import StarRating from 'vue-star-rating'
import DashboardController from '@/api/dashboardController'
import { UpdateSurge } from '@/signalr/twilioEventModels/events'
import Store from '@/store'
import { Worker, WorkerWithAdditions } from '@/components/dashboard/worker'
import TwilioController from '@/api/twilioController'
import UserModel from '@/models/user/UserModel'
import storeGetters from '@/storeGetters'
import SupervisorCall from '@/models/twilio/SupervisorCall'
import eventBus from '@/common/bus'

@Component({
  components: { StarRating },
})
export default class ManagerSupervisorTable extends Vue {
  private search = ''
  private pagination: any = {}
  private headers: any = []
  private sortable = false
  private items: any = [{ title: 'Take Out of Queue' }]
  private teamList: string[] = []
  private isSorting = false
  private selectedTeamName = 'All'
  private allWorkers: WorkerWithAdditions[] = []
  private activeIndex = 0
  private onCall = false
  private supervisorCallDetails: SupervisorCall | null = null
  @Prop() private isFromMainDashBoard: boolean
  @Prop() private tableName: string
  @Prop() private workers: WorkerWithAdditions[]

  public async created() {
    this.headers = [
      { text: '', sortable: false, value: 'userimage', class: 'team-img-head' },
      { text: 'First Name', value: 'attributes.firstName', align: 'left' },
      { text: 'Last Name', value: 'attributes.lastName', align: 'left' },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        class: 'team-member-status',
      },
      { text: 'Actions', sortable: false, value: 'action', align: 'left' },
      { text: 'Rating', value: 'attributes.rating', align: 'left' },
    ]
    this.pagination.rowsPerPage = 10
  }

  private get getWorkers(): WorkerWithAdditions[] {
    if (this.workers) {
      const workers = this.workers
      workers.forEach((item: WorkerWithAdditions) => {
        item.attributes.firstName = item.friendlyName.split(' ') ? item.friendlyName.split(' ')[0] : item.friendlyName
        item.attributes.lastName = item.friendlyName.split(' ') ? item.friendlyName.split(' ')[1] : ''
        // add check for undefined
        if (item.attributes.skills) {
          item.attributes.rating =
            Object.keys(item.attributes.skills).reduce((acc, value) => acc + item.attributes.skills[value], 0) / 6
        }
      })
      return this.updateWorkerTable(workers)
    }
    return []
  }

  private get getAllWorker() {
    if (this.selectedTeamName === 'All') {
      return this.allWorkers.filter((e: any) => e.activityName !== 'Offline')
    } else {
      return this.allWorkers.filter(
        (e: any) => e.attributes.teamName === this.selectedTeamName && e.activityName !== 'Offline'
      )
    }
  }

  @Watch('selectedTeamName')
  private onTeachChange() {
    return this.getAllWorker
  }

  private updateWorkerTable(workers: WorkerWithAdditions[]) {
    this.allWorkers = workers
    const teamList: string[] = []
    this.workers.filter((worker: WorkerWithAdditions) => {
      if (worker.attributes.teamName && teamList.indexOf(worker.attributes.teamName) === -1) {
        teamList.push(worker.attributes.teamName)
      }
    })
    this.teamList = teamList
    if (this.teamList) {
      this.teamList.push('All')
      this.teamList = this.teamList.sort((a, b) => {
        if (a === 'All') {
          return -1
        }
        return 1
      })
    }
    return this.getAllWorker
  }

  private get users(): UserModel[] {
    return storeGetters.getUsers()
  }

  private getImageUrl(userId: string): string {
    if (userId) {
      const user = this.users.find((e) => e.id === userId)
      if (user) {
        return user.profileThumbnailUrlWithUnknownFallback
      }
    }
    return '/img/unknownuser.png'
  }

  private customFilter(items, search, filter) {
    if (search !== '') {
      search = search.toString().toLowerCase()
      const attributes = 'attributes'
      return items.filter(
        (row) =>
          filter(row[attributes].firstName, search) ||
          filter(row[attributes].lastName, search) ||
          filter(row[attributes].teamName, search)
      )
    } else {
      return items
    }
  }

  private changeSort(column) {
    if (column !== 'action' && column !== 'userimage') {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
    if (column === 'teamName') {
      this.isSorting = !this.isSorting
    }
  }

  private updateWorkerQueueStatus(workerSid: string, inQueue: boolean): void {
    TwilioController.UpdateWorkerQueueStatus(workerSid, inQueue)
  }

  private async joinConference(workerSid: string, muted = true) {
    const self = this
    if (workerSid) {
      if (muted && this.isUserRoleSupervisor) {
        // supervisor is logged in and joined conference call
        eventBus.$emit('supervisorJoinedConferenceCall')
      }
      TwilioController.JoinConference(workerSid, muted)
        .then((supervisorCall) => {
          if (supervisorCall) {
            self.onCall = true
            self.supervisorCallDetails = supervisorCall
          }
        })
        .catch((error) => {
          // eslint:disable-next-line:no-console
          console.log(error)
        })
    }
  }

  private async coach(workerSid: string) {
    const self = this
    if (workerSid) {
      if (this.isUserRoleSupervisor) {
        // supervisor is logged in and joined conference call
        eventBus.$emit('supervisorJoinedConferenceCall')
      }
      TwilioController.Coach(workerSid)
        .then((supervisorCall) => {
          if (supervisorCall) {
            self.onCall = true
            self.supervisorCallDetails = supervisorCall
          }
        })
        .catch((error) => {
          // eslint:disable-next-line:no-console
          console.log(error)
        })
    }
  }

  private async leaveConference() {
    const self = this
    if (this.supervisorCallDetails && this.supervisorCallDetails.callSid && this.supervisorCallDetails.conferenceSid) {
      TwilioController.LeaveConference(this.supervisorCallDetails.callSid, this.supervisorCallDetails.conferenceSid)
        .then(() => {
          self.supervisorCallDetails = null
        })
        .catch((error) => {
          // eslint:disable-next-line:no-console
          console.log(error)
        })
    }
  }

  private get isUserRoleSupervisor(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor'
    )
  }
}
</script>

<style scoped>
.action-btn-team >>> .v-btn {
  min-width: 56px;
  max-width: 56px;
  font-size: 12px;
}
.teamname-supervisor {
  position: relative;
}
.teamname-supervisor >>> .v-icon--link:first-child {
  transition: all 0.3s linear;
  position: absolute;
  left: -18px;
  top: 27px;
  opacity: 0;
  cursor: pointer;
}
.team-member-img {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.team-member-img img {
  max-height: 45px;
  max-width: 45px;
  border: 3px solid #4caf50;
  border-radius: 50%;
}
.team-member-img.b-red img {
  border-color: #cf1e60;
}
.team-member-img.b-grey img {
  border-color: #808080;
}
.team-member-img.b-pink img {
  border-color: #ff8a65;
}
.gridView >>> .team-member-status {
  min-width: 160px;
}
.gridView >>> .team-img-head {
  max-width: 50px;
  width: 10px;
}
.gridView {
  border: 0px;
}
.gridView >>> tbody tr:first-child td {
  padding-top: 5px !important;
  padding: 0px 10px;
}
.gridView >>> tbody tr td:first-child {
  padding-top: 5px !important;
  padding: 0px 20px 0px 10px;
}
.gridView >>> tbody tr:first-child td.action-btn-team {
  padding-top: 7px !important;
}
.teamname-supervisor {
  width: 140px;
  min-width: 140px;
}
.gridView tbody tr td:last-child {
  padding-left: 10px;
}
/* .request-info>>>.input-group.input-group--selection-controls .input-group__input, */
/* .request-info>>>.input-group.input-group--selection-controls {display: inline-block;width: auto;vertical-align: bottom;}
.request-info>>>.input-group.input-group--selection-controls.switch .input-group--selection-controls__container {top: 20px;} */
.supervisor-list {
  max-height: 705px;
}
.vue-star-rating {
  padding-left: 8px;
}
.gridView >>> .vue-star-rating-rating-text {
  margin-top: -2px;
}
.teamname-supervisor >>> input {
  font-size: 14px;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.supervisor-list table td button {
  font-size: 12px;
  width: 100%;
  max-width: 200px;
}
.supervisor-list >>> table > thead > tr > th:nth-child(1) {
  width: 5px;
}
.supervisor-list >>> table > thead > tr > th:nth-last-child(3) {
  width: 5px;
}
.supervisor-list >>> table tbody td:not(:first-child) {
  padding-left: 0;
}
.supervisor-list >>> table > thead > tr > th:nth-last-child(2),
.supervisor-list >>> table > tbody > tr > td:nth-last-child(2) {
  width: 5px;
  padding-left: 10px !important;
}
.supervisor-list >>> table > thead > tr > th:nth-last-child(1),
.supervisor-list >>> table > tbody > tr > td:nth-last-child(1) {
  padding-left: 10px !important;
}
.supervisor-list >>> table > tbody > tr > td.layout > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.supervisor-list >>> table > tbody > tr > td.layout .v-btn {
  margin: 0;
}
.supervisor-list >>> table > tbody > tr:first-child > td.layout {
  padding-top: 5px !important;
}
</style>
