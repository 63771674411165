<template>
  <div>
    <div class="mb-2">
      <h4>{{ request.requestType }}</h4>
    </div>
    <div v-if="request.requestBody" class="mb-1">
      <label>Request Body: </label>
      <span>{{ request.requestBody }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DefaultRequestLog extends Vue {
  @Prop() private request: any
}
</script>

<style scoped></style>
