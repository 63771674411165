import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { IDelayState } from '../states/interfaces/delay-state'
import DelayState from '../states/delay-state'
import DelayRule from '@/models/delay/delay-rule'
import DelayController from '@/api/delayController'

export const delayState: IDelayState = new DelayState()

export const delayGetters: GetterTree<IDelayState, any> = {
  availableDelayRules: (state) => state.delayRules,
}

export const mutations: MutationTree<IDelayState> = {
  setDelayRules: (state, delayRules: DelayRule[]) => {
    state.delayRules = delayRules
  },
}

export const actions: ActionTree<IDelayState, any> = {
  async submitRetrieveDelayRules({ commit }) {
    DelayController.GetDelayRules().then((delayRules: DelayRule[] | null) => {
      if (delayRules) {
        commit('setDelayRules', delayRules)
      }
    })
  },
}

export const delayModule: Module<IDelayState, any> = {
  state: delayState,
  actions,
  mutations,
  getters: delayGetters,
  namespaced: true,
}
