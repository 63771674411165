<template>
  <div v-if="usingUDEDashboardV2">
    <UndeployedEmergencyTableV2 />
  </div>

  <div v-else>
    <UndeployedEmergencyTable
      v-if="ready"
      :items="jobItems"
      table-name="undeployedEmergencyDashboard"
      :is-loading="isLoading"
      :is-from-main-dash-board="true"
    ></UndeployedEmergencyTable>
    <div v-else>
      <v-progress-circular
        class="loading-spinner"
        :width="2"
        :size="50"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DashboardController from '@/api/dashboardController'
import JobHeader from '@/models/JobHeader'
import UndeployedEmergencyTable from '@/components/tables/UndeployedEmergencyTable.vue'
import UndeployedEmergencyTableV2 from '@/components/tables/UndeployedEmergencyTableV2.vue'
import eventBus from '@/common/bus'

@Component({
  components: { UndeployedEmergencyTable, UndeployedEmergencyTableV2 },
})
export default class UndeployedEmergencyDashboard extends Vue {
  public isLoading = false
  public ready = false
  public jobItems: JobHeader[] = []

  private created() {
    if (!this.usingUDEDashboardV2) {
      this.getJobDashboardData()
    }
  }

  public get usingUDEDashboardV2() {
    return !!this.$ld.variation('wip-439-ude-escalation-reason')
  }

  // Get JobDashboard Data
  private getJobDashboardData() {
    this.isLoading = true
    DashboardController.GetUndeployedJobEmergencies()
      .then((res: JobHeader[]) => {
        if (res) {
          this.jobItems = res.filter(
            (x) => x.undeployedEmergencies && x.undeployedEmergencies.length > 0 && x.undeployedEmergencies[0]
          )
          this.isLoading = false
          this.ready = true
        } else {
          this.isLoading = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading undeployed emergency list, please try again', true)
        this.isLoading = false
      })
  }
}
</script>
