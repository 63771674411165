<template>
  <v-card v-if="jobId" class="pr-1 elevation-0 pt-3 pl-2 pb-4 jobview" :class="jobId ? 'jobview-show' : ''">
    <span class="jobview-hide d-inline-block">
      <v-btn class="elevation-1 secondary white--text" @click="$emit('closeJobView')">
        <v-icon large color="white">keyboard_arrow_down</v-icon>
        {{ btnText }}
      </v-btn>
    </span>
    <JobView :job-id="jobId" :record-id="recordId" :request-type="requestType" :record-type="recordType"></JobView>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import JobView from '@/views/JobView.vue'
import Shared from '@/common/shared'

@Component({
  components: { JobView },
})
export default class PartialJobView extends Vue {
  @Prop() private jobId: string
  @Prop({ default: 'Back to Dashboard' }) private btnText: string
  @Prop({ default: '' }) private recordId: string // for engineerRequest or complaint Id
  @Prop({ default: '' }) private requestType: string // for engineerRequest type
  @Prop({ default: '' }) private recordType: string // for complaint record type

  @Watch('jobId')
  private onJobIdChange() {
    setTimeout(() => {
      Shared.addOverFlowHiddenToDOM(this.$el.parentElement, 'jobview-show')
    }, 0)
  }
}
</script>

<style>
.show-jobs .v-menu__content.preferred-contact-menu {
  z-index: 205 !important;
}
.v-menu__content.preferred-contact-menu {
  z-index: 201 !important;
}
.jobview .main-inner-content {
  padding-bottom: 0;
}
.jobview-show .fullScreen {
  z-index: 203 !important;
  height: calc(100% - 65px) !important;
  top: 65px;
}
.jobview-show .fullScreenIcon {
  z-index: 204 !important;
}
</style>

<style scoped>
.jobview {
  transition: 0.3s all linear;
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  bottom: -30px;
  z-index: 200;
}
/* .jobview-show{ right: 15px; left:20px !important; } */
.jobview-show {
  top: 65px;
  left: 0;
}
.jobview-hide {
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0px;
}
.jobview-hide .v-btn {
  position: absolute;
  left: -94px;
  top: 160px;
  z-index: 10;
  transform: rotate(90deg);
  padding-top: 0px !important;
  outline: solid 5px rgba(252, 81, 133, 0.5);
  margin-right: 6px;
  animation-timing-function: linear;
  -webkit-animation: mymove 1.5s infinite;
  animation: mymove 1.5s infinite;
  animation-fill-mode: both;
}
.jobview-hide >>> .v-btn__content {
  padding-left: 5px;
}
.dashboard .jobview.jobview-show >>> .claim-btn-content,
.dashboard .jobview.jobview-show >>> .claim-btn-content .v-card,
.dashboard .jobview.jobview-show >>> .mapsidebar-content.v-card,
.show-jobs .jobview {
  z-index: 202 !important;
}

@media only screen and (max-width: 1300px) {
  .dashboard .jobview.jobview-show >>> .claim-btn-content {
    right: 25.3%;
    left: 38%;
  }
}

@-webkit-keyframes mymove {
  0% {
    outline-width: 1px;
  }
  50% {
    outline-width: 5px;
  }
  100% {
    outline-width: 1px;
  }
}

/* Standard syntax */
@keyframes mymove {
  0% {
    outline-width: 1px;
  }
  50% {
    outline-width: 5px;
  }
  100% {
    outline-width: 1px;
  }
}
</style>
