import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { IClientState } from '../states/interfaces/client-state'
import ClientState from '../states/client-state'
import InsurerModel from '@/models/policy/InsurerModel'
import InsurerController from '@/api/InsurerController'
import PolicyScheduleController from '@/api/policyScheduleController'
import PolicyModel from '@/models/policy/PolicyModel'

export const clientState: IClientState = new ClientState()

export const clientGetters: GetterTree<IClientState, any> = {
  loading: (state) => state.loading,
  clients: (state) => state.clients,
  policies: (state) => state.policies,
  selectedClientId: (state) => state.selectedClientId,
  selectedPolicyId: (state) => state.selectedPolicyId,
  selectedClient: (state) => state.clients.find(c => c.id === state.selectedClientId),
}

export const mutations: MutationTree<IClientState> = {
  setClients: (state, clients: InsurerModel[]) => {
    state.clients = clients || []
  },
  setPolicies: (state, policies: PolicyModel[]) => {
    state.policies = policies || []
  },
  setSelectedClient: (state, clientId: number | null) => {
    state.selectedClientId = clientId
  },
  setSelectedPolicy: (state, policyId: number | null) => {
    state.selectedPolicyId = policyId
  },
  setLoading: (state, loading: boolean) => {
    state.loading = loading
  },
}

export const actions: ActionTree<IClientState, any> = {
  async submitRetrieveClients({ commit, state }) {
    commit('setLoading', true)
    const clients = await InsurerController.GetInsurerList()
    commit('setClients', clients)
    if (!state.selectedClientId) {
      commit('setSelectedClient', clients[0].id)
    }
    commit('setLoading', false)
  },
  async submitSetSelectedClient({ commit, dispatch }, clientId: number) {
    commit('setLoading', true)
    commit('setSelectedClient', clientId)
    dispatch('submitRetrievePolicies')
    commit('setLoading', false)
  },
  async submitSetSelectedPolicy({ commit }, policyId: number) {
    commit('setSelectedPolicy', policyId)
  },
  async submitRetrievePolicies({ commit, state }) {
    if (!state.selectedClientId) {
      return
    }
    const policies = await PolicyScheduleController.GetPoliciesByInsurerId(state.selectedClientId)
    commit('setPolicies', policies)
  },
}

export const clientModule: Module<IClientState, any> = {
  state: clientState,
  actions,
  mutations,
  getters: clientGetters,
  namespaced: true,
}
