import Vue, { VNodeDirective, VNode } from 'vue'

export interface MouseHoldConfig {
  // Number of tick intervals before "held" is triggered
  ticksBeforeHeld?: number
  // Time in ms between each tick event
  tickInterval?: number
  // Handler for the held event
  held?: () => void
  // Handler for the released event
  released?: () => void
  // Handler for the ticked event
  ticked?: () => void
}

const events = Symbol()

Vue.directive('mouse-hold', {
  bind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
    if (vnode.context) {
      let interval: number | undefined
      const config: MouseHoldConfig = binding.value
      let triggered = false
      const mouseDown = () => {
        let tick = 0
        if (interval) {
          window.clearInterval(interval)
        }
        interval = window.setInterval(() => {
          if (tick++ === (config.ticksBeforeHeld || 5)) {
            if (config.held) {
              config.held()
            }
            window.clearInterval(interval!)
          } else {
            if (config.ticked) {
              config.ticked()
            }
          }
          triggered = true
        }, config.tickInterval || 100)
      }
      const mouseUp = () => {
        if (interval) {
          window.clearInterval(interval)
          if (triggered) {
            triggered = false
            if (config.released) {
              window.setImmediate(() => {
                config.released!()
              })
            }
          }
        }
      }
      const mouseEnter = (e: MouseEvent) => {
        if (e.buttons === 1) {
          mouseDown()
        }
      }
      el[events] = {
        mousedown: mouseDown,
        mouseup: mouseUp,
        mouseleave: mouseUp,
        mouseenter: mouseEnter,
      }
      el.addEventListener('mousedown', mouseDown)
      el.addEventListener('mouseup', mouseUp)
      el.addEventListener('mouseleave', mouseUp)
      el.addEventListener('mouseenter', mouseEnter)
    }
  },
  unbind(el: HTMLElement) {
    if (el[events]) {
      for (const evt of Object.keys(el[events])) {
        el.removeEventListener(evt, el[events])
      }
    }
  },
})
