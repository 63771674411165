<template>
  <div>
    <v-form>
      <v-card class="mb-3 enablement">
        <v-card-title class="pb-0">
          <v-layout>
            <v-flex xs11>
              <h3>Enablement</h3>
            </v-flex>
            <v-flex xs1 text-xs-right>
              <v-icon v-if="syncing" class="processing-icon" medium>sync</v-icon>
              <v-icon v-else-if="isEnablementCompleted" medium dark color="success">check_circle</v-icon>
              <v-icon v-else medium dark color="orange">info</v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-container class="pt-0">
          <v-textarea
            v-model="enablementNote"
            v-validate="'required'"
            label="Please enter the enablement notes"
            data-vv-name="Enablement Notes"
            :error-messages="errors.collect('Enablement Notes')"
            :disabled="disableControls"
            @focus="editStarted"
          ></v-textarea>
        </v-container>
        <v-card-actions xs12>
          <v-spacer />
          <v-btn
            v-if="isUserRoleSupervisor || !isEnablementCompleted || isUserRoleClientManager"
            class="update-button"
            type="submit"
            :class="!saving ? 'green white--text' : 'grey'"
            :loading="saving"
            :disabled="saving || syncing"
            @click.prevent="saveNotes"
          >
            update
          </v-btn>
          <v-btn
            v-if="!isEnablementCompleted"
            class="confirm-button"
            type="submit"
            :class="!saving ? 'green white--text' : 'grey'"
            :loading="saving"
            :disabled="saving || syncing"
            @click.prevent="editComplete"
          >
            Complete
          </v-btn>
        </v-card-actions>
        <v-progress-linear
          v-show="saving"
          color="primary"
          height="3"
          value="15"
          :indeterminate="true"
        ></v-progress-linear>
      </v-card>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SiteInvestigationController from '@/api/siteInvestigationController'
import ChangeSIEnablementModel from '@/models/requests/ChangeSIEnablementModel'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'
import TimeLineItemBase from '../TimeLineItemBase'
import store from '@/store'

@Component
export default class EnablementNotesPreview extends TimeLineItemBase {
  private inEdit = false
  private saving = false
  private syncing = false

  private enablementNote = ''

  private created() {
    this.setLocalAnswer()
  }

  private get isEnablementCompleted(): boolean {
    return this.job ? this.job.enablementCompletedAt !== null : false
  }

  private get enablementNotes(): string {
    return this.job ? this.job.enablementNotes : ''
  }

  private get disableControls(): boolean {
    const syncingSaving = this.saving || this.syncing
    return this.isUserRoleSupervisor || this.isUserRoleClientManager
      ? syncingSaving
      : syncingSaving || this.isEnablementCompleted
  }

  private get isUserRoleSupervisor(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor'
    )
  }

  @Watch('syncing')
  private syncingChanged(): void {
    this.$emit('setSyncing', this.syncing)
  }

  @Watch('job.enablementNotes')
  @Watch('job.enablementCompletedAt')
  private jobChanged(): void {
    if (!this.inEdit) {
      // user not editing (got new value so stop syncing)
      this.syncing = false
      if (this.enablementNote !== this.enablementNotes) {
        this.setLocalAnswer()
      }
    }
  }

  // set local answer as provided in job
  private setLocalAnswer(): void {
    if (this.job) {
      this.enablementNote = this.enablementNotes
    }
  }

  // on answer edit started
  private editStarted(): void {
    this.inEdit = true
    this.syncing = false
  }

  // on answer edit complete
  private async editComplete() {
    const validate: boolean = await this.$validator.validateAll()
    if (!validate) {
      return
    }

    this.inEdit = false
    this.saving = true

    // set local answer to request answer model
    const req = new ChangeSIEnablementModel()
    req.jobId = this.jobId
    req.completionNotes = this.enablementNote
    this.SaveEnablement(req)
  }

  private async saveNotes() {
    const validate: boolean = await this.$validator.validateAll()
    if (!validate) {
      return
    }

    if (this.enablementNotes === this.enablementNote) {
      return
    }

    this.inEdit = false
    this.saving = true

    // set local answer to request answer model
    const req = new ChangeSIEnablementModel()
    req.jobId = this.jobId
    req.requirementNotes = this.enablementNote
    this.SaveEnablement(req)
  }

  // save Enablement notes
  private SaveEnablement(req: ChangeSIEnablementModel) {
    if (this.isEnablementCompleted) {
      this.saving = false
      return
    }

    req.enablementRequired = true
    SiteInvestigationController.Enablement(req)
      .then((res: boolean) => {
        if (!res) {
          eventBus.$emit('errorHandler', 'failed to save enablement notes!')
        } else {
          // save request submitted, set syncing to true until we get new value.
          this.syncing = true
        }
        this.saving = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', err, false)
        this.saving = false
      })
  }

  private get isUserRoleClientManager(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }
}
</script>

<style scoped>
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.enablement >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
