<template>
  <div>
    <div class="mb-2">
      <h4>Update Gas Safety For Job Visit</h4>
    </div>
    <div v-if="engineerName" class="mb-1">
      <label>Engineer: </label>
      <span>{{ engineerName }}</span>
    </div>
    <div class="mb-1">
      <label>Gas Safety Check Complete: </label>
      <span>{{ requestBody.isGasSafetyCheckComplete ? 'Yes' : 'No' }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class UpdateGasSafetyForJobLog extends Vue {
  @Prop() private requestBody: any

  private get engineerName(): string | null {
    return this.$store.getters['jobRequestModule/engineerName'](this.requestBody.id)
  }
}
</script>
