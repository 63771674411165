<template>
  <div>
    <div class="mb-2"><h4>Update Contractor Invoice Detail</h4></div>
    <div v-if="contractorCompanyName" class="mb-1">
      <label>Contractor: </label>
      <span>{{ contractorCompanyName }}</span>
    </div>
    <div v-if="contractorInvoiceStatus" class="mb-1">
      <label>Status: </label>
      <span>{{ contractorInvoiceStatus }}</span>
    </div>
    <div v-if="item.disputedReason" class="mb-1">
      <label>Dispute Reason: </label>
      <span>{{ item.disputedReason }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import UpdateContractorInvoiceDetailStatusModel from '@/models/requests/UpdateContractorInvoiceDetailStatusModel'
import { ContractorInvoiceStatus } from '@/common/enums'
import RequestedJob from '@/models/requests/base/requested-job'

@Component
export default class UpdateContractorInvoiceDetailLog extends Vue {
  @Prop() private requestBody: any

  private get item(): UpdateContractorInvoiceDetailStatusModel {
    return Object.assign(new UpdateContractorInvoiceDetailStatusModel(), this.requestBody)
  }

  private get requestedJob(): RequestedJob | null {
    return this.$store.getters['jobRequestModule/requestedJob']
  }

  private get contractorInvoiceStatus() {
    const isInReview = this.item.status === ContractorInvoiceStatus[ContractorInvoiceStatus.ToReview]
    return isInReview ? 'To Review' : this.item.status
  }

  private get contractorCompanyName(): string {
    if (this.item && this.requestedJob && this.item.contractorId) {
      const contractorDetail = this.requestedJob.contractorAppointedDetails.find(
        (e) => e.contractorId === this.item.contractorId
      )
      return contractorDetail ? contractorDetail.companyName : ''
    }
    return ''
  }
}
</script>
