import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import { DocumentTemplateType } from '@/common/enums'

export default class SMSModel extends CosmosJobsDbRecordBase {
  public direction: string
  public to: string
  public from: string
  public body: string
  public status: string
  public dateSent: Date
  public documentTemplateType: DocumentTemplateType
  public sentToNominee: boolean
}
