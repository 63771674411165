<template>
  <div class="pictureUploadPreview inner-content">
    <PictureUpload :job-id="jobId" uploaded-by="Customer" :picture-list="clientPictureList"></PictureUpload>
    <PictureUpload
      :job-id="jobId"
      uploaded-by="PolicyHolderMissing"
      :picture-list="policyHolderNotPresentPicture"
    ></PictureUpload>
    <PictureUpload
      :job-id="jobId"
      uploaded-by="EngineerBeforeJob"
      :picture-list="engineerBeforeJobPictureList"
    ></PictureUpload>
    <PictureUpload
      :job-id="jobId"
      uploaded-by="DuringJobPhotos"
      :picture-list="engineerDuringJobPictureList"
    ></PictureUpload>
    <PictureUpload
      :job-id="jobId"
      uploaded-by="EngineerAfterJob"
      :picture-list="engineerAfterJobPictureList"
    ></PictureUpload>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import storeGetters from '@/storeGetters'
import PictureUpload from '@/components/timeline/PictureUpload.vue'

@Component({
  components: { PictureUpload },
})
export default class PictureUploadPreview extends TimeLineItemBase {
  private get items(): PictureUploadModel[] {
    if (this.jobId) {
      return storeGetters.getClaimPictures(this.jobId)
    }
    return []
  }

  private get clientPictureList(): PictureUploadModel[] {
    return this.items.filter((a: PictureUploadModel) => a.uploadedBy === 'Customer')
  }

  private get engineerBeforeJobPictureList(): PictureUploadModel[] {
    return this.items.filter((a: PictureUploadModel) => a.uploadedBy === 'EngineerBeforeJob')
  }

  private get engineerAfterJobPictureList(): PictureUploadModel[] {
    return this.items.filter((a: PictureUploadModel) => a.uploadedBy === 'EngineerAfterJob')
  }

  private get policyHolderNotPresentPicture(): PictureUploadModel[] {
    return this.items.filter((a: PictureUploadModel) => a.uploadedBy === 'PolicyHolderMissing')
  }

  private get engineerDuringJobPictureList(): PictureUploadModel[] {
    return this.items.filter((a: PictureUploadModel) => a.uploadedBy === 'DuringJobPhotos')
  }
}
</script>

<style scoped>
.picture-craousel {
  position: relative;
}
.img-block {
  border: 1px solid #ededed;
  margin: 0px 5px;
  text-align: center;
  cursor: pointer;
}
.img-block img {
  height: 100px;
  width: 150px;
  cursor: pointer;
  object-fit: contain;
}
.img-title {
  font-weight: 600;
  font-size: 12px;
}
.picture-craousel >>> .carousel__right .v-btn .v-btn__content .v-icon,
.picture-craousel >>> .carousel__left .v-btn .v-btn__content .v-icon {
  color: #c9004b;
}
.picture-craousel >>> .carousel {
  padding: 0px 50px;
}
.craousel-dialog-close {
  top: 5px;
}
</style>
