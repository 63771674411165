import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Callback from '@/views/Callback.vue'
import JobView from '@/views/JobView.vue'
import NotFound from '@/views/NotFound.vue'
import UnauthorizedView from '@/views/UnauthorizedView.vue'
import Users from '@/views/Users.vue'
import DocumentTemplate from '@/views/DocumentTemplate.vue'
import Contractor from '@/views/Contractor.vue'
import TradeManagement from '@/views/TradeManagement.vue'
import TwilioDashboard from '@/views/TwilioDashboard.vue'
import ComplaintTracker from '@/views/ComplaintTracker.vue'
import ManagerSupervisorDashboard from '@/views/ManagerSupervisorDashboard.vue'
import ClientManagement from '@/views/ClientManagement.vue'
import JobDashboard from '@/views/JobDashboard.vue'
import UnlinkedPhoneCallsDashboard from '@/views/UnlinkedPhoneCallsDashboard.vue'
import IncidentManagerDashboard from '@/views/IncidentManagerDashboard.vue'
import UndeployedEmergencyDashboard from '@/views/UndeployedEmergencyDashboard.vue'
import NetworkManagerDashboard from '@/views/NetworkManagerDashboard.vue'
import RouterAuth from '@/router-auth'
import ThirdPartyDashboard from '@/views/ThirdPartyDashboard.vue'
import QueueDashboard from '@/views/QueueDashboard.vue'
import VIPCustomerList from '@/views/VIPCustomerList.vue'
import DynamicDashboard from '@/views/DynamicDashboard.vue'
import MaterialManagement from '@/views/MaterialManagement.vue'
import OutstandingJobDashboard from '@/views/OutstandingJobDashboard.vue'
import EngineerRequestDashboard from '@/views/EngineerRequestDashboard.vue'
import CTPDashboard from '@/views/CTPDashboard.vue'
import SIJobDashboard from '@/views/SIJobDashboard.vue'
import ContractorPODashboard from '@/views/ContractorPODashboard.vue'
import ClientInvoiceDashboard from '@/views/ClientInvoiceDashboard.vue'
import ClientReportsDashboard from '@/views/ClientReportsDashboard.vue'
import ContractorSignup from '@/views/ContractorSignup.vue'
import ContractorRegistration from '@/views/ContractorRegistration.vue'
import MissedJobsDashboard from '@/views/MissedJobsDashboard.vue'
import USJobDashboard from '@/views/USJobDashboard.vue'
import ClientInvoiceDashboardSI from '@/views/ClientInvoiceDashboardSI.vue'
import CookieManagement from '@/views/cookies/CookieManagement.vue'
import RegionManagement from '@/views/RegionManagement.vue'
import { SearchType } from '@/common/enums'
import AgentsDashboard from '@/views/AgentsDashboard.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/job/:jobId',
      name: 'job',
      component: JobView,
      props: true,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
      },
    },
    {
      path: '/job/:jobId/:engineerRequestId?/:engineerRequestType?',
      name: 'jobWithEngineerRequestId',
      component: JobView,
      props: true,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
      },
    },
    {
      path: '/identity/callback',
      name: 'identityCallback',
      component: Callback,
      meta: {
        isOidcCallback: true,
        isPublic: true,
      },
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'Supervisor'],
      },
    },
    {
      path: '/vipCustomer',
      name: 'vipCustomer',
      component: VIPCustomerList,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator'],
      },
    },
    {
      path: '/documenttemplate/',
      name: 'documentTemplate',
      component: DocumentTemplate,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator'],
      },
    },
    {
      path: '/contractorManagement/',
      name: 'contractorManagement',
      component: Contractor,
      meta: {
        allowedDetailRecordTypes: ['UserDetail', 'EngineerDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'NetworkManager'],
        showSearchBar: true,
        searchType: SearchType.ContractorJobs,
      },
    },
    {
      path: '/contractorManagement/:contractorId/:contractorAppointedDetailId?',
      name: 'contractorManagementWithId',
      props: true,
      component: Contractor,
      meta: {
        allowedDetailRecordTypes: ['UserDetail', 'EngineerDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'NetworkManager'],
        showSearchBar: true,
        searchType: SearchType.ContractorJobs,
      },
    },
    {
      path: '/contractorManagement/:contractorId/:jobId/:contractorAppointedId',
      name: 'contractorManagement', // There's a hardcoded check for this route name, meaning it needs to be a duplicate of the other one.
      component: Contractor,
      meta: {
        allowedDetailRecordTypes: ['UserDetail', 'EngineerDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'NetworkManager'],
        showSearchBar: true,
        searchType: SearchType.ContractorJobs,
      },
    },
    {
      path: '/networkManagerDashboard',
      name: 'networkManagerDashboard',
      component: NetworkManagerDashboard,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'NetworkManager'],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: TwilioDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/tradeManagement',
      name: 'tradeManagement',
      component: TradeManagement,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator'],
      },
    },
    {
      path: '/materialManagement',
      name: 'materialManagement',
      component: MaterialManagement,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator'],
      },
    },
    {
      path: '/clientmanagement',
      name: 'clientManagement',
      component: ClientManagement,
      meta: {
        allowedDetailRecordTypes: ['UserDetail', 'ClientUserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'ClientManager'],
        searchType: SearchType.ClientJobs,
      },
    },
    {
      path: '/clientmanagement/financialSummary',
      name: 'clientManagementwithfinancialSummary',
      component: ClientManagement,
      meta: {
        allowedDetailRecordTypes: ['UserDetail', 'ClientUserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'ClientManager'],
        searchType: SearchType.ClientJobs,
      },
    },
    {
      // I don't know if this route is actually still needed anymore, but I'm scared to remove it.
      path: '/clientmanagement/:insurerId/:bordereauName',
      name: 'clientManagementwithBordereauDetail',
      component: ClientManagement,
      meta: {
        allowedDetailRecordTypes: ['UserDetail', 'ClientUserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'ClientManager'],
        searchType: SearchType.ClientJobs,
      },
    },
    {
      path: '/complainttracker',
      name: 'complaintTracker',
      component: ComplaintTracker,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/managerSupervisorDashboard',
      name: 'managerSupervisorDashboard',
      component: ManagerSupervisorDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/jobDashboard',
      name: 'jobDashboard',
      component: JobDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/unlinkedPhoneCallsDashboard',
      name: 'unlinkedPhoneCallsDashboard',
      component: UnlinkedPhoneCallsDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/incidentmanagerdashboard',
      name: 'incidentmanagerdashboard',
      component: IncidentManagerDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/undeployedemergencydashboard',
      name: 'undeployedEmergencyDashboard',
      component: UndeployedEmergencyDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/thirdPartyDashboard',
      name: 'thirdPartyDashboard',
      component: ThirdPartyDashboard,
      meta: { requiresDashboardAuth: true },
    },
    {
      path: '/outstandingJobDashboard',
      name: 'outstandingJobDashboard',
      component: OutstandingJobDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/sIJobDashboard',
      name: 'sIJobDashboard',
      component: SIJobDashboard,
      meta: {
        requiresDashboardAuth: false,
      },
    },
    {
      path: '/queueDashboard',
      name: 'queueDashboard',
      component: QueueDashboard,
      meta: { requiresDashboardAuth: true },
    },
    {
      path: '/engineerRequestDashboard',
      name: 'engineerRequestDashboard',
      component: EngineerRequestDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/ctpDashboard',
      name: 'ctpDashboard',
      component: CTPDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/contractorPODashboard',
      name: 'contractorPODashboard',
      component: ContractorPODashboard,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
      },
    },
    {
      path: '/clientReports',
      name: 'clientReports',
      component: ClientReportsDashboard,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'Finance'],
      },
    },
    {
      path: '/clientReports/:insurerId/:bordereauName',
      name: 'clientReportsWithBordereauDetail',
      component: ClientReportsDashboard,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'Finance'],
      },
    },
    {
      path: '/clientInvoiceDashboard',
      name: 'clientInvoiceDashboard',
      component: ClientInvoiceDashboard,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
      },
    },
    {
      path: '/dynamicDashboard/:dashboardId?',
      name: 'dynamicDashboard',
      component: DynamicDashboard,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'Supervisor', 'Finance', 'ClientManager'],
      },
    },
    {
      path: '/contractorSignup/:signupToken?',
      name: 'contractorSignup',
      component: ContractorSignup,
      meta: {
        isOidcCallback: false,
        isPublic: true,
      },
    },
    {
      path: '/contractorRegistration',
      name: 'contractorRegistration',
      component: ContractorRegistration,
      meta: {
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator'], // TODO: add user role for, contractor with a status of Application Pending
      },
    },
    {
      path: '/missedJobsDashboard',
      name: 'missedJobsDashboard',
      component: MissedJobsDashboard,
      meta: {
        requiresDashboardAuth: true,
      },
    },
    {
      path: '/usJobDashboard',
      name: 'usJobDashboard',
      component: USJobDashboard,
      meta: {
        requiresDashboardAuth: false,
      },
    },
    {
      path: '/clientInvoiceDashboardSI',
      name: 'clientInvoiceDashboardSI',
      component: ClientInvoiceDashboardSI,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
      },
    },
    {
      path: '/cookieManagement',
      name: 'cookieManagement',
      component: CookieManagement,
      meta: {
        allowedDetailRecordTypes: ['UserDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator'],
      },
    },
    {
      path: '/agentsDashboard',
      name: 'agentsDashboard',
      component: AgentsDashboard,
      meta: {
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'Supervisor'],
      },
    },
    {
      path: '/regionManager',
      name: 'regionManager',
      component: RegionManagement,
      meta: {
        allowedDetailRecordTypes: ['UserDetail', 'EngineerDetail'],
        allowedUserDetailRoleNames: ['SystemAdministrator', 'Administrator', 'NetworkManager'],
      },
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: UnauthorizedView,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '*',
      name: 'notFound',
      component: NotFound,
      meta: {
        isPublic: true,
      },
    },
  ],
})

router.beforeEach((to: any, from: any, next: any) => {
  RouterAuth.BeforeEach(to, from, next)
})

export default router
