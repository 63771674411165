export default class UpdateCustomerAddressDetail {
  public jobId: string
  public clientTitle: string
  public clientForename: string
  public clientLastName: string
  public secondaryClientTitle: string | null
  public secondaryClientForename: string | null
  public secondaryClientLastName: string | null
  public policyNumber: string
  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postCode: string

  public constructor() {
    // set initial values
    this.clientTitle = ''
    this.clientForename = ''
    this.clientLastName = ''
    this.secondaryClientTitle = ''
    this.secondaryClientForename = ''
    this.secondaryClientLastName = ''
    this.addressLine1 = ''
    this.addressLine2 = ''
    this.addressLine3 = ''
    this.addressLine4 = ''
    this.postCode = ''
  }
}
