import { UserRole } from '../user/UserModel'

export default class RelativeUserPermissionsModel {
  public canChangeAccount = false
  public canChangeJobTypes = false
  public canChangeDashboards = false
  public canChangeSkills = false
  public canChangeTeam = false
  public allowedRoles: UserRole[] = []
}
