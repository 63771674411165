<template>
  <v-layout wrap>
    <v-flex xs12>
      <div v-if="getEngineerVisitDetail.siReturnVisitReason" class="revisit-header">
        <v-tooltip top class="v-alert custom-warning-success pa-2 pink lighten-5 pink--text">
          <template #activator="{ on }">
            <span class="return-visit" v-on="on">
              <img src="/img/return-visit.svg" alt="" />
              <span>Revisit Requested</span>
            </span>
          </template>
          <div>
            <div>
              <b>Reason:</b>
              {{ getEngineerVisitDetail.siReturnVisitReason }}
            </div>
            <div v-if="getEngineerVisitDetail.siReturnVisitNote">
              <b>Note:</b>
              {{ getEngineerVisitDetail.siReturnVisitNote }}
            </div>
            <div>
              <b>Total Visits:</b>
              {{ getEngineerVisitDetail.visitNumber }}
            </div>
          </div>
        </v-tooltip>
      </div>
      <div>
        <div class="display-flex flex-wrap items-center justify-between gap-2 small-text mb-2">
          <b>{{ getStatusDetails() }}</b>

          <v-chip
            v-if="getEngineerVisitDetail.visitOutcomeDescription"
            class="ma-0 white--text"
            :color="getEngineerVisitDetail.visitOutcomeDescription === 'Job Completed' ? 'green' : 'orange'"
            small
          >
            {{ getEngineerVisitDetail.visitOutcomeDescription }}
          </v-chip>
        </div>
        <div class="small-text mb-2">
          {{ getFormattedDate(getEngineerVisitDetail.processAt) }}
        </div>
      </div>
      <div v-if="getEngineerVisitDetail.status === engineerJobVisitStatus[engineerJobVisitStatus.RunningLate]">
        <div class="mb-1">
          <b>ETA From:</b>
          {{ getFormattedDate(getEngineerVisitDetail.etaFrom) }}
        </div>
        <div class="mb-1">
          <b>ETA To:</b>
          {{ getFormattedDate(getEngineerVisitDetail.etaTo) }}
        </div>
      </div>
      <div>{{ getEngineerVisitDetail.description }}</div>
      <div v-if="claimPictures && !loading" class="avtar-container">
        <template v-for="(item, i) in claimPictures">
          <v-avatar v-if="i < 2" :key="i" class="mr-2 card-images">
            <img :src="item.fileURL" :alt="item.title" />
          </v-avatar>
        </template>
        <v-avatar v-show="claimPictures.length > 2">
          <span class="total-images">+{{ claimPictures.length - 2 }}</span>
        </v-avatar>
      </div>
      <div v-if="loading" class="pa-2">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import storeGetters from '@/storeGetters'
import Shared from '@/common/shared'
import Store from '@/store'
import moment from 'moment'
import { EngineerJobVisitStatus } from '@/common/enums'
import UserModel from '@/models/user/UserModel'
import EngineerController from '@/api/engineerController'
import EngineerModel from '@/models/contractor/EngineerModel'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import eventBus from '@/common/bus'
import PhotosController from '@/api/photos-controller'
import { imageExists } from '@/common/images'

@Component
export default class EngineerVisitCard extends TimeLineItemBase {
  private engineerJobVisitStatus = EngineerJobVisitStatus
  private engineer: EngineerModel | null = new EngineerModel()
  private claimPictures: PictureUploadModel[] = []
  private loading = false

  private created() {
    this.getEngineerDetails()
    this.claimPictures = JSON.parse(JSON.stringify(this.items.filter((item) => item.visitId === this.itemId)))
    this.getImages()
  }

  private getEngineerDetails() {
    EngineerController.GetEngineerDetails(this.getEngineerVisitDetail.engineerId)
      .then((res: EngineerModel | null) => {
        if (res !== null) {
          this.engineer = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading engineer detail, please try again', true)
      })
  }

  @Watch('items')
  private onClaimPictureChange(newValue: PictureUploadModel[], oldValue: PictureUploadModel[]) {
    this.claimPictures = JSON.parse(JSON.stringify(this.items))
    this.getImages()
  }

  @Watch('getEngineerVisitDetail.engineerId')
  private onEngineerIdChange() {
    this.getEngineerDetails()
  }

  private getUserName(id: string) {
    const user: UserModel | undefined = this.getUsers.find((e) => e.id === id)
    if (user !== undefined) {
      return user.displayValue
    }
  }

  private get getUsers(): UserModel[] {
    return storeGetters.getUsers()
  }

  private get getEngineerVisitDetail(): EngineerVisitDetail {
    return storeGetters.getEngineerVisitDetail(this.jobId, this.itemId)
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private getImages() {
    if (this.claimPictures.length > 0) {
      for (let i = 0; i < this.claimPictures.length; i++) {
        this.imageUrlCheck(i)
      }
    }
  }

  private get items(): PictureUploadModel[] {
    return storeGetters.getClaimPicturesByVisitId(this.jobId, this.getEngineerVisitDetail.id)
  }

  private async imageUrlCheck(i: number) {
    const photo = this.claimPictures[i]
    const exists = await imageExists(photo.fileURL)
    if (exists) {
      return
    }

    if (photo.fileURL.indexOf('?') > 0) {
      return
    }
    try {
      this.loading = true
      const url = await PhotosController.GetThumbnailUrl(photo.jobId, photo.id)
      this.claimPictures[i].fileURL = url
      this.claimPictures = [...this.claimPictures]
    } catch {
      eventBus.$emit('errorHandler', 'Error loading image, please try again', true)
    } finally {
      this.loading = false
    }
  }

  private getStatusDetails(): string {
    let assigneeName: string | undefined = ''
    if (
      this.getEngineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Pending] ||
      this.getEngineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Cancelled] ||
      this.getEngineerVisitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.ReAssigned]
    ) {
      // get user name
      assigneeName = this.getUserName(this.getEngineerVisitDetail.processByUserId)
    } else {
      // get engineer name
      assigneeName = this.engineer ? this.engineer.firstName + ' ' + this.engineer.lastName : ''
    }

    // TODO: Temporary fix to show engineer text instead of undefined
    if (!assigneeName || assigneeName === 'undefined' || assigneeName === 'undefined undefined') {
      assigneeName = 'Engineer'
    }

    const statusMap = {
      [EngineerJobVisitStatus.Pending]: 'Assigned by',
      [EngineerJobVisitStatus.Cancelled]: 'Cancelled by',
      [EngineerJobVisitStatus.Accepted]: 'Accepted by',
      [EngineerJobVisitStatus.Rejected]: 'Rejected by',
      [EngineerJobVisitStatus.OnTheWay]: 'Processed by',
      [EngineerJobVisitStatus.RunningLate]: 'Running Late',
      [EngineerJobVisitStatus.OnSite]: 'On Site',
      [EngineerJobVisitStatus.PolicyHolderAbsent]: 'Processed by',
      [EngineerJobVisitStatus.Abandoned]: 'Job Abandoned by',
      [EngineerJobVisitStatus.JobStarted]: 'Job Started by',
      [EngineerJobVisitStatus.Completed]: 'Job Completed by',
      [EngineerJobVisitStatus.ReAssigned]: 'Reassigned by',
    }
    const statusText = statusMap[EngineerJobVisitStatus[this.getEngineerVisitDetail.status]] || ''
    return [statusText, assigneeName].filter(Boolean).join(': ')
  }
}
</script>

<style scoped>
.pictureUploadCard .v-avatar img {
  object-fit: cover;
}
.total-images {
  font-size: 18px;
  font-weight: 600;
}
.card-images {
  border: 1px solid #dcd6d6;
  border-radius: 50%;
}
.card-images img {
  object-fit: cover;
}
.revisit-header {
  padding-bottom: 10px;
}
.revisit-header .return-visit {
  width: 100%;
}
.revisit-header .return-visit img {
  width: 25px;
}
.revisit-header .return-visit span {
  position: absolute;
  padding: 6px;
}
.revisit-header .custom-warning-success {
  border-top: 0px;
}
</style>
