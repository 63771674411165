<template>
  <div>
    <div class="mb-2"><h4>Delete Water Mains Test</h4></div>
    <div v-if="item">
      <div v-if="item.testNumber" class="mb-1">
        <label>Test Number: </label>
        <span>{{ item.testNumber }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import DeleteWaterMainsTestRecord from '@/models/siteInvestigation/requests/DeleteWaterMainsTestRecord'

@Component
export default class DeleteWaterMainsTestLog extends Vue {
  @Prop() private requestBody: any

  private get item(): DeleteWaterMainsTestRecord {
    return Object.assign(new DeleteWaterMainsTestRecord(), this.requestBody)
  }
}
</script>

<style scoped></style>
