// Properties set as readonly as they should not be able to be
// altered once passed the backend

export default class AutoDeployment {
  public readonly jobId = ''
  public readonly jobEmergencyId = ''
  public readonly autoDeploymentStartedAt: Date
  public readonly autoDeploymentUpdatedAt: Date
  public readonly deploymentsOffered: AutoDeploymentOffer[] = []
}

export class AutoDeploymentOffer {
  public readonly contractorId = ''
  public readonly contractorName = ''
  public readonly status: AutoDeploymentStatus = AutoDeploymentStatus.NotOffered
  public readonly statusUpdatedAt: Date
}

export enum AutoDeploymentStatus {
  NotOffered = 0,
  Offered = 1,
  Accepted = 2,
  Rejected = 3,
}
