import moment, { Moment } from 'moment'
import EmergencyQA from '@/models/EmergencyQA'
import Emergency from '@/models/Emergency'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import { ContactPreference, JobStatus, JobType } from '@/models/types'
import { WeatherForecastModel } from '@/models/WeatherForecastModel'
import storeMutations from '@/storeMutations'
import storeGetters from '@/storeGetters'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import CallContractorModel from '@/models/claim/CallContractorModel'
import CallLogModel from '@/models/claim/CallLogModel'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import FinancialSummaryModel from '@/models/claim/FinancialSummaryModel'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import JobNoteModel from '@/models/claim/JobNoteModel'
import SMSModel from '@/models/claim/SMSModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import PolicyEnquiry from '@/models/claim/PolicyEnquiry'
import ComplaintModel from '@/models/claim/ComplaintModel'
import ThirdPartyAppointedContractor from '@/models/claim/ThirdPartyAppointedContractor'
import { Complexity, CommodityWorkType } from '@/common/enums'
import SoftFixedEmergency from './claim/SoftFixedEmergencyModel'
import UserModel from './user/UserModel'
import EngineerVisitDetail from './claim/EngineerVisitDetailModel'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import BoilerBreakdownQA from './claim/BoilerBreakdownQA'
import ContractorInvoiceDetailModel from '@/models/claim/ContractorInvoiceDetailModel'
import ClientInvoiceDetailModel from './claim/ClientInvoiceDetailModel'
import HelplinePolicyModel from './claim/HelplinePolicyModel'
import VisitHealthAndSafetyProcess from './claim/VisitHealthAndSafetyProcessModel'
import SIJobDetailModel from '@/models/SIJobDetailModel'
import ProductQA from '@/models/ProductQA'
import MonitoringDetail from '@/models/siteInvestigation/MonitoringDetail'
import EmailModel from './claim/EmailModel'
import RemoveEmergencyFromJobModel from '@/models/claim/RemoveEmergencyFromJobModel'
import IVSDetailModel from '@/models/IVSDetailModel'
import SIVisitTrialPit from './siteInvestigation/SIVisitTrialPit'
import CCTVControlLog from './siteInvestigation/CCTVControlLog'
import DatumControlLog from './siteInvestigation/DatumControlLog'
import WaterMainsTest from './siteInvestigation/WaterMainsTest'
import TrialPitDrawingModel from './siteInvestigation/TrialPitDrawingModel'
import DrainageReportModel from '@/models/claim/DrainageReportModel'
import CustomerAppointedTradespeople from './claim/CustomerAppointedTradespeople'
import JobDelay from '@/models/JobDelay'
import JobEscalationDetail from './claim/JobEscalationDetailModel'
import RepairEstimateModel from './undergroundServices/RepairEstimateModel'
import { SIProductType } from './../common/enums'

export default class Job extends CosmosJobsDbRecordBase {
  // top level properties for this job
  public loggedVia: string
  public loggedByUserId: string
  public loggedByUserName: string

  public clientTitle: string
  public clientForename: string
  public clientLastName: string

  public secondaryClientTitle: string | null
  public secondaryClientForename: string | null
  public secondaryClientLastName: string | null

  public addressLine1: string
  public addressLine2: string
  public addressLine3: string
  public addressLine4: string
  public postCode: string

  public latitude: number | null
  public longitude: number | null

  public mobilePhone: string
  public email: string

  public contactPreference: ContactPreference

  public policyNumber: string
  public customerRef: string
  public policyScheme: string
  public insurer: string
  public insurerId: number
  public insuranceValue: number
  public policyScheduleId: number
  public policyScheduleImageUrl: string

  public fnolCallRecordingUrl: string

  public nomineeClientTitle: string | null
  public nomineeClientForename: string | null
  public nomineeClientLastName: string | null
  public relation: string | null
  public nomineeMobilePhone: string | null
  public nomineeEmail: string | null
  public nomineeContactPreference: ContactPreference
  public complexity: Complexity

  public isStarredPolicy: boolean

  public dasPolicyHolderUNID: string

  public emergencies: Emergency[] // collection of emergencies logged for this job
  public emergenciesQAs: EmergencyQA[] // collection of QA's logged for this job
  public healthAndSafetyQAs: HealthAndSafetyQAModel[]
  public callContractors: CallContractorModel[]
  public callLogs: CallLogModel[]
  public claimPictures: PictureUploadModel[]
  public engineerRequest: EngineerRequestModel[]
  public vulnerabilityQA: VulnerabilityQAModel | null
  public customerAvailability: CustomerAvailabilityModel | null
  public financialSummary: FinancialSummaryModel | null
  public customerToPay: CustomerToPayModel[]
  public jobNotes: JobNoteModel[]
  public SmsTexts: SMSModel[]
  public Emails: EmailModel[]
  public weatherForecast: WeatherForecastModel | null
  public contractorAppointed: ContractorAppointedModel[]
  public policyEnquiry: PolicyEnquiry | null
  public complaint: ComplaintModel[]
  public thirdPartyAppointedContractor: ThirdPartyAppointedContractor | null
  public softFixedEmergency: SoftFixedEmergency[]
  public engineerVisitDetail: EngineerVisitDetail[]
  public status: JobStatus
  public isVIPCustomer: boolean
  public accessNote: AccessNoteModel | null
  public jobCompletedAtUtc: Moment | null
  public jobCompletedBy: string
  public additionalCostsAuthorised: boolean
  public additionalPaymentAuthorizationReason: string
  public overrideLabourCost: number
  public overrideMaterialCost: number
  public boilerBreakdownQA: BoilerBreakdownQA[]
  public contractorInvoiceDetail: ContractorInvoiceDetailModel[]
  public clientInvoiceDetails: ClientInvoiceDetailModel[]
  public visitHealthAndSafetyProcess: VisitHealthAndSafetyProcess[]
  public cancellationReason: string
  public reasonDescription: string
  public jobType: JobType
  public recommendationScore: number | null
  public serviceQualityScore: number | null
  public qualityComments: string | undefined

  // eslint:disable-next-line:variable-name
  public __timeLineRebuildRequestedAt: Moment
  public addressModifiedCount: number
  public helplinePolicy: HelplinePolicyModel | null
  public fnolCallSid: string

  public sIJobDetail: SIJobDetailModel | null
  public areQuestionsAnswered: boolean
  public enablementTriggeredAt: moment.Moment | null
  public enablementNotes: string
  public enablementCompletedAt: moment.Moment | null
  public productQAs: ProductQA[] // collection of product QA's logged for this job
  public monitoringDetail: MonitoringDetail | null
  public removedEmergencies: RemoveEmergencyFromJobModel[]
  public isIVSJob: boolean
  public ivsDetail: IVSDetailModel | null
  public siJobDelay: JobDelay | null
  public usJobDelay: JobDelay | null
  public siTrialPitDetail: SIVisitTrialPit[]
  public cctvControlLog: CCTVControlLog | null
  public datumControlLogs: DatumControlLog[]
  public waterMainsTest: WaterMainsTest | null
  public trialPitDrawing: TrialPitDrawingModel[]
  public drainageReport: DrainageReportModel | null
  public healthAndSafetyQA: HealthAndSafetyQAModel | null
  public linkedJobId: string
  public customerAppointedTradespeople: CustomerAppointedTradespeople[]
  public reasonForChoosingCallOption: string
  public nomineeReasonForChoosingCallOption: string | null
  public jobEscalationDetails: JobEscalationDetail[]
  public commodityWorkType: CommodityWorkType
  public policyNumberNotPresentReason: string
  public repairEstimates: RepairEstimateModel[]
  public isJobReopened: boolean
  public excessAmount: number | null

  // init model
  public constructor() {
    super()
    this.emergencies = []
    this.emergenciesQAs = []
    this.healthAndSafetyQAs = []
    this.callContractors = []
    this.callLogs = []
    this.claimPictures = []
    this.engineerRequest = []
    this.vulnerabilityQA = null
    this.customerAvailability = null
    this.financialSummary = null
    this.customerToPay = []
    this.jobNotes = []
    this.SmsTexts = []
    this.latitude = null
    this.longitude = null
    this.weatherForecast = null
    this.contractorAppointed = []
    this.policyEnquiry = null
    this.complaint = []
    this.thirdPartyAppointedContractor = null
    this.softFixedEmergency = []
    this.engineerVisitDetail = []
    this.__timeLineRebuildRequestedAt = moment()
    this.isStarredPolicy = false
    this.addressModifiedCount = 0
    this.accessNote = null
    this.jobCompletedAtUtc = null
    this.overrideLabourCost = 0
    this.overrideMaterialCost = 0
    this.boilerBreakdownQA = []
    this.contractorInvoiceDetail = []
    this.clientInvoiceDetails = []
    this.helplinePolicy = null
    this.visitHealthAndSafetyProcess = []
    this.cancellationReason = ''
    this.jobType = 'HE'
    this.sIJobDetail = null
    this.areQuestionsAnswered = false
    this.enablementTriggeredAt = null
    this.enablementNotes = ''
    this.enablementCompletedAt = null
    this.productQAs = []
    this.monitoringDetail = null
    this.removedEmergencies = []
    this.isIVSJob = false
    this.ivsDetail = null
    this.siJobDelay = null
    this.usJobDelay = null
    this.siTrialPitDetail = []
    this.cctvControlLog = null
    this.datumControlLogs = []
    this.waterMainsTest = null
    this.trialPitDrawing = []
    this.drainageReport = null
    this.recommendationScore = null
    this.serviceQualityScore = null
    this.customerAppointedTradespeople = []
    this.reasonForChoosingCallOption = ''
    this.nomineeReasonForChoosingCallOption = ''
    this.policyNumberNotPresentReason = ''
    this.jobEscalationDetails = []
    this.repairEstimates = []
    this.isJobReopened = false
    this.excessAmount = null
  }

  // used by watch to trigger rebuild of the timeline
  public get timeLineRebuildRequestedAt(): Moment {
    return this.__timeLineRebuildRequestedAt
  }

  // gets the full name of the client
  public get clientFullName(): string {
    let output = ''
    const parts = [this.clientTitle, this.clientForename, this.clientLastName]
    for (const part of parts) {
      if (part && part.trim() !== '') {
        if (output.length > 0) {
          output += ' '
        }
        output += part.trim()
      }
    }
    return output
  }

  // gets the secondary full name of the client
  public get secondaryClientFullName(): string {
    let output = ''
    const parts = [this.secondaryClientTitle, this.secondaryClientForename, this.secondaryClientLastName]
    for (const part of parts) {
      if (part && part.trim() !== '') {
        if (output.length > 0) {
          output += ' '
        }
        output += part.trim()
      }
    }
    return output
  }

  // gets the full name of the nominee client
  public get nomineeClientFullName(): string {
    let output = ''
    const parts = [this.nomineeClientTitle, this.nomineeClientForename, this.nomineeClientLastName]
    for (const part of parts) {
      if (part && part.trim() !== '') {
        if (output.length > 0) {
          output += ' '
        }
        output += part.trim()
      }
    }
    return output
  }

  // updates the _timeLineRebuildRequestedAt
  // field to now, causing the watch to fire
  public triggerTimeLineRebuild() {
    storeMutations.triggerTimeLineRebuild(this.jobId)
  }

  // gets the jobs address
  public getAddress(spacer: string): string {
    let output = ''
    const lines = [this.addressLine1, this.addressLine2, this.addressLine3, this.addressLine4, this.postCode]
    for (const line of lines) {
      if (line && line.trim() !== '') {
        if (output.length > 0) {
          output += spacer
        }
        output += line.trim()
      }
    }
    return output
  }

  public get loggedByUser(): UserModel {
    const match = storeGetters.getUsers().find((e) => e.id === this.loggedByUserId)
    if (match) {
      return match
    }
    const unknownUser = new UserModel()
    unknownUser.id = this.loggedByUserId
    unknownUser.firstName = 'Unknown'
    unknownUser.lastName = 'User'
    return unknownUser
  }

  // check SI - testing only jobs
  public get isTestingOnlyJob(): boolean {
    if (
      !this.isIVSJob &&
      this.sIJobDetail !== null &&
      this.sIJobDetail.jobPackage !== null &&
      this.sIJobDetail.jobPackage.packageInfo == null &&
      this.sIJobDetail.jobPackage.bulkPitProducts == null &&
      !(this.sIJobDetail.jobPackage.extraProducts
        ? this.sIJobDetail.jobPackage.extraProducts.find((c) => c.productTypeId !== SIProductType.TestingOnly)
        : true)
    ) {
      return true
    }
    return false
  }
}
