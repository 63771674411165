<template>
  <div>
    <v-layout wrap>
      <v-flex pr-2 xs6>
        <v-flex>
          <v-select
            v-model="value.returnVisitType"
            v-validate="'required'"
            :items="returnVisitTypeList"
            item-text="description"
            item-value="description"
            label="What is the type for return visit"
            required
            data-vv-name="Return Visit Type"
            :error-messages="errors.collect('Return Visit Type')"
            class="required returnVisitType"
            @change="onReturnVisitTypeChange"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                    {{ data.item.description }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-select>
        </v-flex>
      </v-flex>
      <v-flex pl-2 xs6>
        <DateTimePicker
          ref="dateTimePickerAdditionalReturnVisitRequest"
          :date-time.sync="returnVisitAtUtc"
          :is-static-location="false"
          place-holder-text="Select return visit date"
          :min-date="minReturnVisitDate"
          :is-current-time="true"
          :show-present-time-icon="true"
        />
      </v-flex>
      <v-flex xs6 class="pr-1">
        <v-select
          v-model="estimatedVisitTimeHour"
          :items="estimatedVisitTimeHourList"
          label="Estimate Visit Time (Hours)"
          item-text="text"
          item-value="value"
          class="estimatedVisitTimeHour"
          @change="onVisitTimeHourChange($event)"
        ></v-select>
      </v-flex>
      <v-flex xs6 class="pl-1">
        <v-select
          v-model="estimatedVisitTimeMinute"
          :items="estimatedVisitTimeMinuteList"
          label="Estimate Visit Time (Minutes)"
          item-text="text"
          item-value="value"
          class="estimatedVisitTimeMinute"
          @change="onVisitTimeMinuteChange($event)"
        ></v-select>
      </v-flex>
      <v-flex v-if="!isVisitTimeValid" xs12 class="wrong-visit-time" mb-1>
        <span class="warning--text">Estimated visit duration time must be greater than 0.</span>
      </v-flex>
      <v-flex xs12>
        <v-textarea
          id="ReturnVisitReason"
          v-model="value.reasonForReturnVisit"
          v-validate="'required'"
          name="returnVisitRequestReason"
          label="Reason"
          class="required"
          required
          data-vv-name="Return Visit Reason"
          :error-messages="errors.collect('Return Visit Reason')"
        ></v-textarea>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import MasterRecord from '@/models/MasterRecord'
import DateTimePicker from '@/components/DateTimePicker.vue'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EngineerReturnVisitRequest from '@/models/claim/engineerRequest/EngineerReturnVisitRequest'
import { TimeSpanSlots } from '@/common/interfaces'
import Shared from '@/common/shared'

@Component({
  components: { DateTimePicker },
})
export default class AddReturnVisitRequest extends Vue {
  @Prop({ default: [] }) private returnVisitTypeList: MasterRecord[]
  @Prop() private value: EngineerReturnVisitRequest
  private minReturnVisitDate: string = moment().format(DateTimePicker.DATE_FORMAT)

  private estimatedVisitTimeHourList: TimeSpanSlots[] = Shared.getTimeSpanSlotsList(5, 1)
  private estimatedVisitTimeMinuteList: TimeSpanSlots[] = Shared.getTimeSpanSlotsList(4, 15)
  private estimatedVisitTimeHour: number = this.estimatedVisitTimeHourList[1].value
  private estimatedVisitTimeMinute: number = this.estimatedVisitTimeMinuteList[0].value
  private returnVisitAtUtc: moment.Moment | null = null
  private isVisitTimeValid = true

  @Watch('value')
  private onRequestUpdated() {
    this.$emit('input', this.value)
  }

  private mounted() {
    this.returnVisitAtUtc = null
    this.$emit('updateVisitHourValidation', this.isVisitTimeValid, this.estimatedVisitTimeHour)
    this.$emit('updateVisitMinutesValidation', this.isVisitTimeValid, this.estimatedVisitTimeMinute)
  }

  @Watch('returnVisitAtUtc')
  private updateReturnVisitAtUtc() {
    this.$emit('updateReturnVisitAtUtc', this.returnVisitAtUtc)
  }

  private onReturnVisitTypeChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.openConfigureDropdown()
    }
  }

  private openConfigureDropdown() {
    this.$emit('openConfigureDropdown')
  }

  private onVisitTimeHourChange(estimatedVisitTimeHour: number) {
    this.validateTimeParameters(estimatedVisitTimeHour, this.estimatedVisitTimeMinute)
  }

  private onVisitTimeMinuteChange(estimatedVisitTimeMinute: number) {
    this.validateTimeParameters(this.estimatedVisitTimeHour, estimatedVisitTimeMinute)
  }

  private validateTimeParameters(estimatedVisitTimeHour: number, estimatedVisitTimeMinute: number) {
    this.isVisitTimeValid = estimatedVisitTimeHour === 0 && estimatedVisitTimeMinute === 0 ? false : true
    this.$emit(
      'updateVisitHourMinutesValidation',
      this.isVisitTimeValid,
      estimatedVisitTimeHour,
      estimatedVisitTimeMinute
    )
  }
}
</script>

<style scoped>
.bold-select {
  font-weight: 600;
}
</style>
