import OneClickPolicyHolderModel from './OneClickPolicyHolderModel'

/* tslint:disable:variable-name */
export default class SearchItemModel {
  public id: string
  public insurer: number
  public policy_id: string
  public policy_number: string
  public ph_name: string
  public ph_postcode: string
  public ph_addressLine1: string
  public displayValue: string

  // Path/Route to data source for retrieving a full policy
  public path: string
  public oneClickData?: OneClickPolicyHolderModel
}
